import {ChangeDetectionStrategy, Component, Injector, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {
  CorePortalEntityEditBaseComponent,
  CorePortalFormlyNgSelectTyping,
  CorePortalFormlyReadonlyTypes,
  CorePortalFormlyReadonlyTyping,
  CorePortalFormlyTranslatedTyping,
  CorePortalStereotypeService
} from '@nexnox-web/core-portal';
import {TicketByProcessDefinitionDto, TicketByProcessDefinitionKind} from '@nexnox-web/core-shared';
import {FormlyFieldConfig} from '@ngx-formly/core';
import {ProcessTicketEditComponent} from '../process-ticket-edit/process-ticket-edit.component';
import {ProcessMissionEditComponent} from '../process-mission-edit/process-mission-edit.component';
import {ProcessChatEditComponent} from '../process-chat-edit/process-chat-edit.component';
import {
  CorePortalFeatureMasterDataLocationService,
  noClosedLocationsFilter$
} from '@nexnox-web/core-portal/features/master-data/features/locations';
import {
  CorePortalFeatureMasterDataLocationGroupService
} from '@nexnox-web/core-portal/features/master-data/features/location-groups';
import {ticketProcessDefinitionKindEnumOptions, ticketProcessDefinitionReadonlyKindEnumOptions} from "../../models";
import {map, Observable} from "rxjs";
import {distinctUntilChanged, startWith} from "rxjs/operators";

@Component({
  selector: 'nexnox-web-ticket-settings-processes-process-edit',
  templateUrl: './process-edit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TechPortalFeatureProcessEditComponent extends CorePortalEntityEditBaseComponent<TicketByProcessDefinitionDto> implements OnInit {
  @ViewChild('ticketEditComponent', {static: true}) public ticketEditComponent: ProcessTicketEditComponent;
  @ViewChild('missionEditComponent', {static: true}) public missionEditComponent: ProcessMissionEditComponent;
  @ViewChild('chatEditComponent', {static: true}) public chatEditComponent: ProcessChatEditComponent;

  @ViewChild('locationSelectLabelTitleTemplate', {static: true}) public locationSelectLabelTitleTemplate: TemplateRef<any>;
  @ViewChild('locationSelectOptionTitleTemplate', {static: true}) public locationSelectOptionTitleTemplate: TemplateRef<any>;

  public isLinkableDefinition$: Observable<boolean>;

  constructor(
    protected injector: Injector,
    private locationService: CorePortalFeatureMasterDataLocationService,
    private locationGroupService: CorePortalFeatureMasterDataLocationGroupService,
    private stereotypeService: CorePortalStereotypeService
  ) {
    super(injector);
  }

  public ngOnInit(): void {
    super.ngOnInit();
    setTimeout(() => this.isLinkableDefinition$ = this.form.get('kind')?.valueChanges?.pipe(startWith(TicketByProcessDefinitionKind.Default), map(k => k === TicketByProcessDefinitionKind.Linkable), distinctUntilChanged()))
  }

  public onModelChange(model: TicketByProcessDefinitionDto): void {
    this.modelValidSubject.next({
      ...this.modelValidSubject.getValue(),
      ticket: this.ticketEditComponent.isModelValid(),
      mission: this.missionEditComponent.isModelValid(),
      chat: this.model?.kind === TicketByProcessDefinitionKind.Linkable ? true : this.chatEditComponent?.isModelValid()
    });
    super.onModelChange(model);
  }

  private getKindEnum(): CorePortalFormlyNgSelectTyping {
    return (this.readonly ? ticketProcessDefinitionReadonlyKindEnumOptions : ticketProcessDefinitionKindEnumOptions) as CorePortalFormlyNgSelectTyping;
  }

  /* istanbul ignore next */
  protected createForm(): FormlyFieldConfig[] {
    return [
      {
        key: 'name',
        type: 'input',
        wrappers: ['core-portal-translated'],
        className: 'col-md-6',
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.name',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          },
          type: 'text'
        },
        expressionProperties: {
          'templateOptions.required': () => !this.readonly,
          'templateOptions.disabled': () => this.readonly
        },
        hideExpression: () => !this.creating
      },
      {
        key: 'externalId',
        type: 'core-portal-external-id',
        wrappers: ['core-portal-translated'],
        className: 'col-md-6',
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.external-id',
          },
          titleKey: 'name',
          modelSubject: this.modelSubject
        },
        expressionProperties: {
          'templateOptions.disabled': () => this.readonly
        }
      },
      {
        key: 'kind',
        type: 'core-portal-ng-select',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-6',
        defaultValue: TicketByProcessDefinitionKind.Default,
        templateOptions: {
          corePortalTranslated: {
            label: 'core-portal.settings.fields.kind',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          } as CorePortalFormlyTranslatedTyping,
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.ENUM,
            enumOptions: this.getKindEnum(),
            translate: true
          } as CorePortalFormlyReadonlyTyping,
          corePortalNgSelect: {
            items: this.getKindEnum(),
            translate: true
          } as CorePortalFormlyNgSelectTyping
        },
        expressionProperties: {
          'templateOptions.required': () => !this.readonly,
          'templateOptions.disabled': () => this.readonly || !this.creating,
        }
      },
      {
        key: 'requiresAuthentication',
        type: 'core-portal-switch',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-6',
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.requires-authentication'
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.BOOLEAN
          } as CorePortalFormlyReadonlyTyping
        },
        expressionProperties: {
          'templateOptions.readonly': () => this.readonly,
          'templateOptions.disabled': () => this.readonly
        }
      },
      {
        wrappers: ['core-portal-translated'],
        className: 'col-md-12 px-0 mt-3',
        fieldGroupClassName: 'row',
        templateOptions: {
          corePortalTranslated: {
            label: 'core-portal.settings.subtitles.ticket-settings.available-for',
            title: true,
            labelClass: 'pl-2'
          },
        },
        hideExpression: () => this.form.get('kind').value === TicketByProcessDefinitionKind.Linkable,
        fieldGroup: [
          {
            key: 'locations',
            type: 'core-portal-entity-select',
            wrappers: ['core-portal-translated'],
            className: 'col-md-6 field-group-mb-0',
            defaultValue: [],
            templateOptions: {
              corePortalTranslated: {
                label: 'core-shared.shared.fields.locations'
              },
              entityService: this.locationService,
              idKey: 'locationId',
              displayKey: 'name',
              wholeObject: true,
              multiple: true,
              mapSearchFilter: CorePortalFeatureMasterDataLocationService.mapSearchFilter,
              search: CorePortalFeatureMasterDataLocationService.searchCompare,
              selectLabelTitleTemplate: this.locationSelectLabelTitleTemplate,
              selectOptionTitleTemplate: this.locationSelectOptionTitleTemplate,
              showAll: true,
              defaultFilters$: noClosedLocationsFilter$
            },
            expressionProperties: {
              'templateOptions.disabled': () => this.readonly
            },
          },
          {
            key: 'locationGroups',
            type: 'core-portal-entity-select',
            wrappers: ['core-portal-translated'],
            className: 'col-md-6 field-group-mb-0',
            defaultValue: [],
            templateOptions: {
              corePortalTranslated: {
                label: 'core-shared.shared.fields.location-groups'
              },
              entityService: this.locationGroupService,
              idKey: 'locationGroupId',
              displayKey: 'name',
              wholeObject: true,
              multiple: true,
              showAll: true
            },
            expressionProperties: {
              'templateOptions.disabled': () => this.readonly
            }
          }
        ]
      }
    ];
  }
}
