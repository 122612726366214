import {BaseXsStoreReducerTypes, entityXsStoreSetLoadingForAction, EntityXsStoreState} from '@nexnox-web/core-store';
import {InvitationsDetailXsStoreActions} from './invitations-detail-xs-store.actions';
import {immerOn} from 'ngrx-immer/store';
import {InvitationDto} from "@nexnox-web/core-shared";

export const createInvitationsDetailXsStoreReducer = (
  actions: InvitationsDetailXsStoreActions,
  initialState: EntityXsStoreState<InvitationDto>
): BaseXsStoreReducerTypes<EntityXsStoreState<InvitationDto>, InvitationsDetailXsStoreActions>[] => ([
  immerOn(actions.revokeInvitation, draft => {
    draft.entityData = entityXsStoreSetLoadingForAction(draft.entityData, {
      [actions.revokeInvitation.type]: true
    });
  }),
  immerOn(actions.revokeInvitationSuccess, draft => {
    if (Boolean(draft.entity) && Boolean(draft.model)) {
      draft.entity.isRevoked = true;
      draft.model.isRevoked = true;
    }
    draft.entityData = entityXsStoreSetLoadingForAction(draft.entityData, {
      [actions.revokeInvitation.type]: false
    });
  }),

  immerOn(actions.reactivateInvitation, draft => {
    draft.entityData = entityXsStoreSetLoadingForAction(draft.entityData, {
      [actions.reactivateInvitation.type]: true
    });
  }),
  immerOn(actions.reactivateInvitationSuccess, draft => {
    if (Boolean(draft.entity) && Boolean(draft.model)) {
      draft.entity.isRevoked = false;
      draft.model.isRevoked = false;
    }
    draft.entityData = entityXsStoreSetLoadingForAction(draft.entityData, {
      [actions.reactivateInvitation.type]: false
    });
  }),
]);
