import {Injectable, Injector} from '@angular/core';
import {contactDetailStore} from './contact-detail.store';
import {createEffect, ofType} from '@ngrx/effects';
import {ContactDetailXsStoreActions} from './contact-detail-xs-store.actions';
import {catchError, map, switchMap, tap, withLatestFrom} from 'rxjs/operators';
import {CorePortalFeatureMasterDataContactService} from '../../services';
import {Action, select} from '@ngrx/store';
import {of} from 'rxjs';

@Injectable()
export class ContactDetailStoreEffects extends contactDetailStore.effects {
  public actions: ContactDetailXsStoreActions;
  public service: CorePortalFeatureMasterDataContactService;

  public resetPassword$: any;
  public resetPasswordSuccess$: any;

  public forcePassword$: any;
  public forcePasswordSuccess$: any;

  public enable$: any;
  public enableSuccess$: any;

  public disable$: any;
  public disableSuccess$: any;

  public changeMailAddress$: any;
  public changeMailAddressSuccess$: any;

  public bulkAreaAssignment$: any;
  public bulkAreaAssignmentSuccess$: any;

  constructor(
    protected injector: Injector
  ) {
    super(injector);
  }

  protected createEffects(): void {
    super.createEffects();

    this.resetPassword$ = createEffect(() => this.actions$.pipe(
      ofType(this.actions.resetPassword),
      withLatestFrom(this.store.pipe(select(this.selectors.selectEntity))),
      switchMap(([_, contact]) => this.service.resetPassword(contact).pipe(
        map(() => this.actions.resetPasswordSuccess()),
        catchError(error => of(this.actions.error({ error, action: this.actions.resetPassword })))
      ))
    ));

    this.resetPasswordSuccess$ = createEffect(() => this.actions$.pipe(
      ofType(this.actions.resetPasswordSuccess),
      tap(action => this.actionCallback(action, false))
    ), { dispatch: false });

    this.forcePassword$ = createEffect(() => this.actions$.pipe(
      ofType(this.actions.forcePassword),
      withLatestFrom(this.store.pipe(select(this.selectors.selectEntity))),
      switchMap(([{passwordBase64}, contact]) => this.service.forcePassword(passwordBase64, contact).pipe(
        map(() => this.actions.forcePasswordSuccess()),
        catchError(error => of(this.actions.error({ error, action: this.actions.forcePassword })))
      ))
    ));

    this.forcePasswordSuccess$ = createEffect(() => this.actions$.pipe(
      ofType(this.actions.forcePasswordSuccess),
      tap(action => this.actionCallback(action, false))
    ), { dispatch: false });

    this.enable$ = createEffect(() => this.actions$.pipe(
      ofType(this.actions.enable),
      withLatestFrom(this.store.pipe(select(this.selectors.selectModel))),
      switchMap(([_, contact]) => this.service.enableContact(contact.contactId).pipe(
        map(() => this.actions.enableSuccess()),
        catchError(error => of(this.actions.error({ error, action: this.actions.enable })))
      ))
    ));

    this.enableSuccess$ = createEffect(() => this.actions$.pipe(
      ofType(this.actions.enableSuccess),
      tap(action => this.actionCallback(action, false))
    ), { dispatch: false });

    this.disable$ = createEffect(() => this.actions$.pipe(
      ofType(this.actions.disable),
      withLatestFrom(this.store.pipe(select(this.selectors.selectModel))),
      switchMap(([_, contact]) => this.service.disableContact(contact.contactId).pipe(
        map(() => this.actions.disableSuccess()),
        catchError(error => of(this.actions.error({ error, action: this.actions.disable })))
      ))
    ));

    this.disableSuccess$ = createEffect(() => this.actions$.pipe(
      ofType(this.actions.disableSuccess),
      tap(action => this.actionCallback(action, false))
    ), { dispatch: false });

    this.changeMailAddress$ = createEffect(() => this.actions$.pipe(
      ofType(this.actions.changeMailAddress),
      withLatestFrom(this.store.pipe(select(this.selectors.selectModel))),
      switchMap(([{ email }, contact]) => this.service.changeMailAddress(contact.contactId, { email: email }).pipe(
        map(() => this.actions.changeMailAddressSuccess({ email: email })),
        catchError(error => of(this.actions.error({ error, action: this.actions.changeMailAddress })))
      ))
    ));

    this.changeMailAddressSuccess$ = createEffect(() => this.actions$.pipe(
      ofType(this.actions.changeMailAddressSuccess),
      tap(action => this.actionCallback(action, false))
    ), { dispatch: false });

    this.bulkAreaAssignment$ = createEffect(() => this.actions$.pipe(
      ofType(this.actions.bulkAreaAssignment),
      switchMap(({tenantId, totalItems, columns, filters, areaAssignment}) =>
        this.service.bulkAreaAssignment({tenantId, totalItems, columns, filters, areaAssignment}).pipe(
          map(() => this.actions.bulkAreaAssignmentSuccess({tenantId, totalItems, columns, filters, areaAssignment})),
          catchError(error => of(this.actions.error({error, action: this.actions.bulkAreaAssignment})))
        ))
    ));

    this.bulkAreaAssignmentSuccess$ = createEffect(() => this.actions$.pipe(
      ofType(this.actions.bulkAreaAssignmentSuccess),
      tap(action => this.actionCallback(action, false))
    ), { dispatch: false });
  }

  protected actionCallback(action: Action, isError: boolean): void {
    super.actionCallback(action, isError);

    this.checkAction(this.actions.resetPasswordSuccess, action, () => this.resetPasswordSuccessActionCallback());
    this.checkAction(this.actions.forcePasswordSuccess, action, () => this.forcePasswordSuccessActionCallback());
    this.checkAction(this.actions.enableSuccess, action, () => this.enableSuccessActionCallback());
    this.checkAction(this.actions.disableSuccess, action, () => this.disableSuccessActionCallback());
    this.checkAction(this.actions.changeMailAddressSuccess, action, () => this.changeMailAddressActionCallback());
    this.checkAction(this.actions.bulkAreaAssignmentSuccess, action, () => this.bulkAreaAssignmentActionCallback());
  }

  protected resetPasswordSuccessActionCallback(): void {
    this.apiNotificationService.showTranslatedSuccess('core-portal.master-data.contact.toast.password-reset');
  }

  protected forcePasswordSuccessActionCallback(): void {
    this.apiNotificationService.showTranslatedSuccess('core-portal.master-data.contact.toast.force-password');
  }

  protected enableSuccessActionCallback(): void {
    this.apiNotificationService.showTranslatedSuccess('core-portal.master-data.contact.toast.enabled');
  }

  protected disableSuccessActionCallback(): void {
    this.apiNotificationService.showTranslatedSuccess('core-portal.master-data.contact.toast.disabled');
  }

  protected changeMailAddressActionCallback(): void {
    this.apiNotificationService.showTranslatedSuccess('core-portal.master-data.contact.toast.change-mail-address');
  }

  protected bulkAreaAssignmentActionCallback(): void {
    this.apiNotificationService.showTranslatedSuccess('core-portal.master-data.contact.toast.bulk-area-assignment');
  }
}
