<nexnox-web-entity-edit *ngIf="!creating"
                        [entityEditBaseComponent]="this"
                        displayKey="title">
</nexnox-web-entity-edit>

<nexnox-web-tabs *ngIf="creating"
                 [internal]="true"
                 [isTransparent]="true"
                 startTab="entityEdit">

  <!-- Entity edit tab -->
  <ng-template nexnoxWebTabTemplate
               tabId="entityEdit"
               [heading]="title | translate"
               [hasError]="!isOwnModelValid()">
    <nexnox-web-entity-edit [entityEditBaseComponent]="this"></nexnox-web-entity-edit>
  </ng-template>

  <!-- Filter selection -->
  <ng-template nexnoxWebTabTemplate
               tabId="restriction"
               [heading]="'core-portal.settings.notification-rules.restriction' | translate"
               [lazy]="true"
               [hasError]="(model?.restriction?.filters ?? []).length === 0">
    <nexnox-web-settings-notification-rules-restriction [creating]="creating"
                                                        [readonly]="readonly"
                                                        [loading]="loading"
                                                        [trigger]="model?.trigger"
                                                        [restriction]="model?.restriction"
                                                        (restrictionChanged)="onRestrictionChange($event)">
    </nexnox-web-settings-notification-rules-restriction>
  </ng-template>

  <!-- Target selection -->
  <ng-template nexnoxWebTabTemplate
               tabId="targets"
               [heading]="'core-portal.settings.notification-rules.target' | translate"
               [hasError]="(model?.targets ?? []).length === 0">
    <nexnox-web-settings-notification-rules-target-edit [targets]="model?.targets"
                                                        [readonly]="readonly"
                                                        [loading]="loading"
                                                        (targetsChanged)="onTargetsChanged($event)">
    </nexnox-web-settings-notification-rules-target-edit>
  </ng-template>
</nexnox-web-tabs>
