import {CorePortalSearchListsService} from './services';
import {
  SearchListsDetailStoreEffects,
  searchListsDetailStoreReducer,
  SearchListsListStoreEffects,
  searchListsListStoreReducer
} from './stores';

export * from './services';
export * from './stores';

export * from './search-lists.state';
export * from './search-lists.selectors';

export const searchListsReducers = {
  searchListsList: searchListsListStoreReducer,
  searchListsDetail: searchListsDetailStoreReducer
};

export const searchListsEffects = [
  SearchListsListStoreEffects,
  SearchListsDetailStoreEffects
];

export const searchListsServices = [
  CorePortalSearchListsService
];
