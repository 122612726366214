import {NgModule} from '@angular/core';
import {CorePortalEntityModule} from '@nexnox-web/core-portal';
import {CoreSharedModule} from '@nexnox-web/core-shared';
import {CorePortalFeatureInvitationsEditComponent} from './components';
import {InvitationsContactListComponent, InvitationsDetailComponent, InvitationsListComponent} from './containers';
import {invitationsServices} from './store';

@NgModule({
  declarations: [
    InvitationsListComponent,
    InvitationsDetailComponent,
    CorePortalFeatureInvitationsEditComponent,

    InvitationsContactListComponent
  ],
  providers: [
    ...invitationsServices
  ],
  imports: [
    CoreSharedModule,
    CorePortalEntityModule
  ],
  exports: []
})
export class InvitationsDeclarationsModule {
}
