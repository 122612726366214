import { CoreSharedApiBaseService, Product } from '@nexnox-web/core-shared';
import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {HttpResponse} from "@angular/common/http";

@Injectable()
export class TechPortalFeatureMissionMyRequestsService extends CoreSharedApiBaseService {
  constructor(
    protected injector: Injector
  ) {
    super(injector, Product.TECH, 'contact/me/openeditorquest');
  }

  public acceptMission<AcceptEditorRequestDto>(missionId: string | number, contactId: string | number): Observable<HttpResponse<any>> {
    return this.httpRequestService.post<AcceptEditorRequestDto>(`mission/${missionId}/editorquest/accept`, {contactId});
  }

  public declineMission<DeclineEditorRequestDto>(missionId: string | number, contactId: string | number): Observable<HttpResponse<any>> {
    return this.httpRequestService.post<DeclineEditorRequestDto>(`mission/${missionId}/editorquest/decline`, {contactId});
  }
}
