import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {CoreSharedSortableListItem} from '../../models';
import {cloneDeep, isUndefined, uniq} from 'lodash';
import {SortableComponent} from 'ngx-bootstrap/sortable';
import {DndDropEvent} from 'ngx-drag-drop';
import {map} from 'rxjs/operators';
import {CoreSharedSortableListItemTemplateDirective} from '../../directives';
import {faTimes} from '@fortawesome/free-solid-svg-icons/faTimes';
import {faGripVertical} from '@fortawesome/free-solid-svg-icons/faGripVertical';
import {ConfirmationService} from 'primeng/api';
import {NexnoxWebFaIconString} from '../../../shared';
import {faExclamationTriangle} from '@fortawesome/free-solid-svg-icons/faExclamationTriangle';
import {TranslateService} from '@ngx-translate/core';
import {faArrowRight} from '@fortawesome/free-solid-svg-icons/faArrowRight';
import {IconDefinition} from "@fortawesome/fontawesome-common-types";

interface LocalSortableListItem extends CoreSharedSortableListItem {
  externalData: any;
}

export interface SortableListRowAction {
  icon: IconDefinition;
  label?: string;
  tooltip?: string;
  class?: string;

  disabled?(item: any): boolean;

  hidden?(item: any): boolean;

  click(item: any): void;
}

@Component({
  selector: 'nexnox-web-sortable-list',
  templateUrl: './sortable-list.component.html',
  styleUrls: ['./sortable-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CoreSharedSortableListComponent implements OnInit {
  @Input() public disabled: boolean;
  @Input() public sortable = true;
  @Input() public dropType: string;
  @Input() public editable = false;
  @Input() public hideItemName = false;
  @Input() public itemNameBold = true;
  @Input() public notTitle = false;
  @Input() public noPaddingBottom = false;
  @Input() public asCards: boolean;
  @Input() public deletable = true;
  @Input() public selectable = false;
  @Input() public canSelectFn: (item: any) => boolean;
  @Input() public selectMultiple = false;
  @Input() public deleteLocally = true;
  @Input() public deleteConfirmation: string;
  @Input() public customTrackByFn: (index: number, item: any) => number;
  @Input() public actions: SortableListRowAction[];

  public get items(): CoreSharedSortableListItem[] {
    return this.itemsSubject.getValue();
  }

  @Input()
  public set items(items: CoreSharedSortableListItem[]) {

    items = items ?? [];

    if (this.itemsSubject.getValue().length !== items.length) {
      this.itemsOneTimeExternalDataSubject.next(items.map(x => x.getOneTimeExternalData ? x.getOneTimeExternalData() : {}));
    }

    this.itemsSubject.next(items);
  }

  @ViewChild('sortableComponent', {static: false}) public sortableComponent: SortableComponent;

  @ContentChild(CoreSharedSortableListItemTemplateDirective) public itemTemplate: CoreSharedSortableListItemTemplateDirective;

  @Output() public selectItem: EventEmitter<any> = new EventEmitter<any>();
  @Output() public selectedItemsChange: EventEmitter<any[]> = new EventEmitter<any[]>();
  @Output() public itemsChange: EventEmitter<CoreSharedSortableListItem[]> = new EventEmitter<CoreSharedSortableListItem[]>();
  @Output() public deleteItem: EventEmitter<CoreSharedSortableListItem> = new EventEmitter<CoreSharedSortableListItem>();

  public items$: Observable<LocalSortableListItem[]>;

  public faGripVertical = faGripVertical;
  public faTimes = faTimes;
  public faArrowRight = faArrowRight;

  private itemsSubject: BehaviorSubject<CoreSharedSortableListItem[]> = new BehaviorSubject<CoreSharedSortableListItem[]>([]);
  private itemsOneTimeExternalDataSubject: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  private selectedItemsSubject: BehaviorSubject<CoreSharedSortableListItem[]> = new BehaviorSubject<CoreSharedSortableListItem[]>([]);

  constructor(
    private confirmationService: ConfirmationService,
    private translate: TranslateService
  ) {
  }

  public static orderItems<T extends { position?: number }>(event: DndDropEvent, items: T[]): T[] {

    // Get item form list
    const itemFromEvent: CoreSharedSortableListItem = event.data;
    const itemFromList = items.find(x => x.position === itemFromEvent.position);

    // Assign indexes
    let newIndex = event.index;
    const oldIndex = items.findIndex(x => x.position === event.data.position);

    // Workaround: If the item is moved downwards, adjust the index, because ngx-drag-drop counts the placeholder as an item.
    if (oldIndex < newIndex) {
      newIndex = event.index - 1;
    }

    // Reorder items
    if (!isUndefined(itemFromList) && newIndex !== oldIndex) {
      const reorderedItems = cloneDeep(items);
      reorderedItems.splice(oldIndex, 1);
      reorderedItems.splice(newIndex, 0, itemFromList);
      reorderedItems.map((item: T, index: number) => item.position = index);
      return reorderedItems;
    }

    // If nothing return untouched list
    return items;
  }

  public ngOnInit(): void {
    this.items$ = this.itemsSubject.asObservable().pipe(
      map(items => items.sort((a, b) => a.position - b.position)),
      map(items => items.map((item, index) => {
        const oneTimeExternalData = this.itemsOneTimeExternalDataSubject.getValue() ?? null;
        const oneTimeExternalDataForItem = oneTimeExternalData[index] ?? {};

        return {
          ...item,
          externalData: {...item.getExternalData(), ...oneTimeExternalDataForItem},
          deletable: isUndefined(item.deletable) ? true : item.deletable
        };
      }))
    );
    this.trackItemsBy = this.customTrackByFn ?? this.trackItemsBy;
  }

  public onUpdateTitle(item: CoreSharedSortableListItem, title: string): void {
    const items = cloneDeep(this.items);
    const itemIndex = items.findIndex(x => x.position === item.position);
    items[itemIndex].title = title;
    this.updateItems(items);
  }

  public onDrop(event: DndDropEvent): void {
    const items = CoreSharedSortableListComponent.orderItems(event, this.items);
    this.updateItems(items);
    this.itemsOneTimeExternalDataSubject.next(items.map(x => x.getOneTimeExternalData ? x.getOneTimeExternalData() : {}));
  }

  public onSelect(item: LocalSortableListItem): void {
    this.selectItem.emit(item.externalData);
  }

  public onSelectionChange(item: LocalSortableListItem, value: boolean): void {
    const currentSelectedItems = this.selectedItemsSubject.getValue();

    if (value) {
      this.selectedItemsSubject.next(uniq([...currentSelectedItems, item]));
    } else {
      const itemIndex = currentSelectedItems.findIndex(x => x.position === item.position);

      if (itemIndex > -1) {
        currentSelectedItems.splice(itemIndex, 1);
        this.selectedItemsSubject.next(currentSelectedItems);
      }
    }

    this.selectedItemsChange.next(this.selectedItemsSubject.getValue().map(x => x.getExternalData()));
  }

  public onDelete(item: CoreSharedSortableListItem, event: MouseEvent): void {
    const deleteItem = (): void => {
      const items = cloneDeep(this.items);
      const itemIndex = items.findIndex(x => x.position === item.position);

      if (this.deleteLocally) {
        items.splice(itemIndex, 1);
        for (let i = 0; i < items.length; i++) {
          if (i < itemIndex) {
            continue;
          }

          items[i].position--;
        }
      }

      this.deleteItem.emit(item);
      this.updateItems(items);
    };

    if (this.deleteConfirmation) {
      this.confirmationService.confirm({
        key: 'delete-sortable-list-item',
        target: event.currentTarget,
        message: this.deleteConfirmation,
        icon: NexnoxWebFaIconString.transformIcon(faExclamationTriangle),
        acceptLabel: this.translate.instant('core-portal.core.general.yes'),
        acceptButtonStyleClass: 'p-button-warning',
        rejectLabel: this.translate.instant('core-portal.core.general.cancel'),
        rejectButtonStyleClass: 'p-button-text p-button-secondary',
        accept: () => deleteItem()
      });
    } else {
      deleteItem();
    }
  }

  public trackItemsBy(index: number, item: CoreSharedSortableListItem): number {
    return item.position;
  }

  private updateItems(items: CoreSharedSortableListItem[]): void {
    this.itemsChange.emit(items);
    this.itemsSubject.next(items);
  }
}
