<nexnox-web-entity-edit [entityEditBaseComponent]="this"
                        [stereotyped]="false"
                        [useTitle]="true"
                        [isTransparent]="true"
                        [icon]="faCircle"
                        [iconColor]="model?.currentState?.color"
                        [isAccordion]="true"
                        [isCollapsed]="false"
                        iconSize="xs"
                        [hideAccent]="true">
  <div class="d-flex flex-column px-2"
       [style.margin-top.rem]="-0.5">

    <div *ngIf="locationReference$ | async as locationReference"
         class="d-flex flex-column mb-3">
      <label class="form-label">{{ 'core-portal.master-data.subtitles.location-detail' | translate }}</label>

      <div class="d-flex flex-row pl-3">
        <fa-icon [icon]="faHome"
                 [fixedWidth]="true"
                 size="sm"
                 class="mr-2"></fa-icon>
        <a class="btn-link"
           [nexnoxWebTenantRouterLink]="['/masterdata/locations', locationReference.locationId]"
           module="management"
           target="_blank">
          {{ locationReference.name }}
          <span *ngIf="locationReference.locationNumber">({{ locationReference.locationNumber }})</span>
        </a>
      </div>
    </div>
  </div>
</nexnox-web-entity-edit>
