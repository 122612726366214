import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BehaviorSubject, debounceTime, distinctUntilKeyChanged} from 'rxjs';
import {IssueTemplateSimpleDto, ResourceDto, UnsubscribeHelper} from '@nexnox-web/core-shared';
import {faTrash} from '@fortawesome/free-solid-svg-icons/faTrash';
import {faPlus} from '@fortawesome/free-solid-svg-icons/faPlus';
import {FormControl, FormGroup} from "@angular/forms";
import {FormlyFieldConfig} from "@ngx-formly/core";
import {faEye} from '@fortawesome/free-solid-svg-icons/faEye';

export interface IssueTemplatesChangePayload {
  issueTemplates: IssueTemplateSimpleDto[],
  inheritsIssueTemplate: boolean
}

@Component({
  selector: 'nexnox-web-resources-resource-issue-templates',
  templateUrl: './resource-issue-templates.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ResourceIssueTemplatesComponent extends UnsubscribeHelper implements OnInit {

  @Input() public issueTemplatesSubject: BehaviorSubject<any[]>;
  @Input() public resourceSubject: BehaviorSubject<ResourceDto>;
  @Input() public readonly: boolean;
  @Input() public loading: boolean;

  @Output() public issueTemplatesChange: EventEmitter<IssueTemplatesChangePayload> = new EventEmitter<IssueTemplatesChangePayload>();

  public hasParent = false;
  public parentResourceName = '';

  public form: FormGroup;
  public fields: FormlyFieldConfig[];
  public model: { inheritsIssueTemplate: boolean };

  public faTrash = faTrash;
  public faPlus = faPlus;
  public faEye = faEye;

  /* istanbul ignore next */
  public ngOnInit(): void {

    this.form = new FormGroup({inheritsIssueTemplate: new FormControl()});

    // Check for isInheritSuggestions
    this.subscribe(this.resourceSubject.pipe(distinctUntilKeyChanged('inheritsIssueTemplate')),
      (resource => {
        this.hasParent = !!resource?.parent?.resourceId;
        this.model = {inheritsIssueTemplate: !this.hasParent ? false : (resource?.inheritsIssueTemplate ?? true)}; // Default is true, if parent exists
        this.parentResourceName = this.hasParent ? resource?.parent?.name : '';
      })
    );

    // TODO: Check this
    // Emit when inheritsIssueTemplate changes
    this.subscribe(this.form.valueChanges.pipe(debounceTime(333), distinctUntilKeyChanged('inheritsIssueTemplate')), () => this.onIssueTemplatesChanged());

    this.fields = [
      {
        key: 'inheritsIssueTemplate',
        type: 'core-portal-switch',
        wrappers: ['core-portal-translated'],
        className: 'col-auto pr-0',
        templateOptions: {
          corePortalTranslated: {
            label: 'resources.actions.inherit-from-parent'
          }
        },
        expressionProperties: {
          'templateOptions.disabled': () => this.readonly || this.loading
        }
      }
    ];
  }

  public onIssueTemplatesChanged(issueTemplates?: IssueTemplateSimpleDto[]): void {
    this.issueTemplatesChange.emit({
      issueTemplates: issueTemplates ?? this.issueTemplatesSubject.getValue(),
      inheritsIssueTemplate: this.model.inheritsIssueTemplate
    })
  }

}
