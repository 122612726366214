export enum LocalStorageKeys {
  REFRESH_TOKEN = 'refresh_token',
  ALL_TOKEN = 'all_token',
  loginProvider = 'login_provider',
  ACTIVE_LANGUAGE = 'active_language',
  ACTIVE_THEME = 'active_theme',
  SELECTED_DASHBOARD = 'selected_dashboard',
  DATATABLE_VIEWS = 'datatable_views',
  CREATE_PRESETS = 'create_presets',
  LAST_ERROR = 'last_error',

  TENANT_SETTINGS = 'tenant_settings',
  LAST_TENANT_DOMAIN = 'last_tenant_domain',
  LAST_TENANT = 'last_tenant'
}
