import { MissionInspectionReportXsStore } from './mission-inspection-report.xs-store';
import { createSelector } from '@ngrx/store';
import { selectMissionsState } from '../../missions.selectors';
import { MissionInspectionReportService } from '../../services';
import { AppEntityType } from '@nexnox-web/core-shared';

/* istanbul ignore next */
export const missionInspectionReportStore = new MissionInspectionReportXsStore({
  actionLabel: 'Tech Portal - Missions - Mission Inspection Report',
  stateSelector: createSelector(selectMissionsState, state => state.missionInspectionReport),
  serviceType: MissionInspectionReportService,
  entityType: AppEntityType.MissionInspectionReport
});
