import {Injectable} from '@angular/core';
import {AppEntityType, CoreSharedHttpTechRequestService, IMonitorService, ResourceDto} from '@nexnox-web/core-shared';
import {Observable, of} from "rxjs";
import {map} from "rxjs/operators";

export interface InheritsSuggestionsPreviewPayload {
  entityType: AppEntityType;
}

@Injectable()
export class InheritsSuggestionsPreviewService implements IMonitorService<ResourceDto> {

  constructor(private httpRequestService: CoreSharedHttpTechRequestService) {
  }

  public modifyModel(payload: InheritsSuggestionsPreviewPayload, key: string, model: ResourceDto): Observable<ResourceDto> {

    if (model.inheritsSuggestions === true) {

      // Load a preview
      return this.httpRequestService.post<ResourceDto>(`resource/preview`, {
        entity: payload.entityType,
        parentId: model?.parent?.resourceId,
        stereotypeId: model?.stereotypeId
      }).pipe(
        map(response => response.body),
        map(preview => {
          return {
            ...model,
            primarySuggestions: preview.primarySuggestions,
            alternativeSuggestions: preview.alternativeSuggestions
          };
        })
      );
    } else {

      // Delete Ids
      let primarySuggestions = model?.primarySuggestions ?? [];
      let alternativeSuggestions = model?.alternativeSuggestions ?? [];

      primarySuggestions = primarySuggestions?.map(suggestion => ({...suggestion, id: 0}));
      alternativeSuggestions = alternativeSuggestions?.map(suggestion => ({...suggestion, id: 0}));

      return of({...model, primarySuggestions, alternativeSuggestions});
    }
  }
}
