import {ChangeDetectionStrategy, Component, Injector} from '@angular/core';
import {AppEntityType, ControllerOperationId, Mappers, OccurredErrorProtocolListDto} from '@nexnox-web/core-shared';
import {resourceControlProtocolErrorsStore} from '../../../store';
import {CorePortalEntityOverviewBaseComponent} from '@nexnox-web/core-portal';

@Component({
  selector: 'nexnox-web-resources-control-protocol-errors',
  templateUrl: './control-protocol-errors-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ResourceControlProtocolErrorsComponent extends CorePortalEntityOverviewBaseComponent<OccurredErrorProtocolListDto> {
  public title = 'resources.subtitles.control-protocol-errors';
  public idProperty = 'occurredConnectedErrorHistoryId';
  public displayProperty = 'code';
  public componentId = 'ResourceControlProtocolErrorsComponent';
  public datatableConfigName = 'ResourceControlProtocolErrorsDefault';
  public enableViews = true;
  public pageOperation = ControllerOperationId.OccuredErrorProtocol;

  constructor(
    protected injector: Injector
  ) {
    super(injector, resourceControlProtocolErrorsStore, Mappers.OccurredErrorProtocolListDto.serializedName, AppEntityType.None);
  }
}
