import {CustomPropertySetDto, StereotypeDto} from "@nexnox-web/core-shared";
import {cloneDeep, sortBy} from "lodash";

export function cleanUpStereoTypePositions(model: StereotypeDto): StereotypeDto {
  const newSets = cloneDeep(sortBy(model.customPropertySets, 'position'));
  for (let i = 0; i < newSets.length; i++) {
    newSets[i].position = i + 1;
    newSets[i].customPropertySet = cleanUpPropertySetPositions(newSets[i].customPropertySet);
  }
  model.customPropertySets = newSets;
  return model;
}

export function cleanUpPropertySetPositions(propertySet: CustomPropertySetDto): CustomPropertySetDto {
  propertySet.properties = sortBy(propertySet.properties, 'position');
  for (let j = 0; j < propertySet.properties.length; j++) {
    propertySet.properties[j].position = j + 1;
  }
  return propertySet;
}
