import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'nexnox-web-resources-resource-connected',
  templateUrl: './resource-connected.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ResourceConnectedComponent {
  @Input() public parentIds: Array<string | number> = [];
}

