<nexnox-web-modal #modalComponent
                  [title]="'tickets.actions.follow-up' | translate"
                  [confirmButtonText]="'tickets.actions.follow-up' | translate"
                  [cancelButtonText]="'core-portal.core.general.cancel' | translate"
                  [preConfirmFn]="preConfirmFn">
  <form [formGroup]="form" class="w-100">
    <formly-form class="row w-100"
                 [form]="form"
                 [(model)]="model"
                 [fields]="fields">
    </formly-form>
  </form>
</nexnox-web-modal>
