import { EntityXsStore } from '@nexnox-web/core-store';
import { AppEntityType, ControlTagDto } from '@nexnox-web/core-shared';
import { createSelector } from '@ngrx/store';
import { selectControlTagsState } from '../../control-tags.selectors';
import { CorePortalCCPControlTagService } from '@nexnox-web/core-portal';

/* istanbul ignore next */
export const controlTagDetailStore = new EntityXsStore<ControlTagDto>({
  actionLabel: 'Tech Portal - CCP - Control Tags - Detail',
  stateSelector: createSelector(selectControlTagsState, state => state.detail),
  serviceType: CorePortalCCPControlTagService,
  entityType: AppEntityType.ControlTag,
  stereotyped: true
});
