<div class="d-flex align-items-center">

  <input class="form-control flex-grow-1"
         [readonly]="true"
         [value]="to.async ? (to.value$ | async) : (to.value | translate)"
  />

  <button pButton
          type="button"
          [icon]="to.icon | nexnoxWebFaIconStringPipe"
          [label]="to.buttonLabel | translate"
          [title]="to.buttonLabel | translate"
          class="flex-grow-2"
          [class]="to.buttonClass"
          [disabled]="to.readonly"
          (click)="to.click(field)">
  </button>
</div>
