import {FilterOperators} from "@nexnox-web/core-shared";
import {
  faEquals,
  faGreaterThan,
  faGreaterThanEqual,
  faLessThan,
  faLessThanEqual,
  faNotEqual
} from "@fortawesome/free-solid-svg-icons";
import {faCircle} from "@fortawesome/free-regular-svg-icons";
import {IconDefinition} from "@fortawesome/fontawesome-common-types";

export interface IconOption {
  value: FilterOperators;
  icon: IconDefinition;
  title?: string;
}

export const directions: IconOption[] = [
  {
    value: FilterOperators.Default & FilterOperators.Equal,
    icon: faEquals,
    title: 'core-portal.core.filter-operator.equal'
  },
  {
    value: FilterOperators.GreaterThan,
    icon: faGreaterThan,
    title: 'core-portal.core.filter-operator.greater-than'
  },
  {
    value: FilterOperators.GreaterThanOrEqual,
    icon: faGreaterThanEqual,
    title: 'core-portal.core.filter-operator.greater-than-or-equal'
  },
  {
    value: FilterOperators.LessThan,
    icon: faLessThan,
    title: 'core-portal.core.filter-operator.less-than'
  },
  {
    value: FilterOperators.LessThanOrEqual,
    icon: faLessThanEqual,
    title: 'core-portal.core.filter-operator.less-than-or-equal'
  }
];

export const textFilters: IconOption[] = [
  {
    value: FilterOperators.Default & FilterOperators.Contains,
    icon: faCircle,
    title: 'core-portal.core.filter-operator.contains'
  },
  {
    value: FilterOperators.Equal,
    icon: faEquals,
    title: 'core-portal.core.filter-operator.equal'
  },
  {
    value: FilterOperators.NotEqual,
    icon: faNotEqual,
    title: 'core-portal.core.filter-operator.not-equal'
  }
];

export const booleanItems = [
  {value: 'true', label: 'core-portal.core.general.true'},
  {value: 'false', label: 'core-portal.core.general.false'}
];
