import {
  BaseXsStore,
  BaseXsStoreActions,
  createBaseXsStoreActions,
  CreatorAction,
  EmptyAction,
  PropsAction
} from '@nexnox-web/core-store';
import {
  AuthXsStoreChangePasswordPayload,
  AuthXsStoreChangePasswordSuccessPayload,
  AuthXsStoreChangeTenantPayload,
  AuthXsStoreForgotPasswordPayload,
  AuthXsStoreLoginPayload,
  AuthXsStoreLoginSuccessCreator,
  AuthXsStoreLoginSuccessPayload,
  AuthXsStoreLoginWithRefreshCreator,
  AuthXsStoreLoginWithRefreshPayload,
  AuthXsStoreLogoutCreator,
  AuthXsStoreLogoutPayload,
  AuthXsStoreResetPasswordPayload
} from './auth-xs-store.payloads';
import {createAction, props} from '@ngrx/store';
import {AppPermissions, MeDto, TenantInfoDto} from '@nexnox-web/core-shared';

export interface AuthXsStoreActions extends BaseXsStoreActions {
  login: PropsAction<AuthXsStoreLoginPayload>;
  loginWithRefresh: CreatorAction<AuthXsStoreLoginWithRefreshCreator, AuthXsStoreLoginWithRefreshPayload>;
  changeTenant: PropsAction<AuthXsStoreChangeTenantPayload>;
  loginSuccess: CreatorAction<AuthXsStoreLoginSuccessCreator, AuthXsStoreLoginSuccessPayload>;

  changePassword: PropsAction<AuthXsStoreChangePasswordPayload>;
  changePasswordSuccess: PropsAction<AuthXsStoreChangePasswordSuccessPayload>;

  resetPassword: PropsAction<AuthXsStoreResetPasswordPayload>;
  resetPasswordSuccess: EmptyAction;

  forgotPassword: PropsAction<AuthXsStoreForgotPasswordPayload>;
  forgotPasswordSuccess: EmptyAction;

  logout: CreatorAction<AuthXsStoreLogoutCreator, AuthXsStoreLogoutPayload>;
}

export const createAuthXsStoreActions = (label: string): AuthXsStoreActions => ({
  ...createBaseXsStoreActions(label),

  login: createAction(
    BaseXsStore.getType(label, 'Login'),
    props<AuthXsStoreLoginPayload>()
  ),
  loginWithRefresh: createAction(
    BaseXsStore.getType(label, 'Login with refresh'),
    (
      accessToken: string,
      refreshToken: string,
      restrictedRoleIds: number[],
      info: MeDto,
      activeTenant: TenantInfoDto,
      navigate: boolean = true
    ) => ({accessToken, refreshToken, restrictedRoleIds, info, activeTenant, navigate})
  ),
  changeTenant: createAction(
    BaseXsStore.getType(label, 'Change tenant'),
    props<AuthXsStoreChangeTenantPayload>()
  ),
  loginSuccess: createAction(
    BaseXsStore.getType(label, 'Login success'),
    (
      accessToken: string,
      refreshToken: string,
      restrictedRoleIds: number[],
      permissions: AppPermissions[],
      info: MeDto,
      activeTenant: TenantInfoDto,
      navigate: boolean = true
    ) => ({accessToken, refreshToken, restrictedRoleIds, permissions, info, activeTenant, navigate})
  ),

  changePassword: createAction(
    BaseXsStore.getType(label, 'Change password'),
    props<AuthXsStoreChangePasswordPayload>()
  ),
  changePasswordSuccess: createAction(
    BaseXsStore.getType(label, 'Change password success'),
    props<AuthXsStoreChangePasswordSuccessPayload>()
  ),

  resetPassword: createAction(
    BaseXsStore.getType(label, 'Reset password'),
    props<AuthXsStoreResetPasswordPayload>()
  ),
  resetPasswordSuccess: createAction(BaseXsStore.getType(label, 'Reset password success')),

  forgotPassword: createAction(
    BaseXsStore.getType(label, 'Forgot password'),
    props<AuthXsStoreForgotPasswordPayload>()
  ),
  forgotPasswordSuccess: createAction(BaseXsStore.getType(label, 'Forgot password success')),

  logout: createAction(
    BaseXsStore.getType(label, 'Logout'),
    (navigate: boolean = true) => ({navigate})
  )
});
