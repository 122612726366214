import { ChangeDetectionStrategy, Component, Injector, OnInit } from '@angular/core';
import { CorePortalEntityEditBaseComponent, CorePortalFormlyReadonlyTypes, CorePortalFormlyReadonlyTyping } from '@nexnox-web/core-portal';
import { StateMachineDto } from '@nexnox-web/core-shared';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { filter, map } from 'rxjs/operators';
import { flatten, uniqBy } from 'lodash';
import { Observable } from 'rxjs';

@Component({
  selector: 'nexnox-web-ticket-settings-state-machines-state-machine-edit',
  templateUrl: './state-machine-edit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TechPortalFeatureStateMachineEditComponent extends CorePortalEntityEditBaseComponent<StateMachineDto> implements OnInit {
  private stateEnumOptions$: Observable<any>;

  constructor(
    protected injector: Injector
  ) {
    super(injector);
  }

  public ngOnInit(): void {
    this.stateEnumOptions$ = this.modelSubject.asObservable().pipe(
      filter(model => Boolean(model?.transitions)),
      map(model => uniqBy(
        flatten(model.transitions.map(transition => [transition.inState, transition.outState])),
        state => state?.stateId
      ).filter(x => Boolean(x))),
      map(states => states.map(state => ({ value: state, label: state.name })))
    );

    super.ngOnInit();
  }

  protected createForm(): FormlyFieldConfig[] {
    return [
      {
        key: 'name',
        type: 'input',
        wrappers: ['core-portal-translated'],
        className: 'col-md-6',
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.name',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          },
          type: 'text'
        },
        expressionProperties: {
          'templateOptions.required': () => !this.readonly,
          'templateOptions.disabled': () => this.readonly
        },
        hideExpression: () => !this.creating
      },
      {
        key: 'externalId',
        type: 'core-portal-external-id',
        wrappers: ['core-portal-translated'],
        className: 'col-md-6',
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.external-id',
          },
          titleKey: 'name',
          modelSubject: this.modelSubject
        },
        expressionProperties: {
          'templateOptions.disabled': () => this.readonly
        }
      },
      // Missions
      {
        wrappers: ['core-portal-translated'],
        className: 'col-md-12 font-weight-bold',
        fieldGroupClassName: 'row font-weight-normal',
        templateOptions: {
          corePortalTranslated: {
            label: 'core-portal.settings.fields.ticket-settings.state-machines.missions'
          }
        },
        fieldGroup: [
          {
            key: 'stateAfterRejectedMission',
            type: 'core-portal-ng-select',
            wrappers: ['core-portal-translated', 'core-portal-readonly'],
            className: 'col-md-12',
            templateOptions: {
              corePortalTranslated: {
                label: 'core-portal.settings.fields.ticket-settings.state-machines.state-after-mission-rejected',
                horizontal: true,
                horizontalLabelClass: 'col-sm-8',
                horizontalFieldClass: 'col-sm-4'
              },
              corePortalReadonly: {
                type: CorePortalFormlyReadonlyTypes.ENUM,
                enumOptions: this.stateEnumOptions$
              } as CorePortalFormlyReadonlyTyping,
              corePortalNgSelect: {
                items$: this.stateEnumOptions$
              },
            },
            expressionProperties: {
              'templateOptions.disabled': () => this.readonly,
              'templateOptions.readonly': () => this.readonly
            }
          },
          {
            key: 'stateAfterLastMissionDone',
            type: 'core-portal-ng-select',
            wrappers: ['core-portal-translated', 'core-portal-readonly'],
            className: 'col-md-12',
            templateOptions: {
              corePortalTranslated: {
                label: 'core-portal.settings.fields.ticket-settings.state-machines.state-after-mission-last-done',
                horizontal: true,
                horizontalLabelClass: 'col-sm-8',
                horizontalFieldClass: 'col-sm-4'
              },
              corePortalReadonly: {
                type: CorePortalFormlyReadonlyTypes.ENUM,
                enumOptions: this.stateEnumOptions$
              } as CorePortalFormlyReadonlyTyping,
              corePortalNgSelect: {
                items$: this.stateEnumOptions$
              },
            },
            expressionProperties: {
              'templateOptions.disabled': () => this.readonly,
              'templateOptions.readonly': () => this.readonly
            }
          },
          {
            key: 'stateAfterCanceledMission',
            type: 'core-portal-ng-select',
            wrappers: ['core-portal-translated', 'core-portal-readonly'],
            className: 'col-md-12',
            templateOptions: {
              corePortalTranslated: {
                label: 'core-portal.settings.fields.ticket-settings.state-machines.state-after-mission-cancelled',
                horizontal: true,
                horizontalLabelClass: 'col-sm-8',
                horizontalFieldClass: 'col-sm-4'
              },
              corePortalReadonly: {
                type: CorePortalFormlyReadonlyTypes.ENUM,
                enumOptions: this.stateEnumOptions$
              } as CorePortalFormlyReadonlyTyping,
              corePortalNgSelect: {
                items$: this.stateEnumOptions$
              },
            },
            expressionProperties: {
              'templateOptions.disabled': () => this.readonly,
              'templateOptions.readonly': () => this.readonly
            }
          },
          {
            key: 'stateAfterClosedMission',
            type: 'core-portal-ng-select',
            wrappers: ['core-portal-translated', 'core-portal-readonly'],
            className: 'col-md-12',
            templateOptions: {
              corePortalTranslated: {
                label: 'core-portal.settings.fields.ticket-settings.state-machines.state-after-mission-closed',
                horizontal: true,
                horizontalLabelClass: 'col-sm-8',
                horizontalFieldClass: 'col-sm-4'
              },
              corePortalReadonly: {
                type: CorePortalFormlyReadonlyTypes.ENUM,
                enumOptions: this.stateEnumOptions$
              } as CorePortalFormlyReadonlyTyping,
              corePortalNgSelect: {
                items$: this.stateEnumOptions$
              },
            },
            expressionProperties: {
              'templateOptions.disabled': () => this.readonly,
              'templateOptions.readonly': () => this.readonly
            }
          }
        ]
      },
      // Emails
      {
        wrappers: ['core-portal-translated'],
        className: 'col-md-12 font-weight-bold',
        fieldGroupClassName: 'row font-weight-normal',
        templateOptions: {
          corePortalTranslated: {
            label: 'core-portal.settings.fields.ticket-settings.state-machines.email'
          }
        },
        fieldGroup: [
          {
            key: 'stateAfterReceivedEmail',
            type: 'core-portal-ng-select',
            wrappers: ['core-portal-translated', 'core-portal-readonly'],
            className: 'col-md-12',
            templateOptions: {
              corePortalTranslated: {
                label: 'core-portal.settings.fields.ticket-settings.state-machines.state-after-email-received',
                horizontal: true,
                horizontalLabelClass: 'col-sm-8',
                horizontalFieldClass: 'col-sm-4'
              },
              corePortalReadonly: {
                type: CorePortalFormlyReadonlyTypes.ENUM,
                enumOptions: this.stateEnumOptions$
              } as CorePortalFormlyReadonlyTyping,
              corePortalNgSelect: {
                items$: this.stateEnumOptions$
              },
            },
            expressionProperties: {
              'templateOptions.disabled': () => this.readonly,
              'templateOptions.readonly': () => this.readonly
            }
          }
        ]
      },

      // Chat
      {
        wrappers: ['core-portal-translated'],
        className: 'col-md-12 font-weight-bold',
        fieldGroupClassName: 'row font-weight-normal',
        templateOptions: {
          corePortalTranslated: {
            label: 'core-portal.settings.fields.ticket-settings.state-machines.chat'
          }
        },
        fieldGroup: [
          {
            key: 'stateAfterReceivedChat',
            type: 'core-portal-ng-select',
            wrappers: ['core-portal-translated', 'core-portal-readonly'],
            className: 'col-md-12',
            templateOptions: {
              corePortalTranslated: {
                label: 'core-portal.settings.fields.ticket-settings.state-machines.state-after-chat-received',
                horizontal: true,
                horizontalLabelClass: 'col-sm-8',
                horizontalFieldClass: 'col-sm-4'
              },
              corePortalReadonly: {
                type: CorePortalFormlyReadonlyTypes.ENUM,
                enumOptions: this.stateEnumOptions$
              } as CorePortalFormlyReadonlyTyping,
              corePortalNgSelect: {
                items$: this.stateEnumOptions$
              },
            },
            expressionProperties: {
              'templateOptions.disabled': () => this.readonly,
              'templateOptions.readonly': () => this.readonly
            }
          }
        ]
      }
    ];
  }
}
