<button class="download-btn w-100" *ngIf="item?.file" [title]="'core-portal.core.general.download' | translate"
        (click)="downloadFile(item.file)">
  <div class="d-flex w-100 flex-wrap">
    <div class="d-flex flex-grow-1 align-items-center">
      <div class="attachment-thumbnail">
        <fa-icon *ngIf="!isImage(item.file)" [icon]="getFileImage(item.file)" [fixedWidth]="true" size="lg"></fa-icon>
        <p-image *ngIf="isImage(item.file)" [src]="item.file.uri" [alt]="item.file.name"></p-image>
      </div>
      <span>
        {{ item.file.name }}&nbsp;({{ item.file.size | filesize }})
      </span>
      <div class="d-flex flex-grow-1 align-items-center ml-2 spinner">
        <nexnox-web-loading-icon size="sm" *ngIf="isDownloadingSubject | async">
        </nexnox-web-loading-icon>
      </div>
    </div>
    <div class="d-md-flex align-items-center d-none">
      <span>
        <span>
        {{
            'core-shared.shared.time-phrases.at-date' | translate:{
              date: item.file.creationDateTime | nexnoxWebBsDate:'L LT' | async
            }
          }}
        </span>
      </span>
    </div>
  </div>
</button>

