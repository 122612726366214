import { ModuleWithProviders, NgModule } from '@angular/core';
import { DashboardsStoreModule } from './dashboards-store.module';
import { DashboardsRoutingModule } from './dashboards-routing.module';
import { DashboardsDeclarationsModule } from './dashboards-declarations.module';

@NgModule({
  imports: [
    DashboardsDeclarationsModule,
    DashboardsRoutingModule,
    DashboardsStoreModule
  ]
})
export class CorePortalFeatureSettingsDashboardsModule {
  public static forFeature(): ModuleWithProviders<DashboardsDeclarationsModule> {
    return { ngModule: DashboardsDeclarationsModule };
  }
}
