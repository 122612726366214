import { Injectable, Injector } from '@angular/core';
import { CoreSharedApiBaseService, InventoryNumberPatternDto } from '@nexnox-web/core-shared';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class CorePortalFeatureInventoryNumberPatternService extends CoreSharedApiBaseService {
  constructor(
    protected injector: Injector
  ) {
    super(injector, null, 'inventorynumberpattern');
  }

  public getPreview(id: number): Observable<InventoryNumberPatternDto> {
    return this.httpRequestService.get<InventoryNumberPatternDto>(`${this.buildPathForOne(id)}/preview`).pipe(
      map(response => response.body)
    );
  }
}
