<ng-container *ngIf="(loading$ | async) || !loading else loadingTemplate">
  <div *ngIf="item$ | async as item" class="event-item px-2">
    <div class="event-item__icon">
      <div class="event-item__icon__circle">
        <fa-icon [icon]="getResourceEventItemIcon(item)" size="lg"></fa-icon>
      </div>
      <div class="event-item__icon__vertical-line"
           [class.d-none]="isLastItem">
      </div>
    </div>

    <div class="event-item__left-line">
      <div></div>
    </div>

    <div class="event-item__content event-item__content mb-md-4 mb-sm-0">

      <div class="event-item__content__header">
        <div class="event-item__content__header__title">
          <div>
            <span [innerHTML]="getTranslatedItem(item)"></span>
          </div>
          <div>
            <span class="event-item__content__header__title__date">
              {{ createdAt$ | async | nexnoxWebBsDate:'dd L LT' | async }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #loadingTemplate>
  <div class="event-item px-2">
    <div class="event-item__icon">
      <div class="event-item__icon__circle">
        <nexnox-web-loading-icon [size]="'lg'"></nexnox-web-loading-icon>
      </div>
      <div class="event-item__icon__vertical-line"
           [class.d-none]="true">
      </div>
    </div>

    <div class="event-item__left-line">
      <div></div>
    </div>

    <div class="event-item__content event-item__content mb-md-4 mb-sm-0">

      <div class="event-item__content__header">
        <div class="event-item__content__header__title">
          <div class="mb-1">
            <ngx-skeleton-loader [theme]="{
              display: 'flex',
              width: '200px',
              height: '16.9px',
              'margin-bottom': '0'
            }"></ngx-skeleton-loader>
          </div>
          <ngx-skeleton-loader [theme]="{
            display: 'flex',
            width: '100px',
            height: '12px',
            'margin-bottom': '0'
          }"></ngx-skeleton-loader>
        </div>
      </div>
    </div>
  </div>
</ng-template>
