import {
  BaseXsStore,
  createEntityXsStoreActions,
  EmptyAction,
  EntityXsStoreActions,
  PropsAction
} from '@nexnox-web/core-store';
import {ContactDto} from '@nexnox-web/core-shared';
import {createAction, props} from '@ngrx/store';
import {
  ContactDetailXSStoreBulkAreaAssignmentPayload,
  ContactDetailXSStoreChangeMailAddressPayload,
  ContactDetailXSStoreForcePasswordPayload
} from './contact-detail-xs-store.payloads';

export interface ContactDetailXsStoreActions extends EntityXsStoreActions<ContactDto> {
  resetPassword: EmptyAction;
  resetPasswordSuccess: EmptyAction;

  forcePassword: PropsAction<ContactDetailXSStoreForcePasswordPayload>;
  forcePasswordSuccess: EmptyAction;

  enable: EmptyAction;
  enableSuccess: EmptyAction;

  disable: EmptyAction;
  disableSuccess: EmptyAction;

  changeMailAddress: PropsAction<ContactDetailXSStoreChangeMailAddressPayload>;
  changeMailAddressSuccess: PropsAction<ContactDetailXSStoreChangeMailAddressPayload>;

  bulkAreaAssignment: PropsAction<ContactDetailXSStoreBulkAreaAssignmentPayload>;
  bulkAreaAssignmentSuccess: PropsAction<ContactDetailXSStoreBulkAreaAssignmentPayload>;
}

export const createContactDetailXsStoreActions = (label: string): ContactDetailXsStoreActions => ({
  ...createEntityXsStoreActions(label),

  resetPassword: createAction(BaseXsStore.getType(label, 'Reset password')),
  resetPasswordSuccess: createAction(BaseXsStore.getType(label, 'Reset password success')),

  forcePassword: createAction(
    BaseXsStore.getType(label, 'Force password'),
    props<ContactDetailXSStoreForcePasswordPayload>()
  ),
  forcePasswordSuccess: createAction(
    BaseXsStore.getType(label, 'Force password success')
  ),

  enable: createAction(BaseXsStore.getType(label, 'Enable')),
  enableSuccess: createAction(BaseXsStore.getType(label, 'Enable success')),

  disable: createAction(BaseXsStore.getType(label, 'Disable')),
  disableSuccess: createAction(BaseXsStore.getType(label, 'Disable success')),

  changeMailAddress: createAction(
    BaseXsStore.getType(label, 'Change Mailaddress'),
    props<ContactDetailXSStoreChangeMailAddressPayload>()
  ),
  changeMailAddressSuccess: createAction(
    BaseXsStore.getType(label, 'Change Mailaddress success'),
    props<ContactDetailXSStoreChangeMailAddressPayload>()
  ),

  bulkAreaAssignment: createAction(
    BaseXsStore.getType(label, 'Bulk Area Assignment'),
    props<ContactDetailXSStoreBulkAreaAssignmentPayload>()
  ),
  bulkAreaAssignmentSuccess: createAction(
    BaseXsStore.getType(label, 'Bulk Area Assignment success'),
    props<ContactDetailXSStoreBulkAreaAssignmentPayload>()
  ),
});
