import { Injectable, Injector } from '@angular/core';
import { CoreSharedApiBaseService } from '@nexnox-web/core-shared';

@Injectable()
export class CorePortalFeatureArticleKindService extends CoreSharedApiBaseService {
  constructor(
    protected injector: Injector
  ) {
    super(injector, null,'articlekind');
  }
}
