import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {SearchListsDetailComponent, SearchListsListComponent} from './containers';
import {AppPermissions, I18nBreadcrumbResolver, UnsavedChangesGuard, UnsavedCreateGuard} from '@nexnox-web/core-shared';
import {CorePortalPermissionGuard} from '@nexnox-web/core-portal';

export const CORE_PORTAL_SETTINGS_MAIL_LOGS_ROUTES: Routes = [
  {
    path: '',
    canActivate: [CorePortalPermissionGuard],
    canDeactivate: [UnsavedCreateGuard],
    component: SearchListsListComponent,
    data: {
      permissions: [AppPermissions.ReadSearchList]
    }
  },
  {
    path: ':searchListId',
    component: SearchListsDetailComponent,
    canActivate: [CorePortalPermissionGuard],
    canDeactivate: [UnsavedChangesGuard],
    data: {
      permissions: [AppPermissions.ReadSearchList],
      breadcrumbs: I18nBreadcrumbResolver,
      text: 'core-portal.settings.subtitles.search-list-detail'
    }
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(CORE_PORTAL_SETTINGS_MAIL_LOGS_ROUTES)
  ],
  exports: [
    RouterModule
  ]
})
export class SearchListsRoutingModule {
}
