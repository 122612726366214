import {ChangeDetectionStrategy, Component, Injector, Input, OnInit} from '@angular/core';
import {
  CorePortalCardboxAction,
  CorePortalEntityOverviewBaseComponent,
  DatatableActionButton,
  DeleteEntityModel
} from '@nexnox-web/core-portal';
import {
  ApiNotificationService,
  AppEntityType,
  AppPermissions,
  FilterOperators,
  FilterTypes,
  Mappers,
  ProductSubscriptionDto
} from '@nexnox-web/core-shared';
import {tenantSubscriptionListStore} from '../../store';
import {BehaviorSubject, catchError, firstValueFrom, Observable, of} from "rxjs";
import {faPencilAlt} from "@fortawesome/free-solid-svg-icons/faPencilAlt";
import {delay, map, switchMap} from "rxjs/operators";
import {faPlus} from "@fortawesome/free-solid-svg-icons/faPlus";
import {faSave} from "@fortawesome/free-solid-svg-icons/faSave";
import {faTimesCircle} from "@fortawesome/free-solid-svg-icons/faTimesCircle";

@Component({
  selector: 'nexnox-web-service-tenants-tenant-subscription-list',
  templateUrl: './tenant-subscription-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TenantSubscriptionListComponent extends CorePortalEntityOverviewBaseComponent<ProductSubscriptionDto> implements OnInit {
  @Input() public tenantId: number | string;

  public title = 'core-portal.service.tenants.subscriptions';
  public createTitle = 'core-portal.service.tenants.actions.create-subscription';
  public idProperty = 'productSubscriptionId';
  public displayProperty = null;
  public datatableConfigName = 'ServiceTenantSubscriptionDefault';
  public componentId = 'ServiceTenantSubscriptionListComponent';
  public canCreateProductSubscription$: Observable<boolean>;
  public canUpdateProductSubscription$: Observable<boolean>;
  public isEditingSubscription: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public isCreating: Observable<boolean>;

  public createSubscriptionHeaderActions: CorePortalCardboxAction[] = [];

  constructor(
    protected injector: Injector,
    protected apiNotificationService: ApiNotificationService
  ) {
    super(injector, tenantSubscriptionListStore, Mappers.ProductSubscriptionListDto.serializedName, AppEntityType.None);

    this.getSubscriptionHeaderActions(this.isEditingSubscription.getValue());
    this.isCreating = this.isEditingSubscription.asObservable()
      .pipe(map(editing => {
        this.getSubscriptionHeaderActions(editing);
        return !editing && !this.readonly$.getValue();
      }));
  }

  public ngOnInit(): void {
    this.defaultFilter = [{
      property: 'tenantId',
      type: FilterTypes.DataTransferObject,
      operator: FilterOperators.Equal,
      value: this.tenantId.toString()
    }];

    this.canCreateProductSubscription$ = this.permissionService.hasPermission$(AppPermissions.CreateProductSubscription);
    this.canUpdateProductSubscription$ = this.permissionService.hasPermission$(AppPermissions.UpdateProductSubscription);

    super.ngOnInit();
  }

  public getRowActionButtons(): DatatableActionButton[] {
    return [{
      tooltip: 'core-portal.service.tenants.actions.edit-subscription',
      icon: faPencilAlt,
      onClick: (row) => this.onEditSubscription(row),
      show: () => firstValueFrom(this.canUpdateProductSubscription$),
      permissions: [AppPermissions.UpdateProductSubscription]
    }];
  }

  private onEditSubscription(row: ProductSubscriptionDto): void {
    this.onEditAction();
    this.setEditMode();
    this.isEditingSubscription.next(true);
    this.editComponent?.reset();
    this.createModel$.next(row);
  }

  private exitEditSubscriptionMode(): void {
    this.isEditingSubscription.next(false);
    this.createModel$.next({});
    this.editComponent.reset();
  }

  private async onSave(): Promise<void> {
    const model = this.createModel$.getValue();

    let error: boolean;
    firstValueFrom(this.entityService.saveOne(model.productSubscriptionId, model, this.parentIds)
      .pipe(
        catchError(error => {
          error = true;
          this.apiNotificationService.handleApiError(error);
          return of(model);
        })))
      .then(() => {
        if (!error) {
          this.refreshList().then(() => this.apiNotificationService.showTranslatedSuccess('core-shared.shared.toast.entity-saved'))
          this.exitEditSubscriptionMode();
        }
      });
  }

  public getDeleteEntityModel(): DeleteEntityModel {
    return {
      titleKey: 'core-portal.service.tenants.actions.delete-subscription',
      descriptionKey: 'core-portal.service.tenants.descriptions.delete-subscription',
      deletePermission: AppPermissions.DeleteProductSubscription
    };
  }

  private getSubscriptionHeaderActions(isEditing: boolean = false): void {
    const label = isEditing ? 'core-portal.service.tenants.actions.edit-subscription' :
      'core-portal.service.tenants.actions.create-subscription';
    this.createTitle = label;
    this.createSubscriptionHeaderActions = [
      {
        label: 'core-portal.core.general.cancel',
        class: 'btn-outline-secondary',
        icon: faTimesCircle,
        shouldShow: () => this.readonly$.asObservable().pipe(
          map(readonly => !readonly && isEditing)
        ),
        isLoading: () => this.loading$,
        callback: () => this.exitEditSubscriptionMode()
      },
      {
        label,
        icon: !isEditing ? faPlus : faSave,
        class: 'btn-outline-primary',
        permission: AppPermissions.CreateProductSubscription,
        shouldShow: () => this.readonly$.pipe(
          map(readonly => this.canCreate && !readonly)
        ),
        isDisabled: () => this.readonly$.pipe(
          delay(0),
          switchMap(() => this.createModel$.asObservable().pipe(
            map(() => this.editComponent ? !this.editComponent.isModelValid() : true)
          ))
        ),
        isLoading: () => this.loading$,
        callback: () => !isEditing ? this.onCreate() : this.onSave()
      }
    ];
  }
}
