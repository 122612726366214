import { PagedEntitiesXsStore, PagedEntitiesXsStoreState } from '@nexnox-web/core-store';
import {
  AppEntityType,
  AppRoleListDto,
  AreaAssignmentDto,
  LocationGroupRelationDto,
  LocationRelationDto,
  RoleSimpleListDto
} from '@nexnox-web/core-shared';
import { createSelector } from '@ngrx/store';
import { selectContactsState } from '../../contacts.selectors';
import { ContactAreaService } from '../../services';

export interface ContactAreaListStoreState extends PagedEntitiesXsStoreState<AreaAssignmentDto> {
}

/* istanbul ignore next */
export const contactAreaListStore = new PagedEntitiesXsStore<AreaAssignmentDto>({
  actionLabel: 'Core Portal - Master Data - Contact - Area List',
  stateSelector: createSelector(selectContactsState, state => state.contactAreaList),
  serviceType: ContactAreaService,
  entityType: AppEntityType.None,
  selectId: area => area.entity?.areaAssignmentId ?? area.model?.areaAssignmentId,
  stereotyped: false,
  prepareEntity: (entity: AreaAssignmentDto, base: (entity: AreaAssignmentDto) => AreaAssignmentDto) => {
    const newEntity = base(entity);

    newEntity.locations = (newEntity.locations ?? []).map((x: LocationRelationDto) => ({ ...x, location: x?.location ?? x }));
    newEntity.locationGroups = (newEntity.locationGroups ?? [])
      .map((x: LocationGroupRelationDto) => ({ ...x, locationGroup: x?.locationGroup ?? x }));
    newEntity.roles = (newEntity.roles ?? []).map((x: any) => ({ ...x, role: x?.role ?? x })) as any[];
    newEntity.functions = (newEntity.functions ?? []).map((x: any) => ({ ...x, function: x?.function ?? x }));

    return newEntity;
  },
  sanitizeModel: (
    model: AreaAssignmentDto,
    entity: AreaAssignmentDto,
    base: (model: AreaAssignmentDto, entity: AreaAssignmentDto) => AreaAssignmentDto
  ) => {
    const newModel = base(model, entity);

    newModel.roles = (newModel.roles ?? []).map((x: RoleSimpleListDto & AppRoleListDto) => ({
      ...x,
      id: x.appRoleId,
      name: x.title
    }));

    return newModel;
  }
});
