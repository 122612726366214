import {Injectable, Injector} from '@angular/core';
import {invitationsDetailStore} from './invitations-detail.store';
import {createEffect, ofType} from '@ngrx/effects';
import {InvitationsDetailXsStoreActions} from './invitations-detail-xs-store.actions';
import {catchError, map, mergeMap, tap} from 'rxjs/operators';
import {CorePortalInvitationsService} from '../../services';
import {Action} from '@ngrx/store';
import {of} from 'rxjs';

@Injectable()
export class InvitationsDetailStoreEffects extends invitationsDetailStore.effects {
  public actions: InvitationsDetailXsStoreActions;
  public service: CorePortalInvitationsService;

  public revokeInvitation$: any;
  public revokeInvitationSuccess$: any;

  public reactivateInvitation$: any;
  public reactivateInvitationSuccess$: any;

  constructor(
    protected injector: Injector
  ) {
    super(injector);
  }

  protected createEffects(): void {
    super.createEffects();

    this.revokeInvitation$ = createEffect(() => this.actions$.pipe(
      ofType(this.actions.revokeInvitation),
      mergeMap(({invitation}) => this.service.revokeInvitation(invitation).pipe(
        map(() => this.actions.revokeInvitationSuccess()),
        catchError(error => of(this.actions.error({ error, action: this.actions.revokeInvitation })))
      ))
    ));

    this.revokeInvitationSuccess$ = createEffect(() => this.actions$.pipe(
      ofType(this.actions.revokeInvitationSuccess),
      tap(action => this.actionCallback(action, false))
    ), { dispatch: false });

    this.reactivateInvitation$ = createEffect(() => this.actions$.pipe(
      ofType(this.actions.reactivateInvitation),
      mergeMap(({invitation}) => this.service.reactivateInvitation(invitation).pipe(
        map(() => this.actions.reactivateInvitationSuccess()),
        catchError(error => of(this.actions.error({ error, action: this.actions.reactivateInvitation })))
      ))
    ));

    this.reactivateInvitationSuccess$ = createEffect(() => this.actions$.pipe(
      ofType(this.actions.reactivateInvitationSuccess),
      tap(action => this.actionCallback(action, false))
    ), { dispatch: false });
  }

  protected actionCallback(action: Action, isError: boolean): void {
    super.actionCallback(action, isError);

    this.checkAction(this.actions.revokeInvitationSuccess, action, () => this.revokeInvitationSuccessActionCallback());
    this.checkAction(this.actions.reactivateInvitationSuccess, action, () => this.reactivateInvitationSuccessActionCallback());
  }

  protected revokeInvitationSuccessActionCallback(): void {
    this.apiNotificationService.showTranslatedSuccess('core-portal.settings.invitations.toasts.revoke-invitation');
  }

  protected reactivateInvitationSuccessActionCallback(): void {
    this.apiNotificationService.showTranslatedSuccess('core-portal.settings.invitations.toasts.reactivate-invitation');
  }
}
