import {ChangeDetectionStrategy, Component, OnDestroy, OnInit} from '@angular/core';
import {FieldType, FormlyTemplateOptions} from '@ngx-formly/core';
import {BehaviorSubject, Subscription} from "rxjs";
import {isEmpty, isEqual} from "lodash";
import {pairwise, startWith} from "rxjs/operators";

interface FormlyExternalIdTemplateOptions extends FormlyTemplateOptions {
  titleKey: string;
  modelSubject: BehaviorSubject<any>;
}

@Component({
  selector: 'nexnox-web-formly-external-id',
  templateUrl: './formly-external-id.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormlyExternalIdComponent extends FieldType implements OnInit, OnDestroy {

  public readonly to: FormlyExternalIdTemplateOptions;
  private _titleSubscription: Subscription;
  private _modelSubscription: Subscription;

  public ngOnInit(): void {
    // Check template options
    if (!!this.to?.titleKey && (!!this.form.get(this.to.titleKey) || !!this.to?.modelSubject)) {

      // Switch behavior (create or !create)
      if (this.form.get(this.to.titleKey)) {
        this._subscribeFormChangeEvents();
      } else {
        this._subscribeModelChangeEvents();
      }

    } else {
      throw new Error('Title property or modelSubject unknown! (core-portal-external-id)');
    }
  }

  public ngOnDestroy(): void {
    if (this._titleSubscription) {
      this._titleSubscription.unsubscribe();
    }
    if (this._modelSubscription) {
      this._modelSubscription.unsubscribe();
    }
  }

  // Using model on #edit
  private _subscribeModelChangeEvents(): void {
    this._modelSubscription = this.to.modelSubject.pipe(pairwise()).subscribe(([oldModel, newModel]) => {
      if (oldModel && newModel) {
        const oldValue = oldModel[this.to.titleKey];
        const newValue = newModel[this.to.titleKey];
        if (!isEqual(oldValue, newValue) && !isEmpty(oldValue) && !isEmpty(newValue) && !this.to?.disabled) {
          newModel[this.key as string] = this._isAutomaticId(this.formControl.value, oldValue) ? this._generateId(newValue) : newModel[this.key as string];
        }
      }
    });
  }

  // Using form on #create
  private _subscribeFormChangeEvents(): void {
    const titleField = this.form.get(this.to.titleKey);
    this._titleSubscription = titleField.valueChanges.pipe(startWith(this.formControl.value), pairwise()).subscribe(([oldValue, newValue]) => {
      const value = this._isAutomaticId(this.formControl.value, oldValue) && !this.to?.disabled ? this._generateId(newValue ?? '') : this.formControl.value;
      this.formControl.setValue(value)
    });
  }

  private _generateId(input: string = ''): string {
    // Regex
    const alphaNumeric = /[^0-9a-z_]/gi;
    const space = /[ -]/g;
    const ae = /ä/g;
    const oe = /ö/g;
    const ue = /ü/g;
    const ss = /ß/g;

    // Id manipulation
    let id: string = input.trim().toLowerCase() ?? '';
    id = id.replace(space, '_');
    id = id.replace(ae, 'ae').replace(oe, 'oe').replace(ue, 'ue').replace(ss, 'ss');
    id = id.replace(alphaNumeric, '');
    id = !isNaN(+id[0]) ? '_' + id : id;
    id = id.replace(/_{2,}/g, '_');
    return id;
  }

  private _isAutomaticId(externalId: string, input: string): boolean {
    return isEqual(externalId ?? '', this._generateId(input ?? ''));
  }
}
