import { ChangeDetectionStrategy, Component, Injector } from '@angular/core';
import { ActionButton, CorePortalEntityOverviewBaseComponent, DatatableActionButton, DeleteEntityModel } from '@nexnox-web/core-portal';
import { AppEntityType, AppPermissions, CompanyDto, ControllerOperationId, Mappers } from '@nexnox-web/core-shared';
import { companyListStore } from '../../store';

@Component({
  selector: 'nexnox-web-master-data-companies-company-list',
  templateUrl: './company-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CorePortalFeatureMasterDataCompanyListComponent extends CorePortalEntityOverviewBaseComponent<CompanyDto> {
  public title = 'core-portal.master-data.subtitles.company-list';
  public createTitle = 'core-portal.master-data.subtitles.company-create';
  public idProperty = 'companyId';
  public displayProperty = 'name';
  public datatableConfigName = 'MasterDataCompanyDefault';
  public componentId = 'CompanyListComponent';
  public enableViews = true;
  public pageOperation = ControllerOperationId.CompanyControllerList;

  constructor(
    protected injector: Injector
  ) {
    super(injector, companyListStore, Mappers.CompanyListDto.serializedName, AppEntityType.Company);
  }

  public getRowActionButtons(): DatatableActionButton[] {
    return this.getDefaultRowActionButtons(
      'core-portal.master-data.actions.edit-company',
      (row: CompanyDto) => `/masterdata/companies/${row.companyId}`,
      [AppPermissions.UpdateCompany],
      {
        module: 'management'
      }
    );
  }

  public getDeleteEntityModel(): DeleteEntityModel {
    return {
      titleKey: 'core-portal.master-data.actions.delete-company',
      descriptionKey: 'core-portal.master-data.descriptions.delete-company',
      confirmKey: 'core-portal.master-data.actions.delete-company',
      deletePermission: AppPermissions.DeleteCompany
    };
  }

  protected async getActionButtons(): Promise<ActionButton[]> {
    return this.getDefaultActionButtons('core-portal.master-data.actions.create-company', AppPermissions.CreateCompany);
  }
}
