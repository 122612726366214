import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import {
  ConcreteTaskTypes,
  ConditionTypes,
  Filter,
  FilterOperators,
  FilterTypes,
  FunctionDto,
  ImpactDto,
  ImpactTypes,
  TaskDto,
  TaskJobState,
  UnsubscribeHelper
} from '@nexnox-web/core-shared';
import {
  CorePortalFormlyNgSelectTyping,
  CorePortalFormlyReadonlyTypes,
  CorePortalFormlyReadonlyTyping,
  CorePortalFormlyTranslatedTyping,
  CorePortalFunctionService,
  CoreSharedRuleEditorListComponent,
  InheritedField,
  ModelValid
} from '@nexnox-web/core-portal';
import { BehaviorSubject, Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { distinctUntilChanged, filter, map, mergeMap, skip, startWith } from 'rxjs/operators';
import { OrgaPortalFeatureTaskService } from '../../store';
import { isEqual } from 'lodash';
import { FormlyFieldConfig } from '@ngx-formly/core';

const impactTypeEnumOptions = [
  { label: 'orga-portal.tasks.impact-types.1', value: ImpactTypes.Sanction },
  { label: 'orga-portal.tasks.impact-types.2', value: ImpactTypes.Alert },
  { label: 'orga-portal.tasks.impact-types.3', value: ImpactTypes.Status }
];

const impactConditionTypeEnumOptions = [
  { label: 'orga-portal.tasks.impact-condition-types.1', value: ConditionTypes.Percentage },
  { label: 'orga-portal.tasks.impact-condition-types.2', value: ConditionTypes.Time },
  { label: 'orga-portal.tasks.impact-condition-types.3', value: ConditionTypes.ChangedStatus }
];

const changeStateEnumOptions = [
  { label: 'orga-portal.shared.task-job-state-types.4', value: TaskJobState.Done },
  { label: 'orga-portal.shared.task-job-state-types.5', value: TaskJobState.Canceled },
  { label: 'orga-portal.shared.task-job-state-types.6', value: TaskJobState.Overdue },
  { label: 'orga-portal.shared.task-job-state-types.7', value: TaskJobState.Escalated }
];

@Component({
  selector: 'nexnox-web-tasks-task-impacts',
  templateUrl: './task-impacts.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TaskImpactsComponent extends UnsubscribeHelper implements OnInit, ModelValid {
  @Input() public task$: Observable<TaskDto>;
  @Input() public impacts$: Observable<ImpactDto[]>;

  @Input() public readonly: boolean;
  @Input() public loading: boolean;

  @ViewChild('impactsEdit') public impactsEdit: CoreSharedRuleEditorListComponent;

  @Output() public impactsChange: EventEmitter<ImpactDto[]> = new EventEmitter<ImpactDto[]>();

  public taskType$: Observable<ConcreteTaskTypes>;

  public impactTitleFn: any;
  public impactFieldsFn: any;

  constructor(
    private translate: TranslateService,
    private taskService: OrgaPortalFeatureTaskService,
    private functionService: CorePortalFunctionService
  ) {
    super();

    this.impactTitleFn = (impact: ImpactDto) => {
      const typeName = this.translate.instant(`orga-portal.tasks.impact-types.${impact.type}`);
      const conditionType = this.translate.instant(`orga-portal.tasks.impact-condition-types.${impact.condition?.type}`);
      return `${typeName} ${conditionType}`;
    };
    this.impactFieldsFn = () => this.createImpactFields();
  }

  public ngOnInit(): void {
    this.taskType$ = this.task$.pipe(
      map(task => task.type)
    );
  }

  public onImpactsChange(impacts: ImpactDto[]): void {
    this.impactsChange.emit(impacts);
  }

  public onReset(): void {
    this.impactsEdit?.ngOnInit();
  }

  public isModelValid(): boolean {
    return this.impactsEdit ? this.impactsEdit.areItemsValid() : true;
  }

  public isOwnModelValid(): boolean {
    return this.impactsEdit ? this.impactsEdit.isModelValid() : true;
  }

  public isOwnModelPristine(): boolean {
    return this.impactsEdit ? this.impactsEdit.isPristine() : true;
  }

  /* istanbul ignore next */
  public createImpactFields(): InheritedField[] {
    const modelSubject: BehaviorSubject<ImpactDto> = new BehaviorSubject<ImpactDto>(null);

    const impactConditionTypeEnumOptions$ = this.taskType$.pipe(
      mergeMap(taskType => modelSubject.asObservable().pipe(
        map(model => {
          const additionalTypes = model?.type !== ImpactTypes.Status ? [impactConditionTypeEnumOptions[2]] : [];

          switch (taskType) {
            case ConcreteTaskTypes.Document:
            case ConcreteTaskTypes.ChildDocument:
              return [
                ...(model?.type !== ImpactTypes.Status ? [impactConditionTypeEnumOptions[0]] : []),
                impactConditionTypeEnumOptions[1],
                ...additionalTypes
              ];
            default:
              return [
                impactConditionTypeEnumOptions[1],
                ...additionalTypes
              ];
          }
        })
      ))
    );

    const resetConditionFields = (field: FormlyFieldConfig): void => {
      field.form.get('condition.conditionOffset')?.setValue('0.00:00:00');
      field.form.get('condition.percent')?.setValue(0);
      field.form.get('condition.targetState')?.setValue(TaskJobState.Overdue);
    };

    return [
      {
        formlyConfig: {
          type: 'core-portal-ng-select',
          wrappers: ['core-portal-translated'],
          className: 'col-md-6',
          defaultValue: ImpactTypes.Sanction,
          templateOptions: {
            corePortalTranslated: {
              label: 'core-shared.shared.fields.kind',
              validationMessages: {
                required: 'core-portal.core.validation.required'
              }
            },
            corePortalNgSelect: {
              items: impactTypeEnumOptions,
              translate: true
            } as CorePortalFormlyNgSelectTyping
          },
          expressionProperties: {
            'templateOptions.required': () => !this.readonly
          },
          hooks: {
            onInit: field => {
              this.subscribe(field.form.valueChanges.pipe(
                startWith(field.form.value),
                distinctUntilChanged((a, b) => isEqual(a, b))
              ), value => modelSubject.next(value));

              this.subscribe(field.formControl.valueChanges.pipe(
                startWith(field.formControl.value),
                distinctUntilChanged(),
                skip(1)
              ), () => {
                field.form.get('condition.type')?.setValue(null);
                resetConditionFields(field);

                field.form.controls.sanction?.setValue(null);
                field.form.controls.sanctionOffset?.setValue('0.00:00:00');
                field.form.controls.function?.setValue(null);
                field.form.controls.targetState?.setValue(TaskJobState.Overdue);
              });
            }
          }
        },
        ownValueKey: 'type',
        hide: (_, creating) => !creating
      },
      {
        formlyConfig: {
          type: 'core-portal-ng-select',
          wrappers: ['core-portal-translated'],
          className: 'col-md-6',
          defaultValue: null,
          templateOptions: {
            corePortalTranslated: {
              label: 'orga-portal.tasks.fields.kind',
              labelClass: 'd-none d-md-block invisible',
              validationMessages: {
                required: 'core-portal.core.validation.required'
              }
            },
            corePortalNgSelect: {
              items$: impactConditionTypeEnumOptions$,
              translate: true
            } as CorePortalFormlyNgSelectTyping
          },
          expressionProperties: {
            'templateOptions.required': () => !this.readonly
          },
          hooks: {
            onInit: field => this.subscribe(field.formControl.valueChanges.pipe(
              startWith(field.formControl.value),
              distinctUntilChanged(),
              skip(1)
            ), () => resetConditionFields(field))
          }
        },
        ownValueKey: 'condition.type',
        hide: (_, creating) => !creating
      },

      /* Conditions */

      // Time
      {
        formlyConfig: {
          type: 'core-portal-timepicker',
          wrappers: ['core-portal-translated', 'core-portal-readonly'],
          className: 'col-md-3',
          defaultValue: '0.00:00:00',
          templateOptions: {
            corePortalTranslated: {
              label: 'orga-portal.tasks.fields.trigger-after',
              validationMessages: {
                required: 'core-portal.core.validation.required'
              }
            },
            corePortalReadonly: {
              type: CorePortalFormlyReadonlyTypes.BASIC,
              template: value => value
            } as CorePortalFormlyReadonlyTyping,
            corePortalTimepicker: {
              mode: 'timespan',
              showYears: false,
              showWeeks: false,
              showDays: true,
              showHours: true,
              showMinutes: true
            }
          },
          expressionProperties: {
            'templateOptions.required': () => !this.readonly,
            'templateOptions.readonly': () => this.readonly
          }
        },
        ownValueKey: 'condition.conditionOffset',
        hide: (_, __, model?: ImpactDto) => !model || model.condition?.type !== ConditionTypes.Time
      },

      // Rating
      {
        formlyConfig: {
          type: 'input',
          wrappers: ['core-portal-translated', 'core-portal-readonly'],
          className: 'col-md-3',
          defaultValue: 0,
          templateOptions: {
            corePortalTranslated: {
              label: 'orga-portal.tasks.fields.effective-under-rating',
              validationMessages: {
                required: 'core-portal.core.validation.required',
                min: { key: 'core-portal.core.validation.min', args: { min: 0 } },
                max: { key: 'core-portal.core.validation.max', args: { max: 100 } }
              }
            } as CorePortalFormlyTranslatedTyping,
            corePortalReadonly: {
              type: CorePortalFormlyReadonlyTypes.BASIC
            } as CorePortalFormlyReadonlyTyping,
            min: 0,
            max: 100,
            type: 'number'
          },
          expressionProperties: {
            'templateOptions.required': () => !this.readonly,
            'templateOptions.readonly': () => this.readonly
          }
        },
        ownValueKey: 'condition.percent',
        hide: (_, __, model?: ImpactDto) => !model || model.condition?.type !== ConditionTypes.Percentage
      },

      // State change
      {
        formlyConfig: {
          type: 'core-portal-ng-select',
          wrappers: ['core-portal-translated', 'core-portal-readonly'],
          className: 'col-md-3',
          defaultValue: TaskJobState.Overdue,
          templateOptions: {
            corePortalTranslated: {
              label: 'core-shared.shared.fields.current-state',
              validationMessages: {
                required: 'core-portal.core.validation.required'
              }
            },
            corePortalReadonly: {
              type: CorePortalFormlyReadonlyTypes.ENUM,
              enumOptions: changeStateEnumOptions,
              translate: true
            } as CorePortalFormlyReadonlyTyping,
            corePortalNgSelect: {
              items: changeStateEnumOptions,
              translate: true
            } as CorePortalFormlyNgSelectTyping
          },
          expressionProperties: {
            'templateOptions.required': () => !this.readonly,
            'templateOptions.readonly': () => this.readonly
          }
        },
        ownValueKey: 'condition.targetState',
        hide: (_, __, model?: ImpactDto) => !model || model.condition?.type !== ConditionTypes.ChangedStatus
      },

      /* Impacts */

      // Sanction
      {
        formlyConfig: {
          type: 'core-portal-entity-select',
          wrappers: ['core-portal-translated', 'core-portal-readonly'],
          className: 'col-md-9',
          defaultValue: null,
          templateOptions: {
            corePortalTranslated: {
              label: 'orga-portal.tasks.fields.sanction',
              validationMessages: {
                required: 'core-portal.core.validation.required'
              }
            } as CorePortalFormlyTranslatedTyping,
            corePortalReadonly: {
              type: CorePortalFormlyReadonlyTypes.ENTITY,
              displayKey: 'title',
              link: (task: TaskDto) => task?.taskId ? ['/tasks', task.taskId] : null,
              module: 'communication'
            } as CorePortalFormlyReadonlyTyping,
            entityService: this.taskService,
            idKey: 'taskId',
            displayKey: 'title',
            wholeObject: true,
            skipGetOne: true,
            clearable: false,
            defaultFilters$: this.task$.pipe(
              filter(task => Boolean(task?.taskId)),
              distinctUntilChanged((a, b) => isEqual(a.taskId, b.taskId)),
              map(task => ([{
                property: 'taskId',
                type: FilterTypes.DataTransferObject,
                operator: FilterOperators.NotEqual,
                value: task.taskId.toString()
              }] as Filter[]))
            ),
            link: (task: TaskDto) => task?.taskId ? ['/tasks', task.taskId] : null,
            module: 'communication'
          },
          expressionProperties: {
            'templateOptions.required': () => !this.readonly,
            'templateOptions.readonly': () => this.readonly
          }
        },
        ownValueKey: 'sanction',
        hide: (_, __, model?: ImpactDto) => !model || model.type !== ImpactTypes.Sanction
      },
      {
        formlyConfig: {
          type: 'core-portal-timepicker',
          wrappers: ['core-portal-translated', 'core-portal-readonly'],
          className: 'col-md-12',
          defaultValue: '0.00:00:00',
          templateOptions: {
            corePortalTranslated: {
              label: 'orga-portal.tasks.fields.effective-after',
              validationMessages: {
                required: 'core-portal.core.validation.required'
              }
            },
            corePortalReadonly: {
              type: CorePortalFormlyReadonlyTypes.BASIC,
              template: value => value
            } as CorePortalFormlyReadonlyTyping,
            corePortalTimepicker: {
              mode: 'timespan',
              showYears: false,
              showWeeks: false,
              showDays: true,
              showHours: true,
              showMinutes: true
            }
          },
          expressionProperties: {
            'templateOptions.required': () => !this.readonly,
            'templateOptions.readonly': () => this.readonly
          }
        },
        ownValueKey: 'sanctionOffset',
        hide: (_, __, model?: ImpactDto) => !model || model.type !== ImpactTypes.Sanction
      },

      // Alert
      {
        formlyConfig: {
          type: 'core-portal-entity-select',
          wrappers: ['core-portal-translated', 'core-portal-readonly'],
          className: 'col-md-9',
          defaultValue: null,
          templateOptions: {
            corePortalTranslated: {
              label: 'core-shared.shared.fields.function',
              validationMessages: {
                required: 'core-portal.core.validation.required'
              }
            } as CorePortalFormlyTranslatedTyping,
            corePortalReadonly: {
              type: CorePortalFormlyReadonlyTypes.ENTITY,
              displayKey: 'name',
              link: (functionProperty: FunctionDto) => functionProperty?.functionId ?
                ['/masterdata/functions', functionProperty.functionId] : null,
              module: 'management'
            } as CorePortalFormlyReadonlyTyping,
            entityService: this.functionService,
            idKey: 'functionId',
            displayKey: 'name',
            wholeObject: true,
            skipGetOne: true,
            clearable: false,
            link: (functionProperty: FunctionDto) => functionProperty?.functionId ?
              ['/masterdata/functions', functionProperty.functionId] : null,
            module: 'management'
          },
          expressionProperties: {
            'templateOptions.required': () => !this.readonly,
            'templateOptions.readonly': () => this.readonly
          }
        },
        ownValueKey: 'function',
        hide: (_, __, model?: ImpactDto) => !model || model.type !== ImpactTypes.Alert
      },

      // State change
      {
        formlyConfig: {
          type: 'core-portal-ng-select',
          wrappers: ['core-portal-translated', 'core-portal-readonly'],
          className: 'col-md-9',
          defaultValue: TaskJobState.Overdue,
          templateOptions: {
            corePortalTranslated: {
              label: 'core-shared.shared.fields.current-state',
              validationMessages: {
                required: 'core-portal.core.validation.required'
              }
            },
            corePortalReadonly: {
              type: CorePortalFormlyReadonlyTypes.ENUM,
              enumOptions: changeStateEnumOptions.slice(1, changeStateEnumOptions.length),
              translate: true
            } as CorePortalFormlyReadonlyTyping,
            corePortalNgSelect: {
              items: changeStateEnumOptions.slice(1, changeStateEnumOptions.length),
              translate: true
            } as CorePortalFormlyNgSelectTyping
          },
          expressionProperties: {
            'templateOptions.required': () => !this.readonly,
            'templateOptions.readonly': () => this.readonly
          }
        },
        ownValueKey: 'targetState',
        hide: (_, __, model?: ImpactDto) => !model || model.type !== ImpactTypes.Status
      }
    ];
  }
}
