import {PagedEntitiesXsStore, PagedEntitiesXsStoreState} from '@nexnox-web/core-store';
import {AppEntityType, MissionDto} from '@nexnox-web/core-shared';
import {Action, createSelector} from '@ngrx/store';
import {Injectable, Injector} from '@angular/core';
import {TechPortalMissionService} from "@nexnox-web/tech-portal-lib";
import {selectNotificationRulesState} from "../../notification-rules.selector";

export interface NotificationRulesMissionsStoreState extends PagedEntitiesXsStoreState<MissionDto> {
}

/* istanbul ignore next */
export const notificationRulesMissionsStore = new PagedEntitiesXsStore<MissionDto>({
  actionLabel: 'Tech Portal - Notification Rules - Notification Rule Missions',
  stateSelector: createSelector(selectNotificationRulesState, state => state.notificationRulesMissions),
  entityType: AppEntityType.Mission,
  serviceType: TechPortalMissionService,
  selectId: mission => mission.entity?.missionId ?? mission.model?.missionId
});

export function notificationRulesMissionsStoreReducer(state: NotificationRulesMissionsStoreState, action: Action): any {
  return notificationRulesMissionsStore.reducer(state, action);
}

@Injectable()
export class NotificationRulesMissionsStoreEffects extends notificationRulesMissionsStore.effects {
  constructor(
    protected injector: Injector
  ) {
    super(injector);
  }
}
