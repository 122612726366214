<nexnox-web-cardbox [title]="'resources.subtitles.escalation-rules' | translate"
                    [loading]="loading"
                    paddingClasses="px-3 pb-3">
  <nexnox-web-rule-editor-list #rulesEdit
                               [items]="rules$ | async"
                               [title]="rulesTitle"
                               inheritedKey="isInherited"
                               [inheritedFieldsFn]="rulesFieldsFn"
                               [disabled]="readonly"
                               (itemsChange)="onRulesChange($event)">
  </nexnox-web-rule-editor-list>
</nexnox-web-cardbox>
