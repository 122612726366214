import { DashboardItemDatatableStore } from '@nexnox-web/core-portal/features/dashboard';
import { AppEntityType, ContactSimpleDto, StateDto, TicketDto } from '@nexnox-web/core-shared';
import { TechPortalTicketService } from '@nexnox-web/tech-portal-lib';
import { Injector } from '@angular/core';
import { authStore, CorePortalDashboardItemDatatableConfig } from '@nexnox-web/core-portal';
import produce from 'immer';
import { pagedEntitiesXsStoreSetLoadingForId } from '@nexnox-web/core-store';
import { select, Store } from '@ngrx/store';
import { firstValueFrom } from 'rxjs';

export class TechPortalFeatureDashboardTicketDatatableStore extends DashboardItemDatatableStore<TicketDto> {
  protected entityService: TechPortalTicketService;
  protected store: Store;

  constructor(
    protected injector: Injector,
    protected dashboardConfig: CorePortalDashboardItemDatatableConfig
  ) {
    super(
      injector,
      'ticketId',
      TechPortalTicketService,
      AppEntityType.Ticket,
      [],
      dashboardConfig
    );

    this.store = this.injector.get(Store);
  }

  /* Assign to */

  public assignTo(id: number, contact: ContactSimpleDto, parentIds?: Array<number | string>): void {
    this.entityService.assignTo(id, contact.contactId, parentIds)
      .toPromise()
      .then(() => this.assignToSuccess(id, contact))
      .catch(error => this.handleError(error));

    this.setState(produce(this.getState(), draft => {
      draft.entityData = pagedEntitiesXsStoreSetLoadingForId(draft.entityData, id, {
        assignTo: true
      });
    }), 'ASSIGN_TO');
  }

  public assignToSuccess(id: number, contact: ContactSimpleDto): void {
    this.apiNotificationService.showTranslatedSuccess('tickets.toasts.assign-to', { contact: contact.displayName });

    const state = this.getState();
    this.setState(this.adapter.updateOne({
      id: id.toString(),
      changes: {
        entity: {
          ...state.entities[id.toString()].entity,
          editor: contact
        },
        model: {
          ...state.entities[id.toString()].model,
          editor: contact
        }
      }
    }, {
      ...state,
      entityData: pagedEntitiesXsStoreSetLoadingForId(state.entityData, id, {
        assignTo: false
      })
    }), 'ASSIGN_TO_SUCCESS');
  }

  /* Assign to me */

  public async assignToMe(id: number, parentIds?: Array<number | string>): Promise<void> {
    const activeTenant = await firstValueFrom(this.store.pipe(select(authStore.selectors.selectActiveTenant)));

    this.entityService.assignToMe(id, parentIds)
      .toPromise()
      .then(() => this.assignToMeSuccess(id, (activeTenant?.names ?? []).length ? activeTenant.names[0] : null))
      .catch(error => this.handleError(error));

    this.setState(produce(this.getState(), draft => {
      draft.entityData = pagedEntitiesXsStoreSetLoadingForId(draft.entityData, id, {
        assignToMe: true
      });
    }), 'ASSIGN_TO_ME');
  }

  public assignToMeSuccess(id: number, contact: ContactSimpleDto): void {
    this.apiNotificationService.showTranslatedSuccess('tickets.toasts.assign-to-me');

    const state = this.getState();
    this.setState(this.adapter.updateOne({
      id: id.toString(),
      changes: {
        entity: {
          ...state.entities[id.toString()].entity,
          editor: contact
        },
        model: {
          ...state.entities[id.toString()].model,
          editor: contact
        }
      }
    }, {
      ...state,
      entityData: pagedEntitiesXsStoreSetLoadingForId(state.entityData, id, {
        assignToMe: false
      })
    }), 'ASSIGN_TO_ME_SUCCESS');
  }

  /* Change Ticket State */

  public changeTicketState(id: number, state: StateDto, reason: string, parentIds?: Array<number | string>): void {
    this.entityService.changeState(id, state.stateId, reason, parentIds)
      .toPromise()
      .then(() => this.changeTicketStateSuccess(id, state))
      .catch(error => this.handleError(error));

    this.setState(produce(this.getState(), draft => {
      draft.entityData = pagedEntitiesXsStoreSetLoadingForId(draft.entityData, id, {
        changeState: true
      });
    }), 'CHANGE_TICKET_STATE');
  }

  public changeTicketStateSuccess(id: number, ticketState: StateDto): void {
    this.apiNotificationService.showTranslatedSuccess('tickets.toasts.state-changed', { name: ticketState.name });

    const state = this.getState();
    this.setState(this.adapter.updateOne({
      id: id.toString(),
      changes: {
        entity: {
          ...state.entities[id.toString()].entity,
          currentState: ticketState
        },
        model: {
          ...state.entities[id.toString()].model,
          currentState: ticketState
        }
      }
    }, {
      ...state,
      entityData: pagedEntitiesXsStoreSetLoadingForId(state.entityData, id, {
        changeState: false
      })
    }), 'CHANGE_TICKET_STATE_SUCCESS');
  }

  /* Export */

  public export(id: number, templateId?: string | number): void {
    this.entityService.export(id, templateId)
      .toPromise()
      .then(({ uri }) => this.exportSuccess(id, uri))
      .catch(error => this.handleError(error));

    this.setState(produce(this.getState(), draft => {
      draft.entityData = pagedEntitiesXsStoreSetLoadingForId(draft.entityData, id, {
        export: true
      });
    }), 'EXPORT');
  }

  public exportSuccess(id: number, uri: string): void {
    window.open(uri, '_blank');

    this.setState(produce(this.getState(), draft => {
      draft.entityData = pagedEntitiesXsStoreSetLoadingForId(draft.entityData, id, {
        export: false
      });
    }), 'EXPORT_SUCCESS');
  }
}
