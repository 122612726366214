import {ChangeDetectionStrategy, Component, EventEmitter, Injector, Input, OnInit, Output} from '@angular/core';
import {
  AppEntityType,
  ControllerOperationId,
  DataTableColumnType,
  DataTableDto,
  FilterDto,
  mapDatatableFilterToFilter,
  Mappers,
  MissionDto,
  SortObject
} from '@nexnox-web/core-shared';
import {
  CORE_PORTAL_DATATABLE_STANDARD_CONFIG,
  CorePortalEntityOverviewBaseComponent,
  DatatableLoadPagePayload,
  ModelValid
} from '@nexnox-web/core-portal';
import {notificationRulesMissionsStore} from '../../store';
import {BehaviorSubject, Observable, startWith} from 'rxjs';
import {filter, skip, take} from 'rxjs/operators';
import {uniqBy} from 'lodash';
import {faInfoCircle} from '@fortawesome/free-solid-svg-icons/faInfoCircle';

const defaultRestrictionDto = {
  pageOperation: ControllerOperationId.MissionControllerList,
  columns: [],
  filters: []
};

@Component({
  selector: 'nexnox-web-settings-notification-rules-restriction-mission-edit',
  templateUrl: './notification-rules-restriction-mission-edit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationRulesRestrictionMissionEditComponent extends CorePortalEntityOverviewBaseComponent<MissionDto> implements OnInit, ModelValid {
  public componentId = 'NotificationRulesRestrictionEditComponent';

  public get restriction(): DataTableDto {
    const restriction = this.restrictionSubject.getValue();
    const config = this.datatableConfigSubject.getValue();

    return {
      ...(restriction ?? {}),
      columns: config?.columns,
      filters: config?.filters,
      pageOperation: ControllerOperationId.MissionControllerList
    };
  }

  @Input()
  public set restriction(restriction: DataTableDto) {

    const defaultConfig = this.injector.get(CORE_PORTAL_DATATABLE_STANDARD_CONFIG).MissionDefault;
    let defaultColumns = this.datatableColumnService.getDataColumns(this.serializedNameOrMapper, defaultConfig.excludedColumns);
    defaultColumns = defaultColumns.filter(x => defaultConfig.defaultColumns.find(y => y === x.name));

    this.restrictionSubject.next(restriction);
    this.datatableConfigSubject.next({
      columns: restriction?.columns ?? defaultColumns.map((x, index) => ({
        position: index + 1,
        title: x.name,
        type: DataTableColumnType.ByTransfer,
        sortOrder: null,
        property: x.name
      })),
      filters: restriction?.filters ?? [],
      pageOperation: restriction?.pageOperation,
      // ...defaultRestrictionDto
    });
  }

  @Input() public creating: boolean;
  @Input() public loading: boolean;

  @Input() public set readonly(readonly: boolean) {
    super.readonly = readonly;
  }

  public get readonly(): boolean {
    return super.readonly;
  }

  @Output() public restrictionChange: EventEmitter<DataTableDto> = new EventEmitter<DataTableDto>();

  public title = 'missions.subtitles.missions';
  public idProperty = 'missionId';
  public displayProperty = 'title';
  public pageOperation = ControllerOperationId.MissionControllerList;
  public enableViews = true;
  public datatableConfigName = 'MissionDefault';

  public ready$: Observable<any>;
  public datatableConfig$: Observable<DataTableDto>;

  public faInfoCircle = faInfoCircle;

  private readySubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private restrictionSubject: BehaviorSubject<DataTableDto> = new BehaviorSubject<DataTableDto>(null);
  private datatableConfigSubject: BehaviorSubject<DataTableDto> = new BehaviorSubject<DataTableDto>(null);

  constructor(
    protected injector: Injector
  ) {
    super(injector, notificationRulesMissionsStore, Mappers.MissionCompleteDto.serializedName, AppEntityType.Mission);
  }

  public ngOnInit(): void {

    this.ready$ = this.readySubject.asObservable().pipe(
      filter(ready => ready),
      take(1)
    );

    this.datatableConfig$ = this.datatableConfigSubject.asObservable().pipe(
      startWith({}),
      filter(config => Boolean(config))
    );

    this.setDefaults();

    this.datatableConfig$.pipe(
      skip(this.creating ? 0 : 1),
      take(1)
    ).subscribe(() => {
      this.restrictionChange.emit(this.restriction);
      super.ngOnInit();
      setTimeout(() => this.readySubject.next(true));
    });
  }

  // Intended. Don't get entities when the datatable requests it, just when the config changes.
  public onLoadPage(_: DatatableLoadPagePayload): void {
    super.onLoadPage(_); // PH: super function call reactivated, see Bug 2785
  }

  public onDatatableConfigChange(datatableConfig: DataTableDto): void {
    this.datatableConfigSubject.next(datatableConfig);
    this.restrictionChange.emit(this.restriction);
    this.getEntities();
  }

  public isModelValid(): boolean {
    return true;
  }

  public isOwnModelValid(): boolean {
    return true;
  }

  protected async getEntities(
    page: number = 1,
    sortOption?: SortObject,
    filters?: FilterDto[],
    pageSize?: number,
    append: boolean = false
  ): Promise<void> {
    return super.getEntities(
      page,
      sortOption,
      uniqBy([
        ...(filters ?? []),
        ...(this.restriction?.filters ?? []).map(x => mapDatatableFilterToFilter(x))
      ], x => x.property),
      pageSize,
      append,
      this.datatableConfigSubject.getValue()
    );
  }
}
