import { HttpClient } from '@angular/common/http';
import { HttpApiHandler } from '../handlers';
import { Injectable } from '@angular/core';

@Injectable()
export class HttpApiClient extends HttpClient {
  constructor(
    private httpApiHandler: HttpApiHandler
  ) {
    super(httpApiHandler);
  }
}
