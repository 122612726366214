<p-sidebar #sidebarComponent
           position="right"
           appendTo="body"
           styleClass="p-sidebar-md"
           [transitionOptions]="transitionOptions"
           [visible]="visible$ | async"
           (visibleChange)="onVisibleChange($event)">
  <h4>{{ 'core-portal.settings.actions.templates.apply-text-template' | translate }}</h4>

  <div class="d-flex flex-grow-1 flex-column h-100 mt-1">
    <form [formGroup]="form" class="w-100">
      <formly-form class="row w-100"
                   [form]="form"
                   [model]="model"
                   [fields]="fields"
                   (modelChange)="onModelChange($event)">
      </formly-form>
    </form>
  </div>

  <div class="d-flex flex-shrink-0 justify-content-end pt-3">
    <button type="button"
            pButton
            [icon]="faCheck | nexnoxWebFaIconStringPipe"
            [label]="'core-portal.settings.actions.templates.apply-text-template' | translate"
            class="p-button-primary ml-2"
            [disabled]="form.invalid || (hasContextType$ | async) === false"
            [loading]="loading$ | async"
            [loadingIcon]="faSpinner | nexnoxWebFaIconStringPipe:'fa-spin'"
            (click)="onApplyTemplate()">
    </button>
  </div>
</p-sidebar>
