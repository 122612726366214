<!-- Inheritance -->
<nexnox-web-cardbox *ngIf="hasParent" [title]="'resources.subtitles.heredity' | translate">
  <form [formGroup]="form">
    <formly-form class="row w-100"
                 [form]="form"
                 [(model)]="model"
                 [fields]="fields">
    </formly-form>
  </form>
</nexnox-web-cardbox>

<!-- Issue templates list -->
<nexnox-web-issue-templates-sortable-list-edit [readonly]="readonly || model.inheritsIssueTemplate"
                                               [loading]="loading"
                                               [issueTemplatesSubject]="issueTemplatesSubject"
                                               (issueTemplatesChange)="onIssueTemplatesChanged($event)">
</nexnox-web-issue-templates-sortable-list-edit>
