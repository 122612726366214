import {
  BaseXsStore,
  BaseXsStoreReducerTypes,
  PagedEntitiesXsStore,
  PagedEntitiesXsStoreActions,
  PagedEntitiesXsStoreEffects,
  PagedEntitiesXsStoreState,
  PropsAction
} from '@nexnox-web/core-store';
import { AppEntityType, CoreSharedApiBaseService, TaskJobDto, TaskJobState } from '@nexnox-web/core-shared';
import { TaskActiveListXsStoreCancelOnePayload } from './location-task-active-list-xs-store.payloads';
import { Action, createAction, on, props } from '@ngrx/store';
import { Injectable, Injector, Type } from '@angular/core';
import { LocationTaskjobService } from '../../services';
import { createEffect, ofType } from '@ngrx/effects';
import { catchError, groupBy, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { immerOn } from 'ngrx-immer/store';
import {
  TaskActiveListXsStoreActions
} from "@nexnox-web/orga-portal/features/tasks/src/lib/store/stores/task-active-list/task-active-list.xs-store";

export interface LocationTaskActiveListXsStoreActions extends PagedEntitiesXsStoreActions<TaskJobDto> {
  cancelOne: PropsAction<TaskActiveListXsStoreCancelOnePayload>;
  cancelOneSuccess: PropsAction<TaskActiveListXsStoreCancelOnePayload>;
}

export interface LocationTaskActiveListXsStoreState extends PagedEntitiesXsStoreState<TaskJobDto> {
}

export class LocationTaskActiveListXsStore extends PagedEntitiesXsStore<TaskJobDto> {
  public actions: LocationTaskActiveListXsStoreActions;

  protected createActions(label: string): LocationTaskActiveListXsStoreActions {
    return {
      ...super.createActions(label),

      cancelOne: createAction(
        BaseXsStore.getType(label, 'Cancel one'),
        props<TaskActiveListXsStoreCancelOnePayload>()
      ),
      cancelOneSuccess: createAction(
        BaseXsStore.getType(label, 'Cancel one success'),
        props<TaskActiveListXsStoreCancelOnePayload>()
      )
    };
  }

  protected createReducerArray(
    initialState: LocationTaskActiveListXsStoreState
  ): BaseXsStoreReducerTypes<LocationTaskActiveListXsStoreState, LocationTaskActiveListXsStoreActions>[] {
    return [
      ...super.createReducerArray(initialState),

      immerOn(this.actions.cancelOne, draft => {
        draft.loading = true;
      }),
      on(this.actions.cancelOneSuccess, (state, { id, parentId }) => this.adapter.removeOne(id, {
        ...state,
        ...(!parentId ? {
          loading: false,
          paging: {
            ...state.paging,
            totalItems: (state.paging?.totalItems ?? 1) - 1
          }
        } : {})
      }))
    ];
  }

  protected createEffects(
    serviceType: Type<CoreSharedApiBaseService>,
    entityType: AppEntityType,
    prepareEntity: (entity: TaskJobDto) => TaskJobDto,
    prepareModel: (entity: TaskJobDto, model: TaskJobDto) => TaskJobDto,
    sanitizeModel: (model: TaskJobDto, entity: TaskJobDto) => TaskJobDto,
    ...args
  ): Type<PagedEntitiesXsStoreEffects<TaskJobDto, TaskJobDto, LocationTaskActiveListXsStoreState>> {
    const actions = this.actions;
    const selectors = this.selectors;

    @Injectable()
    class Effects extends PagedEntitiesXsStoreEffects<TaskJobDto> {
      public cancelOne$: any;
      public cancelOneSuccess$: any;

      protected actions: LocationTaskActiveListXsStoreActions;
      protected service: LocationTaskjobService;

      constructor(
        protected injector: Injector
      ) {
        super(injector, actions, selectors, serviceType, entityType, prepareEntity, prepareModel, sanitizeModel, false);
      }

      protected createEffects(): void {
        super.createEffects();

        this.cancelOne$ = createEffect(() => this.actions$.pipe(
          ofType(actions.cancelOne),
          groupBy(({ id }) => id),
          mergeMap(group => group.pipe(
            switchMap(({ id, parentId }) => this.service.setState(id, TaskJobState.Canceled).pipe(
              map(() => actions.cancelOneSuccess({ id, parentId })),
              catchError(error => of(actions.error({ error, action: actions.cancelOne })))
            ))
          ))
        ));

        this.cancelOneSuccess$ = createEffect(() => this.actions$.pipe(
          ofType(actions.cancelOneSuccess),
          tap(action => this.actionCallback(action, false))
        ), { dispatch: false });
      }

      protected actionCallback(action: Action, isError: boolean = false): void {
        super.actionCallback(action, isError);

        this.checkAction(actions.cancelOneSuccess, action, () => this.cancelOneSuccessActionCallback());
      }

      protected cancelOneSuccessActionCallback(): void {
        this.apiNotificationService.showTranslatedSuccess('orga-portal.tasks.toasts.taskjob-cancelled');
      }
    }

    return Effects;
  }
}
