import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { SolutionMemberDto, SolutionMemberType, TicketDto } from '@nexnox-web/core-shared';
import { Observable, of } from 'rxjs';
import {filter, map, withLatestFrom} from 'rxjs/operators';

@Component({
  selector: 'nexnox-web-tickets-related-ticket-links',
  templateUrl: './related-ticket-links.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TicketRelatedTicketLinksComponent implements OnInit {

  @Input() public model$: Observable<TicketDto> = of(null);
  @Input() public loading: boolean;

  public solutionMembers$: Observable<SolutionMemberDto[]>;
  public relatedTickets$: Observable<TicketDto[]>

  public faEnvelope = faEnvelope;

  public ngOnInit(): void {
    this.solutionMembers$ = this.model$.pipe(
      filter(model => Boolean(model)),
      map(model => model.solution?.members ?? [])
    )

    this.relatedTickets$ = this.solutionMembers$.pipe(
      withLatestFrom(this.model$),
      map(([solutionMembers, model]) => solutionMembers.filter(member => member.type === SolutionMemberType.Ticket && member.id !== model.ticketId).map(member => ({
        ticketId: member.id,
        title: member.title
      } as TicketDto)))
    );

  }
}
