<div class="row w-100 mb-4">
  <div class="col-md-8 pl-0 pr-0 pr-md-2">
    <nexnox-web-entity-edit [entityEditBaseComponent]="this"
                            displayKey="title"
                            [noMarginBottom]="true"
                            [style.display]="'block'"
                            (nexnoxWebResized)="onEditComponentResized($event)">
    </nexnox-web-entity-edit>
  </div>

  <div class="col-md-4 pl-0 pl-md-2 pr-0">
    <nexnox-web-cardbox [title]="'core-portal.settings.subtitles.templates.text-template-variables' | translate"
                        paddingClasses="h-100 overflow-isHidden"
                        classes="d-flex flex-column overflow-isHidden"
                        [noMarginBottom]="true"
                        [fullHeight]="true"
                        [style.display]="'block'"
                        [style.height.px]="variableHeightSubject | async">
      <div class="w-100 h-100 px-3 pb-3 overflow-auto">
        <button *ngFor="let variable of templateVariables$ | async"
                type="button"
                class="btn btn-link d-block"
                [disabled]="readonly"
                (click)="onAddVariable(variable)">
          {{ variable }}
        </button>
      </div>
    </nexnox-web-cardbox>
  </div>
</div>

