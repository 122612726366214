import {
  inventoryNumberDetailStore,
  InventoryNumberDetailStoreState,
  inventoryNumberListStore,
  InventoryNumberListStoreState
} from './stores';

export interface InventoryNumbersState {
  inventoryNumberList: InventoryNumberListStoreState;
  inventoryNumberDetail: InventoryNumberDetailStoreState;
}

export const getInitialInventoryNumbersState = (): InventoryNumbersState => ({
  inventoryNumberList: inventoryNumberListStore.getInitialState(),
  inventoryNumberDetail: inventoryNumberDetailStore.getInitialState()
});
