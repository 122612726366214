export {
  CorePortalFeatureMasterDataContactListComponent
} from './lib/containers';
export {
  CorePortalFeatureContactEditComponent
} from './lib/components';
export {
  CorePortalFeatureMasterDataContactService
} from './lib/store';
export * from './lib/core-portal-feature-contacts.module';
