<p-sidebar #sidebarComponent
           position="right"
           appendTo="body"
           styleClass="p-sidebar-md"
           [transitionOptions]="transitionOptions"
           [visible]="visible$ | async"
           (visibleChange)="onVisibleChange($event)">

  <div class="row">
    <div class="col-12">
      <h4>{{ 'resources.subtitles.resource-safe' | translate }}</h4>
    </div>
  </div>

  <ul class="nav nav-tabs" *ngIf="!isOutOfSave">
    <li class="nav-item">
      <span class="nav-link" [class.active]="!isRestoreResource">
        <span class="heading" (click)="setDefaultTab()">{{ 'resources.fields.settings' | translate }}</span>
      </span>
    </li>
    <li class="nav-item" *ngIf="hasDeleteSafePermission$ | async">
      <span class="nav-link" [class.active]="isRestoreResource">
        <span class="heading" (click)="setRestoreTab()">{{ 'resources.fields.restore' | translate }}</span>
      </span>
    </li>
  </ul>

  <ng-container *ngTemplateOutlet="isRestoreResource ? restoreTemplate : defaultTemplate"></ng-container>

</p-sidebar>

<!-- Templates -->
<ng-template #defaultTemplate>
  <div class="d-flex flex-grow-1 flex-column h-100 mt-1">
    <div class="row">
      <div class="col-12">
        <div class="alert alert-light d-flex align-items-center pl-0">
          <fa-icon [icon]="faExclamationTriangle" [fixedWidth]="true" class="mr-2"></fa-icon>
          {{ 'resources.descriptions.put-resource-in-safe' | translate }}
        </div>
      </div>
    </div>
    <form [formGroup]="form">
      <formly-form class="row w-100"
                   [form]="form"
                   [(model)]="model"
                   [fields]="fields">
      </formly-form>
    </form>
  </div>

  <div class="d-flex flex-grow-0 justify-content-end pt-3">
    <button type="button"
            pButton
            [label]="'resources.actions.cancel' | translate"
            class="p-button-secondary ml-2"
            (click)="onHide()">
    </button>
    <button type="button"
            pButton
            [icon]="faLock | nexnoxWebFaIconStringPipe"
            [label]="'resources.actions.put-in-safe' | translate"
            class="p-button-primary ml-2"
            [disabled]="form.invalid"
            [loading]="loading$ | async"
            [loadingIcon]="faSpinner | nexnoxWebFaIconStringPipe: 'fa-spin'"
            (click)="onPutInSave()">
    </button>
  </div>
</ng-template>

<ng-template #restoreTemplate>
  <div class="d-flex flex-grow-1 flex-column h-100 mt-1">
    <div class="col-12">
      <div class="alert alert-light d-flex align-items-center pl-0">
        <fa-icon [icon]="faExclamationTriangle" [fixedWidth]="true" class="mr-2"></fa-icon>
        {{ 'resources.descriptions.remove-resource-from-safe' | translate }}
      </div>
    </div>
  </div>
  <div class="d-flex flex-grow-0 justify-content-end pt-3">
    <button type="button"
            pButton
            [label]="'resources.actions.cancel' | translate"
            class="p-button-secondary ml-2"
            (click)="onHide()">
    </button>

    <button type="button"
            pButton
            [icon]="faLockOpen | nexnoxWebFaIconStringPipe"
            [label]="'resources.actions.remove-from-safe' | translate"
            class="p-button-danger ml-2"
            [disabled]="(hasDeleteSafePermission$ | async) === false"
            [loading]="loading$ | async"
            [loadingIcon]="faSpinner | nexnoxWebFaIconStringPipe: 'fa-spin'"
            (click)="onRemoveFromSave()">
    </button>
  </div>
</ng-template>
