import { ChangeDetectionStrategy, Component, Injector } from '@angular/core';
import { CorePortalEntityOverviewBaseComponent, DatatableActionButton } from '@nexnox-web/core-portal';
import {
  AppEntityType,
  FilterOperators,
  FilterTypes,
  LocationDto,
  LocationState,
  Mappers
} from '@nexnox-web/core-shared';
import { locationGroupLocationListStore } from '../../store';

@Component({
  selector: 'nexnox-web-master-data-location-groups-location-group-location-list',
  templateUrl: './location-group-location-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LocationGroupLocationListComponent extends CorePortalEntityOverviewBaseComponent<LocationDto> {
  public title = 'core-portal.master-data.subtitles.location-list';
  public createTitle = 'core-portal.master-data.subtitles.location-create';
  public idProperty = 'locationId';
  public displayProperty = 'name';
  public datatableConfigName = 'MasterDataLocationDefault';
  public componentId = 'LocationGroupLocationListComponent';

  constructor(
    protected injector: Injector
  ) {
    super(injector, locationGroupLocationListStore, Mappers.LocationListDto.serializedName, AppEntityType.Location);
  }

  public getRowActionButtons(): DatatableActionButton[] {
    return this.getEmbeddedRowActionButtons();
  }
}
