import { ChangeDetectionStrategy, Component, Injector } from '@angular/core';
import { ActionButton, CorePortalEntityDetailBaseComponent, CorePortalPermissionService } from '@nexnox-web/core-portal';
import { AppPermissions, CompanyDto } from '@nexnox-web/core-shared';
import { companyDetailStore } from '../../store';
import { Observable } from 'rxjs';

@Component({
  selector: 'nexnox-web-master-data-companies-company-detail',
  templateUrl: './company-detail.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CompanyDetailComponent extends CorePortalEntityDetailBaseComponent<CompanyDto> {
  public title = 'core-portal.master-data.subtitles.company-detail';

  public displayAddressesPermission$: Observable<boolean>;

  protected idParam = 'companyId';
  protected displayKey = 'name';

  constructor(
    protected injector: Injector,
    private permissionService: CorePortalPermissionService
  ) {
    super(injector, companyDetailStore);

    this.displayAddressesPermission$ = this.permissionService.hasPermission$(AppPermissions.DisplayAddresses);
  }

  protected async getActionButtons(): Promise<ActionButton[]> {
    return this.getDefaultActionButtons(
      'core-portal.master-data.actions.edit-company',
      'core-portal.master-data.actions.save-company',
      'core-portal.master-data.actions.delete-company',
      'core-portal.master-data.descriptions.delete-company',
      AppPermissions.UpdateCompany,
      AppPermissions.DeleteCompany,
      ['/masterdata/companies']
    );
  }
}
