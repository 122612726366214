<ng-sidebar-container contentClass="sidebar__content">
  <ng-sidebar [opened]="isOpen"
              [mode]="mode"
              [closeOnClickOutside]="mode === 'over'"
              [keyClose]="mode === 'over'"
              [autoCollapseWidth]="1199"
              [autoCollapseOnInit]="false"
              sidebarClass="sidebar"
              (openedChange)="onOpenedChanged($event)">

    <!-- container -->
    <div class="d-flex max-h-[100%] h-100 flex-column">

      <!-- header -->
      <div class="header flex-shrink-0 d-flex flex-column">
        <div *ngIf="showTrialVersionBanner$ | async" class="trial-flag small">
          <span class="trial-version">{{ 'core-shared.shared.fields.subscription' | translate }}</span>&nbsp;
          <span>{{ 'core-shared.shared.trial.ends' | translate }} {{ trialVersionExpiry$ | async | nexnoxWebDayjsRelative | async }}</span>
          <span class="small">
            &nbsp; (
            <a nexnoxWebTenantRouterLink="/admin/system/general" class="white"
               module="settings">{{ 'core-shared.shared.actions.show-more' | translate }}</a>
            )
          </span>
        </div>
        <div class="logo flex-grow-1">
          <div class="wrapper">
            <img *ngIf="logoPath" class="img-fluid" [src]="logoPath">
          </div>
        </div>
      </div>

      <!-- modules -->
      <div class="divider-top flex-grow-1 overflow-y-hidden d-flex flex-column">
        <ng-container *ngIf="loggedIn">
          <ng-container *ngIf="sidebarContent$ | async as sidebarContent">
            <ng-container *ngFor="let module of sidebarContent">

              <div class="sidebar-item divider-bottom overflow-y-hidden animate-item"
                   *ngIf="!module.hide"
                   [ngClass]="module.expanded ? 'flex-shrink-1' : 'flex-shrink-0'"
                   [style.max-height.px]="module.expanded ? (module.maxHeight + 52) : 50">

                <div class="sidebar-item__content flex-shrink-0 flex-basis-100">
                  <div class="sidebar-item__content__inner-content" (click)="toggleModule(module, sidebarContent)">
                    <fa-icon [icon]="module.icon"
                             [fixedWidth]="true"
                             size="lg"
                             class="mr-2">
                    </fa-icon>
                    {{ module.name | translate}}
                  </div>
                </div>

                <!-- module sidebar items -->
                <div class="items flex-shrink-1 w-100 sidebar-scrollbar overflow-y-auto"
                     [class.overflow-y-hidden]="isAnimating$.asObservable() | async"
                     [class.divider-top]="module.expanded || (isAnimating$.asObservable() | async)">
                  <nexnox-web-sidebar-item *ngFor="let item of module.items; trackBy: trackItemsBy;"
                                           [item]="item"
                                           [moduleIsAnimating$]="isAnimating$"
                                           [level]="0"
                                           [checkPermissionFn]="checkPermissionFn"
                                           (itemClicked)="onItemClicked($event)">
                  </nexnox-web-sidebar-item>
                </div>
              </div>
            </ng-container>
          </ng-container>
        </ng-container>
      </div>

      <!-- settings menu item -->
      <div *ngIf="settingsItem$ | async as item" class="w-100 align-self-end flex-shrink-0">
        <div class="sidebar-item">
          <a class="sidebar-item__content"
             [nexnoxWebTenantRouterLink]="item.path"
             nexnoxWebTenantRouterLinkActive="sidebar-item__content--active"
             [module]="item.module">
            <div class="sidebar-item__content__inner-content">
              <span class="sidebar-item__content__active"></span>
              <fa-icon [icon]="faCog"
                       [fixedWidth]="true"
                       size="lg"
                       class="mr-2">
              </fa-icon>
              {{'core-portal.settings.menu.title' | translate }}
            </div>
          </a>
        </div>
      </div>
    </div>
  </ng-sidebar>

  <div ng-sidebar-content>
    <ng-content></ng-content>
  </div>
</ng-sidebar-container>
