import {
  invitationsContactListStore,
  InvitationsContactListStoreState,
  invitationsDetailStore,
  InvitationsDetailStoreState,
  invitationsListStore,
  InvitationsListStoreState
} from './stores';

export interface InvitationsState {
  invitationsList: InvitationsListStoreState;
  invitationsDetail: InvitationsDetailStoreState;
  invitationsContactList: InvitationsContactListStoreState;
}

export const getInitialInvitationsState = (): InvitationsState => ({
  invitationsList: invitationsListStore.getInitialState(),
  invitationsDetail: invitationsDetailStore.getInitialState(),
  invitationsContactList: invitationsContactListStore.getInitialState()
});
