<p-sidebar #sidebarComponent
           position="right"
           appendTo="body"
           styleClass="p-sidebar-md"
           [transitionOptions]="transitionOptions"
           [visible]="visible$ | async"
           (visibleChange)="onVisibleChange($event)">
  <div class="d-flex align-content-between flex-column h-100">
    <h4>{{ 'resources.fields.resource-new-state' | translate }}: {{ newStateLabel$ | async }}</h4>

    <div class="mt-3 row no-gutters w-100">
      <form [formGroup]="form" class="w-100">
        <formly-form class="row w-100"
                     [(model)]="model"
                     [form]="form"
                     [fields]="fields">
        </formly-form>
        <small *ngIf="isActivateResource"
               class="form-text text-muted mx-2">
          {{ 'resources.descriptions.change-state-add-location' | translate }}
        </small>
      </form>

      <p *ngIf="showIsKeepingLocationSwitch && model.removeLocation" class="form-text text-muted mx-2">
        {{ 'resources.descriptions.change-state-delete-location' | translate }}
      </p>
    </div>

    <div class="w-100 d-flex flex-shrink-1 gap-2 justify-content-end mt-auto mb-0">
      <button type="button"
              pButton
              class="p-button-secondary ml-2"
              [label]="'resources.actions.cancel' | translate"
              (click)="onHide()">
      </button>
      <button type="button"
              pButton
              class="p-button-primary ml-2"
              [label]="'core-shared.shared.actions.accept' | translate"
              [loading]="loading$ | async"
              [loadingIcon]="faSpinner | nexnoxWebFaIconStringPipe: 'fa-spin'"
              (click)="onAccept()">
      </button>
    </div>
  </div>
</p-sidebar>

<!-- Location Templates -->
<ng-template #locationSelectLabelTitleTemplate let-item="item">
  <nexnox-web-master-data-locations-location-location-number [location]="item"
                                                             [label]="true">
  </nexnox-web-master-data-locations-location-location-number>
</ng-template>

<ng-template #locationSelectOptionTitleTemplate let-item="item" let-searchTerm="searchTerm">
  <nexnox-web-master-data-locations-location-location-number [location]="item"
                                                             [searchTerm]="searchTerm"
                                                             [label]="false">
  </nexnox-web-master-data-locations-location-location-number>
</ng-template>
