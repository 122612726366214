import { ChangeDetectionStrategy, Component, Injector } from '@angular/core';
import { CorePortalEntityEditBaseComponent } from '@nexnox-web/core-portal';
import { StateDto } from '@nexnox-web/core-shared';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { Validators } from '@angular/forms';

@Component({
  selector: 'nexnox-web-ticket-settings-states-state-edit',
  templateUrl: './state-edit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TechPortalFeatureStateEditComponent extends CorePortalEntityEditBaseComponent<StateDto> {
  constructor(
    protected injector: Injector
  ) {
    super(injector);
  }

  /* istanbul ignore next */
  protected createForm(): FormlyFieldConfig[] {
    return [
      {
        key: 'name',
        type: 'input',
        wrappers: ['core-portal-translated'],
        className: 'col-md-6',
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.name',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          },
          type: 'text'
        },
        hideExpression: () => !this.creating,
        expressionProperties: {
          'templateOptions.required': () => !this.readonly,
          'templateOptions.disabled': () => this.readonly
        }
      },
      {
        key: 'externalId',
        type: 'core-portal-external-id',
        wrappers: ['core-portal-translated'],
        className: 'col-md-6',
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.external-id',
          },
          titleKey: 'name',
          modelSubject: this.modelSubject
        },
        expressionProperties: {
          'templateOptions.disabled': () => this.readonly
        }
      },
      {
        key: 'color',
        type: 'core-portal-color-picker',
        wrappers: ['core-portal-translated'],
        className: 'col-md-12',
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.color',
            validationMessages: {
              required: 'core-portal.core.validation.required',
              hexColor: 'core-portal.core.validation.hex-color'
            }
          }
        },
        expressionProperties: {
          'templateOptions.required': () => !this.readonly,
          'templateOptions.disabled': () => this.readonly
        },
        validators: {
          hexColor: ctrl => Validators.pattern(/^#([0-9A-F]{3}){1,2}$/i)(ctrl) ? null : { hexColor: true }
        }
      }
    ];
  }
}
