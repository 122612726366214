import {Injectable} from '@angular/core';
import {CoreSharedLocalStorageService, LocalStorageKeys} from '@nexnox-web/core-shared';
import {select, Store} from '@ngrx/store';
import {concatMap, filter, map, take} from 'rxjs/operators';
import {authStore} from '../../store/core/auth/auth.store';
import {from, Subject} from 'rxjs';

interface ChangeQueue {
  callback: (tenantSettings: any) => Promise<any>;
  final: () => void
}

@Injectable()
export class CorePortalTenantLocalStorageService {
  private changeQueue: Subject<ChangeQueue> = new Subject<ChangeQueue>();

  constructor(
    private store: Store<any>,
    private localStorageService: CoreSharedLocalStorageService
  ) {
    this.changeQueue.pipe(
      concatMap(({ callback, final }) => {
        const tenantSettings = this.localStorageService.get(LocalStorageKeys.TENANT_SETTINGS) ?? {};
        return from(callback(tenantSettings)).pipe(
          map(mapped => {
            this.localStorageService.set(LocalStorageKeys.TENANT_SETTINGS, mapped);
            return final();
          })
        );
      })
    ).subscribe();
  }

  public set(key: LocalStorageKeys, storageObject: any, overrideTenantId?: string): Promise<void> {
    return new Promise<void>(resolve => {
      this.changeQueue.next({
        callback: async tenantSettings => {
          const tenantDomain = overrideTenantId ?? (await this.getTenantDomain());
          return {
            ...tenantSettings,
            [tenantDomain]: {
              ...(tenantSettings[tenantDomain] ?? {}),
              [key]: storageObject
            }
          };
        },
        final: () => resolve()
      });
    });
  }

  public async get(key: LocalStorageKeys, overrideTenantDomain?: string, overrideNull: boolean = false): Promise<any> {
    const tenantSettings = this.localStorageService.get(LocalStorageKeys.TENANT_SETTINGS) ?? {};
    const tenantId = overrideTenantDomain || overrideNull ? overrideTenantDomain : (await this.getTenantDomain());

    return Promise.resolve((tenantSettings[tenantId] ?? {})[key]);
  }

  public async getWithoutFilter(key: LocalStorageKeys, overrideTenantDomain?: string, overrideNull: boolean = false): Promise<any> {
    const tenantSettings = this.localStorageService.get(LocalStorageKeys.TENANT_SETTINGS) ?? {};
    const tenantDomain = overrideTenantDomain || overrideNull ? overrideTenantDomain : (await this.getTenantDomain(true));

    return Promise.resolve((tenantSettings[tenantDomain] ?? {})[key]);
  }

  public remove(key: LocalStorageKeys): Promise<void> {
    return new Promise<void>(resolve => {
      this.changeQueue.next({
        callback: async tenantSettings => {
          const tenantDomain = await this.getTenantDomain();

          if (tenantSettings[tenantDomain] && tenantSettings[tenantDomain][key]) {
            delete tenantSettings[tenantDomain][key];
          }

          return tenantSettings;
        },
        final: () => resolve()
      });
    });
  }

  public clear(): Promise<void> {
    return new Promise<void>(resolve => {
      this.changeQueue.next({
        callback: async tenantSettings => {
          const tenantDomain = await this.getTenantDomain();

          if (tenantSettings[tenantDomain]) {
            delete tenantSettings[tenantDomain];
          }

          return tenantSettings;
        },
        final: () => resolve()
      });
    });
  }

  private async getTenantDomain(noFilter: boolean = false): Promise<string> {
    return this.store.pipe(
      select(authStore.selectors.selectActiveTenant),
      filter(tenant => noFilter ? true : Boolean(tenant)),
      map(tenant => tenant?.domain),
      take(1)
    ).toPromise();
  }
}
