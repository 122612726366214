import {Action, createSelector} from '@ngrx/store';
import {selectContractsState} from '../../contracts.selector';
import {Injectable, Injector} from '@angular/core';
import {PagedEntitiesXsStore, PagedEntitiesXsStoreState} from "@nexnox-web/core-store";
import {AppEntityType, TicketListDto} from "@nexnox-web/core-shared";
import {TechPortalFeatureContractTicketService} from "../../services";

export interface ContractTicketStoreState extends PagedEntitiesXsStoreState<TicketListDto> {
}

/* istanbul ignore next */
export const contractTicketStore = new PagedEntitiesXsStore<TicketListDto>({
  actionLabel: 'Tech Portal - Contracts - Ticket List',
  stateSelector: createSelector(selectContractsState, state => state.contractTicket),
  serviceType: TechPortalFeatureContractTicketService,
  entityType: AppEntityType.Ticket,
  selectId: contract => contract.entity?.ticketId ?? contract.model?.ticketId
});


export function contractTicketStoreReducer(state: ContractTicketStoreState, action: Action): any {
  return contractTicketStore.reducer(state, action);
}

@Injectable()
export class ContractTicketStoreEffects extends contractTicketStore.effects {
  constructor(
    protected injector: Injector
  ) {
    super(injector);
  }
}
