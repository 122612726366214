import {ChangeDetectionStrategy, Component, Injector, OnInit, ViewChild} from '@angular/core';
import {faEllipsisV} from '@fortawesome/free-solid-svg-icons/faEllipsisV';
import {faExclamationTriangle} from '@fortawesome/free-solid-svg-icons/faExclamationTriangle';
import {faPlus} from '@fortawesome/free-solid-svg-icons/faPlus';
import {faSave} from '@fortawesome/free-solid-svg-icons/faSave';
import {faSync} from '@fortawesome/free-solid-svg-icons/faSync';
import {
  ActionButton,
  CorePortalEntityCreatePresetService,
  CorePortalEntityDetailBaseComponent
} from '@nexnox-web/core-portal';
import {
  AppEntityType,
  AppPermissions,
  LocationDto,
  ResourceDto,
  ResourceState,
  ResourceType,
  SafeDto
} from '@nexnox-web/core-shared';
import {select} from '@ngrx/store';
import {isEmpty} from 'lodash';
import {filter, firstValueFrom, lastValueFrom, Observable, of} from 'rxjs';
import {distinctUntilChanged, map, startWith, take} from 'rxjs/operators';
import {
  resourceDetailStore,
  resourceEntitiesStore,
  resourceTreeViewStore,
  resourceUIEntitiesStore
} from '../../store/stores';
import {EventListSidebarComponent, ResourceChangeStateSidebarComponent, SafeSidebarComponent} from "../../sidebars";
import {faSpinner} from "@fortawesome/free-solid-svg-icons/faSpinner";
import {faPencilAlt} from "@fortawesome/free-solid-svg-icons/faPencilAlt";
import {faBoxes} from '@fortawesome/free-solid-svg-icons/faBoxes';
import {faExchangeAlt} from "@fortawesome/free-solid-svg-icons/faExchangeAlt";
import {resourceStateColoredEnumOptions} from "../../models";
import {TranslateService} from "@ngx-translate/core";
import {
  InheritanceManufacturerModelService,
  InheritsQuickAccessPreviewService,
  InheritsSuggestionsPreviewService,
  ResourceInventoryNumberPreviewService,
  ResourcePreviewService
} from "../../store";
import {faTimesCircle} from "@fortawesome/free-solid-svg-icons/faTimesCircle";

@Component({
  selector: 'nexnox-web-resources-resource-detail',
  templateUrl: './resource-detail.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ResourceDetailComponent extends CorePortalEntityDetailBaseComponent<ResourceDto> implements OnInit {
  @ViewChild('changeStateSidebar', {static: true}) public changeStateSidebar: ResourceChangeStateSidebarComponent;
  @ViewChild('resourceSafeSidebar', {static: true}) public resourceSafeSidebar: SafeSidebarComponent;
  @ViewChild('resourceEventListSidebar', {static: true}) public resourceEventListSidebar: EventListSidebarComponent;

  public title = 'resources.subtitles.resource';

  public safeContacts$: Observable<string>;
  public changeStateLoading$: Observable<boolean>;
  public resourceId$: Observable<number>;

  public resourceTypes = ResourceType;

  public faExclamationTriangle = faExclamationTriangle;
  public faSync = faSync;
  public faPencil = faPencilAlt;
  public faSpinner = faSpinner;
  public faBoxes = faBoxes;

  protected idParam = 'resourceId';
  protected displayKey = 'name';

  constructor(
    protected injector: Injector,
    public entityCreatePresetService: CorePortalEntityCreatePresetService,
    private translateService: TranslateService
  ) {
    super(injector, resourceDetailStore);
  }

  public ngOnInit(): void {

    super.ngOnInit();

    this.resourceId$ = this.route.paramMap.pipe(map(params => +params.get('resourceId')), distinctUntilChanged(), filter(id => Boolean(id)));

    this.bypassMonitorsSubject.next(true); // ToDo: Since it is working like other base components, figure out a common solution

    this.monitors.add([
      {
        name: 'inventoryNumberPreview',
        keys: ['stereotypeId'],
        service: ResourceInventoryNumberPreviewService,
        payload: {
          stereotypes$: this.stereotypes$,
          entityType: AppEntityType.Resource,
          entityStore: resourceDetailStore
        }
      },
      {
        name: 'resourcePreview',
        keys: ['stereotypeId', 'parent'],
        service: ResourcePreviewService,
        payload: {
          stereotypes$: this.stereotypes$,
          entityType: AppEntityType.Resource,
          entityStore: resourceDetailStore
        }
      },
      {
        name: 'inheritanceManufacturerModelPreview',
        keys: ['inheritsManufacturer', 'inheritsModel'],
        service: InheritanceManufacturerModelService,
        payload: {
          entityType: AppEntityType.Resource,
        }
      },
      {
        name: 'inheritsSuggestionsPreview',
        keys: ['inheritsSuggestions'],
        service: InheritsSuggestionsPreviewService,
        payload: {
          entityType: AppEntityType.Resource,
        }
      },
      {
        name: 'inheritsQuickAccessConfigurationPreview',
        keys: ['inheritsQuickAccessConfiguration'],
        service: InheritsQuickAccessPreviewService,
        payload: {
          entityType: AppEntityType.Resource,
        }
      }
    ]);

    this.safeContacts$ = this.model$.pipe(
      map((resource) => (resource?.safe?.contacts ?? []).map(contact => contact.displayName).join(', '))
    );

    this.changeStateLoading$ = this.store.pipe(
      select(this.entityStore.selectors.selectEntityDataLoading, {key: 'changeState'}),
      startWith(false)
    );

    // Refresh resource tree view
    this.resourceId$.pipe(take(1)).subscribe(resourceId => this.store.dispatch(resourceTreeViewStore.actions.selectById({resourceId})));
    this.getActionButtons().then(actionButtons => this.actionBarService.setActions(actionButtons));
  }

  public async onRefresh(): Promise<void> {
    // Reload entity
    const entity = await this.selectEntity();
    this.store.dispatch(this.entityStore.actions.get({id: entity.resourceId}));
    this.store.dispatch(resourceTreeViewStore.actions.selectById({
      resourceId: entity.resourceId
    }));
  }

  public onPutInSafe(safe: SafeDto): void {
    this.store.dispatch(resourceDetailStore.actions.putInSafe({contacts: safe.contacts}));
  }

  public onRemoveFromSafe(resourceId: number): void {
    this.store.dispatch(resourceDetailStore.actions.removeFromSafe({resourceId}));
  }

  public async openChangeStateSidebar(state: ResourceState): Promise<void> {
    const entity: ResourceDto = await this.selectEntity();
    if (entity.currentState !== state) {
      this.changeStateSidebar.onShow(state, entity);
    }
  }

  public async onChangeState(event: {
    state: ResourceState,
    location: LocationDto | undefined,
    isKeepingLocation: boolean | undefined
  }): Promise<void> {
    const {state, location, isKeepingLocation} = event;
    return this.store.dispatch(resourceDetailStore.actions.changeState({
      state,
      location,
      isKeepingLocation
    }));

  }

  protected async selectEntity(): Promise<ResourceDto> {
    return await lastValueFrom(this.store.pipe(select(this.entityStore.selectors.selectEntity), take(1)));
  }

  /* istanbul ignore next */
  protected async getActionButtons(): Promise<ActionButton[]> {
    return [
      {
        label: 'core-portal.core.general.cancel',
        type: 'button',
        class: 'btn-outline-secondary',
        icon: faTimesCircle,
        shouldShow: () => this.readonly$.asObservable().pipe(
          map(readonly => !readonly)
        ),
        isLoading: () => this.loading$,
        callback: () => this.onCancelAction().catch(() => null)
      },
      {
        label: 'resources.actions.edit-resource',
        type: 'button',
        class: 'btn-outline-primary',
        permission: AppPermissions.UpdateResource,
        icon: faPencilAlt,
        shouldShow: () => this.readonly$.asObservable(),
        isLoading: () => this.loading$,
        callback: () => this.onEditAction(),
        isDisabled: () => this.model$.pipe(map(model => Boolean(model.isInProgressSince)))
      },
      {
        label: 'resources.actions.save-resource',
        type: 'button',
        class: 'btn-primary',
        permission: AppPermissions.UpdateResource,
        icon: faSave,
        shouldShow: () => this.readonly$.asObservable().pipe(
          map(readonly => !readonly)
        ),
        isDisabled: () => this.shouldDisablePrimaryAction(),
        isLoading: () => this.loading$,
        callback: () => this.onSaveAction()
      },

      {
        label: 'resources.actions.create-resource-from-resource',
        type: 'button',
        class: 'btn-outline-secondary',
        permission: AppPermissions.CreateResource,
        icon: faPlus,
        shouldShow: () => this.entity$.pipe(
          map(entity => Boolean(entity) && !isEmpty(entity) && (entity?.type === ResourceType.Device || entity?.type === ResourceType.Group))
        ),
        callback: async () => {
          const entity = await firstValueFrom(this.entity$.pipe(take(1)));

          if (entity) {
            // Prepare preset
            this.entityCreatePresetService.setPreset('TechPortalFeatureFeatureResourceEditComponent', {
              parent: entity,
              stereotypeId: entity.stereotypeId,
              inheritsKnowledge: true,
              inheritsManufacturer: true,
              inheritsModel: true,
              inheritsProcesses: true,
              inheritsQuickAccessConfiguration: true,
              inheritsSuggestions: true
            });
            // Bypass monitors
            this.bypassMonitorsSubject.next(true);
            // Change to create
            await this.tenantRouter.navigate(['/resources'], {fragment: 'create', module: 'inventory'});
          }
        }
      },
      {
        label: 'resources.actions.create-ticket-from-resource',
        type: 'button',
        class: 'btn-outline-secondary',
        permission: AppPermissions.CreateTicket,
        icon: faPlus,
        shouldShow: () => this.entity$.pipe(
          map(entity => Boolean(entity) && !isEmpty(entity) && (entity?.type === ResourceType.Device || entity?.type === ResourceType.Virtual))
        ),
        callback: async () => {
          const entity = await firstValueFrom(this.entity$.pipe(take(1)));

          if (entity) {
            this.entityCreatePresetService.setPreset('TechPortalFeatureTicketEditComponent', {
              resource: entity,
              location: entity.location
            });
            await this.tenantRouter.navigate(['/tickets'], {module: 'communication', fragment: 'create'});
          }
        }
      },
      {
        label$: this.model$.pipe(
          map(resource => {
            const label = this.translateService.instant('resources.actions.change-resource-state');
            const state = resourceStateColoredEnumOptions.find(s => s.value === (resource?.currentState ?? ResourceState.Operation));
            return `${label}: ${state?.label ? this.translateService.instant(state.label) : ''}`;
          })
        ),
        type: 'dropdown',
        icon: faExchangeAlt,
        class: 'btn-outline-primary',
        noTranslate: true,
        isLoading: () => this.changeStateLoading$,
        shouldShow: () => this.model$.pipe(map(model => model?.type === ResourceType.Device && typeof model.isInProgressSince !== 'string')),
        buttons: of(resourceStateColoredEnumOptions.map(resourceState => ({
          label: resourceState.label,
          type: 'button',
          callback: async () => this.openChangeStateSidebar(resourceState.value)
        } as ActionButton))),
        permission: AppPermissions.UpdateResource
      },

      {
        type: 'dropdown',
        class: 'btn-outline-secondary',
        icon: faEllipsisV,
        tooltip: 'core-portal.core.general.more',
        alignRight: true,
        noArrow: true,
        buttons: of([
          {
            label: 'resources.subtitles.resource-events',
            type: 'button',
            permission: AppPermissions.ReadResourceEvent,
            shouldShow: () => this.entity$.pipe(
              map(entity => Boolean(entity?.resourceId))
            ),
            callback: () => this.resourceEventListSidebar.onShow()
          },
          {
            label: 'resources.actions.put-in-safe',
            type: 'button',
            permission: AppPermissions.CreateSafe,
            shouldShow: () => this.entity$.pipe(
              map(entity => !Boolean(entity?.safe) && Boolean(entity?.resourceId) && entity.type === ResourceType.Device)
            ),
            callback: () => this.resourceSafeSidebar.onShow()
          },
          {
            label: 'resources.actions.remove-from-safe',
            type: 'button',
            permission: AppPermissions.DeleteSafe,
            shouldShow: () => this.entity$.pipe(
              map(entity => Boolean(entity?.safe) && Boolean(entity?.resourceId) && entity.type === ResourceType.Device)
            ),
            callback: () => this.resourceSafeSidebar.onShow(true)
          },
          {
            label: 'resources.actions.delete-resource',
            type: 'button',
            permission: AppPermissions.DeleteResource,
            shouldShow: () => this.entity$.pipe(
              map(entity => Boolean(entity) && !isEmpty(entity) && entity.type !== ResourceType.Virtual)
            ),
            callback: async (_, button: ActionButton) => {
              const entity = await firstValueFrom(this.entity$.pipe(take(1)));

              if (entity) {
                this.id = entity.resourceId;
                this.onDeleteAction(
                  button,
                  'resources.descriptions.delete-resource',
                  this.tenantRouter.createCommands(['resources'], {module: 'inventory'})
                );
              }
            }
          }
        ]),
        isLoading: () => this.loading$
      }
    ];
  }

  protected override async onSaveAction(): Promise<void> {
    const entity = await lastValueFrom(this.store.pipe(
      select(this.entityStore.selectors.selectEntity),
      take(1)
    ));
    this.isDeactivateUnsavedChangesModal = true;
    this.bypassMonitorsSubject.next(true);
    this.store.dispatch(this.entityStore.actions.save({id: entity.resourceId, parentIds: this.parentIds}));
  }

  protected override async onCancelAction(): Promise<void> {
    const cancel = (): void => {
      this.isDeactivateUnsavedChangesModal = true;
      window.location.hash = '';
      this.bypassMonitorsSubject.next(true)
    };

    const hasUnsavedChanges = await firstValueFrom(this.hasUnsavedChanges().pipe(take(1)));

    if (hasUnsavedChanges) {
      this.canDeactivate().then(answer => answer ? cancel() : undefined)
    } else {
      cancel();
    }
  }

  protected override deleteEntity(returnPath?: any[]): void {
    super.deleteEntity(returnPath);
    this.isDeactivateUnsavedChangesModal = true;
    this.store.dispatch(resourceEntitiesStore.actions.removeOne({resourceId: +this.id}));
    this.store.dispatch(resourceUIEntitiesStore.actions.removeOne({resourceId: +this.id}));
  }
}
