import { ModuleWithProviders, NgModule } from '@angular/core';
import { InventoryNumbersRoutingModule } from './inventory-numbers-routing.module';
import { InventoryNumbersStoreModule } from './inventory-numbers-store.module';
import { InventoryNumbersDeclarationsModule } from './inventory-numbers-declarations.module';

@NgModule({
  imports: [
    InventoryNumbersDeclarationsModule,
    InventoryNumbersRoutingModule,
    InventoryNumbersStoreModule
  ]
})
export class CorePortalInventoryNumbersModule {
  public static forFeature(): ModuleWithProviders<InventoryNumbersDeclarationsModule> {
    return { ngModule: InventoryNumbersDeclarationsModule };
  }
}
