import {Injectable} from '@angular/core';
import {AppEntityType, IMonitorService, ResourceDto, StereotypeDto} from '@nexnox-web/core-shared';
import {Observable, of} from "rxjs";
import {map, switchMap} from "rxjs/operators";
import {
  CorePortalFeatureInventoryNumberPatternService
} from "@nexnox-web/core-portal/features/settings/features/inventory-numbers";
import {isEqual} from "lodash";
import {EntityXsStore} from "@nexnox-web/core-store";
import {select, Store} from "@ngrx/store";


export interface ResourceInventoryNumberPreviewPayload {
	stereotypes$: Observable<StereotypeDto[]>;
	entityType: AppEntityType;
	entityStore: EntityXsStore<ResourceDto>;
}


@Injectable()
export class ResourceInventoryNumberPreviewService implements IMonitorService<ResourceDto> {

	constructor(
		private store: Store,
		private inventoryNumberPatternService: CorePortalFeatureInventoryNumberPatternService
	) {
	}

	public modifyModel(payload: ResourceInventoryNumberPreviewPayload, key:string, model: ResourceDto): Observable<ResourceDto> {

		return this.store.pipe(
			select(payload.entityStore.selectors.selectEntity),
			switchMap(entity => payload.stereotypes$.pipe(
				switchMap(stereotypes => {

						const selectedStereotype = (stereotypes ?? []).find(x => x?.stereotypeId === model?.stereotypeId);

						if (selectedStereotype?.inventoryNumberPattern && !isEqual(selectedStereotype.stereotypeId, entity.stereotypeId)) {
							return this.inventoryNumberPatternService.getPreview(selectedStereotype.inventoryNumberPattern.inventoryNumberPatternId).pipe(
								map(({nextNumberCount}) => ({
									...model,
									stereotypeRowVersion: selectedStereotype?.rowVersion,
									inventoryNumberCount: nextNumberCount
								}))
							);
						}

						return of({
							...model,
							inventoryNumberCount: isEqual(selectedStereotype.stereotypeId, entity.stereotypeId) && entity.inventoryNumberCount ? entity.inventoryNumberCount : model.inventoryNumberCount
						});
					}
				))
			)
		);
	}

}
