<p-sidebar #sidebarComponent
           position="right"
           appendTo="body"
           styleClass="p-sidebar-xl"
           [transitionOptions]="transitionOptions"
           [visible]="visible$ | async"
           (visibleChange)="onVisibleChange($event)">
  <div class="d-flex align-content-between flex-column h-100"
       *ngIf="(model$ | async); else loadingTemplate">

    <h6 class="pl-2 mb-2">{{ (model$ | async)?.name }}</h6>

    <div *ngIf="(visible$ | async) && (customParams$ | async)" class="overflow-y-auto">
      <nexnox-web-infos-info-progress-contact-read-unread-list [parentIds]="parentIds"
                                                               [isRemoveOfExpectedColumn]="isFunction$ | async"
                                                               [customParams]="customParams$ | async">
      </nexnox-web-infos-info-progress-contact-read-unread-list>
    </div>

    <div class="w-100 d-flex flex-shrink-1 gap-2 justify-content-end mt-auto mb-0">
      <button pButton
              type="button"
              class="p-button p-button-text p-button-secondary"
              [label]="'core-portal.core.general.close' | translate"
              (click)="onHide()">
      </button>
    </div>
  </div>
</p-sidebar>

<ng-template #loadingTemplate>
  <nexnox-web-loading-icon class="d-flex w-100 h-100 align-items-center justify-content-center flex-column p-5"
                           size="5x">
  </nexnox-web-loading-icon>
</ng-template>
