import {ChangeDetectionStrategy, Component, Injector, OnInit} from "@angular/core";
import {SettingsMenuBaseComponent} from "../../base-component";

@Component({
  selector: 'nexnox-web-configuration-settings',
  templateUrl: './configuration-settings-menu.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfigurationSettingsMenuComponent extends SettingsMenuBaseComponent implements OnInit {

  public isExpandAllItemsOnStart = true;

  constructor(
    protected injector: Injector
  ) {
    super(injector, 'settings');
  }

  public ngOnInit(): void {
    super.ngOnInit();
  }
}
