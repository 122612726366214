import { SnapArrow, SnapCircle, SnapComponent, SnapGroup, SnapLine, StateDto, StateTransitionDto } from '@nexnox-web/core-shared';
import { isNull } from 'lodash';

export class StateMachineTransitionsSnap {
  public paddingToNextColumn = 25;

  constructor(
    private getSnapComponent: () => SnapComponent,
    private getStates: () => StateDto[],
    private getTransitions: () => StateTransitionDto[],
    private isReadonly: () => boolean,
    private deleteTransition: (columnIndex: number) => void
  ) {
  }

  public update(): void {
    this.getSnapComponent()?.clear();

    const states = this.getStates();
    const transitions = this.getTransitions();

    for (let i = 0; i < transitions.length; i++) {
      const transition = transitions[i];
      const fromIndex = !isNull(transition.inStateId) ? states.findIndex(x => x.stateId === transition.inStateId) : null;
      const toIndex = !isNull(transition.outStateId) ? states.findIndex(x => x.stateId === transition.outStateId) : null;

      if (!isNull(fromIndex) && !isNull(toIndex) && fromIndex > -1 && toIndex > -1) {
        this.drawArrowFromStateToState(i, fromIndex, toIndex);
      } else if (isNull(fromIndex) && !isNull(toIndex) && toIndex > -1) {
        this.drawArrowFromStateToState(i, null, toIndex);
      } else if (isNull(toIndex) && !isNull(fromIndex) && fromIndex > -1) {
        this.drawArrowFromStateToState(i, fromIndex, null);
      }
    }
  }

  private drawArrowFromStateToState(columnIndex: number, fromIndex: number, toIndex: number): SnapGroup {
    const isReverse = fromIndex > toIndex;
    const isStart = isNull(fromIndex);
    const isEnd = isNull(toIndex);

    const paddingLeft = 15;
    const paddingToRowCenter = 19.5;
    const paddingToNextRowCenter = paddingToRowCenter * 2;

    const columnPaddingLeft = paddingLeft + (this.paddingToNextColumn * columnIndex);
    const rowPaddingTop = paddingToRowCenter + (paddingToNextRowCenter * (!isReverse || isEnd ? fromIndex : toIndex));

    const group = this.createArrowGroup(columnPaddingLeft, rowPaddingTop, isStart);
    const startCircle = this.createArrowStartCircle(group, fromIndex, toIndex, paddingToNextRowCenter, isReverse, isStart, isEnd);
    const endArrow = this.createArrowEndArrow(group, fromIndex, toIndex, paddingToNextRowCenter, isStart, isEnd, isReverse);
    const line = group.addChild(new SnapLine(
      startCircle.centerPoint.x,
      startCircle.centerPoint.y + (!isReverse || isEnd ? 8 : -8),
      endArrow.centerPoint.x,
      endArrow.centerPoint.y,
      'black',
      2
    ));

    group.onHoverIn(() => {
      if (this.isReadonly()) {
        return;
      }

      startCircle.fill = 'red';
      line.stroke = 'red';
      endArrow.stroke = 'red';
      group.addClass('transition-group--hover');
    });

    group.onHoverOut(() => {
      startCircle.fill = 'black';
      line.stroke = 'black';
      endArrow.stroke = 'black';
      group.removeClass('transition-group--hover');
    });

    group.onClick(() => {
      if (this.isReadonly()) {
        return;
      }

      this.deleteTransition(columnIndex);
    });

    return group;
  }

  private createArrowGroup(columnPaddingLeft: number, rowPaddingTop: number, isStart: boolean): SnapGroup {
    const group = this.getSnapComponent().addElement(new SnapGroup(columnPaddingLeft, rowPaddingTop));

    if (isStart) {
      group.y = 0;
    }

    return group;
  }

  private createArrowStartCircle(
    group: SnapGroup,
    fromIndex: number,
    toIndex: number,
    paddingToNextRowCenter: number,
    isReverse: boolean,
    isStart: boolean,
    isEnd: boolean
  ): SnapCircle {
    const startCircle = group.addChild(new SnapCircle(0, 0, 5));

    if (isStart) {
      startCircle.y = -15;
      return startCircle;
    }

    if (isReverse && !isEnd) {
      startCircle.y = paddingToNextRowCenter * (fromIndex - toIndex);
    }

    return startCircle;
  }

  private createArrowEndArrow(
    group: SnapGroup,
    fromIndex: number,
    toIndex: number,
    paddingToNextRowCenter: number,
    isStart: boolean,
    isEnd: boolean,
    isReverse: boolean
  ): SnapArrow {
    const columnPaddingTop = (paddingToNextRowCenter * (toIndex - fromIndex));
    const endArrow = group.addChild(new SnapArrow(0, columnPaddingTop, 5, 'black', 2, 'S'));

    if (isStart) {
      endArrow.y = paddingToNextRowCenter / 2 + columnPaddingTop;
      return endArrow;
    } else if (isEnd) {
      endArrow.y = (paddingToNextRowCenter * this.getStates().length) - 15;
      return endArrow;
    }

    if (isReverse) {
      endArrow.y = 0;
      endArrow.facing = 'N';
    }

    return endArrow;
  }
}
