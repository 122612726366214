import {PagedEntitiesXsStore, PagedEntitiesXsStoreState} from '@nexnox-web/core-store';
import {AppEntityType, OccurredConsumptionListDto} from '@nexnox-web/core-shared';
import {createSelector} from '@ngrx/store';
import {selectResourcesState} from '../../resources.selectors';
import {TechPortalFeatureResourceOccurredConsumptionsService} from '../../services';
import {cloneDeep} from 'lodash';

export interface LocalOccurredConsumptionListDto extends OccurredConsumptionListDto {
  consumptionCodeId?: number;
  displayName?: string;
  externalId?: string;
}

export interface ResourceOccurredConsumptionsStoreState extends PagedEntitiesXsStoreState<OccurredConsumptionListDto, LocalOccurredConsumptionListDto> {
}

/* istanbul ignore next */
export const resourceOccurredConsumptionsStore = new PagedEntitiesXsStore<OccurredConsumptionListDto, LocalOccurredConsumptionListDto>({
  actionLabel: 'Tech Portal - Resources - Resource Occurred Consumptions',
  stateSelector: createSelector(selectResourcesState, state => state.resourceOccurredConsumptions),
  entityType: AppEntityType.None,
  serviceType: TechPortalFeatureResourceOccurredConsumptionsService,
  selectId: consumption => consumption.entity?.consumption?.consumptionCodeId ?? consumption.model?.consumption?.consumptionCodeId,
  prepareModel: (entity: OccurredConsumptionListDto, model: LocalOccurredConsumptionListDto, base: (entity: OccurredConsumptionListDto, model: LocalOccurredConsumptionListDto) => LocalOccurredConsumptionListDto) => {
    const newModel = cloneDeep(base(entity, model));

    newModel.consumptionCodeId = newModel?.consumption?.consumptionCodeId;
    newModel.displayName = newModel?.consumption?.displayName;
    newModel.externalId = newModel?.consumption?.externalId;

    return newModel;
  }
});
