import {Injectable, Injector} from '@angular/core';
import {AppEntityType, MissionReportReceiptDto, OpenEditorRequestOnContactListDto} from '@nexnox-web/core-shared';
import {EntityXsStoreState, PagedEntitiesXsStoreState} from '@nexnox-web/core-store';
import {createEffect, ofType} from '@ngrx/effects';
import {Action, createSelector} from '@ngrx/store';
import {catchError, map, of, switchMap, tap} from 'rxjs';
import {selectMissionsState} from '../../missions.selectors';
import {TechPortalFeatureMissionMyRequestsService, TechPortalFeatureMissionReceiptService} from '../../services';
import {MissionMyRequestsXsStore, MissionMyRequestsXsStoreActions} from './mission-my-requests.xs-store';
import {exhaustMap} from "rxjs/operators";

export interface MissionMyRequestsStoreState extends PagedEntitiesXsStoreState<OpenEditorRequestOnContactListDto> {
}

/* istanbul ignore next */
export const missionMyRequestsStore = new MissionMyRequestsXsStore({
  actionLabel: 'Tech Portal - Missions - Mission My Requests',
  stateSelector: createSelector(selectMissionsState, state => state.missionMyRequests),
  serviceType: TechPortalFeatureMissionMyRequestsService,
  selectId: (state) => state?.entity?.missionId ?? state?.model?.missionId,
  entityType: AppEntityType.None
});

@Injectable()
export class MissionMyRequestsEffects extends missionMyRequestsStore.effects {

  public acceptMission$: any;
  public acceptMissionSuccess$: any;

  public declineMission$: any;
  public declineMissionSuccess$: any;

  public actions: MissionMyRequestsXsStoreActions;

  protected service: TechPortalFeatureMissionMyRequestsService;

  constructor(
    protected injector: Injector
  ) {
    super(injector);
  }

  protected createEffects(): void {
    super.createEffects();

    this.acceptMission$ = createEffect(() => this.actions$.pipe(
      ofType(this.actions.acceptMission),
      exhaustMap(({missionId, contactId}) => this.service.acceptMission(missionId, contactId).pipe(
        map(() => this.actions.acceptMissionSuccess()),
        catchError(error => of(this.actions.error({
          error,
          action: this.actions.acceptMission
        })))
      ))
    ));
    this.acceptMissionSuccess$ = createEffect(() => this.actions$.pipe(
      ofType(this.actions.acceptMissionSuccess),
      tap(action => this.actionCallback(action, false))
    ), {dispatch: false});

    this.declineMission$ = createEffect(() => this.actions$.pipe(
      ofType(this.actions.declineMission),
      exhaustMap(({missionId, contactId}) => this.service.declineMission(missionId, contactId).pipe(
        map(() => this.actions.declineMissionSuccess()),
        catchError(error => of(this.actions.error({
          error,
          action: this.actions.declineMission
        })))
      ))
    ));
    this.declineMissionSuccess$ = createEffect(() => this.actions$.pipe(
      ofType(this.actions.declineMissionSuccess),
      tap(action => this.actionCallback(action, false))
    ), {dispatch: false});


  }

  protected actionCallback(action: Action, isError: boolean): void {
    super.actionCallback(action, isError);

    this.checkAction(this.actions.acceptMissionSuccess, action, () => this.acceptMissionSuccessCallback());
    this.checkAction(this.actions.declineMissionSuccess, action, () => this.declineMissionSuccessCallback());
  }

  protected acceptMissionSuccessCallback(): void {
    this.store.dispatch(this.actions.getPage(1));
    this.apiNotificationService.showTranslatedSuccess('missions.toasts.mission-accepted');
  }

  protected declineMissionSuccessCallback(): void {
    this.store.dispatch(this.actions.getPage(1));
    this.apiNotificationService.showTranslatedSuccess('missions.toasts.mission-declined');
  }
}
