import {ChangeDetectionStrategy, Component, Injector} from '@angular/core';
import {ActionButton, CorePortalEntityDetailBaseComponent} from '@nexnox-web/core-portal';
import {AppPermissions, GeneralDataProtectionRegulationDto} from '@nexnox-web/core-shared';
import {gdprDetailStore} from '../../store/stores';
import {LocalGDPRRegulationDto} from "./../../models";

@Component({
  selector: 'nexnox-web-settings-gdpr-detail',
  templateUrl: './gdpr-detail.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GdprDetailComponent extends CorePortalEntityDetailBaseComponent<LocalGDPRRegulationDto> {
  public title = 'core-portal.settings.gdpr.titles.detail';

  protected idParam = 'generalDataProtectionRegulationId';
  protected displayKey = 'title';

  constructor(
    protected injector: Injector
  ) {
    super(injector, gdprDetailStore);
  }

  protected async getActionButtons(): Promise<ActionButton[]> {
    return this.getDefaultActionButtons(
      'core-portal.settings.gdpr.actions.edit',
      'core-portal.settings.gdpr.actions.save',
      'core-portal.settings.gdpr.actions.delete',
      'core-portal.settings.gdpr.descriptions.delete',
      AppPermissions.UpdateGeneralDataProtectionRegulation,
      AppPermissions.DeleteGeneralDataProtectionRegulation,
      undefined
    );
  }
}
