import {Injectable, Injector} from '@angular/core';
import {locationDetailStore} from './location-detail.store';
import {createEffect, ofType} from '@ngrx/effects';
import {LocationDetailXsStoreActions} from './location-detail-xs-store.actions';
import {catchError, map, switchMap, tap, withLatestFrom} from 'rxjs/operators';
import {CorePortalFeatureMasterDataLocationService} from '../../services';
import {Action, select} from '@ngrx/store';
import {of} from 'rxjs';

@Injectable()
export class LocationDetailStoreEffects extends locationDetailStore.effects {
  public actions: LocationDetailXsStoreActions;
  public service: CorePortalFeatureMasterDataLocationService;

  public closeLocation$: any;
  public closeLocationSuccess$: any;

  public openLocation$: any;
  public openLocationSuccess$: any;

  constructor(
    protected injector: Injector
  ) {
    super(injector);
  }

  protected createEffects(): void {
    super.createEffects();

    this.closeLocation$ = createEffect(() => this.actions$.pipe(
      ofType(this.actions.closeLocation),
      withLatestFrom(this.store.pipe(select(this.selectors.selectEntity))),
      switchMap(([_, location]) => this.service.closeLocation(location.locationId, _).pipe(
        map(() => this.actions.closeLocationSuccess()),
        catchError(error => of(this.actions.error({error, action: this.actions.closeLocation})))
      ))
    ));

    this.closeLocationSuccess$ = createEffect(() => this.actions$.pipe(
      ofType(this.actions.closeLocationSuccess),
      tap(action => this.actionCallback(action, false))
    ), {dispatch: false});

    this.openLocation$ = createEffect(() => this.actions$.pipe(
      ofType(this.actions.openLocation),
      withLatestFrom(this.store.pipe(select(this.selectors.selectEntity))),
      switchMap(([_, location]) => this.service.reopenLocation(location.locationId).pipe(
        map(() => this.actions.openLocationSuccess()),
        catchError(error => of(this.actions.error({error, action: this.actions.openLocation})))
      ))
    ));

    this.openLocationSuccess$ = createEffect(() => this.actions$.pipe(
      ofType(this.actions.openLocationSuccess),
      tap(action => this.actionCallback(action, false))
    ), {dispatch: false});
  }

  protected actionCallback(action: Action, isError: boolean): void {
    super.actionCallback(action, isError);

    this.checkAction(this.actions.closeLocationSuccess, action, () => this.closeLocationSuccessActionCallback());
    this.checkAction(this.actions.openLocationSuccess, action, () => this.openLocationSuccessActionCallback());
  }

  protected closeLocationSuccessActionCallback(): void {
    this.apiNotificationService.showTranslatedSuccess('core-portal.master-data.location.descriptions.close-success');
  }

  protected openLocationSuccessActionCallback(): void {
    this.apiNotificationService.showTranslatedSuccess('core-portal.master-data.location.descriptions.open-success');
  }
}
