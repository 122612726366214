import {BaseXsStoreEffects} from '@nexnox-web/core-store';
import {createEffect, ofType} from '@ngrx/effects';
import {catchError, exhaustMap, groupBy, map, mergeMap, switchMap, withLatestFrom} from 'rxjs/operators';
import {resourceEntitiesStore} from '../resource-entities';
import {Injector} from '@angular/core';
import {ResourceUIEntitiesXsStoreActions, ResourceUIEntitiesXsStoreSelectors} from './resource-ui-entities.xs-store';
import {TechPortalFeatureResourceService} from '../../services';
import {ResourceSimpleDto} from '@nexnox-web/core-shared';
import {of} from 'rxjs';
import {select} from '@ngrx/store';

export abstract class ResourceUIEntitiesXsStoreEffects extends BaseXsStoreEffects {
  public upsertMany$: any;

  public getChildRoot$: any;
  public getChildRootSuccess$: any;

  public getChildNext$: any;
  public getChildNextSuccess$: any;

  protected service: TechPortalFeatureResourceService;

  protected constructor(
    protected injector: Injector,
    protected actions: ResourceUIEntitiesXsStoreActions,
    protected selectors: ResourceUIEntitiesXsStoreSelectors,
    createEffects: boolean = true
  ) {
    super(injector, actions, selectors, false);

    this.service = this.injector.get(TechPortalFeatureResourceService);

    if (createEffects) {
      this.createEffects();
    }
  }

  protected createEffects(): void {
    super.createEffects();

    this.upsertMany$ = createEffect(() => this.actions$.pipe(
      ofType(this.actions.upsertMany),
      map(({ items }) => resourceEntitiesStore.actions.addMany({ resources: items }))
    ));

    this.getChildRoot$ = createEffect(() => this.actions$.pipe(
      ofType(this.actions.getChildRoot),
      groupBy(({ parentId }) => parentId),
      mergeMap(group => group.pipe(
        switchMap(({ parentId }) => this.service.getChildRoot<ResourceSimpleDto>(parentId).pipe(
          map(({ items, paging, filterOptions }) => this.actions.getChildRootSuccess({
            parentId,
            items,
            paging,
            filters: filterOptions
          })),
          catchError(error => of(this.actions.error({ error, action: this.actions.getChildRoot })))
        ))
      ))
    ));

    this.getChildRootSuccess$ = createEffect(() => this.actions$.pipe(
      ofType(this.actions.getChildRootSuccess),
      map(({ items }) => this.actions.upsertMany({ items }))
    ));

    this.getChildNext$ = createEffect(() => this.actions$.pipe(
      ofType(this.actions.getChildNext),
      groupBy(({ parentId }) => parentId),
      mergeMap(group => group.pipe(
        mergeMap(({ parentId }) => of(null).pipe(
          withLatestFrom(this.store.pipe(select(this.selectors.selectPaging(parentId)))),
          exhaustMap(([_, uiEntityPaging]) =>
            this.service.getChildPage<ResourceSimpleDto>(parentId, uiEntityPaging.pageNumber + 1).pipe(
              map(({ items, paging, filterOptions }) => this.actions.getChildNextSuccess({
                parentId,
                items,
                paging,
                filters: filterOptions
              }))
            ))
        )),
        catchError(error => of(this.actions.error({ error, action: this.actions.getChildNext })))
      ))
    ));

    this.getChildNextSuccess$ = createEffect(() => this.actions$.pipe(
      ofType(this.actions.getChildNextSuccess),
      map(({ items }) => this.actions.upsertMany({ items }))
    ));
  }
}
