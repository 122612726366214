<div [id]="customProperty.propertyAnchorId">
  <p-panel styleClass="custom-property-panel">
    <ng-template pTemplate="header">
      <div class="d-flex flex-row flex-wrap w-100">
        <div class="d-flex flex-grow-1 align-items-center mw-100">
          <div *ngIf="showPositionBadge"
               [class.mr-2]="readonly"
               class="d-flex align-items-center flex-wrap"
          ><span class="text-ellipsis rounded-sm text-lg px-3 py-1 bg-primary"
          >{{ customProperty.position }}</span>
          </div>
          <nexnox-web-cardbox-actions [actions]="headerActions"
                                      [useNew]="true"
                                      [hasFlexContent]="true"
                                      [readonly]="readonly"
                                      class="w-100">
            <div class="w-100 align-content-center" [style.min-width.px]="50">
              <span *ngIf="readonly;"
                    [title]="customProperty.name"
                    class="text-ellipsis">
                {{ customProperty.name }}
              </span>

              <input #nameEditInput
                     pInputText
                     type="text"
                     class="form-control"
                     title=""
                     [required]="true"
                     [style.height.px]="29"
                     [style.display]="readonly ? 'none' : 'inline'"
                     [ngModel]="customProperty.name"
                     (ngModelChange)="!readonly ? onNameChange($event) : null">
            </div>
            <div *ngIf="customProperty.type" class="d-flex align-items-center flex-wrap text-nowrap mr-2">
            <span *ngIf="readonly"
                  style="color: var(--gray-500) !important" class="font-weight-bold text-xs"
            >{{ 'core-shared.shared.custom-property-types.' + customProperty.type | translate }}</span>
            <span *ngIf="!readonly"
                  class="text-ellipsis rounded-sm px-3 py-1 bg-primary"
            >{{ 'core-shared.shared.custom-property-types.' + customProperty.type | translate }}</span>
            </div>
          </nexnox-web-cardbox-actions>
        </div>
      </div>
    </ng-template>

    <form #formElement
          [formGroup]="form"
          [class.cursor-pointer]="showCursorPointer$ | async">
      <formly-form class="row"
                   [form]="form"
                   [fields]="fields"
                   [model]="customProperty"
                   (modelChange)="onCustomPropertyChange($event)">
      </formly-form>
    </form>
  </p-panel>
</div>
