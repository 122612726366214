import { Component, Input } from '@angular/core';
import { faSpinner } from '@fortawesome/free-solid-svg-icons/faSpinner';
import { SizeProp } from '@fortawesome/fontawesome-svg-core';

/* istanbul ignore next */
@Component({
  selector: 'nexnox-web-loading-icon',
  templateUrl: './loading-icon.component.html'
})
export class LoadingIconComponent {
  @Input() public size: SizeProp = '1x';

  public faSpinner = faSpinner;
}
