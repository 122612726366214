import {ChangeDetectionStrategy, Component, Injector, TemplateRef, ViewChild} from '@angular/core';
import {
  CorePortalEntityEditBaseComponent,
  CorePortalFormlyButtonSelectTyping,
  CorePortalFormlyTranslatedTyping,
  CorePortalFunctionService,
  CorePortalRoleService
} from '@nexnox-web/core-portal';
import {
  CorePortalFeatureMasterDataLocationGroupService
} from '@nexnox-web/core-portal/features/master-data/features/location-groups';
import {
  CorePortalFeatureMasterDataLocationService,
  noClosedLocationsFilter$
} from '@nexnox-web/core-portal/features/master-data/features/locations';
import {AreaAssignmentDto, AreaAssignmentReach} from '@nexnox-web/core-shared';
import {FormlyFieldConfig} from '@ngx-formly/core';

export const areaAssignmentReachEnumOptions = [
  {
    title: 'core-portal.master-data.contact.area-assignment-reach-types.0.title',
    label: 'core-portal.master-data.contact.area-assignment-reach-types.0.label',
    value: AreaAssignmentReach.Everything
  },
  {
    title: 'core-portal.master-data.contact.area-assignment-reach-types.1.title',
    label: 'core-portal.master-data.contact.area-assignment-reach-types.1.label',
    value: AreaAssignmentReach.Limited | AreaAssignmentReach.Persona
  },
  {
    title: 'core-portal.master-data.contact.area-assignment-reach-types.2.title',
    label: 'core-portal.master-data.contact.area-assignment-reach-types.2.label',
    value: AreaAssignmentReach.Persona
  }
];

@Component({
  selector: 'nexnox-web-master-data-contacts-contact-area-edit',
  templateUrl: './contact-area-edit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContactAreaEditComponent extends CorePortalEntityEditBaseComponent<AreaAssignmentDto> {
  @ViewChild('locationSelectLabelTitleTemplate', { static: true }) public locationSelectLabelTitleTemplate: TemplateRef<any>;
  @ViewChild('locationSelectOptionTitleTemplate', { static: true }) public locationSelectOptionTitleTemplate: TemplateRef<any>;

  constructor(
    protected injector: Injector,
    private locationService: CorePortalFeatureMasterDataLocationService,
    private locationGroupService: CorePortalFeatureMasterDataLocationGroupService,
    private roleService: CorePortalRoleService,
    private functionService: CorePortalFunctionService
  ) {
    super(injector);
  }

  protected createForm(): FormlyFieldConfig[] {
    return [
      /* Reach */
      {
        key: 'reach',
        type: 'core-portal-button-select',
        wrappers: ['core-portal-translated'],
        className: 'col-md-12',
        defaultValue: AreaAssignmentReach.Everything,
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.reach',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          } as CorePortalFormlyTranslatedTyping,
          corePortalButtonSelect: {
            items: areaAssignmentReachEnumOptions,
            translate: true,
            large: true
          } as CorePortalFormlyButtonSelectTyping
        },
        expressionProperties: {
          'templateOptions.required': () => !this.readonly,
          'templateOptions.disabled': () => this.readonly
        }
      },
      {
        key: 'locations',
        type: 'core-portal-entity-select',
        wrappers: ['core-portal-translated'],
        className: 'col-md-6',
        defaultValue: null,
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.locations'
          },
          entityService: this.locationService,
          idKey: 'locationId',
          displayKey: 'name',
          wholeObject: true,
          multiple: true,
          mapSearchFilter: CorePortalFeatureMasterDataLocationService.mapSearchFilter,
          search: CorePortalFeatureMasterDataLocationService.searchCompare,
          selectLabelTitleTemplate: this.locationSelectLabelTitleTemplate,
          selectOptionTitleTemplate: this.locationSelectOptionTitleTemplate,
          showAll: true,
          defaultFilters$: noClosedLocationsFilter$
        },
        expressionProperties: {
          'templateOptions.disabled': () => this.readonly
        },
        hideExpression: (model: AreaAssignmentDto) => !(model.reach & AreaAssignmentReach.Limited)
      },
      {
        key: 'locationGroups',
        type: 'core-portal-entity-select',
        wrappers: ['core-portal-translated'],
        className: 'col-md-6',
        defaultValue: null,
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.location-groups'
          },
          entityService: this.locationGroupService,
          idKey: 'locationGroupId',
          displayKey: 'name',
          wholeObject: true,
          multiple: true,
          showAll: true
        },
        expressionProperties: {
          'templateOptions.disabled': () => this.readonly
        },
        hideExpression: (model: AreaAssignmentDto) => !(model.reach & AreaAssignmentReach.Limited)
      },

      /* Roles */
      {
        key: 'roles',
        type: 'core-portal-entity-select',
        wrappers: ['core-portal-translated'],
        className: 'col-md-12',
        defaultValue: null,
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.roles'
          },
          entityService: this.roleService,
          idKey: 'appRoleId',
          displayKey: 'title',
          wholeObject: true,
          multiple: true,
          showAll: true
        },
        expressionProperties: {
          'templateOptions.disabled': () => this.readonly
        }
      },

      /* Functions */
      {
        key: 'functions',
        type: 'core-portal-entity-select',
        wrappers: ['core-portal-translated'],
        className: 'col-md-12',
        defaultValue: null,
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.functions'
          },
          entityService: this.functionService,
          idKey: 'functionId',
          displayKey: 'name',
          wholeObject: true,
          multiple: true,
          showAll: true
        },
        expressionProperties: {
          'templateOptions.disabled': () => this.readonly
        }
      }
    ];
  }
}
