import { Injectable } from '@angular/core';
import { Toast, ToasterService } from 'angular2-toaster';
import { TranslateService } from '@ngx-translate/core';
import { HttpErrorResponse } from '@angular/common/http';
import { cloneDeep, isArray, isUndefined } from 'lodash';
import { RequestError, RequestErrorWrapper } from '../../models';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class ApiNotificationService {
  private errorToastsSubject: BehaviorSubject<Toast[]> = new BehaviorSubject<Toast[]>([]);

  constructor(
    private toasterService: ToasterService,
    private translate: TranslateService
  ) {
  }

  public handleApiError(error: HttpErrorResponse | Error | string): void {
    if (typeof error === 'string') {
      this.showError(error);
      return;
    } else if (error instanceof Error) {
      if (error.name === 'TimeoutError') {
        this.showTranslatedError('core-portal.core.error.timeout');
        return;
      }

      this.showError(error.message);
      return;
    }

    switch (error.status) {
      case 400:
        this.showRequestError(error);
        break;
      case 403:
        this.showRequestError(error);
        break;
      case 503:
        this.showUnavailableError(error);
        break;
      case 0:
        this.showConnectionError();
        break;
      default:
        this.showUnknownError(error);
        break;
    }
  }

  public showTranslatedSuccess(key: string, params?: any): void {
    this.toasterService.pop('success', this.translate.instant(key, params));
  }

  public showTranslatedError(key: string, buttonKey?: string, fn?: (toast: Toast) => void): void {
    this.showError(this.translate.instant(key), buttonKey ? this.translate.instant(buttonKey) : undefined, fn);
  }

  public showError(message: string, button?: string, fn?: (toast: Toast) => void): void {
    const toasts = cloneDeep(this.errorToastsSubject.getValue());
    const foundToastIndex = toasts.findIndex(x => x?.title === message);

    if (foundToastIndex > -1) {
      this.toasterService.clear(toasts[foundToastIndex].toastId);
      toasts.splice(foundToastIndex, 1);
    }

    toasts.push(this.toasterService.pop({
      type: 'error',
      title: message,
      showCloseButton: Boolean(button),
      closeHtml: button ? `<button class="btn btn-outline-danger">${button}</button>` : undefined,
      onHideCallback: toast => {
        const innerToasts = cloneDeep(this.errorToastsSubject.getValue());
        const innerFoundToastIndex = innerToasts.findIndex(x => x?.title === message);

        if (innerFoundToastIndex > -1) {
          innerToasts.splice(innerFoundToastIndex, 1);
        }

        this.errorToastsSubject.next(innerToasts);
        return fn ? fn(toast) : undefined;
      }
    }));
    this.errorToastsSubject.next(toasts);
  }

  private showRequestError(errorResponse: HttpErrorResponse): void {
    const error: RequestErrorWrapper | RequestError[] = errorResponse.error;

    if (this.isRequestErrorWrapper(error)) {
      for (const innerError of error.Errors) {
        this.showError(innerError.Description);
      }
    } else if (error && isArray(error) && error.length) {
      for (const innerError of error) {
        this.showError(innerError.Description);
      }
    } else if (errorResponse.status === 403) {
      this.showTranslatedError('core-portal.core.error.no-access');
    } else {
      this.showUnknownError(errorResponse);
    }
  }

  private showUnavailableError(errorResponse: HttpErrorResponse): void {
    this.showTranslatedError('core-portal.core.error.unavailable');
  }

  private showConnectionError(): void {
    this.showTranslatedError('core-portal.core.error.connection');
  }

  private showUnknownError(errorResponse: HttpErrorResponse): void {
    const error: RequestErrorWrapper = errorResponse.error;

    if (this.isRequestErrorWrapper(error)) {
      for (const innerError of error.Errors) {
        this.showError(innerError.Description);
      }
    } else {
      this.showTranslatedError('core-portal.core.error.unknown');
    }
  }

  private isRequestErrorWrapper(error: any): error is RequestErrorWrapper {
    return !isUndefined((error as RequestErrorWrapper)?.TraceId);
  }
}
