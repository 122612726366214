import {ChangeDetectionStrategy, Component, Injector, Input, OnInit} from '@angular/core';
import {
  CorePortalEntityEditBaseComponent,
  CorePortalFormlyNgSelectTyping,
  CorePortalFormlyReadonlyTypes,
  CorePortalFormlyReadonlyTyping
} from '@nexnox-web/core-portal';
import {AddressSimpleDto, MissionDto} from '@nexnox-web/core-shared';
import {FormlyFieldConfig} from '@ngx-formly/core';
import {Observable, of} from 'rxjs';
import {delay, distinctUntilChanged, map, skip} from 'rxjs/operators';
import {
  CorePortalFeatureMasterDataAddressService
} from '@nexnox-web/core-portal/features/master-data/features/addresses';
import {isEqual} from 'lodash';

@Component({
  selector: 'nexnox-web-missions-mission-addresses-edit',
  templateUrl: './mission-addresses-edit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MissionAddressesEditComponent extends CorePortalEntityEditBaseComponent<MissionDto> implements OnInit {

  @Input() public allowedAddresses$: Observable<AddressSimpleDto[]> = of([]);

  private addressesEnumOptions$: Observable<any[]>;

  constructor(
    protected injector: Injector,
    private addressService: CorePortalFeatureMasterDataAddressService
  ) {
    super(injector);
  }

  /* istanbul ignore next */
  public ngOnInit(): void {
    this.addressesEnumOptions$ = this.allowedAddresses$.pipe(
      map(allowedAddresses => allowedAddresses.map(allowedAddress => {
        let secondPart = allowedAddress.city;

        if (allowedAddress.zipcode) {
          secondPart = `${allowedAddress.zipcode} ${secondPart}`;
        }

        return {
          label: `${allowedAddress.street} ${allowedAddress.houseNumber}, ${secondPart}`,
          value: allowedAddress
        };
      }))
    );

    this.subscribe(this.addressesEnumOptions$.pipe(
      distinctUntilChanged((a: { value: AddressSimpleDto }[], b: { value: AddressSimpleDto }[]) =>
        isEqual(a.map(x => x.value.addressId), b.map(x => x.value.addressId))),
      skip(2),
      delay(1)
    ), () => this.onModelChange(this.model));

    super.ngOnInit();
  }

  /* istanbul ignore next */
  protected createForm(): FormlyFieldConfig[] {
    return [
      {
        key: 'invoiceAddress',
        type: 'core-portal-ng-select',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-12',
        defaultValue: this.model?.invoiceAddress,
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.invoice-address'
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.ENUM,
            enumOptions: this.addressesEnumOptions$,
            compareFn: (a: AddressSimpleDto, b: AddressSimpleDto) => a?.addressId === b?.addressId
          } as CorePortalFormlyReadonlyTyping,
          corePortalNgSelect: {
            items$: this.addressesEnumOptions$,
            compareFn: (a: AddressSimpleDto, b: AddressSimpleDto) => a?.addressId === b?.addressId
          } as CorePortalFormlyNgSelectTyping
        },
        expressionProperties: {
          'templateOptions.disabled': () => this.readonly,
          'templateOptions.readonly': () => this.readonly
        }
      },
      {
        key: 'shippingAddress',
        type: 'core-portal-ng-select',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-12',
        defaultValue: this.model?.shippingAddress,
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.shipping-address'
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.ENUM,
            enumOptions: this.addressesEnumOptions$,
            compareFn: (a: AddressSimpleDto, b: AddressSimpleDto) => a?.addressId === b?.addressId
          } as CorePortalFormlyReadonlyTyping,
          corePortalNgSelect: {
            items$: this.addressesEnumOptions$,
            compareFn: (a: AddressSimpleDto, b: AddressSimpleDto) => a?.addressId === b?.addressId
          } as CorePortalFormlyNgSelectTyping
        },
        expressionProperties: {
          'templateOptions.disabled': () => this.readonly,
          'templateOptions.readonly': () => this.readonly
        }
      }
    ];
  }
}
