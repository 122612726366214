<ng-template #entityEditTemplate let-title="title">
  <nexnox-web-resources-resource-edit #editComponent
                                      [title]="title"
                                      [model]="createModel$ | async"
                                      [readonly]="false"
                                      [creating]="true"
                                      [loading]="loading$ | async"
                                      [stereotypes$]="stereotypes$"
                                      [stereotyped]="true"
                                      (modelChange)="createModel$.next($event)">
  </nexnox-web-resources-resource-edit>
</ng-template>

<nexnox-web-entity-overview #overviewComponent
                            [entityOverviewBaseComponent]="this"
                            [entityEditTemplate]="entityEditTemplate"
                            detailLink="/resources"
                            module="inventory"
                            [disableSavedFilters]="true"
                            [disableSavedSortObject]="true">
</nexnox-web-entity-overview>
