import { NgModule } from '@angular/core';
import { CoreSharedModule, CoreSharedSortableListModule } from '@nexnox-web/core-shared';
import { CorePortalEntityModule } from '@nexnox-web/core-portal';
import { cancellationReasonsServices } from './store';
import { OrgaPortalFeatureCancellationReasonsEditComponent } from './components';
import { CancellationReasonsDetailComponent, CancellationReasonsListComponent } from './containers';

@NgModule({
  declarations: [
    OrgaPortalFeatureCancellationReasonsEditComponent,

    CancellationReasonsListComponent,
    CancellationReasonsDetailComponent
  ],
  imports: [
    CoreSharedModule,
    CorePortalEntityModule,
    CoreSharedSortableListModule
  ],
  exports: [
    OrgaPortalFeatureCancellationReasonsEditComponent
  ],
  providers: [
    ...cancellationReasonsServices
  ]
})
export class CancellationReasonsDeclarationsModule {
}
