import {Injectable, Injector} from '@angular/core';
import {CoreSharedApiBaseService} from '@nexnox-web/core-shared';
import {map} from "rxjs/operators";
import {Observable} from "rxjs";

@Injectable()
export class LocationAddressDetailService extends CoreSharedApiBaseService {
  constructor(
    protected injector: Injector
  ) {
    super(injector, null, 'address');
  }

  public createOne<T>(data: T, parentIds?: Array<number | string>): Observable<T> {
    return this.httpRequestService.post<T>(`location/${parentIds[0]}/address`, data).pipe(
      map(response => response.body)
    );
  }
}
