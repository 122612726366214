<ng-template #entityEditTemplate let-title="title" let-id="id">
  <nexnox-web-resource-tasks-resource-task-edit #modelComponent
                                                [title]="title"
                                                [id]="id"
                                                [model]="model$ | async"
                                                [readonly]="readonly$ | async"
                                                [creating]="false"
                                                [loading]="loading$ | async"
                                                (modelChange)="onModelChange($event)">
  </nexnox-web-resource-tasks-resource-task-edit>
</ng-template>

<nexnox-web-entity-detail [entityDetailBaseComponent]="this"
                          [entityEditTemplate]="entityEditTemplate"
                          tabId="resourceTask"
                          [tabHeading]="title | translate"
                          [tabCommands]="['.']">
  <!-- Resource Selection -->
  <ng-template nexnoxWebTabTemplate
               tabId="resourceSelection"
               [heading]="'resources.subtitles.resource-list' | translate"
               [hasError]="isNoResourceFilter$ | async"
               (selectTab)="onNavigateToTab(['selection'])">
    <nexnox-web-resource-tasks-resource-selection-edit *ngIf="model$ | async as model"
                                                       [selector]="model?.selector"
                                                       [isEmbedded]="true"
                                                       [canCreate]="false"
                                                       [canRouteToDetail]="false"
                                                       [readonly]="readonly$ | async"
                                                       [loading]="loading$ | async"
                                                       (selectorChange)="onSelectorChange($event, model)">
    </nexnox-web-resource-tasks-resource-selection-edit>
  </ng-template>

  <!-- Missions -->
  <ng-template nexnoxWebTabTemplate
               tabId="missions"
               *ngIf="canDisplayMissions$ | async"
               [heading]="'missions.subtitles.missions' | translate"
               [lazy]="true"
               (selectTab)="onNavigateToTab(['missions'])">
    <nexnox-web-resource-task-missions *ngIf="passedIds"
                                       [isEmbedded]="true"
                                       [canCreate]="false"
                                       [parentIds]="passedIds"
                                       [getEntityOnModeChange]="false">
    </nexnox-web-resource-task-missions>
  </ng-template>
</nexnox-web-entity-detail>
