import {CorePortalGdprEntryService, CorePortalGdprService} from './services';
import {
  GdprDetailStoreEffects,
  gdprDetailStoreReducer,
  GdprEntryListStoreEffects, gdprEntryListStoreReducer,
  GdprListStoreEffects,
  gdprListStoreReducer
} from './stores';

export * from './services';
export * from './stores';

export * from './gdpr.state';
export * from './gdpr.selectors';

export const gdprReducers = {
  gdprList: gdprListStoreReducer,
  gdprDetail: gdprDetailStoreReducer,
  gdprEntryList: gdprEntryListStoreReducer
};

export const gdprEffects = [
  GdprListStoreEffects,
  GdprDetailStoreEffects,
  GdprEntryListStoreEffects
];

export const gdprServices = [
  CorePortalGdprService,
  CorePortalGdprEntryService
];
