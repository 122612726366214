import {
  BaseXsStore,
  createEntityXsStoreActions,
  EmptyAction,
  EntityXsStoreActions,
  PropsAction
} from '@nexnox-web/core-store';
import {ClosingLocationOptionsDto, LocationDto} from '@nexnox-web/core-shared';
import {createAction, props} from '@ngrx/store';

export interface LocationDetailXsStoreActions extends EntityXsStoreActions<LocationDto> {
  closeLocation: PropsAction<ClosingLocationOptionsDto>;
  closeLocationSuccess: EmptyAction;

  openLocation: EmptyAction;
  openLocationSuccess: EmptyAction;
}

export const createLocationDetailXsStoreActions = (label: string): LocationDetailXsStoreActions => ({
  ...createEntityXsStoreActions(label),

  closeLocation: createAction(BaseXsStore.getType(label, 'Close location'), props<ClosingLocationOptionsDto>()),
  closeLocationSuccess: createAction(BaseXsStore.getType(label, 'Close location success')),

  openLocation: createAction(BaseXsStore.getType(label, 'Open location')),
  openLocationSuccess: createAction(BaseXsStore.getType(label, 'Open location success')),
});
