import { InjectionToken } from '@angular/core';
import { AppEntityType, AppFunctions } from '@nexnox-web/core-shared';

export interface CorePortalRolesConfig {
  type: AppEntityType | string;
  label: string;
  permissions: { label: string, value: AppFunctions, force?: boolean }[];
}

export const CORE_PORTAL_ROLES_CONFIG = new InjectionToken<CorePortalRolesConfig[]>('@nexnox-web/core-portal: Roles Config');
