import { ChangeDetectionStrategy, Component, Injector } from '@angular/core';
import { ActionButton, CorePortalEntityDetailBaseComponent, CorePortalPermissionService } from '@nexnox-web/core-portal';
import { AddressDto, AppPermissions } from '@nexnox-web/core-shared';
import { addressDetailStore } from '../../store/stores';
import { Observable } from 'rxjs';

@Component({
  selector: 'nexnox-web-master-data-addresses-address-detail',
  templateUrl: './address-detail.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddressDetailComponent extends CorePortalEntityDetailBaseComponent<AddressDto> {
  public title = 'core-portal.master-data.subtitles.address';

  public readLocationsPermission$: Observable<boolean>;
  public readCompaniesPermission$: Observable<boolean>;

  protected idParam = 'addressId';
  protected displayKey = 'addressId';

  constructor(
    protected injector: Injector,
    private permissionService: CorePortalPermissionService
  ) {
    super(injector, addressDetailStore);

    this.readLocationsPermission$ = this.permissionService.hasPermission$(AppPermissions.ReadLocation);
    this.readCompaniesPermission$ = this.permissionService.hasPermission$(AppPermissions.ReadCompany);
  }

  protected async getActionButtons(): Promise<ActionButton[]> {
    return this.getDefaultActionButtons(
      'core-portal.master-data.actions.edit-address',
      'core-portal.master-data.actions.save-address',
      'core-portal.master-data.actions.delete-address',
      'core-portal.master-data.descriptions.delete-address',
      AppPermissions.UpdateAddress,
      AppPermissions.DeleteAddress,
      ['masterdata/addresses']
    );
  }
}
