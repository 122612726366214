import {Inject, Injectable, Optional} from '@angular/core';
import {
  AppFunctions,
  AppPermissions,
  CORE_SHARED_ENVIRONMENT,
  CoreSharedLocalStorageService,
  Environment,
  PermissionPackageDto,
  RoleGeneralTerms
} from '@nexnox-web/core-shared';
import {combineLatest, firstValueFrom, Observable, of, throwError} from 'rxjs';
import {catchError, filter, map, take, timeoutWith} from 'rxjs/operators';
import {select, Store} from '@ngrx/store';
import {authStore} from '../../store/core/auth/auth.store';
import {JwtHelperService} from '@auth0/angular-jwt';
import {flatten, uniqBy} from 'lodash';
import {InvalidTokenError} from '../../errors';
import {packagesStore} from '../../store/core/packages';
import {CORE_PORTAL_EXCLUDED_ROLE_TERMS} from '../../tokens';

const jwtHelper = new JwtHelperService();

@Injectable()
export class CorePortalPermissionService {
  constructor(
    private store: Store<any>,
    private localStorage: CoreSharedLocalStorageService,
    @Inject(CORE_SHARED_ENVIRONMENT) private environment: Environment,
    @Inject(CORE_PORTAL_EXCLUDED_ROLE_TERMS) @Optional() private excludedRoleTerms: RoleGeneralTerms[] = []
  ) {
  }

  public async hasPermission(permission: AppPermissions): Promise<boolean> {
    return await firstValueFrom(this.hasPermission$(permission));
  }

  public hasPermission$(permission: AppPermissions): Observable<boolean> {
    return this.store.select(authStore.selectors.selectHasPermission, {permission});
  }

  public hasPermissions$(permissions: AppPermissions[]): Observable<boolean> {
    const hasPermissions$ = permissions.map(p => this.hasPermission$(p));
    return combineLatest(hasPermissions$).pipe(map(hasPermissions => hasPermissions.some(p => p === true)))
  }

  public flattenTokenFunctions(accessToken: string): AppFunctions[] {
    try {
      const decodedToken: any = jwtHelper.decodeToken(accessToken);
      const permissions = JSON.parse(decodedToken.permissions);

      return flatten(permissions.map(permission => permission.permissions));
    } catch (_) {
      throw new InvalidTokenError();
    }
  }

  public mergePermissions(permissionPackages: PermissionPackageDto[], functions: AppFunctions[]): AppPermissions[] {
    return uniqBy(
      flatten(
        permissionPackages
          .filter(permissionPackage => functions.includes(permissionPackage.package))
          .map(permissionPackage => permissionPackage.permissions)
      ),
      permission => permission
    );
  }

  public checkAndMergeTokenPermissions(token: string): Observable<AppPermissions[]> {
    return this.selectPackagesOrTimeout().pipe(
      map(packages => {
        const flattenedFunctions = this.flattenTokenFunctions(token);
        return this.mergePermissions(packages, flattenedFunctions);
      }),
      catchError(error => throwError(error))
    );
  }

  public selectPackagesOrTimeout(): Observable<PermissionPackageDto[]> {
    return this.store.pipe(select(packagesStore.selectors.selectPackages)).pipe(
      filter(packages => Boolean(packages?.length)),
      timeoutWith(this.environment.defaultTimeout, of([])),
      take(1)
    );
  }
}
