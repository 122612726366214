<p-sidebar #sidebarComponent
           position="right"
           appendTo="body"
           styleClass="p-sidebar-lg"
           [transitionOptions]="transitionOptions"
           [visible]="visible$ | async"
           (visibleChange)="onVisibleChange($event)">
  <h4>{{ 'missions.actions.add-successors' | translate }}</h4>

  <div class="d-flex flex-grow-1 flex-column h-100 mt-1">
    <nexnox-web-rule-editor-list #successorsEditComponent
                                 [items]="successors$ | async"
                                 [title]="titleFn"
                                 [inheritedFieldsFn]="fieldsFn"
                                 [disabled]="false"
                                 (itemsChange)="onSuccessorsChange($event)">
    </nexnox-web-rule-editor-list>
  </div>

  <div class="d-flex flex-shrink-0 justify-content-end pt-3">
    <button type="button"
            pButton
            [icon]="faPlus | nexnoxWebFaIconStringPipe"
            [label]="'missions.actions.add-successors' | translate"
            class="p-button-primary ml-2"
            [disabled]="(isValid$ | async) === false"
            (click)="onAddSuccessors()">
    </button>
  </div>
</p-sidebar>
