<!-- Inheritance -->
<nexnox-web-cardbox *ngIf="hasParent$ | async" [title]="'resources.subtitles.heredity' | translate">
  <form [formGroup]="form">
    <formly-form class="row w-100"
                 [form]="form"
                 [(model)]="model"
                 [fields]="fields">
    </formly-form>
  </form>
</nexnox-web-cardbox>

<!-- Primary table -->
<nexnox-web-cardbox
  [title]="('resources.subtitles.primary-editor-suggestions' | translate) +
           (model?.isInheritSuggestions && this.hasParent ? ' (' + ('resources.subtitles.suggestion-inherited-by' | translate:{parent:parentResourceName}) +')' : '')"
  [loading]="loading"
  [headerActions]="createPrimaryActions()"
  paddingClasses="p-0"
  ghostPaddingClasses="p-3"
  noDividerMarginBottom="true">
  <nexnox-web-basic-table [config]="primaryBasicTableConfig"
                          [data]="primarySuggestionsSubject | async">
  </nexnox-web-basic-table>
</nexnox-web-cardbox>

<!-- Alternative table -->
<nexnox-web-cardbox [title]="('resources.subtitles.alternative-editor-suggestions' | translate) +
                             (model?.isInheritSuggestions && this.hasParent ? ' (' + ('resources.subtitles.suggestion-inherited-by' | translate:{parent:parentResourceName}) +')' : '')"
                    [loading]="loading"
                    [headerActions]="createAlternativeActions()"
                    paddingClasses="p-0"
                    ghostPaddingClasses="p-3"
                    noDividerMarginBottom="true">
  <nexnox-web-basic-table [config]="alternativeBasicTableConfig"
                          [data]="alternativeSuggestionsSubject | async">
  </nexnox-web-basic-table>
</nexnox-web-cardbox>

<!-- Sidebar -->
<nexnox-web-resources-suggestion-edit-sidebar #suggestionEditSidebarComponent
                                              (suggestionSaved)="onSuggestionSaved($event)">
</nexnox-web-resources-suggestion-edit-sidebar>
