import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {
  AppPermissions,
  AreaAssignmentDto,
  AreaAssignmentReach,
  BasicTableColumnType,
  BasicTableConfig,
  CombineOperator,
  ContactSimpleDto,
  CoreSharedSidebarBaseComponent,
  Filter,
  FilterDtoWithLabel,
  FilterKind,
  FilterOperators,
  FilterTypes,
  FunctionSimpleDto,
  Paging,
  RoleSimpleDto
} from '@nexnox-web/core-shared';
import {CorePortalContactService, CorePortalPermissionService} from "@nexnox-web/core-portal";
import {faCheck, faTimes} from '@fortawesome/free-solid-svg-icons';
import {BehaviorSubject, firstValueFrom, Observable} from "rxjs";
import {TranslateService} from "@ngx-translate/core";
import {cloneDeep} from "lodash";
import {take} from "rxjs/operators";

@Component({
  selector: 'nexnox-web-master-data-contacts-bulk-area-assignment-sidebar',
  templateUrl: './bulk-area-assignment-sidebar.component.html',
  styleUrls: ['./bulk-area-assignment-sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BulkAreaAssignmentSidebarComponent extends CoreSharedSidebarBaseComponent implements OnInit {
  @Input() public contactCount: number;
  @Input() public filters: any[];

  @Output() public accept: EventEmitter<AreaAssignmentDto> = new EventEmitter<AreaAssignmentDto>();

  public model: any;
  public contactListTableConfig: BasicTableConfig;
  public searchValue = '';
  public currentPage = 1;
  public currentSearchPage = 1;
  public currentTab: string;
  public tabs: string[] = [
    'core-portal.core.header.breadcrumbs.master-data.contact-area',
    'core-portal.master-data.contact.tooltip.selected-contacts-list'
  ];

  public contacts$: BehaviorSubject<ContactSimpleDto[]> = new BehaviorSubject<ContactSimpleDto[]>([]);
  public paging$: BehaviorSubject<Paging> = new BehaviorSubject<Paging>(null);
  public appendLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  public loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public canAssign$: Observable<boolean>;

  public faTimes = faTimes;
  public faCheck = faCheck;

  private localModel: AreaAssignmentDto;

  constructor(
    private contactService: CorePortalContactService,
    private permissionService: CorePortalPermissionService,
    private translate: TranslateService
  ) {
    super();

    this.canAssign$ = this.permissionService.hasPermission$(AppPermissions.CreateAreaAssignment);
    this.createContactListTableConfig();
  }

  public get description$(): Observable<string> {
    return this.translate?.get(
      'core-portal.master-data.contact.descriptions.bulk-create-area-assignments',
      {contactCount: this.contactCount}
    );
  }

  public translatedTab(tab: string): string {
    return this.translate.instant(tab, {contactCount: this.contactCount});
  }

  public ngOnInit(): void {
    this.resetAll();
  }

  public changeTab(tab: string): void {
    this.currentTab = tab;
    if (tab === this.tabs[1] && !this.paging$.getValue()) {
      this.getStoreData().then();
    }
  }

  public loadMore(): void {
    this.getStoreData(true).then();
  }

  public onShow(): void {
    super.onShow();
    this.ngOnInit();
    this.loading$.next(false);
  }

  public onHide(): void {
    this.loading$.next(false);
    super.onHide();
  }

  public onAccept(): void {
    this.loading$.next(true);
    this.accept.emit(this.localModel);
    this.onHide();
  }

  public onFilterMenu(): void {
    this.searchValue === '' ? this.currentPage = 1 : this.currentSearchPage = 1;
    this.getStoreData().then();
    this.loading$.next(false);
  }

  public onModelChange(model: any): void {
    this.model = cloneDeep(model);
    this.localModel = cloneDeep(model);
    this.mapLocalModel();
  }

  private async getStoreData(increasePage: boolean = false): Promise<any> {
    this.appendLoading$.next(true);
    let pageNumber: number;
    let filters: any[];
    if (this.searchValue !== '') {
      pageNumber = increasePage ? ++this.currentSearchPage : this.currentSearchPage;
      filters = this.applySearchFilters();
    } else {
      pageNumber = increasePage ? ++this.currentPage : this.currentPage;
      filters = cloneDeep(this.filters)
    }
    const pageData = await firstValueFrom(
      this.contactService.getPage(undefined, pageNumber, filters, undefined, undefined, undefined, 15)
        .pipe(take(1))
    );
    this.paging$.next(cloneDeep(pageData?.paging));
    const contacts: ContactSimpleDto[] = [
      ...cloneDeep(increasePage ? this.contacts$.getValue() : []),
      ...cloneDeep(pageData?.items)
    ];
    this.contacts$.next(contacts);
    this.appendLoading$.next(false);
    return contacts;
  }

  private mapLocalModel(): void {
    const {roles, functions} = cloneDeep(this.model);

    this.localModel = {
      ...cloneDeep(this.model),
      roles: roles?.map(({appRoleId, title}): RoleSimpleDto => ({
        id: appRoleId,
        name: title
      })),
      functions: functions?.map(({externalId, name, functionId}): FunctionSimpleDto => ({
        externalId,
        functionId,
        name
      }))
    };
  }

  private createContactListTableConfig(): void {
    this.contactListTableConfig = {
      columns: [
        {
          header: 'core-shared.shared.fields.display-name',
          key: 'displayName',
          type: BasicTableColumnType.Link,
          link: (row) => row?.contactId ? {
            commands: ['masterdata', 'contacts', row.contactId],
            module: 'management'
          } : null
        },
        {
          header: 'core-shared.shared.fields.email-address',
          key: 'emailAddress',
          type: BasicTableColumnType.Text
        },
      ],
      actions: []
    };
  }

  private applySearchFilters(): Filter[] {
    return [{
      type: FilterTypes.Grouped,
      combinedAs: CombineOperator.Or,
      children: [
        {
          type: FilterTypes.Grouped,
          combinedAs: CombineOperator.And,
          children: [
            ...cloneDeep(this.filters),
            {
              kind: FilterKind.Default,
              property: 'displayName',
              value: this.searchValue,
              operator: FilterOperators.Contains,
              type: FilterTypes.DataTransferObject
            }
          ]
        } as FilterDtoWithLabel,
        {
          type: FilterTypes.Grouped,
          combinedAs: CombineOperator.And,
          children: [
            ...cloneDeep(this.filters),
            {
              kind: FilterKind.Default,
              property: 'user.email',
              value: this.searchValue,
              operator: FilterOperators.Contains,
              type: FilterTypes.DataTransferObject
            }
          ]
        } as FilterDtoWithLabel
      ]
    }];
  }

  private resetAll(): void {
    this.currentTab = this.tabs[0];
    this.paging$.next(null);
    this.contacts$.next([]);
    this.currentPage = 1;
    this.currentSearchPage = 1;
    this.searchValue = '';
    this.localModel = {
      reach: AreaAssignmentReach.Everything,
      locations: undefined,
      locationGroups: undefined,
      roles: undefined,
      functions: undefined
    }
    this.model = cloneDeep(this.localModel);
  }
}
