import {CorePortalCardboxAction, CorePortalPermissionService, CorePortalTenantRouter} from "@nexnox-web/core-portal";
import {AppPermissions} from "@nexnox-web/core-shared";
import {BehaviorSubject, combineLatest, forkJoin, Observable, of} from "rxjs";
import {distinctUntilChanged, map, switchMap, take} from "rxjs/operators";
import {MenuItem} from "primeng/api";
import {isNull} from "lodash";
import {faBars} from "@fortawesome/free-solid-svg-icons/faBars";
import {TranslateService} from "@ngx-translate/core";
import {MissionTableModes} from "@nexnox-web/tech-portal/features/missions/src/lib/containers";


export function getCardBoxHeaderActions(mode: BehaviorSubject<MissionTableModes>, permissionService: CorePortalPermissionService, translateService: TranslateService, tenantRouter: CorePortalTenantRouter): CorePortalCardboxAction[] {
	return [
		{
			icon: faBars,
			class: 'p-button-secondary p-button-text',
			buttonSize: 'lg',
			permission: undefined,
			shouldShow: (data) => forkJoin(
				permissionService.hasPermission$(AppPermissions.DisplayMissionPlan).pipe(take(1)),
				permissionService.hasPermission$(AppPermissions.DisplayMissionMap).pipe(take(1))
			).pipe(map(permissions => permissions.findIndex(p => p === false) > -1 ? false : true), take(1)),

			dropdownItems:
				() => combineLatest([
					of(null),
					translateService.stream('missions.actions.switch-table-view').pipe(
						distinctUntilChanged(),
						switchMap(label => permissionService.hasPermission$(AppPermissions.DisplayMissions).pipe(
							map(hasPermission => ({
								label,
								visible: hasPermission,
								command: () => {
									mode.next(MissionTableModes.LIST);
								}
							}))
						))
					),
					translateService.stream('missions.actions.switch-map-view').pipe(
						distinctUntilChanged(),
						switchMap(label => permissionService.hasPermission$(AppPermissions.DisplayMissionMap).pipe(
							map(hasPermission => ({
								label,
								visible: hasPermission,
								command: () => {
									mode.next(MissionTableModes.MAP);
								}
							}))
						))
					),
					translateService.stream('missions.actions.switch-plan-view').pipe(
						distinctUntilChanged(),
						switchMap(label => permissionService.hasPermission$(AppPermissions.DisplayMissionPlan).pipe(
							map(hasPermission => ({
								label,
								visible: hasPermission,
								command: () => {
									mode.next(MissionTableModes.GANTT);
								}
							}))
						))
					)
				] as Observable<MenuItem>[]).pipe(
					map(items => items.filter(x => !isNull(x)))
				)
		}
	];
}
