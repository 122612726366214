import {ChangeDetectionStrategy, Component, OnDestroy, OnInit} from '@angular/core';
import {ResizeEvent} from 'angular-resizable-element';
import {UnsubscribeHelper} from '@nexnox-web/core-shared';
import {Store} from '@ngrx/store';
import {headerStore} from '@nexnox-web/core-portal';
import {take} from 'rxjs/operators';
import {resourceDetailStore, resourceTreeViewStore} from '../../store/stores';
import {ResourceDetailComponent} from '../resource-detail/resource-detail.component';
import {TechPortalFeatureResourceListComponent} from "../resource-list/resource-list.component";
import {firstValueFrom, Subscription} from "rxjs";

@Component({
  selector: 'nexnox-web-resources',
  templateUrl: './resources.component.html',
  styleUrls: ['./resources.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ResourcesComponent extends UnsubscribeHelper implements OnInit, OnDestroy {

  public outletComponent: ResourceDetailComponent | TechPortalFeatureResourceListComponent;
  public resourceListWidth = 350;

  protected resourceIdSubscription: Subscription;

  constructor(
    private store: Store<any>,
  ) {
    super();
  }

  public ngOnInit(): void {
    this.store.dispatch(headerStore.actions.setTitle({title: 'resources.title'}));
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
    this.resourceIdSubscription?.unsubscribe();
  }

  public onResizing(event: ResizeEvent): void {
    this.resourceListWidth = event.rectangle.width;
  }

  public async onRefresh(): Promise<void> {
    setTimeout(async () => {
      // Refresh when in detail
      if (this.outletComponent instanceof ResourceDetailComponent) {
        const detailModel = await firstValueFrom(this.outletComponent.model$.pipe(take(1)));
        if (Boolean(detailModel.isInProgressSince)) {
          this.store.dispatch(resourceDetailStore.actions.get({id: detailModel.resourceId}));
        }
        this.store.dispatch(resourceTreeViewStore.actions.selectById({
          resourceId: detailModel.resourceId ?? null
        }));
      }
      // Refresh when in list
      if (this.outletComponent instanceof TechPortalFeatureResourceListComponent) {
        this.store.dispatch(resourceTreeViewStore.actions.selectById({
          resourceId: null
        }));
      }
    });
  }

  public onOutletActivate(component: ResourceDetailComponent | TechPortalFeatureResourceListComponent): void {
    this.outletComponent = component;
  }
}
