import {ChangeDetectionStrategy, Component, Input, ViewChild} from '@angular/core';
import {InfoProgressFunctionListDto, InfoProgressLocationListDto} from "@nexnox-web/core-shared";
import {PathsProgressSidebarComponent} from "../../sidebars";

@Component({
  selector: 'nexnox-web-knowledge-paths-progress',
  templateUrl: './paths-progress.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PathsProgressComponent {
  @ViewChild('progressSidebarComponent', {static: true}) public progressSidebarComponent: PathsProgressSidebarComponent;

  @Input() public parentIds: Array<number | string>;

  public contactListTabOpen = false;

  public openSidebar(model: InfoProgressFunctionListDto | InfoProgressLocationListDto): void {
    this.contactListTabOpen = false;
    this.progressSidebarComponent.onShow(model);
  }
}
