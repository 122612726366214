<ng-container *ngIf="(selectedStereotypeId$ | async) && !loading">
    <ng-container *ngIf="customPropertySets$ | async as customPropertySets">
        <nexnox-web-cardbox *ngFor="let customPropertySet of customPropertySets; trackBy: trackSetBy; let isLast = last"
                            [title]="customPropertySet.name"
                            paddingClasses="px-2"
                            ghostPaddingClasses="p-3"
                            [noMarginBottom]="isLast && noMarginForLast"
                            [shadowMarginBottom]="isLast && noMarginForLast">
            <nexnox-web-custom-property-set-edit [customPropertySet]="customPropertySet"
                                                 [model]="getModel()"
                                                 [creating]="creating"
                                                 [readonly]="readonly"
                                                 [isRateable]="isRateable$ | async"
                                                 [inheritance]="inheritance"
                                                 (modelChange)="onModelChange($event)"
                                                 (modelValid)="onModelValid(customPropertySet, $event)">
            </nexnox-web-custom-property-set-edit>
        </nexnox-web-cardbox>
    </ng-container>
</ng-container>
