<nexnox-web-entity-edit *ngIf="!creating"
                        [entityEditBaseComponent]="this"
                        displayKey="title">
</nexnox-web-entity-edit>

<nexnox-web-tabs *ngIf="creating"
                 [internal]="true"
                 [isTransparent]="true"
                 startTab="entityEdit">
  <!-- Entity Edit Tab -->
  <ng-template nexnoxWebTabTemplate
               tabId="entityEdit"
               [heading]="title | translate"
               [hasError]="!isOwnModelValid()">
    <nexnox-web-entity-edit [entityEditBaseComponent]="this"></nexnox-web-entity-edit>
  </ng-template>

  <!-- Links -->
  <ng-template nexnoxWebTabTemplate
               tabId="links"
               [heading]="('tech-portal.subtitles.links' | translate) + (linksComponent ? (linksComponent.linksString$ | async) : '')"
               [hasError]="linksComponent ? !linksComponent.isModelValid() : false">
    <nexnox-web-tech-links #linksComponent
                           [links$]="linksSubject"
                           [loading]="loading"
                           [readonly]="readonly"
                           (linksChange)="onLinksChange($event)">
    </nexnox-web-tech-links>
  </ng-template>

  <!-- Attachments -->
  <ng-template nexnoxWebTabTemplate
               tabId="attachments"
               [heading]="('tech-portal.subtitles.attachments' | translate) + (attachmentsComponent ? (attachmentsComponent.attachmentsString$ | async) : '')"
               [hasError]="attachmentsComponent ? !attachmentsComponent.isModelValid() : false">
    <nexnox-web-tech-attachments #attachmentsComponent
                                 [attachments$]="attachmentsSubject"
                                 [loading]="loading"
                                 [readonly]="readonly"
                                 (attachmentsChange)="onAttachmentsChange($event)">
    </nexnox-web-tech-attachments>
  </ng-template>
</nexnox-web-tabs>
