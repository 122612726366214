<nexnox-web-tabs [internal]="true"
                 [isTransparent]="true"
                 startTab="issue-template-edit">

  <!-- Issue template edit tab -->
  <ng-template nexnoxWebTabTemplate
               tabId="issue-template-edit"
               [heading]="title | translate"
               [hasError]="!isOwnModelValid()">
    <nexnox-web-entity-edit [entityEditBaseComponent]="this" displayKey="name"></nexnox-web-entity-edit>

    <!-- Mission Content priorities -->
    <nexnox-web-cardbox *ngIf="isMissionEditorPrioritySearch"
                        paddingClasses="px-3 pb-3"
                        [title]="'core-portal.settings.subtitles.ticket-settings.priority-set-items' | translate"
                        [loading]="loading">
      <div *ngIf="!readonly && missionPriorityEntitySelectOptions"
           class="d-flex flex-row mb-3">
        <div class="d-flex flex-grow-1">
          <nexnox-web-entity-select class="w-100"
                                    [(model)]="selectedMissionPriorityItem"
                                    [options]="missionPriorityEntitySelectOptions"
                                    [disabled]="readonly">
          </nexnox-web-entity-select>
        </div>

        <button class="btn btn-outline-primary ml-3"
                [disabled]="!selectedMissionPriorityItem"
                (click)="onAddMissionPriority()">
          {{ 'core-shared.shared.select.add-item' | translate }}
        </button>
      </div>

      <nexnox-web-sortable-list [items]="missionPriorities$ | async"
                                [disabled]="readonly"
                                (deleteItem)="onDeleteMissionPriority($event)">
      </nexnox-web-sortable-list>
    </nexnox-web-cardbox>
  </ng-template>

  <!-- Issue template notification edit tab -->
  <ng-template nexnoxWebTabTemplate
               tabId="notifications"
               [heading]="'core-portal.settings.issue-template.notifications' | translate">
    <nexnox-web-settings-notification-issue [notifications]="notifications"
                                            [issueContentType]="issueContentType"
                                            [readonly]="readonly"
                                            [loading]="loading"
                                            (notificationsChanged)="onNotificationsChanged($event)">
    </nexnox-web-settings-notification-issue>
  </ng-template>

  <!-- Issue template published resources tab -->
  <ng-template *ngIf="readonly || !creating"
               nexnoxWebTabTemplate
               tabId="published-resources"
               [heading]="'core-portal.settings.issue-template.published-resources' | translate">
    <nexnox-web-settings-issue-published-resources [resources]="resources"
                                                   [readonly]="readonly"
                                                   [loading]="loading">
    </nexnox-web-settings-issue-published-resources>
  </ng-template>
</nexnox-web-tabs>
