import { Directive, Input, Optional } from '@angular/core';
import { RouterLink, RouterLinkWithHref } from '@angular/router';

@Directive({
  selector: '[routerLink][nexnoxWebDisableRouterLink],[nexnoxWebTenantRouterLink][nexnoxWebDisableRouterLink]'
})
export class DisableRouterLinkDirective {
  @Input() public nexnoxWebDisableRouterLink: boolean;

  constructor(
    @Optional() routerLink: RouterLink,
    @Optional() routerLinkWithHref: RouterLinkWithHref
  ) {
    const link = routerLink ? routerLink : routerLinkWithHref;

    if (link) {
      const { onClick } = link;

      link.onClick = (...args) => {
        if (this.nexnoxWebDisableRouterLink) {
          return !routerLinkWithHref;
        } else {
          return onClick.apply(link, args);
        }
      };
    }
  }
}
