import {NgModule} from '@angular/core';
import {
  CorePortalAttachmentsModule,
  CorePortalEntityModule,
  CorePortalLabelHelperModule,
  CorePortalModule,
  CorePortalRouterOverridesModule,
  CorePortalRuleEditorListModule
} from '@nexnox-web/core-portal';
import {CorePortalFeatureArticlesModule} from '@nexnox-web/core-portal/features/articles';
import {CorePortalFeatureAuditModule} from '@nexnox-web/core-portal/features/audit';
import {
  CorePortalFeatureMasterDataContactsModule
} from '@nexnox-web/core-portal/features/master-data/features/contacts';
import {
  CorePortalFeatureMasterDataFunctionsModule
} from '@nexnox-web/core-portal/features/master-data/features/functions';
import {
  CorePortalFeatureMasterDataLocationGroupsModule
} from '@nexnox-web/core-portal/features/master-data/features/location-groups';
import {
  CorePortalFeatureMasterDataLocationsModule
} from '@nexnox-web/core-portal/features/master-data/features/locations';
import {CorePortalFeatureResourcesModule} from '@nexnox-web/core-portal/features/resources';
import {CoreSharedModule, CoreSharedSortableListModule} from '@nexnox-web/core-shared';
import {TechPortalModule} from '@nexnox-web/tech-portal-lib';
import {TechPortalFeatureResourceTasksModule} from '@nexnox-web/tech-portal/features/resource-tasks';
import {TechPortalFeatureSolutionModule} from '@nexnox-web/tech-portal/features/solution';
import {TechPortalFeatureTemplatesModule} from '@nexnox-web/tech-portal/features/templates';
import {
  TechPortalFeatureTicketSettingsEscalationLevelsModule
} from '@nexnox-web/tech-portal/features/ticket-settings/features/escalation-levels';
import {
  TechPortalFeatureTicketSettingsPrioritySetsModule
} from '@nexnox-web/tech-portal/features/ticket-settings/features/priority-sets';
import {
  TechPortalFeatureTicketSettingsStatesModule
} from '@nexnox-web/tech-portal/features/ticket-settings/features/states';
import {TechPortalFeatureTicketsMissionsModule} from '@nexnox-web/tech-portal/features/tickets-missions';
import {DndModule} from 'ngx-drag-drop';
import {InplaceModule} from 'primeng/inplace';
import {InputTextareaModule} from 'primeng/inputtextarea';
import {
  MissionAddressesEditComponent,
  MissionInspectionReportEditComponent,
  MissionMapViewComponent,
  MissionStatusComponent,
  MissionSuccessorsEditComponent,
  MissionTicketListComponent,
  TechPortalFeatureMissionCreateComponent,
  TechPortalFeatureMissionEditComponent,
  TechPortalFeatureMissionReportEditComponent,
  TechPortalFeatureTicketMissionCreateComponent
} from './components';
import {
  MissionAuditComponent,
  MissionCreateTicketComponent,
  MissionDetailComponent,
  MissionInspectionReportComponent,
  MissionMyRequestsComponent,
  MissionReceiptComponent,
  MissionReportComponent,
  MissionSolutionComponent,
  TechPortalFeatureMissionGanttViewComponent,
  TechPortalFeatureMissionListComponent
} from './containers';
import {TechPortalFeatureMissionAssignModalComponent} from './modals';
import {
  CreateMissionSuccessorsSidebarComponent,
  EditorQuestSidebarComponent,
  EditorSuggestionsSidebarComponent,
  MissionAssignContactSidebarComponent,
  MissionAssignResourceSidebarComponent
} from './sidebars';
import {MissionResourcePreviewService, missionsServices} from './store';
import {DropdownModule} from 'primeng/dropdown';
import {DialogModule} from 'primeng/dialog';
import {TechPortalFeatureIssuesModule} from "@nexnox-web/tech-portal/features/issues/src/lib/issues.module";

@NgModule({
  declarations: [
    MissionAuditComponent,
    TechPortalFeatureMissionListComponent,
    MissionDetailComponent,
    MissionSolutionComponent,
    MissionReportComponent,
    MissionTicketListComponent,
    MissionInspectionReportComponent,
    MissionCreateTicketComponent,
    MissionReceiptComponent,
    TechPortalFeatureMissionEditComponent,
    TechPortalFeatureMissionCreateComponent,
    MissionStatusComponent,
    MissionAddressesEditComponent,
    TechPortalFeatureMissionReportEditComponent,
    MissionInspectionReportEditComponent,
    MissionSuccessorsEditComponent,
    TechPortalFeatureMissionAssignModalComponent,
    CreateMissionSuccessorsSidebarComponent,
    MissionMapViewComponent,
    TechPortalFeatureMissionGanttViewComponent,
    EditorSuggestionsSidebarComponent,
    EditorQuestSidebarComponent,
    MissionMyRequestsComponent,
    MissionAssignResourceSidebarComponent,
    MissionAssignContactSidebarComponent,
    TechPortalFeatureTicketMissionCreateComponent
  ],
    imports: [
        CoreSharedModule,
        CorePortalEntityModule,
        CorePortalRouterOverridesModule,
        CoreSharedSortableListModule,
        CorePortalAttachmentsModule,
        CorePortalModule,
        InplaceModule,
        InputTextareaModule,
        DndModule,
        CorePortalRuleEditorListModule,
        TechPortalFeatureTicketsMissionsModule,
        CorePortalFeatureMasterDataContactsModule.forFeature(),
        CorePortalFeatureMasterDataFunctionsModule.forFeature(),
        CorePortalFeatureMasterDataLocationsModule.forFeature(),
        CorePortalFeatureMasterDataLocationGroupsModule.forFeature(),
        TechPortalModule,
        TechPortalFeatureSolutionModule,
        TechPortalFeatureTemplatesModule.forFeature(),
        TechPortalFeatureTicketSettingsEscalationLevelsModule.forFeature(),
        TechPortalFeatureTicketSettingsPrioritySetsModule.forFeature(),
        TechPortalFeatureTicketSettingsStatesModule.forFeature(),
        CorePortalFeatureResourcesModule.forFeature(),
        TechPortalFeatureResourceTasksModule.forFeature(),
        TechPortalFeatureIssuesModule.forFeature(),
        CorePortalFeatureArticlesModule.forFeature(),
        CorePortalFeatureAuditModule,
        DropdownModule,
        DialogModule,
        CorePortalLabelHelperModule
    ],
  exports: [
    TechPortalFeatureMissionEditComponent,
    TechPortalFeatureMissionCreateComponent,
    TechPortalFeatureTicketMissionCreateComponent,
    TechPortalFeatureMissionReportEditComponent,
    TechPortalFeatureMissionAssignModalComponent
  ],
  providers: [
    ...missionsServices,
    MissionResourcePreviewService
  ]
})
export class MissionsDeclarationsModule {
}
