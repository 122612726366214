import { AppEntityType } from '@nexnox-web/core-shared';
import { createSelector } from '@ngrx/store';
import { selectInfosState } from '../../infos.selectors';
import { OrgaPortalFeatureInfoService } from '../../services';
import { InfoDetailXsStore, InfoDetailXsStoreState } from './info-detail.xs-store';

export interface InfoDetailStoreState extends InfoDetailXsStoreState {
}

/* istanbul ignore next */
export const infoDetailStore = new InfoDetailXsStore({
  actionLabel: 'Orga Portal - Infos - Info Detail',
  stateSelector: createSelector(selectInfosState, state => state.infoDetail),
  serviceType: OrgaPortalFeatureInfoService,
  entityType: AppEntityType.Info,
  stereotyped: false
});
