import {ChangeDetectionStrategy, Component, Injector} from '@angular/core';
import {
  ActionButton,
  CorePortalEntityOverviewBaseComponent,
  DatatableActionButton,
  DeleteEntityModel
} from '@nexnox-web/core-portal';
import {AppEntityType, AppPermissions, DocumentTemplateDto, ExportType, Mappers} from '@nexnox-web/core-shared';
import {documentTemplateListStore} from '../../../store/stores/document-templates';
import {TemplateUsageTypes} from "../../../models";

@Component({
  selector: 'nexnox-web-templates-document-template-list',
  templateUrl: './document-template-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DocumentTemplateListComponent extends CorePortalEntityOverviewBaseComponent<DocumentTemplateDto> {
  public title = 'core-portal.settings.subtitles.templates.document-template-list';
  public createTitle = 'core-portal.settings.subtitles.templates.document-template-create';
  public idProperty = 'documentTemplateId';
  public displayProperty = 'title';
  public datatableConfigName = 'TemplatesDocumentDefault';
  public componentId = 'DocumentTemplateListComponent';
  public detailLink = 'templates/document';
  public templateUsageType: TemplateUsageTypes;

  constructor(
    protected injector: Injector
  ) {
    super(injector, documentTemplateListStore, Mappers.DocumentTemplateListDto.serializedName, AppEntityType.None);
  }

  /* istanbul ignore next */
  public getRowActionButtons(): DatatableActionButton[] {
    return [
      ...this.getDefaultRowActionButtons(
        'core-portal.settings.actions.templates.edit-document-template',
        (row: DocumentTemplateDto) => `${this.detailLink}/${row.documentTemplateId}`,
        [AppPermissions.UpdateDocumentTemplate],
        {
          module: this.module
        }
      ),
      this.exportService.getRowActionExportButton(ExportType.DOCUMENT_TEMPLATE, this.idProperty)
    ];
  }

  public getDeleteEntityModel(): DeleteEntityModel {
    return {
      titleKey: 'core-portal.settings.actions.templates.delete-document-template',
      descriptionKey: 'core-portal.settings.descriptions.templates.delete-document-template',
      confirmKey: 'core-portal.settings.actions.templates.delete-document-template',
      deletePermission: AppPermissions.DeleteDocumentTemplate
    };
  }

  protected async getActionButtons(): Promise<ActionButton[]> {
    return this.getDefaultActionButtons(
      'core-portal.settings.actions.templates.create-document-template',
      AppPermissions.CreateDocumentTemplate
    );
  }
}
