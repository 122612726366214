import { ModuleWithProviders, NgModule } from '@angular/core';
import { ArticlesDeclarationsModule } from './articles-declarations.module';
import { ArticlesRoutingModule } from './articles-routing.module';
import { ArticlesStoreModule } from './articles-store.module';

@NgModule({
  imports: [
    ArticlesDeclarationsModule,
    ArticlesRoutingModule,
    ArticlesStoreModule
  ]
})
export class CorePortalFeatureArticlesModule {
  public static forFeature(): ModuleWithProviders<ArticlesDeclarationsModule> {
    return { ngModule: ArticlesDeclarationsModule };
  }
}
