import {EntityXsStore, EntityXsStoreState} from '@nexnox-web/core-store';
import {AddressDto, AppEntityType} from '@nexnox-web/core-shared';
import {Action, createSelector} from '@ngrx/store';
import {Injectable, Injector} from '@angular/core';
import {selectCompaniesState} from "../../companies.selectors";
import {
  CompanyAddressDetailService
} from "@nexnox-web/core-portal/features/master-data/features/companies/src/lib/store";

export interface CompanyAddressDetailStoreState extends EntityXsStoreState<AddressDto> {
}

export const companyAddressDetailStore = new EntityXsStore<AddressDto>({
  actionLabel: 'Core Portal - Master Data - Company Address Detail',
  stateSelector: createSelector(selectCompaniesState, state => state.companyAddressDetail),
  serviceType: CompanyAddressDetailService,
  stereotyped: true,
  entityType: AppEntityType.Address
});

export function companyAddressDetailStoreReducer(state: CompanyAddressDetailStoreState, action: Action): any {
  return companyAddressDetailStore.reducer(state, action);
}

@Injectable()
export class CompanyAddressAddressDetailStoreEffects extends companyAddressDetailStore.effects {
  constructor(
    protected injector: Injector
  ) {
    super(injector);
  }
}
