import { AfterViewInit, ChangeDetectionStrategy, Component, Injector, Input, OnInit, ViewChild } from '@angular/core';
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons/faCheckCircle';
import { faClock } from '@fortawesome/free-solid-svg-icons/faClock';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons/faEllipsisV';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons/faExclamationCircle';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons/faExclamationTriangle';
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import { faSave } from '@fortawesome/free-solid-svg-icons/faSave';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import { faUser } from '@fortawesome/free-solid-svg-icons/faUser';
import {
  ActionButton,
  CorePortalCardboxAction,
  CorePortalEntityDetailBaseComponent,
  CorePortalPermissionService,
  ModelPristine
} from '@nexnox-web/core-portal';
import { AppPermissions, CreatedByDto, MissionDto, MissionInspectionReportDto, MissionState, StereotypeDto } from '@nexnox-web/core-shared';
import { ofType } from '@ngrx/effects';
import { select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { BindObservable } from 'bind-observable';
import { isEmpty, isNull } from 'lodash';
import { MenuItem } from 'primeng/api';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { delay, distinctUntilChanged, filter, map, switchMap } from 'rxjs/operators';
import { MissionInspectionReportEditComponent } from '../../components';
import { missionInspectionReportStore, MissionInspectionReportXsStore } from '../../store';

@Component({
  selector: 'nexnox-web-missions-mission-inspection-report',
  templateUrl: './mission-inspection-report.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MissionInspectionReportComponent extends CorePortalEntityDetailBaseComponent<MissionInspectionReportDto>
  implements OnInit, AfterViewInit, ModelPristine {
  @Input() public missionId: number;
  @Input() public stereotypeId: number;

  @Input() @BindObservable() public inspectionReportId;
  public inspectionReportId$!: Observable<number>;

  @ViewChild('createComponent') public createComponent: MissionInspectionReportEditComponent;

  public title = 'missions.subtitles.mission-inspection-report';

  public stereotypesLoaded$: Observable<boolean>;
  public delayedStereotypes$: Observable<StereotypeDto[]>;

  public isOkay$: Observable<boolean>;
  public passedPercentage$: Observable<number>;
  public creator$: Observable<CreatedByDto>;
  public createdAt$: Observable<string>;
  public lastModified$: Observable<string>;
  public createdAndModifiedEqual$: Observable<boolean>;
  public isOutdated$: Observable<boolean>;

  public createModelSubject: BehaviorSubject<MissionInspectionReportDto> = new BehaviorSubject<MissionInspectionReportDto>({});
  public unsavedChangesSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public createHeaderActions: CorePortalCardboxAction[] = [];
  public editHeaderActions: CorePortalCardboxAction[] = [];

  public faTimes = faTimes;
  public faCheckCircle = faCheckCircle;
  public faExclamationCircle = faExclamationCircle;
  public faUser = faUser;
  public faClock = faClock;
  public faExclamationTriangle = faExclamationTriangle;
  public faCheck = faCheck;


  @Input() @BindObservable() public mission: MissionDto;
  public mission$!: Observable<MissionDto>;

  protected entityStore: MissionInspectionReportXsStore;

  protected idParam = 'missionInspectionReportId';
  protected displayKey = 'missionInspectionReportId';
  protected useParamMap = false;
  protected initialGet = false;

  /* istanbul ignore next */
  constructor(
    protected injector: Injector,
    private translate: TranslateService,
    private permissionService: CorePortalPermissionService
  ) {
    super(injector, missionInspectionReportStore);

    this.createHeaderActions = [{
      label: 'missions.actions.create-mission-inspection-report',
      icon: faPlus,
      class: 'btn-outline-primary',
      isDisabled: () => this.createModelSubject.asObservable().pipe(
        map(() => !this.createComponent?.isModelValid())
      ),
      isLoading: () => this.loading$.pipe(
        switchMap(loading => this.stereotypesLoaded$.pipe(
          map(stereotypesLoaded => loading || !stereotypesLoaded)
        ))
      ),
      permission: AppPermissions.CreateMissionInspectionReport,
      callback: () => this.onCreate()
    },
    {
      label: 'missions.actions.finish-mission',
      icon: faCheck,
      class: 'btn-outline-primary',
      isDisabled: () =>  this.createModelSubject.asObservable().pipe(
        delay(1),
        map(() => !this.createComponent?.isModelValid())
      ),
      shouldShow: () => this.mission$.pipe(
        map(mission =>
          mission?.state !== MissionState.Done && mission?.state !== MissionState.Canceled
        )
      ),
      isLoading: () => this.loading$,
      permission: AppPermissions.CreateMissionReport,
      callback: () => this.onCreateAndFinish()
    }];

    this.editHeaderActions = [
      {
        label: 'missions.actions.save-mission-inspection-report',
        icon: faSave,
        class: 'btn-outline-primary',
        shouldShow: () => this.readonly$.pipe(
          map(readonly => !readonly)
        ),
        isDisabled: () => this.shouldDisablePrimaryAction(),
        isLoading: () => this.loading$.pipe(
          switchMap(loading => this.stereotypesLoaded$.pipe(
            map(stereotypesLoaded => loading || !stereotypesLoaded)
          ))
        ),
        permission: AppPermissions.UpdateMissionInspectionReport,
        callback: () => this.onSaveAction()
      },
      {
        label: 'missions.actions.finish-mission',
        icon: faCheck,
        class: 'btn-outline-primary',
        shouldShow: () => this.readonly$.pipe(
          switchMap(readonly => this.mission$.pipe(
            map(mission =>
              !readonly &&
              mission?.state !== MissionState.Done && mission?.state !== MissionState.Canceled
            )
          ))
        ),
        isDisabled: () => this.shouldDisablePrimaryAction(),
        isLoading: () => this.loading$,
        permission: AppPermissions.UpdateMissionReport,
        callback: () => this.onSaveAndFinish()
      },
      {
        class: 'btn-outline-secondary',
        icon: faEllipsisV,
        tooltip: 'core-portal.core.general.more',
        shouldShow: () => this.readonly$.pipe(
          map(readonly => !readonly)
        ),
        isLoading: () => this.loading$.pipe(
          switchMap(loading => this.stereotypesLoaded$.pipe(
            map(stereotypesLoaded => loading || !stereotypesLoaded)
          ))
        ),
        dropdownItems: () => combineLatest([
          of(null),
          this.translate.stream('missions.actions.delete-mission-inspection-report').pipe(
            distinctUntilChanged(),
            switchMap(label => this.permissionService.hasPermission$(AppPermissions.DeleteMissionInspectionReport).pipe(
              map(hasPermission => ({
                label,
                visible: hasPermission,
                command: () => this.onDeleteAction(
                  { label: 'missions.actions.delete-mission-inspection-report' } as ActionButton,
                  'missions.descriptions.delete-mission-inspection-report'
                )
              }))
            ))
          )
        ] as Observable<MenuItem>[]).pipe(
          map(items => items.filter(x => !isNull(x)))
        )
      }
    ];
  }

  public isPristine(): boolean {
    return isEmpty(this.createModelSubject.getValue()) && !this.unsavedChangesSubject.getValue();
  }

  /* istanbul ignore next */
  public ngOnInit(): void {
    this.stereotypesLoaded$ = this.store.pipe(
      select(this.entityStore.selectors.selectStereotypesLoaded)
    );

    this.delayedStereotypes$ = this.stereotypes$.pipe(
      switchMap(stereotypes => this.stereotypesLoaded$.pipe(
        filter(stereotypesLoaded => stereotypesLoaded),
        map(() => stereotypes)
      ))
    );

    this.isOkay$ = this.model$.pipe(
      filter(model => Boolean(model)),
      map(model => model.isOk),
      distinctUntilChanged()
    );

    this.passedPercentage$ = this.model$.pipe(
      filter(model => Boolean(model)),
      map(model => model.passedPercentage),
      distinctUntilChanged()
    );

    this.creator$ = this.model$.pipe(
      filter(model => Boolean(model)),
      map(model => model.creator),
      distinctUntilChanged()
    );

    this.createdAt$ = this.model$.pipe(
      filter(model => Boolean(model)),
      map(model => model.createdAt),
      distinctUntilChanged()
    );

    this.lastModified$ = this.model$.pipe(
      filter(model => Boolean(model)),
      map(model => model.lastModified),
      distinctUntilChanged()
    );

    this.createdAndModifiedEqual$ = this.createdAt$.pipe(
      switchMap(createdAt => this.lastModified$.pipe(
        map(lastModified => createdAt === lastModified)
      ))
    );

    this.isOutdated$ = this.model$.pipe(
      filter(model => Boolean(model)),
      map(model => model.isOutdated),
      distinctUntilChanged()
    );

    this.subscribeToFragment();
    this.subscribeToParams();
    this.subscribeToActions();

    this.subscribe(this.inspectionReportId$, inspectionReportId => this.id = inspectionReportId);
    this.subscribe(this.hasUnsavedChanges().pipe(
      distinctUntilChanged()
    ), unsavedChanges => this.unsavedChangesSubject.next(unsavedChanges));
  }

  public ngAfterViewInit(): void {
    if (this.inspectionReportId) {
      this.getEntity(this.getId(), []);
    } else {
      this.getStereotypes();
    }
  }

  public onCreate(): void {
    this.store.dispatch(missionInspectionReportStore.actions.create({
      model: this.createModelSubject.getValue(),
      missionId: this.missionId
    }));
  }

  public onCreateAndFinish(): void {
    this.store.dispatch(missionInspectionReportStore.actions.createAndConfirm({
      model: this.createModelSubject.getValue(),
      missionId: this.missionId
    }));
  }

  public onSaveAndFinish(): void {
    this.store.dispatch(this.entityStore.actions.saveAndConfirm({ id: this.id, parentIds: this.parentIds }));
  }

  protected subscribeToActions(): void {
    super.subscribeToActions();

    this.subscribe(this.actions$.pipe(ofType(missionInspectionReportStore.actions.createSuccess)), () => this.onCreateSuccess());
    this.subscribe(this.actions$.pipe(ofType(this.entityStore.actions.deleteSuccess)), () => this.getStereotypes());
    this.subscribe(this.actions$.pipe(ofType(missionInspectionReportStore.actions.saveAndConfirmSuccess)), payload => this.onSaveSuccess(payload));
  }

  protected onCreateSuccess(): void {
    this.createModelSubject.next({});
  }

  protected getId(): number {
    return this.inspectionReportId;
  }

  private getStereotypes(): void {
    this.store.dispatch(missionInspectionReportStore.actions.getStereotypes({ excludeArchived: true }));
  }
}
