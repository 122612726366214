import { AppEntityType } from '@nexnox-web/core-shared';
import { createSelector } from '@ngrx/store';
import { TaskjobService } from '../../services';
import { selectTasksState } from '../../tasks.selectors';
import { TaskActiveListXsStore, TaskActiveListXsStoreState } from './task-active-list.xs-store';

export interface TaskActiveListStoreState extends TaskActiveListXsStoreState {
}

/* istanbul ignore next */
export const taskActiveListStore = new TaskActiveListXsStore({
  actionLabel: 'Orga Portal - Tasks - Task Active List',
  stateSelector: createSelector(selectTasksState, state => state.taskActiveList),
  serviceType: TaskjobService,
  entityType: AppEntityType.TaskJob,
  selectId: taskJob => taskJob.entity?.taskJobId ?? taskJob.model?.taskJobId,
  stereotyped: false
});
