import { Component, Input } from '@angular/core';

/* istanbul ignore next */
@Component({
  selector: 'nexnox-web-cardbox-ghost',
  templateUrl: './cardbox-ghost.component.html',
  styleUrls: ['./cardbox-ghost.component.scss']
})
export class CardboxGhostComponent {
  @Input() public noMarginBottom = false;
  @Input() public noDividerMarginBottom = false;
  @Input() public fullWidth = false;
  @Input() public fullHeight = false;
  @Input() public transparent = false;

  @Input() public paddingClasses: string;
}
