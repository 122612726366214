<div class="row">
  <div class="col-10">
    <form [formGroup]="form">
      <formly-form class="row"
                   [form]="form"
                   [model]="model"
                   [fields]="initializedFields">
      </formly-form>
    </form>
  </div>
  <div class="col-2 no-gutters p-0 text-right">
    <button type="button"
            pButton
            class="m-0 ml-2"
            [class]="'p-button-secondary p-button-text'"
            [icon]="faPlus | nexnoxWebFaIconStringPipe"
            [label]="'core-shared.shared.actions.add' | translate"
            [class.d-none]="mode !== rowModes.Create"
            [disabled]="form.invalid ?? false"
            (click)="onAdd()">
    </button>
    <button type="button"
            pButton
            class="m-0 ml-2"
            [class]="'p-button-secondary p-button-text'"
            [icon]="faCheck| nexnoxWebFaIconStringPipe"
            [title]="'core-shared.shared.fields.ok' | translate"
            [class.d-none]="mode !== rowModes.Edit"
            [disabled]="form.invalid ?? false"
            (click)="onConfirm()">
    </button>
    <button type="button"
            pButton
            class="m-0 ml-2"
            [class]="'p-button-secondary p-button-text'"
            [icon]="faTimes| nexnoxWebFaIconStringPipe"
            [class.d-none]="mode !== rowModes.Edit"
            [title]="'core-portal.core.general.cancel' | translate"
            (click)="onCancel()">
    </button>
    <button type="button"
            pButton
            class="m-0 ml-2"
            [class]="'p-button-secondary p-button-text'"
            [icon]="faPencil | nexnoxWebFaIconStringPipe"
            [title]="'core-portal.core.general.edit' | translate"
            [class.d-none]="mode !== rowModes.Readonly"
            [disabled]="form.invalid ?? false"
            (click)="onEdit()">
    </button>
    <button type="button"
            pButton
            class="m-0 ml-2"
            [class]="'p-button-secondary p-button-text'"
            [icon]="faTrash| nexnoxWebFaIconStringPipe"
            [title]="'core-shared.shared.table.tooltip.delete' | translate"
            [class.d-none]="mode !== rowModes.Readonly"
            (click)="onDelete()">
    </button>
  </div>
</div>
