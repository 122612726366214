import { ModuleWithProviders, NgModule } from '@angular/core';
import { StatesRoutingModule } from './states-routing.module';
import { StatesDeclarationsModule } from './states-declarations.module';
import { StatesStoreModule } from './states-store.module';

@NgModule({
  imports: [
    StatesDeclarationsModule,
    StatesRoutingModule,
    StatesStoreModule
  ]
})
export class TechPortalFeatureTicketSettingsStatesModule {
  public static forFeature(): ModuleWithProviders<StatesDeclarationsModule> {
    return { ngModule: StatesDeclarationsModule };
  }
}
