<nexnox-web-modal #modalComponent
                  [title]="'tech-portal.subtitles.attachments' | translate"
                  [confirmButtonText]="'core-shared.shared.actions.select' | translate"
                  [cancelButtonText]="'core-portal.core.general.cancel' | translate"
                  [preConfirmFn]="preConfirmFn">
  <div class="w-100">
    <div class="w-100"
         [class.d-none]="loading$ | async">
      <nexnox-web-sortable-list [items]="existingItems$ | async"
                                [sortable]="false"
                                [hideItemName]="true"
                                [selectable]="true"
                                [selectMultiple]="true"
                                [deletable]="false"
                                (selectedItemsChange)="onSelectedItemsChange($event)">
        <ng-template nexnoxWebSortableListItemTemplate let-item="item">
          <ng-container [ngTemplateOutlet]="attachmentItemTemplate" [ngTemplateOutletContext]="{ item: item }"></ng-container>
        </ng-template>
      </nexnox-web-sortable-list>
    </div>

    <div *ngIf="loading$ | async"
         class="d-flex flex-grow-1 justify-content-center">
      <fa-icon [icon]="faSpinner" [spin]="true"></fa-icon>
    </div>
  </div>
</nexnox-web-modal>

<ng-template #attachmentItemTemplate let-item="item">
  <div *ngIf="item?.file" class="d-flex w-100 flex-wrap">
    <div class="d-flex flex-grow-1">
      <fa-icon [icon]="getFileImage(item.file)" [fixedWidth]="true" size="lg" class="mr-1"></fa-icon>
      <a [href]="item.file.uri" target="_blank">{{ item.file.name }}</a>&nbsp;
      ({{ item.file.size | filesize }})
    </div>

    <div class="d-md-flex d-none">
      <span>
        <span *ngIf="item.member">
          <span>{{ 'tech-portal.descriptions.in' | translate }} </span>
          <a [nexnoxWebTenantRouterLink]="item.member.link"
             [module]="item.member.module"
             target="'_blank'">
            {{ item.member.title }}
          </a>
        </span>

        <span>
          {{ 'core-shared.shared.time-phrases.at-date' | translate:{
            date: item.file.creationDateTime | nexnoxWebBsDate:'L LT' | async
          } }}
        </span>
      </span>
    </div>
  </div>
</ng-template>
