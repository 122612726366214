import { PagedEntitiesXsStore, PagedEntitiesXsStoreState } from '@nexnox-web/core-store';
import { AppEntityType, LocationDto } from '@nexnox-web/core-shared';
import { Action, createSelector } from '@ngrx/store';
import { selectLocationsState } from '../../locations.selectors';
import { CorePortalFeatureMasterDataLocationService } from '../../services';
import { Injectable, Injector } from '@angular/core';

export interface LocationListStoreState extends PagedEntitiesXsStoreState<LocationDto> {
}

/* istanbul ignore next */
export const locationListStore = new PagedEntitiesXsStore<LocationDto>({
  actionLabel: 'Core Portal - Master Data - Location List',
  stateSelector: createSelector(selectLocationsState, state => state.locationList),
  serviceType: CorePortalFeatureMasterDataLocationService,
  entityType: AppEntityType.Location,
  selectId: location => location.entity?.locationId ?? location.model?.locationId,
  prepareEntity: (entity, base) => {
    const newEntity = base(entity);

    CorePortalFeatureMasterDataLocationService.mapOpenDaysToWeekDayArray(newEntity);

    return newEntity;
  },
  sanitizeModel: (model, entity, base) => {
    const newModel = base(model, entity);

    CorePortalFeatureMasterDataLocationService.mapOpenDaysToBooleanFields(newModel);

    return newModel;
  }
});

export function locationListStoreReducer(state: LocationListStoreState, action: Action): any {
  return locationListStore.reducer(state, action);
}

@Injectable()
export class LocationListStoreEffects extends locationListStore.effects {
  constructor(
    protected injector: Injector
  ) {
    super(injector);
  }
}
