import {ChangeDetectionStrategy, Component, Injector} from '@angular/core';
import {
  ActionButton,
  CorePortalEntityOverviewBaseComponent,
  DatatableActionButton,
  DeleteEntityModel
} from '@nexnox-web/core-portal';
import {
  AppEntityType,
  AppPermissions,
  ControllerOperationId, FilterOperators,
  FilterTypes,
  LocationDto, LocationState,
  Mappers
} from '@nexnox-web/core-shared';
import {locationListStore} from '../../store';

@Component({
  selector: 'nexnox-web-master-data-locations-location-list',
  templateUrl: './location-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CorePortalFeatureMasterDataLocationListComponent extends CorePortalEntityOverviewBaseComponent<LocationDto> {
  public title = 'core-portal.master-data.subtitles.location-list';
  public createTitle = 'core-portal.master-data.subtitles.location-create';
  public idProperty = 'locationId';
  public displayProperty = 'name';
  public datatableConfigName = 'MasterDataLocationDefault';
  public componentId = 'LocationListComponent';
  public pageOperation = ControllerOperationId.LocationControllerList;
  public enableViews = true;

  constructor(
    protected injector: Injector
  ) {
    super(injector, locationListStore, Mappers.LocationListDto.serializedName, AppEntityType.Location);
  }

  public getRowActionButtons(): DatatableActionButton[] {
    return this.getDefaultRowActionButtons(
      'core-portal.master-data.actions.edit-location',
      (row: LocationDto) => `/masterdata/locations/${row.locationId}`,
      [AppPermissions.UpdateLocation],
      {
        module: 'management'
      }
    );
  }

  public getDeleteEntityModel(): DeleteEntityModel {
    return {
      titleKey: 'core-portal.master-data.actions.delete-location',
      descriptionKey: 'core-portal.master-data.descriptions.delete-location',
      confirmKey: 'core-portal.master-data.actions.delete-location',
      deletePermission: AppPermissions.DeleteLocation
    };
  }

  protected async getActionButtons(): Promise<ActionButton[]> {
    return this.getDefaultActionButtons('core-portal.master-data.actions.create-location', AppPermissions.CreateLocation);
  }
}
