import {NgModule} from '@angular/core';
import {
  CorePortalDatatableColumnService,
  CorePortalDatatableFilterService,
  CorePortalDatatableService,
  CorePortalDatatableViewService,
  DataTableCalendarSubscriptionService
} from './services';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {RouterModule} from '@angular/router';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {CoreSharedModule, CoreSharedSortableListModule} from '@nexnox-web/core-shared';
import {
  CorePortalEntityDatatableCellComponent,
  CorePortalEntityDatatableComponent,
  CorePortalEntityDatatableFilterComponent,
  CorePortalEntityDatatableFilterInputComponent,
  CorePortalEntityDatatableHeaderComponent,
  CorePortalEntityDatatableLastColumnCellComponent,
  CorePortalEntityDatatablePathColumnComponent,
  CorePortalEntityDatatableViewDropdownComponent
} from './components';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgSelectModule} from '@ng-select/ng-select';
import {BsDatepickerModule} from 'ngx-bootstrap/datepicker';
import {CorePortalEntitySelectModule} from '../entity-select';
import {TableModule} from 'primeng/table';
import {ButtonModule} from 'primeng/button';
import {MenuModule} from 'primeng/menu';
import {InputTextareaModule} from 'primeng/inputtextarea';
import {CorePortalRouterOverridesModule} from '../../../router-overrides';
import {CorePortalFormlyModule} from '../../formly';
import {
  CalendarSubscriptionSidebarComponent,
  CorePortalEntityDatatableSettingsSidebarComponent,
  EntityDatatableExportSidebarComponent
} from './sidebars';
import {CorePortalTabsModule} from '../../tabs';
import {TreeModule} from 'primeng/tree';
import {CheckboxModule} from 'primeng/checkbox';
import {ToggleButtonModule} from 'primeng/togglebutton';
import {InplaceModule} from 'primeng/inplace';
import {TieredMenuModule} from 'primeng/tieredmenu';
import {CorePortalResourceInheritableService} from "@nexnox-web/libs/core-portal/src/lib/services";
import {ProgressBarModule} from "primeng/progressbar";

const sharedModules = [
  TableModule,
  ButtonModule,
  MenuModule,
  TreeModule,
  CheckboxModule,
  ToggleButtonModule,
  InplaceModule,
  TieredMenuModule
];

const exportedComponents = [
  CorePortalEntityDatatableComponent
];

const sidebars = [
  CorePortalEntityDatatableSettingsSidebarComponent,
  EntityDatatableExportSidebarComponent,
  CalendarSubscriptionSidebarComponent
];

@NgModule({
  declarations: [
    ...exportedComponents,
    CorePortalEntityDatatableHeaderComponent,
    CorePortalEntityDatatableCellComponent,
    CorePortalEntityDatatableLastColumnCellComponent,
    CorePortalEntityDatatableFilterComponent,
    CorePortalEntityDatatableFilterInputComponent,
    CorePortalEntityDatatablePathColumnComponent,
    CorePortalEntityDatatableViewDropdownComponent,
    ...sidebars
  ],
  imports: [
    CommonModule,
    ...sharedModules,
    TranslateModule,
    RouterModule,
    FontAwesomeModule,
    CoreSharedModule,
    CorePortalFormlyModule,
    CorePortalEntitySelectModule,
    FormsModule,
    ReactiveFormsModule,
    InputTextareaModule,
    NgSelectModule,
    BsDatepickerModule,
    CorePortalTabsModule,
    CorePortalRouterOverridesModule,
    CoreSharedSortableListModule,
    ProgressBarModule,
  ],
  exports: [
    ...sharedModules,
    ...exportedComponents
  ],
  providers: [
    CorePortalDatatableColumnService,
    CorePortalDatatableFilterService,
    CorePortalDatatableViewService,
    CorePortalDatatableService,
    DataTableCalendarSubscriptionService,
    CorePortalResourceInheritableService
  ]
})
export class CorePortalEntityDatatableModule {
}
