import { RouterModule, Routes } from '@angular/router';
import { AddressDetailComponent, AddressListComponent } from './containers';
import {AppPermissions, I18nBreadcrumbResolver, UnsavedChangesGuard, UnsavedCreateGuard} from '@nexnox-web/core-shared';
import { NgModule } from '@angular/core';
import { CorePortalPermissionGuard } from '@nexnox-web/core-portal';

export const CORE_PORTAL_MASTER_DATA_ADDRESSES_ROUTES: Routes = [
  {
    path: '',
    canActivate: [CorePortalPermissionGuard],
    canDeactivate: [UnsavedCreateGuard],
    component: AddressListComponent,
    data: {
      permissions: [AppPermissions.DisplayAddresses]
    }
  },
  {
    path: ':addressId',
    canActivate: [CorePortalPermissionGuard],
    component: AddressDetailComponent,
    canDeactivate: [UnsavedChangesGuard],
    data: {
      permissions: [AppPermissions.ReadAddress, AppPermissions.ReadStereotype],
      breadcrumbs: I18nBreadcrumbResolver,
      tab: 'address',
      text: 'core-portal.core.header.breadcrumbs.master-data.address'
    },
    children: [
      {
        path: 'locations',
        data: {
          tab: 'locations'
        },
        children: []
      },
      {
        path: 'companies',
        data: {
          tab: 'companies'
        },
        children: []
      }
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(CORE_PORTAL_MASTER_DATA_ADDRESSES_ROUTES)
  ],
  exports: [
    RouterModule
  ]
})
export class AddressesRoutingModule {
}
