import {NgModule} from '@angular/core';
import {CorePortalEntityModule} from '@nexnox-web/core-portal';
import {CoreSharedModule} from '@nexnox-web/core-shared';
import {
  ManufacturerDetailComponent,
  ManufacturerListComponent,
  ModelDetailStandaloneComponent,
  ModelListComponent
} from './containers';
import {MergeManufacturerSidebarComponent, MergeModelSidebarComponent, MoveModelSidebarComponent} from './sidebars';
import {manufacturerServices} from './store';
import {ManufacturerEditComponent, ModelEditComponent} from "./components";

@NgModule({
  declarations: [
    ManufacturerEditComponent,
    ManufacturerListComponent,
    ManufacturerDetailComponent,
    ModelListComponent,
    ModelEditComponent,
    ModelDetailStandaloneComponent,

    MergeManufacturerSidebarComponent,
    MoveModelSidebarComponent,
    MergeModelSidebarComponent
  ],
  providers: [
    ...manufacturerServices
  ],
  imports: [
    CoreSharedModule,
    CorePortalEntityModule
  ],
  exports: []
})
export class ManufacturersDeclarationsModule {
}
