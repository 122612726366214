<nexnox-web-entity-edit displayKey="name"
                        [entityEditBaseComponent]="this"
                        [stereotyped]="false">
</nexnox-web-entity-edit>

<nexnox-web-settings-stereotypes-custom-property-sets-edit #customPropertySetsEditComponent
                                                           [model]="model"
                                                           [readonly]="readonly"
                                                           [loading]="loading"
                                                           [customPropertyDropdownOptions]="customPropertyDropdownOptions"
                                                           (modelChange)="onCustomPropertySetsChange($event)">
</nexnox-web-settings-stereotypes-custom-property-sets-edit>
