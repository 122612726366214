import {RouterModule, Routes} from '@angular/router';
import {NgModule} from '@angular/core';
import {CorePortalPermissionGuard} from '@nexnox-web/core-portal';
import {InfoDetailComponent, InfoListComponent} from './containers';
import {AppPermissions, I18nBreadcrumbResolver, UnsavedChangesGuard, UnsavedCreateGuard} from '@nexnox-web/core-shared';

export const ORGA_PORTAL_INFOS_ROUTES: Routes = [
  {
    path: '',
    canActivate: [CorePortalPermissionGuard],
    canDeactivate: [UnsavedCreateGuard],
    component: InfoListComponent,
    data: {
      permissions: [AppPermissions.DisplayInfos]
    }
  },
  {
    path: ':infoId',
    component: InfoDetailComponent,
    canActivate: [CorePortalPermissionGuard],
    canDeactivate: [UnsavedChangesGuard],
    data: {
      permissions: [AppPermissions.ReadInfo],
      breadcrumbs: I18nBreadcrumbResolver,
      tab: 'info',
      text: 'orga-portal.infos.subtitles.info-detail'
    },
    children: [
      {
        path: 'progress',
        children: [],
        data: {
          tab: 'progress'
        }
      },
      {
        path: 'attachments',
        children: [],
        data: {
          tab: 'attachments'
        }
      }
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(ORGA_PORTAL_INFOS_ROUTES)
  ],
  exports: [
    RouterModule
  ]
})
export class InfosRoutingModule {
}
