import {ChangeDetectionStrategy, Component, Injector} from '@angular/core';
import {CorePortalEntityOverviewBaseComponent, DatatableActionButton} from '@nexnox-web/core-portal';
import {AddressDto, AppEntityType, AppPermissions, Mappers} from '@nexnox-web/core-shared';
import {companyAddressListStore} from '../../store';

@Component({
  selector: 'nexnox-web-master-data-companies-company-address-list',
  templateUrl: './company-address-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CompanyAddressListComponent extends CorePortalEntityOverviewBaseComponent<AddressDto> {
  public title = 'core-portal.master-data.subtitles.address-list';
  public createTitle = 'core-portal.master-data.subtitles.address-create';
  public idProperty = 'addressId';
  public displayProperty = 'street';
  public datatableConfigName = 'MasterDataAddressDefault';
  public componentId = 'CompanyAddressListComponent';

  constructor(
    protected injector: Injector
  ) {
    super(injector, companyAddressListStore, Mappers.AddressListDto.serializedName, AppEntityType.Address);
  }

  public getRowActionButtons(): DatatableActionButton[] {
    return [
      ...this.getDefaultRowActionButtons(
        'core-portal.master-data.actions.edit-address',
        address => `masterdata/companies/${this.parentIds[0]}/addresses/${address.addressId}`,
        [AppPermissions.UpdateAddress],
        {
          module: 'management'
        }
      ),
      ...this.getEmbeddedRowActionButtons()
    ];
  }
}
