import { NgModule } from '@angular/core';
import { CoreSharedRuleEditorListComponent, CoreSharedRuleEditorListItemComponent } from './components';
import { CoreSharedModule, CoreSharedSortableListModule } from '@nexnox-web/core-shared';
import { CorePortalFormlyModule } from '../formly';
import { CorePortalCardboxModule } from '../cardbox';

@NgModule({
  declarations: [
    CoreSharedRuleEditorListComponent,
    CoreSharedRuleEditorListItemComponent
  ],
  imports: [
    CoreSharedModule,
    CoreSharedSortableListModule,
    CorePortalFormlyModule,
    CorePortalCardboxModule
  ],
  exports: [
    CoreSharedRuleEditorListComponent
  ]
})
export class CorePortalRuleEditorListModule {
}
