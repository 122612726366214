import { CoreSharedApiBaseService, Product, TicketDto } from '@nexnox-web/core-shared';
import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class TechPortalFeatureMissionTicketService extends CoreSharedApiBaseService {
  constructor(
    protected injector: Injector
  ) {
    super(injector, Product.TECH, 'ticket', ['mission']);
  }

  public startTicket(missionId: number, ticket: TicketDto): Observable<TicketDto> {
    return this.httpRequestService.post<TicketDto>(`${this.buildPath([missionId])}/startTicket`, ticket).pipe(
      map(response => response.body)
    );
  }
}
