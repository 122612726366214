import {IssueReasonType} from "@nexnox-web/core-shared";

export const messageControlReasonTypeEnumOptions = [
  {label: 'issues.reason-types.1', value: IssueReasonType.ReachedFixedTime},
  {label: 'issues.reason-types.2', value: IssueReasonType.ReachedError},
  {label: 'issues.reason-types.3', value: IssueReasonType.ReachedConsumption}
];

export const messageControlReasonReadonlyTypeEnumOptions = [
  {label: 'core-shared.shared.fields.not-specified', value: IssueReasonType.Base},
  {label: 'issues.reason-types.1', value: IssueReasonType.ReachedFixedTime},
  {label: 'issues.reason-types.2', value: IssueReasonType.ReachedError},
  {label: 'issues.reason-types.3', value: IssueReasonType.ReachedConsumption}
];
