import {ChangeDetectionStrategy, Component, Injector, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {
  CorePortalEntityEditBaseComponent,
  CorePortalFormlyReadonlyTypes,
  CorePortalFormlyReadonlyTyping,
  CorePortalFormlyReceiverTyping,
  CorePortalPermissionService,
  CorePortalTenantRouter
} from 'libs/core-portal/src';
import {
  AppPermissions,
  ArticlePreviewSidebarComponent,
  CoreSharedSortableListItem,
  CrossCreationTypes,
  KnowledgeArticleInPathDto,
  KnowledgeExamInPathDto,
  KnowledgePathDto,
  OrgaAttachmentDto,
  SortableListRowAction
} from 'libs/core-shared/src';
import {BehaviorSubject, Observable} from 'rxjs';
import {FormlyFieldConfig} from '@ngx-formly/core';
import {OrgaPortalAttachmentsComponent} from 'libs/orga-portal/src';
import {OrgaPortalFeatureCategoryService} from 'libs/orga-portal/features/categories';
import {PathsArticlesEditComponent, PathsExamsEditComponent} from "../../components";
import {faEye} from "@fortawesome/free-solid-svg-icons/faEye";
import {faExternalLinkAlt} from "@fortawesome/free-solid-svg-icons/faExternalLinkAlt";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'nexnox-web-knowledge-paths-edit',
  templateUrl: './paths-edit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PathsEditComponent extends CorePortalEntityEditBaseComponent<KnowledgePathDto> implements OnInit  {

  @ViewChild('categorySelectLabelTitleTemplate', {static: true}) public categorySelectLabelTitleTemplate: TemplateRef<any>;
  @ViewChild('categorySelectOptionTitleTemplate', {static: true}) public categorySelectOptionTitleTemplate: TemplateRef<any>;

  @ViewChild('attachmentsComponent') public attachmentsComponent: OrgaPortalAttachmentsComponent;

  @ViewChild('controlPointsComponent', {static: true}) public articlesComponent: PathsArticlesEditComponent;
  @ViewChild('controlPointsComponent', {static: true}) public examsComponent: PathsExamsEditComponent;

  @ViewChild('articlePreviewSidebar', {static: true}) public articlePreviewSidebar: ArticlePreviewSidebarComponent;

  public articlesSubject = new BehaviorSubject<KnowledgeArticleInPathDto[]>([]);
  public examsSubject = new BehaviorSubject<KnowledgeExamInPathDto[]>([]);

  public loading$: Observable<boolean>;
  public hasExamPermission$: Observable<boolean>;

  public attachmentsSubject: BehaviorSubject<OrgaAttachmentDto[]> = new BehaviorSubject<OrgaAttachmentDto[]>([]);

  constructor(
    protected injector: Injector,
    private categoryService: OrgaPortalFeatureCategoryService,
    private tenantRouter: CorePortalTenantRouter,
    private translate: TranslateService,
    private permissionService: CorePortalPermissionService
  ) {
    super(injector);

    this.loading$ = this.loadingSubject.asObservable();
  }

  public ngOnInit():void {
    super.ngOnInit();
    this.hasExamPermission$ = this.permissionService.hasPermission$(AppPermissions.DisplayServices);
  }

  public onArticlesChange(articles: KnowledgeArticleInPathDto[]): void {
    super.setModel({
      ...this.model,
      articles
    })
    setTimeout(() => this.onModelChange(this.model));
  }

  public onExamsChange(exams: KnowledgeExamInPathDto[]): void {
    super.setModel({
      ...this.model,
      exams
    })
    setTimeout(() => this.onModelChange(this.model));
  }

  public createArticleActions(): SortableListRowAction[] {
    return [
      {
        icon: faEye,
        tooltip: this.translate.instant('orga-portal.knowledge.tooltips.view-article'),
        click: (item: CoreSharedSortableListItem) => {
          this.articlePreviewSidebar.knowledgeArticleId = item.getExternalData().knowledgeArticleId;
          this.articlePreviewSidebar.onShow();
        }
      },
      {
        icon: faExternalLinkAlt,
        tooltip: this.translate.instant('orga-portal.knowledge.tooltips.edit-article'),
        click: (item: CoreSharedSortableListItem) => {
          const url = this.tenantRouter.createUrlTree(['knowledge/articles', item?.getExternalData()?.knowledgeArticleId], {module: 'communication'});
          window.open(url.toString(), '_blank');
        }
      },
    ];
  }

  public createExamActions(): SortableListRowAction[] {
    return [
      {
        icon: faExternalLinkAlt,
        tooltip: this.translate.instant('orga-portal.knowledge.tooltips.edit-exam'),
        click: (item: CoreSharedSortableListItem) => {
          const url = this.tenantRouter.createUrlTree(['knowledge/exams', item?.getExternalData()?.knowledgeExamId], {module: 'communication'});
          window.open(url.toString(), '_blank');
        }
      },
    ];
  }

  protected setModel(model: KnowledgePathDto): void {
    super.setModel(model);

    this.articlesSubject.next(model?.articles ?? []);
    this.examsSubject.next(model?.exams ?? []);
  }

  protected setReadonly(readonly: boolean): void {
    super.setReadonly(readonly);

    this.articlesComponent?.onReset();
    this.examsComponent?.onReset();
  }

  protected createForm(): FormlyFieldConfig[] {
    return [
      {key: 'contacts'},
      {key: 'functions'},
      {key: 'locations'},
      {key: 'locationGroups'},
      {
        key: 'title',
        type: 'input',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-12',
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.title',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.BASIC
          } as CorePortalFormlyReadonlyTyping,
          type: 'text'
        },
        expressionProperties: {
          'templateOptions.required': () => !this.readonly,
          'templateOptions.disabled': () => this.readonly,
          'templateOptions.readonly': () => this.readonly
        },
        hideExpression: () => !this.creating
      },
      {
        key: 'categories',
        type: 'core-portal-entity-select',
        wrappers: ['core-portal-translated'],
        className: 'col-md-6',
        defaultValue: null,
        templateOptions: {
          corePortalTranslated: {
            label: 'orga-portal.infos.fields.categories'
          },
          entityService: this.categoryService,
          idKey: 'categoryId',
          displayKey: 'name',
          wholeObject: true,
          multiple: true,
          enableCrossCreation: CrossCreationTypes.CATEGORY,
          selectLabelTitleTemplate: this.categorySelectLabelTitleTemplate,
          selectOptionTitleTemplate: this.categorySelectOptionTitleTemplate,
          showAll: true
        },
        expressionProperties: {
          'templateOptions.disabled': () => this.readonly
        }
      },
      {
        key: 'receivers',
        type: 'core-portal-receivers',
        wrappers: ['core-portal-translated'],
        className: 'col-md-6',
        defaultValue: null,
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.receivers'
          },
          corePortalReceivers: {
            model: this.modelSubject
          } as CorePortalFormlyReceiverTyping
        },
        expressionProperties: {
          'templateOptions.disabled': () => this.readonly
        }
      }
    ];
  }
}
