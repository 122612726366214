import { ErrorHandler, Injectable } from '@angular/core';
import { ApiNotificationService } from '../../services';

@Injectable()
export class CoreSharedChunkErrorHandler implements ErrorHandler {
  constructor(
    private apiNotificationService: ApiNotificationService
  ) {
  }

  public handleError(error: any): void {
    if (error?.message?.indexOf('ChunkLoadError') > -1) {
      this.apiNotificationService.showTranslatedError('core-portal.core.error.chunk', null, () => location.reload());
    }
  }
}
