import { PagedEntitiesXsStore, PagedEntitiesXsStoreState } from '@nexnox-web/core-store';
import { AppEntityType, MissionDto } from '@nexnox-web/core-shared';
import { Action, createSelector } from '@ngrx/store';
import { selectResourceTasksState } from '../../resource-tasks.selector';
import { TechPortalFeatureResourceTaskMissionService } from '../../services';
import { Injectable, Injector } from '@angular/core';

export interface ResourceTaskMissionsStoreState extends PagedEntitiesXsStoreState<MissionDto> {
}

/* istanbul ignore next */
export const resourceTaskMissionsStore = new PagedEntitiesXsStore<MissionDto>({
  actionLabel: 'Tech Portal - Resource Tasks - Resource Task Missions',
  stateSelector: createSelector(selectResourceTasksState, state => state.resourceTaskMissions),
  entityType: AppEntityType.Mission,
  serviceType: TechPortalFeatureResourceTaskMissionService,
  selectId: mission => mission.entity?.missionId ?? mission.model?.missionId
});

export function resourceTaskMissionsStoreReducer(state: ResourceTaskMissionsStoreState, action: Action): any {
  return resourceTaskMissionsStore.reducer(state, action);
}

@Injectable()
export class ResourceTaskMissionsStoreEffects extends resourceTaskMissionsStore.effects {
  constructor(
    protected injector: Injector
  ) {
    super(injector);
  }
}
