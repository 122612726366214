import {Injectable} from '@angular/core';
import {AppEntityType, CoreSharedHttpTechRequestService, IMonitorService, ResourceDto} from '@nexnox-web/core-shared';
import {Observable, of} from "rxjs";
import {map} from "rxjs/operators";
import {cloneDeep} from "lodash";

export interface InheritsQuickAccessPreviewPayload {
  entityType: AppEntityType;
}

@Injectable()
export class InheritsQuickAccessPreviewService implements IMonitorService<ResourceDto> {

  constructor(private httpRequestService: CoreSharedHttpTechRequestService) {
  }

  public modifyModel(payload: InheritsQuickAccessPreviewPayload, key: string, model: ResourceDto): Observable<ResourceDto> {

    if (model.inheritsQuickAccessConfiguration === true) {

      // Load a preview
      return this.httpRequestService.post<ResourceDto>(`resource/preview`, {
        entity: payload.entityType,
        parentId: model?.parent?.resourceId,
        stereotypeId: model?.stereotypeId
      }).pipe(
        map(response => response.body),
        map(preview => {
          return {
            ...model,
            quickAccessConfiguration: preview.quickAccessConfiguration
          };
        })
      );
    } else {

      // Delete Ids
      const quickAccessConfiguration = cloneDeep(model.quickAccessConfiguration);

      if(quickAccessConfiguration?.quickAccessConfigurationId) {
        quickAccessConfiguration.quickAccessConfigurationId = 0;
      }

      if(quickAccessConfiguration?.dashboardConfiguration?.quickAccessDashboardConfigurationId) {
        quickAccessConfiguration.dashboardConfiguration.quickAccessDashboardConfigurationId = 0;
      }

      if(quickAccessConfiguration?.issueTemplateConfiguration?.quickAccessIssueTemplateConfigurationId) {
        quickAccessConfiguration.issueTemplateConfiguration.quickAccessIssueTemplateConfigurationId = 0;
      }

      if(quickAccessConfiguration?.knowledgeConfiguration?.quickAccessKnowledgeConfigurationId) {
        quickAccessConfiguration.knowledgeConfiguration.quickAccessKnowledgeConfigurationId = 0;
      }

      return of({...model, quickAccessConfiguration});
    }
  }
}
