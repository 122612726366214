import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injector, Provider } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {CORE_SHARED_ENVIRONMENT, CORE_SHARED_PRODUCT} from '@nexnox-web/core-shared';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { ToasterModule } from 'angular2-toaster';
import { merge } from 'lodash';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ClipboardModule } from 'ngx-clipboard';
import { NgxFilesizeModule } from 'ngx-filesize';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { ImageModule } from 'primeng/image';
import { SidebarModule } from 'primeng/sidebar';
import { TooltipModule } from 'primeng/tooltip';
import { combineLatest, Observable, of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { CoreSharedDeclarationsModule } from './core-shared-declarations.module';
import { TimeoutInterceptor } from './interceptors';
import { Product } from './models';
import { CoreSharedBreadcrumbsModule } from './modules';
import {
  ApiNotificationService,
  ClipboardToastService,
  CoreSharedFileService,
  CoreSharedHttpDataRequestService,
  CoreSharedHttpIdentityRequestService,
  CoreSharedHttpOrgaRequestService,
  CoreSharedHttpRequestService,
  CoreSharedHttpTechRequestService,
  CoreSharedImageService,
  CoreSharedLocalStorageService,
  CoreSharedModalService,
  CoreSharedTextConverterService,
  HttpApiClient,
  HttpApiHandler,
  HttpAuthorizedClient,
  HttpAuthorizedHandler,
  UuidService,
  CoreSharedColorUtilityService,
  CoreSharedColorThemeService,
  CoreSharedKnowledgeArticleService,
  CoreSharedIssueLinkUtilityService
} from './services';

export function httpRequestServiceFactory(
  injector: Injector,
  identityRequestService: CoreSharedHttpIdentityRequestService,
  techRequestService: CoreSharedHttpTechRequestService,
  orgaRequestService: CoreSharedHttpOrgaRequestService,
  dataRequestService: CoreSharedHttpDataRequestService
): CoreSharedHttpRequestService {
  const httpAuthorizedClient = injector.get(HttpAuthorizedClient);
  const httpApiClient = injector.get(HttpApiClient);
  const product = injector.get(CORE_SHARED_PRODUCT);
  const environment = injector.get(CORE_SHARED_ENVIRONMENT);

  switch (product) {
    case Product.IDENTITY:
      return identityRequestService;
    case Product.TECH:
      return techRequestService;
    case Product.ORGA:
      return orgaRequestService;
    case Product.DATA:
      return dataRequestService;
    default:
      if (!window.hasOwnProperty('__karma__')) console.error('HttpRequestService is missing product type definition.');
      return new CoreSharedHttpRequestService(httpAuthorizedClient, httpApiClient, product, environment);
  }
}

const services = [
  {
    provide: CoreSharedHttpRequestService,
    useFactory: httpRequestServiceFactory,
    deps: [
      Injector,
      CoreSharedHttpIdentityRequestService,
      CoreSharedHttpTechRequestService,
      CoreSharedHttpOrgaRequestService,
      CoreSharedHttpDataRequestService
    ]
  },

  CoreSharedHttpIdentityRequestService,
  CoreSharedHttpTechRequestService,
  CoreSharedHttpOrgaRequestService,
  CoreSharedHttpDataRequestService,
  UuidService,
  CoreSharedLocalStorageService,
  ApiNotificationService,
  CoreSharedModalService,
  ClipboardToastService,
  CoreSharedTextConverterService,
  CoreSharedFileService,
  CoreSharedImageService,
  CoreSharedColorUtilityService,
  CoreSharedColorThemeService,
  CoreSharedKnowledgeArticleService,
  CoreSharedIssueLinkUtilityService
];

const interceptors = [
  TimeoutInterceptor
];

const httpHandlers = [
  HttpApiHandler,
  HttpAuthorizedHandler,

  {
    provide: HttpApiClient,
    useFactory: httpApiClientFactory,
    deps: [HttpApiHandler]
  },
  {
    provide: HttpAuthorizedClient,
    useFactory: httpAuthorizedClientFactory,
    deps: [HttpAuthorizedHandler]
  }
];

export function httpApiClientFactory(httpApiHandler: HttpApiHandler): HttpApiClient {
  return new HttpApiClient(httpApiHandler);
}

export function httpAuthorizedClientFactory(httpAuthorizedHandler: HttpAuthorizedHandler): HttpAuthorizedClient {
  return new HttpAuthorizedClient(httpAuthorizedHandler);
}

export class CoreSharedTranslateHttpLoader implements TranslateLoader {
  constructor(
    private appName: string,
    private additionalTranslations: string[],
    private httpClient: HttpClient
  ) {
  }

  public getTranslation(language: string): Observable<any> {
    return this.httpClient.get(`./assets/i18n/core-shared/${language}.json`).pipe(
      mergeMap(coreSharedTranslation => combineLatest([
        of({}),
        ...this.additionalTranslations.map(additionalTranslation =>
          this.httpClient.get(`./assets/i18n/${additionalTranslation}/${language}.json`))
      ]).pipe(
        mergeMap(additionalTranslations => this.httpClient.get(`./assets/i18n/${this.appName}/${language}.json`).pipe(
          map(appTranslation => {
            const mergedTranslation = { ...coreSharedTranslation, ...appTranslation };

            for (const additionalTranslation of additionalTranslations) {
              merge(mergedTranslation, additionalTranslation);
            }

            return mergedTranslation;
          })
        ))
      ))
    );
  }
}

export const coreSharedTranslateHttpLoaderFactory = (
  appName: string = null,
  additionalTranslations: string[] = [],
  httpClient: HttpClient
): CoreSharedTranslateHttpLoader => new CoreSharedTranslateHttpLoader(appName, additionalTranslations, httpClient);

export const sharedModules = [
  CommonModule,
  FontAwesomeModule,
  NgSelectModule,
  BsDatepickerModule,
  BsDropdownModule,
  ClipboardModule,
  RouterModule,
  TranslateModule,
  NgxFilesizeModule,
  SidebarModule,
  ConfirmPopupModule,
  CheckboxModule,
  TooltipModule,
  CalendarModule,
  ImageModule
];

export const exportedModules = [
  CoreSharedDeclarationsModule,
  TranslateModule,
  ToasterModule,
  CoreSharedBreadcrumbsModule,
  NgxSkeletonLoaderModule
];

export const sharedProviders: Provider[] = [
  ...services,
  ...interceptors,
  ...httpHandlers
];
