import { NgModule } from '@angular/core';
import {
  CompanyAddressListComponent,
  CompanyContactListComponent,
  CompanyDetailComponent,
  CorePortalFeatureMasterDataCompanyListComponent,
  CompanyAddressDetailStandaloneComponent
} from './containers';
import { CompanyAddressBreadcrumbsResolver } from "./resolvers";
import { CorePortalFeatureMasterDataCompanyEditComponent } from './components';
import { companiesServices } from './store';
import { CorePortalEntityModule } from '@nexnox-web/core-portal';
import { CoreSharedModule } from '@nexnox-web/core-shared';
import { CorePortalFeatureMasterDataAddressesModule } from '@nexnox-web/core-portal/features/master-data/features/addresses';
import { CorePortalFeatureMasterDataLocationsModule } from '@nexnox-web/core-portal/features/master-data/features/locations';


@NgModule({
  declarations: [
    CorePortalFeatureMasterDataCompanyListComponent,
    CompanyDetailComponent,
    CompanyContactListComponent,
    CompanyAddressListComponent,
    CompanyAddressDetailStandaloneComponent,

    CorePortalFeatureMasterDataCompanyEditComponent
  ],
  imports: [
    CoreSharedModule,
    CorePortalEntityModule,

    CorePortalFeatureMasterDataLocationsModule.forFeature(),
    CorePortalFeatureMasterDataAddressesModule.forFeature()
  ],
  providers: [
    ...companiesServices,
    CompanyAddressBreadcrumbsResolver
  ],
  exports: [
    CorePortalFeatureMasterDataCompanyEditComponent
  ]
})
export class CompaniesDeclarationsModule {
}
