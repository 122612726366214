import {
  CoreSharedHttpDataRequestService,
  CoreSharedHttpIdentityRequestService,
  CoreSharedHttpOrgaRequestService,
  CoreSharedHttpRequestService,
  CoreSharedHttpTechRequestService
} from '../http';
import {Observable} from 'rxjs';
import {
  AppEntityType,
  ContextCloak,
  Environment,
  FilterDto,
  FilterOperations,
  PageableRequest,
  Product,
  SortObject,
  StereotypedShadowDto,
  StereotypeDto
} from '../../models';
import {Query} from '../../utils';
import {map} from 'rxjs/operators';
import {Injector} from '@angular/core';
import {CORE_SHARED_ENVIRONMENT} from '../../tokens';
import {isNull, isUndefined, zip} from 'lodash';
import {CoreSharedApplicationUuidService} from '../application-uuid/application-uuid.service';
import {HttpParams} from '@angular/common/http';

export abstract class CoreSharedApiBaseService {
  protected httpRequestService: CoreSharedHttpRequestService;
  protected environment: Environment;
  protected applicationUuidService: CoreSharedApplicationUuidService;

  protected resource: string;
  protected parentResources: string[];

  protected useFilterUrl = true;
  protected useFilterOperation = true;
  protected mapFiltersToBody: (filters: FilterDto[]) => any = filters => filters;

  protected constructor(
    protected injector: Injector,
    product: Product,
    resource: string,
    parentResources: string[] = []
  ) {
    this.environment = injector.get(CORE_SHARED_ENVIRONMENT);
    this.applicationUuidService = injector.get(CoreSharedApplicationUuidService);

    this.httpRequestService = (() => {
      switch (product) {
        case Product.IDENTITY:
          return injector.get(CoreSharedHttpIdentityRequestService);
        case Product.TECH:
          return injector.get(CoreSharedHttpTechRequestService);
        case Product.ORGA:
          return injector.get(CoreSharedHttpOrgaRequestService);
        case Product.DATA:
          return injector.get(CoreSharedHttpDataRequestService);
        default:
          return injector.get(CoreSharedHttpRequestService);
      }
    })();

    this.resource = resource;
    this.parentResources = parentResources;
  }

  public getPage<T>(
    sortOptions: SortObject = null,
    pageNumber: number = 1,
    filters: FilterDto[] = [],
    operation: FilterOperations = FilterOperations.Include,
    customColumns: number[] = [],
    optionalColumns: string[] = [],
    pageSize: number = this.environment.defaultPageSize,
    parentIds: Array<string | number> = [],
    viewId?: number,
    useAllToken: boolean = false,
    contextCloak?: ContextCloak,
    queryParams?: { [key: string]: number[] | string[] }
  ): Observable<PageableRequest<T>> {
    const query = new Query()
      .add('operation', this.useFilterOperation ? operation : null)
      .add('sortField', sortOptions ? sortOptions.sortField : null)
      .add('sort', sortOptions ? sortOptions.sort : null)
      .add('pageNumber', pageNumber)
      .add('pageSize', pageSize)
      .add('as', !isUndefined(contextCloak) ? contextCloak : null)
      .add('viewId', viewId);

    if (queryParams) {
      for (const key in queryParams) {
        if (queryParams.hasOwnProperty(key)) {
          const values = queryParams[key];
          query.add(key, values.join(','));
        }
      }
    }

    for (const customColumn of customColumns) {
      query.add('customCols', customColumn);
    }
    for (const optionalColumn of optionalColumns) {
      query.add('cols', optionalColumn);
    }

    let params = new HttpParams();

    // ToDo: Eventually remove this once it's not needed anymore
    if (useAllToken) {
      params = params.set('useAllToken', 'true');
    }

    const queryString = query.build();
    const path = `${this.buildPagePath(parentIds, false)}/${this.useFilterUrl ? 'filter' : ''}${queryString}`;

    return this.httpRequestService.post<PageableRequest<T>>(
      path,
      this.mapFiltersToBody(filters),
      null,
      params.keys().length ? params : null
    ).pipe(
      map(response => response.body)
    );
  }


  public getStereotypes(entityType: AppEntityType = null, excludeArchived: boolean = false): Observable<StereotypeDto[]> {
    const query = new Query('stereotype')
      .add('entityType', entityType)
      .add('cols', 'CustomPropertySets')
      .add('cache-control', this.applicationUuidService.getUuid())
      .build();

    return this.httpRequestService.get<PageableRequest<StereotypeDto>>(query).pipe(
      map(response => (response.body ? response.body.items : []).filter(x => excludeArchived ? !x.isArchived : true))
    );
  }

  public getPreview(entityType: AppEntityType, parentId: number | string, stereotypeId: number | string): Observable<any> {
    return this.httpRequestService.post<StereotypedShadowDto>(`${this.buildPath()}/preview`, {
      entity: entityType,
      parentId,
      stereotypeId
    }).pipe(
      map(response => response.body)
    );
  }

  public getOne<T>(id: number | string, parentIds?: Array<number | string>): Observable<T> {
    return this.httpRequestService.get<T>(this.buildPathForOne(id, parentIds)).pipe(
      map(response => response.body)
    );
  }

  public createOne<T>(data: T, parentIds?: Array<number | string>): Observable<T> {
    return this.httpRequestService.post<T>(this.buildPath(parentIds), data).pipe(
      map(response => response.body)
    );
  }

  public saveOne<T>(id: number | string, data: T, parentIds?: Array<string | number>, queryParams?: [string, any][]): Observable<T> {
    const query = new Query(this.buildPathForOne(id, parentIds));

    for (const queryParam of queryParams ?? []) {
      query.add(queryParam[0], queryParam[1]);
    }

    return this.httpRequestService.put<T>(query.build(), data).pipe(
      map(response => response.body)
    );
  }

  public addOneToParent<T>(parentIds: Array<number | string>, id: number | string): Observable<T> {
    return this.httpRequestService.post<T>(this.buildPathForOne(id, parentIds), {}, null, null, true).pipe(
      map(response => response.body)
    );
  }

  public removeOneFromParent(parentIds: Array<number | string>, id: number | string): Observable<any> {
    return this.httpRequestService.delete(this.buildPath(parentIds), id, null, null, true).pipe(
      map(response => response.body)
    );
  }

  public deleteOne<T>(id: number | string, parentIds?: Array<number | string>): Observable<T> {
    return this.httpRequestService.delete<T>(this.buildPathForOne(id, parentIds)).pipe(
      map(response => response.body)
    );
  }

  protected buildPagePath(parentIds: Array<number | string> = [], addTrailing: boolean = true): string {
    return this.buildPath(parentIds, addTrailing);
  }

  protected buildPath(parentIds: Array<number | string> = [], addTrailing: boolean = true, suffix: string = null): string {
    let path = `${this.resource}${!!suffix ? ('/' + suffix) : ''}`;

    if (this.parentResources?.length) {
      const zipped = zip(this.parentResources, parentIds).reverse();

      for (let i = 0; i < zipped.length; i++) {
        const [parentResource, parentId] = zipped[i];
        const isUndefinedOrNull = (value): boolean => isUndefined(value) || isNull(value);

        if (!isUndefinedOrNull(parentResource) && !isUndefinedOrNull(parentId)) {
          path = `${parentResource}/${parentId}/${path}`;
        } else if (isUndefinedOrNull(parentResource) && !isUndefinedOrNull(parentId)) {
          path = `${parentId}/${path}`;
        } else if (!isUndefinedOrNull(parentResource) && isUndefinedOrNull(parentId)) {
          path = `${parentResource}/${path}`;
        }

        if (i < zipped.length - 1 && addTrailing) {
          path += '/';
        }
      }
    }

    path = path.replace(/\/{2}/g, '/');
    if (!addTrailing && path.endsWith('/')) {
      path = path.slice(0, path.length - 1);
    }

    return path;
  }

  protected buildPathForOne(id: number | string, parentIds: Array<number | string> = []): string {
    if (!id) {
      return this.buildPath(parentIds, false);
    }

    return `${this.buildPath(parentIds, false)}/${id}`;
  }
}
