import { CoreSharedApiBaseService, MissionByInspectionDto, MissionDto, Product } from '@nexnox-web/core-shared';
import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class CorePortalCCPControlPointService extends CoreSharedApiBaseService {
  constructor(
    protected injector: Injector
  ) {
    super(injector, Product.TECH, 'v2/controlpoint');
  }

  public createMission(id: number | string, data: MissionDto): Observable<MissionDto> {
    return this.httpRequestService.post<MissionDto>(`${this.buildPathForOne(id)}/mission`, data).pipe(
      map(response => response.body)
    );
  }

  public createMissionByInspection(data: MissionByInspectionDto): Observable<MissionDto> {
    return this.httpRequestService.post<MissionByInspectionDto>('mission/byinspection/create', data).pipe(
      map(response => response.body)
    );
  }

  public startMissionByInspection(data: MissionByInspectionDto): Observable<MissionDto> {
    return this.httpRequestService.post<MissionByInspectionDto>('mission/byinspection/start', data).pipe(
      map(response => response.body)
    );
  }
}
