import {ChangeDetectionStrategy, Component, Injector} from '@angular/core';
import {ActionButton, CorePortalEntityDetailBaseComponent} from '@nexnox-web/core-portal';
import {AppPermissions, HintCodeDto} from '@nexnox-web/core-shared';
import {hintCodeDetailStore} from '../../store/stores';

@Component({
  selector: 'nexnox-web-connected-hint-codes-hint-code-detail',
  templateUrl: './hint-code-detail.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HintCodeDetailComponent extends CorePortalEntityDetailBaseComponent<HintCodeDto> {
  public title = 'tech-portal.connected.hint-codes.subtitles.detail';

  protected idParam = 'hintCodeId';
  protected displayKey = 'displayName';

  constructor(
    protected injector: Injector
  ) {
    super(injector, hintCodeDetailStore);
  }

  protected async getActionButtons(): Promise<ActionButton[]> {
    return this.getDefaultActionButtons(
      'tech-portal.connected.hint-codes.actions.edit',
      'tech-portal.connected.hint-codes.actions.save',
      'tech-portal.connected.hint-codes.actions.delete',
      'tech-portal.connected.hint-codes.descriptions.delete',
      AppPermissions.UpdateHintCode,
      AppPermissions.DeleteHintCode,
      undefined,
      undefined
    );
  }
}
