import {PagedEntitiesXsStore, PagedEntitiesXsStoreState} from '@nexnox-web/core-store';
import {AppEntityType, KnowledgePathProgressContactListDto} from '@nexnox-web/core-shared';
import {createSelector} from '@ngrx/store';
import {PathsProgressContactService} from '../../services';
import {selectKnowledgePathState} from "../../paths.selectors";

export interface PathsProgressContactListStoreState extends PagedEntitiesXsStoreState<KnowledgePathProgressContactListDto> {
}

/* istanbul ignore next */
export const pathsProgressContactListStore = new PagedEntitiesXsStore<KnowledgePathProgressContactListDto>({
  actionLabel: 'Orga Portal - Paths - Path Progress Contact List',
  stateSelector: createSelector(selectKnowledgePathState, state => state.pathsProgressContactList),
  serviceType: PathsProgressContactService,
  defaultPageSize: 11,
  entityType: AppEntityType.None,
  selectId: pathsProgressContact => JSON.stringify([
    pathsProgressContact.entity?.contactId ?? pathsProgressContact?.model?.contactId
  ]),
  stereotyped: false
});
