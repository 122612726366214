<div class="d-flex flex-row align-items-center">
  <ng-select #selectComponent
             class="d-flex ng-form-select"
             [class.layoutText]="options.layout === layouts.TEXT"
             [class.layoutHeadline]="options.layout === layouts.HEADLINE"
             [class.inline]="!options?.showAll"
             [class.has-error]="showError"
             [ngModel]="model$ | async"
             [virtualScroll]="true"
             [items]="items$ | async"
             [loading]="(loading$ | async) || (initializing$ | async)"
             [disabled]="disabled || (initializing$ | async) || (waiting$ | async) || ((loading$ | async) && !selectComponent.isOpen) || (options?.editable && (editableSubject | async) === false)"
             [searchable]="true"
             [clearable]="isClearable()"
             [multiple]="options.multiple"
             [closeOnSelect]="!options.multiple"
             [addTag]="(loading$ | async) === false ? addTagFn : false"
             [addTagText]="'core-shared.shared.select.add-tag-text' | translate"
             [clearAllText]="'core-shared.shared.select.clear' | translate"
             [searchFn]="options?.search ? options.search : searchFn"
             [loadingText]="'core-portal.core.general.loading' | translate"
             [bindValue]="options && !options.wholeObject ? options.idKey : undefined"
             [compareWith]="options?.compareWith ? options.compareWith : compareWithFn"
             [appendTo]="options.appendTo"
             [placeholder]="options?.placeholder ? (options.placeholder | translate) : undefined"
             (ngModelChange)="onModelChange($event)"
             (open)="onOpen()"
             (close)="onClose()"
             (scrollToEnd)="onScrollToEnd()"
             (search)="onSearch($event.term)"
             (clear)="onClear()">
    <ng-template *ngIf="options.multiple"
                 ng-header-tmp
                 let-searchTerm="searchTerm">
      <div class="d-flex align-items-center">
        <input id="searchInput"
               type="text"
               class="form-control"
               [placeholder]="'core-shared.shared.select.search' | translate"
               nexnoxWebAutofocus
               [ngModel]="searchTerm"
               (ngModelChange)="selectComponent.filter($event)">

        <button *ngIf="searchTerm"
                class="btn"
                [title]="'core-shared.shared.select.clear' | translate"
                (click)="selectComponent.filter(null)">
          <fa-icon [icon]="faTimes"></fa-icon>
        </button>
      </div>
    </ng-template>

    <ng-template *ngIf="!options.multiple"
                 ng-label-tmp
                 let-item="item"
                 let-clear="clear">
      <ng-container *ngTemplateOutlet="sharedTemplate; context: {
        item: item,
        clear: clear
      }"></ng-container>
    </ng-template>

    <ng-template *ngIf="options.multiple"
                 ng-multi-label-tmp
                 let-items="items"
                 let-clear="clear">
      <ng-container *ngIf="items.length < 2 || options?.showAll">
        <ng-container *ngFor="let item of (!options?.showAll ? (items |  slice:0:1) : items)"
                      [ngTemplateOutlet]="multipleTemplate"
                      [ngTemplateOutletContext]="{
                        item: item,
                        clear: clear
                      }">
        </ng-container>
      </ng-container>

      <div *ngIf="items.length > 1 && !options?.showAll"
           class="d-flex flex-row align-items-center multi-select-container px-2">
        <span class="default-text-container">
          {{ 'core-shared.shared.select.selected' | translate:{amount: items.length} }}
        </span>
      </div>
    </ng-template>

    <ng-template ng-option-tmp
                 let-item="item"
                 let-searchTerm="searchTerm">
      <ng-container *ngIf="options.selectOptionTitleTemplate; else titleTemplate"
                    [ngTemplateOutlet]="options.selectOptionTitleTemplate"
                    [ngTemplateOutletContext]="{
                      item: item,
                      searchTerm: searchTerm
                    }">
      </ng-container>

      <ng-template #titleTemplate>
        <span *ngIf="!options.minimal" class="text-muted">{{ item[options.idKey] }}: </span>
        <ng-container *ngIf="(options.template ? options.template(item) : item[options.displayKey]) as itemValue">
          <span [title]="itemValue">
            <span [innerHTML]="itemValue | nexnoxWebHighlight:searchTerm"></span>
          </span>
        </ng-container>
      </ng-template>

      <ng-container *ngIf="options.selectOptionTemplate"
                    [ngTemplateOutlet]="options.selectOptionTemplate"
                    [ngTemplateOutletContext]="{
                      item: item,
                      searchTerm: searchTerm
                    }">
      </ng-container>
    </ng-template>

    <ng-container *ngIf="paging$ | async as paging">
      <ng-template *ngIf="paging.pageNumber < paging.totalPages"
                   ng-footer-tmp>
        <button type="button"
                class="btn btn-sm w-100 btn-outline-primary"
                [disabled]="loading$ | async"
                (click)="onScrollToEnd()">
          {{ 'core-shared.shared.pagination.more' | translate }}
        </button>
      </ng-template>
    </ng-container>

    <ng-template ng-notfound-tmp>
      <div class="ng-option disabled">
        {{ 'core-shared.shared.table.empty' | translate }}
      </div>
    </ng-template>
  </ng-select>

  <div class="d-flex flex-row flex-shrink-0">
    <button *ngIf="options.editable && (editableSubject | async) === false"
            type="button"
            class="btn btn-link datatable-btn pr-0 pl-2"
            [title]="'core-shared.shared.select.edit' | translate"
            (click)="onEdit($event)">
      <fa-icon [icon]="faPencilAlt"
               [fixedWidth]="true"
               size="sm"></fa-icon>
    </button>

    <button *ngIf="options.datatableSearch && (options.editable ? (editableSubject | async) : true)"
            type="button"
            class="btn btn-link datatable-btn pr-0 pl-2"
            [title]="'core-shared.shared.select.advanced-search' | translate"
            (click)="onDatatableSearch()">
      <fa-icon [icon]="faSearch"
               [fixedWidth]="true"
               size="sm"></fa-icon>
    </button>

    <a *ngIf="options.link && options.link(model$ | async) as link"
       class="btn btn-link datatable-btn pr-0 pl-2"
       [nexnoxWebTenantRouterLink]="link"
       [module]="options.module"
       target="_blank">
      <fa-icon [icon]="faExternalLinkAlt"
               [fixedWidth]="true"
               size="sm"></fa-icon>
    </a>
  </div>
</div>

<ng-template #multipleTemplate
             let-item="item"
             let-clear="clear">
  <div class="d-flex flex-row align-items-center multi-select-container px-2"
       [class.mr-2]="options?.showAll"
       [class.mb-1]="options?.showAll">
    <span *ngIf="!options?.minimal"
          class="multi-select-clear ng-clear mr-2"
          [title]="'core-shared.shared.select.clear' | translate"
          (click)="clear(item)">
      ×
    </span>

    <ng-container *ngTemplateOutlet="sharedTemplate; context: { item: item }"></ng-container>
  </div>
</ng-template>

<ng-template #sharedTemplate
             let-item="item">
  <ng-container *ngIf="options.selectLabelTitleTemplate; else titleTemplate"
                [ngTemplateOutlet]="options.selectLabelTitleTemplate"
                [ngTemplateOutletContext]="{
                  item: item
                }">
  </ng-container>

  <ng-template #titleTemplate>
    <ng-container *ngIf="(options.template ? options.template(item) : item[options.displayKey]) as itemValue">
      <span *ngIf="itemValue && !options?.minimal; else noIdTitleTemplate"
            title="{{ item[options.idKey] }}: {{ itemValue }}"
            class="default-text-container">
        <span class="text-muted">{{ item[options.idKey] }}: </span>
        <span>{{ itemValue }}</span>
      </span>

      <ng-template #noIdTitleTemplate>
        <span [title]="itemValue"
              class="default-text-container">
          {{ itemValue }}
        </span>
      </ng-template>
    </ng-container>
  </ng-template>

  <ng-container *ngIf="options.selectLabelTemplate"
                [ngTemplateOutlet]="options.selectLabelTemplate"
                [ngTemplateOutletContext]="{
                  item: item
                }">
  </ng-container>
</ng-template>

<nexnox-web-cross-creation-sidebar #crossCreationSidebar
                                   [crossCreationType]="options.enableCrossCreation">
</nexnox-web-cross-creation-sidebar>
