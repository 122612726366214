<ng-template #entityEditTemplate
             let-title="title">
  <nexnox-web-settings-gdpr-edit #editComponent
                                 [title]="title"
                                 [model]="createModel$ | async"
                                 [readonly]="false"
                                 [creating]="true"
                                 [stereotyped]="false"
                                 [loading]="(loaded$ | async) === false"
                                 (modelChange)="createModel$.next($event)">
  </nexnox-web-settings-gdpr-edit>
</ng-template>

<nexnox-web-entity-overview #overviewComponent
                            [entityOverviewBaseComponent]="this"
                            [entityEditTemplate]="entityEditTemplate"
                            [stereotyped]="false"
                            detailLink="gdpr"
                            module="settings">
</nexnox-web-entity-overview>
