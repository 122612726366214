import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {faUser} from '@fortawesome/free-solid-svg-icons/faUser';
import {
  BasicTableColumnType,
  BasicTableConfig,
  ContactSimpleDto,
  CoreSharedSidebarBaseComponent,
  EditorQuestDto,
  EditorQuestState,
  EditorRequestDto,
  EditorRequestState,
  FunctionDto
} from '@nexnox-web/core-shared';
import {Observable, of} from "rxjs";
import {map, mergeMap} from "rxjs/operators";
import {TranslateService} from "@ngx-translate/core";
import dayjs from 'dayjs';
import {faUserSlash} from "@fortawesome/free-solid-svg-icons/faUserSlash";
import {faUserCheck} from '@fortawesome/free-solid-svg-icons/faUserCheck';
import {faTimes} from '@fortawesome/free-solid-svg-icons/faTimes';
import {faArrowCircleRight} from "@fortawesome/free-solid-svg-icons/faArrowCircleRight";
import {faUsers} from '@fortawesome/free-solid-svg-icons/faUsers';

@Component({
  selector: 'nexnox-web-missions-editor-quest-sidebar',
  templateUrl: './editor-quest-sidebar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditorQuestSidebarComponent extends CoreSharedSidebarBaseComponent implements OnInit {

  @Input() public quest$: Observable<EditorQuestDto> = of(null);
  @Input() public currentSolutionContact$: Observable<ContactSimpleDto> = of(null);
  @Input() public loading: boolean;

  @Output() public startQuest: EventEmitter<FunctionDto> = new EventEmitter<FunctionDto>();
  @Output() public cancelQuest: EventEmitter<EditorQuestDto> = new EventEmitter<EditorQuestDto>();
  @Output() public restartQuest: EventEmitter<EditorQuestDto> = new EventEmitter<EditorQuestDto>();
  @Output() public acceptContact: EventEmitter<ContactSimpleDto> = new EventEmitter<ContactSimpleDto>();
  @Output() public declineContact: EventEmitter<ContactSimpleDto> = new EventEmitter<ContactSimpleDto>();

  public config: BasicTableConfig;
  public questStates = EditorQuestState;

  public title$: Observable<string>;
  public info$: Observable<string>;

  public faUser = faUser;
  public faUsers = faUsers;
  public faUserCheck = faUserCheck;
  public faUsersSlash = faUserSlash;
  public faTimes = faTimes;
  public faArrowCircleRight = faArrowCircleRight;

  constructor(private translate: TranslateService) {
    super();
  }

  public ngOnInit(): void {

    this.title$ = this.quest$.pipe(
      mergeMap(quest => this.translate.stream('missions.quest-label-states.' + quest?.state, {functionName: quest?.functionProperty?.name}))
    );

    this.info$ = this.quest$.pipe(
      mergeMap(quest => quest?.creator?.name ?
        this.translate.stream('missions.descriptions.mission-quest-info', {
          userName: quest?.creator?.name,
          dateTime: dayjs(quest?.createdAt).format('DD.MM.YY HH:mm')
        }) :
        this.translate.stream('missions.descriptions.mission-quest-info-automatic', {
          dateTime: dayjs(quest?.createdAt).format('DD.MM.YY HH:mm')
        })
      ));

    // Mapping translated status message
    this.quest$ = this.quest$.pipe(map(quest => ({
      ...quest,
      requests: quest?.requests?.map(r => ({...r, statusMessage: 'missions.quest-request-states.' + r.state, questState: quest.state}))
    })));

    this.config = {
      columns: [
        {
          key: 'contact.displayName',
          header: 'core-shared.shared.fields.contact',
          type: BasicTableColumnType.Link,
          link: (row) => row?.contact?.contactId ? {
            commands: ['masterdata', 'contacts', row.contact.contactId],
            module: 'management'
          } : null
        },
        {
          key: 'statusMessage',
          header: 'core-shared.shared.fields.mission-state',
          type: BasicTableColumnType.TextTranslation
        }
      ],
      actions: [
        {
          icon: this.faUserCheck,
          tooltip: 'missions.actions.instruct-contact',
          label: 'missions.actions.instruct',
          class: 'p-button-secondary p-button-outlined',
          click: (row) => this.onAcceptContact(row.contact),
          disabled: (row) => this.isAcceptButtonDisabled(row),
        },
        {
          icon: this.faUsersSlash,
          tooltip: 'missions.actions.decline-contact',
          label: 'missions.actions.decline',
          class: 'p-button-secondary p-button-outlined',
          click: (row) => this.onDeclineContact(row.contact),
          disabled: (row) => this.isDeclineButtonDisabled(row)
        }
      ]
    };
  }

  public onHide(): void {
    super.onHide();
  }

  public onStartQuest(quest: EditorQuestDto): void {
    const functn = (quest as any).function ?? quest.functionProperty;
    this.startQuest.emit(functn);
    this.onHide();
  }

  public onDeclineContact(contact: ContactSimpleDto): void {
    this.declineContact.emit(contact);
  }

  public onAcceptContact(contact: ContactSimpleDto): void {
    this.acceptContact.emit(contact);
    this.onHide();
  }

  public onCancelQuest(quest: EditorQuestDto): void {
    this.cancelQuest.emit(quest);
    this.onHide();
  }

  public onRestartQuest(quest: EditorQuestDto): void {
    this.restartQuest.emit(quest);
    this.onHide();
  }

  public isDeclineButtonDisabled(row: EditorRequestDto): boolean {
    return row.state !== EditorRequestState.Untouched || (row as any)?.questState !== EditorQuestState.InProcess;
  }

  public isAcceptButtonDisabled(row: EditorRequestDto): boolean {
    return row.state !== EditorRequestState.Untouched || (row as any)?.questState !== EditorQuestState.InProcess;
  }
}
