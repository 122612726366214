import { PagedEntitiesXsStore, PagedEntitiesXsStoreState } from '@nexnox-web/core-store';
import { AppEntityType, TaskDto } from '@nexnox-web/core-shared';
import { createSelector } from '@ngrx/store';
import { selectTasksState } from '../../tasks.selectors';
import { OrgaPortalFeatureTaskService } from '../../services';

export interface TaskListStoreState extends PagedEntitiesXsStoreState<TaskDto> {
}

/* istanbul ignore next */
export const taskListStore = new PagedEntitiesXsStore<TaskDto>({
  actionLabel: 'Orga Portal - Tasks - Task List',
  stateSelector: createSelector(selectTasksState, state => state.taskList),
  serviceType: OrgaPortalFeatureTaskService,
  entityType: AppEntityType.Task,
  selectId: task => task.entity?.taskId ?? task.model?.taskId,
  stereotyped: false,
  sanitizeModel: OrgaPortalFeatureTaskService.sanitizeModel
});
