import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChildren
} from '@angular/core';
import { BehaviorSubject, merge } from 'rxjs';
import { cloneDeep, flatMap, flatten, groupBy, isEqual, map, remove, uniqWith, values } from 'lodash';
import { faAngleDoubleLeft } from '@fortawesome/free-solid-svg-icons/faAngleDoubleLeft';
import { faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons/faAngleDoubleRight';
import { faSearch } from '@fortawesome/free-solid-svg-icons/faSearch';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons/faAngleLeft';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons/faAngleRight';
import { NgSelectComponent } from '@ng-select/ng-select';
import { UnsubscribeHelper } from '../../helper';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons/faArrowUp';
import { faArrowDown } from '@fortawesome/free-solid-svg-icons/faArrowDown';
import { TranslateService } from '@ngx-translate/core';

export interface DualListBoxItemBase {
  title: string;
  suffix?: string;
  translated: boolean;
  externalId?: string | number;
  position?: number;
  getExternalData?: (...args: any[]) => any;
}

export interface DualListBoxItem extends DualListBoxItemBase {
  items?: DualListBoxItemBase[];
}

@Component({
  selector: 'nexnox-web-dual-list-box',
  templateUrl: './dual-list-box.component.html',
  styleUrls: ['./dual-list-box.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DualListBoxComponent extends UnsubscribeHelper implements OnInit, AfterViewInit {
  @Input() public disabled: boolean;
  @Input() public translate: boolean;

  @Output() public availableItemsChange: EventEmitter<DualListBoxItem[]> = new EventEmitter<DualListBoxItem[]>();
  @Output() public activeItemsChange: EventEmitter<DualListBoxItem[]> = new EventEmitter<DualListBoxItem[]>();

  @ViewChildren('ngSelectComponent') private selectComponents: QueryList<NgSelectComponent>;

  public faAngleLeft = faAngleLeft;
  public faAngleDoubleLeft = faAngleDoubleLeft;
  public faAngleRight = faAngleRight;
  public faAngleDoubleRight = faAngleDoubleRight;
  public faArrowUp = faArrowUp;
  public faArrowDown = faArrowDown;
  public faSearch = faSearch;

  public searchFn: any;

  public allItems$: BehaviorSubject<DualListBoxItem[]> = new BehaviorSubject<DualListBoxItem[]>([]);
  public availableItems$: BehaviorSubject<DualListBoxItem[]> = new BehaviorSubject<DualListBoxItem[]>([]);
  public activeItems$: BehaviorSubject<DualListBoxItem[]> = new BehaviorSubject<DualListBoxItem[]>([]);

  public markedAvailableItems$: BehaviorSubject<DualListBoxItem[]> = new BehaviorSubject<DualListBoxItem[]>([]);
  public markedActiveItems$: BehaviorSubject<DualListBoxItem[]> = new BehaviorSubject<DualListBoxItem[]>([]);

  public moveRightEnabled$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public moveAllRightEnabled$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public moveLeftEnabled$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public moveAllLeftEnabled$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public availableItemsSearch$: BehaviorSubject<[string, NgSelectComponent]> =
    new BehaviorSubject<[string, NgSelectComponent]>(['', null]);
  public activeItemsSearch$: BehaviorSubject<[string, NgSelectComponent]> =
    new BehaviorSubject<[string, NgSelectComponent]>(['', null]);

  @Input()
  public set availableItems(items: DualListBoxItem[]) {
    this.setItems(items, this.availableItems$);
  }

  @Input()
  public set activeItems(items: DualListBoxItem[]) {
    this.setItems(items, this.activeItems$);
  }

  public static isItemIdEqual(a: DualListBoxItem, b: DualListBoxItem): boolean {
    return isEqual(DualListBoxComponent.getItemId(a), DualListBoxComponent.getItemId(b));
  }

  public static getItemId(item: DualListBoxItem): string {
    return `${item.title}|${item.externalId}`;
  }

  constructor(
    private translateService: TranslateService
  ) {
    super();

    this.searchFn = (term: string, item: DualListBoxItem) => this.search(term, item);
  }

  public ngOnInit(): void {
    this.subscribeToSearchInputs();
  }

  public ngAfterViewInit(): void {
    this.setMoveAllState();
  }

  public onMarkedChanged(items: DualListBoxItem[], model$: BehaviorSubject<DualListBoxItem[]>): void {
    model$.next(items);
    this.setMoveStates();
  }

  public onMoveAllRight(): void {
    this.onMarkAllItems(this.markedAvailableItems$, this.availableItems$);
    this.onMoveRight();
  }

  public onMoveRight(): void {
    this.moveItems(this.availableItems$, this.markedAvailableItems$, this.activeItems$, this.availableItemsChange, this.activeItemsChange);
  }

  public onMoveAllLeft(): void {
    this.onMarkAllItems(this.markedActiveItems$, this.activeItems$);
    this.onMoveLeft();
  }

  public onMoveLeft(): void {
    this.moveItems(this.activeItems$, this.markedActiveItems$, this.availableItems$, this.activeItemsChange, this.availableItemsChange);
  }

  public onMoveItemUp(
    event: MouseEvent,
    item: DualListBoxItem,
    items$: BehaviorSubject<DualListBoxItem[]>,
    emitter: EventEmitter<DualListBoxItem[]>
  ): void {
    event.preventDefault();
    event.stopPropagation();

    const items = items$.getValue();
    const selectedGroupIndex = items.findIndex(x => x.items.find(y => DualListBoxComponent.isItemIdEqual(y, item)));

    if (selectedGroupIndex > -1) {
      const selectedItemIndex = items[selectedGroupIndex].items.findIndex(x => DualListBoxComponent.isItemIdEqual(x, item));

      if (selectedItemIndex > -1) {
        items[selectedGroupIndex].items[selectedItemIndex].position--;
        items[selectedGroupIndex].items[selectedItemIndex - 1].position++;
        emitter.observers.length ? emitter.emit(items) : this.setItems(items, items$);
      }
    }
  }

  public onMoveItemDown(
    event: MouseEvent,
    item: DualListBoxItem,
    items$: BehaviorSubject<DualListBoxItem[]>,
    emitter: EventEmitter<DualListBoxItem[]>
  ): void {
    event.preventDefault();
    event.stopPropagation();

    const items = items$.getValue();
    const selectedGroupIndex = items.findIndex(x => x.items.find(y => DualListBoxComponent.isItemIdEqual(y, item)));

    if (selectedGroupIndex > -1) {
      const selectedItemIndex = items[selectedGroupIndex].items.findIndex(x => DualListBoxComponent.isItemIdEqual(x, item));

      if (selectedItemIndex > -1) {
        items[selectedGroupIndex].items[selectedItemIndex].position++;
        items[selectedGroupIndex].items[selectedItemIndex + 1].position--;
        emitter.observers.length ? emitter.emit(items) : this.setItems(items, items$);
      }
    }
  }

  public onMarkAllItems(markItems$: BehaviorSubject<DualListBoxItem[]>, items$: BehaviorSubject<DualListBoxItem[]>): void {
    markItems$.next(this.getAllItems(items$));
  }

  public onSearch(term: string, selectComponent: NgSelectComponent, model$: BehaviorSubject<[string, NgSelectComponent]>): void {
    model$.next([term, selectComponent]);
  }

  public search(term: string, item: DualListBoxItem): boolean {
    let title: string;

    if (item.translated) {
      title = this.translateService.instant(item.title);
    } else {
      title = item.title;
    }

    return title?.toLowerCase().includes(term.toLowerCase());
  }

  public getSearchTerm(search: [string, NgSelectComponent]): string {
    return search ? search[0] : '';
  }

  public isSearchDisabled(items: DualListBoxItem[]): boolean {
    return !items?.length;
  }

  private moveItems(
    fromItems$: BehaviorSubject<DualListBoxItem[]>,
    markedItems$: BehaviorSubject<DualListBoxItem[]>,
    toItems$: BehaviorSubject<DualListBoxItem[]>,
    fromEmitter: EventEmitter<DualListBoxItem[]>,
    toEmitter: EventEmitter<DualListBoxItem[]>
  ): void {
    const markedItems = markedItems$.getValue();
    const markedItemGroups = markedItems.map(item => this.getItemWithGroup(item, fromItems$));

    this.markedAvailableItems$.next([]);
    this.markedActiveItems$.next([]);

    const newFromItems = this.removeItems(fromItems$.getValue(), markedItems);
    fromEmitter.observers.length ? fromEmitter.emit(newFromItems) : this.setItems(newFromItems, fromItems$);

    const newToItems = this.mergeItemGroups([...toItems$.getValue(), ...markedItemGroups]);
    toEmitter.observers.length ? toEmitter.emit(newToItems) : this.setItems(newToItems, toItems$);

    this.setMoveStates();
  }

  private setItems(items: DualListBoxItem[], items$: BehaviorSubject<DualListBoxItem[]>): void {
    if (!isEqual(items$.getValue(), items)) {
      items$.next(cloneDeep(items));

      this.allItems$.next(uniqWith([
        ...this.availableItems$.getValue(),
        ...this.activeItems$.getValue()
      ], DualListBoxComponent.isItemIdEqual));
      this.setMoveAllState();
    }
  }

  private removeItems(items: DualListBoxItem[], itemsToRemove: DualListBoxItem[]): DualListBoxItem[] {
    const itemsClone = cloneDeep(items);

    remove(itemsClone, itemToRemove =>
      itemsToRemove.findIndex(x => DualListBoxComponent.getItemId(x) === DualListBoxComponent.getItemId(itemToRemove)) !== -1);

    for (const item of itemsClone) {
      if (item.items?.length) {
        item.items = this.removeItems(item.items, itemsToRemove);
      }
    }

    remove(itemsClone, itemToRemove => itemToRemove.items ? !itemToRemove.items.length : false);

    return itemsClone;
  }

  private mergeItemGroups(items: DualListBoxItem[]): DualListBoxItem[] {
    const mergeableItems = groupBy(items.filter(x => Boolean(x)), x => DualListBoxComponent.getItemId(x));
    const mappedItems: DualListBoxItem[] = values(mergeableItems).map(mergeableItem => {
      const mergeableItemItems = uniqWith(flatten(mergeableItem.map(x => x.items ?? [])), DualListBoxComponent.isItemIdEqual);

      if (mergeableItemItems.length) {
        return {
          ...mergeableItem[0],
          items: this.mergeItemGroups(mergeableItemItems)
        };
      } else {
        return mergeableItem[0];
      }
    });

    return mappedItems;
  }

  private getItemWithGroup(item: DualListBoxItem, model$: BehaviorSubject<DualListBoxItem[]>): DualListBoxItem {
    const group = cloneDeep(this.findItemGroup(item, model$.getValue()));

    if (group?.items?.length) {
      group.items = [item];
    }

    return group;
  }

  private findItemGroup(item: DualListBoxItem, items: DualListBoxItem[]): DualListBoxItem {
    for (const itemInItems of items) {
      if (DualListBoxComponent.isItemIdEqual(itemInItems, item)) {
        return item;
      } else if (itemInItems.items?.length && itemInItems.items.some(x => DualListBoxComponent.isItemIdEqual(x, item))) {
        return itemInItems;
      }
    }

    return null;
  }

  private getAllItems(items$: BehaviorSubject<DualListBoxItem[]>): DualListBoxItem[] {
    return cloneDeep(flatMap(items$.getValue(), item => map(item.items ?? [item], itemItems => itemItems)));
  }

  private setMoveStates(): void {
    this.setMoveAllState();
    this.setMoveState();
  }

  private setMoveAllState(): void {
    this.moveAllRightEnabled$.next(Boolean(this.availableItems$.getValue().length));
    this.moveAllLeftEnabled$.next(Boolean(this.activeItems$.getValue().length));
  }

  private setMoveState(): void {
    this.moveRightEnabled$.next(Boolean(this.markedAvailableItems$.getValue().length));
    this.moveLeftEnabled$.next(Boolean(this.markedActiveItems$.getValue().length));
  }

  private subscribeToSearchInputs(): void {
    this.subscribe(
      merge(this.availableItemsSearch$, this.activeItemsSearch$),
      ([term, selectComponent]) => selectComponent?.filter(term)
    );
  }
}
