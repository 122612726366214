import { Pipe, PipeTransform } from '@angular/core';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';

@Pipe({
  name: 'nexnoxWebFaIconStringPipe'
})
export class NexnoxWebFaIconString implements PipeTransform {
  public static transformIcon(icon: IconDefinition | string, extraClasses?: string): string {
    if (typeof icon === 'string') {
      return icon;
    }

    return `${icon.prefix} fa-${icon.iconName}${extraClasses ? ` ${extraClasses}` : ''}`;
  }

  public transform(icon: IconDefinition | string, extraClasses?: string): string {
    return NexnoxWebFaIconString.transformIcon(icon, extraClasses);
  }
}
