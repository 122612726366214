import {ChangeDetectionStrategy, Component, EventEmitter, Injector, Output} from '@angular/core';
import {CorePortalEntityOverviewBaseComponent} from '@nexnox-web/core-portal';
import {AppEntityType, ControllerOperationId, InfoProgressFunctionListDto, Mappers} from '@nexnox-web/core-shared';
import {infoProgressFunctionListStore} from '../../store';

@Component({
  selector: 'nexnox-web-infos-info-progress-function-list',
  templateUrl: './info-progress-function-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InfoProgressFunctionListComponent extends CorePortalEntityOverviewBaseComponent<InfoProgressFunctionListDto> {
  @Output() public openSidebar: EventEmitter<any> = new EventEmitter<any>();

  public title = 'orga-portal.infos.subtitles.info-progress-functions';
  public idProperty = 'functionId';
  public displayProperty = null;
  public datatableConfigName = 'InfoProgressFunctionsDefault';
  public componentId = 'InfoProgressFunctionListComponent';
  public enableViews = true;
  public pageOperation = ControllerOperationId.InfoProgressFunctionControllerId;

  constructor(
    protected injector: Injector
  ) {
    super(injector, infoProgressFunctionListStore, Mappers.InfoProgressFunctionListDto.serializedName, AppEntityType.Function);
  }

  public ngOnInit(): void {
    super.ngOnInit();

    if (this.parentIds[0]) {
      this.descendantId = Number(this.parentIds[0])
    }
  }

  public onOpenSidebar(event: any): void {
    this.openSidebar.emit(event.model);
  }
}
