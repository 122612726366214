import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'nexnox-web-resources-resource-control-protocol',
  templateUrl: './resource-control-protocol.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ResourceControlProtocolComponent {
  @Input() public parentIds: Array<string | number> = [];
}
