import {
  gdprDetailStore,
  GdprDetailStoreState,
  gdprListStore,
  GdprListStoreState,
  GdprEntryListStoreState,
  gdprEntryListStore
} from './stores';

export interface GdprState {
  gdprList: GdprListStoreState;
  gdprDetail: GdprDetailStoreState;
  gdprEntryList: GdprEntryListStoreState;
}

export const getInitialGdprState = (): GdprState => ({
  gdprList: gdprListStore.getInitialState(),
  gdprDetail: gdprDetailStore.getInitialState(),
  gdprEntryList: gdprEntryListStore.getInitialState()
});
