import {
  StereotypeSkeletonXsStore,
  StereotypeSkeletonXsStoreActions,
  StereotypeSkeletonXsStoreState
} from './stereotype-skeleton.xs-store';
import { Action, createSelector, select } from '@ngrx/store';
import { selectStereotypesState } from '../../stereotypes.selector';
import { authStore, CorePortalSkeletonService } from '@nexnox-web/core-portal';
import { AppEntityType, SkeletonRootDto } from '@nexnox-web/core-shared';
import { Injectable, Injector } from '@angular/core';
import { createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';
import { stereotypeDetailStore } from '../stereotype-detail/stereotype-detail.store';

export interface StereotypeSkeletonStoreState extends StereotypeSkeletonXsStoreState {
}

/* istanbul ignore next */
export const stereotypeSkeletonStore = new StereotypeSkeletonXsStore({
  actionLabel: 'Core Portal - Settings - Stereotypes - Stereotype Skeleton',
  stateSelector: createSelector(selectStereotypesState, state => state.stereotypeSkeleton),
  serviceType: CorePortalSkeletonService,
  entityType: AppEntityType.None,
  stereotyped: false
});

@Injectable()
export class StereotypeSkeletonStoreEffects extends stereotypeSkeletonStore.effects {
  public create$: any;
  public createSuccess$: any;

  public actions: StereotypeSkeletonXsStoreActions;

  constructor(
    protected injector: Injector
  ) {
    super(injector);
  }

  protected createEffects(): void {
    super.createEffects();

    this.create$ = createEffect(() => this.actions$.pipe(
      ofType(this.actions.create),
      withLatestFrom(this.store.pipe(select(authStore.selectors.selectActiveTenant))),
      switchMap(([{ model, stereotypeId }, activeTenant]) => this.service.createOne({
        ...model,
        stereotypeId,
        tenantId: activeTenant.tenantId
      }).pipe(
        map(skeleton => this.actions.createSuccess({
          entity: this.prepareEntity(skeleton),
          model: this.prepareModel(this.prepareEntity(skeleton), {} as any)
        })),
        catchError(error => of(this.actions.error({ error, action: this.actions.create })))
      ))
    ));

    this.createSuccess$ = createEffect(() => this.actions$.pipe(
      ofType(this.actions.createSuccess),
      tap(action => this.actionCallback(action, false))
    ), { dispatch: false });
  }

  protected actionCallback(action: Action, isError: boolean): void {
    super.actionCallback(action, isError);

    this.checkAction(this.actions.createSuccess, action, ({ entity }) => this.createSuccessActionCallback(entity));
  }

  protected async createSuccessActionCallback(defaultSkeleton: SkeletonRootDto): Promise<void> {
    this.apiNotificationService.showTranslatedSuccess('core-shared.shared.toast.entity-created');

    const entity = await this.store.pipe(select(stereotypeDetailStore.selectors.selectEntity), take(1)).toPromise();
    const model = await this.store.pipe(select(stereotypeDetailStore.selectors.selectModel), take(1)).toPromise();
    this.store.dispatch(stereotypeDetailStore.actions.saveSuccess({
      entity: { ...entity, defaultSkeleton },
      model: { ...model, defaultSkeleton },
      doNotEmitEvents: true
    }));
  }
}
