import {ChangeDetectionStrategy, Component, EventEmitter, Injector, Input, OnInit, Output} from '@angular/core';
import {AppEntityType, FilterDto, FilterKind, LocationSimpleDto, Mappers, ResourceDto} from '@nexnox-web/core-shared';
import {
  CorePortalEntitySelectDatatableSearchModalBaseComponent,
  CorePortalTenantRouter,
  DatatableSelectionMode
} from '@nexnox-web/core-portal';
import {CorePortalFeatureResourceService} from '../../services';
import {Observable} from "rxjs";
import {map} from "rxjs/operators";

@Component({
  selector: 'nexnox-web-resources-resource-datatable-search',
  templateUrl: './resource-datatable-search.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CorePortalFeatureResourceDatatableSearchComponent
  extends CorePortalEntitySelectDatatableSearchModalBaseComponent<ResourceDto> implements OnInit {

  @Input() public location$: Observable<LocationSimpleDto>;
  @Input() public selectionMode: DatatableSelectionMode;
  @Output() public selectionChange: EventEmitter<ResourceDto[]> = new EventEmitter<ResourceDto[]>();

  public savedFilters$: Observable<FilterDto[]>;
  public datatableConfigName = 'ResourceDefault';
  public componentId = 'CorePortalFeatureResourceDatatableSearchComponent';

  constructor(
    protected injector: Injector,
    private router: CorePortalTenantRouter
  ) {
    super(
      injector,
      'resourceId',
      'name',
      CorePortalFeatureResourceService,
      AppEntityType.Resource,
      Mappers.ResourceListDto.serializedName
    );
  }

  public ngOnInit(): void {

    this.defaultColumns = [
      'location',
      'inventoryNumber',
      'name',
      'path',
      'stereotype'
    ];

    this.savedFilters$ = this.location$.pipe(
      map((location) => Boolean(location) ? [
        {
          type: 2,
          combinedAs: 2,
          property: 'location.locationId',
          kind: FilterKind.Default,
          children: [
            {
              type: 0,
              operator: -1,
              property: 'location.locationId',
              kind: FilterKind.Default,
              value: location.locationId,
              label: location.name
            }
          ]
        }
      ] : []));

    super.ngOnInit();
  }

  public onDetail(row: ResourceDto): void {
    const url = this.router.createUrlTree(['/resources', row.resourceId], {module: 'inventory'});
    window.open(url.toString(), '_blank');
  }
}
