import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ArticleDetailComponent, ArticleListComponent} from './containers';
import {AppPermissions, I18nBreadcrumbResolver, UnsavedChangesGuard, UnsavedCreateGuard} from '@nexnox-web/core-shared';
import {CorePortalPermissionGuard} from '@nexnox-web/core-portal';

export const CORE_PORTAL_ARTICLES_ROUTES: Routes = [
  {
    path: '',
    canActivate: [CorePortalPermissionGuard],
    canDeactivate: [UnsavedCreateGuard],
    component: ArticleListComponent,
    data: {
      permissions: [AppPermissions.DisplayArticles]
    }
  },
  {
    path: ':articleId',
    component: ArticleDetailComponent,
    canActivate: [CorePortalPermissionGuard],
    canDeactivate: [UnsavedChangesGuard],
    data: {
      permissions: [AppPermissions.ReadArticle, AppPermissions.ReadStereotype],
      breadcrumbs: I18nBreadcrumbResolver,
      tab: 'article',
      text: 'core-portal.core.header.breadcrumbs.articles.article-detail'
    },
    children: [
      {
        path: 'usages',
        data: {
          tab: 'usages'
        },
        children: []
      }
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(CORE_PORTAL_ARTICLES_ROUTES)
  ],
  exports: [
    RouterModule
  ]
})
export class ArticlesRoutingModule {
}
