import {Plugin} from 'ckeditor5';

import NxMarkEditing from './nx-mark.editing';
import NxMarkUI from './nx-mark.ui';

export class NxMarkPlugin extends Plugin {
  public static get pluginName(): string {
    return 'NxMark';
  }

  public static get requires(): any[] {
    return [NxMarkEditing, NxMarkUI];
  }

  public init(): void {
  }
}
