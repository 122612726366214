import {FormControl, Validators} from '@angular/forms';
import {
  CorePortalFormlyNgSelectTyping,
  CorePortalFormlyReadonlyTypes,
  CorePortalFormlyReadonlyTyping,
  CorePortalFormlyTranslatedTyping
} from '@nexnox-web/core-portal';
import {CustomInfoKinds, CustomPropertyType, UnsubscribeHelper} from '@nexnox-web/core-shared';
import {minutesTo} from '@nexnox-web/lodash';
import {FormlyFieldConfig, FormlyTemplateOptions} from '@ngx-formly/core';
import dayjs from 'dayjs';
import {isEmpty, isEqual, isFinite, isNull, isNumber, isString, isUndefined} from 'lodash';
import {merge, Observable} from 'rxjs';
import {delay, distinctUntilChanged, map} from 'rxjs/operators';

// ToDo: Fix this in the future
export const FormlyReadonlyTypes = {
  BASIC: 0,
  BOOLEAN: 1,
  ENUM: 2,
  DATE: 3,
  TIME: 4,
  ENTITY: 5,
  IMAGE: 6,
  HTML: 7,
  PHONE: 8,
  MULTIPLE_CHOICE: 9
};

export interface ExpressionProperties {
  [property: string]: string | ((model: any, formState: any, field?: FormlyFieldConfig) => any) | Observable<any>;
}

export interface CustomPropertyOptions {
  formlyType?: string;
  inputType?: string;
  valuePath?: string;
  className?: string;
  defaultValue?: boolean;
  prependFields?: FormlyFieldConfig[];
  prependFieldsDivider?: boolean;
  showDefaultValueLabel?: boolean;
  additionalFields?: FormlyFieldConfig[];
  templateOptions?: Partial<FormlyTemplateOptions>;
  validationMessages?: any;
  expressionProperties?: ExpressionProperties;
  validators?: any;
  hooks?: any;
  noDefault?: boolean;
  noRequired?: boolean;
  noReadonly?: boolean;
}

/* istanbul ignore next */
export class CustomPropertyTemplates extends UnsubscribeHelper {
  constructor(
    private readonlyFn: () => boolean,
    private rateableFn: () => boolean,
    private rateable$: Observable<boolean>,
    private currentLanguage?: string
  ) {
    super();
  }

  public createCustomPropertyForm(customPropertyType: CustomPropertyType): FormlyFieldConfig[] {
    if (customPropertyType) {
      switch (customPropertyType) {
        case CustomPropertyType.Text:
          return this.text();
        case CustomPropertyType.Numeric:
          return this.numeric();
        case CustomPropertyType.Multiline:
          return this.multilineText();
        case CustomPropertyType.Email:
          return this.email();
        case CustomPropertyType.Checkbox:
          return this.checkbox();
        case CustomPropertyType.Date:
          return this.date();
        case CustomPropertyType.TimeOfDay:
          return this.time();
        case CustomPropertyType.Dropdown:
          return this.dropdown();
        case CustomPropertyType.Phone:
          return this.phone();
        case CustomPropertyType.Signature:
          return this.signature();
        case CustomPropertyType.Picture:
          return this.image();
        case CustomPropertyType.Info:
          return this.info();
        case CustomPropertyType.MultipleChoice:
          return this.multipleChoice();
        default:
          return this.default();
      }
    }
    return [];
  }

  private default(options?: CustomPropertyOptions): FormlyFieldConfig[] {
    const defaultValuePath = 'defaultValues.0.value';

    const formlyType = options?.formlyType ?? 'input';
    const inputType = options?.inputType ?? 'text';
    const valuePath = options?.valuePath ?? defaultValuePath;
    const className = options?.className ?? 'col-md-12';
    const defaultValue = options?.defaultValue;
    const prependFields = options?.prependFields ?? [];
    const prependFieldsDivider = options?.prependFieldsDivider ?? false;
    const showDefaultValueLabel = options?.showDefaultValueLabel ?? true;
    const additionalFields = options?.additionalFields ?? [];
    const templateOptions = options?.templateOptions ?? {};
    const validationMessages = options?.validationMessages ?? {};
    const expressionProperties = options?.expressionProperties ?? {};
    const validators = options?.validators ?? {};
    const hooks = options?.hooks ?? {};
    const noDefault = options?.noDefault;
    const noRequired = options?.noRequired;
    const noReadonly = options?.noReadonly;

    return [
      ...prependFields,
      (prependFieldsDivider && prependFields?.length !== 0 ? {
        type: 'core-portal-divider',
        className: 'col-md-12',
      } : {}),
      ...(!noDefault ? [{
        key: valuePath,
        type: formlyType,
        wrappers: ['core-portal-translated', ...(noReadonly ? [] : ['core-portal-readonly'])],
        className,
        defaultValue,
        templateOptions: {
          corePortalTranslated: {
            label: showDefaultValueLabel ? 'core-shared.shared.fields.default-value' : null,
            validationMessages: {
              ...validationMessages
            }
          },
          corePortalReadonly: {
            type: FormlyReadonlyTypes.BASIC
          },
          type: inputType,
          ...templateOptions
        },
        expressionProperties: {
          'templateOptions.disabled': () => this.readonlyFn(),
          'templateOptions.readonly': () => this.readonlyFn(),
          ...expressionProperties
        },
        validators,
        hooks
      }] : []),
      ...additionalFields,
      ...(!noRequired ? [{
        key: 'isRequired',
        type: 'core-portal-switch',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-6',
        defaultValue: false,
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.is-required'
          },
          corePortalReadonly: {
            type: FormlyReadonlyTypes.BOOLEAN
          } as CorePortalFormlyReadonlyTyping
        },
        expressionProperties: {
          'templateOptions.disabled': () => this.readonlyFn(),
          'templateOptions.readonly': () => this.readonlyFn()
        }
      }] : []),

      /* Rateable */
      {
        key: 'isRateable',
        hooks: {
          onInit: field => this.subscribe(this.rateable$.pipe(
            distinctUntilChanged(),
            delay(1)
          ), rateable => {
            if (rateable) field.formControl.setValue(rateable);
          })
        }
      },
      {
        key: 'weight',
        type: 'input',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-6',
        defaultValue: 1,
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.weight'
          },
          corePortalReadonly: {
            type: FormlyReadonlyTypes.BASIC
          } as CorePortalFormlyReadonlyTyping,
          type: 'number'
        },
        expressionProperties: {
          'templateOptions.disabled': () => this.readonlyFn(),
          'templateOptions.readonly': () => this.readonlyFn(),

          hide: this.rateable$.pipe(
            map(rateable => !rateable)
          )
        }
      }
    ];
  }

  private text(): FormlyFieldConfig[] {
    return this.default({
      inputType: 'text',
      templateOptions: {
        corePortalReadonly: {
          type: FormlyReadonlyTypes.BASIC
        } as CorePortalFormlyReadonlyTyping
      },
      ...this.getSharedTextConfig()
    });
  }

  private multipleChoice(): FormlyFieldConfig[] {
    return this.default({
      valuePath: 'defaultValues',
      formlyType: 'core-portal-stereotypes-multiple-choice',
      noReadonly: true,
      prependFieldsDivider: true,
      showDefaultValueLabel: false,
      templateOptions: {
        corePortalCustomPropertyMultipleChoice: {
          isRateable: false,
          readonly: false
        }
      },
      expressionProperties: {
        'templateOptions.corePortalCustomPropertyMultipleChoice.isRateable': () => this.rateableFn(),
        'templateOptions.corePortalCustomPropertyMultipleChoice.readonly': () => this.readonlyFn(),
      },
      validationMessages: {
        oneOption: 'core-portal.core.validation.one-option',
        filled: 'core-portal.core.validation.filled'
      },
      validators: {
        oneOption: ctrl => Boolean(ctrl.value?.length),
        filled: ctrl => Boolean(ctrl.value?.every(value => !isEmpty(value.value)))
      },
      prependFields: [
        {
          key: 'description',
          type: 'core-portal-editor',
          wrappers: ['core-portal-translated', 'core-portal-readonly'],
          className: 'col-md-12',
          defaultValue: '',
          templateOptions: {
            corePortalReadonly: {
              type: CorePortalFormlyReadonlyTypes.HTML
            } as CorePortalFormlyReadonlyTyping,
          },
          expressionProperties: {
            'templateOptions.disabled': () => this.readonlyFn(),
            'templateOptions.readonly': () => this.readonlyFn()
          }
        }
      ]
    });
  }

  private numeric(): FormlyFieldConfig[] {
    return this.default({
      inputType: 'number',
      className: 'col-sm-9',
      templateOptions: {
        corePortalReadonly: {
          type: FormlyReadonlyTypes.BASIC,
          format: null
        } as CorePortalFormlyReadonlyTyping
      },
      validationMessages: {
        min: {
          key: 'core-portal.core.validation.min',
          args: { min: null }
        },
        max: {
          key: 'core-portal.core.validation.max',
          args: { max: null }
        }
      },
      expressionProperties: {
        'templateOptions.corePortalTranslated.validationMessages.min.args': model => ({ min: model?.minValue }),
        'templateOptions.corePortalTranslated.validationMessages.max.args': model => ({ max: model?.maxValue }),
        'templateOptions.corePortalReadonly.format': model => model?.isNumberFormatActive
      },
      validators: {
        min: (ctrl, field) => this.defaultValueMinValidator(ctrl, field),
        max: (ctrl, field) => this.defaultValueMaxValidator(ctrl, field)
      },
      hooks: {
        onInit: field => this.updateFieldHook(field, ['minValue', 'maxValue'])
      },
      additionalFields: [
        {
          key: 'unit',
          type: 'input',
          wrappers: ['core-portal-translated', 'core-portal-readonly'],
          className: 'col-sm-3',
          templateOptions: {
            corePortalTranslated: {
              label: 'core-shared.shared.fields.unit'
            },
            corePortalReadonly: {
              type: FormlyReadonlyTypes.BASIC
            } as CorePortalFormlyReadonlyTyping,
            type: 'text'
          },
          expressionProperties: {
            'templateOptions.disabled': () => this.readonlyFn(),
            'templateOptions.readonly': () => this.readonlyFn()
          }
        },
        {
          key: 'setPointMin',
          type: 'input',
          wrappers: ['core-portal-translated', 'core-portal-readonly'],
          className: 'col-md-6',
          templateOptions: {
            corePortalTranslated: {
              label: 'core-shared.shared.fields.set-point-min',
              validationMessages: {
                required: 'core-portal.core.validation.required'
              }
            },
            corePortalReadonly: {
              type: FormlyReadonlyTypes.BASIC
            } as CorePortalFormlyReadonlyTyping,
            type: 'number'
          },
          expressionProperties: {
            'templateOptions.required': () => !this.readonlyFn() && this.rateableFn(),
            'templateOptions.disabled': () => this.readonlyFn(),
            'templateOptions.readonly': () => this.readonlyFn()
          },
          hideExpression: () => !this.rateableFn(),
          hooks: {
            onInit: field => this.updateFieldHook(field, ['setPointMax'])
          }
        },
        {
          key: 'setPointMax',
          type: 'input',
          wrappers: ['core-portal-translated', 'core-portal-readonly'],
          className: 'col-md-6',
          templateOptions: {
            corePortalTranslated: {
              label: 'core-shared.shared.fields.set-point-max',
              validationMessages: {
                required: 'core-portal.core.validation.required',
                min: {
                  key: 'core-portal.core.validation.min',
                  args: { min: null }
                }
              }
            },
            corePortalReadonly: {
              type: FormlyReadonlyTypes.BASIC
            } as CorePortalFormlyReadonlyTyping,
            type: 'number'
          },
          expressionProperties: {
            'templateOptions.required': () => !this.readonlyFn() && this.rateableFn(),
            'templateOptions.disabled': () => this.readonlyFn(),
            'templateOptions.readonly': () => this.readonlyFn(),
            'templateOptions.corePortalTranslated.validationMessages.min.args': model => ({
              min: isNumber(model.setPointMin) ? model.setPointMin : null
            })
          },
          hideExpression: () => !this.rateableFn(),
          validators: {
            min: (ctrl, field) => this.defaultValueMinValidator(ctrl, field, 'setPointMin')
          },
          hooks: {
            onInit: field => this.updateFieldHook(field, ['setPointMin'])
          }
        },
        {
          key: 'minValue',
          type: 'input',
          wrappers: ['core-portal-translated', 'core-portal-readonly'],
          className: 'col-md-6',
          templateOptions: {
            corePortalTranslated: {
              label: 'core-shared.shared.fields.min'
            },
            corePortalReadonly: {
              type: FormlyReadonlyTypes.BASIC
            } as CorePortalFormlyReadonlyTyping,
            type: 'number'
          },
          expressionProperties: {
            'templateOptions.disabled': () => this.readonlyFn(),
            'templateOptions.readonly': () => this.readonlyFn()
          },
          hooks: {
            onInit: field => this.updateFieldHook(field, ['defaultValues.0.value', 'maxValue'])
          }
        },
        {
          key: 'maxValue',
          type: 'input',
          wrappers: ['core-portal-translated', 'core-portal-readonly'],
          className: 'col-md-6',
          templateOptions: {
            corePortalTranslated: {
              label: 'core-shared.shared.fields.max',
              validationMessages: {
                min: {
                  key: 'core-portal.core.validation.min',
                  args: { min: null }
                }
              }
            },
            corePortalReadonly: {
              type: FormlyReadonlyTypes.BASIC
            } as CorePortalFormlyReadonlyTyping,
            type: 'number'
          },
          expressionProperties: {
            'templateOptions.disabled': () => this.readonlyFn(),
            'templateOptions.readonly': () => this.readonlyFn(),
            'templateOptions.corePortalTranslated.validationMessages.min.args': model => ({
              min: isNumber(model.minValue) ? model.minValue : null
            })
          },
          validators: {
            min: (ctrl, field) => this.defaultValueMinValidator(ctrl, field)
          },
          hooks: {
            onInit: field => this.updateFieldHook(field, ['defaultValues.0.value', 'minValue'])
          }
        },
        {
          key: 'isNumberFormatActive',
          type: 'core-portal-switch',
          wrappers: ['core-portal-translated', 'core-portal-readonly'],
          className: 'col-md-6',
          defaultValue: true,
          templateOptions: {
            corePortalTranslated: {
              label: 'core-shared.shared.fields.number-format-active'
            },
            corePortalReadonly: {
              type: FormlyReadonlyTypes.BOOLEAN
            } as CorePortalFormlyReadonlyTyping
          },
          expressionProperties: {
            'templateOptions.disabled': () => this.readonlyFn(),
            'templateOptions.readonly': () => this.readonlyFn()
          },
          hooks: {
            onInit: field => field.formControl.updateValueAndValidity()
          }
        }
      ]
    });
  }

  private multilineText(): FormlyFieldConfig[] {
    return this.default({
      formlyType: 'textarea',
      inputType: 'text',
      templateOptions: {
        corePortalReadonly: {
          type: FormlyReadonlyTypes.BASIC
        } as CorePortalFormlyReadonlyTyping,
        rows: 3
      },
      ...this.getSharedTextConfig()
    });
  }

  private email(): FormlyFieldConfig[] {
    return this.default({
      inputType: 'email',
      templateOptions: {
        corePortalReadonly: {
          type: FormlyReadonlyTypes.BASIC
        } as CorePortalFormlyReadonlyTyping
      },
      validationMessages: {
        email: 'core-portal.core.validation.email'
      },
      validators: {
        email: ctrl => !ctrl.value ? true : (Validators.email(ctrl) ? null : { email: true })
      }
    });
  }

  private checkbox(): FormlyFieldConfig[] {
    return this.default({
      formlyType: 'core-portal-switch',
      inputType: 'boolean',
      className: 'col-md-6',
      templateOptions: {
        corePortalReadonly: {
          type: FormlyReadonlyTypes.BOOLEAN
        } as CorePortalFormlyReadonlyTyping
      },
      hooks: {
        onInit: field => {
          if (isUndefined(field.formControl.value) || isNull(field.formControl.value)) field.formControl.setValue(false);
        }
      },
      additionalFields: [
        {
          key: 'setPoint',
          type: 'core-portal-switch',
          wrappers: ['core-portal-translated', 'core-portal-readonly'],
          className: 'col-md-6',
          defaultValue: false,
          templateOptions: {
            corePortalTranslated: {
              label: 'core-shared.shared.fields.set-point'
            } as CorePortalFormlyTranslatedTyping,
            corePortalReadonly: {
              type: FormlyReadonlyTypes.BOOLEAN
            } as CorePortalFormlyReadonlyTyping
          },
          expressionProperties: {
            'templateOptions.readonly': () => this.readonlyFn(),
            'templateOptions.disabled': () => this.readonlyFn()
          },
          hideExpression: () => !this.rateableFn()
        }
      ]
    });
  }

  private date(): FormlyFieldConfig[] {
    return this.default({
      formlyType: 'core-portal-datepicker',
      validationMessages: {
        minDate: 'core-portal.core.validation.min-date',
        maxDate: 'core-portal.core.validation.max-date'
      },
      templateOptions: {
        corePortalReadonly: {
          type: FormlyReadonlyTypes.DATE,
          format: 'LL'
        } as CorePortalFormlyReadonlyTyping,
        minDate: null,
        maxDate: null
      },
      expressionProperties: {
        'templateOptions.minDate': model => model?.minTime,
        'templateOptions.maxDate': model => model?.maxTime
      },
      validators: {
        minDate: (ctrl, field) => {
          const valueDate = dayjs(ctrl.value);
          const minDate = dayjs(field.form.controls.minTime?.value ?? null);

          return !valueDate.isValid() || !minDate.isValid() ? true : valueDate.isSame(minDate) || valueDate.isAfter(minDate);
        },
        maxDate: (ctrl, field) => {
          const valueDate = dayjs(ctrl.value);
          const maxDate = dayjs(field.form.controls.maxTime?.value ?? null);

          return !valueDate.isValid() || !maxDate.isValid() ? true : valueDate.isBefore(maxDate) || valueDate.isSame(maxDate);
        }
      },
      hooks: {
        onInit: field => this.updateFieldHook(field, ['minTime', 'maxTime'])
      },
      additionalFields: [
        {
          key: 'setMinTime',
          type: 'core-portal-datepicker',
          wrappers: ['core-portal-translated', 'core-portal-readonly'],
          className: 'col-md-6',
          templateOptions: {
            corePortalTranslated: {
              label: 'core-shared.shared.fields.set-point-min-date',
              validationMessages: {
                required: 'core-portal.core.validation.required'
              }
            },
            corePortalReadonly: {
              type: FormlyReadonlyTypes.DATE,
              format: 'LL'
            } as CorePortalFormlyReadonlyTyping
          },
          expressionProperties: {
            'templateOptions.required': () => !this.readonlyFn() && this.rateableFn(),
            'templateOptions.disabled': () => this.readonlyFn(),
            'templateOptions.readonly': () => this.readonlyFn()
          },
          hideExpression: () => !this.rateableFn(),
          hooks: {
            onInit: field => this.updateFieldHook(field, ['setMaxTime'])
          }
        },
        {
          key: 'setMaxTime',
          type: 'core-portal-datepicker',
          wrappers: ['core-portal-translated', 'core-portal-readonly'],
          className: 'col-md-6',
          templateOptions: {
            corePortalTranslated: {
              label: 'core-shared.shared.fields.set-point-max-date',
              validationMessages: {
                required: 'core-portal.core.validation.required',
                minDate: 'core-portal.core.validation.min-date'
              }
            },
            corePortalReadonly: {
              type: FormlyReadonlyTypes.DATE,
              format: 'LL'
            } as CorePortalFormlyReadonlyTyping,
            minDate: null
          },
          expressionProperties: {
            'templateOptions.required': () => !this.readonlyFn() && this.rateableFn(),
            'templateOptions.disabled': () => this.readonlyFn(),
            'templateOptions.readonly': () => this.readonlyFn(),
            'templateOptions.minDate': model => model?.setMinTime
          },
          hideExpression: () => !this.rateableFn(),
          validators: {
            minDate: (ctrl, field) => {
              const valueDate = dayjs(ctrl.value);
              const minDate = dayjs(field.form.controls.setMinTime?.value ?? null);

              return !valueDate.isValid() || !minDate.isValid() ? true : valueDate.isSame(minDate) || valueDate.isAfter(minDate);
            }
          },
          hooks: {
            onInit: field => this.updateFieldHook(field, ['setMinTime'])
          }
        },
        {
          key: 'minTime',
          type: 'core-portal-datepicker',
          wrappers: ['core-portal-translated', 'core-portal-readonly'],
          className: 'col-md-6',
          templateOptions: {
            corePortalTranslated: {
              label: 'core-shared.shared.fields.min-date'
            },
            corePortalReadonly: {
              type: FormlyReadonlyTypes.DATE,
              format: 'LL'
            } as CorePortalFormlyReadonlyTyping
          },
          expressionProperties: {
            'templateOptions.disabled': () => this.readonlyFn(),
            'templateOptions.readonly': () => this.readonlyFn()
          },
          hooks: {
            onInit: field => this.updateFieldHook(field, ['defaultValues.0.value', 'maxTime'])
          }
        },
        {
          key: 'maxTime',
          type: 'core-portal-datepicker',
          wrappers: ['core-portal-translated', 'core-portal-readonly'],
          className: 'col-md-6',
          templateOptions: {
            corePortalTranslated: {
              label: 'core-shared.shared.fields.max-date',
              validationMessages: {
                minDate: 'core-portal.core.validation.min-date'
              }
            },
            corePortalReadonly: {
              type: FormlyReadonlyTypes.DATE,
              format: 'LL'
            } as CorePortalFormlyReadonlyTyping,
            minDate: null
          },
          expressionProperties: {
            'templateOptions.disabled': () => this.readonlyFn(),
            'templateOptions.readonly': () => this.readonlyFn(),
            'templateOptions.minDate': model => model?.minTime
          },
          validators: {
            minDate: (ctrl, field) => {
              const valueDate = dayjs(ctrl.value);
              const minDate = dayjs(field.form.controls.minTime?.value ?? null);

              return !valueDate.isValid() || !minDate.isValid() ? true : valueDate.isSame(minDate) || valueDate.isAfter(minDate);
            }
          },
          hooks: {
            onInit: field => this.updateFieldHook(field, ['defaultValues.0.value', 'minTime'])
          }
        }
      ]
    });
  }

  private time(): FormlyFieldConfig[] {
    return this.default({
      formlyType: 'core-portal-timepicker',
      validationMessages: {
        valid: 'core-portal.core.validation.valid-time',
        minTime: 'core-portal.core.validation.min-time',
        maxTime: 'core-portal.core.validation.max-time'
      },
      templateOptions: {
        corePortalReadonly: {
          type: FormlyReadonlyTypes.TIME
        } as CorePortalFormlyReadonlyTyping,
        minTime: null,
        maxTime: null
      },
      expressionProperties: {
        'templateOptions.minTime': model => model?.minTime,
        'templateOptions.maxTime': model => model?.maxTime
      },
      validators: {
        valid: ctrl => this.timeValueValidValidator(ctrl),
        minTime: (ctrl, field) => this.timeValueMinValidator(ctrl, field),
        maxTime: (ctrl, field) => this.timeValueMaxValidator(ctrl, field)
      },
      hooks: {
        onInit: field => this.updateFieldHook(field, ['minMinutes', 'maxMinutes'])
      },
      additionalFields: [
        {
          key: 'setMinMinutes',
          type: 'core-portal-timepicker',
          wrappers: ['core-portal-translated', 'core-portal-readonly'],
          className: 'col-md-12',
          templateOptions: {
            corePortalTranslated: {
              label: 'core-shared.shared.fields.set-point-min-time',
              validationMessages: {
                required: 'core-portal.core.validation.required',
                valid: 'core-portal.core.validation.valid-time'
              }
            },
            corePortalReadonly: {
              type: FormlyReadonlyTypes.TIME
            } as CorePortalFormlyReadonlyTyping
          },
          expressionProperties: {
            'templateOptions.required': () => !this.readonlyFn() && this.rateableFn(),
            'templateOptions.disabled': () => this.readonlyFn(),
            'templateOptions.readonly': () => this.readonlyFn()
          },
          hideExpression: () => !this.rateableFn(),
          validators: {
            valid: ctrl => this.timeValueValidValidator(ctrl)
          },
          hooks: {
            onInit: field => this.updateFieldHook(field, ['setMaxMinutes'])
          }
        },
        {
          key: 'setMaxMinutes',
          type: 'core-portal-timepicker',
          wrappers: ['core-portal-translated', 'core-portal-readonly'],
          className: 'col-md-12',
          templateOptions: {
            corePortalTranslated: {
              label: 'core-shared.shared.fields.set-point-max-time',
              validationMessages: {
                required: 'core-portal.core.validation.required',
                valid: 'core-portal.core.validation.valid-time',
                minTime: 'core-portal.core.validation.min-time'
              }
            },
            corePortalReadonly: {
              type: FormlyReadonlyTypes.TIME
            } as CorePortalFormlyReadonlyTyping,
            minTime: null
          },
          expressionProperties: {
            'templateOptions.required': () => !this.readonlyFn() && this.rateableFn(),
            'templateOptions.disabled': () => this.readonlyFn(),
            'templateOptions.readonly': () => this.readonlyFn(),
            'templateOptions.minTime': model => model?.setMinMinutes
          },
          hideExpression: () => !this.rateableFn(),
          validators: {
            valid: ctrl => this.timeValueValidValidator(ctrl),
            minTime: (ctrl, field) => this.timeValueMinValidator(ctrl, field, 'setMinMinutes')
          },
          hooks: {
            onInit: field => this.updateFieldHook(field, ['setMinMinutes'])
          }
        },
        {
          key: 'minMinutes',
          type: 'core-portal-timepicker',
          wrappers: ['core-portal-translated', 'core-portal-readonly'],
          className: 'col-md-12',
          templateOptions: {
            corePortalTranslated: {
              label: 'core-shared.shared.fields.min-time',
              validationMessages: {
                valid: 'core-portal.core.validation.valid-time'
              }
            },
            corePortalReadonly: {
              type: FormlyReadonlyTypes.TIME
            } as CorePortalFormlyReadonlyTyping
          },
          expressionProperties: {
            'templateOptions.disabled': () => this.readonlyFn(),
            'templateOptions.readonly': () => this.readonlyFn()
          },
          validators: {
            valid: ctrl => this.timeValueValidValidator(ctrl)
          },
          hooks: {
            onInit: field => this.updateFieldHook(field, ['defaultValues.0.value', 'maxMinutes'])
          }
        },
        {
          key: 'maxMinutes',
          type: 'core-portal-timepicker',
          wrappers: ['core-portal-translated', 'core-portal-readonly'],
          className: 'col-md-12',
          templateOptions: {
            corePortalTranslated: {
              label: 'core-shared.shared.fields.max-time',
              validationMessages: {
                valid: 'core-portal.core.validation.valid-time',
                minTime: 'core-portal.core.validation.min-time'
              }
            },
            corePortalReadonly: {
              type: FormlyReadonlyTypes.TIME
            } as CorePortalFormlyReadonlyTyping,
            minTime: null
          },
          expressionProperties: {
            'templateOptions.disabled': () => this.readonlyFn(),
            'templateOptions.readonly': () => this.readonlyFn(),
            'templateOptions.minTime': model => model?.minMinutes
          },
          validators: {
            valid: ctrl => this.timeValueValidValidator(ctrl),
            minTime: (ctrl, field) => this.timeValueMinValidator(ctrl, field)
          },
          hooks: {
            onInit: field => this.updateFieldHook(field, ['defaultValues.0.value', 'minMinutes'])
          }
        }
      ]
    });
  }

  private dropdown(): FormlyFieldConfig[] {
    return this.default({
      valuePath: 'defaultValues',
      formlyType: 'core-portal-stereotypes-dropdown',
      noReadonly: true,
      templateOptions: {
        corePortalCustomPropertyDropdown: {
          isRateable: false
        }
      },
      expressionProperties: {
        'templateOptions.corePortalCustomPropertyDropdown.isRateable': () => this.rateableFn()
      },
      validationMessages: {
        oneOption: 'core-portal.core.validation.one-option',
        filled: 'core-portal.core.validation.filled'
      },
      validators: {
        oneOption: ctrl => Boolean(ctrl.value?.length),
        filled: ctrl => Boolean(ctrl.value?.every(value => !isEmpty(value.value)))
      }
    });
  }

  private phone(): FormlyFieldConfig[] {
    return this.default({
      inputType: 'tel',
      validationMessages: {
        phone: 'core-portal.core.validation.phone'
      },
      templateOptions: {
        corePortalReadonly: {
          type: FormlyReadonlyTypes.PHONE
        } as CorePortalFormlyReadonlyTyping
      },
      validators: {
        phone: ctrl => !isString(ctrl.value) || !Validators.pattern(/^[+]*[(]?[0-9]{1,4}[)]?[-\s./0-9]*$/)(ctrl)
      }
    });
  }

  private signature(): FormlyFieldConfig[] {
    return this.default({ noDefault: true });
  }

  private image(): FormlyFieldConfig[] {
    return this.default({ noDefault: true });
  }

  private info(): FormlyFieldConfig[] {
    const infoKindEnumOptions = [
      { value: CustomInfoKinds.Warning, label: 'core-shared.shared.info-kinds.1' },
      { value: CustomInfoKinds.Hint, label: 'core-shared.shared.info-kinds.2' }
    ];

    return this.default({
      noDefault: true,
      noRequired: true,
      prependFields: [
        {
          key: 'infoKind',
          type: 'core-portal-ng-select',
          wrappers: ['core-portal-translated', 'core-portal-readonly'],
          className: 'col-md-12',
          defaultValue: infoKindEnumOptions[0].value,
          templateOptions: {
            corePortalTranslated: {
              label: 'core-shared.shared.fields.kind'
            },
            corePortalReadonly: {
              type: FormlyReadonlyTypes.ENUM,
              enumOptions: infoKindEnumOptions,
              translate: true
            } as CorePortalFormlyReadonlyTyping,
            corePortalNgSelect: {
              items: infoKindEnumOptions,
              translate: true,
              noClear: true
            } as CorePortalFormlyNgSelectTyping
          },
          expressionProperties: {
            'templateOptions.disabled': () => this.readonlyFn(),
            'templateOptions.readonly': () => this.readonlyFn()
          }
        },
        {
          key: 'content',
          type: 'core-portal-editor',
          wrappers: ['core-portal-translated'],
          className: 'col-md-12',
          templateOptions: {
            corePortalTranslated: {
              label: 'core-shared.shared.fields.content',
              validationMessages: {
                required: 'core-portal.core.validation.required'
              }
            },
            corePortalEditor: {
              language: this.currentLanguage,
            }
          },
          expressionProperties: {
            'templateOptions.required': () => !this.readonlyFn(),
            'templateOptions.disabled': () => this.readonlyFn(),
            'templateOptions.readonly': () => this.readonlyFn()
          }
        }
      ]
    });
  }

  private getSharedTextConfig(): Partial<CustomPropertyOptions> {
    return {
      validationMessages: {
        minLength: {
          key: 'core-portal.core.validation.min-length',
          args: { minLength: null }
        },
        maxLength: {
          key: 'core-portal.core.validation.max-length',
          args: { maxLength: null }
        }
      },
      expressionProperties: {
        'templateOptions.corePortalTranslated.validationMessages.minLength.args': model => ({ minLength: model?.minLength }),
        'templateOptions.corePortalTranslated.validationMessages.maxLength.args': model => ({ maxLength: model?.maxLength })
      },
      validators: {
        minLength: (ctrl, field) => {
          const minLength = field.form.controls.minLength?.value ?? null;
          return ctrl.value?.length && isNumber(minLength) ? ctrl.value?.length >= minLength : true;
        },
        maxLength: (ctrl, field) => {
          const maxLength = field.form.controls.maxLength?.value ?? null;
          return ctrl.value?.length && isNumber(maxLength) ? ctrl.value?.length <= maxLength : true;
        }
      },
      hooks: {
        onInit: field => this.updateFieldHook(field, ['minLength', 'maxLength'])
      },
      additionalFields: [
        {
          key: 'minLength',
          type: 'input',
          wrappers: ['core-portal-translated', 'core-portal-readonly'],
          className: 'col-md-6',
          templateOptions: {
            corePortalTranslated: {
              label: 'core-shared.shared.fields.min-length',
              validationMessages: {
                min: {
                  key: 'core-portal.core.validation.min',
                  args: { min: 0 }
                }
              }
            },
            corePortalReadonly: {
              type: FormlyReadonlyTypes.BASIC
            } as CorePortalFormlyReadonlyTyping,
            type: 'number'
          },
          expressionProperties: {
            'templateOptions.disabled': () => this.readonlyFn(),
            'templateOptions.readonly': () => this.readonlyFn()
          },
          hooks: {
            onInit: field => this.updateFieldHook(field, ['defaultValues.0.value', 'maxLength'])
          }
        },
        {
          key: 'maxLength',
          type: 'input',
          wrappers: ['core-portal-translated', 'core-portal-readonly'],
          className: 'col-md-6',
          templateOptions: {
            corePortalTranslated: {
              label: 'core-shared.shared.fields.max-length',
              validationMessages: {
                min: {
                  key: 'core-portal.core.validation.min',
                  args: { min: null }
                }
              }
            },
            corePortalReadonly: {
              type: FormlyReadonlyTypes.BASIC
            } as CorePortalFormlyReadonlyTyping,
            type: 'number'
          },
          expressionProperties: {
            'templateOptions.disabled': () => this.readonlyFn(),
            'templateOptions.readonly': () => this.readonlyFn(),
            'templateOptions.corePortalTranslated.validationMessages.min.args': model => ({
              min: isFinite(model.minLength) && model.minLength > 1 ? model.minLength : 1
            })
          },
          validators: {
            min: (ctrl, field) => {
              const minLength = field.form.controls.minLength?.value ?? null;
              const validCheck = ctrl.value >= minLength && ctrl.value > 0;
              return !isFinite(ctrl.value) ? true : isFinite(minLength) ? validCheck : ctrl.value > 0;
            }
          },
          hooks: {
            onInit: field => this.updateFieldHook(field, ['defaultValues.0.value', 'minLength'])
          }
        }
      ]
    };
  }

  private defaultValueMinValidator(ctrl: FormControl, field: FormlyFieldConfig, minKey: string = 'minValue'): boolean {
    const minValue = field.form.controls[minKey]?.value ?? null;
    return !isNumber(ctrl.value) ? true : (isNumber(minValue) ? ctrl.value >= minValue : true);
  }

  private defaultValueMaxValidator(ctrl: FormControl, field: FormlyFieldConfig): boolean {
    const maxValue = field.form.controls.maxValue?.value ?? null;
    return !isNumber(ctrl.value) ? true : (isNumber(maxValue) ? ctrl.value <= maxValue : true);
  }

  private timeValueValidValidator(ctrl: FormControl): boolean {
    if (!isNumber(ctrl.value)) {
      return true;
    }

    const { hours, minutes } = minutesTo(ctrl.value);
    return isNumber(hours) && isNumber(minutes) && (hours >= 0 && hours < 24) && (minutes >= 0 && minutes < 60);
  }

  private timeValueMinValidator(ctrl: FormControl, field: FormlyFieldConfig, minKey: string = 'minMinutes'): boolean {
    const minMinutes = field.form.controls[minKey]?.value ?? null;
    if ((!isNumber(ctrl.value) || ctrl.value < 0) || (!isNumber(minMinutes) || minMinutes < 0)) {
      return true;
    }

    return ctrl.value >= minMinutes;
  }

  private timeValueMaxValidator(ctrl: FormControl, field: FormlyFieldConfig): boolean {
    const maxMinutes = field.form.controls.maxMinutes?.value ?? null;
    if ((!isNumber(ctrl.value) || ctrl.value < 0) || (!isNumber(maxMinutes) || maxMinutes < 0)) {
      return true;
    }

    return ctrl.value <= maxMinutes;
  }

  private updateFieldHook(field: FormlyFieldConfig, fields: string[]): void {
    const fields$: Observable<any>[] = fields.map(x => field.form.get(x)?.valueChanges.pipe(
      distinctUntilChanged((a, b) => isEqual(a, b))
    )).filter(x => Boolean(x));
    this.subscribe(merge(...fields$), () => this.updateFieldValidity(field));
  }

  private updateFieldValidity(field: FormlyFieldConfig): void {
    field.formControl.markAsTouched();
    field.formControl.updateValueAndValidity({
      emitEvent: false
    });
  }
}
