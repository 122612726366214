import {BaseXsStoreReducerTypes, EntityXsStore, EntityXsStoreActions, EntityXsStoreState} from '@nexnox-web/core-store';
import {ControlDto} from '@nexnox-web/core-shared';

export interface ControlDetailXsStoreState extends EntityXsStoreState<ControlDto> {
}

export interface ControlDetailXsStoreActions extends EntityXsStoreActions<ControlDto> {
}

export class ControlDetailXsStore extends EntityXsStore<ControlDto> {
  public declare actions: ControlDetailXsStoreActions;

  protected createActions(label: string): ControlDetailXsStoreActions {
    return {
      ...super.createActions(label)
    }
  }

  protected createReducerArray(
    initialState: ControlDetailXsStoreState
  ): BaseXsStoreReducerTypes<ControlDetailXsStoreState, ControlDetailXsStoreActions>[] {
    return [
      ...super.createReducerArray(initialState)
    ];
  }
}
