import {Injectable} from '@angular/core';
import {
  AppEntityType,
  CoreSharedHttpTechRequestService,
  FilledCustomValueDto,
  IMonitorService,
  ResourceDto,
  StereotypeDto
} from '@nexnox-web/core-shared';
import {Observable, of} from "rxjs";
import {map, switchMap} from "rxjs/operators";
import {cloneDeep} from "lodash";


export interface ResourcePreviewPayload {
  stereotypes$: Observable<StereotypeDto[]>;
  entityType: AppEntityType;
}

@Injectable()
export class ResourcePreviewService implements IMonitorService<ResourceDto> {

  constructor(private httpRequestService: CoreSharedHttpTechRequestService) {
  }

  public modifyModel(payload: ResourcePreviewPayload, key: string, model: ResourceDto): Observable<ResourceDto> {

    return this.httpRequestService.post<ResourceDto>(`resource/preview`, {
      entity: payload.entityType,
      parentId: model?.parent?.resourceId,
      stereotypeId: model?.stereotypeId
    }).pipe(
      map(response => response.body),
      switchMap(preview => payload.stereotypes$.pipe(
        switchMap(stereotypes => {

            const selectedStereotype = key === 'stereotypeId' ?
              (stereotypes ?? []).find(x => x?.stereotypeId === model?.stereotypeId) :
              (model?.parent as any)?.stereotype;

            return of({
              ...model,
              // Inheritance
              parent: model.parent,
              knowledgeArticles: model.inheritsKnowledge ? preview.knowledgeArticles : model.knowledgeArticles,
              primarySuggestions: model.inheritsSuggestions ? preview.primarySuggestions : model.primarySuggestions,
              alternativeSuggestions: model.inheritsSuggestions ? preview.alternativeSuggestions : model.alternativeSuggestions,
              issueTemplates: model.issueTemplates ? preview.issueTemplates : model.issueTemplates,
              quickAccessConfiguration: model.inheritsQuickAccessConfiguration ? preview.quickAccessConfiguration : model.quickAccessConfiguration,
              model: model.inheritsModel ? preview.model : model.model,
              manufacturer: model.inheritsManufacturer ? preview.manufacturer : model.manufacturer,
              // Stereotype customPropertyValues
              stereotypeId: selectedStereotype.stereotypeId,
              stereotypeRowVersion: selectedStereotype?.rowVersion,
              customPropertyValues: this.mergeCustomPropertyValues(model, preview)
            });
          }
        ))
      ));
  }

  private mergeCustomPropertyValues(entity: ResourceDto, preview: ResourceDto): FilledCustomValueDto[] {
    const mergedCustomPropertyValues = [];

    // Parse to custom values
    for (const customPropertyValue of cloneDeep(preview.customPropertyValues)) {
      const oldCustomPropertyValue = entity?.customPropertyValues?.find(value => value.propertyId === customPropertyValue.propertyId);

      if (oldCustomPropertyValue) {
        const isInherited = oldCustomPropertyValue.customValues.some(value => value.customValue.isInherited);
        // Ask for inheritance
        if (isInherited === false) {
          // Overtake old values if not inherited
          customPropertyValue.customValues = oldCustomPropertyValue.customValues;
          // Delete IDs of old values
          for (const customValue of customPropertyValue.customValues) {
            delete customValue.filledCustomValueId;
            delete customValue.customValueId;
          }
        }
      }
      mergedCustomPropertyValues.push(customPropertyValue);
    }
    return cloneDeep(mergedCustomPropertyValues);
  }
}
