<div class="mb-4">
  <nexnox-web-resources-control-protocol-consumptions [isEmbedded]="true"
                                                      [parentIds]="parentIds"
                                                      [getEntityOnModeChange]="false"
                                                      [canDelete]="false">
  </nexnox-web-resources-control-protocol-consumptions>
</div>
<nexnox-web-resources-control-protocol-errors [isEmbedded]="true"
                                              [parentIds]="parentIds"
                                              [getEntityOnModeChange]="false"
                                              [canDelete]="false">
</nexnox-web-resources-control-protocol-errors>
