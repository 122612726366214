<nexnox-web-entity-edit [entityEditBaseComponent]="this"
                        displayKey="title"
                        [stereotyped]="false">
</nexnox-web-entity-edit>

<nexnox-web-forms-form-rows-edit #formRowsEditComponent
                                 [rows$]="rowsSubject"
                                 [readonly]="readonly"
                                 [loading]="loading"
                                 (rowsChange)="onRowsChange($event)">
</nexnox-web-forms-form-rows-edit>
