import { PagedEntitiesXsStore, PagedEntitiesXsStoreState } from '@nexnox-web/core-store';
import { AppEntityType, TicketListDto } from '@nexnox-web/core-shared';
import { Action, ActionReducer, createSelector } from '@ngrx/store';
import { selectResourcesState } from '../../resources.selectors';
import { TechPortalFeatureResourceTicketService } from '../../services';
import { Injectable, Injector } from '@angular/core';

export interface ResourceTicketsStoreState extends PagedEntitiesXsStoreState<TicketListDto> {
}

/* istanbul ignore next */
export const resourceTicketsStore = new PagedEntitiesXsStore<TicketListDto>({
  actionLabel: 'Tech Portal - Resources - Resource Tickets',
  stateSelector: createSelector(selectResourcesState, state => state.resourceTickets),
  entityType: AppEntityType.Ticket,
  serviceType: TechPortalFeatureResourceTicketService,
  selectId: ticket => ticket.entity?.ticketId ?? ticket.model?.ticketId
});

export function resourceTicketsStoreReducer(state: ResourceTicketsStoreState, action: Action): ActionReducer<any> {
  return resourceTicketsStore.reducer(state, action);
}

@Injectable()
export class ResourceTicketsStoreEffects extends resourceTicketsStore.effects {
  constructor(
    protected injector: Injector
  ) {
    super(injector);
  }
}
