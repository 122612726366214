import {NgModule} from '@angular/core';
import {
  CorePortalFeatureResourceDescriptionComponent,
  CorePortalFeatureResourceInventoryNumberComponent,
  CorePortalFeatureResourcePathComponent,
  CorePortalFeatureResourceSelectChildrenComponent
} from './components';
import {CoreSharedModule} from '@nexnox-web/core-shared';
import {
  CorePortalFeatureResourceControlPointService,
  CorePortalFeatureResourceInheritableService,
  CorePortalFeatureResourceService,
  CorePortalFeatureResourceTaskResourceSelectionService
} from './services';
import {OverlayPanelModule} from 'primeng/overlaypanel';
import {TreeModule} from 'primeng/tree';
import {CorePortalModule} from '@nexnox-web/core-portal';
import {TechPortalFeatureResourceDatatableSearchModalComponent} from './modals';
import {CorePortalFeatureResourceDatatableSearchComponent} from "./sidebars";

@NgModule({
  declarations: [
    CorePortalFeatureResourcePathComponent,
    CorePortalFeatureResourceInventoryNumberComponent,
    CorePortalFeatureResourceSelectChildrenComponent,
    TechPortalFeatureResourceDatatableSearchModalComponent,
    CorePortalFeatureResourceDatatableSearchComponent,
    CorePortalFeatureResourceDescriptionComponent
  ],
  imports: [
    CorePortalModule,
    CoreSharedModule,
    OverlayPanelModule,
    TreeModule
  ],
  exports: [
    CorePortalFeatureResourcePathComponent,
    CorePortalFeatureResourceInventoryNumberComponent,
    CorePortalFeatureResourceSelectChildrenComponent,
    TechPortalFeatureResourceDatatableSearchModalComponent,
    CorePortalFeatureResourceDatatableSearchComponent,
    CorePortalFeatureResourceDescriptionComponent
  ],
  providers: [
    CorePortalFeatureResourceService,
    CorePortalFeatureResourceControlPointService,
    CorePortalFeatureResourceTaskResourceSelectionService,
    CorePortalFeatureResourceInheritableService
  ]
})
export class CorePortalFeatureResourcesDeclarationsModule {
}
