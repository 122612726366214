import {
  escalationLevelDetailStore,
  EscalationLevelDetailStoreState,
  escalationLevelListStore,
  EscalationLevelListStoreState
} from './stores';

export interface EscalationLevelsState {
  escalationLevelList: EscalationLevelListStoreState;
  escalationLevelDetail: EscalationLevelDetailStoreState;
}

export const getInitialEscalationLevelsState = (): EscalationLevelsState => ({
  escalationLevelList: escalationLevelListStore.getInitialState(),
  escalationLevelDetail: escalationLevelDetailStore.getInitialState()
});
