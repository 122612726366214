<p-sidebar #sidebarComponent
           position="right"
           appendTo="body"
           styleClass="p-sidebar-md"
           [transitionOptions]="transitionOptions"
           [visible]="visible$ | async"
           (visibleChange)="onVisibleChange($event)">
  <h4>{{ 'orga-portal.forms.actions.create-form-row' | translate }}</h4>

  <div class="d-flex flex-grow-1 flex-column h-100 mt-1">
    <form [formGroup]="form">
      <formly-form class="row w-100"
                   [form]="form"
                   [(model)]="model"
                   [fields]="fields">
      </formly-form>
    </form>
  </div>

  <div class="d-flex flex-shrink-0 justify-content-end pt-3">
    <button type="button"
            pButton
            [icon]="faPlus | nexnoxWebFaIconStringPipe"
            [label]="'orga-portal.forms.actions.create-form-row' | translate"
            class="p-button-primary ml-2"
            [disabled]="form.invalid"
            (click)="onCreateFormRow()">
    </button>
  </div>
</p-sidebar>
