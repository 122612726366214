import { CorePortalXsStoreEntityActionsFacade } from '@nexnox-web/core-portal';
import { TicketDto } from '@nexnox-web/core-shared';
import { TechPortalFeatureTicketActionsFacade } from '../ticket-actions/ticket-actions.facade';
import { TicketListXsStore } from '../../store';
import {
  TechPortalFeatureTicketActionsFacadeAssignToMePayload,
  TechPortalFeatureTicketActionsFacadeAssignToPayload,
  TechPortalFeatureTicketActionsFacadeChangeStatePayload,
  TechPortalFeatureTicketActionsFacadeExportPayload
} from '../ticket-actions/ticket-actions-facade.payloads';

export class XsStoreTicketActionsFacade extends CorePortalXsStoreEntityActionsFacade<TicketDto>
  implements TechPortalFeatureTicketActionsFacade {
  protected entityStore: TicketListXsStore | any;

  public assignToMe({ id, parentIds }: TechPortalFeatureTicketActionsFacadeAssignToMePayload): void {
    this.store.dispatch(this.entityStore.actions.assignToMe({ id, parentIds }));
  }

  public assignTo({ id, contact, parentIds }: TechPortalFeatureTicketActionsFacadeAssignToPayload): void {
    this.store.dispatch(this.entityStore.actions.assignTo({ id, contact, parentIds }));
  }

  public changeState({ id, state, reason, parentIds }: TechPortalFeatureTicketActionsFacadeChangeStatePayload): void {
    this.store.dispatch(this.entityStore.actions.changeState({
      id,
      state,
      reason,
      parentIds
    }));
  }

  public export({ id, templateId }: TechPortalFeatureTicketActionsFacadeExportPayload): void {
    this.store.dispatch(this.entityStore.actions.export({ id, templateId }));
  }
}
