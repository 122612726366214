import { Directive, Injector } from '@angular/core';
import { CorePortalEntityOverviewBaseComponent } from '@nexnox-web/core-portal';
import { AppEntityType, AuditListDto, Mappers } from '@nexnox-web/core-shared';
import { CorePortalFeatureAuditListXsStore } from '../../store';
import { CorePortalFeatureAuditDetailModalComponent } from '../../modals';

@Directive()
export abstract class CorePortalFeatureAuditListBaseComponent extends CorePortalEntityOverviewBaseComponent<AuditListDto> {
  public title = 'core-portal.audit.subtitles.audit-list';
  public createTitle = null;
  public idProperty = 'auditId';
  public displayProperty = null;
  public datatableConfigName = 'AuditDefault';

  public canCreate = false;

  /* istanbul ignore next */
  public detailFn = (audit: AuditListDto): void => this.onDetail(audit);

  protected constructor(
    protected injector: Injector,
    entityStore: CorePortalFeatureAuditListXsStore
  ) {
    super(injector, entityStore, Mappers.AuditListDto.serializedName, AppEntityType.None);
  }

  /* istanbul ignore next */
  public onDetail(audit: AuditListDto): void {
    this.modalService.showModal(CorePortalFeatureAuditDetailModalComponent, instance => {
      instance.auditId = audit.auditId;
      instance.entityType = audit.entityType;
      instance.entityId = audit.entityId;
    }).catch(() => null);
  }
}
