import { documentTemplateListStore, DocumentTemplateListStoreState } from './document-template-list/document-template-list.store';
import { documentTemplateDetailStore, DocumentTemplateDetailStoreState } from './document-template-detail/document-template-detail.store';

export interface DocumentTemplatesState {
  documentTemplateList: DocumentTemplateListStoreState;
  documentTemplateDetail: DocumentTemplateDetailStoreState;
}

export const getInitialDocumentTemplatesState = (): DocumentTemplatesState => ({
  documentTemplateList: documentTemplateListStore.getInitialState(),
  documentTemplateDetail: documentTemplateDetailStore.getInitialState()
});
