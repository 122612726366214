<p-sidebar #sidebarComponent
           position="right"
           appendTo="body"
           styleClass="p-sidebar-md"
           [transitionOptions]="transitionOptions"
           [visible]="visible$ | async"
           (visibleChange)="onVisibleChange($event)">
  <div class="d-flex align-content-between flex-column h-100"
       *ngIf="(loading$ | async) === false; else loadingTemplate">

    <h4 class="pl-2">{{ 'core-portal.settings.actions.move-model' | translate }}</h4>

    <div class="row no-gutters w-100">
      <form [formGroup]="form" class="w-100">
        <formly-form class="row w-100"
                     [(model)]="model"
                     [form]="form"
                     [fields]="fields">
        </formly-form>
        <small class="form-text text-muted mx-2">
          {{ descriptionTranslation$ | async }}
        </small>
      </form>
    </div>

    <div class="w-100 d-flex flex-shrink-1 gap-2 justify-content-end mt-auto mb-0">
      <button pButton
              type="button"
              class="p-button p-button-secondary"
              [label]="'core-portal.core.general.cancel' | translate"
              (click)="onHide()">
      </button>
      <button pButton
              type="button"
              class="p-button p-button-danger"
              [disabled]="!form.valid"
              [label]="'core-portal.settings.actions.move-model' | translate"
              (click)="onAccept()">
      </button>
    </div>
  </div>
</p-sidebar>
<ng-template #loadingTemplate>
  <nexnox-web-loading-icon class="d-flex w-100 h-100 align-items-center justify-content-center flex-column p-5"
                           size="5x">
  </nexnox-web-loading-icon>
</ng-template>

<ng-template #destinationTemplate let-item="item">
  <span *ngIf="item.emailAddress">&nbsp;<{{ item.emailAddress }}></span>
</ng-template>
