import {
  BaseXsStoreReducerTypes,
  PagedEntitiesXsStore,
  PagedEntitiesXsStoreActions,
  PagedEntitiesXsStoreEffects,
  PagedEntitiesXsStoreState
} from '@nexnox-web/core-store';
import {AppEntityType, TicketIssueListDto} from '@nexnox-web/core-shared';
import {Action} from '@ngrx/store';
import {Injectable, Injector, Type} from '@angular/core';
import {TicketIssueService} from "@nexnox-web/tech-portal/features/issues/src/lib/store";
// import {TechPortalIssueTemplateService} from '@nexnox-web/tech-portal-lib';

export interface TicketIssueListXsStoreActions extends PagedEntitiesXsStoreActions<TicketIssueListDto> {
}

export class TicketIssueListXsStore extends PagedEntitiesXsStore<TicketIssueListDto> {
  public actions: TicketIssueListXsStoreActions;

  protected createActions(label: string): TicketIssueListXsStoreActions {
    return {
      ...super.createActions(label)
    };
  }

  protected createReducerArray(
    initialState: PagedEntitiesXsStoreState<TicketIssueListDto>
  ): BaseXsStoreReducerTypes<PagedEntitiesXsStoreState<TicketIssueListDto>, TicketIssueListXsStoreActions>[] {
    return [
      ...super.createReducerArray(initialState)
    ];
  }

  protected createEffects(
    serviceType: Type<TicketIssueService>,
    entityType: AppEntityType,
    prepareEntity: (entity: TicketIssueListDto) => TicketIssueListDto,
    prepareModel: (entity: TicketIssueListDto, model: TicketIssueListDto) => TicketIssueListDto,
    sanitizeModel: (model: TicketIssueListDto, entity: TicketIssueListDto) => TicketIssueListDto,
    ...args
  ): Type<PagedEntitiesXsStoreEffects<TicketIssueListDto>> {
    const actions = this.actions;
    const selectors = this.selectors;

    @Injectable()
    class Effects extends PagedEntitiesXsStoreEffects<TicketIssueListDto> {
      protected service: TicketIssueService;

      constructor(
        protected injector: Injector
      ) {
        super(injector, actions, selectors, serviceType, entityType, prepareEntity, prepareModel, sanitizeModel, true);
      }

      protected createEffects(): void {
        super.createEffects();
      }

      protected actionCallback(action: Action, isError: boolean = false): void {
        super.actionCallback(action, isError);
      }
    }

    return Effects;
  }
}
