import { ChangeDetectionStrategy, Component, Injector, Input, OnInit } from '@angular/core';
import {
  AppEntityType,
  AuditDto,
  AuditOfCollectionDto,
  AuditOfPropertyDto,
  AuditPropertyType,
  CoreSharedModalBaseComponent
} from '@nexnox-web/core-shared';
import { CorePortalFeatureAuditDetailService } from '../../store';
import { Observable } from 'rxjs';
import { map, share } from 'rxjs/operators';

@Component({
  selector: 'nexnox-web-audit-audit-detail-modal',
  templateUrl: './audit-detail-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CorePortalFeatureAuditDetailModalComponent extends CoreSharedModalBaseComponent implements OnInit {
  @Input() public auditId: number;
  @Input() public entityType: AppEntityType;
  @Input() public entityId: number;

  public audit$: Observable<AuditDto>;
  public properties$: Observable<AuditOfPropertyDto[]>;
  public collections$: Observable<AuditOfCollectionDto[]>;

  public propertyTypes = AuditPropertyType;

  constructor(
    protected injector: Injector,
    private auditDetailService: CorePortalFeatureAuditDetailService
  ) {
    super(injector);
  }

  /* istanbul ignore next */
  public ngOnInit(): void {
    this.audit$ = this.auditDetailService.getOne(this.auditId, [this.entityType, this.entityId]).pipe(
      share()
    );
    this.properties$ = this.audit$.pipe(
      map(audit => audit.properties)
    );
    this.collections$ = this.audit$.pipe(
      map(audit => audit.collections)
    );
  }
}
