<ng-template #entityEditTemplate let-title="title" let-id="id">
    <nexnox-web-master-functions-function-edit #modelComponent
                                               [title]="title"
                                               [id]="id"
                                               [model]="model$ | async"
                                               [readonly]="readonly$ | async"
                                               [creating]="false"
                                               [loading]="loading$ | async"
                                               [stereotypes$]="stereotypes$"
                                               (modelChange)="onModelChange($event)">
    </nexnox-web-master-functions-function-edit>
</ng-template>

<nexnox-web-entity-detail [entityDetailBaseComponent]="this"
                          [entityEditTemplate]="entityEditTemplate"
                          tabId="function"
                          [tabHeading]="title | translate"
                          [tabCommands]="['.']">
    <!-- Contacts Tab -->
    <ng-template nexnoxWebTabTemplate
                 tabId="contacts"
                 [heading]="'core-portal.master-data.subtitles.contact-list' | translate"
                 [lazy]="true"
                 (selectTab)="onNavigateToTab(['contacts'])">
        <nexnox-web-master-data-functions-contact-list *ngIf="passedIds"
                                                       [isEmbedded]="true"
                                                       [parentIds]="passedIds"
                                                       [getEntityOnModeChange]="false">
        </nexnox-web-master-data-functions-contact-list>
    </ng-template>
</nexnox-web-entity-detail>
