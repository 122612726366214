import {ChangeDetectionStrategy, Component, Injector, OnInit} from '@angular/core';
import {ActionButton, CorePortalEntityDetailStandaloneBaseComponent} from '@nexnox-web/core-portal';
import {companyAddressDetailStore} from "@nexnox-web/core-portal/features/master-data/features/companies/src/lib/store";
import {AddressDto, AppPermissions} from "@nexnox-web/core-shared";

@Component({
  selector: 'nexnox-web-master-data-companies-company-address-detail-partial',
  templateUrl: './company-address-detail-standalone.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CompanyAddressDetailStandaloneComponent extends CorePortalEntityDetailStandaloneBaseComponent<AddressDto> implements OnInit {

  public idParam = "addressId";
  public displayKey = 'street'

  constructor(
    protected injector: Injector,
  ) {
    super(injector, companyAddressDetailStore);
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.setTitle('core-portal.master-data.actions.create-address', 'core-portal.master-data.actions.edit-address');
  }

  protected async getActionButtons(): Promise<ActionButton[]> {
    return this.getStandaloneActionButtons(
      'core-portal.master-data.actions.create-address',
      AppPermissions.CreateAddress,
      'core-portal.master-data.actions.edit-address',
      AppPermissions.UpdateAddress,
      'core-portal.master-data.actions.save-address',
      AppPermissions.UpdateAddress
    )
  }
}
