import { ModuleWithProviders, NgModule } from '@angular/core';
import { MissionsDeclarationsModule } from './missions-declarations.module';
import { MissionsStoreModule } from './missions-store.module';
import { MissionsRoutingModule } from './missions-routing.module';

@NgModule({
  imports: [
    MissionsDeclarationsModule,
    MissionsRoutingModule,
    MissionsStoreModule
  ]
})
export class TechPortalFeatureMissionsModule {
  public static forFeature(): ModuleWithProviders<MissionsDeclarationsModule> {
    return { ngModule: MissionsDeclarationsModule };
  }
}
