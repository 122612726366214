<ng-template #entityEditTemplate let-title="title" let-id="id">
  <nexnox-web-master-data-addresses-address-edit #modelComponent
                                                 [title]="title"
                                                 [id]="id"
                                                 [model]="model$ | async"
                                                 [readonly]="readonly$ | async"
                                                 [creating]="false"
                                                 [loading]="loading$ | async"
                                                 [stereotypes$]="stereotypes$"
                                                 (modelChange)="onModelChange($event)">
  </nexnox-web-master-data-addresses-address-edit>
</ng-template>

<nexnox-web-entity-detail [entityDetailBaseComponent]="this"
                          [entityEditTemplate]="entityEditTemplate"
                          tabId="address"
                          [tabHeading]="title | translate"
                          [tabCommands]="['.']">
  <!-- Locations Tab -->
  <ng-template nexnoxWebTabTemplate
               tabId="locations"
               *ngIf="readLocationsPermission$ | async"
               [heading]="'core-portal.master-data.subtitles.location-list' | translate"
               [lazy]="true"
               (selectTab)="onNavigateToTab(['locations'])">
    <nexnox-web-master-data-addresses-address-location-list *ngIf="passedIds"
                                                            [isEmbedded]="true"
                                                            [canCreate]="false"
                                                            [parentIds]="passedIds">
    </nexnox-web-master-data-addresses-address-location-list>
  </ng-template>

  <!-- Companies Tab -->
  <ng-template nexnoxWebTabTemplate
               tabId="companies"
               *ngIf="readCompaniesPermission$ | async"
               [heading]="'core-portal.master-data.subtitles.company-list' | translate"
               [lazy]="true"
               (selectTab)="onNavigateToTab(['companies'])">
    <nexnox-web-master-data-addresses-address-company-list *ngIf="passedIds"
                                                           [isEmbedded]="true"
                                                           [canCreate]="false"
                                                           [parentIds]="passedIds">
    </nexnox-web-master-data-addresses-address-company-list>
  </ng-template>
</nexnox-web-entity-detail>
