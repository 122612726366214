import { ModuleWithProviders, NgModule } from '@angular/core';
import { ControlPointsDeclarationsModule } from './control-points-declarations.module';
import { ControlPointsRoutingModule } from './control-points-routing.module';
import { ControlPointsStoreModule } from './control-points-store.module';

@NgModule({
  imports: [
    ControlPointsDeclarationsModule,
    ControlPointsRoutingModule,
    ControlPointsStoreModule
  ]
})
export class TechPortalFeatureCCPControlPointsModule {
  public static forFeature(): ModuleWithProviders<ControlPointsDeclarationsModule> {
    return { ngModule: ControlPointsDeclarationsModule };
  }
}
