import { PagedEntitiesXsStoreState } from '@nexnox-web/core-store';
import { AppEntityType, TicketDto } from '@nexnox-web/core-shared';
import { createSelector } from '@ngrx/store';
import { selectTicketsState } from '../../tickets.selectors';
import { TechPortalTicketService } from '@nexnox-web/tech-portal-lib';
import { TicketListXsStore } from './ticket-list.xs-store';

export interface TicketListStoreState extends PagedEntitiesXsStoreState<TicketDto> {
}

/* istanbul ignore next */
export const ticketListStore = new TicketListXsStore({
  actionLabel: 'Tech Portal - Tickets - Ticket List',
  stateSelector: createSelector(selectTicketsState, state => state.ticketList),
  serviceType: TechPortalTicketService,
  entityType: AppEntityType.Ticket,
  selectId: ticket => ticket.entity?.ticketId ?? ticket.model?.ticketId
});
