import { PagedEntitiesXsStore, PagedEntitiesXsStoreState } from '@nexnox-web/core-store';
import { AppEntityType, PrioritySetDto } from '@nexnox-web/core-shared';
import { Action, createSelector } from '@ngrx/store';
import { selectPrioritySetsState } from '../../priority-sets.selectors';
import { TechPortalFeatureTicketSettingsPrioritySetService } from '../../services';
import { Injectable, Injector } from '@angular/core';

export interface PrioritySetListStoreState extends PagedEntitiesXsStoreState<PrioritySetDto> {
}

/* istanbul ignore next */
export const prioritySetListStore = new PagedEntitiesXsStore<PrioritySetDto>({
  actionLabel: 'Tech Portal - Ticket Settings - Priority Sets - Priority Set List',
  stateSelector: createSelector(selectPrioritySetsState, state => state.prioritySetList),
  serviceType: TechPortalFeatureTicketSettingsPrioritySetService,
  entityType: AppEntityType.None,
  selectId: prioritySet => prioritySet.entity?.prioritySetId ?? prioritySet.model?.prioritySetId
});

export function prioritySetListStoreReducer(state: PrioritySetListStoreState, action: Action): any {
  return prioritySetListStore.reducer(state, action);
}

@Injectable()
export class PrioritySetListStoreEffects extends prioritySetListStore.effects {
  constructor(
    protected injector: Injector
  ) {
    super(injector);
  }
}
