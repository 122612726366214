import { NgModule } from '@angular/core';
import { addressesServices } from './store';
import { CorePortalFeatureAddressEditComponent } from './components';
import { CoreSharedModule } from '@nexnox-web/core-shared';
import { CorePortalEntityModule } from '@nexnox-web/core-portal';
import { AddressCompanyListComponent, AddressDetailComponent, AddressListComponent, AddressLocationListComponent } from './containers';

@NgModule({
  declarations: [
    AddressDetailComponent,
    AddressListComponent,
    AddressLocationListComponent,
    AddressCompanyListComponent,

    CorePortalFeatureAddressEditComponent
  ],
  imports: [
    CoreSharedModule,
    CorePortalEntityModule
  ],
  providers: [
    ...addressesServices
  ],
  exports: [
    CorePortalFeatureAddressEditComponent
  ]
})
export class AddressesDeclarationsModule {
}
