import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'nexnoxWebDashCase'
})
export class DashCasePipe implements PipeTransform {
  public static transformString(value: string): string {
    return value.split(/(?=[A-Z])/).map(item => item.toLowerCase()).join('-');
  }

  public transform(value: string): string {
    return DashCasePipe.transformString(value);
  }
}
