import { ChangeDetectionStrategy, Component, Input, QueryList, ViewChildren } from '@angular/core';
import { TechPortalFeatureSolutionBaseComponent } from '../../containers';
import { NoteType, SolutionItemDto, SolutionMemberDto } from '@nexnox-web/core-shared';
import { TranslateService } from '@ngx-translate/core';
import { faSync } from '@fortawesome/free-solid-svg-icons/faSync';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { faComment } from '@fortawesome/free-solid-svg-icons/faComment';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons/faEnvelope';
import { faPhone } from '@fortawesome/free-solid-svg-icons/faPhone';
import { faExchangeAlt } from '@fortawesome/free-solid-svg-icons/faExchangeAlt';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons/faExclamationTriangle';
import { faQuestion } from '@fortawesome/free-solid-svg-icons/faQuestion';
import { BehaviorSubject, Observable } from 'rxjs';
import { TechPortalFeatureSolutionItemComponent } from '../solution-item/solution-item.component';
import { distinctUntilChanged, map } from 'rxjs/operators';

export enum FilterElement {
  STATE_CHANGES,
  CHATS,
  MAILS
}

@Component({
  selector: 'nexnox-web-solutions-solution',
  templateUrl: './solution.component.html',
  styleUrls: ['./solution.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TechPortalFeatureSolutionComponent {
  @Input() public baseComponent: TechPortalFeatureSolutionBaseComponent;
  @Input() public readonly: boolean;

  @ViewChildren('solutionItemComponent') public solutionItemComponents: QueryList<TechPortalFeatureSolutionItemComponent>;

  public filterItemsSubject: BehaviorSubject<FilterElement[]> = new BehaviorSubject<FilterElement[]>([
    FilterElement.STATE_CHANGES,
    FilterElement.CHATS,
    FilterElement.MAILS
  ]);
  public showFilters$: Observable<{ stateChanges: boolean, chats: boolean, mails: boolean }>;

  public selectedSolutionMember: SolutionMemberDto = null;

  public filterItemOptions = [
    { label: 'solutions.filter-elements.0', value: FilterElement.STATE_CHANGES },
    { label: 'solutions.filter-elements.1', value: FilterElement.CHATS },
    { label: 'solutions.filter-elements.2', value: FilterElement.MAILS }
  ];

  public faSync = faSync;

  public searchFn = (term: string, item: SolutionMemberDto): boolean => this.search(term, item);

  constructor(
    private translate: TranslateService
  ) {
    this.showFilters$ = this.filterItemsSubject.asObservable().pipe(
      distinctUntilChanged(),
      map(items => ({
        stateChanges: items.includes(FilterElement.STATE_CHANGES),
        chats: items.includes(FilterElement.CHATS),
        mails: items.includes(FilterElement.MAILS)
      }))
    );
  }

  public onRefresh(): void {
    this.baseComponent?.onLoadPage({
      filters: undefined,
      sortOptions: undefined,
      pageNumber: undefined
    });
  }

  public onChangeShowFilter(filters: FilterElement[]): void {
    this.filterItemsSubject.next(filters);
  }

  public onSelectedSolutionMemberChange(solutionMember: SolutionMemberDto): void {
    this.selectedSolutionMember = solutionMember;
    this.baseComponent.onFilterSolutionMember(solutionMember);
  }

  /* istanbul ignore next */
  public getSolutionItemIcon(solutionItem: SolutionItemDto): IconDefinition {
    switch (solutionItem?.note?.type) {
      case NoteType.Text:
      case NoteType.ChatMessage:
        return faComment;
      case NoteType.Mail:
        return faEnvelope;
      case NoteType.Caller:
        return faPhone;
      case NoteType.ChangedStatus:
        return faExchangeAlt;
      case NoteType.ChangedStatusOfMission:
        return faExchangeAlt;
      case NoteType.EscalatedByEvent:
        return faExclamationTriangle;
      case NoteType.EscalatedByUser:
        return faExclamationTriangle;
      case NoteType.CanceledEscalation:
        return faExclamationTriangle;
      default:
        return faQuestion;
    }
  }

  private search(term: string, item: SolutionMemberDto): boolean {
    if (item.title.toLowerCase().includes(term.toLowerCase())) {
      return true;
    } else if (this.translate.instant(`solutions.solution-members.${item.type}`).toLowerCase().includes(term.toLowerCase())) {
      return true;
    }

    return false;
  }
}
