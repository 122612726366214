import {PagedEntitiesXsStoreState} from '@nexnox-web/core-store';
import {AppEntityType, MessageControlListDto} from '@nexnox-web/core-shared';
import {createSelector} from '@ngrx/store';
import {selectIssuesState} from '../../issues.selectors';
import {MessageControlIssueListXsStore} from './message-control-issue-list-xs.store';
import {MessageControlIssueService} from "../../services";

export interface MessageControlIssueListStoreState extends PagedEntitiesXsStoreState<MessageControlListDto> {
}

/* istanbul ignore next */
export const messageControlIssueListStore = new MessageControlIssueListXsStore({
  actionLabel: 'Tech Portal - Issues - Message Control Issue List',
  stateSelector: createSelector(selectIssuesState, state => state.messageControlIssueList),
  serviceType: MessageControlIssueService,
  entityType: AppEntityType.Issue,
  selectId: issue => issue.entity?.issueId ?? issue.model?.issueId
});
