import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {CoreSharedSidebarBaseComponent} from '@nexnox-web/core-shared';
import {Observable, Subject} from 'rxjs';
import {faClipboardCheck} from "@fortawesome/free-solid-svg-icons/faClipboardCheck";
import {DataTableCalendarSubscriptionService} from "../../services";

@Component({
  selector: 'nexnox-web-calendar-subscription-sidebar',
  templateUrl: './calendar-subscription-sidebar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CalendarSubscriptionSidebarComponent extends CoreSharedSidebarBaseComponent {

  @Input() public subscriptionLink$: Observable<string>;

  @Input() public loadingError$: Subject<boolean> = new Subject<boolean>();

  public faClipboard = faClipboardCheck;

  constructor(
    private calendarSubscriptionService: DataTableCalendarSubscriptionService,
  ) {
    super();
  }

  public onCopy(link: string): void {
    this.calendarSubscriptionService.copyTextToClipboard(link);
    this.onHide();
  }
}
