import { NgModule } from '@angular/core';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { exportedModules } from './shared-exports';

@NgModule({
  imports: [
    ...exportedModules,
    TabsModule.forRoot()
  ],
  exports: [
    ...exportedModules,
    TabsModule
  ]
})
export class CorePortalTabsRootModule {
}
