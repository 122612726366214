import {PagedEntitiesXsStoreState} from '@nexnox-web/core-store';
import {AppEntityType, MissionIssueListDto} from '@nexnox-web/core-shared';
import {createSelector} from '@ngrx/store';
import {selectIssuesState} from '../../issues.selectors';
import {MissionIssueListXsStore} from './mission-issue-list.xs-store';
import {MissionIssueService} from "../../services";

export interface MissionIssueListStoreState extends PagedEntitiesXsStoreState<MissionIssueListDto> {
}

/* istanbul ignore next */
export const missionIssueListStore = new MissionIssueListXsStore({
  actionLabel: 'Tech Portal - Issues - Mission Issue List',
  stateSelector: createSelector(selectIssuesState, state => state.missionIssueList),
  serviceType: MissionIssueService,
  entityType: AppEntityType.Issue,
  selectId: issue => issue.entity?.issueId ?? issue.model?.issueId
});
