import {ChangeDetectionStrategy, Component, Injector} from '@angular/core';
import {ActionButton, CorePortalEntityDetailBaseComponent} from '@nexnox-web/core-portal';
import {AppPermissions, ExportType, TemplateDto} from '@nexnox-web/core-shared';
import {textTemplateDetailStore} from '../../../store';
import {faEye} from '@fortawesome/free-solid-svg-icons/faEye';
import {map, take} from 'rxjs/operators';
import {TechPortalFeatureTextTemplateEditComponent} from '../../../components/text-templates';
import {BehaviorSubject} from "rxjs";
import {TemplateUsageTypes} from "../../../models";

@Component({
  selector: 'nexnox-web-templates-text-template-detail',
  templateUrl: './text-template-detail.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TextTemplateDetailComponent extends CorePortalEntityDetailBaseComponent<TemplateDto> {
  public title = 'core-portal.settings.subtitles.templates.text-template-detail';

  public previewLoadingSubject = new BehaviorSubject<boolean>(false);
  public templateUsageType: TemplateUsageTypes;

  protected idParam = 'templateId';
  protected displayKey = 'title';

  constructor(
    protected injector: Injector
  ) {
    super(injector, textTemplateDetailStore);
  }

  /* istanbul ignore next */
  protected async getActionButtons(): Promise<ActionButton[]> {
    return [
      ...this.getDefaultActionButtons(
        'core-portal.settings.actions.templates.edit-text-template',
        'core-portal.settings.actions.templates.save-text-template',
        'core-portal.settings.actions.templates.delete-text-template',
        'core-portal.settings.descriptions.templates.delete-text-template',
        AppPermissions.UpdateTemplate,
        AppPermissions.DeleteTemplate,
        undefined,
        undefined,
        undefined,
        ExportType.TEXT_TEMPLATE
      ),
      {
        label: 'core-portal.settings.actions.templates.preview-text-template',
        icon: faEye,
        type: 'button',
        class: 'btn-outline-primary',
        isDisabled: () => this.isModelValid$.pipe(
          map(valid => !valid)
        ),
        isLoading: () => this.previewLoadingSubject.asObservable(),
        callback: async () => {
          this.previewLoadingSubject.next(true)
          const model = await this.model$.pipe(take(1)).toPromise();
          (this.modelComponents.toArray()[0] as TechPortalFeatureTextTemplateEditComponent)?.onPreview(model.content, model.context, this.previewLoadingSubject);
        }
      }
    ];
  }
}
