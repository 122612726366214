import {Inject, Injectable} from '@angular/core';
import {BehaviorSubject, Observable, throwError} from "rxjs";
import {
  ApiNotificationService,
  AppPermissions,
  CORE_SHARED_ENVIRONMENT,
  Environment,
  HttpAuthorizedClient
} from "@nexnox-web/core-shared";
import {catchError, take, tap} from "rxjs/operators";
import {faFileExport} from "@fortawesome/free-solid-svg-icons/faFileExport";
import {DatatableActionButton} from "@nexnox-web/core-portal";

export enum ExportType {
  APP_SHORTCUT = 'appoverview',
  DASHBOARD = 'dashboard',
  DOCUMENT_TEMPLATE = 'documenttemplate',
  FUNCTION = 'function',
  INVENTORY_NUMBER_PATTERN = 'inventorynumberpattern',
  PRIORITY_SET = 'priorityset',
  ROLE = 'role',
  STATE = 'state',
  STATE_MACHINE = 'statemachine',
  STEREOTYPE = 'stereotype',
  TEXT_TEMPLATE = 'template',
}

@Injectable()
export class CoreSharedExportService {

  public faFileExport = faFileExport;
  private _exportLoading$ = new BehaviorSubject<boolean>(false);

  constructor(
    @Inject(CORE_SHARED_ENVIRONMENT) protected environment: Environment,
    private http: HttpAuthorizedClient,
    private apiNotificationService: ApiNotificationService
  ) {
  }

  public getLoading(): Observable<boolean> {
    return this._exportLoading$.asObservable();
  }

  public getRowActionExportButton(type: ExportType, idProperty: string, permissions?: AppPermissions[]): DatatableActionButton {
    return {
      tooltip: 'core-shared.shared.table.tooltip.export-object',
      icon: faFileExport,
      onClick: (row) => this.export(type, row[idProperty]),
      permissions: permissions ? permissions : [AppPermissions.ExportEntity]
    }
  }

  public export(type: ExportType, id: number): void {
    this._exportLoading$.next(true);
    this._getExportObject(this._createURL(type, id)).subscribe((exportObject) => {
      this._downloadObject(exportObject?.metadata?.defaultFileName, this._stringify(exportObject));
      this._exportLoading$.next(false);
    });
  }

  private _getExportObject(url: string): Observable<any> {
    return this.http.get(url).pipe(
      take(1),
      catchError((error) => {
        this.apiNotificationService.handleApiError(error);
        this._exportLoading$.next(false);
        return throwError(error);
      }),
      tap(() => this.apiNotificationService.showTranslatedSuccess('core-shared.shared.toast.entity-downloaded'))
    );
  }

  private _downloadObject(filename: string = 'download.json', exportObject: string): void {
    const blob = new Blob([exportObject], {type: 'application/json'});
    const downloadURL = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = downloadURL;
    link.download = filename;
    link.click();
  }

  private _createURL(type: ExportType, id: number): string {
    switch (type) {
      case ExportType.APP_SHORTCUT:
        return `${this.environment.apiUrl.orga}/${type}/${id}/export`;
        break;
      default:
        return `${this.environment.apiUrl.tech}/${type}/${id}/export`;
    }
  }

  private _stringify(object: any): string {
    return JSON.stringify(object);
  }
}
