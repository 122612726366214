import {ChangeDetectionStrategy, Component, Injector} from '@angular/core';
import {ActionButton, CorePortalEntityDetailBaseComponent} from '@nexnox-web/core-portal';
import {AppPermissions, InvitationDto} from '@nexnox-web/core-shared';
import {invitationsDetailStore} from '../../store/stores';
import {faTimesCircle} from "@fortawesome/free-solid-svg-icons/faTimesCircle";
import {lastValueFrom, Observable, take} from "rxjs";
import {select} from "@ngrx/store";
import {map} from "rxjs/operators";
import {faHandshakeSlash} from "@fortawesome/free-solid-svg-icons/faHandshakeSlash";
import {faHandshake} from "@fortawesome/free-solid-svg-icons/faHandshake";
import {faExclamationTriangle} from "@fortawesome/free-solid-svg-icons/faExclamationTriangle";

@Component({
  selector: 'nexnox-web-settings-invitations-detail',
  templateUrl: './invitations-detail.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InvitationsDetailComponent extends CorePortalEntityDetailBaseComponent<InvitationDto> {
  public title = 'core-portal.settings.invitations.titles.detail';

  public faExclamationTriangle = faExclamationTriangle;

  protected revokeInvitationLoading$: Observable<boolean>;
  protected reactivateInvitationLoading$: Observable<boolean>;

  protected idParam = 'invitationId';
  protected displayKey = 'name';

  constructor(
    protected injector: Injector
  ) {
    super(injector, invitationsDetailStore);

    this.revokeInvitationLoading$ = this.store.pipe(
      select(invitationsDetailStore.selectors.selectEntityDataLoading, {key: invitationsDetailStore.actions.revokeInvitation.type}),
      map(loading => Boolean(loading))
    );

    this.reactivateInvitationLoading$ = this.store.pipe(
      select(invitationsDetailStore.selectors.selectEntityDataLoading, {key: invitationsDetailStore.actions.reactivateInvitation.type}),
      map(loading => Boolean(loading))
    );
  }

  protected async getActionButtons(): Promise<ActionButton[]> {
    const defaultButtons = this.getDefaultActionButtons(
      'core-portal.settings.invitations.actions.edit',
      'core-portal.settings.invitations.actions.save',
      'core-portal.settings.invitations.actions.delete',
      'core-portal.settings.invitations.descriptions.delete',
      AppPermissions.UpdateInvitation,
      AppPermissions.DeleteInvitation,
      ['invitations'],
      'settings'
    );
    // Remove edit button, keep delete submenu, add cancel/return button, add revoke buttons
    return [
      ...defaultButtons.filter(button => button.permission !== AppPermissions.UpdateInvitation),
      {
        label: 'core-portal.core.general.cancel',
        type: 'button',
        class: 'btn-outline-secondary',
        icon: faTimesCircle,
        isLoading: () => this.loading$,
        callback: () => this.router.navigate(this.getReturnToListPath())
      },
      {
        label: 'core-portal.settings.invitations.actions.revoke',
        type: 'button',
        class: 'btn-outline-danger',
        icon: faHandshakeSlash,
        permission: AppPermissions.UpdateInvitation,
        isLoading: () => this.revokeInvitationLoading$,
        shouldShow: () => this.model$.pipe(map(model => model.isRevoked !== true)),
        callback: async () => {
          const invitation = await lastValueFrom(this.model$.pipe(take(1)));
          this.store.dispatch(invitationsDetailStore.actions.revokeInvitation({invitation}));
        }
      },
      {
        label: 'core-portal.settings.invitations.actions.reactivate',
        type: 'button',
        class: 'btn-outline-success',
        icon: faHandshake,
        permission: AppPermissions.UpdateInvitation,
        isLoading: () => this.reactivateInvitationLoading$,
        shouldShow: () => this.model$.pipe(map(model => model.isRevoked === true)),
        callback: async () => {
          const invitation = await lastValueFrom(this.model$.pipe(take(1)));
          this.store.dispatch(invitationsDetailStore.actions.reactivateInvitation({invitation}));
        }
      }
    ];
  }
}
