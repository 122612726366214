import { CoreSharedApiBaseService, Product, TaskJobState } from '@nexnox-web/core-shared';
import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class TaskjobService extends CoreSharedApiBaseService {
  constructor(
    protected injector: Injector
  ) {
    super(injector, Product.ORGA, 'taskjob');
  }

  public setState(id: number | string, targetState: TaskJobState): Observable<any> {
    return this.httpRequestService.post(`${this.buildPathForOne(id)}/status`, { targetState }).pipe(
      map(response => response.body)
    );
  }
}
