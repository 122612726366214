import {ChangeDetectionStrategy, Component, Input, OnInit, ViewChild} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {Observable, of} from 'rxjs';
import {authStore, headerStore} from '../../../../store';
import {map, mergeMap} from 'rxjs/operators';
import {faUser} from '@fortawesome/free-solid-svg-icons/faUser';
import {faBars} from '@fortawesome/free-solid-svg-icons/faBars';
import {AppSettingsSidebarComponent} from '../../sidebars';
import {CorePortalSidebarService} from '../../../sidebar';
import {faQuestionCircle} from "@fortawesome/free-solid-svg-icons/faQuestionCircle";
import {CoreSharedBreadcrumb, RoleGeneralTerms, TenantInfoDto} from '@nexnox-web/core-shared';

@Component({
  selector: 'nexnox-web-layout-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CorePortalLayoutHeaderComponent implements OnInit {
  @Input() public breadcrumbs$: Observable<CoreSharedBreadcrumb[]>;
  @Input() public sidebarOpen: boolean;

  @ViewChild('appUserSettingsSidebarComponent') public appUserSettingsSidebarComponent: AppSettingsSidebarComponent;

  public title$: Observable<string>;
  public loggedIn$: Observable<boolean>;
  public loading$: Observable<boolean>;

  public tenantItems$: Observable<{ label: string; id: number }[]>
  public activeTenant$: Observable<TenantInfoDto>;

  public faBars = faBars;
  public faUser = faUser;
  public faQuestionCircle = faQuestionCircle;


  constructor(
    private store: Store<any>,
    private router: Router,
    private translate: TranslateService,
    private sidebarService: CorePortalSidebarService
  ) {
  }

  public ngOnInit(): void {
    this.title$ = this.store.pipe(
      select(headerStore.selectors.selectTitle),
      mergeMap(title => title ? this.translate.stream(title) : of(null))
    );

    this.loggedIn$ = this.store.pipe(select(authStore.selectors.selectLoggedIn));
    this.loading$ = this.store.pipe(
      select(authStore.selectors.selectLoading),
      mergeMap(loading => this.loggedIn$.pipe(
        map(loggedIn => loading || !loggedIn)
      ))
    );

    this.activeTenant$ = this.store.pipe(select(authStore.selectors.selectActiveTenant));
    this.tenantItems$ = this.store.pipe(
      select(authStore.selectors.selectTenants),
      map(tenants => tenants.filter(tenant => this._isActiveTenant(tenant))),
      map(tenants => tenants.filter(tenant => !this._hasAppRoleOnly(tenant))),
      map(tenants => tenants.map(tenant => ({id: tenant?.tenantId, label: tenant?.name})))
    );
  }

  public onToggleSidebar(): void {
    this.sidebarService.toggle();
  }

  public onChangePassword(): void {
    this.router.navigate(['/user/change-password']);
  }

  public onLogout(): void {
    this.store.dispatch(authStore.actions.logout());
  }

  public onGoToHelp(): void {
    window.open('https://help.semco.app/', '_blank');
  }

  public onOpenUserSettings(): void {
    this.appUserSettingsSidebarComponent?.onShow();
  }

  private _isActiveTenant(tenant: TenantInfoDto): boolean  {
    return tenant.subscriptions.some(s => s.isActive === true);
  }

  private _hasAppRoleOnly(tenant: TenantInfoDto): boolean {

    return tenant.roles.every(role =>
      role.term === RoleGeneralTerms.TeamUser ||
      role.term === RoleGeneralTerms.DeviceUser ||
      role.term === RoleGeneralTerms.QuickAccessUser ||
      role.term === RoleGeneralTerms.OrgaUser
    );
  }
}
