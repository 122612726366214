import { ChangeDetectionStrategy, Component, Injector, Input, OnInit } from '@angular/core';
import { CorePortalEntityOverviewBaseComponent, DatatableActionButton } from '@nexnox-web/core-portal';
import {
  AppEntityType,
  AttachmentTypes,
  CoreSharedFileService,
  FileDto,
  FilledFormDto,
  FilterOperators,
  FilterTypes,
  Mappers,
  TaskJobDto, TaskJobListDto
} from '@nexnox-web/core-shared';
import { FilledformService, taskArchiveListStore } from '../../store';
import { faDownload } from '@fortawesome/free-solid-svg-icons/faDownload';
import { Observable, throwError } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'nexnox-web-tasks-task-archive-list',
  templateUrl: './task-archive-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TaskArchiveListComponent extends CorePortalEntityOverviewBaseComponent<TaskJobListDto> implements OnInit {
  @Input() public taskId: number;
  @Input() public isCollection: boolean;

  public title = 'orga-portal.tasks.subtitles.archive';
  public idProperty = 'taskJobId';
  public displayProperty = null;
  public datatableConfigName = 'TaskArchiveDefault';
  public componentId = 'TaskArchiveListComponent';

  constructor(
    protected injector: Injector,
    private fileService: CoreSharedFileService,
    private filledFormService: FilledformService
  ) {
    super(injector, taskArchiveListStore, Mappers.TaskJobListDto.serializedName, AppEntityType.TaskJob);
  }

  public ngOnInit(): void {
    this.defaultFilter = [{
      property: 'taskId',
      type: FilterTypes.DataTransferObject,
      operator: FilterOperators.Equal,
      value: this.taskId.toString()
    }];

    super.ngOnInit();
  }

  /* istanbul ignore next */
  public getRowActionButtons(): DatatableActionButton[] {
    return [
      {
        tooltip: 'core-portal.core.general.download',
        style: 'p-button-primary',
        isolate: true,
        icon: faDownload,
        onClick: (row: TaskJobDto) => {
          let request: Observable<FileDto> = throwError('error');

          switch (row.attachment.type) {
            case AttachmentTypes.File:
              request = this.fileService.getFile(row.attachment.referenceId);
              break;
            case AttachmentTypes.FilledForm:
              request = this.filledFormService.getOne<FilledFormDto>(row.attachment.referenceId).pipe(
                map(filledForm => {
                  if (!filledForm?.file) throw new Error('NoFile');
                  return filledForm.file;
                })
              );
              break;
          }

          request
            .toPromise()
            .then((file: FileDto) => window.open(file.uri, '_blank'))
            .catch(error => {
              if (error === 'NoFile') {
                this.apiNotificationService.showTranslatedError('orga-portal.tasks.errors.no-pdf-attachment');
              } else {
                this.apiNotificationService.handleApiError(error);
              }
            });
        },
        show: (row: TaskJobDto) => Boolean(row?.attachment?.referenceId) &&
          (row.attachment.type === AttachmentTypes.File || row.attachment.type === AttachmentTypes.FilledForm)
      }
    ];
  }
}
