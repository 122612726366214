import { ChangeDetectionStrategy, Component, Injector, Input, OnInit } from '@angular/core';
import {
  CoreSharedModalBaseComponent,
  FilterOperators,
  FilterTypes,
  StateDto
} from '@nexnox-web/core-shared';
import { TechPortalFeatureTicketSettingsStateService } from '@nexnox-web/tech-portal/features/ticket-settings/features/states';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { CorePortalEntitySelectOptions } from '@nexnox-web/core-portal';

@Component({
  selector: 'nexnox-web-ticket-settings-state-machines-state-machine-add-state-modal',
  templateUrl: './state-machine-add-state-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StateMachineAddStateModalComponent extends CoreSharedModalBaseComponent implements OnInit {
  @Input()
  public set filteredStateIds(stateIds: number[]) {
    this.filteredStateIdsSubject.next(stateIds);
  }

  public get filteredStateIds(): number[] {
    return this.filteredStateIdsSubject.getValue();
  }

  public statesSelectOptions: CorePortalEntitySelectOptions;
  public selectedState: StateDto = null;

  private filteredStateIdsSubject: BehaviorSubject<number[]> = new BehaviorSubject<number[]>([]);

  constructor(
    protected injector: Injector,
    private stateService: TechPortalFeatureTicketSettingsStateService
  ) {
    super(injector);
  }

  public ngOnInit(): void {
    super.ngOnInit();

    this.statesSelectOptions = {
      idKey: 'stateId',
      displayKey: 'name',
      wholeObject: true,
      entityService: this.stateService,
      clearable: false,
      defaultFilters$: this.filteredStateIdsSubject.asObservable().pipe(
        map(filteredStateIds => filteredStateIds.map(stateId => ({
          property: 'stateId',
          operator: FilterOperators.NotEqual,
          type: FilterTypes.DataTransferObject,
          value: stateId.toString()
        })))
      )
    };
  }

  public onModelChange(state: StateDto): void {
    this.selectedState = state;
    this.selectedState ? this.enableConfirmButton() : this.disableConfirmButton();
  }

  /* istanbul ignore next */
  protected onConfirmButtonAvailable(): void {
    super.onConfirmButtonAvailable();
    this.disableConfirmButton();
  }

  /* instanbul ignore next */
  protected onPreConfirm(): any {
    return this.selectedState;
  }
}
