import {PagedEntitiesXsStore, PagedEntitiesXsStoreState} from '@nexnox-web/core-store';
import {AppEntityType, InfoProgressFunctionListDto} from '@nexnox-web/core-shared';
import {createSelector} from '@ngrx/store';
import {selectInfosState} from '../../infos.selectors';
import {InfoProgressFunctionService} from "../../services";

export interface InfoProgressFunctionListStoreState extends PagedEntitiesXsStoreState<InfoProgressFunctionListDto> {
}

/* istanbul ignore next */
export const infoProgressFunctionListStore = new PagedEntitiesXsStore<InfoProgressFunctionListDto>({
  actionLabel: 'Orga Portal - Infos - Info Progress Function List',
  stateSelector: createSelector(selectInfosState, state => state.infoProgressFunctionList),
  serviceType: InfoProgressFunctionService,
  entityType: AppEntityType.None,
  defaultPageSize: 11,
  selectId: infoProgressFunction => JSON.stringify([
    infoProgressFunction.entity?.functionId ?? infoProgressFunction.model?.functionId
  ]),
  stereotyped: false
});
