<ng-container *ngIf="!loading; else ghostTemplate">
    <ng-container *ngIf="breadcrumbs && breadcrumbs.length; else ghostTemplate">
      <nexnox-web-core-shared-breadcrumbs [permissions]="permissions$ | async"
                                          class="d-flex align-items-center w-100 h-100">
      </nexnox-web-core-shared-breadcrumbs>
    </ng-container>
</ng-container>

<ng-template #ghostTemplate>
    <nexnox-web-breadcrumbs-ghost class="d-flex align-items-center h-100"></nexnox-web-breadcrumbs-ghost>
</ng-template>
