<nexnox-web-entity-edit *ngIf="!creating"
                        [entityEditBaseComponent]="this"
                        displayKey="title"
                        [stereotyped]="false">
</nexnox-web-entity-edit>

<nexnox-web-tabs *ngIf="creating"
                 [internal]="true"
                 [isTransparent]="true"
                 startTab="entityEdit">
  <!-- Entity Edit Tab -->
  <ng-template nexnoxWebTabTemplate
               tabId="entityEdit"
               [heading]="title | translate"
               [hasError]="!isOwnModelValid()">
    <nexnox-web-entity-edit [entityEditBaseComponent]="this"></nexnox-web-entity-edit>
  </ng-template>

  <!-- Subtasks Tab -->
  <ng-template nexnoxWebTabTemplate
               tabId="subtasks"
               [heading]="'orga-portal.tasks.subtitles.subtasks' | translate"
               [hasError]="subtasksComponent ? !subtasksComponent.isModelValid() : false"
               [hide]="model?.type !== concreteTaskTypes.Collection">
    <nexnox-web-tasks-task-subtasks #subtasksComponent
                                    [strictOrder$]="strictOrder$"
                                    [subtasks$]="subtasksSubject"
                                    [loading]="loading"
                                    [readonly]="readonly"
                                    (strictOrderChange)="onStrictOrderChange($event)"
                                    (subtasksChange)="onSubtasksChange($event)">
    </nexnox-web-tasks-task-subtasks>
  </ng-template>

  <!-- Executions Tab -->
  <ng-template nexnoxWebTabTemplate
               tabId="executions"
               [heading]="'orga-portal.tasks.subtitles.executions' | translate"
               [hasError]="executionsComponent ? !executionsComponent.isModelValid() : false"
               [hide]="$any(model)?.executionPlan?.type !== executionTypes.Planned">
    <nexnox-web-tasks-task-executions #executionsComponent
                                      [executionPatterns$]="executionPatternsSubject"
                                      [loading]="loading"
                                      [readonly]="readonly"
                                      (executionPatternsChange)="onExecutionsChange($event)">
    </nexnox-web-tasks-task-executions>
  </ng-template>

  <!-- Impacts Tab -->
  <ng-template nexnoxWebTabTemplate
               tabId="impacts"
               [heading]="'orga-portal.tasks.subtitles.impacts' | translate"
               [hasError]="impactsComponent ? !impactsComponent.isModelValid() : false">
    <nexnox-web-tasks-task-impacts #impactsComponents
                                   [task$]="model$"
                                   [impacts$]="impactsSubject"
                                   [loading]="loading"
                                   [readonly]="readonly"
                                   (impactsChange)="onImpactsChange($event)">
    </nexnox-web-tasks-task-impacts>
  </ng-template>
</nexnox-web-tabs>

<!-- Location Templates -->
<ng-template #locationSelectLabelTitleTemplate let-item="item">
  <nexnox-web-master-data-locations-location-location-number [location]="item"
                                                             [label]="true">
  </nexnox-web-master-data-locations-location-location-number>
</ng-template>

<ng-template #locationSelectOptionTitleTemplate let-item="item" let-searchTerm="searchTerm">
  <nexnox-web-master-data-locations-location-location-number [location]="item"
                                                             [searchTerm]="searchTerm"
                                                             [label]="false">
  </nexnox-web-master-data-locations-location-location-number>
</ng-template>

<!-- Category Templates -->
<ng-template #categorySelectLabelTitleTemplate let-item="item">
  <nexnox-web-categories-category-label [category]="item"></nexnox-web-categories-category-label>
</ng-template>

<ng-template #categorySelectOptionTitleTemplate let-item="item" let-searchTerm="searchTerm">
  <nexnox-web-categories-category-label [category]="item" [searchTerm]="searchTerm"></nexnox-web-categories-category-label>
</ng-template>
