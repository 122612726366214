import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FieldType, FormlyTemplateOptions } from '@ngx-formly/core';

export interface CorePortalFormlyButtonSelectOption {
  title?: string;
  label: string;
  value: any;
}

export interface CorePortalFormlyButtonSelectTyping {
  items?: CorePortalFormlyButtonSelectOption[];
  translate?: boolean;
  large?: boolean;
}

interface FormlyButtonSelectTemplateOptions extends FormlyTemplateOptions {
  corePortalButtonSelect: CorePortalFormlyButtonSelectTyping;
}

@Component({
  selector: 'nexnox-web-formly-button-select',
  templateUrl: './formly-button-select.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormlyButtonSelectComponent extends FieldType {
  public readonly to: FormlyButtonSelectTemplateOptions;
}
