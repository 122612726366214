import {
  BaseXsStore,
  BaseXsStoreReducerTypes,
  EmptyAction,
  PagedEntitiesXsStore,
  PagedEntitiesXsStoreActions,
  PagedEntitiesXsStoreSelectors,
  PagedEntitiesXsStoreState,
  PropsAction
} from '@nexnox-web/core-store';
import {
  ResourcesTreeViewXsStoreGetResourcesInProgressSuccessPayload,
  ResourcesTreeViewXsStoreSelectByIdPayload,
  ResourcesTreeViewXsStoreSelectByIdSuccessPayload,
  ResourceTreeViewXsStorePreloadPayload,
  ResourceTreeViewXsStorePreloadSuccessPayload
} from './resource-tree-view-xs-store.payload';
import {createAction, createSelector, MemoizedSelector, on, props} from '@ngrx/store';
import {Paging, ResourceListDto, ResourceTreeListDto} from '@nexnox-web/core-shared';
import {immerOn} from 'ngrx-immer/store';

export interface ResourceTreeViewXsStoreState extends PagedEntitiesXsStoreState<ResourceTreeListDto> {
  preloading: boolean;
  resourcesInProgress: ResourceListDto[];
  resourcesInProgressPaging: Paging;
}

export interface ResourceTreeViewXsStoreActions extends PagedEntitiesXsStoreActions<ResourceTreeListDto> {
  preload: PropsAction<ResourceTreeViewXsStorePreloadPayload>;
  preloadSuccess: PropsAction<ResourceTreeViewXsStorePreloadSuccessPayload>;
  selectById: PropsAction<ResourcesTreeViewXsStoreSelectByIdPayload>;
  selectByIdSuccess: PropsAction<ResourcesTreeViewXsStoreSelectByIdSuccessPayload>;
  getResourcesInProgress: EmptyAction;
  getResourcesInProgressSuccess: PropsAction<ResourcesTreeViewXsStoreGetResourcesInProgressSuccessPayload>;
}

export interface ResourceTreeViewXsStoreSelectors extends PagedEntitiesXsStoreSelectors<ResourceTreeListDto> {
  selectPreloading: MemoizedSelector<ResourceTreeViewXsStoreState, boolean>;
  selectResourcesInProgressPaging: MemoizedSelector<ResourceTreeViewXsStoreState, Paging>,
  selectResourcesInProgress: MemoizedSelector<ResourceTreeViewXsStoreState, ResourceListDto[]>
}

export class ResourceTreeViewXsStore extends PagedEntitiesXsStore<ResourceTreeListDto, ResourceTreeListDto, ResourceTreeViewXsStoreState> {
  public actions: ResourceTreeViewXsStoreActions;
  public selectors: ResourceTreeViewXsStoreSelectors;

  public getInitialState(): ResourceTreeViewXsStoreState {
    return {
      ...super.getInitialState(),

      preloading: false,
      resourcesInProgress: [],
      resourcesInProgressPaging: null
    };
  }

  protected createActions(label: string): ResourceTreeViewXsStoreActions {
    return {
      ...super.createActions(label),

      preload: createAction(
        BaseXsStore.getType(label, 'Preload'),
        props<ResourceTreeViewXsStorePreloadPayload>()
      ),
      preloadSuccess: createAction(
        BaseXsStore.getType(label, 'Preload success'),
        props<ResourceTreeViewXsStorePreloadSuccessPayload>()
      ),
      selectById: createAction(
        BaseXsStore.getType(label, 'Select by id'),
        props<ResourcesTreeViewXsStoreSelectByIdPayload>()
      ),
      selectByIdSuccess: createAction(
        BaseXsStore.getType(label, 'Select by id success'),
        props<ResourcesTreeViewXsStoreSelectByIdSuccessPayload>()
      ),
      getResourcesInProgress: createAction(
        BaseXsStore.getType(label, 'Get resources in progress')
      ),
      getResourcesInProgressSuccess: createAction(
        BaseXsStore.getType(label, 'Get resources in progress success'),
        props<ResourcesTreeViewXsStoreGetResourcesInProgressSuccessPayload>()
      ),
    };
  }

  protected createReducerArray(
    initialState: ResourceTreeViewXsStoreState
  ): BaseXsStoreReducerTypes<ResourceTreeViewXsStoreState, ResourceTreeViewXsStoreActions>[] {
    return [
      ...super.createReducerArray(initialState),

      immerOn(this.actions.selectById, draft => {
        draft.loading = true;
      }),

      immerOn(this.actions.selectByIdSuccess, draft => {
        draft.loading = false;
      }),

      immerOn(this.actions.preload, draft => {
        draft.preloading = true;
        draft.loading = true;
      }),
      immerOn(this.actions.preloadSuccess, draft => {
        draft.preloading = false;
        draft.loading = false;
        draft.loaded = true;
      }),

      on(this.actions.getResourcesInProgressSuccess, (state, { items, paging }) => {
        return {
          ...state,
          resourcesInProgress: paging.pageNumber > 1 ? [...state.resourcesInProgress, ...items] : items,
          resourcesInProgressPaging: paging,
        };
      }),
    ];
  }

  protected createSelectors(): ResourceTreeViewXsStoreSelectors {
    const stateSelector = this.options.stateSelector;

    return {
      ...super.createSelectors(),

      selectPreloading: createSelector(stateSelector, state => state.preloading),
      selectResourcesInProgressPaging: createSelector(stateSelector, state => state.resourcesInProgressPaging),
      selectResourcesInProgress: createSelector(stateSelector, state => state.resourcesInProgress)
    };
  }
}
