import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CorePortalPermissionGuard } from '@nexnox-web/core-portal';
import { HolidaysDetailComponent, HolidaysListComponent } from './containers';
import {AppPermissions, I18nBreadcrumbResolver, UnsavedCreateGuard} from '@nexnox-web/core-shared';

export const CORE_PORTAL_MASTER_DATA_HOLIDAYS_ROUTES: Routes = [
  {
    path: '',
    canActivate: [CorePortalPermissionGuard],
    canDeactivate: [UnsavedCreateGuard],
    component: HolidaysListComponent,
    data: {
      permissions: [AppPermissions.DisplayHolidays]
    }
  },
  {
    path: ':holidaySetId',
    component: HolidaysDetailComponent,
    canActivate: [CorePortalPermissionGuard],
    data: {
      permissions: [AppPermissions.ReadHolidaySet],
      breadcrumbs: I18nBreadcrumbResolver,
      tab: 'holidaySet',
      text: 'core-portal.core.header.breadcrumbs.master-data.holidays-detail'
    }
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(CORE_PORTAL_MASTER_DATA_HOLIDAYS_ROUTES)
  ],
  exports: [
    RouterModule
  ]
})
export class HolidaysRoutingModule {
}
