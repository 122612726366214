import { CorePortalIssueTemplateService } from './services';
import {
  IssueTemplateDetailStoreEffects,
  issueTemplateDetailStoreReducer,
  IssueTemplateListStoreEffects,
  issueTemplateListStoreReducer
} from './stores';

export * from './services';
export * from './stores';

export * from './issue-template.state';
export * from './issue-template.selectors';

export const issueTemplateReducers = {
  issueTemplateList: issueTemplateListStoreReducer,
  issueTemplateDetail: issueTemplateDetailStoreReducer,
};

export const issueTemplateEffects = [
  IssueTemplateListStoreEffects,
  IssueTemplateDetailStoreEffects
];

export const issueTemplateServices = [
  CorePortalIssueTemplateService
];
