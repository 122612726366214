import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CorePortalRootModule } from './core-portal-root.module';
import { CORE_PORTAL_ROOT_GUARD } from './tokens';
import { CORE_SHARED_ADDITIONAL_TRANSLATIONS, CORE_SHARED_APP_NAME, Environment, Product } from '@nexnox-web/core-shared';
import {
  _CORE_SHARED_ENVIRONMENT,
  _CORE_SHARED_PRODUCT,
  sharedGuards,
  sharedInterceptors,
  sharedModules,
  sharedServices
} from './shared-exports';
import { CorePortalContentContainerInfiniteScrollComponent, CorePortalNoPermissionComponent, CorePortalWelcomeComponent } from './components';

const exportedComponents = [
  CorePortalNoPermissionComponent,
  CorePortalWelcomeComponent,
  CorePortalContentContainerInfiniteScrollComponent
];

@NgModule({
  declarations: [
    ...exportedComponents
  ],
  imports: [
    CommonModule,
    ...sharedModules
  ],
  exports: [
    ...exportedComponents,
    ...sharedModules,
  ],
  providers: [
    ...sharedServices,
    ...sharedGuards,
    ...sharedInterceptors
  ]
})
export class CorePortalModule {
  public static forRoot(
    environment: Environment,
    product: Product,
    name: string,
    additionalTranslations: string[] = []
  ): ModuleWithProviders<CorePortalRootModule> {
    return {
      ngModule: CorePortalRootModule,
      providers: [
        {
          provide: CORE_PORTAL_ROOT_GUARD,
          useFactory: rootGuardFactory,
          deps: [[CORE_PORTAL_ROOT_GUARD, new Optional(), new SkipSelf()]]
        },

        {
          provide: _CORE_SHARED_ENVIRONMENT,
          useValue: environment
        },
        {
          provide: _CORE_SHARED_PRODUCT,
          useValue: product
        },
        {
          provide: CORE_SHARED_APP_NAME,
          useValue: name
        },
        {
          provide: CORE_SHARED_ADDITIONAL_TRANSLATIONS,
          useValue: additionalTranslations
        },

        ...sharedServices,
        ...sharedGuards,
        ...sharedInterceptors
      ]
    };
  }
}

export function rootGuardFactory(rootGuard: any): any {
  if (rootGuard) {
    throw new TypeError('CorePortalModule.forRoot() called twice.');
  }

  return 'guarded';
}
