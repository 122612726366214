import {
  contractSettingsStore,
  ContractSettingsStoreEffects,
  ResourcesByLocationStereotypeDetailStoreEffects,
  resourcesByLocationStereotypeDetailStoreReducer,
  resourcesByLocationStereotypeListStore,
  stereotypeDetailStore,
  stereotypeListStore,
  stereotypeSkeletonStore,
  StereotypeSkeletonStoreEffects,
  TicketsByLocationStereotypeDetailStoreEffects,
  ticketsByLocationStereotypeDetailStoreReducer,
  ticketsByLocationStereotypeListStore
} from './stores';

export * from './stores';

export * from './stereotypes.state';
export * from './stereotypes.selector';

export const stereotypesReducers = {
  stereotypeList: stereotypeListStore.reducer,
  stereotypeDetail: stereotypeDetailStore.reducer,
  stereotypeSkeleton: stereotypeSkeletonStore.reducer,
  contractSettings: contractSettingsStore.reducer,
  resourcesByLocationStereotypeList: resourcesByLocationStereotypeListStore.reducer,
  resourcesByLocationStereotypeDetail: resourcesByLocationStereotypeDetailStoreReducer,
  ticketsByLocationStereotypeList: ticketsByLocationStereotypeListStore.reducer,
  ticketsByLocationStereotypeDetail: ticketsByLocationStereotypeDetailStoreReducer
};

export const stereotypesEffects = [
  stereotypeListStore.effects,
  stereotypeDetailStore.effects,
  StereotypeSkeletonStoreEffects,
  ContractSettingsStoreEffects,
  resourcesByLocationStereotypeListStore.effects,
  ResourcesByLocationStereotypeDetailStoreEffects,
  ticketsByLocationStereotypeListStore.effects,
  TicketsByLocationStereotypeDetailStoreEffects
];
