import {ChangeDetectionStrategy, Component, Injector, OnInit} from '@angular/core';
import {ActionButton, CorePortalEntityDetailStandaloneBaseComponent} from '@nexnox-web/core-portal';
import {AppPermissions, TicketsByLocationDefinitionDto} from "@nexnox-web/core-shared";
import {ticketsByLocationStereotypeDetailStore} from "./../../store";
import {BehaviorSubject, merge} from "rxjs";
import {map, withLatestFrom} from "rxjs/operators";
import {select} from "@ngrx/store";

@Component({
  selector: 'nexnox-web-settings-stereotypes-tickets-by-location-definition-detail',
  templateUrl: './tickets-by-location-stereotype-detail-standalone.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TicketsByLocationStereotypeDetailComponent extends CorePortalEntityDetailStandaloneBaseComponent<TicketsByLocationDefinitionDto> implements OnInit {

  public stereotypeId: number;

  public idParam = "ticketsByLocationDefinitionId";
  public displayKey = 'title'
  public formTableValidSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  constructor(
    protected injector: Injector,
  ) {
    super(injector, ticketsByLocationStereotypeDetailStore);
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.setTitle('core-shared.shared.skeletons.create-skeleton', 'core-shared.shared.skeletons.edit-skeleton');
    this.stereotypeId = +this.route.snapshot.paramMap.get('stereotypeId');

    this.disablePrimaryAction$ = merge(this.isModelValid$, this.model$).pipe(
        withLatestFrom(
        this.store.pipe(select(this.entityStore.selectors.selectHasChanged)),
        this.isModelValid$,
        this.formTableValidSubject.asObservable(),
        this.model$
      ),
      map(([_, hasChanges, isModelValid, isTableValid, model]) => !hasChanges || !isModelValid || !isTableValid || !(model?.tickets?.length > 0))
    );
  }

  protected async getActionButtons(): Promise<ActionButton[]> {
    return this.getStandaloneActionButtons(
      'core-shared.shared.skeletons.create-skeleton',
      AppPermissions.CreateTicketsByLocationDefinition,
      'core-shared.shared.skeletons.edit-skeleton',
      AppPermissions.UpdateTicketsByLocationDefinition,
      'core-shared.shared.skeletons.save-skeleton',
      AppPermissions.UpdateTicketsByLocationDefinition
    )
  }
}
