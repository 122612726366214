import { controlPointDetailStore, controlPointListStore } from './stores';

export * from './stores';

export * from './control-points.state';
export * from './control-points.selectors';

export const controlPointsReducers = {
  list: controlPointListStore.reducer,
  detail: controlPointDetailStore.reducer
};

export const controlPointsEffects = [
  controlPointListStore.effects,
  controlPointDetailStore.effects
];

export const controlPointsServices = [
  
];
