import {PagedEntitiesXsStore, PagedEntitiesXsStoreState} from '@nexnox-web/core-store';
import {AppEntityType, ContactListDto} from '@nexnox-web/core-shared';
import {Action, createSelector} from '@ngrx/store';
import {CorePortalInvitationsContactService} from '../../services';
import {Injectable, Injector} from '@angular/core';
import {selectInvitationsState} from "../../invitations.selectors";

export interface InvitationsContactListStoreState extends PagedEntitiesXsStoreState<ContactListDto> {
}

/* istanbul ignore next */
export const invitationsContactListStore = new PagedEntitiesXsStore<ContactListDto>({
  actionLabel: 'Core Portal - Settings - Invitations - Invitation Contact List',
  stateSelector: createSelector(selectInvitationsState, state => state.invitationsContactList),
  serviceType: CorePortalInvitationsContactService,
  entityType: AppEntityType.Contact,
  stereotyped: true,
  selectId: invitations => invitations.entity?.contactId ?? invitations.model?.contactId,
});

export function invitationsContactListStoreReducer(state: InvitationsContactListStoreState, action: Action): any {
  return invitationsContactListStore.reducer(state, action);
}

@Injectable()
export class InvitationsContactListStoreEffects extends invitationsContactListStore.effects {
  constructor(
    protected injector: Injector
  ) {
    super(injector);
  }
}
