import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { isUndefined } from 'lodash';

@Directive({
  selector: '[nexnoxWebAutofocus]'
})
export class CoreSharedAutofocusDirective implements OnInit {
  @Input()
  public set nexnoxWebAutofocus(focus: boolean) {
    this.focus = focus !== false;
  }

  private focus = true;

  constructor(
    private element: ElementRef
  ) {
  }

  public ngOnInit(): void {
    if (this.focus) {
      setTimeout(() => this.element.nativeElement.focus());
    }
  }
}
