import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ControlDetailComponent, TechPortalFeatureControlListComponent} from './containers';
import {AppPermissions, I18nBreadcrumbResolver, UnsavedChangesGuard} from '@nexnox-web/core-shared';
import {CorePortalPermissionGuard} from "@nexnox-web/core-portal";

const CORE_PORTAL_CONTROLS_ROUTES: Routes = [
  {
    path: '',
    component: TechPortalFeatureControlListComponent,
    canActivate: [CorePortalPermissionGuard],
    canDeactivate: [UnsavedChangesGuard],
    data: {
      permissions: [AppPermissions.DisplayControls]
    },
    children: []
  },
  {
    path: `:controlId`,
    component: ControlDetailComponent,
    canActivate: [CorePortalPermissionGuard],
    canDeactivate: [UnsavedChangesGuard],
    data: {
      tab: 'control',
      text: 'controls.subtitles.control-detail',
      breadcrumbs: I18nBreadcrumbResolver,
      permissions: [AppPermissions.ReadControl]
    },
    children: [
      {
        path: 'notifications',
        data: {
          tab: 'notifications'
        },
        children: []
      },
      {
        path: 'resources',
        data: {
          tab: 'resources'
        },
        children: []
      },
    ]
  },

  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(CORE_PORTAL_CONTROLS_ROUTES)
  ],
  exports: [
    RouterModule
  ]
})
export class ControlsRoutingModule {
}
