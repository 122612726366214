import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {CorePortalPermissionGuard} from '@nexnox-web/core-portal';
import {AppPermissions, I18nBreadcrumbResolver, UnsavedChangesGuard, UnsavedCreateGuard} from '@nexnox-web/core-shared';
import {InvitationsDetailComponent, InvitationsListComponent} from "./containers";

export const CORE_PORTAL_INVITATIONS_ROUTES: Routes = [
  {
    path: '',
    canActivate: [CorePortalPermissionGuard],
    canDeactivate: [UnsavedCreateGuard],
    component: InvitationsListComponent,
    data: {
      permissions: [AppPermissions.ReadInvitation]
    }
  },
  {
    path: ':invitationId',
    component: InvitationsDetailComponent,
    canActivate: [CorePortalPermissionGuard],
    canDeactivate: [UnsavedChangesGuard],
    data: {
      permissions: [AppPermissions.ReadInvitation],
      breadcrumbs: I18nBreadcrumbResolver,
      tab: 'invitations',
      text: 'core-portal.settings.invitations.titles.detail'
    },
    children: [
      {
        path: 'contacts',
        data: {
          tab: 'contacts'
        },
        children: []
      }
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(CORE_PORTAL_INVITATIONS_ROUTES)
  ],
  exports: [
    RouterModule
  ]
})
export class InvitationsRoutingModule {
}
