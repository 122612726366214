import {ApplicationRef, ComponentFactoryResolver, ComponentRef, Injectable, Injector, Type} from '@angular/core';
import {CoreSharedModalBaseComponent} from '../../base-components';
import {SweetAlertIcon} from 'sweetalert2';
import {ConfirmModalComponent} from '../../modals';

@Injectable()
export class CoreSharedModalService {
	constructor(
		private componentFactoryResolver: ComponentFactoryResolver,
		private appRef: ApplicationRef,
		private injector: Injector
	) {
	}

	/**
	 *  Unsaved changes standard dialog
	 * 	Modal service throws an error, when cancel modal, instead of returning a simple false (!!!)
	 * 	This is why the modal promise is wrapped in another boolean promise here as a workaround
	 */
	public promptUnsavedChangesModal(): Promise<boolean> {
		return new Promise((resolve, reject) => {
			// ask for confirmation
			this.showConfirmationModal(
				'core-shared.shared.unsaved-changes.title',
				'core-shared.shared.unsaved-changes.text',
				'warning',
				'core-portal.core.general.yes'
			)
				.then(() => resolve(true))
				.catch(() => resolve(false));
		});
	}

	public showConfirmationModal(
		titleKey: string,
		textKey: string,
		icon: SweetAlertIcon = 'question',
		confirmKey: string = 'core-portal.core.general.okay',
		cancelKey: string = 'core-portal.core.general.cancel'
	): Promise<any> {
		return this.showModal(ConfirmModalComponent, instance => {
			instance.options = {
				titleKey,
				textKey,
				confirmKey,
				cancelKey,
				icon
			};
		});
	}

	public showModal<T extends CoreSharedModalBaseComponent>(modal: Type<T>, instanceMod?: (instance: T) => void): Promise<any> {
		return new Promise<any>((resolve, reject) => {
			const componentRef = this.createModal(modal);
			const instance: T = componentRef.instance;

			if (instanceMod) {
				instanceMod(instance);
			}

			const sub = instance.modalComponent.init.subscribe(swal => swal.fire()
				.then(response => {
					if (response.dismiss) {
						reject(response);
					} else {
						resolve(response);
					}
				})
				.catch(() => reject())
				.finally(() => {
					sub.unsubscribe();
					this.destroyModal(componentRef);
				})
			);
		});
	}

	private createModal<T>(modal: Type<T>): ComponentRef<T> {
		const componentFactory = this.componentFactoryResolver.resolveComponentFactory(modal);
		const componentRef = componentFactory.create(this.injector);
		this.appRef.attachView(componentRef.hostView);
		return componentRef;
	}

	private destroyModal(modal: ComponentRef<CoreSharedModalBaseComponent>): void {
		this.appRef.detachView(modal.hostView);
		modal.destroy();
	}
}
