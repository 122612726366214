import {PagedEntitiesXsStore, PagedEntitiesXsStoreState} from '@nexnox-web/core-store';
import {AppEntityType, IssueListDto} from '@nexnox-web/core-shared';
import {createSelector} from '@ngrx/store';
import {selectLocationsState} from '../../locations.selectors';
import {LocationIssueService} from '../../services';

export interface LocationIssueListStoreState extends PagedEntitiesXsStoreState<IssueListDto> {
}

/* istanbul ignore next */
export const locationIssueListStore = new PagedEntitiesXsStore<IssueListDto>({
  actionLabel: 'Core Portal - Master Data - Location - Issue List',
  stateSelector: createSelector(selectLocationsState, state => state.locationIssueList),
  serviceType: LocationIssueService,
  entityType: AppEntityType.Issue,
  selectId: issue => issue.entity?.issueId ?? issue.model?.issueId
});
