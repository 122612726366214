import { RouterModule, Routes } from '@angular/router';
import { CorePortalPermissionGuard } from '@nexnox-web/core-portal';
import { TaskDetailComponent, TaskListComponent } from './containers';
import {AppPermissions, I18nBreadcrumbResolver, UnsavedChangesGuard, UnsavedCreateGuard} from '@nexnox-web/core-shared';
import { NgModule } from '@angular/core';

export const ORGA_PORTAL_TASKS_ROUTES: Routes = [
  {
    path: '',
    canActivate: [CorePortalPermissionGuard],
    canDeactivate: [UnsavedCreateGuard],
    component: TaskListComponent,
    data: {
      permissions: [AppPermissions.DisplayTasks]
    }
  },
  {
    path: ':taskId',
    component: TaskDetailComponent,
    canActivate: [CorePortalPermissionGuard],
    canDeactivate: [UnsavedChangesGuard],
    data: {
      permissions: [AppPermissions.ReadTask],
      breadcrumbs: I18nBreadcrumbResolver,
      tab: 'task',
      text: 'orga-portal.tasks.subtitles.task-detail'
    },
    children: [
      {
        path: 'subtasks',
        data: {
          tab: 'subtasks'
        },
        children: []
      },
      {
        path: 'executions',
        data: {
          tab: 'executions'
        },
        children: []
      },
      {
        path: 'impacts',
        data: {
          tab: 'impacts'
        },
        children: []
      },
      {
        path: 'planned',
        data: {
          tab: 'planned'
        },
        children: []
      }
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(ORGA_PORTAL_TASKS_ROUTES)
  ],
  exports: [
    RouterModule
  ]
})
export class TasksRoutingModule {
}
