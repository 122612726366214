<p-sidebar #sidebarComponent
           position="right"
           appendTo="body"
           styleClass="p-sidebar-lg"
           [transitionOptions]="transitionOptions"
           [visible]="visible$ | async"
           (visibleChange)="onVisibleChange($event)">

    <h4>{{ 'missions.subtitles.editor-suggestions-current-editor' | translate }}</h4>
    <div class="d-flex flex-row mb-4">
      <ng-container *ngIf="currentSolutionContact$ | async as solutionContact; else noSolutionContactTemplate">
        <fa-icon [icon]="faUser"
                 [fixedWidth]="true"
                 size="sm"
                 class="mr-2"></fa-icon>
        <a class="btn-link"
           [nexnoxWebTenantRouterLink]="['/masterdata/contacts', solutionContact.contactId]"
           module="management"
           target="_blank">
          {{ solutionContact.displayName }}
        </a>
      </ng-container>
      <ng-template #noSolutionContactTemplate>
        {{ 'core-shared.shared.select.empty' | translate }}
      </ng-template>
    </div>

    <ng-container *ngIf="quest$ | async as quest">
      <div class="row no-gutters mb-4 d-flex align-items-center">
        <div class="p-0">
          <h4 class="mb-0">{{ title$ | async }}</h4>
          <span class="small color-grey-700 mb-4">{{ info$ | async }}</span>
        </div>
        <div class="p-0 pl-4">
          <button pButton
                  type="button"
                  class="p-button p-button-primary"
                  [label]="'missions.actions.cancel-quest' | translate"
                  [icon]="faTimes | nexnoxWebFaIconStringPipe"
                  [class.d-none]="quest.state !== questStates.InProcess"
                  (click)="onCancelQuest(quest)">
          </button>

          <button pButton
                  type="button"
                  class="p-button p-button-primary"
                  [label]="'missions.actions.restart-quest' | translate"
                  [icon]="faArrowCircleRight | nexnoxWebFaIconStringPipe"
                  [class.d-none]="quest.state < 3"
                  (click)="onRestartQuest(quest)">
          </button>
        </div>
      </div>

      <div class="row no-gutters" *ngIf="quest.state === questStates.Suggested">
        <div class="col-6 p-0">
          <button pButton
                  type="button"
                  class="p-button p-button-primary"
                  [label]="'missions.actions.start-quest' | translate"
                  [icon]="faUsers | nexnoxWebFaIconStringPipe"
                  (click)="onStartQuest(quest)">
          </button>
        </div>
      </div>

      <nexnox-web-basic-table *ngIf="quest.state !== questStates.Suggested"
                              [config]="config"
                              [data]="quest.requests"
                              [loading]="loading">
      </nexnox-web-basic-table>
    </ng-container>
</p-sidebar>
