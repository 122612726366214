<nexnox-web-time-picker [formControl]="formControl"
                        [formlyAttributes]="field"
                        [hasError]="showError"
                        [showYears]="to.corePortalTimepicker?.showYears !== null && to.corePortalTimepicker?.showYears !== undefined ? to.corePortalTimepicker.showYears : false"
                        [showWeeks]="to.corePortalTimepicker?.showWeeks !== null && to.corePortalTimepicker?.showWeeks !== undefined ? to.corePortalTimepicker.showWeeks : false"
                        [showDays]="to.corePortalTimepicker?.showDays !== null && to.corePortalTimepicker?.showDays !== undefined ? to.corePortalTimepicker.showDays : false"
                        [showHours]="to.corePortalTimepicker?.showHours !== null && to.corePortalTimepicker?.showHours !== undefined ? to.corePortalTimepicker.showHours : true"
                        [showMinutes]="to.corePortalTimepicker?.showMinutes !== null && to.corePortalTimepicker?.showMinutes !== undefined ? to.corePortalTimepicker.showMinutes : true"
                        [mode]="to.corePortalTimepicker?.mode ? to.corePortalTimepicker.mode : 'number'"
                        [allowNegative]="to.corePortalTimepicker?.allowNegative"
                        [canClear]="to.corePortalTimepicker?.clearable !== null && to.corePortalTimepicker?.clearable !== undefined ? to.corePortalTimepicker.clearable : true">
</nexnox-web-time-picker>
