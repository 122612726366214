<ng-container *ngIf="(localItem$ | async) as item">
  <ng-container [ngSwitch]="item.note.type">

    <ng-container *ngSwitchCase="noteTypes.ChatMessage">
      <strong>{{ typeTranslationKey$ | async | translate }}: </strong>
      <span *ngIf="isCollapsed"
            [innerHTML]="escapedNoteDescription$ | async">
      </span>
    </ng-container>

    <ng-container *ngSwitchCase="noteTypes.Mail">
      <strong>{{ typeTranslationKey$ | async | translate }}: </strong>
      <span *ngIf="isCollapsed"
            [innerHTML]="escapedNoteDescription$ | async">
      </span>
    </ng-container>

    <ng-container *ngSwitchCase="noteTypes.Caller">
      <strong>{{ typeTranslationKey$ | async | translate }}: </strong>
      <span *ngIf="isCollapsed"
            [innerHTML]="escapedNoteDescription$ | async">
      </span>
    </ng-container>

    <ng-container *ngSwitchCase="noteTypes.Text">
      <strong>{{ typeTranslationKey$ | async | translate }}: </strong>
      <span *ngIf="isCollapsed"
            [innerHTML]="escapedNoteDescription$ | async">
      </span>
    </ng-container>

    <ng-container *ngSwitchCase="noteTypes.FinalizedMission">
      <span [innerHTML]="getFinalizedMissionDescription(item.note) | async"></span>
    </ng-container>

    <ng-container *ngSwitchCase="noteTypes.ChangedStatus">
      <span [innerHTML]="'solutions.descriptions.solution-item-state-change' | translate: {stateTo: (item.asChangedStatus().changedToState?.name ?? '')}"></span>

      <ng-container *ngIf="item.asChangedStatus().isChangedByEmail">
        <span>{{ 'solutions.descriptions.solution-item-state-change-from-mail' | translate }}</span>
      </ng-container>

      <ng-container *ngIf="item.asChangedStatus().causedMission">
        <span [innerHTML]="'solutions.descriptions.solution-item-state-change-from-mission' | translate: {mission: item.asChangedStatus().causedMission.title}"></span>
      </ng-container>

      <ng-container *ngIf="item.asChangedStatus().causedContract">
        <span [innerHTML]="'solutions.descriptions.solution-item-state-change-from-contract' | translate: {contract: item.asChangedStatus().causedContract.title}"></span>
      </ng-container>

      <ng-container *ngIf="item.asChangedStatus().description !== '' && item.asChangedStatus().description !== undefined && item.asChangedStatus().description !== null && isCollapsed">
        <span>: </span><span [innerHTML]="escapedNoteDescription$ | async"></span>
      </ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="noteTypes.ChangedStatusOfMission">
      <span [innerHTML]="'solutions.descriptions.solution-item-mission-state-change' | translate: {stateTo: ('missions.mission-states.' + item.asChangedStatusOfMission().missionState | translate)}"></span>
    </ng-container>

    <ng-container *ngSwitchCase="noteTypes.EscalatedByEvent">
      <span [innerHTML]="'solutions.descriptions.solution-item-escalation' | translate: {level: item.asEscalatedByEvent().escalatedLevelByEvent?.name, time: (this.offsetDate$ | async)}"></span>
    </ng-container>

    <ng-container *ngSwitchCase="noteTypes.EscalatedByUser">
      <span [innerHTML]="'solutions.descriptions.solution-item-user-escalation' | translate: {level: item.asEscalatedByUser().escalatedLevelByUser?.name }"></span>
    </ng-container>

    <ng-container *ngSwitchCase="noteTypes.CanceledEscalation">
      <span [innerHTML]="'solutions.descriptions.solution-item-escalation-cancelled' | translate: {level: item.asCanceledEscalation().cancelLevel?.name }"></span>
    </ng-container>

    <ng-container *ngSwitchCase="noteTypes.ChangedResource">
      <ng-container *ngIf="item.asChangedResource().currentResource && item.asChangedResource().previousResource then hasChangedResources else hasNoChangedResources"></ng-container>
      <ng-template #hasChangedResources>
        <span [innerHTML]="'solutions.descriptions.solution-item-changed-resource' | translate: {previousResource: item.asChangedResource().previousResource?.name, currentResource: item.asChangedResource().currentResource?.name}"></span>
      </ng-template>
      <ng-template #hasNoChangedResources>
        <span [innerHTML]="'solutions.descriptions.solution-item-changed-resource-plain' | translate"></span>
      </ng-template>
    </ng-container>

  </ng-container>
</ng-container>
