import { TechPortalFeatureTicketSettingsEscalationLevelService } from './services';
import {
  EscalationLevelDetailStoreEffects,
  escalationLevelDetailStoreReducer,
  EscalationLevelListStoreEffects,
  escalationLevelListStoreReducer
} from './stores';

export * from './services';
export * from './stores';

export * from './escalation-levels.state';
export * from './escalation-levels.selectors';

export const escalationLevelsReducers = {
  escalationLevelList: escalationLevelListStoreReducer,
  escalationLevelDetail: escalationLevelDetailStoreReducer
};

export const escalationLevelsEffects = [
  EscalationLevelListStoreEffects,
  EscalationLevelDetailStoreEffects
];

export const escalationLevelsServices = [
  TechPortalFeatureTicketSettingsEscalationLevelService
];
