<nexnox-web-entity-edit [entityEditBaseComponent]="this"
                        displayKey="title"
                        [stereotyped]="false"
                        [useTitle]="true"
                        [isTitleEditable]="false">
</nexnox-web-entity-edit>

<nexnox-web-cardbox [title]="'orga-portal.cancellation-reasons.subtitles.cancellation-reasons-detail' | translate"
                    [loading]="loading"
                    paddingClasses="px-3 pb-3">
  <!-- Add Cancellation Reason -->
  <div *ngIf="!readonly" class="mb-3">
    <form [formGroup]="addCancellationReasonForm">
      <formly-form class="row w-100"
                   [form]="addCancellationReasonForm"
                   [model]="addCancellationReasonModelSubject | async"
                   [fields]="addCancellationReasonFields"
                   (modelChange)="addCancellationReasonModelSubject.next($event)">
      </formly-form>
    </form>

    <div class="d-flex justify-content-end w-100">
      <button type="button"
              pButton
              [icon]="faPlus | nexnoxWebFaIconStringPipe"
              [title]="'core-shared.shared.actions.add' | translate"
              class="p-button-secondary p-button-outlined mr-2"
              [label]="'orga-portal.cancellation-reasons.actions.add-cancellation-reason' | translate"
              [disabled]="addCancellationReasonForm.invalid"
              (click)="onAddCancellationReason()">
      </button>
    </div>
  </div>

  <!-- Cancellation Reasons -->
  <div class="pt-2 px-2 pb-3">
    <nexnox-web-sortable-list [items]="cancellationReasons$ | async"
                              [disabled]="readonly"
                              [sortable]="false"
                              [editable]="false"
                              [notTitle]="true"
                              [hideItemName]="true"
                              (itemsChange)="onCancellationReasonsChange($event)">
      <ng-template nexnoxWebSortableListItemTemplate let-item="item">
        <form [formGroup]="item.form" class="row w-100 align-items-center">
          <formly-form class="col-12 px-0 row w-100 align-items-center"
                       [form]="item.form"
                       [model]="item.model"
                       [fields]="item.fields"
                       (modelChange)="onCancellationReasonChange(item.index, $event)">
          </formly-form>
        </form>
      </ng-template>
    </nexnox-web-sortable-list>
  </div>
</nexnox-web-cardbox>
