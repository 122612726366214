<div class="d-flex align-items-center">
  <div class="image-attachment-thumbnail mr-3 mb-3" *ngIf="image$ | async as image">
    <p-image [src]="image.uri" [preview]="true" [alt]="image.name" appendTo="body" width="100"></p-image>
  </div>

  <div class="d-flex flex-grow-1">
    <form [formGroup]="form" class="w-100">
      <formly-form class="row"
                   [form]="form"
                   [model]="model$ | async"
                   [fields]="fields"
                   (modelChange)="onModelChange($event)">
      </formly-form>
    </form>
  </div>
</div>
