import {
  CollectionTaskDto,
  ConcreteTaskTypes,
  CoreSharedApiBaseService,
  DailyDateTimePatternDto,
  DateTimePatternDto,
  DocumentTaskDto,
  ExecutionPlanDto,
  ExecutionTypes,
  PlannedExecutionDto,
  Product,
  TaskDto,
  TimePatterns,
  WeeklyDateTimePatternDto
} from '@nexnox-web/core-shared';
import { Injectable, Injector } from '@angular/core';
import { cloneDeep, isUndefined } from 'lodash';
import { WeekDay } from '@angular/common';

@Injectable()
export class OrgaPortalFeatureTaskService extends CoreSharedApiBaseService {
  constructor(
    protected injector: Injector
  ) {
    super(injector, Product.ORGA, 'v2/task');
  }

  public static sanitizeModel(model: TaskDto, entity: TaskDto, base: (model: TaskDto, entity: TaskDto) => TaskDto): TaskDto {
    const newModel = base(model, entity) as TaskDto & DocumentTaskDto & CollectionTaskDto;

    if (newModel.type !== ConcreteTaskTypes.Document && newModel.type !== ConcreteTaskTypes.ChildDocument) {
      delete newModel.form;
    }

    if (newModel.executionPlan && newModel.executionPlan?.type !== ExecutionTypes.Planned) {
      delete (newModel.executionPlan as PlannedExecutionDto).startMinuteOffset;
    }

    if (newModel.type === ConcreteTaskTypes.Collection) {
      delete newModel.requiresAuthentication;
    }

    if (OrgaPortalFeatureTaskService.isExecutionPlanWithPatterns(newModel.executionPlan) && newModel.executionPlan?.patterns?.length) {
      newModel.executionPlan.patterns = newModel.executionPlan.patterns.map(pattern => {
        const newPattern = cloneDeep(pattern) as DateTimePatternDto & DailyDateTimePatternDto;
        const isMonthly = newPattern.type === TimePatterns.MonthlyDay ||
          newPattern.type === TimePatterns.MonthlyWeekDay;
        const isLongTimely = newPattern.type === TimePatterns.Weekly || isMonthly;
        const isTimely = newPattern.type === TimePatterns.Daily || isLongTimely;

        if (isTimely && !newPattern.useOpeningDays && !isUndefined((newPattern as any)?.weekDays)) {
          const weekDays: WeekDay[] = (newPattern as any).weekDays ?? [];
          newPattern.monday = weekDays.includes(WeekDay.Monday);
          newPattern.tuesday = weekDays.includes(WeekDay.Tuesday);
          newPattern.wednesday = weekDays.includes(WeekDay.Wednesday);
          newPattern.thursday = weekDays.includes(WeekDay.Thursday);
          newPattern.friday = weekDays.includes(WeekDay.Friday);
          newPattern.saturday = weekDays.includes(WeekDay.Saturday);
          newPattern.sunday = weekDays.includes(WeekDay.Sunday);
        }

        newPattern.useOpeningDays = Boolean(newPattern.useOpeningDays);
        newPattern.useNextOpeningDay = Boolean(newPattern.useNextOpeningDay);

        if (!isTimely || isMonthly) {
          delete newPattern.useOpeningDays;
        }

        if (isTimely && !isUndefined((newPattern as any)?.dateRange)) {
          const splitDate: string[] = (newPattern as any).dateRange?.split(' - ');
          newPattern.startDate = splitDate && splitDate[0] ? splitDate[0] : null;
          newPattern.endDate = splitDate && splitDate[1] ? splitDate[1] : null;
        }

        if (!isLongTimely) {
          delete (newPattern as WeeklyDateTimePatternDto).repeat;
        }

        return newPattern;
      });
    }

    return newModel;
  }

  public static isExecutionPlanWithPatterns(executionPlan: ExecutionPlanDto): executionPlan is ExecutionPlanDto & PlannedExecutionDto {
    return Boolean((executionPlan as ExecutionPlanDto & PlannedExecutionDto)?.patterns);
  }

  protected buildPagePath(parentIds: Array<number | string> = [], addTrailing: boolean = true): string {
    return this.buildPath(parentIds, addTrailing, 'active');
  }
}
