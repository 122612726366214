<div dropdown
     container="body"
     class="btn-group"
     placement="bottom right">
    <button dropdownToggle
            pButton
            type="button"
            class="p-button-text button- p-button-secondary p-ripple p-button-icon-only p-button-sm ml-2"
            [title]="'core-shared.shared.table.subtitles.datatable-views.title' | translate">
        <fa-icon [icon]="faFilter"
                 [fixedWidth]="true">
        </fa-icon>
    </button>

    <ul *dropdownMenu
        role="menu"
        class="dropdown-menu dropdown-menu-right bg-light-blue">
        <ng-container *ngFor="let menu of (dropdownActions | async); let first=first">
            <hr *ngIf="menu?.menuTitle && !first" class="mt-0 mb-0">
            <li *ngIf="menu?.menuTitle && menu?.buttons?.length > 0"
                class="px-3 py-3 menu-title">
                {{ menu?.menuTitle | translate }}
            </li>

            <ng-container *ngFor="let button of menu?.buttons"
                          [ngTemplateOutlet]="dropdownItem"
                          [ngTemplateOutletContext]="{ item: button, first: first }">
            </ng-container>
        </ng-container>
    </ul>
</div>

<ng-template #dropdownItem let-item="item" let-first="first">
  <hr *ngIf="item?.useDivider && !first" class="mt-0 mb-0">
  <li *ngIf="item"
        role="menuitem"
        [class.disabled]="false"
        [title]="item.title | translate">
        <a class="dropdown-item"
           [class.no-icon]="!item.icon"
           (click)="item.action ? item.action() : null">
            <fa-icon *ngIf="item.icon as icon"
                     [class.has-time-stamp]="item.hasTimeStamp"
                     [icon]="icon">
            </fa-icon>
            <span>{{ item.title | translate }}</span>
        </a>
    </li>
</ng-template>
