import {
  BaseXsStore,
  BaseXsStoreReducerTypes,
  EntityXsStore,
  EntityXsStoreActions,
  EntityXsStoreState,
  PropsAction
} from '@nexnox-web/core-store';
import { SkeletonRootDto } from '@nexnox-web/core-shared';
import { createAction, props } from '@ngrx/store';
import { cloneDeep } from 'lodash';
import { immerOn } from 'ngrx-immer/store';

export interface StereotypeSkeletonXsStoreState extends EntityXsStoreState<SkeletonRootDto> {
}

export interface StereotypeSkeletonXsStoreCreatePayload {
  stereotypeId: number;
  model: SkeletonRootDto;
}

export interface StereotypeSkeletonXsStoreCreateSuccessPayload {
  entity: SkeletonRootDto;
  model: SkeletonRootDto;
}

export interface StereotypeSkeletonXsStoreActions extends EntityXsStoreActions<SkeletonRootDto> {
  create: PropsAction<StereotypeSkeletonXsStoreCreatePayload>;
  createSuccess: PropsAction<StereotypeSkeletonXsStoreCreateSuccessPayload>;
}

export class StereotypeSkeletonXsStore extends EntityXsStore<SkeletonRootDto> {
  public actions: StereotypeSkeletonXsStoreActions;

  protected createActions(label: string): StereotypeSkeletonXsStoreActions {
    return {
      ...super.createActions(label),

      create: createAction(
        BaseXsStore.getType(label, 'Create'),
        props<StereotypeSkeletonXsStoreCreatePayload>()
      ),
      createSuccess: createAction(
        BaseXsStore.getType(label, 'Create success'),
        props<StereotypeSkeletonXsStoreCreateSuccessPayload>()
      )
    };
  }

  protected createReducerArray(
    initialState: StereotypeSkeletonXsStoreState
  ): BaseXsStoreReducerTypes<StereotypeSkeletonXsStoreState, StereotypeSkeletonXsStoreActions>[] {
    return [
      ...super.createReducerArray(initialState),

      immerOn(this.actions.create, draft => {
        draft.loading = true;
      }),
      immerOn(this.actions.createSuccess, (draft, { entity, model }) => {
        draft.entity = cloneDeep(entity);
        draft.model = cloneDeep(model);
        draft.loading = false;
        draft.loaded = true;
      })
    ];
  }
}
