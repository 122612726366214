import { ModuleWithProviders, NgModule } from '@angular/core';
import { TemplatesDeclarationsModule } from './templates-declarations.module';
import { TemplatesRoutingModule } from './templates-routing.module';
import { TemplatesStoreModule } from './templates-store.module';

@NgModule({
  imports: [
    TemplatesDeclarationsModule,
    TemplatesRoutingModule,
    TemplatesStoreModule
  ]
})
export class TechPortalFeatureTemplatesModule {
  public static forFeature(): ModuleWithProviders<TemplatesDeclarationsModule> {
    return { ngModule: TemplatesDeclarationsModule };
  }
}
