import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {CorePortalPermissionGuard} from '@nexnox-web/core-portal';
import {AppPermissions, I18nBreadcrumbResolver, UnsavedChangesGuard, UnsavedCreateGuard} from '@nexnox-web/core-shared';
import {ManufacturerDetailComponent, ManufacturerListComponent, ModelDetailStandaloneComponent} from './containers';

export const CORE_PORTAL_LABEL_ROUTES: Routes = [
	{
		path: '',
		canActivate: [CorePortalPermissionGuard],
		component: ManufacturerListComponent,
		data: {
			permissions: [AppPermissions.DisplayResourceManufacturers]
		}
	},
	{
		path: ':resourceManufacturerId',
		component: ManufacturerDetailComponent,
		canActivate: [CorePortalPermissionGuard],
		canDeactivate: [UnsavedChangesGuard],
		data: {
			permissions: [AppPermissions.ReadResourceManufacturer],
			breadcrumbs: I18nBreadcrumbResolver,
      tab: 'manufacturer',
			text: 'core-portal.settings.subtitles.manufacturer-detail'
		},
		children: [
			{
				path: 'model',
				data: {
					tab: 'model'
				},
        children: []
			}
		]
	},
	{
		path: ':resourceManufacturerId/model/create',
		component: ModelDetailStandaloneComponent,
		canActivate: [CorePortalPermissionGuard],
		canDeactivate: [UnsavedCreateGuard],
		data: {
			permissions: [AppPermissions.CreateResourceModel],
			breadcrumbs: I18nBreadcrumbResolver,
			text: 'core-portal.settings.subtitles.model-detail'
		}
	},
	{
		path: ':resourceManufacturerId/model/:resourceModelId',
		component: ModelDetailStandaloneComponent,
		canActivate: [CorePortalPermissionGuard],
		canDeactivate: [UnsavedCreateGuard],
		data: {
			permissions: [AppPermissions.UpdateResourceModel],
			breadcrumbs: I18nBreadcrumbResolver,
			text: 'core-portal.settings.subtitles.model-detail'
		}
	}
];

@NgModule({
	imports: [
		RouterModule.forChild(CORE_PORTAL_LABEL_ROUTES)
	],
	exports: [
		RouterModule
	]
})
export class ManufacturersRoutingModule {
}
