import { CustomPropertyType } from '@nexnox-web/core-shared';

export const customPropertyTypeOptionsForStereotypes = [
  { label: 'core-shared.shared.custom-property-types.1', value: CustomPropertyType.Text },
  { label: 'core-shared.shared.custom-property-types.2', value: CustomPropertyType.Multiline },
  { label: 'core-shared.shared.custom-property-types.3', value: CustomPropertyType.Numeric },
  { label: 'core-shared.shared.custom-property-types.4', value: CustomPropertyType.Email },
  { label: 'core-shared.shared.custom-property-types.5', value: CustomPropertyType.Dropdown },
  { label: 'core-shared.shared.custom-property-types.6', value: CustomPropertyType.Checkbox },
  { label: 'core-shared.shared.custom-property-types.7', value: CustomPropertyType.Date },
  { label: 'core-shared.shared.custom-property-types.8', value: CustomPropertyType.TimeOfDay },
  { label: 'core-shared.shared.custom-property-types.9', value: CustomPropertyType.Phone },
  { label: 'core-shared.shared.custom-property-types.10', value: CustomPropertyType.Signature },
  { label: 'core-shared.shared.custom-property-types.11', value: CustomPropertyType.Picture },
  { label: 'core-shared.shared.custom-property-types.12', value: CustomPropertyType.Info }
];

export enum CustomPropertySetType {
  STEREOTYPE,
  KNOWLEDGE_EXAM
}
