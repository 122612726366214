<nexnox-web-modal #modalComponent
                  [title]="'core-shared.shared.date-picker.title' | translate"
                  [confirmButtonText]="'core-portal.core.general.okay' | translate"
                  [cancelButtonText]="'core-portal.core.general.cancel' | translate"
                  [preConfirmFn]="preConfirmFn">
  <div class="w-100">
    <p-calendar [ngModel]="value"
                [minDate]="minDate"
                [maxDate]="maxDate"
                [dateFormat]="'core-shared.shared.primeng.date-format' | translate"
                [showButtonBar]="true"
                [firstDayOfWeek]="1"
                class="w-100"
                styleClass="date-picker-input"
                (ngModelChange)="onValueChange($event)">
    </p-calendar>
  </div>
</nexnox-web-modal>
