import {ChangeDetectionStrategy, Component, Injector, Input} from '@angular/core';
import {
  CorePortalEntityEditBaseComponent,
  CorePortalFormlyNgSelectTyping,
  CorePortalFormlyReadonlyTypes,
  CorePortalFormlyReadonlyTyping,
  CorePortalFormlyTranslatedTyping
} from '@nexnox-web/core-portal';
import {ProductEditions, ProductKind, ProductSubscriptionDto} from '@nexnox-web/core-shared';
import {FormlyFieldConfig} from '@ngx-formly/core';
import {nonClassicTenantSubscriptionTypeEnumOptions, tenantSubscriptionTypeEnumOptions} from '../../models';
import {isNumber} from "lodash";

export const tenantSubscriptionKindOptions = [
  {label: 'core-portal.service.tenants.kind-types.0', value: ProductKind.Internal},
  {label: 'core-portal.service.tenants.kind-types.1', value: ProductKind.Demo},
  {label: 'core-portal.service.tenants.kind-types.2', value: ProductKind.Customer}
];

@Component({
  selector: 'nexnox-web-service-tenants-tenant-subscription-edit',
  templateUrl: './tenant-subscription-edit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TenantSubscriptionEditComponent extends CorePortalEntityEditBaseComponent<ProductSubscriptionDto> {

  @Input() public tenantId: number | string;

  constructor(
    protected injector: Injector
  ) {
    super(injector);
  }

  /* istanbul ignore next */
  protected createForm(): FormlyFieldConfig[] {
    return [
      {key: 'tenantId', defaultValue: this.tenantId},
      {
        key: 'edition',
        type: 'core-portal-ng-select',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-3',
        defaultValue: ProductEditions.OrgaModule,
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.edition',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          } as CorePortalFormlyTranslatedTyping,
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.ENUM,
            enumOptions: tenantSubscriptionTypeEnumOptions,
            translate: true
          } as CorePortalFormlyReadonlyTyping,
          corePortalNgSelect: {
            items: nonClassicTenantSubscriptionTypeEnumOptions,
            translate: true
          } as CorePortalFormlyNgSelectTyping
        },
        expressionProperties: {
          'templateOptions.required': () => !this.readonly,
          'templateOptions.readonly': () => this.readonly || !this.creating
        }
      },
      {
        key: 'kind',
        type: 'core-portal-ng-select',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-3',
        defaultValue: ProductKind.Internal,
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.kind',
          } as CorePortalFormlyTranslatedTyping,
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.ENUM,
            enumOptions: tenantSubscriptionKindOptions,
            translate: true
          } as CorePortalFormlyReadonlyTyping,
          corePortalNgSelect: {
            items: tenantSubscriptionKindOptions,
            translate: true
          } as CorePortalFormlyNgSelectTyping,
        },
        expressionProperties: {
          'templateOptions.disabled': () => this.readonly,
          'templateOptions.readonly': () => this.readonly,
          'templateOptions.required': () => !this.readonly
        },
      },
      {
        key: 'expiresIn',
        type: 'core-portal-datepicker',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-3',
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.expires-in',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          } as CorePortalFormlyTranslatedTyping,
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.DATE,
            format: 'LL'
          } as CorePortalFormlyReadonlyTyping
        },
        expressionProperties: {
          'templateOptions.disabled': () => this.readonly,
          'templateOptions.readonly': () => this.readonly,
          'templateOptions.required': () => this.form.get('kind').value === ProductKind.Demo
        },
      },
      {
        key: 'amount',
        type: 'input',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-3',
        defaultValue: 1,
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.amount',
            validationMessages: {
              positive: {
                key: 'core-portal.core.validation.min',
                args: { min: 1 }
              }
            }
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.BASIC
          } as CorePortalFormlyReadonlyTyping,
          type: 'number'
        },
        expressionProperties: {
          'templateOptions.disabled': () => this.readonly,
          'templateOptions.readonly': () => this.readonly
        },
        validators: {
          positive: ctrl => !isNumber(ctrl.value) ? true : ctrl.value >= 1,
        }
      },
      {
        key: 'dealCode',
        type: 'input',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-6',
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.deal-code',
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.BASIC
          } as CorePortalFormlyReadonlyTyping,
          type: 'text'
        },
        expressionProperties: {
          'templateOptions.disabled': () => this.readonly,
          'templateOptions.readonly': () => this.readonly
        }
      }
    ];
  }
}
