import { EntityXsStore, EntityXsStoreState } from '@nexnox-web/core-store';
import { AppEntityType, TemplateDto } from '@nexnox-web/core-shared';
import { Action, createSelector } from '@ngrx/store';
import { selectTextTemplatesState } from '../text-templates.selectors';
import { TechPortalFeatureTextTemplateService } from '../../../services';
import { Injectable, Injector } from '@angular/core';

export interface TextTemplateDetailStoreState extends EntityXsStoreState<TemplateDto> {
}

/* istanbul ignore next */
export const textTemplateDetailStore = new EntityXsStore<TemplateDto>({
  actionLabel: 'Tech Portal - Templates - Text Template Detail',
  stateSelector: createSelector(selectTextTemplatesState, state => state.textTemplateDetail),
  serviceType: TechPortalFeatureTextTemplateService,
  entityType: AppEntityType.None,
  stereotyped: false
});

export function textTemplateDetailStoreReducer(state: TextTemplateDetailStoreState, action: Action): any {
  return textTemplateDetailStore.reducer(state, action);
}

@Injectable()
export class TextTemplateDetailStoreEffects extends textTemplateDetailStore.effects {
  constructor(
    protected injector: Injector
  ) {
    super(injector);
  }
}
