import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';
import { MissionByTicketDto, MissionDto, SolutionMemberDto, SolutionMemberType, TicketDto } from '@nexnox-web/core-shared';
import { filter, map } from 'rxjs/operators';
import { faClipboardCheck } from '@fortawesome/free-solid-svg-icons/faClipboardCheck';

@Component({
  selector: 'nexnox-web-tickets-ticket-mission-links',
  templateUrl: './ticket-mission-links.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TicketMissionLinksComponent implements OnInit {
  @Input() public model$: Observable<TicketDto> = of(null);
  @Input() public loading: boolean;

  public solutionMembers$: Observable<SolutionMemberDto[]>;
  public missions$: Observable<MissionByTicketDto[]>;

  public faClipboardCheck = faClipboardCheck;

  /* istanbul ignore next */
  public ngOnInit(): void {
    this.solutionMembers$ = this.model$.pipe(
      filter(model => Boolean(model)),
      map(model => model.solution?.members ?? [])
    );

    this.missions$ = this.solutionMembers$.pipe(
      map(solutionMembers => solutionMembers.filter(x => x.type === SolutionMemberType.Mission).map(x => ({
        missionId: x.id,
        title: x.title
      }) as MissionDto))
    );
  }
}
