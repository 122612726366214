import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  Inject,
  Injector,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { CORE_PORTAL_DASHBOARD_CONFIG, CorePortalDashboardConfig } from '@nexnox-web/core-portal';
import { DashboardComponent } from '../dashboard/dashboard.component';

@Component({
  selector: 'nexnox-web-dashboard-wrapper',
  templateUrl: './dashboard-wrapper.component.html'
})
export class DashboardWrapperComponent implements AfterViewInit {
  @ViewChild('viewContainer', { read: ViewContainerRef }) public viewContainer: ViewContainerRef;

  constructor(
    private injector: Injector,
    private componentFactoryResolver: ComponentFactoryResolver,
    private changeDetector: ChangeDetectorRef,
    @Inject(CORE_PORTAL_DASHBOARD_CONFIG) private dashboardConfig: CorePortalDashboardConfig
  ) {
  }

  /* istanbul ignore next */
  public ngAfterViewInit(): void {
    this.viewContainer.clear();
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(DashboardComponent);
    this.viewContainer.createComponent(componentFactory);
    this.changeDetector.detectChanges();
  }
}
