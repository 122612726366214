import {
  CorePortalFormlyNgSelectTyping,
  CorePortalFormlyReadonlyTypes,
  CorePortalFormlyReadonlyTyping
} from "@nexnox-web/core-portal";
import {FormlyFieldConfig} from "@ngx-formly/core";
import {productOrderOperationStatusTypes} from "../models";

export function getWinterHalterProductOrderFormlyConfig(readonly: boolean): FormlyFieldConfig[] {
  return [
    {
      key: 'winterhalterOrder.orderId',
      type: 'input',
      wrappers: ['core-portal-translated', 'core-portal-readonly'],
      className: 'col-md-6',
      templateOptions: {
        type: 'text',
        corePortalTranslated: {
          label: 'core-shared.shared.fields.order-id'
        },
        corePortalReadonly: {
          type: CorePortalFormlyReadonlyTypes.BASIC
        } as CorePortalFormlyReadonlyTyping
      },
      expressionProperties: {
        'templateOptions.disabled': () => readonly,
        'templateOptions.readonly': () => readonly
      }
    },
    {
      key: 'winterhalterOrder.operations.status',
      type: 'core-portal-ng-select',
      wrappers: ['core-portal-translated', 'core-portal-readonly'],
      className: 'col-md-6',
      defaultValue: productOrderOperationStatusTypes[0].value,
      templateOptions: {
        type: 'text',
        corePortalTranslated: {
          label: 'core-shared.shared.fields.current-state',
          validationMessages: {
            required: 'core-portal.core.validation.required'
          }
        },
        corePortalReadonly: {
          type: CorePortalFormlyReadonlyTypes.ENUM,
          enumOptions: productOrderOperationStatusTypes,
          translate: true
        } as CorePortalFormlyReadonlyTyping,
        corePortalNgSelect: {
          items: productOrderOperationStatusTypes,
          translate: true
        } as CorePortalFormlyNgSelectTyping
      },
      expressionProperties: {
        'templateOptions.disabled': () => readonly,
        'templateOptions.readonly': () => readonly
      }
    },
    {
      key: 'winterhalterOrder.orderRefreshAt',
      type: 'core-portal-datepicker',
      wrappers: ['core-portal-translated', 'core-portal-readonly'],
      className: 'col-md-6',
      templateOptions: {
        type: 'text',
        corePortalTranslated: {
          label: 'core-shared.shared.fields.refresh-at'
        },
        corePortalReadonly: {
          type: CorePortalFormlyReadonlyTypes.DATE,
          format: 'LL'
        } as CorePortalFormlyReadonlyTyping
      },
      expressionProperties: {
        'templateOptions.disabled': () => readonly,
        'templateOptions.readonly': () => readonly
      }
    }
  ]
}
