<p-sidebar #sidebarComponent
           position="right"
           appendTo="body"
           styleClass="p-sidebar-lg cross-creation"
           [transitionOptions]="transitionOptions"
           [visible]="visible$ | async"
           (visibleChange)="onVisibleChange($event)">

  <div class="d-flex align-content-between flex-column h-100">
    <div class="w-100 flex-grow-1">
      <ng-container #editComponentContainer>
      </ng-container>
    </div>

    <div class="w-100 flex-shrink-1 d-flex justify-content-end" *ngIf="config && editComponent">
      <button pButton
              type="button"
              class="p-button p-button-secondary mr-3"
              [label]="'core-portal.core.general.cancel' | translate"
              [icon]="faTimes | nexnoxWebFaIconStringPipe"
              (click)="onHide()">
      </button>

      <button pButton
              type="button"
              class="p-button p-button-primary"
              [label]="config.createTitle | translate"
              [icon]="faPlus | nexnoxWebFaIconStringPipe"
              [disabled]="disablePrimaryAction$ | async"
              (click)="onCreate()">
      </button>
    </div>
  </div>
</p-sidebar>
