import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsDeclarationsModule } from './forms-declarations.module';
import { FormsStoreModule } from './forms-store.module';
import { FormsRoutingModule } from './forms-routing.module';

@NgModule({
  imports: [
    FormsDeclarationsModule,
    FormsRoutingModule,
    FormsStoreModule
  ]
})
export class OrgaPortalFeatureFormsModule {
  public static forFeature(): ModuleWithProviders<FormsDeclarationsModule> {
    return { ngModule: FormsDeclarationsModule };
  }
}
