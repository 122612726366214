import {
  contactAreaDetailStore,
  ContactAreaDetailStoreState,
  contactAreaListStore,
  ContactAreaListStoreState,
  contactDetailStore,
  ContactDetailStoreState,
  contactListStore,
  ContactListStoreState,
  contactLocationGroupListStore,
  ContactLocationGroupListStoreState,
  contactOpenRequestListStore,
  ContactLocationListStoreState,
  contactRoleListStore,
  ContactRoleListStoreState,
  ContactOpenRequestListStoreState,
  contactLocationListStore
} from './stores';

export interface ContactsState {
  contactList: ContactListStoreState;
  contactDetail: ContactDetailStoreState;

  contactLocationList: ContactLocationListStoreState;
  contactLocationGroupList: ContactLocationGroupListStoreState;
  contactRoleList: ContactRoleListStoreState;
  contactAreaList: ContactAreaListStoreState;
  contactAreaDetail: ContactAreaDetailStoreState;
  contactOpenRequestList: ContactOpenRequestListStoreState,
}

export const getInitialContactsState = (): ContactsState => ({
  contactList: contactListStore.getInitialState(),
  contactDetail: contactDetailStore.getInitialState(),

  contactLocationList: contactLocationListStore.getInitialState(),
  contactLocationGroupList: contactLocationGroupListStore.getInitialState(),
  contactRoleList: contactRoleListStore.getInitialState(),
  contactAreaList: contactAreaListStore.getInitialState(),
  contactAreaDetail: contactAreaDetailStore.getInitialState(),
  contactOpenRequestList: contactOpenRequestListStore.getInitialState()
});
