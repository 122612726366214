import { cloneDeep } from 'lodash';
import { BaseXsStore, BaseXsStoreOptions } from '../base';

export interface ModelXsStoreOptions<E, M, S> extends BaseXsStoreOptions<S> {
  prepareEntity?: (entity: E, base: (entity: E) => E) => E;
  prepareModel?: (entity: E, model: M, base: (entity: E, model: M) => M) => M;
  sanitizeModel?: (model: M, entity: E, base: (model: M, entity: E) => E) => E;
}

export class ModelXsStore<E, M = E, S = any> extends BaseXsStore<S> {
  constructor(
    protected options: ModelXsStoreOptions<E, M, S>
  ) {
    super(options);
  }

  protected static getPrepareEntityFn<E, M, S>(options: ModelXsStoreOptions<E, M, S>): (entity: E) => E {
    const baseFn = (entity: E): E => this.prepareEntity(entity);
    return options.prepareEntity ? entity => options.prepareEntity(entity, baseFn) : baseFn;
  }

  protected static getPrepareModelFn<E, M, S>(options: ModelXsStoreOptions<E, M, S>): (entity: E, model: M) => M {
    const baseFn = (entity: E, model: M): M => this.prepareModel(entity, model);
    return options.prepareModel ? (entity, model) => options.prepareModel(entity, model, baseFn) : baseFn;
  }

  protected static getSanitizeModelFn<M, E, S>(options: ModelXsStoreOptions<E, M, S>): (model: M, entity: E) => E {
    const baseFn = (model: M, entity: E): E => this.sanitizeModel(model, entity);
    return options.sanitizeModel ? (model, entity) => options.sanitizeModel(model, entity, baseFn) : baseFn;
  }

  protected static prepareEntity<E>(entity: E): E {
    return cloneDeep(entity);
  }

  protected static prepareModel<E, M>(entity: E, model: M): M {
    return cloneDeep(entity) as any as M;
  }

  protected static sanitizeModel<M, E>(model: M, entity: E): E {
    return cloneDeep(model) as any as E;
  }
}
