import {ChangeDetectionStrategy, Component, Injector, OnInit} from '@angular/core';
import {ActionButton, CorePortalEntityDetailStandaloneBaseComponent} from '@nexnox-web/core-portal';
import {AppPermissions, MissionStartingState, StartingMissionDto} from "@nexnox-web/core-shared";
import {ticketMissionDetailStore} from "../../store";
import {firstValueFrom} from "rxjs";
import {faPaperPlane} from "@fortawesome/free-solid-svg-icons/faPaperPlane";
import {TicketMissionPreviewService} from "../../store/services/monitor";

@Component({
  selector: 'nexnox-web-tickets-ticket-mission-detail-standalone',
  templateUrl: './ticket-mission-detail-standalone.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TicketMissionDetailStandaloneComponent extends CorePortalEntityDetailStandaloneBaseComponent<StartingMissionDto> implements OnInit {

  public idParam = "missionId";
  public displayKey = 'title'

  constructor(
    protected injector: Injector
  ) {
    super(injector, ticketMissionDetailStore);
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.setTitle('missions.actions.create-mission', 'missions.actions.edit-mission');

    // Add keyless monitor and just trigger at onInit
    setTimeout(() => {
      this.monitors.add([
        {
          name: 'ticketMissionPreview',
          service: TicketMissionPreviewService,
          payload: {
            parentId: this.parentIds[0]
          }
        }
      ]);
      this.monitors.trigger('ticketMissionPreview').then();
    });
  }

  protected async getActionButtons(): Promise<ActionButton[]> {
    return [
      ...this.getStandaloneActionButtons(
        'missions.actions.create-mission',
        AppPermissions.CreateMission,
        'missions.actions.edit-mission',
        AppPermissions.UpdateMission,
        'missions.actions.save-mission',
        AppPermissions.UpdateMission
      ),
      {
        type: "button",
        class: 'btn-primary',
        label: 'missions.mission-states-change.activate-and-send-mail',
        icon: faPaperPlane,
        permission: AppPermissions.CreateMission,
        isDisabled: () => this.disablePrimaryAction$,
        isLoading: () => this.loading$,
        callback: () => this.onActivateAndSendMail()
      }
    ]
  }

  protected async onActivateAndSendMail(): Promise<void> {
    const model = await firstValueFrom(this.model$);
    this.onModelChange({
      ...model,
      state: MissionStartingState.Instructed
    });

    this.isDeactivateUnsavedChangesModal = true;
    setTimeout(() => this.onCreateNative(this.getReturnPathForSaveOrCreate()));
  }

  protected override getReturnPathForSaveOrCreate(): Array<string> {
    return this.tenantRouter.createCommands(['missions', 'all', ':missionId'], {module: 'inventory'});
  }
}
