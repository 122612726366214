import { ChangeDetectionStrategy, Component, Injector } from '@angular/core';
import { CorePortalFeatureStereotypeSkeletonWrapperBaseComponent } from '@nexnox-web/core-portal/features/settings/features/stereotypes';

@Component({
  selector: 'nexnox-web-skeletons-mission-report-edit-wrapper',
  templateUrl: './mission-report-edit-wrapper.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MissionReportEditWrapperComponent extends CorePortalFeatureStereotypeSkeletonWrapperBaseComponent {
  constructor(
    protected injector: Injector
  ) {
    super(injector);
  }
}
