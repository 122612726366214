<ng-template #entityEditTemplate let-title="title" let-id="id">
  <nexnox-web-templates-document-template-edit #modelComponent
                                               [title]="title"
                                               [id]="id"
                                               [model]="model$ | async"
                                               [readonly]="readonly$ | async"
                                               [creating]="false"
                                               [loading]="loading$ | async"
                                               [stereotypes$]="stereotypes$"
                                               [usageType]="templateUsageType"
                                               (modelChange)="onModelChange($event)">
  </nexnox-web-templates-document-template-edit>
</ng-template>

<nexnox-web-entity-detail [entityDetailBaseComponent]="this"
                          [entityEditTemplate]="entityEditTemplate"
                          tabId="template"
                          [tabHeading]="title | translate"
                          [tabCommands]="['.']">
</nexnox-web-entity-detail>
