import {
  ChatMessageInNoteListDto,
  ContractSimpleListDto,
  EscalationLevelListDto,
  MissionSimpleListDto,
  MissionState,
  NoteCompleteDto,
  NoteDto,
  NoteMailBoxListDto,
  ResourceInNoteListDto,
  SolutionItemDto,
  StateListDto,
  SystemDirections,
  UserSimpleDto
} from "@nexnox-web/core-shared";

// Local solution item

export class LocalSolutionItemDto implements SolutionItemDto {

  public solutionItemId?: number;
  public tenantId?: number;
  public solutionId?: number;
  public lastModified?: string;
  public createdAt?: string;
  public ticketId?: number;
  public missionId?: number;
  public isSolution?: boolean;
  public createdByUser?: UserSimpleDto;
  public note?: NoteDto & NoteCompleteDto;

  constructor(item: SolutionItemDto) {
    Object.assign(this, item);
  }

  public asText(): LocalNoteTextDto {
    return this.note as LocalNoteTextDto;
  }

  public asMail(): LocalNoteMailDto {
    return this.note as LocalNoteMailDto;
  }

  public asCaller(): LocalNoteCallerDto {
    return this.note as LocalNoteCallerDto;
  }

  public asChangedStatus(): LocalNoteChangedStatusDto {
    return this.note as LocalNoteChangedStatusDto;
  }

  public asEscalatedByEvent(): LocalNoteEscalatedByEventDto {
    return this.note as LocalNoteEscalatedByEventDto;
  }

  public asEscalatedByUser(): LocalNoteEscalatedByUserDto {
    return this.note as LocalNoteEscalatedByUserDto;
  }

  public asCanceledEscalation(): LocalNoteCanceledEscalationDto {
    return this.note as LocalNoteCanceledEscalationDto;
  }

  public asChangedStatusOfMission(): LocalNoteChangedStatusOfMissionDto {
    return this.note as LocalNoteChangedStatusOfMissionDto;
  }

  public asChatMessage(): LocalNoteChatMessageDto {
    return this.note as LocalNoteChatMessageDto;
  }

  public asChangedResource(): LocalNoteChangedResourceDto {
    return this.note as LocalNoteChangedResourceDto;
  }
}

// Local note dto types:

export interface LocalNoteChangedStatusDto extends NoteDto {
  causedMissionId?: number;
  causedMission?: MissionSimpleListDto;
  causedContract?: ContractSimpleListDto;
  isChangedByEmail?: boolean;
  changedToState?: StateListDto;
}

export interface LocalNoteEscalatedByEventDto extends NoteDto {
  escalatedLevelIdByEvent?: number;
  escalatedLevelByEvent?: EscalationLevelListDto;
  offsetForEscalatedByEvent?: any;
}

export interface LocalNoteEscalatedByUserDto extends NoteDto {
  escalatedLevelIdByUser?: number;
  escalatedLevelByUser?: EscalationLevelListDto;
}

export interface LocalNoteCanceledEscalationDto extends NoteDto {
  cancelLevelId?: number;
  cancelLevel?: EscalationLevelListDto;
  offsetForCanceledLevel?: any;
}

export interface LocalNoteChangedStatusOfMissionDto extends NoteDto {
  missionState?: MissionState;
}

export interface LocalNoteChangedResourceDto extends NoteDto {
  previousResource?: ResourceInNoteListDto;
  currentResource?: ResourceInNoteListDto;
}

export interface LocalNoteTextDto extends NoteDto {
}

export interface LocalNoteMailDto extends NoteDto {
  direction?: SystemDirections;
  to?: NoteMailBoxListDto[];
  cc?: NoteMailBoxListDto[];
  bcc?: NoteMailBoxListDto[];
  from?: NoteMailBoxListDto[];
}

export interface LocalNoteCallerDto extends NoteDto {
  direction?: SystemDirections;
  phoneNumber?: string;
}

export interface LocalNoteChatMessageDto extends NoteDto {
  chatMessage?: ChatMessageInNoteListDto;
}
