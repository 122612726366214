<nexnox-web-entity-edit displayKey="title"
                        [entityEditBaseComponent]="this"
                        [stereotyped]="true">
</nexnox-web-entity-edit>

<nexnox-web-cardbox [title]="'issues.fields.products' | translate" [noDividerMarginBottom]="true">
  <nexnox-web-basic-table [config]="productTableConfig"
                          [data]="products$ | async">
  </nexnox-web-basic-table>
</nexnox-web-cardbox>
