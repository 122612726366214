import { ModuleWithProviders, NgModule } from '@angular/core';
import { InfosDeclarationsModule } from './infos-declarations.module';
import { InfosStoreModule } from './infos-store.module';
import { InfosRoutingModule } from './infos-routing.module';

@NgModule({
  imports: [
    InfosDeclarationsModule,
    InfosRoutingModule,
    InfosStoreModule
  ]
})
export class OrgaPortalFeatureInfosModule {
  public static forFeature(): ModuleWithProviders<InfosDeclarationsModule> {
    return { ngModule: InfosDeclarationsModule };
  }
}
