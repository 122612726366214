import {RouterModule, Routes} from '@angular/router';
import {
  ContactAreaDetailStandaloneComponent,
  ContactDetailComponent,
  CorePortalFeatureMasterDataContactListComponent
} from './containers';
import {NgModule} from '@angular/core';
import {AppPermissions, I18nBreadcrumbResolver, UnsavedChangesGuard, UnsavedCreateGuard} from '@nexnox-web/core-shared';
import {CorePortalComponentConfigGuard, CorePortalPermissionGuard} from '@nexnox-web/core-portal';
import {ContactAreaBreadcrumbsResolver} from "./resolvers";

export const CORE_PORTAL_MASTER_DATA_CONTACTS_ROUTES: Routes = [
  {
    path: '',
    canActivate: [CorePortalPermissionGuard, CorePortalComponentConfigGuard],
    canDeactivate: [UnsavedCreateGuard],
    component: CorePortalFeatureMasterDataContactListComponent,
    data: {
      permissions: [AppPermissions.DisplayContacts],
      requiredComponentConfig: ['allowList']
    }
  },
  {
    path: ':contactId',
    component: ContactDetailComponent,
    canActivate: [CorePortalPermissionGuard],
    canDeactivate: [UnsavedChangesGuard],
    data: {
      permissions: [AppPermissions.ReadContact, AppPermissions.ReadStereotype],
      breadcrumbs: I18nBreadcrumbResolver,
      tab: 'contact',
      text: 'core-portal.core.header.breadcrumbs.master-data.contact',
    },
    children: [
      {
        path: 'areas',
        data: {
          tab: 'areas',
          breadcrumbs: I18nBreadcrumbResolver,
          text: 'core-portal.core.header.breadcrumbs.master-data.contact-areas'
        },
        children: []
      },
      {
        path: 'requests',
        data: {
          tab: 'requests'
        },
        children: []
      },
      {
        path: 'links',
        data: {
          tab: 'links'
        },
        children: []
      },
      {
        path: 'locations',
        data: {
          tab: 'locations'
        },
        children: []
      },
      {
        path: 'locationgroups',
        data: {
          tab: 'locationGroups'
        },
        children: []
      },
      {
        path: 'roles',
        data: {
          tab: 'roles'
        },
        children: []
      }
    ]
  },

  {
    path: ':contactId/areas/create',
    canDeactivate: [UnsavedChangesGuard],
    component: ContactAreaDetailStandaloneComponent,
    data: {
      permissions: AppPermissions.CreateAreaAssignment,
      breadcrumbs: ContactAreaBreadcrumbsResolver,
      text: 'core-portal.core.header.breadcrumbs.master-data.contact-area'
    }
  },
  {
    path: ':contactId/areas/:areaAssignmentId',
    canDeactivate: [UnsavedChangesGuard],
    component: ContactAreaDetailStandaloneComponent,
    data: {
      permissions: AppPermissions.UpdateAreaAssignment,
      breadcrumbs: ContactAreaBreadcrumbsResolver,
      text: 'core-portal.core.header.breadcrumbs.master-data.contact-area'
    }
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(CORE_PORTAL_MASTER_DATA_CONTACTS_ROUTES)
  ],
  exports: [
    RouterModule
  ]
})
export class ContactsRoutingModule {
}
