import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';
import { MissionDto, SolutionMemberDto, SolutionMemberType, TicketDto } from '@nexnox-web/core-shared';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons/faEnvelope';
import { filter, map } from 'rxjs/operators';

@Component({
  selector: 'nexnox-web-missions-mission-ticket-list',
  templateUrl: './mission-ticket-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MissionTicketListComponent implements OnInit {
  @Input() public model$: Observable<MissionDto> = of(null);
  @Input() public loading: boolean;

  public solutionMembers$: Observable<SolutionMemberDto[]>;
  public tickets$: Observable<TicketDto[]>;

  public faEnvelope = faEnvelope;

  /* istanbul ignore next */
  public ngOnInit(): void {
    this.solutionMembers$ = this.model$.pipe(
      filter(model => Boolean(model)),
      map(model => model.solution?.members ?? [])
    );

    this.tickets$ = this.solutionMembers$.pipe(
      map(solutionMembers => solutionMembers.filter(x => x?.type === SolutionMemberType.Ticket).map(x => ({
        ticketId: x.id,
        title: x.title
      }) as TicketDto))
    );
  }
}
