import { AppEntityType } from '@nexnox-web/core-shared';
import { createSelector } from '@ngrx/store';
import { selectDevicesState } from '../../devices.selectors';
import { OrgaPortalFeatureDeviceService } from '../../services';
import { DeviceDetailXsStore, DeviceDetailXsStoreState } from './device-detail.xs-store';

export interface DeviceDetailStoreState extends DeviceDetailXsStoreState {
}

/* istanbul ignore next */
export const deviceDetailStore = new DeviceDetailXsStore({
  actionLabel: 'Orga Portal - Devices - Device Detail',
  stateSelector: createSelector(selectDevicesState, state => state.deviceDetail),
  serviceType: OrgaPortalFeatureDeviceService,
  entityType: AppEntityType.None,
  stereotyped: false
});
