import {PagedEntitiesXsStore, PagedEntitiesXsStoreState} from '@nexnox-web/core-store';
import {AppEntityType, KnowledgePathProgressLocationListDto} from '@nexnox-web/core-shared';
import {createSelector} from '@ngrx/store';
import {selectKnowledgePathState} from '../../paths.selectors';
import {PathsProgressLocationService} from "../../services";

export interface PathsProgressLocationListStoreState extends PagedEntitiesXsStoreState<KnowledgePathProgressLocationListDto> {
}

/* istanbul ignore next */
export const pathsProgressLocationListStore = new PagedEntitiesXsStore<KnowledgePathProgressLocationListDto>({
  actionLabel: 'Orga Portal - Paths - Path Progress Location List',
  stateSelector: createSelector(selectKnowledgePathState, state => state.pathsProgressLocationList),
  serviceType: PathsProgressLocationService,
  entityType: AppEntityType.None,
  defaultPageSize: 11,
  selectId: pathsProgressLocation => JSON.stringify([
    pathsProgressLocation.entity?.locationId ?? pathsProgressLocation.model?.locationId
  ]),
  stereotyped: false
});
