import {Injectable} from '@angular/core';
import {ControllerOperationId, DataTableDto, NotificationTrigger} from '@nexnox-web/core-shared';

@Injectable()
export class CorePortalFeatureNotificationRulesTriggerTypeRestrictionService {

  public isDifferentRestriction(trigger: NotificationTrigger, restriction: DataTableDto): boolean {
    return (this.isContractRestriction(trigger) && restriction?.pageOperation !== ControllerOperationId.ContractControllerList) ||
      (this.isMissionRestriction(trigger) && restriction?.pageOperation !== ControllerOperationId.MissionControllerList) ||
      (this.isTicketRestriction(trigger) && restriction?.pageOperation !== ControllerOperationId.TicketControllerList) ||
      (this.isInfoRestriction(trigger) && restriction?.pageOperation !== ControllerOperationId.InfoControllerList)
  }

  public isMissionRestriction(triggerType: NotificationTrigger): boolean {
    return triggerType === NotificationTrigger.MissionChangedEditor ||
      triggerType === NotificationTrigger.MissionChangedResource ||
      triggerType === NotificationTrigger.MissionChangedState;
  }

  public isTicketRestriction(triggerType: NotificationTrigger): boolean {
    return triggerType === NotificationTrigger.TicketChangedEditor ||
      triggerType === NotificationTrigger.TicketChangedResource ||
      triggerType === NotificationTrigger.TicketChangedState;
  }

  public isContractRestriction(triggerType: NotificationTrigger): boolean {
    return triggerType === NotificationTrigger.ContractChangedState;
  }

  public isInfoRestriction(triggerType: NotificationTrigger): boolean {
    return triggerType === NotificationTrigger.ActivatedInfo;
  }
}
