<nexnox-web-entity-edit [entityEditBaseComponent]="this"
                        displayKey="name"
                        [isTitleEditable]="false">
</nexnox-web-entity-edit>

<nexnox-web-cardbox [title]="'core-portal.settings.subtitles.dashboard-items' | translate"
                    [loading]="loading"
                    paddingClasses="px-3 pb-3">
  <div class="d-flex flex-row mb-3">
    <div class="d-flex flex-grow-1">
      <nexnox-web-entity-select class="w-100"
                                [(model)]="selectedItem"
                                [disabled]="readonly"
                                [options]="selectOptions">
      </nexnox-web-entity-select>
    </div>

    <button class="btn btn-outline-primary ml-3"
            [disabled]="!selectedItem || readonly"
            (click)="onAddItem()">
      {{ 'core-shared.shared.select.add-item' | translate }}
    </button>
  </div>

  <nexnox-web-sortable-list [items]="items$ | async"
                            [disabled]="readonly"
                            (itemsChange)="onItemsChange($event)">
  </nexnox-web-sortable-list>
</nexnox-web-cardbox>

<ng-template #selectOptionTemplate let-item="item">
  <ng-container *ngIf="dashboardConfig[item.pageOperation] as dashboardItem">
    <br>
    <span class="text-muted">{{ ('core-shared.shared.entity-types.' + dashboardItem.entityType) | translate }}</span>
  </ng-container>
</ng-template>

<!-- Location Templates -->
<ng-template #locationSelectLabelTitleTemplate let-item="item">
  <nexnox-web-master-data-locations-location-location-number [location]="item"
                                                             [label]="true">
  </nexnox-web-master-data-locations-location-location-number>
</ng-template>

<ng-template #locationSelectOptionTitleTemplate let-item="item" let-searchTerm="searchTerm">
  <nexnox-web-master-data-locations-location-location-number [location]="item"
                                                             [searchTerm]="searchTerm"
                                                             [label]="false">
  </nexnox-web-master-data-locations-location-location-number>
</ng-template>
