import {EntityXsStoreState} from '@nexnox-web/core-store';
import {AppEntityType, ResourceModelDto} from '@nexnox-web/core-shared';
import {createSelector} from '@ngrx/store';
import {CorePortalModelService} from '../../services';
import {selectManufacturerState} from "../../manufacturer.selectors";
import {ModelDetailXsStore} from "./model-detail.xs-store";

export interface ModelDetailStoreState extends EntityXsStoreState<ResourceModelDto> {
}

/* istanbul ignore next */
export const modelDetailStore = new ModelDetailXsStore({
  actionLabel: 'Core Portal - Settings - Models - Model Detail',
  stateSelector: createSelector(selectManufacturerState, state => state.modelDetail),
  serviceType: CorePortalModelService,
  entityType: AppEntityType.None,
  stereotyped: false
});


