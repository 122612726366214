import {ChangeDetectionStrategy, Component, Injector, OnInit, ViewChild} from '@angular/core';
import {
  AppEntityType,
  AppPermissions,
  AreaAssignmentDto,
  CommonFilterDto,
  ContactDto,
  ControllerOperationId,
  Filter,
  mapFilterToDatatableFilter,
  Mappers,
  Paging,
} from '@nexnox-web/core-shared';
import {
  ActionButton,
  authStore,
  CorePortalEntityOverviewBaseComponent,
  DatatableActionButton,
  DeleteEntityModel
} from '@nexnox-web/core-portal';
import {contactDetailStore, contactListStore} from '../../store';
import {firstValueFrom, lastValueFrom, merge, mergeMap, Observable, take} from "rxjs";
import {select} from "@ngrx/store";
import {map, withLatestFrom} from "rxjs/operators";
import {BulkAreaAssignmentSidebarComponent} from "../../sidebars";
import {faMailBulk} from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: 'nexnox-web-master-data-contacts-contact-list',
  templateUrl: './contact-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CorePortalFeatureMasterDataContactListComponent extends CorePortalEntityOverviewBaseComponent<ContactDto> implements OnInit {
  @ViewChild('bulkAreaAssignmentSidebar', {static: true}) public bulkAreaAssignmentSidebar: BulkAreaAssignmentSidebarComponent;

  public title = 'core-portal.master-data.subtitles.contact-list';
  public createTitle = 'core-portal.master-data.subtitles.contact-create';
  public idProperty = 'contactId';
  public displayProperty = 'displayName';
  public pageOperation = ControllerOperationId.ContactControllerList;
  public componentId = 'ContactListComponent';
  public enableViews = true;
  public datatableConfigName = 'MasterDataContactDefault';
  public isBulkAreaAssignmentLoading$: Observable<boolean>;
  public contactCount = 0;
  public filters$: Observable<any>;

  private bulkAreaAssignmentButtonLabel$: Observable<string>;
  private isBulkAreaAssignmentButtonDisabled$: Observable<boolean>;

  constructor(
    protected injector: Injector
  ) {
    super(injector, contactListStore, Mappers.ContactListDto.serializedName, AppEntityType.Contact);
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.getStoreData();
  }

  public async openBulkAreaAssignmentSidebar(): Promise<void> {
    this.contactCount = await firstValueFrom(this.store.select(contactListStore.selectors.selectPaging).pipe(map(
      (paging: Paging) => paging?.totalItems
    )));
    this.bulkAreaAssignmentSidebar.onShow();
  }

  public async onBulkAreaAssign(areaAssignment: AreaAssignmentDto): Promise<void> {
    const tenantId: number = await firstValueFrom(this.store.pipe(
      select(authStore.selectors.selectTenantId),
      take(1)
    ));
    const filters: CommonFilterDto[] = (await lastValueFrom(this.filters$.pipe(take(1))) ?? [])
      .map((filter: Filter) => ({...mapFilterToDatatableFilter(filter), tenantId}));
    const totalItems = this.contactCount > 0 ? this.contactCount : null;

    this.store.dispatch(contactDetailStore.actions.bulkAreaAssignment({
      tenantId,
      filters,
      totalItems,
      areaAssignment: {
        ...areaAssignment,
        tenantId
      }
    }));
  }

  public getRowActionButtons(): DatatableActionButton[] {
    return this.getDefaultRowActionButtons(
      'core-portal.master-data.actions.edit-contact',
      (row: ContactDto) => `/masterdata/contacts/${row.contactId}`,
      [AppPermissions.UpdateContact],
      {
        module: 'management'
      }
    );
  }

  public getDeleteEntityModel(): DeleteEntityModel {
    return {
      titleKey: 'core-portal.master-data.actions.delete-contact',
      descriptionKey: 'core-portal.master-data.descriptions.delete-contact',
      confirmKey: 'core-portal.master-data.actions.delete-contact',
      deletePermission: AppPermissions.DeleteContact
    };
  }

  protected async getActionButtons(): Promise<ActionButton[]> {
    return [
      ...this.getDefaultActionButtons('core-portal.master-data.actions.create-contact', AppPermissions.CreateContact),
      {
        label$: this.bulkAreaAssignmentButtonLabel$,
        noTranslate: true,
        class: 'btn-outline-primary',
        type: 'button',
        permission: AppPermissions.CreateAreaAssignment,
        icon: faMailBulk,
        tooltip: 'core-portal.master-data.contact.tooltip.bulk-create-area-assignment',
        callback: () => this.openBulkAreaAssignmentSidebar(),
        shouldShow: () => this.permissionService.hasPermission$(AppPermissions.CreateAreaAssignment)
          .pipe(mergeMap(hasPermission => this.readonly$.pipe(map(readonly => hasPermission && readonly)))),
        isDisabled: () => this.isBulkAreaAssignmentButtonDisabled$,
        isLoading: () => this.isBulkAreaAssignmentLoading$
      }
    ];
  }

  private getStoreData(): void {
    this.isBulkAreaAssignmentLoading$ = this.store.pipe(
      select(contactDetailStore.selectors.selectEntityDataLoading, {key: contactDetailStore.actions.bulkAreaAssignment.type}),
      map(loading => Boolean(loading))
    );
    this.bulkAreaAssignmentButtonLabel$ =
      merge(this.translate.onLangChange, this.store.select(contactListStore.selectors.selectPaging))
        .pipe(
          withLatestFrom(this.store.select(contactListStore.selectors.selectPaging)),
          map(([_, paging]) =>
            this.translate?.instant(
              'core-portal.master-data.contact.actions.bulk-create-area-assignment',
              {contactCount: paging?.totalItems ?? 0}
            )
          )
        );
    this.filters$ = this.store.select(contactListStore.selectors.selectFilters);
    this.isBulkAreaAssignmentButtonDisabled$ = this.store.select(contactListStore.selectors.selectPaging).pipe(map(
      (paging: Paging) => !paging?.totalItems
    ));
  }
}
