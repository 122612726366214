import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {CoreSharedSidebarBaseComponent, ResourceManufacturerDto, ResourceModelDto} from '@nexnox-web/core-shared';
import {IconDefinition} from "@fortawesome/fontawesome-common-types";
import {BehaviorSubject, Observable, take} from "rxjs";
import {faTimes, faUser} from '@fortawesome/free-solid-svg-icons';
import {FormGroup} from "@angular/forms";
import {FormlyFieldConfig} from "@ngx-formly/core";
import {CorePortalManufacturerService} from "../../store";
import {ActivatedRoute} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";


@Component({
  selector: 'nexnox-web-move-model-sidebar',
  templateUrl: './move-model-sidebar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MoveModelSidebarComponent extends CoreSharedSidebarBaseComponent implements OnInit {
  @ViewChild('destinationTemplate', {static: true}) public destinationTemplate: TemplateRef<any>;
  @ViewChild('confirmButton', {static: true}) public confirmButton: HTMLButtonElement;

  @Input() public modelToMove: BehaviorSubject<ResourceModelDto>;

  @Output() public move: EventEmitter<ResourceManufacturerDto> = new EventEmitter<ResourceManufacturerDto>();

  public modelManufacturerId$: BehaviorSubject<number> = new BehaviorSubject<number>(null);

  public loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public form: FormGroup;
  public model: { newManufacturer: ResourceManufacturerDto };
  public fields: FormlyFieldConfig[];

  public faUser: IconDefinition = faUser;
  public faTimes: IconDefinition = faTimes;

  constructor(
    private route: ActivatedRoute,
    private translate: TranslateService,
    private manufacturerService: CorePortalManufacturerService
  ) {
    super();

    this.subscribe(this.route.url.pipe(take(1)), (url => {
      this.modelManufacturerId$.next(+url[0]?.path);
    }))
  }

  public get descriptionTranslation$(): Observable<string> {
    return this.translate?.get(
      'core-portal.settings.descriptions.move-model',
      {model: this.modelToMove?.getValue().name}
    );
  }

  public ngOnInit(): void {
    this.fields = this.createForm();
    this.form = new FormGroup({});
    this.model = {} as any;
  }

  public onShow(): void {
    super.onShow();
    this.ngOnInit();
    this.loading$.next(false);
  }

  public onHide(): void {
    this.loading$.next(false);
    super.onHide();
  }

  public onAccept(): void {
    this.loading$.next(true);
    if (this.model.newManufacturer) {
      this.move.emit(this.model.newManufacturer);
    }
    this.onHide();
  }

  /* istanbul ignore next */
  private createForm(): FormlyFieldConfig[] {
    return [
      {
        key: 'newManufacturer',
        type: 'core-portal-entity-select',
        wrappers: ['core-portal-translated'],
        className: 'col-md-12',
        defaultValue: null,
        templateOptions: {
          showError: true,
          corePortalTranslated: {
            label: 'core-shared.shared.fields.manufacturer',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          },
          entityService: this.manufacturerService,
          idKey: 'resourceManufacturerId',
          displayKey: 'name',
          wholeObject: true,
          selectLabelTemplate: this.destinationTemplate,
          selectOptionTemplate: this.destinationTemplate,
          required: true,
          excludedIds: [this.modelManufacturerId$.getValue()]
        }
      }
    ];
  }
}
