import { Pipe, PipeTransform } from '@angular/core';
import { isNumber } from 'lodash';

@Pipe({
  name: 'nexnoxWebHighlight'
})
export class HighlightPipe implements PipeTransform {
  public transform(value: string, highlight: string, exclude?: string): string {
    if (!highlight) {
      return value;
    }
    // It replaces "(" and ")" occurrences to avoid errors
    highlight = highlight.replace(/[()]/g, '\\$&');

    let excludeIndex: number;

    if (exclude) {
      excludeIndex = value.indexOf(exclude) ?? null;
      value = value.replace(exclude, '');
    }

    const highlightRegex = new RegExp(highlight, 'gi');
    let returnValue = value.replace(highlightRegex, match => `<strong>${match}</strong>`);

    if (exclude && isNumber(excludeIndex)) {
      returnValue =
        returnValue.substring(0, excludeIndex) + exclude + returnValue.substring(excludeIndex, returnValue.length);
    }

    return returnValue;
  }
}
