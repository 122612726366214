import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';


@Component({
  selector: 'nexnox-web-content-container-infinite-scroll',
  templateUrl: './cc-infinite-scroll.component.html',
  styles: ['.infinite-scroll-loading-icon { height: 50px; }'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CorePortalContentContainerInfiniteScrollComponent {
  @Input() public loading: boolean;
  @Input() public error: boolean;
  @Input() public finished: boolean;
  @Input() public disabled= false;

  @Output() public loadMore: EventEmitter<boolean> = new EventEmitter<boolean>();

  public onVisibleChange(visible: boolean): void {
    if (visible && this.isValid()) {
      this.loadMore.emit();
    }
  }

  public onReload(): void {
    if (this.isValid()) {
      this.loadMore.emit();
    }
  }

  private isValid(): boolean {
    return !this.disabled && !this.loading && !this.finished && !this.error;
  }
}
