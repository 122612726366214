import { ChangeDetectionStrategy, Component, Injector } from '@angular/core';
import { CorePortalEntityEditBaseComponent, CorePortalFormlyReadonlyTypes, CorePortalFormlyReadonlyTyping } from '@nexnox-web/core-portal';
import { InventoryNumberPatternDto } from '@nexnox-web/core-shared';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { isNumber } from 'lodash';

@Component({
  selector: 'nexnox-web-settings-inventory-number-patterns-inventory-number-pattern-edit',
  templateUrl: './inventory-number-pattern-edit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CorePortalFeatureInventoryNumberPatternEditComponent extends CorePortalEntityEditBaseComponent<InventoryNumberPatternDto> {
  constructor(
    protected injector: Injector
  ) {
    super(injector);
  }

  /* istanbul ignore next */
  protected createForm(): FormlyFieldConfig[] {
    return [
      {
        key: 'title',
        type: 'input',
        wrappers: ['core-portal-translated'],
        className: 'col-md-6',
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.title',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          },
          type: 'text'
        },
        expressionProperties: {
          'templateOptions.required': () => !this.readonly,
          'templateOptions.disabled': () => this.readonly
        },
        hideExpression: () => !this.creating
      },
      {
        key: 'externalId',
        type: 'core-portal-external-id',
        wrappers: ['core-portal-translated'],
        className: this.creating ? 'col-md-6' : 'col-md-12',
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.external-id',
          },
          titleKey: 'title',
          modelSubject: this.modelSubject
        },
        expressionProperties: {
          'templateOptions.disabled': () => this.readonly
        }
      },
      {
        key: 'prefix',
        type: 'input',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-6',
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.prefix'
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.BASIC
          } as CorePortalFormlyReadonlyTyping,
          type: 'text'
        },
        expressionProperties: {
          'templateOptions.disabled': () => this.readonly,
          'templateOptions.readonly': () => this.readonly
        }
      },
      {
        key: 'suffix',
        type: 'input',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-6',
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.suffix'
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.BASIC
          } as CorePortalFormlyReadonlyTyping,
          type: 'text'
        },
        expressionProperties: {
          'templateOptions.disabled': () => this.readonly,
          'templateOptions.readonly': () => this.readonly
        }
      },
      {
        key: 'length',
        type: 'input',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-4',
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.length',
            validationMessages: {
              positive: {
                key: 'core-portal.core.validation.min',
                args: { min: 1 }
              }
            }
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.BASIC
          } as CorePortalFormlyReadonlyTyping,
          type: 'number'
        },
        expressionProperties: {
          'templateOptions.disabled': () => this.readonly,
          'templateOptions.readonly': () => this.readonly
        },
        validators: {
          positive: ctrl => !isNumber(ctrl.value) ? true : ctrl.value >= 1,
        }
      },
      {
        key: 'minimum',
        type: 'input',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-4',
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.min',
            validationMessages: {
              positive: {
                key: 'core-portal.core.validation.min',
                args: { min: 0 }
              },
              max: {
                key: 'core-portal.core.validation.max',
                args: { max: null }
              }
            }
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.BASIC
          } as CorePortalFormlyReadonlyTyping,
          type: 'number'
        },
        expressionProperties: {
          'templateOptions.disabled': () => this.readonly,
          'templateOptions.readonly': () => this.readonly,
          'templateOptions.corePortalTranslated.validationMessages.max.args': () => ({ max: this.model?.maximum })
        },
        validators: {
          positive: ctrl => !isNumber(ctrl.value) ? true : ctrl.value >= 0,
          max: ctrl => !isNumber(ctrl.value) ? true : (isNumber(this.model?.maximum) ? ctrl.value <= this.model.maximum : true)
        },
        hooks: {
          onInit: field => this.updateFieldHook(field, ['maximum'])
        }
      },
      {
        key: 'maximum',
        type: 'input',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-4',
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.max',
            validationMessages: {
              positive: {
                key: 'core-portal.core.validation.min',
                args: { min: 0 }
              },
              min: {
                key: 'core-portal.core.validation.min',
                args: { min: null }
              }
            }
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.BASIC
          } as CorePortalFormlyReadonlyTyping,
          type: 'number'
        },
        expressionProperties: {
          'templateOptions.disabled': () => this.readonly,
          'templateOptions.readonly': () => this.readonly,
          'templateOptions.corePortalTranslated.validationMessages.min.args': () => ({ min: this.model?.minimum })
        },
        validators: {
          positive: ctrl => !isNumber(ctrl.value) || isNumber(this.model?.minimum) ? true : ctrl.value >= 0,
          min: ctrl => !isNumber(ctrl.value) ? true : (isNumber(this.model?.minimum) ? ctrl.value >= this.model.minimum : true)
        },
        hooks: {
          onInit: field => this.updateFieldHook(field, ['minimum'])
        }
      },

      {
        key: 'nextNumber',
        type: 'input',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-8',
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.next-number'
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.BASIC
          } as CorePortalFormlyReadonlyTyping,
          type: 'text',
          readonly: true
        },
        hideExpression: () => this.creating
      },
      {
        key: 'isExhausted',
        type: 'core-portal-switch',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-4',
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.is-exhausted'
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.BOOLEAN
          } as CorePortalFormlyReadonlyTyping,
          readonly: true
        },
        hideExpression: () => this.creating
      }
    ];
  }
}
