import {
  BaseXsStore, BaseXsStoreReducerTypes,
  EmptyAction,
  EntityXsStore,
  EntityXsStoreActions,
  entityXsStoreSetLoadingForAction,
  EntityXsStoreState,
  PropsAction
} from '@nexnox-web/core-store';
import { DocumentTemplateDto } from '@nexnox-web/core-shared';
import { createAction, props } from '@ngrx/store';
import { immerOn } from 'ngrx-immer/store';

export interface DocumentTemplateDetailXsStoreState extends EntityXsStoreState<DocumentTemplateDto> {
}

export interface DocumentTemplateDetailXsStorePreviewSuccessAction {
  uri: string;
}

export interface DocumentTemplateDetailXsStoreActions extends EntityXsStoreActions<DocumentTemplateDto> {
  preview: EmptyAction;
  previewSuccess: PropsAction<DocumentTemplateDetailXsStorePreviewSuccessAction>;
}

export class DocumentTemplateDetailXsStore extends EntityXsStore<DocumentTemplateDto> {
  public actions: DocumentTemplateDetailXsStoreActions;

  protected createActions(label: string): DocumentTemplateDetailXsStoreActions {
    return {
      ...super.createActions(label),

      preview: createAction(BaseXsStore.getType(label, 'Preview')),
      previewSuccess: createAction(
        BaseXsStore.getType(label, 'Preview success'),
        props<DocumentTemplateDetailXsStorePreviewSuccessAction>()
      )
    };
  }

  protected createReducerArray(
    initialState: DocumentTemplateDetailXsStoreState
  ): BaseXsStoreReducerTypes<DocumentTemplateDetailXsStoreState, DocumentTemplateDetailXsStoreActions>[] {
    return [
      ...super.createReducerArray(initialState),

      immerOn(this.actions.preview, draft => {
        draft.entityData = entityXsStoreSetLoadingForAction(draft.entityData, {
          preview: true
        });
      }),

      immerOn(this.actions.previewSuccess, draft => {
        draft.entityData = entityXsStoreSetLoadingForAction(draft.entityData, {
          preview: false
        });
      })
    ];
  }
}
