import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  QueryList,
  Renderer2,
  ViewChild,
  ViewChildren
} from '@angular/core';
import {faPlus} from '@fortawesome/free-solid-svg-icons/faPlus';
import {AppPermissions, CustomPropertyType, TenantInfoDto} from '@nexnox-web/core-shared';
import {Observable, of} from 'rxjs';
import {CorePortalCardboxAction} from '@nexnox-web/core-portal';
import {faArrowUp} from '@fortawesome/free-solid-svg-icons/faArrowUp';
import {faArrowDown} from '@fortawesome/free-solid-svg-icons/faArrowDown';
import {faTrashAlt} from '@fortawesome/free-solid-svg-icons/faTrashAlt';
import {CustomPropertyEditComponent} from '../custom-property-edit/custom-property-edit.component';
import {cloneDeep, maxBy, minBy} from 'lodash';
import {
  CustomPropertySetType,
  LocalCustomPropertyDto,
  LocalCustomPropertySetDto,
  LocalCustomSetReferenceDto
} from '../../models';
import {BindObservable} from 'bind-observable';
import {map, switchMap} from 'rxjs/operators';
import {cleanUpPropertySetPositions} from "../../functions";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'nexnox-web-settings-stereotypes-custom-property-set-edit',
  templateUrl: './custom-property-set-edit.component.html',
  styleUrls: ['./custom-property-set-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomPropertySetEditComponent implements AfterViewInit {
  @Input() public customSetReference: LocalCustomSetReferenceDto;
  @Input() public customPropertySet: LocalCustomPropertySetDto;
  @Input() public customPropertyDropdownOptions: { label: string, value: CustomPropertyType }[];
  @Input() public customPropertySetType: CustomPropertySetType;

  @Input() @BindObservable() public readonly: boolean;
  public readonly$!: Observable<boolean>;

  @Input() @BindObservable() public isInherited: boolean;
  public isInherited$!: Observable<boolean>;

  @Input() public position: number;
  @Input() public isRateable: boolean;
  @Input() public activeTenant$: Observable<TenantInfoDto>;

  @Input() @BindObservable() public isFirst: boolean;
  public isFirst$!: Observable<boolean>;

  @Input() @BindObservable() public isLast: boolean;
  public isLast$!: Observable<boolean>;

  @Output() public modelChange: EventEmitter<LocalCustomPropertySetDto> = new EventEmitter<LocalCustomPropertySetDto>();
  @Output() public moveUp: EventEmitter<void> = new EventEmitter<void>();
  @Output() public moveDown: EventEmitter<void> = new EventEmitter<void>();
  @Output() public delete: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild('nameEditInput') public nameEditInput: ElementRef<HTMLInputElement>;

  @ViewChildren('customPropertyEditComponent') public customPropertyEditComponents: QueryList<CustomPropertyEditComponent>;

  /* istanbul ignore next */
  public headerActions: CorePortalCardboxAction[] = [
    {
      icon: faPlus,
      class: 'p-button-primary',
      tooltip: 'core-portal.settings.actions.create-custom-property',
      permission: AppPermissions.CreateCustomProperty,
      shouldShow: () => this.readonly$.pipe(
        switchMap(readonly => this.isInherited$.pipe(
          map(isInherited => !readonly && !isInherited)
        ))
      ),
      dropdownItems: () => of(this.customPropertyDropdownOptions.map(option => ({
        label: this.translate.instant(option.label),
        command: (event) => this.onAddProperty(option.value, event)
      }))),
    },
    {
      icon: faArrowUp,
      class: 'p-button-secondary',
      tooltip: 'core-shared.shared.actions.move-up',
      permission: AppPermissions.UpdateCustomPropertySet,
      shouldShow: () => this.readonly$.pipe(
        map(readonly => !readonly)
      ),
      isDisabled: () => this.isFirst$,
      callback: () => this.moveUp.emit()
    },
    {
      icon: faArrowDown,
      class: 'p-button-secondary',
      tooltip: 'core-shared.shared.actions.move-down',
      permission: AppPermissions.UpdateCustomPropertySet,
      shouldShow: () => this.readonly$.pipe(
        map(readonly => !readonly)
      ),
      isDisabled: () => this.isLast$,
      callback: () => this.moveDown.emit()
    },
    {
      icon: faTrashAlt,
      class: 'p-button-danger',
      tooltip: 'core-portal.settings.actions.delete-custom-property-set',
      permission: AppPermissions.DeleteCustomPropertySet,
      shouldShow: () => this.readonly$.pipe(
        switchMap(readonly => this.isInherited$.pipe(
          map(isInherited => !readonly && !isInherited)
        ))
      ),
      callback: () => this.delete.emit()
    }
  ];
  public trackPropertiesBy = (index: number, customProperty: LocalCustomPropertyDto): any =>
    customProperty.customPropertyId ?? customProperty.localId;

  constructor(
    private translate: TranslateService,
    private renderer: Renderer2
  ) {
  }

  public ngAfterViewInit(): void {
    this.scrollToNewPropertySet();
  }

  public onNameChange(name: string): void {
    this.modelChange.emit({...this.customPropertySet, name});
  }

  public onCustomPropertyChange(customProperty: LocalCustomPropertyDto, position: number): void {
    const newModel = cloneDeep(this.customPropertySet);
    const index = newModel.properties.findIndex(x => x.position === position);
    newModel.properties[index] = customProperty;
    this.modelChange.emit({
      ...this.customPropertySet,
      properties: newModel.properties
    });
  }

  public onAddProperty(propertyType: CustomPropertyType, event?: any): void {
    const newModel = cloneDeep(this.customPropertySet);

    if (!newModel.properties) {
      newModel.properties = [];
    }

    const maxIdProperty = newModel.properties.length ?
      maxBy(newModel.properties, x => x.customPropertyId ?? x.localId) : null;
    const localId = (maxIdProperty?.customPropertyId ?? (maxIdProperty?.localId ?? 0)) + 1;

    let newProperty: LocalCustomPropertyDto = {
      name: '',
      type: propertyType,
      localId,
      position: newModel.properties.length ? maxBy(newModel.properties, x => x.position).position + 1 : 1,
      defaultValues: []
    }
    newProperty = {
      ...newProperty,
      propertyAnchorId: this.getPropertyAnchorId(newProperty)
    };

    newModel.properties.push(newProperty);

    this.modelChange.emit(
      cleanUpPropertySetPositions({
        ...this.customPropertySet,
        properties: newModel.properties
      })
    );
  }

  public onMoveUp(position: number): void {
    const newModel = cloneDeep(this.customPropertySet);
    const index = newModel.properties.findIndex(x => x.position === position);
    const previousIndex = newModel.properties.findIndex(x => x.position === maxBy(
      newModel.properties.filter(y => y.position < position), y => y.position
    )?.position);

    newModel.properties[index].position--;
    newModel.properties[previousIndex].position++;
    this.modelChange.emit(
      cleanUpPropertySetPositions({
        ...this.customPropertySet,
        properties: newModel.properties
      })
    );
  }

  public onMoveDown(position: number): void {
    const newModel = cloneDeep(this.customPropertySet);
    const index = newModel.properties.findIndex(x => x.position === position);
    const nextIndex = newModel.properties.findIndex(x => x.position === minBy(
      newModel.properties.filter(y => y.position > position), y => y.position
    )?.position);

    newModel.properties[nextIndex].position--;
    newModel.properties[index].position++;
    this.modelChange.emit(
      cleanUpPropertySetPositions({
        ...this.customPropertySet,
        properties: newModel.properties
      })
    );
  }

  public onDelete(position: number): void {
    const newModel = cloneDeep(this.customPropertySet);
    const index = newModel.properties.findIndex(x => x.position === position);
    const nextIndexes = newModel.properties
      .filter(x => x.position > position)
      .map(x => newModel.properties.findIndex(y => y.position === x.position));

    newModel.properties.splice(index, 1);
    for (let i = 0; i < nextIndexes.length; i++) {
      newModel.properties[i].position--;
    }
    this.modelChange.emit(
      cleanUpPropertySetPositions({
        ...this.customPropertySet,
        properties: newModel.properties
      })
    );
  }

  public isModelValid(): boolean {
    if (this.readonly || this.isInherited) {
      return true;
    }

    return Boolean(this.nameEditInput?.nativeElement?.checkValidity()) && this.customPropertyEditComponents.toArray()
      .every(x => x.isModelValid());
  }

  private getPropertyAnchorId(customProperty: LocalCustomPropertyDto): string {
    return `${this.customSetReference.customPropertySetId ||
    (this.customSetReference as LocalCustomSetReferenceDto)?.localId}.${customProperty.customPropertyId || customProperty.localId}`;
  }

  private scrollToNewPropertySet(): void {
    if (this.customSetReference?.customSetReferenceAnchorId) {
      const element = document.getElementById(this.customSetReference.customSetReferenceAnchorId);
      if (element) {
        element?.scrollIntoView({
          behavior: 'smooth',
          block: 'end',
          inline: 'start'
        });
        if (this.nameEditInput) {
          this.renderer.selectRootElement(this.nameEditInput.nativeElement).focus();
        }
        this.customSetReference.customSetReferenceAnchorId = null;
      }
    }
  }

}
