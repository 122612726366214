import {
  InventoryNumberDetailStoreEffects,
  inventoryNumberDetailStoreReducer,
  InventoryNumberListStoreEffects,
  inventoryNumberListStoreReducer
} from './stores';
import { CorePortalFeatureInventoryNumberPatternService } from './services';

export * from './services';
export * from './stores';

export * from './inventory-numbers.state';
export * from './inventory-numbers.selector';

export const inventoryNumbersReducers = {
  inventoryNumberList: inventoryNumberListStoreReducer,
  inventoryNumberDetail: inventoryNumberDetailStoreReducer
};

export const inventoryNumbersEffects = [
  InventoryNumberListStoreEffects,
  InventoryNumberDetailStoreEffects
];

export const inventoryNumbersServices = [
  CorePortalFeatureInventoryNumberPatternService
];
