import { controlTagDetailStore, controlTagListStore } from './stores';

export * from './stores';

export * from './control-tags.state';
export * from './control-tags.selectors';

export const controlTagsReducers = {
  list: controlTagListStore.reducer,
  detail: controlTagDetailStore.reducer
};

export const controlTagsEffects = [
  controlTagListStore.effects,
  controlTagDetailStore.effects
];

export const controlTagsServices = [];
