<p-treeSelect #treeSelectComponent
              [ngModel]="value$ | async"
              [options]="treeSelectOptions$ | async"
              selectionMode="single"
              [disabled]="to.disabled"
              appendTo="body"
              (ngModelChange)="onValueChange($event)"
              (onNodeExpand)="onExpand($event)"
              (onShow)="onShow()">
  <ng-template pTemplate="value" let-node>
    <span *ngIf="node">{{ node.data }}</span>
  </ng-template>
</p-treeSelect>
