import { PagedEntitiesXsStore } from '@nexnox-web/core-store';
import { AppEntityType, ControlPointDto } from '@nexnox-web/core-shared';
import { createSelector } from '@ngrx/store';
import { selectControlPointsState } from '../../control-points.selectors';
import { CorePortalCCPControlPointService } from '@nexnox-web/core-portal';

/* istanbul ignore next */
export const controlPointListStore = new PagedEntitiesXsStore<ControlPointDto>({
  actionLabel: 'Tech Portal - CCP - Control Points - List',
  stateSelector: createSelector(selectControlPointsState, state => state.list),
  serviceType: CorePortalCCPControlPointService,
  entityType: AppEntityType.None,
  selectId: controlPoint => controlPoint.entity?.controlPointId ?? controlPoint.model?.controlPointId,
  stereotyped: false
});
