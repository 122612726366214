<ng-template #entityEditTemplate let-title="title" let-id="id">
  <nexnox-web-master-data-contacts-contact-edit #modelComponent
                                                [title]="title"
                                                [id]="id"
                                                [model]="model$ | async"
                                                [readonly]="readonly$ | async"
                                                [creating]="false"
                                                [loading]="loading$ | async"
                                                [stereotypes$]="stereotypes$"
                                                [isChangeMailAddressloading$]="isChangeMailAddressLoading$"
                                                (modelChange)="onModelChange($event)">
  </nexnox-web-master-data-contacts-contact-edit>
</ng-template>

<nexnox-web-entity-detail #entityDetailComponent
                          [entityDetailBaseComponent]="this"
                          [entityEditTemplate]="entityEditTemplate"
                          tabId="contact"
                          [tabHeading]="title | translate"
                          [tabCommands]="['.']">
  <ng-container *ngIf="(loading$ | async) === false">
    <div *ngIf="(model$ | async).access?.isLockedOut as isLockedOut" class="alert alert-warning">
      <fa-icon [icon]="faExclamationTriangle" [fixedWidth]="true"></fa-icon>
      {{ 'core-portal.master-data.contact.descriptions.is-locked-out' | translate }}
    </div>
  </ng-container>

  <!-- Areas Tab -->
  <ng-template nexnoxWebTabTemplate
               tabId="areas"
               [heading]="'core-portal.master-data.subtitles.contact-area-list' | translate"
               [lazy]="true"
               (selectTab)="onNavigateToTab(['areas'])">
    <nexnox-web-master-data-contacts-contact-area-list *ngIf="passedIds"
                                                       [isEmbedded]="true"
                                                       [parentIds]="passedIds"
                                                       [canCreate]="true"
                                                       [canRouteToDetail]="true"
                                                       [noRouteToDetail]="true"
                                                       [keepEditOnCreateSuccess]="true"
                                                       detailLink="masterdata/contacts/{{ passedIds[0] }}/areas"
                                                       [getEntityOnModeChange]="false">
    </nexnox-web-master-data-contacts-contact-area-list>
  </ng-template>

  <!-- Open Editor Requests Tab -->
  <ng-template nexnoxWebTabTemplate
               tabId="requests"
               [heading]="'core-portal.master-data.subtitles.contact-open-requests' | translate"
               [lazy]="true"
               (selectTab)="onNavigateToTab(['requests'])">
    <nexnox-web-master-data-contacts-contact-open-request-list *ngIf="passedIds"
                                                               [isEmbedded]="true"
                                                               [parentIds]="passedIds"
                                                               [canRouteToDetail]="false"
                                                               [noRouteToDetail]="true"
                                                               [keepEditOnCreateSuccess]="false"
                                                               [getEntityOnModeChange]="false">
    </nexnox-web-master-data-contacts-contact-open-request-list>
  </ng-template>

  <!-- Links -->
  <ng-template nexnoxWebTabTemplate
               tabId="links"
               [heading]="('tech-portal.subtitles.links' | translate) + (linksComponent ? (linksComponent.linksString$ | async) : '')"
               [hasError]="linksComponent ? !linksComponent.isModelValid() : false"
               (selectTab)="onNavigateToTab(['links'])">
    <nexnox-web-tech-links *ngIf="model$ | async as model"
                           #linksComponent
                           [links$]="links$"
                           [entityId]="model.contactId"
                           [type]="linkedElementTypes.Contact"
                           [readonly]="readonly$ | async"
                           [loading]="loading$ | async"
                           (linksChange)="onLinksChange($event, model)">
    </nexnox-web-tech-links>
  </ng-template>
</nexnox-web-entity-detail>

<nexnox-web-master-data-contacts-force-password-sidebar #forcePasswordSidebarComponent>
</nexnox-web-master-data-contacts-force-password-sidebar>
