import { DatatableTableColumnType, DatatableTableEnumOption } from '../';
import { TemplateRef, Type } from '@angular/core';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { CoreSharedApiBaseService, FilterDto } from '@nexnox-web/core-shared';
import { Observable } from 'rxjs';
import { SizeProp } from '@fortawesome/fontawesome-svg-core';

export enum DatatableTableColumnOption {
  DATA,
  STEREOTYPE,
  OPTIONAL
}

export interface DatatableTableColumnIcon {
  icon: IconDefinition;
  iconFn?: (value: any) => IconDefinition;
  color: (value: any) => string;
  size: SizeProp;
  margin?: number,
  show?: (value: any) => boolean;
}

export interface DatatableTableColumn {
  minWidth?: number;
  maxWidth?: number;
  padding?: number;
  width?: number;
  minHeight?: number;
  maxHeight?: number;
  height?: number;
  name?: string;
  prop?: string | number;
  cellTemplate?: TemplateRef<any>;
  sortable?: boolean;
  hasFilter?: boolean;
  noFilter?: boolean;
  disableFilter?: boolean;
  translate?: boolean;
  type?: DatatableTableColumnType;
  option?: DatatableTableColumnOption;
  enumOptions?: DatatableTableEnumOption[];
  customPropertyId?: number;
  utc?: boolean;
  link?: string | ((value: any) => string);
  withoutModule?: boolean;
  module?: string;
  fragment?: string;
  idKey?: string;
  displayKey?: string;
  multiple?: boolean;
  separator?: string;
  mapValues?: (values: any[]) => any[];
  template?: (value: any) => string;
  additionalSearchProperties?: string[];
  service?: Type<CoreSharedApiBaseService>;
  filterProp?: string;
  alternateFilter?: {
    idKey?: string;
    displayKey?: string;
    service?: Type<CoreSharedApiBaseService>;
    filters$?: Observable<FilterDto[]>;
  },
  prependIcon?: DatatableTableColumnIcon;
  position?: number;
  optional?: boolean;
  filters$?: Observable<FilterDto[]>;
  format?: string;
  hideByDefault?: boolean;
  trueLabel?: string;
  falseLabel?: string;
  isOfArchivedStereotype?: boolean;
  clickable?: boolean;

  // Reference Filter
  labelTitleTemplateKey?: string;
  optionTitleTemplateKey?: string;
  joinSymbol?: string;

  // Progress Bar
  totalKey?: string;
}
