<nexnox-web-cardbox [title]="'core-shared.shared.fields.successors' | translate"
                    [loading]="loading"
                    [transparent]="true"
                    [headerActions]="headerActions"
                    [isAccordion]="true"
                    [isCollapsed]="true"                    
                    [indicatorNum]="(successors$ | async)?.length"
                    [isAutoExpandOnIndicator]="true"
                    paddingClasses="px-3">
  <div *ngIf="hasSuccessors$ | async; else emptyTemplate"
       class="d-flex flex-column">
    <div *ngFor="let successor of successors$ | async"
         class="d-flex flex-column mb-3">
      <div class="d-flex flex-row">
        <fa-icon [icon]="faClipboardCheck"
                 [fixedWidth]="true"
                 size="sm"
                 class="mr-2"></fa-icon>

        <a class="btn-link"
           [nexnoxWebTenantRouterLink]="['/missions', successor.missionId]"
           module="inventory"
           target="_blank">
          {{ successor.title }}
        </a>
      </div>
    </div>
  </div>

  <ng-template #emptyTemplate>
    <div class="my-3">{{ 'core-shared.shared.table.empty' | translate }}</div>
  </ng-template>
</nexnox-web-cardbox>

<nexnox-web-missions-create-mission-successors-sidebar #createMissionSuccessorsSidebarComponent
                                                       (successorsAdded)="onAddSuccessors($event)">
</nexnox-web-missions-create-mission-successors-sidebar>
