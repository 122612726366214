import { EntityXsStoreState, PagedEntitiesXsStoreState } from '@nexnox-web/core-store';
import { ControlPointDto } from '@nexnox-web/core-shared';
import { controlPointDetailStore, controlPointListStore } from './stores';

export interface ControlPointsState {
  list: PagedEntitiesXsStoreState<ControlPointDto>;
  detail: EntityXsStoreState<ControlPointDto>;
}

export const getInitialControlPointsState = (): ControlPointsState => ({
  list: controlPointListStore.getInitialState(),
  detail: controlPointDetailStore.getInitialState()
});
