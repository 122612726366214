<nexnox-web-cardbox [title]="'controls.fields.notifications' | translate"
                    [loading]="loading"
                    paddingClasses="px-3 pb-3">

    <div *ngIf="!readonly"
         class="mb-3">
        <form [formGroup]="notificationForm">
            <formly-form class="row w-100"
                         [form]="notificationForm"
                         [model]="notificationModelSubject.asObservable() | async"
                         [fields]="notificationFields"
                         (modelChange)="notificationModelSubject.next($event)">
            </formly-form>
        </form>

        <div class="d-flex justify-content-end w-100">
            <button *ngIf="isEditingNotification$ | async"
                    type="button"
                    pButton
                    class="p-button-secondary p-button-outlined mr-2"
                    [title]="'core-portal.core.general.cancel' | translate"
                    [label]="'core-portal.core.general.cancel' | translate"
                    (click)="onCancelEdit()">
            </button>
            <button type="button"
                    pButton
                    class="p-button-secondary p-button-outlined mr-2"
                    [icon]="((isEditingNotification$ | async) ?  faCheck : faPlus)  | nexnoxWebFaIconStringPipe"
                    [title]="((isEditingNotification$ | async) ? 'core-shared.shared.actions.accept' : 'core-shared.shared.actions.add') | translate"
                    [label]="((isEditingNotification$ | async) ? 'core-shared.shared.actions.accept' : 'core-shared.shared.actions.add') | translate"
                    [disabled]="notificationForm.invalid"
                    (click)="handleNotificationAction()">
            </button>
        </div>
    </div>

    <div class="pt-2 px-2 pb-3" *ngIf="tableItems$ | async as items">
        <nexnox-web-basic-table [config]="tableConfig"
                                [data]="items">
        </nexnox-web-basic-table>
    </div>

</nexnox-web-cardbox>
