import { ChangeDetectionStrategy, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { CoreSharedSidebarBaseComponent } from '@nexnox-web/core-shared';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import { CorePortalFormlyTranslatedTyping } from '@nexnox-web/core-portal';

@Component({
  selector: 'nexnox-web-forms-create-form-row-sidebar',
  templateUrl: './create-form-row-sidebar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CreateFormRowSidebarComponent extends CoreSharedSidebarBaseComponent implements OnInit {
  @Output() public createFormRow: EventEmitter<string> = new EventEmitter<string>();

  public form: FormGroup;
  public model: { title: string };
  public fields: FormlyFieldConfig[];

  public faPlus = faPlus;

  constructor() {
    super();
  }

  public ngOnInit(): void {
    this.form = new FormGroup({});
    this.model = {} as any;
    this.fields = this.createForm();
  }

  public onCreateFormRow(): void {
    this.createFormRow.emit(this.model.title);
    this.onHide();
  }

  public onHide(): void {
    super.onHide();

    this.form.reset();
    this.form.markAsPristine();
  }

  /* istanbul ignore next */
  private createForm(): FormlyFieldConfig[] {
    return [
      {
        key: 'title',
        type: 'input',
        wrappers: ['core-portal-translated'],
        className: 'col-md-12',
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.title',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          } as CorePortalFormlyTranslatedTyping,
          type: 'text',
          required: true
        }
      }
    ];
  }
}
