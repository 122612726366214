<p-sidebar #sidebarComponent
           position="right"
           appendTo="body"
           styleClass="p-sidebar-md"
           [transitionOptions]="transitionOptions"
           [visible]="visible$ | async"
           (visibleChange)="onVisibleChange($event)">
  <h4>{{ 'missions.subtitles.editor-suggestions-current-editor' | translate }}</h4>
  <div class="d-flex flex-row mb-4">
    <ng-container *ngIf="currentSolutionContact; else noSolutionContactTemplate">
      <fa-icon [icon]="faUser"
               [fixedWidth]="true"
               size="sm"
               class="mr-2"></fa-icon>
      <a class="btn-link"
         [nexnoxWebTenantRouterLink]="['/masterdata/contacts', currentSolutionContact.contactId]"
         module="management"
         target="_blank">
        {{ currentSolutionContact.displayName }}
      </a>
    </ng-container>
    <ng-template #noSolutionContactTemplate>
      {{ 'core-shared.shared.select.empty' | translate }}
    </ng-template>
  </div>

  <ng-container *ngIf="canAssign$">
    <h4 class="mt-4">{{'missions.subtitles.new-solution-contact' | translate}}</h4>
    <div class="row w-100">
      <form [formGroup]="form" class="w-100">
        <formly-form class="row w-100"
                     [form]="form"
                     [(model)]="model"
                     [fields]="fields">
        </formly-form>
      </form>
    </div>
  </ng-container>

  <div class="w-100 mt-2">
    <button *ngIf="canUnassign$ | async"
            pButton
            type="button"
            class="p-button p-button-secondary mr-3"
            [label]="'core-shared.shared.actions.unassign-selection'  | translate"
            [icon]="faTimes | nexnoxWebFaIconStringPipe"
            (click)="onUnassignContact()">
    </button>

    <button *ngIf="canAssignMe$ | async"
            pButton
            type="button"
            class="p-button p-button-secondary mr-3"
            [label]="'core-shared.shared.actions.assign-me' | translate"
            [icon]="faUser | nexnoxWebFaIconStringPipe"
            (click)="onAssignMe()">
    </button>

    <button *ngIf="canAssign$ | async"
            pButton
            type="button"
            class="p-button p-button-primary"
            [label]="'core-shared.shared.actions.assign'  | translate"
            [icon]="faUser | nexnoxWebFaIconStringPipe"
            [disabled]="!form.valid"
            (click)="onAssignContact()">
    </button>
  </div>
</p-sidebar>
