import { Injectable, Injector } from '@angular/core';
import { AttachmentForTechDto, CoreSharedApiBaseService, Product, SolutionAttachmentDto } from '@nexnox-web/core-shared';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class TechPortalFeatureSolutionService extends CoreSharedApiBaseService {
  constructor(
    protected injector: Injector
  ) {
    super(injector, Product.TECH, 'solution');
  }

  public addAttachment(id: number | string, attachment: SolutionAttachmentDto): Observable<SolutionAttachmentDto> {
    return this.httpRequestService.post<SolutionAttachmentDto>(`${this.buildPathForOne(id)}/attachment`, attachment).pipe(
      map(response => response.body)
    );
  }

  public deleteAttachment(id: number | string, attachment: AttachmentForTechDto): Observable<any> {
    return this.httpRequestService.delete(`${this.buildPathForOne(id)}/attachment`, attachment.attachmentId).pipe(
      map(response => response.body)
    );
  }
}
