import { ResourceUIEntitiesXsStore, ResourceUIEntitiesXsStoreState } from './resource-ui-entities.xs-store';
import { Action, createSelector } from '@ngrx/store';
import { selectResourcesState } from '../../resources.selectors';
import { Injectable, Injector } from '@angular/core';

export interface ResourceUIEntitiesStoreState extends ResourceUIEntitiesXsStoreState {
}

export const resourceUIEntitiesStore = new ResourceUIEntitiesXsStore({
  actionLabel: 'Core Portal - Resources - Resource UI Entities',
  stateSelector: createSelector(selectResourcesState, state => state.resourceUIEntities)
});

export function resourceUIEntitiesStoreReducer(state: ResourceUIEntitiesStoreState, action: Action): any {
  return resourceUIEntitiesStore.reducer(state, action);
}

@Injectable()
export class ResourceUIEntitiesStoreEffects extends resourceUIEntitiesStore.effects {
  constructor(
    protected injector: Injector
  ) {
    super(injector);
  }
}
