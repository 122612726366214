<div *ngIf="!loading" class="d-flex flex-nowrap ml-2">
  <p-tieredMenu #menu
                [popup]="true"
                [model]="menuItems$ | async"
                appendTo="body">
  </p-tieredMenu>

  <button *ngIf="(menuItems$ | async)?.length"
          type="button"
          pButton
          [icon]="menuIcon$ | async"
          class="p-button-text p-button-secondary p-ripple p-button-icon-only p-button-sm mr-1"
          [disabled]="isMenuItemLoading$ | async"
          (click)="menu.toggle($event)">
  </button>

  <button *ngFor="let menuItem of isolatedMenuItems$ | async"
          type="button"
          pButton
          [title]="menuItem.label"
          [icon]="menuItem.icon | nexnoxWebFaIconStringPipe"
          [class]="'p-button-text p-ripple p-button-icon-only p-button-sm mr-1 ' + menuItem.styleClass"
          [disabled]="menuItem.disabled"
          (click)="menuItem.command ? menuItem.command() : undefined">
  </button>
</div>
