<ng-template #entityEditTemplate let-title="title">
  <nexnox-web-connected-error-codes-error-code-edit #editComponent
                                                    [title]="title"
                                                    [model]="createModel$ | async"
                                                    [readonly]="false"
                                                    [creating]="true"
                                                    [loading]="(loaded$ | async) === false"
                                                    [stereotyped]="false"
                                                    (modelChange)="createModel$.next($event)">
  </nexnox-web-connected-error-codes-error-code-edit>
</ng-template>

<nexnox-web-entity-overview #overviewComponent
                            [entityOverviewBaseComponent]="this"
                            [entityEditTemplate]="entityEditTemplate"
                            detailLink="connected/error-codes"
                            module="settings"
                            [stereotyped]="false">
</nexnox-web-entity-overview>
