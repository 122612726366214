import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChildren
} from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { CoreSharedSortableListItem } from '@nexnox-web/core-shared';
import { map, startWith, switchMap } from 'rxjs/operators';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { FormGroup } from '@angular/forms';
import { cloneDeep, flatten, isNumber, mapValues, maxBy, sortBy } from 'lodash';
import { CoreSharedRuleEditorListItemComponent } from '..';
import { TranslateService } from '@ngx-translate/core';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons/faInfoCircle';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons/faExclamationTriangle';

export interface InheritedField {
  formlyConfig: Partial<FormlyFieldConfig>;
  inheritedKey?: string;
  ownValueKey: string;
  inheritedValueKey?: string;
  disable?: (item: any, creating: boolean, disabled: boolean) => boolean;
  hide?: (value: any, creating: boolean, model?: any) => boolean;
  additionalFields?: FormlyFieldConfig[];
  noPR?: boolean;
  smartExpressionProperties?: {
    [property: string]: ((model: any, formState: any, creating: boolean, field?: FormlyFieldConfig) => any)
  };
}

@Component({
  selector: 'nexnox-web-rule-editor-list',
  templateUrl: './rule-editor-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CoreSharedRuleEditorListComponent implements OnInit {
  @Input() public identifier: string | ((item: any) => any);
  @Input() public title: string | ((item: any) => string);
  @Input() public inheritedKey: string;
  @Input() public inheritedFieldsFn: () => InheritedField[];
  @Input() public disabled: boolean;
  @Input() public asCards: boolean;
  @Input() public sortable = false;
  @Input() public sortKey: string;
  @Input() public imageKey: string;
  @Input() public canSelectFn: (item: any) => boolean;
  @Input() public selectFn: (item: any) => void;
  @Input() public updateOnLanguageChange: boolean;
  @Input() public maxItems: number;

  public get items(): any[] {
    return this.itemsSubject.getValue();
  }

  @Input()
  public set items(items: any[]) {
    this.itemsSubject.next(items);
  }

  @ViewChildren('ruleEditorListItemComponent') public ruleEditorListItemComponents: QueryList<CoreSharedRuleEditorListItemComponent>;

  @Output() public itemsChange: EventEmitter<any[]> = new EventEmitter<any[]>();

  public items$: Observable<CoreSharedSortableListItem[]>;

  public tooManyItems$: Observable<boolean>;
  public tooManyItemsError$: Observable<boolean>;

  public form: FormGroup;
  public fields: FormlyFieldConfig[];
  public model: any;

  public faInfoCircle = faInfoCircle;
  public faExclamationTriangle = faExclamationTriangle;

  private itemsSubject: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);

  constructor(
    public changeDetector: ChangeDetectorRef,
    private translate: TranslateService
  ) {
  }

  public ngOnInit(): void {
    this.items$ = this.itemsSubject.asObservable().pipe(
      switchMap(items => {
        return (this.updateOnLanguageChange ? this.translate.onLangChange.asObservable().pipe(map(() => true)) : of(true))
          .pipe(
            startWith(this.translate.currentLang),
            map(() => items.map((item, index) => ({
              title: this.title ? (typeof this.title === 'string' ? item[this.title] : this.title(item)) : null,
              position: this.sortKey ? item[this.sortKey] : index,
              deletable: this.inheritedKey ? !item[this.inheritedKey] : true,
              getExternalData: () => ({ ...item, index })
            })) as CoreSharedSortableListItem[])
          );
      })
    );

    this.tooManyItems$ = this.items$.pipe(
      map(items => (items ?? []).length >= (this.maxItems ?? Infinity))
    );

    this.tooManyItemsError$ = this.items$.pipe(
      map(items => (items ?? []).length > (this.maxItems ?? Infinity))
    );

    this.form = new FormGroup({});
    this.fields = this.createForm();
    this.model = {};
  }

  public onAddItem(): void {
    const item = cloneDeep(this.model);
    this.form.reset();
    this.form.markAsPristine();
    this.form.markAsUntouched();

    if (this.sortable && this.sortKey) {
      const previousItem = maxBy(this.itemsSubject.getValue(), item => item[this.sortKey]);
      item[this.sortKey] = previousItem && isNumber(previousItem[this.sortKey]) ? previousItem[this.sortKey] + 1 : 0;
    }

    this.itemsSubject.next([...this.itemsSubject.getValue(), item]);
    this.itemsChange.emit(this.itemsSubject.getValue());
  }

  public onItemsChange(items: CoreSharedSortableListItem[]): void {
    const externalItems = sortBy(items, item => item.position).map(item => {
      const { index, ...externalData } = item.getExternalData();

      if (this.sortable && this.sortKey) {
        externalData[this.sortKey] = item.position;
      }

      return externalData;
    });

    this.itemsSubject.next(externalItems);
    this.itemsChange.emit(externalItems);
  }

  public onItemChange(index: number, item: any): void {
    const items = cloneDeep(this.itemsSubject.getValue());
    const { index: _, ...itemData } = item;
    items[index] = cloneDeep(itemData);

    this.itemsSubject.next(items);
    this.itemsChange.emit(items);
  }

  public isModelValid(): boolean {
    return this.form.valid;
  }

  public isPristine(): boolean {
    return this.form.pristine;
  }

  public areItemsValid(): boolean {
    const items = (this.ruleEditorListItemComponents?.toArray() ?? []);
    return items.length <= (this.maxItems ?? Infinity) && items.every(item => item.isModelValid());
  }

  /* istanbul ignore next */
  private createForm(): FormlyFieldConfig[] {
    const inheritedFields = this.inheritedFieldsFn();

    return flatten(inheritedFields.map((field, index) => ([
      {
        ...field.formlyConfig,
        key: field.ownValueKey,
        className: field.formlyConfig.className ?? `col-12 col-md pr-0 pl-0 ${index > 0 ? 'pl-md-3' : ''}`,
        templateOptions: {
          ...(field.formlyConfig.templateOptions ?? {}),
          creating: true
        },
        expressionProperties: {
          ...(field.formlyConfig.expressionProperties ?? {}),
          'templateOptions.disabled': () => field.disable ? field.disable(this.model, true, this.disabled) : this.disabled,
          ...(mapValues(
            field.smartExpressionProperties,
            property => (model, formState, field) => property(model, formState, true, field)
          ))
        },
        hideExpression: () => field.hide ? field.hide(this.model[field.ownValueKey], true, this.model) : false
      },
      ...(field.additionalFields ?? []).map(additionalField => ({
        hideExpression: () => additionalField?.templateOptions?.ruleEditorListHide ?
          additionalField.templateOptions.ruleEditorListHide(this.model[field.ownValueKey], true) : false,
        ...additionalField
      }))
    ])));
  }
}
