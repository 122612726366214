import { AddressCompanyService, AddressLocationService, CorePortalFeatureMasterDataAddressService } from './services';
import {
  AddressCompanyListStoreEffects,
  addressCompanyListStoreReducer,
  AddressDetailStoreEffects,
  addressDetailStoreReducer,
  AddressListStoreEffects,
  addressListStoreReducer,
  AddressLocationListStoreEffects,
  addressLocationListStoreReducer
} from './stores';

export * from './stores';
export * from './services';

export * from './addresses.state';
export * from './addresses.selectors';

export const addressesReducers = {
  addressDetail: addressDetailStoreReducer,
  addressList: addressListStoreReducer,
  addressLocationList: addressLocationListStoreReducer,
  addressCompanyList: addressCompanyListStoreReducer
};

export const addressesEffects = [
  AddressDetailStoreEffects,
  AddressListStoreEffects,
  AddressLocationListStoreEffects,
  AddressCompanyListStoreEffects
];

export const addressesServices = [
  CorePortalFeatureMasterDataAddressService,
  AddressCompanyService,
  AddressLocationService
];
