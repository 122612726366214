import {ChangeDetectionStrategy, Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormGroup, Validators} from '@angular/forms';
import {faPlus} from '@fortawesome/free-solid-svg-icons/faPlus';
import {CorePortalFormlyTranslatedTyping} from '@nexnox-web/core-portal';
import {CoreSharedSidebarBaseComponent, SystemContactDto} from '@nexnox-web/core-shared';
import {FormlyFieldConfig} from '@ngx-formly/core';

@Component({
  selector: 'nexnox-web-service-tenants-tenant-create-systemcontact-sidebar',
  templateUrl: './create-systemcontact-sidebar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CreateSystemContactSidebarComponent extends CoreSharedSidebarBaseComponent implements OnInit {

  @Output() public createSystemContact: EventEmitter<SystemContactDto> = new EventEmitter<SystemContactDto>();

  public form: FormGroup;
  public model: SystemContactDto;
  public fields: FormlyFieldConfig[];

  public faPlus = faPlus;

  constructor() {
    super();
  }

  public ngOnInit(): void {
    this.form = new FormGroup({});
    this.model = {} as SystemContactDto;
    this.fields = this.createForm();
  }

  public onCreateSystemContact(): void {
    // Nulled propertys will be set by server
    this.model.timeZone = null;
    this.model.culture = null;

    // Emit & hide
    this.createSystemContact.emit(this.model);
    this.onHide();
  }

  public onHide(): void {
    super.onHide();
    this.ngOnDestroy();
    this.ngOnInit();
  }

  /* istanbul ignore next */
  private createForm(): FormlyFieldConfig[] {
    return [
      {
        key: 'firstName',
        type: 'input',
        wrappers: ['core-portal-translated'],
        className: 'col-md-6',
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.first-name',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          },
          type: 'text',
          change: () => this.adminFirstNameOrLastNameChange()
        },
        expressionProperties: {
          'templateOptions.required': () => true
        }
      },
      {
        key: 'lastName',
        type: 'input',
        wrappers: ['core-portal-translated'],
        className: 'col-md-6',
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.last-name',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          },
          type: 'text',
          change: () => this.adminFirstNameOrLastNameChange()
        },
        expressionProperties: {
          'templateOptions.required': () => true
        }
      },
      {
        key: 'displayName',
        type: 'input',
        wrappers: ['core-portal-translated'],
        className: 'col-md-12',
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.display-name',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          },
          type: 'text'
        },
        expressionProperties: {
          'templateOptions.required': () => true,
        }
      },
      {
        key: 'emailAddress',
        type: 'input',
        wrappers: ['core-portal-translated'],
        className: 'col-md-12',
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.email-address',
            validationMessages: {
              required: 'core-portal.core.validation.required',
              email: 'core-portal.core.validation.email'
            }
          } as CorePortalFormlyTranslatedTyping,
          required: true,
          type: 'email'
        },
        validators: {
          email: ctrl => Validators.email(ctrl) ? null : { email: true }
        }
      }
    ];
  }

  private adminFirstNameOrLastNameChange(): void {
    const model = this.model;

    const firstName = model && model?.firstName ? model.firstName : '';
    const lastName = model && model?.lastName ? model.lastName : '';

    this.model = {
      ...model,
      displayName: `${firstName} ${lastName}`
    };
  }
}
