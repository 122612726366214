import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {DatatableActionButton} from '../../models';
import {EntityData} from '../../../models';
import {BehaviorSubject, from, Observable, of} from 'rxjs';
import {catchError, concatMap, distinctUntilChanged, last, map, mergeMap, scan, startWith, take} from 'rxjs/operators';
import {MenuItem} from 'primeng/api';
import {TranslateService} from '@ngx-translate/core';
import {CorePortalPermissionService} from '../../../../../services';
import {cloneDeep, isEqual, isNull} from 'lodash';
import {CorePortalTenantRouter} from '../../../../../router-overrides';
import {IconDefinition} from '@fortawesome/fontawesome-common-types';
import {faEllipsisV} from '@fortawesome/free-solid-svg-icons/faEllipsisV';
import {faSpinner} from '@fortawesome/free-solid-svg-icons/faSpinner';
import {NexnoxWebFaIconString} from '@nexnox-web/core-shared';
import {faTrashAlt} from '@fortawesome/free-solid-svg-icons/faTrashAlt';
import {Router} from "@angular/router";

export type CustomMenuItem = MenuItem & {
  isolate?: boolean;
  loading?: boolean;
};

@Component({
  selector: 'nexnox-web-entity-datatable-last-column-cell',
  templateUrl: './entity-datatable-last-column-cell.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CorePortalEntityDatatableLastColumnCellComponent {
  @Input() public row: any;
  @Input() public loading: boolean;
  @Input() public canDelete: boolean;
  @Input() public idProp: string;

  public get actionButtons(): DatatableActionButton[] {
    return this.actionButtonsSubject.getValue();
  }

  @Input()
  public set actionButtons(actionButtons: DatatableActionButton[]) {
    this.actionButtonsSubject.next(cloneDeep(actionButtons));
  }

  public get entityData(): EntityData {
    return this.entityDataSubject.getValue();
  }

  @Input()
  public set entityData(entityData: EntityData) {
    this.entityDataSubject.next(entityData);
  }

  @Output() public delete: EventEmitter<any> = new EventEmitter<any>();

  public allMenuItems$: Observable<CustomMenuItem[]>;
  public menuItems$: Observable<CustomMenuItem[]>;
  public isMenuItemLoading$: Observable<boolean>;
  public isolatedMenuItems$: Observable<CustomMenuItem[]>;
  public menuIcon$: Observable<IconDefinition | string>;

  private entityDataSubject: BehaviorSubject<EntityData> = new BehaviorSubject<EntityData>(null);
  private actionButtonsSubject: BehaviorSubject<DatatableActionButton[]> = new BehaviorSubject<DatatableActionButton[]>([]);

  constructor(
    private translate: TranslateService,
    private permissionService: CorePortalPermissionService,
    private tenantRouter: CorePortalTenantRouter,
    private router: Router
  ) {
    this.allMenuItems$ = this.actionButtonsSubject.asObservable().pipe(
      distinctUntilChanged((previous, current) => !isEqual(previous, current)),
      mergeMap(actionButtons => this.translate.onLangChange.asObservable().pipe(
        startWith(null),
        mergeMap(() => {
          if (!actionButtons?.length) {
            return of(this.getDefaultMenuItems());
          }

          return from(actionButtons).pipe(
            concatMap(actionButton => from(actionButton?.permissions ?? [null]).pipe(
              mergeMap(permission =>
                !isNull(permission) ? this.permissionService.hasPermission$(permission).pipe(take(1)) : of(true)
              ),
              scan((all, current) => [...all, current], []),
              last(),
              mergeMap(permissions => from(this.mapActionButtonToMenuItem(actionButton, permissions)))
            )),
            scan((all, current) => [...all, current], []),
            last(),
            map(mappedActionButtons => ([
              ...mappedActionButtons.filter(x => Boolean(x)),
              ...this.getDefaultMenuItems()
            ].filter((menuItem: MenuItem) => menuItem.visible))),
            catchError(error => {
              console.error(error);
              return of(this.getDefaultMenuItems());
            })
          );
        }),

        mergeMap((menuItems: MenuItem[]) => this.entityDataSubject.asObservable().pipe(
          distinctUntilChanged(),
          map(entityData => menuItems.map(menuItem => {
            let isLoading = false;

            if (entityData && entityData[this.row[this.idProp]]?.loading && menuItem.id) {
              const loadingData = entityData[this.row[this.idProp]].loading;
              isLoading = loadingData.hasOwnProperty(menuItem.id) && loadingData[menuItem.id];
            }

            return {
              ...menuItem,
              loading: isLoading,
              disabled: menuItem.disabled || isLoading,
              icon: !isLoading ? menuItem.icon : NexnoxWebFaIconString.transformIcon(faSpinner, 'fa-spin')
            };
          }))
        ))
      ))
    );

    this.menuItems$ = this.allMenuItems$.pipe(
      map(menuItems => menuItems.filter(x => !x.isolate))
    );

    this.isMenuItemLoading$ = this.menuItems$.pipe(
      map(menuItems => menuItems.some(x => x.loading))
    );

    this.isolatedMenuItems$ = this.allMenuItems$.pipe(
      map(menuItems => menuItems.filter(x => x.isolate))
    );

    this.menuIcon$ = this.isMenuItemLoading$.pipe(
      map(isMenuItemLoading => isMenuItemLoading ?
        NexnoxWebFaIconString.transformIcon(faSpinner, 'fa-spin') : NexnoxWebFaIconString.transformIcon(faEllipsisV))
    );
  }

  public onDelete(entity: any): void {
    this.delete.emit(entity);
  }

  public trackActionButtonsBy(index: number, actionButton: DatatableActionButton): string {
    return actionButton.id;
  }

  private getDefaultMenuItems(): MenuItem[] {
    return [
      {
        id: 'deleteOne',
        label: this.translate.instant('core-shared.shared.table.tooltip.delete'),
        visible: this.canDelete,
        disabled: !this.canDelete,
        command: () => this.onDelete(this.row),
        icon: NexnoxWebFaIconString.transformIcon(faTrashAlt)
      }
    ];
  }

  private async mapActionButtonToMenuItem(actionButton: DatatableActionButton, permissions: any[]): Promise<CustomMenuItem> {
    if (isNull(actionButton)) {
      return {visible: true, separator: true};
    }

    let isDisabled = actionButton.disabled ? await actionButton.disabled(this.row) : false;
    const shouldShow = actionButton.show ? await actionButton.show(this.row) : true;

    if (!permissions.every(permission => Boolean(permission))) {
      isDisabled = true;
    }

    const command = actionButton.onClick ? () => actionButton.onClick(this.row) : undefined;
    const url = actionButton.link && actionButton.target ?
      this.router.serializeUrl(this.tenantRouter.createUrlTree([actionButton.link(this.row)], {
        module: actionButton.module,
        fragment: actionButton.fragment ?? undefined
      })) : undefined;
    const routerLink = actionButton.link && !actionButton.target ?
      this.tenantRouter.createCommands([actionButton.link(this.row)], {
        module: actionButton.module
      }) : undefined;

    return {
      id: actionButton.id ?? undefined,
      routerLink,
      fragment: actionButton.fragment && !url ? actionButton.fragment : undefined,
      url,
      target: actionButton.target ?? undefined,
      command,
      styleClass: actionButton.style ?? undefined,
      label: actionButton.tooltip ? this.translate.instant(actionButton.tooltip) : undefined,
      icon: actionButton.icon ? NexnoxWebFaIconString.transformIcon(actionButton.icon) : undefined,
      visible: shouldShow,
      disabled: isDisabled,
      items: actionButton.buttons ?
        await Promise.all((actionButton.buttons(this.row) ?? []).map(x => this.mapActionButtonToMenuItem(x, permissions))) : undefined,
      isolate: actionButton.isolate
    } as CustomMenuItem;
  }
}
