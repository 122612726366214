<ng-template #entityEditTemplate
             let-title="title"
             let-id="id">
  <nexnox-web-ccp-control-tag-edit #modelComponent
                                   [title]="title"
                                   [id]="id"
                                   [model]="model$ | async"
                                   [readonly]="readonly$ | async"
                                   [creating]="false"
                                   [loading]="loading$ | async"
                                   [stereotypes$]="stereotypes$"
                                   [stereotyped]="true"
                                   (modelChange)="onModelChange($event)">
  </nexnox-web-ccp-control-tag-edit>
</ng-template>



<nexnox-web-entity-detail [entityDetailBaseComponent]="this"
                          [entityEditTemplate]="entityEditTemplate"
                          tabId="controlTag"
                          [tabHeading]="title | translate"
                          [tabCommands]="['.']">

  <ng-template nexnoxWebTabTemplate
               tabId="controlPoints"
               [heading]="'tech-portal.ccp.control-points.subtitles.list' | translate"
               (selectTab)="onNavigateToTab(['controlPoints'])">
    <nexnox-web-ccp-control-tag-control-points-edit *ngIf="model$ | async as model"
                                                    #controlPointsComponent
                                                    [loading]="loading$ | async"
                                                    [readonly]="readonly$ | async"
                                                    [controlPointsSubject]="controlPointsSubject"
                                                    (controlPointsChange)="onControlPointsChange($event, model)">
    </nexnox-web-ccp-control-tag-control-points-edit>
  </ng-template>
</nexnox-web-entity-detail>
