import {NgModule} from '@angular/core';
import {
  ContactAreaDetailStandaloneComponent,
  ContactAreaListComponent,
  ContactDetailComponent,
  ContactOpenRequestListComponent,
  CorePortalFeatureMasterDataContactListComponent
} from './containers';
import {
  ContactAreaEditComponent,
  ContactNewFunctionsAlertComponent,
  CorePortalFeatureContactEditComponent
} from './components';
import {CorePortalEntityModule, CorePortalModule, CorePortalRouterOverridesModule} from '@nexnox-web/core-portal';
import {CoreSharedModule} from '@nexnox-web/core-shared';
import {contactsServices} from './store';
import {CorePortalFeatureMasterDataLocationsModule} from '../../../locations';
import {CorePortalFeatureMasterDataLocationGroupsModule} from '../../../location-groups';
import {CorePortalFeatureMasterDataCompaniesModule} from '../../../companies';
import {TechPortalModule} from "@nexnox-web/tech-portal-lib";
import {
  BulkAreaAssignmentSidebarComponent,
  ChangeMailAddressSidebarComponent,
  ForcePasswordSidebarComponent
} from './sidebars';
import {ContactAreaBreadcrumbsResolver} from "./resolvers";
import {InputTextModule} from "primeng/inputtext";

@NgModule({
  declarations: [
    CorePortalFeatureMasterDataContactListComponent,
    ContactDetailComponent,
    ContactAreaListComponent,

    CorePortalFeatureContactEditComponent,
    ContactAreaEditComponent,

    ContactOpenRequestListComponent,

    ContactNewFunctionsAlertComponent,
    ContactAreaDetailStandaloneComponent,

    BulkAreaAssignmentSidebarComponent,
    ChangeMailAddressSidebarComponent,
    ForcePasswordSidebarComponent
  ],
  imports: [
    CoreSharedModule,
    CorePortalEntityModule,
    CorePortalRouterOverridesModule,
    TechPortalModule,
    InputTextModule,
    CorePortalModule,

    CorePortalFeatureMasterDataLocationGroupsModule.forFeature(),
    CorePortalFeatureMasterDataLocationsModule.forFeature(),
    CorePortalFeatureMasterDataCompaniesModule.forFeature()
  ],
  providers: [
    ...contactsServices,
    ContactAreaBreadcrumbsResolver
  ],
  exports: [
    CorePortalFeatureContactEditComponent
  ]
})
export class ContactsDeclarationsModule {
}
