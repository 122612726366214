import { PagedEntitiesXsStore, PagedEntitiesXsStoreState } from '@nexnox-web/core-store';
import { AppEntityType, ResourceTaskDto } from '@nexnox-web/core-shared';
import { createSelector } from '@ngrx/store';
import { selectLocationsState } from '../../locations.selectors';
import { LocationTaskService } from '../../services';

export interface LocationTaskListStoreState extends PagedEntitiesXsStoreState<ResourceTaskDto> {
}

/* istanbul ignore next */
export const locationTaskListStore = new PagedEntitiesXsStore<ResourceTaskDto>({
  actionLabel: 'Core Portal - Master Data - LOcation - Task List',
  stateSelector: createSelector(selectLocationsState, state => state.locationTaskList),
  serviceType: LocationTaskService,
  entityType: AppEntityType.Task,
  selectId: task => task.entity?.resourceTaskId ?? task.model?.resourceTaskId
});
