import { stateDetailStore, StateDetailStoreState, stateListStore, StateListStoreState } from './stores';

export interface StatesState {
  stateList: StateListStoreState;
  stateDetail: StateDetailStoreState;
}

export const getInitialStatesState = (): StatesState => ({
  stateList: stateListStore.getInitialState(),
  stateDetail: stateDetailStore.getInitialState()
});
