<div class="row">
  <div class="col-xl-8 col-lg-12 pl-0 pr-0 pr-xl-2">
    <nexnox-web-solutions-solution-item-edit *ngIf="(readonly$ | async) === false"
                                             #editComponent
                                             [title]="createTitle | translate"
                                             [model]="createModel$ | async"
                                             [readonly]="false"
                                             [creating]="true"
                                             [headerActions]="createNoteHeaderActions"
                                             [relationId]="relationId"
                                             [relationIdProperty]="relationIdProperty"
                                             [solution$]="solution$"
                                             [solutionMembers]="(solution$ | async)?.members"
                                             [type]="solutionMemberType"
                                             [hasChat]="hasChat"
                                             (modelChange)="createModel$.next($event)">
    </nexnox-web-solutions-solution-item-edit>
  </div>
</div>

<nexnox-web-solutions-solution [baseComponent]="this"
                               [readonly]="readonly$ | async">
</nexnox-web-solutions-solution>
