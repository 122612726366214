<ng-template #entityEditTemplate let-title="title">
    <nexnox-web-master-data-location-groups-location-group-edit #editComponent
                                                                [title]="title"
                                                                [model]="createModel$ | async"
                                                                [readonly]="false"
                                                                [creating]="true"
                                                                [loading]="(loaded$ | async) === false"
                                                                [stereotypes$]="stereotypes$"
                                                                (modelChange)="createModel$.next($event)">
    </nexnox-web-master-data-location-groups-location-group-edit>
</ng-template>

<nexnox-web-entity-overview #overviewComponent
                            [entityOverviewBaseComponent]="this"
                            [entityEditTemplate]="entityEditTemplate"
                            [canExport]="true"
                            detailLink="/masterdata/locationgroups"
                            module="management">
</nexnox-web-entity-overview>
