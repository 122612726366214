import { isEqual, keys, merge } from 'lodash';

export interface IsDeepEqualWithCustomizer {
  path: string;
  isEqual: (a: any, b: any) => boolean;
}

export interface IsDeepEqualWithOptions {
  path?: string;
  customizers?: IsDeepEqualWithCustomizer[];
}

export function isDeepEqualWith(a: any, b: any, options: IsDeepEqualWithOptions = {}): boolean {
  const matchingCustomizers = (options?.customizers ?? []).filter(x => x.path === options?.path);
  const hasMatchingCustomizers = matchingCustomizers.length;
  if (hasMatchingCustomizers) return customizerEqual(a, b, matchingCustomizers);

  return defaultEqual(a, b, options);
}

function customizerEqual(a: any, b: any, matchingCustomizers: IsDeepEqualWithCustomizer[]): boolean {
  for (const customizer of matchingCustomizers) {
    if (!customizer.isEqual(a, b)) return false;
  }

  return true;
}

function defaultEqual(a: any, b: any, options: IsDeepEqualWithOptions = {}): boolean {
  const commonType = typeof a === 'undefined' ? typeof b : typeof a;
  switch (commonType) {
    case 'object': {
      if (!isObjectEqual(a, b, options)) return false;
      break;
    }
    default:
      if (!isEqual(a, b)) return false;
      break;
  }

  return true;
}

function isObjectEqual(a: any, b: any, options: IsDeepEqualWithOptions = {}): boolean {
  const keysToIterate = merge(keys(a), keys(b));

  for (const key of keysToIterate) {
    const newPath = `${options?.path?.length ? `${options.path}.` : ''}${key}`;
    const entityValue = a?.hasOwnProperty(key) ? a[key] : undefined;
    const modelValue = b?.hasOwnProperty(key) ? b[key] : undefined;

    if (!isDeepEqualWith(entityValue, modelValue, { ...options, path: newPath })) return false;
  }

  return true;
}
