<p-sidebar #sidebarComponent
           position="right"
           appendTo="body"
           styleClass="p-sidebar-xl"
           [transitionOptions]="transitionOptions"
           [visible]="visible$ | async"
           (visibleChange)="onVisibleChange($event)">

  <h4 class="mb-4">{{'resources.actions.select-resource' | translate}}</h4>

  <nexnox-web-resources-resource-datatable-search [location$]="locationSubject.asObservable()"
                                                  [selectionMode]="selectionModes.Single"
                                                  (selectionChange)="onSelectionChange($event)">
  </nexnox-web-resources-resource-datatable-search>


  <div class="w-100 d-flex align-items-center my-4 py-2">

    <div class="flex-grow-1 pr-4">
      <ng-container *ngIf="selectedResource">
        {{ 'core-portal.core.general.selection' | translate }}:
        <fa-icon [icon]="faFolder"
                 [fixedWidth]="true"
                 size="sm"
                 class="mr-1 ml-2"></fa-icon>
        <a class="btn-link"
           [nexnoxWebTenantRouterLink]="['/resources', selectedResource?.resourceId]"
           module="inventory"
           target="_blank">
          {{ selectedResource?.name }}
        </a>
      </ng-container>
    </div>

    <div>
      <button *ngIf="create" pButton
              type="button"
              class="p-button p-button-primary"
              [label]="'core-shared.shared.actions.assign-selection' | translate"
              [icon]="faFolder | nexnoxWebFaIconStringPipe"
              [disabled]="!selectedResource"
              (click)="onAssignResource()">
      </button>

      <ng-container *ngIf="!create">
        <button pButton
                type="button"
                class="p-button p-button-secondary mr-3"
                [label]="'core-shared.shared.actions.assign-to-mission' | translate"
                [icon]="faFolder | nexnoxWebFaIconStringPipe"
                [disabled]="!selectedResource"
                (click)="onAssignResource()">
        </button>

        <button pButton
                type="button"
                class="p-button p-button-primary"
                [label]="'core-shared.shared.actions.assign-all' | translate"
                [icon]="faFolder | nexnoxWebFaIconStringPipe"
                [disabled]="!selectedResource"
                (click)="onAssignResource(true)">
        </button>
      </ng-container>
    </div>
  </div>
</p-sidebar>
