<div *ngIf="isItemVisible$ | async"
     class="sidebar-item"
     [class.sidebar-item--child]="level > 0">
  <ng-container *ngIf="item$ | async as sidebarItem">
    <a class="sidebar-item__content"
       [class.sidebar-item__content--expanded]="sidebarItem.expanded"
       [nexnoxWebTenantRouterLink]="sidebarItem.path"
       [module]="sidebarItem.module"
       nexnoxWebTenantRouterLinkActive="sidebar-item__content--active"
       [nexnoxWebDisableRouterLink]="!!sidebarItem.children"
    >
      <div class="sidebar-item__content__inner-content"
           [style.padding-left]="0.75 + (level * 2.25) + 'rem'"
           (click)="onItemClicked(!sidebarItem.children, $event)">
        <span class="sidebar-item__content__active"></span>
        <fa-icon *ngIf="sidebarItem.icon"
                 [icon]="sidebarItem.icon"
                 [fixedWidth]="true"
                 size="sm"
                 class="mr-2">
        </fa-icon>
        {{ sidebarItem.title | translate }}

        <fa-icon *ngIf="sidebarItem.children"
                 class="sidebar-item__content__expand"
                 [icon]="sidebarItem.expanded ? faChevronUp : faChevronDown">
        </fa-icon>
      </div>
    </a>

    <div class="sidebar-item__children"
         [class.--expanded]="sidebarItem.expanded"
         [@expandedCollapsed]="sidebarItem.expanded ? 'expanded' : 'collapsed'">
      <ng-container *ngFor="let childItem of sidebarItem.children; trackBy: trackChildrenBy">
        <nexnox-web-sidebar-item [item]="childItem"
                                 [moduleIsAnimating$]="moduleIsAnimating$"
                                 [parent]="this"
                                 [level]="level + 1"
                                 [checkPermissionFn]="checkPermissionFn"
                                 (click)="onItemClicked(!childItem.children)">
        </nexnox-web-sidebar-item>
      </ng-container>
    </div>

  </ng-container>
</div>
