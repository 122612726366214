import {ChangeDetectionStrategy, Component, Injector} from '@angular/core';
import {
  ActionButton,
  CorePortalEntityOverviewBaseComponent,
  DatatableActionButton,
  DeleteEntityModel
} from '@nexnox-web/core-portal';
import {AppEntityType, AppPermissions, FormDto, Mappers} from '@nexnox-web/core-shared';
import {formListStore} from '../../store';

@Component({
  selector: 'nexnox-web-forms-form-list',
  templateUrl: './form-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormListComponent extends CorePortalEntityOverviewBaseComponent<FormDto> {
  public title = 'orga-portal.forms.subtitles.form-list';
  public createTitle = 'orga-portal.forms.actions.create-form';
  public idProperty = 'formId';
  public displayProperty = 'title';
  public datatableConfigName = 'FormDefault';
  public componentId = 'FormListComponent';

  protected forceGetEntities = true;

  constructor(
    protected injector: Injector
  ) {
    super(injector, formListStore, Mappers.FormListDto.serializedName, AppEntityType.Form);
  }

  /* istanbul ignore next */
  public getRowActionButtons(): DatatableActionButton[] {
    return this.getDefaultRowActionButtons(
      'orga-portal.forms.actions.edit-form',
      (row: FormDto) => `forms/${row.formId}`,
      [AppPermissions.UpdateForm],
      {
        module: 'settings'
      }
    );
  }

  public getDeleteEntityModel(): DeleteEntityModel {
    return {
      titleKey: 'orga-portal.forms.actions.delete-form',
      descriptionKey: 'orga-portal.forms.descriptions.delete-form',
      confirmKey: 'orga-portal.forms.actions.delete-form',
      deletePermission: AppPermissions.DeleteForm
    };
  }

  protected async getActionButtons(): Promise<ActionButton[]> {
    return this.getDefaultActionButtons('orga-portal.forms.actions.create-form', AppPermissions.CreateForm);
  }
}
