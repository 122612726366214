import {Injectable, Injector} from '@angular/core';
import {CoreSharedApiBaseService, Product} from '@nexnox-web/core-shared';

@Injectable()
export class CorePortalSearchListsService extends CoreSharedApiBaseService {
  constructor(
    protected override injector: Injector
  ) {
    super(injector, Product.ORGA, 'v2/searchlist');
  }
}
