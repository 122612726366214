import { ChangeDetectionStrategy, Component, Injector } from '@angular/core';
import { CorePortalFeatureAuditListBaseComponent } from '@nexnox-web/core-portal/features/audit';
import { resourceAuditStore } from '../../store/stores';

@Component({
  selector: 'nexnox-web-resources-resource-audit',
  templateUrl: './resource-audit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ResourceAuditComponent extends CorePortalFeatureAuditListBaseComponent {
  public componentId = 'ResourceAuditComponent';

  constructor(
    protected injector: Injector
  ) {
    super(injector, resourceAuditStore);
  }
}
