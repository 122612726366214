import { EntityXsStore, EntityXsStoreState } from '@nexnox-web/core-store';
import { AppEntityType, AppTenantDto } from '@nexnox-web/core-shared';
import { createSelector } from '@ngrx/store';
import { selectTenantsState } from '../../tenants.selectors';
import { TenantService } from '../../services';

export interface TenantDetailStoreState extends EntityXsStoreState<AppTenantDto> {
}

/* istanbul ignore next */
export const tenantDetailStore = new EntityXsStore<AppTenantDto>({
  actionLabel: 'Core Portal - Service - Tenants - Tenant Detail',
  stateSelector: createSelector(selectTenantsState, state => state.tenantDetail),
  serviceType: TenantService,
  entityType: AppEntityType.None,
  stereotyped: false
});
