<div class="p-messages p-component">
  <div *ngFor="let toast of toastListSubject | async; let i = index"
       class="p-message p-message-success">
    <div class="p-message-wrapper d-flex align-items-center">
      <span class="p-message-icon pi pi-check"></span>

      <span class="p-message-summary">
        {{
          toast.title | translate: toast.translationParams
        }}
      </span>
      <button type="button"
              class="p-message-close p-link position-relative overflow-hidden ml-auto d-flex align-items-center justify-content-center"
              (click)="onCloseToast(i)">
        <i class="p-message-close-icon pi pi-times"><span class="p-ink"></span></i>
      </button>
    </div>
  </div>
</div>
