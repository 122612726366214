import {EntityXsStore, EntityXsStoreState} from '@nexnox-web/core-store';
import {AppEntityType, WinterhalterServiceCallIssueDto} from '@nexnox-web/core-shared';
import {Action, createSelector} from '@ngrx/store';
import {WinterhalterServiceCallIssueService} from '../../services';
import {Injectable, Injector} from '@angular/core';
import {selectIssuesState} from "../../issues.selectors";

export interface WinterhalterServiceCallIssueDetailStoreState extends EntityXsStoreState<WinterhalterServiceCallIssueDto> {
}

/* istanbul ignore next */
export const winterhalterServiceCallIssueDetailStore = new EntityXsStore<WinterhalterServiceCallIssueDto>({
  actionLabel: 'Tech Portal - Issues - Winterhalter Service Call Issue Detail',
  stateSelector: createSelector(selectIssuesState, state => state.winterhalterServiceCallIssueDetail),
  serviceType: WinterhalterServiceCallIssueService,
  entityType: AppEntityType.Issue,
  stereotyped: true
});

export function winterhalterServiceCallIssueDetailStoreReducer(state: WinterhalterServiceCallIssueDetailStoreState, action: Action): any {
  return winterhalterServiceCallIssueDetailStore.reducer(state, action);
}

@Injectable()
export class WinterhalterServiceCallIssueDetailStoreEffects extends winterhalterServiceCallIssueDetailStore.effects {
  constructor(
    protected injector: Injector
  ) {
    super(injector);
  }
}
