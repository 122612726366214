import {ChangeDetectionStrategy, Component, Injector, Input, OnInit} from '@angular/core';
import {CorePortalEntityOverviewBaseComponent} from '@nexnox-web/core-portal';
import {AppEntityType, ControllerOperationId, InfoProgressContactListDto, Mappers} from '@nexnox-web/core-shared';
import {infoProgressContactReadUnreadListStore, InfoProgressContactReadUnreadService} from '../../store';
import {cloneDeep, isArray} from "lodash";

@Component({
  selector: 'nexnox-web-infos-info-progress-contact-read-unread-list',
  templateUrl: './info-progress-contact-read-unread-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InfoProgressContactReadUnreadListComponent extends CorePortalEntityOverviewBaseComponent<InfoProgressContactListDto> implements OnInit {
  @Input() public customParams: { [key: string]: number[] | string[] };
  @Input() public isRemoveOfExpectedColumn: boolean;

  public title = 'orga-portal.infos.subtitles.info-progress-sidebar-title';
  public idProperty = 'contactId';
  public displayProperty = null;
  public datatableConfigName = 'InfoProgressContactsDefault';
  public componentId = 'InfoProgressContactReadUnreadListComponent';
  public enableViews = true;
  public pageOperation = ControllerOperationId.InfoProgressContactControllerId;
  public isEmbedded = true;

  public initialExcludedColumns: string[];


  constructor(
    protected injector: Injector,
    protected infoProgressContactReadUnreadService: InfoProgressContactReadUnreadService
  ) {
    super(injector, infoProgressContactReadUnreadListStore, Mappers.InfoProgressContactListDto.serializedName, AppEntityType.None);
  }

  public ngOnInit(): void {

    this.infoProgressContactReadUnreadService.customQueryParams = this.customParams ?? {};

    if (this.parentIds[0]) {
      this.descendantId = Number(this.parentIds[0])
    }

    super.ngOnInit();

    // Excluded column conditions
    if (!isArray(this.initialExcludedColumns)) {
      this.initialExcludedColumns = cloneDeep(this.datatableConfig[this.datatableConfigName]?.excludedColumns ?? []);
    }

    this.excludedColumns = [...this.initialExcludedColumns, 'locations', 'functions', 'readLocation'];

    if (this.isRemoveOfExpectedColumn) {
      this.excludedColumns.push('isExpected');
    }
  }

}
