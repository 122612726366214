import { NgModule } from '@angular/core';
import { MissionReportEditWrapperComponent } from './mission-report-edit-wrapper/mission-report-edit-wrapper.component';
import { CoreSharedModule } from '@nexnox-web/core-shared';
import { TechPortalFeatureMissionsModule } from '@nexnox-web/tech-portal/features/missions';

const skeletonWrapperComponents = [
  MissionReportEditWrapperComponent
];

@NgModule({
    declarations: [
        ...skeletonWrapperComponents
    ],
    imports: [
        CoreSharedModule,
        TechPortalFeatureMissionsModule.forFeature()
    ],
    exports: [
        ...skeletonWrapperComponents
    ]
})
export class SkeletonsModule {
}
