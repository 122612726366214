import {
  cancellationReasonsDetailStore,
  CancellationReasonsDetailStoreState,
  cancellationReasonsListStore,
  CancellationReasonsListStoreState
} from './stores';

export interface CancellationReasonsState {
  cancellationReasonsList: CancellationReasonsListStoreState;
  cancellationReasonsDetail: CancellationReasonsDetailStoreState;
}

export const getInitialCancellationReasonsState = (): CancellationReasonsState => ({
  cancellationReasonsList: cancellationReasonsListStore.getInitialState(),
  cancellationReasonsDetail: cancellationReasonsDetailStore.getInitialState()
});
