<div class="d-flex align-items-center h-100">
  <ng-container *ngIf="currentModule$ | async as currentModule">
    <p-overlayPanel #modulePanel
                    [dismissable]="true"
                    appendTo="body">
      <ng-template pTemplate>
        <div class="row">
          <ng-container *ngFor="let module of modules$ | async">
            <div *ngIf="!module.hide" class="col-md-4 p-0">
              <a type="button"
                 pButton
                 class="module-item p-button-text p-3"
                 [class.p-button-primary]="module.path === currentModule.path"
                 [class.p-button-secondary]="module.path !== currentModule.path"
                 [class.is-disabled]="module.path === currentModule.path"
                 [routerLink]="!module.target ? module.path : null"
                 (click)="onSelectModule(module, modulePanel, $event)">
                <fa-icon [icon]="module.icon" size="3x" class="mb-2"></fa-icon>
                {{ module.name | translate }}
              </a>
            </div>
          </ng-container>
        </div>
      </ng-template>
    </p-overlayPanel>

    <button type="button"
            pButton
            class="p-button-text p-button-icon-only p-button-secondary p-button-h-100 p-button-lg px-4"
            [icon]="faTh | nexnoxWebFaIconStringPipe"
            [pTooltip]="'core-portal.core.header.tooltip.change-module' | translate"
            tooltipPosition="left"
            (click)="modulePanel.toggle($event)">
    </button>
  </ng-container>
</div>
