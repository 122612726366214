import { PagedEntitiesXsStore, PagedEntitiesXsStoreState } from '@nexnox-web/core-store';
import { AppEntityType, MissionSimpleListDto } from '@nexnox-web/core-shared';
import { Action, ActionReducer, createSelector } from '@ngrx/store';
import { selectResourcesState } from '../../resources.selectors';
import { TechPortalFeatureResourceMissionService } from '../../services';
import { Injectable, Injector } from '@angular/core';

export interface ResourceMissionsStoreState extends PagedEntitiesXsStoreState<MissionSimpleListDto> {
}

/* istanbul ignore next */
export const resourceMissionsStore = new PagedEntitiesXsStore<MissionSimpleListDto>({
  actionLabel: 'Tech Portal - Resources - Resource Missions',
  stateSelector: createSelector(selectResourcesState, state => state.resourceMissions),
  entityType: AppEntityType.Mission,
  serviceType: TechPortalFeatureResourceMissionService,
  selectId: mission => mission.entity?.missionId ?? mission.model?.missionId
});

export function resourceMissionsStoreReducer(state: ResourceMissionsStoreState, action: Action): ActionReducer<any> {
  return resourceMissionsStore.reducer(state, action);
}

@Injectable()
export class ResourceMissionsStoreEffects extends resourceMissionsStore.effects {
  constructor(
    protected injector: Injector
  ) {
    super(injector);
  }
}
