<div class="input-group">
  <div class="custom-file">
    <input #fileInput
           type="file"
           class="form-control-file custom-file-input"
           [class.has-error]="showError"
           [disabled]="to.disabled || (progress$ | async)"
           [accept]="to.corePortalFileUpload?.accept"
           (change)="onUpload($any($event.target).files)">
    <label class="custom-file-label">
      <span *ngIf="modelAtKey as file; else browseTemplate">
        <span>{{ file.name }}</span>&nbsp;
        <span class="text-muted">({{ (file?.size ?? 0) | filesize }})</span>
      </span>

      <ng-template #browseTemplate>
        {{ 'core-portal.core.general.browse' | translate }}...
      </ng-template>
    </label>
  </div>

  <div *ngIf="modelAtKey" class="input-group-append">
    <button type="button"
            class="btn btn-outline-secondary"
            [disabled]="!modelAtKey || to.disabled"
            (click)="onClear()">
      <fa-icon [icon]="faTimes"></fa-icon>
    </button>
  </div>

  <div *ngIf="modelAtKey" class="input-group-append">
    <!-- load or download button -->
    <button type="button"
            class="btn btn-outline-secondary"
            [disabled]="isDownloadingSubject | async"
            (click)="onDownload(modelAtKey)">
      <nexnox-web-loading-icon size="sm" *ngIf="isDownloadingSubject | async; else cloudIcon"></nexnox-web-loading-icon>
      <ng-template #cloudIcon>
       <fa-icon [icon]="faCloudDownload"></fa-icon>
      </ng-template>
    </button>
  </div>
</div>

<div *ngIf="progress$ | async as progress" class="progress mt-3">
  <div class="progress-bar bg-primary" [style.width.%]="progress * 100">{{ progress | percent }}</div>
</div>

<div *ngIf="to.corePortalFileUpload?.image && modelAtKey"
     class="image-preview d-flex justify-content-center p-3"
     [class.is-disabled]="to.disabled || (progress$ | async)">
  <a [href]="modelAtKey.uri" target="_blank">
    <img [src]="modelAtKey.uri" class="img-fluid">
  </a>
</div>


