<ng-container *ngIf="!disabled">
  <ng-container *ngIf="!asCards; else cardTemplate">
    <div class="d-flex flex-column mb-3">
      <ng-container *ngTemplateOutlet="createTemplate"></ng-container>
    </div>
  </ng-container>
</ng-container>

<ng-template #cardTemplate>
  <nexnox-web-cardbox [title]="'core-shared.shared.select.add-item' | translate"
                      paddingClasses="px-3 pb-3">
    <ng-container *ngTemplateOutlet="createTemplate"></ng-container>
  </nexnox-web-cardbox>
</ng-template>

<ng-template #createTemplate>
  <form [formGroup]="form" class="w-100">
    <formly-form class="row"
                 [form]="form"
                 [(model)]="model"
                 [fields]="fields">
    </formly-form>
  </form>

  <div class="d-flex w-100 justify-content-end">
    <button class="btn btn-outline-primary"
            [disabled]="!model || form.invalid || (tooManyItems$ | async)"
            (click)="onAddItem()">
      {{ 'core-shared.shared.select.add-item' | translate }}
    </button>
  </div>
</ng-template>

<div *ngIf="!disabled && (tooManyItems$ | async)"
     class="alert d-flex align-items-center w-100"
     [class.alert-info]="(tooManyItemsError$ | async) === false"
     [class.alert-warning]="tooManyItemsError$ | async">
  <fa-icon [icon]="(tooManyItemsError$ | async) ? faExclamationTriangle : faInfoCircle" class="mr-2"></fa-icon>
  <span>
    {{ 'core-portal.shared.rule-editor.descriptions.max-items-reached' | translate }}
  </span>
</div>

<nexnox-web-sortable-list [items]="items$ | async"
                          [disabled]="disabled"
                          [editable]="false"
                          [selectable]="$any(selectFn)"
                          [canSelectFn]="canSelectFn"
                          [sortable]="sortable"
                          [asCards]="asCards"
                          [noPaddingBottom]="true"
                          (itemsChange)="onItemsChange($event)"
                          (selectItem)="selectFn($event)">
  <ng-template nexnoxWebSortableListItemTemplate let-item="item">
    <nexnox-web-rule-editor-list-item #ruleEditorListItemComponent
                                      [item]="item"
                                      [imageKey]="imageKey"
                                      [inheritedFieldsFn]="inheritedFieldsFn"
                                      [disabled]="disabled"
                                      [inherited]="inheritedKey ? item[inheritedKey] : false"
                                      (itemChange)="onItemChange(item.index, $event)">
    </nexnox-web-rule-editor-list-item>
  </ng-template>
</nexnox-web-sortable-list>
