<ng-template #entityEditTemplate let-title="title" let-id="id">
  <nexnox-web-categories-category-edit #modelComponent
                                       [title]="title"
                                       [id]="id"
                                       [model]="model$ | async"
                                       [readonly]="readonly$ | async"
                                       [creating]="false"
                                       [loading]="loading$ | async"
                                       [stereotyped]="false"
                                       (modelChange)="onModelChange($event)">
  </nexnox-web-categories-category-edit>
</ng-template>

<nexnox-web-entity-detail [entityDetailBaseComponent]="this"
                          [entityEditTemplate]="entityEditTemplate"
                          tabId="category"
                          [tabHeading]="title | translate"
                          [tabCommands]="['.']">
</nexnox-web-entity-detail>
