import {ChangeDetectionStrategy, Component, Injector} from '@angular/core';
import {
  CorePortalEntityEditBaseComponent,
  CorePortalFormlyReadonlyTypes,
  CorePortalFormlyReadonlyTyping
} from '@nexnox-web/core-portal';
import {AddressDto, AddressSimpleDto, CompanyDto, FilterDto} from '@nexnox-web/core-shared';
import {FormlyFieldConfig} from '@ngx-formly/core';
import {take} from "rxjs/operators";
import {CompanyAddressService} from "../../store";

@Component({
  selector: 'nexnox-web-master-data-companies-company-edit',
  templateUrl: './company-edit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CorePortalFeatureMasterDataCompanyEditComponent extends CorePortalEntityEditBaseComponent<CompanyDto> {
  constructor(
    protected injector: Injector,
    protected companyAdressService: CompanyAddressService
  ) {
    super(injector);
  }

  protected createForm(): FormlyFieldConfig[] {

    const addressTemplate = (address: AddressSimpleDto): string => {
      let firstPart = address.street;
      let secondPart = address.city;

      if (address.houseNumber) {
        firstPart = `${firstPart} ${address.houseNumber}`;
      }

      if (address.zipcode) {
        secondPart = `${address.zipcode} ${secondPart}`;
      }

      return `${firstPart}${secondPart ? `, ${secondPart}` : ''}`;
    };

    return [
      {
        key: 'name',
        type: 'input',
        wrappers: ['core-portal-translated'],
        className: 'col-md-12',
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.name',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          },
          type: 'text'
        },
        expressionProperties: {
          'templateOptions.required': () => !this.readonly,
          'templateOptions.disabled': () => this.readonly
        },
        hideExpression: () => !this.creating
      },
      {
        key: 'invoiceAddress',
        type: 'core-portal-entity-select',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-6',
        defaultValue: null,
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.invoice-address',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.ENTITY,
            displayKey: 'street',
            template: addressTemplate,
            link: (address: AddressDto) => address?.addressId ? ['/masterdata', 'addresses', address.addressId] : null,
            module: 'management'
          } as CorePortalFormlyReadonlyTyping,
          entityService: this.companyAdressService,
          idKey: 'addressId',
          displayKey: 'street',
          additionalSearchProperties: ['houseNumber', 'zipcode', 'city'],
          template: addressTemplate,
          wholeObject: true,
          skipGetOne: true,
          overrideGetPage: (pageNumber: number, pageSize: number, filters: FilterDto[]) => {
            const locationId = this.model.companyId;
            return this.companyAdressService.getPage(
              undefined,
              pageNumber,
              filters,
              undefined,
              undefined,
              undefined,
              pageSize,
              [locationId]
            ).pipe(take(1)).toPromise();
          },
          link: (address: AddressDto) => address?.addressId ? ['/masterdata', 'addresses', address.addressId] : null,
          module: 'management'
        },
        expressionProperties: {
          'templateOptions.disabled': () => this.readonly,
          'templateOptions.readonly': () => this.readonly
        },
        hideExpression: () => this.creating
      },
      {
        key: 'shippingAddress',
        type: 'core-portal-entity-select',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-6',
        defaultValue: null,
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.shipping-address',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.ENTITY,
            displayKey: 'street',
            template: addressTemplate,
            link: (address: AddressDto) => address?.addressId ? ['/masterdata', 'addresses', address.addressId] : null,
            module: 'management'
          } as CorePortalFormlyReadonlyTyping,
          entityService: this.companyAdressService,
          idKey: 'addressId',
          displayKey: 'street',
          additionalSearchProperties: ['houseNumber', 'zipcode', 'city'],
          template: addressTemplate,
          wholeObject: true,
          skipGetOne: true,
          overrideGetPage: (pageNumber: number, pageSize: number, filters: FilterDto[]) => {
            const locationId = this.model.companyId;
            return this.companyAdressService.getPage(
              undefined,
              pageNumber,
              filters,
              undefined,
              undefined,
              undefined,
              pageSize,
              [locationId]
            ).pipe(take(1)).toPromise();
          },
          link: (address: AddressDto) => address?.addressId ? ['/masterdata', 'addresses', address.addressId] : null,
          module: 'management'
        },
        expressionProperties: {
          'templateOptions.disabled': () => this.readonly,
          'templateOptions.readonly': () => this.readonly
        },
        hideExpression: () => this.creating
      },
      ...this.getStereotypeFields(false)
    ];
  }
}
