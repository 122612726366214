import { NgModule } from '@angular/core';
import {CorePortalEntityModule, CorePortalAttachmentsModule, CorePortalModule} from '@nexnox-web/core-portal';
import { CorePortalFeatureMasterDataLocationsModule } from '@nexnox-web/core-portal/features/master-data/features/locations';
import { CorePortalFeatureResourcesModule } from '@nexnox-web/core-portal/features/resources';
import { CoreSharedModule } from '@nexnox-web/core-shared';
import { TechPortalModule } from '@nexnox-web/tech-portal-lib';
import { TechPortalFeatureTicketSettingsPrioritySetsModule } from '@nexnox-web/tech-portal/features/ticket-settings/features/priority-sets';
import { TechPortalFeatureTicketEditComponent } from './components';
import { TechPortalFeatureMissionTicketService } from './services';
import {
  TicketAssignContactSidebarComponent,
  TicketAssignResourceSidebarComponent
} from "./sidebars";

@NgModule({
  declarations: [
    TechPortalFeatureTicketEditComponent,
    TicketAssignResourceSidebarComponent,
    TicketAssignContactSidebarComponent
  ],
  imports: [
    CoreSharedModule,
    CorePortalEntityModule,
    CorePortalAttachmentsModule,
    CorePortalModule,

    TechPortalModule,
    CorePortalFeatureMasterDataLocationsModule.forFeature(),
    TechPortalFeatureTicketSettingsPrioritySetsModule.forFeature(),
    CorePortalFeatureResourcesModule.forFeature()
  ],
  exports: [
    TechPortalFeatureTicketEditComponent,
    TicketAssignContactSidebarComponent
  ],
  providers: [
    TechPortalFeatureMissionTicketService
  ]
})
export class TechPortalFeatureTicketsMissionsModule {
}
