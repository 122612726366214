import { Injector, Type } from '@angular/core';
import { CoreSharedApiBaseService, Product } from '@nexnox-web/core-shared';
import { Observable } from 'rxjs';

export abstract class CorePortalEmbeddedEntityBaseService extends CoreSharedApiBaseService {
  protected baseService: CoreSharedApiBaseService;

  protected constructor(
    protected injector: Injector,
    baseServiceType: Type<CoreSharedApiBaseService>,
    product: Product,
    resource: string,
    parentResources: string[] = []
  ) {
    super(injector, product, resource, parentResources);

    this.baseService = injector.get(baseServiceType);
  }

  public createOne<T>(data: T, parentIds?: Array<number | string>): Observable<T> {
    return this.baseService.createOne<T>(data, parentIds);
  }

  public deleteOne<T>(id: number | string, parentIds?: number[]): Observable<T> {
    return this.baseService.deleteOne(id, parentIds);
  }
}
