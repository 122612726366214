<div [class.mb-0]="isLast" [class.mb-5]="!isLast">
  <p-panel styleClass="{{ row.fields?.length ? 'form-row-panel' : '' }}">
    <ng-template pTemplate="header">
      <div class="d-flex flex-column w-100">
        <div class="d-flex flex-row w-100">
          <div class="d-flex align-items-center flex-wrap position-relative">
            <ng-container *ngIf="row.type === formRowTypes.Repeatable">
              <p-badge *ngIf="!readonly"
                       [value]="'orga-portal.forms.descriptions.is-repeated' | translate"
                       styleClass="form-row-panel__header__float-badge p-badge--top bg-secondary whitespace-nowrap">
              </p-badge>

              <p-badge *ngIf="readonly"
                       [value]="'orga-portal.forms.descriptions.is-repeated' | translate"
                       styleClass="d-flex bg-secondary mr-2 align-middle">
              </p-badge>
            </ng-container>
          </div>

          <div class="d-flex flex-grow-1 align-items-center mw-100">
            <nexnox-web-cardbox-actions [actions]="headerActions"
                                        [useNew]="true"
                                        [hasFlexContent]="true"
                                        [readonly]="readonly"
                                        class="w-100">
              <div class="w-100" [style.min-width.px]="50">
                <span *ngIf="readonly; else titleEditTemplate"
                      [title]="row.title"
                      class="text-ellipsis">
                  {{ row.title }}
                </span>

                <ng-template #titleEditTemplate>
                  <input #titleEditInput
                         pInputText
                         type="text"
                         class="form-control"
                         [required]="true"
                         [style.height.px]="29"
                         [ngModel]="row.title"
                         (ngModelChange)="onTitleChange($event)">
                </ng-template>
              </div>
            </nexnox-web-cardbox-actions>
          </div>
        </div>

        <div *ngIf="row.type === formRowTypes.Repeatable" class="d-flex flex-row mt-2">
          <form [formGroup]="repeatRowForm"
                class="d-flex flex-grow-1 w-100">
            <formly-form class="row w-100"
                         [form]="repeatRowForm"
                         [model]="row"
                         [fields]="repeatRowFields"
                         (modelChange)="onRowChange($event)">
            </formly-form>
          </form>
        </div>
      </div>
    </ng-template>

    <div class="row">
      <div *ngFor="let field of row.fields;
                   let isFirst = first;
                   let isLast = last;
                   trackBy: trackFieldsBy"
           [class]="'mb-3 col-md-' + field.width">
        <nexnox-web-forms-form-field-edit #formFieldEditComponent
                                          [field]="field"
                                          [readonly]="readonly"
                                          [isFirst]="isFirst"
                                          [isLast]="isLast"
                                          (fieldChange)="onFieldChange($event, field.position)"
                                          (moveUp)="onMoveUp(field.position)"
                                          (moveDown)="onMoveDown(field.position)"
                                          (delete)="onDelete(field.position)">
        </nexnox-web-forms-form-field-edit>
      </div>
    </div>

    <span *ngIf="!row.fields?.length" class="text-muted">{{ 'core-shared.shared.table.empty' | translate }}</span>
  </p-panel>

  <nexnox-web-forms-create-form-field-sidebar #createFormFieldSidebarComponent
                                              (createFormField)="onCreateField($event)">
  </nexnox-web-forms-create-form-field-sidebar>
</div>
