import {PagedEntitiesXsStoreState} from '@nexnox-web/core-store';
import {AppEntityType, OccurredErrorListDto} from '@nexnox-web/core-shared';
import {Action, ActionReducer, createSelector} from '@ngrx/store';
import {selectResourcesState} from '../../resources.selectors';
import {TechPortalFeatureResourceOccurredErrorsService} from '../../services';
import {cloneDeep} from 'lodash';
import {
  ResourceOccurredErrorsListXsStore,
  ResourceOccurredErrorsListXsStoreState
} from "./resource-occurred-errors.xs-store";

export interface LocalOccurredErrorListDto extends OccurredErrorListDto {
  errorCodeId?: number;
  displayName?: string;
  externalId?: string;
}

export interface ResourceOccurredErrorsStoreState extends PagedEntitiesXsStoreState<OccurredErrorListDto, LocalOccurredErrorListDto> {
}

/* istanbul ignore next */
export const resourceOccurredErrorsListStore = new ResourceOccurredErrorsListXsStore({
  actionLabel: 'Tech Portal - Resources - Resource Occurred Errors List',
  stateSelector: createSelector(selectResourcesState, state => state.resourceOccurredErrorsList),
  entityType: AppEntityType.None,
  serviceType: TechPortalFeatureResourceOccurredErrorsService,
  selectId: error => error.entity?.error.errorCodeId ?? error.model?.error?.errorCodeId,
  prepareModel: (entity: OccurredErrorListDto, model: LocalOccurredErrorListDto, base: (entity: OccurredErrorListDto, model: LocalOccurredErrorListDto) => LocalOccurredErrorListDto) => {
    const newModel = cloneDeep(base(entity, model));

    newModel.errorCodeId = newModel?.error?.errorCodeId;
    newModel.displayName = newModel?.error?.displayName;
    newModel.externalId = newModel?.error?.externalId;

    return newModel;
  }
});

export function resourceOccurredErrorListStoreReducer(state: ResourceOccurredErrorsListXsStoreState, action: Action): ActionReducer<any> {
  return resourceOccurredErrorsListStore.reducer(state, action);
}

