import { RouterModule, Routes } from '@angular/router';
import { PrioritySetDetailComponent, PrioritySetListComponent } from './containers';
import { NgModule } from '@angular/core';
import { I18nBreadcrumbResolver, UnsavedChangesGuard } from '@nexnox-web/core-shared';

export const TECH_PORTAL_PRIORITY_SETS_ROUTES: Routes = [
  {
    path: '',
    component: PrioritySetListComponent
  },
  {
    path: ':prioritySetId',
    component: PrioritySetDetailComponent,
    canDeactivate: [UnsavedChangesGuard],
    data: {
      breadcrumbs: I18nBreadcrumbResolver,
      tab: 'prioritySet',
      text: 'core-portal.core.header.breadcrumbs.settings.ticket-settings.priority-set'
    }
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(TECH_PORTAL_PRIORITY_SETS_ROUTES)
  ],
  exports: [
    RouterModule
  ]
})
export class PrioritySetsRoutingModule {
}
