import {ModuleWithProviders, NgModule} from "@angular/core";
import {GdprDeclarationsModule} from "./gdpr-declarations.module";
import {GdprRoutingModule} from "./gdpr-routing.module";
import {GdprStoreModule} from "./gdpr-store.module";

@NgModule({
  imports: [GdprRoutingModule, GdprDeclarationsModule, GdprStoreModule]
})
export class CorePortalGdprModule {
  public static forFeature(): ModuleWithProviders<GdprDeclarationsModule> {
    return {ngModule: GdprDeclarationsModule};
  }
}
