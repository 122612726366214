import { BaseXsStoreEffects } from '../base';
import { Directive, Injector, OnDestroy, Type } from '@angular/core';
import { createEffect, ofType } from '@ngrx/effects';
import {
  AppEntityType,
  ContextCloak,
  CoreSharedApiBaseService,
  CoreSharedLocalStorageService,
  FilterDto,
  PageableRequest,
  SortObject
} from '@nexnox-web/core-shared';
import { catchError, groupBy, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { Observable, of, Subscription } from 'rxjs';
import { PagedEntitiesXsStoreActions } from './paged-entities-xs-store.actions';
import { Action, MemoizedSelector, select } from '@ngrx/store';
import { PagedEntitiesXsStoreSelectors } from './paged-entities-xs-store.selectors';
import { PagedEntitiesXsStoreState } from './paged-entities-xs-store.state';
import { CORE_STORE_CHANGE_TENANT_ACTION, CORE_STORE_TENANT_ID_SELECTOR } from '../../tokens';
import { PagedEntitiesXsStoreCreateOneSuccessPayload, PagedEntitiesXsStoreGetPageParentPayload } from './paged-entities-xs-store.payloads';
import { AnyAction } from '../../types';
import { PagedEntitiesXsStoreOptions } from './paged-entities.xs-store';

@Directive()
export abstract class PagedEntitiesXsStoreEffects<E, M = E, S = PagedEntitiesXsStoreState<E, M>> extends BaseXsStoreEffects
  implements OnDestroy {
  public getPage$: any;
  public appendPage$: any;
  public getStereotypes$: any;

  public createOne$: any;
  public createOneSuccess$: any;

  public addOneToParent$: any;
  public addOneToParentSuccess$: any;

  public removeOneFromParent$: any;
  public removeOneFromParentSuccess$: any;

  public deleteOne$: any;
  public deleteOneSuccess$: any;

  public changeRole$: any;

  protected service: CoreSharedApiBaseService;
  protected localStorageService: CoreSharedLocalStorageService;
  protected tenantIdSelector: MemoizedSelector<any, number>;
  protected changeRoleAction: AnyAction;

  protected createWithParentIds = true;

  private roleIdSubscription: Subscription;

  protected constructor(
    protected injector: Injector,
    protected actions: PagedEntitiesXsStoreActions<E, M>,
    protected selectors: PagedEntitiesXsStoreSelectors<E, M, S>,
    serviceType: Type<CoreSharedApiBaseService>,
    protected entityType: AppEntityType,
    protected prepareEntity: (entity: E) => E,
    protected prepareModel: (entity: E, model: M) => M,
    protected sanitizeModel: (model: M, entity: E) => E,
    protected stereotyped: boolean,
    protected options?: PagedEntitiesXsStoreOptions<E, M>,
    createEffects: boolean = true
  ) {
    super(injector, actions, selectors, false);

    this.service = injector.get(serviceType);
    this.localStorageService = injector.get(CoreSharedLocalStorageService);
    this.tenantIdSelector = injector.get(CORE_STORE_TENANT_ID_SELECTOR);
    this.changeRoleAction = injector.get(CORE_STORE_CHANGE_TENANT_ACTION);

    if (createEffects) {
      this.createEffects();
    }
  }

  public ngOnDestroy(): void {
    if (this.roleIdSubscription && !this.roleIdSubscription.closed) this.roleIdSubscription.unsubscribe();
  }

  protected createEffects(): void {
    super.createEffects();

    this.getPage$ = createEffect(() => this.actions$.pipe(
      ofType(this.actions.getPage),
      withLatestFrom(this.store.pipe(select(this.selectors.selectStereotypesLoaded))),
      map(([action, stereotypesLoaded]) => {
        if (!stereotypesLoaded && this.stereotyped) {
          this.store.dispatch(this.actions.getStereotypes());
        }

        return action;
      }),
      switchMap(({
                   pageNumber,
                   sortOptions,
                   filters,
                   parent,
                   pageSize,
                   customColumns,
                   optionalColumns,
                   contextCloak
                 }) => this.getPage(
        pageNumber,
        sortOptions,
        filters,
        parent,
        pageSize,
        customColumns,
        optionalColumns,
        contextCloak
      ).pipe(
        map(({ items, paging }) => this.actions.getPageSuccess({
          items: items.map(item => ({
            entity: this.prepareEntity(item),
            model: this.prepareModel(this.prepareEntity(item), {} as any)
          })),
          paging
        })),
        catchError(error => of(this.actions.error({ error, action: this.actions.getPage })))
      ))
    ));

    this.appendPage$ = createEffect(() => this.actions$.pipe(
      ofType(this.actions.appendPage),
      switchMap(({ pageNumber, parent, pageSize, filters, sortOptions, customColumns, optionalColumns, contextCloak }) => this.getPage(
        pageNumber,
        sortOptions,
        filters,
        parent,
        pageSize,
        customColumns,
        optionalColumns,
        contextCloak
      ).pipe(
        map(({ items, paging }) => this.actions.appendPageSuccess({
          items: items.map(item => ({
            entity: this.prepareEntity(item),
            model: this.prepareModel(this.prepareEntity(item), {} as any)
          })),
          paging
        })),
        catchError(error => of(this.actions.error({ error, action: this.actions.appendPage })))
      ))
    ));

    this.getStereotypes$ = createEffect(() => this.actions$.pipe(
      ofType(this.actions.getStereotypes),
      switchMap(() => this.service.getStereotypes(this.entityType, false).pipe(
        map(stereotypes => this.actions.getStereotypesSuccess({ stereotypes })),
        catchError(error => of(this.actions.error({ error, action: this.actions.getStereotypes })))
      ))
    ));

    this.createOne$ = createEffect(() => this.actions$.pipe(
      ofType(this.actions.createOne),
      withLatestFrom(this.store.pipe(select(this.tenantIdSelector))),
      switchMap(([{ model, parentIds }, tenantId]) => this.createOne(model, tenantId, parentIds).pipe(
        map(entity => this.actions.createOneSuccess({
          entity: this.prepareEntity(entity),
          model: this.prepareModel(this.prepareEntity(entity), {} as any),
          parentIds
        })),
        catchError(error => of(this.actions.error({ error, action: this.actions.createOne })))
      ))
    ));

    this.createOneSuccess$ = createEffect(() => this.actions$.pipe(
      ofType(this.actions.createOneSuccess),
      tap(action => this.actionCallback(action, false))
    ), { dispatch: false });

    this.addOneToParent$ = createEffect(() => this.actions$.pipe(
      ofType(this.actions.addOneToParent),
      groupBy(({ id }) => id),
      mergeMap(group => group.pipe(
        switchMap(({ parentIds, id }) => this.service.addOneToParent(parentIds, id).pipe(
          map(() => this.actions.addOneToParentSuccess({ parentIds, id })),
          catchError(error => of(this.actions.error({ error, action: this.actions.addOneToParent })))
        ))
      ))
    ));

    this.addOneToParentSuccess$ = createEffect(() => this.actions$.pipe(
      ofType(this.actions.addOneToParentSuccess),
      tap(action => this.actionCallback(action, false))
    ), { dispatch: false });

    this.removeOneFromParent$ = createEffect(() => this.actions$.pipe(
      ofType(this.actions.removeOneFromParent),
      groupBy(({ id }) => id),
      mergeMap(group => group.pipe(
        switchMap(({ parentIds, id }) => this.service.removeOneFromParent(parentIds, id).pipe(
          map(() => this.actions.removeOneFromParentSuccess({ parentIds, id })),
          catchError(error => of(this.actions.error({ error, action: this.actions.removeOneFromParent })))
        ))
      ))
    ));

    this.removeOneFromParentSuccess$ = createEffect(() => this.actions$.pipe(
      ofType(this.actions.removeOneFromParentSuccess),
      tap(action => this.actionCallback(action, false))
    ), { dispatch: false });

    this.deleteOne$ = createEffect(() => this.actions$.pipe(
      ofType(this.actions.deleteOne),
      switchMap(({ id, parentIds }) => this.service.deleteOne(id, parentIds).pipe(
        map(() => this.actions.deleteOneSuccess({ id })),
        catchError(error => of(this.actions.error({ error, action: this.actions.deleteOne })))
      ))
    ));

    this.deleteOneSuccess$ = createEffect(() => this.actions$.pipe(
      ofType(this.actions.deleteOneSuccess),
      tap(action => this.actionCallback(action))
    ), { dispatch: false });

    this.changeRole$ = createEffect(() => this.actions$.pipe(
      ofType(this.changeRoleAction),
      tap(() => this.onRoleChanged())
    ), { dispatch: false });
  }

  protected getPage(
    pageNumber: number,
    sortOptions: SortObject,
    filters: FilterDto[],
    parent: PagedEntitiesXsStoreGetPageParentPayload,
    pageSize: number,
    customColumns: number[] = [],
    optionalColumns: string[] = [],
    contextCloak?: ContextCloak
  ): Observable<PageableRequest<E>> {
    return this.service.getPage<E>(
      sortOptions,
      pageNumber,
      filters,
      parent?.filterOperation ?? undefined,
      customColumns,
      optionalColumns,
      pageSize,
      parent?.parentIds ?? [],
      undefined,
      undefined,
      contextCloak
    );
  }

  protected createOne(model: M, tenantId: number | string, parentIds: Array<number | string> = []): Observable<E> {
    const sanitizedModel = this.prepareModelForCreate(model, tenantId);
    return this.service.createOne(sanitizedModel, this.createWithParentIds ? parentIds : undefined);
  }

  protected prepareModelForCreate(model: M, tenantId: number | string): E {
    const sanitizedModel = this.sanitizeModel({ ...model, rowVersion: [] }, {} as any);

    if (!this.options?.removeTenantIdOnCreate) {
      sanitizedModel['tenantId'] = tenantId;
    }

    return sanitizedModel;
  }

  protected actionCallback(action: Action, isError: boolean = false): void {
    super.actionCallback(action, isError);

    this.checkAction(this.actions.createOneSuccess, action, payload => this.createOneSuccessActionCallback(payload));
    this.checkAction(this.actions.deleteOneSuccess, action, () => this.deleteOneSuccessActionCallback());
    this.checkAction(this.actions.addOneToParentSuccess, action, () => this.addOneSuccessActionCallback());
    this.checkAction(this.actions.removeOneFromParentSuccess, action, () => this.removeOneSuccessActionCallback());
  }

  protected createOneSuccessActionCallback(payload: PagedEntitiesXsStoreCreateOneSuccessPayload<E, M>): void {
    this.apiNotificationService.showTranslatedSuccess('core-shared.shared.toast.entity-created');
  }

  protected deleteOneSuccessActionCallback(): void {
    this.apiNotificationService.showTranslatedSuccess('core-shared.shared.toast.entity-deleted');
  }

  protected addOneSuccessActionCallback(): void {
    this.apiNotificationService.showTranslatedSuccess('core-shared.shared.toast.entity-added');
  }

  protected removeOneSuccessActionCallback(): void {
    this.apiNotificationService.showTranslatedSuccess('core-shared.shared.toast.entity-removed');
  }

  protected onRoleChanged(): void {
    this.store.dispatch(this.actions.clear());
  }
}
