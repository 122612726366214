import {
  issueListStore,
  messageControlIssueDetailStore,
  messageControlIssueDetailStoreReducer,
  messageControlIssueListStore,
  messageIssueDetailStore,
  messageIssueDetailStoreReducer,
  messageIssueListStore,
  missionIssueDetailStore,
  missionIssueDetailStoreReducer,
  missionIssueListStore,
  ticketIssueDetailStore,
  ticketIssueDetailStoreReducer,
  ticketIssueListStore,
  winterhalterProductOrderIssueDetailStore,
  winterhalterProductOrderIssueDetailStoreReducer,
  winterhalterProductOrderIssueListStore,
  winterhalterServiceCallIssueDetailStore,
  winterhalterServiceCallIssueDetailStoreReducer,
  winterhalterServiceCallIssueListStore
} from './stores';
import {
  MessageControlIssueService,
  MessageIssueService,
  MissionIssueService,
  TechPortalFeatureIssueListService,
  TicketIssueService,
  WinterhalterProductOrderIssueService,
  WinterhalterServiceCallIssueService
} from "./services";

export * from './stores';
export * from './services';

export * from './issues.state';
export * from './issues.selectors';

export const issuesReducers = {
  issueList: issueListStore.reducer,
  messageControlIssueList: messageControlIssueListStore.reducer,
  messageIssueList: messageIssueListStore.reducer,
  missionIssueList: missionIssueListStore.reducer,
  ticketIssueList: ticketIssueListStore.reducer,
  winterhalterServiceCallIssueList: winterhalterServiceCallIssueListStore.reducer,
  winterhalterProductOrderIssueList: winterhalterProductOrderIssueListStore.reducer,

  messageControlIssueDetail: messageControlIssueDetailStoreReducer,
  messageIssueDetail: messageIssueDetailStoreReducer,
  missionIssueDetail: missionIssueDetailStoreReducer,
  ticketIssueDetail: ticketIssueDetailStoreReducer,
  winterhalterServiceCallIssueDetail: winterhalterServiceCallIssueDetailStoreReducer,
  winterhalterProductOrderIssueDetail: winterhalterProductOrderIssueDetailStoreReducer
};

export const issuesEffects = [
  issueListStore.effects,
  messageControlIssueListStore.effects,
  messageIssueListStore.effects,
  missionIssueListStore.effects,
  ticketIssueListStore.effects,
  winterhalterServiceCallIssueListStore.effects,
  winterhalterProductOrderIssueListStore.effects,

  messageControlIssueDetailStore.effects,
  messageIssueDetailStore.effects,
  missionIssueDetailStore.effects,
  ticketIssueDetailStore.effects,
  winterhalterServiceCallIssueDetailStore.effects,
  winterhalterProductOrderIssueDetailStore.effects
];

export const issuesServices = [
  TechPortalFeatureIssueListService,

  MessageIssueService,
  MessageControlIssueService,
  MissionIssueService,
  TicketIssueService,
  WinterhalterServiceCallIssueService,
  WinterhalterProductOrderIssueService
];
