import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren
} from '@angular/core';
import { LocalFormFieldDto, LocalFormRowDto } from '../../models';
import {
  CorePortalCardboxAction,
  CorePortalFormlyReadonlyTypes,
  CorePortalFormlyReadonlyTyping,
  CorePortalFormlyTranslatedTyping
} from '@nexnox-web/core-portal';
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import {AppPermissions, FormRowTypes, UnsubscribeHelper, UuidService} from '@nexnox-web/core-shared';
import { merge, Observable } from 'rxjs';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons/faArrowUp';
import { faArrowDown } from '@fortawesome/free-solid-svg-icons/faArrowDown';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons/faTrashAlt';
import { cloneDeep, isNumber, maxBy, minBy } from 'lodash';
import { faRetweet } from '@fortawesome/free-solid-svg-icons/faRetweet';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { FormFieldEditComponent } from '../form-field-edit/form-field-edit.component';
import { CreateFormFieldEvent, CreateFormFieldSidebarComponent } from '../../sidebars';
import { BindObservable } from 'bind-observable';
import { map, switchMap } from 'rxjs/operators';

@Component({
  selector: 'nexnox-web-forms-form-row-edit',
  templateUrl: './form-row-edit.component.html',
  styleUrls: ['./form-row-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormRowEditComponent extends UnsubscribeHelper implements OnInit {
  @Input() @BindObservable() public row: LocalFormRowDto;
  public row$!: Observable<LocalFormRowDto>;

  @Input() @BindObservable() public readonly: boolean;
  public readonly$!: Observable<boolean>;

  @Input() public position: number;

  @Input() @BindObservable() public isFirst: boolean;
  public isFirst$!: Observable<boolean>;

  @Input() @BindObservable() public isLast: boolean;
  public isLast$!: Observable<boolean>;

  @ViewChild('titleEditInput') public titleEditInput: ElementRef<HTMLInputElement>;
  @ViewChild('createFormFieldSidebarComponent', { static: true }) public createFormFieldSidebarComponent: CreateFormFieldSidebarComponent;

  @ViewChildren('formFieldEditComponent') public formFieldEditComponents: QueryList<FormFieldEditComponent>;

  @Output() public rowChange: EventEmitter<LocalFormRowDto> = new EventEmitter<LocalFormRowDto>();
  @Output() public moveUp: EventEmitter<void> = new EventEmitter<void>();
  @Output() public moveDown: EventEmitter<void> = new EventEmitter<void>();
  @Output() public delete: EventEmitter<void> = new EventEmitter<void>();

  public headerActions: CorePortalCardboxAction[];
  public trackFieldsBy: any;

  public repeatRowForm: FormGroup;
  public repeatRowFields: FormlyFieldConfig[];

  public formRowTypes = FormRowTypes;

  /* istanbul ignore next */
  constructor(private uuidService: UuidService) {
    super();

    this.headerActions = [
      {
        icon: faPlus,
        class: 'p-button-primary',
        tooltip: 'orga-portal.forms.actions.create-form-field',
        permission: AppPermissions.CreateFormField,
        shouldShow: () => this.readonly$.pipe(
          map(readonly => !readonly)
        ),
        callback: () => this.createFormFieldSidebarComponent.onShow()
      },
      {
        icon: faRetweet,
        class: 'p-button-secondary',
        tooltip: 'orga-portal.forms.actions.repeat-form-row',
        permission: AppPermissions.UpdateFormRow,
        shouldShow: () => this.readonly$.pipe(
          switchMap(readonly => this.row$.pipe(
            map(row => !readonly && row.type !== FormRowTypes.Repeatable)
          ))
        ),
        callback: () => this.rowChange.emit({ ...this.row, type: FormRowTypes.Repeatable })
      },
      {
        icon: faRetweet,
        class: 'p-button-secondary',
        tooltip: 'orga-portal.forms.actions.single-form-row',
        permission: AppPermissions.UpdateFormRow,
        shouldShow: () => this.readonly$.pipe(
          switchMap(readonly => this.row$.pipe(
            map(row => !readonly && row.type !== FormRowTypes.Single)
          ))
        ),
        callback: () => {
          this.repeatRowForm.reset({
            repeatLabel: ''
          });
          this.repeatRowForm.markAsPristine();
          this.rowChange.emit({ ...this.row, type: FormRowTypes.Single });
        }
      },
      {
        icon: faArrowUp,
        class: 'p-button-secondary',
        tooltip: 'core-shared.shared.actions.move-up',
        permission: AppPermissions.UpdateFormRow,
        shouldShow: () => this.readonly$.pipe(
          map(readonly => !readonly)
        ),
        isDisabled: () => this.isFirst$,
        callback: () => this.moveUp.emit()
      },
      {
        icon: faArrowDown,
        class: 'p-button-secondary',
        tooltip: 'core-shared.shared.actions.move-down',
        permission: AppPermissions.UpdateFormRow,
        shouldShow: () => this.readonly$.pipe(
          map(readonly => !readonly)
        ),
        isDisabled: () => this.isLast$,
        callback: () => this.moveDown.emit()
      },
      {
        icon: faTrashAlt,
        class: 'p-button-danger',
        tooltip: 'orga-portal.forms.actions.delete-form-row',
        permission: AppPermissions.DeleteFormRow,
        shouldShow: () => this.readonly$.pipe(
          map(readonly => !readonly)
        ),
        callback: () => this.delete.emit()
      }
    ];
    this.trackFieldsBy = (index: number, field: LocalFormFieldDto) => field.formFieldId ?? field.localFormFieldId;
  }

  public ngOnInit(): void {
    this.createRepeatRowForm();
  }

  public onTitleChange(title: string): void {
    this.onRowChange({ ...this.row, title });
  }

  public onRowChange(row: LocalFormRowDto): void {
    this.rowChange.emit(row);
  }

  public onFieldChange(field: LocalFormFieldDto, position: number): void {
    const newFields = cloneDeep(this.row.fields) ?? [];
    const index = newFields.findIndex(x => x.position === position);
    newFields[index] = field;
    this.onRowChange({ ...this.row, fields: newFields });
  }

  public onCreateField(event: CreateFormFieldEvent): void {
    const newFields = cloneDeep(this.row.fields) ?? [];
    const maxIdProperty = newFields.length ? maxBy(newFields, x => x.formFieldId ?? x.localFormFieldId) : null;
    newFields.push({
      localFormFieldId: this.uuidService.generateUuid(),
      title: event.title,
      type: event.type,
      width: 6,
      position: newFields.length ? maxBy(newFields, x => x.position).position + 1 : 1
    });
    this.onRowChange({ ...this.row, fields: newFields });
  }

  public onMoveUp(position: number): void {
    const newFields = cloneDeep(this.row.fields) ?? [];
    const index = newFields.findIndex(x => x.position === position);
    const previousIndex = newFields.findIndex(
      x => x.position === maxBy(newFields.filter(y => y.position < position), y => y.position)?.position
    );

    newFields[index].position--;
    newFields[previousIndex].position++;
    this.onRowChange({ ...this.row, fields: newFields });
  }

  public onMoveDown(position: number): void {
    const newFields = cloneDeep(this.row.fields) ?? [];
    const index = newFields.findIndex(x => x.position === position);
    const nextIndex = newFields.findIndex(
      x => x.position === minBy(newFields.filter(y => y.position > position), y => y.position)?.position
    );

    newFields[nextIndex].position--;
    newFields[index].position++;
    this.onRowChange({ ...this.row, fields: newFields });
  }

  public onDelete(position: number): void {
    const newFields = cloneDeep(this.row.fields) ?? [];
    const index = newFields.findIndex(x => x.position === position);
    const nextIndexes = newFields
      .filter(x => x.position > position)
      .map(x => newFields.findIndex(y => y.position === x.position));

    for (const nextIndex of nextIndexes) {
      newFields[nextIndex].position--;
    }
    newFields.splice(index, 1);

    this.onRowChange({ ...this.row, fields: newFields });
  }

  public isModelValid(): boolean {
    if (this.readonly) return true;

    return Boolean(this.titleEditInput?.nativeElement?.checkValidity()) &&
      Boolean(this.repeatRowForm.valid) &&
      this.formFieldEditComponents.toArray().every(x => x.isModelValid());
  }

  /* istanbul ignore next */
  private createRepeatRowFields(): FormlyFieldConfig[] {
    const updateFieldHook = (field: FormlyFieldConfig, fields: string[]): void => {
      const fields$: Observable<any>[] = fields.map(x => field.form.get(x).valueChanges);
      this.subscribe(merge(...fields$), () => {
        field.formControl.markAsTouched();
        field.formControl.updateValueAndValidity({ emitEvent: false });
      });
    };

    return [
      {
        key: 'minRepeat',
        type: 'input',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-3 pl-0 pr-0 pr-md-2 field-group-mb-0',
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.min',
            validationMessages: {
              positive: { key: 'core-portal.core.validation.min', args: { min: 0 } },
              max: { key: 'core-portal.core.validation.max', args: { max: null } }
            }
          } as CorePortalFormlyTranslatedTyping,
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.BASIC
          } as CorePortalFormlyReadonlyTyping,
          type: 'number'
        },
        expressionProperties: {
          'templateOptions.disabled': () => this.readonly,
          'templateOptions.readonly': () => this.readonly,
          'templateOptions.corePortalTranslated.validationMessages.max.args': () => ({ max: this.row?.maxRepeat })
        },
        validators: {
          positive: ctrl => !isNumber(ctrl.value) ? true : ctrl.value >= 0,
          max: ctrl => !isNumber(ctrl.value) ? true : (isNumber(this.row?.maxRepeat) ? ctrl.value <= this.row.maxRepeat : true)
        },
        hooks: {
          onInit: field => updateFieldHook(field, ['maxRepeat'])
        }
      },
      {
        key: 'maxRepeat',
        type: 'input',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-3 pl-0 pl-md-2 pr-0 pr-md-2 field-group-mb-0',
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.max',
            validationMessages: {
              positive: { key: 'core-portal.core.validation.min', args: { min: 0 } },
              min: { key: 'core-portal.core.validation.min', args: { min: null } }
            }
          } as CorePortalFormlyTranslatedTyping,
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.BASIC
          } as CorePortalFormlyReadonlyTyping,
          type: 'number'
        },
        expressionProperties: {
          'templateOptions.disabled': () => this.readonly,
          'templateOptions.readonly': () => this.readonly,
          'templateOptions.corePortalTranslated.validationMessages.min.args': () => ({ min: this.row?.minRepeat })
        },
        validators: {
          positive: ctrl => !isNumber(ctrl.value) ? true : ctrl.value >= 0,
          min: ctrl => !isNumber(ctrl.value) ? true : (isNumber(this.row?.minRepeat) ? ctrl.value >= this.row.minRepeat : true)
        },
        hooks: {
          onInit: field => updateFieldHook(field, ['minRepeat'])
        }
      },
      {
        key: 'repeatLabel',
        type: 'input',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-6 pl-0 pl-md-2 pr-0 field-group-mb-0',
        templateOptions: {
          corePortalTranslated: {
            label: 'orga-portal.forms.fields.repeat-label'
          } as CorePortalFormlyTranslatedTyping,
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.BASIC
          } as CorePortalFormlyReadonlyTyping,
          type: 'text'
        },
        expressionProperties: {
          'templateOptions.disabled': () => this.readonly,
          'templateOptions.readonly': () => this.readonly
        }
      }
    ];
  }

  private createRepeatRowForm(): void {
    this.repeatRowForm = new FormGroup({});
    this.repeatRowFields = this.createRepeatRowFields();
  }
}
