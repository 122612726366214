import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { DailyDateTimePatternDto, DateTimePatternV1Dto, TimePatterns, UnsubscribeHelper } from '@nexnox-web/core-shared';
import {
  CorePortalFormlyDatepickerTyping,
  CorePortalFormlyMultiToggleTyping,
  CorePortalFormlyReadonlyTypes,
  CorePortalFormlyReadonlyTyping,
  CorePortalFormlyTranslatedTyping,
  CoreSharedRuleEditorListComponent,
  InheritedField,
  ModelValid
} from '@nexnox-web/core-portal';
import { TranslateService } from '@ngx-translate/core';
import dayjs from 'dayjs';
import { distinctUntilChanged, skip, startWith } from 'rxjs/operators';
import { WeekDay } from '@angular/common';
import { FormControl } from '@angular/forms';

const patternTypeEnumOptions = [
  { label: 'orga-portal.tasks.execution-pattern-types.1', value: TimePatterns.Single },
  { label: 'orga-portal.tasks.execution-pattern-types.2', value: TimePatterns.Daily },
  { label: 'orga-portal.tasks.execution-pattern-types.3', value: TimePatterns.Weekly },
  { label: 'orga-portal.tasks.execution-pattern-types.4', value: TimePatterns.MonthlyDay },
  { label: 'orga-portal.tasks.execution-pattern-types.5', value: TimePatterns.MonthlyWeekDay }
];

const weekDayEnumOptions = [
  { label: 'core-shared.shared.week-days.monday', value: WeekDay.Monday },
  { label: 'core-shared.shared.week-days.tuesday', value: WeekDay.Tuesday },
  { label: 'core-shared.shared.week-days.wednesday', value: WeekDay.Wednesday },
  { label: 'core-shared.shared.week-days.thursday', value: WeekDay.Thursday },
  { label: 'core-shared.shared.week-days.friday', value: WeekDay.Friday },
  { label: 'core-shared.shared.week-days.saturday', value: WeekDay.Saturday },
  { label: 'core-shared.shared.week-days.sunday', value: WeekDay.Sunday }
];

const weekDayCountEnumOptions = [
  { label: 'orga-portal.tasks.week-day-count-types.1', value: 1 },
  { label: 'orga-portal.tasks.week-day-count-types.2', value: 2 },
  { label: 'orga-portal.tasks.week-day-count-types.3', value: 3 },
  { label: 'orga-portal.tasks.week-day-count-types.4', value: 4 },
  { label: 'orga-portal.tasks.week-day-count-types.5', value: 5 }
];

@Component({
  selector: 'nexnox-web-tasks-task-executions',
  templateUrl: './task-executions.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TaskExecutionsComponent extends UnsubscribeHelper implements ModelValid {
  @Input() public executionPatterns$: Observable<DateTimePatternV1Dto[]>;

  @Input() public readonly: boolean;
  @Input() public loading: boolean;

  @ViewChild('executionPatternsEdit') public executionPatternsEdit: CoreSharedRuleEditorListComponent;

  @Output() public executionPatternsChange: EventEmitter<DateTimePatternV1Dto[]> = new EventEmitter<DateTimePatternV1Dto[]>();

  public executionPatternTitleFn: any;
  public executionPatternFieldsFn: any;

  constructor(
    private translate: TranslateService
  ) {
    super();

    this.executionPatternTitleFn = (executionPattern: DateTimePatternV1Dto) => {
      const typeName = this.translate.instant(`orga-portal.tasks.execution-pattern-types.${executionPattern.type}`);
      return `${this.translate.instant('orga-portal.tasks.subtitles.execution')} (${typeName})`;
    };
    this.executionPatternFieldsFn = () => this.createExecutionPatternFields();
  }

  public onExecutionPatternsChange(executionPatterns: DateTimePatternV1Dto[]): void {
    this.executionPatternsChange.emit(executionPatterns);
  }

  public onReset(): void {
    this.executionPatternsEdit?.ngOnInit();
  }

  public isModelValid(): boolean {
    return this.executionPatternsEdit ? this.executionPatternsEdit.areItemsValid() : true;
  }

  public isOwnModelValid(): boolean {
    return this.executionPatternsEdit ? this.executionPatternsEdit.isModelValid() : true;
  }

  public isOwnModelPristine(): boolean {
    return this.executionPatternsEdit ? this.executionPatternsEdit.isPristine() : true;
  }

  /* istanbul ignore next */
  public createExecutionPatternFields(): InheritedField[] {
    return [
      {
        formlyConfig: {
          type: 'core-portal-ng-select',
          wrappers: ['core-portal-translated', 'core-portal-readonly'],
          className: 'col-md-12',
          defaultValue: TimePatterns.Single,
          templateOptions: {
            corePortalTranslated: {
              label: 'core-shared.shared.fields.kind',
              validationMessages: {
                required: 'core-portal.core.validation.required'
              }
            },
            corePortalReadonly: {
              type: CorePortalFormlyReadonlyTypes.ENUM,
              enumOptions: patternTypeEnumOptions,
              translate: true
            } as CorePortalFormlyReadonlyTyping,
            corePortalNgSelect: {
              items: patternTypeEnumOptions,
              translate: true
            }
          },
          expressionProperties: {
            'templateOptions.required': () => !this.readonly,
            'templateOptions.readonly': () => this.readonly
          },
          hooks: {
            onInit: field => this.subscribe(field.formControl.valueChanges.pipe(
              startWith(field.formControl.value),
              distinctUntilChanged(),
              skip(1)
            ), () => {
              field.form.controls.date?.setValue(null);
              field.form.controls.dateRange?.setValue(null);
              field.form.controls.repeat?.setValue(null);
              field.form.controls.day?.setValue(null);
              field.form.controls.useOpeningDays?.setValue(false);
              field.form.controls.useNextOpeningDay?.setValue(false);
              field.form.controls.weekDays?.setValue(null);
              field.form.get('plannedTimes.0.time')?.setValue(null);
            })
          }
        },
        ownValueKey: 'type',
        hide: (_, creating) => !creating
      },

      // Single
      {
        formlyConfig: {
          type: 'core-portal-datepicker',
          wrappers: ['core-portal-translated', 'core-portal-readonly'],
          className: 'col-md-9',
          templateOptions: {
            corePortalTranslated: {
              label: 'core-shared.shared.fields.date',
              validationMessages: {
                required: 'core-portal.core.validation.required'
              }
            },
            corePortalReadonly: {
              type: CorePortalFormlyReadonlyTypes.DATE,
              format: 'LL'
            } as CorePortalFormlyReadonlyTyping,
            noUtc: true
          },
          expressionProperties: {
            'templateOptions.required': (model: DateTimePatternV1Dto) => !this.readonly && model.type === TimePatterns.Single,
            'templateOptions.readonly': () => this.readonly
          }
        },
        ownValueKey: 'date',
        hide: (_, __, model?: DateTimePatternV1Dto) => !model || model.type !== TimePatterns.Single
      },

      // Daily, Weekly, Monthly
      {
        formlyConfig: {
          type: 'core-portal-datepicker',
          wrappers: ['core-portal-translated'],
          className: 'col-md-6',
          templateOptions: {
            corePortalTranslated: {
              label: 'core-shared.shared.fields.date',
              validationMessages: {
                required: 'core-portal.core.validation.date-range'
              }
            },
            corePortalDatepicker: {
              isRange: true,
              numberOfMonths: 2
            } as CorePortalFormlyDatepickerTyping,
            noUtc: true,
            minDate: dayjs().startOf('day').toDate()
          },
          expressionProperties: {
            'className': (model: DateTimePatternV1Dto) => {
              switch (model.type) {
                case TimePatterns.Weekly:
                case TimePatterns.MonthlyDay:
                  return 'col-md-6';
                default:
                  return 'col-md-12';
              }
            },

            'templateOptions.required': () => !this.readonly
          }
        },
        ownValueKey: 'dateRange',
        hide: (_, __, model?: DateTimePatternV1Dto) => !model ||
          (
            model.type !== TimePatterns.Daily &&
            model.type !== TimePatterns.Weekly &&
            model.type !== TimePatterns.MonthlyDay &&
            model.type !== TimePatterns.MonthlyWeekDay
          )
      },

      // Monthly (Week)
      {
        formlyConfig: {
          type: 'core-portal-ng-select',
          wrappers: ['core-portal-translated', 'core-portal-readonly'],
          className: 'col-md-3',
          defaultValue: 1,
          templateOptions: {
            corePortalTranslated: {
              label: 'orga-portal.tasks.fields.weekday',
              validationMessages: {
                required: 'core-portal.core.validation.required'
              }
            },
            corePortalReadonly: {
              type: CorePortalFormlyReadonlyTypes.ENUM,
              enumOptions: weekDayCountEnumOptions,
              translate: true
            } as CorePortalFormlyReadonlyTyping,
            corePortalNgSelect: {
              items: weekDayCountEnumOptions,
              translate: true
            }
          },
          expressionProperties: {
            'templateOptions.required': () => !this.readonly,
            'templateOptions.readonly': () => this.readonly
          },
        },
        ownValueKey: 'weekdayCount',
        hide: (_, __, model?: DailyDateTimePatternDto) => !model || model.type !== TimePatterns.MonthlyWeekDay
      },
      {
        formlyConfig: {
          type: 'core-portal-ng-select',
          wrappers: ['core-portal-translated', 'core-portal-readonly'],
          className: 'col-md-9',
          defaultValue: WeekDay.Monday,
          templateOptions: {
            corePortalTranslated: {
              label: 'orga-portal.tasks.fields.weekday',
              labelClass: 'd-none d-md-block invisible',
              validationMessages: {
                required: 'core-portal.core.validation.required'
              }
            } as CorePortalFormlyTranslatedTyping,
            corePortalReadonly: {
              type: CorePortalFormlyReadonlyTypes.ENUM,
              enumOptions: weekDayEnumOptions,
              translate: true
            } as CorePortalFormlyReadonlyTyping,
            corePortalNgSelect: {
              items: weekDayEnumOptions,
              translate: true
            }
          },
          expressionProperties: {
            'templateOptions.required': () => !this.readonly,
            'templateOptions.readonly': () => this.readonly
          },
        },
        ownValueKey: 'weekday',
        hide: (_, __, model?: DailyDateTimePatternDto) => !model || model.type !== TimePatterns.MonthlyWeekDay
      },

      // Weekly, Monthly
      {
        formlyConfig: {
          type: 'input',
          wrappers: ['core-portal-translated', 'core-portal-readonly'],
          className: 'col-md-6',
          templateOptions: {
            corePortalTranslated: {
              label: null,
              validationMessages: {
                required: 'core-portal.core.validation.required'
              }
            } as CorePortalFormlyTranslatedTyping,
            corePortalReadonly: {
              type: CorePortalFormlyReadonlyTypes.BASIC
            } as CorePortalFormlyReadonlyTyping,
            type: 'number'
          },
          expressionProperties: {
            'className': (model: DateTimePatternV1Dto) => {
              switch (model.type) {
                case TimePatterns.MonthlyWeekDay:
                  return 'col-md-12';
                default:
                  return 'col-md-6';
              }
            },

            'templateOptions.corePortalTranslated.label': (model: DateTimePatternV1Dto) => {
              switch (model.type) {
                case TimePatterns.Weekly:
                  return 'orga-portal.tasks.fields.repeat-weekly';
                case TimePatterns.MonthlyDay:
                case TimePatterns.MonthlyWeekDay:
                  return 'orga-portal.tasks.fields.repeat-monthly';
                default:
                  return null;
              }
            },

            'templateOptions.required': () => !this.readonly,
            'templateOptions.readonly': () => this.readonly
          }
        },
        ownValueKey: 'repeat',
        hide: (_, __, model?: DateTimePatternV1Dto) => !model ||
          (
            model.type !== TimePatterns.Weekly &&
            model.type !== TimePatterns.MonthlyDay &&
            model.type !== TimePatterns.MonthlyWeekDay
          )
      },

      // Monthly (Day)
      {
        formlyConfig: {
          type: 'input',
          wrappers: ['core-portal-translated', 'core-portal-readonly'],
          className: 'col-md-12',
          templateOptions: {
            corePortalTranslated: {
              label: 'orga-portal.tasks.fields.day-of-the-month',
              validationMessages: {
                required: 'core-portal.core.validation.required',
                min: { key: 'core-portal.core.validation.min', args: { min: 1 } },
                max: { key: 'core-portal.core.validation.max', args: { max: 31 } }
              }
            } as CorePortalFormlyTranslatedTyping,
            corePortalReadonly: {
              type: CorePortalFormlyReadonlyTypes.BASIC
            } as CorePortalFormlyReadonlyTyping,
            type: 'number',
            min: 1,
            max: 31
          },
          expressionProperties: {
            'templateOptions.required': () => !this.readonly,
            'templateOptions.readonly': () => this.readonly
          }
        },
        ownValueKey: 'day',
        hide: (_, __, model?: DateTimePatternV1Dto) => !model || model.type !== TimePatterns.MonthlyDay
      },

      // Daily, Weekly
      {
        formlyConfig: {
          type: 'core-portal-switch',
          wrappers: ['core-portal-translated', 'core-portal-readonly'],
          className: 'col-md-12',
          defaultValue: false,
          templateOptions: {
            corePortalTranslated: {
              label: 'orga-portal.tasks.fields.use-opening-days'
            },
            corePortalReadonly: {
              type: CorePortalFormlyReadonlyTypes.BOOLEAN
            } as CorePortalFormlyReadonlyTyping
          },
          expressionProperties: {
            'templateOptions.readonly': () => this.readonly
          }
        },
        ownValueKey: 'useOpeningDays',
        hide: (_, __, model?: DateTimePatternV1Dto) => !model ||
          (
            model.type !== TimePatterns.Daily &&
            model.type !== TimePatterns.Weekly
          )
      },

      // Daily, Weekly, Monthly
      {
        formlyConfig: {
          type: 'core-portal-switch',
          wrappers: ['core-portal-translated', 'core-portal-readonly'],
          className: 'col-md-12',
          defaultValue: false,
          templateOptions: {
            corePortalTranslated: {
              label: 'orga-portal.tasks.fields.use-next-opening-day'
            },
            corePortalReadonly: {
              type: CorePortalFormlyReadonlyTypes.BOOLEAN
            } as CorePortalFormlyReadonlyTyping
          },
          expressionProperties: {
            'templateOptions.readonly': () => this.readonly
          }
        },
        ownValueKey: 'useNextOpeningDay',
        hide: (_, __, model?: DateTimePatternV1Dto & DailyDateTimePatternDto) => !model ||
          model.useOpeningDays ||
          (
            model.type !== TimePatterns.Daily &&
            model.type !== TimePatterns.Weekly &&
            model.type !== TimePatterns.MonthlyDay &&
            model.type !== TimePatterns.MonthlyWeekDay
          )
      },

      // Daily, Weekly
      {
        formlyConfig: {
          type: 'core-portal-multi-toggle',
          wrappers: ['core-portal-translated'],
          className: 'col-md-12',
          templateOptions: {
            corePortalTranslated: {
              label: 'orga-portal.tasks.fields.week-days',
              validationMessages: {
                oneDay: 'core-portal.core.validation.one-day'
              }
            },
            corePortalMultiToggle: {
              items: weekDayEnumOptions,
              translate: true
            } as CorePortalFormlyMultiToggleTyping
          },
          expressionProperties: {
            'templateOptions.required': () => !this.readonly
          },
          validators: {
            oneDay: (ctrl: FormControl) => Boolean(ctrl.value?.length)
          }
        },
        ownValueKey: 'weekDays',
        hide: (_, __, model?: DateTimePatternV1Dto & DailyDateTimePatternDto) => !model ||
          model.useOpeningDays ||
          (
            model.type !== TimePatterns.Daily &&
            model.type !== TimePatterns.Weekly
          )
      },

      {
        formlyConfig: {
          type: 'core-portal-timepicker',
          wrappers: ['core-portal-translated', 'core-portal-readonly'],
          className: 'col-md-3',
          templateOptions: {
            corePortalTranslated: {
              label: 'core-shared.shared.fields.date-time',
              validationMessages: {
                required: 'core-portal.core.validation.required'
              }
            },
            corePortalReadonly: {
              type: CorePortalFormlyReadonlyTypes.BASIC,
              template: value => value
            } as CorePortalFormlyReadonlyTyping,
            corePortalTimepicker: {
              mode: 'timespan',
              showYears: false,
              showWeeks: false,
              showDays: false,
              showHours: true,
              showMinutes: true
            }
          },
          expressionProperties: {
            'templateOptions.required': () => !this.readonly,
            'templateOptions.readonly': () => this.readonly
          }
        },
        ownValueKey: 'plannedTimes.0.time'
      }
    ];
  }
}
