import {ChangeDetectionStrategy, Component, Injector, Input, OnInit} from '@angular/core';
import {CorePortalEntityEditBaseComponent} from '@nexnox-web/core-portal';
import {AddressDto} from '@nexnox-web/core-shared';
import {FormlyFieldConfig} from '@ngx-formly/core';
import {CorePortalFormlyGoogleMapsTyping} from 'libs/core-portal/src/lib/modules/formly/types';
import {Observable} from "rxjs";

@Component({
  selector: 'nexnox-web-master-data-addresses-address-edit',
  templateUrl: './core-portal-feature-address-edit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CorePortalFeatureAddressEditComponent extends CorePortalEntityEditBaseComponent<AddressDto> {

  constructor(
    protected injector: Injector
  ) {
    super(injector);
  }

  protected createForm(): FormlyFieldConfig[] {
    return [
      {
        key: 'point',
        type: 'core-portal-google-map',
        className: 'col-md-12',
        wrappers: ['core-portal-translated'],
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.location',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          },
          corePortalGoogleMap: {
            width: '100%',
            height: '500px',
            isAutomaticAddressKeySearch: true,
            addressKeys: ['street', 'houseNumber', 'zipcode', 'city', 'countryCode']
          } as CorePortalFormlyGoogleMapsTyping,
          type: 'text'
        },
        expressionProperties: {
          'templateOptions.required': () => !this.readonly,
          'templateOptions.disabled': () => this.readonly
        }
      },
      {
        key: 'street',
        type: 'input',
        wrappers: ['core-portal-translated'],
        className: 'col-md-3',
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.street',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          },
          type: 'text'
        },
        expressionProperties: {
          'templateOptions.required': () => !this.readonly,
          'templateOptions.disabled': () => this.readonly
        }
      },
      {
        key: 'houseNumber',
        type: 'input',
        wrappers: ['core-portal-translated'],
        className: 'col-md-3',
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.house-number',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          },
          type: 'text'
        },
        expressionProperties: {
          'templateOptions.required': () => !this.readonly,
          'templateOptions.disabled': () => this.readonly
        }
      },
      {
        key: 'zipcode',
        type: 'input',
        wrappers: ['core-portal-translated'],
        className: 'col-md-3',
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.zipcode',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          },
          type: 'text'
        },
        expressionProperties: {
          'templateOptions.required': () => !this.readonly,
          'templateOptions.disabled': () => this.readonly
        }
      },
      {
        key: 'city',
        type: 'input',
        wrappers: ['core-portal-translated'],
        className: 'col-md-3',
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.city',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          },
          type: 'text'
        },
        expressionProperties: {
          'templateOptions.required': () => !this.readonly,
          'templateOptions.disabled': () => this.readonly
        }
      },
      {
        key: 'state',
        type: 'input',
        wrappers: ['core-portal-translated'],
        className: 'col-md-6',
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.state',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          },
          type: 'text'
        },
        expressionProperties: {
          'templateOptions.disabled': () => this.readonly
        }
      },
      {
        key: 'countryCode',
        type: 'core-portal-ng-select-country',
        wrappers: ['core-portal-translated'],
        className: 'col-md-6',
        defaultValue: 'DE',
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.country-code',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          }
        },
        expressionProperties: {
          'templateOptions.required': () => !this.readonly,
          'templateOptions.disabled': () => this.readonly
        }
      },
      ...this.getStereotypeFields(),
      {
        type: 'core-portal-divider',
        className: 'col-md-12',
        hideExpression: () => !this.creating
      },
      {
        key: 'isPrimaryInvoice',
        type: 'core-portal-switch',
        wrappers: ['core-portal-translated'],
        className: 'col-md-3',
        defaultValue: true,
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.primary-invoice',
          }
        },
        expressionProperties: {
          'templateOptions.disabled': () => this.readonly
        },
        hideExpression: () => !this.creating
      },
      {
        key: 'isPrimaryShipping',
        type: 'core-portal-switch',
        wrappers: ['core-portal-translated'],
        className: 'col-md-3',
        defaultValue: true,
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.primary-shipping',
          }
        },
        expressionProperties: {
          'templateOptions.disabled': () => this.readonly
        },
        hideExpression: () => {
          return !this.creating
        }
      }
    ];
  }
}
