import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {CoreSharedLocalStorageService, LocalStorageKeys} from '@nexnox-web/core-shared';

@Injectable()
export class CorePortalCurrentTenantService {
  public currentTenantDomainSubject: BehaviorSubject<string> = new BehaviorSubject<string>(undefined);

  constructor(
    protected localStorageService: CoreSharedLocalStorageService
  ) {
  }

  public setTenantDomain(domain: string): void {
    this.currentTenantDomainSubject.next(domain);

    if (domain) {
      this.localStorageService.set(LocalStorageKeys.LAST_TENANT_DOMAIN, domain);
    }
  }

  public getTenantDomain(): string {
    return this.currentTenantDomainSubject.getValue();
  }
}
