<div *ngIf="!finished"
     nexnoxWebVisibleObserver
     class="infinite-scroll-loading-icon w-100 d-flex justify-content-center"
     (visible)="onVisibleChange($event)">
  <nexnox-web-loading-icon *ngIf="loading && !error"
                           size="lg">
  </nexnox-web-loading-icon>
  <button *ngIf="error"
          class="btn btn-outline-danger mb-4"
          (click)="onReload()">
    {{ 'core-shared.shared.table.tooltip.refresh' | translate }}
  </button>
</div>
