import { PagedEntitiesXsStore, PagedEntitiesXsStoreState } from '@nexnox-web/core-store';
import { AppEntityType, FunctionDto } from '@nexnox-web/core-shared';
import { createSelector } from '@ngrx/store';
import { CorePortalFeatureMasterDataFunctionService } from '../../services';
import { selectFunctionsState } from '../../functions.selectors';

export interface FunctionListStoreState extends PagedEntitiesXsStoreState<FunctionDto> {
}

/* istanbul ignore next */
export const functionListStore = new PagedEntitiesXsStore<FunctionDto>({
  actionLabel: 'Core Portal - Master Data - Function List',
  stateSelector: createSelector(selectFunctionsState, state => state.functionList),
  serviceType: CorePortalFeatureMasterDataFunctionService,
  entityType: AppEntityType.Function,
  selectId: functionProperty => functionProperty.entity?.functionId ?? functionProperty.model?.functionId
});
