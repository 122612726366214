import {ChangeDetectionStrategy, Component, Injector, OnInit} from '@angular/core';
import {ActionButton, CorePortalEntityDetailBaseComponent} from '@nexnox-web/core-portal';
import {AppEntityType, MissionIssueDto} from '@nexnox-web/core-shared';
import {missionIssueDetailStore} from '../../store';

@Component({
  selector: 'nexnox-web-issues-mission-issue-detail',
  templateUrl: './mission-issue-detail.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MissionIssueDetailComponent extends CorePortalEntityDetailBaseComponent<MissionIssueDto> implements OnInit {

  public title = 'issues.subtitles.issue-detail';
  public entityTypes = AppEntityType;
  protected idParam = 'issueId';
  protected displayKey = 'title';

  constructor(
    protected injector: Injector
  ) {
    super(injector, missionIssueDetailStore);
  }

  public ngOnInit(): void {
    super.ngOnInit();
  }

  protected async getActionButtons(): Promise<ActionButton[]> {
    return [];
  }
}
