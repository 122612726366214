import {ResourceState} from "@nexnox-web/core-shared";

export const resourceStateColoredEnumOptions = [
  {value: ResourceState.Operation, label: 'resources.resource-states-colored.1'},
  {value: ResourceState.Maintenance, label: 'resources.resource-states-colored.2'},
  {value: ResourceState.Retired, label: 'resources.resource-states-colored.3'},
]

export const resourceStateEnumOptions = [
  {value: ResourceState.Operation, label: 'resources.resource-states.1'},
  {value: ResourceState.Maintenance, label: 'resources.resource-states.2'},
  {value: ResourceState.Retired, label: 'resources.resource-states.3'},
]
