import {
  infoDetailStore,
  InfoDetailStoreState,
  infoListStore,
  InfoListStoreState,
  infoProgressContactListStore,
  InfoProgressContactListStoreState,
  infoProgressContactReadUnreadListStore,
  InfoProgressContactReadUnreadListStoreState,
  infoProgressFunctionListStore,
  InfoProgressFunctionListStoreState,
  infoProgressLocationListStore,
  InfoProgressLocationListStoreState
} from './stores';

export interface InfosState {
  infoList: InfoListStoreState;
  infoDetail: InfoDetailStoreState;

  infoProgressContactList: InfoProgressContactListStoreState;
  infoProgressContactReadUnreadList: InfoProgressContactReadUnreadListStoreState;
  infoProgressFunctionList: InfoProgressFunctionListStoreState;
  infoProgressLocationList: InfoProgressLocationListStoreState;
}

export const getInitialInfosState = (): InfosState => ({
  infoList: infoListStore.getInitialState(),
  infoDetail: infoDetailStore.getInitialState(),

  infoProgressContactList: infoProgressContactListStore.getInitialState(),
  infoProgressContactReadUnreadList: infoProgressContactReadUnreadListStore.getInitialState(),
  infoProgressFunctionList: infoProgressFunctionListStore.getInitialState(),
  infoProgressLocationList: infoProgressLocationListStore.getInitialState()
});
