import {
  Type,
  ɵNG_COMP_DEF as NG_COMP_DEF,
  ɵNG_DIR_DEF as NG_DIR_DEF,
  ɵNG_MOD_DEF as NG_MOD_DEF,
  ɵNG_PIPE_DEF as NG_PIPE_DEF,
} from '@angular/core';

export class IvyHelper {
  public static isRunningIvy(): boolean {
    const ng: any = (window as any).ng;

    return ng === undefined || ng.getComponent !== undefined || ng.markDirty !== undefined;
  }

  public static isIvyModule(moduleType: Type<any>): boolean {
    return (moduleType as any)[NG_MOD_DEF] !== undefined;
  }

  public static isIvyComponent(componentType: Type<any>): boolean {
    return (componentType as any)[NG_COMP_DEF] !== undefined;
  }

  public static isIvyDirective(directiveType: Type<any>): boolean {
    return (directiveType as any)[NG_DIR_DEF] !== undefined;
  }

  public static isIvyPipe(pipeType: Type<any>): boolean {
    return (pipeType as any)[NG_PIPE_DEF] !== undefined;
  }
}
