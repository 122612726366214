import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { StateMachineDetailComponent, StateMachineListComponent } from './containers';
import { I18nBreadcrumbResolver, UnsavedChangesGuard } from '@nexnox-web/core-shared';

export const TECH_PORTAL_STATE_MACHINES_ROUTES: Routes = [
  {
    path: '',
    component: StateMachineListComponent
  },
  {
    path: ':stateMachineId',
    component: StateMachineDetailComponent,
    canDeactivate: [UnsavedChangesGuard],
    data: {
      breadcrumbs: I18nBreadcrumbResolver,
      tab: 'stateMachine',
      text: 'core-portal.core.header.breadcrumbs.settings.ticket-settings.state-machine'
    }
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(TECH_PORTAL_STATE_MACHINES_ROUTES)
  ],
  exports: [
    RouterModule
  ]
})
export class StateMachinesRoutingModule {
}
