<nexnox-web-date-picker [formControl]="formControl"
                        [formlyAttributes]="field"
                        [minDate]="to.minDate"
                        [maxDate]="to.maxDate"
                        [noUtc]="to.noUtc"
                        [numberOfMonths]="to.corePortalDatepicker?.numberOfMonths"
                        [isRange]="to.corePortalDatepicker?.isRange"
                        [performanceMode]="to.performanceMode"
                        [hasError]="showError">
</nexnox-web-date-picker>
