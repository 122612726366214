<nexnox-web-entity-overview #overviewComponent
                            [entityOverviewBaseComponent]="this"
                            [cardBoxHeaderActions]="getCardboxHeaderActions()"
                            [canExport]="true"
                            detailLink="/tickets"
                            module="communication">
</nexnox-web-entity-overview>

<nexnox-web-location-create-tickets-by-definition-sidebar #definitionSidebar
                                                          [locationStereotypeId$]="locationStereotypeId$"
                                                          [locationId]="parentIds[0]"
                                                          (refreshList)="onRefresh()">
</nexnox-web-location-create-tickets-by-definition-sidebar>
