import { NgModule } from '@angular/core';
import { CorePortalEntityModule } from '@nexnox-web/core-portal';
import { CoreSharedModule, CoreSharedSortableListModule } from '@nexnox-web/core-shared';
import { ControlTagControlPointsEditComponent, TechPortalFeatureCCPControlTagEditComponent } from './components';
import { ControlTagDetailComponent, ControlTagListComponent } from './containers';
import { controlTagsServices } from './store';

@NgModule({
  declarations: [
    TechPortalFeatureCCPControlTagEditComponent,

    ControlTagListComponent,
    ControlTagDetailComponent,
    
    ControlTagControlPointsEditComponent
  ],
  imports: [
    CorePortalEntityModule,
    CoreSharedSortableListModule,
    
    CoreSharedModule,
    CorePortalEntityModule
  ],
  exports: [
    TechPortalFeatureCCPControlTagEditComponent
  ],
  providers: [
    ...controlTagsServices
  ]
})
export class ControlTagsDeclarationsModule {
}
