import {ChangeDetectionStrategy, Component, Injector} from '@angular/core';
import {
  ActionButton,
  CorePortalEntityOverviewBaseComponent,
  DatatableActionButton,
  DeleteEntityModel
} from '@nexnox-web/core-portal';
import {AppEntityType, AppPermissions, ControlPointDto, Mappers} from '@nexnox-web/core-shared';
import {controlPointListStore} from '../../store';

@Component({
  selector: 'nexnox-web-ccp-control-point-list',
  templateUrl: './control-point-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ControlPointListComponent extends CorePortalEntityOverviewBaseComponent<ControlPointDto> {
  public title = 'tech-portal.ccp.control-points.subtitles.list';
  public createTitle = 'tech-portal.ccp.control-points.actions.create';
  public idProperty = 'controlPointId';
  public displayProperty = 'name';
  public datatableConfigName = 'ControlPointDefault';
  public componentId = 'ControlPointListComponent';

  constructor(
    protected injector: Injector
  ) {
    super(injector, controlPointListStore, Mappers.ControlPointListDto.serializedName, AppEntityType.None);
  }

  public getRowActionButtons(): DatatableActionButton[] {
    return this.getDefaultRowActionButtons(
      'tech-portal.ccp.control-points.actions.edit',
      (row: ControlPointDto) => `ccp/control-points/${row.controlPointId}`,
      [AppPermissions.UpdateControlPoint],
      {
        module: 'settings'
      }
    );
  }

  public getDeleteEntityModel(): DeleteEntityModel {
    return {
      titleKey: 'tech-portal.ccp.control-points.actions.delete',
      descriptionKey: 'tech-portal.ccp.control-points.descriptions.delete',
      deletePermission: AppPermissions.DeleteControlPoint
    };
  }

  protected async getActionButtons(): Promise<ActionButton[]> {
    return this.getDefaultActionButtons('tech-portal.ccp.control-points.actions.create', AppPermissions.CreateControlPoint);
  }
}
