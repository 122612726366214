<nexnox-web-sidebar *ngIf="(showShell$ | async); else contentTemplate"
                    [loggedIn]="loggedIn$ | async"
                    [isOpen]="sidebarOpen$ | async"
                    [isModuleMenuExpanded]="moduleMenuOpen$ | async"
                    [logoPath]="sidebarLogoPath$ | async"
                    [selectedTenant]="sidebarTenant$ | async"
                    [checkPermissionFn]="sidebarCheckPermissionFn"
                    (isOpenChanged)="onSidebarOpenChanged($event)">
  <ng-template [ngTemplateOutlet]="contentTemplate"></ng-template>
</nexnox-web-sidebar>

<ng-template #contentTemplate>
  <div class="d-flex flex-column w-100 h-100">
    <div *ngIf="showShell$ | async" class="d-flex flex-grow-0 flex-column">
      <nexnox-web-layout-header [breadcrumbs$]="breadcrumbs$"
                                [sidebarOpen]="sidebarOpen$ | async">
      </nexnox-web-layout-header>

      <div class="d-flex flex-column">
        <nexnox-web-layout-action-bar></nexnox-web-layout-action-bar>
      </div>
    </div>

    <div id="content-container" #contentContainer class="d-flex flex-grow-1 container-fluid w-100 h-100 overflow-auto">
      <ng-container *ngIf="(loggedIn$ | async); else ghostOutletTemplate">
        <router-outlet></router-outlet>
      </ng-container>
    </div>
  </div>
</ng-template>

<ng-template #ghostOutletTemplate>
  <div class="w-100 h-100">
    <div class="cardbox px-3 pt-4 pb-3">
      <div class="row">
        <div class="col-12 d-flex justify-content-between">
          <ngx-skeleton-loader [theme]="{
            width: '250px',
            height: '28px',
            margin: '0'
          }"></ngx-skeleton-loader>
        </div>
      </div>

      <div class="row mt-4">
        <div class="col-12">
          <ngx-skeleton-loader [theme]="{ height: '20px', width: '90%'}"></ngx-skeleton-loader>
          <ngx-skeleton-loader [theme]="{ height: '20px', width: '60%' }"></ngx-skeleton-loader>
          <ngx-skeleton-loader [theme]="{ height: '20px' }"></ngx-skeleton-loader>
          <ngx-skeleton-loader [theme]="{ height: '20px', width: '90%' }"></ngx-skeleton-loader>
          <ngx-skeleton-loader [theme]="{ height: '20px', width: '30%' }"></ngx-skeleton-loader>
          <br><br>
          <ngx-skeleton-loader [theme]="{ height: '20px', width: '50%' }"></ngx-skeleton-loader>
        </div>
      </div>
    </div>
  </div>
</ng-template>
