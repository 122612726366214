import {ChangeDetectionStrategy, Component, Injector} from '@angular/core';
import {CorePortalFeatureAuditListBaseComponent} from '@nexnox-web/core-portal/features/audit';
import {locationAuditListStore} from '../../store/stores';

@Component({
  selector: 'nexnox-web-location-audit-list',
  templateUrl: './location-audit-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LocationAuditListComponent extends CorePortalFeatureAuditListBaseComponent {
  public componentId = 'LocationAuditListComponent';

  constructor(
    protected injector: Injector
  ) {
    super(injector, locationAuditListStore);
  }
}
