import { PackagesXsStore } from './packages.xs-store';
import { Action, createSelector } from '@ngrx/store';
import { PackagesXsStoreState } from './packages-xs-store.state';
import { selectCoreState } from '../core.selectors';

export const packagesStore = new PackagesXsStore({
  actionLabel: 'Core Portal - Core - Packages',
  stateSelector: createSelector(selectCoreState, state => state.packages)
});

export function packagesStoreReducer(state: PackagesXsStoreState, action: Action): any {
  return packagesStore.reducer(state, action);
}
