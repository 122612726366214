import { OrgaPortalFeatureDeviceMetadataService, OrgaPortalFeatureDeviceService, OrgaPortalFeaturePingService } from './services';
import { deviceDetailStore, deviceListStore, deviceMetadataListStore, devicePingListStore } from './stores';

export * from './services';
export * from './stores';

export * from './devices.state';
export * from './devices.selectors';

export const devicesReducers = {
  deviceList: deviceListStore.reducer,
  deviceDetail: deviceDetailStore.reducer,
  devicePingList: devicePingListStore.reducer,
  deviceMetadata: deviceMetadataListStore.reducer
};

export const devicesEffects = [
  deviceListStore.effects,
  deviceDetailStore.effects,
  devicePingListStore.effects,
  deviceMetadataListStore.effects
];

export const devicesServices = [
  OrgaPortalFeatureDeviceService,
  OrgaPortalFeaturePingService,
  OrgaPortalFeatureDeviceMetadataService
];
