<ng-template #entityEditTemplate>

    <nexnox-web-missions-ticket-mission-create #modelComponent
                                               [title]="title | translate"
                                               [model]="model$ | async"
                                               [creating]="isCreate$ | async"
                                               [readonly]="readonly$ | async"
                                               [loading]="loading$ | async"
                                               [stereotypes$]="stereotypes$"
                                               [stereotyped]="true"
                                               (modelChange)="onModelChange($event)">
    </nexnox-web-missions-ticket-mission-create>
</ng-template>

<nexnox-web-entity-detail [entityDetailBaseComponent]="this"
                          [useTabs]="false"
                          [entityEditTemplate]="entityEditTemplate">
</nexnox-web-entity-detail>
