import {NgModule} from '@angular/core';
import {CorePortalEntityModule} from '@nexnox-web/core-portal';
import {CoreSharedModule} from '@nexnox-web/core-shared';
import {CorePortalFeatureGdprEditComponent} from './components';
import {GdprDetailComponent, GdprEntryListComponent, GdprListComponent} from './containers';
import {gdprServices} from './store';
import {
  OrgaPortalFeatureChatDefinitionService
} from "@nexnox-web/orga-portal/features/chat-definitions/src/lib/store/services/chat-definition/chat-definition.service";
import {
  OrgaPortalFeatureTaskService
} from "@nexnox-web/orga-portal/features/tasks/src/lib/store/services/task/task.service";

@NgModule({
  declarations: [
    GdprListComponent,
    GdprDetailComponent,
    GdprEntryListComponent,
    CorePortalFeatureGdprEditComponent
  ],
  providers: [
    ...gdprServices,
    OrgaPortalFeatureChatDefinitionService,
    OrgaPortalFeatureTaskService
  ],
  imports: [
    CoreSharedModule,
    CorePortalEntityModule
  ],
  exports: []
})
export class GdprDeclarationsModule {
}
