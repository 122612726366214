<ng-template #entityEditTemplate
             let-title="title"
             let-id="id">
  <nexnox-web-service-tenants-tenant-edit #modelComponent
                                          [title]="title"
                                          [id]="id"
                                          [model]="model$ | async"
                                          [readonly]="readonly$ | async"
                                          [creating]="false"
                                          [loading]="loading$ | async"
                                          [stereotyped]="false"
                                          (modelChange)="onModelChange($event)">
  </nexnox-web-service-tenants-tenant-edit>
</ng-template>

<nexnox-web-entity-detail [entityDetailBaseComponent]="this"
                          [entityEditTemplate]="entityEditTemplate"
                          tabId="tenant"
                          [tabHeading]="title | translate"
                          [tabCommands]="['.']">
  <!-- Subscriptions Tab -->
  <ng-template nexnoxWebTabTemplate
               tabId="subscriptions"
               [heading]="'core-portal.service.tenants.subscriptions' | translate"
               [lazy]="true"
               (selectTab)="onNavigateToTab(['subscriptions'])">
    <nexnox-web-service-tenants-tenant-subscription-list *ngIf="passedIds"
                                                         [isEmbedded]="true"
                                                         [tenantId]="passedIds[0]"
                                                         [canCreate]="true"
                                                         [getEntityOnModeChange]="false">
    </nexnox-web-service-tenants-tenant-subscription-list>
  </ng-template>

  <!-- Systemcontacts Tab -->
  <ng-template nexnoxWebTabTemplate
               tabId="systemcontacts"
               [heading]="'core-portal.service.tenants.systemcontacts' | translate"
               [lazy]="true"
               (selectTab)="onNavigateToTab(['systemcontacts'])">
    <nexnox-web-service-tenants-tenant-systemcontact-list *ngIf="passedIds"
                                                          [isEmbedded]="true"
                                                          [tenantId]="passedIds[0]"
                                                          [canCreate]="false"
                                                          [parentIds]="passedIds"
                                                          [getEntityOnModeChange]="false">
    </nexnox-web-service-tenants-tenant-systemcontact-list>
  </ng-template>

  <!-- Usage Tab -->
  <ng-template nexnoxWebTabTemplate
               tabId="tenant-usage"
               [heading]="'core-portal.service.tenants.license-usage' | translate"
               [lazy]="true"
               (selectTab)="onNavigateToTab(['tenant-usage'])">
    <nexnox-web-service-tenants-tenant-usage *ngIf="passedIds"
                                             [tenantId]="passedIds[0]"
                                             [loading$]="loading$">
    </nexnox-web-service-tenants-tenant-usage>
  </ng-template>
</nexnox-web-entity-detail>
