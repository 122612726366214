import {PagedEntitiesXsStoreState} from '@nexnox-web/core-store';
import {AppEntityType, WinterhalterServiceCallIssueListDto} from '@nexnox-web/core-shared';
import {createSelector} from '@ngrx/store';
import {selectIssuesState} from '../../issues.selectors';
import {WinterhalterServiceCallIssueListXsStore} from './winterhalter-service-call-issue-list.xs-store';
import {WinterhalterServiceCallIssueService} from "../../services";

export interface WinterhalterServiceCallIssueListStoreState extends PagedEntitiesXsStoreState<WinterhalterServiceCallIssueListDto> {
}

/* istanbul ignore next */
export const winterhalterServiceCallIssueListStore = new WinterhalterServiceCallIssueListXsStore({
  actionLabel: 'Tech Portal - Issues - Winterhalter Service Call Issue List',
  stateSelector: createSelector(selectIssuesState, state => state.winterhalterServiceCallIssueList),
  serviceType: WinterhalterServiceCallIssueService,
  entityType: AppEntityType.Issue,
  selectId: issue => issue.entity?.issueId ?? issue.model?.issueId
});
