import { ActivatedRouteSnapshot, CanActivate, UrlTree } from '@angular/router';
import { Injectable } from '@angular/core';
import { CorePortalTenantRouter } from '../../router-overrides';

export interface CorePortalComponentConfig {
  [key: string]: boolean;
}

@Injectable()
export class CorePortalComponentConfigGuard implements CanActivate {
  constructor(
    private router: CorePortalTenantRouter
  ) {
  }

  public canActivate(route: ActivatedRouteSnapshot): boolean | UrlTree {
    const requiredComponentConfig: string[] = route.routeConfig.data?.requiredComponentConfig ?? [];
    const config: CorePortalComponentConfig = route.parent?.routeConfig?.data?.componentConfig ?? null;

    if (config === null) return true;

    for (const requiredKey of requiredComponentConfig) {
      if (!config[requiredKey]) return this.router.createUrlTree(route.parent?.url ?? ['/']);
    }

    return true;
  }
}
