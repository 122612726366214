import { ChangeDetectionStrategy, Component, Injector } from '@angular/core';
import { ActionButton, CorePortalEntityDetailBaseComponent } from '@nexnox-web/core-portal';
import {AppPermissions, ExportType, PrioritySetDto} from '@nexnox-web/core-shared';
import { prioritySetDetailStore } from '../../store/stores';

@Component({
  selector: 'nexnox-web-ticket-settings-priority-sets-priority-set-detail',
  templateUrl: './priority-set-detail.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PrioritySetDetailComponent extends CorePortalEntityDetailBaseComponent<PrioritySetDto> {
  public title = 'core-portal.settings.subtitles.ticket-settings.priority-set-detail';

  protected idParam = 'prioritySetId';
  protected displayKey = 'name';

  constructor(
    protected injector: Injector
  ) {
    super(injector, prioritySetDetailStore);
  }

  protected async getActionButtons(): Promise<ActionButton[]> {
    return this.getDefaultActionButtons(
      'core-portal.settings.actions.ticket-settings.edit-priority-set',
      'core-portal.settings.actions.ticket-settings.save-priority-set',
      'core-portal.settings.actions.ticket-settings.delete-priority-set',
      'core-portal.settings.descriptions.ticket-settings.delete-priority-set',
      AppPermissions.UpdatePrioritySet,
      AppPermissions.DeletePrioritySet,
      ['priority-sets'],
      undefined,
      undefined,
      ExportType.PRIORITY_SET
    );
  }
}
