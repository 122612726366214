import {CoreSharedApiBaseService, MissionType, Product, StartingMissionDto} from '@nexnox-web/core-shared';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {Injectable, Injector} from '@angular/core';

@Injectable()
export class TicketMissionService extends CoreSharedApiBaseService {
  constructor(
    protected injector: Injector
  ) {
    super(injector, Product.TECH, 'mission', ['ticket']);
  }

  public getPreviewByTicket(ticketId: number): Observable<StartingMissionDto> {
    return this.httpRequestService.post<StartingMissionDto>(`mission/preview`, {
      ticketId,
      type: MissionType.Ticket
    }).pipe(
      map(response => response.body)
    );
  }
}
