import { Pipe, PipeTransform } from '@angular/core';
import { CustomSetReferenceDto } from '@nexnox-web/core-shared';

@Pipe({
  name: 'nexnoxWebSortCustomPropertySets'
})
export class SortCustomPropertySetsPipe implements PipeTransform {
  public transform(customPropertySets: CustomSetReferenceDto[]): CustomSetReferenceDto[] {
    let sortedSets = [...customPropertySets];

    if (sortedSets.length) {
      sortedSets = sortedSets.sort((a, b) => a.position - b.position).map(customSetReference => {
        if (!customSetReference.customPropertySet.properties?.length) {
          return customSetReference;
        }

        return {
          ...customSetReference,
          customPropertySet: {
            ...customSetReference.customPropertySet,
            properties: customSetReference.customPropertySet.properties.sort((a, b) => a.position - b.position)
          }
        };
      });
    }

    return sortedSets;
  }
}
