import { ChangeDetectionStrategy, Component, Injector, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActionButton, CorePortalEntityOverviewBaseComponent, DatatableActionButton, DeleteEntityModel } from '@nexnox-web/core-portal';
import {
  AppEntityType,
  AppPermissions,
  CombineOperator, ControllerOperationId,
  FilterKind,
  FilterOperators,
  FilterTypes,
  Mappers,
  TaskDto,
  TaskState
} from '@nexnox-web/core-shared';
import { taskListStore } from '../../store';

@Component({
  selector: 'nexnox-web-tasks-task-list',
  templateUrl: './task-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TaskListComponent extends CorePortalEntityOverviewBaseComponent<TaskDto> implements OnInit {
  @ViewChild('categoryLabelTitleTemplate', { static: true }) public categoryLabelTitleTemplate: TemplateRef<any>;
  @ViewChild('categoryOptionTitleTemplate', { static: true }) public categoryOptionTitleTemplate: TemplateRef<any>;

  public title = 'orga-portal.tasks.subtitles.task-list';
  public createTitle = 'orga-portal.tasks.actions.create-task';
  public idProperty = 'taskId';
  public displayProperty = 'title';
  public datatableConfigName = 'TaskDefault';
  public componentId = 'TaskListComponent';

  public enableViews = true;
  public pageOperation = ControllerOperationId.TaskControllerList;

  constructor(
    protected injector: Injector
  ) {
    super(injector, taskListStore, Mappers.TaskListDto.serializedName, AppEntityType.Task);
  }

  public ngOnInit(): void {
    this.templates = {
      categoryLabelTitleTemplate: this.categoryLabelTitleTemplate,
      categoryOptionTitleTemplate: this.categoryOptionTitleTemplate
    };

    super.ngOnInit();
  }

  /* istanbul ignore next */
  public getRowActionButtons(): DatatableActionButton[] {
    return this.getDefaultRowActionButtons(
      'orga-portal.tasks.actions.edit-task',
      (row: TaskDto) => `/tasks/${row.taskId}`,
      [AppPermissions.UpdateTask],
      {
        module: 'communication'
      }
    );
  }

  public getDeleteEntityModel(): DeleteEntityModel {
    return {
      titleKey: 'orga-portal.tasks.actions.delete-task',
      descriptionKey: 'orga-portal.tasks.descriptions.delete-task',
      deletePermission: AppPermissions.DeleteTask
    };
  }

  protected async getActionButtons(): Promise<ActionButton[]> {
    return this.getDefaultActionButtons('orga-portal.tasks.actions.create-task', AppPermissions.CreateTask);
  }
}
