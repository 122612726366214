import {AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {Observable} from 'rxjs';
import {
  AppPermissions,
  CORE_SHARED_ENVIRONMENT,
  CORE_SHARED_PRODUCT,
  CoreSharedBreadcrumb,
  CoreSharedBreadcrumbsService,
  Environment,
  Product,
  RoleGeneralTerms,
  TenantInfoDto,
  UnsubscribeHelper
} from '@nexnox-web/core-shared';
import {select, Store} from '@ngrx/store';
import {
  CorePortalBootstrapService,
  CorePortalContentContainerService,
  CorePortalPermissionService
} from '../../../../services';
import {CorePortalSidebarService} from '../../../sidebar';
import {authStore, headerStore} from '../../../../store';
import {DOCUMENT} from '@angular/common';
import {Title} from '@angular/platform-browser';
import {CORE_PORTAL_EXCLUDED_ROLE_TERMS} from '../../../../tokens';

@Component({
  selector: 'nexnox-web-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CorePortalLayoutComponent extends UnsubscribeHelper implements OnInit, AfterViewInit {
  @ViewChild('contentContainer') public contentContainer: ElementRef;

  public loggedIn$: Observable<boolean>;
  public showShell$: Observable<boolean>;
  public breadcrumbs$: Observable<CoreSharedBreadcrumb[]>;

  public sidebarOpen$: Observable<boolean>;
  public moduleMenuOpen$: Observable<boolean>;
  public sidebarLogoPath$: Observable<string>;
  public sidebarTenant$: Observable<TenantInfoDto>;
  public sidebarCheckPermissionFn: any;

  constructor(
    private permissionService: CorePortalPermissionService,
    private breadcrumbsService: CoreSharedBreadcrumbsService,
    private sidebarService: CorePortalSidebarService,
    private store: Store<any>,
    private contentContainerService: CorePortalContentContainerService,
    private bootstrapService: CorePortalBootstrapService,
    @Inject(DOCUMENT) private document: Document,
    private titleService: Title,
    @Inject(CORE_PORTAL_EXCLUDED_ROLE_TERMS) private excludedRoleTerms: RoleGeneralTerms[],
    @Inject(CORE_SHARED_ENVIRONMENT) private environment: Environment,
    @Inject(CORE_SHARED_PRODUCT) private productType: Product
  ) {
    super();

    this.sidebarCheckPermissionFn = (permission: AppPermissions) => this.permissionService.hasPermission$(permission);

    this.breadcrumbs$ = this.breadcrumbsService.breadcrumbs$;
    this.sidebarOpen$ = this.sidebarService.isOpen$;
    this.moduleMenuOpen$ = this.sidebarService.isModuleMenuOpen$;
    this.sidebarLogoPath$ = this.sidebarService.getLogoPath$();
    this.sidebarTenant$ = this.sidebarService.getSelectedTenant$();
  }

  public ngOnInit(): void {
    this.loggedIn$ = this.store.pipe(select(authStore.selectors.selectLoggedIn));
    this.showShell$ = this.store.pipe(select(headerStore.selectors.selectShowShell));

    this.setTitle();
  }

  public ngAfterViewInit(): void {
    this.bootstrapService.bootstrapTheme(this.document.body);
    this.contentContainerService.setContentContainer(this.contentContainer);
  }

  public onSidebarOpenChanged(open: boolean): void {
    this.sidebarService.setOpen(open);
  }

  private setTitle(): void {
    this.titleService.setTitle(this.environment?.title);
  }
}
