import {AppEntityType} from '@nexnox-web/core-shared';
import {createSelector} from '@ngrx/store';
import {selectManufacturerState} from '../../manufacturer.selectors';
import {CorePortalManufacturerService} from '../../services';
import {ManufacturerListXsStore, ManufacturerListXsStoreState} from "./manufacturer-list.xs-store";

export interface ManufacturerListStoreState extends ManufacturerListXsStoreState {
}

/* istanbul ignore next */
export const manufacturerListStore = new ManufacturerListXsStore({
  actionLabel: 'Core Portal - Settings - Manufacturers - Manufacturer List',
  stateSelector: createSelector(selectManufacturerState, state => state.manufacturerList),
  serviceType: CorePortalManufacturerService,
  entityType: AppEntityType.None,
  stereotyped: false,
  selectId: manufacturer => manufacturer.entity?.resourceManufacturerId ?? manufacturer.model?.resourceManufacturerId
});


