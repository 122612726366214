import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {
  ChatMemberType,
  CoreSharedTextConverterService,
  MissionState,
  NoteCompleteDto,
  NoteDto,
  NoteType,
  SolutionItemDto
} from '@nexnox-web/core-shared';
import {timespanTo} from '@nexnox-web/lodash';
import dayjs from 'dayjs';
import {filter, map, Observable} from 'rxjs';
import {LocalSolutionItemDto} from "../../models";
import {TranslateService} from "@ngx-translate/core";


@Component({
  selector: 'nexnox-web-solutions-solution-item-title',
  templateUrl: './solution-item-title.component.html',
  styleUrls: ['./solution-item-title.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TechPortalFeatureSolutionItemTitleComponent implements OnInit {

  @Input() public item$: Observable<SolutionItemDto>;
  public localItem$: Observable<LocalSolutionItemDto>;

  @Input() public isCollapsed: boolean;

  public noteTypes = NoteType;

  public escapedNoteDescription$: Observable<string>;
  public typeTranslationKey$: Observable<string>;
  public offsetDate$: Observable<string>;

  public stateTypeOptions = {
    [MissionState.Created]: 'missions.mission-states.0',
    [MissionState.Instructed]: 'missions.mission-states.1',
    [MissionState.Rejected]: 'missions.mission-states.2',
    [MissionState.Canceled]: 'missions.mission-states.3',
    [MissionState.Done]: 'missions.mission-states.4'
  };

  constructor(
    private textConverterService: CoreSharedTextConverterService,
    private translate: TranslateService
  ) {
  }

  public ngOnInit(): void {
    this.localItem$ = this.item$.pipe(map(x => new LocalSolutionItemDto(x)));

    this.escapedNoteDescription$ = this.localItem$.pipe(
      filter(item => Boolean(item)),
      map(item => this.getEscapedDescription(item?.note))
    );

    this.typeTranslationKey$ = this.localItem$.pipe(
      filter(item => Boolean(item)),
      map(item => this.getTypeTranslationKey(item))
    );

    this.offsetDate$ = this.localItem$.pipe(
      filter(item => Boolean(item)),
      map(item => this.getOffsetDate(item.asEscalatedByEvent().offsetForEscalatedByEvent))
    );
  }

  public getFinalizedMissionDescription(note: NoteCompleteDto): Observable<string> {
    const label = `solutions.descriptions.solution-item-mission-${note?.isMissionCompleted ? 'completed' : 'resumed'}`
    return this.translate.get(label);
  }

  private getEscapedDescription(note: NoteDto): string {
    return this.textConverterService.convertHTMLtoEscapedText((note.description ?? ''), true);
  }

  private getTypeTranslationKey(item: LocalSolutionItemDto): string {
    switch (item.note.type) {
      case NoteType.Mail:
        return `solutions.descriptions.solution-item-mail.${item.asMail().direction}`;
      case NoteType.Caller:
        return `solutions.descriptions.solution-item-call.${item.asCaller().direction}`;
      case NoteType.ChatMessage:
        return item.asChatMessage().chatMessage?.sender?.type === ChatMemberType.Ticket ? 'solutions.descriptions.solution-item-chat-message-outgoing' : 'solutions.descriptions.solution-item-chat-message-incomming';
      default:
        return `solutions.solution-item-types.${item.note.type}`;
    }
  }

  private getOffsetDate(offset: string): string {
    const {days, hours, minutes} = timespanTo(offset);
    const date = dayjs()
      .subtract(days, 'day')
      .subtract(hours, 'hour')
      .subtract(minutes, 'minute')
      .format('YYYY-MM-DDTHH:mm:ss');
    return dayjs().to(dayjs(date), true);
  }
}
