import { ChangeDetectionStrategy, Component, Injector, ViewChild } from '@angular/core';
import { CorePortalEntityEditBaseComponent, CorePortalFormlyReadonlyTypes, CorePortalFormlyReadonlyTyping } from '@nexnox-web/core-portal';
import { ControlPointInTagDto, ControlTagDto } from '@nexnox-web/core-shared';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { BehaviorSubject } from 'rxjs';
import { ControlTagControlPointsEditComponent } from '../control-tag-control-points-edit/control-tag-control-points-edit.component';

@Component({
  selector: 'nexnox-web-ccp-control-tag-edit',
  templateUrl: './control-tag-edit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TechPortalFeatureCCPControlTagEditComponent extends CorePortalEntityEditBaseComponent<ControlTagDto> {

  @ViewChild('controlPointsComponent', { static: true })
  public controlPointsComponent: ControlTagControlPointsEditComponent;

  public controlPointsSubject = new BehaviorSubject<ControlPointInTagDto[]>([]);

  constructor(
    protected injector: Injector
  ) {
    super(injector);
  }

  public onControlPointsChange(points: ControlPointInTagDto[]): void {
    super.setModel({
      ...this.model,
      points
    })
    setTimeout(() => this.onModelChange(this.model));
  }

  protected createForm(): FormlyFieldConfig[] {
    return [
      {
        key: 'name',
        type: 'input',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-12',
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.name',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.BASIC
          } as CorePortalFormlyReadonlyTyping,
          type: 'text'
        },
        expressionProperties: {
          'templateOptions.required': () => !this.readonly,
          'templateOptions.disabled': () => this.readonly,
          'templateOptions.readonly': () => this.readonly
        }
      },
      ...this.getStereotypeFields(false)
    ];
  }

  protected setModel(model: ControlTagDto): void {
    super.setModel(model);

    this.controlPointsSubject.next(model?.points ?? []);
  }

  protected setReadonly(readonly: boolean): void {
    super.setReadonly(readonly);

    this.controlPointsComponent?.onReset();
  }
}
