import {
  BaseXsStore,
  BaseXsStoreReducerTypes,
  EntityXsStore,
  EntityXsStoreActions,
  EntityXsStoreEffects,
  EntityXsStoreSavePayload,
  EntityXsStoreSaveSuccessPayload,
  EntityXsStoreState,
  PropsAction
} from '@nexnox-web/core-store';
import { AppEntityType, CoreSharedApiBaseService, MissionByInspectionDto, MissionInspectionReportDto, MissionState } from '@nexnox-web/core-shared';
import {
  MissionInspectionReportXsStoreCreatePayload,
  MissionInspectionReportXsStoreCreateSuccessPayload
} from './mission-inspection-report-xs-store.payloads';
import { Action, createAction, on, props, select } from '@ngrx/store';
import { cloneDeep } from 'lodash';
import { Injectable, Injector, Type } from '@angular/core';
import { MissionInspectionReportService } from '../../services';
import { createEffect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, map, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { authStore } from '@nexnox-web/core-portal';
import { of } from 'rxjs';
import { missionDetailStore } from '../mission-detail';
import { UrlTree } from '@angular/router';
import { immerOn } from 'ngrx-immer/store';

export interface MissionInspectionReportXsStoreState extends EntityXsStoreState<MissionInspectionReportDto> {
}

export interface MissionInspectionReportXsStoreActions extends EntityXsStoreActions<MissionInspectionReportDto> {
  create: PropsAction<MissionInspectionReportXsStoreCreatePayload>;
  createSuccess: PropsAction<MissionInspectionReportXsStoreCreateSuccessPayload>;

  createAndConfirm: PropsAction<MissionInspectionReportXsStoreCreatePayload>;
  createAndConfirmSuccess: PropsAction<MissionInspectionReportXsStoreCreateSuccessPayload>;

  saveAndConfirm: PropsAction<EntityXsStoreSavePayload>;
  saveAndConfirmSuccess: PropsAction<EntityXsStoreSaveSuccessPayload<MissionInspectionReportDto, MissionInspectionReportDto>>;
}

export class MissionInspectionReportXsStore extends EntityXsStore<MissionInspectionReportDto> {
  public actions: MissionInspectionReportXsStoreActions;

  protected createActions(label: string): MissionInspectionReportXsStoreActions {
    return {
      ...super.createActions(label),

      create: createAction(
        BaseXsStore.getType(label, 'Create'),
        props<MissionInspectionReportXsStoreCreatePayload>()
      ),
      createSuccess: createAction(
        BaseXsStore.getType(label, 'Create success'),
        props<MissionInspectionReportXsStoreCreateSuccessPayload>()
      ),

      createAndConfirm: createAction(
        BaseXsStore.getType(label, 'Create and confirm'),
        props<MissionInspectionReportXsStoreCreatePayload>()
      ),
      createAndConfirmSuccess: createAction(
        BaseXsStore.getType(label, 'Create and confirm success'),
        props<MissionInspectionReportXsStoreCreateSuccessPayload>()
      ),

      saveAndConfirm: createAction(
        BaseXsStore.getType(label, 'Save and confirm'),
        props<EntityXsStoreSavePayload>()
      ),
      saveAndConfirmSuccess: createAction(
        BaseXsStore.getType(label, 'Save and confirm success'),
        props<EntityXsStoreSaveSuccessPayload<MissionInspectionReportDto, MissionInspectionReportDto>>()
      )
    };
  }

  protected createReducerArray(
    initialState: MissionInspectionReportXsStoreState
  ): BaseXsStoreReducerTypes<MissionInspectionReportXsStoreState, MissionInspectionReportXsStoreActions>[] {
    return [
      ...super.createReducerArray(initialState),

      immerOn(this.actions.create, draft => {
        draft.loading = true;
      }),
      immerOn(this.actions.createSuccess, (draft, { entity, model }) => {
        draft.entity = cloneDeep(entity);
        draft.model = cloneDeep(model);
        draft.loading = false;
        draft.loaded = true;
      }),

      immerOn(this.actions.createAndConfirm, draft => {
        draft.loading = true;
      }),
      immerOn(this.actions.createAndConfirmSuccess, (draft, { entity, model }) => {
        draft.entity = cloneDeep(entity);
        draft.model = cloneDeep(model);
        draft.loading = false;
        draft.loaded = true;
      }),

      immerOn(this.actions.saveAndConfirm, draft => {
        draft.loading = true;
      }),
      immerOn(this.actions.saveAndConfirmSuccess, (draft, { entity, model }) => {
        draft.entity = cloneDeep(entity);
        draft.model = cloneDeep(model);
        draft.loading = false;
      }),

      on(this.actions.deleteSuccess, () => initialState)
    ];
  }

  protected createEffects(
    serviceType: Type<CoreSharedApiBaseService>,
    entityType: AppEntityType,
    prepareEntity: (entity: MissionInspectionReportDto) => MissionInspectionReportDto,
    prepareModel: (entity: MissionInspectionReportDto, model: MissionInspectionReportDto) => MissionInspectionReportDto,
    sanitizeModel: (model: MissionInspectionReportDto, entity: MissionInspectionReportDto) => MissionInspectionReportDto,
    ...args
  ): Type<EntityXsStoreEffects<MissionInspectionReportDto, MissionInspectionReportDto, MissionInspectionReportXsStoreState>> {
    const actions = this.actions;
    const selectors = this.selectors;

    @Injectable()
    class Effects extends EntityXsStoreEffects<MissionInspectionReportDto> {
      public create$: any;
      public createSuccess$: any;

      public createAndConfirm$: any;
      public createAndConfirmSuccess$: any;

      public saveAndConfirm$: any;
      public saveAndConfirmSuccess$: any;

      protected actions: MissionInspectionReportXsStoreActions;
      protected service: MissionInspectionReportService;

      constructor(
        protected injector: Injector
      ) {
        super(injector, actions, selectors, serviceType, entityType, prepareEntity, prepareModel, sanitizeModel, true, false);
      }

      protected createEffects(): void {
        super.createEffects();

        this.create$ = createEffect(() => this.actions$.pipe(
          ofType(this.actions.create),
          withLatestFrom(this.store.pipe(select(authStore.selectors.selectActiveTenant))),
          switchMap(([{ model, missionId }, activeTenant]) => this.service.createOne({
            ...model,
            missionId,
            tenantId: activeTenant.tenantId
          }).pipe(
            map(report => this.actions.createSuccess({
              entity: this.prepareEntity(report),
              model: this.prepareModel(this.prepareEntity(report), {} as any)
            })),
            catchError(error => of(this.actions.error({ error, action: this.actions.create })))
          ))
        ));

        this.createSuccess$ = createEffect(() => this.actions$.pipe(
          ofType(this.actions.createSuccess),
          tap(action => this.actionCallback(action, false))
        ), { dispatch: false });

        this.createAndConfirm$ = createEffect(() => this.actions$.pipe(
          ofType(this.actions.createAndConfirm),
          withLatestFrom(this.store.pipe(select(authStore.selectors.selectActiveTenant))),
          switchMap(([{ model, missionId }, activeTenant]) => this.service.confirmOne({
            ...model,
            missionId,
            tenantId: activeTenant.tenantId
          }).pipe(
            map(report => this.actions.createAndConfirmSuccess({
              entity: this.prepareEntity(report),
              model: this.prepareModel(this.prepareEntity(report), {} as any)
            })),
            catchError(error => of(this.actions.error({ error, action: this.actions.createAndConfirm })))
          ))
        ));

        this.createAndConfirmSuccess$ = createEffect(() => this.actions$.pipe(
          ofType(this.actions.createAndConfirmSuccess),
          tap(action => this.actionCallback(action, false))
        ), { dispatch: false });

        this.saveAndConfirm$ = createEffect(() => this.actions$.pipe(
          ofType(this.actions.saveAndConfirm),
          withLatestFrom(this.store.pipe(select(this.selectors.selectModel))),
          withLatestFrom(this.store.pipe(select(this.selectors.selectEntity))),
          exhaustMap(([[{ id, parentIds, queryParams }, model], entity]) =>
            this.mapEntityResponse(this.service.confirmOne(this.sanitizeModel(model, entity), parentIds)).pipe(
              map(savedEntity => this.actions.saveAndConfirmSuccess({
                entity: this.prepareEntity(savedEntity),
                model: this.prepareModel(this.prepareEntity(savedEntity), model)
              })),
              catchError(error => of(this.actions.error({ error, action: this.actions.saveAndConfirm })))
            ))
        ));

        this.saveAndConfirmSuccess$ = createEffect(() => this.actions$.pipe(
          ofType(this.actions.saveAndConfirmSuccess),
          tap(action => this.actionCallback(action, false))
        ), { dispatch: false });
      }

      protected actionCallback(action: Action, isError: boolean): void {
        super.actionCallback(action, isError);

        this.checkAction(this.actions.createSuccess, action, ({ entity }) =>
          this.createSuccessActionCallback(entity.missionInspectionReportId));
        this.checkAction(this.actions.createAndConfirmSuccess, action, ({ entity }) => this.createAndConfirmSuccessActionCallback(entity.missionInspectionReportId));
        this.checkAction(this.actions.saveAndConfirmSuccess, action, () => this.saveAndConfirmSuccessActionCallback());
      }

      protected async createSuccessActionCallback(inspectionId: number): Promise<void> {
        this.apiNotificationService.showTranslatedSuccess('core-shared.shared.toast.entity-created');

        const entity = await this.store.pipe(select(missionDetailStore.selectors.selectEntity), take(1)).toPromise();
        const model = await this.store.pipe(select(missionDetailStore.selectors.selectModel), take(1)).toPromise();
        this.store.dispatch(missionDetailStore.actions.saveSuccess({
          entity: { ...entity, inspectionId } as MissionByInspectionDto,
          model: { ...model, inspectionId } as MissionByInspectionDto,
          doNotEmitEvents: true
        }));
      }

      protected async deleteSuccessActionCallback(returnPath?: any[]): Promise<void> {
        const entity = await this.store.pipe(select(missionDetailStore.selectors.selectEntity), take(1)).toPromise();
        const model = await this.store.pipe(select(missionDetailStore.selectors.selectModel), take(1)).toPromise();
        this.store.dispatch(missionDetailStore.actions.saveSuccess({
          entity: { ...entity, inspectionId: null } as MissionByInspectionDto,
          model: { ...model, inspectionId: null } as MissionByInspectionDto,
          doNotEmitEvents: true
        }));

        super.deleteSuccessActionCallback(returnPath);
      }


      protected async createAndConfirmSuccessActionCallback(inspectionId: number): Promise<void> {
        this.apiNotificationService.showTranslatedSuccess('core-shared.shared.toast.entity-created');

        const entity = await this.store.pipe(select(missionDetailStore.selectors.selectEntity), take(1)).toPromise();
        const model = await this.store.pipe(select(missionDetailStore.selectors.selectModel), take(1)).toPromise();
        this.store.dispatch(missionDetailStore.actions.saveSuccess({
          entity: {
            ...entity,
            inspectionId,
            state: MissionState.Done
          } as MissionByInspectionDto,
          model: {
            ...model,
            inspectionId,
            state: MissionState.Done
          } as MissionByInspectionDto,
          doNotEmitEvents: true
        }));
      }

      protected async saveAndConfirmSuccessActionCallback(): Promise<void> {
        this.apiNotificationService.showTranslatedSuccess('core-shared.shared.toast.entity-saved');

        const entity = await this.store.pipe(select(missionDetailStore.selectors.selectEntity), take(1)).toPromise();
        const model = await this.store.pipe(select(missionDetailStore.selectors.selectModel), take(1)).toPromise();
        this.store.dispatch(missionDetailStore.actions.saveSuccess({
          entity: {
            ...entity,
            state: MissionState.Done
          },
          model: {
            ...model,
            state: MissionState.Done
          },
          doNotEmitEvents: true
        }));
      }
    }

    return Effects;
  }
}
