<div class="input-group d-flex flex-nowrap">
  <div *ngIf="prepends$ | async as prependArray" class="input-group-prepend">
    <span *ngFor="let prependText of prependArray"
          class="input-group-text"
          [class.disabled]="formControl.disabled">
      {{ prependText }}
    </span>
  </div>

  <nexnox-web-input-with-transformers *ngIf="transformers$ | async as transformers"
                                      class="d-flex flex-grow-1"
                                      [type]="to.type"
                                      [hasError]="showError"
                                      [formControl]="formControl"
                                      [formlyAttributes]="field"
                                      [transformers]="transformers">
  </nexnox-web-input-with-transformers>

  <div *ngIf="appends$ | async as appendArray" class="input-group-append">
    <span *ngFor="let appendText of appendArray"
          class="input-group-text"
          [class.disabled]="to.disabled">
      {{ appendText }}
    </span>
  </div>
</div>
