import {PagedEntitiesXsStore, PagedEntitiesXsStoreState} from '@nexnox-web/core-store';
import {AppEntityType, ResourceDto} from '@nexnox-web/core-shared';
import {Action, createSelector} from '@ngrx/store';
import {selectResourceTasksState} from '../../resource-tasks.selector';
import {CorePortalFeatureResourceTaskResourceSelectionService} from '@nexnox-web/core-portal/features/resources';
import {Injectable, Injector} from '@angular/core';

export interface ResourceTaskResourcesStoreState extends PagedEntitiesXsStoreState<ResourceDto> {
}

/* istanbul ignore next */
export const resourceTaskResourcesStore = new PagedEntitiesXsStore<ResourceDto>({
  actionLabel: 'Tech Portal - Resource Tasks - Resource Task Resources',
  stateSelector: createSelector(selectResourceTasksState, state => state.resourceTaskResources),
  entityType: AppEntityType.Resource,
  serviceType: CorePortalFeatureResourceTaskResourceSelectionService,
  selectId: resource => resource.entity?.resourceId ?? resource.model?.resourceId
});

export function resourceTaskResourcesStoreReducer(state: ResourceTaskResourcesStoreState, action: Action): any {
  return resourceTaskResourcesStore.reducer(state, action);
}

@Injectable()
export class ResourceTaskResourcesStoreEffects extends resourceTaskResourcesStore.effects {
  constructor(
    protected injector: Injector
  ) {
    super(injector);
  }
}
