<nexnox-web-entity-edit [entityEditBaseComponent]="this"
                        displayKey="name"
                        [isTitleEditable]="false">
</nexnox-web-entity-edit>

<nexnox-web-cardbox [title]="'core-portal.settings.subtitles.ticket-settings.priority-set-items' | translate"
                    [loading]="loading"
                    paddingClasses="px-3 pb-3">
  <div class="d-flex flex-row mb-3">
    <div class="d-flex flex-grow-1">
      <input #priorityNameControl
             type="text"
             class="form-control"
             [(ngModel)]="priorityName"
             [disabled]="readonly"
             (keyup.enter)="priorityName ? onAddPriority() : null">
    </div>

    <button class="btn btn-outline-primary ml-3"
            [disabled]="!priorityName || readonly"
            (click)="onAddPriority()">
      {{ 'core-shared.shared.select.add-item' | translate }}
    </button>
  </div>

  <nexnox-web-sortable-list [items]="priorities$ | async"
                            [disabled]="readonly"
                            [sortable]="false"
                            [editable]="true"
                            (itemsChange)="onPrioritiesChange($event)">
  </nexnox-web-sortable-list>
</nexnox-web-cardbox>
