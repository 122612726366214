import { ChangeDetectionStrategy, Component, Injector, Input, OnInit, ViewChild } from '@angular/core';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons/faExternalLinkAlt';
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import { CorePortalCardboxAction, CorePortalEntityOverviewBaseComponent, DatatableActionButton, DeleteEntityModel } from '@nexnox-web/core-portal';
import { AppEntityType, AppPermissions, Mappers, SystemContactDto, SystemContactListDto } from '@nexnox-web/core-shared';
import { of } from 'rxjs';
import { CreateSystemContactSidebarComponent } from '../../sidebars';
import { tenantSystemContactListStore } from '../../store';

@Component({
  selector: 'nexnox-web-service-tenants-tenant-systemcontact-list',
  templateUrl: './tenant-systemcontact-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TenantSystemContactListComponent extends CorePortalEntityOverviewBaseComponent<SystemContactDto> implements OnInit {

  @ViewChild('createSystemContactSidebar', { static: true })
  public createSystemContactSidebar: CreateSystemContactSidebarComponent;

  @Input() public tenantId: number | string;

  public title = 'core-portal.service.tenants.systemcontacts';
  public idProperty = 'contactId';
  public displayProperty = 'displayName';
  public datatableConfigName = 'ServiceTenantSystemContactDefault';
  public componentId = 'TenantSystemContactListComponent';

  constructor(
    protected injector: Injector
  ) {
    super(injector, tenantSystemContactListStore, Mappers.SystemContactDto.serializedName, AppEntityType.None);
  }

  public ngOnInit(): void {
    super.ngOnInit();
  }

  /* istanbul ignore next */
  public createCardBoxHeaderActions(): CorePortalCardboxAction[] {
    return [
      {
        label: 'core-portal.service.tenants.actions.create-systemcontact',
        class: 'btn-outline-primary',
        icon: faPlus,
        tooltip: 'core-portal.service.tenants.tooltips.create-systemcontact',
        permission: AppPermissions.CreateSystemContact,
        callback: () => this.createSystemContactSidebar.onShow(),
        isLoading: () => this.loading$,
        shouldShow: () => of(true)
      }
    ]
  }

  /* istanbul ignore next */
  public getRowActionButtons(): DatatableActionButton[] {
    return [
      {
        icon: faExternalLinkAlt,
        tooltip: 'core-shared.shared.actions.new-tab',
        isolate: false,
        onClick: (row: SystemContactListDto) => this._openLinkInNewTab(row)
      }
    ];
  }

  public getDeleteEntityModel(): DeleteEntityModel {
    return {
      titleKey: 'core-portal.service.tenants.actions.delete-systemcontact',
      descriptionKey: 'core-portal.service.tenants.descriptions.delete-systemcontact',
      deletePermission: AppPermissions.DeleteSystemContact
    };
  }

  public onCreateSystemContact(model: SystemContactDto): void {
    super.createOne(model);
  }

  private _openLinkInNewTab(row: SystemContactListDto): void {
    const url = this.router.serializeUrl(this.router.createUrlTree([`tenant/${row.tenant.domain}/management/masterdata/contacts/${row.contactId}`]));
    window.open(url, '_blank');
  }
}
