<div class="d-flex flex-row align-items-center">
  <ng-select class="d-flex ng-form-select flex-grow-1"
             [class.is-invalid]="showError"
             [items]="to.corePortalNgSelect?.items ? to.corePortalNgSelect.items : (to.corePortalNgSelect.items$ | async)"
             [formControl]="formControl"
             [formlyAttributes]="field"
             [clearable]="!to.required && !to.corePortalNgSelect.noClear"
             [compareWith]="compareFn"
             [searchable]="!to.corePortalNgSelect.noSearch"
             [groupBy]="groupByFn"
             [groupValue]="groupValueFn"
             [searchFn]="searchFn"
             bindValue="value">
    <ng-template ng-optgroup-tmp let-item="item">
      <span>{{ item.label | translate }}</span>
    </ng-template>

    <ng-template ng-label-tmp let-item="item">
      <div class="d-flex align-items-center">
        <span *ngIf="item.flag" class="flag-icon flag-icon-{{ item.flag }} mr-2"></span>
        <span *ngIf="!to.corePortalNgSelect?.translate; else translatedItem">{{ item.readonlyLabel ?? item.label }}</span>

        <ng-template #translatedItem>
          <span>{{ (item.readonlyLabel ?? item.label) | translate }}</span>
        </ng-template>
      </div>
    </ng-template>

    <ng-template ng-option-tmp let-item="item" let-searchTerm="searchTerm">
      <div class="d-flex flex-row align-items-center">
        <div class="d-flex flex-grow-1"
             [style.padding-left.rem]="(item.depth ?? 0) * 1.5">
          <fa-icon *ngIf="item.depth > 0"
                   [icon]="faLevelUpAlt"
                   size="xs"
                   [rotate]="90"
                   class="text-muted mr-2">
          </fa-icon>

          <span *ngIf="item.flag" class="flag-icon flag-icon-{{ item.flag }} mr-2"></span>
          <span *ngIf="!to.corePortalNgSelect?.translate; else translatedItem"
                [title]="item.label"
                [innerHTML]="item.label | nexnoxWebHighlight:searchTerm">
          </span>

          <ng-template #translatedItem>
            <span [title]="item.label | translate"
                  [innerHTML]="item.label | translate | nexnoxWebHighlight:searchTerm">
            </span>
          </ng-template>
        </div>

        <div *ngIf="to.corePortalNgSelect?.rateable"
             class="d-flex flex-shrink-0">
          <fa-icon [icon]="item.accepted ? faCheckCircle : faExclamationCircle"
                   [class.text-success]="item.accepted"
                   [class.text-warning]="!item.accepted">
          </fa-icon>
        </div>
      </div>
    </ng-template>

    <ng-template ng-notfound-tmp>
      <div class="ng-option disabled">
        {{ 'core-shared.shared.table.empty' | translate }}
      </div>
    </ng-template>
  </ng-select>

  <a *ngIf="to.corePortalNgSelect?.link && to.corePortalNgSelect.link(formControl.value) as link"
     [nexnoxWebTenantRouterLink]="link"
     [module]="to.corePortalNgSelect.module"
     class="pl-2"
     target="_blank">
    <fa-icon [icon]="faExternalLinkAlt" [fixedWidth]="true" size="sm"></fa-icon>
  </a>
</div>
