import {PagedEntitiesXsStore, PagedEntitiesXsStoreState} from '@nexnox-web/core-store';
import {AppEntityType, AreaAssignmentInContactDto, AreaAssignmentReach, ContactDto} from '@nexnox-web/core-shared';
import {Action, createSelector} from '@ngrx/store';
import {selectContactsState} from '../../contacts.selectors';
import {CorePortalFeatureMasterDataContactService} from '../../services';
import {Injectable, Injector} from '@angular/core';

export interface ContactListStoreState extends PagedEntitiesXsStoreState<ContactDto> {
}

/* istanbul ignore next */
export const contactListStore = new PagedEntitiesXsStore<ContactDto>({
  actionLabel: 'Core Portal - Master Data - Contact List',
  stateSelector: createSelector(selectContactsState, state => state.contactList),
  serviceType: CorePortalFeatureMasterDataContactService,
  entityType: AppEntityType.Contact,
  selectId: contact => contact.entity?.contactId ?? contact.model?.contactId,
  sanitizeModel: (
    model: ContactDto,
    entity: ContactDto,
    base: (model: ContactDto, entity: ContactDto) => ContactDto
  ) => {
    const newEntity = base(model, entity) as any;

    // Mapping areas
    if (newEntity.location) {

      const newArea = {} as AreaAssignmentInContactDto;
      newArea.areaAssignmentId = 0;
      newArea.reach = AreaAssignmentReach.Limited | AreaAssignmentReach.Persona;

      newArea.locations = [newEntity.location];
      delete newEntity.location;

      newArea.roles = newEntity.roles?.map(role => ({...role, id: role.appRoleId}));
      delete newEntity.roles;

      newArea.functions = newEntity.functions;
      delete newEntity.functions;

      newEntity.areas = [newArea];
    }
    return newEntity;
  }
});

export function contactListStoreReducer(state: ContactListStoreState, action: Action): any {
  return contactListStore.reducer(state, action);
}

@Injectable()
export class ContactListStoreEffects extends contactListStore.effects {
  constructor(
    protected injector: Injector
  ) {
    super(injector);
  }
}
