<div *ngFor="let item of items$ | async; trackBy: trackItemsBy; let index = index; let isFirst = first; let isLast = last"
     class="d-flex flex-row align-items-center flex-wrap form-dropdown-item"
     [class.mb-2]="!to.disabled || !isLast">
  <div class="d-flex flex-shrink-1 flex-grow-1 align-items-center">
    <span #inputContainer
          class="d-flex flex-shrink-1 flex-grow-1 mr-1 align-items-center p-input-icon-left"
          [style.min-width.px]="50"
          [style.height.px]="29">
      <i *ngIf="item.isSelected"
         [class]="faCheck | nexnoxWebFaIconStringPipe"
         [class.text-success]="to.corePortalCustomPropertyDropdown?.isRateable && item.rating === ratingTypes.Ok"
         [class.text-danger]="to.corePortalCustomPropertyDropdown?.isRateable && item.rating === ratingTypes.Error">
      </i>

      <i *ngIf="to.corePortalCustomPropertyDropdown?.isRateable && !item.isSelected"
         [class]="faCircle | nexnoxWebFaIconStringPipe"
         [class.text-success]="item.rating === ratingTypes.Ok"
         [class.text-danger]="item.rating === ratingTypes.Error">
      </i>

      <input pInputText
             type="text"
             class="w-100 p-input text-sm"
             [ngModel]="item.value"
             [disabled]="to.disabled"
             [class.is-invalid]="!item.value"
             (ngModelChange)="onValueChange(item, $event)">
    </span>

    <ng-container *ngIf="itemsDropdownActions$ | async as itemsDropdownActions">
      <div *ngIf="!to.disabled"
           class="d-flex flex-shrink-0 p-1">
        <p-tieredMenu #moreActionMenu
                      [model]="itemsDropdownActions[index]"
                      [popup]="true"
                      appendTo="body">
        </p-tieredMenu>

        <button type="button"
                pButton
                [icon]="faEllipsisV | nexnoxWebFaIconStringPipe:'fa-fw'"
                class="p-button-text p-button-icon-only p-button-sm p-button-secondary"
                [title]="'core-portal.core.general.more' | translate"
                (click)="moreActionMenu.toggle($event)">
        </button>
      </div>
    </ng-container>
  </div>
</div>

<div *ngIf="!to.disabled" class="d-flex flex-row align-items-center">
  <div class="d-flex flex-shrink-1 flex-grow-1 mr-1">
    <input #addItemInput
           pInputText
           type="text"
           class="w-100 p-input text-sm"
           [(ngModel)]="addModel"
           [disabled]="to.disabled"
           (keyup.enter)="onAddItem()">
  </div>

  <div class="d-flex flex-shrink-0 p-1">
    <button pButton
            type="button"
            class="p-button-secondary p-button-text p-button-icon-only p-button-sm"
            [icon]="faPlus | nexnoxWebFaIconStringPipe"
            [pTooltip]="'core-shared.shared.actions.add' | translate"
            [disabled]="!addModel"
            (click)="onAddItem()">
    </button>
  </div>
</div>
