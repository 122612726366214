import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {
  ApiNotificationService,
  AppPermissions,
  AppRoleDto,
  AreaAssignmentDto,
  AreaAssignmentReach,
  ContactDto,
  CoreSharedSidebarBaseComponent,
  LocationDto
} from '@nexnox-web/core-shared';
import {faUser} from '@fortawesome/free-solid-svg-icons/faUser';
import {FormGroup} from "@angular/forms";
import {FormlyFieldConfig} from "@ngx-formly/core";
import {
  CorePortalContactService,
  CorePortalFunctionService,
  CorePortalPermissionService,
  CorePortalRoleService
} from "@nexnox-web/core-portal";
import {faTimes} from '@fortawesome/free-solid-svg-icons/faTimes';
import {BehaviorSubject, Observable} from "rxjs";
import {LocationContactService} from "./../../store";
import {filter} from "rxjs/operators";

@Component({
  selector: 'nexnox-web-location-create-area-assignment-sidebar',
  templateUrl: './create-area-assignment-sidebar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CreateAreaAssignmentSidebarComponent extends CoreSharedSidebarBaseComponent implements OnInit {

  @Input() public location$: Observable<LocationDto>;
  @Output() public refreshList: EventEmitter<boolean> = new EventEmitter<boolean>();

  public canAssign$: Observable<boolean>;
  public loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public location: LocationDto;

  public form: FormGroup;
  public model: AreaAssignmentDto & { contact: ContactDto };
  public fields: FormlyFieldConfig[];

  public faTimes = faTimes;
  public faUser = faUser;

  constructor(
    private contactService: CorePortalContactService,
    private permissionService: CorePortalPermissionService,
    private roleService: CorePortalRoleService,
    private functionService: CorePortalFunctionService,
    private locationContactService: LocationContactService,
    private apiNotificationService: ApiNotificationService
  ) {
    super();
  }

  public ngOnInit(): void {
    this.form = new FormGroup({});
    this.fields = this.createForm();
    this.canAssign$ = this.permissionService.hasPermission$(AppPermissions.CreateAreaAssignment);
    this.subscribe(this.location$.pipe(filter(location => Boolean(location))), (location) => {
      this.location = location
      this.model = {...this.model, locations: [location]};
      this.form.updateValueAndValidity();
    });
  }

  public onCreate(): void {
    this.loading$.next(true);
    this.subscribe(this.locationContactService.addAreaAssignment(this.model?.contact?.contactId, this._mapAreaAssignment(this.model)),
      () => {
        this.apiNotificationService.showTranslatedSuccess('core-shared.shared.toast.entity-added');
        this.refreshList.emit(true);
        this.loading$.next(false);
        this.onHide();
      },
      (error) => {
        this.apiNotificationService.handleApiError(error);
        this.loading$.next(false);
      }
    )
  }

  public onShow(): void {
    super.onShow();
  }

  public onHide(): void {
    this.model = {locations: [this.location]} as AreaAssignmentDto & { contact: ContactDto };
    this.form.reset();
    super.onHide();
  }

  /* istanbul ignore next */
  protected createForm(): FormlyFieldConfig[] {
    return [
      {
        key: 'reach',
        type: 'input',
        defaultValue: AreaAssignmentReach.Limited | AreaAssignmentReach.Persona,
        hide: true
      },
      {
        key: 'contact',
        type: 'core-portal-entity-select',
        wrappers: ['core-portal-translated'],
        className: 'col-md-12',
        defaultValue: null,
        templateOptions: {
          corePortalTranslated: {
            label: 'core-portal.master-data.subtitles.contact',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          },
          entityService: this.contactService,
          idKey: 'contactId',
          displayKey: 'displayName',
          clearable: false,
          wholeObject: true,
          required: true
        }
      },
      {
        key: 'roles',
        type: 'core-portal-entity-select',
        wrappers: ['core-portal-translated'],
        className: 'col-md-12',
        defaultValue: null,
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.roles',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          },
          entityService: this.roleService,
          idKey: 'appRoleId',
          displayKey: 'title',
          wholeObject: true,
          multiple: true,
          showAll: true
        },

      },
      {
        key: 'functions',
        type: 'core-portal-entity-select',
        wrappers: ['core-portal-translated'],
        className: 'col-md-12',
        defaultValue: null,
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.functions',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          },
          entityService: this.functionService,
          idKey: 'functionId',
          displayKey: 'name',
          wholeObject: true,
          multiple: true,
          showAll: true
        }
      }
    ];
  }

  private _mapAreaAssignment(model: AreaAssignmentDto): AreaAssignmentDto {
    return {
      ...model,
      tenantId: this.location.tenantId,
      roles: model?.roles?.map((role) => ({
        id: (role as AppRoleDto).appRoleId, name: (role as AppRoleDto).title
      }))
    }
  }
}
