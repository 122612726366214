import {ChangeDetectionStrategy, Component, Injector, ViewChild} from '@angular/core';
import {
  CorePortalEntityEditBaseComponent,
  CorePortalFormlyReadonlyTypes,
  CorePortalFormlyReadonlyTyping,
  CorePortalFormlyTranslatedTyping
} from '@nexnox-web/core-portal';
import {FormDto} from '@nexnox-web/core-shared';
import {FormlyFieldConfig} from '@ngx-formly/core';
import {FormRowsEditComponent} from '../form-rows-edit/form-rows-edit.component';
import {BehaviorSubject} from 'rxjs';
import {LocalFormRowDto} from '../../models';

@Component({
  selector: 'nexnox-web-forms-form-edit',
  templateUrl: './form-edit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormEditComponent extends CorePortalEntityEditBaseComponent<FormDto> {
  @ViewChild('formRowsEditComponent', { static: true }) public formRowsEditComponent: FormRowsEditComponent;

  public rowsSubject: BehaviorSubject<LocalFormRowDto[]> = new BehaviorSubject<LocalFormRowDto[]>([]);

  constructor(
    protected injector: Injector
  ) {
    super(injector, 'OrgaPortalFeatureFormEditComponent');
  }

  public onRowsChange(rows: LocalFormRowDto[]): void {
    this.setModel({ ...this.model, rows });
    setTimeout(() => this.onModelChange(this.model));
  }

  public onModelChange(model: FormDto): void {
    this.modelValidSubject.next({
      ...this.modelValidSubject.getValue(),
      rows: this.formRowsEditComponent.isModelValid()
    });

    super.onModelChange(model);
  }

  /* istanbul ignore next */
  protected createForm(): FormlyFieldConfig[] {
    return [
      {
        key: 'title',
        type: 'input',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-12',
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.title',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.BASIC
          } as CorePortalFormlyReadonlyTyping,
          type: 'text'
        },
        expressionProperties: {
          'templateOptions.required': () => !this.readonly,
          'templateOptions.disabled': () => this.readonly,
          'templateOptions.readonly': () => this.readonly
        },
        hideExpression: () => !this.creating
      },
      {
        key: 'description',
        type: 'input',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-12',
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.description'
          } as CorePortalFormlyTranslatedTyping,
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.BASIC
          } as CorePortalFormlyReadonlyTyping,
          type: 'text'
        },
        expressionProperties: {
          'templateOptions.disabled': () => this.readonly,
          'templateOptions.readonly': () => this.readonly
        }
      },
      // this story is deferred for more discussion
      // {
      //   className: 'col-md-12 one-line-switch',
      //   type: 'core-portal-switch',
      //   key: 'isSensitive',
      //   wrappers: ['core-portal-translated'],
      //   templateOptions: {
      //     corePortalTranslated: {
      //       label: 'core-shared.shared.fields.is-sensitive',
      //     } as CorePortalFormlyTranslatedTyping,
      //   },
      //   expressionProperties: {
      //     'templateOptions.disabled': () => this.readonly
      //   },
      //   defaultValue: false,
      // },
    ];
  }

  protected setModel(model: FormDto): void {
    super.setModel(model);

    this.rowsSubject.next(model?.rows ?? []);
  }
}
