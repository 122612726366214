import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { I18nBreadcrumbResolver, UnsavedChangesGuard } from '@nexnox-web/core-shared';
import { HintCodeDetailComponent, HintCodeListComponent } from './containers';

export const TECH_PORTAL_HINT_CODES_ROUTES: Routes = [
  {
    path: '',
    component: HintCodeListComponent
  },
  {
    path: ':hintCodeId',
    component: HintCodeDetailComponent,
    canDeactivate: [UnsavedChangesGuard],
    data: {
      breadcrumbs: I18nBreadcrumbResolver,
      tab: 'hintCode',
      text: 'tech-portal.connected.hint-codes.subtitles.detail'
    }
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(TECH_PORTAL_HINT_CODES_ROUTES)
  ],
  exports: [
    RouterModule
  ]
})
export class HintCodesRoutingModule {
}
