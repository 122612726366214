import {NgModule} from '@angular/core';
import {CoreSharedModule} from '@nexnox-web/core-shared';
import {CorePortalRouterOverridesModule} from '../../router-overrides/router-overrides.module';
import {CorePortalCardboxModule} from '../cardbox/cardbox.module';
import {CorePortalEntityLabelsComponent} from "./components";
import {CorePortalEntitySelectModule} from "@nexnox-web/core-portal/lib";
import {CorePortalLabelService} from "@nexnox-web/core-portal/features/settings/features/labels/src/lib/store/services/label/label.service";


@NgModule({
  declarations: [
    CorePortalEntityLabelsComponent
  ],
  providers: [
		CorePortalLabelService
  ],
	imports: [
		CoreSharedModule,
		CorePortalCardboxModule,
		CorePortalRouterOverridesModule,
		CorePortalEntitySelectModule
	],
  exports: [
    CorePortalEntityLabelsComponent
  ]
})
export class CorePortalLabelHelperModule {
}
