import { TaskJobState } from '@nexnox-web/core-shared';

export const taskJobStateEnumOptions = [
  { label: 'orga-portal.tasks.task-job-states.1', value: TaskJobState.Pending },
  { label: 'orga-portal.tasks.task-job-states.2', value: TaskJobState.Start },
  { label: 'orga-portal.tasks.task-job-states.3', value: TaskJobState.Started },
  { label: 'orga-portal.tasks.task-job-states.4', value: TaskJobState.Done },
  { label: 'orga-portal.tasks.task-job-states.5', value: TaskJobState.Canceled },
  { label: 'orga-portal.tasks.task-job-states.6', value: TaskJobState.Overdue },
  { label: 'orga-portal.tasks.task-job-states.7', value: TaskJobState.Escalated }
];
