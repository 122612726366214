import {BaseXsStoreReducerTypes, entityXsStoreSetLoadingForAction, EntityXsStoreState} from '@nexnox-web/core-store';
import {ResourceModelDto} from '@nexnox-web/core-shared';
import {ModelDetailXsStoreActions} from './model-detail-xs-store.actions';
import {immerOn} from 'ngrx-immer/store';

export const createModelDetailXsStoreReducer = (
  actions: ModelDetailXsStoreActions,
  initialState: EntityXsStoreState<ResourceModelDto>
): BaseXsStoreReducerTypes<EntityXsStoreState<ResourceModelDto>, ModelDetailXsStoreActions>[] => ([
  immerOn(actions.merge, draft => {
    draft.entityData = entityXsStoreSetLoadingForAction(draft.entityData, {
      [actions.merge.type]: true
    });
  }),
  immerOn(actions.mergeSuccess, draft => {
    draft.entityData = entityXsStoreSetLoadingForAction(draft.entityData, {
      [actions.merge.type]: false
    });
  }),

  immerOn(actions.move, draft => {
    draft.entityData = entityXsStoreSetLoadingForAction(draft.entityData, {
      [actions.move.type]: true
    });
  }),
  immerOn(actions.moveSuccess, draft => {
    draft.entityData = entityXsStoreSetLoadingForAction(draft.entityData, {
      [actions.move.type]: false
    });
  })
]);
