import {
  functionContactListStore,
  FunctionContactListStoreState,
  functionDetailStore,
  FunctionDetailStoreState,
  functionListStore,
  FunctionListStoreState
} from './stores';

export interface FunctionsState {
  functionList: FunctionListStoreState;
  functionDetail: FunctionDetailStoreState;
  functionContactList: FunctionContactListStoreState;
}

export const getInitialFunctionsState = (): FunctionsState => ({
  functionList: functionListStore.getInitialState(),
  functionDetail: functionDetailStore.getInitialState(),
  functionContactList: functionContactListStore.getInitialState()
});
