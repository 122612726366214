import {ModuleWithProviders, NgModule} from "@angular/core";
import {InvitationsDeclarationsModule} from "./invitations-declarations.module";
import {InvitationsRoutingModule} from "./invitations-routing.module";
import {InvitationsStoreModule} from "./invitations-store.module";

@NgModule({
  imports: [
    InvitationsDeclarationsModule,
    InvitationsRoutingModule,
    InvitationsStoreModule
  ]
})
export class CorePortalInvitationsModule {
  public static forFeature(): ModuleWithProviders<InvitationsDeclarationsModule> {
    return {ngModule: InvitationsDeclarationsModule};
  }
}
