import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {
  BasicTableColumnType,
  BasicTableConfig,
  ProductEditions,
  TenantUsageBookingDto,
  TenantUsageDto,
  UnsubscribeHelper
} from '@nexnox-web/core-shared';
import {CorePortalPageTitleService} from "@nexnox-web/core-portal";
import {mergeMap, Observable} from "rxjs";
import {TenantService} from "@nexnox-web/core-portal/features/service/src/lib/tenants/store";
import {map} from "rxjs/operators";
import {tenantSubscriptionTypeEnumOptions} from "../../models";

interface TenantUsageLocalDto {
  title: ProductEditions;
  availableVolume: number;
  usage: {
    usage: number;
    total: number
  }
}

@Component({
  selector: 'nexnox-web-service-tenants-tenant-usage',
  templateUrl: './tenant-usage.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TenantUsageComponent extends UnsubscribeHelper implements OnInit {

  @Input() public tenantId: number | string;
  @Input() public loading$: Observable<boolean>;

  public config: BasicTableConfig;
  public usageData$: Observable<TenantUsageLocalDto[]>;

  constructor(
    private titleService: CorePortalPageTitleService,
    private tenantService: TenantService
  ) {
    super();
  }

  public ngOnInit(): void {
    this.titleService.setPageTitle('core-portal.service.tenants.license-usage');

    this.config = {
      class: 'w-100',
      columns: [
        {
          key: 'title',
          header: 'core-shared.shared.fields.edition',
          type: BasicTableColumnType.Enum,
          enumOptions: tenantSubscriptionTypeEnumOptions
        },
        {
          key: 'availableVolume',
          header: 'core-portal.service.tenants.fields.available-licenses',
          type: BasicTableColumnType.Text,
        },
        {
          key: 'usage',
          header: 'core-portal.service.tenants.fields.usage',
          type: BasicTableColumnType.UsageOfTotal
        }
      ]
    }

    this.usageData$ = this.loading$?.pipe(mergeMap(() => this._getUsageForTenant(this.tenantId)));
  }

  private _getUsageForTenant(tenantId: number | string): Observable<any[]> {
    return this.tenantService.getUsage(tenantId)
      .pipe(map((usage: TenantUsageDto) => this._mapUsageData(usage?.usages)));
  }

  private _mapUsageData(usageBooking: TenantUsageBookingDto[]): TenantUsageLocalDto[] {
    if (!usageBooking) return [];
    return usageBooking.map(booking => ({
      title: booking.edition,
      availableVolume: booking.amount - booking.usage,
      usage: {
        usage: booking.usage,
        total: booking.amount
      }
    }));
  }
}
