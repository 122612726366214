<p-confirmPopup key="edit-resource"></p-confirmPopup>

<nexnox-web-entity-edit *ngIf="!creating"
                        [entityEditBaseComponent]="this"
                        displayKey="title">
  <div *ngIf="model && !creating"
       class="d-flex w-100 justify-content-end text-muted p-2">
    <span [title]="model.lastModified | nexnoxWebBsDate:'L LT' | async">
      {{ 'core-shared.shared.time-phrases.modified' | translate }} {{ model.lastModified | nexnoxWebDayjsRelative | async }}
    </span>
    ,&nbsp;
    <span [title]="model.createdAt | nexnoxWebBsDate:'L LT' | async">
      {{ 'core-shared.shared.time-phrases.created' | translate }} {{ model.createdAt | nexnoxWebDayjsRelative | async }}
    </span>
  </div>
</nexnox-web-entity-edit>

<nexnox-web-tabs *ngIf="creating"
                 [internal]="true"
                 [isTransparent]="true"
                 startTab="entityEdit">
  <!-- Entity Edit Tab -->
  <ng-template nexnoxWebTabTemplate
               tabId="entityEdit"
               [heading]="title | translate"
               [hasError]="!isOwnModelValid()">
    <nexnox-web-entity-edit [entityEditBaseComponent]="this"></nexnox-web-entity-edit>
  </ng-template>

  <!-- Links -->
  <ng-template nexnoxWebTabTemplate
               tabId="links"
               [heading]="('tech-portal.subtitles.links' | translate) + (linksComponent ? (linksComponent.linksString$ | async) : '')"
               [hasError]="linksComponent ? !linksComponent.isModelValid() : false">
    <nexnox-web-tech-links #linksComponent
                           [links$]="linksSubject"
                           [loading]="loading"
                           [readonly]="readonly"
                           (linksChange)="onLinksChange($event)">
    </nexnox-web-tech-links>
  </ng-template>

  <!-- Attachments -->
  <ng-template nexnoxWebTabTemplate
               tabId="attachments"
               *ngIf="readSolutionPermission$ | async"
               [heading]="('tech-portal.subtitles.attachments' | translate) + (attachmentsComponent ? (attachmentsComponent.attachmentsString$ | async) : '')"
               [hasError]="attachmentsComponent ? !attachmentsComponent.isModelValid() : false">
    <nexnox-web-tech-attachments #attachmentsComponent
                                 [attachments$]="attachmentsSubject"
                                 [loading]="loading"
                                 [readonly]="readonly"
                                 (attachmentsChange)="onAttachmentsChange($event)">
    </nexnox-web-tech-attachments>
  </ng-template>
</nexnox-web-tabs>

<!-- Location Templates -->
<ng-template #locationSelectLabelTitleTemplate let-item="item">
  <nexnox-web-master-data-locations-location-location-number [location]="item"
                                                             [label]="true">
  </nexnox-web-master-data-locations-location-location-number>
</ng-template>

<ng-template #locationSelectOptionTitleTemplate let-item="item" let-searchTerm="searchTerm">
  <nexnox-web-master-data-locations-location-location-number [location]="item"
                                                             [searchTerm]="searchTerm"
                                                             [label]="false">
  </nexnox-web-master-data-locations-location-location-number>
</ng-template>

<!-- Resource Templates -->
<ng-template #resourceDescriptionTemplate let-item="item">
<nexnox-web-resources-resource-description
  [resource]="item">
</nexnox-web-resources-resource-description>
</ng-template>

<!-- Assign Contact Sidebar -->
<nexnox-web-ticket-assign-contact-sidebar #assignContactSidebar
                                          [currentSolutionContact]="model?.editor"
                                          (unassignContact)="unassignContact.emit($event)"
                                          (assignContact)="assignContact.emit($event)">
</nexnox-web-ticket-assign-contact-sidebar>

<!-- Assign Resource Sidebar -->
<ng-container *ngIf="isShowResourceAssignmentSidebar">
  <nexnox-web-ticket-assign-resource-sidebar #assignResourceSidebar
                                             [creating]="creating"
                                             [location]="model?.resource?.location ?? model?.location"
                                             [hasCurrentResource]="!!model?.resource"
                                             (unassignResource)="unassignResource.emit()"
                                             (assignResource)="onAssignResource($event)">
  </nexnox-web-ticket-assign-resource-sidebar>
</ng-container>
