import {ChangeDetectionStrategy, Component, EventEmitter, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {faSpinner} from '@fortawesome/free-solid-svg-icons/faSpinner';
import {CorePortalFormlyReadonlyTypes, CorePortalFormlyReadonlyTyping} from '@nexnox-web/core-portal';
import {CoreSharedSidebarBaseComponent, LocationSimpleDto, ResourceDto, ResourceState} from '@nexnox-web/core-shared';
import {FormlyFieldConfig} from '@ngx-formly/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {faExclamationTriangle} from "@fortawesome/free-solid-svg-icons/faExclamationTriangle";
import {TranslateService} from "@ngx-translate/core";
import {resourceStateColoredEnumOptions} from "../../models";
import {
  CorePortalFeatureMasterDataLocationService,
  noClosedLocationsFilter$
} from "@nexnox-web/core-portal/features/master-data/features/locations";
import {cloneDeep} from "lodash";
import {map, mergeMap} from "rxjs/operators";

@Component({
  selector: 'nexnox-web-resource-change-state-sidebar',
  templateUrl: './change-state-sidebar.component.html',
  styleUrls: ['./change-state-sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ResourceChangeStateSidebarComponent extends CoreSharedSidebarBaseComponent implements OnInit {
  @ViewChild('locationSelectLabelTitleTemplate', {static: true}) public locationSelectLabelTitleTemplate: TemplateRef<any>;
  @ViewChild('locationSelectOptionTitleTemplate', {static: true}) public locationSelectOptionTitleTemplate: TemplateRef<any>;

  @Output() public accept: EventEmitter<any> = new EventEmitter<any>();

  public newState: ResourceState = ResourceState.Operation;
  public currentLocation: LocationSimpleDto | undefined;
  public isActivateResource: boolean;
  public showIsKeepingLocationSwitch: boolean;

  public form: FormGroup;
  public model: { location: LocationSimpleDto, removeLocation: boolean } = {} as any;
  public fields: FormlyFieldConfig[];

  public currentLocation$: BehaviorSubject<LocationSimpleDto> = new BehaviorSubject<LocationSimpleDto>(null);
  public isActivateResource$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  public loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public faSpinner = faSpinner;
  public faExclamationTriangle = faExclamationTriangle;

  constructor(
    private translate: TranslateService,
    private locationService: CorePortalFeatureMasterDataLocationService
  ) {
    super();
  }

  public get newStateLabel$(): Observable<string> {
    return this.translate?.get(
      resourceStateColoredEnumOptions[this.newState - 1]?.label ?? ''
    );
  }

  public ngOnInit(): void {
    this.form = new FormGroup({});
    this.model = {} as any;
  }

  public onAccept(): void {
    this.loading$.next(true);

    let location: LocationSimpleDto;
    let isKeepingLocation: boolean;

    if (this.newState === ResourceState.Operation) {
      location = this.model.location;
    } else {
      isKeepingLocation = !this.model.removeLocation && !!this.currentLocation;
    }

    this.accept.emit({
      state: this.newState,
      location,
      isKeepingLocation
    });
    this.onHide();
  }

  public onHide(): void {
    this.loading$.next(false);
    super.onHide();
  }

  public onShow(newState: ResourceState, resource: ResourceDto): void {
    super.onShow();
    this.ngOnInit();
    this.newState = newState;
    this.loadFieldsAfterResourceUpdated();
    this.currentLocation$.next(cloneDeep(resource?.location));
    this.isActivateResource$.next(this.newState === ResourceState.Operation);
    this.loading$.next(false);
  }

  /* istanbul ignore next */
  private createForm(): FormlyFieldConfig[] {
    return [
      {
        key: 'location',
        type: 'core-portal-entity-select',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-12',
        defaultValue: null,
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.location'
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.ENTITY,
            displayKey: 'name',
            link: (location: LocationSimpleDto) => location?.locationId ? ['/masterdata', 'locations', location.locationId] : null,
            module: 'management'
          } as CorePortalFormlyReadonlyTyping,
          entityService: this.locationService,
          idKey: 'locationId',
          displayKey: 'name',
          wholeObject: true,
          multiple: false,
          mapSearchFilter: CorePortalFeatureMasterDataLocationService.mapSearchFilter,
          search: CorePortalFeatureMasterDataLocationService.searchCompare,
          selectLabelTitleTemplate: this.locationSelectLabelTitleTemplate,
          selectOptionTitleTemplate: this.locationSelectOptionTitleTemplate,
          showAll: true,
          defaultFilters$: noClosedLocationsFilter$,
        },
        hideExpression: () => !this.isActivateResource
      },
      {
        key: 'removeLocation',
        type: 'core-portal-switch',
        wrappers: ['core-portal-translated'],
        className: 'col-md-12',
        defaultValue: false,
        templateOptions: {
          corePortalTranslated: {
            label: 'resources.fields.change-state-remove-location',
          },
        },
        hideExpression: () => !this.showIsKeepingLocationSwitch
      }
    ];
  }

  private loadFieldsAfterResourceUpdated(): void {
    this.subscribe(this.isActivateResource$.pipe(
      mergeMap(isActivate => this.currentLocation$.pipe(
        map(currentLocation => {
          return {currentLocation: currentLocation, isActivate: isActivate}
        })
      ))
    ), (value) => {
      const {isActivate, currentLocation} = value;
      if (isActivate !== null) {
        this.currentLocation = currentLocation;
        this.isActivateResource = isActivate;
        this.showIsKeepingLocationSwitch = !!currentLocation && !isActivate;
        if (this.isActivateResource !== null) {
          this.model.location = currentLocation;
          this.fields = this.createForm();
        }
      }
    });
  }
}
