import { NgModule } from '@angular/core';
import { TabTemplateDirective } from './directives';
import { TabsComponent } from './components';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';

const exportedComponents = [
  TabsComponent
];

const exportedDirectives = [
  TabTemplateDirective
];

@NgModule({
  declarations: [
    ...exportedComponents,
    ...exportedDirectives
  ],
  imports: [
    CommonModule,
    TabsModule,
    TranslateModule
  ],
  exports: [
    ...exportedComponents,
    ...exportedDirectives
  ]
})
export class TabsDeclarationsModule {
}
