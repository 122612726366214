import { Injectable } from '@angular/core';
import { CoreSharedFileService } from '../file/file.service';
import { from, Observable, of, throwError } from 'rxjs';
import { FileDto } from '@nexnox-web/core-shared';
import { mergeMap, timeout } from 'rxjs/operators';
import { HttpEvent } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class CoreSharedImageService {
  public static readonly RESIZE_TIMEOUT = 3000;
  public static readonly MAX_IMAGE_SIZE = 10 * 1500 * 1500;

  constructor(
    private fileService: CoreSharedFileService,
    private translate: TranslateService
  ) {
  }

  public uploadImage(fileOrData: File | string, tenantId: number | string, quality?: number): Observable<HttpEvent<FileDto>> {
    const data$ = typeof fileOrData === 'string' ? of(fileOrData) : this.fileService.convertFileToData(fileOrData);

    return data$.pipe(
      mergeMap(imageData => this.resizeImage(
        imageData,
        undefined,
        undefined,
        undefined,
        quality
      ).pipe(
        mergeMap(resizedImageData => {
          const blob = this.fileService.convertDataToBlob(resizedImageData);
          const fileName = typeof fileOrData === 'string' ? 'default' : fileOrData.name.split('.').slice(0, -1).join('.');

          if (blob.size > CoreSharedImageService.MAX_IMAGE_SIZE) {
            return throwError(this.translate.instant('core-portal.core.error.file-size'));
          }

          return this.fileService.uploadBlob(blob, `${fileName}.png`, tenantId);
        })
      ))
    );
  }

  public resizeImage(
    imageData: string,
    maxWidth: number = 1500,
    maxHeight: number = 1500,
    contentType: string = 'image/png',
    quality: number = 0.9,
    respectAspectRatio: boolean = true
  ): Observable<string> {
    const image = new Image();

    return from(new Promise<string>((resolve, reject) => {
      image.onload = () => {
        const canvas = document.createElement('canvas');
        const canvasContext = canvas.getContext('2d');
        canvas.width = image.width;
        canvas.height = image.height;

        if (canvas.width > maxWidth || canvas.height > maxHeight) {
          if (respectAspectRatio) {
            const newSize = this.calculateAspectRatioFit(canvas.width, canvas.height, maxWidth, maxHeight);
            canvas.width = newSize.width;
            canvas.height = newSize.height;
          } else {
            image.width = maxWidth;
            image.height = maxHeight;
            canvas.width = maxWidth;
            canvas.height = maxHeight;
          }
        }

        canvasContext.drawImage(image, 0, 0, image.width, image.height, 0, 0, canvas.width, canvas.height);
        resolve(canvas.toDataURL(contentType, quality));
      };

      image.onabort = reject;
      image.onerror = reject;

      image.src = imageData;
    })).pipe(
      timeout(CoreSharedImageService.RESIZE_TIMEOUT)
    );
  }

  private calculateAspectRatioFit(srcWidth, srcHeight, maxWidth, maxHeight): { width: number, height: number } {
    const ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);
    return { width: srcWidth * ratio, height: srcHeight * ratio };
  }
}
