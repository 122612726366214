import { ChangeDetectionStrategy, Component, Injector } from '@angular/core';
import { ActionButton, CorePortalEntityDetailBaseComponent } from '@nexnox-web/core-portal';
import {AppPermissions, ExportType, StateMachineDto} from '@nexnox-web/core-shared';
import { stateMachineDetailStore } from '../../store';

@Component({
  selector: 'nexnox-web-ticket-settings-state-machines-state-machine-detail',
  templateUrl: './state-machine-detail.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StateMachineDetailComponent extends CorePortalEntityDetailBaseComponent<StateMachineDto> {
  public title = 'core-portal.settings.subtitles.ticket-settings.state-machine-detail';

  protected idParam = 'stateMachineId';
  protected displayKey = 'name';

  constructor(
    protected injector: Injector
  ) {
    super(injector, stateMachineDetailStore);
  }

  protected async getActionButtons(): Promise<ActionButton[]> {
    return this.getDefaultActionButtons(
      'core-portal.settings.actions.ticket-settings.edit-state-machine',
      'core-portal.settings.actions.ticket-settings.save-state-machine',
      'core-portal.settings.actions.ticket-settings.delete-state-machine',
      'core-portal.settings.descriptions.ticket-settings.delete-state-machine',
      AppPermissions.UpdateStateMachine,
      AppPermissions.DeleteStateMachine,
      ['state-machines'],
      undefined,
      undefined,
      ExportType.STATE_MACHINE
    );
  }
}
