import {ChangeDetectionStrategy, Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {faCheck} from '@fortawesome/free-solid-svg-icons/faCheck';
import {faSpinner} from '@fortawesome/free-solid-svg-icons/faSpinner';
import {
  CorePortalFormlyNgSelectTyping,
  CorePortalFormlyReadonlyTypes,
  CorePortalFormlyReadonlyTyping,
  CorePortalFunctionService
} from '@nexnox-web/core-portal';
import {
  ContactDto,
  CoreSharedSidebarBaseComponent,
  EditorSuggestionTargetType,
  FilterDto,
  FilterOperators,
  FilterTypes,
  FunctionDto
} from '@nexnox-web/core-shared';
import {FormlyFieldConfig} from '@ngx-formly/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {LocalEditorSuggestionDto} from "../../components";
import {cloneDeep, isNumber, isUndefined} from "lodash";
import {
  CorePortalFeatureMasterDataContactService
} from "@nexnox-web/core-portal/features/master-data/features/contacts/src/lib/store/services/contact/contact.service";
import {
  TechPortalFeatureTicketSettingsPriorityService
} from "@nexnox-web/tech-portal/features/ticket-settings/features/priority-sets/src/lib/store/services/priority/priority.service";
import {map, pairwise, startWith} from "rxjs/operators";

export const suggestionTargetTypeEnumOptions = [
  {label: 'resources.suggestion-target-types.1', value: EditorSuggestionTargetType.ByContact},
  {label: 'resources.suggestion-target-types.2', value: EditorSuggestionTargetType.ByFunction}
];

@Component({
  selector: 'nexnox-web-resources-suggestion-edit-sidebar',
  templateUrl: './suggestion-edit-sidebar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SuggestionEditSidebarComponent extends CoreSharedSidebarBaseComponent implements OnInit {

  @Output() public suggestionSaved: EventEmitter<LocalEditorSuggestionDto> = new EventEmitter<LocalEditorSuggestionDto>();
  public primarySuggestionsSubject: BehaviorSubject<LocalEditorSuggestionDto[]> = new BehaviorSubject<LocalEditorSuggestionDto[]>([]);

  public form: FormGroup;
  public model: LocalEditorSuggestionDto;
  public fields: FormlyFieldConfig[];

  public loading$: Observable<boolean>;

  public faCheck = faCheck;
  public faSpinner = faSpinner;

  constructor(
    private contactService: CorePortalFeatureMasterDataContactService,
    private priorityService: TechPortalFeatureTicketSettingsPriorityService,
    private functionService: CorePortalFunctionService
  ) {
    super();
  }

  public ngOnInit(): void {
    this.form = new FormGroup({});
    this.model = {} as LocalEditorSuggestionDto;
    this.fields = this.createForm();
  }

  public onHide(): void {
    super.onHide();
    this.form.reset();
    this.form.markAsPristine();
  }

  public onShow(
    isPrimary: boolean,
    suggestion: LocalEditorSuggestionDto,
    index: number,
    localPrimarySuggestions: LocalEditorSuggestionDto[]): void {

    this.primarySuggestionsSubject.next(localPrimarySuggestions);

    if (isUndefined(suggestion)) {
      this.model.isPrimary = isPrimary;
      this.model.type = EditorSuggestionTargetType.ByContact;
      this.form.get('type').setValue(suggestionTargetTypeEnumOptions[0].value);
    } else {
      this.model = suggestion;
    }
    this.model.index = index;

    // Clear id when type changes, to avoid api error
    // Remove either contact or function target, by type value
    // Re-subscribe everytime when onShow()
    // Win a race condition with timeOut at form
    setTimeout(() => this.subscribe(this.form.get('type')?.valueChanges.pipe(
        startWith(EditorSuggestionTargetType.ByContact),
        pairwise(),
      ), ([prev, curr]) => {
        if (isNumber(prev) && isNumber(curr)) {
          // Reset id
          this.form.get('id')?.setValue(undefined);
          // Reset target
          switch (curr) {
            case EditorSuggestionTargetType.ByContact:
              this.form.get('byFunction')?.setValue(null);
              break;
            case EditorSuggestionTargetType.ByFunction:
              this.form.get('byContact')?.setValue(null);
              break;
          }
        }
      })
    );

    super.onShow();
  }

  public onSave(): void {
    this.suggestionSaved.emit(cloneDeep(this.model));
    this.onHide();
  }

  /* istanbul ignore next */
  private createForm(): FormlyFieldConfig[] {
    return [
      {key: 'id'},
      {key: 'isPrimary'},
      {
        key: 'type',
        type: 'core-portal-ng-select',
        wrappers: ['core-portal-translated'],
        className: 'col-md-12',
        defaultValue: suggestionTargetTypeEnumOptions[0].value,
        templateOptions: {
          corePortalTranslated: {
            label: 'resources.subtitles.contact-function',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.ENUM,
            enumOptions: suggestionTargetTypeEnumOptions,
            translate: true
          } as CorePortalFormlyReadonlyTyping,
          corePortalNgSelect: {
            items: suggestionTargetTypeEnumOptions,
            translate: true,
            noClear: true,
            noSearch: true
          } as CorePortalFormlyNgSelectTyping,
          required: true
        }
      },
      {
        type: 'core-portal-divider',
        className: 'col-md-12'
      },
      {
        key: 'byContact',
        type: 'core-portal-entity-select',
        className: 'col-12',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        defaultValue: null,
        templateOptions: {
          corePortalTranslated: {
            label: 'resources.fields.solution-contact',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.ENTITY,
            displayKey: 'displayName',
            link: (contact: ContactDto) => contact?.contactId ? ['/masterdata', 'contacts', contact.contactId] : null,
            module: 'management'
          },
          entityService: this.contactService,
          idKey: 'contactId',
          displayKey: 'displayName',
          wholeObject: true,
          skipGetOne: true,
          clearable: false,
          link: (contact: ContactDto) => contact?.contactId ? ['/masterdata', 'contacts', contact.contactId] : null,
          module: 'management',
          required: true,
        },
        hideExpression: () => this.model.type === EditorSuggestionTargetType.ByFunction
      },
      {
        key: 'byFunction',
        type: 'core-portal-entity-select',
        className: 'col-12',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        defaultValue: 'all',
        templateOptions: {
          corePortalTranslated: {
            label: 'resources.fields.function'
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.ENTITY,
            displayKey: 'name',
            link: (functionProperty: FunctionDto) => functionProperty?.functionId ? ['/masterdata', 'functions', functionProperty.functionId] : null,
            module: 'management'
          },
          entityService: this.functionService,
          idKey: 'functionId',
          displayKey: 'name',
          wholeObject: true,
          multiple: false,
          showAll: false,
          required: true
        },
        hideExpression: () => this.model.type === EditorSuggestionTargetType.ByContact
      },
      {
        key: 'priority',
        type: 'core-portal-entity-select',
        className: 'col-12',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        defaultValue: null,
        templateOptions: {
          corePortalTranslated: {
            label: 'resources.fields.priority',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.ENTITY,
            displayKey: 'name'
          } as CorePortalFormlyReadonlyTyping,
          entityService: this.priorityService,
          idKey: 'priorityId',
          displayKey: 'name',
          wholeObject: true,
          skipGetOne: true,
          clearable: true,
          defaultFilters$: this.primarySuggestionsSubject.pipe(
            map((suggestions: LocalEditorSuggestionDto[]) => {
              const priorityIds = suggestions
                .map(suggestion => suggestion.priority?.priorityId)
                .filter(x => isNumber(x));

              return priorityIds.map(priorityId => ({
                property: 'priorityId',
                type: FilterTypes.DataTransferObject,
                operator: FilterOperators.NotEqual,
                value: priorityId.toString()
              })) as FilterDto[];
            })
          )
        },
        hideExpression: (value) => !value.isPrimary,
      },
      {
        key: 'processingTime',
        type: 'core-portal-timepicker',
        className: 'col-12',
        wrappers: ['core-portal-translated'],
        templateOptions: {
          corePortalTranslated: {
            label: 'resources.fields.solution-offset',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          },
          corePortalTimepicker: {
            mode: 'timespan',
            showYears: false,
            showWeeks: false,
            showDays: true
          }
        },
        hideExpression: (value) => !value.isPrimary,
        expressionProperties: {
          'templateOptions.required': (value) => value.isPrimary
        }
      }
    ];
  }
}
