import { AppEntityType } from '@nexnox-web/core-shared';
import { createSelector } from '@ngrx/store';
import { selectMissionsState } from '../../missions.selectors';
import { TechPortalFeatureMissionService } from '../../services';
import { MissionListXsStore } from './mission-list.xs-store';

/* istanbul ignore next */
export const missionListStore = new MissionListXsStore({
  actionLabel: 'Tech Portal - Missions - Mission List',
  stateSelector: createSelector(selectMissionsState, state => state.missionList),
  serviceType: TechPortalFeatureMissionService,
  entityType: AppEntityType.Mission,
  selectId: mission => mission.entity?.missionId ?? mission.model?.missionId
});
