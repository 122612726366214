import {Filter, FilterDto, SortObject} from '@nexnox-web/core-shared';

export interface DatatableLoadPagePayload {
  filters: FilterDto[];
  filtersChanged?: boolean;
  pageNumber: number;
  pageSize?: number;
  sortOptions: SortObject;
}

export interface ApplyFilterPayload {
  columnName: string,
  filter: Filter
}

export enum DatatableSelectionMode {
  None = 0,
  Single = 1,
  Multiple = 2
}
