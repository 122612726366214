<ng-template #entityEditTemplate let-title="title">
  <nexnox-web-controls-control-edit #editComponent
                                    [title]="title"
                                    [model]="createModel$ | async"
                                    [readonly]="false"
                                    [creating]="true"
                                    [loading]="(loaded$ | async) === false"
                                    (modelChange)="createModel$.next($event)">
  </nexnox-web-controls-control-edit>
</ng-template>

<nexnox-web-entity-overview #overviewComponent
                            detailLink="/controls"
                            module="inventory"
                            [entityOverviewBaseComponent]="this"
                            [entityEditTemplate]="entityEditTemplate"
                            [stereotyped]="false"
                            [showSettings]="false">
</nexnox-web-entity-overview>
