import {AppEntityType, InvitationDto} from '@nexnox-web/core-shared';
import {createSelector} from '@ngrx/store';
import {selectInvitationsState} from '../../invitations.selectors';
import {CorePortalInvitationsService} from '../../services';
import {InvitationsDetailXsStore} from "./invitations-detail.xs-store";
import {EntityXsStoreState} from "@nexnox-web/core-store";

export interface InvitationsDetailStoreState extends EntityXsStoreState<InvitationDto> {
}

/* istanbul ignore next */
export const invitationsDetailStore = new InvitationsDetailXsStore({
  actionLabel: 'Core Portal - Settings - Invitations - Invitation Detail',
  stateSelector: createSelector(selectInvitationsState, state => state.invitationsDetail),
  serviceType: CorePortalInvitationsService,
  entityType: AppEntityType.None,
  stereotyped: false
});
