import {
  AreaAssignmentBulkDto,
  ChangeEmailDto,
  ContactDto,
  CoreSharedApiBaseService
} from '@nexnox-web/core-shared';
import {Injectable, Injector} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable()
export class CorePortalFeatureMasterDataContactService extends CoreSharedApiBaseService {
  constructor(
    protected injector: Injector
  ) {
    super(injector, null, 'contact');
  }

  public resetPassword(contact: ContactDto): Observable<any> {
    return this.httpRequestService.post(`${this.buildPathForOne(contact.contactId)}/ForcePasswordResetEmail`, null).pipe(
      map(response => response.body)
    );
  }

  public forcePassword(passwordBase64: string, contact: ContactDto): Observable<any> {
    return this.httpRequestService.post(`${this.buildPathForOne(contact.contactId)}/forcePassword`, {passwordBase64}).pipe(
      map(response => response.body)
    );
  }

  public enableContact(contactId: number | string): Observable<void> {
    return this.httpRequestService.post<void>(`${this.buildPathForOne(contactId)}/enable`, null).pipe(
      map(response => response.body)
    );
  }

  public disableContact(contactId: number | string): Observable<void> {
    return this.httpRequestService.post<void>(`${this.buildPathForOne(contactId)}/disable`, null).pipe(
      map(response => response.body)
    );
  }

  public changeMailAddress(contactId: number | string, email: ChangeEmailDto): Observable<void> {
    return this.httpRequestService.post<void>(`${this.buildPathForOne(contactId)}/changeEmail`, email).pipe(
      map(response => response.body)
    )
  }

  public bulkAreaAssignment(body: AreaAssignmentBulkDto): Observable<void> {
    return this.httpRequestService.post<void>(`contact/area/bulkCreate`, body).pipe(
      map(response => response.body)
    )
  }
}
