import {Injectable, Injector, Type} from '@angular/core';
import {
  BaseXsStoreReducerTypes,
  PagedEntitiesXsStore,
  PagedEntitiesXsStoreActions,
  PagedEntitiesXsStoreEffects,
  PagedEntitiesXsStoreState
} from '@nexnox-web/core-store';
import {AppEntityType, MessageControlListDto} from '@nexnox-web/core-shared';
import {Action} from '@ngrx/store';
import {MessageControlIssueService} from "../../services";

export interface MessageControlIssueListXsStoreActions extends PagedEntitiesXsStoreActions<MessageControlListDto> {
}

export class MessageControlIssueListXsStore extends PagedEntitiesXsStore<MessageControlListDto> {
  public declare actions: MessageControlIssueListXsStoreActions;

  protected createActions(label: string): MessageControlIssueListXsStoreActions {
    return {
      ...super.createActions(label)
    };
  }

  protected createReducerArray(
    initialState: PagedEntitiesXsStoreState<MessageControlListDto>
  ): BaseXsStoreReducerTypes<PagedEntitiesXsStoreState<MessageControlListDto>, MessageControlIssueListXsStoreActions>[] {
    return [
      ...super.createReducerArray(initialState)
    ];
  }

  protected createEffects(
    serviceType: Type<MessageControlIssueService>,
    entityType: AppEntityType,
    prepareEntity: (entity: MessageControlListDto) => MessageControlListDto,
    prepareModel: (entity: MessageControlListDto, model: MessageControlListDto) => MessageControlListDto,
    sanitizeModel: (model: MessageControlListDto, entity: MessageControlListDto) => MessageControlListDto,
    ...args
  ): Type<PagedEntitiesXsStoreEffects<MessageControlListDto>> {
    const actions = this.actions;
    const selectors = this.selectors;

    @Injectable()
    class Effects extends PagedEntitiesXsStoreEffects<MessageControlListDto> {
      protected declare service: MessageControlIssueService;

      constructor(
        protected injector: Injector
      ) {
        super(injector, actions, selectors, serviceType, entityType, prepareEntity, prepareModel, sanitizeModel, true);
      }

      protected createEffects(): void {
        super.createEffects();
      }

      protected actionCallback(action: Action, isError: boolean = false): void {
        super.actionCallback(action, isError);
      }
    }

    return Effects;
  }
}
