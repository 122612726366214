<ng-template #entityEditTemplate>
  <nexnox-web-master-data-contacts-contact-area-edit #modelComponent
                                                 [title]="title | translate"
                                                 [model]="model$ | async"
                                                 [creating]="isCreate$ | async"
                                                 [readonly]="readonly$ | async"
                                                 [loading]="loading$ | async"
                                                 [stereotyped]="false"
                                                 (modelChange)="onModelChange($event)">
  </nexnox-web-master-data-contacts-contact-area-edit>
</ng-template>

<nexnox-web-entity-detail [entityDetailBaseComponent]="this"
                          [useTabs]="false"
                          [entityEditTemplate]="entityEditTemplate">
</nexnox-web-entity-detail>
