import { NgModule } from '@angular/core';
import {
  BsDatePipe,
  DashCasePipe,
  HighlightPipe,
  NexnoxWebFaIconString,
  NexnoxWebFractionPipe,
  NexnoxWebLocaleString,
  NexnoxWebMinutesToTimespanPipe
} from './pipes';

const exportedPipes = [
  BsDatePipe,
  DashCasePipe,
  HighlightPipe,
  NexnoxWebFaIconString,
  NexnoxWebLocaleString,
  NexnoxWebMinutesToTimespanPipe,
  NexnoxWebFractionPipe
];

@NgModule({
  declarations: [
    ...exportedPipes
  ],
  exports: [
    ...exportedPipes
  ]
})
export class CoreSharedSharedModule {
}
