import {EntityXsStore, EntityXsStoreState} from '@nexnox-web/core-store';
import {AddressDto, AppEntityType} from '@nexnox-web/core-shared';
import {Action, createSelector} from '@ngrx/store';
import {Injectable, Injector} from '@angular/core';
import {selectLocationsState} from "../../locations.selectors";
import {
  LocationAddressDetailService
} from "../../services";

export interface LocationAddressDetailStoreState extends EntityXsStoreState<AddressDto> {
}

export const locationAddressDetailStore = new EntityXsStore<AddressDto>({
  actionLabel: 'Core Portal - Master Data - Location Address Detail',
  stateSelector: createSelector(selectLocationsState, state => state.locationAddressDetail),
  serviceType: LocationAddressDetailService,
  stereotyped: true,
  entityType: AppEntityType.Address
});

export function locationAddressDetailStoreReducer(state: LocationAddressDetailStoreState, action: Action): any {
  return locationAddressDetailStore.reducer(state, action);
}

@Injectable()
export class LocationAddressAddressDetailStoreEffects extends locationAddressDetailStore.effects {
  constructor(
    protected injector: Injector
  ) {
    super(injector);
  }
}
