import { NgModule } from '@angular/core';
import { CoreSharedSortableListComponent } from './components';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { DndModule } from 'ngx-drag-drop';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslateModule } from '@ngx-translate/core';
import { CoreSharedSortableListItemTemplateDirective } from './directives';
import { ButtonModule } from 'primeng/button';
import { CoreSharedSharedModule } from '../shared';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { CheckboxModule } from 'primeng/checkbox';

@NgModule({
  declarations: [
    CoreSharedSortableListComponent,

    CoreSharedSortableListItemTemplateDirective
  ],
  imports: [
    CommonModule,
    FormsModule,
    DndModule,
    FontAwesomeModule,
    TranslateModule,
    CoreSharedSharedModule,
    ButtonModule,
    ConfirmPopupModule,
    CheckboxModule
  ],
  exports: [
    CoreSharedSortableListComponent,

    CoreSharedSortableListItemTemplateDirective
  ]
})
export class CoreSharedSortableListModule {
}
