import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ComponentFactoryResolver,
  Injector,
  Input,
  OnInit,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { CorePortalCardboxAction, CorePortalEntityDetailBaseComponent } from '@nexnox-web/core-portal';
import { AppEntityType, AppPermissions, SkeletonRootDto } from '@nexnox-web/core-shared';
import { stereotypeSkeletonStore } from '../../store';
import { BehaviorSubject, Observable } from 'rxjs';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import { delay, map, startWith, take } from 'rxjs/operators';
import { faSave } from '@fortawesome/free-solid-svg-icons/faSave';
import { CorePortalFeatureStereotypeSkeletonWrapperBaseComponent } from './stereotype-skeleton-wrapper-base.component';
import { ofType } from '@ngrx/effects';

@Component({
  selector: 'nexnox-web-stereotypes-stereotype-skeleton',
  templateUrl: './stereotype-skeleton.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StereotypeSkeletonComponent extends CorePortalEntityDetailBaseComponent<SkeletonRootDto> implements OnInit, AfterViewInit {
  @Input() public stereotypeId: number;
  @Input() public skeleton: any;
  @Input() public entityType: AppEntityType;

  public get skeletonRootId(): number {
    return this.skeletonRootIdSubject.getValue();
  }

  @Input()
  public set skeletonRootId(skeletonRootId: number) {
    this.skeletonRootIdSubject.next(skeletonRootId);
    this.id = skeletonRootId;
  }

  @ViewChild('editContainer', { read: ViewContainerRef }) public editContainer: ViewContainerRef;

  public title = 'core-portal.settings.subtitles.stereotype-skeleton';

  public creatingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public createModelSubject: BehaviorSubject<SkeletonRootDto> = new BehaviorSubject<SkeletonRootDto>({});

  public createHeaderActions: CorePortalCardboxAction[] = [];
  public editHeaderActions: CorePortalCardboxAction[] = [];

  public faTimes = faTimes;

  protected idParam = 'skeletonRootId';
  protected displayKey = 'skeletonRootId';
  protected useParamMap = false;
  protected initialGet = false;

  private skeletonRootIdSubject: BehaviorSubject<number> = new BehaviorSubject<number>(undefined);
  private skeletonInstanceSubject: BehaviorSubject<CorePortalFeatureStereotypeSkeletonWrapperBaseComponent> =
    new BehaviorSubject<CorePortalFeatureStereotypeSkeletonWrapperBaseComponent>(null);

  /* istanbul ignore next */
  constructor(
    protected injector: Injector,
    private componentFactoryResolver: ComponentFactoryResolver
  ) {
    super(injector, stereotypeSkeletonStore);

    this.createHeaderActions = [{
      label: 'core-portal.settings.actions.create-stereotype-skeleton',
      icon: faPlus,
      class: 'btn-outline-primary',
      isDisabled: () => this.createModelSubject.asObservable().pipe(
        map(() => !this.skeletonInstanceSubject.getValue()?.isModelValid())
      ),
      isLoading: () => this.loading$,
      permission: AppPermissions.CreateSkeletonRoot,
      callback: () => this.onCreate()
    }];

    this.editHeaderActions = [{
      label: 'core-portal.settings.actions.save-stereotype-skeleton',
      icon: faSave,
      class: 'btn-outline-primary',
      shouldShow: () => this.readonly$.pipe(
        map(readonly => !readonly)
      ),
      isDisabled: () => this.shouldDisablePrimaryAction(),
      isLoading: () => this.loading$,
      permission: AppPermissions.UpdateSkeletonRoot,
      callback: () => this.onSaveAction()
    }];
  }

  public ngOnInit(): void {
    this.subscribeToFragment();
    this.subscribeToParams();

    this.subscribe(this.actions$.pipe(
      ofType(stereotypeSkeletonStore.actions.createSuccess)
    ), () => setTimeout(() => this.createEditComponent(this.editContainer)));
  }

  public ngAfterViewInit(): void {
    if (this.skeletonRootId) this.getEntity(this.getId(), []);
  }

  public onCreate(): void {
    this.store.dispatch(stereotypeSkeletonStore.actions.create({
      model: this.createModelSubject.getValue(),
      stereotypeId: this.stereotypeId
    }));
  }

  public isModelValid(): Observable<boolean> {
    return this.skeletonInstanceSubject.asObservable().pipe(
      map(instance => instance?.isModelValid())
    );
  }

  public isOwnModelValid(): Observable<boolean> {
    return this.skeletonInstanceSubject.asObservable().pipe(
      map(instance => instance?.isOwnModelValid())
    );
  }

  public onCreateMode(): void {
    this.creatingSubject.next(true);
    setTimeout(() => this.createEditComponent(this.editContainer, true));
  }

  protected setViewMode(): void {
    super.setViewMode();
    this.creatingSubject.next(false);
    setTimeout(() => this.createEditComponent(this.editContainer));
  }

  protected setEditMode(): void {
    super.setEditMode();
    setTimeout(() => this.createEditComponent(this.editContainer));
  }

  protected getId(): number {
    return this.skeletonRootId;
  }

  /* istanbul ignore next */
  private createEditComponent(viewContainerRef: ViewContainerRef, creating: boolean = false): void {
    if (this.skeleton && viewContainerRef) {
      const componentFactory = this.componentFactoryResolver
        .resolveComponentFactory<CorePortalFeatureStereotypeSkeletonWrapperBaseComponent>(this.skeleton);
      viewContainerRef.clear();
      const componentRef = viewContainerRef.createComponent<CorePortalFeatureStereotypeSkeletonWrapperBaseComponent>(componentFactory);

      componentRef.instance.creating = creating;
      componentRef.instance.headerActions = creating ? this.createHeaderActions : this.editHeaderActions;
      componentRef.instance.readonly$ = this.readonly$;
      componentRef.instance.model$ = (creating ? this.createModelSubject.asObservable() : this.model$).pipe(
        map(model => model?.entity)
      );

      this.subscribe(componentRef.instance.modelChange.asObservable().pipe(
        creating ? startWith(({})) : delay(0)
      ), async model => {
        const oldModel = (creating ? this.createModelSubject.getValue() : (await this.model$.pipe(take(1)).toPromise()));
        const newModel = {
          ...oldModel, entity: { ...model, type: this.entityType }
        };
        creating ? this.createModelSubject.next(newModel) : this.onModelChange(newModel);
      });

      this.skeletonInstanceSubject.next(componentRef.instance);
      componentRef.changeDetectorRef.detectChanges();
    }
  }
}
