import {
  issueTemplateDetailStore,
  IssueTemplateDetailStoreState,
  issueTemplateListStore,
  IssueTemplateListStoreState
} from './stores';

export interface IssueTemplateState {
  issueTemplateList: IssueTemplateListStoreState;
  issueTemplateDetail: IssueTemplateDetailStoreState;
}

export const getInitialIssueTemplateState = (): IssueTemplateState => ({
  issueTemplateList: issueTemplateListStore.getInitialState(),
  issueTemplateDetail: issueTemplateDetailStore.getInitialState()
});
