<div class="module-container text-center mx-auto">
  <h2>{{ 'core-portal.welcome.title' | translate }}</h2>
  <p class="text-muted">{{ 'core-portal.welcome.description' | translate }}</p>

  <ul role="list" class="module-list mt-4 py-4 border-top border-grey-200">
    <li *ngFor="let module of modules$ | async"
        class="module-item"
        [class.disabled]="module.path === '/management'">
      <div>
        <div class="d-flex flex-shrink-0 align-items-center justify-content-center rounded-lg">
          <fa-icon [icon]="module.icon" size="2x" class="text-white"></fa-icon>
        </div>

        <div class="d-flex align-items-center ml-3 mr-auto">
          <h5 class="mb-0">
            <a class="module-item__link"
               [nexnoxWebTenantRouterLink]="!module.target ? module.path : null"
               [withoutModule]="true"
               (click)="onSelectModule(module)">
              <span class="position-absolute" aria-hidden="true"></span>
              {{ module.name | translate }}
              <span aria-hidden="true"> →</span>
            </a>
          </h5>
        </div>
      </div>
    </li>
  </ul>
</div>
