import {ModuleWithProviders, NgModule} from '@angular/core';
import {ControlsDeclarationsModule} from './controls-declarations.module';
import {ControlsStoreModule} from './controls-store.module';
import {ControlsRoutingModule} from './controls-routing.module';

@NgModule({
  imports: [
    ControlsDeclarationsModule,
    ControlsRoutingModule,
    ControlsStoreModule
  ]
})
export class TechPortalFeatureControlsModule {
  public static forFeature(): ModuleWithProviders<ControlsDeclarationsModule> {
    return {ngModule: ControlsDeclarationsModule};
  }
}
