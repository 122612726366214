import { minutesTo } from '../minutes-to/minutes-to.lodash';

export function timespanTo(value: string, excluded: string[] = []): {
  years: number,
  weeks: number,
  days: number,
  hours: number,
  minutes: number,
  negative: boolean
} {
  const matches = value?.match(/^(-)?((\d+)\.)?(\d+):(\d+):(\d+)$/);

  if (matches && matches.length === 7) {
    const negative = Boolean(matches[1]);
    const daysMatch = matches[3] ?? null;
    const hoursMatch = matches[4];
    const minutesMatch = matches[5];

    const { years, weeks, days, hours, minutes } = minutesTo(
      ((daysMatch ? +daysMatch : 0) * 24 * 60) + ((hoursMatch ? +hoursMatch : 0) * 60) + (minutesMatch ? +minutesMatch : 0),
      excluded
    );

    return {
      years: +years,
      weeks: +weeks,
      days: +days,
      hours: +hours,
      minutes: +minutes,
      negative
    };
  }

  return { years: 0, weeks: 0, days: 0, hours: 0, minutes: 0, negative: false };
}
