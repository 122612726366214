import { ChangeDetectionStrategy, Component, Injector } from '@angular/core';
import {
  CorePortalEntityEditBaseComponent,
  CorePortalFormlyNgSelectTyping,
  CorePortalFormlyReadonlyTypes,
  CorePortalFormlyReadonlyTyping
} from '@nexnox-web/core-portal';
import { CategoryDto } from '@nexnox-web/core-shared';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { Validators } from '@angular/forms';
import { categorySectorEnumOptions } from '../../models';

@Component({
  selector: 'nexnox-web-categories-category-edit',
  templateUrl: './category-edit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrgaPortalFeatureCategoryEditComponent extends CorePortalEntityEditBaseComponent<CategoryDto> {
  constructor(
    protected injector: Injector
  ) {
    super(injector);
  }

  protected createForm(): FormlyFieldConfig[] {
    return [
      {
        key: 'name',
        type: 'input',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-6',
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.name',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.BASIC
          } as CorePortalFormlyReadonlyTyping,
          type: 'text'
        },
        expressionProperties: {
          'templateOptions.required': () => this.creating && !this.readonly,
          'templateOptions.disabled': () => !this.creating || this.readonly,
          'templateOptions.readonly': () => !this.creating || this.readonly
        },
        hideExpression: () => !this.creating
      },
      {
        key: 'color',
        type: 'core-portal-color-picker',
        wrappers: ['core-portal-translated'],
        className: 'col-md-6',
        defaultValue: '#9B9B9B',
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.color',
            validationMessages: {
              required: 'core-portal.core.validation.required',
              hexColor: 'core-portal.core.validation.hex-color'
            }
          }
        },
        expressionProperties: {
          'templateOptions.required': () => !this.readonly,
          'templateOptions.disabled': () => this.readonly
        },
        validators: {
          hexColor: ctrl => Validators.pattern(/^#([0-9A-F]{3}){1,2}$/i)(ctrl) ? null : { hexColor: true }
        }
      },
      {
        key: 'sector',
        type: 'core-portal-ng-select',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-6',
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.display',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.ENUM,
            enumOptions: categorySectorEnumOptions,
            translate: true
          } as CorePortalFormlyReadonlyTyping,
          corePortalNgSelect: {
            items: categorySectorEnumOptions,
            translate: true
          } as CorePortalFormlyNgSelectTyping
        },
        expressionProperties: {
          'templateOptions.required': () => !this.readonly,
          'templateOptions.disabled': () => this.readonly,
          'templateOptions.readonly': () => this.readonly
        }
      }
    ];
  }
}
