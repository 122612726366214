import {Directive, Input} from '@angular/core';
import {faSpinner} from '@fortawesome/free-solid-svg-icons/faSpinner';
import {FaIconComponent} from '@fortawesome/angular-fontawesome';
import {IconProp} from '@fortawesome/fontawesome-svg-core';
import {isObservable, Subscription} from "rxjs";
import {take} from "rxjs/operators";

@Directive({
  selector: 'fa-icon[icon][nexnoxWebLoadingIcon]'
})
export class LoadingIconDirective {
  @Input() public icon: IconProp;

  private subscription: Subscription;

  constructor(
    private faIcon: FaIconComponent
  ) {
  }

  @Input()
  public set nexnoxWebLoadingIcon(loading: boolean) {
    if (loading) {
      this.faIcon.icon = faSpinner as IconProp;
      this.faIcon.spin = true;
    } else {
      if (this.icon && isObservable(this.icon)) {
        this.subscription = this.icon?.pipe(take(1)).subscribe({
            next: (icon: IconProp) => {
              this.faIcon.icon = icon
            },
            complete: () => {
              this.subscription?.unsubscribe();
            }
          }
        )
      } else if (this.icon) {
        this.faIcon.icon = this.icon;
      }
      this.faIcon.spin = false;
    }

    this.faIcon.render();
  }
}
