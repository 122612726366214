<nexnox-web-cardbox [title]="'core-portal.settings.subtitles.ticket-settings.state-machine-transitions' | translate"
                    [noDividerMarginBottom]="true"
                    ghostPaddingClasses="p-3">
  <div #snapContainer class="snap-container">
    <nexnox-web-snap #snapComponent
                     [width]="snapWidth"
                     [height]="snapHeight">
    </nexnox-web-snap>
  </div>

  <nexnox-web-entity-datatable #datatableComponent
                               [models$]="states$"
                               [usesStore]="false"
                               [paging]="null"
                               [loading]="false"
                               idProp="stateId"
                               displayProp="name"
                               [excludedColumns]="excludedColumns"
                               [defaultColumns]="defaultColumns"
                               [prependColumns]="prependColumns"
                               [showRefresh]="false"
                               [showSettings]="false"
                               [canDelete]="!readonly"
                               [hideFilters]="true"
                               [headerHeight]="45"
                               [columnTypings]="columnTypings"
                               [additionalHeaderActions]="datatableHeaderActions"
                               (loadPage)="onLoadPage($event)"
                               (delete)="onRemoveState($event)">
  </nexnox-web-entity-datatable>
</nexnox-web-cardbox>

<ng-template #addTransitionCellTemplate
             let-row="row">
  <span class="selectable w-100 h-100 align-items-center justify-content-end">
    <button *ngIf="shouldShowMakeTransition(row)"
            class="btn btn-sm btn-outline-primary ml-2"
            [title]="'core-portal.settings.actions.ticket-settings.add-state-machine-transition' | translate"
            [disabled]="readonly"
            (click)="onMakeTransition(row)">
      <fa-icon [icon]="faPlus" [fixedWidth]="true" size="sm"></fa-icon>
    </button>

    <button *ngIf="transitionMode && selectedState.stateId === row.stateId"
            class="btn btn-sm btn-outline-secondary ml-2"
            [title]="'core-portal.core.general.cancel' | translate"
            [disabled]="readonly"
            (click)="onCancel()">
        <fa-icon [icon]="faTimes" [fixedWidth]="true" size="sm"></fa-icon>
    </button>

    <button class="btn btn-sm btn-outline-secondary ml-2"
            [title]="'core-portal.settings.actions.ticket-settings.mark-start-state' | translate"
            [disabled]="shouldDisableMarkAsStart(row) || readonly"
            (click)="onMarkStateAsStart(row)">
      <fa-icon [icon]="faFlag" [fixedWidth]="true" size="md"></fa-icon>
    </button>

    <button class="btn btn-sm btn-outline-secondary ml-2"
            [title]="'core-portal.settings.actions.ticket-settings.mark-end-state' | translate"
            [disabled]="shouldDisableMarkAsEnd(row) || readonly"
            (click)="onMarkStateAsEnd(row)">
      <fa-icon [icon]="faFlagCheckered" [fixedWidth]="true" size="md"></fa-icon>
    </button>
  </span>
</ng-template>
