<div *ngIf="(createModeSubject | async) === true || id > 0" class="alert alert-primary d-flex align-items-center">
  <fa-icon [icon]="faExclamationTriangle" [fixedWidth]="true" class="mr-2"></fa-icon>
  {{ 'core-portal.settings.descriptions.contract-settings.info' | translate }}
</div>


<ng-template #entityEditTemplate>
  <nexnox-web-stereotypes-contract-settings-edit #modelComponent
                                                 [title]="title | translate"
                                                 [id]="id"
                                                 [readonly]="readonly$ | async"
                                                 [creating]="false"

                                                 [headerActions]="getCardBoxActionButtons()"
                                                 [model]="createModelSubject | async"
                                                 (modelChange)="createModelSubject.next($event)">
  </nexnox-web-stereotypes-contract-settings-edit>
</ng-template>

<div class="min-h-100">
  <nexnox-web-entity-detail *ngIf="id; else promptCreateReportTemplate"
                            [hideOverflow]="false"
                            [entityDetailBaseComponent]="this"
                            [entityEditTemplate]="entityEditTemplate"
                            [useTabs]="false">
  </nexnox-web-entity-detail>
</div>
<ng-template #promptCreateReportTemplate>
    <div *ngIf="(createModeSubject | async) === false; else entityEditTemplate"
         class="d-flex w-100 h-100 align-items-center justify-content-center flex-column py-5">
      <fa-icon class="d-flex justify-content-center mb-2" [icon]="faTimes" size="5x"></fa-icon>
      <h4 class="d-flex justify-content-center">
        {{ 'core-portal.settings.subtitles.stereotype-no-contract-settings.title' | translate }}
      </h4>
      <h6 class="d-flex justify-content-center">
        {{ 'core-portal.settings.subtitles.stereotype-no-contract-settings.subtitle' | translate }}
      </h6>

      <button *ngIf="(readonly$ | async) === false"
              type="button"
              pButton
              class="p-button-secondary p-button-outlined"
              [label]="'core-portal.settings.actions.create-contract-settings' | translate"
              (click)="setCreateMode()">
      </button>
    </div>
</ng-template>
