import {ChangeDetectionStrategy, Component, Injector, OnInit, ViewChild} from '@angular/core';
import {ActionButton, CorePortalEntityDetailBaseComponent} from '@nexnox-web/core-portal';
import {AppPermissions, InfoDto, OrgaAttachmentDto} from '@nexnox-web/core-shared';
import {infoDetailStore} from '../../store';
import {OrgaPortalAttachmentsComponent} from '@nexnox-web/orga-portal-lib';
import {lastValueFrom, Observable} from 'rxjs';
import {map, switchMap, take} from 'rxjs/operators';
import {select} from '@ngrx/store';
import {faPaperPlane} from '@fortawesome/free-solid-svg-icons/faPaperPlane';
import {faCopy} from "@fortawesome/free-solid-svg-icons/faCopy";
import dayjs from 'dayjs';
import {isEmpty} from "lodash";

@Component({
  selector: 'nexnox-web-infos-info-detail',
  templateUrl: './info-detail.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InfoDetailComponent extends CorePortalEntityDetailBaseComponent<InfoDto> implements OnInit {
  @ViewChild('attachmentsComponent') public attachmentsComponent: OrgaPortalAttachmentsComponent;

  public title = 'orga-portal.infos.subtitles.info-detail';

  public isShowProgressTab$: Observable<boolean>;
  public attachments$: Observable<OrgaAttachmentDto[]>;

  protected idParam = 'infoId';
  protected displayKey = 'title';

  constructor(
    protected injector: Injector
  ) {
    super(injector, infoDetailStore);
  }

  public ngOnInit(): void {
    super.ngOnInit();

    this.attachments$ = this.model$.pipe(
      map(model => model?.attachments ?? [])
    );

    this.isShowProgressTab$ = this.model$.pipe(
      map(model => Boolean(model?.requestRead))
    );

    this.subscribe(this.readonly$.asObservable(), () => {
      this.attachmentsComponent?.onReset();
    });
  }

  public onAttachmentsChange(attachments: OrgaAttachmentDto[], model: InfoDto): void {
    this.onModelChange({...model, attachments});
  }

  public onPushAction(): void {
    this.store.dispatch(infoDetailStore.actions.push());
  }

  public async onDuplicateInfo(): Promise<void> {
    const entity: InfoDto = await lastValueFrom(this.entity$.pipe(take(1)));

    if (entity) {
      this.entityCreatePresetService.setPreset('OrgaPortalFeatureInfoEditComponent', {
        ...entity,
        infoId: undefined,
      });
      await this.tenantRouter.navigate(['/infos'], {module: 'communication', fragment: 'create'});
    }
  }

  /* istanbul ignore next */
  protected async getActionButtons(): Promise<ActionButton[]> {
    return [
      ...this.getDefaultActionButtons(
        'orga-portal.infos.actions.edit-info',
        'orga-portal.infos.actions.save-info',
        'orga-portal.infos.actions.delete-info',
        'orga-portal.infos.descriptions.delete-info',
        AppPermissions.UpdateInfo,
        AppPermissions.DeleteInfo,
        ['/infos']
      ),
      {
        label: 'orga-portal.infos.actions.push-info',
        type: 'button',
        class: 'btn-outline-secondary',
        permission: AppPermissions.UpdateInfo,
        icon: faPaperPlane,
        isDisabled: () => this.model$.pipe(
          map(model => model?.dateTime),
          map(dateTime => {
            const date = dayjs.utc(dateTime);
            if (!date?.isValid()) return true;

            return date.isAfter(dayjs());
          })
        ),
        isLoading: () => this.loading$.pipe(
          switchMap(loading => this.store.pipe(
            select(infoDetailStore.selectors.selectEntityDataLoading, {key: 'push'}),
            map(pushLoading => loading || pushLoading)
          ))
        ),
        callback: () => this.modalService.showConfirmationModal(
          'orga-portal.infos.actions.push-info',
          'orga-portal.infos.descriptions.push-info',
          'warning',
          'orga-portal.infos.actions.push-info'
        )
          .then(() => this.onPushAction())
          .catch(() => null)
      },
      {
        label: 'orga-portal.infos.actions.duplicate-info',
        type: 'button',
        class: 'btn-outline-secondary',
        permission: AppPermissions.CreateInfo,
        icon: faCopy,
        isDisabled: () => this.model$.pipe(
          map(model => model?.dateTime),
          map(dateTime => {
            const date = dayjs.utc(dateTime);
            if (!date?.isValid()) return true;

            return date.isAfter(dayjs());
          })
        ),
        callback: () => this.onDuplicateInfo(),
        isLoading: () => this.loading$.pipe(
          switchMap(loading => this.store.pipe(
            select(infoDetailStore.selectors.selectEntityDataLoading, {key: 'push'}),
            map(pushLoading => loading || pushLoading)
          ))
        ),
        shouldShow: () => this.entity$.pipe(
          map(entity => Boolean(entity) && !isEmpty(entity))
        ),
      }
    ];
  }
}
