import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TranslateModule } from '@ngx-translate/core';
import { CorePortalTabsRootModule } from './tabs-root.module';
import { exportedModules } from './shared-exports';

@NgModule({
  imports: [
    CommonModule,
    ...exportedModules,
    TabsModule,
    TranslateModule
  ],
  exports: [
    ...exportedModules
  ]
})
export class CorePortalTabsModule {
  public static forRoot(): ModuleWithProviders<CorePortalTabsRootModule> {
    return {
      ngModule: CorePortalTabsRootModule
    };
  }
}
