<ng-template #entityEditTemplate>
  <ng-container #editContainer></ng-container>
</ng-template>

<nexnox-web-entity-detail *ngIf="skeletonRootId; else promptCreateReportTemplate"
                          [entityDetailBaseComponent]="this"
                          [entityEditTemplate]="entityEditTemplate"
                          [useTabs]="false"
                          [hideOverflow]="false">
</nexnox-web-entity-detail>

<ng-template #promptCreateReportTemplate>
  <div class="min-h-100">
    <div *ngIf="(creatingSubject | async) === false; else entityEditTemplate"
         class="d-flex w-100 h-100 align-items-center justify-content-center flex-column py-5">
      <fa-icon class="d-flex justify-content-center mb-2" [icon]="faTimes" size="5x"></fa-icon>
      <h4 class="d-flex justify-content-center">{{ 'core-portal.settings.subtitles.stereotype-no-skeleton.title' | translate }}</h4>
      <h6 class="d-flex justify-content-center">{{ 'core-portal.settings.subtitles.stereotype-no-skeleton.subtitle' | translate }}</h6>

      <button *ngIf="(readonly$ | async) === false"
              type="button"
              pButton
              class="p-button-secondary p-button-outlined"
              [label]="'core-portal.settings.actions.create-stereotype-skeleton' | translate"
              (click)="onCreateMode()">
      </button>
    </div>
  </div>
</ng-template>
