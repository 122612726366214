<div class="alert alert-info d-flex align-items-center">
  <fa-icon [icon]="faInfoCircle" [fixedWidth]="true" class="mr-2"></fa-icon>
  {{ 'controls.descriptions.resource-list' | translate }}
</div>

<nexnox-web-entity-overview *ngIf="(datatableConfig$ | async) as config"
                            #overviewComponent
                            detailLink="/resources"
                            module="inventory"
                            [entityOverviewBaseComponent]="this"
                            [isEmbedded]="true"
                            [title]="title | translate"
                            [showSettings]="!readonly"
                            [disableFilters]="readonly"
                            [allowMargin]="true"
                            [disableSettingsViewSelect]="true"
                            [saveFiltersOnChange]="true"
                            [datatableConfig]="config"
                            [customInit$]="ready$"
                            (datatableConfigChange)="onDatatableConfigChange($event)">
</nexnox-web-entity-overview>
