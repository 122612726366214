import { Injectable, Injector } from '@angular/core';
import { CoreSharedApiBaseService, Product } from '@nexnox-web/core-shared';

@Injectable()
export class TicketStatemachineService extends CoreSharedApiBaseService {
  constructor(
    protected injector: Injector
  ) {
    super(injector, Product.TECH, 'states', ['statemachine']);
  }
}
