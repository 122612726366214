import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import {
  CorePortalFormlyReadonlyTypes,
  CorePortalFormlyReadonlyTyping,
  CoreSharedRuleEditorListComponent,
  InheritedField,
  ModelValid
} from '@nexnox-web/core-portal';
import { BehaviorSubject, merge, Observable } from 'rxjs';
import {
  EscalationLevelDto,
  EscalationRuleDto,
  FilterDto,
  FilterOperators,
  FilterTypes,
  PriorityDto,
  StateDto,
  UnsubscribeHelper
} from '@nexnox-web/core-shared';
import { TechPortalFeatureTicketSettingsPriorityService } from '@nexnox-web/tech-portal/features/ticket-settings/features/priority-sets';
import { TechPortalFeatureTicketSettingsStateService } from '@nexnox-web/tech-portal/features/ticket-settings/features/states';
import { TechPortalFeatureTicketSettingsEscalationLevelService } from '@nexnox-web/tech-portal/features/ticket-settings/features/escalation-levels';
import { distinctUntilChanged, map, startWith, withLatestFrom } from 'rxjs/operators';
import { isEqual } from 'lodash';

@Component({
  selector: 'nexnox-web-resources-escalation-rules',
  templateUrl: './escalation-rules.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EscalationRulesComponent extends UnsubscribeHelper implements OnInit, ModelValid {
  @Input() public rules$: Observable<EscalationRuleDto[]>;
  @Input() public readonly: boolean;
  @Input() public loading: boolean;

  @ViewChild('rulesEdit') public rulesEdit: CoreSharedRuleEditorListComponent;

  @Output() public rulesChange: EventEmitter<EscalationRuleDto[]> = new EventEmitter<EscalationRuleDto[]>();

  public rulesTitle: any;
  public rulesFieldsFn: any;

  private values$: Observable<{ priority: PriorityDto, state: StateDto, escalationLevel: EscalationLevelDto }>;
  private priorityValueSubject: BehaviorSubject<PriorityDto> = new BehaviorSubject<PriorityDto>(null);
  private stateValueSubject: BehaviorSubject<StateDto> = new BehaviorSubject<StateDto>(null);
  private escalationLevelValueSubject: BehaviorSubject<EscalationLevelDto> = new BehaviorSubject<EscalationLevelDto>(null);

  constructor(
    private priorityService: TechPortalFeatureTicketSettingsPriorityService,
    private stateService: TechPortalFeatureTicketSettingsStateService,
    private escalationLevelService: TechPortalFeatureTicketSettingsEscalationLevelService
  ) {
    super();

    this.rulesTitle = (rule: EscalationRuleDto) => rule.level?.name;
    this.rulesFieldsFn = () => this.createRulesFields();
  }

  public ngOnInit(): void {
    this.values$ = merge(
      this.priorityValueSubject.asObservable(),
      this.stateValueSubject.asObservable(),
      this.escalationLevelValueSubject.asObservable()
    ).pipe(
      withLatestFrom(this.priorityValueSubject.asObservable()),
      withLatestFrom(this.stateValueSubject.asObservable()),
      withLatestFrom(this.escalationLevelValueSubject.asObservable()),
      map(([[[_, priority], state], escalationLevel]) => ({ priority, state, escalationLevel }))
    );
  }

  public onRulesChange(rules: EscalationRuleDto[]): void {
    this.rulesChange.emit(rules);
  }

  public reset(): void {
    this.rulesEdit?.ngOnInit();
  }

  public isModelValid(): boolean {
    return this.rulesEdit ? this.rulesEdit.areItemsValid() : true;
  }

  public isOwnModelValid(): boolean {
    return this.rulesEdit ? this.rulesEdit.isModelValid() : true;
  }

  public isOwnModelPristine(): boolean {
    return this.rulesEdit ? this.rulesEdit.isPristine() : true;
  }

  /* istanbul ignore next */
  private createRulesFields(): InheritedField[] {
    return [
      {
        formlyConfig: {
          type: 'core-portal-entity-select',
          wrappers: ['core-portal-translated', 'core-portal-readonly'],
          defaultValue: null,
          templateOptions: {
            corePortalTranslated: {
              label: 'resources.fields.priority',
              validationMessages: {
                required: 'core-portal.core.validation.required'
              }
            },
            corePortalReadonly: {
              type: CorePortalFormlyReadonlyTypes.ENTITY,
              displayKey: 'name'
            } as CorePortalFormlyReadonlyTyping,
            entityService: this.priorityService,
            idKey: 'priorityId',
            displayKey: 'name',
            wholeObject: true,
            skipGetOne: true,
            clearable: false,
            defaultFilters$: merge(
              this.rules$,
              this.values$
            ).pipe(
              withLatestFrom(this.rules$),
              withLatestFrom(this.values$),
              map(([[_, rules], { state, escalationLevel }]) => {
                const combinations = rules
                  .filter(rule => rule.state?.stateId === state?.stateId)
                  .filter(rule => rule.level?.escalationLevelId === escalationLevel?.escalationLevelId);

                return combinations.map(rule => ({
                  property: 'priorityId',
                  type: FilterTypes.DataTransferObject,
                  operator: FilterOperators.NotEqual,
                  value: rule.priority.priorityId.toString()
                })) as FilterDto[];
              })
            )
          },
          expressionProperties: {
            'templateOptions.required': () => !this.readonly,
            'templateOptions.readonly': () => this.readonly
          },
          hooks: {
            onInit: field => this.subscribe(field.formControl.valueChanges.pipe(
              startWith(field.formControl.value),
              distinctUntilChanged((a, b) => isEqual(a, b))
            ), (value: PriorityDto) => {
              field.form.controls.priorityId.setValue(value?.priorityId ?? null);

              if (field.templateOptions.creating) {
                this.priorityValueSubject.next(value);
              }
            })
          }
        },
        ownValueKey: 'priority',
        disable: (_, creating, disabled) => disabled || !creating,
        additionalFields: [{ key: 'priorityId' }]
      },
      {
        formlyConfig: {
          type: 'core-portal-entity-select',
          wrappers: ['core-portal-translated', 'core-portal-readonly'],
          defaultValue: null,
          templateOptions: {
            corePortalTranslated: {
              label: 'resources.fields.state',
              validationMessages: {
                required: 'core-portal.core.validation.required'
              }
            },
            corePortalReadonly: {
              type: CorePortalFormlyReadonlyTypes.ENTITY,
              displayKey: 'name',
              link: (state: StateDto) => state?.stateId ?
                ['states', state.stateId] : null,
              module: 'settings'
            } as CorePortalFormlyReadonlyTyping,
            entityService: this.stateService,
            idKey: 'stateId',
            displayKey: 'name',
            wholeObject: true,
            skipGetOne: true,
            clearable: false,
            link: (state: StateDto) => state?.stateId ?
              ['states', state.stateId] : null,
            module: 'settings',
            defaultFilters$: merge(
              this.rules$,
              this.values$
            ).pipe(
              withLatestFrom(this.rules$),
              withLatestFrom(this.values$),
              map(([[_, rules], { priority, escalationLevel }]) => {
                const combinations = rules
                  .filter(rule => rule.priority?.priorityId === priority?.priorityId)
                  .filter(rule => rule.level?.escalationLevelId === escalationLevel?.escalationLevelId);

                return combinations.map(rule => ({
                  property: 'stateId',
                  type: FilterTypes.DataTransferObject,
                  operator: FilterOperators.NotEqual,
                  value: rule.state.stateId.toString()
                })) as FilterDto[];
              })
            )
          },
          expressionProperties: {
            'templateOptions.required': () => !this.readonly,
            'templateOptions.readonly': () => this.readonly
          },
          hooks: {
            onInit: field => this.subscribe(field.formControl.valueChanges.pipe(
              startWith(field.formControl.value),
              distinctUntilChanged((a, b) => isEqual(a, b))
            ), (value: StateDto) => {
              field.form.controls.stateId.setValue(value?.stateId ?? null);

              if (field.templateOptions.creating) {
                this.stateValueSubject.next(value);
              }
            })
          }
        },
        ownValueKey: 'state',
        disable: (_, creating, disabled) => disabled || !creating,
        additionalFields: [{ key: 'stateId' }]
      },
      {
        formlyConfig: {
          type: 'core-portal-entity-select',
          wrappers: ['core-portal-translated', 'core-portal-readonly'],
          defaultValue: null,
          templateOptions: {
            corePortalTranslated: {
              label: 'resources.fields.escalation-level',
              validationMessages: {
                required: 'core-portal.core.validation.required'
              }
            },
            corePortalReadonly: {
              type: CorePortalFormlyReadonlyTypes.ENTITY,
              displayKey: 'name',
              link: (escalationLevel: EscalationLevelDto) => escalationLevel?.escalationLevelId ?
                [ 'escalation-levels', escalationLevel.escalationLevelId] : null,
              module: 'settings'
            } as CorePortalFormlyReadonlyTyping,
            entityService: this.escalationLevelService,
            idKey: 'escalationLevelId',
            displayKey: 'name',
            wholeObject: true,
            skipGetOne: true,
            clearable: false,
            link: (escalationLevel: EscalationLevelDto) => escalationLevel?.escalationLevelId ?
              ['escalation-levels', escalationLevel.escalationLevelId] : null,
            module: 'settings',
            defaultFilters$: merge(
              this.rules$,
              this.values$
            ).pipe(
              withLatestFrom(this.rules$),
              withLatestFrom(this.values$),
              map(([[_, rules], { priority, state }]) => {
                const combinations = rules
                  .filter(rule => rule.priority?.priorityId === priority?.priorityId)
                  .filter(rule => rule.state?.stateId === state?.stateId);

                return combinations.map(rule => ({
                  property: 'escalationLevelId',
                  type: FilterTypes.DataTransferObject,
                  operator: FilterOperators.NotEqual,
                  value: rule.level.escalationLevelId.toString()
                })) as FilterDto[];
              })
            )
          },
          expressionProperties: {
            'templateOptions.required': () => !this.readonly,
            'templateOptions.readonly': () => this.readonly
          },
          hooks: {
            onInit: field => this.subscribe(field.formControl.valueChanges.pipe(
              startWith(field.formControl.value),
              distinctUntilChanged((a, b) => isEqual(a, b))
            ), (value: EscalationLevelDto) => {
              field.form.controls.levelId.setValue(value?.escalationLevelId ?? null);

              if (field.templateOptions.creating) {
                this.escalationLevelValueSubject.next(value);
              }
            })
          }
        },
        ownValueKey: 'level',
        disable: (_, creating, disabled) => disabled || !creating,
        additionalFields: [{ key: 'levelId' }]
      },
      {
        formlyConfig: {
          type: 'core-portal-timepicker',
          wrappers: ['core-portal-translated'],
          templateOptions: {
            corePortalTranslated: {
              label: 'resources.fields.escalation-offset',
              validationMessages: {
                required: 'core-portal.core.validation.required'
              }
            },
            corePortalTimepicker: {
              mode: 'timespan',
              showDays: true
            }
          },
          expressionProperties: {
            'templateOptions.required': () => !this.readonly
          }
        },
        inheritedKey: 'isOffsetInherited',
        ownValueKey: 'ownOffset',
        inheritedValueKey: 'inheritedOffset'
      }
    ];
  }
}
