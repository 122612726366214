import {ChangeDetectionStrategy, Component, Injector} from '@angular/core';
import {
  ActionButton,
  CorePortalEntityOverviewBaseComponent,
  DatatableActionButton,
  DeleteEntityModel
} from '@nexnox-web/core-portal';
import {AppEntityType, AppPermissions, ConsumptionCodeDto, Mappers} from '@nexnox-web/core-shared';
import {consumptionCodeListStore} from '../../store/stores';

@Component({
  selector: 'nexnox-web-connected-consumption-codes-consumption-code-list',
  templateUrl: './consumption-code-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConsumptionCodeListComponent extends CorePortalEntityOverviewBaseComponent<ConsumptionCodeDto> {
  public title = 'tech-portal.connected.consumption-codes.subtitles.list';
  public createTitle = 'tech-portal.connected.consumption-codes.actions.create';
  public idProperty = 'consumptionCodeId';
  public displayProperty = 'displayName';
  public datatableConfigName = 'ConsumptionCodeDefault';
  public componentId = 'ConsumptionCodeListComponent';

  constructor(
    protected injector: Injector
  ) {
    super(injector, consumptionCodeListStore, Mappers.ConsumptionCodeListDto.serializedName, AppEntityType.None);
  }

  /* istanbul ignore next */
  public getRowActionButtons(): DatatableActionButton[] {
    return this.getDefaultRowActionButtons(
      'tech-portal.connected.consumption-codes.actions.edit',
      (row: ConsumptionCodeDto) => `connected/consumption-codes/${row.consumptionCodeId}`,
      [AppPermissions.UpdateConsumptionCode],
      {
        module: 'settings'
      }
    );
  }

  public getDeleteEntityModel(): DeleteEntityModel {
    return {
      titleKey: 'tech-portal.connected.consumption-codes.actions.delete',
      descriptionKey: 'tech-portal.connected.consumption-codes.descriptions.delete',
      deletePermission: AppPermissions.DeleteConsumptionCode
    };
  }

  protected async getActionButtons(): Promise<ActionButton[]> {
    return this.getDefaultActionButtons(
      'tech-portal.connected.consumption-codes.actions.create',
      AppPermissions.CreateConsumptionCode
    );
  }
}
