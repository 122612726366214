import { Injectable, Injector } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, Router } from '@angular/router';
import { distinctUntilChanged, filter, map, switchMap, takeUntil } from 'rxjs/operators';
import { BehaviorSubject, from, isObservable, Observable, of } from 'rxjs';
import { CoreSharedBreadcrumb } from '../models';
import { CoreSharedBreadcrumbsResolver } from './breadcrumbs.resolver';
import { isArray, isEqual } from 'lodash';

@Injectable()
export class CoreSharedBreadcrumbsService {
  public get breadcrumbs$(): Observable<CoreSharedBreadcrumb[]> {
    return this.breadcrumbs.asObservable();
  }

  private breadcrumbs: BehaviorSubject<CoreSharedBreadcrumb[]> = new BehaviorSubject<CoreSharedBreadcrumb[]>([]);
  private defaultResolver = new CoreSharedBreadcrumbsResolver();

  private routerEvent$: Observable<NavigationEnd>;

  constructor(
    private injector: Injector,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.routerEvent$ = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(event => event as NavigationEnd)
    );

    this.routerEvent$.subscribe((event: NavigationEnd) => {
      const currentRoot = this.router.routerState.snapshot.root;
      this.resolveBreadcrumbs(currentRoot).pipe(
        takeUntil(this.routerEvent$),
        distinctUntilChanged((a, b) => isEqual(a, b))
      ).subscribe(breadcrumbs => this.breadcrumbs.next(breadcrumbs));
    });
  }

  private resolveBreadcrumbs(route: ActivatedRouteSnapshot): Observable<CoreSharedBreadcrumb[]> {
    const data = route.routeConfig?.data;
    let crumbs$: Observable<CoreSharedBreadcrumb[]>;

    if (data && data.breadcrumbs) {
      let resolver: CoreSharedBreadcrumbsResolver;

      if (data.breadcrumbs.prototype instanceof CoreSharedBreadcrumbsResolver) {
        resolver = this.injector.get(data.breadcrumbs);
      } else {
        resolver = this.defaultResolver;
      }

      const result = resolver.resolve(route, this.router.routerState.snapshot);
      crumbs$ = isObservable(result) ? result : (isArray(result) ? of(result) : from(result));
    } else {
      crumbs$ = of([]);
    }

    if (route.firstChild) {
      return crumbs$.pipe(
        switchMap(crumbs => this.resolveBreadcrumbs(route.firstChild).pipe(
          map(childCrumbs => [...crumbs, ...childCrumbs]),
          distinctUntilChanged((a, b) => isEqual(a, b))
        ))
      );
    }

    return crumbs$;
  }
}
