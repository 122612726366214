<nexnox-web-entity-overview #overviewComponent
                            [entityOverviewBaseComponent]="this"
                            [cardBoxHeaderActions]="headerActions"
                            [stereotyped]="false"
                            [detailLink]="'/manufacturers/' + parentIds[0] + '/model'">
</nexnox-web-entity-overview>

<nexnox-web-move-model-sidebar #moveModelSidebar
                               [modelToMove]="modelToMergeOrMoveSubject"
                               (move)="moveModel($event)">
</nexnox-web-move-model-sidebar>

<nexnox-web-merge-model-sidebar #mergeModelSidebar
                                [modelToMerge]="modelToMergeOrMoveSubject"
                                (merge)="mergeModel($event)">
</nexnox-web-merge-model-sidebar>
