import { ChangeDetectionStrategy, Component, Injector } from '@angular/core';
import { CorePortalEntityOverviewBaseComponent, DatatableActionButton } from '@nexnox-web/core-portal';
import { AppEntityType, AppPermissions, ContactDto, ControllerOperationId, Mappers } from '@nexnox-web/core-shared';
import { companyContactListStore } from '../../store';

@Component({
  selector: 'nexnox-web-master-data-companies-company-contact-list',
  templateUrl: './company-contact-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CompanyContactListComponent extends CorePortalEntityOverviewBaseComponent<ContactDto> {
  public title = 'core-portal.master-data.subtitles.contact-list';
  public createTitle = 'core-portal.master-data.subtitles.contact-create';
  public idProperty = 'contactId';
  public displayProperty = 'displayName';
  public pageOperation = ControllerOperationId.ContactControllerList;
  public componentId = 'CompanyContactListComponent';
  public enableViews = true;
  public datatableConfigName = 'MasterDataContactDefault';

  constructor(
    protected injector: Injector
  ) {
    super(injector, companyContactListStore, Mappers.ContactListDto.serializedName, AppEntityType.Contact);
  }

  /* istanbul ignore next */
  public getRowActionButtons(): DatatableActionButton[] {
    return this.getDefaultRowActionButtons(
      'core-portal.master-data.actions.edit-contact',
      contact => `masterdata/contacts/${contact.contactId}`,
      [AppPermissions.UpdateContact],
      {
        module: 'management'
      }
    );
  }
}
