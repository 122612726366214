import {CorePortalDatatablePredefinedViewConfig} from '@nexnox-web/core-portal';
import {FilterKind, FilterOperators, FilterTypes} from "@nexnox-web/core-shared";

export function provideDatatablePredefinedViewConfig(): CorePortalDatatablePredefinedViewConfig {
  return {
    ...provideManagementDatatablePredefinedViewConfig()
  };
}

function provideManagementDatatablePredefinedViewConfig(): CorePortalDatatablePredefinedViewConfig {
  return {
    /* Contact List */
    ContactListComponent: [
      {
        name: 'core-portal.master-data.subtitles.active-contacts',
        filters: [
          {
            type: FilterTypes.DataTransferObject,
            operator: FilterOperators.Default,
            property: 'isFree',
            kind: FilterKind.Default,
            value: 'true'
          }
        ]
      },
      {
        name: 'core-portal.master-data.subtitles.not-active-contacts',
        pageSize: 10,
        filters: [
          {
            type: FilterTypes.DataTransferObject,
            operator: FilterOperators.Default,
            property: 'isFree',
            kind: FilterKind.Default,
            value: 'false'
          },
        ]
      }
    ]
  }
}

