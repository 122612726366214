import { Injectable, Injector } from '@angular/core';
import { CoreSharedApiBaseService } from '@nexnox-web/core-shared';
import { CorePortalEmbeddedEntityBaseService } from '@nexnox-web/core-portal';
import { CorePortalFeatureMasterDataLocationGroupService } from '@nexnox-web/core-portal/features/master-data/features/location-groups';

@Injectable()
export class ContactLocationGroupService extends CorePortalEmbeddedEntityBaseService {
  constructor(
    protected injector: Injector
  ) {
    super(injector, CorePortalFeatureMasterDataLocationGroupService, null, 'locationGroup', ['contact']);
  }
}
