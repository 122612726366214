import { ChangeDetectionStrategy, Component, Injector, Input } from '@angular/core';
import { CoreSharedModalBaseComponent } from '../../base-components';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'nexnox-web-datepicker-modal',
  templateUrl: './datepicker-modal.component.html',
  styleUrls: ['./datepicker-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DatepickerModalComponent extends CoreSharedModalBaseComponent {
  public get value(): Date {
    return this.valueSubject.getValue();
  }

  @Input()
  public set value(value: Date) {
    this.valueSubject.next(value);
  }

  @Input() public minDate: Date;
  @Input() public maxDate: Date;

  private valueSubject: BehaviorSubject<Date> = new BehaviorSubject<Date>(null);

  constructor(
    protected injector: Injector
  ) {
    super(injector);
  }

  public onValueChange(value: Date): void {
    this.valueSubject.next(value);

    if (this.valueSubject.getValue()) {
      this.enableConfirmButton();
    } else {
      this.disableConfirmButton();
    }
  }

  /* istanbul ignore next */
  protected onConfirmButtonAvailable(): void {
    super.onConfirmButtonAvailable();
    this.disableConfirmButton();
  }

  /* istanbul ignore next */
  protected async onPreConfirm(): Promise<any> {
    return this.value;
  }
}
