import { ChangeDetectionStrategy, Component, Injector } from '@angular/core';
import { CorePortalEntityOverviewBaseComponent, DatatableActionButton, DeleteEntityModel } from '@nexnox-web/core-portal';
import { AppEntityType, AppPermissions, ControllerOperationId, Mappers, TicketListDto } from '@nexnox-web/core-shared';
import { resourceTicketsStore } from '../../store/stores';

@Component({
  selector: 'nexnox-web-resources-resource-tickets',
  templateUrl: './resource-tickets.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ResourceTicketsComponent extends CorePortalEntityOverviewBaseComponent<TicketListDto> {
  public title = 'tickets.subtitles.ticket-list';
  public idProperty = 'ticketId';
  public displayProperty = 'title';
  public pageOperation = ControllerOperationId.TicketsInResourcesControllerList;
  public componentId = 'ResourceTicketsComponent';
  public enableViews = true;
  public datatableConfigName = 'TicketDefault';

  constructor(
    protected injector: Injector
  ) {
    super(injector, resourceTicketsStore, Mappers.TicketListDto.serializedName, AppEntityType.Ticket);
  }

  /* istanbul ignore next */
  public getRowActionButtons(): DatatableActionButton[] {
    return this.getDefaultRowActionButtons(
      'tickets.actions.edit-ticket',
      (row: TicketListDto) => `/tickets/${row.ticketId}`,
      [AppPermissions.UpdateTicket],
      {
        module: 'communication'
      }
    );
  }

  public getDeleteEntityModel(): DeleteEntityModel {
    return {
      titleKey: 'tickets.actions.delete-ticket',
      descriptionKey: 'tickets.descriptions.delete-ticket',
      confirmKey: 'tickets.actions.delete-ticket',
      deletePermission: AppPermissions.DeleteTicket
    };
  }
}
