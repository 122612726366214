import { NgModule } from '@angular/core';
import { inventoryNumbersServices } from './store';
import { CorePortalFeatureInventoryNumberPatternEditComponent } from './components';
import { CoreSharedModule } from '@nexnox-web/core-shared';
import { CorePortalEntityModule } from '@nexnox-web/core-portal';
import { InventoryNumberPatternDetailComponent, InventoryNumberPatternListComponent } from './containers';

@NgModule({
  declarations: [
    InventoryNumberPatternListComponent,
    InventoryNumberPatternDetailComponent,

    CorePortalFeatureInventoryNumberPatternEditComponent
  ],
  imports: [
    CoreSharedModule,
    CorePortalEntityModule
  ],
  exports: [
    CorePortalFeatureInventoryNumberPatternEditComponent
  ],
  providers: [
    ...inventoryNumbersServices,
  ]
})
export class InventoryNumbersDeclarationsModule {
}
