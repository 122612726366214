<nexnox-web-entity-edit [entityEditBaseComponent]="this"
                        displayKey="name"
                        [stereotyped]="false"
                        [useTitle]="true"
                        [isTitleEditable]="false">
</nexnox-web-entity-edit>

<nexnox-web-cardbox [title]="'core-portal.master-data.subtitles.holidays-detail' | translate"
                    [loading]="loading"
                    paddingClasses="px-3 pb-3">
  <!-- Add Holiday -->
  <div *ngIf="!readonly" class="mb-3">
    <form [formGroup]="addHolidayForm">
      <formly-form class="row w-100"
                   [form]="addHolidayForm"
                   [model]="addHolidayModelSubject | async"
                   [fields]="addHolidayFields"
                   (modelChange)="addHolidayModelSubject.next($event)">
      </formly-form>
    </form>

    <div class="d-flex justify-content-end w-100">
      <button type="button"
              pButton
              [icon]="faPlus | nexnoxWebFaIconStringPipe"
              [title]="'core-shared.shared.actions.add' | translate"
              class="p-button-secondary p-button-outlined mr-2"
              [label]="'core-portal.master-data.actions.holidays-add-holiday' | translate"
              [disabled]="addHolidayForm.invalid"
              (click)="onAddHoliday()">
      </button>
    </div>
  </div>

  <!-- Holidays -->
  <div class="pt-2 px-2 pb-3">
    <nexnox-web-sortable-list [items]="holidays$ | async"
                              [disabled]="readonly"
                              [sortable]="false"
                              [editable]="false"
                              [notTitle]="true"
                              [hideItemName]="true"
                              [customTrackByFn]="trackByHolidayIdFn"
                              (itemsChange)="onHolidaysChange($event)">
      <ng-template nexnoxWebSortableListItemTemplate let-item="item">
        <form [formGroup]="item.form" class="row w-100 align-items-center">
          <formly-form class="col-12 px-0 row w-100 align-items-center"
                       [form]="item.form"
                       [model]="item.model"
                       [fields]="item.fields"
                       (modelChange)="onHolidayChange(item.id, $event)">
          </formly-form>
        </form>
      </ng-template>
    </nexnox-web-sortable-list>
  </div>
</nexnox-web-cardbox>
