import {ChangeDetectionStrategy, Component, Injector} from '@angular/core';
import {CorePortalEntityOverviewBaseComponent, DatatableActionButton} from '@nexnox-web/core-portal';
import {
  AppEntityType,
  AppPermissions,
  ControllerOperationId,
  CoreSharedIssueLinkUtilityService,
  IssueListDto,
  Mappers
} from '@nexnox-web/core-shared';
import {locationIssueListStore} from '../../store';

@Component({
  selector: 'nexnox-web-master-data-locations-location-issue-list',
  templateUrl: './location-issue-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LocationIssueListComponent extends CorePortalEntityOverviewBaseComponent<IssueListDto> {
  public title = 'issues.title';
  public idProperty = 'issueId';
  public displayProperty = 'title';
  public pageOperation = ControllerOperationId.IssueControllerId;
  public datatableConfigName = 'IssueDefault';
  public componentId = 'LocationIssueListComponent';
  public detailLink = '';

  public detailFn = (row: IssueListDto): void => {
    this.tenantRouter.navigate(this.issueUtilityService.getIssueSegments(row), {module: 'communication'}).then()
  };

  constructor(
    protected injector: Injector,
    private issueUtilityService: CoreSharedIssueLinkUtilityService
  ) {
    super(injector, locationIssueListStore, Mappers.IssueListDto.serializedName, AppEntityType.Issue);
  }

  public getRowActionButtons(): DatatableActionButton[] {
    return this.getDefaultRowActionButtons(
      'issues.actions.edit-issue',
      (row: IssueListDto) => this.issueUtilityService.getIssueLink(row),
      [AppPermissions.UpdateIssue],
      {
        module: 'communication'
      }
    );
  }
}
