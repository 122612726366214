import { ModuleWithProviders, NgModule } from '@angular/core';
import { FunctionsRoutingModule } from './functions-routing.module';
import { FunctionsDeclarationsModule } from './functions-declarations.module';
import { FunctionsStoreModule } from './functions-store.module';

@NgModule({
  imports: [
    FunctionsDeclarationsModule,
    FunctionsRoutingModule,
    FunctionsStoreModule
  ]
})
export class CorePortalFeatureMasterDataFunctionsModule {
  public static forFeature(): ModuleWithProviders<FunctionsDeclarationsModule> {
    return { ngModule: FunctionsDeclarationsModule };
  }
}
