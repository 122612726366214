import { NgModule } from '@angular/core';
import { FormlyModule } from '@ngx-formly/core';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { defaultExtensions, exportedModules, importedModules, sharedModules } from './shared-exports';

@NgModule({
  imports: [
    ...sharedModules,
    ...importedModules,
    FormlyModule.forRoot({
      extensions: defaultExtensions
    }),
    BsDatepickerModule.forRoot(),
    PopoverModule.forRoot()
  ],
  exports: [
    ...sharedModules,
    ...exportedModules
  ]
})
export class CorePortalFormlyRootModule {
}
