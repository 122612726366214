import {PagedEntitiesXsStore, PagedEntitiesXsStoreState} from '@nexnox-web/core-store';
import {AppEntityType, InfoProgressContactListDto} from '@nexnox-web/core-shared';
import {createSelector} from '@ngrx/store';
import {selectInfosState} from '../../infos.selectors';
import {InfoProgressContactService} from '../../services';

export interface InfoProgressContactListStoreState extends PagedEntitiesXsStoreState<InfoProgressContactListDto> {
}

/* istanbul ignore next */
export const infoProgressContactListStore = new PagedEntitiesXsStore<InfoProgressContactListDto>({
  actionLabel: 'Orga Portal - Infos - Info Progress Contact List',
  stateSelector: createSelector(selectInfosState, state => state.infoProgressContactList),
  serviceType: InfoProgressContactService,
  defaultPageSize: 11,
  entityType: AppEntityType.None,
  selectId: infoProgressContact => JSON.stringify([
    infoProgressContact.entity?.contactId ?? infoProgressContact?.model?.contactId
  ]),
  stereotyped: false
});
