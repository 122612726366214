import { RouterModule, Routes } from '@angular/router';
import { corePortalGetAppRoutingDefaults, CorePortalPermissionGuard } from '@nexnox-web/core-portal';
import { NgModule } from '@angular/core';
import { AppPermissions } from '@nexnox-web/core-shared';

const APP_ROUTES: Routes = corePortalGetAppRoutingDefaults(
  [
    {
      path: 'management',
      loadChildren: () => import('./modules').then(m => m.ManagementModule),
      data: {
        module: 'management'
      }
    },
    {
      path: 'communication',
      loadChildren: () => import('./modules').then(m => m.CommunicationModule),
      data: {
        module: 'communication'
      }
    },
    {
      path: 'inventory',
      loadChildren: () => import('./modules').then(m => m.InventoryModule),
      data: {
        module: 'inventory'
      }
    },
    {
      path: 'service',
      canActivate: [CorePortalPermissionGuard],
      loadChildren: () => import('./modules').then(m => m.ServiceModule),
      data: {
        permissions: [AppPermissions.UseExpiredSubscription],
        module: 'service'
      }
    },
    {
      path: 'analytics',
      canActivate: [CorePortalPermissionGuard],
      loadChildren: () => import('./modules').then(m => m.AnalyticsModule),
      data: {
        module: 'analytics'
      }
    },
    {
      path: 'settings',
      loadChildren: () => import('./modules').then(m => m.SettingsModule),
      data: {
        module: 'settings'
      }
    }
  ],
  [
    { path: 'management', permissions: [] },
    { path: 'communication', permissions: [] },
    { path: 'inventory', permissions: [] },
    { path: 'service', permissions: [AppPermissions.UseExpiredSubscription] },
    { path: 'analytics', permissions: [] },
    { path: 'settings', permissions: [] }
  ]
);

@NgModule({
  imports: [
    RouterModule.forRoot(APP_ROUTES)
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule {
}
