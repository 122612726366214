<p-dropdown [options]="tenantItems$ | async"
            [ngModel]="activeTenantItem$ | async"
            [disabled]="loading$ | async"
            [filter]="true"
            [emptyFilterMessage]="'core-shared.shared.table.empty' | translate"
            styleClass="w-100"
            [pTooltip]="'core-portal.core.header.tooltip.change-role' | translate"
            tooltipPosition="left"
            [tooltipDisabled]="embedded"
            (ngModelChange)="onTenantChange($event)">

  <ng-template pTemplate="selectedItem">
    {{ (activeTenant$ | async)?.name }}
  </ng-template>

  <ng-template pTemplate="item" let-item>
    <span>{{ item.label }}</span>
  </ng-template>

</p-dropdown>
