<nexnox-web-cardbox *ngIf="withCardbox; else attachmentsTemplate"
                    [title]="cardboxTitle | translate"
                    [loading]="loading"
                    paddingClasses="px-3 pb-3">
  <ng-container *ngTemplateOutlet="attachmentsTemplate"></ng-container>
</nexnox-web-cardbox>

<ng-template #attachmentsTemplate>
  <nexnox-web-sortable-list [items]="items$ | async"
                            [disabled]="readonly || (attachmentsLoading$ | async)"
                            [sortable]="false"
                            [hideItemName]="true"
                            (itemsChange)="onItemsChange($event)">
    <ng-template nexnoxWebSortableListItemTemplate let-item="item">
      <ng-container [ngTemplateOutlet]="attachmentItemTemplate"
                    [ngTemplateOutletContext]="{ item: item }"></ng-container>
    </ng-template>
  </nexnox-web-sortable-list>

  <ng-container *ngIf="!readonly">
    <div class="row align-items-center">

      <div class="col-md mt-3 pl-0 pr-0 pr-md-2">
        <div class="custom-file">
          <input #fileInput
                 type="file"
                 class="form-control-file custom-file-input"
                 id="fileInput"
                 accept=".pdf,.png,.jpg,.jpeg"
                 [disabled]="readonly || (loadingSubject | async) || (attachmentsLoading$ | async)"
                 (change)="onUploadFile($any($event.target).files)">
          <label class="custom-file-label" for="fileInput">
            <ng-container
              *ngIf="(selectedFileSubject | async) === null && (selectedFileAttachmentSubject | async) === null; else (selectedFileAttachmentSubject | async) ? selectedFileAttachmentTemplate : selectedFileTemplate">
              {{ 'core-shared.shared.actions.select-file' | translate }}...
            </ng-container>

            <ng-template #selectedFileAttachmentTemplate>
              {{ (selectedFileAttachmentSubject | async).file?.name }}
            </ng-template>

            <ng-template #selectedFileTemplate>
              {{ (selectedFileSubject | async).name }}
            </ng-template>
          </label>
        </div>
      </div>

      <div *ngIf="canAddExisting" class="col-md-3 mt-3 pl-0 pl-md-2 pr-0 pr-md-2">
        <button pButton
                type="button"
                [label]="'tech-portal.actions.add-existing-attachment' | translate"
                class="p-button-secondary p-button-outlined w-100"
                [disabled]="loading || (isInProgress$ | async) || (attachmentsLoading$ | async)"
                (click)="onAddExisting()">
        </button>
      </div>
    </div>

    <div *ngIf="progressSubject | async as progress" class="progress mt-3">
      <div class="progress-bar bg-primary" [style.width.%]="progress * 100">{{ progress | percent }}</div>
    </div>
  </ng-container>
</ng-template>

<ng-template #attachmentItemTemplate let-item="item">
  <nexnox-web-tech-attachments-download [item]="item"></nexnox-web-tech-attachments-download>
  <div class="d-md-flex align-items-center d-none ml-2">
    <span *ngIf="item.member">
      <span>{{ 'tech-portal.descriptions.in' | translate }} </span>
        <a [nexnoxWebTenantRouterLink]="item.member.link"
           [module]="item.member.module"
           target="'_blank'">
        {{ item.member.title }}
        </a>
    </span>
  </div>
</ng-template>
