import {ChangeDetectionStrategy, Component, Injector, OnInit} from '@angular/core';
import {
  ActionButton,
  CorePortalEntityOverviewBaseComponent,
  DatatableActionButton,
  DeleteEntityModel
} from '@nexnox-web/core-portal';
import {AppEntityType, AppPermissions, Mappers, NotificationRuleDto} from '@nexnox-web/core-shared';
import {notificationRulesListStore} from '../../store';

@Component({
  selector: 'nexnox-web-settings-notification-rules-list',
  templateUrl: './notification-rules-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationRulesListComponent extends CorePortalEntityOverviewBaseComponent<NotificationRuleDto> implements OnInit {
  public title = 'core-portal.settings.subtitles.notification-rules.notification-rules-list';
  public createTitle = 'core-portal.settings.subtitles.notification-rules.notification-rules-create';
  public idProperty = 'notificationRuleId';
  public displayProperty = 'title';
  public datatableConfigName = 'NotificationRulesDefault';
  public componentId = 'NotificationRulesListComponent';

  constructor(
    protected injector: Injector
  ) {
    super(injector, notificationRulesListStore, Mappers.NotificationRuleListDto.serializedName, AppEntityType.None);
  }

  public ngOnInit(): void {
    super.ngOnInit();
  }

  /* istanbul ignore next */
  public getRowActionButtons(): DatatableActionButton[] {
    return this.getDefaultRowActionButtons(
      'core-portal.settings.actions.notification-rules.edit',
      (row: NotificationRuleDto) => `notification-rules/${row.notificationRuleId}`,
      [AppPermissions.UpdateNotificationRule],
      {
        module: 'settings'
      }
    );
  }

  public getDeleteEntityModel(): DeleteEntityModel {
    return {
      titleKey: 'core-portal.settings.actions.notification-rules.delete',
      descriptionKey: 'core-portal.settings.actions.notification-rules.delete-description',
      confirmKey: 'core-portal.settings.actions.notification-rules.delete',
      deletePermission: AppPermissions.DeleteNotificationRule
    };
  }

  protected async getActionButtons(): Promise<ActionButton[]> {
    return this.getDefaultActionButtons('core-portal.settings.subtitles.notification-rules.notification-rules-create', AppPermissions.CreateResourceTask);
  }
}
