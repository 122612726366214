import { ModuleWithProviders, NgModule } from '@angular/core';
import { ResourcesDeclarationsModule } from './resources-declarations.module';
import { ResourcesStoreModule } from './resources-store.module';
import { ResourcesRoutingModule } from './resources-routing.module';

@NgModule({
  imports: [
    ResourcesDeclarationsModule,
    ResourcesRoutingModule,
    ResourcesStoreModule
  ]
})
export class TechPortalFeatureResourcesModule {
  public static forFeature(): ModuleWithProviders<ResourcesDeclarationsModule> {
    return { ngModule: ResourcesDeclarationsModule };
  }
}
