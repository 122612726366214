import {ControlNotificationType, ControlReceiverType} from "@nexnox-web/core-shared";

export const controlNotificationTypeEnumOptions = [
  {value: ControlNotificationType.Email, label: 'controls.control-notification-types.1'}
];

export const controlNotificationReceiverTypeEnumOptions: { label: string, value: ControlReceiverType }[] = [
  {
    label: 'controls.notification-receivers.1',
    value: ControlReceiverType.EmailReceiver
  },
  {
    label: 'controls.notification-receivers.2',
    value: ControlReceiverType.ContactReceiver
  },
  {
    label: 'controls.notification-receivers.3',
    value: ControlReceiverType.FunctionReceiver
  }
];
