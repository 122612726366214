import {
  locationGroupDetailStore,
  LocationGroupDetailStoreState,
  locationGroupListStore,
  LocationGroupListStoreState,
  locationGroupLocationListStore,
  LocationGroupLocationListStoreState
} from './stores';

export interface LocationGroupsState {
  locationGroupList: LocationGroupListStoreState;
  locationGroupDetail: LocationGroupDetailStoreState;

  locationGroupLocationList: LocationGroupLocationListStoreState;
}

export const getInitialLocationGroupsState = (): LocationGroupsState => ({
  locationGroupList: locationGroupListStore.getInitialState(),
  locationGroupDetail: locationGroupDetailStore.getInitialState(),

  locationGroupLocationList: locationGroupLocationListStore.getInitialState()
});
