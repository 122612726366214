import {
  taskActiveListStore,
  TaskActiveListStoreState,
  taskArchiveListStore,
  TaskArchiveListStoreState,
  taskDetailStore,
  TaskDetailStoreState,
  taskListStore,
  TaskListStoreState
} from './stores';

export interface TasksState {
  taskList: TaskListStoreState;
  taskDetail: TaskDetailStoreState;
  taskArchiveList: TaskArchiveListStoreState;
  taskActiveList: TaskActiveListStoreState;
}

export const getInitialTasksState = (): TasksState => ({
  taskList: taskListStore.getInitialState(),
  taskDetail: taskDetailStore.getInitialState(),
  taskArchiveList: taskArchiveListStore.getInitialState(),
  taskActiveList: taskActiveListStore.getInitialState()
});
