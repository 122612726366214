import {WinterhalterOperationStatus} from "@nexnox-web/core-shared";

export const productOrderOperationStatusTypes: {
  label: string,
  value: WinterhalterOperationStatus
}[] =
  [
    {
      label: 'issues.order-operation-status-types.1',
      value: WinterhalterOperationStatus.Created
    },
    {
      label: 'issues.order-operation-status-types.2',
      value: WinterhalterOperationStatus.Instructed
    },
    {
      label: 'issues.order-operation-status-types.3',
      value: WinterhalterOperationStatus.Canceled
    },
    {
      label: 'issues.order-operation-status-types.4',
      value: WinterhalterOperationStatus.Done
    },
    {
      label: 'issues.order-operation-status-types.5',
      value: WinterhalterOperationStatus.Transition
    },
  ];
