import {
  CorePortalFeatureArticleKindService,
  CorePortalFeatureArticleService,
  CorePortalFeatureArticleUsagesService
} from './services';
import {
  ArticleDetailStoreEffects,
  articleDetailStoreReducer,
  ArticleListStoreEffects,
  articleListStoreReducer,
  ArticleUsagesListStoreEffects,
  articleUsagesListStoreReducer
} from './stores';

export * from './services';
export * from './stores';

export * from './articles.state';
export * from './articles.selector';

export const articlesReducers = {
  articleList: articleListStoreReducer,
  articleDetail: articleDetailStoreReducer,
  articleUsagesList: articleUsagesListStoreReducer
};

export const articlesEffects = [
  ArticleListStoreEffects,
  ArticleDetailStoreEffects,
  ArticleUsagesListStoreEffects
];

export const articlesServices = [
  CorePortalFeatureArticleService,
  CorePortalFeatureArticleKindService,
  CorePortalFeatureArticleUsagesService
];
