import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardDetailComponent, DashboardListComponent } from './containers';
import {AppPermissions, I18nBreadcrumbResolver, UnsavedChangesGuard, UnsavedCreateGuard} from '@nexnox-web/core-shared';
import { CorePortalPermissionGuard } from '@nexnox-web/core-portal';

export const CORE_PORTAL_SETTINGS_DASHBOARDS_ROUTES: Routes = [
  {
    path: '',
    canActivate: [CorePortalPermissionGuard],
    canDeactivate: [UnsavedCreateGuard],
    component: DashboardListComponent,
    data: {
      permissions: [AppPermissions.UpdateDashboard]
    }
  },
  {
    path: ':dashboardId',
    component: DashboardDetailComponent,
    canActivate: [CorePortalPermissionGuard],
    canDeactivate: [UnsavedChangesGuard],
    data: {
      permissions: [AppPermissions.ReadDashboard],
      breadcrumbs: I18nBreadcrumbResolver,
      tab: 'dashboard',
      text: 'core-portal.core.header.breadcrumbs.settings.dashboard'
    }
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(CORE_PORTAL_SETTINGS_DASHBOARDS_ROUTES)
  ],
  exports: [
    RouterModule
  ]
})
export class DashboardsRoutingModule {
}
