import {AppEntityType, CoreSharedApiBaseService, IStereotypedDto, StereotypeDto} from '@nexnox-web/core-shared';
import {Injector, Type} from '@angular/core';
import {EntityXsStoreActions} from './entity-xs-store.actions';
import {EntityXsStoreSelectors} from './entity-xs-store.selectors';
import {BaseXsStoreEffects} from '../base';
import {createEffect, ofType} from '@ngrx/effects';
import {catchError, exhaustMap, filter, map, mergeMap, take, tap, withLatestFrom} from 'rxjs/operators';
import {Observable, of} from 'rxjs';
import {Action, MemoizedSelector, select} from '@ngrx/store';
import {cloneDeep, isUndefined} from 'lodash';
import {Router, UrlTree} from '@angular/router';
import {EntityXsStoreState} from './entity-xs-store.state';
import {EntityXsStoreGetSuccessPayload, EntityXsStoreModelUpdatePayload} from './entity-xs-store.payloads';
import {CORE_STORE_TENANT_ID_SELECTOR} from "@nexnox-web/libs/core-store/src";

export abstract class EntityXsStoreEffects<E, M = E, S = EntityXsStoreState<E, M>> extends BaseXsStoreEffects {

  public tenantIdSelector: MemoizedSelector<any, number>;

  public get$: any;
  public getSuccess$: any;

  public getStereotypes$: any;
  public getStereotypesSuccess$: any;

  public modelUpdate$: any;
  public modelReset$: any;

  public save$: any;
  public saveSuccess$: any;

  public delete$: any;
  public deleteSuccess$: any;

  public createOneNative$: any;
  public createOneNativeSuccess$: any;

  protected service: CoreSharedApiBaseService;
  protected router: Router;

  protected constructor(
    protected injector: Injector,
    protected actions: EntityXsStoreActions<E, M>,
    protected selectors: EntityXsStoreSelectors<E, M, S>,
    serviceType: Type<CoreSharedApiBaseService>,
    protected entityType: AppEntityType,
    protected prepareEntity: (entity: E) => E,
    protected prepareModel: (entity: E, model: M) => M,
    protected sanitizeModel: (model: M, entity: E) => E,
    protected stereotyped: boolean,
    protected inheritance: boolean,
    createEffects: boolean = true
  ) {
    super(injector, actions, selectors, false);

    this.service = injector.get(serviceType);
    this.router = injector.get(Router);
    this.tenantIdSelector = injector.get(CORE_STORE_TENANT_ID_SELECTOR);

    if (createEffects) {
      this.createEffects();
    }
  }

  protected createEffects(): void {
    super.createEffects();

    this.get$ = createEffect(() => this.actions$.pipe(
      ofType(this.actions.get),
      tap(action => this.actionCallback(action, false)),
      exhaustMap(({id, parentIds}) => this.mapEntityResponse(this.service.getOne<E>(id, parentIds)).pipe(
        map(entity => this.actions.getSuccess({
          entity: this.prepareEntity(entity),
          model: this.prepareModel(this.prepareEntity(entity), {} as any)
        })),
        catchError(error => of(this.actions.error({error, action: this.actions.get})))
      ))
    ));

    this.getSuccess$ = createEffect(() => this.actions$.pipe(
      ofType(this.actions.getSuccess),
      tap(action => this.actionCallback(action, false))
    ), {dispatch: false});

    this.getStereotypes$ = createEffect(() => this.actions$.pipe(
      ofType(this.actions.getStereotypes),
      exhaustMap(({excludeArchived}) => this.service.getStereotypes(this.entityType, excludeArchived).pipe(
        map(stereotypes => {
          if (this.stereotyped && (!stereotypes || !stereotypes.length)) {
            this.apiNotificationService.showTranslatedError('core-shared.shared.toast.no-stereotypes');
          }

          return this.actions.getStereotypesSuccess({stereotypes});
        }),
        catchError(error => of(this.actions.error({error, action: this.actions.getStereotypes})))
      ))
    ));

    this.getStereotypesSuccess$ = createEffect(() => this.actions$.pipe(
      ofType(this.actions.getStereotypesSuccess),
      tap(action => this.actionCallback(action, false))
    ), {dispatch: false});

    this.modelUpdate$ = createEffect(() => this.actions$.pipe(
      ofType(this.actions.modelUpdate),
      tap(action => this.actionCallback(action, false)),
      map(() => this.actions.update())
    ));

    this.modelReset$ = createEffect(() => this.actions$.pipe(
      ofType(this.actions.modelReset),
      withLatestFrom(this.store.pipe(select(this.selectors.selectModel))),
      withLatestFrom(this.store.pipe(select(this.selectors.selectEntity))),
      map(([[_, model], entity]) => this.actions.modelResetSuccess({
        model: this.prepareModel(entity, model)
      })),
      catchError(error => of(this.actions.error({error, action: this.actions.modelReset}))) // ToDo: Maybe test this
    ));

    this.save$ = createEffect(() => this.actions$.pipe(
      ofType(this.actions.save),
      withLatestFrom(this.store.pipe(select(this.selectors.selectModel))),
      withLatestFrom(this.store.pipe(select(this.selectors.selectEntity))),
      exhaustMap(([[{id, parentIds, queryParams, returnPath}, model], entity]) =>
        this.mapEntityResponse(this.service.saveOne(id, this.sanitizeModel(model, entity), parentIds, queryParams)).pipe(
          map(savedEntity => this.actions.saveSuccess({
            returnPath: returnPath,
            entity: this.prepareEntity(savedEntity),
            model: this.prepareModel(this.prepareEntity(savedEntity), model)
          })),
          catchError(error => of(this.actions.error({error, action: this.actions.save})))
        ))
    ));

    this.saveSuccess$ = createEffect(() => this.actions$.pipe(
      ofType(this.actions.saveSuccess),
      tap(action => this.actionCallback(action, false))
    ), {dispatch: false});

    this.delete$ = createEffect(() => this.actions$.pipe(
      ofType(this.actions.delete),
      exhaustMap(({id, parentIds, returnPath}) => this.service.deleteOne(id, parentIds).pipe(
        map(() => this.actions.deleteSuccess({id, parentIds, returnPath})),
        catchError(error => of(this.actions.error({error, action: this.actions.delete})))
      ))
    ));

    this.deleteSuccess$ = createEffect(() => this.actions$.pipe(
      ofType(this.actions.deleteSuccess),
      tap(action => this.actionCallback(action, false))
    ), {dispatch: false});

    this.createOneNative$ = createEffect(() => this.actions$.pipe(
      ofType(this.actions.createOneNative),
      withLatestFrom(this.store.pipe(select(this.selectors.selectModel))),
      withLatestFrom(this.store.pipe(select(this.tenantIdSelector))),
      exhaustMap(([[{parentIds, returnPath}, model], tenantId]) =>
        this.mapEntityResponse(this.service.createOne(this.prepareModelForCreate(model, tenantId), parentIds)).pipe(
          map(savedEntity => this.actions.createOneNativeSuccess({
            returnPath: this.modifyReturnPath(returnPath, savedEntity),
            entity: this.prepareEntity(savedEntity),
            model: this.prepareModel(this.prepareEntity(savedEntity), model)
          })),
          catchError(error => of(this.actions.error({error, action: this.actions.createOneNative})))
        ))
    ));

    this.createOneNativeSuccess$ = createEffect(() => this.actions$.pipe(
      ofType(this.actions.createOneNativeSuccess),
      tap(action => this.actionCallback(action, false))
    ), {dispatch: false});
  }

  protected actionCallback(action: Action, isError: boolean): void {
    super.actionCallback(action, isError);

    this.checkAction(this.actions.get, action, () => this.getActionCallback());
    this.checkAction(this.actions.getSuccess, action, payload => this.getSuccessActionCallback(payload));
    this.checkAction(this.actions.getStereotypesSuccess, action, () => this.getStereotypeSuccessActionCallback());
    this.checkAction(this.actions.modelUpdate, action, payload => this.modelUpdateActionCallback(payload));

    this.checkAction(this.actions.saveSuccess, action, ({
                                                          doNotEmitEvents,
                                                          returnPath
                                                        }) => this.saveSuccessActionCallback(doNotEmitEvents, returnPath));

    this.checkAction(this.actions.deleteSuccess, action, ({returnPath}) => this.deleteSuccessActionCallback(returnPath));

    this.checkAction(this.actions.createOneNativeSuccess, action, ({
                                                                     doNotEmitEvents,
                                                                     returnPath
                                                                   }) => this.createOneNativeSuccessActionCallback(doNotEmitEvents, returnPath));
  }

  protected getActionCallback(): void {
    if (this.stereotyped) this.store.dispatch(this.actions.getStereotypes({}));
  }

  protected getSuccessActionCallback(payload: EntityXsStoreGetSuccessPayload<E, M>): void {
  }

  protected getStereotypeSuccessActionCallback(): void {
  }

  protected modelUpdateActionCallback(action: EntityXsStoreModelUpdatePayload<M>): void {
  }

  protected saveSuccessActionCallback(doNotEmitEvents?: boolean, returnPath?: any[] | UrlTree): void {
    if (!doNotEmitEvents) {
      this.apiNotificationService.showTranslatedSuccess('core-shared.shared.toast.entity-saved');
    }

    if (returnPath) {
      if (returnPath instanceof UrlTree) {
        this.router.navigateByUrl(cloneDeep(returnPath));
      } else {
        this.router.navigate(returnPath);
      }
    }
  }

  protected deleteSuccessActionCallback(returnPath?: any[]): void {
    this.apiNotificationService.showTranslatedSuccess('core-shared.shared.toast.entity-deleted');

    if (returnPath) {
      const path: UrlTree = returnPath ? this.router.createUrlTree(returnPath, {
        queryParams: {
          reload: true
        }
      }) : undefined;
      this.router.navigateByUrl(path);
    }
  }

  protected createOneNativeSuccessActionCallback(doNotEmitEvents?: boolean, returnPath?: any[] | UrlTree): void {
    if (!doNotEmitEvents) {
      this.apiNotificationService.showTranslatedSuccess('core-shared.shared.toast.entity-created');
    }

    if (returnPath) {
      if (returnPath instanceof UrlTree) {
        this.router.navigateByUrl(cloneDeep(returnPath));
      } else {
        this.router.navigate(returnPath);
      }
    }
  }

  protected mapEntityResponse(entity$: Observable<E>): Observable<E> {
    return entity$.pipe(
      mergeMap(savedEntity => this.store.pipe(
        select(this.selectors.selectStereotypes),
        mergeMap(stereotypes => {
          if (this.stereotyped && this.isStereotypedDto(savedEntity)) {
            return this.getStereotypesForEntity(savedEntity, stereotypes);
          }

          return of(stereotypes);
        }),
        take(1),
        map(stereotypes => this.mergeStereotypeRowVersionIntoEntity(savedEntity as E & IStereotypedDto, stereotypes))
      ))
    );
  }

  private getStereotypesForEntity(entity: E, stereotypes: StereotypeDto[]): Observable<StereotypeDto[]> {
    return this.stereotyped ? of(stereotypes).pipe(
      filter(innerStereotypes => Boolean(innerStereotypes.length)),
      take(1)
    ) : of(stereotypes);
  }

  private mergeStereotypeRowVersionIntoEntity(
    entity: E & IStereotypedDto,
    stereotypes: StereotypeDto[]
  ): E & IStereotypedDto {
    return {
      ...entity,
      stereotypeRowVersion: entity.stereotypeId ? stereotypes.find(
        x => x.stereotypeId === entity.stereotypeId
      )?.rowVersion ?? [] : []
    };
  }

  private isStereotypedDto(entity: any): entity is IStereotypedDto {
    return !isUndefined((entity as IStereotypedDto).stereotypeId);
  }

  protected prepareModelForCreate(model: M, tenantId: number | string): E {
    const sanitizedModel = this.sanitizeModel({...model, rowVersion: []}, {} as any);
    sanitizedModel['tenantId'] = tenantId;
    return sanitizedModel;
  }

  private modifyReturnPath(returnPath: any[] | UrlTree, model: E): any[] | UrlTree {

    // looking for placeholder like :missionId and replacing it with real model.missionId
    const getId = (segment: any): any => {
      return typeof segment === 'string' && segment.includes(':') ? (model[segment.replace(':', '')] ?? segment) : segment
    };

    if (returnPath instanceof UrlTree) {
      return returnPath;
    } else {
      return returnPath.map((segment) => getId(segment));
    }
  }
}
