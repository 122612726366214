import { EntityXsStore, EntityXsStoreState } from '@nexnox-web/core-store';
import { AppEntityType, HolidaySetDto } from '@nexnox-web/core-shared';
import { selectHolidaysState } from '../../holidays.selectors';
import { createSelector } from '@ngrx/store';
import { CorePortalFeatureMasterDataHolidaySetService } from '../../services';

export interface HolidaysDetailStoreState extends EntityXsStoreState<HolidaySetDto> {
}

/* istanbul ignore next */
export const holidaysDetailStore = new EntityXsStore<HolidaySetDto>({
  actionLabel: 'Core Portal - Master Data - Holidays Detail',
  stateSelector: createSelector(selectHolidaysState, state => state.holidaysDetail),
  serviceType: CorePortalFeatureMasterDataHolidaySetService,
  entityType: AppEntityType.None,
  stereotyped: false
});
