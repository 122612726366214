<nexnox-web-cardbox [title]="'tickets.subtitles.ticket-related' | translate"
                    [transparent]="true"
                    [isAccordion]="true"
                    [isCollapsed]="true"
                    [indicatorNum]="(relatedTickets$ |async)?.length"
                    [isAutoExpandOnIndicator]="true"
                    paddingClasses="px-3">
  <div class="d-flex flex-column pb-2">
    <div *ngFor="let ticket of relatedTickets$ | async"
         class="d-flex flex-row- mb-2">
      <fa-icon [icon]="faEnvelope"
               [fixedWidth]="true"
               size="sm"
               class="mr-2"></fa-icon>
      <a class="btn-link"
         [nexnoxWebTenantRouterLink]="['tickets', ticket.ticketId]"
         module="communication"
         target="_blank">
        {{ ticket.title }}
      </a>
    </div>

    <div class="mb-2" *ngIf="!(relatedTickets$ | async)?.length">{{ 'core-shared.shared.table.empty' | translate }}</div>
  </div>
</nexnox-web-cardbox>
