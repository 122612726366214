<div id="map"
     [style.width]="width"
     [style.height]="height">
</div>
<ng-container *ngIf="isManualPlacesSearch">
  <div [style.width]="width"
       class="d-flex">
    <input id="places"
           class="form-control flex-grow-1"
           [disabled]="form.disabled"
           [style.width]="'auto'"
           type="text" />
    <button class="p-button-secondary p-button-outlined"
            (click)="takeOverAddress()"
            [disabled]="form.disabled || addressKeys.length === 0">
      {{ 'core-portal.core.general.takeover-and-search' | translate }}
    </button>
  </div>
</ng-container>

<ng-container *ngIf="isNoPointSetButValidAddress && form.enabled">
  <div [style.width]="width"
       class="d-flex">
    <button class="p-button-secondary p-button-outlined"
            (click)="takeOverAddress()"
            [disabled]="form.disabled || addressKeys.length === 0">
      {{ 'core-portal.core.general.takeover-and-search' | translate }}
    </button>
  </div>
</ng-container>
