import { ModuleWithProviders, NgModule } from '@angular/core';
import { PathsDeclarationsModule } from './paths-declarations.module';
import { PathsRoutingModule } from './paths-routing.module';
import { PathsStoreModule } from './paths-store.module';

@NgModule({
  imports: [
    PathsDeclarationsModule,
    PathsRoutingModule,
    PathsStoreModule
  ]
})
export class OrgaPortalFeatureKnowledgePathsModule {
  public static forFeature(): ModuleWithProviders<PathsDeclarationsModule> {
    return { ngModule: PathsDeclarationsModule };
  }
}
