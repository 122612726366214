import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {CoreSharedSidebarBaseComponent} from '@nexnox-web/core-shared';
import {BehaviorSubject, Observable} from "rxjs";

@Component({
  selector: 'nexnox-web-resource-event-list-sidebar',
  templateUrl: './event-list-sidebar.component.html',
  styleUrls: ['./event-list-sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EventListSidebarComponent extends CoreSharedSidebarBaseComponent {
  @Input() public parentId$: Observable<string | number> = new Observable<string | number>();

  public loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor() {
    super();
  }

  public onShow(): void {
    super.onShow();
    this.loading$.next(false);
  }

  public onHide(): void {
    this.loading$.next(false);
    super.onHide();
  }
}
