import {ChangeDetectionStrategy, Component, Input} from "@angular/core";
import {
  SettingsMenuConfigCategoryDto,
  SettingsMenuConfigChapterDto,
  SettingsMenuConfigItemDto,
  SettingsMenuDto
} from "../../models";
import {faPlus} from "@fortawesome/free-solid-svg-icons/faPlus";
import {faEye} from "@fortawesome/free-solid-svg-icons/faEye";
import {faChevronDown} from "@fortawesome/free-solid-svg-icons/faChevronDown";
import {faChevronUp} from "@fortawesome/free-solid-svg-icons/faChevronUp";
import {settingsMenuItemAnimations} from "./settings-menu-sidebar.animations";
import {BehaviorSubject} from "rxjs";
import {TranslateService} from "@ngx-translate/core";
import {UnsubscribeHelper} from "@nexnox-web/core-shared";
import {faBars} from "@fortawesome/free-solid-svg-icons/faBars";

@Component({
  selector: 'nexnox-web-settings-menu-sidebar',
  templateUrl: './settings-menu-sidebar.component.html',
  styleUrls: ['settings-menu-sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: settingsMenuItemAnimations
})
export class SettingsMenuSidebarComponent extends UnsubscribeHelper {

  @Input() public menu$: BehaviorSubject<SettingsMenuDto>;
  @Input() public module: string;

  public filter: string;
  public isHideMenu: boolean;
  public isMobile$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public faEye = faEye;
  public faPlus = faPlus;
  public faChevronDown = faChevronDown;
  public faChevronUp = faChevronUp;
  public faBars = faBars;


  constructor(private translate: TranslateService) {
    super();
    this._subscribeToMediaQuery();
  }

  public toggleCategory(category: SettingsMenuConfigCategoryDto, menu: SettingsMenuDto): void {
    category.isExpanded = !category.isExpanded;
    this.menu$.next(menu);
  }

  public toggleChapterCategories(chapter: SettingsMenuConfigChapterDto): void {
    const isExpanded = chapter.isExpanded;
    chapter.categories.forEach(category => category.isExpanded = !isExpanded);
    chapter.isExpanded = !isExpanded;
  }

  public toggleMenu(): void {
    this.isHideMenu = !this.isHideMenu;
  }

  public hideMenu(): void {
    if (this.isMobile$.getValue()) {
      this.isHideMenu = true;
    }
  }

  public onFilterMenu(value: string): void {
    let chapters: SettingsMenuConfigChapterDto[] = this.menu$.value.chapters;

    // Apply filter to multidimensional menu array
    chapters = chapters.map((chapter: SettingsMenuConfigChapterDto) => ({
      ...chapter, categories: chapter.categories.map((category: SettingsMenuConfigCategoryDto) => {

        // Hide items without containing value
        const filteredItems: SettingsMenuConfigItemDto[] = category.items.map((item: SettingsMenuConfigItemDto) =>
          ({
            ...item,
            isHidden: this._filterHiddenItem(item, chapter, category, value)
          })
        );

        // Hide and expand categories by filtered items
        const isCategoryHidden: boolean = filteredItems.every(item => item.isHidden);
        return {...category, items: filteredItems, isHidden: isCategoryHidden, isExpanded: !isCategoryHidden}
      })
    }));

    // Hide chapter if every category is hidden
    chapters = chapters.map((chapter: SettingsMenuConfigChapterDto) => ({
      ...chapter,
      isHidden: chapter.categories.every((category: SettingsMenuConfigCategoryDto) => category.isHidden)
    }));

    // Return
    this.menu$.next({...this.menu$.value, chapters: chapters});
  }

  private _filterHiddenItem(item: SettingsMenuConfigItemDto, chapter: SettingsMenuConfigChapterDto, category: SettingsMenuConfigCategoryDto, value: string): boolean {
    // Check if the value is contained in a title of either a chapter, a category or an item
    return !this._contains(chapter.title, value)
      && !this._contains(category.title, value)
      && !this._contains(item.title, value);
  }

  private _contains(title: string, value: string): boolean {
    return this.translate.instant(title).toLowerCase().includes(value.trim().toLowerCase());
  }

  private _subscribeToMediaQuery(): void {
    // Implement isMobile flag
    const matchesMobile = window.matchMedia('(max-width: 576px)');
    matchesMobile.addEventListener('change', event => this.isMobile$.next(event.matches));
    setTimeout(() => this.isMobile$.next(matchesMobile.matches));

    // On isMobile changes
    this.subscribe(this.isMobile$, (isMobile => {
      if (isMobile) {
        // Clear filter if user switches to mobile
        this.onFilterMenu('');
        this.filter = '';
      } else {
        // Open menu if user switches from mobile
        this.isHideMenu = false;
      }
    }));
  }
}
