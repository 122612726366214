import {Injectable, Injector} from '@angular/core';
import {createEffect, ofType} from '@ngrx/effects';
import {ManufacturerDetailXsStoreActions} from './manufacturer-detail-xs-store.actions';
import {catchError, map, switchMap, tap} from 'rxjs/operators';
import {CorePortalManufacturerService} from '../../services';
import {Action} from '@ngrx/store';
import {of} from 'rxjs';
import {manufacturerDetailStore} from "./manufacturer-detail.store";

@Injectable()
export class ManufacturerDetailStoreEffects extends manufacturerDetailStore.effects {
  public actions: ManufacturerDetailXsStoreActions;
  public service: CorePortalManufacturerService;

  public merge$: any;
  public mergeSuccess$: any;

  constructor(
    protected injector: Injector
  ) {
    super(injector);
  }

  protected createEffects(): void {
    super.createEffects();

    this.merge$ = createEffect(() => this.actions$.pipe(
      ofType(this.actions.merge),
      switchMap(({manufacturer, newManufacturerId}) => this.service.mergeManufacturer(manufacturer, newManufacturerId).pipe(
        map(() => this.actions.mergeSuccess()),
        catchError(error => of(this.actions.error({error, action: this.actions.merge})))
      ))
    ));

    this.mergeSuccess$ = createEffect(() => this.actions$.pipe(
      ofType(this.actions.mergeSuccess),
      tap(action => this.actionCallback(action, false))
    ), {dispatch: false});

  }

  protected actionCallback(action: Action, isError: boolean): void {
    super.actionCallback(action, isError);

    this.checkAction(this.actions.mergeSuccess, action, () => this.mergeSuccess());
  }

  protected mergeSuccess(): void {
    this.apiNotificationService.showTranslatedSuccess('core-portal.settings.toasts.resource-manufacturer.merge-success');
  }

}
