<nexnox-web-modal #modalComponent
                  [title]="'core-portal.settings.subtitles.ticket-settings.add-state' | translate"
                  [confirmButtonText]="'core-portal.settings.actions.ticket-settings.add-state' | translate"
                  [cancelButtonText]="'core-portal.core.general.cancel' | translate"
                  [preConfirmFn]="preConfirmFn">
  <nexnox-web-entity-select [model]="selectedState"
                            [options]="statesSelectOptions"
                            class="w-100"
                            (modelChange)="onModelChange($event)">
  </nexnox-web-entity-select>
</nexnox-web-modal>
