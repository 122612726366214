import {ChangeDetectionStrategy, Component, Injector} from '@angular/core';
import {CorePortalEntityOverviewBaseComponent, DatatableActionButton} from '@nexnox-web/core-portal';
import {AppEntityType, ContactListDto, Mappers} from '@nexnox-web/core-shared';
import {invitationsContactListStore} from '../../store/stores';

@Component({
  selector: 'nexnox-web-settings-invitations-contact-list',
  templateUrl: './invitations-contact-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InvitationsContactListComponent extends CorePortalEntityOverviewBaseComponent<ContactListDto> {
  public title = 'core-portal.settings.invitations.titles.contact-list';
  public createTitle = 'core-portal.settings.invitations.actions.create';
  public idProperty = 'contactId';
  public displayProperty = 'displayName';
  public datatableConfigName = 'MasterDataContactDefault';
  public componentId = 'InvitationsContactListComponent';
  public detailLink = 'masterdata/contacts';
  public module = 'management';
  public canDelete = false;
  public canCreate = false;
  public canRouteToDetail = true;

  constructor(
    protected injector: Injector
  ) {
    super(injector, invitationsContactListStore, Mappers.ContactListDto.serializedName, AppEntityType.Contact);
  }

  public getRowActionButtons(): DatatableActionButton[] {
    return [];
  }

}
