import {InvitationType} from "@nexnox-web/core-shared";


export const invitationTypeEnumOptions =
  [
    {
      label: 'core-portal.settings.invitations.invitation-types.1',
      value: InvitationType.Identify
    }
  ];
