<nexnox-web-cardbox [title]="'core-portal.settings.issue-template.published-resources' | translate"
                    [loading]="loading"
                    paddingClasses="px-3 pb-3">

  <div class="pt-2 px-2 pb-3" *ngIf="resources$ | async as items">
    <nexnox-web-basic-table [config]="resourceTableConfig"
                            [data]="items">
    </nexnox-web-basic-table>
  </div>

</nexnox-web-cardbox>
