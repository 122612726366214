import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { distinctUntilChanged, map, mergeMap, tap, withLatestFrom } from 'rxjs/operators';
import { UnsubscribeHelper } from '../../helper';
import { minutesTo, timespanTo } from '@nexnox-web/lodash';
import { isEqual, isFinite, isNull, isUndefined } from 'lodash';
import { faCaretUp } from '@fortawesome/free-solid-svg-icons/faCaretUp';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons/faCaretDown';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import { faMinus } from '@fortawesome/free-solid-svg-icons/faMinus';

@Component({
  selector: 'nexnox-web-time-picker',
  templateUrl: './time-picker.component.html',
  styleUrls: ['./time-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => TimePickerComponent)
    }
  ]
})
export class TimePickerComponent extends UnsubscribeHelper implements OnInit, ControlValueAccessor {
  @Input() public showYears = false;
  @Input() public showWeeks = false;
  @Input() public showDays = false;
  @Input() public showHours = true;
  @Input() public showMinutes = true;

  @Input() public yearStep = 1;
  @Input() public weekStep = 1;
  @Input() public dayStep = 1;
  @Input() public hourStep = 1;
  @Input() public minuteStep = 1;

  @Input() public minTime = 0;
  @Input() public maxTime = 1439;

  @Input() public mode: 'timespan' | 'number' = 'number';
  @Input() public canClear = true;
  @Input() public allowNegative = false;

  @Input() public hasError = false;

  public value$: Observable<string | number>;
  public isNegative$: Observable<boolean>;
  public years$: Observable<number>;
  public weeks$: Observable<number>;
  public days$: Observable<number>;
  public hours$: Observable<number>;
  public minutes$: Observable<number>;
  public valid$: Observable<boolean>;
  public isDisabled$: Observable<boolean>;

  public stepYearsFn: any;
  public stepWeeksFn: any;
  public stepDaysFn: any;
  public stepHoursFn: any;
  public stepMinutesFn: any;

  public faCaretUp = faCaretUp;
  public faCaretDown = faCaretDown;
  public faTimes = faTimes;
  public faMinus = faMinus;

  public get minHours(): number {
    return minutesTo(this.minTime).hours;
  }

  public get maxHours(): number {
    return minutesTo(this.maxTime).hours;
  }

  public get minMinutes(): number {
    return minutesTo(this.minTime).minutes;
  }

  public get maxMinutes(): number {
    return minutesTo(this.maxTime).minutes;
  }

  private isNegativeSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private yearsSubject: BehaviorSubject<number> = new BehaviorSubject<number>(null);
  private weeksSubject: BehaviorSubject<number> = new BehaviorSubject<number>(null);
  private daysSubject: BehaviorSubject<number> = new BehaviorSubject<number>(null);
  private hoursSubject: BehaviorSubject<number> = new BehaviorSubject<number>(null);
  private minutesSubject: BehaviorSubject<number> = new BehaviorSubject<number>(null);
  private updateSubject: Subject<void> = new Subject<void>();
  private validSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private isDisabledSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private onChange: any;
  private onTouched: any;

  constructor() {
    super();

    this.stepYearsFn = (negative: boolean, valid: boolean) => valid ? this.onStepYears(negative) : null;
    this.stepWeeksFn = (negative: boolean, valid: boolean) => valid ? this.onStepWeeks(negative) : null;
    this.stepDaysFn = (negative: boolean, valid: boolean) => valid ? this.onStepDays(negative) : null;
    this.stepHoursFn = (negative: boolean, valid: boolean) => valid ? this.onStepHours(negative) : null;
    this.stepMinutesFn = (negative: boolean, valid: boolean) => valid ? this.onStepMinutes(negative) : null;
  }

  public ngOnInit(): void {
    this.isNegative$ = this.isNegativeSubject.asObservable();
    this.years$ = this.yearsSubject.asObservable();
    this.weeks$ = this.weeksSubject.asObservable();
    this.days$ = this.daysSubject.asObservable();
    this.hours$ = this.hoursSubject.asObservable();
    this.minutes$ = this.minutesSubject.asObservable();
    this.valid$ = this.validSubject.asObservable();
    this.isDisabled$ = this.isDisabledSubject.asObservable();

    this.value$ = this.updateSubject.pipe(
      withLatestFrom(this.years$),
      map(([_, years]) => years),
      withLatestFrom(this.weeks$),
      withLatestFrom(this.days$),
      withLatestFrom(this.hours$),
      withLatestFrom(this.minutes$),
      distinctUntilChanged((a, b) => isEqual(a, b)),
      tap(() => this.validSubject.next(!this.areAllInvalid())),
      mergeMap(([[[[years, weeks], days], hours], minutes]) => this.isNegative$.pipe(
        map(isNegative => {
          if (this.mode === 'number') {
            return (((years * 365) * 24) * 60) + (((weeks * 7) * 24) * 60) + ((days * 24) * 60) + (hours * 60) + minutes;
          }

          const formatNumber = (n: number, digits: number = 2): string => ('0' + n).slice(-digits);
          const combinedDays = ((years ?? 0) * 365) + ((weeks ?? 0) * 7) + (days ?? 0);
          const dayString = combinedDays > 0 ? combinedDays + '.' : '';
          return `${isNegative ? '-' : ''}${dayString}${formatNumber(hours ?? 0)}:${formatNumber(minutes ?? 0)}:00`;
        })
      )),
    );

    this.subscribe(this.value$, value => {
      if (this.onChange && this.onTouched) {
        const yearsValid = this.areYearsValid(this.yearsSubject.getValue());
        const weeksValid = this.areWeeksValid(this.weeksSubject.getValue());
        const daysValid = this.areDaysValid(this.daysSubject.getValue());
        const hoursValid = this.areHoursValid(this.hoursSubject.getValue());
        const minutesValid = this.areMinutesValid(this.minutesSubject.getValue());

        if (
          (this.showYears ? yearsValid : true) &&
          (this.showWeeks ? weeksValid : true) &&
          (this.showDays ? daysValid : true) &&
          (this.showHours ? hoursValid : true) &&
          (this.showMinutes ? minutesValid : true)
        ) {
          this.onChange(value);
        } else {
          this.onChange(null);
        }

        this.onTouched();
      }
    });
  }

  public onNegativeChange(negative: boolean, update: boolean = true): void {
    this.isNegativeSubject.next(negative);

    if (update) {
      this.updateAllValues('negative');
    }
  }

  public onYearsChange(years: number | string, update: boolean = true): void {
    this.yearsSubject.next(this.areYearsValid(years) ? +years : null);

    if (update) {
      this.updateAllValues('years');
    }
  }

  public onWeeksChange(weeks: number | string, update: boolean = true): void {
    this.weeksSubject.next(this.areWeeksValid(weeks) ? +weeks : null);

    if (update) {
      this.updateAllValues('weeks');
    }
  }

  public onDaysChange(days: number | string, update: boolean = true): void {
    this.daysSubject.next(this.areDaysValid(days) ? +days : null);

    if (update) {
      this.updateAllValues('days');
    }
  }

  public onHoursChange(hours: number | string, update: boolean = true): void {
    this.hoursSubject.next(this.areHoursValid(hours) ? +hours : null);

    if (update) {
      this.updateAllValues('hours');
    }
  }

  public onMinutesChange(minutes: number | string, update: boolean = true): void {
    this.minutesSubject.next(this.areMinutesValid(minutes) ? +minutes : null);

    if (update) {
      this.updateAllValues('minutes');
    }
  }

  public onStepYears(negative: boolean): void {
    this.stepValue(
      this.yearsSubject,
      negative,
      this.yearStep,
      0,
      -1,
      value => this.areYearsValid(value),
      value => this.onYearsChange(value)
    );
  }

  public onStepWeeks(negative: boolean): void {
    this.stepValue(
      this.weeksSubject,
      negative,
      this.weekStep,
      0,
      -1,
      value => this.areWeeksValid(value),
      value => this.onWeeksChange(value)
    );
  }

  public onStepDays(negative: boolean): void {
    this.stepValue(
      this.daysSubject,
      negative,
      this.dayStep,
      0,
      -1,
      value => this.areDaysValid(value),
      value => this.onDaysChange(value)
    );
  }

  public onStepHours(negative: boolean): void {
    this.stepValue(
      this.hoursSubject,
      negative,
      this.hourStep,
      this.maxHours,
      this.maxHours,
      value => this.areHoursValid(value),
      value => this.onHoursChange(value)
    );
  }

  public onStepMinutes(negative: boolean): void {
    this.stepValue(
      this.minutesSubject,
      negative,
      this.minuteStep,
      this.minMinutes,
      this.maxMinutes,
      value => this.areMinutesValid(value),
      value => this.onMinutesChange(value)
    );
  }

  public onBlurYears(): void {
    if (isNull(this.yearsSubject.getValue()) && this.isAnyValueSet()) {
      this.onYearsChange(0);
    }
  }

  public onBlurWeeks(): void {
    if (isNull(this.weeksSubject.getValue()) && this.isAnyValueSet()) {
      this.onWeeksChange(0);
    }
  }

  public onBlurDays(): void {
    if (isNull(this.daysSubject.getValue()) && this.isAnyValueSet()) {
      this.onDaysChange(0);
    }
  }

  public onBlurHours(): void {
    if (isNull(this.hoursSubject.getValue()) && this.isAnyValueSet()) {
      this.onHoursChange(0);
    }
  }

  public onBlurMinutes(): void {
    if (isNull(this.minutesSubject.getValue()) && this.isAnyValueSet()) {
      this.onMinutesChange(0);
    }
  }

  public onClear(update: boolean = true): void {
    this.onYearsChange(null, false);
    this.onWeeksChange(null, false);
    this.onDaysChange(null, false);
    this.onHoursChange(null, false);
    this.onMinutesChange(null, false);

    if (update) {
      this.updateSubject.next();
    }
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.isDisabledSubject.next(isDisabled);
  }

  public writeValue(value: string | number): void {
    if (isUndefined(value) || isNull(value)) {
      this.onClear();
      return;
    }

    if (this.mode === 'number') {
      const { years, weeks, days, hours, minutes } = minutesTo(value as number);
      if (this.showYears) this.onYearsChange(years, false);
      if (this.showWeeks) this.onWeeksChange(weeks, false);
      if (this.showDays) this.onDaysChange(days, false);
      if (this.showHours) this.onHoursChange(hours, false);
      if (this.showMinutes) this.onMinutesChange(minutes, false);
    } else if (this.mode === 'timespan' && value) {
      const values = timespanTo(value as string);
      let { years, weeks, days } = values;
      const { negative, hours, minutes } = values;

      if (!this.showYears) {
        weeks += Math.floor((years * 365) / 7);
        days += (years * 365) % 7;
        years = null;
      }

      if (!this.showWeeks) {
        days += weeks * 7;
        weeks = null;
      }

      if (this.allowNegative) this.onNegativeChange(negative, false);
      if (this.showYears) this.onYearsChange(years, false);
      if (this.showWeeks) this.onWeeksChange(weeks, false);
      if (this.showDays) this.onDaysChange(days, false);
      if (this.showHours) this.onHoursChange(hours, false);
      if (this.showMinutes) this.onMinutesChange(minutes, false);
    }

    this.updateSubject.next();
  }

  private updateAllValues(current: 'negative' | 'years' | 'weeks' | 'days' | 'hours' | 'minutes'): void {
    if (this.showYears && current !== 'years' && isNull(this.yearsSubject.getValue())) {
      this.yearsSubject.next(0);
    }

    if (this.showWeeks && current !== 'weeks' && isNull(this.weeksSubject.getValue())) {
      this.weeksSubject.next(0);
    }

    if (this.showDays && current !== 'days' && isNull(this.daysSubject.getValue())) {
      this.daysSubject.next(0);
    }

    if (this.showHours && current !== 'hours' && isNull(this.hoursSubject.getValue())) {
      this.hoursSubject.next(0);
    }

    if (this.showMinutes && current !== 'minutes' && isNull(this.minutesSubject.getValue())) {
      this.minutesSubject.next(0);
    }

    this.updateSubject.next();
  }

  private stepValue(
    subject: BehaviorSubject<number>,
    negative: boolean,
    step: number,
    min: number,
    max: number,
    validFn: (value: number) => boolean,
    changeFn: (value: number) => void
  ): void {
    let value = subject.getValue() + (!negative ? step : -step);

    if (!validFn(value)) {
      value = !negative ? max : min;
    }

    changeFn(value);
  }

  private isAnyValueSet(): boolean {
    const values = [
      this.yearsSubject.getValue(),
      this.weeksSubject.getValue(),
      this.daysSubject.getValue(),
      this.hoursSubject.getValue(),
      this.minutesSubject.getValue()
    ];
    return values.some(x => !isNull(x));
  }

  private areYearsValid(years: number | string): boolean {
    return !(isNull(years) || years === '' || !isFinite(+years) || +years < 0);
  }

  private areWeeksValid(weeks: number | string): boolean {
    return !(isNull(weeks) || weeks === '' || !isFinite(+weeks) || +weeks < 0);
  }

  private areDaysValid(days: number | string): boolean {
    return !(isNull(days) || days === '' || !isFinite(+days) || +days < 0);
  }

  private areHoursValid(hours: number | string): boolean {
    return !(isNull(hours) || hours === '' || !isFinite(+hours) || +hours < this.minHours || +hours > this.maxHours);
  }

  private areMinutesValid(minutes: number | string): boolean {
    return !(isNull(minutes) || minutes === '' || !isFinite(+minutes) || +minutes < this.minMinutes || +minutes > this.maxMinutes);
  }

  private areAllInvalid(): boolean {
    return !this.areYearsValid(this.yearsSubject.getValue()) &&
      !this.areWeeksValid(this.weeksSubject.getValue()) &&
      !this.areDaysValid(this.daysSubject.getValue()) &&
      !this.areHoursValid(this.hoursSubject.getValue()) &&
      !this.areMinutesValid(this.minutesSubject.getValue());
  }
}
