import {ResourceType} from "@nexnox-web/core-shared";

export const resourceTypeEnumOptions = [
	{value: ResourceType.Group, label: 'resources.resource-types.3'},
	{value: ResourceType.Device, label: 'resources.resource-types.0'},
	{value: ResourceType.VirtualGroup, label: 'resources.resource-types.1'},
	{value: ResourceType.Virtual, label: 'resources.resource-types.2'}
]

export const resourceTypeSelectableCreateEnumOptions = [
	{value: ResourceType.Group, label: 'resources.resource-types.3'},
	{value: ResourceType.Device, label: 'resources.resource-types.0'},
	{value: ResourceType.VirtualGroup, label: 'resources.resource-types.1'},
]

export const resourceTypeSelectableEditEnumOptions = [
	{value: ResourceType.Group, label: 'resources.resource-types.3'},
	{value: ResourceType.Device, label: 'resources.resource-types.0'}
]
