import {Injectable} from '@angular/core';
import {CoreSharedBreadcrumb, CoreSharedBreadcrumbsResolver, I18nBreadcrumbResolver} from '@nexnox-web/core-shared';
import {ActivatedRouteSnapshot} from '@angular/router';
import {catchError, combineLatest, Observable} from 'rxjs';
import {map} from "rxjs/operators";
import {TranslateService} from "@ngx-translate/core";


@Injectable({
  providedIn: 'root'
})
export class ContactAreaBreadcrumbsResolver extends CoreSharedBreadcrumbsResolver {
  constructor(
    private translate: TranslateService,
    private i18nBreadcrumbResolver: I18nBreadcrumbResolver
  ) {
    super();
  }

  public resolve(route: ActivatedRouteSnapshot): Observable<CoreSharedBreadcrumb[]> {
    const baseRoute = this.getPathUntil(route, 'contacts');
    const contactId = route.paramMap.get('contactId');
    const areaAssignmentId = route.paramMap.get('areaAssignmentId');


    return combineLatest(
        this.translate.stream('core-portal.core.header.breadcrumbs.master-data.contact'),
        this.translate.stream('core-portal.core.header.breadcrumbs.master-data.contact-areas'),
        this.translate.stream(!areaAssignmentId ? 'core-portal.master-data.actions.create-contact-area' : 'core-portal.core.header.breadcrumbs.master-data.contact-area')
      ).pipe(
      map(([contactText, areasText, areaText]) => [
        {
          path: `${baseRoute}/contacts/${contactId}`,
          text: contactText
        },
        {
          path: `${baseRoute}/contacts/${contactId}/areas`,
          text: areasText
        },
        {
          path: `${baseRoute}/contacts/${contactId}/areas/${areaAssignmentId ? areaAssignmentId : 'create'}`,
          text: areaAssignmentId ?? areaText
        }
      ]),
      catchError(error => this.i18nBreadcrumbResolver.resolve(route))
    );
  }
}
