import { PagedEntitiesXsStore, PagedEntitiesXsStoreState } from '@nexnox-web/core-store';
import { AppEntityType, CompanyDto } from '@nexnox-web/core-shared';
import { Action, createSelector } from '@ngrx/store';
import { selectCompaniesState } from '../../companies.selectors';
import { CorePortalFeatureMasterDataCompanyService } from '../../services';
import { Injectable, Injector } from '@angular/core';

export interface CompanyListStoreState extends PagedEntitiesXsStoreState<CompanyDto> {
}

/* istanbul ignore next */
export const companyListStore = new PagedEntitiesXsStore<CompanyDto>({
  actionLabel: 'Core Portal - Master Data - Company List',
  stateSelector: createSelector(selectCompaniesState, state => state.companyList),
  serviceType: CorePortalFeatureMasterDataCompanyService,
  entityType: AppEntityType.Company,
  selectId: company => company.entity?.companyId ?? company.model?.companyId
});

export function companyListStoreReducer(state: CompanyListStoreState, action: Action): any {
  return companyListStore.reducer(state, action);
}

@Injectable()
export class CompanyListStoreEffects extends companyListStore.effects {
  constructor(
    protected injector: Injector
  ) {
    super(injector);
  }
}
