import {DashboardItemEntityActionsFacade} from '@nexnox-web/core-portal/features/dashboard';
import {
  TechPortalFeatureMissionActionsFacade,
  TechPortalFeatureMissionActionsFacadeAssignToMePayload,
  TechPortalFeatureMissionActionsFacadeAssignToPayload,
  TechPortalFeatureMissionActionsFacadeChangeStatePayload,
  TechPortalFeatureMissionActionsFacadeCompleteOrResumePayload,
  TechPortalFeatureMissionActionsFacadeExportPayload
} from '../mission-actions';
import {TechPortalFeatureDashboardMissionDatatableStore} from './dashboard-mission.datatable-store';

export class TechPortalFeatureDashboardMissionActionsFacade extends DashboardItemEntityActionsFacade
  implements TechPortalFeatureMissionActionsFacade {
  protected datatableStore: TechPortalFeatureDashboardMissionDatatableStore;

  public assignTo({ id, parentIds, contact }: TechPortalFeatureMissionActionsFacadeAssignToPayload): void {
    this.datatableStore.assignTo(id, contact, parentIds);
  }

  public assignToMe({ id, parentIds }: TechPortalFeatureMissionActionsFacadeAssignToMePayload): void {
    this.datatableStore.assignToMe(id, parentIds);
  }

  public changeState({ id, state }: TechPortalFeatureMissionActionsFacadeChangeStatePayload): void {
    this.datatableStore.changeMissionState(id, state);
  }

  public complete({ id }: TechPortalFeatureMissionActionsFacadeCompleteOrResumePayload): void {
    this.datatableStore.complete(id);
  }

  public resume({ id }: TechPortalFeatureMissionActionsFacadeCompleteOrResumePayload): void {
    this.datatableStore.resume(id);
  }

  public export({ id, templateId }: TechPortalFeatureMissionActionsFacadeExportPayload): void {
    this.datatableStore.export(id, templateId);
  }
}
