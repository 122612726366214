import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {ConfigurationSettingsMenuComponent} from "./containers";
import {CorePortalSettingsMenuService} from "./services";
import {SettingsMenuComponent, SettingsMenuSidebarComponent} from "./components";
import {NgxSkeletonLoaderModule} from "ngx-skeleton-loader";
import {CorePortalCardboxModule} from "@nexnox-web/libs/core-portal/src/lib/modules/cardbox/cardbox.module";
import {TranslateModule} from "@ngx-translate/core";
import {CorePortalRouterOverridesModule} from "@nexnox-web/libs/core-portal/src/lib/router-overrides";
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import {RouterModule} from "@angular/router";
import {ButtonModule} from "primeng/button";
import {CoreSharedSharedModule} from "@nexnox-web/core-shared";
import {InputTextModule} from 'primeng/inputtext';
import {FormsModule} from "@angular/forms";
import {I18nSettingsBreadcrumbResolver} from "./resolver";

@NgModule({
  declarations: [
    SettingsMenuComponent,
    SettingsMenuSidebarComponent,
    ConfigurationSettingsMenuComponent
  ],
  providers: [
    CorePortalSettingsMenuService,
    I18nSettingsBreadcrumbResolver
  ],
  imports: [
    CommonModule,
    NgxSkeletonLoaderModule,
    CorePortalCardboxModule,
    TranslateModule,
    CorePortalRouterOverridesModule,
    FontAwesomeModule,
    RouterModule,
    ButtonModule,
    CoreSharedSharedModule,
    InputTextModule,
    FormsModule
  ]
})
export class CorePortalSettingsMenuModule {

}
