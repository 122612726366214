import { ChangeDetectionStrategy, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { CoreSharedSidebarBaseComponent, FormFieldTypes } from '@nexnox-web/core-shared';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import { CorePortalFormlyNgSelectTyping, CorePortalFormlyTranslatedTyping } from '@nexnox-web/core-portal';
import { formFieldTypeEnumOptions } from '../../models';

export interface CreateFormFieldEvent {
  title: string;
  type: FormFieldTypes;
}

@Component({
  selector: 'nexnox-web-forms-create-form-field-sidebar',
  templateUrl: './create-form-field-sidebar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CreateFormFieldSidebarComponent extends CoreSharedSidebarBaseComponent implements OnInit {
  @Output() public createFormField: EventEmitter<CreateFormFieldEvent> = new EventEmitter<CreateFormFieldEvent>();

  public form: FormGroup;
  public model: CreateFormFieldEvent;
  public fields: FormlyFieldConfig[];

  public faPlus = faPlus;

  constructor() {
    super();
  }

  public ngOnInit(): void {
    this.form = new FormGroup({});
    this.model = {} as CreateFormFieldEvent;
    this.fields = this.createForm();
  }

  public onCreateFormField(): void {
    this.createFormField.emit(this.model);
    this.onHide();
  }

  public onHide(): void {
    super.onHide();

    this.form.reset({
      type: FormFieldTypes.Text
    });
    this.form.markAsPristine();
  }

  /* istanbul ignore next */
  private createForm(): FormlyFieldConfig[] {
    return [
      {
        key: 'title',
        type: 'input',
        wrappers: ['core-portal-translated'],
        className: 'col-md-12',
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.title',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          } as CorePortalFormlyTranslatedTyping,
          type: 'text',
          required: true
        }
      },
      {
        key: 'type',
        type: 'core-portal-ng-select',
        wrappers: ['core-portal-translated'],
        className: 'col-md-12',
        defaultValue: FormFieldTypes.Text,
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.kind',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          } as CorePortalFormlyTranslatedTyping,
          corePortalNgSelect: {
            items: formFieldTypeEnumOptions,
            translate: true,
            noClear: true
          } as CorePortalFormlyNgSelectTyping,
          required: true
        }
      }
    ];
  }
}
