import {ChangeDetectionStrategy, Component, Injector} from '@angular/core';
import {
  ActionButton,
  CorePortalEntityOverviewBaseComponent,
  DatatableActionButton,
  DeleteEntityModel
} from '@nexnox-web/core-portal';
import {AppEntityType, AppPermissions, Mappers, TicketByProcessDefinitionDto} from '@nexnox-web/core-shared';
import {processListStore} from '../../store';

@Component({
  selector: 'nexnox-web-ticket-settings-processes-process-list',
  templateUrl: './process-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProcessListComponent extends CorePortalEntityOverviewBaseComponent<TicketByProcessDefinitionDto> {
  public title = 'core-portal.settings.subtitles.ticket-settings.process-list';
  public createTitle = 'core-portal.settings.subtitles.ticket-settings.process-create';
  public idProperty = 'ticketByProcessDefinitionId';
  public displayProperty = 'name';
  public datatableConfigName = 'SettingsTicketSettingsProcessDefault';
  public componentId = 'ProcessListComponent';

  constructor(
    protected injector: Injector
  ) {
    super(injector, processListStore, Mappers.TicketByProcessDefinitionListDto.serializedName, AppEntityType.None);
  }

  /* istanbul ignore next */
  public getRowActionButtons(): DatatableActionButton[] {
    return this.getDefaultRowActionButtons(
      'core-portal.settings.actions.ticket-settings.edit-process',
      (row: TicketByProcessDefinitionDto) => `/processes/${row.ticketByProcessDefinitionId}`,
      [AppPermissions.UpdateTicketByProcessDefinition],
      {
        module: 'settings'
      }
    );
  }

  public getDeleteEntityModel(): DeleteEntityModel {
    return {
      titleKey: 'core-portal.settings.actions.ticket-settings.delete-process',
      descriptionKey: 'core-portal.settings.descriptions.ticket-settings.delete-process',
      confirmKey: 'core-portal.settings.actions.ticket-settings.delete-process',
      deletePermission: AppPermissions.DeleteTicketByProcessDefinition
    };
  }

  protected async getActionButtons(): Promise<ActionButton[]> {
    return this.getDefaultActionButtons(
      'core-portal.settings.actions.ticket-settings.create-process',
      AppPermissions.CreateTicketByProcessDefinition
    );
  }
}
