import {InjectionToken, NgModule} from '@angular/core';
import {ActionReducerMap, StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {authStore, corePortalEffects, corePortalReducers, corePortalServices} from './store';
import {CORE_STORE_CHANGE_TENANT_ACTION, CORE_STORE_TENANT_ID_SELECTOR, CoreStoreModule} from '@nexnox-web/core-store';

export const REDUCER_TOKEN = new InjectionToken<ActionReducerMap<any>>('@nexnox-web/core-portal: Store Reducers');

@NgModule({
  imports: [
    CoreStoreModule.forRoot(),
    StoreModule.forFeature('core-portal', REDUCER_TOKEN),
    EffectsModule.forFeature(corePortalEffects)
  ],
  providers: [
    ...corePortalServices,
    {
      provide: REDUCER_TOKEN,
      useValue: corePortalReducers
    },
    {
      provide: CORE_STORE_TENANT_ID_SELECTOR,
      useValue: authStore.selectors.selectTenantId
    },
    {
      provide: CORE_STORE_CHANGE_TENANT_ACTION,
      useValue: authStore.actions.changeTenant
    }
  ]
})
export class CorePortalStoreModule {
}
