import { Injectable, Injector } from '@angular/core';
import { CorePortalEmbeddedEntityBaseService } from '@nexnox-web/core-portal';
import { CorePortalFeatureMasterDataAddressService } from '@nexnox-web/core-portal/features/master-data/features/addresses';

@Injectable()
export class LocationAddressService extends CorePortalEmbeddedEntityBaseService {
  constructor(
    protected injector: Injector
  ) {
    super(injector, CorePortalFeatureMasterDataAddressService, null, 'address', ['location']);
  }
}
