import {Injectable, Injector} from '@angular/core';
import {CoreSharedApiBaseService, Product} from '@nexnox-web/core-shared';
import {map} from "rxjs/operators";
import {Observable} from "rxjs";

@Injectable()
export class SolutionMissionService extends CoreSharedApiBaseService {
	constructor(
		protected injector: Injector
	) {
		super(injector, Product.TECH, 'mission', ['solution']);
	}

	public override deleteOne<T>(id: number | string, parentIds?: Array<number | string>): Observable<T> {
		return this.httpRequestService.delete<T>(`mission/${id}`).pipe(
			map(response => response.body)
		);
	}
}
