import { Injectable, Injector } from '@angular/core';
import { CombineOperator, CoreSharedApiBaseService, Filter, FilterOperators, FilterTypes, ResourceDto } from '@nexnox-web/core-shared';

@Injectable()
export class CorePortalFeatureResourceInheritableService extends CoreSharedApiBaseService {
  constructor(
    protected injector: Injector
  ) {
    super(injector, null, 'resource/inheritable');
  }

  /* istanbul ignore next */
  public static mapSearchFilter(searchFilter: Filter): Filter {
    return ({
      type: FilterTypes.Grouped,
      combinedAs: CombineOperator.Or,
      children: [
        searchFilter,
        {
          type: FilterTypes.DataTransferObject,
          operator: FilterOperators.Contains,
          property: 'inventoryNumber',
          value: searchFilter.value
        }
      ]
    } as Filter);
  }

  /* istanbul ignore next */
  public static searchCompare(term: string, item: ResourceDto): boolean {
    return item.name?.toLowerCase()?.includes(term.toLowerCase()) ||
      item.inventoryNumber?.toLowerCase()?.includes(term.toLowerCase());
  }
}
