import { ChangeDetectionStrategy, Component, Injector, Input, OnInit } from '@angular/core';
import { CoreSharedModalBaseComponent, StateDto } from '@nexnox-web/core-shared';
import { FormControl, FormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import dayjs from 'dayjs';

@Component({
  selector: 'nexnox-web-tickets-ticket-follow-up-modal',
  templateUrl: './ticket-follow-up-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TicketFollowUpModalComponent extends CoreSharedModalBaseComponent implements OnInit {
  @Input() public states: StateDto[];

  public form: FormGroup;
  public model: { stateId: number, triggersAt: string };
  public fields: FormlyFieldConfig[];

  constructor(
    protected injector: Injector
  ) {
    super(injector);
  }

  public ngOnInit(): void {
    super.ngOnInit();

    this.form = new FormGroup({});
    this.fields = this.createForm();
    this.model = {} as any;

    this.subscribe(this.form.valueChanges, () => {
      if (this.form.valid) {
        this.enableConfirmButton();
      } else {
        this.disableConfirmButton();
      }
    });
  }

  /* istanbul ignore next */
  protected createForm(): FormlyFieldConfig[] {
    return [
      {
        key: 'stateId',
        type: 'core-portal-ng-select',
        wrappers: ['core-portal-translated'],
        className: 'col-md-6 pl-0 pr-md-2 pr-0',
        defaultValue: null,
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.change-state-to',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          },
          corePortalNgSelect: {
            items: (this.states ?? []).map(state => ({
              label: state.name,
              value: state.stateId
            }))
          },
          required: true
        }
      },
      {
        key: 'triggersAt',
        type: 'core-portal-date-time-picker',
        wrappers: ['core-portal-translated'],
        className: 'col-md-6 pl-md-2 pl-0 pr-0',
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.triggers-at',
            validationMessages: {
              required: 'core-portal.core.validation.required',
              noPast: 'core-portal.core.validation.no-past'
            }
          },
          required: true
        },
        validators: {
          noPast: (ctrl: FormControl) => ctrl.value ? dayjs(ctrl.value).isAfter(dayjs()) : true
        }
      }
    ];
  }

  /* istanbul ignore next */
  protected onPreConfirm(): any {
    return this.model;
  }

  /* istanbul ignore next */
  protected onConfirmButtonAvailable(): void {
    super.onConfirmButtonAvailable();

    this.disableConfirmButton();
  }
}
