import { ChangeDetectionStrategy, Component, Injector } from '@angular/core';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons/faExclamationTriangle';
import { ActionButton, CorePortalEntityDetailBaseComponent } from '@nexnox-web/core-portal';
import { AppPermissions, LocationGroupDto } from '@nexnox-web/core-shared';
import { locationGroupDetailStore } from '../../store';

@Component({
  selector: 'nexnox-web-master-data-location-groups-location-group-detail',
  templateUrl: './location-group-detail.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LocationGroupDetailComponent extends CorePortalEntityDetailBaseComponent<LocationGroupDto> {
  public title = 'core-portal.master-data.subtitles.location-group-detail';

  public faExclamationTriangle = faExclamationTriangle;

  protected idParam = 'locationGroupId';
  protected displayKey = 'name';

  constructor(
    protected injector: Injector
  ) {
    super(injector, locationGroupDetailStore);
  }

  protected async getActionButtons(): Promise<ActionButton[]> {
    return this.getDefaultActionButtons(
      'core-portal.master-data.actions.edit-location-group',
      'core-portal.master-data.actions.save-location-group',
      'core-portal.master-data.actions.delete-location-group',
      'core-portal.master-data.descriptions.delete-location-group',
      AppPermissions.UpdateLocationGroup,
      AppPermissions.DeleteLocationGroup,
      ['/masterdata/locationgroups']
    );
  }
}
