import { TechPortalFeatureConnectedHintCodeService } from './services';
import { hintCodeDetailStore, hintCodeListStore } from './stores';

export * from './hint-codes.selectors';
export * from './hint-codes.state';

export * from './services';
export * from './stores';

export const hintCodeReducers = {
  hintCodeList: hintCodeListStore.reducer,
  hintCodeDetail: hintCodeDetailStore.reducer
};

export const hintCodeEffects = [
  hintCodeListStore.effects,
  hintCodeDetailStore.effects
];

export const hintCodeServices = [
  TechPortalFeatureConnectedHintCodeService
];
