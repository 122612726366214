<nexnox-web-entity-edit *ngIf="!creating"
                        displayKey="name"
                        [isTitleEditable]="false"
                        [entityEditBaseComponent]="this"
                        [stereotyped]="true">
</nexnox-web-entity-edit>

<nexnox-web-tabs *ngIf="creating"
                 [internal]="true"
                 [isTransparent]="true"
                 startTab="entityEdit">
  <!-- Entity Edit Tab -->
  <ng-template nexnoxWebTabTemplate
               tabId="entityEdit"
               [heading]="title | translate"
               [hasError]="!isOwnModelValid()">
    <nexnox-web-entity-edit [entityEditBaseComponent]="this"
                            displayKey="name"
                            [isTitleEditable]="false">
    </nexnox-web-entity-edit>
  </ng-template>

  <!-- Control Points Tab -->
  <ng-template nexnoxWebTabTemplate
               tabId="controlPoints"
               [heading]="'tech-portal.ccp.control-points.subtitles.list' | translate">
    <nexnox-web-ccp-control-tag-control-points-edit #controlPointsComponent
                                                    [loading]="loading"
                                                    [readonly]="readonly"
                                                    [controlPointsSubject]="controlPointsSubject"
                                                    (controlPointsChange)="onControlPointsChange($event)">
    </nexnox-web-ccp-control-tag-control-points-edit>
  </ng-template>
</nexnox-web-tabs>
