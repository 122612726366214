<ng-template #entityEditTemplate let-title="title" let-id="id">
  <nexnox-web-master-data-holidays-holidays-edit #modelComponent
                                                 [title]="title"
                                                 [id]="id"
                                                 [model]="model$ | async"
                                                 [readonly]="readonly$ | async"
                                                 [creating]="false"
                                                 [loading]="loading$ | async"
                                                 [stereotypes$]="stereotypes$"
                                                 (modelChange)="onModelChange($event)">
  </nexnox-web-master-data-holidays-holidays-edit>
</ng-template>

<nexnox-web-entity-detail [entityDetailBaseComponent]="this"
                          [entityEditTemplate]="entityEditTemplate"
                          tabId="holidaySet"
                          [tabHeading]="title | translate"
                          [tabCommands]="['.']">
</nexnox-web-entity-detail>
