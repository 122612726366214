import {ChangeDetectionStrategy, Component, Injector, OnInit, ViewChild} from '@angular/core';
import {
  ActionButton,
  authStore,
  CorePortalEntityOverviewBaseComponent,
  DatatableActionButton,
  DeleteEntityModel
} from '@nexnox-web/core-portal';
import {
  AppEntityType,
  AppPermissions,
  ControllerOperationId,
  Mappers,
  TenantInfoDto,
  TicketDto
} from '@nexnox-web/core-shared';
import {ticketListStore} from '../../store';
import {faCircle} from '@fortawesome/free-solid-svg-icons/faCircle';
import {faExchangeAlt} from '@fortawesome/free-solid-svg-icons/faExchangeAlt';
import {TicketAssignModalComponent} from '../../modals';
import {faUserCheck} from '@fortawesome/free-solid-svg-icons/faUserCheck';
import {
  CorePortalFeatureMasterDataContactService
} from '@nexnox-web/core-portal/features/master-data/features/contacts';
import {BehaviorSubject} from 'rxjs';
import {select} from '@ngrx/store';
import {distinctUntilChanged, map} from 'rxjs/operators';
import {isEqual} from 'lodash';
import {faFileExport} from '@fortawesome/free-solid-svg-icons/faFileExport';
import {TechPortalExportByTemplateModalComponent} from '@nexnox-web/tech-portal-lib';
import {
  documentContextTypeEnumOptions,
  TechPortalFeatureDocumentTemplateService
} from '@nexnox-web/tech-portal/features/templates';
import {TechPortalFeatureTicketActionsFacade, XsStoreTicketActionsFacade} from '../../facades';
import {TicketStateChangeSidebarComponent} from "./../../sidebars";

@Component({
  selector: 'nexnox-web-tickets-ticket-list',
  templateUrl: './ticket-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TechPortalFeatureTicketListComponent extends CorePortalEntityOverviewBaseComponent<TicketDto> implements OnInit {

  @ViewChild('ticketStateChangeSidebar', {static: true}) public ticketStateChangeSidebar: TicketStateChangeSidebarComponent;

  public title = 'tickets.subtitles.ticket-list';
  public createTitle = 'tickets.subtitles.ticket-create';
  public idProperty = 'ticketId';
  public displayProperty = 'title';
  public pageOperation = ControllerOperationId.TicketControllerList;
  public componentId = 'TicketListComponent';
  public enableViews = true;
  public datatableConfigName = 'TicketDefault';

  public faCircle = faCircle;

  protected entityActionsFacade: TechPortalFeatureTicketActionsFacade;

  private activeTenantSubject = new BehaviorSubject<TenantInfoDto>(null);

  constructor(
    protected injector: Injector,
    private masterDataContactService: CorePortalFeatureMasterDataContactService,
    private documentTemplateService: TechPortalFeatureDocumentTemplateService
  ) {
    super(injector, ticketListStore, Mappers.TicketListDto.serializedName, AppEntityType.Ticket);
  }

  public ngOnInit(): void {
    this.customActionsFacade = this.customActionsFacade ?? new XsStoreTicketActionsFacade(this.injector, ticketListStore);

    super.ngOnInit();

    this.subscribe(this.store.pipe(
      select(authStore.selectors.selectActiveTenant),
      distinctUntilChanged((a, b) => isEqual(a, b))
    ), tenant => this.activeTenantSubject.next(tenant));
  }

  /* istanbul ignore next */
  public getRowActionButtons(): DatatableActionButton[] {
    return [
      ...this.getDefaultRowActionButtons(
        'tickets.actions.edit-ticket',
        (row: TicketDto) => `/tickets/${row.ticketId}`,
        [AppPermissions.UpdateTicket],
        {
          module: 'communication'
        }
      ),

      null,

      {
        id: 'assignToMe',
        tooltip: 'tickets.actions.assign-to-me',
        icon: faUserCheck,
        permissions: [AppPermissions.AssignTicketToMe],
        disabled: (row: TicketDto) => {
          const tenant = this.activeTenantSubject.getValue();
          return (tenant?.names ?? []).length ? tenant.names[0].contactId === row.editor?.contactId : true;
        },
        onClick: ({ticketId}: TicketDto) => this.entityActionsFacade.assignToMe({id: ticketId})
      },
      {
        id: 'assignTo',
        tooltip: 'tickets.actions.assign-to',
        icon: faUserCheck,
        permissions: [AppPermissions.AssignTicketEditor],
        onClick: ({ticketId}: TicketDto) => this.modalService.showModal(TicketAssignModalComponent, instance => {
          instance.service = this.masterDataContactService;
        })
          .then(({value: {contact}}) => this.entityActionsFacade.assignTo({id: ticketId, contact}))
          .catch(() => null)
      },

      {
        id: 'changeState',
        tooltip: 'tickets.actions.change-ticket-state',
        icon: faExchangeAlt,
        permissions: [AppPermissions.ForceTicketState],
        onClick: ({ticketId, stereotypeId}: TicketDto) => {
          this.ticketStateChangeSidebar.ticketId = ticketId;
          this.ticketStateChangeSidebar.stateMachineId$ = this.stereotypes$.pipe(map(stereotypes => stereotypes.find(st => st.stereotypeId === stereotypeId)?.stateMachine?.stateMachineId));
          this.ticketStateChangeSidebar.onShow();
        }
      },

      {
        id: 'export',
        tooltip: 'tickets.actions.export-ticket',
        icon: faFileExport,
        permissions: [AppPermissions.ReadDocumentTemplate],
        onClick: ({ticketId}: TicketDto) => this.modalService.showModal(TechPortalExportByTemplateModalComponent, instance => {
          instance.service = this.documentTemplateService;
          instance.contextTypes = [documentContextTypeEnumOptions[0]];
        })
          .then(({value: {template}}) => this.entityActionsFacade.export({
            id: ticketId,
            templateId: template.documentTemplateId
          }))
          .catch(() => null)
      }
    ];
  }

  public getDeleteEntityModel(): DeleteEntityModel {
    return {
      titleKey: 'tickets.actions.delete-ticket',
      descriptionKey: 'tickets.descriptions.delete-ticket',
      confirmKey: 'tickets.actions.delete-ticket',
      deletePermission: AppPermissions.DeleteTicket
    };
  }

  protected async getActionButtons(): Promise<ActionButton[]> {
    return this.getDefaultActionButtons('tickets.actions.create-ticket', AppPermissions.CreateTicket);
  }
}
