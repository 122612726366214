import { NgModule } from '@angular/core';
import { CorePortalEntityModule } from '@nexnox-web/core-portal';
import { CoreSharedModule } from '@nexnox-web/core-shared';
import { TechPortalFeatureConnectedHintCodeEditComponent, TechPortalFeatureHintCodeExternalIdComponent } from './components';
import { HintCodeDetailComponent, HintCodeListComponent } from './containers';
import { hintCodeServices } from './store';

const exportedComponents = [
  TechPortalFeatureConnectedHintCodeEditComponent,

  TechPortalFeatureHintCodeExternalIdComponent
];

@NgModule({
  declarations: [
    HintCodeDetailComponent,
    HintCodeListComponent,

    ...exportedComponents
  ],
  imports: [
    CoreSharedModule,
    CorePortalEntityModule
  ],
  exports: [
    ...exportedComponents
  ],
  providers: [
    ...hintCodeServices
  ]
})
export class HintCodesDeclarationsModule {
}
