import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { I18nBreadcrumbResolver, UnsavedChangesGuard } from '@nexnox-web/core-shared';
import { ConsumptionCodeDetailComponent, ConsumptionCodeListComponent } from './containers';

export const TECH_PORTAL_CONSUMPTION_CODES_ROUTES: Routes = [
  {
    path: '',
    component: ConsumptionCodeListComponent
  },
  {
    path: ':consumptionCodeId',
    component: ConsumptionCodeDetailComponent,
    canDeactivate: [UnsavedChangesGuard],
    data: {
      breadcrumbs: I18nBreadcrumbResolver,
      tab: 'consumptionCode',
      text: 'tech-portal.connected.consumption-codes.subtitles.detail'
    }
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(TECH_PORTAL_CONSUMPTION_CODES_ROUTES)
  ],
  exports: [
    RouterModule
  ]
})
export class ConsumptionCodesRoutingModule {
}
