import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Injector, Input, OnInit, Output, ViewChild } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  CoreSharedApiBaseService,
  FilterDto,
  FilterOperators,
  FilterTypes,
  LinkDto,
  LinkedElementType, LinkToContactDto, LinkToContractDto,
  LinkToMissionDto,
  LinkToResourceDto,
  LinkToTicketDto,
  UnsubscribeHelper
} from '@nexnox-web/core-shared';
import {
  CorePortalEntitySelectOptions,
  CorePortalFormlyReadonlyTypes,
  CorePortalFormlyReadonlyTyping,
  CoreSharedRuleEditorListComponent,
  InheritedField,
  ModelValid
} from '@nexnox-web/core-portal';
import { TECH_PORTAL_LINKS_CONFIG_TOKEN } from '../../tokens';
import { TechPortalLinkConfig } from '../../models';
import { distinctUntilChanged, filter, map, startWith } from 'rxjs/operators';
import { isEqual } from 'lodash';

type LinkTo = LinkToTicketDto | LinkToMissionDto | LinkToResourceDto | LinkToContractDto | LinkToContactDto;

@Component({
  selector: 'nexnox-web-tech-links',
  templateUrl: './links.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TechPortalLinksComponent extends UnsubscribeHelper implements OnInit, ModelValid {
  @Input() public links$: Observable<LinkDto[]>;
  @Input() public entityId: number;
  @Input() public type: LinkedElementType;
  @Input() public loading: boolean;

  public get readonly(): boolean {
    return this.readonlySubject.getValue();
  }

  @Input()
  public set readonly(readonly: boolean) {
    this.readonlySubject.next(readonly);
  }

  @ViewChild('linksEdit') public linksEdit: CoreSharedRuleEditorListComponent;

  @Output() public linksChange: EventEmitter<LinkDto[]> = new EventEmitter<LinkDto[]>();

  public linksString$: Observable<string>;

  public linksTitle: any;
  public linksFieldsFn: any;

  private readonlySubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  private serviceInstances: Map<LinkedElementType, CoreSharedApiBaseService> = new Map<LinkedElementType, CoreSharedApiBaseService>();

  constructor(
    private injector: Injector,
    @Inject(TECH_PORTAL_LINKS_CONFIG_TOKEN) private linksConfig: TechPortalLinkConfig[]
  ) {
    super();

    this.linksTitle = (link: LinkDto) => {
      const config = this.linksConfig.find(x => x.elementType === link.to.type);

      if (config) {
        return (link.to as LinkTo).element[config.displayKey];
      }

      return null;
    };
    this.linksFieldsFn = () => this.createLinksFields();

    for (const config of this.linksConfig) {
      this.serviceInstances.set(config.elementType, this.injector.get(config.serviceType));
    }
  }

  public ngOnInit(): void {
    this.linksString$ = this.links$.pipe(
      map(links => {
        if (!links?.length) {
          return '';
        }

        return ` (${links.length})`;
      })
    );
  }

  public onLinksChange(links: LinkDto[]): void {
    this.linksChange.emit(links);
  }

  public onReset(): void {
    this.linksEdit?.ngOnInit();
  }

  public isModelValid(): boolean {
    return this.linksEdit ? this.linksEdit.areItemsValid() : true;
  }

  public isOwnModelValid(): boolean {
    return this.linksEdit ? this.linksEdit.isModelValid() : true;
  }

  public isOwnModelPristine(): boolean {
    return this.linksEdit ? this.linksEdit.isPristine() : true;
  }

  public createLinksFields(): InheritedField[] {
    const selectedConfigSubject: BehaviorSubject<TechPortalLinkConfig> = new BehaviorSubject<TechPortalLinkConfig>(null);
    const elementValueSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    const typeEnumOptions = this.linksConfig.map(config => ({
      label: config.labelTranslation,
      value: config.elementType
    }));

    /* istanbul ignore next */
    return [
      {
        formlyConfig: {
          type: 'core-portal-ng-select',
          wrappers: ['core-portal-translated', 'core-portal-readonly'],
          templateOptions: {
            corePortalTranslated: {
              label: 'core-shared.shared.fields.kind',
              validationMessages: {
                required: 'core-portal.core.validation.required'
              }
            },
            corePortalReadonly: {
              type: CorePortalFormlyReadonlyTypes.ENUM,
              enumOptions: typeEnumOptions,
              translate: true
            } as CorePortalFormlyReadonlyTyping,
            corePortalNgSelect: {
              items: typeEnumOptions,
              translate: true
            }
          },
          expressionProperties: {
            'templateOptions.required': () => !this.readonly,
            'templateOptions.readonly': () => this.readonly
          },
          hooks: {
            onInit: field => this.subscribe(field.formControl.valueChanges.pipe(
              startWith(field.formControl.value),
              distinctUntilChanged()
            ), value => selectedConfigSubject.next(this.linksConfig.find(x => x.elementType === value)))
          }
        },
        ownValueKey: 'to.type',
        disable: (_, creating, disabled) => disabled || !creating
      },
      {
        formlyConfig: {
          type: 'core-portal-entity-select',
          wrappers: ['core-portal-translated', 'core-portal-readonly'],
          defaultValue: null,
          templateOptions: {
            corePortalTranslated: {
              label: 'tech-portal.fields.link-to',
              validationMessages: {
                required: 'core-portal.core.validation.required'
              }
            },
            corePortalReadonly: {
              type: CorePortalFormlyReadonlyTypes.ENTITY,
              displayKey: selectedConfigSubject.asObservable().pipe(
                map(config => config?.displayKey)
              ),
              link: null,
              module: null
            } as CorePortalFormlyReadonlyTyping,
            customInit$: selectedConfigSubject.asObservable().pipe(
              filter(config => Boolean(config)),
              map(config => ({
                entityService: this.serviceInstances.get(config.elementType),
                idKey: config.idKey,
                displayKey: config.displayKey,
                entityId: config.elementType === this.type ? this.entityId : undefined,
                skipGetOne: true,
                wholeObject: true,
                clearable: false,
                link: (value: any) => value && value[config.idKey] ? [config.detailLink, value[config.idKey]] : null,
                module: config.module,
                defaultFilters$: this.links$.pipe(
                  map(links => {
                    const linksToFilter = links.filter(x => x.to.type === config.elementType);

                    return linksToFilter.map(link => ({
                      property: config.idKey,
                      type: FilterTypes.DataTransferObject,
                      operator: FilterOperators.NotEqual,
                      value: (link.to as LinkTo).element[config.idKey]?.toString()
                    })) as FilterDto[];
                  })
                )
              } as CorePortalEntitySelectOptions))
            )
          },
          expressionProperties: {
            'templateOptions.required': () => !this.readonly,
            'templateOptions.readonly': () => this.readonly,

            'templateOptions.corePortalReadonly.link': () => selectedConfigSubject.getValue() ?
              ((value: any) => value && value[selectedConfigSubject.getValue().idKey] ?
                  [selectedConfigSubject.getValue().detailLink, value[selectedConfigSubject.getValue().idKey]] : null
              ) : null,
            'templateOptions.corePortalReadonly.module': () => selectedConfigSubject.getValue() ?
              selectedConfigSubject.getValue().module : null
          },
          hooks: {
            onInit: field => this.subscribe(field.formControl.valueChanges.pipe(
              startWith(field.formControl.value),
              distinctUntilChanged((a, b) => isEqual(a, b))
            ), value => elementValueSubject.next(value))
          },
          hideExpression: () => !selectedConfigSubject.getValue()
        },
        ownValueKey: 'to.element',
        disable: (_, creating, disabled) => disabled || !creating
      },
      {
        formlyConfig: {
          type: 'input',
          wrappers: ['core-portal-translated', 'core-portal-readonly'],
          templateOptions: {
            corePortalTranslated: {
              label: 'core-shared.shared.fields.comment'
            },
            corePortalReadonly: {
              type: CorePortalFormlyReadonlyTypes.BASIC
            } as CorePortalFormlyReadonlyTyping,
            type: 'text'
          },
          expressionProperties: {
            'templateOptions.readonly': () => this.readonly
          }
        },
        ownValueKey: 'comment'
      }
    ];
  }
}
