import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { CorePortalPermissionGuard } from '@nexnox-web/core-portal';
import { ControlTagDetailComponent, ControlTagListComponent } from './containers';
import {AppPermissions, I18nBreadcrumbResolver, UnsavedChangesGuard, UnsavedCreateGuard} from '@nexnox-web/core-shared';

export const TECH_PORTAL_CCP_CONTROL_TAGS_ROUTES: Routes = [
  {
    path: '',
    canActivate: [CorePortalPermissionGuard],
    canDeactivate: [UnsavedCreateGuard],
    component: ControlTagListComponent,
    data: {
      permissions: [AppPermissions.DisplayControlPoint]
    }
  },
  {
    path: ':controlTagId',
    component: ControlTagDetailComponent,
    canActivate: [CorePortalPermissionGuard],
    canDeactivate: [UnsavedChangesGuard],
    data: {
      permissions: [AppPermissions.UpdateControlTag],
      breadcrumbs: I18nBreadcrumbResolver,
      tab: 'controlTag',
      text: 'tech-portal.ccp.control-tags.subtitles.detail'
    },
    children: [
      {
        path: 'controlPoints',
        data: {
          tab: 'controlPoints'
        },
        children: []
      }
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(TECH_PORTAL_CCP_CONTROL_TAGS_ROUTES)
  ],
  exports: [
    RouterModule
  ]
})
export class ControlTagsRoutingModule {
}
