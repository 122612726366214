import { NgModule } from '@angular/core';
import { CoreSharedModule } from '@nexnox-web/core-shared';
import { CorePortalEntityModule } from '@nexnox-web/core-portal';
import { categoriesServices } from './store';
import { OrgaPortalFeatureCategoryEditComponent, OrgaPortalFeatureCategoryLabelComponent } from './components';
import { CategoryDetailComponent, CategoryListComponent } from './containers';

@NgModule({
  declarations: [
    OrgaPortalFeatureCategoryEditComponent,

    OrgaPortalFeatureCategoryLabelComponent,

    CategoryListComponent,
    CategoryDetailComponent
  ],
  imports: [
    CoreSharedModule,
    CorePortalEntityModule
  ],
  exports: [
    OrgaPortalFeatureCategoryEditComponent,

    OrgaPortalFeatureCategoryLabelComponent
  ],
  providers: [
    ...categoriesServices
  ]
})
export class CategoriesDeclarationsModule {
}
