import {EntityXsStore, EntityXsStoreState} from '@nexnox-web/core-store';
import {AppEntityType, MissionIssueDto} from '@nexnox-web/core-shared';
import {Action, createSelector} from '@ngrx/store';
import {MissionIssueService} from '../../services';
import {Injectable, Injector} from '@angular/core';
import {selectIssuesState} from "../../issues.selectors";

export interface MissionIssueDetailStoreState extends EntityXsStoreState<MissionIssueDto> {
}

/* istanbul ignore next */
export const missionIssueDetailStore = new EntityXsStore<MissionIssueDto>({
  actionLabel: 'Tech Portal - Issues - Mission Issue Detail',
  stateSelector: createSelector(selectIssuesState, state => state.missionIssueDetail),
  serviceType: MissionIssueService,
  entityType: AppEntityType.Issue,
  stereotyped: true
});

export function missionIssueDetailStoreReducer(state: MissionIssueDetailStoreState, action: Action): any {
  return missionIssueDetailStore.reducer(state, action);
}

@Injectable()
export class MissionIssueDetailStoreEffects extends missionIssueDetailStore.effects {
  constructor(
    protected injector: Injector
  ) {
    super(injector);
  }
}
