import {EntityXsStore, EntityXsStoreState} from '@nexnox-web/core-store';
import {AppEntityType, TicketIssueDto} from '@nexnox-web/core-shared';
import {Action, createSelector} from '@ngrx/store';
import {TicketIssueService} from '../../services';
import {Injectable, Injector} from '@angular/core';
import {selectIssuesState} from "../../issues.selectors";

export interface TicketIssueDetailStoreState extends EntityXsStoreState<TicketIssueDto> {
}

/* istanbul ignore next */
export const ticketIssueDetailStore = new EntityXsStore<TicketIssueDto>({
  actionLabel: 'Tech Portal - Issues - Ticket Issue Detail',
  stateSelector: createSelector(selectIssuesState, state => state.ticketIssueDetail),
  serviceType: TicketIssueService,
  entityType: AppEntityType.Issue,
  stereotyped: true
});

export function ticketIssueDetailStoreReducer(state: TicketIssueDetailStoreState, action: Action): any {
  return ticketIssueDetailStore.reducer(state, action);
}

@Injectable()
export class TicketIssueDetailStoreEffects extends ticketIssueDetailStore.effects {
  constructor(
    protected injector: Injector
  ) {
    super(injector);
  }
}
