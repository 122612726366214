import {ResourceTaskConsumptionTriggerType, ResourceTaskScheduleType} from '@nexnox-web/core-shared';

export const resourceTaskScheduleTypeEnumOptions = [
  {label: 'resource-tasks.schedule-types.fixed', value: ResourceTaskScheduleType.Fixed},
  {label: 'resource-tasks.schedule-types.relative', value: ResourceTaskScheduleType.Relative},
  {label: 'resource-tasks.schedule-types.consumption', value: ResourceTaskScheduleType.Consumption},
  {label: 'resource-tasks.schedule-types.error', value: ResourceTaskScheduleType.Error}
];

export const resourceTaskConsumptionScheduleTypeEnumOptions = [
  {label: 'resource-tasks.consumption-schedule-types.2', value: ResourceTaskConsumptionTriggerType.Stepped},
  {label: 'resource-tasks.consumption-schedule-types.3', value: ResourceTaskConsumptionTriggerType.OutOfRange},
  {label: 'resource-tasks.consumption-schedule-types.4', value: ResourceTaskConsumptionTriggerType.LookBack}
];

export const resourceTaskConsumptionScheduleLookBackIntervalEnumOptions = [
  {label: 'core-shared.shared.look-back-times.M_5', value: '00:05:00'},
  {label: 'core-shared.shared.look-back-times.M_10', value: '00:10:00'},
  {label: 'core-shared.shared.look-back-times.M_15', value: '00:15:00'},
  {label: 'core-shared.shared.look-back-times.M_30', value: '00:30:00'},
  {label: 'core-shared.shared.look-back-times.H_1', value: '01:00:00'},
  {label: 'core-shared.shared.look-back-times.H_2', value: '02:00:00'},
  {label: 'core-shared.shared.look-back-times.H_4', value: '04:00:00'},
  {label: 'core-shared.shared.look-back-times.H_8', value: '08:00:00'},
  {label: 'core-shared.shared.look-back-times.H_12', value: '12:00:00'},
  {label: 'core-shared.shared.look-back-times.H_24', value: '1.00:00:00'},
];
