import { Injectable, Injector } from '@angular/core';
import { UrlTree } from '@angular/router';
import { authStore } from '@nexnox-web/core-portal';
import { AppEntityType, MissionState } from '@nexnox-web/core-shared';
import { createEffect, ofType } from '@ngrx/effects';
import { Action, createSelector, select } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, exhaustMap, map, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { selectMissionsState } from '../../missions.selectors';
import { TechPortalFeatureMissionReportService } from '../../services';
import { missionDetailStore } from '../mission-detail';
import { MissionReportXsStore, MissionReportXsStoreActions, MissionReportXsStoreState } from './mission-report.xs-store';

export interface MissionReportStoreState extends MissionReportXsStoreState {
}

/* istanbul ignore next */
export const missionReportStore = new MissionReportXsStore({
  actionLabel: 'Tech Portal - Missions - Mission Report',
  stateSelector: createSelector(selectMissionsState, state => state.missionReport),
  serviceType: TechPortalFeatureMissionReportService,
  entityType: AppEntityType.MissionReport
});

@Injectable()
export class MissionReportStoreEffects extends missionReportStore.effects {
  public create$: any;
  public createSuccess$: any;

  public createAndConfirm$: any;
  public createAndConfirmSuccess$: any;

  public saveAndConfirm$: any;
  public saveAndConfirmSuccess$: any;

  public actions: MissionReportXsStoreActions;

  protected service: TechPortalFeatureMissionReportService;

  constructor(
    protected injector: Injector
  ) {
    super(injector);
  }

  protected createEffects(): void {
    super.createEffects();

    this.create$ = createEffect(() => this.actions$.pipe(
      ofType(this.actions.create),
      withLatestFrom(this.store.pipe(select(authStore.selectors.selectActiveTenant))),
      switchMap(([{ model, missionId }, activeTenant]) => this.service.createOne({
        ...model,
        missionId,
        tenantId: activeTenant.tenantId
      }).pipe(
        map(report => this.actions.createSuccess({
          entity: this.prepareEntity(report),
          model: this.prepareModel(this.prepareEntity(report), {} as any)
        })),
        catchError(error => of(this.actions.error({ error, action: this.actions.create })))
      ))
    ));

    this.createSuccess$ = createEffect(() => this.actions$.pipe(
      ofType(this.actions.createSuccess),
      tap(action => this.actionCallback(action, false))
    ), { dispatch: false });

    this.createAndConfirm$ = createEffect(() => this.actions$.pipe(
      ofType(this.actions.createAndConfirm),
      withLatestFrom(this.store.pipe(
        select(authStore.selectors.selectActiveTenant))),
      switchMap(([{ model, missionId }, activeTenant]) => {
        return this.service.confirmOne({
          ...model,
          missionId,
          tenantId: activeTenant?.tenantId
        }).pipe(
          map(report => this.actions.createAndConfirmSuccess({
            entity: this.prepareEntity(report),
            model: this.prepareModel(this.prepareEntity(report), {} as any)
          })),
          catchError(error => of(this.actions.error({ error, action: this.actions.createAndConfirm })))
        )
      })
    ));

    this.createAndConfirmSuccess$ = createEffect(() => this.actions$.pipe(
      ofType(this.actions.createAndConfirmSuccess),
      tap(action => this.actionCallback(action, false))
    ), { dispatch: false });

    this.saveAndConfirm$ = createEffect(() => this.actions$.pipe(
      ofType(this.actions.saveAndConfirm),
      withLatestFrom(this.store.pipe(select(this.selectors.selectModel))),
      withLatestFrom(this.store.pipe(select(this.selectors.selectEntity))),
      exhaustMap(([[{ id, parentIds, queryParams }, model], entity]) =>
        this.mapEntityResponse(this.service.confirmOne(this.sanitizeModel(model, entity), parentIds)).pipe(
          map(savedEntity => this.actions.saveAndConfirmSuccess({
            entity: this.prepareEntity(savedEntity),
            model: this.prepareModel(this.prepareEntity(savedEntity), model)
          })),
          catchError(error => of(this.actions.error({ error, action: this.actions.saveAndConfirm })))
        ))
    ));

    this.saveAndConfirmSuccess$ = createEffect(() => this.actions$.pipe(
      ofType(this.actions.saveAndConfirmSuccess),
      tap(action => this.actionCallback(action, false))
    ), { dispatch: false });
  }

  protected actionCallback(action: Action, isError: boolean): void {
    super.actionCallback(action, isError);

    this.checkAction(this.actions.createSuccess, action, ({ entity }) =>
      this.createSuccessActionCallback(entity.missionReportId));
    this.checkAction(this.actions.createAndConfirmSuccess, action, ({ entity }) =>
      this.createAndConfirmSuccessActionCallback(entity.missionReportId));

    this.checkAction(this.actions.saveAndConfirmSuccess, action, () =>
      this.saveAndConfirmSuccessActionCallback());
  }

  protected async createSuccessActionCallback(reportId: number): Promise<void> {
    this.apiNotificationService.showTranslatedSuccess('core-shared.shared.toast.entity-created');

    const entity = await this.store.pipe(select(missionDetailStore.selectors.selectEntity), take(1)).toPromise();
    const model = await this.store.pipe(select(missionDetailStore.selectors.selectModel), take(1)).toPromise();
    this.store.dispatch(missionDetailStore.actions.saveSuccess({
      entity: { ...entity, reportId },
      model: { ...model, reportId },
      doNotEmitEvents: true
    }));
  }

  protected async createAndConfirmSuccessActionCallback(reportId: number): Promise<void> {
    this.apiNotificationService.showTranslatedSuccess('core-shared.shared.toast.entity-created');

    const entity = await this.store.pipe(select(missionDetailStore.selectors.selectEntity), take(1)).toPromise();
    const model = await this.store.pipe(select(missionDetailStore.selectors.selectModel), take(1)).toPromise();
    this.store.dispatch(missionDetailStore.actions.saveSuccess({
      entity: {
        ...entity,
        reportId,
        state: MissionState.Done
      },
      model: {
        ...model,
        reportId,
        state: MissionState.Done
      },
      doNotEmitEvents: true
    }));
  }

  protected async saveAndConfirmSuccessActionCallback(): Promise<void> {
    this.apiNotificationService.showTranslatedSuccess('core-shared.shared.toast.entity-saved');

    const entity = await this.store.pipe(select(missionDetailStore.selectors.selectEntity), take(1)).toPromise();
    const model = await this.store.pipe(select(missionDetailStore.selectors.selectModel), take(1)).toPromise();
    this.store.dispatch(missionDetailStore.actions.saveSuccess({
      entity: {
        ...entity,
        state: MissionState.Done
      },
      model: {
        ...model,
        state: MissionState.Done
      },
      doNotEmitEvents: true
    }));
  }

  protected async deleteSuccessActionCallback(returnPath?: any[]): Promise<void> {
    const entity = await this.store.pipe(select(missionDetailStore.selectors.selectEntity), take(1)).toPromise();
    const model = await this.store.pipe(select(missionDetailStore.selectors.selectModel), take(1)).toPromise();
    this.store.dispatch(missionDetailStore.actions.saveSuccess({
      entity: { ...entity, reportId: null },
      model: { ...model, reportId: null },
      doNotEmitEvents: true
    }));

    super.deleteSuccessActionCallback(returnPath);
  }
}
