import {
  BaseXsStore,
  BaseXsStoreReducerTypes,
  EntityXsStore,
  EntityXsStoreActions,
  EntityXsStoreState,
  PropsAction
} from '@nexnox-web/core-store';
import {TicketByContractDefinitionDto} from '@nexnox-web/core-shared';
import {createAction, props} from '@ngrx/store';
import {cloneDeep} from 'lodash';
import {immerOn} from 'ngrx-immer/store';

export interface ContractSettingsXsStoreState extends EntityXsStoreState<TicketByContractDefinitionDto> {
}

export interface ContractSettingsXsStoreCreatePayload {
  contractStereotypeId: number;
  model: TicketByContractDefinitionDto;
}

export interface ContractSettingsXsStoreCreateSuccessPayload {
  entity: TicketByContractDefinitionDto;
  model: TicketByContractDefinitionDto;
}

export interface ContractSettingsXsStoreActions extends EntityXsStoreActions<TicketByContractDefinitionDto> {
  create: PropsAction<ContractSettingsXsStoreCreatePayload>;
  createSuccess: PropsAction<ContractSettingsXsStoreCreateSuccessPayload>;
}

export class ContractSettingsXsStore extends EntityXsStore<TicketByContractDefinitionDto> {
  public actions: ContractSettingsXsStoreActions;

  protected createActions(label: string): ContractSettingsXsStoreActions {
    return {
      ...super.createActions(label),

      create: createAction(
        BaseXsStore.getType(label, 'Create'),
        props<ContractSettingsXsStoreCreatePayload>()
      ),
      createSuccess: createAction(
        BaseXsStore.getType(label, 'Create success'),
        props<ContractSettingsXsStoreCreateSuccessPayload>()
      )
    };
  }

  protected createReducerArray(
    initialState: ContractSettingsXsStoreState
  ): BaseXsStoreReducerTypes<ContractSettingsXsStoreState, ContractSettingsXsStoreActions>[] {
    return [
      ...super.createReducerArray(initialState),

      immerOn(this.actions.create, draft => {
        draft.loading = true;
      }),
      immerOn(this.actions.createSuccess, (draft, { entity, model }) => {
        draft.entity = cloneDeep(entity);
        draft.model = cloneDeep(model);
        draft.loading = false;
        draft.loaded = true;
      })
    ];
  }
}
