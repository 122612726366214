import { ChangeDetectionStrategy, Component, Injector } from '@angular/core';
import { ActionButton, CorePortalEntityDetailBaseComponent } from '@nexnox-web/core-portal';
import { AppPermissions, ControlPointDto } from '@nexnox-web/core-shared';
import { controlPointDetailStore } from '../../store';

@Component({
  selector: 'nexnox-web-control-point-detail',
  templateUrl: './control-point-detail.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ControlPointDetailComponent extends CorePortalEntityDetailBaseComponent<ControlPointDto> {
  public title = 'tech-portal.ccp.control-points.subtitles.detail';

  protected idParam = 'controlPointId';
  protected displayKey = 'name';

  constructor(
    protected injector: Injector
  ) {
    super(injector, controlPointDetailStore);
  }

  protected async getActionButtons(): Promise<ActionButton[]> {
    return this.getDefaultActionButtons(
      'tech-portal.ccp.control-points.actions.edit',
      'tech-portal.ccp.control-points.actions.save',
      'tech-portal.ccp.control-points.actions.delete',
      'tech-portal.ccp.control-points.descriptions.delete',
      AppPermissions.UpdateControlPoint,
      AppPermissions.DeleteControlPoint
    );
  }
}
