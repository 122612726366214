import { ChangeDetectionStrategy, Component, Injector } from '@angular/core';
import { AppEntityType, AppPermissions, Mappers } from '@nexnox-web/core-shared';
import { LocalOccurredConsumptionListDto, resourceOccurredConsumptionsStore } from '../../../store';
import { CorePortalEntityOverviewBaseComponent, DatatableActionButton } from '@nexnox-web/core-portal';

@Component({
  selector: 'nexnox-web-resources-resource-occurred-consumptions',
  templateUrl: './occurred-consumptions-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ResourceOccurredConsumptionsComponent extends CorePortalEntityOverviewBaseComponent<LocalOccurredConsumptionListDto> {
  public title = 'resources.subtitles.occurred-consumptions';
  public idProperty = 'consumptionCodeId';
  public displayProperty = 'displayName';
  public componentId = 'ResourceOccurredConsumptionsComponent';
  public datatableConfigName = 'ResourceOccurredConsumptionsDefault';

  constructor(
    protected injector: Injector
  ) {
    super(injector, resourceOccurredConsumptionsStore, Mappers.OccurredConsumptionListDto.serializedName, AppEntityType.None);
  }

  public getRowActionButtons(): DatatableActionButton[] {
    return this.getDefaultRowActionButtons(
      'tech-portal.connected.consumption-codes.actions.edit',
      occurredConsumption => occurredConsumption?.consumptionCodeId ? `connected/consumption-codes/${occurredConsumption.consumptionCodeId}` : null,
      [AppPermissions.UpdateResourceTask],
      {
        module: 'settings'
      }
    );
  }
}
