import {EntityXsStore, EntityXsStoreState} from '@nexnox-web/core-store';
import {AppEntityType, TicketsByLocationDefinitionDto} from '@nexnox-web/core-shared';
import {Action, createSelector} from '@ngrx/store';
import {Injectable, Injector} from '@angular/core';
import {selectStereotypesState} from './../../stereotypes.selector';
import {TicketsByLocationStereotypeService} from "./../../services";

export interface TicketsByLocationStereotypeDetailStoreState extends EntityXsStoreState<TicketsByLocationDefinitionDto> {
}

export const ticketsByLocationStereotypeDetailStore = new EntityXsStore<TicketsByLocationDefinitionDto>({
  actionLabel: 'Core Portal - Settings - Stereotypes - Tickets By Location Stereotype Detail',
  stateSelector: createSelector(selectStereotypesState, state => state.ticketsByLocationStereotypeDetail),
  serviceType: TicketsByLocationStereotypeService,
  stereotyped: false,
  entityType: AppEntityType.None
});

export function ticketsByLocationStereotypeDetailStoreReducer(state: TicketsByLocationStereotypeDetailStoreState, action: Action): any {
  return ticketsByLocationStereotypeDetailStore.reducer(state, action);
}

@Injectable()
export class TicketsByLocationStereotypeDetailStoreEffects extends ticketsByLocationStereotypeDetailStore.effects {
  constructor(
    protected injector: Injector
  ) {
    super(injector);
  }
}
