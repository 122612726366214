import {ContactSimpleDto, FunctionSimpleDto, LocationGroupSimpleDto, LocationSimpleDto} from "@nexnox-web/core-shared";

export type ReceiverSimpleDto = ContactSimpleDto | FunctionSimpleDto | LocationSimpleDto | LocationGroupSimpleDto;

export enum ReceiverType {
  LOCATION_GROUPS = 'locationGroups',
  LOCATIONS = 'locations',
  FUNCTIONS = 'functions',
  CONTACTS = 'contacts'
}

export const ReceiverField = {
  [ReceiverType.LOCATION_GROUPS]: {url: 'locationgroups', translationKey: 'location-groups'},
  [ReceiverType.LOCATIONS]: {url: 'locations', translationKey: 'locations'},
  [ReceiverType.FUNCTIONS]: {url: 'functions', translationKey: 'functions'},
  [ReceiverType.CONTACTS]: {url: 'contacts', translationKey: 'contacts'}
};

// Important to keep this order for sidebar tabs
export const receiverTypes: { key: string, type: ReceiverType }[] = [
  { key: 'locationGroups', type: ReceiverType.LOCATION_GROUPS },
  { key: 'locations', type: ReceiverType.LOCATIONS },
  { key: 'functions', type: ReceiverType.FUNCTIONS },
  { key: 'contacts', type: ReceiverType.CONTACTS }
];

export interface ReceiverTabSettings {
  useLocationGroupTab?: boolean;
  useLocationTab?: boolean;
  useFunctionTab?: boolean;
  useContactTab?: boolean;
}
