import { ModuleWithProviders, NgModule } from '@angular/core';
import { LocationsRoutingModule } from './locations-routing.module';
import { LocationsDeclarationsModule } from './locations-declarations.module';
import { LocationsStoreModule } from './locations-store.module';

@NgModule({
  imports: [
    LocationsDeclarationsModule,
    LocationsRoutingModule,
    LocationsStoreModule
  ]
})
export class CorePortalFeatureMasterDataLocationsModule {
  public static forFeature(): ModuleWithProviders<LocationsDeclarationsModule> {
    return { ngModule: LocationsDeclarationsModule };
  }
}
