import {
  AppPermissions,
  DocumentTemplateContextType,
  IssueContentType,
  IssueNotificationType,
  IssueReceiverType,
  TemplateContextType
} from "@nexnox-web/core-shared";
import { CorePortalFormlyNgSelectOption } from "@nexnox-web/core-portal";

export const issueContentTypeEnumOptions: {
  label: string,
  value: IssueContentType,
  createLabel: string,
  permission: AppPermissions
}[] =
  [
    {
      label: 'core-portal.settings.issue-template.content-types.1',
      createLabel: 'core-portal.settings.issue-template.actions.create-message-template',
      value: IssueContentType.Message,
      permission: AppPermissions.CreateMessageIssueContent
    },
    {
      label: 'core-portal.settings.issue-template.content-types.2',
      value: IssueContentType.Ticket,
      createLabel: 'core-portal.settings.issue-template.actions.create-ticket-template',
      permission: AppPermissions.CreateTicketIssueContent
    },
    {
      label: 'core-portal.settings.issue-template.content-types.3',
      value: IssueContentType.Mission,
      createLabel: 'core-portal.settings.issue-template.actions.create-mission-template',
      permission: AppPermissions.CreateMissionIssueContent
    },
    {
      label: 'core-portal.settings.issue-template.content-types.4',
      value: IssueContentType.WinterhalterServiceCall,
      createLabel: 'core-portal.settings.issue-template.actions.create-winterhalter-service-call-template',
      permission: AppPermissions.CreateWinterhalterServiceIssueContent
    },
    {
      label: 'core-portal.settings.issue-template.content-types.5',
      value: IssueContentType.WinterhalterProductOrder,
      createLabel: 'core-portal.settings.issue-template.actions.create-winterhalter-product-order-template',
      permission: AppPermissions.CreateWinterhalterOrderIssueContent
    }
  ];

export const issueNotificationTypeEnumOptions: { label: string, value: IssueNotificationType }[] =
  [
    {
      label: 'core-portal.settings.issue-template.notification-types.1',
      value: IssueNotificationType.Email
    }
  ];

export const issueNotificationReceiverTypeEnumOptions: { label: string, value: IssueReceiverType }[] =
  [
    {
      label: 'core-portal.settings.issue-template.notification-receivers.1',
      value: IssueReceiverType.EmailReceiver
    },
    {
      label: 'core-portal.settings.issue-template.notification-receivers.2',
      value: IssueReceiverType.ContactReceiver
    },
    {
      label: 'core-portal.settings.issue-template.notification-receivers.3',
      value: IssueReceiverType.FunctionReceiver
    },
  ];

export const documentContextTypeForIssueTemplatesEnumOptions: CorePortalFormlyNgSelectOption[] = [
  {
    value: DocumentTemplateContextType.Issue,
    label: 'core-portal.settings.document-template-context-types.14'
  },
]

export const contextTypeForIssueTemplatesEnumOptions: CorePortalFormlyNgSelectOption[] = [
  {
    value: TemplateContextType.IssueEmailSubject,
    label: 'core-portal.settings.document-template-context-types.18'
  },
  {
    value: TemplateContextType.IssueEmailContent,
    label: 'core-portal.settings.document-template-context-types.19'
  },
]
