import {ChangeDetectionStrategy, Component, Injector, OnInit} from '@angular/core';
import { AppEntityType, AppPermissions, ControllerOperationId, Mappers, MissionDto } from '@nexnox-web/core-shared';
import { resourceMissionsStore } from '../../store/stores';
import { CorePortalEntityOverviewBaseComponent, DatatableActionButton, DeleteEntityModel } from '@nexnox-web/core-portal';

@Component({
  selector: 'nexnox-web-resources-resource-missions',
  templateUrl: './resource-missions.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ResourceMissionsComponent extends CorePortalEntityOverviewBaseComponent<MissionDto> implements OnInit{
  public title = 'missions.subtitles.missions';
  public idProperty = 'missionId';
  public displayProperty = 'title';
  public pageOperation = ControllerOperationId.MissionsInResourcesControllerList;
  public componentId = 'ResourceMissionsComponent';
  public enableViews = true;
  public datatableConfigName = 'MissionDefault';

  constructor(
    protected injector: Injector
  ) {
    super(injector, resourceMissionsStore, Mappers.MissionCompleteDto.serializedName, AppEntityType.Mission);
  }

  /* istanbul ignore next */
  public getRowActionButtons(): DatatableActionButton[] {
    return this.getDefaultRowActionButtons(
      'missions.actions.edit-mission',
      (row: MissionDto) => `/missions/${row.missionId}`,
      [AppPermissions.UpdateMission],
      {
        module: 'inventory'
      }
    );
  }

  public getDeleteEntityModel(): DeleteEntityModel {
    return {
      titleKey: 'missions.actions.delete-mission',
      descriptionKey: 'missions.descriptions.delete-mission',
      confirmKey: 'missions.actions.delete-mission',
      deletePermission: AppPermissions.DeleteMission
    };
  }
}
