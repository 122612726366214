import {ChangeDetectionStrategy, Component, Injector, OnInit, ViewChild} from '@angular/core';
import {faExclamationTriangle} from '@fortawesome/free-solid-svg-icons/faExclamationTriangle';
import {faLockOpen} from '@fortawesome/free-solid-svg-icons/faLockOpen';
import {faUnlock} from '@fortawesome/free-solid-svg-icons/faUnlock';
import {
  ActionButton,
  CorePortalContactService,
  CorePortalEntityDetailBaseComponent,
  CorePortalPermissionService
} from '@nexnox-web/core-portal';
import {AppPermissions, ContactDto, ContractDto, LinkDto, LinkedElementType} from '@nexnox-web/core-shared';
import {select} from '@ngrx/store';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {contactDetailStore, ContactDetailXsStore} from '../../store';
import {TechPortalLinksComponent} from "@nexnox-web/tech-portal-lib";
import {faKey} from '@fortawesome/free-solid-svg-icons/faKey';
import {ForcePasswordSidebarComponent} from "../../sidebars";
import {faLock} from "@fortawesome/free-solid-svg-icons";
import {Actions} from "@ngrx/effects";

@Component({
  selector: 'nexnox-web-master-data-contacts-contact-detail',
  templateUrl: './contact-detail.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContactDetailComponent extends CorePortalEntityDetailBaseComponent<ContactDto> implements OnInit {

  @ViewChild('forcePasswordSidebarComponent', {static: true}) public forcePasswordSidebarComponent: ForcePasswordSidebarComponent;
  @ViewChild('linksComponent') public linksComponent: TechPortalLinksComponent;

  public title = 'core-portal.master-data.subtitles.contact-detail';

  public displayLocationsTab$: Observable<boolean>;
  public displayLocationGroupsTab$: Observable<boolean>;
  public isChangeMailAddressLoading$: Observable<boolean>;

  public faExclamationTriangle = faExclamationTriangle;

  public links$: Observable<LinkDto[]>;
  public linkedElementTypes = LinkedElementType;

  protected idParam = 'contactId';
  protected displayKey = 'displayName';

  protected entityStore: ContactDetailXsStore;
  protected contactService: CorePortalContactService;
  protected actions$: Actions;

  constructor(
    protected injector: Injector,
    private permissionService: CorePortalPermissionService
  ) {
    super(injector, contactDetailStore);

    this.displayLocationsTab$ = this.permissionService.hasPermission$(AppPermissions.DisplayLocations);
    this.displayLocationGroupsTab$ = this.permissionService.hasPermission$(AppPermissions.DisplayLocationGroups);

    this.isChangeMailAddressLoading$ = this.store.pipe(
      select(this.entityStore.selectors.selectEntityDataLoading, {key: this.entityStore.actions.changeMailAddress.type}),
      map(loading => Boolean(loading))
    );
  }

  public ngOnInit(): void {
    super.ngOnInit();

    this.links$ = this.model$.pipe(
      map(model => model?.links ?? [])
    );
  }

  public onLinksChange(links: LinkDto[], model: ContractDto): void {
    this.onModelChange({...model, links});
  }

  public onResetPassword(): void {
    this.store.dispatch(contactDetailStore.actions.resetPassword());
  }

  public onForcePassword(): void {
    this.forcePasswordSidebarComponent.onShow();
  }

  /* istanbul ignore next */
  protected async getActionButtons(): Promise<ActionButton[]> {
    return [
      ...this.getDefaultActionButtons(
        'core-portal.master-data.actions.edit-contact',
        'core-portal.master-data.actions.save-contact',
        'core-portal.master-data.actions.delete-contact',
        'core-portal.master-data.descriptions.delete-contact',
        AppPermissions.UpdateContact,
        AppPermissions.DeleteContact,
        ['/masterdata/contacts'],
        'management'
      ),
      {
        label: 'core-portal.master-data.actions.reset-password',
        type: 'button',
        class: 'btn-outline-primary',
        permission: AppPermissions.UpdateContact,
        icon: faUnlock,
        callback: () => this.onResetPassword(),
        isLoading: () => this.store.pipe(select(this.entityStore.selectors.selectEntityDataLoading, {
          key: this.entityStore.actions.resetPassword.type
        }))
      },

      {
        label: 'core-portal.master-data.actions.force-password',
        type: 'button',
        class: 'btn-outline-primary',
        permission: AppPermissions.UseSetPassword,
        icon: faKey,
        callback: () => this.onForcePassword(),
        isLoading: () => this.store.pipe(select(this.entityStore.selectors.selectEntityDataLoading, {
          key: this.entityStore.actions.forcePassword.type
        }))
      },
      {
        label: 'core-portal.master-data.actions.disable-contact',
        type: 'button',
        class: 'btn-outline-primary',
        permission: AppPermissions.UpdateContact,
        icon: faLock,
        callback: () => this.store.dispatch(this.entityStore.actions.disable()),
        shouldShow: () => this.store.pipe(
          select(this.entityStore.selectors.selectModel),
          map(model => Boolean(model) && model.access?.isLockedOut !== true)
        ),
        isLoading: () => this.store.pipe(select(this.entityStore.selectors.selectEntityDataLoading, {
          key: this.entityStore.actions.enable.type
        }))
      },
      {
        label: 'core-portal.master-data.actions.enable-contact',
        type: 'button',
        class: 'btn-outline-primary',
        permission: AppPermissions.UpdateContact,
        icon: faLockOpen,
        callback: () => this.store.dispatch(this.entityStore.actions.enable()),
        shouldShow: () => this.store.pipe(
          select(this.entityStore.selectors.selectModel),
          map(model => Boolean(model) && model.access?.isLockedOut)
        ),
        isLoading: () => this.store.pipe(select(this.entityStore.selectors.selectEntityDataLoading, {
          key: this.entityStore.actions.enable.type
        }))
      }
    ];
  }

}
