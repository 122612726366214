import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren
} from '@angular/core';
import {CorePortalCardboxAction} from '@nexnox-web/core-portal';
import {Observable} from 'rxjs';
import {map, take} from 'rxjs/operators';
import {LocalFormRowDto} from '../../models';
import {faPlus} from '@fortawesome/free-solid-svg-icons/faPlus';
import {AppPermissions, FormFieldTypes, FormRowTypes, UuidService} from '@nexnox-web/core-shared';
import {cloneDeep, forEach, maxBy, minBy, sortBy} from 'lodash';
import {FormRowEditComponent} from '../form-row-edit/form-row-edit.component';
import {CreateFormRowSidebarComponent} from '../../sidebars';
import {BindObservable} from 'bind-observable';

@Component({
  selector: 'nexnox-web-forms-form-rows-edit',
  templateUrl: './form-rows-edit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormRowsEditComponent implements OnInit {
  @Input() public rows$: Observable<LocalFormRowDto[]>;

  @Input() @BindObservable() public readonly: boolean;
  public readonly$!: Observable<boolean>;

  @Input() public loading: boolean;

  @ViewChild('createFormRowSidebarComponent', {static: true}) public createFormRowSidebarComponent: CreateFormRowSidebarComponent;

  @ViewChildren('formRowEditComponent') public formRowEditComponents: QueryList<FormRowEditComponent>;

  @Output() public rowsChange: EventEmitter<LocalFormRowDto[]> = new EventEmitter<LocalFormRowDto[]>();

  public sortedRows$: Observable<LocalFormRowDto[]>;

  public headerActions: CorePortalCardboxAction[];
  public trackRowsBy: any;

  /* istanbul ignore next */
  constructor(private uuidService: UuidService) {
    this.headerActions = [
      {
        label: 'orga-portal.forms.actions.create-form-row',
        icon: faPlus,
        class: 'btn-outline-primary',
        permission: AppPermissions.CreateFormRow,
        shouldShow: () => this.readonly$.pipe(
          map(readonly => !readonly)
        ),
        callback: () => this.createFormRowSidebarComponent.onShow()
      }
    ];
    this.trackRowsBy = (index: number, row: LocalFormRowDto) => row.formRowId ?? row.localFormRowId;
  }

  public ngOnInit(): void {
    this.sortedRows$ = this.rows$.pipe(
      map(rows => cloneDeep(rows)),
      map(rows => sortBy(rows, 'position').map(row => {
        if (!row.fields?.length) {
          return row;
        }

        return {...row, fields: sortBy(row.fields, 'position')};
      }))
    );
  }

  public async onRowChange(row: LocalFormRowDto, position: number): Promise<void> {
    const newRows = cloneDeep(await this.rows$.pipe(take(1)).toPromise()) ?? [];
    const index = newRows.findIndex(x => x.position === position);
    newRows[index] = row;
    this.rowsChange.emit(this._cleanUpPositions(newRows.map(newRow => ({
      ...newRow,
      fields: (newRow.fields ?? []).map(field => {
        if (field.type === FormFieldTypes.Dropdown) {
          return field;
        }

        return {
          ...field,
          defaultValues: field.defaultValues?.length && (field.defaultValues[0] as any).value ?
            field.defaultValues.map(x => ({...x, type: field.type})) : []
        };
      })
    }))));
  }

  public async onCreateRow(title: string): Promise<void> {
    const newRows = cloneDeep(await this.rows$.pipe(take(1)).toPromise()) ?? [];
    const maxIdSet = newRows.length ? maxBy(newRows, x => x.formRowId ?? x.localFormRowId) : null;
    newRows.push({
      localFormRowId: this.uuidService.generateUuid(),
      title,
      type: FormRowTypes.Single,
      position: newRows.length ? maxBy(newRows, x => x.position).position + 1 : 1
    });
    this.rowsChange.emit(this._cleanUpPositions(newRows));
  }

  public async onMoveUp(position: number): Promise<void> {
    const newRows = cloneDeep(await this.rows$.pipe(take(1)).toPromise()) ?? [];
    const index = newRows.findIndex(x => x.position === position);
    const previousIndex = newRows.findIndex(
      x => x.position === maxBy(newRows.filter(y => y.position < position), y => y.position)?.position
    );

    newRows[index].position--;
    newRows[previousIndex].position++;
    this.rowsChange.emit(this._cleanUpPositions(newRows));
  }

  public async onMoveDown(position: number): Promise<void> {
    const newRows = cloneDeep(await this.rows$.pipe(take(1)).toPromise()) ?? [];
    const index = newRows.findIndex(x => x.position === position);
    const nextIndex = newRows.findIndex(
      x => x.position === minBy(newRows.filter(y => y.position > position), y => y.position)?.position
    );

    newRows[nextIndex].position--;
    newRows[index].position++;
    this.rowsChange.emit(this._cleanUpPositions(newRows));
  }

  public async onDelete(position: number): Promise<void> {
    const newRows = cloneDeep(await this.rows$.pipe(take(1)).toPromise()) ?? [];
    const index = newRows.findIndex(x => x.position === position);
    const nextIndexes = newRows
      .filter(x => x.position > position)
      .map(x => newRows.findIndex(y => y.position === x.position));

    for (const nextIndex of nextIndexes) {
      newRows[nextIndex].position--;
    }
    newRows.splice(index, 1);

    this.rowsChange.emit(this._cleanUpPositions(newRows));
  }

  public isModelValid(): boolean {
    return this.formRowEditComponents.toArray().every(x => x.isModelValid());
  }

  private _cleanUpPositions(rows: LocalFormRowDto[]): LocalFormRowDto[] {
    const newRows = cloneDeep(sortBy(rows, 'position'));
    for (let i = 0; i < newRows.length; i++) {
      newRows[i].position = i + 1;
      newRows[i].fields = sortBy(newRows[i].fields, 'position');
      for (let j = 0; j < newRows[i].fields.length; j++) {
        newRows[i].fields[j].position = j + 1;
      }
    }
    return newRows;
  }
}
