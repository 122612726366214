import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output, TemplateRef, ViewChild} from '@angular/core';
import {DatatableTableColumn, DatatableTableColumnOption, DatatableTableColumnType} from '../../models';
import {BehaviorSubject, Observable} from 'rxjs';
import {IconDefinition} from '@fortawesome/fontawesome-common-types';
import {map, mergeMap, startWith} from 'rxjs/operators';
import {faSortUp} from '@fortawesome/free-solid-svg-icons/faSortUp';
import {faSortDown} from '@fortawesome/free-solid-svg-icons/faSortDown';
import {CorePortalEntityDatatableFilterComponent} from '..';
import {Filter, FilterKind, isFilterDuplicate} from '@nexnox-web/core-shared';
import {SortIcon} from 'primeng/table';
import {faMagic} from '@fortawesome/free-solid-svg-icons/faMagic';
import {Dictionary} from '@ngrx/entity';

@Component({
  selector: 'nexnox-web-entity-datatable-header',
  templateUrl: './entity-datatable-header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CorePortalEntityDatatableHeaderComponent {
  @Input() public hideFilters: boolean;
  @Input() public hideSorting: boolean;
  @Input() public disableFilters: boolean;
  @Input() public templates: Dictionary<TemplateRef<any>>;

  public get column(): DatatableTableColumn {
    return this.columnSubject.getValue();
  }

  @Input()
  public set column(column: DatatableTableColumn) {
    this.columnSubject.next(column);
  }

  public get currentFilters(): Filter[] {
    return this.currentFiltersSubject.getValue();
  }

  @Input()
  public set currentFilters(currentFilters: Filter[]) {
    this.currentFiltersSubject.next(currentFilters);
  }

  @Output() public search: EventEmitter<Filter> = new EventEmitter<Filter>();
  @Output() public openSettings: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild('sortIconComponent') private sortIconComponent: SortIcon;
  @ViewChild('filterInput') private filterInput: CorePortalEntityDatatableFilterComponent;

  public isDataColumn$: Observable<boolean>;
  public isOptionalColumn$: Observable<boolean>;
  public directionIcons$: Observable<IconDefinition[]>;
  public currentFilter$: Observable<Filter>;
  public isComplex$: Observable<boolean>;
  public isRelativeFilter$: Observable<boolean>;
  public disabledInputValue$: Observable<string>;

  public faMagic = faMagic;

  private columnSubject: BehaviorSubject<DatatableTableColumn> = new BehaviorSubject<DatatableTableColumn>(null);
  private sortDirectionSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  private currentFiltersSubject: BehaviorSubject<Filter[]> = new BehaviorSubject<Filter[]>([]);

  /* istanbul ignore next */
  constructor() {
    this.directionIcons$ = this.sortDirectionSubject.asObservable().pipe(
      map(direction => direction === 'asc' ? [faSortUp] : direction === 'desc' ? [faSortDown] : [faSortUp, faSortDown])
    );

    this.currentFilter$ = this.columnSubject.asObservable().pipe(
      mergeMap(column => this.currentFiltersSubject.asObservable().pipe(
        map(currentFilters => {
          if (column.prop === 'customPropertyValues') {
            return currentFilters.find(x => x.property === column.customPropertyId?.toString());
          }

          let property: string;

          switch (this.column.type) {
            case DatatableTableColumnType.PATH:
              property = `${this.column.prop?.toString()}.id`;
              break;
            case DatatableTableColumnType.REFERENCE:
              if (this.column.filterProp) {
                property = this.column.filterProp;
              } else {
                property = `${this.column.prop?.toString()}`;
                const columnFilter = currentFilters.find(filter => (filter.property === column.prop?.toString()));
                if (this.column.idKey && columnFilter?.kind !== FilterKind.Relative) {
                  property += `.${this.column.idKey}`;
                }
              }
              break;
            default:
              property = this.column.prop?.toString();
              break;
          }
          return currentFilters.find(x => x.property === property);
        })
      ))
    );

    this.isComplex$ = this.columnSubject.asObservable().pipe(
      mergeMap(column => this.currentFilter$.pipe(
        startWith(null),
        map(currentFilter => {
          let columnProperty: string;
          let isGrouped = false;

          if (column.prop === 'customPropertyValues') {
            columnProperty = column.customPropertyId?.toString();
          } else {
            switch (column.type) {
              case DatatableTableColumnType.PATH:
                columnProperty = 'path.id';
                break;
              case DatatableTableColumnType.REFERENCE:
                columnProperty = `${column.prop?.toString()}.${column.idKey}`;
                break;
              default:
                columnProperty = column.prop?.toString();
                break;
            }
          }

          if (column.type === DatatableTableColumnType.REFERENCE
            || column.type === DatatableTableColumnType.PATH
            || column.type === DatatableTableColumnType.ENUM) {
            isGrouped = true;
          }

          return (currentFilter?.isComplex && !isGrouped) ||
            isFilterDuplicate({property: columnProperty}, this.currentFilters, true, isGrouped);
        })
      ))
    );

    this.isRelativeFilter$ = this.currentFilter$.pipe(
      map(currentFilter => currentFilter?.kind === FilterKind.Relative)
    );

    this.disabledInputValue$ = this.isRelativeFilter$.pipe(map((isRelative: boolean) => {
        if (!isRelative) return '';
        switch (this.column?.type) {
          case DatatableTableColumnType.REFERENCE:
            return 'core-portal.core.sidebar.menu.settings.relative-me';
          default:
            return '';
        }
      }
    ));

    this.isDataColumn$ = this.columnSubject.asObservable().pipe(
      map(column => column.option === DatatableTableColumnOption.DATA)
    );

    this.isOptionalColumn$ = this.columnSubject.asObservable().pipe(
      map(column => column.option === DatatableTableColumnOption.OPTIONAL)
    );
  }

  public clearFilter(): void {
    this.filterInput?.clear();
  }

  /* istanbul ignore next */
  public updateSortIcon(): void {
    setTimeout(() => {
      this.sortIconComponent?.dt?.tableService?.onSort(null);
      this.sortIconComponent?.updateSortState();
      this.sortIconComponent?.cd?.detectChanges();
    });
  }
}
