import {
  roleContactListStore,
  RoleContactListStoreState,
  roleDetailStore,
  RoleDetailStoreState,
  roleListStore,
  RoleListStoreState
} from './stores';

export interface RolesState {
  roleList: RoleListStoreState;
  roleDetail: RoleDetailStoreState;
  roleContactList: RoleContactListStoreState;
}

export const getInitialRolesState = (): RolesState => ({
  roleList: roleListStore.getInitialState(),
  roleDetail: roleDetailStore.getInitialState(),
  roleContactList: roleContactListStore.getInitialState()
});
