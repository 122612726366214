import {PagedEntitiesXsStore, PagedEntitiesXsStoreState} from '@nexnox-web/core-store';
import {AppEntityType, TaskJobDto} from '@nexnox-web/core-shared';
import {createSelector} from '@ngrx/store';
import {LocationTaskjobArchiveService} from '../../services';
import {selectLocationsState} from "../../locations.selectors";

export interface LocationTaskArchiveListStoreState extends PagedEntitiesXsStoreState<TaskJobDto> {
}

/* istanbul ignore next */
export const locationTaskArchiveListStore = new PagedEntitiesXsStore<TaskJobDto>({
  actionLabel: 'Orga Portal - Locations - Task Archive List',
  stateSelector: createSelector(selectLocationsState, state => state.locationTaskArchiveList),
  serviceType: LocationTaskjobArchiveService,
  entityType: AppEntityType.TaskJob,
  selectId: taskJob => taskJob.entity?.taskJobId ?? taskJob.model?.taskJobId,
  stereotyped: false
});
