import { PagedEntitiesXsStore, PagedEntitiesXsStoreState } from '@nexnox-web/core-store';
import { AppEntityType, DocumentTemplateListDto } from '@nexnox-web/core-shared';
import { Action, createSelector } from '@ngrx/store';
import { selectDocumentTemplatesState } from '../document-templates.selectors';
import { TechPortalFeatureDocumentTemplateService } from '../../../services';
import { Injectable, Injector } from '@angular/core';

export interface DocumentTemplateListStoreState extends PagedEntitiesXsStoreState<DocumentTemplateListDto> {
}

/* istanbul ignore next */
export const documentTemplateListStore = new PagedEntitiesXsStore<DocumentTemplateListDto>({
  actionLabel: 'Tech Portal - Templates - Document Template List',
  stateSelector: createSelector(selectDocumentTemplatesState, state => state.documentTemplateList),
  serviceType: TechPortalFeatureDocumentTemplateService,
  entityType: AppEntityType.None,
  selectId: template => template.entity?.documentTemplateId ?? template.model?.documentTemplateId
});

export function documentTemplateListStoreReducer(state: DocumentTemplateListStoreState, action: Action): any {
  return documentTemplateListStore.reducer(state, action);
}

@Injectable()
export class DocumentTemplateListStoreEffects extends documentTemplateListStore.effects {
  constructor(
    protected injector: Injector
  ) {
    super(injector);
  }
}
