import {AppPermissions, AppRoleDto, MeDto, TenantInfoDto} from '@nexnox-web/core-shared';

export interface AuthXsStoreState {
  accessToken: string;
  refreshToken: string;
  restrictedRoleIds: number[];
  permissions: AppPermissions[];
  info: MeDto;
  activeTenant: TenantInfoDto;
  activeRoles: AppRoleDto[];
  tenantId: number;
  loggedIn: boolean;
  loading: boolean;
  loaded: boolean;
}

export const getInitialAuthXsStoreState = (): AuthXsStoreState => ({
  accessToken: null,
  refreshToken: null,
  restrictedRoleIds: [],
  permissions: [],
  info: null,
  tenantId: null,
  activeTenant: null,
  activeRoles: [],
  loggedIn: false,
  loading: false,
  loaded: false
});
