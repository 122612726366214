import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { LocalFormFieldDto } from '../../models';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { CorePortalCardboxAction } from '@nexnox-web/core-portal';
import { AppPermissions, CoreSharedFileService, FormFieldTypes } from '@nexnox-web/core-shared';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons/faTrashAlt';
import { FormFieldTemplates } from './form-field.templates';
import { OrgaPortalKnowledgeArticleService } from '@nexnox-web/orga-portal-lib';
import { map, switchMap } from 'rxjs/operators';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons/faArrowLeft';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons/faArrowRight';
import { faArrowsAltH } from '@fortawesome/free-solid-svg-icons/faArrowsAltH';
import Fraction from 'fraction.js';
import { MenuItem } from 'primeng/api';
import { BindObservable } from 'bind-observable';
import {
  CorePortalSearchListsService
} from "@nexnox-web/core-portal/features/settings/features/search-lists/src/lib/store/services/search-lists/search-lists.service";

@Component({
  selector: 'nexnox-web-forms-form-field-edit',
  templateUrl: './form-field-edit.component.html',
  styleUrls: ['./form-field-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormFieldEditComponent implements OnInit {
  @Input() @BindObservable() public field: LocalFormFieldDto;
  public field$!: Observable<LocalFormFieldDto>;

  @Input() @BindObservable() public readonly: boolean;
  public readonly$!: Observable<boolean>;

  @Input() @BindObservable() public isFirst: boolean;
  public isFirst$!: Observable<boolean>;

  @Input() @BindObservable() public isLast: boolean;
  public isLast$!: Observable<boolean>;

  @ViewChild('titleEditInput') public titleEditInput: ElementRef<HTMLInputElement>;

  @Output() public fieldChange: EventEmitter<LocalFormFieldDto> = new EventEmitter<LocalFormFieldDto>();
  @Output() public moveUp: EventEmitter<void> = new EventEmitter<void>();
  @Output() public moveDown: EventEmitter<void> = new EventEmitter<void>();
  @Output() public delete: EventEmitter<void> = new EventEmitter<void>();

  public form: FormGroup;
  public formFields: FormlyFieldConfig[];

  public headerActions: CorePortalCardboxAction[];

  private formFieldTemplates: FormFieldTemplates;

  /* istanbul ignore next */
  constructor(
    private fileService: CoreSharedFileService,
    private knowledgeArticleService: OrgaPortalKnowledgeArticleService,
    private searchListService: CorePortalSearchListsService
  ) {
    this.headerActions = [
      {
        icon: faArrowsAltH,
        class: 'p-button-secondary',
        tooltip: 'orga-portal.forms.fields.width',
        permission: AppPermissions.UpdateFormField,
        shouldShow: () => this.readonly$.pipe(
          map(readonly => !readonly)
        ),
        badgeText: () => this.readonly$.pipe(
          switchMap(readonly => this.field$.pipe(
            map(field => {
              if (readonly) return null;

              const fraction = new Fraction((field?.width ?? 0) / 12);
              return `${fraction.n}/${fraction.d}`;
            })
          ))
        ),
        badgeClass: 'bg-secondary',
        dropdownItems: () => of([2, 3, 4, 5, 6, 7, 8, 9, 10, 12].map(x => {
          const fraction = new Fraction(x / 12);
          return {
            label: `${fraction.n}/${fraction.d}`,
            disabled: x === this.field?.width,
            command: () => this.onFieldChange({ ...this.field, width: x }, true)
          } as MenuItem;
        }))
      },
      {
        icon: faArrowLeft,
        class: 'p-button-secondary',
        tooltip: 'core-shared.shared.actions.move-left',
        permission: AppPermissions.UpdateFormField,
        shouldShow: () => this.readonly$.pipe(
          map(readonly => !readonly)
        ),
        isDisabled: () => this.isFirst$,
        callback: () => this.moveUp.emit()
      },
      {
        icon: faArrowRight,
        class: 'p-button-secondary',
        tooltip: 'core-shared.shared.actions.move-right',
        permission: AppPermissions.UpdateFormField,
        shouldShow: () => this.readonly$.pipe(
          map(readonly => !readonly)
        ),
        isDisabled: () => this.isLast$,
        callback: () => this.moveDown.emit()
      },
      {
        icon: faTrashAlt,
        class: 'p-button-danger',
        tooltip: 'orga-portal.forms.actions.delete-form-field',
        permission: AppPermissions.DeleteFormField,
        shouldShow: () => this.readonly$.pipe(
          map(readonly => !readonly)
        ),
        callback: () => this.delete.emit()
      }
    ];
  }

  public ngOnInit(): void {
    this.formFieldTemplates = new FormFieldTemplates(() => this.readonly);
    this.form = new FormGroup({});
    this.formFields = this.createForm();
  }

  public onTitleChange(title: string): void {
    this.onFieldChange({ ...this.field, title }, true);
  }

  public onFieldChange(field: LocalFormFieldDto, ignorePristine?: boolean): void {
    if (this.form.pristine && !ignorePristine) return;

    this.fieldChange.emit(field);
  }

  public isModelValid(): boolean {
    return Boolean(this.titleEditInput?.nativeElement?.checkValidity()) && this.form.valid;
  }

  /* istanbul ignore next */
  private createForm(): FormlyFieldConfig[] {
    if (!this.field) return [];

    switch (this.field.type) {
      case FormFieldTypes.Info:
        return this.formFieldTemplates.info();
      case FormFieldTypes.Text:
        return this.formFieldTemplates.text();
      case FormFieldTypes.Multiline:
        return this.formFieldTemplates.multilineText();
      case FormFieldTypes.Number:
        return this.formFieldTemplates.numeric();
      case FormFieldTypes.Date:
        return this.formFieldTemplates.date();
      case FormFieldTypes.Time:
        return this.formFieldTemplates.time();
      case FormFieldTypes.Picture:
        return this.formFieldTemplates.picture();
      case FormFieldTypes.Signature:
        return this.formFieldTemplates.signature();
      case FormFieldTypes.Checkbox:
        return this.formFieldTemplates.checkbox();
      case FormFieldTypes.Dropdown:
        return this.formFieldTemplates.dropdown();
      case FormFieldTypes.Attachment:
        return this.formFieldTemplates.attachment(this.fileService, this.knowledgeArticleService);
      case FormFieldTypes.SearchList:
        return this.formFieldTemplates.searchList(this.searchListService);
      default:
        return this.formFieldTemplates.default();
    }
  }
}
