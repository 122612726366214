import {ChangeDetectionStrategy, Component, Injector, Input, OnInit} from '@angular/core';
import {ActionButton, CorePortalEntityDetailStandaloneBaseComponent} from '@nexnox-web/core-portal';
import {AppPermissions, ResourcesByLocationDefinitionDto} from "@nexnox-web/core-shared";
import {resourcesByLocationStereotypeDetailStore} from "./../../store";
import {BehaviorSubject, combineLatest, merge, Observable} from "rxjs";
import {select} from "@ngrx/store";
import {map, withLatestFrom} from "rxjs/operators";

@Component({
  selector: 'nexnox-web-settings-stereotypes-resource-by-location-definition-detail',
  templateUrl: './resources-by-location-stereotype-detail-standalone.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ResourcesByLocationStereotypeDetailComponent extends CorePortalEntityDetailStandaloneBaseComponent<ResourcesByLocationDefinitionDto> implements OnInit {

  public stereotypeId: number;

  public idParam = "resourcesByLocationDefinitionId";
  public displayKey = 'title';

  public formTableValidSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  constructor(
    protected injector: Injector,
  ) {
    super(injector, resourcesByLocationStereotypeDetailStore);
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.setTitle('core-shared.shared.skeletons.create-skeleton', 'core-shared.shared.skeletons.edit-skeleton');
    this.stereotypeId = +this.route.snapshot.paramMap.get('stereotypeId');

    this.disablePrimaryAction$ = merge(this.isModelValid$, this.model$).pipe(
      withLatestFrom(
        this.store.pipe(select(this.entityStore.selectors.selectHasChanged)),
        this.isModelValid$,
        this.formTableValidSubject.asObservable(),
        this.model$
      ),
      map(([_, hasChanges, isModelValid, isTableValid, model]) => !hasChanges || !isModelValid || !isTableValid || !(model?.resources?.length > 0))
    );
  }

  protected async getActionButtons(): Promise<ActionButton[]> {
    return this.getStandaloneActionButtons(
      'core-shared.shared.skeletons.create-skeleton',
      AppPermissions.CreateResourcesByLocationDefinition,
      'core-shared.shared.skeletons.edit-skeleton',
      AppPermissions.UpdateResourcesByLocationDefinition,
      'core-shared.shared.skeletons.save-skeleton',
      AppPermissions.UpdateResourcesByLocationDefinition
    )
  }
}
