import { PagedEntitiesXsStore, PagedEntitiesXsStoreOptions, PagedEntitiesXsStoreState } from '@nexnox-web/core-store';
import { AuditListDto } from '@nexnox-web/core-shared';
import { CorePortalFeatureAuditService } from '../../services';

export interface CorePortalFeatureAuditListXsStoreOptions
  extends Omit<PagedEntitiesXsStoreOptions<AuditListDto>, 'selectId' | 'serviceType' | 'stereotyped'> {
}

export interface CorePortalFeatureAuditListXsStoreState extends PagedEntitiesXsStoreState<AuditListDto> {
}

export class CorePortalFeatureAuditListXsStore extends PagedEntitiesXsStore<AuditListDto> {
  constructor(
    private specialOptions: CorePortalFeatureAuditListXsStoreOptions
  ) {
    super({
      ...specialOptions,
      selectId: audit => audit.entity?.auditId ?? audit.model?.auditId,
      serviceType: CorePortalFeatureAuditService,
      stereotyped: false
    });
  }
}
