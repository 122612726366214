<ng-template #entityEditTemplate>
  <div class="row">
    <div class="col-md-9 px-0 pr-md-2">
      <nexnox-web-missions-mission-inspection-report-edit #modelComponent
                                                          [title]="'missions.subtitles.mission-inspection-report' | translate"
                                                          [model]="model$ | async"
                                                          [stereotypeId]="stereotypeId"
                                                          [readonly]="readonly$ | async"
                                                          [creating]="false"
                                                          [loading]="loading$ | async"
                                                          [stereotypes$]="stereotypes$"
                                                          [headerActions]="editHeaderActions"
                                                          (modelChange)="onModelChange($event)">
      </nexnox-web-missions-mission-inspection-report-edit>
    </div>

    <div class="col-md-3 px-0 pl-md-2">
      <nexnox-web-cardbox [title]="'missions.subtitles.mission-inspection-status' | translate"
                          [transparent]="true"
                          paddingClasses="px-3"
                          [loading]="loading$ | async">
        <div class="d-flex flex-row align-items-center font-weight-bold mb-3">
          <fa-icon [icon]="(isOkay$ | async) ? faCheckCircle : faExclamationCircle"
                   [fixedWidth]="true"
                   class="mr-2"
                   [class.text-success]="isOkay$ | async"
                   [class.text-warning]="(isOkay$ | async) === false">
          </fa-icon>
          <span>{{ (passedPercentage$ | async) }}%</span>&nbsp;
          <span class="text-muted">({{ ('core-portal.core.general.' + ((isOkay$ | async) ? 'okay' : 'not-okay')) | translate }})</span>
        </div>

        <div *ngIf="creator$ | async as creator" class="d-flex flex-column mb-3">
          <label class="form-label">{{ 'core-shared.shared.fields.created-by-user-name' | translate }}</label>

          <div class="d-flex flex-row pl-3">
            <fa-icon [icon]="faUser" [fixedWidth]="true" size="sm" class="mr-2"></fa-icon>
            <span>{{ creator.name }}</span>
          </div>
        </div>

        <div *ngIf="createdAt$ | async as createdAt" class="d-flex flex-column mb-3">
          <label class="form-label">{{ 'core-shared.shared.fields.created-at' | translate }}</label>

          <div class="d-flex flex-row pl-3">
            <fa-icon [icon]="faClock" [fixedWidth]="true" size="sm" class="mr-2"></fa-icon>
            <span [pTooltip]="createdAt | nexnoxWebBsDate:'L LT' | async"
                  appendTo="body"
                  tooltipPosition="bottom">
              {{ createdAt | nexnoxWebDayjsRelative | async }}
            </span>
          </div>
        </div>

        <ng-container *ngIf="(createdAndModifiedEqual$ | async) === false">
          <div *ngIf="lastModified$ | async as lastModified" class="d-flex flex-column mb-3">
            <label class="form-label">{{ 'core-shared.shared.fields.last-modified' | translate }}</label>

            <div class="d-flex flex-row pl-3">
              <fa-icon [icon]="faClock" [fixedWidth]="true" size="sm" class="mr-2"></fa-icon>
              <span [pTooltip]="lastModified | nexnoxWebBsDate:'L LT' | async"
                    appendTo="body"
                    tooltipPosition="bottom">
                {{ lastModified | nexnoxWebDayjsRelative | async }}
              </span>
            </div>
          </div>
        </ng-container>

        <div *ngIf="isOutdated$ | async" class="d-flex flex-column mb-3">
          <fa-icon [icon]="faExclamationTriangle"
                   [fixedWidth]="true"
                   class="text-warning mr-2">
          </fa-icon>
          <span>{{ 'missions.descriptions.outdated-control-point' | translate }}</span>
        </div>
      </nexnox-web-cardbox>
    </div>
  </div>
</ng-template>

<nexnox-web-entity-detail *ngIf="inspectionReportId; else promptCreateReportTemplate"
                          [entityDetailBaseComponent]="this"
                          [entityEditTemplate]="entityEditTemplate"
                          [useTabs]="true"
                          [hideOverflow]="false">
</nexnox-web-entity-detail>

<ng-template #promptCreateReportTemplate>
  <div class="min-h-100">
    <div *ngIf="(readonly$ | async); else createReportTemplate"
         class="d-flex w-100 h-100 align-items-center justify-content-center flex-column py-5">
      <fa-icon class="d-flex justify-content-center mb-2" [icon]="faTimes" size="5x"></fa-icon>
      <h4 class="d-flex justify-content-center">{{ 'missions.no-inspection-report.title' | translate }}</h4>
      <h6 class="d-flex justify-content-center">{{ 'missions.no-inspection-report.subtitle' | translate }}</h6>
    </div>
  </div>
</ng-template>

<ng-template #createReportTemplate>
  <nexnox-web-missions-mission-inspection-report-edit #createComponent
                                                      [title]="'missions.actions.create-mission-inspection-report' | translate"
                                                      [model]="createModelSubject | async"
                                                      [stereotypeId]="stereotypeId"
                                                      [readonly]="false"
                                                      [creating]="true"
                                                      [loading]="loading$ | async"
                                                      [headerActions]="createHeaderActions"
                                                      [stereotypes$]="delayedStereotypes$"
                                                      (modelChange)="createModelSubject.next($event)">
  </nexnox-web-missions-mission-inspection-report-edit>
</ng-template>
