import {
  searchListsDetailStore,
  SearchListsDetailStoreState,
  searchListsListStore,
  SearchListsListStoreState
} from './stores';

export interface SearchListsState {
  searchListsList: SearchListsListStoreState;
  searchListsDetail: SearchListsDetailStoreState;
}

export const getInitialSearchListsState = (): SearchListsState => ({
  searchListsList: searchListsListStore.getInitialState(),
  searchListsDetail: searchListsDetailStore.getInitialState()
});
