import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {
  CorePortalFeatureMasterDataLocationListComponent,
  LocationAddressDetailStandaloneComponent,
  LocationDetailComponent
} from './containers';
import {
  AppPermissions,
  I18nBreadcrumbResolver,
  I18nEntityBreadcrumbConfig,
  I18nEntityBreadcrumbResolver,
  UnsavedChangesGuard,
  UnsavedCreateGuard
} from '@nexnox-web/core-shared';
import {CorePortalPermissionGuard} from '@nexnox-web/core-portal';
import {locationDetailStore} from "./store";
import {LocationAddressBreadcrumbsResolver} from "./resolvers";

export const CORE_PORTAL_ROUTING_MASTER_DATA_LOCATIONS_ROUTES: Routes = [
  {
    path: '',
    canActivate: [CorePortalPermissionGuard],
    canDeactivate: [UnsavedCreateGuard],
    component: CorePortalFeatureMasterDataLocationListComponent,
    data: {
      permissions: [AppPermissions.DisplayLocations]
    }
  },
  {
    path: ':locationId',
    component: LocationDetailComponent,
    canActivate: [CorePortalPermissionGuard],
    canDeactivate: [UnsavedChangesGuard],
    data: {
      breadcrumbs: I18nEntityBreadcrumbResolver,
      entityStore: locationDetailStore,
      idProperty: 'locationId',
      displayProperty: 'name',
      tab: 'location',
      text: 'core-portal.core.header.breadcrumbs.master-data.location',
      permissions: [AppPermissions.ReadLocation, AppPermissions.ReadStereotype]
    } as I18nEntityBreadcrumbConfig,
    children: [
      {
        path: 'addresses',
        data: {
          tab: 'addresses',
          breadcrumbs: I18nBreadcrumbResolver,
          text: 'core-portal.core.header.breadcrumbs.master-data.addresses'
        },
        children: []
      },
      {
        path: 'location-groups',
        data: {
          tab: 'location-groups',
        },
        children: []
      },
      {
        path: 'contacts',
        data: {
          tab: 'contacts'
        },
        children: []
      },
      {
        path: 'resources',
        data: {
          tab: 'resources'
        },
        children: []
      },
      {
        path: 'missions',
        data: {
          tab: 'missions'
        },
        children: []
      },
      {
        path: 'issues',
        data: {
          tab: 'issues'
        },
        children: []
      },
      {
        path: 'audit',
        data: {
          tab: 'audit'
        },
        children: []
      },
      {
        path: 'tickets',
        data: {
          tab: 'tickets'
        },
        children: []
      },
      {
        path: 'tasks',
        data: {
          tab: 'tasks'
        },
        children: []
      },
      {
        path: 'planned',
        data: {
          tab: 'planned'
        },
        children: []
      },
      {
        path: 'contracts',
        data: {
          tab: 'contracts'
        },
        children: []
      },
      {
        path: 'attachments',
        data: {
          tab: 'attachments'
        },
        children: []
      }
    ]
  },
  {
    path: ':locationId/addresses/create',
    canDeactivate: [UnsavedChangesGuard],
    component: LocationAddressDetailStandaloneComponent,
    data: {
      breadcrumbs: LocationAddressBreadcrumbsResolver,
      permissions: [AppPermissions.CreateAddress]
    }
  },
  {
    path: ':locationId/addresses/:addressId',
    canDeactivate: [UnsavedChangesGuard],
    component: LocationAddressDetailStandaloneComponent,
    data: {
      breadcrumbs: LocationAddressBreadcrumbsResolver,
      permissions: [AppPermissions.UpdateAddress]
    }
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(CORE_PORTAL_ROUTING_MASTER_DATA_LOCATIONS_ROUTES)
  ],
  exports: [
    RouterModule
  ]
})
export class LocationsRoutingModule {
}
