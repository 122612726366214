import { ModuleWithProviders, NgModule } from '@angular/core';
import { ResourceTasksDeclarationsModule } from './resource-tasks-declarations.module';
import { ResourceTasksStoreModule } from './resource-tasks-store.module';
import { ResourceTasksRoutingModule } from './resource-tasks-routing.module';

@NgModule({
  imports: [
    ResourceTasksDeclarationsModule,
    ResourceTasksStoreModule,
    ResourceTasksRoutingModule
  ]
})
export class TechPortalFeatureResourceTasksModule {
  public static forFeature(): ModuleWithProviders<ResourceTasksDeclarationsModule> {
    return { ngModule: ResourceTasksDeclarationsModule };
  }
}
