<ng-template #entityEditTemplate let-title="title" let-id="id">
  <nexnox-web-ccp-control-point-edit #modelComponent
                                     [title]="title"
                                     [id]="id"
                                     [model]="model$ | async"
                                     [readonly]="readonly$ | async"
                                     [creating]="false"
                                     [loading]="loading$ | async"
                                     [stereotyped]="false"
                                     (modelChange)="onModelChange($event)">
  </nexnox-web-ccp-control-point-edit>
</ng-template>

<nexnox-web-entity-detail [entityDetailBaseComponent]="this"
                          [entityEditTemplate]="entityEditTemplate"
                          tabId="controlPoint"
                          [tabHeading]="title | translate"
                          [tabCommands]="['.']">
</nexnox-web-entity-detail>
