import { HeaderXsStore } from './header.xs-store';
import { Action, createSelector } from '@ngrx/store';
import { selectCoreGuiState } from '../gui.selectors';
import { HeaderXsStoreState } from './header-xs-store.state';
import { Injectable, Injector } from '@angular/core';

export const headerStore = new HeaderXsStore({
  actionLabel: 'Core Portal - Core - Gui - Header',
  stateSelector: createSelector(selectCoreGuiState, state => state.header)
});

export function headerStoreReducer(state: HeaderXsStoreState, action: Action): any {
  return headerStore.reducer(state, action);
}

@Injectable()
export class HeaderStoreEffects extends headerStore.effects {
  constructor(
    protected injector: Injector
  ) {
    super(injector);
  }
}
