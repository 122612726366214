import {on} from '@ngrx/store';
import {AuthXsStoreActions} from './auth-xs-store.actions';
import {AuthXsStoreState} from './auth-xs-store.state';
import {BaseXsStoreReducerTypes} from '@nexnox-web/core-store';
import {immerOn} from 'ngrx-immer/store';

export const createAuthXsStoreReducer = (
  actions: AuthXsStoreActions,
  initialState: AuthXsStoreState
): BaseXsStoreReducerTypes<AuthXsStoreState, AuthXsStoreActions>[] => ([
  immerOn(actions.login, draft => {
    draft.loading = true;
  }),

  immerOn(actions.loginWithRefresh, draft => {
    draft.loading = true;
  }),

  immerOn(actions.loginSuccess, (draft, payload) => {
    draft.accessToken = payload.accessToken;
    draft.refreshToken = payload.refreshToken;
    draft.restrictedRoleIds = payload.restrictedRoleIds;
    draft.permissions = payload.permissions;
    draft.info = payload.info;
    draft.activeTenant = payload.activeTenant;
    draft.activeRoles = payload.activeTenant?.roles ?? [];
    draft.tenantId = payload.activeTenant?.tenantId;

    draft.loggedIn = true;
    draft.loading = false;
    draft.loaded = true;
  }),

  immerOn(actions.changePassword, draft => {
    draft.loading = true;
  }),
  immerOn(actions.changePasswordSuccess, draft => {
    draft.loading = false;
  }),

  immerOn(actions.resetPassword, draft => {
    draft.loading = true;
  }),
  immerOn(actions.resetPasswordSuccess, draft => {
    draft.loading = false;
  }),

  immerOn(actions.forgotPassword, draft => {
    draft.loading = true;
  }),
  immerOn(actions.forgotPasswordSuccess, draft => {
    draft.loading = false;
  }),

  on(actions.logout, () => initialState),

  immerOn(actions.error, draft => {
    draft.loading = false;
  })
]);
