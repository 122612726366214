<div class="d-flex flex-row w-100 dual-list-box">
  <div class="d-flex flex-grow-1 justify-content-center w-100">
    <ng-container [ngTemplateOutlet]="ngSelectTemplate"
                  [ngTemplateOutletContext]="{
                    items$: availableItems$,
                    model$: markedAvailableItems$,
                    search$: availableItemsSearch$,
                    emitter: availableItemsChange
                  }">
    </ng-container>
  </div>

  <div class="d-flex flex-grow-0 flex-shrink-0 flex-column justify-content-center align-items-center mx-3">
    <button type="button"
            class="btn btn-outline-secondary mb-2"
            [disabled]="disabled || (moveAllRightEnabled$ | async) === false"
            (click)="onMoveAllRight()">
      <fa-icon [icon]="faAngleDoubleRight" size="lg" [fixedWidth]="true"></fa-icon>
    </button>

    <button type="button"
            class="btn btn-outline-secondary mb-2 mt-2"
            [disabled]="disabled || (moveRightEnabled$ | async) === false"
            (click)="onMoveRight()">
      <fa-icon [icon]="faAngleRight" size="lg" [fixedWidth]="true"></fa-icon>
    </button>

    <button type="button"
            class="btn btn-outline-secondary mb-2 mt-2"
            [disabled]="disabled || (moveLeftEnabled$ | async) === false"
            (click)="onMoveLeft()">
      <fa-icon [icon]="faAngleLeft" size="lg" [fixedWidth]="true"></fa-icon>
    </button>

    <button type="button"
            class="btn btn-outline-secondary mt-2"
            [disabled]="disabled || (moveAllLeftEnabled$ | async) === false"
            (click)="onMoveAllLeft()">
      <fa-icon [icon]="faAngleDoubleLeft" size="lg" [fixedWidth]="true"></fa-icon>
    </button>
  </div>

  <div class="d-flex flex-grow-1 justify-content-center w-100">
    <ng-container [ngTemplateOutlet]="ngSelectTemplate"
                  [ngTemplateOutletContext]="{
                    items$: activeItems$,
                    model$: markedActiveItems$,
                    search$: activeItemsSearch$,
                    emitter: activeItemsChange,
                    sort: true
                  }">
    </ng-container>
  </div>
</div>

<ng-template #ngSelectTemplate
             let-items$="items$"
             let-model$="model$"
             let-search$="search$"
             let-emitter="emitter"
             let-sort="sort">
  <ng-select #ngSelectComponent
             class="dual-list-box__select_embed w-100"
             [class.disabled]="disabled"
             [items]="items$ | async"
             [ngModel]="model$ | async"
             [multiple]="true"
             [searchable]="true"
             [searchFn]="searchFn"
             [clearSearchOnAdd]="false"
             [selectableGroup]="true"
             [selectableGroupAsModel]="false"
             [isOpen]="true"
             [disabled]="disabled"
             dropdownPosition="bottom"
             groupBy="items"
             (ngModelChange)="onMarkedChanged($event, model$)">
    <ng-template ng-header-tmp>
      <div class="input-group w-100">
        <div class="input-group-prepend">
          <span class="input-group-text"><fa-icon [icon]="faSearch"></fa-icon></span>
        </div>

        <input type="search"
               class="form-control"
               [ngModel]="getSearchTerm(search$ | async)"
               [disabled]="disabled || isSearchDisabled(items$ | async)"
               (ngModelChange)="onSearch($event, ngSelectComponent, search$)"/>
      </div>
    </ng-template>

    <ng-template ng-optgroup-tmp let-item="item" let-searchTerm="searchTerm">
      <span *ngIf="item.translated; else notTranslated"
            [title]="item.title | translate"
            [innerHTML]="item.title | translate | nexnoxWebHighlight:searchTerm">
      </span>

      <ng-template #notTranslated>
        <span [title]="item.title" [innerHTML]="item.title | nexnoxWebHighlight:searchTerm"></span>
      </ng-template>
    </ng-template>

    <ng-template ng-option-tmp let-item="item" let-item$="item$" let-searchTerm="searchTerm">
      <div class="d-flex w-100 align-items-center">
        <div class="d-flex flex-grow-1">
          <span *ngIf="item.translated; else notTranslated"
                class="w-100"
                [title]="item.title | translate">
            <span [innerHTML]="item.title | translate | nexnoxWebHighlight:searchTerm"></span>
            <span *ngIf="item.suffix" class="text-muted"> ({{ item.suffix | translate }})</span>
          </span>

          <ng-template #notTranslated>
            <span class="w-100" [title]="item.title">
              <span [innerHTML]="item.title | nexnoxWebHighlight:searchTerm"></span>
              <span *ngIf="item.suffix" class="text-muted"> ({{ item.suffix | translate }})</span>
            </span>
          </ng-template>
        </div>

        <div *ngIf="sort" class="d-flex">
          <ng-container *ngIf="items$ | async">
            <button *ngIf="item.position < item$?.parent?.children?.length"
                    type="button"
                    class="btn btn-sm btn-outline-secondary"
                    [class.mr-1]="item.position > 1"
                    (click)="onMoveItemDown($event, item, items$, emitter)">
              <fa-icon [icon]="faArrowDown" [fixedWidth]="true" size="xs"></fa-icon>
            </button>

            <button *ngIf="item.position > 1"
                    type="button"
                    class="btn btn-sm btn-outline-secondary"
                    (click)="onMoveItemUp($event, item, items$, emitter)">
              <fa-icon [icon]="faArrowUp" [fixedWidth]="true" size="xs"></fa-icon>
            </button>
          </ng-container>
        </div>
      </div>
    </ng-template>

    <ng-template ng-notfound-tmp>
      <div class="d-flex w-100 h-100 justify-content-center align-items-center p-3">
        <span class="text-muted">{{ 'core-shared.shared.table.empty' | translate }}</span>
      </div>
    </ng-template>
  </ng-select>
</ng-template>
