import {EntityXsStore, EntityXsStoreState} from '@nexnox-web/core-store';
import {
  AppEntityType,
  ChatByDefinitionGeneralDataProtectionRestrictionDto,
  GeneralDataProtectionRegulationDto,
  GeneralDataProtectionRestrictionType,
  TaskGeneralDataProtectionRestrictionDto
} from '@nexnox-web/core-shared';
import {Action, createSelector} from '@ngrx/store';
import {selectGdprState} from '../../gdpr.selectors';
import {CorePortalGdprService} from '../../services';
import {Injectable, Injector} from '@angular/core';
import {LocalGDPRRegulationDto, LocalGDPRRestrictionDto} from "./../../../models";
import {cloneDeep} from "lodash";

export interface GdprDetailStoreState extends EntityXsStoreState<LocalGDPRRegulationDto> {
}

/* istanbul ignore next */
export const gdprDetailStore = new EntityXsStore<LocalGDPRRegulationDto>({
  actionLabel: 'Core Portal - Settings - GDPR - GDPR Detail',
  stateSelector: createSelector(selectGdprState, state => state.gdprDetail),
  serviceType: CorePortalGdprService,
  entityType: AppEntityType.None,
  stereotyped: false,
  prepareEntity: (entity, base) => ({
    ...base(entity),
    tasks: mapRestrictions(entity, GeneralDataProtectionRestrictionType.Task),
    definitions: mapRestrictions(entity, GeneralDataProtectionRestrictionType.ChatByDefinition),
  }),
  prepareModel: (entity, model, base) => ({
    ...base(entity, model),
    tasks: mapRestrictions(entity, GeneralDataProtectionRestrictionType.Task),
    definitions: mapRestrictions(entity, GeneralDataProtectionRestrictionType.ChatByDefinition),
  }),
  sanitizeModel: (model, entity, base) => {
    return sanitizeToRegulationDto(model);
  }
});

export function gdprDetailStoreReducer(state: GdprDetailStoreState, action: Action): any {
  return gdprDetailStore.reducer(state, action);
}

@Injectable()
export class GdprDetailStoreEffects extends gdprDetailStore.effects {
  constructor(
    protected injector: Injector
  ) {
    super(injector);
  }
}

function mapRestrictions(entity: GeneralDataProtectionRegulationDto, type: GeneralDataProtectionRestrictionType): LocalGDPRRestrictionDto[] {
  const restrictions = (cloneDeep(entity?.restrictions) as any ?? []);
  const first = restrictions[0];
  const localRestrictions = restrictions.map(
    restriction => ({
      ...(restriction?.task ?? restriction?.definition ?? {}),
      restrictionType: restriction.type,
      restrictionId: restriction.generalDataProtectionRestrictionId
    } as LocalGDPRRestrictionDto)
  );
  return first?.type === type ? localRestrictions : undefined;
}

export function sanitizeToRegulationDto(model: LocalGDPRRegulationDto): GeneralDataProtectionRegulationDto {

  const sanitized = {...model} as GeneralDataProtectionRegulationDto;
  const localRestrictions = (sanitized as LocalGDPRRegulationDto).tasks ?? (sanitized as LocalGDPRRegulationDto).definitions ?? [];

  sanitized.restrictions = localRestrictions.map(
    restriction => {
      restriction = cloneDeep(restriction)
      const restrictionId = cloneDeep(restriction?.restrictionId);
      let mappedRestriction;

      delete restriction.restrictionId;
      delete restriction.restrictionType;

      if(restriction.taskId > 0) {
        mappedRestriction = {
          task: restriction,
          generalDataProtectionRestrictionId: restrictionId ?? 0,
          type: GeneralDataProtectionRestrictionType.Task
        } as TaskGeneralDataProtectionRestrictionDto;
      }

      if(restriction.chatByFormDefinitionId > 0) {
        mappedRestriction = {
          definition: restriction,
          generalDataProtectionRestrictionId: restrictionId ?? 0,
          type: GeneralDataProtectionRestrictionType.ChatByDefinition
        } as ChatByDefinitionGeneralDataProtectionRestrictionDto;
      }

      return mappedRestriction;
    }
  );
  return sanitized;
}
