<div [class]="'form-group ' + to.corePortalTranslated?.formGroupClassName"
     [class.row]="to.corePortalTranslated?.horizontal"
     [class.has-error]="showError">
  <label *ngIf="(to.label || to.corePortalTranslated?.label) && !to.corePortalTranslated?.hideLabel && !to.corePortalTranslated?.title"
         [class]="to.corePortalTranslated?.labelClass"
         [class.col-form-label]="to.corePortalTranslated?.horizontal"
         [ngClass]="to.corePortalTranslated?.horizontal ? (to.corePortalTranslated.horizontalLabelClass ?? 'col-sm-4') : ''">
    <span [class.text-bold]="to.corePortalTranslated?.bold">
      {{ to.label }}
      {{ to.corePortalTranslated?.label | translate }}
    </span>

    <span *ngIf="to.required && !to.corePortalTranslated?.hideRequiredMarker">*</span>
  </label>

  <h5 *ngIf="to.corePortalTranslated?.title"
      [class]="to.corePortalTranslated?.labelClass">
    {{ to.label }}
    {{ to.corePortalTranslated?.label | translate }}

    <span *ngIf="to.required && !to.corePortalTranslated?.hideRequiredMarker">*</span>
  </h5>

  <div class="d-flex align-items-center formly-input-container"
       [ngClass]="to.corePortalTranslated?.horizontal ? (to.corePortalTranslated.horizontalFieldClass ?? 'col-sm-8') : ''">
    <ng-template *ngIf="!to.corePortalTranslated?.hideValue" #fieldComponent></ng-template>

    <div class="d-flex flex-column">
      <small *ngIf="to.description || to.description$" class="form-text text-muted">
        {{ to.description ? to.description : (to.description$ | async) }}
      </small>

      <small *ngIf="to.corePortalTranslated?.description" class="form-text text-muted">
        {{ to.corePortalTranslated.description | translate }}
      </small>

      <ng-template *ngIf="to.corePortalTranslated?.descriptionTemplate"
                   [ngTemplateOutlet]="to.corePortalTranslated.descriptionTemplate"
                   [ngTemplateOutletContext]="{ item: formControl.value }">
      </ng-template>
    </div>

    <div *ngIf="to.corePortalTranslated?.rateable && to.corePortalTranslated?.setPointMessages"
         class="d-flex flex-column small mt-1">
      <div *ngFor="let setPointMessage of to.corePortalTranslated?.setPointMessages"
           class="d-flex align-items-center"
           [class.d-flex]="hasValue$ | async"
           [class.d-none]="(hasValue$ | async) === false"
           [class.text-muted]="!to.required && (hasValue$ | async) === false"
           [class.text-success]="(to.required || (hasValue$ | async)) && setPointMessage.valid"
           [class.text-warning]="(to.required || (hasValue$ | async)) && !setPointMessage.valid">
        <fa-icon [icon]="setPointMessage.valid ? faCheckCircle : faExclamationCircle" class="mr-1"></fa-icon>
        <span>{{ setPointMessage.label | translate:(setPointMessage.args ?? {}) }}</span>
      </div>
    </div>

    <div *ngIf="showError && to.corePortalTranslated?.validationMessages" class="d-block invalid-feedback">
      <nexnox-web-formly-validation-messages [field]="field"></nexnox-web-formly-validation-messages>
    </div>
  </div>
</div>
