<p-selectButton [class.is-invalid]="showError"
                styleClass="p-selectbutton-primary{{ to.corePortalButtonSelect.large ? ' p-selectbutton-large' : '' }}"
                [options]="to.corePortalButtonSelect.items"
                [formControl]="formControl"
                [formlyAttributes]="field"
                optionValue="value">
  <ng-template let-item>
    <div class="d-flex flex-column">
      <span *ngIf="item.title" class="p-selectbutton-title">
        <ng-container *ngIf="!to.corePortalButtonSelect.translate; else translatedItemTitle">{{ item.title }}</ng-container>
      </span>
      <span>
        <ng-container *ngIf="!to.corePortalButtonSelect.translate; else translatedItemLabel">{{ item.label }}</ng-container>
      </span>
    </div>

    <ng-template #translatedItemTitle>{{ item.title | translate }}</ng-template>
    <ng-template #translatedItemLabel>{{ item.label | translate }}</ng-template>
  </ng-template>
</p-selectButton>
