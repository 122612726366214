import {Component, EventEmitter, Input, Output} from '@angular/core';
import {BehaviorSubject} from "rxjs";

export interface Toast {
  title: string;
  translationParams?: { [key: string]: string };
}

@Component({
  selector: 'nexnox-web-static-toast',
  templateUrl: './static-toast.component.html'
})
export class StaticToastComponent {
  @Input() public toastListSubject: BehaviorSubject<Toast[]> = new BehaviorSubject<Toast[]>([]);

  @Output() public closeToast: EventEmitter<number> = new EventEmitter<number>();

  public onCloseToast(index: number): void {
    this.closeToast.emit(index);
  }

}
