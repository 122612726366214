import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable, Injector} from '@angular/core';
import {BehaviorSubject, from, Observable, throwError} from 'rxjs';
import {catchError, filter, map, mergeMap, switchMap, take, withLatestFrom} from 'rxjs/operators';
import {CoreSharedLocalStorageService, LocalStorageKeys, TenantInfoDto, TokenDto} from '@nexnox-web/core-shared';
import {select, Store} from '@ngrx/store';
import {JwtHelperService} from '@auth0/angular-jwt';
import {isUndefined} from 'lodash';
import {
  CorePortalAuthService,
  CorePortalCurrentTenantService,
  CorePortalTenantLocalStorageService
} from '../../services';
import {authStore} from '../../store';

const jwtHelper = new JwtHelperService();

export class NoTokenError extends Error {
  constructor(public loggedIn: boolean = false) {
    super('NoToken');

    this.loggedIn = loggedIn;
  }
}

export class TokenExpiredError extends Error {
  constructor(public loggedIn: boolean = false) {
    super('TokenExpired');
  }
}

export class NoRefreshTokenError extends Error {
  constructor(public loggedIn: boolean = false) {
    super('NoRefreshToken');
  }
}

@Injectable()
export class CorePortalJwtInterceptor implements HttpInterceptor {
  private refreshTokenInProgressSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private refreshTokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  private authService: CorePortalAuthService;
  private localStorageService: CoreSharedLocalStorageService;
  private tenantLocalStorageService: CorePortalTenantLocalStorageService;
  private currentTenantService: CorePortalCurrentTenantService;
  private store: Store<any>;

  constructor(
    private injector: Injector,
  ) {
    setTimeout(() => {
      this.authService = this.injector.get(CorePortalAuthService);
      this.localStorageService = this.injector.get(CoreSharedLocalStorageService);
      this.tenantLocalStorageService = this.injector.get(CorePortalTenantLocalStorageService);
      this.currentTenantService = this.injector.get(CorePortalCurrentTenantService);
      this.store = this.injector.get(Store);
    });
  }

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.refreshTokenInProgressSubject.getValue()) {
      return this.refreshTokenSubject.asObservable().pipe(
        filter(token => Boolean(token)),
        take(1),
        switchMap(token => from(this.addTokenToHeader(request, token)).pipe(
          switchMap(requestWithAuth => next.handle(requestWithAuth)),
          catchError(error => throwError(error))
        ))
      );
    }

    return this.handleLoggedIn(request, next).pipe(
      catchError(error => {
        if (error instanceof NoTokenError || error instanceof TokenExpiredError) {
          return this.handleExpiredToken(request, next, error.loggedIn);
        } else if (error instanceof NoRefreshTokenError) {
          if (error.loggedIn) this.store.dispatch(authStore.actions.logout());
        }

        return throwError(error);
      })
    );
  }

  private handleLoggedIn(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.isLoggedIn().pipe(
      mergeMap(isLoggedIn => {
        if (isLoggedIn) {
          return this.getToken().pipe(
            mergeMap(token => this.handleToken(request, next, token, true)),
            catchError(error => throwError(error))
          );
        }

        return throwError(new NoTokenError());
      })
    );
  }

  private handleToken(request: HttpRequest<any>, next: HttpHandler, token: string, loggedIn: boolean = false): Observable<HttpEvent<any>> {
    if (!token) return throwError(new NoTokenError(loggedIn));
    if (this.isTokenExpired(token)) return throwError(new TokenExpiredError(loggedIn));

    return from(this.addTokenToHeader(request, token)).pipe(
      switchMap(requestWithAuth => this.handleResponse(requestWithAuth, next)),
      catchError(error => throwError(error))
    );
  }

  private handleResponse(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError(httpError => {
        if (this.isUnauthorizedError(httpError)) {
          if (this.isInvalidTokenError(httpError)) {
            return this.handleExpiredToken(request, next);
          }
        }

        return throwError(httpError);
      })
    );
  }

  private handleExpiredToken(request: HttpRequest<any>, next: HttpHandler, loggedIn: boolean = false): Observable<HttpEvent<any>> {
    return this.store.pipe(
      select(authStore.selectors.selectRefreshToken),
      take(1),
      withLatestFrom(this.store.pipe(select(authStore.selectors.selectActiveTenant))),
      switchMap(([refreshToken, activeTenant]) => {
        if (refreshToken) {
          return this.refreshToken(refreshToken, activeTenant).pipe(
            switchMap(({ token, restrictedRoleIds, info }) => from(this.addTokenToHeader(request, token)).pipe(
              switchMap(requestWithAuth => {
                this.store.dispatch(authStore.actions.loginWithRefresh(token, refreshToken, restrictedRoleIds, info, activeTenant, false));
                return this.handleResponse(requestWithAuth, next);
              }),
              catchError(error => throwError(error))
            )),
            catchError(error => throwError(error))
          );
        }

        return throwError(new NoRefreshTokenError(loggedIn));
      }),
      catchError(error => {
        if (error instanceof NoRefreshTokenError) {
          return from(new Promise<any>(async resolve => {
            const refreshToken = this.localStorageService.get(LocalStorageKeys.REFRESH_TOKEN);
            const activeTenant = this.localStorageService.get(LocalStorageKeys.LAST_TENANT);
            resolve({ refreshToken, activeTenant });
          })).pipe(
            mergeMap(({ refreshToken, activeTenant }) => {
              if (refreshToken) {
                return this.refreshToken(refreshToken, activeTenant).pipe(
                  switchMap(({ token, restrictedRoleIds, info }) => from(this.addTokenToHeader(request, token)).pipe(
                    switchMap(requestWithAuth => {
                      this.store.dispatch(authStore.actions.loginWithRefresh(token, refreshToken, restrictedRoleIds, info, activeTenant));
                      return this.handleResponse(requestWithAuth, next);
                    }),
                    catchError(innerError => throwError(innerError))
                  )),
                  catchError(innerError => throwError(innerError))
                );
              }

              return throwError(new NoRefreshTokenError(loggedIn));
            }),
            catchError(innerError => {
              if (innerError instanceof NoRefreshTokenError) {
                if (loggedIn) this.store.dispatch(authStore.actions.logout());
              }

              return throwError(innerError);
            })
          );
        }

        return throwError(error);
      })
    );
  }

  private refreshToken(refreshToken: string, activeTenant?: TenantInfoDto, beforeResolve?: (response: TokenDto) => void): Observable<TokenDto> {
    this.refreshTokenInProgressSubject.next(true);
    this.refreshTokenSubject.next(null);
    return this.authService.refreshLoginV3(refreshToken, activeTenant?.tenantId).pipe(
      map(response => {
        this.authService.saveRefreshToken(response.refreshToken);
        if (beforeResolve) beforeResolve(response);
        this.refreshTokenInProgressSubject.next(false);
        this.refreshTokenSubject.next(response.token);
        return response;
      }),
      catchError(error => throwError(error))
    );
  }

  private isLoggedIn(): Observable<boolean> {
    return this.store.pipe(
      select(authStore.selectors.selectLoggedIn),
      take(1)
    );
  }

  private getToken(): Observable<string> {
    return this.store.pipe(
      select(authStore.selectors.selectAccessToken),
      take(1)
    );
  }

  private async addTokenToHeader(request: HttpRequest<any>, token: string): Promise<HttpRequest<any>> {
    // ToDo: Eventually remove this once it's not needed anymore
    if (request.params.has('useAllToken')) {
      const refreshToken = this.localStorageService.get(LocalStorageKeys.REFRESH_TOKEN);
      const allToken = this.localStorageService.get(LocalStorageKeys.ALL_TOKEN);

      if (!allToken || this.isTokenExpired(allToken)) {
        await this.refreshToken(refreshToken, undefined, tokenDto => {
          token = tokenDto.token;
          this.localStorageService.set(LocalStorageKeys.ALL_TOKEN, token);
        }).toPromise();
      } else {
        token = allToken;
      }

      request = request.clone({
        params: request.params.delete('useAllToken')
      });
    }

    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`
      }
    });
  }

  private isTokenExpired(token: string): boolean {
    try {
      return !token || jwtHelper.isTokenExpired(token, 30);
    } catch (_) {
      return true;
    }
  }

  private isUnauthorizedError(httpError: any): boolean {
    return httpError instanceof HttpErrorResponse && httpError.status === 401;
  }

  private isInvalidTokenError(error: any): boolean {
    if (this.isUnauthorizedError(error) && error.error && error.error.length > 0) {
      const errorCode = error.error[0].Code;
      return errorCode === 'InvalidRefreshToken';
    }

    return false;
  }
}
