import {
  AppEntityType,
  AppPermissions,
  ControllerOperationId,
  DashCasePipe,
  DataTableColumnDto,
  DataTableColumnType,
  DataTableCustomColumnDto,
  DataTableDto,
  DataTableFilterDto,
  DataTableFilterType,
  DataTableTransferColumnDto,
  Filter,
  FilterOperators,
  FilterTypes,
  SortObject,
  UnsubscribeHelper
} from '@nexnox-web/core-shared';
import {EventEmitter, Injectable, Injector} from '@angular/core';
import {BehaviorSubject, lastValueFrom, Observable} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {cloneDeep, isEqual, isNull} from 'lodash';
import {DatatableTableColumnOption} from '../../models';
import {CorePortalPermissionService} from '../../../../../services';
import {LocalDatatableTableColumn} from '../../sidebars';
import {CorePortalDatatableColumnService, CorePortalDatatableService, EntityDatatablePresetStore} from "../../services";
import {map} from "rxjs/operators";

export type LastUsedView = DataTableDto & { usedOn?: string };

export interface LocalDataTableDto extends DataTableDto {
  lastUsedViews?: LastUsedView[];
}

@Injectable()
export class CorePortalDatatableViewService extends UnsubscribeHelper {
  public settingsChange: EventEmitter<LocalDataTableDto> = new EventEmitter<LocalDataTableDto>();

  public currentViewTitle$: BehaviorSubject<string>;

  public selectedDatatableView$: Observable<DataTableDto>;

  public storeLoading$: Observable<boolean>;
  public loaded$: Observable<boolean>;

  public readDatatableViewPermission$: Observable<boolean>;

  public lastUsedViewsSubject: BehaviorSubject<LastUsedView[]> = new BehaviorSubject<LastUsedView[]>(null);

  private MAX_USED_VIEWS_LENGTH = 10;

  private store: EntityDatatablePresetStore;
  private translate: TranslateService;
  private permissionService: CorePortalPermissionService;

  constructor(private injector: Injector,
              private datatableService: CorePortalDatatableService,
              private datatableColumnService: CorePortalDatatableColumnService) {
    super();

    this.translate = injector.get(TranslateService);
    this.permissionService = injector.get(CorePortalPermissionService);

    this.readDatatableViewPermission$ = this.permissionService.hasPermission$(AppPermissions.ReadDataTable);
    this.setup();
  }

  public setup(): void {
    this.store = new EntityDatatablePresetStore(this.injector);

    this.storeLoading$ = this.store.selectLoading();
    this.loaded$ = this.store.selectLoaded();

    this.selectedDatatableView$ = this.store.selectSelectedDatatableView();
    this.currentViewTitle$ = this.store.currentPresetTitle;
  }

  public setDatatableConfig(datatableConfig: LocalDataTableDto): void {
    if (isNull(this.getOriginalDatatableView())) {
      this.setOriginalDatatableView(cloneDeep(datatableConfig));
    }

    this.store.setCurrentPresetTitle(datatableConfig?.name);
    this.setSelectedDatatableView({...datatableConfig});
  }

  public updateSelectedDatatableView(datatableView: Partial<LocalDataTableDto>): void {
    this.store.updateSelectedDatatableView(datatableView);
  }

  public getOriginalDatatableView(): LocalDataTableDto {
    return this.store.getOriginalDatatableView();
  }

  public getSelectedDatatableView(): LocalDataTableDto {
    return this.store.getSelectedDatatableView();
  }

  public setSelectedDatatableView(datatableConfig: LocalDataTableDto): void {
    this.store.setSelectedDatatableView(datatableConfig);
  }

  public setOriginalDatatableView(datatableConfig: LocalDataTableDto): void {
    this.store.setOriginalDatatableView(datatableConfig);
  }

  public onResetCurrentView(callback: (datatableView: LocalDataTableDto) => void): void {
    this.getOne(
      this.store.getSelectedDatatableView()?.dataTableId,
      (datatableView: LocalDataTableDto) => callback(datatableView)
    );
  }

  public async onSettingsChange(
    datatableConfig: LocalDataTableDto,
    enableViews?: boolean,
    componentId?: string,
    pageOperation?: ControllerOperationId,
    entityType?: AppEntityType
  ): Promise<void> {
    const recentViews = datatableConfig?.lastUsedViews?.length > 0 ?
      datatableConfig?.lastUsedViews : this.lastUsedViewsSubject.getValue()
    if (recentViews) {
      datatableConfig = this.updateConfigToLocalConfig(datatableConfig, recentViews);
    } else if (enableViews && componentId && (pageOperation || entityType)) {
      datatableConfig = {...await this.getConfigWithLastUsedViews(datatableConfig, componentId, pageOperation, entityType)};
    }

    this.settingsChange.emit(datatableConfig);
  }

  public async getConfigWithLastUsedViews(
    datatableConfig?: LocalDataTableDto,
    componentId?: string,
    pageOperation?: ControllerOperationId,
    entityType?: AppEntityType
  ): Promise<LocalDataTableDto> {
    if (!componentId) return undefined;

    const pageOperationOrEntityType = pageOperation || entityType;
    if (pageOperationOrEntityType) {
      const currentDatatableView: LocalDataTableDto = await this.getDatatableConfig(pageOperationOrEntityType, componentId, datatableConfig);

      let views: DataTableDto[];
      const currentViews = currentDatatableView?.lastUsedViews ?? this.lastUsedViewsSubject.getValue();
      if (currentViews?.length > 0) {
        views = currentViews.map((v: LocalDataTableDto) => (
          {...v, lastUsedViews: undefined}
        ));
      } else {
        views = await this.getViewsFromStore(datatableConfig, pageOperation);
      }
      return this.updateConfigToLocalConfig(datatableConfig ?? undefined, views);
    }
    return undefined;
  }

  public getDatatableConfig(
    pageOperationOrEntityType: ControllerOperationId | AppEntityType,
    componentId: string,
    datatableConfig?: LocalDataTableDto
  ): Promise<LocalDataTableDto> {
    return this.datatableColumnService.getDatatableConfig(pageOperationOrEntityType, componentId, datatableConfig ?? this.getSelectedDatatableView());
  }

  public onSelectedDatatableViewChange(view: LocalDataTableDto, callback: (datatableView: LocalDataTableDto) => void, skipGet?: boolean): void {
    this.store.setSelectedDatatableView({...view});

    if (isNull(view)) {
      this.store.setApplied(true);
    } else if (!skipGet && !isEqual(view, this.store.getOriginalDatatableView())) {
      this.getOne(
        this.store.getSelectedDatatableView().dataTableId,
        (datatableView: LocalDataTableDto) => callback(datatableView)
      );
    }
  }

  public setApplied(applied: boolean): void {
    this.store.setApplied(applied);
  }

  public getOne(datatableId: number, callback: (datatableView: LocalDataTableDto) => void): void {
    this.store.getOne(datatableId, (datatableView) => callback(datatableView));
  }

  public saveOne(datatableConfig: LocalDataTableDto, callback: (datatableView: LocalDataTableDto) => void): void {
    this.store.saveOne(datatableConfig, callback);
  }

  public onSaveCurrentConfigAsNew(datatableConfig: LocalDataTableDto): Promise<LocalDataTableDto> {
    return new Promise<LocalDataTableDto>(resolve => this.store.createOne(
      datatableConfig, (datatableView: LocalDataTableDto) => resolve(datatableView))
    );
  }

  public onDeleteCurrentConfig(callback: (currentView: LocalDataTableDto) => void): void {
    const currentView = this.getSelectedDatatableView();
    this.store.deleteOne(() => {
      this.lastUsedViewsSubject.next(this.lastUsedViewsSubject.getValue()?.filter((v) => v.dataTableId !== currentView?.dataTableId));
      callback(this.getSelectedDatatableView());
    });
  }

  /* istanbul ignore next */
  public mapColumn(column: LocalDatatableTableColumn, sortObject: SortObject): DataTableColumnDto {
    let columnType: DataTableColumnType;

    switch (column.option) {
      case DatatableTableColumnOption.STEREOTYPE:
        columnType = DataTableColumnType.ByCustomProperty;
        break;
      default:
        columnType = DataTableColumnType.ByTransfer;
        break;
    }

    if (columnType === DataTableColumnType.ByTransfer) {
      return {
        title: this.translate.instant(`core-shared.shared.fields.${DashCasePipe.transformString(column.name?.toString())}`),
        property: column.prop,
        type: columnType,
        position: column.position,
        sortOrder: sortObject && sortObject.sortField === column.prop ? sortObject.sort : undefined
      } as DataTableColumnDto;
    } else {
      return {
        title: column.name,
        customPropertyId: column.customPropertyId,
        type: columnType,
        position: column.position,
        sortOrder: sortObject && sortObject.sortField === column.prop ? sortObject.sort : undefined
      } as DataTableColumnDto;
    }
  }

  /* istanbul ignore next */
  public mapFilter(filter: Filter): DataTableFilterDto {
    let property: string;
    let type: DataTableFilterType;

    switch (filter.type) {
      case FilterTypes.DataTransferObject:
        property = filter.property;
        type = DataTableFilterType.ByTransfer;
        break;
      case FilterTypes.Custom:
        type = DataTableFilterType.ByCustomProperty;
        break;
      case FilterTypes.Grouped:
        property = filter.property;
        type = DataTableFilterType.ByGroup;
        break;
      default:
        property = filter.property;
        type = DataTableFilterType.Base;
        break;
    }

    const {operator, kind, value, label} = filter;
    const newFilter = {
      property,
      type,
      operator,
      kind,
      value,
      label,
      customPropertyId: filter.type === FilterTypes.Custom ? parseInt(filter.property, 10) : undefined,
      children: filter.children?.map(x => this.mapFilter(x)) ?? undefined,
      combinator: filter.combinedAs ?? undefined
    } as DataTableFilterDto;

    for (const key in newFilter) {
      if (newFilter.hasOwnProperty(key) && typeof newFilter[key] === 'undefined') {
        delete newFilter[key];
      }
    }

    return newFilter;
  }

  public areColumnsEqual(column: LocalDatatableTableColumn, datatableColumn: DataTableColumnDto): boolean {
    if (datatableColumn.type === DataTableColumnType.ByCustomProperty) {
      return column.identifier === (datatableColumn as DataTableCustomColumnDto).customPropertyId?.toString();
    } else {
      return column.identifier === (datatableColumn as DataTableTransferColumnDto).property;
    }
  }

  private updateConfigToLocalConfig(datatableConfig: LocalDataTableDto, usedViews?: DataTableDto[]): LocalDataTableDto {
    let lastUsedViews = [...(usedViews ?? this.lastUsedViewsSubject.getValue() ?? [])];
    const config = {...datatableConfig, lastUsedViews: undefined};

    if (config?.dataTableId && lastUsedViews?.length > 0) {
      lastUsedViews = lastUsedViews.filter(v => v.dataTableId !== datatableConfig.dataTableId);
      lastUsedViews.unshift(this.addTimeStampToView(config));

      if (lastUsedViews.length > this.MAX_USED_VIEWS_LENGTH) {
        lastUsedViews.splice(this.MAX_USED_VIEWS_LENGTH, lastUsedViews.length);
      }
    }

    return {
      ...config,
      lastUsedViews
    } as LocalDataTableDto;
  }

  private addTimeStampToView(view: LocalDataTableDto): LastUsedView {
    return {
      ...view,
      usedOn: new Date().toISOString()
    }
  }

  private async getViewsFromStore(
    activeView: DataTableDto,
    pageOperation: ControllerOperationId,
  ): Promise<DataTableDto[]> {
    return await this.getDatatableViewsFromStore(pageOperation, activeView?.dataTableId ? this.MAX_USED_VIEWS_LENGTH - 1 : undefined);
  }

  private async getDatatableViewsFromStore(pageOperation: ControllerOperationId, pageSize?: number): Promise<DataTableDto[]> {
    const defaultFilters = [{
      property: 'pageOperation',
      type: FilterTypes.DataTransferObject,
      operator: FilterOperators.Equal,
      value: pageOperation?.toString()
    }]
    return await lastValueFrom(this.datatableService.getPage(null, 1, defaultFilters, null, [], [], pageSize ?? this.MAX_USED_VIEWS_LENGTH)
      .pipe(map(page => (page?.items as DataTableDto[]))));
  }
}
