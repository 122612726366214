import {BaseXsStoreReducerTypes, EntityXsStore, EntityXsStoreState} from '@nexnox-web/core-store';
import {ResourceManufacturerDto} from '@nexnox-web/core-shared';
import {
  createManufacturerDetailXsStoreActions,
  ManufacturerDetailXsStoreActions
} from './manufacturer-detail-xs-store.actions';
import {createManufacturerDetailXsStoreReducer} from './manufacturer-detail-xs-store.reducer';

export class ManufacturerDetailXsStore extends EntityXsStore<ResourceManufacturerDto> {
  public actions: ManufacturerDetailXsStoreActions;

  protected createActions(label: string): ManufacturerDetailXsStoreActions {
    return createManufacturerDetailXsStoreActions(label);
  }

  protected createReducerArray(
    initialState: EntityXsStoreState<ResourceManufacturerDto>
  ): BaseXsStoreReducerTypes<EntityXsStoreState<ResourceManufacturerDto>, ManufacturerDetailXsStoreActions>[] {
    return [
      ...super.createReducerArray(initialState),
      ...createManufacturerDetailXsStoreReducer(this.actions, initialState)
    ];
  }
}
