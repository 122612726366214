import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Injector,
  Input,
  OnInit,
  Output,
  QueryList,
  TemplateRef,
  ViewChild,
  ViewChildren
} from '@angular/core';
import {PagedEntitiesXsStoreEntity} from '@nexnox-web/core-store';
import {
  ApiNotificationService,
  AppEntityType,
  AppPermissions,
  CombineOperator,
  CommonEmailDestinationDto,
  ControllerOperationId,
  CoreSharedSidebarBaseComponent,
  DashCasePipe,
  DataTableColumnDto,
  DataTableColumnType,
  DataTableFilterDto,
  DataTableUsage,
  ExportDestinationTypes,
  Filter,
  FilterTypes,
  isFilterComplex,
  isFilterDuplicate,
  mapDatatableFilterToFilter,
  mapFilterToDatatableFilter,
  Mappers,
  Orders,
  Paging,
  SortObject,
  StereotypeDto,
  UnsubscribeHelper
} from '@nexnox-web/core-shared';
import {BehaviorSubject, firstValueFrom, NEVER, Observable, throwError} from 'rxjs';
import {catchError, debounceTime, filter, map, skip, take, withLatestFrom} from 'rxjs/operators';
import {
  DatatableActionButton,
  DatatableHeaderAction,
  DatatableLoadPagePayload,
  DatatablePreColumn,
  DatatableSelectionMode,
  DatatableTableColumn,
  DatatableTableColumnOption,
  DatatableTableColumnType,
  DatatableTableColumnTyping
} from '../../models';
import {
  CorePortalDatatableColumnService,
  CorePortalDatatableFilterService,
  CorePortalDatatableService,
  CorePortalDatatableViewService,
  DataTableCalendarSubscriptionService,
  LocalDataTableDto,
  SimpleDatatableFilterField
} from '../../services';
import {faSync} from '@fortawesome/free-solid-svg-icons/faSync';
import {cloneDeep, isEqual, isNull, isNumber, isUndefined, sortBy, values} from 'lodash';
import {faCompress} from '@fortawesome/free-solid-svg-icons/faCompress';
import {faTrashAlt} from '@fortawesome/free-solid-svg-icons/faTrashAlt';
import {EntityData} from '../../../models';
import {faCog} from '@fortawesome/free-solid-svg-icons/faCog';
import {CorePortalEntityDatatableHeaderComponent} from '..';
import {CompositeMapper} from '@azure/ms-rest-js';
import {LazyLoadEvent} from 'primeng/api';
import {Table} from 'primeng/table';
import {faSpinner} from '@fortawesome/free-solid-svg-icons/faSpinner';
import {faFileExport} from '@fortawesome/free-solid-svg-icons/faFileExport';
import {CorePortalPermissionService} from '../../../../../services';
import {TranslateService} from '@ngx-translate/core';
import {select, Store} from '@ngrx/store';
import {authStore} from '../../../../../store';
import {
  CalendarSubscriptionSidebarComponent,
  EntityDatatableExportSidebarComponent
} from '../../sidebars';
import {Dictionary} from '@ngrx/entity';
import {faChevronDown} from '@fortawesome/free-solid-svg-icons/faChevronDown';
import {faChevronRight} from '@fortawesome/free-solid-svg-icons/faChevronRight';
import {faCalendarDay} from '@fortawesome/free-solid-svg-icons/faCalendarDay';
import {PredefinedDatatableView} from "@nexnox-web/libs/core-portal/src/lib/tokens";

@Component({
  selector: 'nexnox-web-entity-datatable',
  templateUrl: './entity-datatable.component.html',
  styleUrls: ['./entity-datatable.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CorePortalEntityDatatableComponent extends UnsubscribeHelper implements OnInit, AfterViewInit {
  /**
   * Is used to identify the entity and to determine one of the two columns with links and quick actions.
   */
  @Input() public idProp: string;

  /**
   * Is used to determine one of the two columns with links and quick actions.
   */
  @Input() public displayProp: string;

  /**
   * Is used for identifying the datatable instead of {@link entityType} and for saving datatable views and exporting them.
   */
  @Input() public pageOperation: ControllerOperationId;

  /**
   * Is used to identify this specific datatable in local storage. Should usually be the parent container name.
   * For example: `ParentContainerInThisFeatureComponent`
   */
  @Input() public componentId: string;

  /**
   * Columns that should be excluded from being rendered and being selected in the datatable settings.
   *
   * @default Empty
   */
  @Input() public excludedColumns: string[] = [];

  /**
   * Columns that should be rendered by default without having to select them in the datatable settings.
   *
   * @default Empty
   */
  @Input() public defaultColumns: string[] = [];

  /**
   * Column typings for all special columns. The column typing defines the type of the column, for example: `Text`, `Boolean` or `Date`.
   * See {@link DatatableTableColumnType} for all possible types.
   *
   * @default Empty
   */
  @Input() public columnTypings: DatatableTableColumnTyping[] = [];

  /**
   * Quick actions for the columns matching {@link idProp} and {@link displayProp}.
   *
   * @default Empty
   * @see DatatableActionButton
   */
  @Input() public actionButtons: DatatableActionButton[] = [];

  /**
   * Additional header actions in addition to the default header actions.
   *
   * @default Empty
   * @see DatatableHeaderAction
   */
  @Input() public additionalHeaderActions: DatatableHeaderAction[] = [];

  /**
   * Whether you can delete entries or not.
   *
   * @default false
   */
  @Input() public canDelete: boolean;

  /**
   * Default filters applied to this datatable. They are shown.
   *
   * @default Empty
   */
  @Input() public defaultFilters: Filter[] = [];

  /**
   * Is used for identifying the datatable instead of {@link pageOperation} and for saving datatable views and exporting them.
   */
  @Input() public entityType: AppEntityType;

  /**
   * The url to navigate to when clicking on one of the link columns or quick actions navigating to detail. Uses {@link module}.
   */
  @Input() public detailLink: string;

  /**
   * The module to navigate to in combination to {@link detailLink}.
   */
  @Input() public module: string;

  /**
   * Execute custom logic instead of using the default {@link detailLink} and {@link module}.
   */
  @Input() public detailFn: (row: any) => void;

  /**
   * Is used to have a loading animation for rows.
   */
  @Input() public entityData: EntityData;

  /**
   * Whether the datatable uses an XS Store or not
   *
   * @default true
   */
  @Input() public usesStore = true;

  /**
   * Whether to create columns automatically instead of generating them manually. Requires {@link serializedNameOrMapper}.
   *
   * @default false
   */
  @Input() public createColumnsAutomatically = false;

  /**
   * Is used to generate columns automatically.
   */
  @Input() public serializedNameOrMapper: string | CompositeMapper;

  /**
   * Whether datatable configs can be saved in the API or not. Also allows export.
   *
   * @default false
   */
  @Input() public enableViews = false;

  /**
   * An external datatable config that gets overridden when the datatable settings change instead of saving the config in local storage.
   * Also applies this config by default.
   *
   * @default null
   */
  @Input() public datatableConfig: LocalDataTableDto = null;

  /**
   * An external datatable config for custom datatable view filtering.
   * Uses this config by default.
   *
   * @default null
   */
  @Input() public datatablePredefinedViewConfig: PredefinedDatatableView[] = null;

  /**
   * Whether to show the refresh button or not.
   *
   * @default true
   */
  @Input() public showRefresh = true;

  /**
   * Whether to show the settings button or not.
   *
   * @default true
   */
  @Input() public showSettings = true;

  /**
   * Whether to show the calendar subscription button or not.
   *
   * @default false
   */
  @Input() public enableCalendarSubscription = false;

  /**
   * Whether to hide filters or not.
   *
   * @default false
   */
  @Input() public hideFilters = false;

  /**
   * Whether to hide sorting or not.
   *
   * @default false
   */
  @Input() public hideSorting = false;

  /**
   * Whether to hide the header or not.
   *
   * @default false
   */
  @Input() public hideHeader = false;

  /**
   * Whether to show the datatable as loading at any point. It makes sense turning loading off when the datatable already has values on
   * creation for example.
   *
   * @default true;
   */
  @Input() public showLoading = true;

  /**
   * Whether to disable filters or not.
   *
   * @default false
   */
  @Input() public disableFilters = false;

  /**
   * Whether to disable settings views or not.
   *
   * @default false
   */
  @Input() public disableSettingsViews = false;

  /**
   * Whether to disable just the selection of views in settings or not.
   *
   * @default false
   */
  @Input() public disableSettingsViewSelect = false;

  /**
   * Whether to disable sorting by optional columns or not.
   *
   * @default false
   */
  @Input() public disableOptionalSorting = false;

  /**
   * Whether it is possible to export the datatable config or not.
   *
   * @default true
   */
  @Input() public canExport = true;

  /**
   * Is used to define the filters used for exporting the datatable. If the user configured filters should not be used for export
   * you can override this and define custom filters. They are used instead of the user configured filters.
   */
  @Input() public filtersForExport: DataTableFilterDto[];

  /**
   * The templates needed for filters or other parts of the datatable requiring custom templates. Custom templates are needed for
   * select boxes in filters that need custom templates, for example an additional property being displayed.
   */
  @Input() public templates: Dictionary<TemplateRef<any>>;

  /**
   * The height of the header.
   *
   * @default 85
   */
  @Input() public headerHeight = 85;

  /**
   * The template used for expanded rows. Settings this property also enables expanding of rows.
   */
  @Input() public detailTemplate: TemplateRef<any>;

  /**
   * A function that controls the visibility of the detail template expand button.
   */
  @Input() public showDetailTemplateExpandButtonFn: (row: any) => boolean;

  /**
   * Columns prepended to the generated ones.
   *
   * @default Empty
   */
  @Input() public prependColumns: DatatableTableColumn[] = [];

  /**
   * Columns appended to the generated ones.
   *
   * @default Empty
   */
  @Input() public appendColumns: DatatableTableColumn[] = [];

  /**
   * Entities from the PagedEntitiesXsStore. Only required if {@link usesStore} is enabled.
   */
  @Input() public storeEntities$: Observable<PagedEntitiesXsStoreEntity<any, any>[]>;

  /**
   * Entities to use instead of entities from the store. Can be provided as Observable or not.
   *
   * @see models
   */
  @Input() public models$: Observable<any[]>;

  /**
   * Entities to use instead of entities from the store. Can be provided as Observable or not.
   *
   * @see models$
   */
  @Input() public models: any[];

  /**
   * The paging for the entries.
   */
  @Input() public paging: Paging;

  /**
   * Whether the datatable is loading or not.
   */
  @Input() public loading: boolean;

  /**
   * Stereotypes for this datatable. Is also used to re-create columns whenever stereotypes change.
   * Only required if {@link stereotyped} is enabled.
   *
   * @default NEVER
   */
  @Input() public stereotypes$: Observable<StereotypeDto[]> = NEVER;

  /**
   * Whether stereotypes are enabled or not.
   */
  @Input() public stereotyped = true;

  /**
   * Externally saved filters. Are used on first load of the datatable and will set these as the default instead of any default filters
   * configured. Useful for re-applying filters from local storage after user navigation.
   *
   * @default null
   */
  @Input() public savedFilters: Filter[] = null;

  /**
   * Externally saved sorting. Is used on first load of the datatable and will set the sorting to this instead of default sorting
   * configured. Useful for re-applying sorting from local storage after user navigation.
   *
   * @default null
   */
  @Input() public savedSortObject: SortObject = null;

  /**
   * Switch between selection mode. Either single or multiple selection. Default is None.
   *
   * @default 0
   */
  @Input() public selectionMode: DatatableSelectionMode = DatatableSelectionMode.None;

  @Output() public selectionChange: EventEmitter<any[]> = new EventEmitter<any[]>();

  /**
   * Descendant id is used for csv exports, that have a parent object (eg: Missions in a resource)
   *
   * @default null
   */
  @Input() public descendantId = null;

  @Output() public loadPage: EventEmitter<DatatableLoadPagePayload> = new EventEmitter<DatatableLoadPagePayload>();
  @Output() public delete: EventEmitter<any> = new EventEmitter<any>();
  @Output() public expanded: EventEmitter<any> = new EventEmitter<any>();
  @Output() public closed: EventEmitter<any> = new EventEmitter<any>();
  @Output() public clickableCellEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() public datatableCreated: EventEmitter<any> = new EventEmitter<any>();

  @Output() public datatableConfigChange: EventEmitter<LocalDataTableDto> = new EventEmitter<LocalDataTableDto>();

  public entities$: Observable<any[]>;
  public columns: DatatableTableColumn[] = [];
  public dataColumns: DatatableTableColumn[] = [];
  public activeColumns$: Observable<DatatableTableColumn[]>;
  public stereotypeColumns: DatatableTableColumn[] = [];
  public optionalColumns: DatatableTableColumn[] = [];
  public activeColumnsSubject: BehaviorSubject<DatatableTableColumn[]> = new BehaviorSubject<DatatableTableColumn[]>([]);

  public currentFilters$: Observable<Filter[]>;
  public visibleFilters$: Observable<Filter[]>;
  public complexFilters$: Observable<Filter[]>;

  public currentSortOption: SortObject = null;
  public localDatatableConfig: LocalDataTableDto = null;

  public canExportDatatable$: Observable<boolean>;
  public hasCalendarSubscriptionPermission$: Observable<boolean>;

  public orders = Orders;

  public faSync = faSync;
  public faCompress = faCompress;
  public faTrashAlt = faTrashAlt;
  public faCog = faCog;
  public faSpinner = faSpinner;
  public faFileExport = faFileExport;
  public faChevronRight = faChevronRight;
  public faChevronDown = faChevronDown;
  public faCalendarDay = faCalendarDay;

  public expandedRows = 0;

  public selectionModes = DatatableSelectionMode;

  public get count(): number {
    return isNumber(this.paging?.totalItems) ? this.paging.totalItems : undefined;
  }

  public get offset(): number {
    return isNumber(this.paging?.pageNumber) ? this.paging.pageNumber - 1 : undefined;
  }

  public get limit(): number {
    return isNumber(this.paging?.pageSize) ? this.paging.pageSize : undefined;
  }

  @ViewChild('datatableComponent') public datatableComponent: Table;

  @ViewChild('exportSidebarComponent') public exportSidebarComponent: EntityDatatableExportSidebarComponent;

  @ViewChild('settingsSidebarComponent') public settingsSidebarComponent: CoreSharedSidebarBaseComponent;

  @ViewChild('calendarSubscriptionSidebarComponent') public calendarSubscriptionSidebarComponent: CalendarSubscriptionSidebarComponent;

  @ViewChildren('headerComponent') private headerComponents: QueryList<CorePortalEntityDatatableHeaderComponent>;

  private mapper: CompositeMapper;

  private dataPreColumns: DatatablePreColumn[] = [];
  private stereotypePreColumns: DatatablePreColumn[] = [];
  private optionalPreColumns: DatatablePreColumn[] = [];
  private currentFiltersSubject: BehaviorSubject<Filter[]> = new BehaviorSubject<Filter[]>([]);

  private filterList: SimpleDatatableFilterField[] = [];
  private filters: any = {};

  private searchSubject = new BehaviorSubject<{ column: DatatableTableColumn, filterField: Filter }>({
    column: null,
    filterField: null
  });
  private pageSize: number = undefined;

  public datatableViewService: CorePortalDatatableViewService;

  constructor(
    private injector: Injector,
    private datatableService: CorePortalDatatableService,
    private datatableColumnService: CorePortalDatatableColumnService,
    private datatableFilterService: CorePortalDatatableFilterService,
    private apiNotificationService: ApiNotificationService,
    private translate: TranslateService,
    private store: Store<any>,
    private calendarSubscriptionService: DataTableCalendarSubscriptionService,
    private permissionService: CorePortalPermissionService
  ) {
    super();

    this.datatableViewService = new CorePortalDatatableViewService(injector, datatableService, datatableColumnService);
    this.subscribe(this.datatableViewService.settingsChange, (config) => this.onSettingsChange(config));

    this.activeColumns$ = this.activeColumnsSubject.asObservable().pipe(
      map(activeColumns => activeColumns.sort((a, b) => a.position - b.position))
    );

    this.currentFilters$ = this.currentFiltersSubject.asObservable().pipe(
      withLatestFrom(this.activeColumns$),
      map(([currentFilters, activeColumns]) => currentFilters.map(filter => {
        const filterColumn = activeColumns.find(column => {
          switch (column.type) {
            case DatatableTableColumnType.PATH:
              return filter.property?.startsWith(column.prop?.toString());
            case DatatableTableColumnType.REFERENCE:
              return `${column.prop.toString()}.${column.idKey}` === filter.property;
            default:
              return column.prop.toString() === filter.property;
          }
        });
        const isGrouped = filterColumn?.type === DatatableTableColumnType.REFERENCE
          || filterColumn?.type === DatatableTableColumnType.ENUM
          || filterColumn?.type === DatatableTableColumnType.PATH;

        return {
          ...filter,
          isComplex: isFilterComplex(filter) || isFilterDuplicate(filter, currentFilters, true, isGrouped)
        };
      }))
    );

    this.visibleFilters$ = this.currentFilters$.pipe(
      map(currentFilters => currentFilters.filter(x => !x.isComplex))
    );

    this.complexFilters$ = this.currentFilters$.pipe(
      map(currentFilters => currentFilters.filter(x => x.isComplex))
    );

    this.canExportDatatable$ = this.permissionService.hasPermission$(AppPermissions.ExportDataTable);

    this.hasCalendarSubscriptionPermission$ = this.permissionService.hasPermission$(AppPermissions.CreateCalendarMission)
  }

  public ngOnInit(): void {
    if (this.usesStore) {
      this.entities$ = this.storeEntities$.pipe(
        map(storeEntities => storeEntities.map(storeEntity => storeEntity.entity))
      );
      this.models$ = this.storeEntities$.pipe(
        map(storeEntities => storeEntities.map(storeEntity => storeEntity.model))
      );
    }

    this.subscribeToSearch();
  }

  public ngAfterViewInit(): void {
    if (this.createColumnsAutomatically && this.serializedNameOrMapper) this.createColumns(this.serializedNameOrMapper);
    this.showDetailTemplateExpandButton = isUndefined(this.showDetailTemplateExpandButtonFn) ? this.showDetailTemplateExpandButton : this.showDetailTemplateExpandButtonFn;

    // selection event
    if (this.datatableComponent) {
      this.subscribe(this.datatableComponent.selectionChange.pipe(filter(() => this.selectionMode !== DatatableSelectionMode.None)), (selection: any[]) => {
        this.selectionChange.emit(selection);
      });
    }
  }

  public async createColumns(serializedNameOrMapper: string | CompositeMapper): Promise<void> {
    this.createColumnsDefault(serializedNameOrMapper);
    this.createColumnsByStereotypes(this.stereotypes$);

    // !keys(this.mapper?.type.modelProperties)?.find(x => x === 'stereotype')
    // This condition was removed as fix for dto's, that aren't stereotyped but have a modelporperty called stereotype
    if (this.stereotyped) {
      await this.connectColumns(true);
    }
  }

  public onPage(event: LazyLoadEvent): void {
    if (this.usesStore && isNaN(event.first)) {
      return;
    }

    if (event.sortField) {
      const newSortOption = {
        sortField: event.sortField,
        sort: event.sortOrder === 1 ? Orders.Ascending : Orders.Descending
      };

      if (
        isEqual(newSortOption, this.currentSortOption) &&
        isEqual(1 + (event.first / event.rows), this.paging.pageNumber)
      ) {
        return;
      }

      this.currentSortOption = newSortOption;
    }

    this.loadPage.emit({
      pageNumber: 1 + (event.first / event.rows),
      sortOptions: this.currentSortOption,
      filters: this.currentFiltersSubject.getValue(),
      pageSize: this.pageSize
    });
  }

  public onSearch(column: DatatableTableColumn = null, filterField: Filter = null): void {
    this.searchSubject.next({column, filterField});
  }

  public onCellClick(column: any, row: any, mouseEvent: any): void {
    if (column?.clickable) {
      this.clickableCellEvent.emit({
        'column': column,
        'model': row,
        'mouseEvent': mouseEvent
      });
    }
  }

  public onRefresh(): void {
    this.loadPage.emit({
      pageNumber: 1,
      sortOptions: this.currentSortOption,
      filters: this.currentFiltersSubject.getValue(),
      pageSize: this.pageSize
    });
  }

  public async onClearFilters(): Promise<void> {
    this.filters = {};
    this.currentFiltersSubject.next(cloneDeep(this.defaultFilters));

    for (const headerComponent of this.headerComponents) {
      headerComponent.clearFilter();
    }

    await this.datatableColumnService.modifyDatatableConfig(
      {filters: []},
      this.pageOperation ?? this.entityType,
      this.componentId,
      this.datatableConfig,
      !this.enableViews
    );
    this.onSearch();
  }

  public onExpandRow(row: any): void {
    if (!this.datatableComponent) return;

    const expandedRowKeys = cloneDeep(this.datatableComponent.expandedRowKeys);
    const rowKey = row[this.idProp];
    const rowValue = expandedRowKeys[rowKey];

    this.datatableComponent.expandedRowKeys = {
      ...expandedRowKeys,
      [rowKey]: !isUndefined(rowValue) ? !rowValue : true
    };
    this.expandedRows = values(this.datatableComponent.expandedRowKeys).filter(x => Boolean(x)).length;
  }

  public onCollapseAll(): void {
    if (this.datatableComponent) {
      this.datatableComponent.expandedRowKeys = {};
      this.expandedRows = 0;
    }
  }

  public onShowSettingsSidebar(): void {
    this.settingsSidebarComponent?.onShow();
  }

  /**
   * Saves the changed datatable config either locally or in local storage, re-connects columns and fetches the first page.
   *
   * @param datatableConfig - The changed datatable config
   */
  public async onSettingsChange(datatableConfig: LocalDataTableDto): Promise<void> {
    const config: LocalDataTableDto = {...datatableConfig};
    if (this.datatableConfig) {
      this.datatableConfig = cloneDeep(config);
    } else {
      await this.datatableColumnService.saveDatatableConfig(
        this.pageOperation ?? this.entityType,
        this.componentId,
        config,
        !this.enableViews
      );
    }

    await this.connectColumns();
    this.loadPage.emit({
      pageNumber: 1,
      sortOptions: this.currentSortOption,
      filters: this.currentFiltersSubject.getValue(),
      pageSize: this.pageSize
    });

    // PH: Added emit with new datatable config after settings sidebar closed
    // PH: Not sure why this wasn't done by Leon
    this.datatableConfigChange.emit(config);
  }

  public openExportSidebar(): void {
    this.exportSidebarComponent?.onShow();
  }

  /**
   * Prepares the datatable config export and exports it.
   */
  public async onExport(result: any): Promise<void> {
    const {contact, totalItems} = result;
    const activeColumns = cloneDeep(this.activeColumnsSubject.getValue() ?? []);

    const columns: DataTableColumnDto[] =
      this.addProgressBarExtraColumn(activeColumns).map(column => this.mapColumnToDataTableColumn(column));

    let filters = (this.currentFiltersSubject.getValue() ?? []).map(filter => mapFilterToDatatableFilter(filter));

    if (!isUndefined(this.filtersForExport)) {
      filters = [...filters, ...this.filtersForExport];
    }

    const destination: CommonEmailDestinationDto = {
      type: ExportDestinationTypes.Email,
      displayName: contact.displayName,
      address: contact.emailAddress
    };
    const tenantId = await firstValueFrom(this.store.pipe(
      select(authStore.selectors.selectTenantId),
      take(1)
    ));

    await firstValueFrom(this.datatableService.export(
        this.pageOperation,
        destination,
        columns,
        filters,
        totalItems,
        tenantId,
        this.translate.currentLang,
        this.descendantId ?? undefined
      )
    )
      .then(() => this.apiNotificationService.showTranslatedSuccess('core-shared.shared.datatable-export.success'))
      .catch(error => this.apiNotificationService.handleApiError(error));
  }

  public onGetCalendarSubscriptionLink(): void {
    let subscriptionLink$: Observable<string>;
    this.getMyDataTableDto().then((table) => {
      subscriptionLink$ = this.calendarSubscriptionService.getCalendarSubscriptionLink(table).pipe(
        catchError((error) => {
          this.calendarSubscriptionSidebarComponent.loadingError$.next(true);
          return throwError(error);
        }),
        map((table => this.calendarSubscriptionService.generateSubscriptionLink(table))),
      );
      this.calendarSubscriptionSidebarComponent.subscriptionLink$ = subscriptionLink$;
      this.calendarSubscriptionSidebarComponent.onShow(null);
    });
  }

  public async getMyDataTableDto(): Promise<LocalDataTableDto> {
    const tenantId = await firstValueFrom(this.store.pipe(
      select(authStore.selectors.selectTenantId),
      take(1)
    ));

    // Get columns and filters
    let columns: DataTableColumnDto[] = (this.activeColumnsSubject.getValue() ?? [])
      .map(column => this.mapColumnToDataTableColumn(column));
    let filters = (this.currentFiltersSubject.getValue() ?? []).map(filter => mapFilterToDatatableFilter(filter));

    if (!isUndefined(this.filtersForExport)) {
      filters = [...filters, ...this.filtersForExport];
    }

    // Map tenantId and delete ids
    columns = columns?.map((col) => ({...col, tenantId, dataTableColumnId: undefined}));
    filters = filters?.map((filter) => ({
      ...filter,
      dataTableFilterId: undefined,
      tenantId,
      children: (filter as any)?.children?.map((child) => ({...child, tenantId, dataTableFilterId: undefined}))
    }));

    return {
      filters,
      columns,
      tenantId,
      dataTableId: undefined,
      name: 'Calendar',
      usage: DataTableUsage.DataTable,
      pageOperation: this.pageOperation,
      pageSize: this.pageSize ?? this.paging?.pageSize,
      lastUsedViews: this.datatableConfig?.lastUsedViews ?? {}
    } as LocalDataTableDto;
  }

  /**
   * Emits the delete event.
   *
   * @param entity - The entity to delete
   */
  public onDelete(entity: any): void {
    this.delete.emit(entity);
  }

  public trackColumnBy(index: number, column: DatatableTableColumn): string {
    return column.name;
  }

  /* istanbul ignore next */
  public reconnectFilters(first?: boolean, savedFilters?: Filter[], savedSortObject?: SortObject): void {
    this.connectFilters(this.localDatatableConfig, this.columns, first, savedFilters, savedSortObject);
  }

  /**
   * The default function can be overwritten with ()Input showDetailTemplateFn
   */
  public showDetailTemplateExpandButton(row: any): boolean {
    return true;
  }

  private addProgressBarExtraColumn(columns: DatatableTableColumn[]): DatatableTableColumn[] {
    const currentProgressBarColumnIndexes: number[] = [];

    currentProgressBarColumnIndexes
      .push(columns.findIndex(item => item.type === DatatableTableColumnType.PROGRESSBAR));

    if (currentProgressBarColumnIndexes[0] !== -1) {
      currentProgressBarColumnIndexes.forEach((index) => {
        const progressBarTotalColumn = {
          ...columns[index],
          prop: columns[index]?.totalKey ?? columns[index]?.prop,
          name: columns[index]?.totalKey ?? columns[index]?.name
        }
        columns.splice(index + 1, 0, progressBarTotalColumn);
      })
    }
    return columns;
  }

  private createColumnsDefault(serializedNameOrMapper: string | CompositeMapper): void {
    this.mapper = typeof serializedNameOrMapper === 'string' ? Mappers[serializedNameOrMapper] : serializedNameOrMapper;

    const columns = this.datatableColumnService.getDataColumns(this.mapper, this.excludedColumns);
    this.dataPreColumns = columns.filter(x => !x.optional);
    this.optionalPreColumns = columns.filter(x => x.optional);

    this.filterList = this.datatableFilterService.buildFilters(this.datatableFilterService.getFilterColumns(this.mapper));
  }

  /* istanbul ignore next */
  private createColumnsByStereotypes(stereotypes$: Observable<StereotypeDto[]>): void {
    this.subscribe(stereotypes$.pipe(
      filter(stereotypes => Boolean(stereotypes?.length))
    ), stereotypes => {
      this.stereotypePreColumns = this.datatableColumnService.getStereotypeColumns(stereotypes);
      this.connectColumns(true);
    });
  }

  private subscribeToSearch(): void {
    this.subscribe(this.searchSubject.pipe(
      skip(1),
      debounceTime(400)
    ), ({column, filterField}) => {
      if (column) {
        const [newFilters, currentFilters] = this.datatableFilterService
          .buildSortFilter(this.filters, this.defaultFilters, column, filterField);
        this.filters = newFilters;
        this.currentFiltersSubject.next(currentFilters);
      }

      this.loadPage.emit({
        pageNumber: 1,
        sortOptions: this.currentSortOption,
        filters: this.currentFiltersSubject.getValue(),
        pageSize: this.pageSize,
        filtersChanged: true
      });
    });
  }

  /**
   * Generates and processes the columns to be rendered in the datatable.
   *
   * @param first - If it's the first time this is run
   */
  private async connectColumns(first?: boolean): Promise<void> {
    this.currentFiltersSubject.next([]);
    this.filters = {};
    this.currentSortOption = null;
    this.pageSize = undefined;

    this.columns = [];
    this.activeColumnsSubject.next([]);
    const initialActiveColumns: DatatableTableColumn[] = [];
    const config: LocalDataTableDto = await this.datatableColumnService.getDatatableConfig(
      this.pageOperation ?? this.entityType,
      this.componentId,
      this.datatableConfig,
      !this.enableViews
    );
    this.localDatatableConfig = config;

    this.columns.push(...this.prependColumns);
    initialActiveColumns.push(...this.prependColumns);

    this.dataColumns = this.datatableColumnService.addDataColumns(
      this.dataPreColumns,
      this.columnTypings,
      this.filterList,
      this.displayProp
    );
    this.optionalColumns = this.datatableColumnService.addOptionalColumns(
      this.optionalPreColumns,
      this.columnTypings,
      this.filterList,
      this.disableOptionalSorting
    );

    const dataAndOptionalColumns = [...this.dataColumns, ...this.optionalColumns];
    let activeDataAndOptionalColumns = [
      ...(await this.datatableColumnService.getActiveDataColumns(
        this.dataColumns,
        this.entityType,
        config,
        this.defaultColumns
      )),
      ...(await this.datatableColumnService.getActiveOptionalColumns(
        this.optionalColumns,
        this.entityType,
        config,
        this.defaultColumns
      ))
    ];

    if (!config) {
      activeDataAndOptionalColumns = sortBy(activeDataAndOptionalColumns, column =>
        this.defaultColumns.indexOf(column.prop?.toString()));
    }

    this.columns.push(...dataAndOptionalColumns);
    initialActiveColumns.push(...activeDataAndOptionalColumns);

    // Stereotype columns
    this.stereotypeColumns = this.datatableColumnService.addStereotypeColumns(
      this.stereotypePreColumns,
      this.filterList
    );
    this.columns.push(...this.stereotypeColumns);
    initialActiveColumns.push(...(await this.datatableColumnService.getActiveStereotypeColumns(
      this.stereotypeColumns,
      this.entityType,
      config
    )));

    this.columns.push(...this.appendColumns);
    initialActiveColumns.push(...this.appendColumns);

    this.activeColumnsSubject.next([...initialActiveColumns]);
    this.connectFilters(config, this.columns, first);

    this.onDatatableCreationDone(
      initialActiveColumns.map(x => this.mapColumnToDataTableColumn(x)),
      this.filters
    );
  }


  /* istanbul ignore next */
  private mapColumnToDataTableColumn(column: DatatableTableColumn): DataTableColumnDto {
    let columnType: DataTableColumnType;
    const sortObject: SortObject = this.currentSortOption;

    switch (column.option) {
      case DatatableTableColumnOption.STEREOTYPE:
        columnType = DataTableColumnType.ByCustomProperty;
        break;
      default:
        columnType = DataTableColumnType.ByTransfer;
        break;
    }

    if (columnType === DataTableColumnType.ByTransfer) {
      return {
        title: this.translate.instant(`core-shared.shared.fields.${DashCasePipe.transformString(column.name?.toString() ?? '')}`),
        property: column.prop,
        type: columnType,
        position: column.position,
        sortOrder: sortObject && sortObject.sortField === column.prop ? sortObject.sort : undefined
      } as DataTableColumnDto;
    } else {
      return {
        title: column.name,
        customPropertyId: column.customPropertyId,
        type: columnType,
        position: column.position,
        sortOrder: sortObject && sortObject.sortField === column.prop ? sortObject.sort : undefined
      } as DataTableColumnDto;
    }
  }

  private onDatatableCreationDone(columns: DataTableColumnDto[], filters: DataTableFilterDto[]): void {
    this.datatableCreated.emit({...this.datatableConfig, columns, filters});
  }

  private connectFilters(
    config: LocalDataTableDto,
    columns: DatatableTableColumn[],
    first?: boolean,
    savedFilters: Filter[] = null,
    savedSortObject: SortObject = null
  ): void {
    if (savedFilters) this.savedFilters = savedFilters;
    if (savedSortObject) this.savedSortObject = savedSortObject;

    const isSavedFilters = first && this.savedFilters?.length > 0;
    const newFilters = this.mapFilters(config, columns);
    const filters = isSavedFilters ? this.savedFilters : newFilters;

    for (let i = 0; i < filters.length; i++) {
      const filterField = filters[i];
      this.filters[filterField.property ?? i.toString()] = filterField;
    }

    if (config) {
      const defaultFilters = this.datatableFilterService.getCurrentFilters(this.defaultFilters);
      this.currentFiltersSubject.next(
        (!isNull(savedFilters) || !isNull(this.savedFilters)) && first ?
          (savedFilters ?? this.savedFilters) : [...defaultFilters, ...filters]
      );
      this.pageSize = config.pageSize;

      if (!isNull(savedSortObject) || !isNull(this.savedSortObject) && first) {
        this.currentSortOption = savedSortObject ?? this.savedSortObject;
      } else {
        const sortColumn = (config.columns ?? []).find(x => !isUndefined(x.sortOrder) && !isNull(x.sortOrder));
        let sortField;
        if (sortColumn) {
          if (this.datatableColumnService.isDataColumn(sortColumn)) {
            sortField = sortColumn.property;
          } else if (this.datatableColumnService.isStereotypeColumn(sortColumn)) {
            sortField = sortColumn.customPropertyId;
          }

          if (sortField) {
            this.currentSortOption = {
              sortField,
              sort: sortColumn.sortOrder
            };
          }
        }
      }

      for (const headerComponent of this.headerComponents) {
        headerComponent.updateSortIcon();
      }
    } else {
      const defaultFilters = this.datatableFilterService.getCurrentFilters(this.defaultFilters);
      this.currentFiltersSubject.next(
        (!isNull(savedFilters) || !isNull(this.savedFilters)) && first ? (savedFilters ?? this.savedFilters) : defaultFilters
      );
      this.currentSortOption = !isNull(savedSortObject) || !isNull(this.savedSortObject) && first
        ? (savedSortObject ?? this.savedSortObject) : this.currentSortOption;
    }
  }

  private mapFilters(config: LocalDataTableDto, columns: DatatableTableColumn[]): Array<Filter> {
    return (config?.filters ?? []).map(filterField => this.migrateFilter(mapDatatableFilterToFilter(filterField), columns));
  }

  /* istanbul ignore next */
  private migrateFilter(filterField: Filter, columns: DatatableTableColumn[]): Filter {
    const column = columns.find(x => {
      let columnProperty: string;

      switch (x.type) {
        case DatatableTableColumnType.REFERENCE:
          columnProperty = `${x.prop}.${x.idKey}`;
          break;
        default:
          columnProperty = x.option === DatatableTableColumnOption.STEREOTYPE ? x.customPropertyId?.toString() : x.prop.toString();
          break;
      }

      return columnProperty === filterField.property;
    });

    if (column && filterField.type !== FilterTypes.Grouped) {
      if (column.type === DatatableTableColumnType.REFERENCE || column.type === DatatableTableColumnType.PATH || column.type === DatatableTableColumnType.ENUM) {
        return {
          type: FilterTypes.Grouped,
          property: filterField.property,
          combinedAs: CombineOperator.Or,
          children: [filterField]
        } as Filter;
      }
    }
    return filterField;
  }

}
