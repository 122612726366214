import {NgModule} from '@angular/core';
import {templatesServices} from './store';
import {CoreSharedModule} from '@nexnox-web/core-shared';
import {CorePortalEntityModule} from '@nexnox-web/core-portal';
import {
  DocumentTemplateDetailComponent,
  DocumentTemplateListComponent,
  TextTemplateDetailComponent,
  TextTemplateListComponent
} from './containers';
import {TechPortalFeatureDocumentTemplateEditComponent, TechPortalFeatureTextTemplateEditComponent} from './components';
import {TextTemplatePreviewModalComponent} from './modals';
import {TechPortalFeatureTextTemplateApplySidebarComponent} from './sidebars';
import {
  FormDocumentTemplateDetailWrapperComponent,
  FormDocumentTemplateListWrapperComponent,
  IssueDocumentTemplateDetailWrapperComponent,
  IssueDocumentTemplateListWrapperComponent,
  IssueTextTemplateDetailWrapperComponent,
  IssueTextTemplateListWrapperComponent,
  MissionDocumentTemplateDetailWrapperComponent,
  MissionDocumentTemplateListWrapperComponent,
  MissionTextTemplateDetailWrapperComponent,
  MissionTextTemplateListWrapperComponent,
  NotificationTextTemplateDetailWrapperComponent,
  NotificationTextTemplateListWrapperComponent,
  SolutionTextTemplateDetailWrapperComponent,
  SolutionTextTemplateListWrapperComponent,
  TicketDocumentTemplateDetailWrapperComponent,
  TicketDocumentTemplateListWrapperComponent
} from "./wrappers";

const exportedSidebars = [
  TechPortalFeatureTextTemplateApplySidebarComponent
];

const wrappers = [
  SolutionTextTemplateListWrapperComponent,
  IssueTextTemplateListWrapperComponent,
  NotificationTextTemplateListWrapperComponent,
  TicketDocumentTemplateListWrapperComponent,
  IssueDocumentTemplateListWrapperComponent,
  MissionDocumentTemplateListWrapperComponent,
  MissionTextTemplateListWrapperComponent,
  FormDocumentTemplateListWrapperComponent,

  MissionTextTemplateDetailWrapperComponent,
  NotificationTextTemplateDetailWrapperComponent,
  SolutionTextTemplateDetailWrapperComponent,
  IssueTextTemplateDetailWrapperComponent,
  FormDocumentTemplateDetailWrapperComponent,
  TicketDocumentTemplateDetailWrapperComponent,
  IssueDocumentTemplateDetailWrapperComponent,
  MissionDocumentTemplateDetailWrapperComponent
]

@NgModule({
  declarations: [
    TextTemplateListComponent,
    TextTemplateDetailComponent,
    DocumentTemplateListComponent,
    DocumentTemplateDetailComponent,
    TechPortalFeatureTextTemplateEditComponent,
    TechPortalFeatureDocumentTemplateEditComponent,
    TextTemplatePreviewModalComponent,
    ...exportedSidebars,
    ...wrappers
  ],
  imports: [
    CoreSharedModule,
    CorePortalEntityModule
  ],
  exports: [
    TechPortalFeatureTextTemplateEditComponent,
    TechPortalFeatureDocumentTemplateEditComponent,
    ...exportedSidebars
  ],
  providers: [
    ...templatesServices
  ]
})
export class TemplatesDeclarationsModule {
}
