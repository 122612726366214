import { EntityXsStore, EntityXsStoreState } from '@nexnox-web/core-store';
import { AppEntityType, TicketByProcessDefinitionDto } from '@nexnox-web/core-shared';
import { createSelector } from '@ngrx/store';
import { selectProcessesState } from '../../processes.selectors';
import { TechPortalFeatureTicketSettingsProcessService } from '../../services';

export interface ProcessDetailStoreState extends EntityXsStoreState<TicketByProcessDefinitionDto> {
}

/* istanbul ignore next */
export const processDetailStore = new EntityXsStore<TicketByProcessDefinitionDto>({
  actionLabel: 'Tech Portal - Ticket Settings - Processes - Process Detail',
  stateSelector: createSelector(selectProcessesState, state => state.processDetail),
  serviceType: TechPortalFeatureTicketSettingsProcessService,
  entityType: AppEntityType.None,
  stereotyped: false
});
