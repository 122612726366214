import { NgModule } from '@angular/core';
import { CoreSharedModule, CoreSharedSortableListModule } from '@nexnox-web/core-shared';
import { prioritySetsServices } from './store';
import { CorePortalEntityModule } from '@nexnox-web/core-portal';
import { PrioritySetDetailComponent, PrioritySetListComponent } from './containers';
import { TechPortalFeatureTicketSettingsPrioritySetEditComponent } from './components';

@NgModule({
  declarations: [
    PrioritySetListComponent,
    PrioritySetDetailComponent,

    TechPortalFeatureTicketSettingsPrioritySetEditComponent
  ],
  imports: [
    CoreSharedModule,
    CoreSharedSortableListModule,
    CorePortalEntityModule
  ],
  exports: [
    TechPortalFeatureTicketSettingsPrioritySetEditComponent
  ],
  providers: [
    ...prioritySetsServices
  ]
})
export class PrioritySetsDeclarationsModule {
}
