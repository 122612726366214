import { TechPortalFeatureTicketSettingsStateMachineService } from './services';
import {
  StateMachineDetailStoreEffects,
  stateMachineDetailStoreReducer,
  StateMachineListStoreEffects,
  stateMachineListStoreReducer
} from './stores';

export * from './services';
export * from './stores';

export * from './state-machines.state';
export * from './state-machines.selectors';

export const stateMachinesReducers = {
  stateMachineList: stateMachineListStoreReducer,
  stateMachineDetail: stateMachineDetailStoreReducer
};

export const stateMachinesEffects = [
  StateMachineListStoreEffects,
  StateMachineDetailStoreEffects
];

export const stateMachinesServices = [
  TechPortalFeatureTicketSettingsStateMachineService
];
