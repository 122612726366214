import {EntityXsStore, EntityXsStoreState} from '@nexnox-web/core-store';
import {
  AppEntityType, IssueTemplateDto
} from '@nexnox-web/core-shared';
import {Action, createSelector} from '@ngrx/store';
import {selectIssueTemplateState} from '../../issue-template.selectors';
import {CorePortalIssueTemplateService} from '../../services';
import {Injectable, Injector} from '@angular/core';

export interface IssueTemplateDetailStoreState extends EntityXsStoreState<IssueTemplateDto> {
}

/* istanbul ignore next */
export const issueTemplateDetailStore = new EntityXsStore<IssueTemplateDto>({
  actionLabel: 'Core Portal - Settings - Issue templates - Issue Template Detail',
  stateSelector: createSelector(selectIssueTemplateState, state => state.issueTemplateDetail),
  serviceType: CorePortalIssueTemplateService,
  entityType: AppEntityType.IssueTemplate,
  stereotyped: false
});

export function issueTemplateDetailStoreReducer(state: IssueTemplateDetailStoreState, action: Action): any {
  return issueTemplateDetailStore.reducer(state, action);
}

@Injectable()
export class IssueTemplateDetailStoreEffects extends issueTemplateDetailStore.effects {
  constructor(
    protected injector: Injector
  ) {
    super(injector);
  }
}
