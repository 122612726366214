import {ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot} from '@angular/router';
import {Injectable} from '@angular/core';
import {CorePortalCurrentTenantService} from '../../services/current-tenant/current-tenant.service';
import {CoreSharedLocalStorageService, LocalStorageKeys} from '@nexnox-web/core-shared';
import {CorePortalCurrentModuleService} from '../../services/current-module/current-module.service';

@Injectable()
export class CorePortalTenantGuard implements CanActivate, CanActivateChild {
  constructor(
    private router: Router,
    private currentTenantService: CorePortalCurrentTenantService,
    private currentModuleService: CorePortalCurrentModuleService,
    private localStorageService: CoreSharedLocalStorageService
  ) {
  }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {

    if (route.paramMap.has('tenantDomain')) {

      // If user navigates to tenant
      const tenantDomain = route.paramMap.get('tenantDomain');
      const module = route.children[0] ? (route.children[0].data?.module ?? undefined) : undefined;

      this.currentTenantService.setTenantDomain(tenantDomain);
      this.currentModuleService.setModule(module);
      return true;

    } else if (this.localStorageService.get(LocalStorageKeys.LAST_TENANT_DOMAIN)) {

      // If user has last tenant
      const lastTenantDomain = this.localStorageService.get(LocalStorageKeys.LAST_TENANT_DOMAIN);
      this.currentTenantService.setTenantDomain(lastTenantDomain ?? null);

      this.router.navigate(['/tenant', lastTenantDomain]);
      return false;
    }

    // Default
    this.currentTenantService.setTenantDomain(null);
    return true;
  }


  public canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    this.currentModuleService.setModule(childRoute.data.module ?? this.currentModuleService.getModule());
    return true;
  }
}
