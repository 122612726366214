<div class="input-group">
  <div class="input-group-prepend">
    <div class="input-group-text color-preview"
         [class.is-disabled]="formControl.disabled"
         [style.background-color]="formControl.value ? formControl.value : 'white'"
         [popover]="colorPickerTemplate"
         [outsideClick]="true"
         placement="right"
         container="body"
         containerClass="popover-no-padding"
         triggers=""
         #popover="bs-popover"
         (click)="popover.show()">
    </div>
  </div>

  <input type="text"
         class="form-control"
         [class.is-invalid]="showError"
         [formControl]="formControl"
         [formlyAttributes]="field">
</div>

<ng-template #colorPickerTemplate>
  <color-sketch [color]="formControl.value ? formControl.value : ''"
                [disableAlpha]="true"
                (onChange)="onChange($event)">
  </color-sketch>
</ng-template>
