import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {NotificationTrigger, UnsubscribeHelper} from "@nexnox-web/core-shared";
import {BindObservable} from "bind-observable";
import {BehaviorSubject, Observable, pairwise, startWith} from "rxjs";
import {
  CorePortalFeatureNotificationRulesTriggerTypeRestrictionService
} from "@nexnox-web/core-portal/features/settings/features/notification-rules/src/lib/store";

@Component({
  selector: 'nexnox-web-settings-notification-rules-restriction',
  templateUrl: 'notification-rules-restriction-wrapper.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationRulesRestrictionWrapperComponent extends UnsubscribeHelper implements OnInit {

  @Input()
  public set trigger(trigger) {
    this.triggerSubject.next(trigger);
  }

  public get trigger(): any {
    return this.triggerSubject.getValue();
  }

  @Input()
  public set restriction(restriction) {
    this.restrictionSubject.next(restriction);
  };

  public get restriction(): any {
    return this.restrictionSubject.getValue();
  }

  @Input() public creating: boolean;
  @Input() public loading: boolean;
  @Input() @BindObservable() public readonly: boolean;
  public readonly$!: Observable<boolean>;

  @Output() public restrictionChanged = new EventEmitter<any>();

  public triggerSubject: BehaviorSubject<any> = new BehaviorSubject<any>(NotificationTrigger.ContractChangedState);
  public trigger$: Observable<any>;

  public restrictionSubject: BehaviorSubject<any> = new BehaviorSubject<any>({});
  public restriction$: Observable<any>;

  public isMissionRestriction: boolean;
  public isTicketRestriction: boolean;
  public isContractRestriction: boolean;
  public isInfoRestriction: boolean;

  constructor(
    public restrictionService: CorePortalFeatureNotificationRulesTriggerTypeRestrictionService
  ) {
    super();
  }

  public ngOnInit(): void {

    // init table
    this._detectCurrentTable(this.trigger);

    // init streams
    this.trigger$ = this.triggerSubject.asObservable().pipe(startWith(this.trigger));
    this.restriction$ = this.restrictionSubject.asObservable().pipe(startWith(this.restriction));

    // trigger change logic
    this.subscribe(this.trigger$.pipe(pairwise()), ([previousTrigger, currentTrigger]) => {
      this._detectCurrentTable(currentTrigger);
      if (previousTrigger !== currentTrigger && this.restrictionService.isDifferentRestriction(currentTrigger, this.restriction)) {
        this.restrictionSubject.next(null);
      }
    });
  }

  private _detectCurrentTable(trigger: NotificationTrigger): void {
    this.isMissionRestriction = this.restrictionService.isMissionRestriction(trigger);
    this.isTicketRestriction = this.restrictionService.isTicketRestriction(trigger);
    this.isContractRestriction = this.restrictionService.isContractRestriction(trigger);
    this.isInfoRestriction = this.restrictionService.isInfoRestriction(trigger);
  }
}
