import {ChangeDetectionStrategy, Component, Injector, Input, OnInit} from '@angular/core';
import {CorePortalEntityOverviewBaseComponent, DatatableActionButton, DeleteEntityModel} from '@nexnox-web/core-portal';
import {
  AppEntityType,
  AppPermissions,
  ControllerOperationId,
  Mappers,
  MissionDto,
  SolutionDto,
  StartingMissionDto
} from '@nexnox-web/core-shared';
import {ticketMissionsStore} from '../../store';
import {PagedEntitiesXsStoreGetPageParentPayload} from '@nexnox-web/core-store';
import {combineLatestWith, map} from 'rxjs/operators';
import {BehaviorSubject} from 'rxjs';
import {SolutionMissionService} from '../../store/services/solution-mission/solution-mission.service';
import {PRIMARY_OUTLET} from "@angular/router";

@Component({
  selector: 'nexnox-web-tickets-ticket-missions',
  templateUrl: './ticket-missions.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TicketMissionsComponent extends CorePortalEntityOverviewBaseComponent<MissionDto> implements OnInit {

  @Input()
  public set solution(solution: SolutionDto) {
    this.solutionSubject.next(solution);
  }

  public title = 'missions.subtitles.missions';
  public createTitle = 'missions.subtitles.mission-create';
  public idProperty = 'missionId';
  public displayProperty = 'title';
  public pageOperation = ControllerOperationId.MissionControllerList;
  public componentId = 'TicketMissionsComponent';
  public enableViews = true;
  public datatableConfigName = 'MissionDefault';

  private solutionSubject: BehaviorSubject<SolutionDto> = new BehaviorSubject<SolutionDto>(null);
  public previewModel$: BehaviorSubject<StartingMissionDto> = new BehaviorSubject<StartingMissionDto>({} as any);
  public previewModelLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  protected entityService: SolutionMissionService;

  constructor(
    protected injector: Injector
  ) {
    super(injector, ticketMissionsStore, Mappers.MissionCompleteDto.serializedName, AppEntityType.Mission);
  }

  /* istanbul ignore next */
  public ngOnInit(): void {
    super.ngOnInit();

    this.loading$ = this.loading$.pipe(
      combineLatestWith(this.previewModelLoading$),
      map((loadingArray) => loadingArray.some(l => l === true)),
      combineLatestWith(this.stereotypesLoaded$),
      map(([loading, stereotypesLoaded]) => stereotypesLoaded ? loading : loading && !stereotypesLoaded)
    );
  }

  /* istanbul ignore next */
  public getRowActionButtons(): DatatableActionButton[] {
    return this.getDefaultRowActionButtons(
      'missions.actions.edit-mission',
      (row: MissionDto) => `missions/all/${row.missionId}`,
      [AppPermissions.UpdateMission],
      {
        module: 'inventory'
      }
    );
  }

  public getDeleteEntityModel(): DeleteEntityModel {
    return {
      titleKey: 'missions.actions.delete-mission',
      descriptionKey: 'missions.descriptions.delete-mission',
      confirmKey: 'missions.actions.delete-mission',
      deletePermission: AppPermissions.DeleteMission
    };
  }

  public gotoStandaloneCreate(): void {
    const url = this.router.parseUrl(this.router.url).root.children[PRIMARY_OUTLET].segments.map((s) => s.path);
    this.router.navigate([...url, 'create'])
  }

  protected getParentObject(): PagedEntitiesXsStoreGetPageParentPayload {
    return {
      ...super.getParentObject(),
      parentIds: [this.solutionSubject.getValue()?.solutionId]
    };
  }
}
