import {ChangeDetectionStrategy, Component, Injector, Input, OnInit} from '@angular/core';
import {
  ActionButton,
  CorePortalEntityOverviewBaseComponent,
  DatatableActionButton,
  DeleteEntityModel
} from '@nexnox-web/core-portal';
import {
  AppEntityType,
  AppPermissions,
  ControllerOperationId,
  FilterOperators,
  FilterTypes,
  Mappers,
  ResourceDto
} from '@nexnox-web/core-shared';
import {
  resourceDetailStore,
  resourceEntitiesStore,
  resourceListStore,
  resourceTreeViewStore,
  resourceUIEntitiesStore
} from '../../store/stores';
import {
  InheritanceManufacturerModelService,
  InheritsSuggestionsPreviewService,
  ResourceInventoryNumberPreviewService,
  ResourcePreviewService
} from "../../store";
import {PagedEntitiesXsStoreCreateOneSuccessPayload} from "@nexnox-web/core-store";
import {filter} from "rxjs";
import {select} from "@ngrx/store";
import {take} from "rxjs/operators";

@Component({
  selector: 'nexnox-web-resources-resource-list',
  templateUrl: './resource-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TechPortalFeatureResourceListComponent extends CorePortalEntityOverviewBaseComponent<ResourceDto> implements OnInit {
  @Input() public parentResource: ResourceDto;
  @Input() public componentId = 'ResourceDetailListComponent';
  @Input() public allowMargin: boolean;
  @Input() public isEmbedded = false;
  @Input() public canCreate = true;

  public title = 'resources.subtitles.resource-list-all';
  public createTitle = 'resources.actions.create-resource';
  public idProperty = 'resourceId';
  public displayProperty = 'name';
  public pageOperation = ControllerOperationId.ResourceControllerList;
  public enableViews = true;
  public datatableConfigName = 'ResourceDefault';
  public isDashboardView = false;

  constructor(
    protected injector: Injector
  ) {
    super(injector, resourceListStore, Mappers.ResourceListDto.serializedName, AppEntityType.Resource);
  }

  public ngOnInit(): void {
    this.defaultFilter = [{
      property: 'isArchived',
      operator: FilterOperators.Equal,
      type: FilterTypes.DataTransferObject,
      value: 'false'
    }];

    if (this.parentResource) {
      this.defaultFilter.push({
        type: FilterTypes.DataTransferObject,
        operator: FilterOperators.Equal,
        property: 'parentId',
        value: this.route.snapshot.paramMap.get('resourceId').toString()
      });
      setTimeout(() => this.store.dispatch(resourceListStore.actions.getPage(undefined, undefined, this.defaultFilter)));
    }

    super.ngOnInit();

    this.monitors.add([
      {
        name: 'inventoryNumberPreview',
        keys: ['stereotypeId'],
        service: ResourceInventoryNumberPreviewService,
        payload: {
          stereotypes$: this.stereotypes$,
          entityType: AppEntityType.Resource,
          entityStore: resourceDetailStore
        }
      },
      {
        name: 'resourcePreview',
        keys: ['stereotypeId', 'parent'],
        service: ResourcePreviewService,
        payload: {
          stereotypes$: this.stereotypes$,
          entityType: AppEntityType.Resource,
          entityStore: resourceDetailStore
        }
      },
      {
        name: 'inheritanceManufacturerModelPreview',
        keys: ['inheritsManufacturer', 'inheritsModel'],
        service: InheritanceManufacturerModelService,
        payload: {
          entityType: AppEntityType.Resource,
        }
      },
      {
        name: 'inheritsSuggestionsPreview',
        keys: ['inheritsSuggestions'],
        service: InheritsSuggestionsPreviewService,
        payload: {
          entityType: AppEntityType.Resource,
        }
      },
    ]);

    // On create resource from resource execute monitors
    this.subscribe(this.isCreateVisible$.pipe(filter(isCreate => isCreate)), () => {
      setTimeout(() => {
        if (!!this.createModel$.getValue()?.parent) {
          this.monitors.trigger('resourcePreview').then(() => this.monitors.trigger('inventoryNumberPreview'));
        }
      })
    });

    if(this.isDashboardView === false) {
      this.subscribe(this.store.pipe(select(resourceTreeViewStore.selectors.selectIds), take(1)), ids => {
        if (ids?.length === 0) {
          this.store.dispatch(resourceTreeViewStore.actions.selectById({resourceId: null}))
        }
      });
    }
  }

  /* istanbul ignore next */
  public getRowActionButtons(): DatatableActionButton[] {
    return this.getDefaultRowActionButtons(
      'resources.actions.edit-resource',
      (resource: ResourceDto) => resource?.resourceId ? `/resources/${resource.resourceId}` : null,
      [AppPermissions.UpdateResource],
      {
        module: 'inventory'
      }
    );
  }

  public getDeleteEntityModel(): DeleteEntityModel {
    return {
      deletePermission: AppPermissions.DeleteResource,
      confirmKey: 'resources.actions.delete-resource',
      descriptionKey: 'resources.descriptions.delete-resource',
      titleKey: 'resources.actions.delete-resource'
    };
  }

  protected async getActionButtons(): Promise<ActionButton[]> {
    return this.getDefaultActionButtons('resources.actions.create-resource', AppPermissions.CreateResource);
  }

  // Refresh list and redirect to resource after create
  protected override onCreateOneSuccess(payload: PagedEntitiesXsStoreCreateOneSuccessPayload<ResourceDto, ResourceDto>): void {
    const resource = payload.entity;

    this.bypassMonitorsSubject.next(true);

    this.store.dispatch(resourceUIEntitiesStore.actions.upsertMany({
      items: [{
        ...resource,
        isInProgressSince: new Date().toUTCString()
      }]
    }));

    setTimeout(() => this.tenantRouter.navigate(['resources', resource.resourceId], {
      module: 'inventory'
    }));
  }

  protected override deleteEntity(entity: ResourceDto): void {
    super.deleteEntity(entity);
    this.store.dispatch(resourceEntitiesStore.actions.removeOne({resourceId: entity.resourceId}));
  }
}
