import {EntityXsStoreState} from '@nexnox-web/core-store';
import {AppEntityType, ResourceManufacturerDto} from '@nexnox-web/core-shared';
import {createSelector} from '@ngrx/store';
import {selectManufacturerState} from '../../manufacturer.selectors';
import {CorePortalManufacturerService} from '../../services';
import {ManufacturerDetailXsStore} from "./manufacturer-detail.xs-store";

export interface ManufacturerDetailStoreState extends EntityXsStoreState<ResourceManufacturerDto> {
}

/* istanbul ignore next */
export const manufacturerDetailStore = new ManufacturerDetailXsStore({
  actionLabel: 'Core Portal - Settings - Manufacturers - Manufacturer Detail',
  stateSelector: createSelector(selectManufacturerState, state => state.manufacturerDetail),
  serviceType: CorePortalManufacturerService,
  entityType: AppEntityType.None,
  stereotyped: false
});



