import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AppPermissions, I18nBreadcrumbResolver, UnsavedChangesGuard, UnsavedCreateGuard} from '@nexnox-web/core-shared';
import {CorePortalComponentConfigGuard, CorePortalPermissionGuard} from '@nexnox-web/core-portal';
import {
  MessageControlIssueDetailComponent,
  MessageIssueDetailComponent,
  MissionIssueDetailComponent,
  TechPortalFeatureIssueListComponent,
  TicketIssueDetailComponent,
  WinterhalterProductOrderIssueDetailComponent,
  WinterhalterServiceCallIssueDetailComponent
} from "./containers";

export const TECH_PORTAL_ISSUES_ROUTES: Routes = [
  {
    path: '',
    canActivate: [CorePortalPermissionGuard, CorePortalComponentConfigGuard],
    canDeactivate: [UnsavedCreateGuard],
    component: TechPortalFeatureIssueListComponent,
    data: {
      permissions: [AppPermissions.ReadIssue],
      tab: '',
      requiredComponentConfig: ['allowList']
    },
    children: [
      {
        path: 'message',
        canActivate: [CorePortalPermissionGuard, CorePortalComponentConfigGuard],
        canDeactivate: [UnsavedCreateGuard],
        data: {
          permissions: [AppPermissions.ReadMessageIssueContent],
          tab: 'message'
        },
        children: []
      },
      {
        path: 'message-control',
        canActivate: [CorePortalPermissionGuard, CorePortalComponentConfigGuard],
        canDeactivate: [UnsavedCreateGuard],
        data: {
          permissions: [AppPermissions.ReadMessageControlContent],
          tab: 'message-control'
        },
        children: []
      },
      {
        path: 'mission',
        canActivate: [CorePortalPermissionGuard, CorePortalComponentConfigGuard],
        canDeactivate: [UnsavedCreateGuard],
        data: {
          permissions: [AppPermissions.ReadMissionIssueContent],
          tab: 'mission'
        },
        children: []
      },
      {
        path: 'ticket',
        canActivate: [CorePortalPermissionGuard, CorePortalComponentConfigGuard],
        canDeactivate: [UnsavedCreateGuard],
        data: {
          permissions: [AppPermissions.ReadTicketIssueContent],
          tab: 'ticket'
        },
        children: []
      },
      {
        path: 'winterhalter-service-call',
        canActivate: [CorePortalPermissionGuard, CorePortalComponentConfigGuard],
        canDeactivate: [UnsavedCreateGuard],
        data: {
          permissions: [AppPermissions.ReadWinterhalterServiceIssueContent],
          tab: 'winterhalter-service-call'
        },
        children: []
      },
      {
        path: 'winterhalter-product-order',
        canActivate: [CorePortalPermissionGuard, CorePortalComponentConfigGuard],
        canDeactivate: [UnsavedCreateGuard],
        data: {
          permissions: [AppPermissions.ReadWinterhalterOrderIssueContent],
          tab: 'winterhalter-product-order'
        },
        children: []
      }
    ]
  },
  {
    path: 'message/:issueId',
    component: MessageIssueDetailComponent,
    canActivate: [CorePortalPermissionGuard],
    canDeactivate: [UnsavedChangesGuard],
    data: {
      permissions: [AppPermissions.ReadMessageIssueContent, AppPermissions.ReadStereotype],
      breadcrumbs: I18nBreadcrumbResolver,
      text: 'issues.subtitles.message-issue-detail'
    }
  },
  {
    path: 'message-control/:issueId',
    component: MessageControlIssueDetailComponent,
    canActivate: [CorePortalPermissionGuard],
    canDeactivate: [UnsavedChangesGuard],
    data: {
      permissions: [AppPermissions.ReadMessageControlContent, AppPermissions.ReadStereotype],
      breadcrumbs: I18nBreadcrumbResolver,
      text: 'issues.subtitles.message-control-issue-detail'
    }
  },
  {
    path: 'mission/:issueId',
    component: MissionIssueDetailComponent,
    canActivate: [CorePortalPermissionGuard],
    canDeactivate: [UnsavedChangesGuard],
    data: {
      permissions: [AppPermissions.ReadMissionIssueContent, AppPermissions.ReadStereotype],
      breadcrumbs: I18nBreadcrumbResolver,
      text: 'issues.subtitles.mission-issue-detail'
    }
  },
  {
    path: 'ticket/:issueId',
    component: TicketIssueDetailComponent,
    canActivate: [CorePortalPermissionGuard],
    canDeactivate: [UnsavedChangesGuard],
    data: {
      permissions: [AppPermissions.ReadTicketIssueContent, AppPermissions.ReadStereotype],
      breadcrumbs: I18nBreadcrumbResolver,
      text: 'issues.subtitles.ticket-issue-detail'
    }
  },
  {
    path: 'winterhalter-service-call/:issueId',
    component: WinterhalterServiceCallIssueDetailComponent,
    canActivate: [CorePortalPermissionGuard],
    canDeactivate: [UnsavedChangesGuard],
    data: {
      permissions: [AppPermissions.ReadWinterhalterServiceIssueContent, AppPermissions.ReadStereotype],
      breadcrumbs: I18nBreadcrumbResolver,
      text: 'issues.subtitles.winterhalter-service-call-issue-detail'
    }
  },
  {
    path: 'winterhalter-product-order/:issueId',
    component: WinterhalterProductOrderIssueDetailComponent,
    canActivate: [CorePortalPermissionGuard],
    canDeactivate: [UnsavedChangesGuard],
    data: {
      permissions: [AppPermissions.ReadWinterhalterOrderIssueContent, AppPermissions.ReadStereotype],
      breadcrumbs: I18nBreadcrumbResolver,
      text: 'issues.subtitles.winterhalter-product-order-issue-detail'
    }
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(TECH_PORTAL_ISSUES_ROUTES)
  ],
  exports: [
    RouterModule
  ]
})
export class IssuesRoutingModule {
}
