import {PagedEntitiesXsStore, PagedEntitiesXsStoreState} from '@nexnox-web/core-store';
import {AppEntityType, ContractDto} from '@nexnox-web/core-shared';
import {Action, createSelector} from '@ngrx/store';
import {Injectable, Injector} from '@angular/core';
import {TechPortalContractService} from "@nexnox-web/tech-portal-lib";
import {selectNotificationRulesState} from "../../notification-rules.selector";

export interface NotificationRulesContractsStoreState extends PagedEntitiesXsStoreState<ContractDto> {
}

/* istanbul ignore next */
export const notificationRulesContractsStore = new PagedEntitiesXsStore<ContractDto>({
  actionLabel: 'Tech Portal - Notification Rules - Notification Rule Contracts',
  stateSelector: createSelector(selectNotificationRulesState, state => state.notificationRulesContracts),
  entityType: AppEntityType.Contract,
  serviceType: TechPortalContractService,
  selectId: contract => contract.entity?.contractId ?? contract.model?.contractId
});

export function notificationRulesContractsStoreReducer(state: NotificationRulesContractsStoreState, action: Action): any {
  return notificationRulesContractsStore.reducer(state, action);
}

@Injectable()
export class NotificationRulesContractsStoreEffects extends notificationRulesContractsStore.effects {
  constructor(
    protected injector: Injector
  ) {
    super(injector);
  }
}
