import {ChangeDetectionStrategy, Component, Injector} from '@angular/core';
import {ActionButton, CorePortalEntityDetailBaseComponent} from '@nexnox-web/core-portal';
import {AppPermissions, ConsumptionCodeDto} from '@nexnox-web/core-shared';
import {consumptionCodeDetailStore} from '../../store/stores';

@Component({
  selector: 'nexnox-web-connected-consumption-codes-consumption-code-detail',
  templateUrl: './consumption-code-detail.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConsumptionCodeDetailComponent extends CorePortalEntityDetailBaseComponent<ConsumptionCodeDto> {
  public title = 'tech-portal.connected.consumption-codes.subtitles.detail';

  protected idParam = 'consumptionCodeId';
  protected displayKey = 'displayName';

  constructor(
    protected injector: Injector
  ) {
    super(injector, consumptionCodeDetailStore);
  }

  protected async getActionButtons(): Promise<ActionButton[]> {
    return this.getDefaultActionButtons(
      'tech-portal.connected.consumption-codes.actions.edit',
      'tech-portal.connected.consumption-codes.actions.save',
      'tech-portal.connected.consumption-codes.actions.delete',
      'tech-portal.connected.consumption-codes.descriptions.delete',
      AppPermissions.UpdateConsumptionCode,
      AppPermissions.DeleteConsumptionCode,
      undefined,
      undefined
    );
  }
}
