<!-- is revoked warning -->
<div *ngIf="(entity$  | async).isRevoked"
     class="alert alert-warning d-flex align-items-center justify-space-between">
  <fa-icon [icon]="faExclamationTriangle" [fixedWidth]="true" class="mr-2"></fa-icon>
  <div
    class="w-100 flex-grow-1">{{ 'core-portal.settings.invitations.descriptions.invitation-is-revoked' | translate }}
  </div>
</div>


<ng-template #entityEditTemplate
             let-title="title"
             let-id="id">
  <nexnox-web-settings-invitations-edit #modelComponent
                                        [title]="title"
                                        [id]="id"
                                        [model]="model$ | async"
                                        [readonly]="readonly$ | async"
                                        [creating]="false"
                                        [stereotyped]="false"
                                        [loading]="loading$ | async"
                                        (modelChange)="onModelChange($event)">
  </nexnox-web-settings-invitations-edit>
</ng-template>

<nexnox-web-entity-detail [entityDetailBaseComponent]="this"
                          [entityEditTemplate]="entityEditTemplate"
                          tabId="invitations"
                          [tabHeading]="title | translate"
                          [tabCommands]="['.']">

  <!-- Log Entries Tab -->
  <ng-template nexnoxWebTabTemplate
               tabId="contacts"
               *ngIf="model$ | async"
               [heading]="'core-portal.settings.invitations.titles.contact-list' | translate"
               [lazy]="true"
               (selectTab)="onNavigateToTab(['contacts'])">
    <nexnox-web-settings-invitations-contact-list *ngIf="passedIds"
                                                  [isEmbedded]="true"
                                                  [parentIds]="passedIds">
    </nexnox-web-settings-invitations-contact-list>
  </ng-template>
</nexnox-web-entity-detail>
