<ng-template #entityEditTemplate let-title="title">
  <nexnox-web-ticket-settings-priority-sets-priority-set-edit #editComponent
                                                              [title]="title"
                                                              [model]="createModel$ | async"
                                                              [readonly]="false"
                                                              [creating]="true"
                                                              [loading]="(loaded$ | async) === false"
                                                              (modelChange)="createModel$.next($event)">
  </nexnox-web-ticket-settings-priority-sets-priority-set-edit>
</ng-template>

<nexnox-web-entity-overview #overviewComponent
                            [entityOverviewBaseComponent]="this"
                            [entityEditTemplate]="entityEditTemplate"
                            detailLink="/priority-sets"
                            module="settings"
                            [showSettings]="false">
</nexnox-web-entity-overview>
