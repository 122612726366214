<ng-template #entityEditTemplate>
  <ng-container *ngIf="isCreate$">
    <nexnox-web-master-data-addresses-address-edit #modelComponent
                                                   [title]="title | translate"
                                                   [model]="model$ | async"
                                                   [creating]="isCreate$ | async"
                                                   [readonly]="readonly$ | async"
                                                   [loading]="loading$ | async"
                                                   [stereotypes$]="stereotypes$"
                                                   [stereotyped]="true"
                                                   (modelChange)="onModelChange($event)">
    </nexnox-web-master-data-addresses-address-edit>
  </ng-container>
</ng-template>

<nexnox-web-entity-detail [entityDetailBaseComponent]="this"
                          [useTabs]="false"
                          [entityEditTemplate]="entityEditTemplate">
</nexnox-web-entity-detail>

