import {TicketByProcessDefinitionKind} from "@nexnox-web/core-shared";

export const ticketProcessDefinitionKindEnumOptions = [
  { label: 'core-portal.settings.ticket-by-process-definition-kinds.0', value: TicketByProcessDefinitionKind.Default }
];

export const ticketProcessDefinitionReadonlyKindEnumOptions = [
  { label: 'core-portal.settings.ticket-by-process-definition-kinds.0', value: TicketByProcessDefinitionKind.Default },
  { label: 'core-portal.settings.ticket-by-process-definition-kinds.1', value: TicketByProcessDefinitionKind.Linkable }
];
