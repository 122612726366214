import { RouterModule, Routes } from '@angular/router';
import { ErrorCodeDetailComponent, ErrorCodeListComponent } from './containers';
import { NgModule } from '@angular/core';
import { I18nBreadcrumbResolver, UnsavedChangesGuard } from '@nexnox-web/core-shared';

export const TECH_PORTAL_ERROR_CODES_ROUTES: Routes = [
  {
    path: '',
    component: ErrorCodeListComponent
  },
  {
    path: ':errorCodeId',
    component: ErrorCodeDetailComponent,
    canDeactivate: [UnsavedChangesGuard],
    data: {
      breadcrumbs: I18nBreadcrumbResolver,
      tab: 'errorCode',
      text: 'tech-portal.connected.error-codes.subtitles.detail'
    }
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(TECH_PORTAL_ERROR_CODES_ROUTES)
  ],
  exports: [
    RouterModule
  ]
})
export class ErrorCodesRoutingModule {
}
