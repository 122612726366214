import { DevicePingListXsStore, DevicePingListXsStoreState } from './device-ping-list.xs-store';
import { createSelector } from '@ngrx/store';
import { selectDevicesState } from '../../devices.selectors';
import { OrgaPortalFeaturePingService } from '../../services';
import { AppEntityType } from '@nexnox-web/core-shared';

export interface DevicePingListStoreState extends DevicePingListXsStoreState {
}

/* istanbul ignore next */
export const devicePingListStore = new DevicePingListXsStore({
  actionLabel: 'Orga Portal - Devices - Device Ping List',
  stateSelector: createSelector(selectDevicesState, state => state.devicePingList),
  serviceType: OrgaPortalFeaturePingService,
  entityType: AppEntityType.None,
  selectId: ping => ping.entity?.pingId ?? ping.model?.pingId,
  stereotyped: false
});
