import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CorePortalFeatureAuditListBaseComponent } from '../audit-list-base/audit-list-base.component';

@Component({
  selector: 'nexnox-web-audit-audit-list',
  templateUrl: './audit-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CorePortalFeatureAuditListComponent {
  @Input() public base: CorePortalFeatureAuditListBaseComponent;
  @Input() public isEmbedded: boolean;
}
