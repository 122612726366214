import {
  BaseXsStoreReducerTypes,
  PagedEntitiesXsStore,
  PagedEntitiesXsStoreActions,
  PagedEntitiesXsStoreEffects,
  PagedEntitiesXsStoreState
} from '@nexnox-web/core-store';
import {AppEntityType, IssueListDto} from '@nexnox-web/core-shared';
import {Action} from '@ngrx/store';
import {Injectable, Injector, Type} from '@angular/core';
import {TechPortalIssueTemplateService} from "@nexnox-web/tech-portal-lib";

export interface IssueListXsStoreActions extends PagedEntitiesXsStoreActions<IssueListDto> {
}

export class IssueListXsStore extends PagedEntitiesXsStore<IssueListDto> {
  public declare actions: IssueListXsStoreActions;

  protected createActions(label: string): IssueListXsStoreActions {
    return {
      ...super.createActions(label)
    };
  }

  protected createReducerArray(
    initialState: PagedEntitiesXsStoreState<IssueListDto>
  ): BaseXsStoreReducerTypes<PagedEntitiesXsStoreState<IssueListDto>, IssueListXsStoreActions>[] {
    return [
      ...super.createReducerArray(initialState)
    ];
  }

  protected createEffects(
    serviceType: Type<TechPortalIssueTemplateService>,
    entityType: AppEntityType,
    prepareEntity: (entity: IssueListDto) => IssueListDto,
    prepareModel: (entity: IssueListDto, model: IssueListDto) => IssueListDto,
    sanitizeModel: (model: IssueListDto, entity: IssueListDto) => IssueListDto,
    ...args
  ): Type<PagedEntitiesXsStoreEffects<IssueListDto>> {
    const actions = this.actions;
    const selectors = this.selectors;

    @Injectable()
    class Effects extends PagedEntitiesXsStoreEffects<IssueListDto> {
      protected declare service: TechPortalIssueTemplateService;

      constructor(
        protected injector: Injector
      ) {
        super(injector, actions, selectors, serviceType, entityType, prepareEntity, prepareModel, sanitizeModel, true);
      }

      protected createEffects(): void {
        super.createEffects();
      }

      protected actionCallback(action: Action, isError: boolean = false): void {
        super.actionCallback(action, isError);
      }
    }

    return Effects;
  }
}
