<ng-template #entityEditTemplate
             let-title="title">
  <nexnox-web-settings-issue-template-edit #editComponent
                                           [title]="title"
                                           [model]="createModel$ | async"
                                           [readonly]="readonly"
                                           [creating]="!readonly"
                                           [stereotyped]="false"
                                           [loading]="(loaded$ | async) === false"
                                           [issueContentType]="createIssueContentType | async"
                                           (modelChange)="createModel$.next($event)">
  </nexnox-web-settings-issue-template-edit>
</ng-template>

<nexnox-web-entity-overview #overviewComponent
                            [entityOverviewBaseComponent]="this"
                            [entityEditTemplate]="entityEditTemplate"
                            [stereotyped]="false"
                            detailLink="issues"
                            module="settings">
</nexnox-web-entity-overview>
