import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {CorePortalCardboxComponent} from '../../../../cardbox';
import {CorePortalEntityEditBaseComponent} from '../../base-components';
import {CorePortalCustomPropertySetsEditComponent} from '../';
import {IconDefinition, SizeProp} from '@fortawesome/fontawesome-svg-core';
import {FormlyFieldConfig} from '@ngx-formly/core';
import {NEVER} from "rxjs";
import {filter} from "rxjs/operators";
import {UnsubscribeHelper} from "@nexnox-web/core-shared";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'nexnox-web-entity-edit',
  templateUrl: './entity-edit.component.html'
})
export class CorePortalEntityEditComponent extends UnsubscribeHelper implements OnInit {

  /**
   * The {@link CorePortalEntityEditBaseComponent} required for this component to function properly.
   */
  @Input() public entityEditBaseComponent: CorePortalEntityEditBaseComponent<any>;

  /**
   * If enabled, will not show the configured form, just ng-content.
   *
   * @default false
   */
  @Input() public noForm = false;

  /**
   * If enabled, will not show the cardbox, just the form/ng-content.
   *
   * @default false
   */
  @Input() public noCardbox = false;

  /**
   * If enabled, removes the margin bottom from the cardbox.
   *
   * @default false
   */
  @Input() public noMarginBottom = false;

  /**
   * If enabled, removes the margin from the last custom property.
   *
   * @default false
   */
  @Input() public noMarginForLastCustomProperty = false;

  /**
   * Whether the entity is stereotyped or not.
   *
   * @default true
   */
  @Input() public stereotyped = true;

  /**
   * Whether inheritance should be enabled or not. Allows the use of a parentId for example.
   *
   * @default false
   */
  @Input() public inheritance = false;

  /**
   * If enabled, uses {@link customTitle} instead of the entity display key.
   *
   * @default false
   */
  @Input() public useTitle = false;

  /**
   * Whether the title is editable in the cardbox or not.
   */
  @Input() public isTitleEditable = true;

  /**
   * Use custom title fields instead of just the title.
   */
  @Input() public titleFieldFn: (base: FormlyFieldConfig) => FormlyFieldConfig[];

  /**
   * The custom title that should be used if {@link useTitle} is enabled, there is no {@link displayKey} or the entity is being created.
   */
  @Input() public customTitle: string;

  /**
   * The display key of the entity used to display the title. The title of the base component will be used if not defined.
   */
  @Input() public displayKey: string;

  /**
   * An optional icon for the cardbox.
   */
  @Input() public icon: IconDefinition;

  /**
   * The {@link icon} color.
   */
  @Input() public iconColor: string;

  /**
   * The {@link icon} size.
   */
  @Input() public iconSize: SizeProp;

  /**
   * If enabled, will hide the accent colour on the left of the cardbox.
   *
   * @default false
   */
  @Input() public hideAccent = false;

  /**
   * A custom accent colour for the cardbox.
   */
  @Input() public customAccent: string;

  /**
   * If enabled, the cardbox will be display transparently.
   *
   * @default false
   */
  @Input() public isTransparent = false;

  /**
   * If enabled, won't show any header or footer actions.
   *
   * @default false
   */
  @Input() public disableActions: boolean;

  /**
   * If enabled, the cardbox is collapsible.
   *
   * @default false
   */
  @Input() public isAccordion = false;

  /**
   * If enabled, the cardbox will be collapsed by default.
   *
   * @default false
   */
  @Input() public isCollapsed = false;

  @Input() public tabsComponent;

  @ViewChild('cardboxComponent') public cardboxComponent: CorePortalCardboxComponent;
  @ViewChild('customPropertySetsEditComponent') public customPropertySetsEditComponent: CorePortalCustomPropertySetsEditComponent;

  constructor(private route: ActivatedRoute) {
    super();
  }

  public ngOnInit(): void {
    this.subscribe((this.route?.firstChild?.data ?? NEVER).pipe(
        filter(data => Boolean(data?.tab)),
      ), data => {
        setTimeout(() => this.tabsComponent?.activateTab(data.tab));
      }
    );
  }

  public onModelChange(model: any, fromForm: boolean = false): void {
    this.entityEditBaseComponent?.modelValidSubject?.next({
      ...this.entityEditBaseComponent.modelValidSubject.getValue(),
      ownModel: this.entityEditBaseComponent?.isOwnModelValid ? this.entityEditBaseComponent.isOwnModelValid() : true,
      customPropertySets: this.customPropertySetsEditComponent ? this.customPropertySetsEditComponent.isModelValid() : true
    });

    if (fromForm) {
      delete model.customPropertyValues;
    }

    this.entityEditBaseComponent?.onModelChange(model);
  }

  public onTitleChange(model: any): void {
    this.entityEditBaseComponent?.modelValidSubject?.next({
      ...this.entityEditBaseComponent.modelValidSubject.getValue(),
      title: this.cardboxComponent?.titleForm ? this.cardboxComponent.titleForm.valid : false
    });
    this.onModelChange({...this.entityEditBaseComponent.model, ...model});
  }

  public onCustomPropertyValidChange(): void {
    this.entityEditBaseComponent?.modelValidSubject.next({
      ...this.entityEditBaseComponent.modelValidSubject.getValue(),
      customPropertySets: this.customPropertySetsEditComponent?.isModelValid()
    });
  }
}
