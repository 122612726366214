import { Directive, EventEmitter, Injector, Input, Output, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { CorePortalCardboxAction, CorePortalEntityEditBaseComponent, ModelValid } from '@nexnox-web/core-portal';

@Directive()
export abstract class CorePortalFeatureStereotypeSkeletonWrapperBaseComponent implements ModelValid {
  @Input() public readonly$: Observable<boolean>;
  @Input() public creating: boolean;
  @Input() public model$: Observable<any>;
  @Input() public headerActions: CorePortalCardboxAction[] = [];

  @ViewChild('modelComponent', { static: true }) public modelComponent: CorePortalEntityEditBaseComponent<any>;

  @Output() public modelChange: EventEmitter<any> = new EventEmitter<any>();

  protected constructor(
    protected injector: Injector
  ) {
  }

  public isOwnModelValid(): boolean {
    return this.modelComponent.isOwnModelValid();
  }

  public isModelValid(): boolean {
    return this.modelComponent.isModelValid();
  }
}
