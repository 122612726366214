import { EntityXsStore, EntityXsStoreState } from '@nexnox-web/core-store';
import { AppEntityType, ResourceTaskDto } from '@nexnox-web/core-shared';
import { Action, createSelector } from '@ngrx/store';
import { selectResourceTasksState } from '../../resource-tasks.selector';
import { TechPortalFeatureResourceTaskService } from '../../services';
import { Injectable, Injector } from '@angular/core';

export interface ResourceTaskDetailStoreState extends EntityXsStoreState<ResourceTaskDto> {
}

export const resourceTaskDetailStore = new EntityXsStore<ResourceTaskDto>({
  actionLabel: 'Tech Portal - Resource Tasks - Resource Task Detail',
  stateSelector: createSelector(selectResourceTasksState, state => state.resourceTaskDetail),
  serviceType: TechPortalFeatureResourceTaskService,
  entityType: AppEntityType.None,
  stereotyped: false
});

export function resourceTaskDetailStoreReducer(state: ResourceTaskDetailStoreState, action: Action): any {
  return resourceTaskDetailStore.reducer(state, action);
}

@Injectable()
export class ResourceTaskDetailStoreEffects extends resourceTaskDetailStore.effects {
  constructor(
    protected injector: Injector
  ) {
    super(injector);
  }
}
