import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {
  CORE_PORTAL_FEATURE_MODULES,
  CorePortalCurrentModuleService,
  CorePortalPermissionService,
  CorePortalTenantRouter
} from '@nexnox-web/core-portal';
import {combineLatest, Observable, of} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';
import {faTh} from '@fortawesome/free-solid-svg-icons/faTh';
import {OverlayPanel} from 'primeng/overlaypanel';
import {CorePortalFeatureModule} from '../../../../model';

@Component({
  selector: 'nexnox-web-layout-module-select',
  templateUrl: './module-select.component.html',
  styleUrls: ['./module-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModuleSelectComponent implements OnInit {
  public modules$: Observable<CorePortalFeatureModule[]>;
  public currentModule$: Observable<CorePortalFeatureModule>;

  public faTh = faTh;

  constructor(
    private currentModuleService: CorePortalCurrentModuleService,
    private tenantRouter: CorePortalTenantRouter,
    private permissionService: CorePortalPermissionService
  ) {
    this.modules$ = combineLatest(CORE_PORTAL_FEATURE_MODULES.map(module => of(module).pipe(
      switchMap(module => (module.permissions ? this.permissionService.hasPermissions$(module.permissions) : of(true)).pipe(
        map(hasPermission => ({ module, hasPermission }))
      )),
      map(({ module, hasPermission }) => ({ ...module, hide: !hasPermission }))
    )));
  }

  public ngOnInit(): void {
    this.currentModule$ = this.currentModuleService.currentModuleSubject.asObservable().pipe(
      switchMap(currentModule => this.modules$.pipe(
        map(modules => modules.find(x => x.path === currentModule))
      ))
    );
  }

  public onSelectModule(module: CorePortalFeatureModule, modulePanel: OverlayPanel, event: any): void {
    if (module.target) {
      window.open(module.path, module.target);
    }

    setTimeout(() => modulePanel.onCloseClick(event));
  }
}
