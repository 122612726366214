import {ChangeDetectionStrategy, Component, Injector} from '@angular/core';
import {ActionButton, CorePortalEntityDetailBaseComponent} from '@nexnox-web/core-portal';
import {AppPermissions, ExportType, InventoryNumberPatternDto} from '@nexnox-web/core-shared';
import {inventoryNumberDetailStore} from '../../store/stores';

@Component({
  selector: 'nexnox-web-settings-inventory-number-patterns-inventory-number-pattern-detail',
  templateUrl: './inventory-number-pattern-detail.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InventoryNumberPatternDetailComponent extends CorePortalEntityDetailBaseComponent<InventoryNumberPatternDto> {
  public title = 'core-portal.settings.subtitles.inventory-number-pattern-detail';

  protected idParam = 'inventoryNumberPatternId';
  protected displayKey = 'title';

  constructor(
    protected injector: Injector
  ) {
    super(injector, inventoryNumberDetailStore);
  }

  protected async getActionButtons(): Promise<ActionButton[]> {
    return this.getDefaultActionButtons(
      'core-portal.settings.actions.edit-inventory-number-pattern',
      'core-portal.settings.actions.save-inventory-number-pattern',
      'core-portal.settings.actions.delete-inventory-number-pattern',
      'core-portal.settings.descriptions.delete-inventory-number-pattern',
      AppPermissions.UpdateInventoryNumberPattern,
      AppPermissions.DeleteInventoryNumberPattern,
      undefined,
      undefined,
      undefined,
      ExportType.INVENTORY_NUMBER_PATTERN
    );
  }
}
