import { Component, ContentChild, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { IconDefinition, IconProp } from '@fortawesome/fontawesome-svg-core';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons/faChevronDown';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons/faChevronUp';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { BehaviorSubject, Observable, tap } from 'rxjs';
import { CorePortalCardboxAction } from '../cardbox-actions';

@Component({
  selector: 'nexnox-web-cardbox',
  templateUrl: './cardbox.component.html',
  styleUrls: ['./cardbox.component.scss']
})
export class CorePortalCardboxComponent implements OnInit {
  @Input() public isTitleEditable = false;
  @Input() public icon: IconDefinition;
  @Input() public iconColor: string;
  @Input() public iconSize: IconProp;
  @Input() public loading = false;
  @Input() public classes = '';
  @Input() public prependTitle: string;

  @Input() public headerActions: CorePortalCardboxAction[];
  @Input() public footerActions: CorePortalCardboxAction[];
  @Input() public actionsData: any;

  @Input() public noMarginBottom = false;
  @Input() public shadowMarginBottom = false;
  @Input() public noDividerMarginBottom = false;
  @Input() public fullWidth = false;
  @Input() public fullHeight = false;
  @Input() public paddingClasses: string;
  @Input() public ghostPaddingClasses: string;
  @Input() public overflowAuto = false;
  @Input() public noTitle = false;
  @Input() public withAccent = false;
  @Input() public customAccent: string;
  @Input() public transparent = false;
  @Input() public showBadge: boolean;
  @Input() public badgeTitle: string;
  @Input() public badgeStyleClasses = 'bg-primary';
  @Input() public title: string;
  @Input() public titleKey: string;
  @Input() public titleFieldFn: (base: FormlyFieldConfig) => FormlyFieldConfig[];
  @Input() public wrapTitle = false;
  @Input() public alignTitleStart = false;

  @Input() public isAccordion = false;
  @Input() public isCollapsed = false;
  @Input() public isAutoExpandOnIndicator = false;
  public indicatorSubject: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public indicator$: Observable<number>;
  public faChevronUp = faChevronUp;
  public faChevronDown = faChevronDown;

  public get indicatorNum(): number {
    return this.indicatorSubject.getValue();
  }

  @Input()
  public set indicatorNum(value: number) {
    this.indicatorSubject.next(value);
  }

  public get titleModel(): any {
    return this._titleModel;
  }

  @Input()
  public set titleModel(titleModel: any) {
    if (!titleModel) {
      return;
    }

    this._titleModel = {
      ...this._titleModel,
      ...titleModel
    };
  }

  @Output() public titleChange: EventEmitter<any> = new EventEmitter<any>();

  @ContentChild('appendTitleTemplate') public appendTitleTemplate: TemplateRef<any>;

  public titleForm: FormGroup;
  public titleFields: FormlyFieldConfig[];

  private _titleModel: any = {};

  public ngOnInit(): void {
    this.titleForm = new FormGroup({});
    this.titleFields = this.getTitleFields(this.createDefaultTitleField());

    this.indicator$ = this.indicatorSubject.asObservable().pipe(
      tap(value => this.expandOnIndicator(value)
      ));
  }

  public onTitleChange(model: any): void {
    this.titleModel = model;
    this.titleChange.emit(this.titleModel);
  }

  public toggleCollapsed(): void {
    if (this.isAccordion) {
      this.isCollapsed = !this.isCollapsed;
    }
  }

  private expandOnIndicator(indicator: number): void {
    if (this.isAccordion && this.isAutoExpandOnIndicator && indicator > 0) {
      this.isCollapsed = false;
    }
  }

  private getTitleFields(base): FormlyFieldConfig[] {
    return this.titleFieldFn ? this.titleFieldFn(base) : [base];
  }

  private createDefaultTitleField(): FormlyFieldConfig {
    return {
      key: this.titleKey,
      type: 'input',
      wrappers: ['core-portal-translated'],
      className: 'col-md-12 title-input',
      templateOptions: {
        corePortalTranslated: {
          hideLabel: true,
          formGroupClassName: 'mb-0'
        },
        required: true
      }
    };
  }
}
