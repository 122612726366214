import { PagedEntitiesXsStore, PagedEntitiesXsStoreState } from '@nexnox-web/core-store';
import { AppEntityType, LocationDto } from '@nexnox-web/core-shared';
import { Action, createSelector } from '@ngrx/store';
import { selectLocationGroupsState } from '../../location-groups.selectors';
import { LocationGroupLocationService } from '../../services';
import { Injectable, Injector } from '@angular/core';

export interface LocationGroupLocationListStoreState extends PagedEntitiesXsStoreState<LocationDto> {
}

/* istanbul ignore next */
export const locationGroupLocationListStore = new PagedEntitiesXsStore<LocationDto>({
  actionLabel: 'Core Portal - Master Data - Location Group - Location List',
  stateSelector: createSelector(selectLocationGroupsState, state => state.locationGroupLocationList),
  serviceType: LocationGroupLocationService,
  entityType: AppEntityType.Location,
  selectId: location => location.entity?.locationId ?? location.model?.locationId
});

export function locationGroupLocationListStoreReducer(state: LocationGroupLocationListStoreState, action: Action): any {
  return locationGroupLocationListStore.reducer(state, action);
}

@Injectable()
export class LocationGroupLocationListStoreEffects extends locationGroupLocationListStore.effects {
  constructor(
    protected injector: Injector
  ) {
    super(injector);
  }
}
