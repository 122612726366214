<div class="alert alert-warning" *ngIf="entityDetailBaseComponent.isArchived$ | async">{{ 'core-shared.shared.detail.is-archived' | translate }}</div>
<div class="min-h-100"
     [class.overflow-hidden]="hideOverflow">
  <ng-content></ng-content>

  <nexnox-web-tabs *ngIf="useTabs; else noTabs"
                   #tabsComponent
                   [isTransparent]="true"
                   [ready$]="ready$">
    <!-- Entity Tab -->
    <ng-template nexnoxWebTabTemplate
                 [tabId]="tabId"
                 [heading]="tabHeading"
                 [hasError]="entityDetailBaseComponent ? (entityDetailBaseComponent.isOwnModelValid$ | async) === false : false"
                 (selectTab)="onNavigateToTab(tabCommands)">
      <ng-container *ngTemplateOutlet="entityEditTemplate; context: { title: tabHeading, id: entityDetailBaseComponent.id }">
      </ng-container>
    </ng-template>

    <!-- Other Tabs -->
    <ng-template *ngFor="let tabTemplate of tabTemplates"
                 nexnoxWebTabTemplate
                 [tabId]="tabTemplate.tabId"
                 [heading]="tabTemplate.heading"
                 [hasError]="tabTemplate.hasError"
                 [lazy]="tabTemplate.lazy"
                 [hide]="tabTemplate.hide"
                 (selectTab)="tabTemplate.selectTab.emit()">
      <ng-container *ngIf="tabTemplate.template" [ngTemplateOutlet]="tabTemplate.template"></ng-container>
    </ng-template>
  </nexnox-web-tabs>
</div>

<ng-template #noTabs>
  <ng-container *ngTemplateOutlet="entityEditTemplate; context: { title: tabHeading, id: entityDetailBaseComponent.id }">
  </ng-container>
</ng-template>
