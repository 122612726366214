import {RouterModule, Routes} from '@angular/router';
import {NgModule} from '@angular/core';
import {
  CompanyDetailComponent,
  CorePortalFeatureMasterDataCompanyListComponent,
  CompanyAddressDetailStandaloneComponent
} from './containers';
import {
  AppPermissions, I18nEntityBreadcrumbConfig,
  I18nBreadcrumbResolver,
  I18nEntityBreadcrumbResolver,
  UnsavedChangesGuard, UnsavedCreateGuard
} from '@nexnox-web/core-shared';
import {CorePortalPermissionGuard} from '@nexnox-web/core-portal';
import {
  companyDetailStore
} from "./store";
import {
  CompanyAddressBreadcrumbsResolver
} from "./resolvers";

export const CORE_PORTAL_MASTER_DATA_COMPANIES_ROUTES: Routes = [
  {
    path: '',
    canActivate: [CorePortalPermissionGuard],
    canDeactivate: [UnsavedCreateGuard],
    component: CorePortalFeatureMasterDataCompanyListComponent,
    data: {
      permissions: [AppPermissions.DisplayCompanies]
    }
  },
  {
    path: ':companyId',
    component: CompanyDetailComponent,
    canActivate: [CorePortalPermissionGuard],
    canDeactivate: [UnsavedChangesGuard],
    data: {
      breadcrumbs: I18nEntityBreadcrumbResolver,
      entityStore: companyDetailStore,
      idProperty: 'companyId',
      displayProperty: 'name',
      tab: 'company',
      text: 'core-portal.core.header.breadcrumbs.master-data.company',
      permissions: [AppPermissions.ReadCompany, AppPermissions.ReadStereotype]
    } as I18nEntityBreadcrumbConfig,
    children: [
      {
        path: 'contacts',
        data: {
          tab: 'contacts'
        },
        children: []
      },
      {
        path: 'addresses',
        data: {
          tab: 'addresses',
          breadcrumbs: I18nBreadcrumbResolver,
          text: 'core-portal.core.header.breadcrumbs.master-data.addresses'
        },
        children: []
      }
    ]
  },

  {
    path: ':companyId/addresses/create',
    canDeactivate: [UnsavedChangesGuard],
    component: CompanyAddressDetailStandaloneComponent,
    data: {
      breadcrumbs: CompanyAddressBreadcrumbsResolver,
      permissions: [AppPermissions.CreateAddress]
    }
  },

  {
    path: ':companyId/addresses/:addressId',
    canDeactivate: [UnsavedChangesGuard],
    component: CompanyAddressDetailStandaloneComponent,
    data: {
      breadcrumbs: CompanyAddressBreadcrumbsResolver,
      permissions: [AppPermissions.UpdateAddress]
    }
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(CORE_PORTAL_MASTER_DATA_COMPANIES_ROUTES)
  ],
  exports: [
    RouterModule
  ]
})
export class CompaniesRoutingModule {
}
