import {
  BaseXsStore,
  BaseXsStoreReducerTypes,
  EntityXsStore,
  EntityXsStoreActions,
  EntityXsStoreSavePayload,
  EntityXsStoreSaveSuccessPayload,
  EntityXsStoreState,
  PropsAction
} from '@nexnox-web/core-store';
import { MissionReportDto } from '@nexnox-web/core-shared';
import { createAction, on, props } from '@ngrx/store';
import { cloneDeep } from 'lodash';
import { immerOn } from 'ngrx-immer/store';

export interface MissionReportXsStoreState extends EntityXsStoreState<MissionReportDto> {
}

export interface MissionReportXsStoreCreatePayload {
  missionId: number;
  model: MissionReportDto;
}

export interface MissionReportXsStoreCreateSuccessPayload {
  entity: MissionReportDto;
  model: MissionReportDto;
}

export interface MissionReportXsStoreActions extends EntityXsStoreActions<MissionReportDto> {
  create: PropsAction<MissionReportXsStoreCreatePayload>;
  createSuccess: PropsAction<MissionReportXsStoreCreateSuccessPayload>;

  createAndConfirm: PropsAction<MissionReportXsStoreCreatePayload>;
  createAndConfirmSuccess: PropsAction<MissionReportXsStoreCreateSuccessPayload>;

  saveAndConfirm: PropsAction<EntityXsStoreSavePayload>;
  saveAndConfirmSuccess: PropsAction<EntityXsStoreSaveSuccessPayload<MissionReportDto, MissionReportDto>>;
}

export class MissionReportXsStore extends EntityXsStore<MissionReportDto> {
  public actions: MissionReportXsStoreActions;

  protected createActions(label: string): MissionReportXsStoreActions {
    return {
      ...super.createActions(label),

      create: createAction(
        BaseXsStore.getType(label, 'Create'),
        props<MissionReportXsStoreCreatePayload>()
      ),
      createSuccess: createAction(
        BaseXsStore.getType(label, 'Create success'),
        props<MissionReportXsStoreCreateSuccessPayload>()
      ),

      createAndConfirm: createAction(
        BaseXsStore.getType(label, 'Create and confirm'),
        props<MissionReportXsStoreCreatePayload>()
      ),
      createAndConfirmSuccess: createAction(
        BaseXsStore.getType(label, 'Create and confirm success'),
        props<MissionReportXsStoreCreateSuccessPayload>()
      ),

      saveAndConfirm: createAction(
        BaseXsStore.getType(label, 'Save and confirm'),
        props<EntityXsStoreSavePayload>()
      ),
      saveAndConfirmSuccess: createAction(
        BaseXsStore.getType(label, 'Save and confirm success'),
        props<EntityXsStoreSaveSuccessPayload<MissionReportDto, MissionReportDto>>()
      )
    };
  }

  protected createReducerArray(
    initialState: MissionReportXsStoreState
  ): BaseXsStoreReducerTypes<MissionReportXsStoreState, MissionReportXsStoreActions>[] {
    return [
      ...super.createReducerArray(initialState),

      immerOn(this.actions.create, draft => {
        draft.loading = true;
      }),
      immerOn(this.actions.createSuccess, (draft, { entity, model }) => {
        draft.entity = cloneDeep(entity);
        draft.model = cloneDeep(model);
        draft.loading = false;
        draft.loaded = true;
      }),

      immerOn(this.actions.createAndConfirm, draft => {
        draft.loading = true;
      }),
      immerOn(this.actions.createAndConfirmSuccess, (draft, { entity, model }) => {
        draft.entity = cloneDeep(entity);
        draft.model = cloneDeep(model);
        draft.loading = false;
        draft.loaded = true;
      }),

      immerOn(this.actions.saveAndConfirm, draft => {
        draft.loading = true;
      }),
      immerOn(this.actions.saveAndConfirmSuccess, (draft, { entity, model }) => {
        draft.entity = cloneDeep(entity);
        draft.model = cloneDeep(model);
        draft.loading = false;
      }),

      on(this.actions.deleteSuccess, () => initialState)
    ];
  }
}
