import {
  BaseXsStore,
  createEntityXsStoreActions, EmptyAction,
  EntityXsStoreActions,
  PropsAction
} from '@nexnox-web/core-store';
import {ResourceManufacturerDto} from '@nexnox-web/core-shared';
import {createAction, props} from '@ngrx/store';
import {ManufacturerDetailXsStoreMergePayload} from "./manufacturer-detail-xs-store.payloads";

export interface ManufacturerDetailXsStoreActions extends EntityXsStoreActions<ResourceManufacturerDto> {
  merge: PropsAction<ManufacturerDetailXsStoreMergePayload>;
  mergeSuccess: EmptyAction;
}

export const createManufacturerDetailXsStoreActions = (label: string): ManufacturerDetailXsStoreActions => ({
  ...createEntityXsStoreActions(label),

  merge: createAction(
    BaseXsStore.getType(label, 'Merge'),
    props<ManufacturerDetailXsStoreMergePayload>()
  ),
  mergeSuccess: createAction(
    BaseXsStore.getType(label, 'Merge Success')
  )
});
