import { CorePortalFeatureAuditListXsStore, CorePortalFeatureAuditListXsStoreState } from '@nexnox-web/core-portal/features/audit';
import { Action, createSelector } from '@ngrx/store';
import { selectTicketsState } from '../../tickets.selectors';
import { Injectable, Injector } from '@angular/core';

export interface TicketAuditStoreState extends CorePortalFeatureAuditListXsStoreState {
}

/* istanbul ignore next */
export const ticketAuditStore = new CorePortalFeatureAuditListXsStore({
  actionLabel: 'Tech Portal - Tickets - Ticket Audit',
  stateSelector: createSelector(selectTicketsState, state => state.ticketAudit)
});

export function ticketAuditStoreReducer(state: TicketAuditStoreState, action: Action): any {
  return ticketAuditStore.reducer(state, action);
}

@Injectable()
export class TicketAuditStoreEffects extends ticketAuditStore.effects {
  constructor(
    protected injector: Injector
  ) {
    super(injector);
  }
}
