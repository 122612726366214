import {InvitationPurpose} from "@nexnox-web/core-shared";


export const invitationPurposeEnumOptions =
  [
    {
      label: 'core-portal.settings.invitations.invitation-purposes.1',
      value: InvitationPurpose.Team
    }
  ];
