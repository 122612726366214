import { TechPortalFeatureConnectedConsumptionCodeService } from './services';
import { consumptionCodeDetailStore, consumptionCodeListStore } from './stores';

export * from './consumption-codes.selectors';
export * from './consumption-codes.state';

export * from './services';
export * from './stores';

export const consumptionCodeReducers = {
  consumptionCodeList: consumptionCodeListStore.reducer,
  consumptionCodeDetail: consumptionCodeDetailStore.reducer
};

export const consumptionCodeEffects = [
  consumptionCodeListStore.effects,
  consumptionCodeDetailStore.effects
];

export const consumptionCodeServices = [
  TechPortalFeatureConnectedConsumptionCodeService
];