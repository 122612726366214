import {ChangeDetectionStrategy, Component, Injector} from '@angular/core';
import {TemplateUsageTypes} from "../../models";
import {TextTemplateDetailComponent} from "../../containers";

@Component({
  selector: 'nexnox-web-templates-mission-text-template-detail-wrapper',
  templateUrl: '../../containers/text-templates/text-template-detail/text-template-detail.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MissionTextTemplateDetailWrapperComponent extends TextTemplateDetailComponent {

  public templateUsageType = TemplateUsageTypes.Mission

  constructor(
    protected injector: Injector
  ) {
    super(injector);
  }
}
