import {BaseXsStoreReducerTypes, entityXsStoreSetLoadingForAction, EntityXsStoreState} from '@nexnox-web/core-store';
import {ResourceManufacturerDto} from '@nexnox-web/core-shared';
import {ManufacturerDetailXsStoreActions} from './manufacturer-detail-xs-store.actions';
import {immerOn} from 'ngrx-immer/store';

export const createManufacturerDetailXsStoreReducer = (
  actions: ManufacturerDetailXsStoreActions,
  initialState: EntityXsStoreState<ResourceManufacturerDto>
): BaseXsStoreReducerTypes<EntityXsStoreState<ResourceManufacturerDto>, ManufacturerDetailXsStoreActions>[] => ([
  immerOn(actions.merge, draft => {
    draft.entityData = entityXsStoreSetLoadingForAction(draft.entityData, {
      [actions.merge.type]: true
    });
  }),
  immerOn(actions.mergeSuccess, draft => {
    draft.entityData = entityXsStoreSetLoadingForAction(draft.entityData, {
      [actions.merge.type]: false
    });
  })
]);
