import {EntityXsStore, EntityXsStoreState} from '@nexnox-web/core-store';
import {AppEntityType, MissionSettingDto} from '@nexnox-web/core-shared';
import {createSelector} from '@ngrx/store';
import {selectCoreState} from '../core.selectors';
import {CorePortalTenantSettingsService} from '../../../services/api';

export interface MissionSettingsStoreState extends EntityXsStoreState<MissionSettingDto> {
}

export const missionSettingsStore = new EntityXsStore<MissionSettingDto>({
  actionLabel: 'Core Portal - Core - Mission Settings',
  stateSelector: createSelector(selectCoreState, state => state.missionSettings),
  entityType: AppEntityType.None,
  serviceType: CorePortalTenantSettingsService,
  stereotyped: false
});
