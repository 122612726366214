export enum CrossCreationTypes {
  COMPANY = 'company',
  LOCATION = 'location',
  CATEGORY = 'category',
  CANCELATION_REASON = 'cancelation_reason',
  HOLIDAYS = 'holidays',
  CONSUMPTION_CODE = 'consumption_code',
  ERROR_CODE = 'error_code',
  KNOWLEDGE_ARTICLE = 'knowledge_article',
  KNOWLEDGE_EXAM = 'knowledge_exam'
}
