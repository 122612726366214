<div class="alert alert-info d-flex align-items-center">
  <fa-icon [icon]="faInfoCircle" [fixedWidth]="true" class="mr-2"></fa-icon>
  {{ 'core-portal.settings.subtitles.notification-rules.restriction-info' | translate }}
</div>

<nexnox-web-entity-overview #overviewComponent
                            [entityOverviewBaseComponent]="this"
                            detailLink="/missions"
                            module="inventory"
                            [isEmbedded]="true"
                            [title]="title | translate"
                            [showSettings]="!readonly"
                            [disableFilters]="readonly"
                            [allowMargin]="true"
                            [disableSettingsViewSelect]="true"
                            [saveFiltersOnChange]="true"
                            [datatableConfig]="datatableConfig$ | async"
                            [customInit$]="ready$"
                            (datatableConfigChange)="onDatatableConfigChange($event)">
</nexnox-web-entity-overview>
