import {PagedEntitiesXsStore, PagedEntitiesXsStoreState} from '@nexnox-web/core-store';
import {AppEntityType, InvitationListDto} from '@nexnox-web/core-shared';
import {Action, createSelector} from '@ngrx/store';
import {CorePortalInvitationsService} from '../../services';
import {Injectable, Injector} from '@angular/core';
import {selectInvitationsState} from "../../invitations.selectors";

export interface InvitationsListStoreState extends PagedEntitiesXsStoreState<InvitationListDto> {
}

/* istanbul ignore next */
export const invitationsListStore = new PagedEntitiesXsStore<InvitationListDto>({
  actionLabel: 'Core Portal - Settings - Invitations - Invitation List',
  stateSelector: createSelector(selectInvitationsState, state => state.invitationsList),
  serviceType: CorePortalInvitationsService,
  entityType: AppEntityType.None,
  stereotyped: false,
  selectId: invitations => invitations.entity?.invitationId ?? invitations.model?.invitationId,
});

export function invitationsListStoreReducer(state: InvitationsListStoreState, action: Action): any {
  return invitationsListStore.reducer(state, action);
}

@Injectable()
export class InvitationsListStoreEffects extends invitationsListStore.effects {
  constructor(
    protected injector: Injector
  ) {
    super(injector);
  }
}
