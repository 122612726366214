import { HttpClient, HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { ErrorHandler, Inject, NgModule, Optional } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { ToasterModule } from 'angular2-toaster';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { NgxMaskModule } from 'ngx-mask';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { ConfirmationService } from 'primeng/api';
import { CoreSharedChunkErrorHandler, CoreSharedGlobalErrorHandler } from './error-handlers';
import { CoreSharedBreadcrumbsModule } from './modules';
import { ClipboardToastService, CoreSharedApplicationUuidService } from './services';
import { coreSharedTranslateHttpLoaderFactory, exportedModules, sharedModules, sharedProviders } from './shared-exports';
import { CORE_SHARED_ADDITIONAL_TRANSLATIONS, CORE_SHARED_APP_NAME, CORE_SHARED_ROOT_GUARD } from './tokens';

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    HttpClientJsonpModule,

    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: coreSharedTranslateHttpLoaderFactory,
        deps: [[new Optional(), CORE_SHARED_APP_NAME], [new Optional(), CORE_SHARED_ADDITIONAL_TRANSLATIONS], HttpClient]
      }
    }),

    ToasterModule.forRoot(),
    NgxMaskModule.forRoot(),
    SweetAlert2Module.forRoot(),
    BsDatepickerModule.forRoot(),
    BsDropdownModule.forRoot(),
    CoreSharedBreadcrumbsModule.forRoot(),
    NgxSkeletonLoaderModule.forRoot(),
    ...sharedModules
  ],
  exports: [
    ...sharedModules,
    ...exportedModules
  ],
  providers: [
    ConfirmationService,

    ...sharedProviders,

    CoreSharedChunkErrorHandler,
    {
      provide: ErrorHandler,
      useClass: CoreSharedGlobalErrorHandler
    },

    CoreSharedApplicationUuidService
  ]
})
export class CoreSharedRootModule {
  constructor(
    @Optional() @Inject(CORE_SHARED_ROOT_GUARD) rootGuard: any,
    private applicationUuidService: CoreSharedApplicationUuidService,
    private clipboardToastService: ClipboardToastService
  ) {
    this.applicationUuidService.generateUuid();
  }
}
