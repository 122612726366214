<p-sidebar #sidebarComponent
           position="right"
           appendTo="body"
           styleClass="p-sidebar-lg"
           [transitionOptions]="transitionOptions"
           [visible]="visible$ | async"
           (visibleChange)="onVisibleChange($event)">

  <ng-container *ngIf="articleSubject | async as article; else loadingTemplate">
  <div class="row">
    <div class="col-12">
      <h4>{{ article.title }}</h4>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <div [innerHtml]="bypassHTMLSanitizer(article.content)"></div>
    </div>
  </div>
  </ng-container>
</p-sidebar>

<ng-template #loadingTemplate>
  <nexnox-web-loading-icon class="d-flex w-100 h-100 align-items-center justify-content-center flex-column p-5"
                           size="5x">
  </nexnox-web-loading-icon>
</ng-template>
