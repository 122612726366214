import {ChangeDetectionStrategy, Component, Injector, Input, OnInit} from '@angular/core';
import {CorePortalEntityOverviewBaseComponent, DatatableActionButton} from '@nexnox-web/core-portal';
import {
  ApiNotificationService,
  AppEntityType,
  AttachmentTypes,
  CoreSharedFileService,
  FileDto,
  FilledFormDto,
  FilterOperators,
  FilterTypes,
  Mappers,
  TaskJobDto, TaskJobListDto, TaskJobState
} from '@nexnox-web/core-shared';
import {locationTaskArchiveListStore} from '../../store';
import {faDownload} from '@fortawesome/free-solid-svg-icons/faDownload';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {FilledformService} from "@nexnox-web/orga-portal/features/tasks/src/lib/store";
import {TranslateService} from "@ngx-translate/core";
import {isUndefined} from "lodash";

@Component({
  selector: 'nexnox-web-location-task-archive-list',
  templateUrl: './location-task-archive-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LocationTaskArchiveListComponent extends CorePortalEntityOverviewBaseComponent<TaskJobDto> implements OnInit {
  @Input() public locationId: number;

  public title = 'orga-portal.tasks.subtitles.archive';
  public idProperty = 'taskJobId';
  public displayProperty = null;
  public datatableConfigName = 'LocationTaskArchiveDefault';
  public componentId = 'LocationTaskArchiveListComponent';
  public canDelete = false;
  public showDetailTemplateExpandButtonFn: (row: any) => boolean;

  constructor(
    protected injector: Injector,
    private fileService: CoreSharedFileService,
    private filledFormService: FilledformService,
    protected apiNotificationService: ApiNotificationService
  ) {
    super(injector, locationTaskArchiveListStore, Mappers.TaskJobListDto.serializedName, AppEntityType.TaskJob);
  }

  public ngOnInit(): void {
    this.defaultFilter = [
      {
        property: 'locationId',
        type: FilterTypes.DataTransferObject,
        operator: FilterOperators.Equal,
        value: this.locationId.toString()
      }
    ];
    this.showDetailTemplateExpandButtonFn = (row: TaskJobListDto) => row?.children?.length > 0;
    super.ngOnInit();
  }

  /* istanbul ignore next */
  public getRowActionButtons(): DatatableActionButton[] {
    return [
      {
        tooltip: 'core-portal.core.general.download',
        style: 'p-button-primary',
        isolate: true,
        icon: faDownload,
        onClick: (row: TaskJobDto) => {
          let request: Observable<FileDto>;

          switch (row.attachment.type) {
            case AttachmentTypes.File:
              request = this.fileService.getFile(row.attachment.referenceId);
              break;
            case AttachmentTypes.FilledForm:
              request = this.filledFormService.getOne<FilledFormDto>(row.attachment.referenceId).pipe(
                map((filledForm: FilledFormDto) => filledForm?.file)
              );
              break;
          }
          request
            .toPromise()
            .then((file: FileDto) => file ? window.open(file.uri, '_blank') : this.apiNotificationService.showTranslatedError('orga-portal.tasks.errors.no-pdf-attachment-for-task'))
            .catch(error => this.apiNotificationService.handleApiError(error));
        },
        show: (row: TaskJobDto) => Boolean(row?.attachment?.referenceId) &&
          (row.attachment.type === AttachmentTypes.File || row.attachment.type === AttachmentTypes.FilledForm)
      }
    ];
  }
}
