import {
  notificationRulesContractsStore,
  NotificationRulesContractsStoreState,
  notificationRulesDetailStore,
  NotificationRulesDetailStoreState,
  notificationRulesInfosStore,
  NotificationRulesInfosStoreState,
  notificationRulesListStore,
  NotificationRulesListStoreState,
  notificationRulesMissionsStore,
  NotificationRulesMissionsStoreState,
  notificationRulesTicketsStore,
  NotificationRulesTicketsStoreState
} from './stores';

export interface NotificationRulesState {
  notificationRulesList: NotificationRulesListStoreState;
  notificationRulesDetail: NotificationRulesDetailStoreState;
  notificationRulesMissions: NotificationRulesMissionsStoreState;
  notificationRulesTickets: NotificationRulesTicketsStoreState;
  notificationRulesContracts: NotificationRulesContractsStoreState;
  notificationRulesInfos: NotificationRulesInfosStoreState
}

export const getInitialNotificationRulesState = (): NotificationRulesState => ({
  notificationRulesList: notificationRulesListStore.getInitialState(),
  notificationRulesDetail: notificationRulesDetailStore.getInitialState(),
  notificationRulesMissions: notificationRulesMissionsStore.getInitialState(),
  notificationRulesTickets: notificationRulesTicketsStore.getInitialState(),
  notificationRulesContracts: notificationRulesContractsStore.getInitialState(),
  notificationRulesInfos: notificationRulesInfosStore.getInitialState()
});
