import { MissionReportReceiptDto } from '@nexnox-web/core-shared';
import {
  BaseXsStore,
  BaseXsStoreReducerTypes, EntityXsStore,
  EntityXsStoreActions, EntityXsStoreState, PropsAction
} from '@nexnox-web/core-store';
import { createAction, props } from '@ngrx/store';
import { cloneDeep } from 'lodash';
import { immerOn } from 'ngrx-immer/store';

export interface MissionReceiptXsStoreGetReceiptPayload {
  missionId: number;
}

export interface MissionReceiptXsStoreGetReceiptSuccessPayload {
  entity: MissionReportReceiptDto;
  model: MissionReportReceiptDto;
}

export interface MissionReceiptXsStoreActions extends EntityXsStoreActions<MissionReportReceiptDto> {
  getReceipt: PropsAction<MissionReceiptXsStoreGetReceiptPayload>;
  getReceiptSuccess: PropsAction<MissionReceiptXsStoreGetReceiptSuccessPayload>;
}

export class MissionReceiptXsStore extends EntityXsStore<MissionReportReceiptDto> {
  public actions: MissionReceiptXsStoreActions;

  protected createActions(label: string): MissionReceiptXsStoreActions {
    return {
      ...super.createActions(label),

      getReceipt: createAction(
        BaseXsStore.getType(label, 'Get receipt'),
        props<MissionReceiptXsStoreGetReceiptPayload>()
      ),
      getReceiptSuccess: createAction(
        BaseXsStore.getType(label, 'Get receipt success'),
        props<MissionReceiptXsStoreGetReceiptSuccessPayload>()
      )
    };
  }

  protected createReducerArray(
    initialState: EntityXsStoreState<MissionReportReceiptDto>
  ): BaseXsStoreReducerTypes<EntityXsStoreState<MissionReportReceiptDto>, MissionReceiptXsStoreActions>[] {
    return [
      ...super.createReducerArray(initialState),

      immerOn(this.actions.getReceipt, draft => {
        draft.loading = true;
      }),
      immerOn(this.actions.getReceiptSuccess, (draft, { entity, model }) => {
        draft.entity = cloneDeep(entity);
        draft.model = cloneDeep(model);
        draft.loading = false;
        draft.loaded = true;
      }),
    ];
  }
}
