import {ChangeDetectionStrategy, Component, Injector} from '@angular/core';
import {
  CorePortalEntityEditBaseComponent,
  CorePortalFormlyReadonlyTypes,
  CorePortalFormlyReadonlyTyping,
  CorePortalVirtualResourceService
} from '@nexnox-web/core-portal';
import {LocationDto, ResourceSimpleDto, WinterhalterServiceCallIssueDto} from '@nexnox-web/core-shared';
import {FormlyFieldConfig} from '@ngx-formly/core';
import {
  CorePortalFeatureMasterDataLocationService
} from "@nexnox-web/core-portal/features/master-data/features/locations";
import {getWinterHalterProductOrderFormlyConfig} from "../../utils";

@Component({
  selector: 'nexnox-web-issues-winterhalter-service-call-issue-edit',
  templateUrl: './winterhalter-service-call-issue-edit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WinterhalterServiceCallIssueEditComponent extends CorePortalEntityEditBaseComponent<WinterhalterServiceCallIssueDto> {

  constructor(
    protected injector: Injector,
    private resourceService: CorePortalVirtualResourceService,
    private locationService: CorePortalFeatureMasterDataLocationService
  ) {
    super(injector);
  }

  /* istanbul ignore next */
  protected createForm(): FormlyFieldConfig[] {
    return [
      {
        key: 'title',
        type: 'input',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-6',
        templateOptions: {
          type: 'text',
          corePortalTranslated: {
            label: 'core-shared.shared.fields.name',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          }
        },
        expressionProperties: {
          'templateOptions.required': () => !this.readonly,
          'templateOptions.disabled': () => this.readonly,
          'templateOptions.readonly': () => this.readonly
        },
        hideExpression: () => !this.creating
      },
      {
        key: 'contactFirstName',
        type: 'input',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-6',
        templateOptions: {
          type: 'text',
          corePortalTranslated: {
            label: 'core-shared.shared.fields.first-name'
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.BASIC
          } as CorePortalFormlyReadonlyTyping
        },
        expressionProperties: {
          'templateOptions.disabled': () => this.readonly,
          'templateOptions.readonly': () => this.readonly
        }
      },
      {
        key: 'contactLastName',
        type: 'input',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-6',
        templateOptions: {
          type: 'text',
          corePortalTranslated: {
            label: 'core-shared.shared.fields.last-name'
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.BASIC
          } as CorePortalFormlyReadonlyTyping
        },
        expressionProperties: {
          'templateOptions.disabled': () => this.readonly,
          'templateOptions.readonly': () => this.readonly
        }
      },
      {
        key: 'resource',
        type: 'core-portal-entity-select',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-6',
        defaultValue: null,
        templateOptions: {
          corePortalTranslated: {
            label: 'core-portal.settings.fields.ticket-settings.processes.resource'
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.ENTITY,
            displayKey: 'name',
            link: (resource: ResourceSimpleDto) => resource?.resourceId ? ['resources', resource.resourceId] : null,
            module: 'inventory'
          } as CorePortalFormlyReadonlyTyping,
          entityService: this.resourceService,
          idKey: 'resourceId',
          displayKey: 'name',
          wholeObject: true,
          multiple: false
        },
        expressionProperties: {
          'templateOptions.disabled': () => this.readonly,
          'templateOptions.readonly': () => this.readonly
        }
      },
      {
        key: 'location',
        type: 'core-portal-entity-select',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-6',
        defaultValue: null,
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.location'
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.ENTITY,
            displayKey: 'name',
            link: (location: LocationDto) => location?.locationId ? ['/masterdata', 'locations', location.locationId] : null,
            module: 'management'
          } as CorePortalFormlyReadonlyTyping,
          entityService: this.locationService,
          idKey: 'locationId',
          displayKey: 'name',
          wholeObject: true,
          multiple: true,
          showAll: true
        },
        expressionProperties: {
          'templateOptions.disabled': () => this.readonly,
          'templateOptions.readonly': () => this.readonly
        }
      },
      // Winterhalter Service Call fields
      {
        key: 'problem',
        type: 'input',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-6',
        templateOptions: {
          type: 'text',
          corePortalTranslated: {
            label: 'core-shared.shared.fields.problem'
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.BASIC
          } as CorePortalFormlyReadonlyTyping
        },
        expressionProperties: {
          'templateOptions.disabled': () => this.readonly,
          'templateOptions.readonly': () => this.readonly
        }
      },
      {
        key: 'category',
        type: 'input',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-6',
        templateOptions: {
          type: 'text',
          corePortalTranslated: {
            label: 'core-shared.shared.fields.category'
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.BASIC
          } as CorePortalFormlyReadonlyTyping
        },
        expressionProperties: {
          'templateOptions.disabled': () => this.readonly,
          'templateOptions.readonly': () => this.readonly
        }
      },
      {
        key: 'message',
        type: 'input',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-6',
        templateOptions: {
          type: 'text',
          corePortalTranslated: {
            label: 'core-shared.shared.fields.message'
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.BASIC
          } as CorePortalFormlyReadonlyTyping
        },
        expressionProperties: {
          'templateOptions.disabled': () => this.readonly,
          'templateOptions.readonly': () => this.readonly
        }
      },
      {
        key: 'orderId',
        type: 'input',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-6',
        templateOptions: {
          type: 'text',
          corePortalTranslated: {
            label: 'core-shared.shared.fields.order-id',
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.BASIC
          } as CorePortalFormlyReadonlyTyping
        },
        expressionProperties: {
          'templateOptions.disabled': () => this.readonly,
          'templateOptions.readonly': () => this.readonly
        }
      },

      /* Winterhalter Product Order fields */
      ...getWinterHalterProductOrderFormlyConfig(this.readonly)
    ];
  }
}
