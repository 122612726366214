import {NgModule} from '@angular/core';
import {CoreSharedModule} from '@nexnox-web/core-shared';
import {CorePortalEntityModule} from '@nexnox-web/core-portal';
import {
  CorePortalFeatureSettingsStereotypeEditComponent,
  CustomPropertyEditComponent,
  CustomPropertySetEditComponent,
  CustomPropertySetsEditComponent,
  ResourcesByLocationStereotypeEditComponent,
  StereotypeContractSettingsEditComponent,
  TicketsByLocationStereotypeEditComponent
} from './components';
import {
  ResourcesByLocationStereotypeDetailComponent,
  ResourcesByLocationStereotypeListComponent,
  StereotypeContractSettingsDetailComponent,
  StereotypeDetailComponent,
  StereotypeListComponent,
  StereotypeSkeletonComponent,
  TicketsByLocationStereotypeDetailComponent,
  TicketsByLocationStereotypeListComponent
} from './containers';
import {SortCustomPropertySetsPipe} from './pipes';
import {PanelModule} from 'primeng/panel';
import {InputTextModule} from 'primeng/inputtext';
import {TagModule} from 'primeng/tag';
import {FormlyCustomPropertyDropdownComponent, FormlyCustomPropertyMultipleChoiceComponent} from './formly-templates';
import {FormlyModule} from '@ngx-formly/core';
import {BadgeModule} from 'primeng/badge';
import {TieredMenuModule} from 'primeng/tieredmenu';
import {
  CorePortalSettingsStereotypeService,
  CorePortalStereotypeContractSettingsService,
  CorePortalTicketSettingsPriorityService,
  CorePortalTicketSettingsStateService,
  ResourcesByLocationStereotypeService,
  TicketsByLocationStereotypeService
} from "./store/services";
import {
  AddressStereotypeListWrapperComponent,
  ArticleStereotypeListWrapperComponent,
  CompanyStereotypeListWrapperComponent,
  ContactStereotypeListWrapperComponent,
  ContractStereotypeListWrapperComponent,
  ControlTagStereotypeListWrapperComponent,
  FunctionStereotypeListWrapperComponent,
  IssueStereotypeListWrapperComponent,
  LocationGroupStereotypeListWrapperComponent,
  LocationStereotypeListWrapperComponent,
  MissionInspectionReportStereotypeListWrapperComponent,
  MissionReportStereotypeListWrapperComponent,
  MissionStereotypeListWrapperComponent,
  ResourceStereotypeListWrapperComponent,
  TicketStereotypeListWrapperComponent
} from "./wrappers";

const wrappers = [
  MissionStereotypeListWrapperComponent,
  TicketStereotypeListWrapperComponent,
  ResourceStereotypeListWrapperComponent,
  ContractStereotypeListWrapperComponent,
  ControlTagStereotypeListWrapperComponent,
  ArticleStereotypeListWrapperComponent,
  MissionReportStereotypeListWrapperComponent,
  AddressStereotypeListWrapperComponent,
  CompanyStereotypeListWrapperComponent,
  ContactStereotypeListWrapperComponent,
  FunctionStereotypeListWrapperComponent,
  LocationGroupStereotypeListWrapperComponent,
  LocationStereotypeListWrapperComponent,
  IssueStereotypeListWrapperComponent,
  MissionInspectionReportStereotypeListWrapperComponent
]

@NgModule({
  declarations: [
    CorePortalFeatureSettingsStereotypeEditComponent,
    StereotypeContractSettingsEditComponent,
    CustomPropertySetsEditComponent,
    CustomPropertySetEditComponent,
    CustomPropertyEditComponent,

    StereotypeListComponent,
    StereotypeDetailComponent,
    StereotypeSkeletonComponent,
    StereotypeContractSettingsDetailComponent,

    ResourcesByLocationStereotypeDetailComponent,
    ResourcesByLocationStereotypeListComponent,
    ResourcesByLocationStereotypeEditComponent,

    TicketsByLocationStereotypeListComponent,
    TicketsByLocationStereotypeDetailComponent,
    TicketsByLocationStereotypeEditComponent,

    ...wrappers,

    FormlyCustomPropertyDropdownComponent,
    FormlyCustomPropertyMultipleChoiceComponent,

    SortCustomPropertySetsPipe
  ],
  imports: [
    CoreSharedModule,
    CorePortalEntityModule,

    FormlyModule.forChild({
      types: [
        {
          name: 'core-portal-stereotypes-dropdown',
          component: FormlyCustomPropertyDropdownComponent
        },
        {
          name: 'core-portal-stereotypes-multiple-choice',
          component: FormlyCustomPropertyMultipleChoiceComponent
        }
      ]
    }),

    PanelModule,
    InputTextModule,
    TagModule,
    BadgeModule,
    TieredMenuModule
  ],
  exports: [
    CustomPropertySetsEditComponent,
    SortCustomPropertySetsPipe
  ],
  providers: [
    CorePortalStereotypeContractSettingsService,
    CorePortalTicketSettingsPriorityService,
    CorePortalTicketSettingsStateService,
    CorePortalSettingsStereotypeService,
    ResourcesByLocationStereotypeService,
    TicketsByLocationStereotypeService
  ]
})
export class StereotypesDeclarationsModule {
}
