import {
  CorePortalEntityActionsFacade,
  CorePortalEntityActionsFacadeAddRemoveParentPayload,
  CorePortalEntityActionsFacadeCreateOnePayload,
  CorePortalEntityActionsFacadeDeleteOnePayload,
  CorePortalEntityActionsFacadeGetPagePayload
} from '../../facades';
import {Injector} from '@angular/core';
import {Store} from '@ngrx/store';
import {PagedEntitiesXsStore} from '@nexnox-web/core-store';
import {isFunction} from "lodash";
import {Actions, ofType} from "@ngrx/effects";
import {take} from "rxjs/operators";

export class CorePortalXsStoreEntityActionsFacade<E, M = E> implements CorePortalEntityActionsFacade<E, M> {
  protected store: Store<any>;
  private actions$: Actions;

  constructor(
    protected injector: Injector,
    protected entityStore: PagedEntitiesXsStore<E, M> | any
  ) {
    this.store = this.injector.get(Store);
    this.actions$ = this.injector.get(Actions);
  }

  public getPage({
                   pageNumber,
                   datatableConfig,
                   optionalColumns,
                   contextCloak,
                   pageSize,
                   filters,
                   defaultFilters,
                   sortOption,
                   append,
                   stereotypeColumns,
                   parent
                 }: CorePortalEntityActionsFacadeGetPagePayload): void {
    this.store.dispatch(this.entityStore.actions.setFilters({filters: filters, sortObject: sortOption}));
    this.store.dispatch((append ? this.entityStore.actions.appendPage : this.entityStore.actions.getPage)(
      pageNumber,
      sortOption,
      (defaultFilters ?? []).concat(filters ?? []),
      parent,
      pageSize ? pageSize : (datatableConfig?.pageSize ?? undefined),
      stereotypeColumns,
      optionalColumns,
      contextCloak
    ));
  }

  public clear(): void {
    this.store.dispatch(this.entityStore.actions.clear());
  }

  public createOne({model, parentIds, goBack}: CorePortalEntityActionsFacadeCreateOnePayload<M>): void {
    this.store.dispatch(this.entityStore.actions.createOne({model, parentIds}));

    if (isFunction(goBack)) {
      this.actions$.pipe(
        ofType(this.entityStore.actions.createOneSuccess),
        take(1)
      ).subscribe(() => {
        goBack();
      });
    }
  }

  public deleteOne({id, parentIds}: CorePortalEntityActionsFacadeDeleteOnePayload): void {
    this.store.dispatch(this.entityStore.actions.deleteOne({id, parentIds}));
  }

  public addOneToParent({id, parentIds}: CorePortalEntityActionsFacadeAddRemoveParentPayload): void {
    this.store.dispatch(this.entityStore.actions.addOneToParent({id, parentIds}));
  }

  public removeOneFromParent({id, parentIds}: CorePortalEntityActionsFacadeAddRemoveParentPayload): void {
    this.store.dispatch(this.entityStore.actions.removeOneFromParent({id, parentIds}));
  }
}
