import { Injectable, Injector } from '@angular/core';
import { CoreSharedApiBaseService, Product } from '@nexnox-web/core-shared';

@Injectable()
export class CorePortalFunctionService extends CoreSharedApiBaseService {
  constructor(
    protected injector: Injector
  ) {
    super(injector, Product.TECH, 'function');
  }
}
