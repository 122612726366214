<ng-container *ngFor="let validationMessage of validationMessages$ | async">
  <ng-container *ngIf="validationMessage.hasError">
    <ng-container *ngIf="validationMessage.hasArgs; else noArgs">
      {{ validationMessage.message.key | translate:validationMessage.message.args }}
    </ng-container>

    <ng-template #noArgs>
      {{ validationMessage.message | translate }}
    </ng-template>

    <br>
  </ng-container>
</ng-container>
