import { HttpHandler, HttpInterceptor } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { HttpBaseHandler } from './http-base.handler';
import { CORE_SHARED_HTTP_API_INTERCEPTORS } from '../../../tokens';

@Injectable()
export class HttpApiHandler extends HttpBaseHandler {
  constructor(
    protected next: HttpHandler,
    @Optional() @Inject(CORE_SHARED_HTTP_API_INTERCEPTORS) private httpApiInterceptors: HttpInterceptor[]
  ) {
    super(next, httpApiInterceptors ? httpApiInterceptors : []);
  }
}
