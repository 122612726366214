import { categoryDetailStore, CategoryDetailStoreState, categoryListStore, CategoryListStoreState } from './stores';

export interface CategoriesState {
  categoryList: CategoryListStoreState;
  categoryDetail: CategoryDetailStoreState;
}

export const getInitialCategoriesState = (): CategoriesState => ({
  categoryList: categoryListStore.getInitialState(),
  categoryDetail: categoryDetailStore.getInitialState()
});
