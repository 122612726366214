import { ChangeDetectionStrategy, Component, Injector, OnInit, ViewChild } from '@angular/core';
import { ActionButton, CorePortalEntityCreatePresetService, CorePortalEntityDetailBaseComponent } from '@nexnox-web/core-portal';
import {
  AppPermissions,
  ChildTaskSimpleDto,
  CollectionTaskDto,
  ConcreteTaskTypes,
  ConfirmationTaskDto,
  DateTimePatternDto,
  DocumentChildTaskDto,
  DocumentTaskDto,
  ExecuteableTaskDto,
  ExecutionPlanDto,
  ExecutionTypes,
  ImpactDto,
  ManualExecutionDto,
  PlannedExecutionDto,
  TaskDto
} from '@nexnox-web/core-shared';
import { taskDetailStore } from '../../store';
import { TaskExecutionsComponent, TaskImpactsComponent, TaskSubtasksComponent } from '../../components';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import { isEmpty, omit } from 'lodash';

@Component({
  selector: 'nexnox-web-task-detail',
  templateUrl: './task-detail.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TaskDetailComponent extends CorePortalEntityDetailBaseComponent<TaskDto> implements OnInit {
  @ViewChild('subtasksComponent') public subtasksComponent: TaskSubtasksComponent;
  @ViewChild('executionsComponent') public executionsComponent: TaskExecutionsComponent;
  @ViewChild('impactsComponent') public impactsComponent: TaskImpactsComponent;

  public title = 'orga-portal.tasks.subtitles.task-detail';

  public isChild$: Observable<boolean>;
  public strictOrder$: Observable<boolean>;
  public subtasks$: Observable<ChildTaskSimpleDto[]>;
  public executionPatterns$: Observable<DateTimePatternDto[]>;
  public impacts$: Observable<ImpactDto[]>;

  public concreteTaskTypes = ConcreteTaskTypes;
  public executionTypes = ExecutionTypes;

  protected idParam = 'taskId';
  protected displayKey = 'title';

  constructor(
    protected injector: Injector
  ) {
    super(injector, taskDetailStore);
  }

  public ngOnInit(): void {
    super.ngOnInit();

    // ToDo: Use parentId instead of position
    this.isChild$ = this.model$.pipe(
      map(model => model && model.type === ConcreteTaskTypes.ChildDocument)
    );

    this.strictOrder$ = this.model$.pipe(
      map(model => (model as CollectionTaskDto)?.hasStrictOrder)
    );

    this.subtasks$ = this.model$.pipe(
      map(model => (model as CollectionTaskDto)?.children ?? [])
    );

    this.executionPatterns$ = this.model$.pipe(
      map(model => ((model as ExecuteableTaskDto)?.executionPlan as PlannedExecutionDto)?.patterns ?? [])
    );

    this.impacts$ = this.model$.pipe(
      map(model => model?.impacts ?? [])
    );

    this.subscribe(this.readonly$.asObservable(), () => {
      this.subtasksComponent?.onReset();
      this.executionsComponent?.onReset();
      this.impactsComponent?.onReset();
    });
  }

  public onStrictOrderChange(hasStrictOrder: boolean, model: TaskDto): void {
    this.onModelChange({ ...model, hasStrictOrder } as CollectionTaskDto);
  }

  public onSubtasksChange(subtasks: ChildTaskSimpleDto[], model: TaskDto): void {
    this.onModelChange({ ...model, children: subtasks } as CollectionTaskDto);
  }

  public onExecutionPatternsChange(executionPatterns: DateTimePatternDto[], model: TaskDto): void {
    this.onModelChange({
      ...model,
      executionPlan: {
        ...((model as ExecuteableTaskDto)?.executionPlan ?? {}),
        patterns: executionPatterns
      } as PlannedExecutionDto
    } as ExecuteableTaskDto);
  }

  public onImpactsChange(impacts: ImpactDto[], model: TaskDto): void {
    this.onModelChange({ ...model, impacts });
  }

  /* istanbul ignore next */
  protected async getActionButtons(): Promise<ActionButton[]> {
    return [
      ...this.getDefaultActionButtons(
        'orga-portal.tasks.actions.edit-task',
        'orga-portal.tasks.actions.save-task',
        'orga-portal.tasks.actions.delete-task',
        'orga-portal.tasks.descriptions.delete-task',
        AppPermissions.UpdateTask,
        AppPermissions.DeleteTask,
        ['/tasks']
      ),
      {
        label: 'core-shared.shared.actions.duplicate',
        type: 'button',
        class: 'btn-outline-secondary',
        permission: AppPermissions.CreateTask,
        icon: faPlus,
        shouldShow: () => this.entity$.pipe(
          map(entity => Boolean(entity) && !isEmpty(entity))
        ),
        callback: async () => {
          const entity = await this.entity$.pipe(take(1)).toPromise() as TaskDto & DocumentTaskDto & CollectionTaskDto;

          if (entity) {
            this.entityCreatePresetService.setPreset('OrgaPortalFeatureTaskEditComponent', {
              ...omit(entity, ['taskId']),
              impacts: (entity.impacts ?? []).map(impact => ({
                ...omit(impact, 'impactId'),
                condition: omit(impact.condition, 'conditionId')
              })),
              executionPlan: {
                ...omit(entity.executionPlan, ['executionId']),
                patterns: ((entity.executionPlan as PlannedExecutionDto)?.patterns ?? []).map(pattern => ({
                  ...omit(pattern, ['dateTimePatternId', 'lastModified']),
                  plannedTimes: (pattern?.plannedTimes ?? []).map(plannedTime => omit(plannedTime, 'plannedTimeId'))
                }))
              } as ExecutionPlanDto & PlannedExecutionDto & ManualExecutionDto,
              children: (entity.children ?? []).map(child => omit(child, 'taskId'))
            } as TaskDto & ConfirmationTaskDto & DocumentTaskDto & CollectionTaskDto & DocumentChildTaskDto);
            await this.tenantRouter.navigate(['/tasks'], { fragment: 'create' });
          }
        }
      }
    ];
  }
}
