import {BaseXsStoreReducerTypes, EntityXsStore, EntityXsStoreState} from '@nexnox-web/core-store';
import {
  createInvitationsDetailXsStoreActions,
  InvitationsDetailXsStoreActions
} from './invitations-detail-xs-store.actions';
import {createInvitationsDetailXsStoreReducer} from './invitations-detail-xs-store.reducer';
import {InvitationDto} from "@nexnox-web/core-shared";

export class InvitationsDetailXsStore extends EntityXsStore<InvitationDto> {
  public actions: InvitationsDetailXsStoreActions;

  protected createActions(label: string): InvitationsDetailXsStoreActions {
    return createInvitationsDetailXsStoreActions(label);
  }

  protected createReducerArray(
    initialState: EntityXsStoreState<InvitationDto>
  ): BaseXsStoreReducerTypes<EntityXsStoreState<InvitationDto>, InvitationsDetailXsStoreActions>[] {
    return [
      ...super.createReducerArray(initialState),
      ...createInvitationsDetailXsStoreReducer(this.actions, initialState)
    ];
  }
}
