import { InjectionToken, NgModule } from '@angular/core';
import { ActionReducerMap, StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import {
  notificationRulesEffects,
  notificationRulesReducers
} from './store';

export const REDUCER_TOKEN = new InjectionToken<ActionReducerMap<any>>(
  '@nexnox-web/core-portal/settings/notification-rules: Store Reducers'
);

@NgModule({
  imports: [
    StoreModule.forFeature('core-portal-feature-settings-notification-rules', REDUCER_TOKEN),
    EffectsModule.forFeature(notificationRulesEffects)
  ],
  providers: [
    {
      provide: REDUCER_TOKEN,
      useValue: notificationRulesReducers
    }
  ]
})
export class NotificationRulesStoreModule {
}
