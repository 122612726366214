import {PagedEntitiesXsStore, PagedEntitiesXsStoreState} from '@nexnox-web/core-store';
import {AppEntityType, MissionByTicketDto} from '@nexnox-web/core-shared';
import {Action, createSelector} from '@ngrx/store';
import {selectTicketsState} from '../../tickets.selectors';
import {SolutionMissionService, TicketMissionService} from '../../services';
import {Injectable, Injector} from '@angular/core';
import {Observable} from 'rxjs';

export interface TicketMissionsStoreState extends PagedEntitiesXsStoreState<MissionByTicketDto> {
}

/* istanbul ignore next */
export const ticketMissionsStore = new PagedEntitiesXsStore<MissionByTicketDto>({
  actionLabel: 'Tech Portal - Tickets - Ticket Missions List',
  stateSelector: createSelector(selectTicketsState, state => state.ticketMissions),
  serviceType: SolutionMissionService,
  entityType: AppEntityType.Mission,
  selectId: mission => mission.entity?.missionId ?? mission.model?.missionId
});

export function ticketMissionsStoreReducer(state: TicketMissionsStoreState, action: Action): any {
  return ticketMissionsStore.reducer(state, action);
}

@Injectable()
export class TicketMissionsStoreEffects extends ticketMissionsStore.effects {
  constructor(
    protected injector: Injector,
    private ticketMissionService: TicketMissionService
  ) {
    super(injector);
  }

  /* istanbul ignore next */
  protected createOne(
    model: MissionByTicketDto,
    tenantId: number | string,
    parentIds: Array<number | string> = []
  ): Observable<MissionByTicketDto> {
    return this.ticketMissionService.createOne(
      this.sanitizeModel({ ...model, tenantId, rowVersion: [] } as any, {} as any),
      this.createWithParentIds ? parentIds : undefined
    );
  }
}
