import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CorePortalHierarchicalTreeViewModule } from './hierarchical-tree-view';

const exportedModules = [
  CorePortalHierarchicalTreeViewModule
];

@NgModule({
  imports: [
    CommonModule,
    ...exportedModules
  ],
  exports: [
    ...exportedModules
  ]
})
export class CorePortalHierarchicalModule {
}
