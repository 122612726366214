import {
  pathsDetailStore,
  pathsListStore,
  pathsProgressContactListStore,
  pathsProgressContactReadUnreadListStore,
  pathsProgressFunctionListStore,
  pathsProgressLocationListStore
} from './stores';
import {
  PathsProgressContactReadUnreadService,
  PathsProgressContactService,
  PathsProgressFunctionService,
  PathsProgressLocationService
} from "./services";

export * from './stores';
export * from './services';
export * from './paths.state';
export * from './paths.selectors';

export const pathsReducers = {
  pathsList: pathsListStore.reducer,
  pathsDetail: pathsDetailStore.reducer,

  pathsProgressContactList: pathsProgressContactListStore.reducer,
  pathsProgressFunctionList: pathsProgressFunctionListStore.reducer,
  pathsProgressLocationList: pathsProgressLocationListStore.reducer,
  pathsProgressContactReadUnreadList: pathsProgressContactReadUnreadListStore.reducer
};

export const pathsEffects = [
  pathsListStore.effects,
  pathsDetailStore.effects,

  pathsProgressContactListStore.effects,
  pathsProgressFunctionListStore.effects,
  pathsProgressLocationListStore.effects,
  pathsProgressContactReadUnreadListStore.effects
];

export const pathsServices = [
  PathsProgressFunctionService,
  PathsProgressLocationService,
  PathsProgressContactReadUnreadService,
  PathsProgressContactService
];
