<nexnox-web-cardbox [title]="'missions.subtitles.mission-nearby' | translate"
                    [fullHeight]="true"
                    [headerActions]="getCardBoxHeaderActions()"
                    paddingClasses="p-3 dashboard-height">

  <div class="d-flex flex-column h-100">
    <div class="d-flex align-items-center row mb-2 px-0 w-100">

      <div class="col p-0" style="max-width:30px">
        <div class="form-group">
          <label class="d-none d-sm-block">&nbsp;</label>
          <div class="d-flex align-items-center">
            <button class="p-button-secondary  p-button-text mr-2"
                    title="{{ 'missions.actions.locate-position' | translate }}"
                    (click)="getUserPosition()">
              <fa-icon [icon]="faMapMarkerAlt"></fa-icon>
            </button>
          </div>
        </div>
      </div>

      <div class="col-md-3">
        <div class="form-group">
          <label>{{ 'missions.fields.location' | translate }}</label>
          <div class="d-flex align-items-center">
            <input id="places"
                   class="form-control"
                   placeholder="{{'missions.fields.enter-address' | translate}}"
            />
          </div>
        </div>
      </div>


      <div class="col-md-4">
        <form [formGroup]="filterForm">
          <formly-form class="row w-100"
                       [form]="filterForm"
                       [(model)]="filterModel"
                       [fields]="filterFields">
          </formly-form>
        </form>
      </div>

      <div class="col-md-2">
        <div class="form-group">
          <label>&nbsp;</label>
          <div>
            <button *ngIf="paging?.pageNumber < paging?.totalPages" class="p-button-secondary p-button-text mr-3"
                    (click)="loadMore()">
              {{ 'missions.actions.load-more' | translate }}
            </button>
            <fa-icon *ngIf="loading$ | async" [icon]="faSpinner" [spin]="true"></fa-icon>
          </div>
        </div>
      </div>


      <div class="col text-right pt-3">
        <span *ngIf="missionMap?.nearby?.length > 0">{{ 'missions.descriptions.missions-nearby' | translate: {
          found: paging?.displayedItems,
          total: paging?.totalItems
        } }}</span>
        <span
          *ngIf="missionMap?.nearby?.length === 0"> {{ 'missions.descriptions.missions-nearby-not-found' | translate }}</span>
      </div>
    </div>

    <div class="flex-grow-1">
      <div id="map" class="h-100"></div>
    </div>
  </div>

</nexnox-web-cardbox>

<p-dialog [(visible)]="showNoUserPositionDialog" [style]="{width: '25vw'}" [baseZIndex]="10000">
  <p>{{ 'missions.descriptions.no-user-position-found' | translate }}</p>
  <ng-template pTemplate="footer">
    <p-button (click)="showNoUserPositionDialog=false" label="{{ 'missions.fields.ok' | translate }}"></p-button>
  </ng-template>
</p-dialog>


