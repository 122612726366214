import {Injectable, Injector} from '@angular/core';
import {CORE_PORTAL_SETTINGS_MENU_CONFIG} from '../token'
import {
  SettingsMenuConfigCategoryDto,
  SettingsMenuConfigItemDto,
  SettingsMenuDto,
  SidebarSettingsItemDto
} from "../models";
import {CorePortalPermissionService} from "@nexnox-web/core-portal";
import {combineLatest, firstValueFrom, Observable, of} from "rxjs";
import {AppPermissions} from "@nexnox-web/core-shared";
import {map} from "rxjs/operators";

@Injectable()
export class CorePortalSettingsMenuService {

	private readonly config: SettingsMenuConfigCategoryDto[];
	private permissionService: CorePortalPermissionService;

	constructor(
		private injector: Injector
	) {
		this.config = this.injector.get(CORE_PORTAL_SETTINGS_MENU_CONFIG) ?? [];
		this.permissionService = this.injector.get(CorePortalPermissionService);
	}

	public getSettingsItem$(): Observable<SidebarSettingsItemDto> {
		return this._hasPermissionForSettingsItem(this.config).pipe(
			map(hasAccess => hasAccess ? {path: '', module: 'settings'} : undefined)
		);
	}

	public async setupMenu(config: SettingsMenuConfigCategoryDto[]): Promise<SettingsMenuDto> {
		const grantedConfig = await this._grantAccessToSettingsMenuConfig(config);
		return this._buildMenu(grantedConfig);
	}

	public async getFirstGrantedItem(): Promise<SettingsMenuConfigItemDto> {
		const grantedConfig = await this._grantAccessToSettingsMenuConfig(this.config);
		return grantedConfig[0]?.items[0];
	}

	private async _grantAccessToSettingsMenuConfig(config: SettingsMenuConfigCategoryDto[]): Promise<SettingsMenuConfigCategoryDto[]> {
		// Parses menu and controls permission of items and access of categories
		for (const category of config?.length ? config : []) {
			for (const item of category?.items ?? []) {
				// Validate items
				item.hasAccess = await firstValueFrom(this.hasAccess$(item.permissions));
			}
			// Validate categories
			category.hasAccess = !category?.items?.every(i => i.hasAccess === false);
		}
		return config ?? [];
	}

	private _hasPermissionForSettingsItem(config: SettingsMenuConfigCategoryDto[]): Observable<boolean> {
		const access = [];
		// Parses menu and controls permission of items and access of categories
		for (const category of config?.length ? config : []) {
			for (const item of category?.items ?? []) {
				access.push(this.hasAccess$(item.permissions));
			}
		}
		return combineLatest(access).pipe(map(access => access.some((value => value === true))));
	}

	private _buildMenu(config: SettingsMenuConfigCategoryDto[]): SettingsMenuDto {
		// Get all chapters
		const chapters = config?.reduce((acc, curr) => acc?.find((v) => v.chapter === curr.chapter) ? acc : [...acc, curr], [])?.map(c => c.chapter);
		// Build & return menu
		const menu: SettingsMenuDto = {chapters: []};
		for (const chapter of chapters) {
			const categories = config?.filter(category => category.chapter === chapter);
			menu.chapters.push({
				title: chapter,
				categories: categories,
				isHidden: categories?.every(category => category.isHidden),
				hasAccess: !categories?.every(category => category.hasAccess === false),
				isExpanded: true
			});
		}
		return menu;
	}

	public hasAccess$(permissions: AppPermissions[]): Observable<boolean> {
		// If every permission of an array is permitted it returns true otherwise false. If no array is given it returns true.
		return (permissions ?? []).length > 0 ? combineLatest(permissions.map(p => this.permissionService.hasPermission$(p))).pipe(map(access => access.every(a => a === true))) : of(true);
	}
}
