import {InjectionToken} from '@angular/core';
import {ControllerOperationId} from "@nexnox-web/core-shared";

export type PredefinedDatatableView = {
  name: string,
  filters?: any[],
  columns?: string[],
  pageSize?: number,
  pageOperation?: ControllerOperationId
}

export interface CorePortalDatatablePredefinedViewConfig {
  [name: string]: PredefinedDatatableView[];
}

export const CORE_PORTAL_DATATABLE_PREDEFINED_VIEWS_CONFIG = new InjectionToken<CorePortalDatatablePredefinedViewConfig>(
  '@nexnox-web/core-portal: Datatable predefined view config'
);
