import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { CORE_SHARED_ENVIRONMENT, Environment, LocalStorageKeys } from '@nexnox-web/core-shared';
import { CorePortalTenantLocalStorageService } from '../../services';
import { mergeMap } from 'rxjs/operators';

@Injectable()
export class LanguageInterceptor implements HttpInterceptor {
  constructor(
    @Inject(CORE_SHARED_ENVIRONMENT) private environment: Environment,
    private tenantLocalStorageService: CorePortalTenantLocalStorageService
  ) {
  }

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return from(this.tenantLocalStorageService.getWithoutFilter(LocalStorageKeys.ACTIVE_LANGUAGE)).pipe(
      mergeMap(activeLanguage => {
        const language = activeLanguage ? activeLanguage : this.environment.defaultLanguage;

        request = request.clone({
          setHeaders: {
            'Accept-Language': language
          }
        });

        return next.handle(request);
      })
    );
  }
}
