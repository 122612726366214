import { Injectable, Injector } from '@angular/core';
import { CoreSharedApiBaseService } from '@nexnox-web/core-shared';

@Injectable()
export class CorePortalStereotypeContractSettingsService extends CoreSharedApiBaseService {
  constructor(
    protected injector: Injector
  ) {
    super(injector, null, 'V2/ticketByContractDefinition');
  }
}
