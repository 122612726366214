import { Directive, Injector, Input, OnInit } from '@angular/core';
import { CorePortalCardboxAction, CorePortalContentContainerService, CorePortalEntityOverviewBaseComponent } from '@nexnox-web/core-portal';
import {
  AppEntityType,
  AppPermissions,
  ContactListDto,
  FilterOperators,
  FilterTypes,
  Mappers,
  NoteDto,
  NoteMailDto,
  NoteType,
  Orders,
  Paging,
  SolutionDto,
  SolutionItemDto,
  SolutionItemListDto,
  SolutionMemberDto,
  SolutionMemberType,
  SortObject,
  SystemDirections
} from '@nexnox-web/core-shared';
import { TechPortalFeatureSolutionItemListXsStore } from '../../store';
import { ofType } from '@ngrx/effects';
import { AnyAction } from '@nexnox-web/core-store';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { XsStoreSolutionActionsFacade } from '../../facades';

@Directive()
export abstract class TechPortalFeatureSolutionBaseComponent extends CorePortalEntityOverviewBaseComponent<SolutionItemDto>
  implements OnInit {
  @Input()
  public set solution(solution: SolutionDto) {
    this.solutionSubject.next(solution);
  }

  public get solution(): SolutionDto {
    return this.solutionSubject.getValue();
  }

  @Input() public relationId: number;
  @Input() public relationIdProperty: string;

  public title = 'title';
  public createTitle = 'solutions.subtitles.solution-item-create';
  public idProperty = 'noteId';
  public displayProperty = null;

  public solution$: Observable<SolutionDto>;

  protected entityActionsFacade: XsStoreSolutionActionsFacade;

  private solutionSubject: BehaviorSubject<SolutionDto> = new BehaviorSubject<SolutionDto>(null);

  private contentContainerService: CorePortalContentContainerService;

  public createNoteHeaderActions: CorePortalCardboxAction[] = this.getCreateEntityCardboxHeaderActions(
    'solutions.actions.create-solution-item',
    AppPermissions.CreateNote
  );

  protected defaultSortOptions: SortObject = {
    sortField: 'createdAt',
    sort: Orders.Descending
  };

  protected constructor(
    protected injector: Injector,
    entityStore: TechPortalFeatureSolutionItemListXsStore,
    protected refreshActions: AnyAction[]
  ) {
    super(injector, entityStore, Mappers.SolutionItemDto.serializedName, AppEntityType.None);

    this.contentContainerService = injector.get(CorePortalContentContainerService);

    /**
     * This will only work as long as there is no customActionsFacade in a child of this component
     * If there is the need for a customActionsFacade in the future, extract the entityStore to be able to use it in ngOnInit
     *
     * Code for ngOnInit:
     * this.customActionsFacade = this.customActionsFacade ?? new XsStoreSolutionActionsFacade(this.injector, entityStore);
     */
    this.customActionsFacade = new XsStoreSolutionActionsFacade(this.injector, entityStore);
  }

  public ngOnInit(): void {
    this.solution$ = this.solutionSubject.asObservable();

    this.solution$.pipe(
      filter(solution => Boolean(solution)),
      take(1)
    ).subscribe(solution => {
      this.parentIds = [solution.solutionId];
      super.ngOnInit();
    });

    for (const refreshAction of this.refreshActions) {
      this.subscribe(this.actions$.pipe(
        ofType(refreshAction)
      ), () => this.getEntities());
    }
  }

  public onMore(paging: Paging): void {
    this.getEntities(
      paging.pageNumber < paging.totalPages ? paging.pageNumber + 1 : paging.pageNumber,
      undefined,
      undefined,
      undefined,
      true
    );
  }

  public onMarkAsSolution(solutionItem: SolutionItemListDto): void {
    this.entityActionsFacade.markItemAsSolution({ id: solutionItem.solutionItemId, parentIds: [this.solution.solutionId] });
  }

  public onUnmarkAsSolution(solutionItem: SolutionItemListDto): void {
    this.entityActionsFacade.unmarkItemAsSolution({ id: solutionItem.solutionItemId, parentIds: [this.solution.solutionId] });
  }

  public onReplyToMail(mail: NoteMailDto): void {
    this.onEditAction();
    setTimeout(() => {
      this.editComponent?.onModelChange({
        note: {
          type: NoteType.Mail,
          direction: SystemDirections.Out,
          to: mail.from.map(x => ({
            emailAddress: x.mailBox.address,
            displayName: x.mailBox.displayName
          } as ContactListDto)),
          cc: mail.cc.map(x => ({
            emailAddress: x.mailBox.address,
            displayName: x.mailBox.displayName
          } as ContactListDto))
        } as NoteMailDto
      });
      this.contentContainerService.scrollTo({ top: 0, behavior: 'smooth' });
    });
  }

  public onReplyToChatMessage(message: NoteDto): void {
    this.onEditAction();
    setTimeout(() => {
      // ToDo: Add info for chat
      this.editComponent?.onModelChange({
        note: {
          type: NoteType.ChatMessage
        } as NoteDto
      });
      this.contentContainerService.scrollTo({ top: 0, behavior: 'smooth' });
    });
  }

  public onFilterSolutionMember(solutionMember: SolutionMemberDto): void {
    if (solutionMember) {
      let property: string;

      switch (solutionMember.type) {
        case SolutionMemberType.Mission:
          property = 'missionId';
          break;
        case SolutionMemberType.Ticket:
          property = 'ticketId';
          break;
      }

      this.defaultFilter = [{
        property,
        operator: FilterOperators.Equal,
        type: FilterTypes.DataTransferObject,
        value: solutionMember.id.toString()
      }];
    } else {
      this.defaultFilter = [];
    }

    this.getEntities();
  }
}
