import {PagedEntitiesXsStore, PagedEntitiesXsStoreState} from '@nexnox-web/core-store';
import {
  AppEntityType,
  KnowledgePathProgressFunctionListDto
} from '@nexnox-web/core-shared';
import {createSelector} from '@ngrx/store';
import {selectKnowledgePathState} from '../../paths.selectors';
import {PathsProgressFunctionService} from "../../services";

export interface PathsProgressFunctionListStoreState extends PagedEntitiesXsStoreState<KnowledgePathProgressFunctionListDto> {
}

/* istanbul ignore next */
export const pathsProgressFunctionListStore = new PagedEntitiesXsStore<KnowledgePathProgressFunctionListDto>({
  actionLabel: 'Orga Portal - Paths - Path Progress Function List',
  stateSelector: createSelector(selectKnowledgePathState, state => state.pathsProgressFunctionList),
  serviceType: PathsProgressFunctionService,
  entityType: AppEntityType.None,
  defaultPageSize: 11,
  selectId: pathsProgressFunction => JSON.stringify([
    pathsProgressFunction.entity?.functionId ?? pathsProgressFunction.model?.functionId
  ]),
  stereotyped: false
});
