<div class="overflow-hidden min-h-100">
  <ng-container *ngIf="(readDashboardPermission$ | async); else emptyTemplate">
    <div class="row mb-3">
      <div class="col-sm-6 pl-0 pr-0">
        <ng-container *ngIf="(loadingSubject | async) === false">
          <nexnox-web-entity-select *ngIf="dashboardSelectOptionsSubject | async as dashboardSelectOptions"
                                    #entitySelectComponent
                                    [model]="selectedDashboardSubject | async"
                                    [options]="dashboardSelectOptions"
                                    [disabled]="loadingSubject | async"
                                    (modelChange)="onSelectDashboard($event)"
                                    (loadedChange)="onLoadedChange($event)"
                                    (availableChange)="onAvailableChange($event)">
          </nexnox-web-entity-select>
        </ng-container>
      </div>
    </div>

    <ng-container *ngIf="(itemsLoadedSubject | async) || (entitySelectComponent?.hasError$ | async); else loadingTemplate">
      <ng-container *ngIf="(dashboardItems$ | async)?.length; else ((itemsAvailableSubject | async) && (loadingSubject | async) ? loadingTemplate : emptyTemplate)">
        <ng-container *ngIf="(loadingSubject | async) === false; else loadingTemplate">
          <ng-container *ngIf="(selectedDashboardSubject | async); else emptyTemplate">
            <ng-container *ngFor="let item of dashboardItems$ | async; trackBy: trackItemsBy">
              <ng-container [ngSwitch]="item.type">
                <ng-container *ngSwitchCase="itemTypes.DataTable"
                              [ngTemplateOutlet]="dashboardItemDatatableTemplate"
                              [ngTemplateOutletContext]="{ item: item }">
                </ng-container>

                <ng-container *ngSwitchDefault>
                  {{ item | json }}
                </ng-container>
              </ng-container>
            </ng-container>
          </ng-container>
        </ng-container>
      </ng-container>
    </ng-container>
  </ng-container>

  <ng-template #dashboardItemDatatableTemplate let-item="item">
    <nexnox-web-new-dashboard-item-datatable [item]="item"
                                             [position]="item.position"
                                             (itemChange)="onUpdateDashboardItem($event)"
                                             (loadPage)="onDashboardLoadPage($event)">
    </nexnox-web-new-dashboard-item-datatable>
  </ng-template>

  <ng-template #emptyTemplate>
    <div class="d-flex w-100 h-100 align-items-center justify-content-center flex-column">
      <fa-icon class="d-flex justify-content-center mb-2" [icon]="faTimes" size="5x"></fa-icon>
      <h4 class="d-flex justify-content-center">{{ 'dashboard.no-dashboards.title' | translate }}</h4>
      <h6 class="d-flex justify-content-center">{{ 'dashboard.no-dashboards.subtitle' | translate }}</h6>
    </div>
  </ng-template>

  <ng-template #loadingTemplate>
    <nexnox-web-cardbox-ghost [noDividerMarginBottom]="true" paddingClasses="p-3"></nexnox-web-cardbox-ghost>
  </ng-template>
</div>
