import {ChangeDetectionStrategy, Component, Injector} from '@angular/core';
import {
  ActionButton,
  CorePortalEntityOverviewBaseComponent,
  DatatableActionButton,
  DeleteEntityModel
} from '@nexnox-web/core-portal';
import {AppEntityType, AppPermissions, GeneralDataProtectionRegulationListDto, Mappers} from '@nexnox-web/core-shared';
import {gdprListStore} from '../../store/stores';

@Component({
  selector: 'nexnox-web-settings-gdpr-list',
  templateUrl: './gdpr-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GdprListComponent extends CorePortalEntityOverviewBaseComponent<GeneralDataProtectionRegulationListDto> {
  public title = 'core-portal.settings.gdpr.titles.list';
  public createTitle = 'core-portal.settings.gdpr.actions.create';
  public idProperty = 'generalDataProtectionRegulationId';
  public displayProperty = 'title';
  public datatableConfigName = 'SettingsGdprDefault';
  public componentId = 'GdprListComponent';

  constructor(
    protected injector: Injector
  ) {
    super(injector, gdprListStore, Mappers.GeneralDataProtectionRegulationListDto.serializedName, AppEntityType.None);
  }

  public getRowActionButtons(): DatatableActionButton[] {
    return this.getDefaultRowActionButtons(
      'core-portal.settings.gdpr.actions.edit',
      (row: GeneralDataProtectionRegulationListDto) => `gdpr/${row.generalDataProtectionRegulationId}`,
      [AppPermissions.UpdateGeneralDataProtectionRegulation],
      {
        module: 'settings'
      }
    );
  }

  public getDeleteEntityModel(): DeleteEntityModel {
    return {
      titleKey: 'core-portal.settings.gdpr.actions.delete',
      descriptionKey:  'core-portal.settings.gdpr.descriptions.delete',
      confirmKey:  'core-portal.settings.gdpr.actions.delete',
      deletePermission: AppPermissions.DeleteGeneralDataProtectionRegulation
    };
  }

  protected async getActionButtons(): Promise<ActionButton[]> {
    return this.getDefaultActionButtons(
      'core-portal.settings.gdpr.actions.create',
      AppPermissions.CreateGeneralDataProtectionRegulation
    );
  }
}
