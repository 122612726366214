import { ModuleWithProviders, NgModule } from '@angular/core';
import { ControlTagsDeclarationsModule } from './control-tags-declarations.module';
import { ControlTagsRoutingModule } from './control-tags-routing.module';
import { ControlTagsStoreModule } from './control-tags-store.module';

@NgModule({
  imports: [
    ControlTagsDeclarationsModule,
    ControlTagsRoutingModule,
    ControlTagsStoreModule
  ]
})
export class TechPortalFeatureCCPControlTagsModule {
  public static forFeature(): ModuleWithProviders<ControlTagsDeclarationsModule> {
    return { ngModule: ControlTagsDeclarationsModule };
  }
}
