<nexnox-web-entity-edit *ngIf="!creating"
                        [entityEditBaseComponent]="this"
                        displayKey="title">
</nexnox-web-entity-edit>

<nexnox-web-tabs *ngIf="creating"
                 [internal]="true"
                 [isTransparent]="true"
                 startTab="entityEdit">
  <!-- Entity Edit Tab -->
  <ng-template nexnoxWebTabTemplate
               tabId="entityEdit"
               [heading]="title | translate"
               [hasError]="!isOwnModelValid()">
    <nexnox-web-entity-edit [entityEditBaseComponent]="this"></nexnox-web-entity-edit>
  </ng-template>

  <!-- Resource Selection -->
  <ng-template nexnoxWebTabTemplate
               tabId="resourceSelection"
               [heading]="'resources.subtitles.resource-list' | translate"
               [hasError]="!isResourceFilter">
    <nexnox-web-resource-tasks-resource-selection-edit [selector]="model?.selector"
                                                       [isEmbedded]="true"
                                                       [canCreate]="false"
                                                       [canRouteToDetail]="false"
                                                       [creating]="creating"
                                                       [readonly]="readonly"
                                                       [loading]="loading"
                                                       (selectorChange)="onSelectorChange($event)">
    </nexnox-web-resource-tasks-resource-selection-edit>
  </ng-template>
</nexnox-web-tabs>

<!-- Consumption Code Templates -->
<ng-template #consumptionCodeSelectLabelTitleTemplate let-item="item">
  <nexnox-web-connected-consumption-codes-consumption-code-external-id [consumptionCode]="item"
                                                                       [isLabel]="true">
  </nexnox-web-connected-consumption-codes-consumption-code-external-id>
</ng-template>

<ng-template #consumptionCodeSelectOptionTitleTemplate let-item="item" let-searchTerm="searchTerm">
  <nexnox-web-connected-consumption-codes-consumption-code-external-id [consumptionCode]="item"
                                                                       [searchTerm]="searchTerm"
                                                                       [isLabel]="false">
  </nexnox-web-connected-consumption-codes-consumption-code-external-id>
</ng-template>
