<ng-template #detailTemplate let-taskjob>
  <nexnox-web-entity-datatable [models]="taskjob.children ?? []"
                               [usesStore]="false"
                               [loading]="loading$ | async"
                               [showLoading]="false"
                               idProp="taskJobId"
                               [createColumnsAutomatically]="true"
                               [serializedNameOrMapper]="serializedNameOrMapper"
                               [defaultColumns]="defaultColumns"
                               [excludedColumns]="excludedColumns"
                               [columnTypings]="defaultColumnTypings"
                               [actionButtons]="getRowActionButtons()"
                               [canDelete]="false"
                               [hideFilters]="true"
                               [hideHeader]="true"
                               [hideSorting]="true">
  </nexnox-web-entity-datatable>
</ng-template>

<nexnox-web-entity-overview #overviewComponent
                            [entityOverviewBaseComponent]="this"
                            [stereotyped]="false"
                            [detailTemplate]="detailTemplate"
                            [showDetailTemplateExpandButtonFn]="showDetailTemplateExpandButtonFn">
</nexnox-web-entity-overview>
