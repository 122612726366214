import {PagedEntitiesXsStore, PagedEntitiesXsStoreState} from '@nexnox-web/core-store';
import {AppEntityType, InfoProgressLocationListDto} from '@nexnox-web/core-shared';
import {createSelector} from '@ngrx/store';
import {selectInfosState} from '../../infos.selectors';
import {InfoProgressLocationService} from "../../services";

export interface InfoProgressLocationListStoreState extends PagedEntitiesXsStoreState<InfoProgressLocationListDto> {
}

/* istanbul ignore next */
export const infoProgressLocationListStore = new PagedEntitiesXsStore<InfoProgressLocationListDto>({
  actionLabel: 'Orga Portal - Infos - Info Progress Location List',
  stateSelector: createSelector(selectInfosState, state => state.infoProgressLocationList),
  serviceType: InfoProgressLocationService,
  entityType: AppEntityType.None,
  defaultPageSize: 11,
  selectId: infoProgressLocation => JSON.stringify([
    infoProgressLocation.entity?.locationId ?? infoProgressLocation.model?.locationId
  ]),
  stereotyped: false
});
