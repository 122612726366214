<ng-template #entityEditTemplate let-title="title" let-id="id">
  <nexnox-web-settings-stereotypes-stereotype-edit #modelComponent
                                                   [title]="title"
                                                   [id]="id"
                                                   [model]="model$ | async"
                                                   [readonly]="readonly$ | async"
                                                   [creating]="false"
                                                   [loading]="loading$ | async"
                                                   [stereotyped]="false"
                                                   (modelChange)="onModelChange($event)">
  </nexnox-web-settings-stereotypes-stereotype-edit>
</ng-template>

<nexnox-web-entity-detail [entityDetailBaseComponent]="this"
                          [entityEditTemplate]="entityEditTemplate"
                          tabId="stereotype"
                          [tabHeading]="title | translate"
                          [tabCommands]="['.']"
                          [ready$]="loaded$">

  <!-- Skeleton Tab -->
  <ng-template nexnoxWebTabTemplate
               *ngIf="isMissionReport$ | async"
               tabId="skeleton"
               [heading]="'core-portal.settings.subtitles.stereotype-skeleton' | translate"
               (selectTab)="onNavigateToTab(['skeleton'])">
    <ng-container *ngIf="model$ | async as model">
      <ng-container *ngIf="skeletons && skeletons[model.entityType]; else noSkeletons">
        <nexnox-web-stereotypes-stereotype-skeleton *ngIf="model.stereotypeId"
                                                    [stereotypeId]="model.stereotypeId"
                                                    [skeletonRootId]="model.defaultSkeleton?.skeletonRootId"
                                                    [skeleton]="skeletons ? skeletons[model.entityType] : null"
                                                    [entityType]="model.entityType">
        </nexnox-web-stereotypes-stereotype-skeleton>
      </ng-container>
    </ng-container>

    <ng-template #noSkeletons>
      <div class="min-h-100">
        <div class="d-flex w-100 h-100 align-items-center justify-content-center flex-column py-5">
          <fa-icon class="d-flex justify-content-center mb-2" [icon]="faTimes" size="5x"></fa-icon>
          <h4
            class="d-flex justify-content-center">{{ 'core-portal.settings.subtitles.stereotype-no-skeleton.title' | translate }}</h4>
          <h6
            class="d-flex justify-content-center">{{ 'core-portal.settings.subtitles.stereotype-no-skeleton.subtitle-disabled' | translate }}</h6>
        </div>
      </div>
    </ng-template>
  </ng-template>

  <!-- Resources by location definition Tab -->
  <ng-template nexnoxWebTabTemplate
               *ngIf="isLocation$ | async"
               tabId="resources-by-location"
               [heading]="'core-shared.shared.skeletons.resource-skeletons'"
               [lazy]="true"
               (selectTab)="onNavigateToTab(['resources-by-location'])">

    <nexnox-web-settings-stereotypes-resources-by-location-definition-list *ngIf="passedIds && +id > 0"
                                                                           [stereotypeId]="id"
                                                                           [parentIds]="passedIds">
    </nexnox-web-settings-stereotypes-resources-by-location-definition-list>

  </ng-template>

  <!-- Resources by location definition Tab -->
  <ng-template nexnoxWebTabTemplate
               *ngIf="isLocation$ | async"
               tabId="tickets-by-location"
               [heading]="'core-shared.shared.skeletons.ticket-skeletons'"
               [lazy]="true"
               (selectTab)="onNavigateToTab(['tickets-by-location'])">

    <nexnox-web-settings-stereotypes-tickets-by-location-definition-list *ngIf="passedIds && +id > 0"
                                                                         [stereotypeId]="id"
                                                                         [parentIds]="passedIds">
    </nexnox-web-settings-stereotypes-tickets-by-location-definition-list>

  </ng-template>

  <!-- Contract Settings Tab -->
  <ng-template nexnoxWebTabTemplate
               *ngIf="isContract$ | async"
               tabId="contract-settings"
               [heading]="'core-portal.settings.subtitles.contract-settings'"
               [lazy]="true"
               (selectTab)="onNavigateToTab(['contract-settings'])">
    <ng-container *ngIf="model$ | async as model">
      <nexnox-web-stereotypes-contract-settings-detail [contractStereotypeId]="model?.stereotypeId"
                                                       [ticketByContractSimpleDefinition]="model?.ticketByContractDefinition"
                                                       [readonly$]="readonly$">
      </nexnox-web-stereotypes-contract-settings-detail>
    </ng-container>
  </ng-template>
</nexnox-web-entity-detail>
