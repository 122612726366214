<ng-template #entityEditTemplate let-title="title" let-id="id">
  <nexnox-web-contracts-contract-edit #modelComponent
                                      [title]="title"
                                      [id]="id"
                                      [model]="model$ | async"
                                      [readonly]="readonly$ | async"
                                      [creating]="false"
                                      [loading]="loading$ | async"
                                      [stereotypes$]="stereotypes$"
                                      (modelChange)="onModelChange($event)">
  </nexnox-web-contracts-contract-edit>
</ng-template>

<nexnox-web-entity-detail [entityDetailBaseComponent]="this"
                          [entityEditTemplate]="entityEditTemplate"
                          tabId="contract"
                          [tabHeading]="title | translate"
                          [tabCommands]="['.']">
  <!-- Links -->
  <ng-template nexnoxWebTabTemplate
               tabId="links"
               [heading]="('tech-portal.subtitles.links' | translate) + (linksComponent ? (linksComponent.linksString$ | async) : '')"
               [hasError]="linksComponent ? !linksComponent.isModelValid() : false"
               (selectTab)="onNavigateToTab(['links'])">
    <nexnox-web-tech-links *ngIf="model$ | async as model"
                           #linksComponent
                           [links$]="links$"
                           [entityId]="model.contractId"
                           [type]="linkedElementTypes.Contract"
                           [readonly]="readonly$ | async"
                           [loading]="loading$ | async"
                           (linksChange)="onLinksChange($event, model)">
    </nexnox-web-tech-links>
  </ng-template>

  <!-- Attachments -->
  <ng-template nexnoxWebTabTemplate
               tabId="attachments"
               [heading]="('tech-portal.subtitles.attachments' | translate) + (attachmentsComponent ? (attachmentsComponent.attachmentsString$ | async) : '')"
               [hasError]="attachmentsComponent ? !attachmentsComponent.isModelValid() : false"
               (selectTab)="onNavigateToTab(['attachments'])">
    <nexnox-web-tech-attachments *ngIf="model$ | async as model"
                                 #attachmentsComponent
                                 [attachments$]="attachments$"
                                 [readonly]="readonly$ | async"
                                 [loading]="loading$ | async"
                                 (attachmentsChange)="onAttachmentsChange($event, model)">
    </nexnox-web-tech-attachments>
  </ng-template>

  <!-- Ticket List -->
  <ng-template nexnoxWebTabTemplate
               tabId="tickets"
               *ngIf="true"
               [heading]="'tickets.title' | translate"
               [lazy]="true"
               (selectTab)="onNavigateToTab(['tickets'])">
    <nexnox-web-contracts-contract-tickets *ngIf="passedIds"
                                         [isEmbedded]="true"
                                         [parentIds]="[passedIds[0]]">
    </nexnox-web-contracts-contract-tickets>
  </ng-template>

  <!-- Audit -->
  <ng-template nexnoxWebTabTemplate
               tabId="audit"
               *ngIf="readAuditPermission$ | async"
               [heading]="'core-portal.audit.title' | translate"
               [lazy]="true"
               (selectTab)="onNavigateToTab(['audit'])">
    <nexnox-web-contracts-contract-audit *ngIf="passedIds"
                                         [isEmbedded]="true"
                                         [parentIds]="[entityTypes.Contract, passedIds[0]]">
    </nexnox-web-contracts-contract-audit>
  </ng-template>
</nexnox-web-entity-detail>

