import { PagedEntitiesXsStore, PagedEntitiesXsStoreState } from '@nexnox-web/core-store';
import { AppEntityType, TicketByProcessDefinitionDto } from '@nexnox-web/core-shared';
import { createSelector } from '@ngrx/store';
import { selectProcessesState } from '../../processes.selectors';
import { TechPortalFeatureTicketSettingsProcessService } from '../../services';

export interface ProcessListStoreState extends PagedEntitiesXsStoreState<TicketByProcessDefinitionDto> {
}

/* istanbul ignore next */
export const processListStore = new PagedEntitiesXsStore<TicketByProcessDefinitionDto>({
  actionLabel: 'Tech Portal - Ticket Settings - Processes - Process List',
  stateSelector: createSelector(selectProcessesState, state => state.processList),
  serviceType: TechPortalFeatureTicketSettingsProcessService,
  entityType: AppEntityType.None,
  selectId: process => process.entity?.ticketByProcessDefinitionId ?? process.model?.ticketByProcessDefinitionId,
  stereotyped: false
});
