import {Component, OnDestroy, OnInit} from '@angular/core';
import {ApiNotificationService, CoreSharedGoogleMapsService} from '@nexnox-web/core-shared';
import {FieldType, FormlyTemplateOptions} from '@ngx-formly/core';
import {isArray, isEqual, isUndefined} from 'lodash';
import {
  BehaviorSubject,
  combineLatestWith,
  debounceTime,
  filter,
  map,
  mergeAll,
  Observable,
  of,
  pairwise,
  queueScheduler,
  scheduled,
  startWith,
  Subscription,
  take,
  takeWhile,
  tap
} from 'rxjs';

export interface CorePortalFormlyGoogleMapsTyping {
  width?: string;
  height?: string;
  initialCenter?: google.maps.LatLngLiteral;
  mapOptions?: google.maps.MapOptions;
  isAutomaticAddressKeySearch?: boolean;
  addressKeys?: string[]; // Address keys (street, housenumber, city...) of your form
}

interface FormlyGoogleMapsTemplateOptions extends FormlyTemplateOptions {
  corePortalGoogleMap: CorePortalFormlyGoogleMapsTyping;
}

@Component({
  selector: 'nexnox-web-formly-google-map',
  templateUrl: './formly-google-map.component.html'
})
export class FormlyGoogleMapComponent extends FieldType implements OnInit, OnDestroy {

  // Formly
  public readonly to: FormlyGoogleMapsTemplateOptions;

  // Maps
  public mapDiv: HTMLDivElement;
  public map: google.maps.Map;
  public width: string;
  public height: string;
  public mapOptions: google.maps.MapOptions;
  public detailZoom = 17;
  public marker: google.maps.Marker;

  // Places
  public placesInput: HTMLInputElement;
  public placeAutoComplete: google.maps.places.Autocomplete;
  public addressKeys: string[];
  public isManualPlacesSearch: boolean;

  // Initial conditions
  public apiLoaded$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public componentReady$: Observable<boolean> = of(false);
  public isNoPointSetButValidAddress: boolean;

  private _detectChangesSubscription: Subscription;
  private _placesService: google.maps.places.PlacesService;
  private _isDetectChanges: boolean;

  constructor(
    private mapsService: CoreSharedGoogleMapsService,
    private apiNotificationService: ApiNotificationService
  ) {
    super();
  }

  public ngOnInit(): void {
    // Size
    this.width = this.to?.corePortalGoogleMap?.width ?? '500px';
    this.height = this.to?.corePortalGoogleMap?.height ?? '500px';

    // Automatic search with given address fields defined within template options
    this.addressKeys = isArray(this.to?.corePortalGoogleMap?.addressKeys) ? this.to.corePortalGoogleMap.addressKeys : [];
    this._isDetectChanges = !isUndefined(this.to?.corePortalGoogleMap?.isAutomaticAddressKeySearch) && this.to.corePortalGoogleMap.isAutomaticAddressKeySearch === true && this._isAddressKeyArrayValid();
    this.isManualPlacesSearch = this._isDetectChanges === false;

    // When address is set but no point has been saved yet
    // (Shows a take-over-address-button in case of an existing address, that has been entered before this google maps component was implemented)
    this.form.statusChanges.pipe(
      filter((status) => status !== 'DISABLED'),
      take(1)
    ).subscribe(() => {
      const key = isArray(this.key) ? this.key[0] : this.key;
      this.isNoPointSetButValidAddress = this._isDetectChanges && this._isModelAddressValid() && !this.model[key];
    });

    // Initialize
    this.initializeComponent();
  }

  public ngOnDestroy(): void {
    if (this._detectChangesSubscription) this._detectChangesSubscription.unsubscribe();
  }

  public initializeComponent(): void {
    // Load maps api with service
    const isApiLoaded$ = this.mapsService.getApiStatus();
    this.mapsService.loadMapsApi();

    // Get elements
    const mapsDiv$ = this.mapsService.getElementById('map');
    const placesInput$ = !this._isDetectChanges ? this.mapsService.getElementById('places') : of(true);

    // Wait and set elements
    this.componentReady$ = isApiLoaded$.pipe(
      combineLatestWith(mapsDiv$, placesInput$),
      map(([isApi, mapDiv, placesInput]) => {
        if ((isApi && mapDiv && placesInput)) {
          this.mapDiv = mapDiv;
          this.placesInput = placesInput;
          return true;
        } else {
          return false;
        }
      })
    );

    // Initialize when ready
    this.componentReady$.pipe(
      tap((isReady) => {
        if (isReady) this.initializeMaps();
      }),
      takeWhile((isReady) => isReady !== true)
    ).subscribe();
  }

  public initializeMaps(): void {
    this.initMap();
    this.initPlaces();
    this.initChangeDetection();
    this.initCenter();
  }

  public initMap(): void {
    // Create map
    this.map = new google.maps.Map(this.mapDiv);

    // Map options
    this.mapOptions = this.to?.corePortalGoogleMap?.mapOptions ?? undefined;
    if (!this.mapOptions) {
      this.mapOptions = {
        streetViewControl: false,
        zoom: this.detailZoom
      }
    }
    this.map.setOptions(this.mapOptions);

    // Map style
    // Generate a style json here:
    // https://mapstyle.withgoogle.com/
    this.map.set('styles', [
      {
        "featureType": "administrative.land_parcel",
        "elementType": "labels",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      },
      {
        "featureType": "poi",
        "elementType": "labels",
        "stylers": [
          {
            "visibility": "off"
          }
        ]
      }
    ]);
  }

  public initPlaces(): void {
    this._placesService = this.mapsService.getPlacesService(this.map);

    if (!this._isDetectChanges) {
      this.placeAutoComplete = new google.maps.places.Autocomplete(this.placesInput as HTMLInputElement, {});
      this.placeAutoComplete.bindTo('bounds', this.map);
      this.placeAutoComplete.addListener('place_changed', () => {
        const place = this.placeAutoComplete.getPlace();
        this.setLocation(place.geometry.location.toJSON(), '');
        this._setPointToModel(place.geometry.location.toJSON());
      });
    }
  }

  /* istanbul ignore next */
  public initChangeDetection(): void {
    // Building a merged controls$ array out of addressKey fields,
    // because form.valueChanges did not get triggered correctly
    if (this._isDetectChanges) {
      const controls$ = [];
      for (let i = 0; i < this.addressKeys.length; i++) {
        controls$.push(this.form.get(this.addressKeys[i])?.valueChanges.pipe(
          startWith(this.form.get(this.addressKeys[i]).value),
          debounceTime(1000),
          pairwise()
        ));
      }
      this._detectChangesSubscription = scheduled(controls$, queueScheduler).pipe(
        mergeAll(),
        filter(() => this._isModelAddressValid() && !this.to.disabled && !this.to.readonly),
        filter(([previous, current]) => this._isDistinct(previous, current)),
      ).subscribe(() => this.takeOverAddress());
    }
  }

  public initCenter(): void {
    if (this.model.point) {
      this.setLocation(this.model.point as google.maps.LatLngLiteral, '')
    } else if (this.to?.corePortalGoogleMap?.initialCenter) {
      this.setLocation(this.to.corePortalGoogleMap.initialCenter, '');
    } else {
      // Go to Berlin
      this.map.setZoom(5);
      this.setLocation({lat: 52.58462545984763, lng: 13.411000359874432}, '')
    }
  }

  public takeOverAddress(): void {
    this.searchPlace(this._getQueryFromModel(), this.detailZoom, true);
  }

  public searchPlace(query: string, zoom?: number, setPointToModel: boolean = true): void {

    // Building request
    const request: google.maps.places.FindPlaceFromQueryRequest = {
      fields: ['formatted_address', 'geometry'],
      query: query,
      language: 'de'
    }

    // Searching...
    this._placesService.findPlaceFromQuery(request, (results: google.maps.places.PlaceResult[], status) => {

      // Error Handling
      if (status !== google.maps.places.PlacesServiceStatus.OK) {
        this.apiNotificationService.showTranslatedError('core-portal.core.error.google-places-status');
        this._setPointToModel({lat: 0, lng: 0});
        return;
      }

      // Parse result
      const location = isArray(results) ? results[0]?.geometry?.location?.toJSON() : undefined;
      const label = isArray(results) ? results[0]?.formatted_address : '';

      if (location) {

        if (setPointToModel) {
          this._setPointToModel(location);
        }

        if (zoom) {
          this.map.setZoom(zoom);
        }

        // Set location to map and model
        this.setLocation(location, label);

      } else {
        // Fly over europe
        this.searchPlace('Europe', 5, false);
      }
    });
  }

  public setLocation(location: google.maps.LatLngLiteral, label: string): void {
    // Delete previous marker
    if (this.marker instanceof google.maps.Marker) {
      this.marker.setMap(null);
      delete this.marker;
    }
    // Place new marker
    this.marker = new google.maps.Marker({
      map: this.map,
      draggable: !this.to.disabled,
      clickable: true,
      position: location,
      animation: google.maps.Animation.DROP
    });
    this.marker.addListener('dragstart', () => {
      this.marker.setLabel(null);
    })
    this.marker.addListener('dragend', (event) => {
      this._setPointToModel(event.latLng.toJSON())
    });
    this.marker.addListener('dblclick', (event) => {
      this.map.setCenter(event.latLng.toJSON());
      this.map.setZoom(this.detailZoom);
    });
    this.marker.setLabel(label);
    // Label to places input
    if (!this._isDetectChanges) {
      this.placesInput.value = label;
    }
    // Center map
    this.map.setCenter(location);
  }

  private _setPointToModel(location: google.maps.LatLngLiteral): void {
    this.field.formControl.setValue(location);
  }

  private _getQueryFromModel(): string {
    let query = '';
    if (this._isModelAddressValid()) {
      for (let i = 0; i < this.addressKeys.length; i++) {
        query += this.model[this.addressKeys[i]];
        query += i >= 0 && i < this.addressKeys.length - 1 ? ', ' : '';
      }
    }
    return query;
  }

  private _isModelAddressValid(): boolean {
    for (let i = 0; i < this.addressKeys.length; i++) {
      const value = this.model[this.addressKeys[i]];
      if (!value || value.length === 0) return false;
    }
    return true;
  }

  private _isDistinct(previous: any, current: any): boolean {
    return !isEqual(previous, current);
  }

  private _isAddressKeyArrayValid(): boolean {
    if (isArray(this.addressKeys) && this.addressKeys.length > 0) {
      return true;
    } else {
      throw new Error(`No addressKeys array defined in template options. Can't take over address from formly model.
      Define the form-keys for road, housenumber, zipcode, city, country or use manual places search.`);
    }
    ;
  }
}
