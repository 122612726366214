import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {BehaviorSubject, debounceTime, distinctUntilKeyChanged, Observable, of} from 'rxjs';
import {
  AlternativeEditorSuggestionDto,
  BasicTableColumnType,
  BasicTableConfig,
  ContactDto,
  EditorSuggestionByContactDto,
  EditorSuggestionByFunctionDto,
  EditorSuggestionTargetType,
  FunctionDto,
  PrimaryEditorSuggestionDto,
  PriorityDto,
  ResourceDto,
  UnsubscribeHelper
} from '@nexnox-web/core-shared';
import {CorePortalCardboxAction, CoreSharedRuleEditorListComponent} from '@nexnox-web/core-portal';
import {SuggestionEditSidebarComponent} from "../../sidebars";
import {cloneDeep, isNumber} from "lodash";
import {faTrash} from '@fortawesome/free-solid-svg-icons/faTrash';
import {faPen} from '@fortawesome/free-solid-svg-icons';
import {faPlus} from '@fortawesome/free-solid-svg-icons/faPlus';
import {TechPortalFeatureResourceService} from "../../store";
import {distinctUntilChanged, filter, map} from "rxjs/operators";
import {FormControl, FormGroup} from "@angular/forms";
import {FormlyFieldConfig} from "@ngx-formly/core";
import {TranslateService} from "@ngx-translate/core";

export interface LocalEditorSuggestionDto {
  id: number,
  index?: number,
  priority?: PriorityDto,
  displayName: string,
  target: EditorSuggestionByFunctionDto | EditorSuggestionByContactDto | any,
  byContact?: ContactDto,
  byFunction?: FunctionDto,
  processingTime?: string,
  type: EditorSuggestionTargetType,
  isPrimary: boolean
}

export interface EditorSuggestionsChangedEvent {
  primary: PrimaryEditorSuggestionDto[],
  alternative: AlternativeEditorSuggestionDto[],
  isInheritSuggestions: boolean
}

@Component({
  selector: 'nexnox-web-resources-editor-suggestions',
  templateUrl: './editor-suggestions.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditorSuggestionsComponent extends UnsubscribeHelper implements OnInit {
  @Input() public resourceSubject: BehaviorSubject<ResourceDto>;
  @Input() public stereotypeId$: Observable<number>;
  @Input() public primarySuggestions$: Observable<PrimaryEditorSuggestionDto[]>;
  @Input() public alternativeSuggestions$: Observable<AlternativeEditorSuggestionDto[]>;
  @Input() public readonly: boolean;
  @Input() public loading: boolean;

  @ViewChild('suggestionEditSidebarComponent', {static: true}) public suggestionEditSidebarComponent: SuggestionEditSidebarComponent;

  @ViewChild('solutionTemplatesEdit') public primarySuggestionsEdit: CoreSharedRuleEditorListComponent;

  @Output() public editorSuggestionsChanged: EventEmitter<EditorSuggestionsChangedEvent> = new EventEmitter<EditorSuggestionsChangedEvent>();

  public hasParent$: Observable<boolean>;
  public hasParent: boolean;
  public parentResourceName = '';

  public form: FormGroup;
  public fields: FormlyFieldConfig[];
  public model: { isInheritSuggestions: boolean };

  public primaryBasicTableConfig: BasicTableConfig;
  public alternativeBasicTableConfig: BasicTableConfig;

  public faPen = faPen;
  public faTrash = faTrash;
  public faPlus = faPlus;

  public primarySuggestionsSubject: BehaviorSubject<LocalEditorSuggestionDto[]> = new BehaviorSubject<LocalEditorSuggestionDto[]>([]);
  public alternativeSuggestionsSubject: BehaviorSubject<LocalEditorSuggestionDto[]> = new BehaviorSubject<LocalEditorSuggestionDto[]>([]);

  private _stereotypeId: number;

  constructor(
    private resourceService: TechPortalFeatureResourceService,
    private translate: TranslateService
  ) {
    super();
  }

  /* istanbul ignore next */
  public ngOnInit(): void {

    this.form = new FormGroup({isInheritSuggestions: new FormControl()});

    this.hasParent$ = this.resourceSubject.pipe(map((resource) => Boolean(resource?.parent)));

    this.subscribe(this.stereotypeId$.pipe(distinctUntilChanged()), (id => this._stereotypeId = id));

    this.subscribe(
      this.primarySuggestions$, (suggestions) => this.primarySuggestionsSubject.next(this._mapPrimarySuggestionsToLocalDto(suggestions)));

    this.subscribe(
      this.alternativeSuggestions$, (suggestions) => this.alternativeSuggestionsSubject.next(this._mapAlternativeSuggestionsToLocalDto(suggestions)));

    // Check for isInheritSuggestions
    this.subscribe(this.resourceSubject.pipe(filter((resource) => Boolean(resource))),
      (resource => {
        this.hasParent = !!resource?.parent?.resourceId;
        this.form.get('isInheritSuggestions').setValue(resource?.inheritsSuggestions ?? this.hasParent);
        this.parentResourceName = this.hasParent ? resource?.parent?.name : '';
      })
    );

    // Emit when inheritsKnowledge changes
    this.subscribe(this.form.valueChanges.pipe(debounceTime(333), distinctUntilKeyChanged('isInheritSuggestions')),
      () => this._convertAndEmitSuggestions(this.primarySuggestionsSubject.getValue(), this.alternativeSuggestionsSubject.getValue())
    );


    this.fields = [
      {
        key: 'isInheritSuggestions',
        type: 'core-portal-switch',
        wrappers: ['core-portal-translated'],
        className: 'col-auto pr-0',
        defaultValue: this.resourceSubject.getValue()?.inheritsSuggestions ?? this.hasParent ?? true,
        templateOptions: {
          corePortalTranslated: {
            label: 'resources.actions.inherit-from-parent'
          }
        },
        expressionProperties: {
          'templateOptions.disabled': () => this.readonly || this.loading
        }
      }
    ]

    this.primaryBasicTableConfig = {
      columns: [
        {
          header: 'resources.fields.priority',
          key: 'priority.name',
          type: BasicTableColumnType.Text
        },
        {
          header: 'resources.subtitles.contact-function',
          key: 'displayName',
          type: BasicTableColumnType.Link,
          link: (row) => row?.target?.contact?.contactId ? {
            commands: ['masterdata', 'contacts', row.target.contact.contactId],
            module: 'management'
          } : row?.target?.function?.functionId ? {
            commands: ['masterdata', 'functions', row.target.function.functionId],
            module: 'management'
          } : null
        },
        {
          header: 'resources.fields.solution-offset',
          key: 'processingTime',
          type: BasicTableColumnType.HumanizedTimeSpan
        }
      ],
      actions: [
        {
          icon: faPen,
          tooltip: 'core-shared.shared.table.tooltip.edit',
          disabled: () => this.readonly || (this.model?.isInheritSuggestions && this.hasParent),
          click: (row, index) => this.openSidebar(true, row, index)
        },
        {
          icon: faTrash,
          tooltip: 'core-shared.shared.table.tooltip.delete',
          disabled: () => this.readonly || (this.model?.isInheritSuggestions && this.hasParent),
          click: (row, index) => this.onRemoveSuggestion(row, index)
        }
      ]
    }

    this.alternativeBasicTableConfig = {
      columns: [
        {
          header: 'resources.subtitles.contact-function',
          key: 'displayName',
          type: BasicTableColumnType.Link,
          link: (row) => row?.target?.contact?.contactId ? {
            commands: ['masterdata', 'contacts', row.target.contact.contactId],
            module: 'management'
          } : row?.target?.function?.functionId ? {
            commands: ['masterdata', 'functions', row.target.function.functionId],
            module: 'management'
          } : null
        }
      ],
      actions: [
        {
          icon: faPen,
          tooltip: 'core-shared.shared.table.tooltip.edit',
          disabled: () => this.readonly || (this.model?.isInheritSuggestions && this.hasParent),
          click: (row, index) => this.openSidebar(false, row, index)
        },
        {
          icon: faTrash,
          tooltip: 'core-shared.shared.table.tooltip.delete',
          disabled: () => this.readonly || (this.model?.isInheritSuggestions && this.hasParent),
          click: (row, index) => this.onRemoveSuggestion(row, index)
        }
      ]
    }
  }

  public openSidebar(isPrimarySuggestion: boolean, suggestion?: LocalEditorSuggestionDto, index?: number): void {
    this.suggestionEditSidebarComponent.onShow(isPrimarySuggestion, cloneDeep(suggestion), index, this.primarySuggestionsSubject.getValue());
  }

  public onRemoveSuggestion(suggestion, index: number): void {
    const primarySuggestions = this.primarySuggestionsSubject.value;
    const alternativeSuggestions = this.alternativeSuggestionsSubject.value;
    if (suggestion.isPrimary) {
      primarySuggestions.splice(index, 1);
      this.primarySuggestionsSubject.next(primarySuggestions);
    } else {
      alternativeSuggestions.splice(index, 1);
      this.alternativeSuggestionsSubject.next(alternativeSuggestions);
    }
    this._convertAndEmitSuggestions(primarySuggestions, alternativeSuggestions);
  }

  public onSuggestionSaved(suggestion: LocalEditorSuggestionDto): void {
    // Convert
    suggestion = {
      ...suggestion,
      target: {
        contact: suggestion.type === EditorSuggestionTargetType.ByContact ? suggestion.byContact : undefined,
        functionProperty: suggestion.type === EditorSuggestionTargetType.ByFunction  ? suggestion.byFunction : undefined,
        function: suggestion.type === EditorSuggestionTargetType.ByFunction  ? suggestion.byFunction : undefined,
        type: suggestion.type,
        processingTime: suggestion.processingTime
      }
    }
    // Add to suggestions
    const primarySuggestions = this.primarySuggestionsSubject.value;
    const alternativeSuggestions = this.alternativeSuggestionsSubject.value;
    if (suggestion.isPrimary) {
      const index = suggestion.index ?? -1;
      index !== -1 ? primarySuggestions[index] = suggestion : primarySuggestions.push(suggestion);
      this.primarySuggestionsSubject.next(primarySuggestions);
    } else {
      const index = suggestion.index ?? -1;
      index !== -1 ? alternativeSuggestions[index] = suggestion : alternativeSuggestions.push(suggestion);
      this.alternativeSuggestionsSubject.next(alternativeSuggestions);
    }
    this._convertAndEmitSuggestions(primarySuggestions, alternativeSuggestions);
  }

  /* istanbul ignore next */
  public createPrimaryActions(): CorePortalCardboxAction[] {
    return [
      {
        label: 'resources.actions.add-editor',
        class: 'p-button-outlined p-button-primary',
        icon: faPlus,
        buttonSize: 'sm',
        isLoading: () => of(this.loading),
        shouldShow: () => of(!(this.readonly || this.model?.isInheritSuggestions)),
        callback: () => this.openSidebar(true)
      }
    ];
  }

  /* istanbul ignore next */
  public createAlternativeActions(): CorePortalCardboxAction[] {
    return [
      {
        label: 'resources.actions.add-editor',
        class: 'p-button-outlined p-button-primary',
        icon: faPlus,
        buttonSize: 'sm',
        isLoading: () => of(this.loading),
        shouldShow: () => of(!(this.readonly || this.model?.isInheritSuggestions)),
        callback: () => this.openSidebar(false)
      }
    ];
  }

  private _convertAndEmitSuggestions(primarySuggestions: LocalEditorSuggestionDto[], alternativeSuggestions: LocalEditorSuggestionDto[]): void {

    this.editorSuggestionsChanged.emit({
      isInheritSuggestions: this.model?.isInheritSuggestions,

      primary: primarySuggestions.map(s => ({
        id: isNumber(s.id) ? s.id : undefined,
        priority: s.priority,
        target: s.target
      })) as PrimaryEditorSuggestionDto[],

      alternative: alternativeSuggestions.map(s => ({
        id: isNumber(s.id) ? s.id : undefined,
        target: s.target
      })) as AlternativeEditorSuggestionDto[]
    });
  }

  private _mapPrimarySuggestionsToLocalDto(suggestions: PrimaryEditorSuggestionDto[] | LocalEditorSuggestionDto[]): LocalEditorSuggestionDto[] {
    return suggestions.map((s) => ({
      id: s.id,
      displayName: this._getDisplayName(s.target),
      priority: s.priority,
      target: s.target,
      processingTime: s.target.processingTime,
      type: s.target.type,
      byFunction: s.target?.function,
      byContact: s.target?.contact,
      isPrimary: true
    } as LocalEditorSuggestionDto));
  }

  private _mapAlternativeSuggestionsToLocalDto(suggestions: AlternativeEditorSuggestionDto[] | LocalEditorSuggestionDto[]): LocalEditorSuggestionDto[] {
    return suggestions.map((s) => ({
      id: s.id,
      displayName: this._getDisplayName(s.target),
      target: s.target,
      type: s.target.type,
      byFunction: s.target?.function,
      byContact: s.target?.contact,
      isPrimary: false
    } as LocalEditorSuggestionDto));
  }

  private _getDisplayName(target: EditorSuggestionByFunctionDto | EditorSuggestionByContactDto): string {
    return `${target.type === EditorSuggestionTargetType.ByContact ?
      (target as EditorSuggestionByContactDto)?.contact?.displayName :
      (target as EditorSuggestionByFunctionDto | any)?.function?.name} (${this.translate.instant(
      `resources.suggestion-target-types.${target.type}`)})`;
  }
}
