import { EntityXsStore, EntityXsStoreState } from '@nexnox-web/core-store';
import { AppEntityType, StateDto } from '@nexnox-web/core-shared';
import { Action, createSelector } from '@ngrx/store';
import { selectStatesState } from '../../states.selectors';
import { TechPortalFeatureTicketSettingsStateService } from '../../services';
import { Injectable, Injector } from '@angular/core';

export interface StateDetailStoreState extends EntityXsStoreState<StateDto> {
}

/* istanbul ignore next */
export const stateDetailStore = new EntityXsStore<StateDto>({
  actionLabel: 'Tech Portal - Ticket Settings - States - State Detail',
  stateSelector: createSelector(selectStatesState, state => state.stateDetail),
  serviceType: TechPortalFeatureTicketSettingsStateService,
  entityType: AppEntityType.None,
  stereotyped: false
});

export function stateDetailStoreReducer(state: StateDetailStoreState, action: Action): any {
  return stateDetailStore.reducer(state, action);
}

@Injectable()
export class StateDetailStoreEffects extends stateDetailStore.effects {
  constructor(
    protected injector: Injector
  ) {
    super(injector);
  }
}
