import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { InheritedField } from '../rule-editor-list/rule-editor-list.component';
import { cloneDeep, isEqual, isUndefined, mapValues, flatten } from 'lodash';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { FileDto, UnsubscribeHelper } from '@nexnox-web/core-shared';

@Component({
  selector: 'nexnox-web-rule-editor-list-item',
  styleUrls: ['./rule-editor-list-item.component.scss'],
  templateUrl: './rule-editor-list-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CoreSharedRuleEditorListItemComponent extends UnsubscribeHelper implements OnInit {
  public get item(): any {
    return this.itemSubject.getValue();
  }

  @Input()
  public set item(item: any) {
    this.itemSubject.next(cloneDeep(item));
  }

  @Input() public imageKey: string;
  @Input() public inheritedFieldsFn: () => InheritedField[];
  @Input() public disabled: boolean;
  @Input() public inherited: boolean;

  @Output() public itemChange: EventEmitter<any> = new EventEmitter<any>();

  public form: FormGroup;
  public fields: FormlyFieldConfig[];
  public model$: Observable<any>;

  public image$: Observable<FileDto>;

  private itemSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  public ngOnInit(): void {
    this.form = new FormGroup({});
    this.fields = this.createForm();
    this.model$ = this.itemSubject.asObservable().pipe(
      distinctUntilChanged((a, b) => isEqual(a, b))
    );

    this.image$ = this.itemSubject.asObservable().pipe(
      map(item => {
        if (!item || !this.imageKey || !(item[this.imageKey] as FileDto)?.uri) return null;

        switch ((item[this.imageKey] as FileDto).mimeType) {
          case 'image/jpg':
          case 'image/jpeg':
          case 'image/png':
            return (item[this.imageKey] as FileDto);
          default:
            return null;
        }
      })
    );
  }

  public onModelChange(model: any): void {
    const newModel = cloneDeep(model);
    this.itemChange.emit(newModel);
    this.itemSubject.next(newModel);
  }

  public isModelValid(): boolean {
    return this.form.valid || this.form.disabled;
  }

  /* istanbul ignore next */
  private createForm(): FormlyFieldConfig[] {
    const inheritedFields = this.inheritedFieldsFn();

    return flatten(inheritedFields.map((field, index) => {
      const { className: smartClassNameExpression, ...smartFieldExpressions } = mapValues(
        field.smartExpressionProperties,
        property => (model, formState, field) => property(model, formState, false, field)
      );
      const { className: classNameExpression, ...fieldExpressions } = field.formlyConfig.expressionProperties ?? {};

      return [
        {
          className: field.formlyConfig.className ??
            `col-12 col-md pl-0 pr-0 ${(index >= inheritedFields.length - 1 || field.noPR) ? 'pr-0' : 'pr-md-3'}`,
          fieldGroupClassName: 'row p-0',
          fieldGroup: [
            {
              ...field.formlyConfig,
              key: field.ownValueKey,
              className: !isUndefined(field.inheritedKey) && this.inherited ? 'col pl-0' : 'col-md-12 p-0',
              expressionProperties: {
                ...fieldExpressions,
                ...smartFieldExpressions,
                'templateOptions.disabled': () => field.disable ? field.disable(this.item, false, this.disabled) : this.disabled
              },
              hideExpression: model => !isUndefined(field.inheritedKey) && this.inherited ? model[field.inheritedKey] : false
            },
            ...(!isUndefined(field.inheritedKey) && this.inherited ? [
              {
                ...field.formlyConfig,
                key: field.inheritedValueKey,
                className: 'col pl-0',
                templateOptions: {
                  ...(field.formlyConfig.templateOptions ?? {}),
                  disabled: true
                },
                expressionProperties: {
                  ...(field.formlyConfig.expressionProperties ?? {})
                },
                hideExpression: model => !model[field.inheritedKey]
              },
              {
                key: field.inheritedKey,
                type: 'core-portal-switch',
                wrappers: ['core-portal-translated'],
                className: 'col-auto pr-0',
                defaultValue: false,
                templateOptions: {
                  corePortalTranslated: {
                    label: 'core-shared.shared.fields.is-inherited'
                  }
                },
                expressionProperties: {
                  'templateOptions.disabled': () => field.disable ? field.disable(this.item, false, this.disabled) : this.disabled
                }
              }
            ] : [])
          ],
          expressionProperties: classNameExpression || smartClassNameExpression ? {
            'className': classNameExpression ?? smartClassNameExpression
          } : {},
          hideExpression: () => field.hide ? field.hide(this.item[field.ownValueKey], false, this.item) : false
        },
        ...(field.additionalFields ?? []).map(additionalField => ({
          hideExpression: () => additionalField?.templateOptions?.ruleEditorListHide ?
            additionalField.templateOptions.ruleEditorListHide(this.item[field.ownValueKey], false) : false,
          ...additionalField
        }))
      ];
    }));
  }
}
