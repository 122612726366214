<p-table #datatableComponent
         responsiveLayout="scroll"
         styleClass="p-datatable-gridlines entity-datatable"
         [value]="models$ ? (models$ | async) : models"
         [loading]="showLoading ? loading || (this.datatableViewService?.storeLoading$ | async) : false"
         [lazyLoadOnInit]="false"
         [first]="offset * limit"
         [rows]="limit"
         [totalRecords]="count"
         [lazy]="true"
         [loadingIcon]="faSpinner | nexnoxWebFaIconStringPipe:'fa-spin'"
         [paginator]="!!paging"
         [showFirstLastIcon]="true"
         [showCurrentPageReport]="!!paging"
         [currentPageReportTemplate]="'{totalRecords} ' + ('core-shared.shared.table.total-items' | translate)"
         [resizableColumns]="true"
         [rowHover]="true"
         [dataKey]="idProp"
         [customSort]="true"
         [resetPageOnSort]="false"
         [sortField]="currentSortOption?.sortField ? currentSortOption.sortField : null"
         [sortOrder]="currentSortOption?.sort ? (currentSortOption.sort === orders.Ascending ? 1 : -1) : 1"
         (onLazyLoad)="onPage($event)">
  <ng-template *ngIf="!hideHeader" pTemplate="caption">
    <div class="d-flex flex-row w-100 justify-content-end">
      <ng-container *ngFor="let headerAction of additionalHeaderActions">
        <button *ngIf="headerAction.show$ ? (headerAction.show$ | async) : true"
                pButton
                [class]="'p-ripple p-button-icon-only p-button-sm ml-2 ' + headerAction.className"
                [icon]="headerAction.icon | nexnoxWebFaIconStringPipe"
                [title]="headerAction.title | translate"
                [disabled]="headerAction.disabled$ ? (headerAction.disabled$ | async) : false"
                (click)="headerAction.onClick()">
        </button>
      </ng-container>

      <nexnox-web-entity-datatable-settings-sidebar #settingsSidebarComponent
                                                    *ngIf="showSettings && datatableViewService"
                                                    [datatableViewService]="datatableViewService"
                                                    [activeColumns]="activeColumns$ | async"
                                                    [dataColumns]="dataColumns"
                                                    [optionalColumns]="optionalColumns"
                                                    [stereotypeColumns]="stereotypeColumns"
                                                    [sortObject]="currentSortOption"
                                                    [filters]="currentFilters$ | async"
                                                    [datatableConfig]="datatableConfig ?? localDatatableConfig"
                                                    [componentId]="componentId"
                                                    [columnTypings]="columnTypings"
                                                    [pageOperation]="pageOperation"
                                                    [enableViews]="enableViews"
                                                    [disableDatatableViews]="disableSettingsViews"
                                                    [disableDatatableViewSelect]="disableSettingsViewSelect"
                                                    [templates]="templates">
      </nexnox-web-entity-datatable-settings-sidebar>

      <ng-container *ngIf="enableViews && !disableSettingsViews && !disableSettingsViewSelect
                    && datatableViewService && showSettings">
        <div *ngIf="(datatableViewService.currentViewTitle$ | async) as viewTitle"
             class="applied-view-title ml-2 align-self-center">
          {{ viewTitle | translate }}
        </div>

        <nexnox-web-entity-datatable-view-dropdown
                                                   [componentId]="componentId"
                                                   [datatableViewService]="datatableViewService"
                                                   [activeColumns]="activeColumns$ | async"
                                                   [dataColumns]="dataColumns"
                                                   [optionalColumns]="optionalColumns"
                                                   [stereotypeColumns]="stereotypeColumns"
                                                   [sortObject]="currentSortOption"
                                                   [enableViews]="enableViews && showSettings"
                                                   [datatablePredefinedViewConfig]="datatablePredefinedViewConfig ?? null"
                                                   [columnTypings]="columnTypings"
                                                   [pageOperation]="pageOperation"
                                                   [datatableConfig]="datatableConfig ?? localDatatableConfig"
                                                   [entityType]="entityType"
                                                   (openSettings)="onShowSettingsSidebar()">
        </nexnox-web-entity-datatable-view-dropdown>
      </ng-container>

      <button *ngIf="enableCalendarSubscription && (hasCalendarSubscriptionPermission$ | async)"
              type="button"
              pButton
              class="p-button-text p-button-secondary p-ripple p-button-icon-only p-button-sm ml-2"
              [icon]="faCalendarDay | nexnoxWebFaIconStringPipe"
              [title]="'missions.calendar-subscription.subscribe' | translate"
              (click)="onGetCalendarSubscriptionLink()">
      </button>

      <button *ngIf="showSettings"
              type="button"
              pButton
              class="p-button-text p-button-secondary p-ripple p-button-icon-only p-button-sm ml-2"
              [icon]="faCog | nexnoxWebFaIconStringPipe"
              [title]="'core-shared.shared.table.tooltip.settings' | translate"
              (click)="onShowSettingsSidebar()">
      </button>

      <button *ngIf="enableViews && canExport && (canExportDatatable$ | async)"
              type="button"
              pButton
              class="p-button-text p-button-secondary p-ripple p-button-icon-only p-button-sm ml-2"
              [icon]="faFileExport | nexnoxWebFaIconStringPipe"
              [title]="'core-shared.shared.table.tooltip.export' | translate"
              (click)="openExportSidebar()">
      </button>

      <button *ngIf="detailTemplate && expandedRows > 0"
              type="button"
              pButton
              class="p-button-text p-button-secondary p-ripple p-button-icon-only p-button-sm ml-2"
              [icon]="faCompress | nexnoxWebFaIconStringPipe"
              [title]="'core-shared.shared.table.tooltip.collapse-rows' | translate"
              (click)="onCollapseAll()">
      </button>

      <button *ngIf="showRefresh"
              type="button"
              pButton
              class="p-button-text p-button-primary p-ripple p-button-icon-only p-button-sm ml-2"
              [icon]="faSync | nexnoxWebFaIconStringPipe"
              [title]="'core-shared.shared.table.tooltip.refresh' | translate"
              [disabled]="loading"
              (click)="onRefresh()">
      </button>
    </div>
  </ng-template>

  <ng-template pTemplate="header">
    <tr>

      <th *ngIf="selectionMode === selectionModes.Multiple" style="width: 3rem">
        {{ 'core-portal.core.general.selection' | translate }}
        <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
      </th>
      <th *ngIf="selectionMode === selectionModes.Single" style="width: 3rem">
        {{ 'core-portal.core.general.selection' | translate }}
        <!-- radio button placeholder -->
      </th>

      <th *ngIf="detailTemplate"
          [style.width.px]="60.98"
          [style.min-width.px]="60.98"
          [style.max-width.px]="60.98"
          [style.height.px]="hideFilters ? 45 : null">
      </th>

      <th *ngFor="let column of activeColumns$ | async; trackBy: trackColumnBy"
          [pSortableColumn]="column.prop"
          [pSortableColumnDisabled]="!column.sortable || hideSorting"
          pResizableColumn
          [style.width.px]="column.width"
          [style.min-width.px]="column.minWidth"
          [style.max-width.px]="column.maxWidth"
          [style.height.px]="hideFilters ? 45 : null">
        <nexnox-web-entity-datatable-header #headerComponent
                                            [column]="column"
                                            [currentFilters]="currentFilters$ | async"
                                            [hideFilters]="hideFilters"
                                            [hideSorting]="hideSorting"
                                            [disableFilters]="disableFilters"
                                            [templates]="templates"
                                            (search)="onSearch(column, $event)">
        </nexnox-web-entity-datatable-header>
      </th>
    </tr>
  </ng-template>

  <ng-template pTemplate="body" let-row let-expanded="expanded" let-rowIndex="rowIndex">
    <tr [pSelectableRow]="row" [pSelectableRowIndex]="rowIndex">
      <td *ngIf="selectionMode === selectionModes.Multiple">
        <p-tableCheckbox [pSelectableRow]="row" [value]="row"></p-tableCheckbox>
      </td>
      <td *ngIf="selectionMode === selectionModes.Single">
        <p-tableRadioButton [pSelectableRow]="row" [value]="row"></p-tableRadioButton>
      </td>
      <td *ngIf="detailTemplate"
          [class.is-expanded]="expanded"
          [style.width.px]="60.98"
          [style.min-width.px]="60.98"
          [style.max-width.px]="60.98">
        <button *ngIf="!hideFilters && showDetailTemplateExpandButton(row)"
                type="button"
                pButton
                class="p-button-text p-button-icon-only p-button-sm p-button-secondary"
                [icon]="(expanded ? faChevronDown : faChevronRight) | nexnoxWebFaIconStringPipe"
                [disabled]="loading"
                (click)="onExpandRow(row)">
        </button>
      </td>

      <td *ngFor="let column of activeColumns$ | async; trackBy: trackColumnBy"
          [class.pr-0]="column.prop === displayProp || column.prop === idProp"
          [class.is-expanded]="expanded"
          [style.width.px]="column.width"
          [style.min-width.px]="column.minWidth"
          [style.max-width.px]="column.maxWidth"
          [style.padding.px]="column.padding">
        <div class="d-flex justify-content-between h-100"
             [class.align-items-center]="!column.minHeight && !column.height && !column.maxHeight"
             [class.overflow-auto]="column.minHeight || column.height || column.maxHeight"
             [class.cursor-pointer]="column.clickable === true"
             [style.height.px]="column.height"
             [style.min-height.px]="column.minHeight"
             [style.max-height.px]="column.maxHeight">
          <nexnox-web-entity-datatable-cell *ngIf="!column.cellTemplate; else cellTemplate"
                                            [row]="row"
                                            [column]="column"
                                            [idProp]="idProp"
                                            [isTitle]="column.prop === displayProp || column.prop === idProp"
                                            [detailLink]="detailLink"
                                            [module]="module"
                                            [detailFn]="detailFn"
                                            (click)="onCellClick(column, row, $event)">
          </nexnox-web-entity-datatable-cell>

          <ng-template #cellTemplate>
            <ng-container [ngTemplateOutlet]="column.cellTemplate"
                          [ngTemplateOutletContext]="{ row: row }">
            </ng-container>
          </ng-template>

          <ng-container *ngIf="column.prop === displayProp || column.prop === idProp">
            <nexnox-web-entity-datatable-last-column-cell [actionButtons]="actionButtons"
                                                          [row]="row"
                                                          [loading]="loading"
                                                          [canDelete]="canDelete"
                                                          [entityData]="entityData"
                                                          [idProp]="idProp"
                                                          (delete)="onDelete($event)">
            </nexnox-web-entity-datatable-last-column-cell>
          </ng-container>
        </div>
      </td>
    </tr>
  </ng-template>

  <ng-template pTemplate="rowexpansion" let-row>
    <tr class="is-expanded">
      <td colspan="100%"
          class="p-0 border-top-0">
        <div class="py-3"
             [style.padding-left.px]="60.98"
             [style.padding-right.px]="60.98">
          <ng-container [ngTemplateOutlet]="detailTemplate"
                        [ngTemplateOutletContext]="{ $implicit: row }"></ng-container>
        </div>
      </td>
    </tr>
  </ng-template>

  <ng-template pTemplate="emptymessage" let-columns>
    <tr>
      <td colspan="100">
        {{ 'core-shared.shared.table.empty' | translate }}
      </td>
    </tr>
  </ng-template>
</p-table>

<nexnox-web-entity-datatable-export-sidebar #exportSidebarComponent
                                            (accept)="onExport($event)">
</nexnox-web-entity-datatable-export-sidebar>

<nexnox-web-calendar-subscription-sidebar #calendarSubscriptionSidebarComponent>
</nexnox-web-calendar-subscription-sidebar>
