import {IssueContentType} from "@nexnox-web/core-shared";

export const issueTypeEnumOptions: {
  label: string,
  value: IssueContentType
}[] =
  [
    {
      label: 'issues.content-types.1',
      value: IssueContentType.Message
    },
    {
      label: 'issues.content-types.2',
      value: IssueContentType.Ticket
    },
    {
      label: 'issues.content-types.3',
      value: IssueContentType.Mission
    },
    {
      label: 'issues.content-types.4',
      value: IssueContentType.WinterhalterServiceCall
    },
    {
      label: 'issues.content-types.5',
      value: IssueContentType.WinterhalterProductOrder
    },
  ];
