import { Filter, Paging, SortObject, StereotypeDto } from '@nexnox-web/core-shared';

export * from './payloads';

export interface PagedEntitiesXsStoreGetPageSuccessPayload<S> {
  items: S[];
  paging: Paging;
}

export interface PagedEntitiesXsStoreGetStereotypesSuccess {
  stereotypes: StereotypeDto[];
}

export interface PagedEntitiesXsStoreCreateOnePayload<M> {
  model: M;
  parentIds?: Array<string | number>;
}

export interface PagedEntitiesXsStoreCreateOneSuccessPayload<E, M> {
  entity: E;
  model: M;
  parentIds?: Array<string | number>;
}

export interface PagedEntitiesXsStoreAddRemoveParentPayload {
  parentIds: Array<string | number>;
  id: string | number;
}

export interface PagedEntitiesXsStoreDeleteOnePayload {
  id: number;
  parentIds?: Array<string | number>;
}

export interface PagedEntitiesXsStoreSetFiltersPayload {
  filters: Filter[];
  sortObject: SortObject;
}
