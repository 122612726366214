import { ThisReceiver } from '@angular/compiler';
import { ElementRef, Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

/* istanbul ignore next */
@Injectable()
export class CorePortalContentContainerService {
  private contentContainer: ElementRef;

  public getContentContainer(): ElementRef {
    return this.contentContainer;
  }

  public setContentContainer(contentContainer: ElementRef): void {
    this.contentContainer = contentContainer;
  }

  public scrollTo(options?: ScrollToOptions): void {
    this.contentContainer?.nativeElement?.scrollTo(options);
  }
}
