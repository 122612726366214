import { Injectable } from '@angular/core';
import { CoreSharedBreadcrumb, CoreSharedBreadcrumbsResolver, I18nBreadcrumbResolver } from '@nexnox-web/core-shared';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { missionDetailStore } from '../../store';

@Injectable({
  providedIn: 'root'
})
export class MissionCreateTicketBreadcrumbsResolver extends CoreSharedBreadcrumbsResolver {
  constructor(
    private i18nBreadcrumbResolver: I18nBreadcrumbResolver,
    private store: Store<any>
  ) {
    super();
  }

  public resolve(route: ActivatedRouteSnapshot): Observable<CoreSharedBreadcrumb[]> {
    if (route.paramMap.has('missionId')) {
      return this.store.pipe(
        select(missionDetailStore.selectors.selectEntity),
        filter(entity => Boolean(entity?.missionId)),
        switchMap(mission => this.i18nBreadcrumbResolver.resolve(route).pipe(
          map(breadcrumbs => [
            {
              path: `${this.getFullPath(route.parent)}/${mission.missionId}`,
              text: mission.title
            },
            ...breadcrumbs
          ])
        ))
      );
    }

    return this.i18nBreadcrumbResolver.resolve(route);
  }
}
