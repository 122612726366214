import { PagedEntitiesXsStore } from '@nexnox-web/core-store';
import { AppEntityType, ControlPointDto } from '@nexnox-web/core-shared';
import { createSelector } from '@ngrx/store';
import { selectResourcesState } from '../../resources.selectors';
import { CorePortalFeatureResourceControlPointService } from '@nexnox-web/core-portal/features/resources';

/* istanbul ignore next */
export const resourceControlPointsStore = new PagedEntitiesXsStore<ControlPointDto>({
  actionLabel: 'Tech Portal - Resources - Resource Control Points',
  stateSelector: createSelector(selectResourcesState, state => state.resourceControlPoints),
  serviceType: CorePortalFeatureResourceControlPointService,
  entityType: AppEntityType.None,
  selectId: controlPoint => controlPoint.entity?.controlPointId ?? controlPoint.model?.controlPointId
});
