import {NgModule} from '@angular/core';
import {CorePortalEntitySelectComponent} from './components';
import {CoreSharedModule} from '@nexnox-web/core-shared';
import {FormsModule} from '@angular/forms';
import {CorePortalRouterOverridesModule} from '../../../router-overrides';
import {CrossCreationSidebarComponent} from "./sidebars";
import {FormlyModule} from "@ngx-formly/core";

@NgModule({
  declarations: [
    CorePortalEntitySelectComponent,
    CrossCreationSidebarComponent
  ],
  imports: [
    CoreSharedModule,
    FormsModule,
    CorePortalRouterOverridesModule,
    FormlyModule
  ],
  exports: [
    CoreSharedModule,
    FormsModule,

    CorePortalEntitySelectComponent
  ]
})
export class CorePortalEntitySelectModule {
}
