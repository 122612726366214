import {ChangeDetectionStrategy, Component, Injector, Input, ViewChild} from '@angular/core';
import {
  CorePortalCardboxAction,
  CorePortalEntityOverviewBaseComponent,
  DatatableActionButton
} from '@nexnox-web/core-portal';
import {AppEntityType, AppPermissions, ControllerOperationId, Mappers, TicketDto} from '@nexnox-web/core-shared';
import {locationTicketListStore} from '../../store';
import {faPlus} from "@fortawesome/free-solid-svg-icons/faPlus";
import {CreateTicketsByDefinitionSidebarComponent} from "./../../sidebars";
import {Observable} from "rxjs";

@Component({
  selector: 'nexnox-web-master-data-locations-location-ticket-list',
  templateUrl: './location-ticket-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LocationTicketListComponent extends CorePortalEntityOverviewBaseComponent<TicketDto> {

  @ViewChild('definitionSidebar', {static: true}) public definitionSidebar: CreateTicketsByDefinitionSidebarComponent;

  @Input() public locationStereotypeId$: Observable<number>;

  public title = 'tickets.subtitles.ticket-list';
  public idProperty = 'ticketId';
  public displayProperty = 'title';
  public pageOperation = ControllerOperationId.TicketInLocationControllerId;
  public datatableConfigName = 'TicketDefault';
  public componentId = 'LocationTicketListComponent';
  public enableViews = true;

  constructor(
    protected injector: Injector
  ) {
    super(injector, locationTicketListStore, Mappers.TicketListDto.serializedName, AppEntityType.Ticket);
  }

  public getCardboxHeaderActions(): CorePortalCardboxAction[] {
    return [
      {
        label: 'core-shared.shared.skeletons.apply-skeleton',
        class: 'p-button p-button-primary',
        icon: faPlus,
        tooltip: 'core-shared.shared.skeletons.apply-skeleton',
        buttonSize: 'lg',

        permission: AppPermissions.CreateTicketsByLocationDefinition,
        callback: () => this.definitionSidebar.onShow()
      },
    ]
  }

  public getRowActionButtons(): DatatableActionButton[] {
    return this.getDefaultRowActionButtons(
      'tickets.actions.edit-ticket',
      ticket => `tickets/${ticket.ticketId}`,
      [AppPermissions.UpdateTicket],
      {
        module: 'communication'
      }
    );
  }

  public onRefresh(): void {
    this.store.dispatch(locationTicketListStore.actions.getPage(undefined, undefined, undefined, {
      parentIds: this.parentIds,
      filterOperation: undefined
    }));
  }
}
