<div class="d-flex flex-row">
  <!-- Negative -->
  <div *ngIf="allowNegative"
       class="mr-1 mt-2">
    <p-checkbox [ngModel]="isNegative$ | async"
                [binary]="true"
                [checkboxIcon]="faMinus | nexnoxWebFaIconStringPipe"
                (ngModelChange)="onNegativeChange($event)">
    </p-checkbox>
  </div>

  <!-- Years -->
  <div *ngIf="showYears"
       class="input-group time-input-group mr-1">
    <input type="text"
           class="d-inline-block form-control time-input text-center"
           [class.is-invalid]="hasError"
           [disabled]="isDisabled$ | async"
           [maxLength]="4"
           mask="9999"
           inputmode="decimal"
           [title]="'core-shared.shared.time-picker.years' | translate"
           [ngModel]="years$ | async"
           (ngModelChange)="onYearsChange($event)"
           (keyup.arrowUp)="onStepYears(false)"
           (keyup.arrowDown)="onStepYears(true)"
           (blur)="onBlurYears()">
    <div class="input-group-append">
      <ng-container [ngTemplateOutlet]="stepperTemplate"
                    [ngTemplateOutletContext]="{
                      step: stepYearsFn,
                      downExpression: (years$ | async) <= 0
                    }">
      </ng-container>
    </div>
    <small class="text-muted flex-basis-100">{{ 'core-shared.shared.time-picker.years' | translate }}</small>
  </div>
  <span *ngIf="showYears && (showWeeks || showDays || showHours || showMinutes)" class="separator">:</span>

  <!-- Weeks -->
  <div *ngIf="showWeeks"
       class="input-group time-input-group mr-1"
       [class.ml-1]="showYears">
    <input type="text"
           class="d-inline-block form-control time-input text-center"
           [class.is-invalid]="hasError"
           [disabled]="isDisabled$ | async"
           [maxLength]="4"
           mask="9999"
           inputmode="decimal"
           [title]="'core-shared.shared.time-picker.weeks' | translate"
           [ngModel]="weeks$ | async"
           (ngModelChange)="onWeeksChange($event)"
           (keyup.arrowUp)="onStepWeeks(false)"
           (keyup.arrowDown)="onStepWeeks(true)"
           (blur)="onBlurWeeks()">
    <div class="input-group-append">
      <ng-container [ngTemplateOutlet]="stepperTemplate"
                    [ngTemplateOutletContext]="{
                      step: stepWeeksFn,
                      downExpression: (weeks$ | async) <= 0
                    }">
      </ng-container>
    </div>
    <small class="text-muted flex-basis-100">{{ 'core-shared.shared.time-picker.weeks' | translate }}</small>
  </div>
  <span *ngIf="showWeeks && (showDays || showHours || showMinutes)" class="separator">:</span>

  <!-- Days -->
  <div *ngIf="showDays"
       class="input-group time-input-group mr-1"
       [class.ml-1]="showYears || showWeeks">
    <input type="text"
           class="d-inline-block form-control time-input text-center"
           [class.is-invalid]="hasError"
           [disabled]="isDisabled$ | async"
           [maxLength]="4"
           mask="9999"
           inputmode="decimal"
           [title]="'core-shared.shared.time-picker.days' | translate"
           [ngModel]="days$ | async"
           (ngModelChange)="onDaysChange($event)"
           (keyup.arrowUp)="onStepDays(false)"
           (keyup.arrowDown)="onStepDays(true)"
           (blur)="onBlurDays()">
    <div class="input-group-append">
      <ng-container [ngTemplateOutlet]="stepperTemplate"
                    [ngTemplateOutletContext]="{
                      step: stepDaysFn,
                      downExpression: (days$ | async) <= 0
                    }">
      </ng-container>
    </div>
    <small class="text-muted flex-basis-100">{{ 'core-shared.shared.time-picker.days' | translate }}</small>
  </div>
  <span *ngIf="showDays && (showHours || showMinutes)" class="separator">:</span>

  <!-- Hours -->
  <div *ngIf="showHours"
       class="input-group time-input-group mr-1"
       [class.ml-1]="showYears ||showWeeks || showDays">
    <input type="text"
           class="d-inline-block form-control time-input text-center"
           [class.is-invalid]="hasError"
           [disabled]="isDisabled$ | async"
           [maxLength]="2"
           mask="Hh"
           inputmode="decimal"
           [title]="'core-shared.shared.time-picker.hours' | translate"
           [ngModel]="hours$ | async"
           (ngModelChange)="onHoursChange($event)"
           (keyup.arrowUp)="onStepHours(false)"
           (keyup.arrowDown)="onStepHours(true)"
           (blur)="onBlurHours()">
    <div class="input-group-append">
      <ng-container [ngTemplateOutlet]="stepperTemplate"
                    [ngTemplateOutletContext]="{
                      step: stepHoursFn,
                      upExpression: (hours$ | async) >= maxHours,
                      downExpression: (hours$ | async) <= minHours
                    }">
      </ng-container>
    </div>
    <small class="text-muted flex-basis-100">{{ 'core-shared.shared.time-picker.hours' | translate }}</small>
  </div>
  <span *ngIf="showHours && showMinutes" class="separator">:</span>

  <!-- Minutes -->
  <div *ngIf="showMinutes"
       class="input-group time-input-group"
       [class.ml-1]="showYears ||showWeeks || showDays || showHours">
    <input type="text"
           class="d-inline-block form-control time-input text-center"
           [class.is-invalid]="hasError"
           [disabled]="isDisabled$ | async"
           [maxLength]="2"
           mask="m0"
           inputmode="decimal"
           [title]="'core-shared.shared.time-picker.minutes' | translate"
           [ngModel]="minutes$ | async"
           (ngModelChange)="onMinutesChange($event)"
           (keyup.arrowUp)="onStepMinutes(false)"
           (keyup.arrowDown)="onStepMinutes(true)"
           (blur)="onBlurMinutes()">
    <div class="input-group-append">
      <ng-container [ngTemplateOutlet]="stepperTemplate"
                    [ngTemplateOutletContext]="{
                      step: stepMinutesFn,
                      upExpression: (minutes$ | async) >= maxMinutes,
                      downExpression: (minutes$ | async) <= minMinutes
                    }">
      </ng-container>
    </div>
    <small class="text-muted flex-basis-100">{{ 'core-shared.shared.time-picker.minutes' | translate }}</small>
  </div>

  <button *ngIf="(valid$ | async) && (isDisabled$ | async) === false && canClear"
          class="btn align-self-start clear-btn"
          [title]="'core-shared.shared.select.clear' | translate"
          (click)="onClear()">
    <fa-icon [icon]="faTimes"></fa-icon>
  </button>
</div>

<ng-template #stepperTemplate
             let-step="step"
             let-upExpression="upExpression"
             let-downExpression="downExpression">
  <span class="input-group-text step-text-container d-none d-xl-flex">
    <fa-icon [icon]="faCaretUp"
             class="step-icon-up"
             [class.disabled]="(isDisabled$ | async) || upExpression"
             (click)="step(false, !upExpression)">
    </fa-icon>

    <fa-icon [icon]="faCaretDown"
             class="step-icon-down"
             [class.disabled]="(isDisabled$ | async) || downExpression"
             (click)="step(true, !downExpression)">
    </fa-icon>
  </span>
</ng-template>
