import {ChangeDetectionStrategy, Component, Injector, TemplateRef, ViewChild} from '@angular/core';
import {
  CorePortalEntityEditBaseComponent,
  CorePortalFormlyReadonlyTypes,
  CorePortalFormlyReadonlyTyping
} from '@nexnox-web/core-portal';
import {InfoDto, KnowledgeArticleDto, OrgaAttachmentDto} from '@nexnox-web/core-shared';
import {BehaviorSubject} from 'rxjs';
import {FormlyFieldConfig} from '@ngx-formly/core';
import {OrgaPortalAttachmentsComponent} from '@nexnox-web/orga-portal-lib';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'nexnox-web-knowledge-knowledge-article-edit',
  templateUrl: './knowledge-article-edit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrgaPortalFeatureKnowledgeArticleEditComponent extends CorePortalEntityEditBaseComponent<KnowledgeArticleDto> {
  @ViewChild('locationSelectLabelTitleTemplate', { static: true }) public locationSelectLabelTitleTemplate: TemplateRef<any>;
  @ViewChild('locationSelectOptionTitleTemplate', { static: true }) public locationSelectOptionTitleTemplate: TemplateRef<any>;

  @ViewChild('categorySelectLabelTitleTemplate', { static: true }) public categorySelectLabelTitleTemplate: TemplateRef<any>;
  @ViewChild('categorySelectOptionTitleTemplate', { static: true }) public categorySelectOptionTitleTemplate: TemplateRef<any>;

  @ViewChild('attachmentsComponent') public attachmentsComponent: OrgaPortalAttachmentsComponent;

  public attachmentsSubject: BehaviorSubject<OrgaAttachmentDto[]> = new BehaviorSubject<OrgaAttachmentDto[]>([]);

  constructor(
    protected injector: Injector,
    private translate: TranslateService
  ) {
    super(injector);
  }

  public onAttachmentsChange(attachments: OrgaAttachmentDto[]): void {
    this.setModel({ ...this.model, attachments });
    setTimeout(() => this.onModelChange(this.model));
  }

  public onModelChange(model: KnowledgeArticleDto): void {
    this.modelValidSubject.next({
      ...this.modelValidSubject.getValue(),
      attachments: this.attachmentsComponent ? this.attachmentsComponent.isModelValid() : true
    });

    super.onModelChange(model);
  }

  protected createForm(): FormlyFieldConfig[] {
    return [
      {
        key: 'title',
        type: 'input',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-12',
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.title',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.BASIC
          } as CorePortalFormlyReadonlyTyping,
          type: 'text'
        },
        expressionProperties: {
          'templateOptions.required': () => !this.readonly,
          'templateOptions.disabled': () => this.readonly,
          'templateOptions.readonly': () => this.readonly
        },
        hideExpression: () => !this.creating
      },
      {
        key: 'externalId',
        type: 'core-portal-external-id',
        wrappers: ['core-portal-translated'],
        className: 'col-md-6',
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.external-id',
          },
          titleKey: 'title',
          modelSubject: this.modelSubject
        },
        expressionProperties: {
          'templateOptions.disabled': () => this.readonly
        }
      },
      {
        key: 'tags',
        type: 'input',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-6',
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.tags'
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.BASIC
          } as CorePortalFormlyReadonlyTyping,
          type: 'text'
        },
        expressionProperties: {
          'templateOptions.disabled': () => this.readonly,
          'templateOptions.readonly': () => this.readonly
        }
      },
      {
        key: 'content',
        type: 'core-portal-editor',
        wrappers: ['core-portal-translated'],
        className: 'col-md-12',
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.content',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          },
          corePortalEditor: {
            language: this.translate.currentLang
          }
        },
        expressionProperties: {
          'templateOptions.required': () => !this.readonly,
          'templateOptions.disabled': () => this.readonly
        }
      }
    ];
  }

  protected setModel(model: InfoDto): void {
    super.setModel(model);

    this.attachmentsSubject.next(model?.attachments ?? []);
  }

  protected setReadonly(readonly: boolean): void {
    super.setReadonly(readonly);

    this.attachmentsComponent?.onReset();
  }
}
