import {PagedEntitiesXsStore, PagedEntitiesXsStoreState} from '@nexnox-web/core-store';
import {AppEntityType, InfoDto} from '@nexnox-web/core-shared';
import {Action, createSelector} from '@ngrx/store';
import {Injectable, Injector} from '@angular/core';
import {selectNotificationRulesState} from "../../notification-rules.selector";
import {OrgaPortalFeatureInfoService} from "@nexnox-web/orga-portal/features/infos/src/lib/store/services/info/info.service";

export interface NotificationRulesInfosStoreState extends PagedEntitiesXsStoreState<InfoDto> {
}

/* istanbul ignore next */
export const notificationRulesInfosStore = new PagedEntitiesXsStore<InfoDto>({
  actionLabel: 'Tech Portal - Notification Rules - Notification Rule Infos',
  stateSelector: createSelector(selectNotificationRulesState, state => state.notificationRulesInfos),
  entityType: AppEntityType.Info,
  serviceType: OrgaPortalFeatureInfoService,
  selectId: info => info.entity?.infoId ?? info.model?.infoId
});

export function notificationRulesInfosStoreReducer(state: NotificationRulesInfosStoreState, action: Action): any {
  return notificationRulesInfosStore.reducer(state, action);
}

@Injectable()
export class NotificationRulesInfosStoreEffects extends notificationRulesInfosStore.effects {
  constructor(
    protected injector: Injector
  ) {
    super(injector);
  }
}
