import { ChangeDetectionStrategy, Component, Injector, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons/faCaretDown';
import { faCaretUp } from '@fortawesome/free-solid-svg-icons/faCaretUp';
import { faGripVertical } from '@fortawesome/free-solid-svg-icons/faGripVertical';
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import { CorePortalEntityEditBaseComponent, CorePortalFormlyReadonlyTypes, CorePortalFormlyReadonlyTyping } from '@nexnox-web/core-portal';
import { CorePortalFeatureArticleService } from '@nexnox-web/core-portal/features/articles';
import {
  ArticleDto,
  ArticleUsageDto,
  CoreSharedSortableListComponent,
  CoreSharedSortableListItem,
  MissionReportDto,
  MissionReportGroupDto,
  MissionReportSkeletonDto,
  NexnoxWebLocaleString
} from '@nexnox-web/core-shared';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { cloneDeep, flatten, isEqual, keys, merge, pick, round, values } from 'lodash';
import { DndDropEvent } from 'ngx-drag-drop';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { distinctUntilChanged, map, mergeMap, startWith } from 'rxjs/operators';

interface AddArticle {
  article: ArticleDto;
  count: number;
  price: number;
  note?: string;
}

type LocalMissionReportGroupDto = MissionReportGroupDto & { usedArticles: CoreSharedSortableListItem[] }

interface ArticlesValid {
  [group: string]: {
    [articlePosition: number]: boolean;
  };
}

@Component({
  selector: 'nexnox-web-missions-mission-report-edit',
  templateUrl: './mission-report-edit.component.html',
  styleUrls: ['./mission-report-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TechPortalFeatureMissionReportEditComponent extends CorePortalEntityEditBaseComponent<MissionReportDto>
  implements OnInit {
  public addArticleForm: FormGroup;
  public addArticleModelSubject: BehaviorSubject<AddArticle> = new BehaviorSubject<AddArticle>(null);
  public addArticlesFields: FormlyFieldConfig[];
  public addArticlesUnitSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  public missionReportGroups$: Observable<LocalMissionReportGroupDto[]>;
  public missionReportForms: { [group: string]: { [position: number]: FormGroup } };
  public missionReportTotal$: Observable<string>;

  public trackGroupsByFn: any;

  public faPlus = faPlus;
  public faCaretUp = faCaretUp;
  public faCaretDown = faCaretDown;
  public faGripVertical = faGripVertical;

  private articlesValid$: Observable<boolean>;
  private articlesValidSubject: BehaviorSubject<ArticlesValid> = new BehaviorSubject<ArticlesValid>({});

  constructor(
    protected injector: Injector,
    private articleService: CorePortalFeatureArticleService
  ) {
    super(injector);

    this.trackGroupsByFn = (index: number, group: MissionReportGroupDto) => group.name;

    this.missionReportGroups$ = this.modelSubject.asObservable().pipe(
      map(model => (model?.groups ?? []).map(group => ({
        ...group,
        usedArticles: (group.usedArticles ?? []).map((articleUsage, index) => ({
          title: articleUsage.article.name,
          position: articleUsage.position,
          deletable: true,
          getExternalData: () => ({ model: articleUsage, }),
          getOneTimeExternalData: () => ({
            fields: this.createEditArticleForm(group.name, articleUsage.position, articleUsage.unit)
          })
        }) as CoreSharedSortableListItem)
      })).sort((a, b) => a.position - b.position))
    );

    this.subscribe(this.missionReportGroups$.pipe(
      distinctUntilChanged((a, b) => isEqual(a, b)),
      map(missionReportGroups => missionReportGroups
        .map(missionReportGroup => (missionReportGroup.usedArticles ?? []).map(usedArticle => ({
          [missionReportGroup.name]: {
            [usedArticle.position]: new FormGroup({})
          }
        })))
      ),
      map(groups => {
        const mergedGroups = {};

        for (const group of flatten(groups)) {
          merge(mergedGroups, group);
        }

        return mergedGroups;
      }),
      distinctUntilChanged((a, b) => {
        const getKeysDeep = (obj: any, array: any[] = []): any[] => {
          if (obj instanceof FormGroup) return array;

          Object.keys(obj).forEach(key => {
            if (typeof obj[key] === 'object' && !Array.isArray(obj[key]) && obj[key] !== null) {
              array = getKeysDeep(obj[key], array);
            }
          });

          return array.concat(Object.keys(obj));
        };

        return isEqual(getKeysDeep(a), getKeysDeep(b));
      })
    ), mergedGroups => this.missionReportForms = mergedGroups);

    this.missionReportTotal$ = this.modelSubject.asObservable().pipe(
      map(model => NexnoxWebLocaleString.transformNumber(model?.total ?? 0, 2))
    );

    this.articlesValid$ = this.articlesValidSubject.asObservable().pipe(
      mergeMap(articlesValid => this.modelSubject.asObservable().pipe(
        map(model => model?.groups ?? []),
        distinctUntilChanged((a, b) => isEqual(a, b)),
        map(groups => {
          const pickedGroupsValid: ArticlesValid = pick(articlesValid, groups.map(x => x.name));
          const pickedArticlesValid = keys(pickedGroupsValid)
            .map(key => pick(pickedGroupsValid[key], groups.find(x => x.name === key).usedArticles.map((_, index) => index)));
          return pickedArticlesValid.every(x => values(x).every(y => y));
        })
      ))
    );

    this.subscribe(this.articlesValid$.pipe(distinctUntilChanged()), articlesValid => {
      this.modelValidSubject.next({ ...this.modelValidSubject.getValue(), articlesValid });
      setTimeout(() => this.onModelChange(this.model));
    });
  }

  public ngOnInit(): void {
    super.ngOnInit();

    this.addArticleForm = new FormGroup({});
    this.addArticlesFields = this.createAddArticleForm();
  }

  public onDropGroup(event: DndDropEvent): void {
    const items = CoreSharedSortableListComponent.orderItems(event, this.model?.groups ?? []);
    this.onModelChange({ ...(this.model ?? {}), groups: items });
  }

  public onAddArticle(): void {
    const articleToAdd = cloneDeep(this.addArticleModelSubject.getValue());
    const newGroups = cloneDeep(this.model?.groups ?? []);
    const groupFoundIndex = newGroups.findIndex(x => x.name === articleToAdd.article.kind.name);
    const groupFound = groupFoundIndex > -1 ? newGroups[groupFoundIndex] : null;

    const newArticleUsage = {
      article: articleToAdd.article,
      count: articleToAdd.count,
      price: articleToAdd.price,
      unit: articleToAdd.article.unit,
      note: articleToAdd.note,
      ...(this.isSkeleton ? {} : { total: round(articleToAdd.price * articleToAdd.count, 2) })
    };

    if (groupFound) {
      const usedArticles = cloneDeep(groupFound.usedArticles);
      usedArticles.push({ ...newArticleUsage, position: usedArticles.length });
      newGroups[groupFoundIndex] = {
        ...groupFound,
        usedArticles,
        ...(this.isSkeleton ? {} : { total: round(this.getArticlesTotal(usedArticles), 2) })
      };
    } else {
      const usedArticles = [{ ...newArticleUsage, position: 0 }];
      newGroups.push({
        name: articleToAdd.article.kind.name,
        usedArticles,
        position: newGroups.length,
        ...(this.isSkeleton ? {} : { total: round(this.getArticlesTotal(usedArticles), 2) })
      });
    }

    this.onModelChange({
      ...this.model,
      groups: newGroups,
      ...(this.isSkeleton ? {} : {
        total: round(newGroups.map(x => x.total).reduce((acc, value) => acc + value), 2)
      })
    });

    this.addArticlesUnitSubject.next(null);
    this.addArticleModelSubject.next({} as any);
    this.addArticleForm.reset();
  }

  public onArticlesChange(group: MissionReportGroupDto, items: CoreSharedSortableListItem[]): void {
    const newGroups = cloneDeep(this.model?.groups ?? []);
    const groupIndex = newGroups.findIndex(x => x.name === group.name);

    if (groupIndex > -1) {
      const articles: ArticleUsageDto[] = items
        .sort((a, b) => a.position - b.position)
        .map(x => ({ ...x.getExternalData().model, position: x.position }));
      newGroups[groupIndex].usedArticles = articles;

      if (!newGroups[groupIndex].usedArticles.length) {
        newGroups.splice(groupIndex, 1);
      } else if (!this.isSkeleton) {
        newGroups[groupIndex].total =
          articles.length ? round(articles.map(x => x.total).reduce((acc, value) => acc + value), 2) : 0;
      }
    }

    this.onModelChange({
      ...this.model,
      groups: newGroups,
      ...(this.isSkeleton ? {} : {
        total: newGroups.length ? round(newGroups.map(x => x.total).reduce((acc, value) => acc + value), 2) : 0
      })
    });
  }

  public onArticleChange(group: MissionReportGroupDto, position: number, articleUsage: ArticleUsageDto): void {
    const newGroups = cloneDeep(this.model?.groups ?? []);
    const groupIndex = newGroups.findIndex(x => x.name === group.name);

    if (groupIndex > -1) {
      const articleIndex = newGroups[groupIndex].usedArticles.findIndex(x => x.position === position);

      newGroups[groupIndex].usedArticles[articleIndex] = {
        ...articleUsage,
        ...(this.isSkeleton ? {} : { total: round(articleUsage.price * articleUsage.count, 2) })
      };
      const usedArticles = newGroups[groupIndex].usedArticles;
      if (!this.isSkeleton) newGroups[groupIndex].total = round(this.getArticlesTotal(usedArticles), 2);
    }

    this.onModelChange({
      ...this.model,
      groups: newGroups,
      ...(this.isSkeleton ? {} : { total: round(newGroups.map(x => x.total).reduce((acc, value) => acc + value), 2) })
    });
  }

  public onChangeArticleNote(group: MissionReportGroupDto, position: number, note: string, articleUsage: ArticleUsageDto): void {
    this.onArticleChange(group, position, { ...articleUsage, note });
  }

  protected createForm(): FormlyFieldConfig[] {
    return this.getStereotypeFields(false);
  }

  /* istanbul ignore next */
  protected createAddArticleForm(): FormlyFieldConfig[] {
    return [
      {
        key: 'article',
        type: 'core-portal-entity-select',
        wrappers: ['core-portal-translated'],
        className: 'col-md-6',
        defaultValue: null,
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.article',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          },
          entityService: this.articleService,
          idKey: 'articleId',
          displayKey: 'name',
          wholeObject: true,
          skipGetOne: true
        },
        expressionProperties: {
          'templateOptions.required': () => !this.readonly
        },
        hooks: {
          onInit: field => this.subscribe(field.formControl.valueChanges.pipe(
            distinctUntilChanged((a, b) => isEqual(a, b))
          ), (article: ArticleDto) => {
            this.addArticlesUnitSubject.next(article?.unit ?? null);
            field.form.controls.price.setValue(article?.sellingPrice ?? null);
          })
        }
      },
      {
        key: 'count',
        type: 'core-portal-input-group-input',
        wrappers: ['core-portal-translated'],
        className: 'col-md-3',
        defaultValue: 1,
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.count',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          },
          corePortalInputGroupInput: {
            append: this.addArticlesUnitSubject.asObservable().pipe(
              map(unit => unit ? [unit] : [])
            )
          },
          type: 'number'
        },
        expressionProperties: {
          'templateOptions.required': () => !this.readonly && !this.isSkeleton
        }
      },
      {
        key: 'price',
        type: 'core-portal-input-group-input',
        wrappers: ['core-portal-translated'],
        className: 'col-md-3',
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.selling-price',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          },
          corePortalInputGroupInput: {
            append: of(['€'])
          },
          type: 'number'
        },
        expressionProperties: {
          'templateOptions.required': () => !this.readonly && !this.isSkeleton
        }
      },
      ...(!this.isSkeleton ? [{
        key: 'note',
        type: 'textarea',
        wrappers: ['core-portal-translated'],
        className: 'col-md-12',
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.note'
          },
          type: 'text',
          rows: 2
        }
      }] : [])
    ];
  }

  /* istanbul ignore next */
  protected createEditArticleForm(group: string, position: number, unit: string): FormlyFieldConfig[] {
    return [
      {
        key: 'count',
        type: 'core-portal-input-group-input',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-6',
        defaultValue: 1,
        templateOptions: {
          corePortalTranslated: {
            hideLabel: true,
            formGroupClassName: 'mb-0'
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.BASIC,
            template: value => `${value}${unit ? ` ${unit}` : ''}`
          } as CorePortalFormlyReadonlyTyping,
          corePortalInputGroupInput: {
            append: of(unit ? [unit] : [])
          },
          type: 'number'
        },
        expressionProperties: {
          'templateOptions.required': () => !this.readonly && !this.isSkeleton,
          'templateOptions.disabled': () => this.readonly,
          'templateOptions.readonly': () => this.readonly
        },
        hooks: {
          onInit: field => this.subscribe(field.form.valueChanges.pipe(
            startWith(field.form.value),
            distinctUntilChanged()
          ), () => {
            const articlesValid = this.articlesValidSubject.getValue();
            this.articlesValidSubject.next({
              ...articlesValid,
              [group]: {
                ...(articlesValid[group] ?? {}),
                [position]: field.form.valid
              }
            });
          })
        }
      },
      {
        key: 'price',
        type: 'core-portal-input-group-input',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-6',
        templateOptions: {
          corePortalTranslated: {
            hideLabel: true,
            formGroupClassName: 'mb-0'
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.BASIC,
            template: value => `${value} €`
          } as CorePortalFormlyReadonlyTyping,
          corePortalInputGroupInput: {
            append: of(['€'])
          },
          type: 'number'
        },
        expressionProperties: {
          'templateOptions.required': () => !this.readonly && !this.isSkeleton,
          'templateOptions.disabled': () => this.readonly,
          'templateOptions.readonly': () => this.readonly
        }
      }
    ];
  }

  /* istanbul ignore next */
  protected mapSkeletonToEntity(skeleton: MissionReportSkeletonDto): MissionReportDto {
    const groups: MissionReportGroupDto[] = (skeleton.groups ?? []).map(group => ({
      name: group.name,
      tenantId: group.tenantId,
      usedArticles: (group.usedArticles ?? []).map(usedArticle => ({
        article: {
          articleId: usedArticle.article.articleId,
          isArchived: usedArticle.article.isArchived,
          name: usedArticle.article.name
        },
        count: usedArticle.count,
        price: usedArticle.price,
        unit: usedArticle.article.unit,
        total: usedArticle.price * usedArticle.count,
        position: usedArticle.position
      }))
    }));

    for (const group of groups) {
      group.total = group.usedArticles.length ?
        round(group.usedArticles.map(x => x.total).reduce((acc, value) => acc + value), 2) : 0;
    }

    return {
      groups,
      total: groups.length ? round(groups.map(x => x.total).reduce((acc, value) => acc + value), 2) : 0
    };
  }

  private getArticlesTotal(articles: ArticleUsageDto[]): number {
    return articles.map(x => x.total).reduce((acc, currentValue) => acc + currentValue);
  }
}
