import {Injectable, Injector} from '@angular/core';
import {
  AppliedTemplateDto,
  CoreSharedApiBaseService,
  Product, ResourceDto,
  StatePreviewRequestDto,
  StatePreviewResponseDto
} from '@nexnox-web/core-shared';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable()
export class TechPortalTicketService extends CoreSharedApiBaseService {
  constructor(
    protected injector: Injector
  ) {
    super(injector, Product.TECH, 'ticket');
  }

  public getPreviewStates(stateMachineId: number, stateId: number = null): Observable<StatePreviewResponseDto> {
    return this.httpRequestService.post<StatePreviewResponseDto>(`${this.resource}/previewstate`, {
      stateId,
      stateMachineId
    } as StatePreviewRequestDto).pipe(
      map(response => response.body)
    );
  }

  public changeState(ticketId: number | string, stateId: number, reason: string, parentIds?: Array<number | string>): Observable<void> {
    return this.httpRequestService.post<void>(`${this.buildPathForOne(ticketId, parentIds)}/changeState`, {
      ticketId,
      stateId,
      reason
    }).pipe(
      map(response => response.body)
    );
  }

  public assignToMe(ticketId: number | string, parentIds?: Array<number | string>): Observable<void> {
    return this.httpRequestService.post<void>(`${this.buildPathForOne(ticketId, parentIds)}/editor/me/assign`, null).pipe(
      map(response => response.body)
    );
  }

  public assignTo(ticketId: number | string, contactId: number | string, parentIds?: Array<number | string>): Observable<void> {
    return this.httpRequestService.post<void>(`${this.buildPathForOne(ticketId, parentIds)}/editor/${contactId}/assign`, null).pipe(
      map(response => response.body)
    );
  }

  public unassign(ticketId: number | string, parentIds?: Array<number | string>): Observable<void> {
    return this.httpRequestService.post<void>(`${this.buildPathForOne(ticketId, parentIds)}/editor/unassign`, null).pipe(
      map(response => response.body)
    );
  }

  public assignResource(ticketId: number | string, resourceId: number | string, isUpdateRelations: boolean): Observable<ResourceDto> {
    const url = `${this.buildPathForOne(ticketId)}/resource/${resourceId}/assign?isSyncingRelation=${isUpdateRelations ?? false}`;
    return this.httpRequestService.post<ResourceDto>(url, null).pipe(
      map(response => response.body)
    );
  }

  public unassignResource(ticketId: number | string, parentIds?: Array<number | string>): Observable<void> {
    return this.httpRequestService.post<void>(`${this.buildPathForOne(ticketId, parentIds)}/resource/unassign`, null).pipe(
      map(response => response.body)
    );
  }

  public export(id: number | string, templateId?: number | string, parentIds?: Array<number | string>): Observable<AppliedTemplateDto> {
    return this.httpRequestService.post(`${this.buildPathForOne(id, parentIds)}/export`, {
      templateId
    }).pipe(
      map(response => response.body)
    );
  }
}
