import {ChangeDetectionStrategy, Component, Injector} from '@angular/core';
import {
  CorePortalEntityEditBaseComponent,
  CorePortalFormlyReadonlyTypes,
  CorePortalFormlyReadonlyTyping
} from '@nexnox-web/core-portal';
import {FormlyFieldConfig} from '@ngx-formly/core';
import {InvitationDto, InvitationPurpose, InvitationType} from "@nexnox-web/core-shared";
import {
  invitationPurposeEnumOptions,
  invitationTypeEnumOptions
} from "@nexnox-web/core-portal/features/settings/features/invitations";
import {
  FormlyReadonlyTypes
} from "@nexnox-web/core-portal/features/settings/features/stereotypes/src/lib/components/custom-property-edit/custom-property.templates";

@Component({
  selector: 'nexnox-web-settings-invitations-edit',
  templateUrl: './invitations-edit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CorePortalFeatureInvitationsEditComponent extends CorePortalEntityEditBaseComponent<InvitationDto> {
  constructor(
    protected injector: Injector,
  ) {
    super(injector);
  }

  /* istanbul ignore next */
  protected createForm(): FormlyFieldConfig[] {
    return [
      {
        key: 'name',
        type: 'input',
        wrappers: ['core-portal-translated'],
        className: 'col-md-12',
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.name',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          },
          type: 'text'
        },
        expressionProperties: {
          'templateOptions.required': () => !this.readonly,
          'templateOptions.disabled': () => this.readonly
        },
        hideExpression: () => !this.creating
      },
      {
        key: 'purpose',
        type: 'core-portal-ng-select',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-6',
        defaultValue: InvitationPurpose.Team,
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.purpose',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.ENUM,
            enumOptions: invitationPurposeEnumOptions,
            translate: true
          } as CorePortalFormlyReadonlyTyping,
          corePortalNgSelect: {
            items: invitationPurposeEnumOptions,
            translate: true
          }
        },
        expressionProperties: {
          'templateOptions.required': () => !this.readonly,
          'templateOptions.disabled': () => this.readonly
        },
      },
      {
        key: 'type',
        type: 'core-portal-ng-select',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-6',
        defaultValue: InvitationType.Identify,
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.type',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.ENUM,
            enumOptions: invitationTypeEnumOptions,
            translate: true
          } as CorePortalFormlyReadonlyTyping,
          corePortalNgSelect: {
            items: invitationTypeEnumOptions,
            translate: true
          }
        },
        expressionProperties: {
          'templateOptions.required': () => !this.readonly,
          'templateOptions.disabled': () => this.readonly
        },
      },
      {
        key: 'code',
        type: 'input',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-6',
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.invitation-code',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.BASIC
          } as CorePortalFormlyReadonlyTyping,
          type: 'text'
        },
        expressionProperties: {
          'templateOptions.required': () => false,
          'templateOptions.readonly': () => true
        },
        hideExpression: () => this.creating
      },
      {
        key: 'isRevoked',
        type: 'core-portal-switch',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        defaultValue: false,
        className: 'col-md-6',
        templateOptions: {
          corePortalReadonly: {
            type: FormlyReadonlyTypes.BOOLEAN
          } as CorePortalFormlyReadonlyTyping,
          corePortalTranslated: {
            label: 'core-shared.shared.fields.is-revoked',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          },
          type: 'text'
        },
        expressionProperties: {
          'templateOptions.required': () => false,
          'templateOptions.readonly': () => true
        },
        hideExpression: () => this.creating
      }
    ];
  }
}
