import {Type} from '@angular/core';
import {DatatableTableColumnIcon} from './datatable-table-colum.model';
import {AppPermissions, CoreSharedApiBaseService, Filter} from '@nexnox-web/core-shared';
import {Observable} from 'rxjs';

export enum DatatableTableColumnType {
  TEXT = 'text',
  NUMBER = 'number',
  BOOLEAN = 'boolean',
  ENUM = 'enum',
  DATE = 'date',
  TIME = 'time',
  REFERENCE = 'reference',
  CURRENCY = 'currency',
  PATH = 'path',
  ARRAY = 'array',
  HTML = 'html',
  PHONE = 'phone',
  LINK = 'link',
  PROGRESSBAR = 'progressbar',
}

export interface DatatableTableEnumOption {
  value: any;
  label: string;
}

export type DatatableTableColumnTyping = BasicColumnTyping |
  BooleanColumnTyping |
  EnumColumnTyping |
  DateColumnTyping |
  ReferenceColumnTyping |
  CurrencyColumnTyping |
  ArrayColumnTyping |
  ProgressBarColumnTyping |
  HTMLColumnTyping;

interface ColumnTyping {
  key: string;
  name?: string;
  prependIcon?: DatatableTableColumnIcon;
  disableFilter?: boolean;
  disableSorting?: boolean;
  addIfNotAvailable?: boolean;
  fixedWidth?: number;
  maxHeight?: number;
  hideByDefault?: boolean;
  // Disables detailFn and returns model & column data from a clicked cell
  clickable?: boolean;
}

interface BasicColumnTyping extends ColumnTyping {
  type: Exclude<DatatableTableColumnType,
    DatatableTableColumnType.BOOLEAN |
    DatatableTableColumnType.ENUM |
    DatatableTableColumnType.DATE |
    DatatableTableColumnType.REFERENCE |
    DatatableTableColumnType.CURRENCY |
    DatatableTableColumnType.ARRAY |
    DatatableTableColumnType.PROGRESSBAR |
    DatatableTableColumnType.HTML>
}

interface BooleanColumnTyping extends ColumnTyping {
  type: DatatableTableColumnType.BOOLEAN;
  trueLabel?: string;
  falseLabel?: string;
}

interface EnumColumnTyping extends ColumnTyping {
  type: DatatableTableColumnType.ENUM;
  enumOptions: DatatableTableEnumOption[];
}

interface DateColumnTyping extends ColumnTyping {
  type: DatatableTableColumnType.DATE;
  utc: boolean;
  format?: string;
}

interface ReferenceColumnTyping extends ColumnTyping {
  type: DatatableTableColumnType.REFERENCE;
  idKey: string;
  displayKey: string;
  multiple?: boolean;
  separator?: string;
  mapValues?: (values: any[]) => any[];
  additionalSearchProperties?: string[];
  template?: (value: any) => string;
  service?: Type<CoreSharedApiBaseService>;
  filterProp?: string;
  filters$?: Observable<Filter[]>;
  link?: string | ((value: any) => string);
  withoutModule?: boolean;
  module?: string;
  fragment?: string;
  permissions?: AppPermissions[];
  linkPermissions?: AppPermissions[];
  labelTitleTemplateKey?: string;
  optionTitleTemplateKey?: string;
  alternate?: {
    idKey: string;
    displayKey: string;
    service?: Type<CoreSharedApiBaseService>;
    filters$?: Observable<Filter[]>;
  }
}

interface CurrencyColumnTyping extends ColumnTyping {
  type: DatatableTableColumnType.CURRENCY;
}

interface ArrayColumnTyping extends ColumnTyping {
  type: DatatableTableColumnType.ARRAY,
  displayKey: string,
  joinSymbol?: string
}

interface HTMLColumnTyping extends ColumnTyping {
  type: DatatableTableColumnType.HTML
  filterProp: string;
}

interface ProgressBarColumnTyping extends ColumnTyping {
  type: DatatableTableColumnType.PROGRESSBAR
  totalKey: string;
}


