import { NgModule } from '@angular/core';
import { CoreSharedModule, CoreSharedSortableListModule } from '@nexnox-web/core-shared';
import { CorePortalEntityModule, CorePortalEntitySelectModule } from '@nexnox-web/core-portal';
import { CorePortalFeatureMasterDataContactsModule } from '@nexnox-web/core-portal/features/master-data/features/contacts';
import { CorePortalFeatureMasterDataLocationsModule } from '@nexnox-web/core-portal/features/master-data/features/locations';
import { CorePortalFeatureMasterDataLocationGroupsModule } from '@nexnox-web/core-portal/features/master-data/features/location-groups';
import { CorePortalFeatureMasterDataFunctionsModule } from '@nexnox-web/core-portal/features/master-data/features/functions';
import { DashboardDetailComponent, DashboardListComponent } from './containers';
import { CorePortalFeatureSettingsDashboardEditComponent } from './components';
import { dashboardsServices } from './store';

@NgModule({
  declarations: [
    DashboardListComponent,
    DashboardDetailComponent,

    CorePortalFeatureSettingsDashboardEditComponent
  ],
  imports: [
    CoreSharedModule,
    CorePortalEntitySelectModule,
    CoreSharedSortableListModule,

    CorePortalEntityModule,

    CorePortalFeatureMasterDataContactsModule.forFeature(),
    CorePortalFeatureMasterDataFunctionsModule.forFeature(),
    CorePortalFeatureMasterDataLocationsModule.forFeature(),
    CorePortalFeatureMasterDataLocationGroupsModule.forFeature()
  ],
  exports: [
    CorePortalFeatureSettingsDashboardEditComponent
  ],
  providers: [
    ...dashboardsServices
  ]
})
export class DashboardsDeclarationsModule {
}
