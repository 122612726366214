<ng-template #entityEditTemplate let-title="title">
    <nexnox-web-master-data-locations-location-edit #editComponent
                                                  [title]="title"
                                                  [model]="createModel$ | async"
                                                  [readonly]="false"
                                                  [creating]="true"
                                                  [loading]="(loaded$ | async) === false"
                                                  [stereotypes$]="stereotypes$"
                                                  (modelChange)="createModel$.next($event)">
    </nexnox-web-master-data-locations-location-edit>
</ng-template>

<nexnox-web-entity-overview #overviewComponent
                            [entityOverviewBaseComponent]="this"
                            [entityEditTemplate]="entityEditTemplate"
                            detailLink="/masterdata/locations"
                            module="management">
</nexnox-web-entity-overview>
