import { ChangeDetectionStrategy, Component, Injector, OnInit } from '@angular/core';
import { CorePortalEntityEditBaseComponent, CorePortalFormlyReadonlyTypes, CorePortalFormlyReadonlyTyping } from '@nexnox-web/core-portal';
import { CoreSharedSortableListItem, TaskJobStateReasonDto, TaskJobStateReasonSetDto } from '@nexnox-web/core-shared';
import { BehaviorSubject, Observable } from 'rxjs';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import { distinctUntilChanged, map, mergeMap, startWith } from 'rxjs/operators';
import { cloneDeep, isEqual, pick, values } from 'lodash';

interface CancellationReasonsValid {
  [position: number]: boolean;
}

@Component({
  selector: 'nexnox-web-cancellation-reasons-cancellation-reasons-edit',
  templateUrl: './cancellation-reasons-edit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrgaPortalFeatureCancellationReasonsEditComponent extends CorePortalEntityEditBaseComponent<TaskJobStateReasonSetDto>
  implements OnInit {
  public cancellationReasons$: Observable<CoreSharedSortableListItem[]>;

  public addCancellationReasonForm: FormGroup;
  public addCancellationReasonModelSubject: BehaviorSubject<TaskJobStateReasonDto> = new BehaviorSubject<TaskJobStateReasonDto>(null);
  public addCancellationReasonFields: FormlyFieldConfig[];

  public faPlus = faPlus;

  private cancellationReasonsSubject: BehaviorSubject<TaskJobStateReasonDto[]> = new BehaviorSubject<TaskJobStateReasonDto[]>([]);

  private readonly cancellationReasonsValid$: Observable<boolean>;
  private cancellationReasonsValidSubject: BehaviorSubject<CancellationReasonsValid> =
    new BehaviorSubject<CancellationReasonsValid>({});

  constructor(
    protected injector: Injector
  ) {
    super(injector);

    this.cancellationReasons$ = this.cancellationReasonsSubject.asObservable().pipe(
      distinctUntilChanged((a, b) => isEqual(a, b)),
      map(cancellationReasons => this.mapCancellationReasonsToSortableItems(cancellationReasons))
    );

    this.cancellationReasonsValid$ = this.cancellationReasonsValidSubject.asObservable().pipe(
      mergeMap(cancellationReasonsValid => this.cancellationReasons$.pipe(
        map(cancellationReasons => {
          const pickedCancellationReasonsValid: CancellationReasonsValid =
            pick(cancellationReasonsValid, cancellationReasons.map(x => x.position));
          return values(pickedCancellationReasonsValid).every(x => Boolean(x));
        })
      ))
    );

    this.subscribe(this.cancellationReasonsValid$.pipe(distinctUntilChanged()), cancellationReasonsValid => {
      this.modelValidSubject.next({ ...this.modelValidSubject.getValue(), cancellationReasonsValid });
      setTimeout(() => this.onModelChange(this.model));
    });
  }

  public ngOnInit(): void {
    super.ngOnInit();

    this.addCancellationReasonForm = new FormGroup({});
    this.addCancellationReasonFields = this.createAddCancellationReasonForm();
  }

  public onAddCancellationReason(): void {
    const cancellationReasonToAdd = cloneDeep(this.addCancellationReasonModelSubject.getValue());
    const newCancellationReasons = cloneDeep(this.model?.reasons ?? []);

    newCancellationReasons.push(cancellationReasonToAdd);

    this.cancellationReasonsSubject.next(newCancellationReasons);
    this.onModelChange({ ...this.model, reasons: this.cancellationReasonsSubject.getValue() });
    this.addCancellationReasonModelSubject.next({} as any);
    this.addCancellationReasonForm.reset();
    this.addCancellationReasonForm.markAsUntouched();
  }

  public onCancellationReasonChange(index: number, cancellationReason: TaskJobStateReasonDto): void {
    const newCancellationReasons = cloneDeep(this.model?.reasons ?? []);

    if (newCancellationReasons[index]) {
      newCancellationReasons[index] = cancellationReason;
    }

    this.cancellationReasonsSubject.next(newCancellationReasons);
    this.onModelChange({ ...this.model, reasons: this.cancellationReasonsSubject.getValue() });
  }

  public onCancellationReasonsChange(cancellationReasons: CoreSharedSortableListItem[]): void {
    this.cancellationReasonsSubject.next(cancellationReasons.map(item => ({
      ...item.getExternalData().model,
      content: item.title
    })));
    this.onModelChange({ ...this.model, reasons: this.cancellationReasonsSubject.getValue() });
  }

  /* istanbul ignore next */
  protected createForm(): FormlyFieldConfig[] {
    return [
      {
        key: 'title',
        type: 'input',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-12',
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.title',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.BASIC
          } as CorePortalFormlyReadonlyTyping,
          type: 'text'
        },
        expressionProperties: {
          'templateOptions.required': () => !this.readonly,
          'templateOptions.disabled': () => this.readonly,
          'templateOptions.readonly': () => this.readonly
        }
      }
    ];
  }

  /* istanbul ignore next */
  protected createAddCancellationReasonForm(): FormlyFieldConfig[] {
    return [
      {
        key: 'content',
        type: 'input',
        wrappers: ['core-portal-translated'],
        className: 'col-md-12',
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.content',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          },
          type: 'text'
        },
        expressionProperties: {
          'templateOptions.required': () => !this.readonly
        }
      }
    ];
  }

  /* istanbul ignore next */
  protected createEditCancellationReasonForm(position: number): FormlyFieldConfig[] {
    return [
      {
        key: 'content',
        type: 'input',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-12 pl-0',
        templateOptions: {
          corePortalTranslated: {
            validationMessages: {
              required: 'core-portal.core.validation.required'
            },
            formGroupClassName: 'mb-0'
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.BASIC
          } as CorePortalFormlyReadonlyTyping,
          type: 'text'
        },
        expressionProperties: {
          'templateOptions.required': () => !this.readonly,
          'templateOptions.disabled': () => this.readonly,
          'templateOptions.readonly': () => this.readonly
        },
        hooks: {
          onInit: field => this.subscribe(field.form.statusChanges.pipe(
            startWith(field.form.status),
            distinctUntilChanged((a, b) => isEqual(a, b))
          ), () => {
            const cancellationReasons = this.model.reasons;
            const cancellationReasonsValid = this.cancellationReasonsValidSubject.getValue();
            const pickedCancellationReasonsValid: CancellationReasonsValid = pick(
              cancellationReasonsValid,
              cancellationReasons.map((_, index) => index)
            );
            const newCancellationReasonsValid = {
              ...pickedCancellationReasonsValid,
              [position]: field.form.valid
            };

            if (!isEqual(cancellationReasonsValid, newCancellationReasonsValid)) {
              this.cancellationReasonsValidSubject.next(newCancellationReasonsValid);
            }
          })
        }
      }
    ];
  }

  protected setModel(model: TaskJobStateReasonSetDto): void {
    super.setModel(model);
    this.cancellationReasonsSubject.next(model?.reasons ?? []);
  }

  private mapCancellationReasonsToSortableItems(cancellationReasons: TaskJobStateReasonDto[]): CoreSharedSortableListItem[] {
    return cancellationReasons.map((cancellationReason, index) => ({
      title: cancellationReason.content,
      position: index,
      getExternalData: () => ({ index, model: cloneDeep(cancellationReason) }),
      getOneTimeExternalData: () => ({
        form: new FormGroup({}),
        fields: this.createEditCancellationReasonForm(index)
      })
    }));
  }
}
