import {Injectable} from '@angular/core';
import {CoreSharedBreadcrumb, CoreSharedBreadcrumbsResolver, I18nBreadcrumbResolver} from '@nexnox-web/core-shared';
import {ActivatedRouteSnapshot} from '@angular/router';
import {catchError, combineLatest, Observable} from 'rxjs';
import {map} from "rxjs/operators";
import {TranslateService} from "@ngx-translate/core";


@Injectable({
  providedIn: 'root'
})
export class TicketMissionsBreadcrumbsResolver extends CoreSharedBreadcrumbsResolver {
  constructor(
    private translate: TranslateService,
    private i18nBreadcrumbResolver: I18nBreadcrumbResolver
  ) {
    super();
  }

  public resolve(route: ActivatedRouteSnapshot): Observable<CoreSharedBreadcrumb[]> {
    const baseRoute = this.getPathUntil(route, 'tickets');
    const ticketId = route.paramMap.get('ticketId');
    const missionId = route.paramMap.get('missionId');


    return combineLatest(
      this.translate.stream('core-portal.core.header.breadcrumbs.tickets.ticket-detail'),
      this.translate.stream('core-portal.core.header.breadcrumbs.missions.mission-list'),
      this.translate.stream('missions.subtitles.mission-create')
    ).pipe(
      map(([ticketsText, missionsText, missionText]) => [
        {
          path: `${baseRoute}/tickets/${ticketId}`,
          text: ticketsText
        },
        {
          path: `${baseRoute}/tickets/${ticketId}/missions`,
          text: missionsText
        },
        {
          path: `${baseRoute}/tickets/${ticketId}/missions/${missionId ?? 'create'}`,
          text: missionId ?? missionText
        }
      ]),
      catchError(error => this.i18nBreadcrumbResolver.resolve(route))
    );
  }
}
