import {CoreSharedApiBaseService, Product} from '@nexnox-web/core-shared';
import {Injectable, Injector} from '@angular/core';

@Injectable()
export class TechPortalFeatureResourceControlProtocolErrorsService extends CoreSharedApiBaseService {
  constructor(
    protected injector: Injector
  ) {
    super(injector, Product.TECH, 'occurredErrorProtocol', ['v2/resource']);
  }
}
