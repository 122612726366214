<ng-container *ngIf="baseComponent as bc; else loading">
  <div class="d-flex mb-3 justify-content-md-end w-100">
    <div class="d-flex align-items-md-center flex-wrap flex-column flex-md-row w-100 justify-content-start mb-3">
      <p-multiSelect [options]="filterItemOptions"
                     [ngModel]="filterItemsSubject | async"
                     optionLabel="label"
                     optionValue="value"
                     [disabled]="(bc.loaded$ | async) === false"
                     [filter]="false"
                     [placeholder]="'core-shared.shared.select.selected' | translate:{amount: 0}"
                     styleClass="multiSelect mb-1 mb-md-0"
                     (ngModelChange)="onChangeShowFilter($event)">
        <ng-template pTemplate="selectedItems"
                     let-items>
          <span *ngIf="items?.length >= 3">{{ 'core-shared.shared.select.all' | translate }}</span>
          <span *ngIf="items?.length < 3">{{ 'core-shared.shared.select.selected' | translate:{ amount: items?.length } }}</span>
        </ng-template>

        <ng-template pTemplate="item"
                     let-item>
          <span>{{ item.label | translate }}</span>
        </ng-template>
      </p-multiSelect>

      <ng-select [ngModel]="selectedSolutionMember"
                 [items]="(bc.solution$ | async)?.members"
                 [loading]="(bc.loaded$ | async) === false"
                 [readonly]="(bc.loaded$ | async) === false"
                 groupBy="type"
                 [searchable]="true"
                 [searchFn]="searchFn"
                 [placeholder]="'solutions.descriptions.no-filters' | translate"
                 class="ng-form-select ml-md-2 mb-1 mb-md-0 multiSelect multiSelect-height"
                 (ngModelChange)="onSelectedSolutionMemberChange($event)">
        <ng-template ng-label-tmp
                     let-item="item">
          {{ item.title }}
        </ng-template>

        <ng-template ng-option-tmp
                     let-item="item"
                     let-searchTerm="searchTerm">
          <span [title]="item.title"
                [innerHTML]="item.title | nexnoxWebHighlight:searchTerm">
          </span>
        </ng-template>

        <ng-template ng-optgroup-tmp
                     let-item="item"
                     let-searchTerm="searchTerm">
          <span *ngIf="('solutions.solution-members.' + item.type) | translate as title"
                [title]="title"
                [innerHTML]="title | nexnoxWebHighlight:searchTerm">
          </span>
        </ng-template>

        <ng-template ng-notfound-tmp>
          <div class="ng-option disabled">
            {{ 'core-shared.shared.table.empty' | translate }}
          </div>
        </ng-template>
      </ng-select>

      <button class="btn btn-outline-secondary multiSelect-height ml-md-2"
              [title]="'core-shared.shared.table.tooltip.refresh' | translate"
              [disabled]="(bc.loaded$ | async) === false || (bc.loading$ | async)"
              (click)="onRefresh()">
        <fa-icon *ngIf="((bc.loaded$ | async) && (bc.loading$ | async) === false); else loadingTemplate"
                 [icon]="faSync"></fa-icon>

        <ng-template #loadingTemplate>
          <nexnox-web-loading-icon></nexnox-web-loading-icon>
        </ng-template>
      </button>
    </div>
  </div>

  <ng-container *ngIf="(bc.loading$ | async) === false && bc.entities$ | async as entities; else loading">
    <ng-container *ngIf="(bc.loaded$ | async) && entities?.length; else emptyTemplate">
      <nexnox-web-solutions-solution-item *ngFor="let item of entities; last as isLastItem"
                                          [entityData]="bc.entityData$ | async"
                                          [item]="item.entity"
                                          [isLastItem]="isLastItem"
                                          [readonly]="(bc.readonly$ | async) || readonly"
                                          [loading$]="bc.loading$"
                                          [showFilters$]="showFilters$"
                                          (markAsSolution)="bc.onMarkAsSolution($event)"
                                          (unmarkAsSolution)="bc.onUnmarkAsSolution($event)"
                                          (replyToMail)="bc.onReplyToMail($event)"
                                          (replyToChatMessage)="bc.onReplyToChatMessage($event)">
      </nexnox-web-solutions-solution-item>
    </ng-container>
  </ng-container>

  <nexnox-web-content-container-infinite-scroll *ngIf="bc.paging$ | async as paging"
                                                [loading]="(bc.appendLoading$ | async)"
                                                [disabled]="(bc.loading$ | async)"
                                                [finished]="paging.pageNumber >= paging.totalPages"
                                                [error]="bc.hasError$ | async"
                                                (loadMore)="bc.onMore(paging)">
  </nexnox-web-content-container-infinite-scroll>
</ng-container>

<ng-template #loading>
  <nexnox-web-solutions-solution-item [loading]="true"></nexnox-web-solutions-solution-item>
</ng-template>

<ng-template #emptyTemplate>
  <span>{{ 'core-shared.shared.table.empty' | translate }}</span>
</ng-template>
