import { ChangeDetectionStrategy, Component, Injector } from '@angular/core';
import { ActionButton, CorePortalEntityDetailBaseComponent } from '@nexnox-web/core-portal';
import { AppPermissions, HolidaySetDto } from '@nexnox-web/core-shared';
import { holidaysDetailStore } from '../../store';
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import { map, take } from 'rxjs/operators';
import { isEmpty } from 'lodash';

@Component({
  selector: 'nexnox-web-holidays-holidays-detail',
  templateUrl: './holidays-detail.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HolidaysDetailComponent extends CorePortalEntityDetailBaseComponent<HolidaySetDto> {
  public title = 'core-portal.master-data.subtitles.holidays-detail';

  protected idParam = 'holidaySetId';
  protected displayKey = 'name';

  constructor(
    protected injector: Injector
  ) {
    super(injector, holidaysDetailStore);
  }

  /* istanbul ignore next */
  protected async getActionButtons(): Promise<ActionButton[]> {
    return [
      ...this.getDefaultActionButtons(
        'core-portal.master-data.actions.edit-holidays',
        'core-portal.master-data.actions.save-holidays',
        'core-portal.master-data.actions.delete-holidays',
        'core-portal.master-data.descriptions.delete-holidays',
        AppPermissions.UpdateHolidaySet,
        AppPermissions.DeleteHolidaySet,
        ['/masterdata/holidays']
      ),
      {
        label: 'core-shared.shared.actions.duplicate',
        type: 'button',
        class: 'btn-outline-secondary',
        permission: AppPermissions.CreateHolidaySet,
        icon: faPlus,
        shouldShow: () => this.entity$.pipe(
          map(entity => Boolean(entity) && !isEmpty(entity))
        ),
        callback: async () => {
          const entity = await this.entity$.pipe(take(1)).toPromise();

          if (entity) {
            this.entityCreatePresetService.setPreset('CorePortalFeatureMasterDataHolidaysEditComponent', {
              name: entity.name,
              holidays: (entity.holidays ?? []).map(x => ({ name: x.name, date: x.date }))
            } as HolidaySetDto);
            await this.tenantRouter.navigate(['/masterdata/holidays'], { fragment: 'create' });
          }
        }
      }
    ];
  }
}
