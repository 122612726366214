import { CoreSharedApiBaseService } from '@nexnox-web/core-shared';
import { Injectable, Injector } from '@angular/core';

@Injectable()
export class LocationMissionService extends CoreSharedApiBaseService {
  constructor(
    protected injector: Injector
  ) {
    super(injector, null, 'mission', ['location']);
  }
}
