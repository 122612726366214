import {EntityXsStoreState} from '@nexnox-web/core-store';
import {AppEntityType, LocationDto} from '@nexnox-web/core-shared';
import {createSelector} from '@ngrx/store';
import {selectLocationsState} from '../../locations.selectors';
import {CorePortalFeatureMasterDataLocationService} from '../../services';
import {
  LocationDetailXsStore
} from "./location-detail.xs-store";

export interface LocationDetailStoreState extends EntityXsStoreState<LocationDto & LocationDto> {
}

/* istanbul ignore next */
export const locationDetailStore = new LocationDetailXsStore({
  actionLabel: 'Core Portal - Master Data - Location Detail',
  stateSelector: createSelector(selectLocationsState, state => state.locationDetail),
  serviceType: CorePortalFeatureMasterDataLocationService,
  entityType: AppEntityType.Location,
  prepareEntity: (entity, base) => {
    const newEntity = base(entity);

    CorePortalFeatureMasterDataLocationService.mapOpenDaysToWeekDayArray(newEntity);

    return newEntity;
  },
  sanitizeModel: (model, entity, base) => {
    const newModel = base(model, entity);

    CorePortalFeatureMasterDataLocationService.mapOpenDaysToBooleanFields(newModel);

    return newModel;
  }
});
