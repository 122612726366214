import {
  InfoProgressContactReadUnreadService,
  InfoProgressContactService,
  InfoProgressFunctionService,
  InfoProgressLocationService,
  OrgaPortalFeatureInfoService
} from './services';
import {
  infoDetailStore,
  infoListStore,
  infoProgressContactListStore,
  infoProgressContactReadUnreadListStore,
  infoProgressFunctionListStore,
  infoProgressLocationListStore
} from './stores';

export * from './services';
export * from './stores';

export * from './infos.state';
export * from './infos.selectors';

export const infosReducers = {
  infoList: infoListStore.reducer,
  infoDetail: infoDetailStore.reducer,

  infoProgressContactList: infoProgressContactListStore.reducer,
  infoProgressContactReadUnreadList: infoProgressContactReadUnreadListStore.reducer,
  infoProgressFunctionList: infoProgressFunctionListStore.reducer,
  infoProgressLocationList: infoProgressLocationListStore.reducer
};

export const infosEffects = [
  infoListStore.effects,
  infoDetailStore.effects,

  infoProgressContactListStore.effects,
  infoProgressContactReadUnreadListStore.effects,
  infoProgressFunctionListStore.effects,
  infoProgressLocationListStore.effects
];

export const infosServices = [
  OrgaPortalFeatureInfoService,
  InfoProgressFunctionService,
  InfoProgressLocationService,
  InfoProgressContactReadUnreadService,
  InfoProgressContactService
];
