import {Injectable} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {BehaviorSubject, combineLatest, Observable} from 'rxjs';
import {distinctUntilChanged, map, shareReplay} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';
import {UnsubscribeHelper} from '@nexnox-web/core-shared';
import {isUndefined} from 'lodash';

@Injectable()
export class CorePortalPageTitleService extends UnsubscribeHelper {
  public title$: Observable<string>;

  private titleSubject = new BehaviorSubject<string>(undefined);
  private entitySubject = new BehaviorSubject<string>(undefined);

  constructor(
    private titleService: Title,
    private translateService: TranslateService
  ) {
    super();

    this.title$ = combineLatest([
      this.titleSubject.asObservable(),
      this.entitySubject.asObservable(),
      this.translateService.onLangChange.asObservable().pipe(
        map(({lang}) => lang),
        distinctUntilChanged(),
        shareReplay(1)
      )
    ]).pipe(
      map(([title, entity]) => {
        const translatedTitle = this.translateService.instant(title ?? 'No title');
        return `${translatedTitle}${!isUndefined(entity) ? ` - ${entity}` : ''}`;
      }),
      shareReplay(1)
    );

    this.subscribe(this.title$.pipe(distinctUntilChanged()), title => this.titleService.setTitle(title));
  }

  public setPageTitle(title: string, entity?: string): void {
    this.titleSubject.next(title);
    this.entitySubject.next(Boolean(entity) ? entity : undefined);
  }
}
