import { ChangeDetectionStrategy, Component, Injector, OnInit } from '@angular/core';
import { CorePortalEntityEditBaseComponent, CorePortalFormlyReadonlyTypes, CorePortalFormlyReadonlyTyping } from '@nexnox-web/core-portal';
import { CoreSharedSortableListItem, HolidayDto, HolidaySetDto } from '@nexnox-web/core-shared';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged, map, mergeMap, startWith, tap } from 'rxjs/operators';
import { FormGroup } from '@angular/forms';
import { cloneDeep, isEqual, maxBy, pick, values } from 'lodash';
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import dayjs from 'dayjs';

interface HolidaysValid {
  [position: number]: boolean;
}

export interface LocalHolidayDto extends HolidayDto {
  localId?: number;
}

@Component({
  selector: 'nexnox-web-master-data-holidays-holidays-edit',
  templateUrl: './holidays-edit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CorePortalFeatureMasterDataHolidaysEditComponent extends CorePortalEntityEditBaseComponent<HolidaySetDto> implements OnInit {
  public holidays$: Observable<CoreSharedSortableListItem[]>;

  public addHolidayForm: FormGroup;
  public addHolidayModelSubject: BehaviorSubject<LocalHolidayDto> = new BehaviorSubject<LocalHolidayDto>(null);
  public addHolidayFields: FormlyFieldConfig[];

  public faPlus = faPlus;

  private holidaysSubject: BehaviorSubject<LocalHolidayDto[]> = new BehaviorSubject<LocalHolidayDto[]>([]);

  private readonly holidaysValid$: Observable<boolean>;
  private holidaysValidSubject: BehaviorSubject<HolidaysValid> = new BehaviorSubject<HolidaysValid>({});

  constructor(
    protected injector: Injector
  ) {
    super(injector, 'CorePortalFeatureMasterDataHolidaysEditComponent');

    this.holidays$ = this.holidaysSubject.asObservable().pipe(
      distinctUntilChanged((a, b) => isEqual(a, b)),
      map(holidays => cloneDeep(holidays).sort(this.sortByDate)),
      map(holidays => this.mapHolidaysToSortableItems(holidays))
    );

    this.holidaysValid$ = this.holidaysValidSubject.asObservable().pipe(
      mergeMap(holidaysValid => this.holidays$.pipe(
        map(holidays => {
          const pickedHolidaysValid: HolidaysValid = pick(holidaysValid, holidays.map(x => x.position));
          return values(pickedHolidaysValid).every(x => Boolean(x));
        })
      ))
    );

    this.subscribe(this.holidaysValid$.pipe(distinctUntilChanged()), holidaysValid => {
      this.modelValidSubject.next({ ...this.modelValidSubject.getValue(), holidaysValid });
      setTimeout(() => this.onModelChange(this.model));
    });
  }

  public trackByHolidayIdFn = (index, item): number => item.getExternalData().id;

  public ngOnInit(): void {
    super.ngOnInit();

    this.addHolidayForm = new FormGroup({});
    this.addHolidayFields = this.createAddHolidayForm();
  }

  public onAddHoliday(): void {
    const holidayToAdd = cloneDeep(this.addHolidayModelSubject.getValue());
    const newHolidays = cloneDeep(this.model?.holidays ?? []) as LocalHolidayDto[];
    const lastHoliday = maxBy(newHolidays, x => x.holidayId ?? x.localId);
    const lastHolidayId = lastHoliday?.holidayId ?? lastHoliday?.localId;

    newHolidays.push({
      ...holidayToAdd,
      localId: (lastHolidayId ?? 0) + 1
    });

    this.holidaysSubject.next(newHolidays);
    this.onModelChange({ ...this.model, holidays: this.holidaysSubject.getValue() });
    this.addHolidayModelSubject.next({} as any);
    this.addHolidayForm.reset();
    this.addHolidayForm.markAsUntouched();
  }

  public onHolidayChange(id: number, holiday: HolidayDto): void {
    const newHolidays = cloneDeep(this.model?.holidays ?? []) as LocalHolidayDto[];
    const index = newHolidays.findIndex(x => (x.holidayId ?? x.localId) === id);

    if (index > -1) {
      newHolidays[index] = holiday;
    }

    this.holidaysSubject.next(newHolidays);
    this.onModelChange({ ...this.model, holidays: this.holidaysSubject.getValue() });
  }

  public onHolidaysChange(holidays: CoreSharedSortableListItem[]): void {
    this.holidaysSubject.next(holidays.map(item => ({
      ...item.getExternalData().model,
      name: item.title
    })));
    this.onModelChange({ ...this.model, holidays: this.holidaysSubject.getValue() });
  }

  /* istanbul ignore next */
  protected createForm(): FormlyFieldConfig[] {
    return [
      {
        key: 'name',
        type: 'input',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-12',
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.name',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.BASIC
          } as CorePortalFormlyReadonlyTyping,
          type: 'text'
        },
        expressionProperties: {
          'templateOptions.required': () => !this.readonly,
          'templateOptions.disabled': () => this.readonly,
          'templateOptions.readonly': () => this.readonly
        }
      }
    ];
  }

  /* istanbul ignore next */
  protected createAddHolidayForm(): FormlyFieldConfig[] {
    return [
      {
        key: 'name',
        type: 'input',
        wrappers: ['core-portal-translated'],
        className: 'col-md-6',
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.name',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          },
          type: 'text'
        },
        expressionProperties: {
          'templateOptions.required': () => !this.readonly
        }
      },
      {
        key: 'date',
        type: 'core-portal-datepicker',
        wrappers: ['core-portal-translated'],
        className: 'col-md-6',
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.date',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          },
          noUtc: true
        },
        expressionProperties: {
          'templateOptions.required': () => !this.readonly
        }
      }
    ];
  }

  /* istanbul ignore next */
  protected createEditHolidayForm(position: number): FormlyFieldConfig[] {
    return [
      {
        key: 'name',
        type: 'input',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-7',
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.name',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            },
            horizontal: true,
            horizontalLabelClass: 'col-sm-3',
            horizontalFieldClass: 'col-sm-9',
            formGroupClassName: 'mb-0'
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.BASIC
          } as CorePortalFormlyReadonlyTyping,
          type: 'text'
        },
        expressionProperties: {
          'templateOptions.required': () => !this.readonly,
          'templateOptions.disabled': () => this.readonly,
          'templateOptions.readonly': () => this.readonly
        },
        hooks: {
          onInit: field => this.subscribe(field.form.statusChanges.pipe(
            startWith(field.form.status),
            distinctUntilChanged((a, b) => isEqual(a, b))
          ), () => {
            const holidays = this.model.holidays;
            const holidaysValid = this.holidaysValidSubject.getValue();
            const pickedHolidaysValid: HolidaysValid = pick(holidaysValid, holidays.map((_, index) => index));
            const newHolidaysValid = {
              ...pickedHolidaysValid,
              [position]: field.form.valid
            };

            if (!isEqual(holidaysValid, newHolidaysValid)) {
              this.holidaysValidSubject.next(newHolidaysValid);
            }
          })
        }
      },
      {
        key: 'date',
        type: 'core-portal-datepicker',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-5',
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.date',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            },
            horizontal: true,
            horizontalLabelClass: 'col-sm-3',
            horizontalFieldClass: 'col-sm-9',
            formGroupClassName: 'mb-0'
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.DATE,
            format: 'LL'
          } as CorePortalFormlyReadonlyTyping,
          noUtc: true,
          performanceMode: true
        },
        expressionProperties: {
          'templateOptions.required': () => !this.readonly,
          'templateOptions.disabled': () => this.readonly,
          'templateOptions.readonly': () => this.readonly
        }
      }
    ];
  }

  protected setModel(model: HolidaySetDto): void {
    super.setModel(model);
    this.holidaysSubject.next(model?.holidays ?? []);
  }

  private mapHolidaysToSortableItems(holidays: LocalHolidayDto[]): CoreSharedSortableListItem[] {
    return holidays.map((holiday, index) => ({
      title: holiday.name,
      position: index,
      getExternalData: () => ({ id: holiday.holidayId ?? holiday.localId, model: cloneDeep(holiday) }),
      getOneTimeExternalData: () => ({
        form: new FormGroup({}),
        fields: this.createEditHolidayForm(index)
      })
    }));
  }

  private sortByDate(a: HolidayDto, b: HolidayDto): number {
    return dayjs(a.date).isBefore(dayjs(b.date)) ? -1 : dayjs(a.date).isAfter(dayjs(b.date)) ? 1 : 0;
  }
}
