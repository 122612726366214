import {CoreSharedApiBaseService, Product} from '@nexnox-web/core-shared';
import {Injectable, Injector} from '@angular/core';
import {map} from "rxjs/operators";
import {Observable} from "rxjs";

@Injectable()
export class TechPortalFeatureResourceOccurredHintsService extends CoreSharedApiBaseService {
  constructor(
    protected injector: Injector
  ) {
    super(injector, Product.TECH, 'occurredHint', ['resource']);
  }

  public deleteOccurredHint<T>(occurredHintId: string | number): Observable<T> {
    return this.httpRequestService.delete<T>(`v2/occurredhint/${occurredHintId}`).pipe(
      map(response => response.body)
    );
  }
}
