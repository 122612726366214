<div class="mb-3">
  <p-checkbox [ngModel]="strictOrder$ | async"
              [label]="'orga-portal.tasks.fields.strict-order' | translate"
              [disabled]="readonly"
              [binary]="true"
              (ngModelChange)="onStrictOrderChange($event)">
  </p-checkbox>
</div>

<nexnox-web-rule-editor-list #subtasksEdit
                             [items]="sortedSubtasks$ | async"
                             [title]="subtasksTitleFn"
                             [inheritedFieldsFn]="subtasksFieldsFn"
                             [canSelectFn]="subtasksCanSelectFn"
                             [selectFn]="subtasksSelectFn"
                             [disabled]="readonly"
                             [asCards]="true"
                             [sortable]="true"
                             sortKey="position"
                             [maxItems]="8"
                             (itemsChange)="onSubtasksChange($event)">
</nexnox-web-rule-editor-list>
