import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';
import { Observable } from 'rxjs';
import { map, mergeMap, startWith } from 'rxjs/operators';
import { keys } from 'lodash';
import { FormlyFieldConfig } from '@ngx-formly/core';

export interface ValidationMessageWithArgs {
  key: string;
  args?: any;
}

interface LocalValidationMessage {
  key: string;
  message: string | ValidationMessageWithArgs;
  hasError: boolean;
  hasArgs: boolean;
}

@Component({
  selector: 'nexnox-web-formly-validation-messages',
  templateUrl: './formly-validation-messages.component.html'
})
export class FormlyValidationMessagesComponent implements OnChanges {
  @Input() public field: FormlyFieldConfig;

  public errors$: Observable<string[]>;
  public validationMessages$: Observable<LocalValidationMessage[]>;

  public ngOnChanges(): void {
    this.errors$ = this.field.formControl.statusChanges.pipe(
      startWith(null),
      mergeMap(() => this.field.formControl.valueChanges.pipe(
        startWith(null),
        map(() => Object.keys(this.field.formControl.errors ?? {}))
      ))
    );

    this.validationMessages$ = this.errors$.pipe(
      map(errors => keys(this.field.templateOptions.corePortalTranslated?.validationMessages ?? {}).map(key => {
        const message = this.field.templateOptions.corePortalTranslated?.validationMessages[key];

        return {
          key,
          message,
          hasError: errors.includes(key),
          hasArgs: this.hasArgs(message)
        };
      }))
    );
  }

  private hasArgs(validationMessage: string | ValidationMessageWithArgs): validationMessage is ValidationMessageWithArgs {
    if (typeof validationMessage === 'string') {
      return false;
    }

    return 'key' in validationMessage && 'args' in validationMessage ? Boolean(validationMessage.args) : false;
  }
}
