<ng-template #entityEditTemplate
             let-title="title"
             let-id="id">
  <nexnox-web-settings-manufacturer-edit #modelComponent
                                         [title]="title"
                                         [id]="id"
                                         [model]="model$ | async"
                                         [readonly]="readonly$ | async"
                                         [creating]="false"
                                         [stereotyped]="false"
                                         [loading]="loading$ | async"
                                         (modelChange)="onModelChange($event)">
  </nexnox-web-settings-manufacturer-edit>
</ng-template>

<nexnox-web-merge-manufacturer-sidebar #mergeManufacturerSidebar
                                       [manufacturer]="manufacturerToMergeSubject"
                                       (merge)="mergeManufacturer($event)">
</nexnox-web-merge-manufacturer-sidebar>

<nexnox-web-entity-detail [entityDetailBaseComponent]="this"
                          [entityEditTemplate]="entityEditTemplate"
                          tabId="manufacturer"
                          [tabHeading]="title | translate"
                          [tabCommands]="['.']">

  <!-- Missions Tab -->
  <ng-template nexnoxWebTabTemplate
               tabId="model"
               [heading]="'core-portal.settings.subtitles.model-list' | translate"
               [lazy]="true"
               (selectTab)="onNavigateToTab(['model'])">
    <nexnox-web-settings-model-list *ngIf="passedIds"
                                    [isEmbedded]="true"
                                    [parentIds]="passedIds">

    </nexnox-web-settings-model-list>
  </ng-template>
</nexnox-web-entity-detail>
