import {ChangeDetectionStrategy, Component, Injector} from '@angular/core';
import {
  ActionButton,
  CorePortalEntityOverviewBaseComponent,
  DatatableActionButton,
  DeleteEntityModel
} from '@nexnox-web/core-portal';
import {AppEntityType, AppPermissions, ExportType, Mappers, PrioritySetDto} from '@nexnox-web/core-shared';
import {prioritySetListStore} from '../../store/stores';

@Component({
  selector: 'nexnox-web-ticket-settings-priority-sets-priority-set-list',
  templateUrl: './priority-set-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PrioritySetListComponent extends CorePortalEntityOverviewBaseComponent<PrioritySetDto> {
  public title = 'core-portal.settings.subtitles.ticket-settings.priority-set-list';
  public createTitle = 'core-portal.settings.subtitles.ticket-settings.priority-set-create';
  public idProperty = 'prioritySetId';
  public displayProperty = 'name';
  public datatableConfigName = 'SettingsTicketSettingsPrioritySetDefault';
  public componentId = 'PrioritySetListComponent';

  constructor(
    protected injector: Injector
  ) {
    super(injector, prioritySetListStore, Mappers.PrioritySetListDto.serializedName, AppEntityType.None);
  }

  /* istanbul ignore next */
  public getRowActionButtons(): DatatableActionButton[] {
    return [
      ...this.getDefaultRowActionButtons(
        'core-portal.settings.actions.ticket-settings.edit-priority-set',
        (row: PrioritySetDto) => `/priority-sets/${row.prioritySetId}`,
        [AppPermissions.UpdatePrioritySet],
        {
          module: 'settings'
        }
      ),
      this.exportService.getRowActionExportButton(ExportType.PRIORITY_SET, this.idProperty)
    ];
  }

  public getDeleteEntityModel(): DeleteEntityModel {
    return {
      titleKey: 'core-portal.settings.actions.ticket-settings.delete-priority-set',
      descriptionKey: 'core-portal.settings.descriptions.ticket-settings.delete-priority-set',
      confirmKey: 'core-portal.settings.actions.ticket-settings.delete-priority-set',
      deletePermission: AppPermissions.DeletePrioritySet
    };
  }

  protected async getActionButtons(): Promise<ActionButton[]> {
    return this.getDefaultActionButtons(
      'core-portal.settings.actions.ticket-settings.create-priority-set',
      AppPermissions.CreatePrioritySet
    );
  }
}
