import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import { BasicTableColumnType, BasicTableConfig, MissionReceiptDto, MissionReportReceiptDto } from '@nexnox-web/core-shared';
import { select, Store } from '@ngrx/store';
import { filter, map, Observable } from 'rxjs';
import { missionReceiptStore } from '../../store';

@Component({
  selector: 'nexnox-web-missions-mission-receipt',
  templateUrl: './mission-receipt.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MissionReceiptComponent implements OnInit {

  @Input() public missionId: number;

  public items$: Observable<MissionReceiptDto[]>;
  public entity$: Observable<MissionReportReceiptDto>;
  public model$: Observable<MissionReportReceiptDto>
  public loading$: Observable<boolean>
  public loaded$: Observable<boolean>

  public tableConfig: BasicTableConfig;
  public faTimes = faTimes;

  constructor(
    private store: Store
  ) {
    this.entity$ = this.store.pipe(select(missionReceiptStore.selectors.selectEntity));
    this.model$ = this.store.pipe(select(missionReceiptStore.selectors.selectModel));
    this.loading$ = this.store.pipe(select(missionReceiptStore.selectors.selectLoading));
    this.loaded$ = this.store.pipe(select(missionReceiptStore.selectors.selectLoaded));
  }

  /* istanbul ignore next */
  public ngOnInit(): void {

    this.store.dispatch(missionReceiptStore.actions.getReceipt({ missionId: this.missionId }));

    this.items$ = this.model$.pipe(
      filter(model => Boolean(model.items)),
      map(receipt => receipt.items)
    );

    this.tableConfig = {
      dateFormat: 'L',
      columns: [
        {
          header: 'missions.fields.mission-receipt.posting-date',
          key: 'bookedAt',
          type: BasicTableColumnType.Date
        },
        {
          header: 'core-shared.shared.fields.title',
          key: 'title',
          type: BasicTableColumnType.Text
        },
        {
          header: 'missions.fields.mission-receipt.credit',
          key: 'credit',
          align: 'right',
          type: BasicTableColumnType.Currency
        },
        {
          header: 'missions.fields.mission-receipt.debit',
          key: 'debit',
          align: 'right',
          type: BasicTableColumnType.Currency
        },
        {
          header: 'missions.fields.mission-receipt.tax',
          key: 'taxInPercent',
          align: 'right',
          suffix: ' %',
          type: BasicTableColumnType.Text
        }
      ]
    }
  };
}
