import { Injectable } from '@angular/core';
import { cloneDeep, isNull, isUndefined, keys } from 'lodash';
import { DatatableTableColumn, DatatableTableColumnType, getAllNestedProperties } from '../../models';
import { CompositeMapper } from '@azure/ms-rest-js';
import mapperMetadata from '@nexnox-web/core-shared/models/metadata';
import { FilterDto, GroupedFilterDto } from '@nexnox-web/core-shared';

export interface SimpleDatatableFilterField {
  property: string;
}

interface FiltersObject {
  [key: string]: FilterDto;
}

@Injectable()
export class CorePortalDatatableFilterService {
  public getFilterColumns(mapper: CompositeMapper): string[] {
    return getAllNestedProperties(mapper, mapperMetadata)
      .filter(x => x.filterable)
      .map(property => property.name);
  }

  public getCurrentFilters(defaultFilter: FilterDto[]): FilterDto[] {
    return defaultFilter?.length ? cloneDeep(defaultFilter) : [];
  }

  public buildFilters(filters: string[]): SimpleDatatableFilterField[] {
    return filters.map(filter => ({
      property: filter
    }));
  }

  public buildSortFilter(
    filters: FiltersObject,
    defaultFilters: FilterDto[],
    column: DatatableTableColumn,
    filter: FilterDto
  ): [FiltersObject, FilterDto[]] {
    let columnKey = column.customPropertyId ? column.customPropertyId?.toString() : column.prop;

    if (column.type === DatatableTableColumnType.REFERENCE) {
      columnKey = column.filterProp ? column.filterProp : `${column.prop}.${column.idKey}`;
    }

    if (column.type === DatatableTableColumnType.PATH) {
      columnKey = column.filterProp ? column.filterProp : `${column.prop}.id`;
    }

    const filtersClone = cloneDeep(filters);
    const requestFilters = cloneDeep(defaultFilters);
    const isFilterValueSet = !isUndefined(filter?.value) && !isNull(filter.value);
    const isGroupedFilterSet = !isUndefined((filter as GroupedFilterDto)?.children) &&
      !isNull((filter as GroupedFilterDto).children) &&
      (filter as GroupedFilterDto).children.length;
    filtersClone[columnKey] = isFilterValueSet || isGroupedFilterSet ? filter : null;

    if (!filtersClone[columnKey]) {
      delete filtersClone[columnKey];
    }

    for (const filterKey of keys(filtersClone)) {
      requestFilters.push(filtersClone[filterKey]);
    }

    return [filtersClone, requestFilters];
  }
}
