<ng-template #entityEditTemplate>

  <nexnox-web-settings-model-edit #modelComponent
                                  [title]="title | translate"
                                  [model]="model$ | async"
                                  [creating]="isCreate$ | async"
                                  [readonly]="readonly$ | async"
                                  [loading]="loading$ | async"
                                  [stereotyped]="false"
                                  (modelChange)="onModelChange($event)">
  </nexnox-web-settings-model-edit>
</ng-template>

<nexnox-web-move-model-sidebar #moveModelSidebar
                               [modelToMove]="modelToMergeOrMoveSubject"
                               (move)="moveModel($event)">
</nexnox-web-move-model-sidebar>

<nexnox-web-merge-model-sidebar #mergeModelSidebar
                                [modelToMerge]="modelToMergeOrMoveSubject"
                                (merge)="mergeModel($event)">
</nexnox-web-merge-model-sidebar>

<nexnox-web-entity-detail [entityDetailBaseComponent]="this"
                          [useTabs]="false"
                          [entityEditTemplate]="entityEditTemplate">
</nexnox-web-entity-detail>
