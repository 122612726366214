import { PagedEntitiesXsStore, PagedEntitiesXsStoreCreateOneSuccessPayload, PagedEntitiesXsStoreState } from '@nexnox-web/core-store';
import { AddressDto, AppEntityType } from '@nexnox-web/core-shared';
import { Action, createSelector } from '@ngrx/store';
import { selectCompaniesState } from '../../companies.selectors';
import { CompanyAddressService } from '../../services';
import { Injectable, Injector } from '@angular/core';

export interface CompanyAddressListStoreState extends PagedEntitiesXsStoreState<AddressDto> {
}

/* istanbul ignore next */
export const companyAddressListStore = new PagedEntitiesXsStore<AddressDto>({
  actionLabel: 'Core Portal - Master Data - Company - Address List',
  stateSelector: createSelector(selectCompaniesState, state => state.companyAddressList),
  serviceType: CompanyAddressService,
  entityType: AppEntityType.Address,
  selectId: address => address.entity?.addressId ?? address.model?.addressId
});

export function companyAddressListStoreReducer(state: CompanyAddressListStoreState, action: Action): any {
  return companyAddressListStore.reducer(state, action);
}

@Injectable()
export class CompanyAddressListStoreEffects extends companyAddressListStore.effects {
  protected createWithParentIds = false;

  constructor(
    protected injector: Injector
  ) {
    super(injector);
  }

  protected createOneSuccessActionCallback(payload: PagedEntitiesXsStoreCreateOneSuccessPayload<AddressDto, AddressDto>): void {
    super.createOneSuccessActionCallback(payload);

    this.store.dispatch(this.actions.addOneToParent({ id: payload.entity.addressId, parentIds: payload.parentIds }));
  }
}
