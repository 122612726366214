import {CorePortalInvitationsContactService, CorePortalInvitationsService} from './services';
import {
  InvitationsContactListStoreEffects,
  invitationsContactListStoreReducer,
  invitationsDetailStore,
  InvitationsDetailStoreEffects,
  InvitationsListStoreEffects,
  invitationsListStoreReducer
} from './stores';

export * from './services';
export * from './stores';

export * from './invitations.state';
export * from './invitations.selectors';

export const invitationsReducers = {
  invitationsList: invitationsListStoreReducer,
  invitationsDetail: invitationsDetailStore.reducer,
  invitationsContactList: invitationsContactListStoreReducer
};

export const invitationsEffects = [
  InvitationsListStoreEffects,
  InvitationsDetailStoreEffects,
  InvitationsContactListStoreEffects
];

export const invitationsServices = [
  CorePortalInvitationsService,
  CorePortalInvitationsContactService
];
