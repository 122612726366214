import {ChangeDetectionStrategy, Component, Injector, Input, ViewChild} from '@angular/core';
import {
  CorePortalCardboxAction,
  CorePortalEntityCreatePresetService,
  CorePortalEntityOverviewBaseComponent,
  DatatableActionButton
} from '@nexnox-web/core-portal';
import {
  AppEntityType,
  AppPermissions,
  ContactDto,
  ControllerOperationId,
  LocationDto,
  Mappers
} from '@nexnox-web/core-shared';
import {locationContactListStore} from '../../store';
import {faPlus} from "@fortawesome/free-solid-svg-icons/faPlus";
import {CreateAreaAssignmentSidebarComponent} from "../../sidebars";
import {Observable} from "rxjs";
import {take} from "rxjs/operators";
import {faUser} from "@fortawesome/free-solid-svg-icons/faUser";

@Component({
  selector: 'nexnox-web-master-data-locations-location-contact-list',
  templateUrl: './location-contact-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LocationContactListComponent extends CorePortalEntityOverviewBaseComponent<ContactDto> {
  @ViewChild('areaAssignmentSidebar', {static: true}) public areaAssignmentSidebar: CreateAreaAssignmentSidebarComponent;

  @Input() public location$: Observable<LocationDto>;

  public title = 'core-portal.master-data.subtitles.contact-list';
  public idProperty = 'contactId';
  public displayProperty = 'displayName';
  public pageOperation = ControllerOperationId.ContactControllerList;
  public datatableConfigName = 'MasterDataContactDefault';
  public componentId = 'LocationContactListComponent';

  constructor(
    protected injector: Injector,
    private entityCreatePresetService: CorePortalEntityCreatePresetService
  ) {
    super(injector, locationContactListStore, Mappers.ContactListDto.serializedName, AppEntityType.Contact);
  }

  /* istanbul ignore next */
  public getRowActionButtons(): DatatableActionButton[] {
    return this.getDefaultRowActionButtons(
      'core-portal.master-data.actions.edit-contact',
      contact => `masterdata/contacts/${contact.contactId}`,
      [AppPermissions.UpdateContact],
      {
        module: 'management'
      }
    );
  }

  public getCardboxHeaderActions(): CorePortalCardboxAction[] {
    return [
      {
        label: 'core-portal.master-data.actions.assign-contact',
        class: 'p-button p-button-primary',
        icon: faUser,
        tooltip: 'core-portal.master-data.actions.assign-contact',
        buttonSize: 'lg',
        permission: AppPermissions.CreateAreaAssignment,
        callback: () => this.areaAssignmentSidebar.onShow()
      },
      {
        label: 'core-portal.master-data.actions.create-contact',
        class: 'p-button p-button-primary',
        permission: AppPermissions.CreateAreaAssignment,
        icon: faPlus,
        tooltip: 'core-portal.master-data.actions.create-contact',
        buttonSize: 'lg',
        callback: async () => {
          const entity = await this.location$.pipe(take(1)).toPromise();

          if (entity) {
            this.entityCreatePresetService.setPreset('CorePortalFeatureContactEditComponent', {
              location: entity,
              backlink: {
                commands: ['/masterdata', '/locations', entity.locationId, 'contacts'],
                module: 'management'
              },
              parentComponentId: this.componentId
            });
            await this.tenantRouter.navigate(['/masterdata', '/contacts'], {module: 'management', fragment: 'create'});
          }
        }
      }
    ]
  }

  public onRefresh(): void {
    this.store.dispatch(locationContactListStore.actions.getPage(undefined, undefined, undefined, {
      parentIds: this.parentIds,
      filterOperation: undefined
    }));
  }
}
