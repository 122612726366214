import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ActivatedRouteSnapshot} from '@angular/router';
import {from, Observable} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';
import {CorePortalSettingsMenuService} from "./../../services/settings-menu.service";
import {CoreSharedBreadcrumb, CoreSharedBreadcrumbsResolver} from "@nexnox-web/core-shared";

@Injectable()
export class I18nSettingsBreadcrumbResolver extends CoreSharedBreadcrumbsResolver {
  constructor(
    private translate: TranslateService,
    private settingsMenuService: CorePortalSettingsMenuService
  ) {
    super();
  }

  public resolve(route: ActivatedRouteSnapshot): Observable<CoreSharedBreadcrumb[]> {
    const path = this.getFullPath(route);

    return this.translate.stream('core-portal.settings.menu.title').pipe(
      switchMap((text) => from(this.settingsMenuService.getFirstGrantedItem()).pipe(
        map(item => ([{text, path: `${path}/${item?.link?.join('/')}`, permissions: item?.permissions}]))
      )));
  }
}
