import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { HttpInterceptorHandler } from './http-interceptor.handler';

export abstract class HttpBaseHandler extends HttpHandler {
  protected constructor(
    protected next: HttpHandler,
    protected interceptors: HttpInterceptor[]
  ) {
    super();
  }

  public handle(request: HttpRequest<any>): Observable<HttpEvent<any>> {
    const chain = this.interceptors.reduceRight(
      (next, interceptor) => new HttpInterceptorHandler(next, interceptor),
      this.next
    );
    return chain.handle(request);
  }
}
