import {PagedEntitiesXsStore, PagedEntitiesXsStoreState} from '@nexnox-web/core-store';
import {AppEntityType, GeneralDataProtectionRegulationLogEntryListDto} from '@nexnox-web/core-shared';
import {Action, createSelector} from '@ngrx/store';
import {selectGdprState} from '../../gdpr.selectors';
import {CorePortalGdprEntryService} from '../../services';
import {Injectable, Injector} from '@angular/core';

export interface GdprEntryListStoreState extends PagedEntitiesXsStoreState<GeneralDataProtectionRegulationLogEntryListDto> {
}

/* istanbul ignore next */
export const gdprEntryListStore = new PagedEntitiesXsStore<GeneralDataProtectionRegulationLogEntryListDto>({
  actionLabel: 'Core Portal - Settings - GDPR - GDPR Entry List',
  stateSelector: createSelector(selectGdprState, state => state.gdprEntryList),
  serviceType: CorePortalGdprEntryService,
  entityType: AppEntityType.None,
  stereotyped: false,
  selectId: gdpr => gdpr.entity?.entityId ?? gdpr.model?.entityId
});

export function gdprEntryListStoreReducer(state: GdprEntryListStoreState, action: Action): any {
  return gdprEntryListStore.reducer(state, action);
}

@Injectable()
export class GdprEntryListStoreEffects extends gdprEntryListStore.effects {
  constructor(
    protected injector: Injector
  ) {
    super(injector);
  }
}
