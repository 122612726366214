import {PagedEntitiesXsStore, PagedEntitiesXsStoreState} from '@nexnox-web/core-store';
import {AppEntityType, OccurredConsumptionProtocolListDto} from '@nexnox-web/core-shared';
import {createSelector} from '@ngrx/store';
import {selectResourcesState} from '../../resources.selectors';
import {TechPortalFeatureResourceControlProtocolConsumptionsService} from '../../services';

export interface ResourceControlProtocolConsumptionsStoreState extends PagedEntitiesXsStoreState<OccurredConsumptionProtocolListDto> {
}

/* istanbul ignore next */
export const resourceControlProtocolConsumptionsStore = new PagedEntitiesXsStore<OccurredConsumptionProtocolListDto>({
  actionLabel: 'Tech Portal - Resources - Resource Control Protocol Consumptions',
  stateSelector: createSelector(selectResourcesState, state => state.resourceControlProtocolConsumptions),
  entityType: AppEntityType.None,
  serviceType: TechPortalFeatureResourceControlProtocolConsumptionsService,
  selectId: consumption => consumption.entity?.occurredConnectedConsumptionHistoryId ?? consumption.model?.occurredConnectedConsumptionHistoryId
});
