import {NotificationTargetType, NotificationTrigger} from '@nexnox-web/core-shared';

export const notificationTriggerTypeOptions = [
  { label: 'core-portal.settings.notification-rules.trigger-types.30', value: NotificationTrigger.ContractChangedState },
  { label: 'core-portal.settings.notification-rules.trigger-types.40', value: NotificationTrigger.ActivatedInfo },
  { label: 'core-portal.settings.notification-rules.trigger-types.22', value: NotificationTrigger.MissionChangedEditor },
  { label: 'core-portal.settings.notification-rules.trigger-types.20', value: NotificationTrigger.MissionChangedResource },
  { label: 'core-portal.settings.notification-rules.trigger-types.21', value: NotificationTrigger.MissionChangedState },
  { label: 'core-portal.settings.notification-rules.trigger-types.12', value: NotificationTrigger.TicketChangedEditor },
  { label: 'core-portal.settings.notification-rules.trigger-types.10', value: NotificationTrigger.TicketChangedResource },
  { label: 'core-portal.settings.notification-rules.trigger-types.11', value: NotificationTrigger.TicketChangedState },
  { label: 'core-portal.settings.notification-rules.trigger-types.23', value: NotificationTrigger.MissionChangedCompletion }
];

export const notificationTargetTypeOptions = [
  { label: 'core-portal.settings.notification-rules.target-types.1', value: NotificationTargetType.Email },
  { label: 'core-portal.settings.notification-rules.target-types.2', value: NotificationTargetType.Contact },
  { label: 'core-portal.settings.notification-rules.target-types.3', value: NotificationTargetType.Function },
];
