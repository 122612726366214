import { TechPortalFeatureResourceTaskMissionService, TechPortalFeatureResourceTaskService } from './services';
import {
  ResourceTaskDetailStoreEffects,
  resourceTaskDetailStoreReducer,
  ResourceTaskListStoreEffects,
  resourceTaskListStoreReducer,
  ResourceTaskMissionsStoreEffects,
  resourceTaskMissionsStoreReducer,
  ResourceTaskResourcesStoreEffects,
  resourceTaskResourcesStoreReducer
} from './stores';

export * from './services';
export * from './stores';

export * from './resource-tasks.state';
export * from './resource-tasks.selector';

export const resourceTasksReducers = {
  resourceTaskList: resourceTaskListStoreReducer,
  resourceTaskDetail: resourceTaskDetailStoreReducer,
  resourceTaskResources: resourceTaskResourcesStoreReducer,
  resourceTaskMissions: resourceTaskMissionsStoreReducer
};

export const resourceTasksEffects = [
  ResourceTaskListStoreEffects,
  ResourceTaskDetailStoreEffects,
  ResourceTaskResourcesStoreEffects,
  ResourceTaskMissionsStoreEffects
];

export const resourceTasksServices = [
  TechPortalFeatureResourceTaskService,
  TechPortalFeatureResourceTaskMissionService
];
