import {AppEntityType, AppPermissions, CrossCreationConfig, CrossCreationTypes} from "@nexnox-web/core-shared";
import {
  CorePortalFeatureMasterDataCompanyEditComponent
} from "@nexnox-web/core-portal/features/master-data/features/companies";
import {CorePortalFeatureLocationEditComponent} from "@nexnox-web/core-portal/features/master-data/features/locations";
import {OrgaPortalFeatureCancellationReasonsEditComponent} from "@nexnox-web/orga-portal/features/cancellation-reasons";
import {OrgaPortalFeatureCategoryEditComponent} from "@nexnox-web/orga-portal/features/categories/src/lib/components";
import {TechPortalFeatureConnectedErrorCodeEditComponent} from "@nexnox-web/tech-portal/features/connected/error-codes";
import {
  TechPortalFeatureConnectedConsumptionCodeEditComponent
} from "@nexnox-web/tech-portal/features/connected/consumption-codes";
import {
  CorePortalFeatureMasterDataHolidaysEditComponent
} from "@nexnox-web/core-portal/features/master-data/features/holidays";
import {
  OrgaPortalFeatureKnowledgeArticleEditComponent
} from "@nexnox-web/orga-portal/features/knowledge/articles/src/lib/components";

export function provideCrossCreationConfig(): CrossCreationConfig[] {
  return [
    {
      crossCreationType: CrossCreationTypes.COMPANY,
      editComponent: CorePortalFeatureMasterDataCompanyEditComponent,
      entityType: AppEntityType.Company,
      displayKey: 'name',
      createTitle: 'core-portal.master-data.subtitles.company-create',
      createPermission: AppPermissions.CreateCompany
    },
    {
      crossCreationType: CrossCreationTypes.LOCATION,
      editComponent: CorePortalFeatureLocationEditComponent,
      entityType: AppEntityType.Location,
      displayKey: 'name',
      createTitle: 'core-portal.master-data.subtitles.location-create',
      createPermission: AppPermissions.CreateLocation
    },
    {
      crossCreationType: CrossCreationTypes.CANCELATION_REASON,
      editComponent: OrgaPortalFeatureCancellationReasonsEditComponent,
      entityType: AppEntityType.None,
      displayKey: 'title',
      createTitle: 'orga-portal.cancellation-reasons.actions.create-cancellation-reasons',
      createPermission: AppPermissions.CreateTaskJobStateReason
    },
    {
      crossCreationType: CrossCreationTypes.CATEGORY,
      editComponent: OrgaPortalFeatureCategoryEditComponent,
      entityType: AppEntityType.None,
      displayKey: 'name',
      createTitle: 'orga-portal.categories.actions.create-category',
      createPermission: AppPermissions.CreateCategory
    },
    {
      crossCreationType: CrossCreationTypes.ERROR_CODE,
      editComponent: TechPortalFeatureConnectedErrorCodeEditComponent,
      entityType: AppEntityType.None,
      displayKey: 'displayName',
      createTitle: 'tech-portal.connected.error-codes.actions.create',
      createPermission: AppPermissions.CreateErrorCode
    },
    {
      crossCreationType: CrossCreationTypes.CONSUMPTION_CODE,
      editComponent: TechPortalFeatureConnectedConsumptionCodeEditComponent,
      entityType: AppEntityType.None,
      displayKey: 'displayName',
      createTitle: 'tech-portal.connected.consumption-codes.actions.create',
      createPermission: AppPermissions.CreateConsumptionCode
    },
    {
      crossCreationType: CrossCreationTypes.HOLIDAYS,
      editComponent: CorePortalFeatureMasterDataHolidaysEditComponent,
      entityType: AppEntityType.None,
      displayKey: 'name',
      createTitle: 'core-portal.master-data.subtitles.holidays-create',
      createPermission: AppPermissions.CreateHolidaySet
    },
    {
      crossCreationType: CrossCreationTypes.KNOWLEDGE_ARTICLE,
      editComponent: OrgaPortalFeatureKnowledgeArticleEditComponent,
      entityType: AppEntityType.KnowledgeArticle,
      displayKey: 'title',
      createTitle: 'orga-portal.knowledge.actions.create-article',
      createPermission: AppPermissions.CreateKnowledgeArticle
    }
  ]
}
