import { TextTemplateListStoreEffects, textTemplateListStoreReducer } from './text-template-list/text-template-list.store';
import { TextTemplateDetailStoreEffects, textTemplateDetailStoreReducer } from './text-template-detail/text-template-detail.store';

export * from './text-template-list/text-template-list.store';
export * from './text-template-detail/text-template-detail.store';

export * from './text-templates.state';
export * from './text-templates.selectors';

export const textTemplatesReducers = {
  textTemplateList: textTemplateListStoreReducer,
  textTemplateDetail: textTemplateDetailStoreReducer
};

export const textTemplatesEffects = [
  TextTemplateListStoreEffects,
  TextTemplateDetailStoreEffects
];
