import { NgModule } from '@angular/core';
import { CoreSharedModule } from '@nexnox-web/core-shared';
import { CorePortalEntityModule } from '@nexnox-web/core-portal';
import { devicesServices } from './store';
import { OrgaPortalFeatureDeviceEditComponent } from './components';
import { DeviceDetailComponent, DeviceListComponent, DeviceMetadataListComponent } from './containers';
import { CorePortalFeatureMasterDataLocationsModule } from '@nexnox-web/core-portal/features/master-data/features/locations';
import { DevicePingListComponent } from './containers/device-ping-list/device-ping-list.component';

@NgModule({
  declarations: [
    OrgaPortalFeatureDeviceEditComponent,

    DeviceListComponent,
    DeviceDetailComponent,
    DevicePingListComponent,
    DeviceMetadataListComponent
  ],
  imports: [
    CoreSharedModule,
    CorePortalEntityModule,

    CorePortalFeatureMasterDataLocationsModule.forFeature()
  ],
  exports: [
    OrgaPortalFeatureDeviceEditComponent
  ],
  providers: [
    ...devicesServices
  ]
})
export class DevicesDeclarationsModule {
}
