import { ChangeDetectionStrategy, Component, Injector, OnInit, ViewChild } from '@angular/core';
import { ActionButton, CorePortalEntityDetailBaseComponent } from '@nexnox-web/core-portal';
import { AppPermissions, ControlPointInTagDto, ControlTagDto } from '@nexnox-web/core-shared';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ControlTagControlPointsEditComponent } from '../../components';
import { controlTagDetailStore } from '../../store';

@Component({
  selector: 'nexnox-web-ccp-control-tag-detail',
  templateUrl: './control-tag-detail.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ControlTagDetailComponent extends CorePortalEntityDetailBaseComponent<ControlTagDto> implements OnInit {

  @ViewChild('controlPointsComponent') public controlPointsComponent: ControlTagControlPointsEditComponent;
  public controlPointsSubject = new BehaviorSubject<ControlPointInTagDto[]>([]);

  public title = 'tech-portal.ccp.control-tags.subtitles.detail';
  public controlPoints$: Observable<ControlPointInTagDto[]>;

  protected idParam = 'controlTagId';
  protected displayKey = 'name';

  constructor(
    protected injector: Injector
  ) {
    super(injector, controlTagDetailStore);
  }

  public ngOnInit(): void {
    super.ngOnInit();

    this.controlPoints$ = this.model$.pipe(
      map(model => model?.points ?? [])
    );

    this.subscribe(this.controlPoints$, (controlPoints) => {
      this.controlPointsSubject.next(controlPoints)
    })

    this.subscribe(this.readonly$.asObservable(), () => {
      this.controlPointsComponent?.onReset();
    });
  }

  public onControlPointsChange(controlPoints: ControlPointInTagDto[], model: ControlTagDto): void {
    this.onModelChange({ ...model, points: controlPoints });
  }

  protected async getActionButtons(): Promise<ActionButton[]> {
    return this.getDefaultActionButtons(
      'tech-portal.ccp.control-tags.actions.edit',
      'tech-portal.ccp.control-tags.actions.save',
      'tech-portal.ccp.control-tags.actions.delete',
      'tech-portal.ccp.control-tags.descriptions.delete',
      AppPermissions.UpdateControlTag,
      AppPermissions.DeleteControlTag
    );
  }
}
