import {ChangeDetectionStrategy, Component, Injector} from '@angular/core';
import {CorePortalEntityOverviewBaseComponent} from '@nexnox-web/core-portal';
import {AppEntityType, GeneralDataProtectionRegulationLogEntryListDto, Mappers} from '@nexnox-web/core-shared';
import {gdprEntryListStore} from '../../store/stores';

@Component({
  selector: 'nexnox-web-settings-gdpr-entry-list',
  templateUrl: './gdpr-entry-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GdprEntryListComponent extends CorePortalEntityOverviewBaseComponent<GeneralDataProtectionRegulationLogEntryListDto> {
  public title = 'core-portal.settings.gdpr.titles.entry-list';
  public idProperty = 'entityId';
  public displayProperty = 'title';
  public datatableConfigName = 'SettingsGdprEntryDefault';
  public componentId = 'GdprEntryListComponent';
  public isEmbedded = true;
  public canRouteToDetail = false;

  constructor(
    protected injector: Injector
  ) {
    super(injector, gdprEntryListStore, Mappers.GeneralDataProtectionRegulationLogEntryListDto.serializedName, AppEntityType.None);
  }
}
