import {ChangeDetectionStrategy, Component, Injector, Input, OnInit} from '@angular/core';
import {CorePortalEntityOverviewBaseComponent} from '@nexnox-web/core-portal';
import {AppEntityType, ControllerOperationId, InfoProgressContactListDto, Mappers} from '@nexnox-web/core-shared';
import {infoProgressContactListStore} from '../../store';

@Component({
  selector: 'nexnox-web-infos-info-progress-contact-list',
  templateUrl: './info-progress-contact-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InfoProgressContactListComponent extends CorePortalEntityOverviewBaseComponent<InfoProgressContactListDto> implements OnInit {
  @Input() public isRemoveOfExpectedColumn: boolean;

  public title = 'orga-portal.infos.subtitles.info-progress-contacts';
  public idProperty = 'contactId';
  public displayProperty = null;
  public datatableConfigName = 'InfoProgressContactsDefault';
  public componentId = 'InfoProgressContactListComponent';
  public enableViews = true;
  public pageOperation = ControllerOperationId.InfoProgressContactControllerId;

  public isEmbedded = true;

  constructor(
    protected injector: Injector
  ) {
    super(injector, infoProgressContactListStore, Mappers.InfoProgressContactListDto.serializedName, AppEntityType.None);
  }

  public ngOnInit(): void {

    if (this.parentIds[0]) {
      this.descendantId = Number(this.parentIds[0])
    }

    super.ngOnInit();

    if (this.isRemoveOfExpectedColumn) {
      this.excludedColumns.push('isExpected');
    }
  }

}
