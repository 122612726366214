import { CorePortalDashboardService } from './services';
import { DashboardDetailStoreEffects, dashboardDetailStoreReducer, DashboardListStoreEffects, dashboardListStoreReducer } from './stores';

export * from './services';
export * from './stores';

export * from './dashboards.state';
export * from './dashboards.selectors';

export const dashboardsReducers = {
  dashboardList: dashboardListStoreReducer,
  dashboardDetail: dashboardDetailStoreReducer
};

export const dashboardsEffects = [
  DashboardListStoreEffects,
  DashboardDetailStoreEffects
];

export const dashboardsServices = [
  CorePortalDashboardService
];
