<ng-template #entityEditTemplate
             let-title="title"
             let-id="id">
  <nexnox-web-settings-gdpr-edit #modelComponent
                                 [title]="title"
                                 [id]="id"
                                 [model]="model$ | async"
                                 [readonly]="readonly$ | async"
                                 [creating]="false"
                                 [stereotyped]="false"
                                 [loading]="loading$ | async"
                                 (modelChange)="onModelChange($event)">
  </nexnox-web-settings-gdpr-edit>
</ng-template>

<nexnox-web-entity-detail [entityDetailBaseComponent]="this"
                          [entityEditTemplate]="entityEditTemplate"
                          tabId="gdpr-settings"
                          [tabHeading]="title | translate"
                          [tabCommands]="['.']">

  <!-- Log Entries Tab -->
  <ng-template nexnoxWebTabTemplate
               tabId="log-entries"
               *ngIf="model$ | async"
               [heading]="'core-portal.settings.gdpr.titles.entry-list' | translate"
               [lazy]="true"
               (selectTab)="onNavigateToTab(['log-entries'])">
    <nexnox-web-settings-gdpr-entry-list *ngIf="passedIds"
                                         [parentIds]="passedIds">
    </nexnox-web-settings-gdpr-entry-list>
  </ng-template>
</nexnox-web-entity-detail>
