<ng-container *ngIf="menu$ | async as menu">

  <!-- Menu toggle on mobile -->
  <div *ngIf="isMobile$ | async" class="px-3 mt-3 cursor-pointer" (click)="toggleMenu()">
    <fa-icon [class]="'mr-3'"
             [icon]="faBars"
             [size]="'sm'">
    </fa-icon>
    <span>Menu</span>
  </div>

  <!-- Menu content -->
  <div class="menu px-3" [@expandedCollapsed]="!isHideMenu ? 'expanded' : 'collapsed'">

    <!-- Filter -->
    <div class="sticky-input-wrapper d-sm-block d-none">
      <div class="p-input-icon-left w-100 my-3">
        <i class="pi pi-search"></i>
        <input class="w-100" type="text" pInputText [(ngModel)]="filter" (ngModelChange)="onFilterMenu(filter)"/>
      </div>
    </div>

    <!-- Items -->
    <ng-container *ngFor="let chapter of menu.chapters">

      <div *ngIf="chapter.hasAccess && !chapter.isHidden"
           class="chapter-title"
           (click)="toggleChapterCategories(chapter)">
        {{ chapter.title | translate }}
      </div>

      <ng-container *ngFor="let category of chapter.categories">
        <div *ngIf="category.hasAccess && !category.isHidden" class="mb-3">
          <div class="category-title d-flex justify-space-between"
               (click)="toggleCategory(category, menu)">
            <div>
              {{ category.title | translate }}
            </div>
            <div>
              <fa-icon [icon]="category.isExpanded ? faChevronUp : faChevronDown"></fa-icon>
            </div>
          </div>

          <div [@expandedCollapsed]="category.isExpanded ? 'expanded' : 'collapsed'">
            <ng-container *ngFor="let item of category.items">
              <div *ngIf="item.hasAccess && !item.isHidden"
                   class="item"
                   [class.collapsed]="!category.isExpanded"
                   (click)="this.hideMenu()">
                <a class="item-link"
                   [nexnoxWebTenantRouterLinkActive]="'active'"
                   [nexnoxWebTenantRouterLink]="item.link"
                   module="{{module}}">
                  {{ item.title  | translate }}
                </a>
              </div>
            </ng-container>
          </div>
        </div>
      </ng-container>

    </ng-container>
  </div>
</ng-container>
