import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {select, Store} from '@ngrx/store';
import {authStore, generalTenantSettingsStore} from '../../../../store';
import {map} from 'rxjs/operators';
import {TenantInfoDto} from '@nexnox-web/core-shared';
import {CorePortalFeatureModule, SidebarItem} from "@nexnox-web/libs/core-portal/src";
import {SidebarModule} from '../../models';

@Injectable()
export class CorePortalSidebarService {
  public isOpen$: Observable<boolean>;
  public isModuleMenuOpen$: Observable<boolean>;

  private isOpenSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  constructor(
    private store: Store<any>
  ) {
    this.isOpen$ = this.isOpenSubject.asObservable();

    const matchesLG = window.matchMedia('(max-width: 991px)');
    if (matchesLG.matches) {
      this.isOpenSubject.next(false);
    }
  }

  public setOpen(open: boolean): void {
    this.isOpenSubject.next(open);
  }

  public toggle(): void {
    this.setOpen(!this.isOpenSubject.getValue());
  }

  public getLogoPath$(): Observable<string> {
    return this.store.pipe(
      select(generalTenantSettingsStore.selectors.selectEntity),
      map(settings => settings?.logo?.file?.uri ?? null)
    );
  }

  public getSelectedTenant$(): Observable<TenantInfoDto> {
    return this.store.pipe(select(authStore.selectors.selectActiveTenant));
  }

  public mapSidebarContent(sidebarItems: SidebarItem[], modules: CorePortalFeatureModule[]): SidebarModule[] {
    const sidebarModules: SidebarModule[] = [];

    for (const module of modules.map(module => ({...module, items: []})) as SidebarModule[]) {
      module.items = sidebarItems.filter(item => item.module === module.path);
      module.maxHeight = this.getModuleMaxHeight(module);
      sidebarModules.push(module)
    }

    return sidebarModules;
  }

  public getModuleMaxHeight(module: SidebarModule): number {
    return module.items.map(item => (item?.children?.length ?? 0) * 50).reduce((acc, current) => acc + current, 0) + (module.items.length * 50);
  }

  public getModuleProcessHeight(module: SidebarModule): number {
    return module.items.filter(item => item.expanded).map(item => (item?.children?.length ?? 0) * 50).reduce((acc, current) => acc + current, 0) + (module.items.length * 50);
  }
}
