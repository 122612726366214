import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import {
  CorePortalCCPControlPointService
} from "@nexnox-web/core-portal";
import { ControlPointInTagDto, ControlPointListDto, CoreSharedSortableListComponent, CoreSharedSortableListItem, UnsubscribeHelper } from "@nexnox-web/core-shared";
import { FormlyFieldConfig } from "@ngx-formly/core";
import { BindObservable } from "bind-observable";
import { cloneDeep, find, maxBy } from "lodash";
import { BehaviorSubject, Observable } from "rxjs";
import { map } from "rxjs/operators";

@Component({
  selector: 'nexnox-web-ccp-control-tag-control-points-edit',
  templateUrl: 'control-tag-control-points-edit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ControlTagControlPointsEditComponent extends UnsubscribeHelper implements OnInit {
  @Input() public controlPointsSubject: BehaviorSubject<ControlPointInTagDto[]>;
  @Input() public loading: boolean;

  @Input() @BindObservable() public readonly: boolean;
  public readonly$!: Observable<boolean>;

  @ViewChild('controlPointListEdit') public controlPointListEdit: CoreSharedSortableListComponent;

  @Output() public controlPointsChange = new EventEmitter<any>();

  public controlPointItems$: Observable<CoreSharedSortableListItem[]>;

  public addControlPointForm: FormGroup;
  public addControlPointFields: FormlyFieldConfig[];
  public addControlPointModelSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  public faPlus = faPlus;

  constructor(
    private controlPointService: CorePortalCCPControlPointService
  ) {
    super();
  }

  public ngOnInit(): void {
    this.controlPointItems$ = this.controlPointsSubject.asObservable().pipe(
      map(controlPoints => this.mapControlPointsToSortableItems(controlPoints))
    );

    this.addControlPointForm = new FormGroup({});
    this.addControlPointFields = this.createControlPointFields();
  }

  public onAddControlPoint(): void {
    const controlPointToAdd: ControlPointListDto = cloneDeep(this.addControlPointModelSubject.getValue()?.controlPoint);
    const lastControlPoint = maxBy(this.controlPointsSubject.getValue(), 'position');
    const position: number = lastControlPoint ? lastControlPoint.position + 1 : 0;

    if (controlPointToAdd) {
      const newControlPointInTag: ControlPointInTagDto = {
        controlPointId: controlPointToAdd.controlPointId,
        name: controlPointToAdd.name,
        position
      }
      this.controlPointsSubject.next([...this.controlPointsSubject.getValue(), newControlPointInTag]);

      this.addControlPointModelSubject.next({} as any);
      this.addControlPointForm.reset();
      this.addControlPointForm.markAsUntouched();

      this.controlPointsChange.emit(this.controlPointsSubject.getValue());
    }
  }

  public onReset(): void {
    this.controlPointListEdit.ngOnInit();
  }

  public onControlPointsChange(controlPoints: CoreSharedSortableListItem[]): void {
    this.controlPointsSubject.next(controlPoints.map(controlPoint => ({
      ...controlPoint.getExternalData(),
      position: controlPoint.position
    })));
    this.controlPointsChange.emit(this.controlPointsSubject.getValue());
  }

  public createControlPointFields(): any {

    /* istanbul ignore next */
    return [
      {
        key: 'controlPoint',
        type: 'core-portal-entity-select',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-12',
        defaultValue: null,
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.control-point',
            validationMessages: {
              required: 'core-portal.core.validation.required',
              duplicate: 'core-portal.core.validation.duplicate'
            }
          },
          entityService: this.controlPointService,
          idKey: 'controlPointId',
          displayKey: 'name',
          skipGetOne: true,
          wholeObject: true,
          clearable: false
        },
        expressionProperties: {
          'templateOptions.required': () => !this.readonly,
          'templateOptions.readonly': () => this.readonly
        },
        validators: {
          duplicate: control => !find(this.controlPointsSubject.getValue(), { controlPointId: control?.value?.controlPointId })
        }
      }
    ]
  }

  /* istanbul ignore next */
  private mapControlPointsToSortableItems(controlPoints: ControlPointInTagDto[]): CoreSharedSortableListItem[] {
    return controlPoints.map((controlPoint) => ({
      title: controlPoint.name,
      position: controlPoint.position,
      getExternalData: () => cloneDeep(controlPoint)
    }));
  }


}
