import { Directive, DoCheck, ElementRef, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';

export class ResizedEvent {
  constructor(
    public readonly element: ElementRef,
    public readonly newWidth: number,
    public readonly newHeight: number,
    public readonly oldWidth: number,
    public readonly oldHeight: number
  ) {
  }
}

/**
 * @deprecated Has very poor performance
 */
@Directive({
  selector: '[nexnoxWebResized]'
})
export class CoreSharedResizedDirective implements OnInit, DoCheck, OnDestroy {
  @Output() public readonly nexnoxWebResized: EventEmitter<ResizedEvent> = new EventEmitter<ResizedEvent>();

  private oldWith: number;
  private oldHeight: number;

  private resizeObserver: ResizeObserver;

  constructor(
    private readonly element: ElementRef<HTMLElement>
  ) {
  }

  public ngOnInit(): void {
    if (ResizeObserver && this.isVisible()) {
      this.createResizeObserver();
    }
  }

  public ngDoCheck(): void {
    if (this.isVisible() && ResizeObserver && !this.resizeObserver) {
      this.createResizeObserver();
    }
  }

  public ngOnDestroy(): void {
    this.resizeObserver?.unobserve(this.element.nativeElement);
    this.resizeObserver?.disconnect();
    this.resizeObserver = null;
  }

  public onResized(): void {
    const newWidth = this.element.nativeElement.clientWidth;
    const newHeight = this.element.nativeElement.clientHeight;

    if (newWidth === this.oldWith && newHeight === this.oldHeight) {
      return;
    }

    const event = new ResizedEvent(this.element, newWidth, newHeight, this.oldWith, this.oldHeight);
    this.oldWith = this.element.nativeElement.clientWidth;
    this.oldHeight = this.element.nativeElement.clientHeight;
    this.nexnoxWebResized.emit(event);
  }

  private createResizeObserver(): void {
    this.resizeObserver = new ResizeObserver(() => this.onResized());
    this.resizeObserver.observe(this.element.nativeElement);
    this.onResized();
  }

  private isVisible(): boolean {
    if (!this.element.nativeElement.getBoundingClientRect) {
      return Boolean(this.element.nativeElement.offsetWidth) && Boolean(this.element.nativeElement.offsetHeight);
    }

    const rect = this.element.nativeElement.getBoundingClientRect();
    return Boolean(Math.round(rect.width)) && Boolean(Math.round(rect.height));
  }
}
