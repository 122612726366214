import { PagedEntitiesXsStore, PagedEntitiesXsStoreState } from '@nexnox-web/core-store';
import { AppEntityType, EscalationLevelDto } from '@nexnox-web/core-shared';
import { Action, createSelector } from '@ngrx/store';
import { selectEscalationLevelsState } from '../../escalation-levels.selectors';
import { TechPortalFeatureTicketSettingsEscalationLevelService } from '../../services';
import { Injectable, Injector } from '@angular/core';

export interface EscalationLevelListStoreState extends PagedEntitiesXsStoreState<EscalationLevelDto> {
}

/* istanbul ignore next */
export const escalationLevelListStore = new PagedEntitiesXsStore<EscalationLevelDto>({
  actionLabel: 'Tech Portal - Ticket Settings - Escalation Levels - Escalation Level List',
  stateSelector: createSelector(selectEscalationLevelsState, state => state.escalationLevelList),
  serviceType: TechPortalFeatureTicketSettingsEscalationLevelService,
  entityType: AppEntityType.None,
  selectId: escalationLevel => escalationLevel.entity?.escalationLevelId ?? escalationLevel.model?.escalationLevelId
});

export function escalationLevelListStoreReducer(state: EscalationLevelListStoreState, action: Action): any {
  return escalationLevelListStore.reducer(state, action);
}

@Injectable()
export class EscalationLevelListStoreEffects extends escalationLevelListStore.effects {
  constructor(
    protected injector: Injector
  ) {
    super(injector);
  }
}
