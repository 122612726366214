import { ChangeDetectionStrategy, Component, Injector, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActionButton, CorePortalEntityOverviewBaseComponent, DatatableActionButton, DeleteEntityModel } from 'libs/core-portal/src';
import {
  AppEntityType,
  AppPermissions,
  ControllerOperationId,
  KnowledgeArticleDto, KnowledgePathListDto,
  Mappers
} from 'libs/core-shared/src';
import { pathsListStore } from '../../store';

@Component({
  selector: 'nexnox-web-knowledge-knowledge-article-list',
  templateUrl: './paths-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PathsListComponent extends CorePortalEntityOverviewBaseComponent<KnowledgePathListDto> implements OnInit {
  @ViewChild('categoryLabelTitleTemplate', { static: true }) public categoryLabelTitleTemplate: TemplateRef<any>;
  @ViewChild('categoryOptionTitleTemplate', { static: true }) public categoryOptionTitleTemplate: TemplateRef<any>;

  public title = 'orga-portal.knowledge.subtitles.path-list';
  public createTitle = 'orga-portal.knowledge.actions.create-path';
  public idProperty = 'knowledgePathId';
  public displayProperty = 'title';
  public datatableConfigName = 'KnowledgePathDefault';
  public componentId = 'PathsListComponent';

  // public pageOperation = ControllerOperationId.KnowledgeArticleControllerList
  public enableViews = false;

  constructor(
    protected injector: Injector
  ) {
    super(injector, pathsListStore, Mappers.KnowledgePathListDto.serializedName, AppEntityType.KnowledgePath);
  }

  public ngOnInit(): void {
    this.templates = {
      categoryLabelTitleTemplate: this.categoryLabelTitleTemplate,
      categoryOptionTitleTemplate: this.categoryOptionTitleTemplate
    };

    super.ngOnInit();
  }

  public getRowActionButtons(): DatatableActionButton[] {
    return this.getDefaultRowActionButtons(
      'orga-portal.knowledge.actions.edit-path',
      (row: KnowledgePathListDto) => `/knowledge/paths/${row.knowledgePathId}`,
      [AppPermissions.UpdateKnowledgePath],
      {
        module: 'communication'
      }
    );
  }

  public getDeleteEntityModel(): DeleteEntityModel {
    return {
      titleKey: 'orga-portal.knowledge.actions.delete-path',
      descriptionKey: 'orga-portal.knowledge.descriptions.delete-path',
      deletePermission: AppPermissions.DeleteKnowledgePath
    };
  }

  protected async getActionButtons(): Promise<ActionButton[]> {
    return this.getDefaultActionButtons('orga-portal.knowledge.actions.create-path', AppPermissions.CreateKnowledgePath);
  }
}
