import { AppEntityType, LocationDto } from '@nexnox-web/core-shared';
import { Action, createSelector } from '@ngrx/store';
import { Injectable, Injector } from '@angular/core';
import { selectContactsState } from '../../contacts.selectors';
import { PagedEntitiesXsStore, PagedEntitiesXsStoreState } from '@nexnox-web/core-store';
import { ContactLocationService } from '../../services';

export interface ContactLocationListStoreState extends PagedEntitiesXsStoreState<LocationDto> {
}

/* istanbul ignore next */
export const contactLocationListStore = new PagedEntitiesXsStore<LocationDto>({
  actionLabel: 'Core Portal - Master Data - Contact - Location List',
  stateSelector: createSelector(selectContactsState, state => state.contactLocationList),
  serviceType: ContactLocationService,
  entityType: AppEntityType.Location,
  selectId: location => location.entity?.locationId ?? location.model?.locationId
});

export function contactLocationListStoreReducer(state: ContactLocationListStoreState, action: Action): any {
  return contactLocationListStore.reducer(state, action);
}

@Injectable()
export class ContactLocationListStoreEffects extends contactLocationListStore.effects {
  constructor(
    protected injector: Injector
  ) {
    super(injector);
  }
}
