import { InjectionToken, NgModule } from '@angular/core';
import { ActionReducerMap, StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { contactsEffects, contactsReducers } from './store';

export const REDUCER_TOKEN = new InjectionToken<ActionReducerMap<any>>(
  '@nexnox-web/core-portal/master-data/contacts: Store Reducers'
);

@NgModule({
  imports: [
    StoreModule.forFeature('core-portal-feature-master-data-contacts', REDUCER_TOKEN),
    EffectsModule.forFeature(contactsEffects)
  ],
  providers: [
    {
      provide: REDUCER_TOKEN,
      useValue: contactsReducers
    }
  ]
})
export class ContactsStoreModule {
}
