import {ChangeDetectionStrategy, Component, Injector} from '@angular/core';
import {CorePortalEntityOverviewBaseComponent, DatatableActionButton} from '@nexnox-web/core-portal';
import {AppEntityType, LocationGroupListDto, Mappers} from '@nexnox-web/core-shared';
import {locationLocationGroupListStore} from '../../store';

@Component({
  selector: 'nexnox-web-master-data-locations-locationgroup-list',
  templateUrl: './location-locationgroup-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LocationLocationGroupListComponent extends CorePortalEntityOverviewBaseComponent<LocationGroupListDto> {
  public title = 'core-portal.master-data.subtitles.location-group-list';
  public idProperty = 'locationGroupId';
  public displayProperty = 'displayName';
  public datatableConfigName = 'MasterDataLocationGroupDefault';
  public componentId = 'LocationLocationGroupListComponent';

  constructor(
    protected injector: Injector
  ) {
    super(injector, locationLocationGroupListStore, Mappers.LocationGroupListDto.serializedName, AppEntityType.LocationGroup);
  }

  /* istanbul ignore next */
  public getRowActionButtons(): DatatableActionButton[] {
    return this.getEmbeddedRowActionButtons();
  }
}
