<nexnox-web-entity-edit *ngIf="!creating"
                        [entityEditBaseComponent]="this"
                        displayKey="title">
  <div *ngIf="model && !creating"
       class="d-flex w-100 justify-content-end text-muted p-2">
    <ng-container *ngIf="model.lastModified">
      <span [title]="model.lastModified | nexnoxWebBsDate:'L LT' | async">
        {{ 'core-shared.shared.time-phrases.modified' | translate }} {{ model.lastModified | nexnoxWebDayjsRelative | async }}
      </span>
      <ng-container *ngIf="model.lastEmailSend">,&nbsp;</ng-container>
    </ng-container>
    <span *ngIf="model.lastEmailSend" [title]="model.lastEmailSend | nexnoxWebBsDate:'L LT' | async">
      {{ 'missions.descriptions.last-mail-sent' | translate }} {{ model.lastEmailSend | nexnoxWebDayjsRelative | async }}
    </span>
  </div>
</nexnox-web-entity-edit>

<nexnox-web-tabs *ngIf="creating"
                 [internal]="true"
                 [isTransparent]="true"
                 startTab="entityEdit">
  <!-- Entity Edit Tab -->
  <ng-template nexnoxWebTabTemplate
               tabId="entityEdit"
               [heading]="title | translate"
               [hasError]="!isOwnModelValid()">
    <nexnox-web-entity-edit [entityEditBaseComponent]="this"></nexnox-web-entity-edit>
  </ng-template>

  <!-- Links -->
  <ng-template nexnoxWebTabTemplate
               tabId="links"
               [heading]="('tech-portal.subtitles.links' | translate) + (linksComponent ? (linksComponent.linksString$ | async) : '')"
               [hasError]="linksComponent ? !linksComponent.isModelValid() : false">
    <nexnox-web-tech-links #linksComponent
                           [links$]="linksSubject"
                           [loading]="loading"
                           [readonly]="readonly"
                           (linksChange)="onLinksChange($event)">
    </nexnox-web-tech-links>
  </ng-template>

  <!-- Attachments -->
  <ng-template nexnoxWebTabTemplate
               tabId="attachments"
               *ngIf="readSolutionPermission$ | async"
               [heading]="('tech-portal.subtitles.attachments' | translate) + (attachmentsComponent ? (attachmentsComponent.attachmentsString$ | async) : '')"
               [hasError]="attachmentsComponent ? !attachmentsComponent.isModelValid() : false">
    <nexnox-web-tech-attachments #attachmentsComponent
                                 [attachments$]="attachmentsSubject"
                                 [loading]="loading"
                                 [readonly]="readonly"
                                 [canAddExisting]="true"
                                 (attachmentsChange)="onAttachmentsChange($event)">
    </nexnox-web-tech-attachments>
  </ng-template>
</nexnox-web-tabs>

<!-- Resource Templates -->
<ng-template #resourceDescriptionTemplate let-item="item">
  <nexnox-web-resources-resource-description [resource]="item">
  </nexnox-web-resources-resource-description>
</ng-template>

<!-- Text Template Apply Sidebar -->
<nexnox-web-templates-text-template-apply-sidebar #textTemplateApplySidebar
                                                  [referenceModel]="model"
                                                  [contextType]="templateContextType$ | async"
                                                  (applied)="onTextTemplateApplied($event)">
</nexnox-web-templates-text-template-apply-sidebar>

<!-- Assign Contact Sidebar -->
<nexnox-web-mission-assign-contact-sidebar #assignContactSidebar
                                           [currentSolutionContact]="model?.solutionContact"
                                           (unassignContact)="unassignContact.emit()"
                                           (assignContact)="assignContact.emit($event)">
</nexnox-web-mission-assign-contact-sidebar>

<!-- Assign Resource Sidebar -->
<ng-container *ngIf="isShowResourceAssignmentSidebar">
  <nexnox-web-mission-assign-resource-sidebar #assignResourceSidebar
                                              [create]="false"
                                              [location]="model?.resource?.location"
                                              (assignResource)="assignResource.emit($event)">
  </nexnox-web-mission-assign-resource-sidebar>
</ng-container>
