import {
  EntityXsStoreCreatePayload, EntityXsStoreCreateSuccessPayload,
  EntityXsStoreDeletePayload, EntityXsStoreEntityUpdatePayload,
  EntityXsStoreGetPayload,
  EntityXsStoreGetStereotypesPayload,
  EntityXsStoreGetStereotypesSuccessPayload,
  EntityXsStoreGetSuccessPayload,
  EntityXsStoreModelUpdatePayload,
  EntityXsStoreSavePayload,
  EntityXsStoreSaveSuccessPayload
} from './entity-xs-store.payloads';
import { createAction, props } from '@ngrx/store';
import { EmptyAction, PropsAction } from '../../types';
import { BaseXsStore, BaseXsStoreActions, createBaseXsStoreActions } from '../base';

export interface EntityXsStoreActions<E, M = E> extends BaseXsStoreActions {
  get: PropsAction<EntityXsStoreGetPayload>;
  getSuccess: PropsAction<EntityXsStoreGetSuccessPayload<E, M>>;

  getStereotypes: PropsAction<EntityXsStoreGetStereotypesPayload>;
  getStereotypesSuccess: PropsAction<EntityXsStoreGetStereotypesSuccessPayload>;

  entityUpdate: PropsAction<EntityXsStoreEntityUpdatePayload<E>>;
  modelUpdate: PropsAction<EntityXsStoreModelUpdatePayload<M>>;
  update: EmptyAction;
  modelReset: EmptyAction;
  modelResetSuccess: PropsAction<EntityXsStoreModelUpdatePayload<M>>;

  save: PropsAction<EntityXsStoreSavePayload>;
  saveSuccess: PropsAction<EntityXsStoreSaveSuccessPayload<E, M>>;

  delete: PropsAction<EntityXsStoreDeletePayload>;
  deleteSuccess: PropsAction<EntityXsStoreDeletePayload>;

  clear: EmptyAction;

  createOneNative: PropsAction<EntityXsStoreCreatePayload>;
  createOneNativeSuccess: PropsAction<EntityXsStoreCreateSuccessPayload<E, M>>;
}

export const createEntityXsStoreActions = <E, M>(label: string): EntityXsStoreActions<E, M> => ({
  ...createBaseXsStoreActions(label),

  get: createAction(
    BaseXsStore.getType(label, 'Get'),
    props<EntityXsStoreGetPayload>()
  ),
  getSuccess: createAction(
    BaseXsStore.getType(label, 'Get success'),
    props<EntityXsStoreGetSuccessPayload<E, M>>()
  ),

  getStereotypes: createAction(
    BaseXsStore.getType(label, 'Get stereotypes'),
    props<EntityXsStoreGetStereotypesPayload>()
  ),
  getStereotypesSuccess: createAction(
    BaseXsStore.getType(label, 'Get stereotype success'),
    props<EntityXsStoreGetStereotypesSuccessPayload>()
  ),

  entityUpdate: createAction(
    BaseXsStore.getType(label, 'Entity update'),
    props<EntityXsStoreEntityUpdatePayload<E>>()
  ),
  modelUpdate: createAction(
    BaseXsStore.getType(label, 'Model update'),
    props<EntityXsStoreModelUpdatePayload<M>>()
  ),
  update: createAction(BaseXsStore.getType(label, 'Update')),
  modelReset: createAction(BaseXsStore.getType(label, 'Model reset')),
  modelResetSuccess: createAction(
    BaseXsStore.getType(label, 'Model reset success'),
    props<EntityXsStoreModelUpdatePayload<M>>()
  ),

  save: createAction(
    BaseXsStore.getType(label, 'Save'),
    props<EntityXsStoreSavePayload>()
  ),
  saveSuccess: createAction(
    BaseXsStore.getType(label, 'Save success'),
    props<EntityXsStoreSaveSuccessPayload<E, M>>()
  ),

  delete: createAction(
    BaseXsStore.getType(label, 'Delete'),
    props<EntityXsStoreDeletePayload>()
  ),
  deleteSuccess: createAction(
    BaseXsStore.getType(label, 'Delete success'),
    props<EntityXsStoreDeletePayload>()
  ),

  clear: createAction(BaseXsStore.getType(label, 'Clear')),

  createOneNative: createAction(
    BaseXsStore.getType(label, 'Create Native'),
    props<EntityXsStoreCreatePayload>()
  ),
  createOneNativeSuccess: createAction(
    BaseXsStore.getType(label, 'Create Native success'),
    props<EntityXsStoreCreateSuccessPayload<E, M>>()
  ),
});
