import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {IconDefinition} from '@fortawesome/fontawesome-common-types';
import {faFile} from '@fortawesome/free-solid-svg-icons/faFile';
import {faFileImage} from '@fortawesome/free-solid-svg-icons/faFileImage';
import {faFilePdf} from '@fortawesome/free-solid-svg-icons/faFilePdf';
import {faSpinner} from '@fortawesome/free-solid-svg-icons/faSpinner';
import {
  ApiNotificationService,
  CoreSharedSortableListItem,
  FileAttachmentForTechDto,
  FileDto
} from '@nexnox-web/core-shared';
import {BehaviorSubject, Observable} from 'rxjs';

@Component({
  selector: 'nexnox-web-tech-attachments-download',
  templateUrl: './download.component.html',
  styleUrls: ['./download.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CorePortalAttachmentsDownloadComponent {
  @Input() public item: CoreSharedSortableListItem & FileAttachmentForTechDto;

  public attachmentsString$: Observable<string>;

  public isDownloadingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public faSpinner = faSpinner;

  constructor(
    private apiNotificationService: ApiNotificationService
  ) {
  }

  public getFileImage(file: FileDto): IconDefinition {
    if (file.mimeType.includes('image')) {
      return faFileImage;
    } else if (file.mimeType.includes('pdf')) {
      return faFilePdf;
    }

    return faFile;
  }

  public isImage(file: FileDto): boolean {
    return file.mimeType.includes('image');
  }

  public downloadFile(file: FileDto): void {
    // Show spinner
    this.isDownloadingSubject.next(true);

    // Fetch the file from the URL
    fetch(file.uri)
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.blob(); // Get the file as a Blob
      })
      .then(blob => {
        // Create a temporary URL for the Blob
        const blobUrl = window.URL.createObjectURL(blob);

        // Create an anchor element
        const a = document.createElement('a');
        a.href = blobUrl;
        a.download = file.name; // Set the custom file name

        // Append the anchor to the body (required for Firefox)
        document.body.appendChild(a);

        // Programmatically click the anchor to trigger the download
        a.click();

        // Clean up
        document.body.removeChild(a);
        window.URL.revokeObjectURL(blobUrl);
        this.isDownloadingSubject.next(false);
      })
      .catch(error => this._onError(error));
  }

  private _onError(error: any): void {
    this.apiNotificationService.handleApiError(error);
    this.isDownloadingSubject.next(false);
  }
}
