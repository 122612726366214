import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HierarchicalTreeViewComponent, HierarchicalTreeViewItemComponent} from './components';
import {CollapseModule} from 'ngx-bootstrap/collapse';
import {NgxSkeletonLoaderModule} from 'ngx-skeleton-loader';
import {GhostHierarchicalTreeViewChildrenComponent, GhostHierarchicalTreeViewComponent} from './ghosts';
import {TranslateModule} from '@ngx-translate/core';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {RouterLink, RouterLinkActive} from "@angular/router";
import {CorePortalRouterOverridesModule} from "../../../router-overrides";

const exportedComponents = [
  HierarchicalTreeViewComponent,
  HierarchicalTreeViewItemComponent
];

const exportedGhosts = [
  GhostHierarchicalTreeViewComponent,
  GhostHierarchicalTreeViewChildrenComponent
];

@NgModule({
  declarations: [
    ...exportedComponents,
    ...exportedGhosts
  ],
  imports: [
    CommonModule,
    CollapseModule,
    NgxSkeletonLoaderModule,
    TranslateModule,
    FontAwesomeModule,
    CorePortalRouterOverridesModule,
    RouterLink
  ],
  exports: [
    ...exportedComponents,
    ...exportedGhosts
  ]
})
export class CorePortalHierarchicalTreeViewModule {
}
