import { EntityXsStore, EntityXsStoreState } from '@nexnox-web/core-store';
import { AppEntityType, ConsumptionCodeDto } from '@nexnox-web/core-shared';
import { createSelector } from '@ngrx/store';
import { TechPortalFeatureConnectedConsumptionCodeService } from '../../services';
import { selectConsumptionCodesState } from '../../consumption-codes.selectors';

export interface ConsumptionCodeDetailStoreState extends EntityXsStoreState<ConsumptionCodeDto> {
}

/* istanbul ignore next */
export const consumptionCodeDetailStore = new EntityXsStore<ConsumptionCodeDto>({
  actionLabel: 'Tech Portal - Connected - Consumption Codes - Consumption Code Detail',
  stateSelector: createSelector(selectConsumptionCodesState, state => state.consumptionCodeDetail),
  serviceType: TechPortalFeatureConnectedConsumptionCodeService,
  entityType: AppEntityType.None,
  stereotyped: false
});
