import {PagedEntitiesXsStoreState} from '@nexnox-web/core-store';
import {AppEntityType, MessageIssueListDto} from '@nexnox-web/core-shared';
import {createSelector} from '@ngrx/store';
import {selectIssuesState} from '../../issues.selectors';
import {MessageIssueListXsStore} from './message-issue-list.xs-store';
import {MessageIssueService} from "../../services";

export interface MessageIssueListStoreState extends PagedEntitiesXsStoreState<MessageIssueListDto> {
}

/* istanbul ignore next */
export const messageIssueListStore = new MessageIssueListXsStore({
  actionLabel: 'Tech Portal - Issues - Message Issue List',
  stateSelector: createSelector(selectIssuesState, state => state.messageIssueList),
  serviceType: MessageIssueService,
  entityType: AppEntityType.Issue,
  selectId: issue => issue.entity?.issueId ?? issue.model?.issueId
});
