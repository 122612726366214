import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {CORE_PORTAL_FEATURE_MODULES, CorePortalFeatureModule} from '../../model';
import {combineLatest, Observable, of} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';
import {CorePortalPermissionService} from '../../services';

@Component({
  selector: 'nexnox-web-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CorePortalWelcomeComponent implements OnInit {
  public modules$: Observable<CorePortalFeatureModule[]>;

  constructor(
    private permissionService: CorePortalPermissionService
  ) {
  }

  public ngOnInit(): void {
    this.modules$ = combineLatest(CORE_PORTAL_FEATURE_MODULES.map(module => of(module).pipe(
      switchMap(module => (module.permissions ? this.permissionService.hasPermissions$(module.permissions) : of(true)).pipe(
        map(hasPermission => ({ module, hasPermission }))
      )),
      map(({ module, hasPermission }) => ({
        ...module,
        path: `/${module.path}`,
        hide: !hasPermission
      }))
    ))).pipe(
      map(modules => modules.filter(x => !x.hide))
    );
  }

  public onSelectModule(module: CorePortalFeatureModule): void {
    if (module.target) {
      window.open(module.path, module.target);
    }
  }
}


