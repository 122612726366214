import {ChangeDetectionStrategy, Component, EventEmitter, Injector, Input, OnInit, Output} from '@angular/core';
import {CorePortalEntityEditBaseComponent} from "@nexnox-web/libs/core-portal/src/lib/modules/entity";
import {
  AppEntityType,
  Filter,
  FilterOperators,
  FilterTypes,
  ResourceDto,
  ResourcesByLocationDefinitionDto,
  ResourceSkeletonDto,
  StereotypeDto
} from '@nexnox-web/core-shared';
import {FormlyFieldConfig} from "@ngx-formly/core";

import {cloneDeep, isEqual} from "lodash";
import {faPlus} from '@fortawesome/free-solid-svg-icons/faPlus';
import {
  CorePortalFormlyReadonlyTypes,
  CorePortalFormlyReadonlyTyping,
  CorePortalStereotypeService
} from "@nexnox-web/core-portal";
import {Observable, of} from "rxjs";
import {distinctUntilChanged, map} from "rxjs/operators";
import {
  CorePortalFeatureResourceInheritableService
} from "@nexnox-web/core-portal/features/resources/src/lib/services/resource-inheritable/resource-inheritable.service";

@Component({
  selector: 'nexnox-web-settings-stereotypes-resource-by-location-definition-edit',
  templateUrl: './resources-by-location-stereotype-edit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ResourcesByLocationStereotypeEditComponent extends CorePortalEntityEditBaseComponent<ResourcesByLocationDefinitionDto> implements OnInit {

  @Input() public stereotypeId: number;
  @Output() public formTableValidityChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  public faPlus = faPlus;
  public skeletons$: Observable<ResourceSkeletonDto[]>

  constructor(
    protected injector: Injector,
    public stereotypeService: CorePortalStereotypeService,
    public resourceInheritableService: CorePortalFeatureResourceInheritableService
  ) {
    super(injector, 'ResourcesByLocationStereotypeEditComponent');
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.skeletons$ = this.modelSubject.asObservable().pipe(
      distinctUntilChanged((prev, curr) => isEqual(prev, curr)),
      map(model => model?.resources)
    );
  }

  /* istanbul ignore next */
  public onModelChange(model: ResourcesByLocationDefinitionDto): void {
    const newModel = {...this.model, ...cloneDeep(model)};
    this.modelSubject.next(newModel);
    this.modelChange.emit(this.model);
  }

  public onSkeletonsChange(skeletons: ResourceSkeletonDto[]): void {
    const newModel = {...this.model, resources: cloneDeep(skeletons)};
    this.modelSubject.next(newModel);
    this.modelChange.emit(this.model);
  }

  /* istanbul ignore next */
  public createForm(): FormlyFieldConfig[] {
    return [
      {key: 'stereotypeId', defaultValue: this.stereotypeId},
      {key: 'resources', defaultValue: []},
      {
        key: 'title',
        type: 'input',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-4',
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.title',
            validationMessages: {
              required: 'core-portal.core.validation.required',
            },
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.BASIC
          },
          required: true,
          type: 'text'
        },
      },
    ];
  }

  /* istanbul ignore next */
  public createSkeletonFields(): FormlyFieldConfig[] {
    return [
      {key: 'resourceSkeletonId', defaultValue: 0},
      {
        key: 'name',
        type: 'input',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-4',
        templateOptions: {
          required: true,
          corePortalTranslated: {
            label: 'core-shared.shared.fields.name',
            validationMessages: {
              required: 'core-portal.core.validation.required',
            },
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.BASIC
          },
          type: 'text'
        },
      },
      {
        key: 'stereotype',
        type: 'core-portal-entity-select',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-4',
        defaultValue: null,
        templateOptions: {
          required: true,
          corePortalTranslated: {
            label: 'core-shared.shared.fields.stereotype',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.ENTITY,
            displayKey: 'name',
            link: (stereotype: StereotypeDto) => stereotype?.stereotypeId ? ['stereotypes', stereotype.stereotypeId] : null,
            module: 'settings'
          } as CorePortalFormlyReadonlyTyping,
          entityService: this.stereotypeService,
          idKey: 'stereotypeId',
          displayKey: 'name',
          wholeObject: true,
          skipGetOne: true,
          clearable: true,
          defaultFilters$: of([{
            property: 'entityType',
            type: FilterTypes.DataTransferObject,
            operator: FilterOperators.Equal,
            value: AppEntityType.Resource.toString()
          }] as Filter[]),
          module: 'settings'
        }
      },
      {
        key: 'parent',
        type: 'core-portal-entity-select',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-4',
        defaultValue: null,
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.inherits-from'
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.ENTITY,
            displayKey: 'name',
            link: (resource: ResourceDto) => resource?.resourceId ? ['/resources', resource.resourceId] : null,
            module: 'inventory'
          } as CorePortalFormlyReadonlyTyping,
          entityService: this.resourceInheritableService,
          idKey: 'resourceId',
          displayKey: 'name',
          wholeObject: true,
          skipGetOne: true,
          clearable: true,
          module: 'inventory',
        }
      },
    ];
  }
}
