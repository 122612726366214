import {BaseXsStoreReducerTypes, entityXsStoreSetLoadingForAction, EntityXsStoreState} from '@nexnox-web/core-store';
import {ContactDto} from '@nexnox-web/core-shared';
import {ContactDetailXsStoreActions} from './contact-detail-xs-store.actions';
import dayjs from 'dayjs';
import {immerOn} from 'ngrx-immer/store';

export const createContactDetailXsStoreReducer = (
  actions: ContactDetailXsStoreActions,
  initialState: EntityXsStoreState<ContactDto>
): BaseXsStoreReducerTypes<EntityXsStoreState<ContactDto>, ContactDetailXsStoreActions>[] => ([
  immerOn(actions.resetPassword, draft => {
    draft.entityData = entityXsStoreSetLoadingForAction(draft.entityData, {
      [actions.resetPassword.type]: true
    });
  }),
  immerOn(actions.resetPasswordSuccess, draft => {
    draft.entityData = entityXsStoreSetLoadingForAction(draft.entityData, {
      [actions.resetPassword.type]: false
    });
  }),

  immerOn(actions.forcePassword, draft => {
    draft.entityData = entityXsStoreSetLoadingForAction(draft.entityData, {
      [actions.forcePassword.type]: true
    });
  }),
  immerOn(actions.forcePasswordSuccess, draft => {
    draft.entityData = entityXsStoreSetLoadingForAction(draft.entityData, {
      [actions.forcePassword.type]: false
    });
  }),

  immerOn(actions.enable, draft => {
    draft.entityData = entityXsStoreSetLoadingForAction(draft.entityData, {
      [actions.enable.type]: true
    });
  }),
  immerOn(actions.enableSuccess, draft => {
    draft.entity = {
      ...draft.entity,
      access: { isLockedOut: false, since: null }
    };
    draft.model = {
      ...draft.model,
      access: { isLockedOut: false, since: null }
    };
    draft.entityData = entityXsStoreSetLoadingForAction(draft.entityData, {
      [actions.enable.type]: false
    });
  }),

  immerOn(actions.disable, draft => {
    draft.entityData = entityXsStoreSetLoadingForAction(draft.entityData, {
      [actions.disable.type]: true
    });
  }),
  immerOn(actions.disableSuccess, draft => {
    const access = {
      isLockedOut: true,
      since: dayjs.utc().format('YYYY-MM-DDTHH:mm:ssZ')
    };

    draft.entity = { ...draft.entity, access };
    draft.model = { ...draft.model, access };
    draft.entityData = entityXsStoreSetLoadingForAction(draft.entityData, {
      [actions.disable.type]: false
    });
  }),

  immerOn(actions.changeMailAddress, (draft) => {
    draft.entityData = entityXsStoreSetLoadingForAction(draft.entityData, {
      [actions.changeMailAddress.type]: true
    });
  }),

  immerOn(actions.changeMailAddressSuccess, (draft, { email }) => {

    draft.entity = { ...draft.entity, emailAddress: email };
    draft.model = { ...draft.model, emailAddress: email };
    draft.entityData = entityXsStoreSetLoadingForAction(draft.entityData, {
      [actions.changeMailAddress.type]: false
    });
  }),

  immerOn(actions.bulkAreaAssignment, (draft) => {
    draft.entityData = entityXsStoreSetLoadingForAction(draft.entityData, {
      [actions.bulkAreaAssignment.type]: true
    });
  }),

  immerOn(actions.bulkAreaAssignmentSuccess,
    (draft, payload) => {
      draft.entityData = entityXsStoreSetLoadingForAction(draft.entityData, {
        [actions.bulkAreaAssignment.type]: false
      });
    }),
]);
