import {WeekDay} from '@angular/common';
import {Injectable, Injector} from '@angular/core';
import {
  ApplyFromResourcesByLocationDto,
  ApplyFromTicketsByLocationDto,
  ClosingLocationOptionsDto,
  CombineOperator,
  CoreSharedApiBaseService,
  Filter,
  FilterOperators,
  FilterTypes,
  LocationDto,
  LocationState,
  PreviewResourcesByLocationDto,
  PreviewTicketsByLocationDto
} from '@nexnox-web/core-shared';
import {Observable, of} from "rxjs";
import {map, take} from "rxjs/operators";

// No closed locations
export const noClosedLocationsFilter$ = of([{
  type: FilterTypes.DataTransferObject,
  operator: FilterOperators.NotEqual,
  property: 'state',
  value: LocationState.Closed.toString()
}]);

@Injectable()
export class CorePortalFeatureMasterDataLocationService extends CoreSharedApiBaseService {
  constructor(
    protected injector: Injector
  ) {
    super(injector, null, 'location');
  }

  /* istanbul ignore next */
  public static mapSearchFilter(searchFilter: Filter): Filter {
    return ({
      type: FilterTypes.Grouped,
      combinedAs: CombineOperator.Or,
      children: [
        searchFilter,
        {
          type: FilterTypes.DataTransferObject,
          operator: FilterOperators.Contains,
          property: 'locationNumber',
          value: searchFilter.value
        }
      ]
    });
  }

  /* istanbul ignore next */
  public static searchCompare(term: string, item: LocationDto): boolean {
    return item.name?.toLowerCase()?.includes(term.toLowerCase()) ||
      item.locationNumber?.toLowerCase()?.includes(term.toLowerCase());
  }

  /* istanbul ignore next: If statements */
  public static mapOpenDaysToWeekDayArray(item: LocationDto): void {
    const openDays: WeekDay[] = [];

    if (item.monday) openDays.push(WeekDay.Monday);
    if (item.tuesday) openDays.push(WeekDay.Tuesday);
    if (item.wednesday) openDays.push(WeekDay.Wednesday);
    if (item.thursday) openDays.push(WeekDay.Thursday);
    if (item.friday) openDays.push(WeekDay.Friday);
    if (item.saturday) openDays.push(WeekDay.Saturday);
    if (item.sunday) openDays.push(WeekDay.Sunday);

    (item as any).openDays = openDays;
  }

  public static mapOpenDaysToBooleanFields(item: LocationDto): void {
    const openDays: WeekDay[] = (item as any)?.openDays ?? [];

    item.monday = openDays.includes(WeekDay.Monday);
    item.tuesday = openDays.includes(WeekDay.Tuesday);
    item.wednesday = openDays.includes(WeekDay.Wednesday);
    item.thursday = openDays.includes(WeekDay.Thursday);
    item.friday = openDays.includes(WeekDay.Friday);
    item.saturday = openDays.includes(WeekDay.Saturday);
    item.sunday = openDays.includes(WeekDay.Sunday);
  }

  public getPreviewResourcesByLocation(stereotypeId: number | string): Observable<PreviewResourcesByLocationDto> {
    return this.httpRequestService.get<PreviewResourcesByLocationDto>(`locationstereotype/${stereotypeId}/previewresourcesbylocation`).pipe(
      map(response => response.body)
    );
  }

  public applyResources(skeletons: ApplyFromResourcesByLocationDto, locationId: number | string): Observable<ApplyFromResourcesByLocationDto> {
    return this.httpRequestService.post<ApplyFromResourcesByLocationDto>(`location/${locationId}/applyfromresourcesbylocation`, skeletons).pipe(
      map(response => response.body),
      take(1)
    );
  }

  public getPreviewTicketsByLocation(stereotypeId: number | string): Observable<PreviewTicketsByLocationDto> {
    return this.httpRequestService.get<PreviewTicketsByLocationDto>(`locationstereotype/${stereotypeId}/previewticketsbylocation`).pipe(
      map(response => response.body)
    );
  }

  public applyTickets(skeletons: ApplyFromTicketsByLocationDto, locationId: number | string): Observable<ApplyFromTicketsByLocationDto> {
    return this.httpRequestService.post<ApplyFromTicketsByLocationDto>(`location/${locationId}/applyfromticketsbylocation`, skeletons).pipe(
      map(response => response.body),
      take(1)
    );
  }

  public closeLocation(locationId: number | string, body: ClosingLocationOptionsDto): Observable<ClosingLocationOptionsDto> {
    const {shouldCancelOpenMissions, shouldMoveResources, shouldMoveResourcesTarget} = body;
    return this.httpRequestService.post<ClosingLocationOptionsDto>(`location/${locationId}/close`, {
      shouldCancelOpenMissions,
      shouldMoveResources,
      shouldMoveResourcesTarget
    }).pipe(
      map(response => response.body)
    );
  }

  public reopenLocation(locationId: number | string): Observable<null> {
    return this.httpRequestService.post<null>(`location/${locationId}/reopen`, null).pipe(
      map(response => response.body)
    );
  }
}
