<nexnox-web-modal #modalComponent
                  [title]="'core-portal.audit.subtitles.audit-detail' | translate:{
                    date: (audit$ | async)?.createdAt | nexnoxWebBsDate:'L' | async
                  }"
                  [showCancelButton]="false"
                  [confirmButtonText]="'core-portal.core.general.okay' | translate"
                  [loading]="(audit$ | async) === undefined">
  <div class="d-flex flex-column w-100">
    <div>
      <p-table [value]="properties$ | async"
               responsiveLayout="scroll"
               [autoLayout]="true">
        <ng-template pTemplate="caption">
          <b>{{ 'core-portal.audit.subtitles.audit-properties' | translate }}</b>
        </ng-template>

        <ng-template pTemplate="header">
          <tr>
            <th>{{ 'core-shared.shared.fields.name' | translate }}</th>
            <th>{{ 'core-shared.shared.fields.old-value' | translate }}</th>
            <th>{{ 'core-shared.shared.fields.new-value' | translate }}</th>
          </tr>
        </ng-template>

        <ng-template pTemplate="body" let-item>
          <tr>
            <td>{{ item.name }}</td>
            <td>
              <ng-container [ngTemplateOutlet]="valueTemplate"
                            [ngTemplateOutletContext]="{ value: item.oldValue, type: item.typeOfProperty }">
              </ng-container>
            </td>
            <td>
              <ng-container [ngTemplateOutlet]="valueTemplate"
                            [ngTemplateOutletContext]="{ value: item.newValue, type: item.typeOfProperty }">
              </ng-container>
            </td>
          </tr>
        </ng-template>

        <ng-template pTemplate="emptymessage">
          <ng-container *ngTemplateOutlet="emptyMessageTemplate"></ng-container>
        </ng-template>
      </p-table>
    </div>

    <div class="mt-3">
      <p-table [value]="collections$ | async"
               responsiveLayout="scroll"
               [autoLayout]="true">
        <ng-template pTemplate="caption">
          <b>{{ 'core-portal.audit.subtitles.audit-collections' | translate }}</b>
        </ng-template>

        <ng-template pTemplate="header">
          <tr>
            <th>{{ 'core-shared.shared.fields.name' | translate }}</th>
            <th>{{ 'core-shared.shared.fields.count' | translate }}</th>
          </tr>
        </ng-template>

        <ng-template pTemplate="body" let-item>
          <tr>
            <td>{{ item.name }}</td>
            <td>{{ item.count }}</td>
          </tr>
        </ng-template>

        <ng-template pTemplate="emptymessage">
          <ng-container *ngTemplateOutlet="emptyMessageTemplate"></ng-container>
        </ng-template>
      </p-table>
    </div>
  </div>
</nexnox-web-modal>

<ng-template #valueTemplate let-value="value" let-type="type">
  <ng-container *ngIf="value" [ngSwitch]="type">
    <ng-container *ngSwitchDefault>{{ value }}</ng-container>

    <ng-container *ngSwitchCase="propertyTypes.DateTime">
      {{ value | nexnoxWebBsDate:'L LT' | async }}
    </ng-container>

    <ng-container *ngSwitchCase="propertyTypes.TimeSpan">
      {{ value }}
    </ng-container>

    <ng-container *ngSwitchCase="propertyTypes.Double">
      {{ value | nexnoxWebLocaleStringPipe:2 }}
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #emptyMessageTemplate>
  <tr>
    <td [colSpan]="100">
      {{ 'core-shared.shared.table.empty' | translate }}
    </td>
  </tr>
</ng-template>
