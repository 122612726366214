import { Injectable, Injector } from '@angular/core';
import { CoreSharedApiBaseService, Product } from '@nexnox-web/core-shared';
import {Observable} from "rxjs";
import {map} from "rxjs/operators";

@Injectable()
export class TechPortalFeatureResourceIssueService extends CoreSharedApiBaseService {
  constructor(
    protected injector: Injector
  ) {
    super(injector, Product.TECH, 'issue', ['resource']);
  }

  public deleteOne<T>(id: number | string): Observable<T> {
    return this.httpRequestService.delete<T>(`issue/${id}`).pipe(
      map(response => response.body)
    );
  }
}
