import {AfterViewInit, Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {
  CorePortalEntitySelectDatatableSearchModalBaseComponent
} from '../entity-select-datatable-search-modal-base/entity-select-datatable-search-modal-base.component';
import {CorePortalEntityDatatableComponent, DatatableSelectionMode} from '../../../entity-datatable';
import {
  FilterDto,
  ResourceDto,
  ViewChildrenHelper
} from '@nexnox-web/core-shared';
import {filter} from "rxjs/operators";

@Component({
  selector: 'nexnox-web-entity-select-datatable-search-modal',
  templateUrl: './entity-select-datatable-search-modal.component.html',
  styleUrls: ['./entity-select-datatable-search-modal.component.scss']
})
export class CorePortalEntitySelectDatatableSearchModalComponent implements AfterViewInit {

  @ViewChild('datatableComponent') public datatableComponent: CorePortalEntityDatatableComponent;

  @Input() public selectionMode: DatatableSelectionMode;

  @Input() public baseComponent: CorePortalEntitySelectDatatableSearchModalBaseComponent<any>;

  @Input() public savedFilters: FilterDto[];

  @Output() public selectionChange: EventEmitter<ResourceDto[]> = new EventEmitter<ResourceDto[]>();

  public detailFn: (row: any) => void = (row: any) => this.onDetail(row);

  public ngAfterViewInit(): void {

    // Filter workaround
    // If saved filters are set, they need to be also declared to defaultFilters for the first load
    // After first load has happened, defaultFilters get removed, savedFilters stay.
    if (this.savedFilters?.length > 0) {
      let clearDefaultFilterAfterFirstLoadPage = true;
      this.baseComponent.defaultFilters = this.savedFilters ?? [];

      const subscription = this.baseComponent.loaded$.pipe(filter(loaded => loaded)).subscribe(() => {
        if (clearDefaultFilterAfterFirstLoadPage) {
          this.baseComponent.defaultFilters = [];
          clearDefaultFilterAfterFirstLoadPage = false;
          subscription.unsubscribe();
        }
      });
    }

    ViewChildrenHelper.untilViewChildLoaded$<this>(this, 'datatableComponent').subscribe(() => {
      this.baseComponent.createColumns(this.datatableComponent);
    });
  }

  public onDetail(row: any): void {
    this.baseComponent.onDetail(row);
  }
}
