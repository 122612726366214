import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { CorePortalPermissionGuard } from '@nexnox-web/core-portal';
import { ControlPointDetailComponent, ControlPointListComponent } from './containers';
import {AppPermissions, I18nBreadcrumbResolver, UnsavedChangesGuard, UnsavedCreateGuard} from '@nexnox-web/core-shared';

export const TECH_PORTAL_CCP_CONTROL_POINTS_ROUTES: Routes = [
  {
    path: '',
    canActivate: [CorePortalPermissionGuard],
    canDeactivate: [UnsavedCreateGuard],
    component: ControlPointListComponent,
    data: {
      permissions: [AppPermissions.DisplayControlPoint]
    }
  },
  {
    path: ':controlPointId',
    component: ControlPointDetailComponent,
    canActivate: [CorePortalPermissionGuard],
    canDeactivate: [UnsavedChangesGuard],
    data: {
      permissions: [AppPermissions.UpdateControlPoint],
      breadcrumbs: I18nBreadcrumbResolver,
      tab: 'controlPoint',
      text: 'tech-portal.ccp.control-points.subtitles.detail'
    }
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(TECH_PORTAL_CCP_CONTROL_POINTS_ROUTES)
  ],
  exports: [
    RouterModule
  ]
})
export class ControlPointsRoutingModule {
}
