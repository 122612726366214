<div class="mb-4">
  <nexnox-web-resources-resource-occurred-consumptions [isEmbedded]="true"
                                                       [parentIds]="parentIds"
                                                       [getEntityOnModeChange]="false"
                                                       [canDelete]="false">
  </nexnox-web-resources-resource-occurred-consumptions>
</div>
<div class="mb-4">
  <nexnox-web-resources-resource-occurred-errors [isEmbedded]="true"
                                                 [parentIds]="parentIds"
                                                 [getEntityOnModeChange]="false"
                                                 [canDelete]="false">
  </nexnox-web-resources-resource-occurred-errors>
</div>
<nexnox-web-resources-resource-occurred-hints [isEmbedded]="true"
                                              [parentIds]="parentIds"
                                              [getEntityOnModeChange]="false"
                                              [canDelete]="false">
</nexnox-web-resources-resource-occurred-hints>
