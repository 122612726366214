import { PagedEntitiesXsStore, PagedEntitiesXsStoreState } from '@nexnox-web/core-store';
import { Action, createSelector } from '@ngrx/store';
import { selectRolesState } from '../../roles.selectors';
import { AppEntityType, AppRoleDto } from '@nexnox-web/core-shared';
import { LocalAppRoleDto, CorePortalRoleService } from '../../services';
import { Injectable, Injector } from '@angular/core';

export interface RoleListStoreState extends PagedEntitiesXsStoreState<AppRoleDto, LocalAppRoleDto> {
}

/* istanbul ignore next */
export const roleListStore = new PagedEntitiesXsStore<AppRoleDto, LocalAppRoleDto, RoleListStoreState>({
  actionLabel: 'Core Portal - Settings - Role List',
  stateSelector: createSelector(selectRolesState, state => state.roleList),
  serviceType: CorePortalRoleService,
  entityType: AppEntityType.Role,
  selectId: role => role.entity?.appRoleId ?? role.model?.appRoleId,
  sanitizeModel: CorePortalRoleService.sanitizeModel
});

export function roleListStoreReducer(state: RoleListStoreState, action: Action): any {
  return roleListStore.reducer(state, action);
}

@Injectable()
export class RoleListStoreEffects extends roleListStore.effects {
  constructor(
    protected injector: Injector
  ) {
    super(injector);
  }
}
