import {ChangeDetectionStrategy, Component, Injector, Input, OnInit} from '@angular/core';
import {CorePortalEntityOverviewBaseComponent, DatatableActionButton, DeleteEntityModel} from '@nexnox-web/core-portal';
import {
  AppEntityType,
  AppPermissions,
  FilterOperators,
  FilterTypes,
  Mappers,
  ResourcesByLocationDefinitionListDto
} from '@nexnox-web/core-shared';
import {resourcesByLocationStereotypeListStore} from '../../store';

@Component({
  selector: 'nexnox-web-settings-stereotypes-resources-by-location-definition-list',
  templateUrl: './resources-by-location-stereotype-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ResourcesByLocationStereotypeListComponent extends CorePortalEntityOverviewBaseComponent<ResourcesByLocationDefinitionListDto> implements OnInit {

  @Input() public stereotypeId: number | string;

  public title = 'core-shared.shared.skeletons.resource-skeletons';
  public idProperty = 'resourcesByLocationDefinitionId';
  public displayProperty = 'title';
  public datatableConfigName = 'SettingsResourcesByLocationDefinitionDefault';
  public componentId = 'ResourcesByLocationStereotypeListComponent';
  public isEmbedded = true;

  constructor(
    protected injector: Injector
  ) {
    super(injector, resourcesByLocationStereotypeListStore, Mappers.ResourcesByLocationDefinitionListDto.serializedName, AppEntityType.None);

  }

  public ngOnInit(): void {
    this.defaultFilter = [{
      type: FilterTypes.DataTransferObject,
      operator: FilterOperators.Equal,
      property: 'stereotype.stereotypeId',
      value: (this.stereotypeId ?? 0).toString()
    }];
    super.ngOnInit();
  }

  public getRowActionButtons(): DatatableActionButton[] {
    return [
      ...this.getDefaultRowActionButtons(
        'core-shared.shared.skeletons.edit-skeleton',
        definition => `stereotypes/location-stereotypes/${this.parentIds[0]}/resources-by-location/${definition.resourcesByLocationDefinitionId}`,
        [AppPermissions.UpdateResourcesByLocationDefinition],
        {
          module: 'settings'
        }
      ),
    ];
  }

  public getDeleteEntityModel(): DeleteEntityModel {
    return {
      titleKey: 'core-shared.shared.skeletons.delete-skeleton',
      descriptionKey: 'core-shared.shared.skeletons.delete-skeleton-description',
      confirmKey: 'core-shared.shared.skeletons.delete-skeleton',
      deletePermission: AppPermissions.DeleteResourcesByLocationDefinition
    };
  }
}
