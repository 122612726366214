<nexnox-web-cardbox [title]="'core-portal.settings.issue-template.titles.list' | translate"
                    [loading]="loading"
                    paddingClasses="px-3 pb-3">

  <div *ngIf="!readonly" class="mb-3">
    <form [formGroup]="addIssueTemplateForm">
      <formly-form class="row w-100"
                   [form]="addIssueTemplateForm"
                   [model]="addIssueTemplateModelSubject | async"
                   [fields]="addIssueTemplateFields"
                   (modelChange)="addIssueTemplateModelSubject.next($event)">
      </formly-form>
    </form>

    <div class="d-flex justify-content-end w-100">
      <button type="button"
              pButton
              [icon]="faPlus | nexnoxWebFaIconStringPipe"
              [title]="'core-shared.shared.actions.add' | translate"
              class="p-button-secondary p-button-outlined mr-2"
              [label]="'core-shared.shared.actions.add' | translate"
              [disabled]="addIssueTemplateForm.invalid"
              (click)="onAddIssueTemplate()">
      </button>
    </div>
  </div>

  <div class="pt-2 px-2 pb-3">
    <nexnox-web-sortable-list #issueTemplatesListEdit
                              [items]="issueTemplatesItems$  | async"
                              [disabled]="readonly"
                              [sortable]="true"
                              [editable]="false"
                              [actions]="actions"
                              (itemsChange)="onIssueTemplatesChange($event)">
    </nexnox-web-sortable-list>
  </div>

</nexnox-web-cardbox>

