import {ChangeDetectionStrategy, Component, Input, OnInit} from "@angular/core";
import {SettingsMenuBaseComponent} from "../../base-component";
import {SettingsMenuConfigCategoryDto, SettingsMenuConfigItemDto, SettingsMenuDto} from "../../models";
import {isUndefined} from "lodash";
import {faPlus} from "@fortawesome/free-solid-svg-icons/faPlus";
import {NavigationEnd, Router} from "@angular/router";
import {BehaviorSubject, filter, startWith} from "rxjs";
import {UnsubscribeHelper} from "@nexnox-web/core-shared";

@Component({
  selector: 'nexnox-web-settings-menu',
  templateUrl: './settings-menu.component.html',
  styleUrls: ['settings-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SettingsMenuComponent extends UnsubscribeHelper implements OnInit {

  @Input() public baseComponent: SettingsMenuBaseComponent;

  public menu$: BehaviorSubject<SettingsMenuDto> = new BehaviorSubject<SettingsMenuDto>(null);
  public module: string;

  public faPlus = faPlus;

  constructor(
    private router: Router
  ) {
    super();
  }

  public ngOnInit(): void {

    if (isUndefined(this.baseComponent)) {
      throw new Error('MenuComponent: No base component found!');
      return;
    }

    this.module = this.baseComponent?.module;

    this.baseComponent?.currentMenu?.then(menu => {
      this.menu$.next(menu);
      this.baseComponent.isExpandAllItemsOnStart ? this._expandAll() : this._expandFirst();
    });

    this.subscribe(
      this.router.events.pipe(filter(event => event instanceof NavigationEnd), startWith(undefined)),
      () => this._expandIfActiveIsCollapsed(this.menu$.value)
    );
  }

  public goToUrl(item: SettingsMenuConfigItemDto, fragment?: string): void {
    this.baseComponent.gotToUrl(item?.link, fragment)
  }

  public getCategories(menu: SettingsMenuDto):SettingsMenuConfigCategoryDto[] {
    return menu.chapters.map(chapter => chapter.categories).flat();
  }

  private _expandIfActiveIsCollapsed(menu: SettingsMenuDto): void {
    for (const chapter of menu?.chapters ?? []) {
      for (const category of chapter?.categories ?? []) {
        for (const item of category?.items ?? []) {
          if (!category.isExpanded && this._itemIsOnRoute(item)) {
            category.isExpanded = true;
          }
        }
      }
    }
    this.menu$.next(menu);
  }

  private _itemIsOnRoute(item: SettingsMenuConfigItemDto): boolean {
    return this.router?.routerState?.snapshot?.url?.includes(`/${item?.link?.join('/')}`);
  }

  private _expandFirst(): void {
    const menu = this.menu$.value;
    const first = menu?.chapters[0].categories.find(c => c.hasAccess === true);
    if (first) {
      first.isExpanded = true;
      this.menu$.next(menu);
    }
  }

  private _expandAll(): void {
    const menu = this.menu$.value;
    for (const category of menu.chapters.map(chapter => chapter.categories).flat() ?? []) {
      category.isExpanded = true;
    }
    this.menu$.next(menu);
  }
}
