import {Injector, NgModule} from '@angular/core';
import {AppComponent} from './app.component';
import {
  CORE_PORTAL_DASHBOARD_CONFIG,
  CORE_PORTAL_DATATABLE_STANDARD_CONFIG,
  CORE_PORTAL_DATATABLE_PREDEFINED_VIEWS_CONFIG,
  CORE_PORTAL_EXCLUDED_ROLE_TERMS,
  CORE_PORTAL_ROLES_CONFIG,
  CORE_PORTAL_SIDEBAR_ITEMS,
  CORE_PORTAL_CROSS_CREATION_CONFIG,
  CorePortalModule
} from '@nexnox-web/core-portal';
import {environment} from '../environments/environment';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {Product, RoleGeneralTerms, ServiceLocator} from '@nexnox-web/core-shared';
import {
  CORE_PORTAL_FEATURE_STEREOTYPE_ADDITIONAL_FIELDS,
  CORE_PORTAL_FEATURE_STEREOTYPE_SKELETONS_TOKEN
} from '@nexnox-web/core-portal/features/settings/features/stereotypes';
import {AppRoutingModule} from './app-routing.module';
import {
  provideDashboardConfig,
  provideDatatableStandardConfig,
  provideLinksConfig,
  provideRolesConfig,
  provideSettingsMenuConfig,
  provideSidebarItems,
  provideStereotypeAdditionalFields,
  provideStereotypeSkeletons,
  provideTextTemplateContextConfig,
  provideCrossCreationConfig,
  provideDatatablePredefinedViewConfig
} from './config';
import {TECH_PORTAL_LINKS_CONFIG_TOKEN, TechPortalModule} from '@nexnox-web/tech-portal-lib';
import {TECH_PORTAL_TEXT_TEMPLATE_CONTEXT_CONFIG} from '@nexnox-web/tech-portal/features/templates';
import {
  TechPortalFeatureTicketSettingsPrioritySetsModule
} from '@nexnox-web/tech-portal/features/ticket-settings/features/priority-sets';
import {
  TechPortalFeatureTicketSettingsStateMachinesModule
} from '@nexnox-web/tech-portal/features/ticket-settings/features/state-machines';
import {CorePortalInventoryNumbersModule} from '@nexnox-web/core-portal/features/settings/features/inventory-numbers';
import {SkeletonsModule} from './skeletons';
import {TranslateService} from '@ngx-translate/core';
import {TechPortalFeatureCCPControlPointsModule} from '@nexnox-web/tech-portal/features/ccp/control-points';
import {TechPortalFeatureMissionsModule} from '@nexnox-web/tech-portal/features/missions';
import {TechPortalFeatureTicketsModule} from '@nexnox-web/tech-portal/features/tickets';
import {TechPortalFeatureResourcesModule} from '@nexnox-web/tech-portal/features/resources';
import {TechPortalFeatureContractsModule} from '@nexnox-web/tech-portal/features/contracts';
import {
  CorePortalFeatureMasterDataLocationsModule
} from '@nexnox-web/core-portal/features/master-data/features/locations';
import {
  CorePortalFeatureMasterDataCompaniesModule
} from '@nexnox-web/core-portal/features/master-data/features/companies';
import {NotificationRulesModule} from "@nexnox-web/core-portal/features/settings/features/notification-rules";
import {
  CORE_PORTAL_SETTINGS_MENU_CONFIG,
  CorePortalSettingsMenuModule
} from "@nexnox-web/core-portal/features/settings/menu";
import {TechPortalFeatureIssuesModule} from "@nexnox-web/tech-portal/features/issues";

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    AppRoutingModule,
    CorePortalModule.forRoot(environment, Product.TECH, 'one-portal', [
      'core-portal',
      'orga-portal',
      'tech-portal',
      'data-portal',
      ...(environment.additionalTranslations ?? [])
    ]),
    TechPortalModule.forRoot(),
    SkeletonsModule,

    TechPortalFeatureTicketSettingsPrioritySetsModule.forFeature(),
    TechPortalFeatureTicketSettingsStateMachinesModule.forFeature(),
    TechPortalFeatureCCPControlPointsModule.forFeature(),
    CorePortalInventoryNumbersModule.forFeature(),
    NotificationRulesModule.forFeature(),
    TechPortalFeatureMissionsModule.forFeature(),
    TechPortalFeatureTicketsModule.forFeature(),
    TechPortalFeatureResourcesModule.forFeature(),
    TechPortalFeatureIssuesModule.forFeature(),
    TechPortalFeatureContractsModule.forFeature(),
    CorePortalFeatureMasterDataLocationsModule.forFeature(),
    CorePortalFeatureMasterDataCompaniesModule.forFeature(),
    CorePortalSettingsMenuModule,

    ...(!environment.production ? [StoreDevtoolsModule.instrument({
      name: 'One Portal NGRX',
      maxAge: 50
    })] : [])
  ],
  providers: [
    {
      provide: CORE_PORTAL_EXCLUDED_ROLE_TERMS,
      useValue: [
        RoleGeneralTerms.OrgaUser,
        RoleGeneralTerms.DeviceUser,
        RoleGeneralTerms.TeamUser
      ]
    },

    {provide: CORE_PORTAL_DASHBOARD_CONFIG, useValue: provideDashboardConfig()},
    {provide: CORE_PORTAL_DATATABLE_STANDARD_CONFIG, useValue: provideDatatableStandardConfig()},
    {provide: CORE_PORTAL_DATATABLE_PREDEFINED_VIEWS_CONFIG, useValue: provideDatatablePredefinedViewConfig()},
    {provide: CORE_PORTAL_ROLES_CONFIG, useValue: provideRolesConfig()},
    {provide: CORE_PORTAL_SIDEBAR_ITEMS, useValue: provideSidebarItems()},

    {provide: CORE_PORTAL_FEATURE_STEREOTYPE_ADDITIONAL_FIELDS, useValue: provideStereotypeAdditionalFields()},
    {provide: CORE_PORTAL_FEATURE_STEREOTYPE_SKELETONS_TOKEN, useValue: provideStereotypeSkeletons()},
    {provide: TECH_PORTAL_LINKS_CONFIG_TOKEN, useValue: provideLinksConfig()},
    {
      provide: TECH_PORTAL_TEXT_TEMPLATE_CONTEXT_CONFIG,
      useFactory: provideTextTemplateContextConfig,
      deps: [TranslateService]
    },
    {provide: CORE_PORTAL_SETTINGS_MENU_CONFIG, useValue: provideSettingsMenuConfig()},
    {provide: CORE_PORTAL_CROSS_CREATION_CONFIG, useValue: provideCrossCreationConfig()}
  ],
  bootstrap: [
    AppComponent
  ]
})

export class AppModule {
  constructor(private injector: Injector) {
    ServiceLocator.injector = this.injector;
  }
}
