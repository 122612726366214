import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CoreSharedModule } from '@nexnox-web/core-shared';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormlyBootstrapModule } from '@ngx-formly/bootstrap';
import { FormlyModule } from '@ngx-formly/core';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { ColorSketchModule } from 'ngx-color/sketch';
import { SignaturePadModule } from 'ngx-signaturepad';
import { UiSwitchModule } from 'ngx-ui-switch';
import { BlockUIModule } from 'primeng/blockui';
import { ButtonModule } from 'primeng/button';
import { PanelModule } from 'primeng/panel';
import { SelectButtonModule } from 'primeng/selectbutton';
import { CorePortalRouterOverridesModule } from '../../router-overrides';
import { CorePortalEntitySelectModule } from '../entity/entity-select';
import {TableModule} from "primeng/table";

export const importedModules = [
  CommonModule,
  CoreSharedModule,
  BsDatepickerModule,
  NgSelectModule,
  UiSwitchModule,
  CKEditorModule,
  PopoverModule,
  SignaturePadModule,
  ButtonModule,
  BlockUIModule,
  PanelModule,
  SelectButtonModule,
  ColorSketchModule,
  SelectButtonModule,
  CorePortalEntitySelectModule,
  CorePortalRouterOverridesModule,
  FontAwesomeModule,
  TableModule
];

export const sharedModules = [
  FormsModule,
  ReactiveFormsModule,
  FormlyBootstrapModule
];

export const exportedModules = [
  FormlyModule
];

export const defaultExtensions = [];
