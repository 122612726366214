import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  Renderer2,
  ViewChild
} from '@angular/core';
import {FormlyFieldConfig} from '@ngx-formly/core';
import {FormGroup} from '@angular/forms';
import {AppPermissions, CustomPropertyDto, UnsubscribeHelper} from '@nexnox-web/core-shared';
import {cloneDeep} from 'lodash';
import {faTrashAlt} from '@fortawesome/free-solid-svg-icons/faTrashAlt';
import {BehaviorSubject, fromEvent, merge, Observable, Subscription} from 'rxjs';
import {faArrowDown} from '@fortawesome/free-solid-svg-icons/faArrowDown';
import {faArrowUp} from '@fortawesome/free-solid-svg-icons/faArrowUp';
import {CorePortalCardboxAction} from '@nexnox-web/core-portal';
import {CustomPropertyTemplates} from './custom-property.templates';
import {BindObservable} from 'bind-observable';
import {map} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';
import {
  CustomPropertySetType,
  LocalCustomPropertyDto
} from "@nexnox-web/core-portal/features/settings/features/stereotypes/src/lib/models";

@Component({
  selector: 'nexnox-web-settings-stereotypes-custom-property-edit',
  templateUrl: './custom-property-edit.component.html',
  styleUrls: ['./custom-property-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomPropertyEditComponent extends UnsubscribeHelper implements OnInit, AfterViewInit {
  @Input() public customProperty: LocalCustomPropertyDto;
  @Input() public customPropertySetType: CustomPropertySetType = CustomPropertySetType.STEREOTYPE;

  @Input() @BindObservable() public readonly: boolean;
  public readonly$!: Observable<boolean>;

  @Input() @BindObservable() public isRateable: boolean;
  public isRateable$!: Observable<boolean>;

  @Input() @BindObservable() public isFirst: boolean;
  public isFirst$!: Observable<boolean>;

  @Input() @BindObservable() public isLast: boolean;
  public isLast$!: Observable<boolean>;

  @Output() public modelChange: EventEmitter<CustomPropertyDto> = new EventEmitter<CustomPropertyDto>();
  @Output() public moveUp: EventEmitter<void> = new EventEmitter<void>();
  @Output() public moveDown: EventEmitter<void> = new EventEmitter<void>();
  @Output() public delete: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild('nameEditInput') public nameEditInput: ElementRef<HTMLInputElement>;
  @ViewChild('formElement') public formElement: ElementRef<HTMLFormElement>;

  public readonlyFn: () => boolean;

  public form: FormGroup;
  public fields: FormlyFieldConfig[];

  public hasFocus$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public nameInputHasFocus$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public showCursorPointer$: Observable<boolean>;
  public nameInputFocusSubscription: Subscription;

  /* istanbul ignore next */
  public headerActions: CorePortalCardboxAction[] = [
    {
      icon: faArrowUp,
      class: 'p-button-secondary',
      tooltip: 'core-shared.shared.actions.move-up',
      permission: AppPermissions.UpdateCustomPropertySet,
      shouldShow: () => this.readonly$.pipe(
        map(readonly => !readonly)
      ),
      isDisabled: () => this.isFirst$,
      callback: () => this.moveUp.emit()
    },
    {
      icon: faArrowDown,
      class: 'p-button-secondary',
      tooltip: 'core-shared.shared.actions.move-down',
      permission: AppPermissions.UpdateCustomPropertySet,
      shouldShow: () => this.readonly$.pipe(
        map(readonly => !readonly)
      ),
      isDisabled: () => this.isLast$,
      callback: () => this.moveDown.emit()
    },
    {
      icon: faTrashAlt,
      class: 'p-button-danger',
      tooltip: 'core-portal.settings.actions.delete-custom-property',
      permission: AppPermissions.DeleteCustomProperty,
      shouldShow: () => this.readonly$.pipe(
        map(readonly => !readonly)
      ),
      callback: () => this.delete.emit()
    }
  ];

  private customPropertyTemplates: CustomPropertyTemplates;

  constructor(
    private translate: TranslateService,
    private renderer: Renderer2,
    private customPropertyElementRef: ElementRef
  ) {
    super();

    this.showCursorPointer$ = this.hasFocus$.asObservable().pipe(map(focused => !this.readonly && !focused));
    this.readonlyFn = () => this.readonly ||
      (!this.hasFocus$.getValue() && this.customPropertySetType === CustomPropertySetType.KNOWLEDGE_EXAM);
  }

  public get showPositionBadge(): boolean {
    return this.customProperty.position && this.customPropertySetType === CustomPropertySetType.KNOWLEDGE_EXAM;
  }

  @HostListener('document:mouseup', ['$event'])
  public handleClick(event: MouseEvent): void {
    if (this.readonly || this.customPropertySetType !== CustomPropertySetType.KNOWLEDGE_EXAM) return;
    this.handleReadonlyClickBehavior(event?.target as HTMLElement);
  }

  public ngOnInit(): void {

    this.customPropertyTemplates = new CustomPropertyTemplates(
      this.readonlyFn,
      () => this.isRateable,
      this.isRateable$,
      this.translate.currentLang
    );
    if (this.customPropertySetType === CustomPropertySetType.KNOWLEDGE_EXAM) {
      this.hasFocus$.next(this.isFirst);
    }
    this.form = new FormGroup({});
    this.fields = this.createForm();
  }

  public ngAfterViewInit(): void {
    this.subscribe(this.readonly$, (readonly) => {
      this.hasFocus$.next(!readonly && this.isFirst);
      if (!readonly) {
        this.subscribeToNameInputFocus();
      } else {
        this.nameInputFocusSubscription?.unsubscribe();
      }
    });
    this.scrollToNewCustomProperty();
  }

  public onNameChange(name: string): void {
    this.modelChange.emit({...this.customProperty, name});
  }

  public onCustomPropertyChange(model: CustomPropertyDto): void {
    this.modelChange.emit(cloneDeep(model));
  }

  public isModelValid(): boolean {
    return Boolean(this.nameEditInput?.nativeElement?.checkValidity()) && this.form.valid;
  }

  /* istanbul ignore next */
  private createForm(): FormlyFieldConfig[] {
    return this.customPropertyTemplates.createCustomPropertyForm(this.customProperty?.type);
  }

  private scrollToNewCustomProperty(): void {
    if (this.customProperty?.propertyAnchorId) {
      const element = document.getElementById(this.customProperty.propertyAnchorId);
      if (element) {
        element?.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
          inline: 'start'
        });
        if (this.nameEditInput) {
          this.renderer.selectRootElement(this.nameEditInput.nativeElement).focus();
          if (this.customPropertySetType === CustomPropertySetType.KNOWLEDGE_EXAM) {
            this.hasFocus$.next(true);
          }
        }
        this.customProperty.propertyAnchorId = null;
      }
    }
  }

  private handleReadonlyClickBehavior(eventTarget: HTMLElement | null): void {
    if (this.clickedOnOverlayMenu(eventTarget as HTMLElement)) return;
    if (!this.hasFocus$.getValue() && this.clickedOnThisCustomProperty(eventTarget)) {
      if (this.clickedOnFormContainer(eventTarget) || this.nameInputHasFocus$.getValue()) {
        this.hasFocus$.next(true);
      } else {
        this.hasFocus$.next(false);
      }
    } else if (this.hasFocus$.getValue() && !this.clickedOnThisCustomProperty(eventTarget)) {
      if (this.clickedOnAnyCustomProperty(eventTarget)) {
        this.hasFocus$.next(false);
      } else if (!this.clickedOnAnyCustomPropertySet(eventTarget)) {
        this.hasFocus$.next(false);
      }
    } else {
      this.hasFocus$.next(this.hasFocus$.getValue() || this.nameInputHasFocus$.getValue());
    }
  }

  private clickedOnThisCustomProperty(eventTarget: HTMLElement | null): boolean {
    return this.customPropertyElementRef?.nativeElement.contains(eventTarget);
  }

  private clickedOnFormContainer(eventTarget: HTMLElement | null): boolean {
    return this.formElement?.nativeElement.contains(eventTarget);
  }

  private clickedOnAnyCustomPropertySet(eventTarget: HTMLElement | null): boolean {
    while (eventTarget) {
      if (eventTarget.tagName.toLowerCase() === 'nexnox-web-settings-stereotypes-custom-property-set-edit') {
        return true;
      }
      eventTarget = eventTarget.parentElement;
    }
    return false;
  }

  private clickedOnAnyCustomProperty(eventTarget: HTMLElement | null): boolean {
    while (eventTarget) {
      if (eventTarget.tagName.toLowerCase() === 'nexnox-web-settings-stereotypes-custom-property-edit') {
        return true;
      }
      eventTarget = eventTarget.parentElement;
    }
    return false;
  }

  private clickedOnOverlayMenu(eventTarget: HTMLElement | null): boolean {
    while (eventTarget) {
      if (eventTarget.classList.contains('p-tieredmenu-overlay')) {
        return true;
      }
      eventTarget = eventTarget.parentElement;
    }
    return false;
  }

  private subscribeToNameInputFocus(): void {
    const nameInput = this.nameEditInput?.nativeElement;
    if (nameInput && this.customPropertySetType === CustomPropertySetType.KNOWLEDGE_EXAM) {
      this.nameInputFocusSubscription = merge(
        fromEvent(nameInput, 'focus').pipe(map(() => true)),
        fromEvent(nameInput, 'blur').pipe(map(() => false))
      ).subscribe((hasFocus: boolean) => this.nameInputHasFocus$.next(hasFocus));
    }
  }

}
