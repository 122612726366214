<ng-template #entityEditTemplate let-title="title">
  <nexnox-web-tickets-ticket-edit #editComponent
                                  [title]="title"
                                  [model]="createModel$ | async"
                                  [readonly]="false"
                                  [creating]="true"
                                  [loading]="(loaded$ | async) === false"
                                  [stereotypes$]="stereotypes$"
                                  (modelChange)="createModel$.next($event)">
  </nexnox-web-tickets-ticket-edit>
</ng-template>

<nexnox-web-entity-overview #overviewComponent
                            [entityOverviewBaseComponent]="this"
                            [entityEditTemplate]="entityEditTemplate"
                            detailLink="/tickets"
                            module="communication">
</nexnox-web-entity-overview>

<nexnox-web-ticket-state-change-sidebar #ticketStateChangeSidebar
                                        [ticketActionFacade]="customActionsFacade">
</nexnox-web-ticket-state-change-sidebar>
