import { isFinite } from 'lodash';

const units = {
  years: 365 * 24 * 60,
  weeks: 7 * 24 * 60,
  days: 24 * 60,
  hours: 60,
  minutes: 1
};

interface ReturnType {
  years: number;
  weeks: number;
  days: number;
  hours: number;
  minutes: number;
}

export function minutesTo(minutes: number, excluded: string[] = []): ReturnType {
  if (!isFinite(minutes) || minutes < 0) {
    return { years: null, weeks: null, days: null, hours: null, minutes: null };
  }

  let value = minutes;
  const returnValue: ReturnType = {} as any;

  for (const name in units) {
    if (units && units[name] && !excluded.includes(name)) {
      returnValue[name] = Math.floor(value / units[name]);
      value %= units[name];
    }
  }

  return returnValue;
}
