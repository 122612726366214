import {EntityXsStore, EntityXsStoreState} from '@nexnox-web/core-store';
import {AppEntityType, WinterhalterProductOrderIssueDto} from '@nexnox-web/core-shared';
import {Action, createSelector} from '@ngrx/store';
import {WinterhalterProductOrderIssueService} from '../../services';
import {Injectable, Injector} from '@angular/core';
import {selectIssuesState} from "../../issues.selectors";

export interface WinterhalterProductOrderIssueDetailStoreState extends EntityXsStoreState<WinterhalterProductOrderIssueDto> {
}

/* istanbul ignore next */
export const winterhalterProductOrderIssueDetailStore = new EntityXsStore<WinterhalterProductOrderIssueDto>({
  actionLabel: 'Tech Portal - Issues - Winterhalter Product Order Issue Detail',
  stateSelector: createSelector(selectIssuesState, state => state.winterhalterProductOrderIssueDetail),
  serviceType: WinterhalterProductOrderIssueService,
  entityType: AppEntityType.Issue,
  stereotyped: true
});

export function winterhalterProductOrderIssueDetailStoreReducer(state: WinterhalterProductOrderIssueDetailStoreState, action: Action): any {
  return winterhalterProductOrderIssueDetailStore.reducer(state, action);
}

@Injectable()
export class WinterhalterProductOrderIssueDetailStoreEffects extends winterhalterProductOrderIssueDetailStore.effects {
  constructor(
    protected injector: Injector
  ) {
    super(injector);
  }
}
