import {GeneralDataProtectionEntityType, TaskTypes} from "@nexnox-web/core-shared";

export const gdprEntityTypeEnumOptions =
[
  {
    label: 'core-portal.settings.gdpr.entity-types.1',
    value: GeneralDataProtectionEntityType.TaskJob
  },
  {
    label: 'core-portal.settings.gdpr.entity-types.2',
    value: GeneralDataProtectionEntityType.Chat
  }
];
