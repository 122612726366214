<ng-template #entityEditTemplate let-title="title">
  <nexnox-web-devices-device-edit #editComponent
                                  [title]="title"
                                  [model]="createModel$ | async"
                                  [readonly]="false"
                                  [creating]="true"
                                  [loading]="(loaded$ | async) === false"
                                  [stereotyped]="false"
                                  (modelChange)="createModel$.next($event)">
  </nexnox-web-devices-device-edit>
</ng-template>


<nexnox-web-static-toast [toastListSubject]="challengeToastsSubject"
                         (closeToast)="onCloseChallenge($event)">
</nexnox-web-static-toast>

<nexnox-web-entity-overview #overviewComponent
                            [entityOverviewBaseComponent]="this"
                            [entityEditTemplate]="entityEditTemplate"
                            [stereotyped]="false"
                            detailLink="/devices"
                            module="management">
</nexnox-web-entity-overview>
