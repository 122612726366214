import {NgModule} from '@angular/core';
import {notificationRulesServices} from './store';
import {CoreSharedModule} from '@nexnox-web/core-shared';
import {CorePortalEntityModule} from '@nexnox-web/core-portal';
import {NotificationRulesDetailComponent, NotificationRulesListComponent} from "./containers";

import {
  CorePortalNotificationRulesEditComponent,
  NotificationRulesRestrictionContractEditComponent,
  NotificationRulesRestrictionInfoEditComponent,
  NotificationRulesRestrictionMissionEditComponent,
  NotificationRulesRestrictionTicketEditComponent,
  NotificationRulesRestrictionWrapperComponent,
  NotificationRulesTargetEditComponent
} from "./components";
import {TechPortalMissionService, TechPortalTicketService} from "@nexnox-web/tech-portal-lib";
import {OrgaPortalFeatureInfoService} from "@nexnox-web/orga-portal/features/infos/src/lib/store";
import {OrgaPortalFeatureCategoryService} from "@nexnox-web/orga-portal/features/categories";

@NgModule({
  declarations: [
    CorePortalNotificationRulesEditComponent,
    NotificationRulesDetailComponent,
    NotificationRulesListComponent,
    NotificationRulesTargetEditComponent,
    NotificationRulesRestrictionMissionEditComponent,
    NotificationRulesRestrictionTicketEditComponent,
    NotificationRulesRestrictionContractEditComponent,
    NotificationRulesRestrictionInfoEditComponent,
    NotificationRulesRestrictionWrapperComponent
  ],
  imports: [
    CoreSharedModule,
    CorePortalEntityModule
  ],
  providers: [
    ...notificationRulesServices,
    TechPortalMissionService,
    TechPortalTicketService,
    OrgaPortalFeatureInfoService,
    OrgaPortalFeatureCategoryService
  ]
})
export class NotificationRulesDeclarationsModule {
}
