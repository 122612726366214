import {Router, RouterLinkActive} from '@angular/router';
import {
  ChangeDetectorRef,
  ContentChildren,
  Directive,
  ElementRef,
  Input,
  Optional,
  QueryList,
  Renderer2
} from '@angular/core';
import {
  CorePortalTenantRouterLinkDirective,
  CorePortalTenantRouterLinkWithHrefDirective
} from './tenant-router-link.directive';

@Directive({
  selector: '[nexnoxWebTenantRouterLinkActive]',
  exportAs: 'nexnoxWebTenantRouterLinkActive'
})
export class CorePortalTenantRouterLinkActiveDirective extends RouterLinkActive {
  @ContentChildren(CorePortalTenantRouterLinkDirective, {descendants: true})
  public links!: QueryList<CorePortalTenantRouterLinkDirective>;

  @ContentChildren(CorePortalTenantRouterLinkWithHrefDirective, {descendants: true})
  public linksWithHrefs!: QueryList<CorePortalTenantRouterLinkWithHrefDirective>;

  @Input('nexnoxWebTenantRouterLinkActive')
  public set routerLinkActive(data: string[] | string) {
    const classes = Array.isArray(data) ? data : data.split(' ');
    (this as any).classes = classes.filter(c => !!c);
  }

  constructor(
    router: Router,
    element: ElementRef,
    renderer: Renderer2,
    cdr: ChangeDetectorRef,
    @Optional() link?: CorePortalTenantRouterLinkDirective,
    @Optional() linkWithHref?: CorePortalTenantRouterLinkWithHrefDirective
  ) {
    super(router, element, renderer, cdr, link ?? linkWithHref);
  }
}
