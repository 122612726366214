import {Injectable, isDevMode} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {
  CoreSharedColorThemeService,
  CoreSharedLocalStorageService,
  ICorePortalHexColorTheme,
  IvyHelper,
  LocalStorageKeys,
  UnsubscribeHelper
} from '@nexnox-web/core-shared';
import {filter, map, mergeMap, startWith, take} from 'rxjs/operators';
import {BsLocaleService} from 'ngx-bootstrap/datepicker';
import {defineLocale, deLocale, enGbLocale} from 'ngx-bootstrap/chronos';
import dayjs from 'dayjs';
import de from 'dayjs/locale/de';
import en from 'dayjs/locale/en';
import {select, Store} from '@ngrx/store';
import {authStore, generalTenantSettingsStore, packagesStore} from '../../store';
import {CorePortalTenantLocalStorageService} from '../local-storage/local-storage.service';
import * as convert from 'color-convert';
import {isEmpty} from 'lodash';
import * as countries from 'i18n-iso-countries';
import {convertToParamMap, NavigationStart, Router} from '@angular/router';
import {CorePortalCurrentTenantService} from '../current-tenant/current-tenant.service';
import {CorePortalAuthService} from '../auth/auth.service';
import {CorePortalPermissionService} from '../permission/permission.service';
import {CorePortalAuthGuard} from '../../guards/auth/auth.guard';
import {PrimeNGConfig} from 'primeng/api';
import {firstValueFrom} from "rxjs";
import {LastTenantStorageDto} from "@nexnox-web/libs/core-portal/src";

@Injectable()
export class CorePortalBootstrapService extends UnsubscribeHelper {
  constructor(
    private translate: TranslateService,
    private bsLocaleService: BsLocaleService,
    private tenantLocalStorageService: CorePortalTenantLocalStorageService,
    private localStorageService: CoreSharedLocalStorageService,
    private colorThemeService: CoreSharedColorThemeService,
    private store: Store<any>,
    private router: Router,
    private currentTenantService: CorePortalCurrentTenantService,
    private authService: CorePortalAuthService,
    private permissionService: CorePortalPermissionService,
    private primengConfig: PrimeNGConfig
  ) {
    super();
  }

  public bootstrap(): void {
    this.bootstrapIvy();
    this.bootstrapI18n();
    this.bootstrapBootstrapLocales();
    this.bootstrapDayjsLocales();
    this.bootstrapCountries();
    this.bootstrapPrimeNG();
    this.bootstrapPermissions();
    this.bootstrapAuthenticationV3();
  }

  public bootstrapIvy(): void {
    if (isDevMode() && IvyHelper.isRunningIvy()) {
      console.log('Angular is running with Ivy enabled.');
    }
  }

  public async bootstrapI18n(): Promise<void> {
    const allowedLanguages = ['en-US', 'de-DE'];
    const defaultI18nLanguage = 'de-DE';
    const browserCultureLanguage = this.translate.getBrowserCultureLang();
    const browserLanguage = this.translate.getBrowserLang();
    let defaultLanguage = allowedLanguages.find(language =>
      language.includes(browserCultureLanguage) || language.includes(browserLanguage)
    );
    defaultLanguage = defaultLanguage ?? defaultI18nLanguage;

    this.translate.addLangs(allowedLanguages);
    this.translate.setDefaultLang(defaultI18nLanguage);

    this.store.pipe(
      select(generalTenantSettingsStore.selectors.selectEntity),
      filter(settings => Boolean(settings) && !isEmpty(settings)),
      take(1)
    ).subscribe(async settings => {
      const localLanguage = await this.tenantLocalStorageService.get(LocalStorageKeys.ACTIVE_LANGUAGE);
      defaultLanguage = allowedLanguages.find(language => language.includes(localLanguage)) ?? defaultLanguage;

      if (!localLanguage && settings.defaultLanguage?.value) {
        defaultLanguage = allowedLanguages.find(language => language.includes(settings.defaultLanguage.value)) ?? defaultLanguage;
        await this.tenantLocalStorageService.set(LocalStorageKeys.ACTIVE_LANGUAGE, defaultLanguage);
      }

      this.translate.use(defaultLanguage);
    });
  }

  public bootstrapBootstrapLocales(): void {
    defineLocale('en-uk', enGbLocale);
    defineLocale('en-us', enGbLocale);
    defineLocale('de-de', deLocale);

    this.subscribe(this.translate.onLangChange.asObservable().pipe(
      startWith({lang: this.translate.currentLang, translations: this.translate.translations})
    ), ({lang}) => this.bsLocaleService.use(lang));
  }

  public bootstrapDayjsLocales(): void {
    dayjs.locale('de-DE', de);
    dayjs.locale('en-US', en);

    this.subscribe(this.translate.onLangChange.asObservable().pipe(
      startWith({lang: this.translate.currentLang, translations: this.translate.translations})
    ), ({lang, translations}) => {
      dayjs.updateLocale(lang, this.translate.getParsedResult(translations, 'core-shared.shared.dayjs'));
      dayjs.locale(lang);
    });
  }

  public bootstrapCountries(): void {
    import('i18n-iso-countries/langs/de.json').then(countries.registerLocale);
    import('i18n-iso-countries/langs/en.json').then(countries.registerLocale);
  }

  public bootstrapPrimeNG(): void {
    this.subscribe(this.translate.stream('core-shared.shared.primeng'), primeng => this.primengConfig.setTranslation(primeng));
  }

  public bootstrapPermissions(): void {
    this.store.dispatch(packagesStore.actions.get());
  }

  public async bootstrapTheme(element: HTMLElement): Promise<void> {
    const activeTheme = await this.tenantLocalStorageService.get(LocalStorageKeys.ACTIVE_THEME);
    if (activeTheme) {
      this.setTheme(activeTheme, element);
    }

    this.subscribe(this.store.pipe(
      select(generalTenantSettingsStore.selectors.selectEntity)
    ), settings => {
      if (settings && settings.primaryColor && settings.primaryColor.value) {
        this.setTheme(settings.primaryColor.value, element);
      } else if (!activeTheme) {
        this.clearTheme(element);
      }
    });
  }

  public async bootstrapAuthenticationV3(): Promise<void> {

    // Get router event
    const routerEvent = (await firstValueFrom(this.router.events.pipe(
      filter(routerEvent => routerEvent instanceof NavigationStart),
      take(1)
    ))) as NavigationStart;

    // Check route, redirect to sign in if not logged in or not on no guard route
    if (CorePortalAuthGuard.notGuardedRoutes.some(route => routerEvent.url === route)) {
      this._handleAuthentication(routerEvent.url);
    }

    const lastRefreshToken = this.localStorageService.get(LocalStorageKeys.REFRESH_TOKEN);
    const lastTenant = this.localStorageService.get(LocalStorageKeys.LAST_TENANT) as LastTenantStorageDto;

    let selectedTenantDomain: string;

    if (routerEvent.url.startsWith('/tenant')) {
      // Get tenant domain from url
      selectedTenantDomain = this._getTenantFromUrl(routerEvent.url);
    } else {
      // Get tenant domain from local storage
      selectedTenantDomain = lastTenant?.domain;
    }

    if (lastRefreshToken) {
      // Refresh login with last tenant
      await this._refreshLoginV3(lastRefreshToken, lastTenant?.tenantId, routerEvent.url, selectedTenantDomain ?? undefined);
    } else {
      //
      this._handleAuthentication(routerEvent.url, false);
    }
  }

  private _refreshLoginV3(refreshToken: string, tenantId: number, currentUrl: string, selectedTenantDomain?: string): Promise<void> {

    return new Promise<void>(resolve => {
      this.authService.refreshLoginV3(refreshToken, tenantId).pipe(
        take(1),
        mergeMap(response => this.permissionService.checkAndMergeTokenPermissions(response.token).pipe(
          map(permissions => ({response, permissions}))
        ))
      ).toPromise()
        .then(async ({response: {token, restrictedRoleIds, info}, permissions}) => {
          const tenant = info.tenants.find(t => t.tenantId === tenantId);

          // If tenant exists, login success else log out
          if (tenant) {

            // If tenant.domain is different then selectedTenantDomain
            if (selectedTenantDomain && tenant.domain !== selectedTenantDomain) {
              let selectedTenant = info.tenants.find(t => t.domain === selectedTenantDomain) ?? undefined;

              // If selectedTenantDomain is not part of logged in MeDto switch to standard tenant and stay logged in
              if (!selectedTenant) {
                selectedTenant = info.tenants[0];
                // Change url and selectedTenantDomain to standard tenant
                let segments = this._getSegmentsFromUrl(currentUrl);
                segments = segments.map(s => s === selectedTenantDomain ? selectedTenant.domain : s);
                currentUrl = segments.join('/');
                selectedTenantDomain = selectedTenant.domain;
              }
              // Execute new login refresh with updated tenant
              return this._refreshLoginV3(refreshToken, selectedTenant.tenantId, currentUrl, selectedTenantDomain);
            }

            // Complete login and redirect
            this.store.dispatch(authStore.actions.loginSuccess(
              token,
              refreshToken,
              restrictedRoleIds,
              permissions,
              info,
              tenant,
              false
            ));
            this._handleAuthentication(currentUrl, true);
            return resolve();

          } else {
            this.localStorageService.remove(LocalStorageKeys.TENANT_SETTINGS);
            this.store.dispatch(authStore.actions.logout(true));
            return resolve();
          }
        })
        .catch(async error => {
          if (error.status === 403 && tenantId) {
            await this._refreshLoginV3(refreshToken, tenantId, currentUrl);
            return resolve();
          }

          this.store.dispatch(authStore.actions.logout(true));
          return resolve();
        });
    });
  }

  private _handleAuthentication(url: string, didLogin: boolean = false): void {
    url = this.router.serializeUrl({
      ...this.router.parseUrl(url),
      queryParams: {},
      queryParamMap: convertToParamMap({})
    });

    if (didLogin) {
      if (url === '/user/signin') {
        this.router.navigate(['/']).then();
      } else {
        // Redirect to route, save fragment
        const segments = this._getSegmentsFromUrl(url);
        const fragment = this._getFragmentFromUrl(url);
        this.router.navigate(segments, {fragment: fragment ? fragment : undefined}).then();
      }
    } else {
      if (url !== '/user/signin' && !CorePortalAuthGuard.notGuardedRoutes.some(route => url === route)) {
        this.router.navigate(['/user/signin']).then();
      }
    }
  }

  public setTheme(color: string, elementRef: HTMLElement): void {
    const hex = color;
    const rgb = convert.hex.rgb(hex);
    const hsl = convert.hex.hsl(hex);
    const theme: ICorePortalHexColorTheme = this.colorThemeService.getLightTheme(hex);

    // Primary color
    elementRef.style.setProperty('--primary', hex);
    elementRef.style.setProperty('--primary-rgb', rgb.toString());
    elementRef.style.setProperty('--primary-h', `${hsl[0]}`);
    elementRef.style.setProperty('--primary-s', `${hsl[1]}%`);
    elementRef.style.setProperty('--primary-l', `${hsl[2]}%`);
    elementRef.style.setProperty('--primary-light', theme.primaryLight);

    // Background color
    elementRef.style.setProperty('--content-background', theme.contentBackground);

    // Sidebar colors
    elementRef.style.setProperty('--sidebar-background-color-1', theme.sidebarBackground1);
    elementRef.style.setProperty('--sidebar-background-color-2', theme.sidebarBackground2);
    elementRef.style.setProperty('--sidebar-background-color-3', theme.sidebarBackground3);
    elementRef.style.setProperty('--sidebar-text-color-1', theme.sidebarTextColor1);
    elementRef.style.setProperty('--sidebar-text-color-2', theme.sidebarTextColor2);

    this.tenantLocalStorageService.set(LocalStorageKeys.ACTIVE_THEME, hex);
  }

  private clearTheme(elementRef: HTMLElement): void {
    elementRef.style.removeProperty('--primary');
    elementRef.style.removeProperty('--primary-rgb');
    elementRef.style.removeProperty('--primary-h');
    elementRef.style.removeProperty('--primary-s');
    elementRef.style.removeProperty('--primary-l');
  }

  private _getTenantFromUrl(url: string): string {
    const segments = this._getSegmentsFromUrl(url);
    const i = segments.findIndex(s => s === 'tenant');
    return segments[i + 1];
  }

  private _getSegmentsFromUrl(url: string): string[] {
    return url?.split('/')?.map(s => s.substring(0, s.indexOf('#') > 0 ? s.indexOf('#') : s.length)).filter(s => s !== '');
  }

  private _getFragmentFromUrl(url: string): string {
    return url?.split('/')?.find(s => s.indexOf('#') !== -1)?.split('#')[1] ?? undefined;
  }
}
