import {
  contractAuditStore,
  ContractAuditStoreState,
  contractDetailStore,
  ContractDetailStoreState,
  contractListStore,
  ContractListStoreState,
  contractTicketStore,
  ContractTicketStoreState
} from './stores';

export interface ContractsState {
  contractList: ContractListStoreState;
  contractDetail: ContractDetailStoreState;
  contractAudit: ContractAuditStoreState;
  contractTicket: ContractTicketStoreState
}

export const getInitialContractsState = (): ContractsState => ({
  contractList: contractListStore.getInitialState(),
  contractDetail: contractDetailStore.getInitialState(),
  contractAudit: contractAuditStore.getInitialState(),
  contractTicket: contractTicketStore.getInitialState()
});
