<nexnox-web-cardbox [title]="'core-portal.core.sidebar.menu.settings.labels' | translate"
                    [transparent]="true"
                    [isAccordion]="true"
                    [isCollapsed]="true"
                    [indicatorNum]="labels.length"
                    [isAutoExpandOnIndicator]="true"
                    paddingClasses="px-3 pb-3">
    <ng-container *ngIf="displayMode !== modes.EDIT else editTemplate">
        <div class="d-flex align-items-center flex-wrap gap-1">

      <!-- DISPLAY MODE -->
      <ng-container *ngIf="displayMode === modes.DISPLAY">
        <ng-container *ngFor="let label of labelSubject.asObservable() | async"
                      [ngTemplateOutlet]="labelTemplate"
                      [ngTemplateOutletContext]="{label: label}">
        </ng-container>
      </ng-container>

      <!-- AUTOMATIC MODE -->
      <div *ngIf="displayMode === modes.AUTOMATIC">
        <span>{{ 'core-portal.settings.descriptions.automatic-labeling' | translate }}</span>
      </div>

      <!-- EMPTY MODE -->
      <div *ngIf="displayMode === modes.EMPTY">
        <span>{{ 'core-shared.shared.table.empty' | translate }}</span>
      </div>

      <button #addButton
              *ngIf="displayMode !== modes.AUTOMATIC"
              type="button"
              pButton
              class="m-0 ml-2 p-button-text p-button-secondary p-button-rounded p-button p-button-icon-only"
              [icon]="faPlus | nexnoxWebFaIconStringPipe"
              [disabled]="loadingSubject | async"
              [loading]="loadingSubject | async"
              [title]="'core-portal.settings.actions.add-label' | translate"
              (click)="onChangeToEditMode()">
      </button>
    </div>
  </ng-container>
</nexnox-web-cardbox>

<!-- EDIT MODE -->
<ng-template #editTemplate>
  <div class="d-flex align-items-center flex-wrap gap-1">
    <ng-container *ngFor="let label of labelSubject.asObservable() | async"
                  [ngTemplateOutlet]="labelTemplate"
                  [ngTemplateOutletContext]="{label: label}">
    </ng-container>
    <div class="label-create">
      <nexnox-web-entity-select class="w-100"
                                [options]="labelCreateOptions"
                                [disabled]="loadingSubject | async"
                                [addTagFn]="saveLabelFn"
                                (modelChange)="onAddLabel($event)">
      </nexnox-web-entity-select>
    </div>
  </div>
</ng-template>

<!-- LABEL TEMPLATE -->
<ng-template #labelTemplate let-label="label">
  <div class="label">
    <span>{{label.content}}</span>
    <button type="button"
            pButton
            class="m-0 ml-2 p-button-text p-button-secondary p-button-rounded p-button p-button-icon-only"
            [icon]="faMinus | nexnoxWebFaIconStringPipe"
            [disabled]="loadingSubject | async"
            [loading]="loadingSubject | async"
            [title]="'core-portal.settings.actions.remove-label' | translate"
            (click)="onRemoveLabel(label)">
    </button>
  </div>
</ng-template>
