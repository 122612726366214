import {Injectable} from '@angular/core';
import {LocalStorageKeys, CoreSharedLocalStorageService} from '@nexnox-web/core-shared';
import {CorePortalLinkDto} from "@nexnox-web/core-portal";

export interface CorePortalEntityCreatePresets {
  [id: string]: any;
}

export interface ModelAdditionPreset {
  [key: string]: any;

  backlink?: CorePortalLinkDto;
  parentComponentId?: string; // The class name of the component, that created the preset
}

@Injectable()
export class CorePortalEntityCreatePresetService {
  constructor(
    protected localStorageService: CoreSharedLocalStorageService
  ) {
  }

  public getPreset(id: string): any {
    const createPresets: CorePortalEntityCreatePresets = this.localStorageService.get(LocalStorageKeys.CREATE_PRESETS);

    if (createPresets && createPresets[id]) {
      this.clearPreset(id);
      return createPresets[id];
    }

    return null;
  }

  public setPreset(id: string, preset: ModelAdditionPreset): void {
    let createPresets: CorePortalEntityCreatePresets = this.localStorageService.get(LocalStorageKeys.CREATE_PRESETS);

    if (!createPresets) {
      createPresets = {};
    }

    createPresets[id] = preset;
    this.localStorageService.set(LocalStorageKeys.CREATE_PRESETS, createPresets);
  }

  public clearPreset(id: string): void {
    this.setPreset(id, undefined);
  }
}
