import { ModuleWithProviders, NgModule } from '@angular/core';
import { ProcessesDeclarationsModule } from './processes-declarations.module';
import { ProcessesStoreModule } from './processes-store.module';
import { ProcessesRoutingModule } from './processes-routing.module';

@NgModule({
  imports: [
    ProcessesDeclarationsModule,
    ProcessesRoutingModule,
    ProcessesStoreModule
  ]
})
export class TechPortalFeatureTicketSettingsProcessesModule {
  public static forFeature(): ModuleWithProviders<ProcessesDeclarationsModule> {
    return { ngModule: ProcessesDeclarationsModule };
  }
}
