import { on } from '@ngrx/store';
import { PagedEntitiesXsStoreEntity, PagedEntitiesXsStoreState } from './paged-entities-xs-store.state';
import { EntityAdapter } from '@ngrx/entity';
import { cloneDeep } from 'lodash';
import { PagedEntitiesXsStoreActions } from './paged-entities-xs-store.actions';
import { PagedEntitiesXsStoreEntityData } from './models';
import dayjs from 'dayjs';
import { immerOn } from 'ngrx-immer/store';
import { BaseXsStoreReducerTypes } from '../base';

export const createPagedEntitiesXsStoreReducer = <E extends Record<any, any>, M extends Record<any, any>>(
  actions: PagedEntitiesXsStoreActions<E, M>,
  adapter: EntityAdapter<PagedEntitiesXsStoreEntity<E, M>>,
  initialState: PagedEntitiesXsStoreState<E, M>
): BaseXsStoreReducerTypes<PagedEntitiesXsStoreState<E, M>, PagedEntitiesXsStoreActions<E, M>>[] => ([
  immerOn(actions.getPage, draft => {
    draft.loading = true;
  }),

  on(actions.getPageSuccess, (state, { items, paging }) => {
    const mappedItems = items.map(item => ({
      entity: cloneDeep(item.entity),
      model: cloneDeep(item.model)
    }));
    return adapter.setAll(mappedItems, {
      ...state,
      paging,
      loading: false,
      loaded: true,
      loadedAt: dayjs().format('YYYY-MM-DDTHH:mm:ss'),
      hasError: false
    });
  }),

  immerOn(actions.appendPage, draft => {
    draft.appendLoading = true;
  }),

  on(actions.appendPageSuccess, (state, { items, paging }) => {
    const mappedItems = items.map(item => ({
      entity: cloneDeep(item.entity),
      model: cloneDeep(item.model)
    }));
    return adapter.addMany(mappedItems, {
      ...state,
      paging,
      appendLoading: false,
      hasError: false
    });
  }),

  immerOn(actions.getStereotypes, draft => {
    draft.stereotypesLoading = true;
  }),

  immerOn(actions.getStereotypesSuccess, (draft, { stereotypes }) => {
    draft.stereotypes = stereotypes;
    draft.stereotypesLoading = false;
    draft.stereotypesLoaded = true;
  }),

  immerOn(actions.createOne, draft => {
    draft.loading = true;
  }),

  on(actions.createOneSuccess, (state, { entity, model }) => adapter.addOne({
    entity: cloneDeep(entity),
    model: cloneDeep(model)
  }, {
    ...state,
    paging: {
      ...state.paging,
      totalItems: (state.paging?.totalItems ?? 0) + 1
    },
    loading: false,
    hasError: false
  })),

  immerOn(actions.addOneToParent, (draft, { id }) => {
    draft.entityData = pagedEntitiesXsStoreSetLoadingForId(draft.entityData, id, {
      [actions.addOneToParent.type]: true
    });
  }),

  on(actions.addOneToParentSuccess, (state, { id }) => adapter.updateOne({
    id: id.toString(),
    changes: {
      entity: { ...state.entities[id].entity, isInRelation: true } as any,
      model: { ...state.entities[id].model, isInRelation: true } as any
    }
  }, {
    ...state,
    entityData: pagedEntitiesXsStoreSetLoadingForId(state.entityData, id, {
      [actions.addOneToParent.type]: false
    }),
    hasError: false
  })),

  immerOn(actions.removeOneFromParent, (draft, { id }) => {
    draft.entityData = pagedEntitiesXsStoreSetLoadingForId(draft.entityData, id, {
      [actions.removeOneFromParent.type]: true
    });
  }),

  on(actions.removeOneFromParentSuccess, (state, { id }) => adapter.updateOne({
    id: id.toString(),
    changes: {
      entity: { ...state.entities[id].entity, isInRelation: false } as any,
      model: { ...state.entities[id].model, isInRelation: false } as any
    }
  }, {
    ...state,
    entityData: pagedEntitiesXsStoreSetLoadingForId(state.entityData, id, {
      [actions.removeOneFromParent.type]: false
    }),
    hasError: false
  })),

  immerOn(actions.deleteOne, (draft, { id }) => {
    draft.entityData = pagedEntitiesXsStoreSetLoadingForId(draft.entityData, id, {
      deleteOne: true
    });
  }),

  on(actions.deleteOneSuccess, (state, { id }) => adapter.removeOne(id, {
    ...state,
    paging: {
      ...state.paging,
      totalItems: (state.paging?.totalItems ?? 1) - 1
    },
    entityData: pagedEntitiesXsStoreSetLoadingForId(state.entityData, id, {
      deleteOne: false
    }),
    hasError: false
  })),

  immerOn(actions.error, draft => {
    draft.loading = false;
    draft.stereotypesLoading = false;
    draft.hasError = true;
    draft.entityData = {};
  }),

  immerOn(actions.setFilters, (draft, { filters, sortObject }) => {
    draft.filters = filters;
    draft.sortObject = sortObject;
  }),

  on(actions.clear, () => initialState)
]);

export function pagedEntitiesXsStoreSetLoadingForId(
  entityData: PagedEntitiesXsStoreEntityData,
  id: number | string,
  loading: { [key: string]: boolean }
): PagedEntitiesXsStoreEntityData {
  return {
    ...entityData,
    [id]: {
      ...entityData[id],
      loading: {
        ...entityData[id]?.loading ?? {},
        ...loading
      }
    }
  };
}
