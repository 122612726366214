import {
  BaseXsStore,
  BaseXsStoreReducerTypes,
  createPagedEntitiesXsStoreActions,
  PagedEntitiesXsStore,
  PagedEntitiesXsStoreEffects,
  pagedEntitiesXsStoreSetLoadingForId,
  PagedEntitiesXsStoreState
} from '@nexnox-web/core-store';
import {AppEntityType, CoreSharedApiBaseService, ResourceManufacturerListDto} from '@nexnox-web/core-shared';
import {
  ManufacturerListXsStoreActions,
  ManufacturerListXsStoreMergePayload
} from './manufacturer-list-xs-store.actions';
import {Action, createAction, on, props} from '@ngrx/store';
import {Injectable, Injector, Type} from '@angular/core';
import {createEffect, ofType} from '@ngrx/effects';
import {catchError, groupBy, map, mergeMap, switchMap, tap} from 'rxjs/operators';
import {of} from 'rxjs';
import {immerOn} from 'ngrx-immer/store';
import {CorePortalManufacturerService} from "../../services";

export interface ManufacturerListXsStoreState extends PagedEntitiesXsStoreState<ResourceManufacturerListDto> {
}

export class ManufacturerListXsStore extends PagedEntitiesXsStore<ResourceManufacturerListDto, ResourceManufacturerListDto, ManufacturerListXsStoreState> {
  public actions: ManufacturerListXsStoreActions;

  protected createActions(label: string): ManufacturerListXsStoreActions {
    return {
      ...createPagedEntitiesXsStoreActions(label),

      mergeOne: createAction(
        BaseXsStore.getType(label, 'Merge'),
        props<ManufacturerListXsStoreMergePayload>()
      ),
      mergeOneSuccess: createAction(
        BaseXsStore.getType(label, 'Merge success'),
        props<ManufacturerListXsStoreMergePayload>()
      )
    };
  }

  protected createReducerArray(
    initialState: ManufacturerListXsStoreState
  ): BaseXsStoreReducerTypes<ManufacturerListXsStoreState, ManufacturerListXsStoreActions>[] {
    return [
      ...super.createReducerArray(initialState),


      immerOn(this.actions.mergeOne, (draft, { manufacturer }) => {
        draft.entityData = pagedEntitiesXsStoreSetLoadingForId(draft.entityData, manufacturer.resourceManufacturerId, {
          deleteOne: true
        });
      }),

      on(this.actions.mergeOneSuccess, (state, { manufacturer }) =>
        this.adapter.removeOne(manufacturer.resourceManufacturerId?.toString(), {
        ...state,
        paging: {
          ...state.paging,
          totalItems: (state.paging?.totalItems ?? 1) - 1
        },
        entityData: pagedEntitiesXsStoreSetLoadingForId(state.entityData, manufacturer.resourceManufacturerId, {
          deleteOne: false
        }),
        hasError: false
      })),
    ];
  }

  protected createEffects(
    serviceType: Type<CoreSharedApiBaseService>,
    entityType: AppEntityType,
    prepareEntity: (entity: ResourceManufacturerListDto) => ResourceManufacturerListDto,
    prepareModel: (entity: ResourceManufacturerListDto, model: ResourceManufacturerListDto) => ResourceManufacturerListDto,
    sanitizeModel: (model: ResourceManufacturerListDto, entity: ResourceManufacturerListDto) => ResourceManufacturerListDto,
    ...args
  ): Type<PagedEntitiesXsStoreEffects<ResourceManufacturerListDto, ResourceManufacturerListDto, ManufacturerListXsStoreState>> {
    const actions = this.actions;
    const selectors = this.selectors;

    @Injectable()
    class Effects extends PagedEntitiesXsStoreEffects<ResourceManufacturerListDto> {
      public mergeOne$: any;
      public mergeOneSuccess$: any;

      protected actions: ManufacturerListXsStoreActions;
      protected service: CorePortalManufacturerService;

      constructor(
        protected injector: Injector
      ) {
        super(injector, actions, selectors, serviceType, entityType, prepareEntity, prepareModel, sanitizeModel, false);
      }

      protected createEffects(): void {
        super.createEffects();

        this.mergeOne$ = createEffect(() => this.actions$.pipe(
          ofType(this.actions.mergeOne),
          groupBy(({manufacturer}) => manufacturer.resourceManufacturerId),
          mergeMap(group => group.pipe(
            switchMap(({
                         manufacturer,
                         newManufacturerId
                       }) => this.service.mergeManufacturer(manufacturer, newManufacturerId).pipe(
              map(() => this.actions.mergeOneSuccess({manufacturer, newManufacturerId})),
              catchError(error => of(this.actions.error({error, action: this.actions.mergeOne})))
            ))
          ))
        ));

        this.mergeOneSuccess$ = createEffect(() => this.actions$.pipe(
          ofType(this.actions.mergeOneSuccess),
          tap(action => this.actionCallback(action, false))
        ), {dispatch: false});

      }

      protected actionCallback(action: Action, isError: boolean = false): void {
        super.actionCallback(action, isError);

        this.checkAction(this.actions.mergeOneSuccess, action, () => this.mergeOneSuccess());
      }

      protected mergeOneSuccess(): void {
        this.apiNotificationService.showTranslatedSuccess('core-portal.settings.toasts.resource-manufacturer.merge-success');
      }
    }

    return Effects;
  }
}
