import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { SnapElement, SnapPaper } from './models';
import { UuidService } from '../../services';

@Component({
  selector: 'nexnox-web-snap',
  templateUrl: './snap.component.html'
})
export class SnapComponent implements OnInit, AfterViewInit {
  @Input() public width = '100%';
  @Input() public height = '100%';

  public svgId: string;

  private snapPaper: SnapPaper;

  constructor(
    private uuidService: UuidService
  ) {
  }

  public ngOnInit(): void {
    this.svgId = `svg-${this.uuidService.generateUuid()}`;
  }

  public ngAfterViewInit(): void {
    this.snapPaper = new SnapPaper(`#${this.svgId}`);
  }

  public addElement<T extends SnapElement>(snapElement: T): T {
    return this.snapPaper.addElement<T>(snapElement);
  }

  public removeElement<T extends SnapElement>(snapElement: T): void {
    this.snapPaper.removeElement<T>(snapElement);
  }

  public clear(): void {
    this.snapPaper.clear();
  }

  public getSnapPaper(): SnapPaper {
    return this.snapPaper;
  }
}
