import { ChangeDetectionStrategy, Component, Injector } from '@angular/core';
import { ActionButton, CorePortalEntityDetailBaseComponent } from '@nexnox-web/core-portal';
import { AppPermissions, CategoryDto } from '@nexnox-web/core-shared';
import { categoryDetailStore } from '../../store';

@Component({
  selector: 'nexnox-web-categories-category-detail',
  templateUrl: './category-detail.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CategoryDetailComponent extends CorePortalEntityDetailBaseComponent<CategoryDto> {
  public title = 'orga-portal.categories.subtitles.category-detail';

  protected idParam = 'categoryId';
  protected displayKey = 'name';

  constructor(
    protected injector: Injector
  ) {
    super(injector, categoryDetailStore);
  }

  protected async getActionButtons(): Promise<ActionButton[]> {
    return this.getDefaultActionButtons(
      'orga-portal.categories.actions.edit-category',
      'orga-portal.categories.actions.save-category',
      'orga-portal.categories.actions.delete-category',
      'orga-portal.categories.descriptions.delete-category',
      AppPermissions.UpdateCategory,
      AppPermissions.DeleteCategory,
      ['/categories']
    );
  }
}
