import {
  locationAddressDetailStore,
  locationAddressListStore,
  LocationAddressListStoreEffects,
  LocationAuditListStoreEffects,
  locationAuditListStoreReducer,
  locationContactListStore,
  locationContractListStore,
  locationDetailStore,
  LocationDetailStoreEffects,
  locationIssueListStore,
  LocationListStoreEffects,
  locationListStoreReducer,
  locationLocationGroupListStore,
  locationMissionListStore,
  LocationResourceListStoreEffects,
  locationResourceListStoreReducer,
  locationTaskActiveListStore,
  locationTaskArchiveListStore,
  locationTaskListStore,
  locationTicketListStore
} from './stores';
import {
  CorePortalFeatureLocationResourceService,
  CorePortalFeatureMasterDataLocationService,
  LocationAddressDetailService,
  LocationAddressService,
  LocationContactService,
  LocationContractService,
  LocationIssueService,
  LocationLocationGroupService,
  LocationMissionService,
  LocationTaskjobArchiveService,
  LocationTaskjobService,
  LocationTaskService,
  LocationTicketService
} from './services';

export * from './stores';
export * from './services';

export * from './locations.state';
export * from './locations.selectors';

export const locationsReducers = {
  locationList: locationListStoreReducer,
  locationDetail: locationDetailStore.reducer,
  locationAuditList: locationAuditListStoreReducer,

  locationAddressList: locationAddressListStore.reducer,
  locationResourceList: locationResourceListStoreReducer,
  locationIssueList: locationIssueListStore.reducer,
  locationMissionList: locationMissionListStore.reducer,
  locationTaskList: locationTaskListStore.reducer,
  locationContractList: locationContractListStore.reducer,
  locationContactList: locationContactListStore.reducer,
  locationTicketList: locationTicketListStore.reducer,
  locationLocationGroupList: locationLocationGroupListStore.reducer,
  locationTaskArchiveList: locationTaskArchiveListStore.reducer,
  locationTaskActiveList: locationTaskActiveListStore.reducer,
  locationAddressDetail: locationAddressDetailStore.reducer
};

export const locationsEffects = [
  LocationListStoreEffects,
  LocationDetailStoreEffects,
  LocationAuditListStoreEffects,

  LocationAddressListStoreEffects,
  LocationResourceListStoreEffects,
  locationIssueListStore.effects,
  locationMissionListStore.effects,
  locationTaskListStore.effects,
  locationContractListStore.effects,
  locationContactListStore.effects,
  locationTicketListStore.effects,
  locationTaskArchiveListStore.effects,
  locationTaskActiveListStore.effects,
  locationLocationGroupListStore.effects,
  locationAddressDetailStore.effects
];

export const locationsServices = [
  CorePortalFeatureMasterDataLocationService,
  LocationAddressService,
  CorePortalFeatureLocationResourceService,
  LocationIssueService,
  LocationMissionService,
  LocationTaskService,
  LocationContractService,
  LocationContactService,
  LocationTicketService,
  LocationTaskjobService,
  LocationTaskjobArchiveService,
  LocationLocationGroupService,
  LocationAddressDetailService
];
