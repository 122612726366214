import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './containers';

export const CORE_PORTAL_DASHBOARD_ROUTES: Routes = [
  {
    path: '',
    component: DashboardComponent
  },

  {
    path: 'tickets',
    loadChildren: () => import('@nexnox-web/tech-portal/features/tickets').then(m => m.TechPortalFeatureTicketsModule),
    data: {
      componentConfig: {
        allowList: false
      }
    }
  },
  {
    path: 'contacts',
    loadChildren: () => import('@nexnox-web/core-portal/features/master-data/features/contacts')
      .then(m => m.CorePortalFeatureMasterDataContactsModule),
    data: {
      componentConfig: {
        allowList: false
      }
    }
  },
  {
    path: 'issues',
    loadChildren: () => import('@nexnox-web/tech-portal/features/issues').then(m => m.TechPortalFeatureIssuesModule)
  },
  {
    path: 'resources',
    loadChildren: () => import('@nexnox-web/tech-portal/features/resources').then(m => m.TechPortalFeatureResourcesModule)
  },
  {
    path: 'missions',
    loadChildren: () => import('@nexnox-web/tech-portal/features/missions').then(m => m.TechPortalFeatureMissionsModule),
    data: {
      componentConfig: {
        allowList: false
      }
    }
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(CORE_PORTAL_DASHBOARD_ROUTES)
  ],
  exports: [
    RouterModule
  ]
})
export class DashboardRoutingModule {
}
