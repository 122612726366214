import { interval, Observable, of } from 'rxjs';
import { filter, map, mergeMap, scan, take } from 'rxjs/operators';
import { QueryList } from '@angular/core';

export class ViewChildrenHelper {
  public static untilViewChildLoaded$<T>(component: T, viewChild: keyof T): Observable<boolean> {
    if (component[viewChild]) {
      return of(true);
    }

    return interval(500).pipe(
      filter(() => Boolean(component[viewChild])),
      map(() => true),
      take(1)
    );
  }

  public static untilViewChildrenLoaded$<T>(viewChildren: QueryList<T>): Observable<T> {
    return of(viewChildren).pipe(
      filter(viewChildrenInner => Boolean(viewChildren)),
      mergeMap(viewChildrenInner => {
        if (viewChildrenInner.length > 0) {
          return of(viewChildrenInner).pipe(
            take(1),
            map(children => children.toArray()),
            mergeMap(child => child)
          );
        } else {
          return viewChildren.changes.pipe(
            filter(children => children.length > 0),
            take(1),
            map(() => viewChildren.toArray()),
            mergeMap(child => child)
          );
        }
      })
    );
  }

  public static untilAllViewChildrenLoaded$<T>(viewChildren: QueryList<T>, compareLength: number): Observable<T[]> {
    return this.untilViewChildrenLoaded$(viewChildren).pipe(
      scan((all, current) => [...all, current], []),
      filter(all => all.length === compareLength)
    );
  }
}
