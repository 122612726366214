import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, UrlTree } from '@angular/router';
import { forkJoin, Observable, of } from 'rxjs';
import { CorePortalPermissionService } from '../../services';
import { ApiNotificationService, AppPermissions } from '@nexnox-web/core-shared';
import { filter, map, mergeMap, take } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { authStore } from '../../store/core/auth';
import { CorePortalTenantRouter } from '../../router-overrides';

@Injectable()
export class CorePortalPermissionGuard implements CanActivate {
  constructor(
    private tenantRouter: CorePortalTenantRouter,
    private store: Store<any>,
    private permissionService: CorePortalPermissionService,
    private apiNotificationService: ApiNotificationService
  ) {
  }

  public canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    const permissions: AppPermissions[] = route.data.permissions;

    if (!permissions?.length) {
      return of(true);
    }

    const hasPermissions$ = permissions.map(permission => this.permissionService.hasPermission$(permission).pipe(take(1)));
    return this.store.pipe(
      select(authStore.selectors.selectActiveTenant),
      filter(activeTenant => Boolean(activeTenant)),
      take(1),
      mergeMap(() => forkJoin(hasPermissions$).pipe(
        map(hasPermissions => {
          const hasPermission = hasPermissions.every(permission => permission);

          if (!hasPermission) {
            this.apiNotificationService.showTranslatedError('core-shared.shared.toast.missing-permissions');
            this.tenantRouter.navigate(['/'], { withoutModule: Boolean(route.routeConfig?.data?.module) });
            return false;
          }

          return true;
        })
      ))
    );
  }
}
