import {PagedEntitiesXsStore, PagedEntitiesXsStoreState} from '@nexnox-web/core-store';
import {AppEntityType, OccurredErrorProtocolListDto} from '@nexnox-web/core-shared';
import {createSelector} from '@ngrx/store';
import {selectResourcesState} from '../../resources.selectors';
import {TechPortalFeatureResourceControlProtocolErrorsService} from '../../services';

export interface ResourceControlProtocolErrorsStoreState extends PagedEntitiesXsStoreState<OccurredErrorProtocolListDto> {
}

/* istanbul ignore next */
export const resourceControlProtocolErrorsStore = new PagedEntitiesXsStore<OccurredErrorProtocolListDto>({
  actionLabel: 'Tech Portal - Resources - Resource Control Protocol Errors',
  stateSelector: createSelector(selectResourcesState, state => state.resourceControlProtocolErrors),
  entityType: AppEntityType.None,
  serviceType: TechPortalFeatureResourceControlProtocolErrorsService,
  selectId: error => error.entity?.occurredConnectedErrorHistoryId ?? error.model?.occurredConnectedErrorHistoryId
});
