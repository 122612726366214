<div *ngIf="!loading; else loadingTemplate"
     [class]="'cardbox w-100 ' + classes"
     [class.overflow-auto]="overflowAuto"
     [class.mb-0]="noMarginBottom"
     [class.shadow-margin-bottom]="shadowMarginBottom"
     [class.w-100]="fullWidth"
     [class.fullheight-container]="fullHeight"
     [class.is-transparent]="transparent"
     [class.is-border-bottom]="!isCollapsed && isAccordion"
     [class.is-accordion]="isAccordion">
  <ng-container *ngIf="!noTitle">
    <div class="d-flex flex-row cardbox-header"
         [class.align-items-center]="!alignTitleStart"
         [class.align-items-start]="alignTitleStart"
         [class.header-accent]="withAccent"
         [class.p-3]="!isTitleEditable"
         [class.py-1]="isTitleEditable"
         [style.border-left-color]="customAccent ? customAccent : undefined">
      <div class="d-flex flex-shrink-1 flex-grow-1 overflow-hidden"
           [class.flex-wrap]="wrapTitle">
        <h5 *ngIf="prependTitle"
            (click)="toggleCollapsed()"
            class="d-flex align-items-center mb-0 text-muted"
            [class.mr-2]="!isTitleEditable"
            [class.pl-3]="isTitleEditable"
            [class.pointer]="isAccordion">
          {{ prependTitle }}
        </h5>

        <h5 *ngIf="!isTitleEditable; else editableTitleTemplate"
            class="d-flex align-items-center flex-grow-1 mb-0"
            (click)="toggleCollapsed()"
            [class.pointer]="isAccordion">
          <span class="align-items-center fade-text">
            <fa-icon *ngIf="icon"
                     [icon]="icon"
                     [size]="iconSize"
                     class="mr-2"
                     [style.color]="iconColor">
            </fa-icon>
            {{ title }}
            <span *ngIf="(indicator$ | async) as indicator">
              ({{ indicator }})
            </span>
          </span>
          <p-badge *ngIf="showBadge && badgeTitle"
                   [value]="badgeTitle"
                   [styleClass]="'whitespace-nowrap align-self-center ' + badgeStyleClasses">
          </p-badge>
        </h5>

        <ng-template #editableTitleTemplate>
          <form class="w-100 py-1"
                [formGroup]="titleForm">
            <formly-form class="row"
                         [form]="titleForm"
                         [model]="titleModel"
                         [fields]="titleFields"
                         (modelChange)="onTitleChange($event)">
            </formly-form>
          </form>
        </ng-template>

        <ng-container *ngIf="appendTitleTemplate"
                      [ngTemplateOutlet]="appendTitleTemplate"></ng-container>
      </div>

      <div *ngIf="headerActions?.length"
           class="d-flex flex-shrink-0 ml-2"
           [class.mr-3]="isTitleEditable">
        <nexnox-web-cardbox-actions [actions]="headerActions"
                                    [data]="actionsData"></nexnox-web-cardbox-actions>
      </div>

      <div class="cardbox-accordion-btn ml-2"
           *ngIf="isAccordion">
        <button *ngIf="isCollapsed"
                pButton
                pRipple
                type="button"
                class="p-button-text p-button-secondary p-button-minimal py-0 px-2"
                [icon]="faChevronDown | nexnoxWebFaIconStringPipe"
                (click)="toggleCollapsed()">
        </button>
        <button *ngIf="!isCollapsed"
                pButton
                pRipple
                type="button"
                class="p-button-text p-button-secondary p-button-minimal py-0 px-2"
                [icon]="faChevronUp | nexnoxWebFaIconStringPipe"
                (click)="toggleCollapsed()">
        </button>
      </div>
    </div>

    <hr class="mt-0 w-100"
        [class.mb-0]="noDividerMarginBottom || isCollapsed">
  </ng-container>

  <ng-container *ngIf="!isCollapsed">

    <div class="cardbox-content"
         [class]="paddingClasses"
         [class.w-100]="fullWidth"
         [class.fullheight-content]="fullHeight">
      <ng-content></ng-content>
    </div>

    <ng-container *ngIf="footerActions?.length">
      <hr class="my-0">

      <div class="d-flex flex-row align-items-center justify-content-end p-3">
        <nexnox-web-cardbox-actions [actions]="footerActions"
                                    [data]="actionsData"></nexnox-web-cardbox-actions>
      </div>
    </ng-container>

  </ng-container>
</div>

<ng-template #loadingTemplate>
  <nexnox-web-cardbox-ghost [noMarginBottom]="noMarginBottom"
                            [noDividerMarginBottom]="noDividerMarginBottom"
                            [fullWidth]="fullWidth"
                            [fullHeight]="fullHeight"
                            [paddingClasses]="ghostPaddingClasses ?? paddingClasses"
                            [transparent]="transparent">
  </nexnox-web-cardbox-ghost>
</ng-template>
