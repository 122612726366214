import { EntityState, IdSelector } from '@ngrx/entity/src/models';
import { isDevMode } from '@angular/core';
import { cloneDeep } from 'lodash';

export enum DidMutate {
  None,
  Both,
  EntitiesOnly
}

export function selectIdValue<T>(entity: T, selectId: IdSelector<T>): string | number {
  const key = selectId(entity);

  if (isDevMode() && key === undefined) {
    console.warn(
      '@ngrx/entity: The entity passed to the `selectId` implementation returned undefined.',
      'You should probably provide your own `selectId` implementation.',
      'The entity that was passed:',
      entity,
      'The `selectId` implementation:',
      selectId.toString()
    );
  }

  return key;
}

export function createStateOperator<EntityType, StateType extends EntityState<EntityType>>(
  mutator: (
    args: any,
    state: StateType
  ) => DidMutate
): any {
  return function operation(args: any, state: StateType): StateType {
    const clonedEntityState: StateType = cloneDeep(state);
    const didMutate = mutator(args, clonedEntityState);

    switch (didMutate) {
      case DidMutate.Both:
        return Object.assign({}, state, clonedEntityState);
      case DidMutate.EntitiesOnly:
        return {
          ...state,
          entities: clonedEntityState.entities
        };
      default:
        return state;
    }
  };
}
