import {
  BaseXsStoreReducerTypes,
  PagedEntitiesXsStore,
  PagedEntitiesXsStoreActions,
  PagedEntitiesXsStoreEffects,
  PagedEntitiesXsStoreState
} from '@nexnox-web/core-store';
import {AppEntityType, MessageIssueListDto} from '@nexnox-web/core-shared';
import {Action} from '@ngrx/store';
import {Injectable, Injector, Type} from '@angular/core';
import {MessageIssueService} from "@nexnox-web/tech-portal/features/issues/src/lib/store";
// import {TechPortalIssueTemplateService} from '@nexnox-web/tech-portal-lib';

export interface MessageIssueListXsStoreActions extends PagedEntitiesXsStoreActions<MessageIssueListDto> {
}

export class MessageIssueListXsStore extends PagedEntitiesXsStore<MessageIssueListDto> {
  public actions: MessageIssueListXsStoreActions;

  protected createActions(label: string): MessageIssueListXsStoreActions {
    return {
      ...super.createActions(label)
    };
  }

  protected createReducerArray(
    initialState: PagedEntitiesXsStoreState<MessageIssueListDto>
  ): BaseXsStoreReducerTypes<PagedEntitiesXsStoreState<MessageIssueListDto>, MessageIssueListXsStoreActions>[] {
    return [
      ...super.createReducerArray(initialState)
    ];
  }

  protected createEffects(
    serviceType: Type<MessageIssueService>,
    entityType: AppEntityType,
    prepareEntity: (entity: MessageIssueListDto) => MessageIssueListDto,
    prepareModel: (entity: MessageIssueListDto, model: MessageIssueListDto) => MessageIssueListDto,
    sanitizeModel: (model: MessageIssueListDto, entity: MessageIssueListDto) => MessageIssueListDto,
    ...args
  ): Type<PagedEntitiesXsStoreEffects<MessageIssueListDto>> {
    const actions = this.actions;
    const selectors = this.selectors;

    @Injectable()
    class Effects extends PagedEntitiesXsStoreEffects<MessageIssueListDto> {
      protected service: MessageIssueService;

      constructor(
        protected injector: Injector
      ) {
        super(injector, actions, selectors, serviceType, entityType, prepareEntity, prepareModel, sanitizeModel, true);
      }

      protected createEffects(): void {
        super.createEffects();
      }

      protected actionCallback(action: Action, isError: boolean = false): void {
        super.actionCallback(action, isError);
      }
    }

    return Effects;
  }
}
