import {PagedEntitiesXsStore, PagedEntitiesXsStoreState} from '@nexnox-web/core-store';
import {AppEntityType, ProductSubscriptionDto} from '@nexnox-web/core-shared';
import {createSelector} from '@ngrx/store';
import {selectCoreState} from '../core.selectors';
import {ProductSubscriptionService} from "../../../../../features/service/src/lib/tenants/store";

export interface GeneralTenantSettingsSubscriptionsStoreState extends PagedEntitiesXsStoreState<ProductSubscriptionDto> {
}

export const generalTenantSettingsSubscriptionsStore = new PagedEntitiesXsStore<ProductSubscriptionDto>({
  actionLabel: 'Core Portal - Core - General Tenant Settings Subscriptions List',
  stateSelector: createSelector(selectCoreState, state => state.generalTenantSettingsSubscriptions),
  serviceType: ProductSubscriptionService,
  entityType: AppEntityType.None,
  selectId: sub => sub.entity?.productSubscriptionId ?? sub.model?.productSubscriptionId,
  stereotyped: false,
});
