import {CoreSharedApiBaseService, Product} from '@nexnox-web/core-shared';
import {Injectable, Injector} from '@angular/core';

@Injectable()
export class InfoProgressContactService extends CoreSharedApiBaseService {

  constructor(
    protected injector: Injector
  ) {
    super(injector, Product.ORGA, 'contact', ['v2/infoprogress']);
  }

}

