import { InjectionToken, NgModule } from '@angular/core';
import { ActionReducerMap, StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { tasksEffects, tasksReducers } from './store';

export const REDUCER_TOKEN = new InjectionToken<ActionReducerMap<any>>(
  '@nexnox-web/orga-portal/tasks: Store Reducers'
);

@NgModule({
  imports: [
    StoreModule.forFeature('orga-portal-feature-tasks', REDUCER_TOKEN),
    EffectsModule.forFeature(tasksEffects)
  ],
  providers: [
    {
      provide: REDUCER_TOKEN,
      useValue: tasksReducers
    }
  ]
})
export class TasksStoreModule {
}
