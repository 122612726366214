import {ChangeDetectionStrategy, Component, Injector, Input, OnInit} from '@angular/core';
import {CoreSharedSidebarBaseComponent, StateDto} from '@nexnox-web/core-shared';
import {FormGroup} from "@angular/forms";
import {FormlyFieldConfig} from "@ngx-formly/core";
import {distinctUntilChanged, filter, map, mergeMap} from "rxjs/operators";
import {TicketStatemachineService} from "./../../store";
import {BehaviorSubject, Observable} from "rxjs";
import {CorePortalFormlyNgSelectOption, CorePortalFormlyReadonlyTyping} from "@nexnox-web/core-portal";
import {faExclamationTriangle} from "@fortawesome/free-solid-svg-icons/faExclamationTriangle";
import {faTimes} from "@fortawesome/free-solid-svg-icons/faTimes";
import {faExchangeAlt} from "@fortawesome/free-solid-svg-icons/faExchangeAlt";
import {Store} from "@ngrx/store";
import {
  FormlyReadonlyTypes
} from "@nexnox-web/core-portal/features/settings/features/stereotypes/src/lib/components/custom-property-edit/custom-property.templates";
import {TechPortalFeatureDashboardTicketActionsFacade} from "../../facades";

@Component({
  selector: 'nexnox-web-ticket-state-change-sidebar',
  templateUrl: './ticket-state-change-sidebar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TicketStateChangeSidebarComponent extends CoreSharedSidebarBaseComponent implements OnInit {

  @Input() public ticketId: number;
  @Input() public stateMachineId$: Observable<number>;
  @Input() public ticketState: StateDto;
  @Input() public ticketActionFacade: TechPortalFeatureDashboardTicketActionsFacade | any;

  public store: Store;

  public availableStatesSubject: BehaviorSubject<CorePortalFormlyNgSelectOption[]> = new BehaviorSubject<CorePortalFormlyNgSelectOption[]>([]);
  public loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public form: FormGroup;
  public model: { reason: string; state?: StateDto };
  public fields: FormlyFieldConfig[];

  public faExclamationTriangle = faExclamationTriangle;
  public faTimes = faTimes;
  public faExchangeAlt = faExchangeAlt;

  public isForceState: boolean;
  public defaultState: any;

  constructor(
    protected injector: Injector,
    private ticketStateMachineService: TicketStatemachineService
  ) {
    super();
    this.store = injector.get(Store) as Store<any>;
  }

  public ngOnInit(): void {
    this.isForceState = this.stateMachineId$ && !Boolean(this.ticketState);
    this.model = {} as any;

    if (this.isForceState && this.stateMachineId$) {
      this.subscribe(
        this.stateMachineId$.pipe(
          filter(stateMachineId => stateMachineId >= 1),
          distinctUntilChanged(),
          mergeMap(stateMachineId => this.ticketStateMachineService.getPage(undefined, 1, undefined, undefined, undefined, undefined, 100, [stateMachineId]).pipe(
              map(states => states.items?.map((state: StateDto) => ({
                label: state.name,
                readonlyLabel: state.name,
                value: state
              })))
            )
          )
        ), items => this.availableStatesSubject.next(items)
      );
      this.defaultState = undefined;
    }

    if (!this.isForceState && this.ticketState) {
      const state = {label: this.ticketState?.name, value: this.ticketState, readonlyLabel: this.ticketState?.name};
      this.availableStatesSubject.next([state]);
      this.defaultState = state;
      this.model = {state: this.ticketState, reason: undefined};
    }

    this.form = new FormGroup({});
    this.fields = this.createForm();
  }

  public onShow(): void {
    this.ngOnInit();
    super.onShow();
  }

  public onHide(): void {
    this.ticketState = undefined;
    this.stateMachineId$ = undefined;
    super.onHide();
  }

  public onChangeState(): void {
    this.ticketActionFacade.changeState({
      id: this.ticketId,
      state: this.model.state as StateDto,
      reason: this.model.reason
    });
    this.onHide();
  }

  /* istanbul ignore next */
  protected createForm(): FormlyFieldConfig[] {
    return [
      {
        key: 'state',
        type: 'core-portal-ng-select',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-12',
        defaultValue: this.defaultState,
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.change-state-to',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          },
          corePortalReadonly: {
            type: FormlyReadonlyTypes.ENUM,
            enumOptions: this.availableStatesSubject.getValue(),
            translate: false
          } as CorePortalFormlyReadonlyTyping,
          corePortalNgSelect: {
            items$: this.availableStatesSubject.asObservable(),
          },
          required: true,
        },
        expressionProperties: {
          'templateOptions.readonly': () => !this.isForceState
        }
      },

      {
        key: 'reason',
        type: 'input',
        wrappers: ['core-portal-translated'],
        className: 'col-md-12',
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.reason',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          },
          required: false
        },
      }
    ];
  }
}
