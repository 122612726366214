import {ModuleWithProviders, NgModule} from "@angular/core";
import {NotificationRulesDeclarationsModule} from "./notification-rules-declarations.module";
import {NotificationRulesRoutingModule} from "./notification-rules-routing.module";
import {NotificationRulesStoreModule} from "./notification-rules-store.module";


@NgModule({
  imports: [
    NotificationRulesDeclarationsModule,
    NotificationRulesRoutingModule,
    NotificationRulesStoreModule
  ]
})
export class NotificationRulesModule {
  public static forFeature(): ModuleWithProviders<NotificationRulesDeclarationsModule> {
    return {ngModule: NotificationRulesDeclarationsModule};
  }
}
