import {
  deviceDetailStore,
  DeviceDetailStoreState,
  deviceListStore,
  DeviceListStoreState,
  deviceMetadataListStore,
  DeviceMetadataListStoreState,
  devicePingListStore,
  DevicePingListStoreState
} from './stores';

export interface DevicesState {
  deviceList: DeviceListStoreState;
  deviceDetail: DeviceDetailStoreState;
  devicePingList: DevicePingListStoreState;
  deviceMetadata: DeviceMetadataListStoreState;
}

export const getInitialDevicesState = (): DevicesState => ({
  deviceList: deviceListStore.getInitialState(),
  deviceDetail: deviceDetailStore.getInitialState(),
  devicePingList: devicePingListStore.getInitialState(),
  deviceMetadata: deviceMetadataListStore.getInitialState()
});
