import { EntityXsStore } from '@nexnox-web/core-store';
import { AppEntityType, ControlPointDto } from '@nexnox-web/core-shared';
import { createSelector } from '@ngrx/store';
import { selectControlPointsState } from '../../control-points.selectors';
import { CorePortalCCPControlPointService } from '@nexnox-web/core-portal';

/* istanbul ignore next */
export const controlPointDetailStore = new EntityXsStore<ControlPointDto>({
  actionLabel: 'Tech Portal - CCP - Control Points - Detail',
  stateSelector: createSelector(selectControlPointsState, state => state.detail),
  serviceType: CorePortalCCPControlPointService,
  entityType: AppEntityType.None,
  stereotyped: false
});
