import {PagedEntitiesXsStore, PagedEntitiesXsStoreState} from '@nexnox-web/core-store';
import {
  AppEntityType,
  GeneralDataProtectionRegulationListDto,
  GeneralDataProtectionRestrictionType
} from '@nexnox-web/core-shared';
import {Action, createSelector} from '@ngrx/store';
import {selectGdprState} from '../../gdpr.selectors';
import {CorePortalGdprService} from '../../services';
import {Injectable, Injector} from '@angular/core';
import {sanitizeToRegulationDto} from "./../gdpr-detail/gdpr-detail.store";

export interface GdprListStoreState extends PagedEntitiesXsStoreState<GeneralDataProtectionRegulationListDto> {
}

/* istanbul ignore next */
export const gdprListStore = new PagedEntitiesXsStore<GeneralDataProtectionRegulationListDto>({
  actionLabel: 'Core Portal - Settings - GDPR - GDPR List',
  stateSelector: createSelector(selectGdprState, state => state.gdprList),
  serviceType: CorePortalGdprService,
  entityType: AppEntityType.None,
  stereotyped: false,
  selectId: gdpr => gdpr.entity?.generalDataProtectionRegulationId ?? gdpr.model?.generalDataProtectionRegulationId,
  sanitizeModel: (model, entity, base) => {
    return sanitizeToRegulationDto(model);
  }
});

export function gdprListStoreReducer(state: GdprListStoreState, action: Action): any {
  return gdprListStore.reducer(state, action);
}

@Injectable()
export class GdprListStoreEffects extends gdprListStore.effects {
  constructor(
    protected injector: Injector
  ) {
    super(injector);
  }
}
