import {EditorQuestState, MissionState, MissionType} from '@nexnox-web/core-shared';

export const missionStateLifecycle: { [key: number]: number[] } = {
  0: [
    MissionState.Instructed,
    MissionState.Rejected,
    MissionState.Canceled,
    MissionState.Done
  ],
  1: [
    MissionState.Done,
    MissionState.Rejected,
    MissionState.Canceled
  ],
  2: [
    MissionState.Done,
    MissionState.Instructed,
    MissionState.Canceled
  ],
  3: [
    MissionState.Instructed,
    MissionState.Rejected
  ],
  4: [
    MissionState.Instructed,
    MissionState.Rejected
  ]
}

export const techPortalFeatureMissionStateEnumOptions = [
  {
    label: 'missions.mission-states.0',
    value: MissionState.Created
  },
  {
    label: 'missions.mission-states.1',
    value: MissionState.Instructed
  },
  {
    label: 'missions.mission-states.2',
    value: MissionState.Rejected
  },
  {
    label: 'missions.mission-states.3',
    value: MissionState.Canceled
  },
  {
    label: 'missions.mission-states.4',
    value: MissionState.Done
  }
];

export const techPortalFeatureMissionTypeEnumOptions = [
  {
    label: 'missions.mission-types.1',
    value: MissionType.Ticket
  }
];

export const techPortalFeatureMissionDistanceOptions = [
  {
    label: 'missions.mission-distance.0',
    value: 10
  },
  {
    label: 'missions.mission-distance.1',
    value: 25
  },
  {
    label: 'missions.mission-distance.2',
    value: 50
  },
  {
    label: 'missions.mission-distance.3',
    value: 100
  },
  {
    label: 'missions.mission-distance.4',
    value: 250
  },
  {
    label: 'missions.mission-distance.5',
    value: 500
  }
];

export const techPortalFeatureMissionAmountOptions = [
  {
    label: 'missions.mission-amount.0',
    value: 25
  },
  {
    label: 'missions.mission-amount.1',
    value: 50
  },
  {
    label: 'missions.mission-amount.2',
    value: 100
  },
  {
    label: 'missions.mission-amount.3',
    value: 150
  },
  {
    label: 'missions.mission-amount.4',
    value: 200
  }
];

export const techPortalFeatureMissionQuestStatEnumOptions = [
  {
    label: 'missions.quest-state.1',
    value: EditorQuestState.Suggested
  },
  {
    label: 'missions.quest-state.2',
    value: EditorQuestState.InProcess
  },
  {
    label: 'missions.quest-state.5',
    value: EditorQuestState.Canceled
  },
  {
    label: 'missions.quest-state.4',
    value: EditorQuestState.Failed
  },
  {
    label: 'missions.quest-state.3',
    value: EditorQuestState.Completed
  }
];
