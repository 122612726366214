<ng-template #entityEditTemplate let-title="title">
  <nexnox-web-service-tenants-tenant-subscription-edit #editComponent
                                                       [title]="title"
                                                       [model]="createModel$ | async"
                                                       [tenantId]="tenantId"
                                                       [readonly]="readonly$ | async"
                                                       [creating]="isCreating | async"
                                                       [loading]="(loaded$ | async) === false || (loading$ | async)"
                                                       [stereotyped]="false"
                                                       [headerActions]="createSubscriptionHeaderActions"
                                                       (modelChange)="createModel$.next($event)">
  </nexnox-web-service-tenants-tenant-subscription-edit>
</ng-template>

<nexnox-web-entity-overview #overviewComponent
                            [entityOverviewBaseComponent]="this"
                            [entityEditTemplate]="entityEditTemplate"
                            [disableCreate]="(canCreateProductSubscription$ | async) === false"
                            [hideIfCreate]="false"
                            [stereotyped]="false">
</nexnox-web-entity-overview>
