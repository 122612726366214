import {AfterViewInit, Component, Input, ViewChild} from '@angular/core';
import {ReplaySubject} from 'rxjs';
import {SwalComponent} from '@sweetalert2/ngx-sweetalert2';
import {SweetAlertIcon} from 'sweetalert2';
import {faSpinner} from '@fortawesome/free-solid-svg-icons/faSpinner';

@Component({
  selector: 'nexnox-web-modal',
  templateUrl: './modal.component.html'
})
export class ModalComponent implements AfterViewInit {
  @Input() public title: string;
  @Input() public icon: SweetAlertIcon;
  @Input() public confirmStyle = 'primary';
  @Input() public width = '75%';
  @Input() public showConfirmButton = true;
  @Input() public confirmButtonText: string;
  @Input() public showCancelButton = true;
  @Input() public cancelButtonText: string;
  @Input() public preConfirmFn: () => any;
  @Input() public loading: boolean;

  @ViewChild('swal', { static: true }) public swal: SwalComponent;

  public init: ReplaySubject<SwalComponent> = new ReplaySubject<SwalComponent>();

  public faSpinner = faSpinner;

  public ngAfterViewInit(): void {
    this.init.next(this.swal);
    this.init.complete();
  }
}
