import {ChangeDetectionStrategy, Component, Injector, OnDestroy, OnInit} from '@angular/core';
import {CombineOperator, FilterOperators, FilterTypes, TemplateContextType} from '@nexnox-web/core-shared';
import {CorePortalEntityCreatePresetService} from "@nexnox-web/core-portal";
import {TextTemplateListComponent} from "../../containers";
import {TemplateUsageTypes} from "../../models";

@Component({
  selector: 'nexnox-web-settings-templates-issue-text-list-wrapper',
  templateUrl: '../../containers/text-templates/text-template-list/text-template-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IssueTextTemplateListWrapperComponent extends TextTemplateListComponent implements OnInit, OnDestroy {

  public templateUsageType = TemplateUsageTypes.Issue;

  constructor(
    protected injector: Injector,
    protected entityCreatePresetService: CorePortalEntityCreatePresetService
  ) {
    super(injector);

    this.defaultFilter = [
      {
        type: FilterTypes.Grouped,
        combinedAs: CombineOperator.Or,
        property: 'context',
        children: [
          {
            type: FilterTypes.DataTransferObject,
            operator: FilterOperators.Equal,
            property: 'context',
            value: TemplateContextType.IssueEmailContent.toString()
          },
          {
            type: FilterTypes.DataTransferObject,
            operator: FilterOperators.Equal,
            property: 'context',
            value: TemplateContextType.IssueEmailSubject.toString()
          },
          {
            type: FilterTypes.DataTransferObject,
            operator: FilterOperators.Equal,
            property: 'context',
            value: TemplateContextType.MessageIssueEmailContent.toString()
          },
          {
            type: FilterTypes.DataTransferObject,
            operator: FilterOperators.Equal,
            property: 'context',
            value: TemplateContextType.MessageIssueEmailSubject.toString()
          },
          {
            type: FilterTypes.DataTransferObject,
            operator: FilterOperators.Equal,
            property: 'context',
            value: TemplateContextType.MissionIssueEmailContent.toString()
          },
          {
            type: FilterTypes.DataTransferObject,
            operator: FilterOperators.Equal,
            property: 'context',
            value: TemplateContextType.MissionIssueEmailSubject.toString()
          },
          {
            type: FilterTypes.DataTransferObject,
            operator: FilterOperators.Equal,
            property: 'context',
            value: TemplateContextType.TicketIssueEmailContent.toString()
          },
          {
            type: FilterTypes.DataTransferObject,
            operator: FilterOperators.Equal,
            property: 'context',
            value: TemplateContextType.TicketIssueEmailSubject.toString()
          },
          {
            type: FilterTypes.DataTransferObject,
            operator: FilterOperators.Equal,
            property: 'context',
            value: TemplateContextType.WinterhalterIssueEmailContent.toString()
          },
          {
            type: FilterTypes.DataTransferObject,
            operator: FilterOperators.Equal,
            property: 'context',
            value: TemplateContextType.WinterhalterIssueEmailSubject.toString()
          }
        ]
      }
    ];

    this.componentId = 'IssueTextTemplateListWrapper';
  }

  public ngOnInit(): void {
    this.setWrapperCreatePreset();
    super.ngOnInit();
    this.detailLink = 'templates/issue-text-templates';
  }

  public ngOnDestroy(): void {
    this.isEmbedded = true;
    super.ngOnDestroy();
  }

  public setWrapperCreatePreset(): void {
    this.entityCreatePresetService.setPreset('TechPortalFeatureTextTemplateEditComponent', {
      context: TemplateContextType.IssueEmailContent
    });
  }
}
