import {
  BaseXsStore,
  createEntityXsStoreActions,
  EmptyAction,
  EntityXsStoreActions,
  PropsAction
} from '@nexnox-web/core-store';
import {InvitationDto} from '@nexnox-web/core-shared';
import {createAction, props} from '@ngrx/store';
import {InvitationsDetailXSStoreInvitationPayload} from './invitations-detail-xs-store.payloads';

export interface InvitationsDetailXsStoreActions extends EntityXsStoreActions<InvitationDto> {
  revokeInvitation: PropsAction<InvitationsDetailXSStoreInvitationPayload>;
  revokeInvitationSuccess: EmptyAction;

  reactivateInvitation: PropsAction<InvitationsDetailXSStoreInvitationPayload>;
  reactivateInvitationSuccess: EmptyAction;
}

export const createInvitationsDetailXsStoreActions = (label: string): InvitationsDetailXsStoreActions => ({
  ...createEntityXsStoreActions(label),

  revokeInvitation: createAction(
    BaseXsStore.getType(label, 'Revoke invitation'),
    props<InvitationsDetailXSStoreInvitationPayload>()
  ),
  revokeInvitationSuccess: createAction(
    BaseXsStore.getType(label, 'Revoke invitation success')
  ),

  reactivateInvitation: createAction(
    BaseXsStore.getType(label, 'Reactivate invitation'),
    props<InvitationsDetailXSStoreInvitationPayload>()
  ),
  reactivateInvitationSuccess: createAction(
    BaseXsStore.getType(label, 'Reactivate invitation success')
  )
});
