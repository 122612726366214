import {PagedEntitiesXsStoreState} from '@nexnox-web/core-store';
import {AppEntityType, TicketIssueListDto} from '@nexnox-web/core-shared';
import {createSelector} from '@ngrx/store';
import {selectIssuesState} from '../../issues.selectors';
import {TicketIssueListXsStore} from './ticket-issue-list.xs-store';
import {TicketIssueService} from "../../services";

export interface TicketIssueListStoreState extends PagedEntitiesXsStoreState<TicketIssueListDto> {
}

/* istanbul ignore next */
export const ticketIssueListStore = new TicketIssueListXsStore({
  actionLabel: 'Tech Portal - Issues - Ticket Issue List',
  stateSelector: createSelector(selectIssuesState, state => state.ticketIssueList),
  serviceType: TicketIssueService,
  entityType: AppEntityType.Issue,
  selectId: issue => issue.entity?.issueId ?? issue.model?.issueId
});
