import {
  BaseXsStore,
  BaseXsStoreActions,
  BaseXsStoreReducerTypes,
  createBaseXsStoreActions,
  EmptyAction,
  PropsAction
} from '@nexnox-web/core-store';
import { getInitialPackagesXsStoreState, PackagesXsStoreState } from './packages-xs-store.state';
import { createAction, createSelector, MemoizedSelector, props } from '@ngrx/store';
import { PackagesXsStoreGetSuccessPayload } from './packages-xs-store.payloads';
import { PermissionPackageDto } from '@nexnox-web/core-shared';
import { immerOn } from 'ngrx-immer/store';

export interface PackagesXsStoreActions extends BaseXsStoreActions {
  get: EmptyAction;
  getSuccess: PropsAction<PackagesXsStoreGetSuccessPayload>;
}

export interface PackagesXsStoreSelectors {
  selectPackages: MemoizedSelector<PackagesXsStoreState, PermissionPackageDto[]>;
  selectLoading: MemoizedSelector<PackagesXsStoreState, boolean>;
  selectLoaded: MemoizedSelector<PackagesXsStoreState, boolean>;
}

export class PackagesXsStore extends BaseXsStore<PackagesXsStoreState> {
  public actions: PackagesXsStoreActions;
  public selectors: PackagesXsStoreSelectors;

  public getInitialState(): PackagesXsStoreState {
    return getInitialPackagesXsStoreState();
  }

  protected createActions(label: string): PackagesXsStoreActions {
    return {
      ...createBaseXsStoreActions(label),

      get: createAction(BaseXsStore.getType(label, 'Get')),
      getSuccess: createAction(
        BaseXsStore.getType(label, 'Get success'),
        props<PackagesXsStoreGetSuccessPayload>()
      )
    };
  }

  protected createReducerArray(
    initialState: PackagesXsStoreState
  ): BaseXsStoreReducerTypes<PackagesXsStoreState, PackagesXsStoreActions>[] {
    return [
      ...super.createReducerArray(initialState),

      immerOn(this.actions.get, draft => {
        draft.loading = true;
      }),

      immerOn(this.actions.getSuccess, (draft, { packages }) => {
        draft.packages = packages;
        draft.loading = false;
        draft.loaded = true;
      }),

      immerOn(this.actions.error, draft => {
        draft.loading = false;
      })
    ];
  }

  protected createSelectors(): PackagesXsStoreSelectors {
    const { stateSelector } = this.options;

    return {
      ...super.createSelectors(),

      selectPackages: createSelector(stateSelector, state => state.packages),
      selectLoading: createSelector(stateSelector, state => state.loading),
      selectLoaded: createSelector(stateSelector, state => state.loaded)
    };
  }
}
