import { InjectionToken, NgModule } from '@angular/core';
import { ActionReducerMap, StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import {
  invitationsEffects,
  invitationsReducers
} from "./store";

export const REDUCER_TOKEN = new InjectionToken<ActionReducerMap<any>>(
  '@nexnox-web/core-portal/settings/invitations: Store Reducers'
);

@NgModule({
  imports: [
    StoreModule.forFeature('core-portal-feature-settings-invitations', REDUCER_TOKEN),
    EffectsModule.forFeature(invitationsEffects)
  ],
  providers: [
    {
      provide: REDUCER_TOKEN,
      useValue: invitationsReducers
    }
  ]
})
export class InvitationsStoreModule {
}
