import { Injectable, Injector } from '@angular/core';
import { CorePortalEmbeddedEntityBaseService } from '@nexnox-web/core-portal';
import { CorePortalFeatureMasterDataLocationService } from '@nexnox-web/core-portal/features/master-data/features/locations';

@Injectable()
export class ContactLocationService extends CorePortalEmbeddedEntityBaseService {
  constructor(
    protected injector: Injector
  ) {
    super(injector, CorePortalFeatureMasterDataLocationService, null, 'location', ['contact']);
  }
}
