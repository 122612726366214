export * from './text-templates/solution-text-template-list-wrapper.component';
export * from './text-templates/notification-text-template-list-wrapper.component';
export * from './document-templates/ticket-document-template-list-wrapper.component';
export * from './document-templates/issue-document-template-list-wrapper.component';
export * from './document-templates/mission-document-template-list-wrapper.component';
export * from './text-templates/mission-text-template-list-wrapper.component';
export * from './document-templates/form-document-template-list-wrapper.component';

export * from './text-templates/mission-text-template-detail-wrapper.component';
export * from './text-templates/solution-text-template-detail-wrapper.component';
export * from './text-templates/issue-text-template-detail-wrapper.component';
export * from './text-templates/issue-text-template-list-wrapper.component';
export * from './text-templates/notification-text-template-detail-wrapper.component';
export * from './document-templates/form-document-template-detail-wrapper.component';
export * from './document-templates/mission-document-template-detail-wrapper.component';
export * from './document-templates/ticket-document-template-detail-wrapper.component';
export * from './document-templates/issue-document-template-detail-wrapper.component';
