<nexnox-web-cardbox *ngIf="withCardbox; else attachmentsTemplate"
                    [title]="'orga-portal.subtitles.attachments' | translate"
                    [loading]="loading"
                    paddingClasses="px-3 pb-3">
  <ng-container *ngTemplateOutlet="attachmentsTemplate"></ng-container>
</nexnox-web-cardbox>

<ng-template #attachmentsTemplate>
  <nexnox-web-rule-editor-list #attachmentsEdit
                               [items]="attachments$ | async"
                               imageKey="file"
                               [title]="attachmentTitleFn"
                               [inheritedFieldsFn]="attachmentFieldsFn"
                               [disabled]="readonly"
                               (itemsChange)="onAttachmentsChange($event)">
  </nexnox-web-rule-editor-list>
</ng-template>
