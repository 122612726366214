import { ChangeDetectionStrategy, Component, Injector } from '@angular/core';
import {
  CorePortalEntityEditBaseComponent,
  CorePortalFormlyReadonlyTypes,
  CorePortalFormlyReadonlyTyping
} from '@nexnox-web/core-portal';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { FunctionDto } from '@nexnox-web/core-shared';

@Component({
  selector: 'nexnox-web-master-functions-function-edit',
  templateUrl: './function-edit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CorePortalFeatureFunctionEditComponent extends CorePortalEntityEditBaseComponent<FunctionDto> {
  constructor(
    protected injector: Injector
  ) {
    super(injector);
  }

  protected createForm(): FormlyFieldConfig[] {
    return [
      {
        key: 'name',
        type: 'input',
        wrappers: ['core-portal-translated'],
        className: 'col-md-6',
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.name',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          },
          type: 'text'
        },
        expressionProperties: {
          'templateOptions.required': () => !this.readonly,
          'templateOptions.disabled': () => this.readonly
        },
        hideExpression: () => !this.creating
      },
      {
        key: 'externalId',
        type: 'core-portal-external-id',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-6',
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.external-id',
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.BASIC
          } as CorePortalFormlyReadonlyTyping,
          titleKey: 'name',
          modelSubject: this.modelSubject
        },
        expressionProperties: {
          'templateOptions.readonly': () => this.readonly,
          'templateOptions.disabled': () => this.readonly
        }
      },
      ...this.getStereotypeFields(false)
    ];
  }
}
