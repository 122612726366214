<div class="action-container w-100 py-0"
     *ngIf="(actions$ | async)?.length && (isOneShowing$ | async)">
  <div class="d-flex container-fluid justify-space-between pt-0 pb-2">
    <div class="d-flex overflow-auto whitespace-nowrap">
      <ng-container *ngFor="let action of leftActions$ | async; trackBy: trackActionsBy">
        <ng-container [ngTemplateOutlet]="itemTemplate" [ngTemplateOutletContext]="{ action: action }"></ng-container>
      </ng-container>
    </div>

    <div class="d-flex ml-2">
      <ng-container *ngFor="let action of rightActions$ | async; trackBy: trackActionsBy; let isLast = isLast">
        <ng-container [ngTemplateOutlet]="itemTemplate" [ngTemplateOutletContext]="{ action: action, isLast: isLast }"></ng-container>
      </ng-container>
    </div>
  </div>
</div>

<ng-template #itemTemplate let-action="action" let-isLast="isLast">
  <div *ngIf="(isDropDown(action.type) | async) === false; else dropdown"
       class="btn-group mr-2"
       [class.d-none]="!action.shouldShowValue">
    <button class="btn {{ action.class }}"
            [disabled]="action.isDisabledValue"
            [attr.title]="action.tooltip ? (action.tooltip | translate) : undefined"
            (click)="action.callback($event, action)">
      <fa-icon *ngIf="action.icon"
               [icon]="action.icon"
               [nexnoxWebLoadingIcon]="action.isLoadingValue"
               class="mr-2">
      </fa-icon>

      <ng-container [ngTemplateOutlet]="buttonTemplate"
                    [ngTemplateOutletContext]="{ action: action }">
      </ng-container>
    </button>

    <button *ngIf="action.splitCallback"
            class="btn {{ action.class }} dropdown-toggle dropdown-toggle-split"
            [disabled]="action.isSplitDisabledValue"
            (click)="action.splitCallback($event, action)">
    </button>
  </div>

  <ng-template #dropdown>
    <div dropdown
         [isDisabled]="action.isDisabledValue && (action.isSplitDisabled ? action.isSplitDisabledValue : true)"
         [placement]="action.alignRight ? 'bottom right' : 'bottom left'"
         container="body"
         class="btn-group mr-2"
         [class.d-none]="!action.shouldShowValue">
      <ng-container *ngIf="!action.isSplitDisabled; else splitTemplate">
        <button dropdownToggle
                class="btn {{ action.class }}"
                [class.dropdown-toggle]="!action.noArrow"
                type="button"
                [disabled]="action.isDisabledValue"
                [class.is-disabled]="action.isDisabledValue"
                [attr.title]="action.tooltip ? (action.tooltip | translate) : undefined">
          <fa-icon *ngIf="action.icon"
                   [icon]="action.icon"
                   [nexnoxWebLoadingIcon]="action.isLoadingValue"
                   [fixedWidth]="true"
                   [class.mr-2]="action.label">
          </fa-icon>

          <ng-container [ngTemplateOutlet]="buttonTemplate"
                        [ngTemplateOutletContext]="{ action: action }">
          </ng-container>
          <span *ngIf="!action.noArrow" class="caret"></span>
        </button>
      </ng-container>

      <ng-template #splitTemplate>
        <button class="btn {{ action.class }}"
                type="button"
                [attr.title]="action.tooltip ? (action.tooltip | translate) : undefined"
                [disabled]="action.isDisabledValue"
                (click)="action.callback($event, action)">
          <fa-icon *ngIf="getAsyncIcon(action.icon) as icon"
                   [icon]="icon | async"
                   [nexnoxWebLoadingIcon]="action.isLoadingValue"
                   class="mr-2">
          </fa-icon>

          <ng-container [ngTemplateOutlet]="buttonTemplate"
                        [ngTemplateOutletContext]="{ action: action }">
          </ng-container>
        </button>

        <button class="btn {{ action.class }} dropdown-toggle dropdown-toggle-split"
                dropdownToggle
                [disabled]="action.isSplitDisabledValue">
        </button>
      </ng-template>

      <ul *dropdownMenu
          class="dropdown-menu"
          [class.dropdown-menu-right]="action.alignRight"
          role="menu"
          style="top: 0 !important;">
        <li *ngFor="let subAction of action.buttonsValue"
            role="menuitem"
            [class.disabled]="subAction.isDisabledValue">
          <a class="dropdown-item"
             [class.d-none]="!subAction.shouldShowValue"
             [class.disabled]="subAction.isDisabledValue"
             (click)="subAction.callback ? subAction.callback($event, subAction) : ''">
            <fa-icon *ngIf="subAction.icon"
                     [icon]="subAction.icon"
                     [nexnoxWebLoadingIcon]="subAction.isLoadingValue"
                     class="mr-2">
            </fa-icon>

            <ng-container [ngTemplateOutlet]="buttonTemplate"
                          [ngTemplateOutletContext]="{ action: subAction }">
            </ng-container>
          </a>
        </li>
      </ul>
    </div>
  </ng-template>
</ng-template>

<ng-template #buttonTemplate let-action="action">
  <ng-container *ngIf="!action.noTranslate; else noTranslateTemplate">
    {{ (action.label$ ? (action.label$ | async) : action.label) | translate }}
  </ng-container>

  <ng-template #noTranslateTemplate>
    {{ action.label$ ? (action.label$ | async) : action.label }}
  </ng-template>
</ng-template>
