export * from './location/location.service';
export * from './location-address-list/location-address.service';
export * from './location-resource/location-resource.service';
export * from './location-contract/location-contract.service';
export * from './location-issue/location-issue.service';
export * from './location-mission/location-mission.service';
export * from './location-task/location-task.service';
export * from './location-contact/location-contact.service';
export * from './location-ticket/location-ticket.service';
export * from './location-taskjob/location-taskjob.service';
export * from './location-taskjob-archive/location-taskjob-archive.service';
export * from './location-locationgroup/location-locationgroup.service';
export * from './location-address-detail/location-address.service';
