import { ChangeDetectionStrategy, Component, Injector } from '@angular/core';
import {
  ActionButton,
  CorePortalEntityDetailBaseComponent
} from '@nexnox-web/core-portal';
import { AppPermissions, ErrorCodeDto } from '@nexnox-web/core-shared';
import { errorCodeDetailStore } from '../../store';

@Component({
  selector: 'nexnox-web-connected-error-codes-error-code-detail',
  templateUrl: './error-code-detail.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ErrorCodeDetailComponent extends CorePortalEntityDetailBaseComponent<ErrorCodeDto> {
  public title = 'tech-portal.connected.error-codes.subtitles.detail';

  protected idParam = 'errorCodeId';
  protected displayKey = 'displayName';

  constructor(
    protected injector: Injector
  ) {
    super(injector, errorCodeDetailStore);
  }

  protected async getActionButtons(): Promise<ActionButton[]> {
    return this.getDefaultActionButtons(
      'tech-portal.connected.error-codes.actions.edit',
      'tech-portal.connected.error-codes.actions.save',
      'tech-portal.connected.error-codes.actions.delete',
      'tech-portal.connected.error-codes.descriptions.delete',
      AppPermissions.UpdateErrorCode,
      AppPermissions.DeleteErrorCode
    );
  }
}
