import { Injectable, Injector } from '@angular/core';
import { createEffect, ofType } from '@ngrx/effects';
import { catchError, delay, exhaustMap, map, retryWhen, take } from 'rxjs/operators';
import { packagesStore } from './packages.store';
import { PackagesXsStoreActions } from './packages.xs-store';
import { Observable, of } from 'rxjs';
import { CoreSharedHttpIdentityRequestService, PermissionPackageDto } from '@nexnox-web/core-shared';

@Injectable()
export class PackagesStoreEffects extends packagesStore.effects {
  public loadPermissions$;

  protected actions: PackagesXsStoreActions;

  constructor(
    protected injector: Injector,
    private identityService: CoreSharedHttpIdentityRequestService,
  ) {
    super(injector);
  }

  protected createEffects(): void {
    super.createEffects();

    this.loadPermissions$ = createEffect(() => this.actions$.pipe(
      ofType(this.actions.get),
      exhaustMap(() => this.getPermissionPackages().pipe(
        map(packages => this.actions.getSuccess({ packages })),
        catchError(error => of(this.actions.error({ error, action: this.actions.get })))
      ))
    ));
  }

  protected getPermissionPackages(): Observable<PermissionPackageDto[]> {
    return this.identityService.get<PermissionPackageDto[]>('permissionPackage', null, null, false).pipe(
      retryWhen(errors => errors.pipe(delay(2500), take(2))),
      map(response => response.body)
    );
  }
}
