import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ResourceDto, ResourcePathPartDto } from '@nexnox-web/core-shared';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'nexnox-web-resources-resource-path',
  templateUrl: './resource-path.component.html',
  styleUrls: ['./resource-path.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CorePortalFeatureResourcePathComponent {
  @Input() public resource: ResourceDto;

  public static preparePath(resource: ResourceDto): string {
    const path = cloneDeep(resource?.path ?? []);

    if (path.length) {
      path.splice(-1, 1);
    }

    return path.map((part: ResourcePathPartDto) => part.name).join(' > ');
  }

  /* istanbul ignore next */
  public trackByResourcePath(index: number, path: ResourcePathPartDto): number {
    return path.index;
  }
}
