import {
  ApiNotificationService,
  AppEntityType,
  CoreSharedApiBaseService,
  CoreSharedLocalStorageService,
  DataTableColumnDto,
  DataTableColumnType,
  DataTableCustomColumnDto,
  DataTableDto,
  DataTableFilterDto,
  DataTableFilterType,
  DataTableTransferColumnDto,
  DataTableTransferFilterDto,
  Filter,
  FilterDto,
  FilterOperations,
  Mappers,
  PageableRequest,
  Paging,
  SortObject,
  StereotypeDto
} from '@nexnox-web/core-shared';
import {
  CoreStoreObservableStore,
  getInitialPagedEntitiesXsStoreState,
  PagedEntitiesXsStoreEntity,
  pagedEntitiesXsStoreSetLoadingForId,
  PagedEntitiesXsStoreState
} from '@nexnox-web/core-store';
import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { Injector, Type } from '@angular/core';
import produce from 'immer';
import { cloneDeep, isEqual } from 'lodash';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map, pairwise } from 'rxjs/operators';
import { CorePortalDatatableColumnService } from '../../services';
import { EntityData } from '../../../models';

export interface CorePortalEntityDatatableObservableStoreState<E, M = E> extends PagedEntitiesXsStoreState<E, M> {
}

export class CorePortalEntityDatatableObservableStore<E, M = E>
  extends CoreStoreObservableStore<CorePortalEntityDatatableObservableStoreState<E, M>> {
  public readonly adapter: EntityAdapter<PagedEntitiesXsStoreEntity<E, M>>;

  protected entityService: CoreSharedApiBaseService;
  protected apiNotificationService: ApiNotificationService;
  protected localStorageService: CoreSharedLocalStorageService;
  protected datatableColumnService: CorePortalDatatableColumnService;

  constructor(
    protected injector: Injector,
    protected idKey: string,
    protected entityServiceType: Type<CoreSharedApiBaseService>,
    protected entityType: AppEntityType,
    protected excludedColumns: string[] = [],
    protected serializedName?: string,
    protected useView: boolean = false
  ) {
    super({});

    this.entityService = this.injector.get(entityServiceType);
    this.apiNotificationService = this.injector.get(ApiNotificationService);
    this.localStorageService = this.injector.get(CoreSharedLocalStorageService);
    this.datatableColumnService = this.injector.get(CorePortalDatatableColumnService);

    this.adapter = createEntityAdapter({
      selectId: entity => entity.model[idKey] ?? entity.entity[idKey]
    });

    this.setState(this.getInitialState(), 'INIT_STATE');
  }

  public getPage(
    pageNumber: number,
    sortOptions: SortObject,
    filters: FilterDto[],
    pageSize?: number,
    config?: DataTableDto,
    useAllToken: boolean = false
  ): void {
    const stereotypesLoaded = this.getState().stereotypesLoaded;
    let stereotypeColumns: DataTableCustomColumnDto[] = [];
    let optionalColumns: DataTableTransferColumnDto[] = [];
    let viewId;

    if (config) {
      stereotypeColumns = config.columns?.filter(column => this.isCustomColumn(column)) as DataTableCustomColumnDto[];

      if (this.serializedName) {
        const mapper = Mappers[this.serializedName] ?? null;
        const dataColumns = config.columns?.filter(column => this.isTransferColumn(column)) as DataTableTransferColumnDto[];
        optionalColumns = this.datatableColumnService.getDataColumns(mapper, this.excludedColumns)
          .filter(x => Boolean(x.optional) && dataColumns?.findIndex(y => y.property === x.name) > -1)
          .map(x => ({ property: x.name } as DataTableTransferColumnDto));
      }

      if (this.useView) {
        viewId = config.dataTableId;
      }
    }

    if (!stereotypesLoaded) {
      this.getStereotypes();
    }

    this.entityService.getPage<E>(
      sortOptions,
      pageNumber,
      filters,
      FilterOperations.Include,
      stereotypeColumns?.map(column => column.customPropertyId),
      optionalColumns?.map(column => column.property),
      pageSize,
      undefined,
      viewId,
      useAllToken
    ).toPromise()
      .then(({ items, paging }) => this.getPageSuccess({
        items: items.map(item => ({
          entity: item,
          model: item as any as M
        })),
        paging
      }, filters))
      .catch(error => this.handleError(error));

    this.setState(produce(this.getState(), draft => {
      draft.loading = true;
    }), 'GET_PAGE');
  }

  public getPageSuccess(response: PageableRequest<PagedEntitiesXsStoreEntity<E, M>>, savedFilters?: FilterDto[]): void {
    const mappedItems = response.items.map(item => ({
      entity: cloneDeep(item.entity),
      model: cloneDeep(item.model)
    }));

    this.setState(this.adapter.setAll(mappedItems, {
      ...this.getState(),
      paging: response.paging,
      filters: savedFilters,
      loading: false,
      loaded: true
    }), 'GET_PAGE_SUCCESS');
  }

  public getStereotypes(): void {
    this.entityService.getStereotypes(this.entityType).toPromise()
      .then(stereotypes => this.getStereotypesSuccess(stereotypes))
      .catch(error => this.handleError(error));

    this.setState(produce(this.getState(), draft => {
      draft.stereotypesLoading = true;
    }), 'GET_STEREOTYPES');
  }

  public getStereotypesSuccess(stereotypes: StereotypeDto[]): void {
    this.setState(produce(this.getState(), draft => {
      draft.stereotypes = stereotypes;
      draft.stereotypesLoading = false;
      draft.stereotypesLoaded = true;
    }), 'GET_STEREOTYPES_SUCCESS');
  }

  public updateEntityAndModelForId(id: number, entity: Partial<E>, model: Partial<M>): void {
    const state = this.getState();

    this.setState(this.adapter.updateOne({
      id,
      changes: {
        entity: { ...(state.entities[id]?.entity ?? {}), ...(entity as E) },
        model: { ...(state.entities[id]?.model ?? {}), ...(model as M) }
      }
    }, this.getState()));
  }

  public setEntityDataLoadingForId(id: number, loading: { [key: string]: boolean }): void {
    this.setState(produce(this.getState(), draft => {
      draft.entityData = pagedEntitiesXsStoreSetLoadingForId(draft.entityData, id, loading);
    }), 'SET_ENTITY_DATA_LOADING_FOR_ID');
  }

  public clear(): void {
    this.setState(this.getInitialState(), 'CLEAR');
  }

  public handleError(error: HttpErrorResponse | string): void {
    if (error instanceof HttpErrorResponse) {
      this.apiNotificationService.handleApiError(error);
    } else {
      this.apiNotificationService.showTranslatedError(error);
    }

    this.setState(produce(this.getState(), draft => {
      draft.loading = false;
      draft.loaded = false;
    }), 'ERROR');
  }

  public getInitialState(): CorePortalEntityDatatableObservableStoreState<E, M> {
    return getInitialPagedEntitiesXsStoreState<E, M>(this.adapter);
  }

  public selectEntities(): Observable<PagedEntitiesXsStoreEntity<E, M>[]> {
    return this.stateChanged.pipe(
      map(state => this.adapter.getSelectors().selectAll(state))
    );
  }

  public selectPaging(): Observable<Paging> {
    return this.stateChanged.pipe(
      map(state => state.paging)
    );
  }

  public selectFilters(): Observable<Filter[]> {
    return this.stateChanged.pipe(
      map(state => state.filters)
    );
  }

  public selectStereotypes(): Observable<StereotypeDto[]> {
    return this.stateChanged.pipe(
      pairwise(),
      filter(([oldState, state]) => !isEqual(oldState.stereotypes, state.stereotypes)),
      map(([oldState, state]) => state.stereotypes)
    );
  }

  public selectStereotypesLoaded(): Observable<boolean> {
    return this.stateChanged.pipe(
      map(state => state.stereotypesLoaded)
    );
  }

  public selectEntityData(): Observable<EntityData> {
    return this.stateChanged.pipe(
      map(state => state.entityData)
    );
  }

  public selectLoading(): Observable<boolean> {
    return this.stateChanged.pipe(
      map(state => state.loading)
    );
  }

  public selectLoaded(): Observable<boolean> {
    return this.stateChanged.pipe(
      map(state => state.loaded)
    );
  }

  public isTransferColumn(column: DataTableColumnDto): column is DataTableTransferColumnDto {
    return column.type === DataTableColumnType.ByTransfer;
  }

  public isCustomColumn(column: DataTableColumnDto): column is DataTableCustomColumnDto {
    return column.type === DataTableColumnType.ByCustomProperty;
  }

  public isTransferFilter(datatableFilter: DataTableFilterDto): datatableFilter is DataTableTransferFilterDto {
    return datatableFilter.type === DataTableFilterType.ByTransfer;
  }
}
