import {ChangeDetectionStrategy, Component, Injector, Input, OnInit} from '@angular/core';
import {CorePortalEntityOverviewBaseComponent, DatatableActionButton, DeleteEntityModel} from '@nexnox-web/core-portal';
import {
  AppEntityType,
  AppPermissions,
  FilterOperators,
  FilterTypes,
  Mappers,
  TicketsByLocationDefinitionListDto
} from '@nexnox-web/core-shared';
import {ticketsByLocationStereotypeListStore} from '../../store';

@Component({
  selector: 'nexnox-web-settings-stereotypes-tickets-by-location-definition-list',
  templateUrl: './tickets-by-location-stereotype-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TicketsByLocationStereotypeListComponent extends CorePortalEntityOverviewBaseComponent<TicketsByLocationDefinitionListDto> implements OnInit {

  @Input() public stereotypeId: number | string;

  public title = 'core-shared.shared.skeletons.ticket-skeletons';
  public createTitle = 'core-shared.shared.skeletons.ticket-skeletons';
  public idProperty = 'ticketsByLocationDefinitionId';
  public displayProperty = 'title';
  public datatableConfigName = 'SettingsTicketsByLocationDefinitionDefault';
  public componentId = 'TicketsByLocationStereotypeListComponent';
  public isEmbedded = true;

  constructor(
    protected injector: Injector
  ) {
    super(injector, ticketsByLocationStereotypeListStore, Mappers.TicketsByLocationDefinitionListDto.serializedName, AppEntityType.None);
  }

  public ngOnInit(): void {
    this.defaultFilter = [{
      type: FilterTypes.DataTransferObject,
      operator: FilterOperators.Equal,
      property: 'stereotype.stereotypeId',
      value: (this.stereotypeId ?? 0).toString()
    }];
    super.ngOnInit();
  }

  public getRowActionButtons(): DatatableActionButton[] {
    return [
      ...this.getDefaultRowActionButtons(
        'core-shared.shared.skeletons.edit-skeleton',
        definition => `stereotypes/location-stereotypes/${this.parentIds[0]}/tickets-by-location/${definition.ticketsByLocationDefinitionId}`,
        [AppPermissions.UpdateTicketsByLocationDefinition],
        {
          module: 'settings'
        }
      ),
    ];
  }

  public getDeleteEntityModel(): DeleteEntityModel {
    return {
      titleKey: 'core-shared.shared.skeletons.delete-skeleton',
      descriptionKey: 'core-shared.shared.skeletons.delete-skeleton-description',
      confirmKey: 'core-shared.shared.skeletons.delete-skeleton',
      deletePermission: AppPermissions.DeleteTicketsByLocationDefinition
    };
  }
}
