import { stateMachineDetailStore, StateMachineDetailStoreState, stateMachineListStore, StateMachineListStoreState } from './stores';

export interface StateMachinesState {
  stateMachineList: StateMachineListStoreState;
  stateMachineDetail: StateMachineDetailStoreState;
}

export const getInitialStateMachinesState = (): StateMachinesState => ({
  stateMachineList: stateMachineListStore.getInitialState(),
  stateMachineDetail: stateMachineDetailStore.getInitialState()
});
