import { CoreSharedApiBaseService, Product } from '@nexnox-web/core-shared';
import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class OrgaPortalFeaturePingService extends CoreSharedApiBaseService {
  constructor(
    protected injector: Injector
  ) {
    super(injector, Product.ORGA, 'ping');
  }

  public ping(deviceId: number | string, tenantId: number): Observable<any> {
    return this.httpRequestService.post(`${this.buildPath()}/ping`, {
      deviceId,
      tenantId
    }).pipe(
      map(response => response.body)
    );
  }
}
