import { InjectionToken, NgModule } from '@angular/core';
import { ActionReducerMap, StoreModule } from '@ngrx/store';
import { consumptionCodeEffects, consumptionCodeReducers } from './store'
import { EffectsModule } from '@ngrx/effects';

export const REDUCER_TOKEN = new InjectionToken<ActionReducerMap<any>>(
  '@nexnox-web/tech-portal/connected/consumption-codes: Store Reducers'
);

@NgModule({
  imports: [
    StoreModule.forFeature('tech-portal-feature-connected-consumption-codes', REDUCER_TOKEN),
    EffectsModule.forFeature(consumptionCodeEffects)
  ],
  providers: [
    {
      provide: REDUCER_TOKEN,
      useValue: consumptionCodeReducers
    }
  ]
})
export class ConsumptionCodesStoreModule {
}
