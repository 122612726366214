<ng-container *ngIf="label; else notLabel">
  <span [title]="title$ | async" class="default-text-container">
    <span [innerHTML]="titleHtml$ | async"></span>
  </span>
</ng-container>

<ng-template #notLabel>
  <span [title]="title$ | async">
    <span [innerHTML]="titleHtml$ | async | nexnoxWebHighlight:searchTerm:(titlePrefixHtml$ | async)"></span>
  </span>
</ng-template>
