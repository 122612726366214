import { roleContactListStore, RoleDetailStoreEffects, roleDetailStoreReducer, RoleListStoreEffects, roleListStoreReducer } from './stores';
import { RoleContactService, CorePortalRoleService } from './services';

export * from './stores';
export * from './services';

export * from './roles.state';
export * from './roles.selectors';

export const rolesReducers = {
  roleList: roleListStoreReducer,
  roleDetail: roleDetailStoreReducer,
  roleContactList: roleContactListStore.reducer
};

export const rolesEffects = [
  RoleListStoreEffects,
  RoleDetailStoreEffects,
  roleContactListStore.effects
];

export const rolesServices = [
  CorePortalRoleService,
  RoleContactService
];
