import {AppEntityType} from '@nexnox-web/core-shared';
import {createSelector} from '@ngrx/store';
import {CorePortalManufacturerModelService} from '../../services';
import {selectManufacturerState} from "../../manufacturer.selectors";
import {ModelListXsStore, ModelListXsStoreState} from "./model-list.xs-store";

export interface ModelListStoreState extends ModelListXsStoreState {
}

/* istanbul ignore next */
export const modelListStore = new ModelListXsStore({
  actionLabel: 'Core Portal - Settings - Models - Model List',
  stateSelector: createSelector(selectManufacturerState, state => state.modelList),
  serviceType: CorePortalManufacturerModelService,
  entityType: AppEntityType.None,
  stereotyped: false,
  selectId: model => model.entity?.resourceModelId ?? model.model?.resourceModelId
});

