import {PagedEntitiesXsStore, PagedEntitiesXsStoreState} from '@nexnox-web/core-store';
import {AppEntityType, IssueListDto} from '@nexnox-web/core-shared';
import {Action, ActionReducer, createSelector} from '@ngrx/store';
import {selectResourcesState} from '../../resources.selectors';
import {TechPortalFeatureResourceIssueService} from '../../services';
import {Injectable, Injector} from '@angular/core';

export interface ResourceIssuesStoreState extends PagedEntitiesXsStoreState<IssueListDto> {
}

/* istanbul ignore next */
export const resourceIssuesStore = new PagedEntitiesXsStore<IssueListDto>({
  actionLabel: 'Tech Portal - Resources - Resource Issues',
  stateSelector: createSelector(selectResourcesState, state => state.resourceIssues),
  entityType: AppEntityType.Issue,
  serviceType: TechPortalFeatureResourceIssueService,
  selectId: issue => issue.entity?.issueId ?? issue.model?.issueId
});

export function resourceIssuesStoreReducer(state: ResourceIssuesStoreState, action: Action): ActionReducer<any> {
  return resourceIssuesStore.reducer(state, action);
}

@Injectable()
export class ResourceIssuesStoreEffects extends resourceIssuesStore.effects {
  constructor(
    protected injector: Injector
  ) {
    super(injector);
  }
}
