import { InjectionToken, NgModule } from '@angular/core';
import { ActionReducerMap, StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { inventoryNumbersEffects, inventoryNumbersReducers } from './store';

export const REDUCER_TOKEN = new InjectionToken<ActionReducerMap<any>>(
  '@nexnox-web/core-portal/settings/inventory-numbers: Store Reducers'
);

@NgModule({
  imports: [
    StoreModule.forFeature('core-portal-feature-settings-inventory-numbers', REDUCER_TOKEN),
    EffectsModule.forFeature(inventoryNumbersEffects)
  ],
  providers: [
    {
      provide: REDUCER_TOKEN,
      useValue: inventoryNumbersReducers
    }
  ]
})
export class InventoryNumbersStoreModule {
}
