import { isEmpty, isEqual, isObject, transform, isArrayLike } from 'lodash';

export function takeDifference(obj: any, base: any): any {
  return transform(obj, (result, value, key) => {
    if (!isEqual(value, base[key])) {
      result[key] = isFullObject(value) && isFullObject(base[key]) ? takeDifference(value, base[key]) : value;

      if (isFullObject(result[key]) && isEmpty(result[key])) {
        result[key] = {};
      }
    }

    return result;
  });
}

function isFullObject(value: any): boolean {
  return isObject(value) && !isArrayLike(value);
}
