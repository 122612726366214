import { PagedEntitiesXsStore, PagedEntitiesXsStoreState } from '@nexnox-web/core-store';
import { AppEntityType, MissionDto } from '@nexnox-web/core-shared';
import { createSelector } from '@ngrx/store';
import { selectLocationsState } from '../../locations.selectors';
import { LocationMissionService } from '../../services';

export interface LocationMissionListStoreState extends PagedEntitiesXsStoreState<MissionDto> {
}

/* istanbul ignore next */
export const locationMissionListStore = new PagedEntitiesXsStore<MissionDto>({
  actionLabel: 'Core Portal - Master Data - Location - Mission List',
  stateSelector: createSelector(selectLocationsState, state => state.locationMissionList),
  serviceType: LocationMissionService,
  entityType: AppEntityType.Mission,
  selectId: mission => mission.entity?.missionId ?? mission.model?.missionId
});
