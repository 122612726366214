import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {faSpinner} from '@fortawesome/free-solid-svg-icons/faSpinner';
import {CoreSharedSidebarBaseComponent} from '../../base-components';
import {KnowledgeArticleDto} from "../../models";
import {Subject} from 'rxjs';
import {take} from "rxjs/operators";
import {DomSanitizer, SafeHtml} from "@angular/platform-browser";
import {CoreSharedKnowledgeArticleService} from "../../services";

@Component({
	selector: 'nexnox-web-resource-article-preview-sidebar',
	templateUrl: './article-preview-sidebar.component.html',
	styleUrls: ['./article-preview-sidebar.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ArticlePreviewSidebarComponent extends CoreSharedSidebarBaseComponent {

	@Input() public knowledgeArticleId: number;

	public articleSubject: Subject<KnowledgeArticleDto> = new Subject<KnowledgeArticleDto>();

	public faSpinner = faSpinner;

	constructor(
		private articleService: CoreSharedKnowledgeArticleService,
		private sanitizer: DomSanitizer
	) {
		super();
	}

	public onHide(): void {
		this.articleSubject.next(undefined);
		super.onHide();
	}

	public onShow(): void {
		this._loadArticle(this.knowledgeArticleId);
		super.onShow();
	}

	public bypassHTMLSanitizer(value: string): SafeHtml | string {
		return this.sanitizer.bypassSecurityTrustHtml(value);
	}

	private _loadArticle(id: number): void {
		this.subscribe(this.articleService.getOne(id).pipe(take(1)), (article) => {
			this.articleSubject.next(article);
		})
	}
}
