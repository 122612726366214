import {ContactSimpleDto, CoreSharedApiBaseService, Product} from '@nexnox-web/core-shared';
import {Injectable, Injector} from '@angular/core';
import {map, Observable} from 'rxjs';
import {tap} from "rxjs/operators";
import {HttpResponse} from "@angular/common/http";

@Injectable()
export class MissionEditorQuestService extends CoreSharedApiBaseService {
  constructor(
    protected injector: Injector
  ) {
    super(injector, Product.TECH, 'editorquest', ['mission']);
  }

  public getQuest<EditorQuestDto>(missionId: string | number): Observable<EditorQuestDto> {
    return this.httpRequestService.get<EditorQuestDto>(`${this.buildPath([missionId])}`).pipe(
      map(response => response.body)
    );
  }

  public getSuggestions<SuggestedEditorsDto>(resourceId: string | number): Observable<SuggestedEditorsDto> {
    return this.httpRequestService.get<SuggestedEditorsDto>(`resource/${resourceId}/suggestededitors`).pipe(
      map(response => response.body)
    );
  }

  public startQuest<StartSearchingEditorDto>(missionId: string | number, functionId: string | number): Observable<HttpResponse<any>> {
    return this.httpRequestService.post<StartSearchingEditorDto>(`mission/${missionId}/editorquest/startsearch`, {functionId});
  }

  public cancelQuest(missionId: string | number): Observable<HttpResponse<any>> {
    return this.httpRequestService.post(`mission/${missionId}/editorquest/cancelsearch`, {});
  }

  public acceptContact<AcceptEditorRequestDto>(missionId: string | number, contactId: string | number): Observable<HttpResponse<any>> {
    return this.httpRequestService.post<AcceptEditorRequestDto>(`mission/${missionId}/editorquest/accept`, {contactId});
  }

  public declineContact<DeclineEditorRequestDto>(missionId: string | number, contactId: string | number): Observable<HttpResponse<any>> {
    return this.httpRequestService.post<DeclineEditorRequestDto>(`mission/${missionId}/editorquest/decline`, {contactId});
  }

  public commissionContact<CommissionEditorDto>(missionId: string | number, contactId: string | number): Observable<ContactSimpleDto> {
    return this.httpRequestService.post<CommissionEditorDto>(`mission/${missionId}/commission`, {contactId}).pipe(
      map(response => response.body)
    );
  }
}
