import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {BehaviorSubject, debounceTime, distinctUntilKeyChanged} from 'rxjs';
import {
  ArticlePreviewSidebarComponent,
  CoreSharedSortableListItem,
  KnowledgeArticleInPathDto,
  KnowledgeArticleSimpleDto,
  ResourceDto,
  SortableListRowAction,
  UnsubscribeHelper
} from '@nexnox-web/core-shared';
import {faTrash} from '@fortawesome/free-solid-svg-icons/faTrash';
import {faPlus} from '@fortawesome/free-solid-svg-icons/faPlus';
import {FormControl, FormGroup} from "@angular/forms";
import {FormlyFieldConfig} from "@ngx-formly/core";
import {faEye} from '@fortawesome/free-solid-svg-icons/faEye';

export interface ArticlesChangePayload {
  knowledgeArticles: KnowledgeArticleSimpleDto[],
  inheritsKnowledge: boolean
}

@Component({
  selector: 'nexnox-web-resources-resource-articles',
  templateUrl: './resource-articles.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ResourceArticlesComponent extends UnsubscribeHelper implements OnInit {

  @ViewChild('articlePreviewSidebar', {static: true}) public articlePreviewSidebar: ArticlePreviewSidebarComponent;

  @Input() public articlesSubject: BehaviorSubject<KnowledgeArticleInPathDto[]>;
  @Input() public resourceSubject: BehaviorSubject<ResourceDto>;
  @Input() public readonly: boolean;
  @Input() public loading: boolean;

  @Output() public articlesChange: EventEmitter<ArticlesChangePayload> = new EventEmitter<ArticlesChangePayload>();

  public hasParent = false;
  public parentResourceName = '';

  public form: FormGroup;
  public fields: FormlyFieldConfig[];
  public model: { inheritsKnowledge: boolean };

  public faTrash = faTrash;
  public faPlus = faPlus;
  public faEye = faEye;


  /* istanbul ignore next */
  public ngOnInit(): void {

    this.form = new FormGroup({inheritsKnowledge: new FormControl()});

    // Check for isInheritSuggestions
    this.subscribe(this.resourceSubject.pipe(distinctUntilKeyChanged('inheritsKnowledge')),
      (resource => {
        this.hasParent = !!resource?.parent?.resourceId;
        this.model = {inheritsKnowledge: !this.hasParent ? false : (resource?.inheritsKnowledge ?? true)}; // Default is true, if parent exists
        this.parentResourceName = this.hasParent ? resource?.parent?.name : '';
      })
    );

    // Emit when inheritsKnowledge changes
    this.subscribe(this.form.valueChanges.pipe(debounceTime(333), distinctUntilKeyChanged('inheritsKnowledge')), () => this.onArticlesChanged());

    this.fields = [
      {
        key: 'inheritsKnowledge',
        type: 'core-portal-switch',
        wrappers: ['core-portal-translated'],
        className: 'col-auto pr-0',
        templateOptions: {
          corePortalTranslated: {
            label: 'resources.actions.inherit-from-parent'
          }
        },
        expressionProperties: {
          'templateOptions.disabled': () => this.readonly || this.loading
        }
      }
    ];
  }

  public onArticlesChanged(knowledgeArticles?: KnowledgeArticleSimpleDto[]): void {
    this.articlesChange.emit({
      knowledgeArticles: knowledgeArticles ?? this.articlesSubject.getValue(),
      inheritsKnowledge: this.model.inheritsKnowledge
    })
  }

  public createArticleActions(): SortableListRowAction[] {
    return [
      {
        icon: faEye,
        tooltip: 'resources.actions.view-article',
        click: (item: CoreSharedSortableListItem) => {
          this.articlePreviewSidebar.knowledgeArticleId = item.getExternalData().knowledgeArticleId;
          this.articlePreviewSidebar.onShow();
        }
      }
    ]
  }
}
