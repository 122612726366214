import {Injectable, Injector} from '@angular/core';
import {CoreSharedApiBaseService, InvitationDto, Product} from '@nexnox-web/core-shared';
import {map} from "rxjs/operators";
import {Observable} from "rxjs";

@Injectable()
export class CorePortalInvitationsService extends CoreSharedApiBaseService {
  constructor(
    protected injector: Injector
  ) {
    super(injector, Product.TECH, 'v2/invitation');
  }

  public revokeInvitation(invitation: InvitationDto):Observable<void> {
    return this.httpRequestService.post<void>(`v2/invitation/${invitation.invitationId}/revoke`, undefined).pipe(
      map(response => response.body)
    );
  }

  public reactivateInvitation(invitation: InvitationDto):Observable<void> {
    return this.httpRequestService.post<void>(`v2/invitation/${invitation.invitationId}/reactivate`, undefined).pipe(
      map(response => response.body)
    );
  }
}
