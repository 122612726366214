import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewChild} from "@angular/core";
import {FormGroup} from "@angular/forms";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import {
  CoreSharedSortableListComponent,
  CoreSharedSortableListItem,
  CrossCreationTypes,
  KnowledgeArticleInPathDto,
  SortableListRowAction,
  UnsubscribeHelper
} from "@nexnox-web/core-shared";
import {FormlyFieldConfig} from "@ngx-formly/core";
import {BindObservable} from "bind-observable";
import {cloneDeep, find, maxBy} from "lodash";
import {BehaviorSubject, Observable} from "rxjs";
import {map} from "rxjs/operators";
import {OrgaPortalKnowledgeArticleService} from "@nexnox-web/orga-portal-lib";

@Component({
	selector: 'nexnox-web-knowledge-paths-articles-edit',
	templateUrl: 'paths-articles-edit.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class PathsArticlesEditComponent extends UnsubscribeHelper implements OnInit {
	@Input() public articlesSubject: BehaviorSubject<KnowledgeArticleInPathDto[]>;
	@Input() public loading: boolean;
	@Input() public actions: SortableListRowAction[];

	@Input() @BindObservable() public readonly: boolean;
	public readonly$!: Observable<boolean>;

	@ViewChild('articlesListEdit') public articlesListEdit: CoreSharedSortableListComponent;

	@Output() public articlesChange = new EventEmitter<any>();

	public articleItems$: Observable<CoreSharedSortableListItem[]>;

	public addArticleForm: FormGroup;
	public addArticleFields: FormlyFieldConfig[];
	public addArticleModelSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

	public faPlus = faPlus;

	constructor(
		private articleService: OrgaPortalKnowledgeArticleService
	) {
		super();
	}

	public ngOnInit(): void {
		this.articleItems$ = this.articlesSubject.asObservable().pipe(
			map(articles => this.mapArticlesToSortableItems(articles))
		);

		this.addArticleForm = new FormGroup({});
		this.addArticleFields = this.createArticleFormFields();
	}

	public onAddArticle(): void {
		const articleToAdd: KnowledgeArticleInPathDto = cloneDeep(this.addArticleModelSubject.getValue()?.article);
		const lastArticle = maxBy(this.articlesSubject.getValue(), 'position');
		const position: number = lastArticle ? lastArticle.position + 1 : 0;

		if (articleToAdd) {
			const newArticleInPath: KnowledgeArticleInPathDto = {
				knowledgeArticleId: articleToAdd.knowledgeArticleId,
				title: articleToAdd.title,
				position
			}
			this.articlesSubject.next([...this.articlesSubject.getValue(), newArticleInPath]);

			this.addArticleModelSubject.next({} as any);
			this.addArticleForm.reset();
			this.addArticleForm.markAsUntouched();

			this.articlesChange.emit(this.articlesSubject.getValue());
		}
	}

	public onReset(): void {
		this.articlesListEdit?.ngOnInit();
	}

	public onArticlesChange(articles: CoreSharedSortableListItem[]): void {
		this.articlesSubject.next(articles.map(article => ({
			...article.getExternalData(),
			position: article.position
		})));
		this.articlesChange.emit(this.articlesSubject.getValue());
	}

	public createArticleFormFields(): any {

		/* istanbul ignore next */
		return [
			{
				key: 'article',
				type: 'core-portal-entity-select',
				wrappers: ['core-portal-translated', 'core-portal-readonly'],
				className: 'col-md-12',
				defaultValue: null,
				templateOptions: {
					corePortalTranslated: {
						label: 'orga-portal.knowledge.subtitles.article-detail',
						validationMessages: {
							required: 'core-portal.core.validation.required',
							duplicate: 'core-portal.core.validation.duplicate'
						}
					},
					entityService: this.articleService,
					idKey: 'knowledgeArticleId',
					displayKey: 'title',
					skipGetOne: true,
					wholeObject: true,
					clearable: false,
          enableCrossCreation: CrossCreationTypes.KNOWLEDGE_ARTICLE
				},
				expressionProperties: {
					'templateOptions.required': () => !this.readonly,
					'templateOptions.readonly': () => this.readonly
				},
				validators: {
					duplicate: control => !find(this.articlesSubject.getValue(), {knowledgeArticleId: control?.value?.knowledgeArticleId})
				}
			}
		]
	}

	/* istanbul ignore next */
	private mapArticlesToSortableItems(articles: KnowledgeArticleInPathDto[]): CoreSharedSortableListItem[] {
		return articles.map((article) => ({
			title: article.title,
			position: article.position,
			getExternalData: () => cloneDeep(article)
		}));
	}


}
