<ng-template #entityEditTemplate let-title="title" let-id="id">
  <nexnox-web-articles-article-edit #modelComponent
                                    [title]="title"
                                    [id]="id"
                                    [model]="model$ | async"
                                    [readonly]="readonly$ | async"
                                    [creating]="false"
                                    [loading]="loading$ | async"
                                    [stereotypes$]="stereotypes$"
                                    (modelChange)="onModelChange($event)">
  </nexnox-web-articles-article-edit>
</ng-template>

<nexnox-web-entity-detail [entityDetailBaseComponent]="this"
                          [entityEditTemplate]="entityEditTemplate"
                          tabId="article"
                          [tabHeading]="title | translate"
                          [tabCommands]="['.']"
                          [ready$]="loaded$">

  <ng-container *ngIf="(model$ | async)?.articleId as articleId">
    <!-- Artikel Usages Tab -->
    <ng-template nexnoxWebTabTemplate
                 tabId="usages"
                 [heading]="'article-usages.title' | translate"
                 (selectTab)="onNavigateToTab(['usages'])">
      <nexnox-web-articles-article-usages-list [parentIds]="[articleId]">
      </nexnox-web-articles-article-usages-list>
    </ng-template>
  </ng-container>

</nexnox-web-entity-detail>
