import {EntityXsStore, EntityXsStoreState} from '@nexnox-web/core-store';
import {AppEntityType, StartingMissionDto} from '@nexnox-web/core-shared';
import {Action, createSelector} from '@ngrx/store';
import {Injectable, Injector} from '@angular/core';
import {selectTicketsState} from "../../tickets.selectors";
import {TicketMissionService} from "../../services";

export interface TicketMissionDetailStoreState extends EntityXsStoreState<StartingMissionDto> {
}

export const ticketMissionDetailStore = new EntityXsStore<StartingMissionDto>({
  actionLabel: 'Tech Portal - Tickets - Ticket Missions Detail',
  stateSelector: createSelector(selectTicketsState, state => state.ticketMissionDetail),
  serviceType: TicketMissionService,
  stereotyped: true,
  entityType: AppEntityType.Mission
});

export function ticketMissionDetailStoreReducer(state: TicketMissionDetailStoreState, action: Action): any {
  return ticketMissionDetailStore.reducer(state, action);
}

@Injectable()
export class TicketMissionDetailStoreEffects extends ticketMissionDetailStore.effects {
  constructor(
    protected injector: Injector
  ) {
    super(injector);
  }
}
