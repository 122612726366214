import { Comparer, EntityAdapter, EntityDefinition, EntityState, IdSelector } from '@ngrx/entity/src/models';
import { createEntityAdapter } from '@ngrx/entity';
import { createStateOperator, DidMutate, selectIdValue } from './adapter-utils';

export interface AdvancedEntityAdapter<T> extends EntityAdapter<T> {
  addOneAt<S extends EntityState<T>>(entity: T, index: number, state: S): S;
}

export function createAdvancedEntityAdapter<T>(
  options: {
    selectId?: IdSelector<T>;
    sortComparer?: false | Comparer<T>
  } = {}
): AdvancedEntityAdapter<T> {
  type R = EntityState<T>;

  const { selectId, sortComparer }: EntityDefinition<T> = {
    sortComparer: false,
    selectId: (instance: any) => instance.id,
    ...options
  };

  function addOneAtMutably(args: { entity: T, index: number }, state: R): DidMutate;
  function addOneAtMutably(args: { entity: any, index: number }, state: any): DidMutate {
    const { entity, index } = args;
    const key = selectIdValue(entity, selectId);

    if (key in state.entities) {
      return DidMutate.None;
    }

    state.ids.splice(index, 0, key);
    state.entities[key] = entity;

    return DidMutate.Both;
  }

  return {
    ...createEntityAdapter<T>(options),
    addOneAt: (entity: T, index: number, state: R) => createStateOperator(addOneAtMutably)({ entity, index }, state)
  };
}
