import { Injectable, Injector } from '@angular/core';
import { AppliedTemplateDto, CoreSharedApiBaseService, DocumentTemplateContextType, Product } from '@nexnox-web/core-shared';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class TechPortalFeatureDocumentTemplateService extends CoreSharedApiBaseService {
  constructor(
    protected injector: Injector
  ) {
    super(injector, Product.TECH, 'documenttemplate');
  }

  public previewTemplate(fileId: number, contextType: DocumentTemplateContextType): Observable<AppliedTemplateDto> {
    return this.httpRequestService.post(`${this.resource}/preview`, { fileId, contextType }).pipe(
      map(response => response.body)
    );
  }
}
