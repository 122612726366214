import { InjectionToken, NgModule } from '@angular/core';
import { ActionReducerMap, StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { contractsEffects, contractsReducers } from './store';

export const REDUCER_TOKEN = new InjectionToken<ActionReducerMap<any>>('@nexnox-web/tech-portal/contracts: Store Reducers');

@NgModule({
  imports: [
    StoreModule.forFeature('tech-portal-feature-contracts', REDUCER_TOKEN),
    EffectsModule.forFeature(contractsEffects)
  ],
  providers: [
    {
      provide: REDUCER_TOKEN,
      useValue: contractsReducers
    }
  ]
})
export class ContractsStoreModule {
}
