import { ChangeDetectionStrategy, Component, Injector } from '@angular/core';
import { CorePortalEntityOverviewBaseComponent, DatatableActionButton } from '@nexnox-web/core-portal';
import { AppEntityType, ContactDto, ControllerOperationId, Mappers } from '@nexnox-web/core-shared';
import { functionContactListStore } from '../../store';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons/faArrowRight';

@Component({
  selector: 'nexnox-web-master-data-functions-contact-list',
  templateUrl: './function-contact-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FunctionContactListComponent extends CorePortalEntityOverviewBaseComponent<ContactDto> {
  public title = 'core-portal.master-data.subtitles.contact-list';
  public createTitle = 'core-portal.master-data.subtitles.contact-create';
  public idProperty = 'contactId';
  public displayProperty = 'displayName';
  public pageOperation = ControllerOperationId.ContactControllerList;
  public componentId = 'FunctionContactListComponent';
  public enableViews = true;
  public datatableConfigName = 'MasterDataContactDefault';

  constructor(
    protected injector: Injector
  ) {
    super(injector, functionContactListStore, Mappers.ContactListDto.serializedName, AppEntityType.Contact);
  }

  /* istanbul ignore next */
  public getRowActionButtons(): DatatableActionButton[] {
    return [
      {
        icon: faArrowRight,
        tooltip: 'core-portal.master-data.actions.edit-contact-area',
        style: 'p-button-primary',
        isolate: true,
        onClick: (row: ContactDto) => this.tenantRouter.navigate(['/masterdata/contacts', row.contactId, 'areas'], {
          module: 'management',
          fragment: !this.readonly ? 'edit' : null
        })
      }
    ];
  }
}
