import { ChangeDetectionStrategy, Component, Injector, Input, OnInit } from '@angular/core';
import { TechPortalFeatureSolutionBaseComponent } from '@nexnox-web/tech-portal/features/solution';
import { missionDetailStore, missionSolutionStore } from '../../store';
import { FileAttachmentForTechDto, MissionType, SolutionMemberType } from '@nexnox-web/core-shared';
import { ofType } from '@ngrx/effects';
import { withLatestFrom } from 'rxjs/operators';
import { select } from '@ngrx/store';
import { cloneDeep, uniqBy } from 'lodash';

@Component({
  selector: 'nexnox-web-missions-mission-solution',
  templateUrl: './mission-solution.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MissionSolutionComponent extends TechPortalFeatureSolutionBaseComponent implements OnInit {
  @Input() public missionType: MissionType;

  public componentId = 'MissionSolutionComponent';

  public solutionMemberType = SolutionMemberType.Mission;
  public missionTypes = MissionType;

  constructor(
    protected injector: Injector
  ) {
    super(injector, missionSolutionStore, [missionDetailStore.actions.changeStateSuccess]);
  }

  /* istanbul ignore next */
  public ngOnInit(): void {
    super.ngOnInit();

    this.subscribe(this.actions$.pipe(
      ofType(missionSolutionStore.actions.createOneSuccess),
      withLatestFrom(this.store.pipe(select(missionDetailStore.selectors.selectEntity))),
      withLatestFrom(this.store.pipe(select(missionDetailStore.selectors.selectModel)))
    ), ([[action, missionEntity], missionModel]) => {
      const newEntity = cloneDeep(missionEntity);
      const newModel = cloneDeep(missionModel);

      if (!action.model?.note?.attachments) return;
      if (!newModel?.solution || !newEntity?.solution) return;

      newEntity.solution.attachments = uniqBy([
        ...(newEntity.solution.attachments ?? []),
        ...(action.model.note.attachments ?? [])
      ], x => (x as FileAttachmentForTechDto).file?.fileId);
      newModel.solution.attachments = uniqBy([
        ...(newModel.solution.attachments ?? []),
        ...(action.model.note.attachments ?? [])
      ], x => (x as FileAttachmentForTechDto).file?.fileId);

      this.store.dispatch(missionDetailStore.actions.entityUpdate({ entity: newEntity }));
      this.store.dispatch(missionDetailStore.actions.modelUpdate({ model: newModel }));
    });
  }
}
