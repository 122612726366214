import { ChangeDetectionStrategy, Component, Injector } from '@angular/core';
import { CorePortalEntityOverviewBaseComponent, DatatableActionButton } from '@nexnox-web/core-portal';
import {AppEntityType, AppPermissions, ControllerOperationId, Mappers, ResourceTaskDto} from '@nexnox-web/core-shared';
import { locationTaskListStore } from '../../store';

@Component({
  selector: 'nexnox-web-master-data-locations-location-task-list',
  templateUrl: './location-task-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LocationTaskListComponent extends CorePortalEntityOverviewBaseComponent<ResourceTaskDto> {
  public title = 'resource-tasks.subtitles.resource-task-list';
  public idProperty = 'resourceTaskId';
  public displayProperty = 'title';
  public datatableConfigName = 'ResourceTaskDefault';
  public componentId = 'LocationTaskListComponent';

  public pageOperation = ControllerOperationId.TaskControllerList;
  public enableViews = true;

  constructor(
    protected injector: Injector
  ) {
    super(injector, locationTaskListStore, Mappers.ResourceTaskListDto.serializedName, AppEntityType.Task);
  }

  public getRowActionButtons(): DatatableActionButton[] {
    return this.getDefaultRowActionButtons(
      'resource-tasks.actions.edit-resource-task',
      resourceTask => `resource-tasks/${resourceTask.resourceTaskId}`,
      [AppPermissions.UpdateResourceTask],
      {
        module: 'inventory'
      }
    );
  }
}
