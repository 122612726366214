import {faAt} from "@fortawesome/free-solid-svg-icons/faAt";
import {faCalendarMinus} from "@fortawesome/free-regular-svg-icons/faCalendarMinus";

export const relativeFilterAllowedProperties = {
  date: { // Date can be always relative
    icon: faCalendarMinus
  },
  reference: {
    allowedIdKeys: ['contactId'],
    icon: faAt
  }
};
