import { CorePortalCCPControlPointService, CorePortalEmbeddedEntityBaseService } from '@nexnox-web/core-portal';
import { Injectable, Injector } from '@angular/core';

@Injectable()
export class CorePortalFeatureResourceControlPointService extends CorePortalEmbeddedEntityBaseService {
  constructor(
    protected injector: Injector
  ) {
    super(injector, CorePortalCCPControlPointService, null, 'controlpoint', ['resource']);
  }
}
