import {ChangeDetectionStrategy, Component, Injector} from '@angular/core';
import {
  AppEntityType,
  ControllerOperationId,
  Mappers,
  OccurredConsumptionProtocolListDto,
} from '@nexnox-web/core-shared';
import {resourceControlProtocolConsumptionsStore} from '../../../store';
import {CorePortalEntityOverviewBaseComponent} from '@nexnox-web/core-portal';

@Component({
  selector: 'nexnox-web-resources-control-protocol-consumptions',
  templateUrl: './control-protocol-consumptions-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ResourceControlProtocolConsumptionsComponent extends CorePortalEntityOverviewBaseComponent<OccurredConsumptionProtocolListDto> {
  public title = 'resources.subtitles.control-protocol-consumptions';
  public idProperty = 'consumption.consumptionCodeId';
  public displayProperty = 'consumption.displayName';
  public componentId = 'ResourceControlProtocolConsumptionsComponent';
  public datatableConfigName = 'ResourceControlProtocolConsumptionsDefault';
  public enableViews = true;
  public pageOperation = ControllerOperationId.OccuredConsumptionProtocol;

  constructor(
    protected injector: Injector
  ) {
    super(injector, resourceControlProtocolConsumptionsStore, Mappers.OccurredConsumptionProtocolListDto.serializedName, AppEntityType.None);
  }
}
