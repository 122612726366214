<nexnox-web-entity-overview #overviewComponent
                            [entityOverviewBaseComponent]="this"
                            [cardBoxHeaderActions]="getCardboxHeaderActions()"
                            detailLink="/resources"
                            module="inventory">
</nexnox-web-entity-overview>

<nexnox-web-location-create-resources-by-definition-sidebar #definitionSidebar
                                                            [locationStereotypeId$]="locationStereotypeId$"
                                                            [locationId]="parentIds[0]"
                                                            (refreshList)="onRefresh()">
</nexnox-web-location-create-resources-by-definition-sidebar>
