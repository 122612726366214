import {ChangeDetectionStrategy, Component, Injector, Input, OnInit} from '@angular/core';
import {CorePortalEntityOverviewBaseComponent} from '@nexnox-web/core-portal';
import {
  AppEntityType,
  ControllerOperationId,
  KnowledgePathProgressContactListDto,
  Mappers
} from '@nexnox-web/core-shared';
import {pathsProgressContactReadUnreadListStore, PathsProgressContactReadUnreadService} from '../../store';
import {cloneDeep, isArray} from "lodash";

@Component({
  selector: 'nexnox-web-paths-path-progress-contact-read-unread-list',
  templateUrl: './paths-progress-contact-read-unread-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PathsProgressContactReadUnreadListComponent extends CorePortalEntityOverviewBaseComponent<KnowledgePathProgressContactListDto> implements OnInit {
  @Input() public customParams: { [key: string]: number[] | string[] };
  @Input() public isRemoveOfExpectedColumn: boolean;

  public title = 'orga-portal.infos.subtitles.info-progress-sidebar-title';
  public idProperty = 'contactId';
  public displayProperty = null;
  public datatableConfigName = 'KnowledgePathsProgressContactsDefault';
  public componentId = 'PathsProgressContactReadUnreadListComponent';
  public enableViews = true;
  public pageOperation = ControllerOperationId.KnowledgePathProgressContactControllerId;
  public isEmbedded = true;

  public initialExcludedColumns: string[];

  constructor(
    protected injector: Injector,
    protected pathsProgressContactReadUnreadService: PathsProgressContactReadUnreadService
  ) {
    super(injector, pathsProgressContactReadUnreadListStore, Mappers.KnowledgePathProgressContactListDto.serializedName, AppEntityType.None);
  }

  public ngOnInit(): void {
    this.pathsProgressContactReadUnreadService.customQueryParams = this.customParams ?? {};

    if (this.parentIds[0]) {
      this.descendantId = Number(this.parentIds[0]);
    }

    super.ngOnInit();

    // Excluded column conditions
    if (!isArray(this.initialExcludedColumns)) {
      this.initialExcludedColumns = cloneDeep(this.datatableConfig[this.datatableConfigName]?.excludedColumns ?? []);
    }

    this.excludedColumns = [...this.initialExcludedColumns, 'locations', 'functions', 'readLocation'];

    if (this.isRemoveOfExpectedColumn) {
      this.excludedColumns.push('isExpected');
    }
  }

}
