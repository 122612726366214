import { EntityXsStore, EntityXsStoreState } from '@nexnox-web/core-store';
import { AppEntityType, CategoryDto } from '@nexnox-web/core-shared';
import { createSelector } from '@ngrx/store';
import { selectCategoriesState } from '../../categories.selectors';
import { OrgaPortalFeatureCategoryService } from '../../services';

export interface CategoryDetailStoreState extends EntityXsStoreState<CategoryDto> {
}

/* istanbul ignore next */
export const categoryDetailStore = new EntityXsStore<CategoryDto>({
  actionLabel: 'Orga Portal - Categories - Category Detail',
  stateSelector: createSelector(selectCategoriesState, state => state.categoryDetail),
  serviceType: OrgaPortalFeatureCategoryService,
  entityType: AppEntityType.None,
  stereotyped: false
});
