import { ModuleWithProviders, NgModule } from '@angular/core';
import { TicketsStoreModule } from './tickets-store.module';
import { TicketsRoutingModule } from './tickets-routing.module';
import { TicketsDeclarationsModule } from './tickets-declarations.module';

@NgModule({
  imports: [
    TicketsDeclarationsModule,
    TicketsRoutingModule,
    TicketsStoreModule
  ]
})
export class TechPortalFeatureTicketsModule {
  public static forFeature(): ModuleWithProviders<TicketsDeclarationsModule> {
    return { ngModule: TicketsDeclarationsModule };
  }
}
