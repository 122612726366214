<nexnox-web-cardbox *ngIf="(resourceSubject.asObservable() | async)?.quickAccess?.link"
                    paddingClasses="p-3"
                    [noTitle]="true">
    <fa-icon [icon]="faInfoCircle" [fixedWidth]="true" class="mr-2"></fa-icon>
    <span [innerHtml]="'resources.descriptions.quick-access-link' | translate"></span>&nbsp;
    <span><a [href]="resourceSubject.value?.quickAccess?.link" target="_blank">{{resourceSubject.value?.quickAccess?.link}}</a></span>
</nexnox-web-cardbox>

<nexnox-web-cardbox [title]="'resources.subtitles.quick-access-options' | translate" paddingClasses="p-3"
                    [loading]="loading">
    <form [formGroup]="form">
        <formly-form class="row w-100"
                     [form]="form"
                     [(model)]="model"
                     [fields]="fields">
        </formly-form>
    </form>
</nexnox-web-cardbox>
