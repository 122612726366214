<ng-template #entityEditTemplate let-title="title">
  <nexnox-web-cancellation-reasons-cancellation-reasons-edit #editComponent
                                                             [title]="title"
                                                             [model]="createModel$ | async"
                                                             [readonly]="false"
                                                             [creating]="true"
                                                             [loading]="(loaded$ | async) === false"
                                                             [stereotyped]="false"
                                                             (modelChange)="createModel$.next($event)">
  </nexnox-web-cancellation-reasons-cancellation-reasons-edit>
</ng-template>

<nexnox-web-entity-overview #overviewComponent
                            [entityOverviewBaseComponent]="this"
                            [entityEditTemplate]="entityEditTemplate"
                            [stereotyped]="false"
                            detailLink="cancellation-reasons"
                            module="settings">
</nexnox-web-entity-overview>
