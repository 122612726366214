import { EntityXsStore, EntityXsStoreState } from '@nexnox-web/core-store';
import { AppEntityType, InventoryNumberPatternDto } from '@nexnox-web/core-shared';
import { Action, createSelector } from '@ngrx/store';
import { selectInventoryNumbersState } from '../../inventory-numbers.selector';
import { CorePortalFeatureInventoryNumberPatternService } from '../../services';
import { Injectable, Injector } from '@angular/core';

export interface InventoryNumberDetailStoreState extends EntityXsStoreState<InventoryNumberPatternDto> {
}

/* istanbul ignore next */
export const inventoryNumberDetailStore = new EntityXsStore<InventoryNumberPatternDto>({
  actionLabel: 'Core Portal - Settings - Inventory Numbers - Inventory Number Detail',
  stateSelector: createSelector(selectInventoryNumbersState, state => state.inventoryNumberDetail),
  serviceType: CorePortalFeatureInventoryNumberPatternService,
  entityType: AppEntityType.None,
  stereotyped: false
});

export function inventoryNumberDetailStoreReducer(state: InventoryNumberDetailStoreState, action: Action): any {
  return inventoryNumberDetailStore.reducer(state, action);
}

@Injectable()
export class InventoryNumberDetailStoreEffects extends inventoryNumberDetailStore.effects {
  constructor(
    protected injector: Injector
  ) {
    super(injector);
  }
}
