import { NgModule } from '@angular/core';
import { CorePortalEntityModule } from '@nexnox-web/core-portal';
import { CoreSharedModule } from '@nexnox-web/core-shared';
import { TechPortalFeatureConnectedConsumptionCodeEditComponent, TechPortalFeatureConsumptionCodeExternalIdComponent } from './components';
import { ConsumptionCodeDetailComponent, ConsumptionCodeListComponent } from './containers';
import { consumptionCodeServices } from './store';

const exportedComponents = [
  TechPortalFeatureConnectedConsumptionCodeEditComponent,

  TechPortalFeatureConsumptionCodeExternalIdComponent
];

@NgModule({
  declarations: [
    ConsumptionCodeDetailComponent,
    ConsumptionCodeListComponent,

    ...exportedComponents
  ],
  imports: [
    CoreSharedModule,
    CorePortalEntityModule
  ],
  exports: [
    ...exportedComponents
  ],
  providers: [
    ...consumptionCodeServices
  ]
})
export class ConsumptionCodesDeclarationsModule {
}
