import { InjectionToken, NgModule } from '@angular/core';
import { ActionReducerMap, StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { controlTagsEffects, controlTagsReducers } from './store';

export const REDUCER_TOKEN = new InjectionToken<ActionReducerMap<any>>(
  '@nexnox-web/tech-portal/ccp/control-tags: Store Reducers'
);

@NgModule({
  imports: [
    StoreModule.forFeature('tech-portal-feature-ccp-control-tags', REDUCER_TOKEN),
    EffectsModule.forFeature(controlTagsEffects)
  ],
  providers: [
    {
      provide: REDUCER_TOKEN,
      useValue: controlTagsReducers
    }
  ]
})
export class ControlTagsStoreModule {
}
