import {
  manufacturerDetailStore,
  ManufacturerDetailStoreState,
  manufacturerListStore,
  ManufacturerListStoreState,
  modelDetailStore,
  ModelDetailStoreState,
  modelListStore,
  ModelListStoreState
} from './stores';

export interface ManufacturerState {
  manufacturerList: ManufacturerListStoreState;
  manufacturerDetail: ManufacturerDetailStoreState;

  modelList: ModelListStoreState;
  modelDetail: ModelDetailStoreState;
}

export const getInitialManufacturerState = (): ManufacturerState => ({
  manufacturerList: manufacturerListStore.getInitialState(),
  manufacturerDetail: manufacturerDetailStore.getInitialState(),

  modelList: modelListStore.getInitialState(),
  modelDetail: modelDetailStore.getInitialState(),
});
