<ng-template #entityEditTemplate let-title="title">
  <nexnox-web-ticket-settings-processes-process-edit #editComponent
                                                     [title]="title"
                                                     [model]="createModel$ | async"
                                                     [readonly]="false"
                                                     [creating]="true"
                                                     [loading]="(loaded$ | async) === false"
                                                     (modelChange)="createModel$.next($event)">
  </nexnox-web-ticket-settings-processes-process-edit>
</ng-template>

<nexnox-web-entity-overview #overviewComponent
                            [entityOverviewBaseComponent]="this"
                            [entityEditTemplate]="entityEditTemplate"
                            [stereotyped]="false"
                            [showSettings]="false"
                            detailLink="/processes"
                            module="settings">
</nexnox-web-entity-overview>
