import {ChangeDetectionStrategy, Component, Injector} from '@angular/core';
import {ActionButton, CorePortalEntityDetailBaseComponent} from '@nexnox-web/core-portal';
import {AppPermissions, SearchListDto} from '@nexnox-web/core-shared';
import {searchListsDetailStore} from '../../store/stores';

@Component({
  selector: 'nexnox-web-settings-searchlists-searchlist-detail',
  templateUrl: './search-lists-detail.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchListsDetailComponent extends CorePortalEntityDetailBaseComponent<SearchListDto> {
  public title = 'core-portal.settings.subtitles.search-list-detail';

  protected idParam = 'searchListId';
  protected displayKey = 'title';

  constructor(
    protected injector: Injector
  ) {
    super(injector, searchListsDetailStore);
  }

  protected async getActionButtons(): Promise<ActionButton[]> {
    return this.getDefaultActionButtons(
      'core-portal.settings.actions.edit-search-list',
      'core-portal.settings.actions.save-search-list',
      'core-portal.settings.actions.delete-search-list',
      'core-portal.settings.descriptions.delete-search-list',
      AppPermissions.UpdateSearchList,
      AppPermissions.DeleteSearchList,
      ['search-lists']
    );
  }
}
