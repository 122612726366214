import { ProductSubscriptionService, TenantService, TenantSystemContactService } from './services';
import { tenantDetailStore, tenantListStore, tenantSubscriptionListStore, tenantSystemContactListStore } from './stores';

export * from './services';
export * from './stores';
export * from './tenants.selectors';
export * from './tenants.state';


export const tenantsReducers = {
  tenantList: tenantListStore.reducer,
  tenantDetail: tenantDetailStore.reducer,
  tenantSubscriptionList: tenantSubscriptionListStore.reducer,
  tenantSystemContactList: tenantSystemContactListStore.reducer
};

export const tenantsEffects = [
  tenantListStore.effects,
  tenantDetailStore.effects,
  tenantSubscriptionListStore.effects,
  tenantSystemContactListStore.effects
];

export const tenantsServices = [
  TenantService,
  ProductSubscriptionService,
  TenantSystemContactService
];
