import {Injectable} from '@angular/core';
import {
  CoreSharedBreadcrumb,
  CoreSharedBreadcrumbsResolver,
  I18nBreadcrumbResolver,
  I18nEntityBreadcrumbResolver
} from '@nexnox-web/core-shared';
import {ActivatedRouteSnapshot} from '@angular/router';
import {select, Store} from '@ngrx/store';
import {catchError, Observable} from 'rxjs';
import {locationAddressDetailStore} from "./../../store";
import {map, withLatestFrom} from "rxjs/operators";
import {TranslateService} from "@ngx-translate/core";


@Injectable({
  providedIn: 'root'
})
export class LocationAddressBreadcrumbsResolver extends CoreSharedBreadcrumbsResolver {
  constructor(
    private store: Store<any>,
    private translate: TranslateService,
    private i18nEntityBreadcrumbResolver: I18nEntityBreadcrumbResolver,
    private i18nBreadcrumbResolver: I18nBreadcrumbResolver
  ) {
    super();
  }

  public resolve(route: ActivatedRouteSnapshot): Observable<CoreSharedBreadcrumb[]> {
    const baseRoute = this.getPathUntil(route, 'locations');
    const locationId = route.paramMap.get('locationId');
    const addressId = route.paramMap.get('addressId');


    return this.store.pipe(
      select(locationAddressDetailStore.selectors.selectEntity),
      withLatestFrom(
        this.translate.stream('core-portal.core.header.breadcrumbs.master-data.location'),
        this.translate.stream('core-portal.core.header.breadcrumbs.master-data.addresses'),
        this.translate.stream(!addressId ? 'core-portal.master-data.actions.create-address' : 'core-portal.core.header.breadcrumbs.master-data.address')
      ),
      map(([address, companyText, addressesText, addressText]) => [
        {
          path: `${baseRoute}/locations/${locationId}`,
          text: this.i18nEntityBreadcrumbResolver.getLocalBreadcrumb(`${baseRoute}/locations/${locationId}`) ?? companyText
        },
        {
          path: `${baseRoute}/locations/${locationId}/addresses`,
          text: addressesText
        },
        {
          path: `${baseRoute}/locations/${locationId}/addresses/${addressId ? addressId : 'create'}`,
          text: address['street'] ?? addressText
        }
      ]),
      catchError(error => this.i18nBreadcrumbResolver.resolve(route))
    );
  }
}
