import {Location} from '@angular/common';
import {Injectable} from '@angular/core';
import {CorePortalCurrentTenantService} from '../../services/current-tenant/current-tenant.service';
import {CorePortalCurrentModuleService} from '../../services/current-module/current-module.service';

@Injectable()
export class CorePortalTenantLocation {
  constructor(
    private location: Location,
    private currentTenantService: CorePortalCurrentTenantService,
    private currentModuleService: CorePortalCurrentModuleService
  ) {
  }

  public replaceState(path: string, module?: string, query?: string, state?: any): void {
    if (path.startsWith('/')) {
      path = path.substring(1);
    }

    this.location.replaceState(
      `/tenant/${this.currentTenantService.getTenantDomain()}/${module ?? this.currentModuleService.getModule()}/${path}`,
      query,
      state
    );
  }
}
