import {ChangeDetectionStrategy, Component, Injector} from '@angular/core';
import {CorePortalEntityOverviewBaseComponent, DatatableActionButton} from '@nexnox-web/core-portal';
import {
  AppEntityType,
  CoreSharedIssueLinkUtilityService,
  Mappers,
  MessageControlListDto
} from '@nexnox-web/core-shared';
import {faCircle} from '@fortawesome/free-solid-svg-icons/faCircle';
import {faExternalLinkAlt} from "@fortawesome/free-solid-svg-icons/faExternalLinkAlt";
import {messageControlIssueListStore} from "../../store";

@Component({
  selector: 'nexnox-web-issues-message-control-issue-list',
  templateUrl: './message-control-issue-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TechPortalFeatureMessageControlIssueListComponent extends CorePortalEntityOverviewBaseComponent<MessageControlListDto> {

  public title = 'issues.subtitles.message-control-issue-list';
  public createTitle = 'issues.subtitles.message-control-issue-create';
  public idProperty = 'issueId';
  public displayProperty = 'issue.title';
  // public pageOperation = ControllerOperationId.MessageControlControllerId;
  public componentId = 'TechPortalFeatureMessageControlIssueListComponent';
  public enableViews = false;
  public datatableConfigName = 'MessageControlIssueDefault';
  public canDelete = false;

  public faCircle = faCircle;

  public detailFn = (row: MessageControlListDto): void => {
    this.tenantRouter.navigate(this.issueUtilityService.getIssueSegments(row)).then()
  };

  constructor(
    protected injector: Injector,
    private issueUtilityService: CoreSharedIssueLinkUtilityService
  ) {
    super(injector, messageControlIssueListStore, Mappers.MessageControlListDto.serializedName, AppEntityType.Issue);
  }

  /* istanbul ignore next */
  public getRowActionButtons(): DatatableActionButton[] {
    return [
      {
        tooltip: 'core-shared.shared.actions.new-tab',
        icon: faExternalLinkAlt,
        link: (row: MessageControlListDto) => this.issueUtilityService.getIssueLink(row),
        module: 'communication',
        target: '_blank'
      }
    ];
  }
}
