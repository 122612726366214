<p-sidebar #sidebarComponent
           position="right"
           appendTo="body"
           styleClass="p-sidebar-md"
           [transitionOptions]="transitionOptions"
           [visible]="visible$ | async"
           (visibleChange)="onVisibleChange($event)">
  <div class="d-flex flex-column align-items-start w-100 h-100">

    <!-- User options -->
    <div class="d-flex flex-row justify-content-end align-items-center w-100 mb-2">
      <button type="button"
              pButton
              class="p-button-text p-button-primary mr-2"
              [label]="'core-portal.core.header.tooltip.change-password' | translate"
              (click)="changePassword.emit()">
      </button>
      <button type="button"
              pButton
              class="p-button-text p-button-primary"
              [label]="'core-portal.core.header.tooltip.logout' | translate"
              (click)="logout.emit()">
      </button>
    </div>

    <!-- User name -->
    <div class="d-flex w-100" *ngIf="activeUser$  | async as user">
      <fa-icon class="flex-shrink-1 mr-3 user-icon" [icon]="faUser"></fa-icon>
      <div>
        <h3 class="mb-0">
          <span>{{ user.displayName }}</span>
        </h3>
        <div class="small" *ngIf="activeTenant$ | async as tenant">
          {{ tenant.name }}
        </div>
      </div>
    </div>

    <div class="w-100">
      <!-- Tenant -->
      <ng-container *ngTemplateOutlet="tenantSelect"></ng-container>

      <!-- Roles -->
      <ng-container *ngIf="activeRoles$ | async as roles">
        <h4 class="mt-4">
          {{ 'core-shared.shared.fields.roles' | translate }}
        </h4>
        <div class="mb-2" *ngFor="let role of roles">
          {{ role?.name }}
        </div>
      </ng-container>

      <!-- Settings -->
      <ng-container *ngTemplateOutlet="languageSelect"></ng-container>
    </div>

    <div class="d-flex flex-grow-1 align-items-end align-self-end text-muted">
      <span *ngIf="activeTenant$ | async as tenant">{{ 'core-shared.shared.fields.tenant-id' | translate }}
        : {{ tenant.tenantId }}</span>
      <br>
      <span *ngIf="version">{{ 'core-portal.core.header.settings.subtitles.version' | translate }}: {{ version }}</span>
    </div>
  </div>
</p-sidebar>

<ng-template #tenantSelect>
  <h4 class="mt-4">
    {{ 'core-shared.shared.fields.tenant' | translate }}
  </h4>
  <nexnox-web-layout-tenant-select id="tenant-select"
                                   class="w-100"
                                   [activeTenant$]="activeTenant$"
                                   [tenantItems$]="tenantItems$"
                                   [embedded]="true">
  </nexnox-web-layout-tenant-select>
</ng-template>

<ng-template #languageSelect>
  <h4 class="mt-4">
    {{ 'core-shared.shared.fields.culture' | translate }}
  </h4>
  <p-dropdown id="language-select"
              styleClass="w-100"
              optionLabel="label"
              optionValue="value"
              [options]="languageOptions$ | async"
              [ngModel]="activeLanguage$ | async"
              (ngModelChange)="onSelectLanguage($event)">
    <ng-template pTemplate="selectedItem">
      <span class="flag-icon flag-icon-{{ (activeLanguageOption$ | async)?.flag }} mr-2"></span>
      <span>{{ (activeLanguageOption$ | async)?.label }}</span>
    </ng-template>

    <ng-template pTemplate="item" let-item>
      <span class="flag-icon flag-icon-{{ item.flag }} mr-2"></span>
      <span>{{ item.label }}</span>
    </ng-template>
  </p-dropdown>
</ng-template>
