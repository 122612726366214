import {ChangeDetectionStrategy, Component, Injector, OnInit} from '@angular/core';
import {contractTicketStore} from '../../store/stores';
import {CorePortalEntityOverviewBaseComponent} from "@nexnox-web/core-portal";
import {AppEntityType, Mappers, TicketListDto} from "@nexnox-web/core-shared";

@Component({
  selector: 'nexnox-web-contracts-contract-tickets',
  templateUrl: './contract-tickets.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContractTicketsComponent extends CorePortalEntityOverviewBaseComponent<TicketListDto> {
  public idProperty = 'ticketId';
  public title = 'tickets.title';
  public displayProperty = 'title';
  public componentId = 'ContractTicketsComponent';
  public datatableConfigName = 'TicketDefault';

  constructor(
    protected injector: Injector
  ) {
    super(injector, contractTicketStore, Mappers.TicketListDto.serializedName, AppEntityType.None);
  }
}
