import { Component, Input, OnInit } from '@angular/core';
import { AppPermissions, CoreSharedBreadcrumb } from '@nexnox-web/core-shared';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { authStore } from '../../../../store/core/auth';

@Component({
  selector: 'nexnox-web-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss']
})
export class BreadcrumbsComponent implements OnInit {
  @Input() public breadcrumbs: CoreSharedBreadcrumb[];
  @Input() public loading: boolean;

  public permissions$: Observable<AppPermissions[]>;

  constructor(
    private store: Store<any>
  ) {
  }

  public ngOnInit(): void {
    this.permissions$ = this.store.pipe(
      select(authStore.selectors.selectPermissions)
    );
  }
}
