import { combineReducers } from '@ngrx/store';
import { rolesEffects, rolesReducers, rolesServices } from './roles';

export * from './roles';

export * from './settings.state';
export * from './settings.selectors';

export const settingsReducers = {
  roles: combineReducers(rolesReducers)
};

export const settingsEffects = [
  ...rolesEffects
];

export const settingsServices = [
  ...rolesServices
];
