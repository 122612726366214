<nexnox-web-cardbox [title]="'tickets.subtitles.ticket-list' | translate"
                    [transparent]="true"
                    paddingClasses="px-3 pb-3"
                    [loading]="loading"
                    [isAccordion]="true"
                    [isCollapsed]="true"
                    [indicatorNum]="(tickets$ | async)?.length"
                    [isAutoExpandOnIndicator]="true">
  <div class="d-flex flex-column">
    <div *ngFor="let ticket of tickets$ | async" class="d-flex flex-row mb-2">
      <fa-icon [icon]="faEnvelope" [fixedWidth]="true" size="sm" class="mr-2"></fa-icon>
      <a class="btn-link"
         [nexnoxWebTenantRouterLink]="['/tickets', ticket.ticketId]"
         module="communication"
         target="_blank">
        {{ ticket.title }}
      </a>
    </div>

    <span *ngIf="!(tickets$ | async)?.length">{{ 'core-shared.shared.table.empty' | translate }}</span>
  </div>
</nexnox-web-cardbox>
