import { PagedEntitiesXsStore, PagedEntitiesXsStoreState } from '@nexnox-web/core-store';
import { AppEntityType, ConsumptionCodeDto } from '@nexnox-web/core-shared';
import { createSelector } from '@ngrx/store';
import { selectConsumptionCodesState } from '../../consumption-codes.selectors' ;
import { TechPortalFeatureConnectedConsumptionCodeService } from '../../services';

export interface ConsumptionCodeListStoreState extends PagedEntitiesXsStoreState<ConsumptionCodeDto> {
}

/* istanbul ignore next */
export const consumptionCodeListStore = new PagedEntitiesXsStore<ConsumptionCodeDto>({
  actionLabel: 'Tech Portal - Connected - Consumption Codes - Consumption Code List',
  stateSelector: createSelector(selectConsumptionCodesState, state => state.consumptionCodeList),
  serviceType: TechPortalFeatureConnectedConsumptionCodeService,
  entityType: AppEntityType.None,
  selectId: consumptionCode => consumptionCode.entity?.consumptionCodeId ?? consumptionCode.model?.consumptionCodeId,
  stereotyped: false
});
