import { ChangeDetectionStrategy, Component, Injector } from '@angular/core';
import { CorePortalEntityOverviewBaseComponent, DatatableActionButton } from '@nexnox-web/core-portal';
import { AppEntityType, ControlPointDto, Mappers } from '@nexnox-web/core-shared';
import { resourceControlPointsStore } from '../../store';

@Component({
  selector: 'nexnox-web-resources-resource-control-points',
  templateUrl: './resource-control-points.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ResourceControlPointsComponent extends CorePortalEntityOverviewBaseComponent<ControlPointDto> {
  public title = 'tech-portal.ccp.control-points.subtitles.list';
  public idProperty = 'controlPointId';
  public displayProperty = 'name';
  public datatableConfigName = 'ControlPointDefault';
  public componentId = 'ResourceControlPointsComponent';

  constructor(
    protected injector: Injector
  ) {
    super(injector, resourceControlPointsStore, Mappers.ControlPointListDto.serializedName, AppEntityType.None);
  }

  public getRowActionButtons(): DatatableActionButton[] {
    return this.getEmbeddedRowActionButtons();
  }
}
