import {
  ResourceTreeViewXsStore,
  ResourceTreeViewXsStoreActions,
  ResourceTreeViewXsStoreState
} from './resource-tree-view.xs-store';
import {Action, ActionReducer, createSelector, select} from '@ngrx/store';
import {selectResourcesState} from '../../resources.selectors';
import {TechPortalFeatureResourceService} from '../../services';
import {Injectable, Injector} from '@angular/core';
import {createEffect, ofType} from '@ngrx/effects';
import {catchError, exhaustMap, filter, map, mergeMap, scan, switchMap, tap, withLatestFrom} from 'rxjs/operators';
import {ResourceDto, ResourceListDto, ResourceTreeListDto} from '@nexnox-web/core-shared';
import {forkJoin, Observable, of} from 'rxjs';
import {PagedEntitiesXsStoreEntity} from '@nexnox-web/core-store';
import {resourceUIEntitiesStore} from '../resource-ui-entities';
import {cloneDeep, flattenDeep, uniq} from 'lodash';
import {asyncForEach} from '@nexnox-web/lodash';
import {CorePortalTenantRouter} from "@nexnox-web/core-portal";

export interface ResourceTreeViewStoreState extends ResourceTreeViewXsStoreState {
}

/* istanbul ignore next */
export const resourceTreeViewStore = new ResourceTreeViewXsStore({
  actionLabel: 'Core Portal - Resources - Resource Tree View',
  stateSelector: createSelector(selectResourcesState, state => state.resourceTreeView),
  selectId: resource => resource.entity?.resourceId ?? resource.model?.resourceId,
  serviceType: TechPortalFeatureResourceService,
  stereotyped: false
});

export function resourceTreeViewStoreReducer(state: ResourceTreeViewStoreState, action: Action): ActionReducer<any> {
  return resourceTreeViewStore.reducer(state, action);
}

@Injectable()
export class ResourceTreeViewStoreEffects extends resourceTreeViewStore.effects {
  public actions: ResourceTreeViewXsStoreActions;

  public getPageSuccess$: any;
  public appendPageSuccess$: any;

  public preload$: any;

  public selectById$: any;
  public selectByIdSuccess$: any;
  public selectByIdError$: any;

  public getResourcesInProgress$: any;
  public getResourcesInProgressSuccess$: any;

  protected service: TechPortalFeatureResourceService;
  protected tenantRouter: CorePortalTenantRouter;

  constructor(
    protected injector: Injector,
  ) {
    super(injector);
    this.tenantRouter = injector.get(CorePortalTenantRouter);
  }

  protected createEffects(): void {
    super.createEffects();

    this.getPage$ = createEffect(() => this.actions$.pipe(
      ofType(this.actions.getPage),
      switchMap(() => this.service.getRoot<ResourceTreeListDto>().pipe(
        map(({items, paging}) => this.actions.getPageSuccess({
          items: items.map(item => ({
            entity: item,
            model: item
          })),
          paging
        })),
        catchError(error => of(this.actions.error({error, action: this.actions.getPage})))
      ))
    ));

    this.getPageSuccess$ = createEffect(() => this.actions$.pipe(
      ofType(this.actions.getPageSuccess),
      tap(action => this.actionCallback(action))
    ), {dispatch: false});

    this.appendPage$ = createEffect(() => this.actions$.pipe(
      ofType(this.actions.appendPage),
      withLatestFrom(this.store.pipe(select(this.selectors.selectPaging))),
      exhaustMap(([_, rootPaging]) => this.service.getRootPage(rootPaging.pageNumber + 1).pipe(
        map(({items, paging}) => this.actions.appendPageSuccess({
          items: items.map(item => ({
            entity: item,
            model: item
          })),
          paging
        }))
      )),
      catchError(error => of(this.actions.error({error, action: this.actions.appendPage})))
    ));

    this.appendPageSuccess$ = createEffect(() => this.actions$.pipe(
      ofType(this.actions.appendPageSuccess),
      tap(action => this.actionCallback(action))
    ), {dispatch: false});

    /* istanbul ignore next */
    this.preload$ = createEffect(() => this.actions$.pipe(
      ofType(this.actions.preload),
      exhaustMap(({resource}) => {

        if (!resource) {
          return [
            this.actions.getPage(),
            this.actions.error({error: 'Invalid resource', action: this.actions.preload})
          ];
        }

        return this.service.getRoot<ResourceTreeListDto>().pipe(
          mergeMap(async ({items: rootItems, paging: rootPaging, filterOptions: rootFilters}) => {
            const observables: Observable<any>[] = [];

            const resourcePathParts = cloneDeep(resource.path);
            const resourcePathPartsWithoutFirst = cloneDeep(resourcePathParts).map((part, index) => ({...part, index}));
            const firstPart = resourcePathPartsWithoutFirst.splice(0, 1)[0];
            const firstPartAsResource: ResourceTreeListDto = {
              resourceId: firstPart?.id,
              name: firstPart?.name,
              type: rootItems?.find(ri => ri.resourceId === firstPart.id)?.type ?? 0,
              currentState: rootItems?.find(ri => ri.resourceId === firstPart.id)?.currentState ?? 0,
              hasChildren: resourcePathParts.length > 1 || resource.hasChildren
            };

            const newRootItems = uniq([
              ...rootItems.map(item => ({entity: item, model: item})),
              {entity: firstPartAsResource, model: firstPartAsResource}
            ]);

            observables.push(of(this.actions.getPageSuccess({
              items: newRootItems,
              paging: {
                ...rootPaging,
                pageSize: newRootItems.length,
                totalPages: newRootItems.length >= rootPaging.totalItems ? 1 : rootPaging.totalPages
              }
            })));

            await asyncForEach(resourcePathPartsWithoutFirst, async ({id, name, index}) => {
              const parentId = resourcePathParts[index - 1]?.id ? resourcePathParts[index - 1].id : undefined;
              const resourcePathPartsStartingFromThis = resourcePathParts.slice(index);

              if (!parentId) {
                return;
              }

              await this.service.getChildRoot(parentId).pipe(
                tap(({items, paging, filterOptions}) => {
                  const partResource: ResourceListDto = {
                    resourceId: id,
                    parentId,
                    type: (items as ResourceTreeListDto[])?.find((ri: ResourceTreeListDto) => ri.resourceId === id)?.type ?? 0,
                    currentState: (items as ResourceTreeListDto[])?.find((ri: ResourceTreeListDto) => ri.resourceId === id)?.currentState ?? 0,
                    name,
                    hasChildren: resourcePathPartsStartingFromThis.length > 1 || resource.hasChildren
                  };

                  const newChildItems = uniq([...items, partResource]);
                  observables.push(of(resourceUIEntitiesStore.actions.getChildRootSuccess({
                    items: newChildItems,
                    paging: {
                      ...paging,
                      pageSize: newChildItems.length,
                      totalPages: newChildItems.length >= paging.totalItems ? 1 : paging.totalPages
                    },
                    filters: filterOptions,
                    parentId
                  })));
                })
              ).toPromise();
            });

            const lastPart = resourcePathParts.length ? resourcePathParts[resourcePathParts.length - 1] : null;
            if (lastPart) {
              await this.service.getChildRoot(lastPart?.id).pipe(
                tap(({items, paging, filterOptions}) => {
                  observables.push(of(resourceUIEntitiesStore.actions.getChildRootSuccess({
                    items,
                    paging,
                    filters: filterOptions,
                    parentId: lastPart?.id
                  })));
                })
              ).toPromise();
            }

            observables.push(of(this.actions.preloadSuccess({ids: resourcePathParts.map(part => part?.id)})));

            return await forkJoin(observables).pipe(
              mergeMap(resourceActions => flattenDeep(resourceActions)),
              scan((all: Action[], current: Action) => [...all, current], [])
            ).toPromise();
          }),
          mergeMap(actions => actions),
          catchError(error => [
            this.actions.getPage(),
            this.actions.error({error, action: this.actions.preload})
          ])
        );
      })
    ));

    this.selectById$ = createEffect(() => this.actions$.pipe(
      ofType(this.actions.selectById),
      switchMap(({resourceId}) => {

        this.store.dispatch(this.actions.getResourcesInProgress());

        if (resourceId === null) {
          return of(this.actions.selectByIdSuccess({resource: null}));
        }

        return this.service.getOne<ResourceDto>(resourceId).pipe(
          map(resource => this.actions.selectByIdSuccess({resource})),
          catchError(error => of(this.actions.error({error, action: this.actions.selectById})))
        )
      })
    ));

    this.selectByIdSuccess$ = createEffect(() => this.actions$.pipe(
      ofType(this.actions.selectByIdSuccess),
      tap(({resource}) => {
        if (resource && !resource.isInProgressSince) {
          this.store.dispatch(this.actions.preload({resource}));
        } else {
          this.store.dispatch(this.actions.getPage()); // get root tree view
        }
      })
    ), {dispatch: false});

    // Redirects on error to resource
    this.selectByIdError$ = createEffect(() => this.actions$.pipe(
      ofType(this.actions.error),
      filter(({action}) => action.type === this.actions.selectById.type),
      map(() => {
        this.tenantRouter.navigate([`/resources`], {module: 'inventory'});
        return this.actions.selectByIdSuccess({resource: null});
      })
    ));

    this.getResourcesInProgress$ = createEffect(() => this.actions$.pipe(
      ofType(this.actions.getResourcesInProgress),
      exhaustMap(() => this.service.getResourcesInProgress(1).pipe(
        map(({items, paging}) => this.actions.getResourcesInProgressSuccess({items, paging}))
      )),
      catchError(error => of(this.actions.error({error, action: this.actions.getResourcesInProgress})))
    ));

    this.getResourcesInProgressSuccess$ = createEffect(() => this.actions$.pipe(
      ofType(this.actions.getResourcesInProgressSuccess)
    ), {dispatch: false});
  }

  protected actionCallback(action: Action, isError: boolean = false): void {
    super.actionCallback(action, isError);

    this.checkAction(this.actions.getPageSuccess, action, ({items}) => this.getPageSuccessActionCallback(items));
    this.checkAction(this.actions.appendPageSuccess, action, ({items}) => this.getPageSuccessActionCallback(items));
  }

  protected getPageSuccessActionCallback(items: PagedEntitiesXsStoreEntity<ResourceTreeListDto>[]): void {
    this.store.dispatch(resourceUIEntitiesStore.actions.upsertMany({items: items.map(item => item.entity)}));
  }
}
