<div class="d-flex">
  <div class="form-control d-flex align-items-baseline"
       [class.receiver-container]="!to.disabled"
       (click)="openSideBar()"
  >
    <div class="text-truncate">
      <ng-container *ngFor="let item of items; let last = last">
        <a class="link"
           style="cursor: pointer !important"
           (click)="navigateTo(item.url)">
          {{ item.name }}
        </a><span>{{ last ? '' : ',&nbsp;' }}</span>
      </ng-container>
    </div>
    <div *ngIf="isShowItemsLength"
         class="small ml-2 text-nowrap">
      ({{ items.length }} {{ 'core-shared.shared.table.total-items' | translate | lowercase }})
    </div>
  </div>
  <button
    class="btn btn-link"
    [hidden]="to.disabled"
    (click)="openSideBar()">
    <fa-icon [icon]="faUserPlus"></fa-icon>
  </button>
</div>

<nexnox-web-formly-receivers-sidebar *ngIf="receiverTabs as tabs"
                                     #receiversSidebar
                                     [items]="items"
                                     [receiverTabs]="tabs"
                                     (accept)="onAccept()">
</nexnox-web-formly-receivers-sidebar>
