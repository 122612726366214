import {ChangeDetectionStrategy, Component, Injector, Input, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {faTimes} from '@fortawesome/free-solid-svg-icons/faTimes';
import {
  CorePortalAttachmentsComponent,
  CorePortalEntityEditBaseComponent,
  CorePortalFormlyActionWrapperTyping,
  CorePortalFormlyReadonlyTypes,
  CorePortalFormlyReadonlyTyping,
  CorePortalFormlyTranslatedTyping,
  CorePortalPermissionService
} from '@nexnox-web/core-portal';
import {
  CorePortalFeatureMasterDataContactService
} from '@nexnox-web/core-portal/features/master-data/features/contacts';
import {
  CorePortalFeatureResourceControlPointService,
  CorePortalFeatureResourceService
} from '@nexnox-web/core-portal/features/resources';
import {
  AppPermissions,
  AttachmentForTechDto,
  ContactDto,
  ControlPointDto,
  CoreSharedModalService,
  FilterDto,
  LinkDto,
  LocationDto,
  MissionDto,
  MissionType,
  RenderedTemplateDto,
  ResourceDto,
  ResourceType,
  StartingMissionByTicketDto,
  StartingMissionContactEditorDto,
  StartingMissionEditorType,
  StereotypeListDto,
  TemplateContextType
} from '@nexnox-web/core-shared';
import {timespanTo} from '@nexnox-web/lodash';
import {TechPortalLinksComponent} from '@nexnox-web/tech-portal-lib';
import {TechPortalFeatureTextTemplateApplySidebarComponent} from '@nexnox-web/tech-portal/features/templates';
import {FormlyFieldConfig} from '@ngx-formly/core';
import {TranslateService} from '@ngx-translate/core';
import dayjs from 'dayjs';
import {BehaviorSubject, Observable} from 'rxjs';
import {distinctUntilChanged, filter, map, mergeMap, skip, startWith, take} from 'rxjs/operators';
import {faSearch} from "@fortawesome/free-solid-svg-icons/faSearch";
import {MissionAssignResourceSidebarComponent} from "../../sidebars";
import {faPencilAlt} from "@fortawesome/free-solid-svg-icons/faPencilAlt";
import {TechPortalFeatureMissionService} from "@nexnox-web/tech-portal/features/missions";


@Component({
  selector: 'nexnox-web-missions-mission-create',
  templateUrl: './mission-create.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TechPortalFeatureMissionCreateComponent extends CorePortalEntityEditBaseComponent<MissionDto> implements OnInit {
  @Input() public missionType: MissionType;
  @Input() public ticketStereotypeId$: Observable<number>;

  @ViewChild('linksComponent') public linksComponent: TechPortalLinksComponent;
  @ViewChild('attachmentsComponent') public attachmentsComponent: CorePortalAttachmentsComponent;
  @ViewChild('resourceDescriptionTemplate', {static: true}) public resourceDescriptionTemplate: TemplateRef<any>;
  @ViewChild('assignResourceSidebar', {static: false}) public assignResourceSidebar: MissionAssignResourceSidebarComponent;
  @ViewChild('textTemplateApplySidebar', {static: true}) public textTemplateApplySidebar: TechPortalFeatureTextTemplateApplySidebarComponent;

  public linksSubject: BehaviorSubject<LinkDto[]> = new BehaviorSubject<LinkDto[]>([]);
  public attachmentsSubject: BehaviorSubject<AttachmentForTechDto[]> = new BehaviorSubject<AttachmentForTechDto[]>([]);

  public location$: Observable<LocationDto>;
  public templateContextType$: Observable<TemplateContextType>;

  public readSolutionPermission$: Observable<boolean>;

  public faTimes = faTimes;

  public isShowResourceAssignmentSidebar = false;

  private selectedResourceIdSubject: BehaviorSubject<number> = new BehaviorSubject<number>(null);

  constructor(
    protected injector: Injector,
    private missionService: TechPortalFeatureMissionService,
    private contactService: CorePortalFeatureMasterDataContactService,
    private translate: TranslateService,
    private permissionService: CorePortalPermissionService,
    private modalService: CoreSharedModalService,
    private resourceService: CorePortalFeatureResourceService,
    private resourceControlPointService: CorePortalFeatureResourceControlPointService
  ) {
    super(injector, 'TechPortalFeatureMissionCreateComponent');

    this.readSolutionPermission$ = this.permissionService.hasPermission$(AppPermissions.ReadSolution);

    this.templateContextType$ = this.modelSubject.asObservable().pipe(
      map(model => {
        switch (model?.type) {
          case MissionType.Ticket:
            return TemplateContextType.MissionByTicketDescription;
          case MissionType.Task:
            return TemplateContextType.MissionByTaskDescription;
          case MissionType.Inspection:
            return TemplateContextType.MissionByInspectionDescription;
          default:
            return TemplateContextType.MissionDescription;
        }
      })
    );
  }

  public ngOnInit(): void {

    if (this.ticketStereotypeId$) {
      this.additionalStereotypeFilterFn = this.ticketStereotypeId$.pipe(
        map(ticketStereotypeId => {
          return (missionType: StereotypeListDto) => this.isMissionTypeAllowedForTicketType(missionType, ticketStereotypeId)
        })
      );
    }

    super.ngOnInit();

    // Location
    this.location$ = this.modelSubject.pipe(map(model => model?.resource?.location ?? (model as any)?.preset?.location ?? null));
  }

  public isMissionTypeAllowedForTicketType(missionType: StereotypeListDto, ticketStereotypeId: number): boolean {
    const isAllowed = (
      missionType?.missionRestrictions?.length > 0 &&
      missionType?.missionRestrictions?.some((x) => x.stereotypeId === ticketStereotypeId) == false
    ) == false;

    return isAllowed;
  }

  public onLinksChange(links: LinkDto[]): void {
    this.setModel({...this.model, links});
    setTimeout(() => this.onModelChange(this.model));
  }

  public onAttachmentsChange(attachments: AttachmentForTechDto[]): void {
    this.setModel({...this.model, attachments});
    setTimeout(() => this.onModelChange(this.model));
  }

  public onResourceChange(resource: ResourceDto): void {
    this.setModel({...this.model, resource});
    setTimeout(() => this.onModelChange(this.model));
  }

  public onTextTemplateApplied(template: RenderedTemplateDto): void {
    this.setModel({...this.model, description: template.content});
    setTimeout(() => this.onModelChange(this.model));
  }

  public onModelChange(model: MissionDto): void {
    this.modelValidSubject.next({
      ...this.modelValidSubject.getValue(),
      links: this.linksComponent ? this.linksComponent.isModelValid() : true,
      attachments: this.attachmentsComponent ? this.attachmentsComponent.isModelValid() : true
    });

    super.onModelChange(model);
  }

  /* istanbul ignore next */
  protected createForm(): FormlyFieldConfig[] {
    return [
      {
        key: 'title',
        type: 'input',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-12',
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.title',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.BASIC
          } as CorePortalFormlyReadonlyTyping,
          type: 'text'
        },
        expressionProperties: {
          'templateOptions.required': () => !this.readonly,
          'templateOptions.disabled': () => this.readonly,
          'templateOptions.readonly': () => this.readonly
        },
        hideExpression: (model: MissionDto) => !this.creating || (this.missionType ?? model?.type) === MissionType.Inspection
      },
      {
        key: 'description',
        type: 'core-portal-editor',
        wrappers: ['core-portal-translated'],
        className: 'col-md-12',
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.description'
          },
          corePortalEditor: {
            language: this.translate.currentLang,
            buttons: [{
              label: 'core-portal.settings.actions.templates.apply-text-template',
              show$: this.permissionService.hasPermission$(AppPermissions.ReadTemplate),
              click: () => this.textTemplateApplySidebar.onShow()
            }]
          }
        },
        expressionProperties: {
          'templateOptions.disabled': () => this.readonly
        },
        hideExpression: (model: MissionDto) => (this.missionType ?? model?.type) === MissionType.Inspection
      },
      {
        key: 'resource',
        type: 'core-portal-entity-select',
        wrappers: ['core-portal-translated', 'core-portal-actions', 'core-portal-readonly'],
        className: 'col-md-6',
        defaultValue: null,
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.resource',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            },
            descriptionTemplate: this.resourceDescriptionTemplate
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.ENTITY,
            displayKey: 'name',
            link: (resource: ResourceDto) => resource?.resourceId ? ['/resources', resource.resourceId] : null,
            module: 'inventory',
            suffix: (model) => model?.resource?.location && model?.resource?.type === ResourceType.Device ? `(${model.resource.location.name})` : '',
          } as CorePortalFormlyReadonlyTyping,
          corePortalActionWrapper: {
            actions: [
              {
                icon: faPencilAlt,
                tooltip: 'core-portal.core.general.edit',
                onClick: () => this._openResourceSidebar()
              }
            ]
          } as CorePortalFormlyActionWrapperTyping,
          entityService: this.resourceService,
          idKey: 'resourceId',
          displayKey: 'name',
          wholeObject: true,
          skipGetOne: true,
          link: (resource: ResourceDto) => resource?.resourceId ? ['/resources', resource.resourceId] : null,
          module: 'inventory',
        },
        expressionProperties: {
          'templateOptions.required': () => true,
          'templateOptions.readonly': () => true
        },
        hooks: {
          onInit: field => this.subscribe(field.formControl.valueChanges.pipe(
            startWith(field.formControl.value),
            distinctUntilChanged()
          ), (resource: ResourceDto) => this.selectedResourceIdSubject.next(resource?.resourceId))
        }
      },
      {key: 'editor.type'},
      {
        key: 'editor.contact',
        type: 'core-portal-entity-select',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-6',
        defaultValue: null,
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.editor'
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.ENTITY,
            displayKey: 'displayName',
            link: (contact: ContactDto) => contact?.contactId ? ['/masterdata', 'contacts', contact.contactId] : null,
            module: 'management',
          } as CorePortalFormlyReadonlyTyping,
          entityService: this.contactService,
          idKey: 'contactId',
          displayKey: 'displayName',
          wholeObject: true,
          skipGetOne: true,
          link: (contact: ContactDto) => contact?.contactId ? ['/masterdata', 'contacts', contact.contactId] : null,
          module: 'management'
        },
        expressionProperties: {
          'templateOptions.disabled': () => this.readonly,
          'templateOptions.readonly': () => this.readonly
        },
        hideExpression: (model: MissionDto) => (model as unknown as StartingMissionByTicketDto)?.editor?.type === StartingMissionEditorType.Function,
        hooks: {
          onChanges: (field) => this.form.get('editor.type').setValue(StartingMissionEditorType.Contact)
        }
      },
      {
        key: 'editor.function',
        type: 'core-portal-label-action',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-6',
        templateOptions: {
          value$: this.modelSubject.asObservable().pipe(
            mergeMap((model) => this.translate.stream('missions.descriptions.mission-quest-suggested',
              {functionName: (model as any)?.editor?.functionProperty?.name ?? (model as any)?.editor?.function?.name}
            ))
          ),
          async: true,
          click: (field) => {
            this.form.get('editor.type').setValue(StartingMissionEditorType.Contact);
            ((this.model as any).editor as StartingMissionContactEditorDto).contact = null;
            (this.model as any).editor.functionProperty = null;
            (this.model as any).editor.function = null;
          },
          icon: faTimes,
          corePortalTranslated: {
            label: 'core-shared.shared.fields.editor',
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.BASIC
          } as CorePortalFormlyReadonlyTyping,
        },
        expressionProperties: {
          'templateOptions.disabled': () => this.readonly,
          'templateOptions.readonly': () => this.readonly
        },
        hideExpression: (model: MissionDto) => (model as unknown as StartingMissionByTicketDto)?.editor?.type !== StartingMissionEditorType.Function
      },
      {
        key: 'controlPoint',
        type: 'core-portal-entity-select',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-6',
        defaultValue: null,
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.control-point',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          } as CorePortalFormlyTranslatedTyping,
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.ENTITY,
            displayKey: 'name',
            link: (controlPoint: ControlPointDto) => controlPoint?.controlPointId ?
              ['/ccp/control-points', controlPoint.controlPointId] : null,
            module: 'settings'
          } as CorePortalFormlyReadonlyTyping,
          corePortalActionWrapper: {
            actions: [{
              icon: faSearch,
              tooltip: 'core-shared.shared.select.advanced-search',
              onClick: () => this._openResourceSidebar()
            }]
          } as CorePortalFormlyActionWrapperTyping,
          entityService: this.resourceControlPointService,
          idKey: 'controlPointId',
          displayKey: 'name',
          wholeObject: true,
          skipGetOne: true,
          defaultToFirst: true,
          link: (controlPoint: ControlPointDto) => controlPoint?.controlPointId ?
            ['/ccp/control-points', controlPoint.controlPointId] : null,
          module: 'settings',
          waitUntil$: this.selectedResourceIdSubject.asObservable().pipe(
            filter(resourceId => Boolean(resourceId)),
            filter(() => (this.missionType ?? this.model?.type) === MissionType.Inspection),
            take(1)
          ),
          refresh$: this.selectedResourceIdSubject.asObservable().pipe(
            distinctUntilChanged(),
            skip(1)
          ),
          overrideGetPage: (pageNumber: number, pageSize: number, filters: FilterDto[]) => {
            const resourceId = this.selectedResourceIdSubject.getValue();
            return this.resourceControlPointService.getPage(
              undefined,
              pageNumber,
              filters,
              undefined,
              undefined,
              undefined,
              pageSize,
              [resourceId]
            ).pipe(take(1)).toPromise();
          }
        },
        expressionProperties: {
          'templateOptions.required': () => !this.readonly,
          'templateOptions.readonly': (model: MissionDto) => this.readonly ||
            (!this.creating && (this.missionType ?? model?.type) === MissionType.Inspection),
          'templateOptions.disabled': (model: MissionDto) => this.readonly ||
            (!this.creating && (this.missionType ?? model?.type) === MissionType.Inspection) ||
            !model?.resource
        },
        hideExpression: (model: MissionDto) => (this.missionType ?? model?.type) !== MissionType.Inspection
      },

      {
        type: 'core-portal-divider',
        className: 'col-md-12'
      },

      {
        key: 'plannedStart',
        type: 'core-portal-date-time-picker',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-6',
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.planned-start',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.DATE,
            format: 'L LT'
          } as CorePortalFormlyReadonlyTyping
        },
        expressionProperties: {
          'templateOptions.required': () => !this.readonly,
          'templateOptions.disabled': this.permissionService.hasPermission$(AppPermissions.UpdatePlannedMissionDates).pipe(
            mergeMap(hasPermission => this.readonlySubject.asObservable().pipe(
              map(readonly => !hasPermission || readonly)
            ))
          ),
          'templateOptions.readonly': () => this.readonly
        },
        hooks: {
          onInit: field => this.subscribe(field.formControl.valueChanges.pipe(
            distinctUntilChanged(),
            skip(!this.creating ? 1 : 0)
          ), (value: string) => {
            let date = dayjs.utc(value);

            if (!value || !date.isValid()) {
              this.form.controls.plannedEnd.setValue(null);
              return;
            }

            if ((this.model as any).offsetByTemplate) {
              const {days, hours, minutes} = timespanTo((this.model as any).offsetByTemplate);
              date = date
                .add(days, 'day')
                .add(hours, 'hour')
                .add(minutes, 'minute');
            }

            this.form.controls.plannedEnd.setValue(date.format());
          })
        }
      },
      {
        key: 'plannedEnd',
        type: 'core-portal-date-time-picker',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-6',
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.planned-end',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.DATE,
            format: 'L LT'
          } as CorePortalFormlyReadonlyTyping
        },
        expressionProperties: {
          'templateOptions.required': () => !this.readonly,
          'templateOptions.disabled': this.permissionService.hasPermission$(AppPermissions.UpdatePlannedMissionDates).pipe(
            mergeMap(hasPermission => this.readonlySubject.asObservable().pipe(
              map(readonly => !hasPermission || readonly)
            ))
          ),
          'templateOptions.readonly': () => this.readonly
        }
      },
      {
        key: 'actualStart',
        type: 'core-portal-date-time-picker',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-6',
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.actual-start'
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.DATE,
            format: 'L LT'
          } as CorePortalFormlyReadonlyTyping
        },
        expressionProperties: {
          'templateOptions.disabled': this.permissionService.hasPermission$(AppPermissions.UpdateActualMissionDates).pipe(
            mergeMap(hasPermission => this.readonlySubject.asObservable().pipe(
              map(readonly => !hasPermission || readonly)
            ))
          ),
          'templateOptions.readonly': () => this.readonly
        },
        hideExpression: (model: MissionDto) => (this.missionType ?? model?.type) === MissionType.Inspection
      },
      {
        key: 'actualEnd',
        type: 'core-portal-date-time-picker',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-6',
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.actual-end'
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.DATE,
            format: 'L LT'
          } as CorePortalFormlyReadonlyTyping
        },
        expressionProperties: {
          'templateOptions.disabled': this.permissionService.hasPermission$(AppPermissions.UpdateActualMissionDates).pipe(
            mergeMap(hasPermission => this.readonlySubject.asObservable().pipe(
              map(readonly => !hasPermission || readonly)
            ))
          ),
          'templateOptions.readonly': () => this.readonly
        },
        hideExpression: (model: MissionDto) => (this.missionType ?? model?.type) === MissionType.Inspection
      },
      ...this.getStereotypeFields()
    ];
  }

  protected setModel(model: MissionDto): void {
    super.setModel(model);

    this.linksSubject.next(model?.links ?? []);
    this.attachmentsSubject.next(model?.attachments ?? []);
  }

  protected setReadonly(readonly: boolean): void {
    super.setReadonly(readonly);

    this.linksComponent?.onReset();
    this.attachmentsComponent?.onReset();
  }

  private _openResourceSidebar(): void {
    // Workaround for saved filters to prevent sidebar from loading table
    // before a location has been initialized
    this.isShowResourceAssignmentSidebar = true;
    setTimeout(() => {
      this.assignResourceSidebar.onShow();
      this.subscribe(this.assignResourceSidebar.visible$, visible => {
        this.isShowResourceAssignmentSidebar = visible;
      });
    });
  }
}
