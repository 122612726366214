import { CoreState, getInitialCoreState } from './core';
import { getInitialSettingsState, SettingsState } from './settings';

export interface CorePortalState {
  core: CoreState;
  settings: SettingsState;
}

export const getInitialCorePortalState = (): CorePortalState => ({
  core: getInitialCoreState(),
  settings: getInitialSettingsState()
});
