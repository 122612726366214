import { Inject, Injector, NgModule, Optional } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CORE_PORTAL_ROOT_GUARD } from './tokens';
import {
  CorePortalActionBarService,
  CorePortalAuthService,
  CorePortalBootstrapService,
  CorePortalContentContainerService, CorePortalCurrentModuleService,
  CorePortalCurrentTenantService, CorePortalPageTitleService,
  CorePortalPermissionService,
  CorePortalTenantLocalStorageService,
  ErrorService
} from './services';
import { _CORE_SHARED_ENVIRONMENT, _CORE_SHARED_PRODUCT, rootModules, sharedModules } from './shared-exports';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { routerReducer, RouterState, StoreRouterConnectingModule } from '@ngrx/router-store';
import {
  CORE_SHARED_ENVIRONMENT,
  CORE_SHARED_HTTP_API_INTERCEPTORS,
  CORE_SHARED_HTTP_AUTHORIZED_INTERCEPTORS,
  CoreSharedModule,
  TimeoutInterceptor
} from '@nexnox-web/core-shared';
import { CorePortalStoreModule } from './core-portal-store.module';
import { CorePortalJwtInterceptor, LanguageInterceptor } from './interceptors';
import { CorePortalAuthGuard } from './guards';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';

export function provideSwal(): any {
  return import('sweetalert2').then(({ default: swal }) => swal.mixin({
    buttonsStyling: false,
    reverseButtons: true,
    showCancelButton: true,
    focusConfirm: true,
    showClass: {
      backdrop: 'swal2-noanimation',
      popup: 'swal2-noanimation',
      icon: 'swal2-noanimation'
    },
    hideClass: {
      backdrop: '',
      popup: '',
      icon: ''
    }
  }));
}

export const importedModules = [
  CommonModule,
  CoreSharedModule.forRoot(_CORE_SHARED_ENVIRONMENT, _CORE_SHARED_PRODUCT),

  StoreModule.forRoot({
    router: routerReducer
  }, {
    runtimeChecks: {
      strictStateImmutability: true,
      strictActionImmutability: true,
      strictStateSerializability: false,
      strictActionSerializability: false
    }
  }),
  EffectsModule.forRoot([]),
  StoreRouterConnectingModule.forRoot({
    routerState: RouterState.Minimal
  }),

  SweetAlert2Module.forRoot({ provideSwal }),

  CorePortalStoreModule
];

const guards = [
  CorePortalAuthGuard
];

@NgModule({
  imports: [
    CommonModule,
    ...importedModules,
    ...sharedModules,
    ...rootModules
  ],
  exports: [
    ...sharedModules
  ],
  providers: [
    CorePortalActionBarService,
    CorePortalBootstrapService,
    CorePortalPermissionService,
    ErrorService,
    CorePortalTenantLocalStorageService,
    CorePortalCurrentTenantService,
    CorePortalCurrentModuleService,
    CorePortalContentContainerService,
    CorePortalAuthService,
    CorePortalPageTitleService,

    ...guards,

    {
      provide: CORE_SHARED_HTTP_API_INTERCEPTORS,
      useClass: LanguageInterceptor,
      deps: [CORE_SHARED_ENVIRONMENT, CorePortalTenantLocalStorageService],
      multi: true
    },
    {
      provide: CORE_SHARED_HTTP_API_INTERCEPTORS,
      useClass: TimeoutInterceptor,
      deps: [CORE_SHARED_ENVIRONMENT],
      multi: true
    },

    {
      provide: CORE_SHARED_HTTP_AUTHORIZED_INTERCEPTORS,
      useClass: LanguageInterceptor,
      deps: [CORE_SHARED_ENVIRONMENT, CorePortalTenantLocalStorageService],
      multi: true
    },
    {
      provide: CORE_SHARED_HTTP_AUTHORIZED_INTERCEPTORS,
      useClass: TimeoutInterceptor,
      deps: [CORE_SHARED_ENVIRONMENT],
      multi: true
    },

    {
      provide: CORE_SHARED_HTTP_AUTHORIZED_INTERCEPTORS,
      useClass: CorePortalJwtInterceptor,
      deps: [Injector],
      multi: true
    }
  ]
})
export class CorePortalRootModule {
  constructor(
    @Optional() @Inject(CORE_PORTAL_ROOT_GUARD) rootGuard: any,
    private bootstrapService: CorePortalBootstrapService
  ) {
    this.bootstrapService.bootstrap();
  }
}
