import { animate, state, style, transition, trigger } from '@angular/animations';

export const settingsMenuItemAnimations = [
  trigger('expandedCollapsed',  [
    state('expanded', style({
      height: '*',
      opacity: 1
    })),
    state('collapsed', style({
      height: 0,
      opacity: 0
    })),
    transition('expanded <=> collapsed', [
      animate('400ms cubic-bezier(0.4, 0.0, 0.2, 1)')
    ])
  ])
];
