<ng-template #entityEditTemplate let-title="title" let-id="id">
  <nexnox-web-cancellation-reasons-cancellation-reasons-edit #modelComponent
                                                             [title]="title"
                                                             [id]="id"
                                                             [model]="model$ | async"
                                                             [readonly]="readonly$ | async"
                                                             [creating]="false"
                                                             [loading]="loading$ | async"
                                                             [stereotyped]="false"
                                                             (modelChange)="onModelChange($event)">
  </nexnox-web-cancellation-reasons-cancellation-reasons-edit>
</ng-template>

<nexnox-web-entity-detail [entityDetailBaseComponent]="this"
                          [entityEditTemplate]="entityEditTemplate"
                          tabId="cancellationReasons"
                          [tabHeading]="title | translate"
                          [tabCommands]="['.']">
</nexnox-web-entity-detail>
