import {PagedEntitiesXsStore, PagedEntitiesXsStoreState} from '@nexnox-web/core-store';
import {AppEntityType, ResourcesByLocationDefinitionListDto} from '@nexnox-web/core-shared';
import {createSelector} from '@ngrx/store';
import {selectStereotypesState} from '../../stereotypes.selector';
import {ResourcesByLocationStereotypeService} from './../../services';

export interface ResourcesByLocationStereotypeListStoreState extends PagedEntitiesXsStoreState<ResourcesByLocationDefinitionListDto> {
}

/* istanbul ignore next */
export const resourcesByLocationStereotypeListStore = new PagedEntitiesXsStore<ResourcesByLocationDefinitionListDto>({
  actionLabel: 'Core Portal - Settings - Stereotypes - Resources By Location Stereotype List',
  stateSelector: createSelector(selectStereotypesState, state => state.resourcesByLocationStereotypeList),
  serviceType: ResourcesByLocationStereotypeService,
  entityType: AppEntityType.None,
  stereotyped: false,
  selectId: item => item?.entity?.resourcesByLocationDefinitionId ?? item?.model?.resourcesByLocationDefinitionId
});
