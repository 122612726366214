<ng-container *ngIf="(mode.asObservable() | async) === missionTableModes.LIST">
    <ng-template #entityEditTemplate let-title="title">
        <nexnox-web-missions-mission-create #editComponent
                                            [title]="(isCreateVisible$ | async) === false ? title : (createTitle$ | async | translate)"
                                            [model]="createModel$ | async"
                                            [readonly]="false"
                                            [creating]="true"
                                            [loading]="(loaded$ | async) === false"
                                            [stereotypes$]="stereotypes$"
                                            [missionType]="createMissionTypeSubject | async"
                                            (modelChange)="createModel$.next($event)">
        </nexnox-web-missions-mission-create>
    </ng-template>

    <nexnox-web-entity-overview #overviewComponent
                                [entityOverviewBaseComponent]="this"
                                [entityEditTemplate]="entityEditTemplate"
                                [cardBoxHeaderActions]="getCardBoxHeaderActions()"
                                [enableCalendarSubscription]="true"
                                detailLink="/missions"
                                module="inventory">
    </nexnox-web-entity-overview>
</ng-container>

<ng-container *ngIf="(mode.asObservable() | async) === missionTableModes.MAP">
    <nexnox-web-missions-mission-map-view [mode]="mode">
    </nexnox-web-missions-mission-map-view>
</ng-container>

<ng-container *ngIf="(mode.asObservable() | async) === missionTableModes.GANTT">
    <nexnox-web-missions-mission-gantt-view [mode]="mode">
    </nexnox-web-missions-mission-gantt-view>
</ng-container>

