import {
  BaseXsStore,
  BaseXsStoreReducerTypes,
  PagedEntitiesXsStore,
  PagedEntitiesXsStoreActions,
  PagedEntitiesXsStoreCreateOnePayload,
  PagedEntitiesXsStoreEffects,
  pagedEntitiesXsStoreSetLoadingForId,
  PagedEntitiesXsStoreState,
  PropsAction
} from '@nexnox-web/core-store';
import { AppEntityType, ContactSimpleDto, CoreSharedApiBaseService, MissionDto, MissionType } from '@nexnox-web/core-shared';
import { Action, createAction, on, props, select } from '@ngrx/store';
import { Injectable, Injector, Type } from '@angular/core';
import { TechPortalFeatureMissionService } from '../../services';
import { createEffect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, groupBy, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import {
  MissionListXsStoreAssignToMePayload,
  MissionListXsStoreAssignToPayload,
  MissionListXsStoreChangeStatePayload,
  MissionListXsStoreChangeStateSuccessPayload,
  MissionListXsStoreExportPayload,
  MissionListXsStoreExportSuccessPayload
} from '../mission-list/mission-list-xs-store.payloads';
import { authStore, CorePortalCCPControlPointService } from '@nexnox-web/core-portal';
import { TranslateService } from '@ngx-translate/core';
import { immerOn } from 'ngrx-immer/store';

export interface MissionGanttListXsStoreActions extends PagedEntitiesXsStoreActions<MissionDto> {
}

export class MissionGanttListXsStore extends PagedEntitiesXsStore<MissionDto> {
  public actions: MissionGanttListXsStoreActions;

  protected createActions(label: string): MissionGanttListXsStoreActions {
    return {
      ...super.createActions(label)
    }
  }

  protected createReducerArray(
    initialState: PagedEntitiesXsStoreState<MissionDto>
  ): BaseXsStoreReducerTypes<PagedEntitiesXsStoreState<MissionDto>, MissionGanttListXsStoreActions>[] {
    return [
      ...super.createReducerArray(initialState)
    ];
  }

  protected createEffects(
    serviceType: Type<CoreSharedApiBaseService>,
    entityType: AppEntityType,
    prepareEntity: (entity: MissionDto) => MissionDto,
    prepareModel: (entity: MissionDto, model: MissionDto) => MissionDto,
    sanitizeModel: (model: MissionDto, entity: MissionDto) => MissionDto,
    ...args
  ): Type<PagedEntitiesXsStoreEffects<MissionDto>> {
    const actions = this.actions;
    const selectors = this.selectors;

    @Injectable()
    class Effects extends PagedEntitiesXsStoreEffects<MissionDto> {

      constructor(
        protected injector: Injector,
        private translate: TranslateService
      ) {
        super(injector, actions, selectors, serviceType, entityType, prepareEntity, prepareModel, sanitizeModel, true);
      }

      protected createEffects(): void {
        super.createEffects();
      }

      protected actionCallback(action: Action, isError: boolean): void {
        super.actionCallback(action, isError);
      }
    }

    return Effects;
  }
}
