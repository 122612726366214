import {
  consumptionCodeDetailStore,
  ConsumptionCodeDetailStoreState,
  consumptionCodeListStore,
  ConsumptionCodeListStoreState
} from './stores';

export interface ConsumptionCodesState {
  consumptionCodeList: ConsumptionCodeListStoreState;
  consumptionCodeDetail: ConsumptionCodeDetailStoreState;
}

export const getInitialConsumptionCodesState = (): ConsumptionCodesState => ({
  consumptionCodeList: consumptionCodeListStore.getInitialState(),
  consumptionCodeDetail: consumptionCodeDetailStore.getInitialState()
});
