import {Injectable, Injector} from '@angular/core';
import {
  CombineOperator,
  CoreSharedApiBaseService,
  Filter,
  FilterOperators,
  FilterTypes,
  LocationDto,
  LocationState,
  PreviewResourcesByLocationDto,
  PreviewTicketsByLocationDto
} from '@nexnox-web/core-shared';
import {Observable, of} from "rxjs";
import {map} from "rxjs/operators";

// No closed locations
export const noClosedLocationsFilter$ = of([{
  type: FilterTypes.DataTransferObject,
  operator: FilterOperators.NotEqual,
  property: 'state',
  value: LocationState.Closed.toString()
}]);

@Injectable()
export class CorePortalLocationService extends CoreSharedApiBaseService {
  constructor(
    protected injector: Injector
  ) {
    super(injector, null, 'location');
  }

  /* istanbul ignore next */
  public static mapSearchFilter(searchFilter: Filter): Filter {
    return ({
      type: FilterTypes.Grouped,
      combinedAs: CombineOperator.Or,
      children: [
        searchFilter,
        {
          type: FilterTypes.DataTransferObject,
          operator: FilterOperators.Contains,
          property: 'locationNumber',
          value: searchFilter.value
        }
      ]
    });
  }

  /* istanbul ignore next */
  public static searchCompare(term: string, item: LocationDto): boolean {
    return item.name?.toLowerCase()?.includes(term.toLowerCase()) ||
      item.locationNumber?.toLowerCase()?.includes(term.toLowerCase());
  }

  public getPreviewResourcesByLocation(stereotypeId: number | string): Observable<PreviewResourcesByLocationDto> {
    return this.httpRequestService.get<PreviewResourcesByLocationDto>(`locationstereotype/${stereotypeId}/previewresourcesbylocation`).pipe(
      map(response => response.body)
    );
  }

  public getPreviewTicketsByLocation(stereotypeId: number | string): Observable<PreviewTicketsByLocationDto> {
    return this.httpRequestService.get<PreviewTicketsByLocationDto>(`locationstereotype/${stereotypeId}/previewticketsbylocation`).pipe(
      map(response => response.body)
    );
  }
}
