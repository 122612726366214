import {
  CompanyAddressDetailService,
  CompanyAddressService,
  CompanyContactService,
  ContactService,
  CorePortalFeatureMasterDataCompanyService
} from './services';
import {
  CompanyAddressListStoreEffects,
  companyAddressListStoreReducer,
  CompanyContactListStoreEffects,
  companyContactListStoreReducer,
  CompanyDetailStoreEffects,
  companyDetailStoreReducer,
  CompanyListStoreEffects,
  companyListStoreReducer,
  companyAddressDetailStoreReducer,
  CompanyAddressAddressDetailStoreEffects
} from './stores';
import {
  CorePortalFeatureMasterDataAddressService
} from "@nexnox-web/core-portal/features/master-data/features/addresses";

export * from './stores';
export * from './services';

export * from './companies.state';
export * from './companies.selectors';

export const companiesReducers = {
  companyList: companyListStoreReducer,
  companyDetail: companyDetailStoreReducer,

  companyContactList: companyContactListStoreReducer,
  companyAddressList: companyAddressListStoreReducer,
  companyAddressDetail: companyAddressDetailStoreReducer
};

export const companiesEffects = [
  CompanyListStoreEffects,
  CompanyDetailStoreEffects,

  CompanyContactListStoreEffects,
  CompanyAddressListStoreEffects,
  CompanyAddressAddressDetailStoreEffects
];

export const companiesServices = [
  CorePortalFeatureMasterDataCompanyService,
  CorePortalFeatureMasterDataAddressService,

  CompanyContactService,
  ContactService,
  CompanyAddressService,
  CompanyAddressDetailService
];
