import { FieldType, FormlyTemplateOptions } from '@ngx-formly/core';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { CoreSharedInputTransformer } from '@nexnox-web/core-shared';
import { flatten } from 'lodash';
import { isObservable, Observable, of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

interface CorePortalFormlyInputGroupInputOptions extends FormlyTemplateOptions {
  corePortalInputGroupInput?: {
    prepend?: string[] | Observable<string[]>;
    append?: string[] | Observable<string[]>;
    withTransform?: boolean; // Only works with strings
  }
}

@Component({
  selector: 'nexnox-web-formly-input-group-input',
  templateUrl: './formly-input-group-input.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormlyInputGroupInputComponent extends FieldType implements OnInit {
  public to: CorePortalFormlyInputGroupInputOptions;

  public prepends$: Observable<string[]>;
  public appends$: Observable<string[]>;
  public transformers$: Observable<CoreSharedInputTransformer[]>;

  public ngOnInit(): void {
    const prependArray = this.to.corePortalInputGroupInput?.prepend ?? [];
    const appendArray = this.to.corePortalInputGroupInput?.append ?? [];

    this.prepends$ = !isObservable(prependArray) ? of(prependArray) : prependArray;
    this.appends$ = !isObservable(appendArray) ? of(appendArray) : appendArray;
    this.transformers$ = of([]);

    if (this.to.corePortalInputGroupInput?.withTransform) {
      this.transformers$ = this.prepends$.pipe(
        map(prepends => this.createTransformers(prepends, 'prepend')),
        mergeMap(prependTransformers => this.appends$.pipe(
          map(appends => this.createTransformers(appends, 'append')),
          map(appendTransformers => prependTransformers.concat(appendTransformers))
        ))
      );
    }
  }

  private createTransformers(values: string[], type: 'append' | 'prepend'): CoreSharedInputTransformer[] {
    return flatten(values.map(removeValue => [
      {
        direction: 'input',
        transformFn: (value: string) => {
          if (!value) {
            return value;
          }

          return value.replace(removeValue, '');
        }
      },
      {
        direction: 'output',
        transformFn: (value: string) => {
          if (!value) {
            return value;
          }

          return type === 'append' ? value + removeValue : removeValue + value;
        }
      }
    ] as CoreSharedInputTransformer[]));
  }
}
