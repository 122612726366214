import {Injector} from '@angular/core';
import {CorePortalDatatableService, LocalDataTableDto} from '../index';
import {select, Store} from '@ngrx/store';
import {ApiNotificationService} from '@nexnox-web/core-shared';
import {HttpErrorResponse} from '@angular/common/http';
import {produce} from 'immer';
import {BehaviorSubject, Observable} from 'rxjs';
import {exhaustMap, map, take} from 'rxjs/operators';
import {cloneDeep} from 'lodash';
import {authStore} from '../../../../../store';
import {CoreStoreObservableStore} from '@nexnox-web/core-store';

export interface EntityDatatableSettingsModalStoreState {
  selectedDatatableView: LocalDataTableDto;
  originalDatatableView: LocalDataTableDto;
  applied: boolean;
  loading: boolean;
  loaded: boolean;
}

export const getInitialEntityDatatableSettingsModalStoreState = (): EntityDatatableSettingsModalStoreState => ({
  selectedDatatableView: null,
  originalDatatableView: null,
  applied: true,
  loading: false,
  loaded: true
});

export class EntityDatatablePresetStore extends CoreStoreObservableStore<EntityDatatableSettingsModalStoreState> {
  public currentPresetTitle: BehaviorSubject<string> = new BehaviorSubject(null);

  private datatableService: CorePortalDatatableService;
  private store: Store<any>;
  private notificationService: ApiNotificationService;

  constructor(
    private injector: Injector
  ) {
    super({
      trackStateHistory: true
    });

    this.datatableService = injector.get(CorePortalDatatableService);
    this.store = injector.get(Store);
    this.notificationService = injector.get(ApiNotificationService);

    this.setState(getInitialEntityDatatableSettingsModalStoreState(), 'INIT_STATE');
  }

  public setCurrentPresetTitle(value: string): void {
    this.currentPresetTitle.next(value);
  }

  public setSelectedDatatableView(datatableView: LocalDataTableDto): void {
    this.setState(produce(this.getState(), draft => {
      draft.selectedDatatableView = datatableView;
      draft.applied = false;
    }), 'SET_SELECTED_DATATABLE_VIEW');
  }

  public updateSelectedDatatableView(datatableView: Partial<LocalDataTableDto>): void {
    this.setState(produce(this.getState(), draft => {
      draft.selectedDatatableView = cloneDeep({...draft.selectedDatatableView, ...datatableView});
      draft.applied = true;
    }), 'UPDATE_SELECTED_DATATABLE_VIEW');
  }

  public setOriginalDatatableView(datatableView: LocalDataTableDto): void {
    this.setState(produce(this.getState(), draft => {
      draft.originalDatatableView = datatableView;
    }), 'SET_ORIGINAL_DATATABLE_VIEW');
  }

  public setApplied(applied: boolean): void {
    this.setState({applied});
  }

  public getOne(id: number, onSuccess?: (datatableView: LocalDataTableDto) => void): void {
    this.datatableService.getOne<LocalDataTableDto>(id)
      .toPromise()
      .then(datatableView => this.getOneSuccess(datatableView, onSuccess))
      .catch(error => this.handleError(error));

    this.setState(produce(this.getState(), draft => {
      draft.loading = true;
    }), 'GET_ONE');
  }

  public getOneSuccess(datatableView: LocalDataTableDto, onSuccess?: (datatableView: LocalDataTableDto) => void): void {
    this.setState(produce(this.getState(), draft => {
      draft.selectedDatatableView = cloneDeep(datatableView);
      draft.applied = true;
      draft.loading = false;
      draft.loaded = true;
    }), 'GET_ONE_SUCCESS');

    if (onSuccess) {
      onSuccess(datatableView);
    }
  }

  public createOne(datatableView: LocalDataTableDto, onSuccess?: (datatableView: LocalDataTableDto) => void): void {
    this.store.pipe(
      select(authStore.selectors.selectTenantId),
      take(1),
      exhaustMap(tenantId => this.datatableService.createOne({
        ...datatableView,
        tenantId
      }))
    ).toPromise()
      .then(apiDatatableView => this.createOneSuccess(apiDatatableView, onSuccess))
      .catch(error => this.handleError(error));

    this.setState(produce(this.getState(), draft => {
      draft.loading = true;
    }), 'CREATE_ONE');
  }

  public createOneSuccess(datatableView: LocalDataTableDto, onSuccess?: (datatableView: LocalDataTableDto) => void): void {
    this.notificationService.showTranslatedSuccess('core-shared.shared.toast.entity-created');

    this.setState(produce(this.getState(), draft => {
      draft.selectedDatatableView = cloneDeep(datatableView);
      draft.originalDatatableView = cloneDeep(datatableView);
      draft.applied = true;
      draft.loading = false;
    }), 'CREATE_ONE_SUCCESS');

    if (onSuccess) {
      onSuccess(datatableView);
    }
  }

  public saveOne(datatableView: LocalDataTableDto, onSuccess?: (datatableView: LocalDataTableDto) => void): void {
    this.datatableService.saveOne(datatableView?.dataTableId, datatableView)
      .toPromise()
      .then(apiDatatableView => this.saveOneSuccess(apiDatatableView, onSuccess))
      .catch(error => this.handleError(error));

    this.setState(produce(this.getState(), draft => {
      draft.loading = true;
    }), 'SAVE_ONE');
  }

  public saveOneSuccess(datatableView: LocalDataTableDto, onSuccess?: (datatableView: LocalDataTableDto) => void): void {
    this.notificationService.showTranslatedSuccess('core-shared.shared.toast.entity-saved');

    this.setState(produce(this.getState(), draft => {
      draft.selectedDatatableView = cloneDeep(datatableView);
      draft.originalDatatableView = cloneDeep(datatableView);
      draft.applied = true;
      draft.loading = false;
    }), 'SAVE_ONE_SUCCESS');

    if (onSuccess) {
      onSuccess(datatableView);
    }
  }

  public deleteOne(onSuccess?: () => void): void {
    const selectedDatatableView = this.getState().selectedDatatableView;

    if (!selectedDatatableView) {
      return;
    }

    this.datatableService.deleteOne(selectedDatatableView.dataTableId)
      .toPromise()
      .then(() => this.deleteOneSuccess(selectedDatatableView, onSuccess))
      .catch(error => this.handleError(error));

    this.setState(produce(this.getState(), draft => {
      draft.loading = true;
    }), 'DELETE_ONE');
  }

  public deleteOneSuccess(datatableView: LocalDataTableDto, onSuccess?: () => void): void {
    this.notificationService.showTranslatedSuccess('core-shared.shared.toast.entity-deleted');

    this.setState(produce(this.getState(), draft => {
      if (datatableView.dataTableId === draft.originalDatatableView?.dataTableId) {
        draft.selectedDatatableView = null;
      } else {
        draft.selectedDatatableView = draft.originalDatatableView;
      }

      draft.originalDatatableView = cloneDeep(draft.selectedDatatableView);
      draft.applied = true;
      draft.loading = false;
    }), 'DELETE_ONE_SUCCESS');

    if (onSuccess) {
      onSuccess();
    }
  }

  public handleError(error: HttpErrorResponse | string): void {
    if (error instanceof HttpErrorResponse) {
      this.notificationService.handleApiError(error);
    } else {
      this.notificationService.showTranslatedError(error);
    }

    this.setState(produce(this.getState(), draft => {
      draft.selectedDatatableView = null;
      draft.loading = false;
      draft.loaded = false;
    }), 'ERROR');
  }

  public selectSelectedDatatableView(): Observable<LocalDataTableDto> {
    return this.stateChanged.pipe(
      map(state => state.selectedDatatableView)
    );
  }

  public selectLoading(): Observable<boolean> {
    return this.stateChanged.pipe(
      map(state => state.loading)
    );
  }

  public selectLoaded(): Observable<boolean> {
    return this.stateChanged.pipe(
      map(state => state.loaded)
    );
  }

  public getSelectedDatatableView(): LocalDataTableDto {
    return this.getState().selectedDatatableView;
  }

  public getOriginalDatatableView(): LocalDataTableDto {
    return this.getState().originalDatatableView;
  }

  public isApplied(): boolean {
    return this.getState().applied;
  }
}
