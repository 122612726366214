import {Injectable} from '@angular/core';
import {IMonitorService, MissionDto, MissionType} from '@nexnox-web/core-shared';
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {TechPortalFeatureMissionService} from "../mission/mission.service";


export interface MissionResourcePreviewPayload {
  missionType: MissionType;
  ticketId?: number
}

@Injectable()
export class MissionResourcePreviewService implements IMonitorService<MissionDto> {

  constructor(private missionService: TechPortalFeatureMissionService) {
  }

  public modifyModel(payload: MissionResourcePreviewPayload, key: string, model: MissionDto): Observable<MissionDto> {

    return this.missionService.getPreviewAfterResourceChange(payload.missionType ?? model?.type, model?.resource?.resourceId, payload.ticketId).pipe(
      map(
        (preview) => ({
          ...model,
          shippingAddress: preview.shippingAddress ?? null,
          invoiceAddress: preview.invoiceAddress ?? null,
          plannedStart: preview.plannedStart ?? null,
          plannedEnd: preview.plannedEnd ?? null,
        })
      )
    );
  }
}
