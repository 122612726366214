import { ChangeDetectionStrategy, Component, Injector, OnInit } from '@angular/core';
import { CorePortalEntityEditBaseComponent, CorePortalFormlyReadonlyTypes, CorePortalFormlyReadonlyTyping } from '@nexnox-web/core-portal';
import { ArticleDto } from '@nexnox-web/core-shared';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { CorePortalFeatureArticleKindService } from '../../store';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { distinctUntilChanged, map, pairwise, startWith } from 'rxjs/operators';
import { isNull, isUndefined } from 'lodash';

@Component({
  selector: 'nexnox-web-articles-article-edit',
  templateUrl: './article-edit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CorePortalFeatureArticleEditComponent extends CorePortalEntityEditBaseComponent<ArticleDto> implements OnInit {
  public unit$: Observable<string>;

  private unitSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  private defaultUnit = '€';

  constructor(
    protected injector: Injector,
    private articleKindService: CorePortalFeatureArticleKindService
  ) {
    super(injector);

    this.unit$ = this.unitSubject.asObservable();
  }

  public ngOnInit(): void {
    super.ngOnInit();

    this.bindSellingPriceToPurchasingPrice();
  }

  /* istanbul ignore next */
  protected createForm(): FormlyFieldConfig[] {
    return [
      {
        key: 'name',
        type: 'input',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-6',
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.name',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.BASIC
          } as CorePortalFormlyReadonlyTyping,
          type: 'text'
        },
        expressionProperties: {
          'templateOptions.required': () => !this.readonly,
          'templateOptions.disabled': () => this.readonly,
          'templateOptions.readonly': () => this.readonly
        },
        hideExpression: () => !this.creating
      },
      {
        key: 'kind',
        type: 'core-portal-entity-select',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: null,
        defaultValue: null,
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.kind',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.ENTITY,
            displayKey: 'name'
          } as CorePortalFormlyReadonlyTyping,
          entityService: this.articleKindService,
          idKey: 'articleKindId',
          displayKey: 'name',
          wholeObject: true,
          skipGetOne: true
        },
        expressionProperties: {
          className: () => this.creating ? 'col-md-4' : 'col-md-10',
          'templateOptions.required': () => !this.readonly,
          'templateOptions.disabled': () => this.readonly,
          'templateOptions.readonly': () => this.readonly
        }
      },
      {
        key: 'number',
        type: 'input',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-2',
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.article-number'
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.BASIC
          } as CorePortalFormlyReadonlyTyping,
          type: 'text'
        },
        expressionProperties: {
          'templateOptions.disabled': () => this.readonly,
          'templateOptions.readonly': () => this.readonly
        }
      },
      {
        key: 'unit',
        type: 'input',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-4',
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.unit',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.BASIC
          } as CorePortalFormlyReadonlyTyping,
          type: 'text'
        },
        expressionProperties: {
          'templateOptions.required': () => !this.readonly,
          'templateOptions.disabled': () => this.readonly,
          'templateOptions.readonly': () => this.readonly
        },
        hooks: {
          onInit: field => this.subscribe(field.formControl.valueChanges.pipe(
            startWith(field.formControl.value),
            distinctUntilChanged()
          ), value => this.unitSubject.next(value))
        }
      },
      {
        key: 'purchasingPrice',
        type: 'core-portal-input-group-input',
        wrappers: ['core-portal-translated'],
        className: 'col-md-4',
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.purchasing-price',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          },
          corePortalInputGroupInput: {
            append: of([this.defaultUnit])
          },
          type: 'number'
        },
        expressionProperties: {
          'templateOptions.required': () => !this.readonly,
          'templateOptions.disabled': () => this.readonly,
          'templateOptions.readonly': () => this.readonly
        }
      },
      {
        key: 'sellingPrice',
        type: 'core-portal-input-group-input',
        wrappers: ['core-portal-translated'],
        className: 'col-md-4',
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.selling-price',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          },
          corePortalInputGroupInput: {
            append: of([this.defaultUnit])
          },
          type: 'number'
        },
        expressionProperties: {
          'templateOptions.required': () => !this.readonly,
          'templateOptions.disabled': () => this.readonly,
          'templateOptions.readonly': () => this.readonly
        }
      },
      ...this.getStereotypeFields()
    ];
  }

  private bindSellingPriceToPurchasingPrice(): void {
    this.subscribe(this.modelSubject.asObservable().pipe(
      map(model => model?.purchasingPrice),
      distinctUntilChanged(),
      pairwise()
    ), ([previousValue, currentValue]) => {
      const sellingPrice = this.model.sellingPrice;

      if (previousValue === sellingPrice || isUndefined(sellingPrice) || isNull(sellingPrice)) {
        this.onModelChange({ ...this.model, sellingPrice: currentValue });
      }
    });
  }
}
