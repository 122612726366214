import { FilledformService, OrgaPortalFeatureTaskService, TaskjobArchiveService, TaskjobService } from './services';
import { taskActiveListStore, taskArchiveListStore, taskDetailStore, taskListStore } from './stores';

export * from './services';
export * from './stores';

export * from './tasks.state';
export * from './tasks.selectors';

export const tasksReducers = {
  taskList: taskListStore.reducer,
  taskDetail: taskDetailStore.reducer,
  taskArchiveList: taskArchiveListStore.reducer,
  taskActiveList: taskActiveListStore.reducer
};

export const tasksEffects = [
  taskListStore.effects,
  taskDetailStore.effects,
  taskArchiveListStore.effects,
  taskActiveListStore.effects
];

export const tasksServices = [
  OrgaPortalFeatureTaskService,
  TaskjobService,
  TaskjobArchiveService,
  FilledformService
];
