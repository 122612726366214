import { ModuleWithProviders, NgModule } from '@angular/core';
import { LocationGroupsRoutingModule } from './location-groups-routing.module';
import { LocationGroupsDeclarationsModule } from './location-groups-declarations.module';
import { LocationGroupsStoreModule } from './location-groups-store.module';

@NgModule({
  imports: [
    LocationGroupsDeclarationsModule,
    LocationGroupsRoutingModule,
    LocationGroupsStoreModule
  ]
})
export class CorePortalFeatureMasterDataLocationGroupsModule {
  public static forFeature(): ModuleWithProviders<LocationGroupsDeclarationsModule> {
    return { ngModule: LocationGroupsDeclarationsModule };
  }
}
