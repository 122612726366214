import { LocationGroupLocationService, CorePortalFeatureMasterDataLocationGroupService } from './services';
import {
  LocationGroupDetailStoreEffects,
  locationGroupDetailStoreReducer,
  LocationGroupListStoreEffects,
  locationGroupListStoreReducer,
  LocationGroupLocationListStoreEffects,
  locationGroupLocationListStoreReducer
} from './stores';

export * from './services';
export * from './stores';

export * from './location-groups.state';
export * from './location-groups.selectors';

export const locationGroupsReducers = {
  locationGroupList: locationGroupListStoreReducer,
  locationGroupDetail: locationGroupDetailStoreReducer,

  locationGroupLocationList: locationGroupLocationListStoreReducer
};

export const locationGroupsEffects = [
  LocationGroupListStoreEffects,
  LocationGroupDetailStoreEffects,

  LocationGroupLocationListStoreEffects
];

export const locationGroupsServices = [
  CorePortalFeatureMasterDataLocationGroupService,

  LocationGroupLocationService
];
