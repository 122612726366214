import {
  CorePortalEntityActionsFacade,
  CorePortalEntityActionsFacadeAddRemoveParentPayload,
  CorePortalEntityActionsFacadeCreateOnePayload,
  CorePortalEntityActionsFacadeDeleteOnePayload,
  CorePortalEntityActionsFacadeGetPagePayload
} from '@nexnox-web/core-portal';
import { DashboardItemDatatableStore } from '../../store';

export class DashboardItemEntityActionsFacade implements CorePortalEntityActionsFacade<any> {
  constructor(
    protected datatableStore: DashboardItemDatatableStore<any>
  ) {
  }

  public getPage({
                   pageNumber,
                   sortOption,
                   filters,
                   pageSize,
                   datatableConfig
                 }: CorePortalEntityActionsFacadeGetPagePayload): void {
    this.datatableStore.getPage(
      pageNumber,
      sortOption,
      filters,
      pageSize,
      datatableConfig
    );
  }

  public clear(): void {
    this.datatableStore.clear();
  }

  public createOne(_: CorePortalEntityActionsFacadeCreateOnePayload<any>): void {
    throw new Error('Not implemented');
  }

  public deleteOne(_: CorePortalEntityActionsFacadeDeleteOnePayload): void {
    throw new Error('Not implemented');
  }

  public addOneToParent(_: CorePortalEntityActionsFacadeAddRemoveParentPayload): void {
    throw new Error('Not implemented');
  }

  public removeOneFromParent(_: CorePortalEntityActionsFacadeAddRemoveParentPayload): void {
    throw new Error('Not implemented');
  }
}
