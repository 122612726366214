import {ChangeDetectionStrategy, Component, Injector, OnInit, ViewChild} from '@angular/core';
import {
  CorePortalCardboxAction,
  CorePortalEntityOverviewBaseComponent,
  DatatableActionButton,
  DeleteEntityModel
} from '@nexnox-web/core-portal';
import {
  AppEntityType,
  AppPermissions,
  ControllerOperationId,
  Mappers,
  ResourceManufacturerDto,
  ResourceModelDto,
  ResourceModelListDto
} from '@nexnox-web/core-shared';
import {modelListStore} from '../../store/stores';
import {PRIMARY_OUTLET} from "@angular/router";
import {MergeModelSidebarComponent, MoveModelSidebarComponent} from "../../sidebars";
import {BehaviorSubject} from "rxjs";
import {faCompressAlt, faExchangeAlt} from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: 'nexnox-web-settings-model-list',
  templateUrl: './model-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModelListComponent extends CorePortalEntityOverviewBaseComponent<ResourceModelListDto> implements OnInit {
  @ViewChild('moveModelSidebar', {static: true}) public moveModelSidebar: MoveModelSidebarComponent;
  @ViewChild('mergeModelSidebar', {static: true}) public mergeModelSidebar: MergeModelSidebarComponent;

  public modelToMergeOrMoveSubject: BehaviorSubject<ResourceModelDto> = new BehaviorSubject<ResourceModelDto>({});

  public title = 'core-portal.settings.subtitles.model-list';
  public createTitle = 'core-portal.settings.actions.create-model';
  public idProperty = 'resourceModelId';
  public displayProperty = 'name';
  public datatableConfigName = 'SettingsModelDefault';
  public componentId = 'ModelListComponent';
  public enableViews = true;
  public pageOperation = ControllerOperationId.ResourceModelInManufacturerControllerId;

  constructor(
    protected injector: Injector
  ) {
    super(injector, modelListStore, Mappers.ResourceModelListDto.serializedName, AppEntityType.None);
  }

  public ngOnInit(): void {
    super.ngOnInit();

    this.descendantId = +this.parentIds[0] ?? undefined;
  }

  public mergeModel(newModel: ResourceModelDto): void {
    if (this.modelToMergeOrMoveSubject.getValue()) {
      this.isDeactivateUnsavedChangesModal = true;
      this.store.dispatch(modelListStore.actions.mergeOne({
        model: this.modelToMergeOrMoveSubject.getValue(),
        newModelId: newModel.resourceModelId
      }));
    }
  }

  public moveModel(newManufacturer: ResourceManufacturerDto): void {
    if (this.modelToMergeOrMoveSubject.getValue()) {
      this.isDeactivateUnsavedChangesModal = true;
      this.store.dispatch(modelListStore.actions.moveOne({
        model: this.modelToMergeOrMoveSubject.getValue(),
        newManufacturerId: newManufacturer.resourceManufacturerId
      }));
    }
  }

  public get headerActions(): CorePortalCardboxAction[] {
    return this.createStandaloneHeaderActions('core-portal.settings.actions.create-model');
  }

  public getRowActionButtons(): DatatableActionButton[] {
    return [
      ...this.getDefaultRowActionButtons(
        'core-portal.settings.actions.edit-model',
        (row: ResourceModelListDto) => `manufacturers/${this.parentIds[0]}/model/${row.resourceModelId}`,
        [AppPermissions.UpdateResourceModel],
        {
          module: 'settings'
        }),
      {
        tooltip: 'core-portal.settings.actions.merge-model',
        permissions: [AppPermissions.MergeResourceModel],
        icon: faCompressAlt,
        onClick: (row) => this.onMergeAction(row),
        show: () => this.canDelete
      },
      {
        tooltip: 'core-portal.settings.actions.move-model',
        permissions: [AppPermissions.MergeResourceModel],
        icon: faExchangeAlt,
        onClick: (row) => this.onMoveAction(row),
        show: () => this.canDelete
      },
    ];
  }

  public getDeleteEntityModel(): DeleteEntityModel {
    return {
      titleKey: 'core-portal.settings.actions.delete-model',
      descriptionKey: 'core-portal.settings.descriptions.delete-model',
      confirmKey: 'core-portal.settings.actions.delete-model',
      deletePermission: AppPermissions.DeleteResourceModel
    };
  }

  public gotoStandaloneCreate(): void {
    const url = this.router.parseUrl(this.router.url).root.children[PRIMARY_OUTLET].segments.map((s) => s.path);
    this.router.navigate([...url, 'create']).then();
  }


  private async onMoveAction(row: any): Promise<void> {
    if (row?.resourceModelId) {
      this.modelToMergeOrMoveSubject.next(row);
      this.moveModelSidebar.onShow();
    }
  }

  private async onMergeAction(row: any): Promise<void> {
    if (row?.resourceModelId) {
      this.modelToMergeOrMoveSubject.next(row);
      this.mergeModelSidebar.onShow();
    }
  }
}
