import {PagedEntitiesXsStore, PagedEntitiesXsStoreState} from '@nexnox-web/core-store';
import {AppEntityType, ArticleUsageListDto} from '@nexnox-web/core-shared';
import {Action, ActionReducer, createSelector} from '@ngrx/store';
import {selectArticlesState} from '../../articles.selector';
import {CorePortalFeatureArticleUsagesService} from '../../services';
import {Injectable, Injector} from '@angular/core';

export interface ArticleUsagesListStoreState extends PagedEntitiesXsStoreState<ArticleUsageListDto> {
}

/* istanbul ignore next */
export const articleUsagesListStore = new PagedEntitiesXsStore<ArticleUsageListDto>({
  actionLabel: 'Core Portal - Articles - Article Usages List',
  stateSelector: createSelector(selectArticlesState, state => state.articleUsagesList),
  serviceType: CorePortalFeatureArticleUsagesService,
  entityType: AppEntityType.None,
  stereotyped: false,
  selectId: article => article.entity?.articleUsageId ?? article.model?.articleUsageId
});

export function articleUsagesListStoreReducer(state: ArticleUsagesListStoreState, action: Action): ActionReducer<any> {
  return articleUsagesListStore.reducer(state, action);
}

@Injectable()
export class ArticleUsagesListStoreEffects extends articleUsagesListStore.effects {
  constructor(
    protected injector: Injector
  ) {
    super(injector);
  }
}
