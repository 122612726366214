<ng-template #entityEditTemplate let-title="title">
    <nexnox-web-master-functions-function-edit #editComponent
                                               [title]="title"
                                               [model]="createModel$ | async"
                                               [readonly]="false"
                                               [creating]="true"
                                               [loading]="(loaded$ | async) === false"
                                               [stereotypes$]="stereotypes$"
                                               (modelChange)="createModel$.next($event)">
    </nexnox-web-master-functions-function-edit>
</ng-template>

<nexnox-web-entity-overview #overviewComponent
                            [entityOverviewBaseComponent]="this"
                            [entityEditTemplate]="entityEditTemplate"
                            detailLink="/masterdata/functions"
                            module="management">
</nexnox-web-entity-overview>
