
import { ModuleWithProviders, NgModule } from '@angular/core';
import { ConsumptionCodesDeclarationsModule } from './consumption-codes-declarations.module';
import { ConsumptionCodesRoutingModule } from './consumption-codes-routing.module';
import { ConsumptionCodesStoreModule } from './consumption-codes-store.module';

@NgModule({
  imports: [
    ConsumptionCodesDeclarationsModule,
    ConsumptionCodesRoutingModule,
    ConsumptionCodesStoreModule
  ]
})
export class TechPortalFeatureConnectedConsumptionCodesModule {
  public static forFeature(): ModuleWithProviders<ConsumptionCodesDeclarationsModule> {
    return { ngModule: ConsumptionCodesDeclarationsModule };
  }
}