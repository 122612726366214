<ng-template #entityEditTemplate let-title="title" let-id="id">
    <nexnox-web-master-data-location-groups-location-group-edit #modelComponent
                                                                [title]="title"
                                                                [id]="id"
                                                                [model]="model$ | async"
                                                                [readonly]="(readonly$ | async) || (model$ | async)?.isSystem"
                                                                [creating]="false"
                                                                [loading]="loading$ | async"
                                                                [stereotypes$]="stereotypes$"
                                                                (modelChange)="onModelChange($event)">
    </nexnox-web-master-data-location-groups-location-group-edit>
</ng-template>

<nexnox-web-entity-detail [entityDetailBaseComponent]="this"
                          [entityEditTemplate]="entityEditTemplate"
                          tabId="locationGroup"
                          [tabHeading]="title | translate"
                          [tabCommands]="['.']">
  <ng-container *ngIf="(loading$ | async) === false">
    <div *ngIf="(model$ | async)?.isSystem" class="alert alert-warning">
      <fa-icon [icon]="faExclamationTriangle" [fixedWidth]="true"></fa-icon>
      {{ 'core-portal.master-data.location-group.descriptions.is-system' | translate }}
    </div>
  </ng-container>

  <!-- Locations Tab -->
  <ng-template nexnoxWebTabTemplate
               tabId="locations"
               [heading]="'core-portal.master-data.subtitles.location-list' | translate"
               [lazy]="true"
               (selectTab)="onNavigateToTab(['locations'])">
    <nexnox-web-master-data-location-groups-location-group-location-list *ngIf="passedIds"
                                                                         [isEmbedded]="true"
                                                                         [parentIds]="passedIds">
    </nexnox-web-master-data-location-groups-location-group-location-list>
  </ng-template>
</nexnox-web-entity-detail>
