import {PagedEntitiesXsStore, PagedEntitiesXsStoreState} from '@nexnox-web/core-store';
import {AppEntityType, KnowledgePathProgressContactListDto} from '@nexnox-web/core-shared';
import {createSelector} from '@ngrx/store';
import {selectKnowledgePathState} from '../../paths.selectors';
import {PathsProgressContactReadUnreadService} from '../../services';

export interface PathsProgressContactReadUnreadListStoreState extends PagedEntitiesXsStoreState<KnowledgePathProgressContactListDto> {
}

/* istanbul ignore next */
export const pathsProgressContactReadUnreadListStore = new PagedEntitiesXsStore<KnowledgePathProgressContactListDto>({
  actionLabel: 'Orga Portal - Paths - Path Progress Contact Read Unread List',
  stateSelector: createSelector(selectKnowledgePathState, state => state.pathsProgressContactReadUnreadList),
  serviceType: PathsProgressContactReadUnreadService,
  entityType: AppEntityType.None,
  defaultPageSize: 10,
  selectId: pathsProgressContact => JSON.stringify([
    pathsProgressContact.entity?.contactId ?? pathsProgressContact?.model?.contactId
  ]),
  stereotyped: false
});
