import { PagedEntitiesXsStore, PagedEntitiesXsStoreState } from '@nexnox-web/core-store';
import { AppEntityType, ContractDto } from '@nexnox-web/core-shared';
import { createSelector } from '@ngrx/store';
import { selectLocationsState } from '../../locations.selectors';
import { LocationContractService } from '../../services';

export interface LocationContractListStoreState extends PagedEntitiesXsStoreState<ContractDto> {
}

/* istanbul ignore next */
export const locationContractListStore = new PagedEntitiesXsStore<ContractDto>({
  actionLabel: 'Core Portal - Master Data - Location - Contract List',
  stateSelector: createSelector(selectLocationsState, state => state.locationContractList),
  serviceType: LocationContractService,
  entityType: AppEntityType.Contract,
  selectId: contract => contract.entity?.contractId ?? contract.model?.contractId
});
