import { CorePortalEntitySelectorsFacade } from '@nexnox-web/core-portal';
import { DashboardItemDatatableStore } from '../../store';
import { NEVER, Observable, of } from 'rxjs';
import { PagedEntitiesXsStoreEntity, PagedEntitiesXsStoreEntityData } from '@nexnox-web/core-store';
import { Filter, Paging, SortObject, StereotypeDto } from '@nexnox-web/core-shared';

export class DashboardItemEntitySelectorsFacade implements CorePortalEntitySelectorsFacade<any> {
  constructor(
    protected datatableStore: DashboardItemDatatableStore<any>
  ) {
  }

  public selectEntities(): Observable<PagedEntitiesXsStoreEntity<any>[]> {
    return this.datatableStore.selectEntities();
  }

  public selectPaging(): Observable<Paging> {
    return this.datatableStore.selectPaging();
  }

  public selectSort(): Observable<SortObject> {
    return of(null);
  }

  public selectFilters(): Observable<Filter[]> {
    return this.datatableStore.selectFilters();
  }

  public selectEntityData(): Observable<PagedEntitiesXsStoreEntityData> {
    return this.datatableStore.selectEntityData();
  }

  public selectLoading(): Observable<boolean> {
    return this.datatableStore.selectLoading();
  }

  public selectLoaded(): Observable<boolean> {
    return this.datatableStore.selectLoaded();
  }

  public selectAppendLoading(): Observable<boolean> {
    return of(false);
  }

  public selectLoadedAt(): Observable<string> {
    return of(null);
  }

  public selectHasError(): Observable<boolean> {
    return of(false);
  }

  public selectStereotypes(): Observable<StereotypeDto[]> {
    return this.datatableStore.selectStereotypes();
  }

  public selectStereotypesLoaded(): Observable<boolean> {
    return this.datatableStore.selectStereotypesLoaded();
  }

  /* Callbacks */

  public selectCreateOneSuccess(): Observable<{ entity: any; model: any }> {
    return NEVER;
  }
}
