import { EntityXsStore, EntityXsStoreState } from '@nexnox-web/core-store';
import { AppEntityType, AppFunctions, AppRoleDto } from '@nexnox-web/core-shared';
import { Action, createSelector } from '@ngrx/store';
import { LocalAppRoleDto, CorePortalRoleService } from '../../services';
import { Injectable, Injector } from '@angular/core';
import { selectRolesState } from '../../roles.selectors';

export interface RoleDetailStoreState extends EntityXsStoreState<AppRoleDto, LocalAppRoleDto> {
}

/* istanbul ignore next */
export const roleDetailStore = new EntityXsStore<AppRoleDto, LocalAppRoleDto, RoleDetailStoreState>({
  actionLabel: 'Core Portal - Settings - Role Detail',
  stateSelector: createSelector(selectRolesState, state => state.roleDetail),
  serviceType: CorePortalRoleService,
  entityType: AppEntityType.Role,
  stereotyped: false,
  prepareEntity: CorePortalRoleService.prepareEntity,
  prepareModel: CorePortalRoleService.prepareModel,
  sanitizeModel: CorePortalRoleService.sanitizeModel
});

export function roleDetailStoreReducer(state: RoleDetailStoreState, action: Action): any {
  return roleDetailStore.reducer(state, action);
}

@Injectable()
export class RoleDetailStoreEffects extends roleDetailStore.effects {
  constructor(
    protected injector: Injector
  ) {
    super(injector);
  }
}
