import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {ResourceTreeListDto} from '@nexnox-web/core-shared';
import {
  BaseXsStore,
  BaseXsStoreActions,
  BaseXsStoreOptions,
  BaseXsStoreReducerTypes,
  EmptyAction,
  PropsAction
} from '@nexnox-web/core-store';
import {
  ResourceEntitiesXsStoreAddManyPayload,
  ResourceEntitiesXsStoreRemoveOnePayload
} from './resource-entities-xs-store.payload';
import {createAction, createSelector, MemoizedSelector, on, props} from '@ngrx/store';
import {EntitySelectors} from '@ngrx/entity/src/models';

export interface ResourceEntitiesXsStoreState extends EntityState<ResourceTreeListDto> {
}

export interface ResourceEntitiesXsStoreActions extends BaseXsStoreActions {
  addMany: PropsAction<ResourceEntitiesXsStoreAddManyPayload>;
  removeOne: PropsAction<ResourceEntitiesXsStoreRemoveOnePayload>;
  clear: EmptyAction;
}

export interface ResourceEntitiesXsStoreSelectors extends EntitySelectors<ResourceTreeListDto, ResourceEntitiesXsStoreState> {
  selectFiltered: (ids: Array<string | number>) => MemoizedSelector<ResourceEntitiesXsStoreState, ResourceTreeListDto[]>;
  selectChildren: (parentId: number) => MemoizedSelector<ResourceEntitiesXsStoreState, ResourceTreeListDto[]>;
}

export class ResourceEntitiesXsStore extends BaseXsStore<ResourceEntitiesXsStoreState> {
  public actions: ResourceEntitiesXsStoreActions;
  public selectors: ResourceEntitiesXsStoreSelectors;
  public adapter: EntityAdapter<ResourceTreeListDto>;

  constructor(
    protected options: BaseXsStoreOptions<ResourceEntitiesXsStoreState>
  ) {
    super(options);

    this.adapter = this.createAdapter();
  }

  public getInitialState(): ResourceEntitiesXsStoreState {
    if (!this.adapter) {
      this.adapter = this.createAdapter();
    }

    return {
      ...this.adapter.getInitialState(),
      ...super.getInitialState()
    };
  }

  protected createActions(label: string): ResourceEntitiesXsStoreActions {
    return {
      ...super.createActions(label),

      addMany: createAction(
        BaseXsStore.getType(label, 'Add many'),
        props<ResourceEntitiesXsStoreAddManyPayload>()
      ),

      removeOne: createAction(
        BaseXsStore.getType(label, 'Remove One'),
        props<ResourceEntitiesXsStoreRemoveOnePayload>()
      ),

      clear: createAction(BaseXsStore.getType(label, 'Clear'))
    };
  }

  protected createReducerArray(
    initialState: ResourceEntitiesXsStoreState
  ): BaseXsStoreReducerTypes<ResourceEntitiesXsStoreState, ResourceEntitiesXsStoreActions>[] {
    return [
      ...super.createReducerArray(initialState),

      on(this.actions.addMany, (state, { resources }) => this.adapter.upsertMany(resources, state)),
      on(this.actions.removeOne, (state, { resourceId }) => this.adapter.removeOne(resourceId, state)),
      on(this.actions.clear, () => this.getInitialState())
    ];
  }

  /* istanbul ignore next */
  protected createSelectors(): ResourceEntitiesXsStoreSelectors {
    const stateSelector = this.options.stateSelector;
    const adapterSelectors = this.adapter.getSelectors(stateSelector);

    return {
      ...super.createSelectors(),
      ...adapterSelectors,

      selectFiltered: (ids: Array<string | number>) => createSelector(
        adapterSelectors.selectAll,
        all => all.filter(x => ids.includes(x.resourceId))
      ),

      selectChildren: (parentId: number) => createSelector(
        adapterSelectors.selectAll,
        all => all.filter(x => x.parentId === parentId)
      )
    };
  }

  protected createAdapter(): EntityAdapter<ResourceTreeListDto> {
    return this.adapter ?? createEntityAdapter({
      selectId: resource => resource.resourceId,
      sortComparer: undefined
    });
  }
}
