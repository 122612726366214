import { formDetailStore, FormDetailStoreState, formListStore, FormListStoreState } from './stores';

export interface FormsState {
  formList: FormListStoreState;
  formDetail: FormDetailStoreState;
}

export const getInitialFormsState = (): FormsState => ({
  formList: formListStore.getInitialState(),
  formDetail: formDetailStore.getInitialState()
});
