import { FormlyFieldConfig } from '@ngx-formly/core';
import { CorePortalFormlyReadonlyTypes, CorePortalFormlyReadonlyTyping } from '@nexnox-web/core-portal';

/* istanbul ignore next */
export function getFormlyPreSelectedField(
  key: string,
  label: string,
  fieldConfig: FormlyFieldConfig,
  readonlyFn: () => boolean,
  required: boolean = true,
  defaultIsOverridable: boolean = false,
  disableOverridableFn: () => boolean = () => false
): FormlyFieldConfig[] {
  return [
    {
      ...fieldConfig,
      key: `${key}.value`,
      className: 'col-md-8',
      templateOptions: {
        ...(fieldConfig.templateOptions ?? {}),
        corePortalTranslated: {
          label,
          labelClass: 'd-none d-md-block',
          validationMessages: {
            required: 'core-portal.core.validation.required'
          }
        }
      },
      expressionProperties: {
        'templateOptions.required': (_, __, field) => required ? (!readonlyFn() && !field.form.get(`${key}.isOverridable`)?.value) : false,
        'templateOptions.readonly': () => readonlyFn(),
        'templateOptions.disabled': () => readonlyFn(),
        ...(fieldConfig.expressionProperties ?? {})
      }
    },
    {
      key: `${key}.isOverridable`,
      type: 'core-portal-switch',
      wrappers: ['core-portal-translated', 'core-portal-readonly'],
      className: 'col-md-4',
      defaultValue: defaultIsOverridable,
      templateOptions: {
        corePortalTranslated: {
          label: 'core-shared.shared.fields.is-overridable'
        },
        corePortalReadonly: {
          type: CorePortalFormlyReadonlyTypes.BOOLEAN
        } as CorePortalFormlyReadonlyTyping
      },
      expressionProperties: {
        'templateOptions.readonly': () => readonlyFn(),
        'templateOptions.disabled': () => readonlyFn() || disableOverridableFn()
      }
    }
  ];
}
