import {ChangeDetectionStrategy, Component, Injector} from '@angular/core';
import {CorePortalEntityOverviewBaseComponent, DatatableActionButton} from '@nexnox-web/core-portal';
import {AppEntityType, AppPermissions, Mappers} from '@nexnox-web/core-shared';
import {LocalOccurredErrorListDto, resourceOccurredErrorsListStore} from '../../../store';

@Component({
  selector: 'nexnox-web-resources-resource-occurred-errors',
  templateUrl: './occurred-errors-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ResourceOccurredErrorsComponent extends CorePortalEntityOverviewBaseComponent<LocalOccurredErrorListDto> {
  public title = 'resources.subtitles.occurred-errors';
  public idProperty = 'errorCodeId';
  public displayProperty = 'displayName';
  public componentId = 'ResourceOccurredErrorsComponent';
  public datatableConfigName = 'ResourceOccurredErrorsDefault';

  constructor(
    protected injector: Injector
  ) {
    super(injector, resourceOccurredErrorsListStore, Mappers.OccurredErrorListDto.serializedName, AppEntityType.None);
  }

  public getRowActionButtons(): DatatableActionButton[] {
    return [
      ...this.getDefaultRowActionButtons(
        'tech-portal.connected.error-codes.actions.edit',
        occurredError => occurredError?.errorCodeId ? `connected/error-codes/${occurredError.errorCodeId}` : null,
        [AppPermissions.UpdateResourceTask],
        {
          module: 'settings'
        }
      )
    ];
  }
}
