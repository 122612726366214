import {ChangeDetectionStrategy, Component, Input, ViewChild} from '@angular/core';
import {Observable} from 'rxjs';
import {AppPermissions, IssueSimpleDto} from '@nexnox-web/core-shared';
import {faInbox} from "@fortawesome/free-solid-svg-icons/faInbox";
import {IssueDetailSidebarComponent} from '../../sidebars';
import {issueTypeEnumOptions} from "../../models";
import {CorePortalCardboxAction} from "@nexnox-web/core-portal";

@Component({
  selector: 'nexnox-web-mission-ticket-issue-status',
  templateUrl: './mission-ticket-issue-status.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MissionTicketIssueStatusComponent {
  @ViewChild('issueDetailSidebar', {static: true}) public issueDetailSidebar: IssueDetailSidebarComponent;

  @Input() public issue$: Observable<IssueSimpleDto>;
  @Input() public loading: boolean;

  public faInbox = faInbox;
  public headerActions: CorePortalCardboxAction[];

  constructor() {
    this.headerActions = [{
      label: 'issues.actions.show-issue',
      icon: faInbox,
      class: 'btn-outline-secondary',
      permission: AppPermissions.ReadIssue,
      callback: () => this.issueDetailSidebar.onShow()
    }];
  }

  public openIssueSidebar(): void {
    this.issueDetailSidebar.onShow();
  }

  public getTypeLabel(issueType): string {
    return issueTypeEnumOptions.find(type => type.value === issueType).label;
  }
}
