import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewChild} from "@angular/core";
import {FormGroup} from "@angular/forms";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import {
  CoreSharedSortableListComponent,
  CoreSharedSortableListItem,
  CrossCreationTypes,
  KnowledgeExamInPathDto,
  SortableListRowAction,
  UnsubscribeHelper
} from "@nexnox-web/core-shared";
import {FormlyFieldConfig} from "@ngx-formly/core";
import {BindObservable} from "bind-observable";
import {cloneDeep, find, maxBy} from "lodash";
import {BehaviorSubject, Observable} from "rxjs";
import {map} from "rxjs/operators";
import {OrgaPortalKnowledgeExamService} from "@nexnox-web/orga-portal-lib";

@Component({
	selector: 'nexnox-web-knowledge-paths-exams-edit',
	templateUrl: 'paths-exams-edit.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class PathsExamsEditComponent extends UnsubscribeHelper implements OnInit {
	@Input() public examsSubject: BehaviorSubject<KnowledgeExamInPathDto[]>;
	@Input() public loading: boolean;
	@Input() public actions: SortableListRowAction[];

	@Input() @BindObservable() public readonly: boolean;
	public readonly$!: Observable<boolean>;

	@ViewChild('examsListEdit') public examsListEdit: CoreSharedSortableListComponent;

	@Output() public examsChange = new EventEmitter<any>();

	public examItems$: Observable<CoreSharedSortableListItem[]>;

	public addExamForm: FormGroup;
	public addExamFields: FormlyFieldConfig[];
	public addExamModelSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

	public faPlus = faPlus;

	constructor(
		private examService: OrgaPortalKnowledgeExamService
	) {
		super();
	}

	public ngOnInit(): void {
		this.examItems$ = this.examsSubject.asObservable().pipe(
			map(exams => this.mapExamsToSortableItems(exams))
		);

		this.addExamForm = new FormGroup({});
		this.addExamFields = this.createExamFormFields();
	}

	public onAddExam(): void {
		const examToAdd: KnowledgeExamInPathDto = cloneDeep(this.addExamModelSubject.getValue()?.exam);
		const lastExam = maxBy(this.examsSubject.getValue(), 'position');
		const position: number = lastExam ? lastExam.position + 1 : 0;

		if (examToAdd) {
			const newExamInPath: KnowledgeExamInPathDto = {
				knowledgeExamId: examToAdd.knowledgeExamId,
				title: examToAdd.title,
				position
			}
			this.examsSubject.next([...this.examsSubject.getValue(), newExamInPath]);

			this.addExamModelSubject.next({} as any);
			this.addExamForm.reset();
			this.addExamForm.markAsUntouched();

			this.examsChange.emit(this.examsSubject.getValue());
		}
	}

	public onReset(): void {
		this.examsListEdit?.ngOnInit();
	}

	public onExamsChange(exams: CoreSharedSortableListItem[]): void {
		this.examsSubject.next(exams.map(exam => ({
			...exam.getExternalData(),
			position: exam.position
		})));
		this.examsChange.emit(this.examsSubject.getValue());
	}

	public createExamFormFields(): any {

		/* istanbul ignore next */
		return [
			{
				key: 'exam',
				type: 'core-portal-entity-select',
				wrappers: ['core-portal-translated', 'core-portal-readonly'],
				className: 'col-md-12',
				defaultValue: null,
				templateOptions: {
					corePortalTranslated: {
						label: 'orga-portal.knowledge.subtitles.exam-detail',
						validationMessages: {
							required: 'core-portal.core.validation.required',
							duplicate: 'core-portal.core.validation.duplicate'
						}
					},
					entityService: this.examService,
					idKey: 'knowledgeExamId',
					displayKey: 'title',
					skipGetOne: true,
					wholeObject: true,
					clearable: false,
          enableCrossCreation: CrossCreationTypes.KNOWLEDGE_EXAM
				},
				expressionProperties: {
					'templateOptions.required': () => !this.readonly,
					'templateOptions.readonly': () => this.readonly
				},
				validators: {
					duplicate: control => !find(this.examsSubject.getValue(), {knowledgeExamId: control?.value?.knowledgeExamId})
				}
			}
		]
	}

	/* istanbul ignore next */
	private mapExamsToSortableItems(exams: KnowledgeExamInPathDto[]): CoreSharedSortableListItem[] {
		return exams.map((exam) => ({
			title: exam.title,
			position: exam.position,
			getExternalData: () => cloneDeep(exam)
		}));
	}

}
