<ng-container *ngIf="!performanceMode; else performanceModeTemplate" [ngTemplateOutlet]="calendarTemplate"></ng-container>

<ng-template #performanceModeTemplate>
  <button type="button"
          pButton
          class="p-button-secondary p-button-text"
          [label]="valueAsDate$ | async | nexnoxWebBsDate:'LL' | async"
          [icon]="faPencilAlt | nexnoxWebFaIconStringPipe"
          iconPos="right"
          (click)="onOpenModal()">
  </button>
</ng-template>

<ng-template #calendarTemplate>
  <p-calendar #calendarComponent
              class="w-100"
              [styleClass]="'date-picker-input' + (hasError ? ' is-invalid' : '')"
              appendTo="body"
              [ngModel]="isRange ? (valueAsDateRange$ | async) : (valueAsDate$ | async)"
              [selectionMode]="isRange ? 'range' : 'single'"
              [minDate]="bsMinDate"
              [maxDate]="bsMaxDate"
              [disabled]="disabled$ | async"
              [dateFormat]="'core-shared.shared.primeng.date-format' | translate"
              [numberOfMonths]="numberOfMonths ?? 1"
              [showButtonBar]="true"
              [showWeek]="true"
              [firstDayOfWeek]="1"
              (ngModelChange)="onValueChange($event)"
              (onSelect)="onSelect()"
              [autoZIndex]="false">
  </p-calendar>
</ng-template>
