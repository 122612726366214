import {ChangeDetectionStrategy, Component, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {faPencilAlt} from '@fortawesome/free-solid-svg-icons/faPencilAlt';
import {FieldWrapper, FormlyTemplateOptions} from '@ngx-formly/core';
import {combineLatest, isObservable, Observable, of} from 'rxjs';
import {cloneDeep} from "lodash";
import {IconDefinition} from "@fortawesome/fontawesome-common-types";
import {distinctUntilChanged, map, startWith} from "rxjs/operators";
import {AppPermissions} from "@nexnox-web/core-shared";


export interface CorePortalFormlyActionWrapperTyping {
  actions: IActionWrapperAction[];
}

export interface IActionWrapperAction {
  icon: IconDefinition,
  onClick: () => void,
  hidden?: (model: any) => boolean,
  disabled?: (model: any) => boolean,
  tooltip?: string,
  loading$?: Observable<boolean>,
  permissions$?: Observable<boolean>[]
}

export interface IActionWrapperActionComplete extends IActionWrapperAction {
  permission$?: Observable<boolean>
}

interface FormlyActionWrapperTemplateOptions extends FormlyTemplateOptions {
  corePortalActionWrapper?: CorePortalFormlyActionWrapperTyping;
}

@Component({
  templateUrl: './formly-action-wrapper.component.html',
  styleUrls: ['./formly-action-wrapper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormlyActionWrapperComponent extends FieldWrapper implements OnInit {
  @ViewChild('fieldComponent', {read: ViewContainerRef}) public fieldComponent: ViewContainerRef;

  public readonly to: FormlyActionWrapperTemplateOptions;
  public visible$: Observable<boolean>;
  public actions: IActionWrapperActionComplete[];
  public loading$: Observable<boolean>[] = [];

  public ngOnInit(): void {
    this.actions = this._mapActions(this.to.corePortalActionWrapper.actions);
    this.visible$ = combineLatest(this.loading$).pipe(
      map(loading => !loading.some(l => l === true)),
      distinctUntilChanged(),
      startWith(true)
    );
  }

  private _mapActions(actions: IActionWrapperActionComplete[]): IActionWrapperActionComplete[] {
    const mappedActions = cloneDeep(actions ?? []);
    for (let i = 0; i < mappedActions.length; i++) {
      // Mapping
      const action = mappedActions[i];
      action.onClick = action?.onClick ?? (() => false);
      action.hidden = action?.hidden ?? (() => false);
      action.disabled = action?.disabled ?? (() => false);
      action.icon = action?.icon ?? faPencilAlt
      action.permission$ = of(true);
      // Loading
      if (isObservable(actions[i].loading$)) {
        this.loading$.push(actions[i].loading$);
      }
      // Permissions
      if (action.permissions$?.length) {
        action.permission$ = combineLatest(action.permissions$).pipe(
          map(permissions => permissions.some(p => p === true))
        );
      }
    }
    return mappedActions;
  }
}
