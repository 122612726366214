<nexnox-web-modal #modalComponent
                  [title]="'core-shared.shared.custom-property-signature.title' | translate"
                  [confirmButtonText]="'core-shared.shared.custom-property-signature.confirm' | translate"
                  [cancelButtonText]="'core-portal.core.general.cancel' | translate"
                  [preConfirmFn]="preConfirmFn">
  <div class="d-flex flex-row mw-100">
    <div #signaturePadWrapper
         class="signature-pad-wrapper w-100"
         [class.is-blocked]="isUploading$ | async">
      <signature-pad #signaturePad
                     [options]="signaturePadOptions"
                     (onEndEvent)="onDrawComplete()">
      </signature-pad>
    </div>

    <div class="d-flex flex-column ml-2">
      <button type="button"
              pButton
              [icon]="faTrashAlt | nexnoxWebFaIconStringPipe"
              [title]="'core-shared.shared.select.clear' | translate"
              class="p-button-secondary p-button-text p-button-icon-only"
              [disabled]="isEmpty$ | async"
              (click)="onClear()">
      </button>
    </div>
  </div>

</nexnox-web-modal>
