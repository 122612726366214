import { ChangeDetectionStrategy, Component, Injector, OnInit, ViewChild } from '@angular/core';
import { ActionButton, CorePortalEntityDetailBaseComponent, CorePortalPermissionService, CorePortalAttachmentsComponent } from '@nexnox-web/core-portal';
import { AppEntityType, AppPermissions, AttachmentForTechDto, ContractDto, LinkDto, LinkedElementType } from '@nexnox-web/core-shared';
import { TechPortalLinksComponent } from '@nexnox-web/tech-portal-lib';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { contractDetailStore } from '../../store';

@Component({
  selector: 'nexnox-web-contracts-contract-detail',
  templateUrl: './contract-detail.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContractDetailComponent extends CorePortalEntityDetailBaseComponent<ContractDto> implements OnInit {
  @ViewChild('linksComponent') public linksComponent: TechPortalLinksComponent;
  @ViewChild('attachmentsComponent') public attachmentsComponent: CorePortalAttachmentsComponent;

  public title = 'contracts.subtitles.contract-detail';

  public readAuditPermission$: Observable<boolean>;

  public attachments$: Observable<AttachmentForTechDto[]>;
  public links$: Observable<LinkDto[]>;

  public entityTypes = AppEntityType;
  public linkedElementTypes = LinkedElementType;

  protected idParam = 'contractId';
  protected displayKey = 'title';

  constructor(
    protected injector: Injector,
    private permissionService: CorePortalPermissionService
  ) {
    super(injector, contractDetailStore);

    this.readAuditPermission$ = this.permissionService.hasPermission$(AppPermissions.ReadAudit);
  }

  public ngOnInit(): void {
    super.ngOnInit();

    this.attachments$ = this.model$.pipe(
      map(model => model?.attachments ?? [])
    );

    this.links$ = this.model$.pipe(
      map(model => model?.links ?? [])
    );

    this.subscribe(this.readonly$.asObservable(), () => {
      this.attachmentsComponent?.onReset();
      this.linksComponent?.onReset();
    });
  }

  public onAttachmentsChange(attachments: AttachmentForTechDto[], model: ContractDto): void {
    this.onModelChange({ ...model, attachments });
  }

  public onLinksChange(links: LinkDto[], model: ContractDto): void {
    this.onModelChange({ ...model, links });
  }

  public onResponsibilityChange(responsibilityModel: ContractDto, model: ContractDto): void {
    this.onModelChange({
      ...model,
      contacts: responsibilityModel?.contacts ?? [],
      functions: responsibilityModel?.functions ?? [],
      locations: responsibilityModel?.locations ?? [],
      locationGroups: responsibilityModel?.locationGroups ?? []
    });
  }

  protected async getActionButtons(): Promise<ActionButton[]> {
    return this.getDefaultActionButtons(
      'contracts.actions.edit-contract',
      'contracts.actions.save-contract',
      'contracts.actions.delete-contract',
      'contracts.descriptions.delete-contract',
      AppPermissions.UpdateContract,
      AppPermissions.DeleteContract,
      ['/contracts']
    );
  }
}
