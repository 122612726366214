import { ChangeDetectionStrategy, Component, Injector, Input } from '@angular/core';
import { CoreSharedModalBaseComponent } from '@nexnox-web/core-shared';

@Component({
  selector: 'nexnox-web-templates-text-template-preview-modal',
  templateUrl: './text-template-preview-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TextTemplatePreviewModalComponent extends CoreSharedModalBaseComponent {
  @Input() public preview: string;

  constructor(
    protected injector: Injector
  ) {
    super(injector);
  }
}
