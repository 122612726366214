import { NgModule } from '@angular/core';
import { CoreSharedModule } from '@nexnox-web/core-shared';
import { CorePortalEntityModule } from '@nexnox-web/core-portal';
import { controlPointsServices } from './store';
import { TechPortalFeatureCCPControlPointEditComponent } from './components';
import { ControlPointDetailComponent, ControlPointListComponent } from './containers';
import { TechPortalFeatureCCPControlTagsModule } from '@nexnox-web/tech-portal/features/ccp/control-tags';

@NgModule({
  declarations: [
    TechPortalFeatureCCPControlPointEditComponent,

    ControlPointListComponent,
    ControlPointDetailComponent
  ],
  imports: [
    CoreSharedModule,
    CorePortalEntityModule,

    TechPortalFeatureCCPControlTagsModule.forFeature()
  ],
  exports: [
    TechPortalFeatureCCPControlPointEditComponent
  ],
  providers: [
    ...controlPointsServices
  ]
})
export class ControlPointsDeclarationsModule {
}
