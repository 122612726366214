import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {ActionButton, CorePortalActionBarService, CorePortalPermissionService} from '../../../../services';
import {combineLatest, isObservable, Observable, of, Subscription, throwError} from 'rxjs';
import {catchError, distinctUntilChanged, map, switchMap, take} from 'rxjs/operators';
import {IconDefinition} from "@fortawesome/fontawesome-common-types";

interface LocalAction extends ActionButton {
  buttonsValue?: LocalAction[];
  shouldShowValue?: boolean;
  isDisabledValue?: boolean;
  isSplitDisabledValue?: boolean;
  isLoadingValue?: boolean;
}

@Component({
  selector: 'nexnox-web-layout-action-bar',
  templateUrl: './action-bar.component.html',
  styleUrls: ['./action-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ActionBarComponent implements OnInit {
  public actions$: Observable<LocalAction[]>;
  public leftActions$: Observable<LocalAction[]>;
  public rightActions$: Observable<LocalAction[]>;
  public isOneShowing$: Observable<boolean>;

  public trackActionsBy: any;

  private subscription: Subscription;

  constructor(
    private actionBarService: CorePortalActionBarService,
    private permissionService: CorePortalPermissionService
  ) {
  }

  public getAsyncIcon(icon: IconDefinition | Observable<IconDefinition>): Observable<IconDefinition> {
    return (icon instanceof Observable) ? icon : of(icon);
  }

  public ngOnInit(): void {
    this.actions$ = this.actionBarService.actions$.pipe(
      distinctUntilChanged(),
      switchMap(actions => actions?.length ? combineLatest(actions.map(action => this.getActionMapping(action))) : of([])),
      catchError((error, caught) => {
        this.ngOnInit();
        return of([]);
      })
    );

    this.leftActions$ = this.actions$.pipe(
      map(actions => actions.filter(x => !x.alignRight))
    );
    this.rightActions$ = this.actions$.pipe(
      map(actions => actions.filter(x => x.alignRight))
    );

    this.isOneShowing$ = this.actions$.pipe(
      map(actionsShown => actionsShown.some(x => x.shouldShowValue))
    );

    this.trackActionsBy = (index: number, action: ActionButton) => index;
  }

  public isDropDown(actionType): Observable<boolean> {
    let isDropDown = false;
    if (isObservable(actionType)) {
      this.subscription = actionType.pipe(take(1)).subscribe({
          next: (type) => {
            isDropDown = type === 'dropdown';
          },
          complete: () => {
            this.subscription?.unsubscribe();
          }
        }
      )
    } else {
      isDropDown = actionType === 'dropdown'
    }
    return of(isDropDown);
  }

  private getActionMapping(action: ActionButton): Observable<LocalAction> {
    return this.actionShouldShow(action).pipe(
      distinctUntilChanged(),
      switchMap(shouldShow => this.actionIsDisabled(action).pipe(
        distinctUntilChanged(),
        switchMap(isDisabled => this.actionIsSplitDisabled(action).pipe(
          distinctUntilChanged(),
          switchMap(isSplitDisabled => this.actionIsLoading(action).pipe(
            distinctUntilChanged(),
            switchMap(isLoading => {
              let nextObservable: Observable<LocalAction[] | undefined>;

              if (action.buttons) {
                nextObservable = action.buttons.pipe(
                  switchMap(buttons =>
                    buttons?.length ? combineLatest(buttons.map(button => this.getActionMapping(button))) : of([])),
                  catchError(error => throwError(error))
                );
              } else {
                nextObservable = of(undefined);
              }

              return nextObservable.pipe(
                map(buttons => ({
                  ...action,
                  buttonsValue: buttons,
                  shouldShowValue: shouldShow,
                  isDisabledValue: isDisabled,
                  isSplitDisabledValue: isSplitDisabled,
                  isLoadingValue: isLoading
                } as LocalAction))
              );
            })
          )),
          catchError(error => throwError(error))
        )),
        catchError(error => throwError(error))
      )),
      catchError(error => throwError(error)),
    );
  }

  private actionShouldShow(action: ActionButton): Observable<boolean> {
    let shouldShow$: Observable<boolean> = of(true);
    // Handle different types
    if (action.type && isObservable(action.type)) {
      return action.type.pipe(switchMap((type) => type === 'button' ? this._shouldShowButton(action) : this._shouldShowDropdown(action)));
    } else if (action.type === 'button') {
      shouldShow$ = this._shouldShowButton(action);
    } else if (action.type === 'dropdown') {
      shouldShow$ = this._shouldShowDropdown(action);
    }
    return shouldShow$;
  }

  private _shouldShowDropdown(dropdown: ActionButton): Observable<boolean> {
    return dropdown.buttons.pipe(
      switchMap(buttons => combineLatest([
        of(false), // in case there are no buttons defined
        ...buttons.map(childAction => this.actionShouldShow(childAction)) // map child buttons
      ])),
      map(buttonsShown => buttonsShown.some(x => Boolean(x))), // cumulate boolean values from child buttons
      switchMap(shouldShowBecauseButtonsAreShown => dropdown.shouldShow ?
        // Evaluate shouldShow of whole dropdown button
        dropdown.shouldShow().pipe(map(shouldShow => shouldShowBecauseButtonsAreShown ? shouldShow : false)) :
        of(shouldShowBecauseButtonsAreShown))
    ).pipe(
      // Add permission to value
      switchMap(shouldShowDropdown => {
        if (dropdown.permission) {
          return this.permissionService.hasPermission$(dropdown.permission).pipe(
            map(hasPermission => shouldShowDropdown && hasPermission)
          );
        }
        return of(shouldShowDropdown);
      })
    );
  }

  private _shouldShowButton(button: ActionButton): Observable<boolean> {
    return button.shouldShow ? button.shouldShow().pipe(
        switchMap(shouldShow => {
          if (button.permission) {
            return this.permissionService.hasPermission$(button.permission).pipe(
              map(hasPermission => shouldShow && hasPermission)
            );
          }
          return of(shouldShow);
        }))
      : button.permission ? this.permissionService.hasPermission$(button.permission) : of(true);
  }

  private actionIsDisabled(action: ActionButton): Observable<boolean> {
    if (action.isDisabled) {
      return action.isDisabled().pipe(
        switchMap(isDisabled => this.actionIsLoading(action).pipe(
          map(loading => isDisabled || loading)
        ))
      );
    }

    return this.actionIsLoading(action);
  }

  private actionIsSplitDisabled(action: ActionButton): Observable<boolean> {
    return this.actionIsDisabled(action).pipe(
      switchMap(isDisabled => this.actionIsLoading(action).pipe(
        switchMap(isLoading => {
          if (action.isSplitDisabled) {
            return action.isSplitDisabled().pipe(
              map(isSplitDisabled => {
                if (isLoading) {
                  return true;
                }

                if (!isSplitDisabled) {
                  return false;
                }

                return isDisabled || isSplitDisabled;
              })
            );
          }

          return of(isDisabled);
        })
      ))
    );
  }

  private actionIsLoading(action: ActionButton): Observable<boolean> {
    if (action.isLoading) {
      return action.isLoading();
    }

    return of(false);
  }
}
