import { textTemplateListStore, TextTemplateListStoreState } from './text-template-list/text-template-list.store';
import { textTemplateDetailStore, TextTemplateDetailStoreState } from './text-template-detail/text-template-detail.store';

export interface TextTemplatesState {
  textTemplateList: TextTemplateListStoreState;
  textTemplateDetail: TextTemplateDetailStoreState;
}

export const getInitialTextTemplatesState = (): TextTemplatesState => ({
  textTemplateList: textTemplateListStore.getInitialState(),
  textTemplateDetail: textTemplateDetailStore.getInitialState()
});
