import { Component } from '@angular/core';
import { FieldType, FormlyTemplateOptions } from '@ngx-formly/core';

export interface CorePortalFormlyDatepickerTyping {
  numberOfMonths?: number;
  isRange?: boolean;
}

interface FormlyDatepickerTemplateOptions extends FormlyTemplateOptions {
  corePortalDatepicker: CorePortalFormlyDatepickerTyping;
}

@Component({
  selector: 'nexnox-web-formly-datepicker',
  templateUrl: './formly-datepicker.component.html'
})
export class FormlyDatepickerComponent extends FieldType {
  public readonly to: FormlyDatepickerTemplateOptions;
}
