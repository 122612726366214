import { PagedEntitiesXsStore, PagedEntitiesXsStoreState } from '@nexnox-web/core-store';
import { AppEntityType, TemplateListDto } from '@nexnox-web/core-shared';
import { Action, createSelector } from '@ngrx/store';
import { selectTextTemplatesState } from '../text-templates.selectors';
import { TechPortalFeatureTextTemplateService } from '../../../services';
import { Injectable, Injector } from '@angular/core';

export interface TextTemplateListStoreState extends PagedEntitiesXsStoreState<TemplateListDto> {
}

/* istanbul ignore next */
export const textTemplateListStore = new PagedEntitiesXsStore<TemplateListDto>({
  actionLabel: 'Tech Portal - Templates - Text Template List',
  stateSelector: createSelector(selectTextTemplatesState, state => state.textTemplateList),
  serviceType: TechPortalFeatureTextTemplateService,
  entityType: AppEntityType.None,
  selectId: template => template.entity?.templateId ?? template.model?.templateId
});

export function textTemplateListStoreReducer(state: TextTemplateListStoreState, action: Action): any {
  return textTemplateListStore.reducer(state, action);
}

@Injectable()
export class TextTemplateListStoreEffects extends textTemplateListStore.effects {
  constructor(
    protected injector: Injector
  ) {
    super(injector);
  }
}
