import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class CorePortalCurrentModuleService {
  public currentModuleSubject: BehaviorSubject<string> = new BehaviorSubject<string>(undefined);

  public setModule(module: string): void {
    this.currentModuleSubject.next(module);
  }

  public getModule(): string {
    return this.currentModuleSubject.getValue();
  }
}
