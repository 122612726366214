<p-sidebar #sidebarComponent
           position="right"
           appendTo="body"
           styleClass="p-sidebar-md"
           [transitionOptions]="transitionOptions"
           [visible]="visible$ | async"
           (visibleChange)="onVisibleChange($event)">
  <div class="d-flex align-content-between flex-column h-100"
       *ngIf="(loading$ | async) === false; else loadingTemplate">

    <h4 class="pl-2">{{ 'core-shared.shared.fields.receivers' | translate }}</h4>

    <tabset class="receiver-tab">
      <tab *ngFor="let tab of receiverTabs; let isLast = last"
           [customClass]="(isLast ? 'is-last ' : '')"
           (selectTab)="updateTab(tab.type)"
           [id]="tab.type">
        <ng-template tabHeading>
          <span class="heading">
            {{ getTranslationField(tab.type) | translate }}
          </span>
        </ng-template>
      </tab>
    </tabset>

    <form [formGroup]="form" class="w-100 mt-1">
      <formly-form class="row w-100"
                   [(model)]="model"
                   [form]="form"
                   [fields]="fields"
                   (modelChange)="onModelChange($event)">
      </formly-form>
    </form>

    <div class="px-2 overflow-y-auto my-4">
      <ng-container *ngFor="let field of receivers | keyvalue; let first = first">
        <ng-container *ngIf="field.value?.length > 0">
          <h5 [ngClass]="!first ? 'mt-4' : ''">
            {{ getTranslationField(field.key) | translate }}
            <span> ({{ field.value.length }})</span>
          </h5>
          <ng-container *ngFor="let item of field.value">
            <div class="form-control d-flex justify-space-between">
              <div class="flex-shrink-1 text-truncate">
                <a class="link cursor-pointer"
                   (click)="navigateTo(item.url)">
                  {{ item.name }}
                </a>
              </div>
              <div (click)="removeOne(item, field.key)" class="cursor-pointer px-1">
                <fa-icon [icon]="faTimes" size="sm"></fa-icon>
              </div>
            </div>
          </ng-container>
        </ng-container>
      </ng-container>
    </div>

    <div class="w-100 d-flex flex-shrink-1 gap-2 justify-content-end mt-auto mb-0">
      <button pButton
              type="button"
              class="p-button p-button-text p-button-secondary"
              [label]="'core-shared.shared.actions.remove-all' | translate"
              [disabled]="isRemoveButtonDisabled"
              (click)="removeAll()">
      </button>
      <button pButton
              type="button"
              class="p-button p-button-primary"
              [label]="'core-shared.shared.actions.accept' | translate"
              [icon]="faUser | nexnoxWebFaIconStringPipe"
              [disabled]="!form.valid"
              (click)="onAssignReceivers()">
      </button>

    </div>
  </div>
</p-sidebar>

<!-- Location Templates -->
<ng-template #locationSelectLabelTitleTemplate let-item="item">
  <nexnox-web-core-shared-location-number [location]="item"
                                          [label]="true">
  </nexnox-web-core-shared-location-number>
</ng-template>

<ng-template #locationSelectOptionTitleTemplate let-item="item" let-searchTerm="searchTerm">
  <nexnox-web-core-shared-location-number [location]="item"
                                          [searchTerm]="searchTerm"
                                          [label]="false">
  </nexnox-web-core-shared-location-number>
</ng-template>

<ng-template #loadingTemplate>
  <nexnox-web-loading-icon class="d-flex w-100 h-100 align-items-center justify-content-center flex-column p-5"
                           size="5x">
  </nexnox-web-loading-icon>
</ng-template>
