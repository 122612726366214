import { AuthXsStore } from './auth.xs-store';
import { Action, createSelector } from '@ngrx/store';
import { AuthXsStoreState } from './auth-xs-store.state';
import { selectCoreState } from '../core.selectors';

export const authStore = new AuthXsStore({
  actionLabel: 'Core Portal - Core - Auth',
  stateSelector: createSelector(selectCoreState, state => state.auth)
});

export function authStoreReducer(state: AuthXsStoreState, action: Action): any {
  return authStore.reducer(state, action);
}
