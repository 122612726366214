import { ModuleWithProviders, NgModule } from '@angular/core';
import { IssuesStoreModule } from './issues-store.module';
import { IssuesRoutingModule } from './issues-routing.module';
import { IssuesDeclarationsModule } from './issues-declarations.module';

@NgModule({
  imports: [
    IssuesDeclarationsModule,
    IssuesRoutingModule,
    IssuesStoreModule
  ]
})
export class TechPortalFeatureIssuesModule {
  public static forFeature(): ModuleWithProviders<IssuesDeclarationsModule> {
    return { ngModule: IssuesDeclarationsModule };
  }
}
