<ng-template #entityEditTemplate let-title="title" let-id="id">
  <nexnox-web-settings-inventory-number-patterns-inventory-number-pattern-edit #modelComponent
                                                                               [title]="title"
                                                                               [id]="id"
                                                                               [model]="model$ | async"
                                                                               [readonly]="readonly$ | async"
                                                                               [creating]="false"
                                                                               [loading]="loading$ | async"
                                                                               [stereotypes$]="stereotypes$"
                                                                               (modelChange)="onModelChange($event)">
  </nexnox-web-settings-inventory-number-patterns-inventory-number-pattern-edit>
</ng-template>

<nexnox-web-entity-detail [entityDetailBaseComponent]="this"
                          [entityEditTemplate]="entityEditTemplate"
                          tabId="inventoryNumberPattern"
                          [tabHeading]="title | translate"
                          [tabCommands]="['.']">
</nexnox-web-entity-detail>
