<ng-container>
  <div class="sticky-top d-flex mb-3 justify-content-md-end w-100 multiselect-bg">
    <div class="d-flex align-items-md-center flex-wrap flex-column flex-md-row w-100 justify-content-start mb-3">

      <!-- Dropdown filter selector -->
      <p-multiSelect optionLabel="label"
                     optionValue="value"
                     styleClass="multiSelect mb-1 mb-md-0"
                     [options]="filterItemOptions"
                     [ngModel]="filterItemsSubject | async"
                     [disabled]="(loaded$ | async) === false"
                     [filter]="false"
                     [placeholder]="'core-shared.shared.select.selected' | translate:{amount: 0}"
                     (ngModelChange)="onChangeShowFilter($event)">
        <ng-template pTemplate="selectedItems" let-items>
          <span *ngIf="items?.length >= filterItemOptions.length">
            {{ 'core-shared.shared.select.all' | translate }}</span>
          <span *ngIf="items?.length < filterItemOptions.length">
            {{ 'core-shared.shared.select.selected' | translate:{amount: items?.length} }}</span>
        </ng-template>

        <ng-template pTemplate="item" let-item>
          <span>{{ item.label | translate }}</span>
        </ng-template>
      </p-multiSelect>

      <!-- Refresh button -->
      <button class="btn btn-outline-secondary multiSelect-height ml-md-2"
              [title]="'core-shared.shared.table.tooltip.refresh' | translate"
              [disabled]="(loaded$ | async) === false || (loading$ | async)"
              (click)="onRefresh()">
        <fa-icon *ngIf="((loaded$ | async) && (loading$ | async) === false); else loadingTemplate"
                 [icon]="faSync"></fa-icon>

        <ng-template #loadingTemplate>
          <nexnox-web-loading-icon></nexnox-web-loading-icon>
        </ng-template>
      </button>
    </div>
  </div>

  <ng-container *ngIf="(loading$ | async) === false && filteredEvents$ | async as events; else loading">
    <ng-container *ngIf="(loaded$ | async) && events?.length > 0; else emptyTemplate">
        <nexnox-web-resources-resource-event-list-item *ngFor="let item of events; last as isLastItem"
                                                       [entityData]="entityData$ | async"
                                                       [item]="item.entity"
                                                       [isLastItem]="isLastItem"
                                                       [readonly]="(readonly$ | async) || readonly"
                                                       [loading$]="loading$">
        </nexnox-web-resources-resource-event-list-item>

        <nexnox-web-content-container-infinite-scroll *ngIf="paging$ | async as paging"
                                                      [loading]="(appendLoading$ | async)"
                                                      [disabled]="(loading$ | async)"
                                                      [finished]="paging.pageNumber >= paging.totalPages"
                                                      [error]="hasError$ | async"
                                                      (loadMore)="onMore(paging)">
        </nexnox-web-content-container-infinite-scroll>
    </ng-container>
  </ng-container>

</ng-container>

<ng-template #loading>
  <nexnox-web-resources-resource-event-list-item [loading]="true"></nexnox-web-resources-resource-event-list-item>
</ng-template>

<ng-template #emptyTemplate>
  <span>{{ 'core-shared.shared.table.empty' | translate }}</span>
</ng-template>
