import {PagedEntitiesXsStoreState} from '@nexnox-web/core-store';
import {AppEntityType, WinterhalterProductOrderIssueListDto} from '@nexnox-web/core-shared';
import {createSelector} from '@ngrx/store';
import {selectIssuesState} from '../../issues.selectors';
import {WinterhalterProductOrderIssueListXsStore} from './winterhalter-product-order-issue-list.xs-store';
import {WinterhalterProductOrderIssueService} from "../../services";

export interface WinterhalterProductOrderIssueListStoreState extends PagedEntitiesXsStoreState<WinterhalterProductOrderIssueListDto> {
}

/* istanbul ignore next */
export const winterhalterProductOrderIssueListStore = new WinterhalterProductOrderIssueListXsStore({
  actionLabel: 'Tech Portal - Issues - Winterhalter Product Order Issue List',
  stateSelector: createSelector(selectIssuesState, state => state.winterhalterProductOrderIssueList),
  serviceType: WinterhalterProductOrderIssueService,
  entityType: AppEntityType.Issue,
  selectId: issue => issue.entity?.issueId ?? issue.model?.issueId
});
