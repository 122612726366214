import {NgModule} from '@angular/core';
import {CoreSharedModule} from '@nexnox-web/core-shared';
import {
  ActionBarComponent,
  CorePortalLayoutHeaderComponent,
  ModuleSelectComponent,
  TenantSelectComponent
} from './components';
import {ButtonModule} from 'primeng/button';
import {AppSettingsSidebarComponent} from './sidebars';
import {DropdownModule} from 'primeng/dropdown';
import {FormsModule} from '@angular/forms';
import {CorePortalSidebarModule} from '../sidebar';
import {CorePortalBreadcrumbsModule} from '../breadcrumbs';
import {CorePortalLayoutComponent} from './containers';
import {OverlayPanelModule} from 'primeng/overlaypanel';

const exportedComponents = [
  CorePortalLayoutComponent,

  CorePortalLayoutHeaderComponent
];

@NgModule({
  declarations: [
    ...exportedComponents,
    TenantSelectComponent,
    ModuleSelectComponent,
    AppSettingsSidebarComponent,
    ActionBarComponent
  ],
  imports: [
    CoreSharedModule,
    FormsModule,
    ButtonModule,
    DropdownModule,
    OverlayPanelModule,
    CorePortalSidebarModule,
    CorePortalBreadcrumbsModule
  ],
  exports: [
    ...exportedComponents
  ]
})
export class CorePortalLayoutModule {
}
