import { NgModule } from '@angular/core';
import { CoreSharedModule } from '@nexnox-web/core-shared';
import { CorePortalEntityModule } from '@nexnox-web/core-portal';
import { resourceTasksServices } from './store';
import { ResourceSelectionEditComponent, TechPortalFeatureResourceTaskEditComponent } from './components';
import { ResourceTaskDetailComponent, ResourceTaskListComponent, ResourceTaskMissionsComponent } from './containers';
import { TechPortalFeatureTemplatesModule } from '@nexnox-web/tech-portal/features/templates';
import { CorePortalFeatureResourcesModule } from '@nexnox-web/core-portal/features/resources';
import { CorePortalFeatureMasterDataLocationsModule } from '@nexnox-web/core-portal/features/master-data/features/locations';
import { TechPortalFeatureTicketSettingsPrioritySetsModule } from '@nexnox-web/tech-portal/features/ticket-settings/features/priority-sets';
import { CorePortalFeatureMasterDataContactsModule } from '@nexnox-web/core-portal/features/master-data/features/contacts';
import { TechPortalFeatureConnectedConsumptionCodesModule } from '@nexnox-web/tech-portal/features/connected/consumption-codes';
import { TechPortalFeatureConnectedErrorCodesModule } from '@nexnox-web/tech-portal/features/connected/error-codes';
import { TechPortalFeatureConnectedHintCodesModule } from "@nexnox-web/tech-portal/features/connected/hint-codes";

@NgModule({
  declarations: [
    ResourceTaskListComponent,
    ResourceTaskDetailComponent,

    ResourceTaskMissionsComponent,

    TechPortalFeatureResourceTaskEditComponent,
    ResourceSelectionEditComponent
  ],
  imports: [
    CoreSharedModule,
    CorePortalEntityModule,

    TechPortalFeatureTemplatesModule.forFeature(),
    CorePortalFeatureResourcesModule.forFeature(),
    CorePortalFeatureMasterDataLocationsModule.forFeature(),
    TechPortalFeatureTicketSettingsPrioritySetsModule.forFeature(),
    CorePortalFeatureMasterDataContactsModule.forFeature(),
    TechPortalFeatureConnectedConsumptionCodesModule.forFeature(),
    TechPortalFeatureConnectedErrorCodesModule.forFeature(),
    TechPortalFeatureConnectedHintCodesModule.forFeature()
  ],
  providers: [
    ...resourceTasksServices
  ],
  exports: [
    TechPortalFeatureResourceTaskEditComponent
  ]
})
export class ResourceTasksDeclarationsModule {
}
