<nexnox-web-cardbox [title]="'missions.subtitles.missions' | translate"
                    [transparent]="true"
                    [isAccordion]="true"
                    [isCollapsed]="true"
                    [indicatorNum]="(missions$ | async)?.length"
                    [isAutoExpandOnIndicator]="true"
                    paddingClasses="px-3"
                    [loading]="(missions$ | async) === undefined || loading">
  <div class="d-flex flex-column pb-2">
    <div *ngFor="let mission of missions$ | async" class="d-flex flex-row mb-2">
      <fa-icon [icon]="faClipboardCheck" [fixedWidth]="true" size="sm" class="mr-2"></fa-icon>
      <a class="btn-link"
         [nexnoxWebTenantRouterLink]="['/missions', mission.missionId]"
         module="inventory"
         target="_blank">
        {{ mission.title }}
      </a>
    </div>
    <div class="mb-2" *ngIf="!(missions$ | async)?.length">{{ 'core-shared.shared.table.empty' | translate }}</div>
  </div>
</nexnox-web-cardbox>
