import { EntityXsStoreState } from '@nexnox-web/core-store';
import { AppEntityType, ContactDto } from '@nexnox-web/core-shared';
import { ContactDetailXsStore } from './contact-detail.xs-store';
import { createSelector } from '@ngrx/store';
import { selectContactsState } from '../../contacts.selectors';
import { CorePortalFeatureMasterDataContactService } from '../../services';

export interface ContactDetailStoreState extends EntityXsStoreState<ContactDto> {
}

/* istanbul ignore next */
export const contactDetailStore = new ContactDetailXsStore({
  actionLabel: 'Core Portal - Master Data - Contact Detail',
  stateSelector: createSelector(selectContactsState, state => state.contactDetail),
  serviceType: CorePortalFeatureMasterDataContactService,
  entityType: AppEntityType.Contact
});
