<div class="d-flex flex-row">
  <nexnox-web-date-picker class="d-flex flex-grow-1"
                          [ngModel]="date$ | async"
                          [disabled]="disabled$ | async"
                          [hasError]="hasError"
                          (ngModelChange)="onDateChange($event)">
  </nexnox-web-date-picker>

  <nexnox-web-time-picker class="d-flex"
                          [ngModel]="time$ | async"
                          [canClear]="false"
                          [disabled]="disabled$ | async"
                          [hasError]="hasError"
                          (ngModelChange)="onTimeChange($event)">
  </nexnox-web-time-picker>

  <button class="btn"
          [class.invisible]="(valid$ | async) === false || (disabled$ | async) || !canClear"
          [title]="'core-shared.shared.select.clear' | translate"
          (click)="onClear()">
    <fa-icon [icon]="faTimes"></fa-icon>
  </button>
</div>

<div>
  <button type="button"
          class="btn btn-link"
          (click)="onSetToNow()"
          [disabled]="disabled$ | async">
    {{ 'core-shared.shared.date-time-picker.now' | translate }}
  </button>

  <div *ngIf="enabledRelativeTimes?.length"
       class="btn-group"
       dropdown
       [isDisabled]="disabled$ | async">
    <button type="button"
            dropdownToggle
            class="btn btn-link dropdown-toggle">
      {{ 'core-shared.shared.date-time-picker.add-relative-time' | translate }}
    </button>

    <ul *dropdownMenu class="dropdown-menu">
      <li *ngFor="let relativeTime of enabledRelativeTimes">
        <a class="dropdown-item" (click)="onSetRelativeTime(relativeTime)">
          {{ ('core-shared.shared.date-time-picker.relative-times.' + relativeTimeTypes[relativeTime]) | translate }}
        </a>
      </li>
    </ul>
  </div>
</div>
