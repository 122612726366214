import {ChangeDetectionStrategy, Component, EventEmitter, Injector, Output} from '@angular/core';
import {CorePortalEntityOverviewBaseComponent} from '@nexnox-web/core-portal';
import {AppEntityType, ControllerOperationId, InfoProgressLocationListDto, Mappers} from '@nexnox-web/core-shared';
import {infoProgressLocationListStore} from '../../store';

@Component({
  selector: 'nexnox-web-infos-info-progress-location-list',
  templateUrl: './info-progress-location-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InfoProgressLocationListComponent extends CorePortalEntityOverviewBaseComponent<InfoProgressLocationListDto> {
  @Output() public openSidebar: EventEmitter<any> = new EventEmitter<any>();

  public title = 'orga-portal.infos.subtitles.info-progress-locations';
  public idProperty = 'locationId';
  public displayProperty = null;
  public datatableConfigName = 'InfoProgressLocationsDefault';
  public componentId = 'InfoProgressLocationListComponent';
  public enableViews = true;
  public pageOperation = ControllerOperationId.InfoProgressLocationControllerId;

  constructor(
    protected injector: Injector
  ) {
    super(injector, infoProgressLocationListStore, Mappers.InfoProgressLocationListDto.serializedName, AppEntityType.Location);
  }

  public ngOnInit(): void {
    super.ngOnInit();

    if (this.parentIds[0]) {
      this.descendantId = Number(this.parentIds[0])
    }
  }

  public onOpenSidebar(event: any): void {
    this.openSidebar.emit(event.model);
  }
}
