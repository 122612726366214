<tabset #tabsetComponent [class.is-transparent]="isTransparent">
  <tab *ngFor="let tabTemplate of tabTemplates; let isFirst = first; let isLast = last"
       [id]="tabTemplate.tabId"
       [customClass]="(isLast ? 'is-last ' : '') + (tabTemplate.hide ? 'is-hidden' : '')"
       [disabled]="tabTemplate.hide"
       (selectTab)="onSelectTab(tabTemplate)">
    <ng-template tabHeading>
      <span class="heading" [class.has-error]="tabTemplate.hasError">
        {{ tabTemplate.heading | translate }}

        <span *ngIf="tabTemplate.hasError" class="text-danger">*</span>
      </span>
    </ng-template>

    <ng-container *ngIf="tabTemplate.template && (tabTemplate.lazy ? (isTabActive(tabTemplate.tabId) || tabTemplate.wasActive) : true)"
                  [ngTemplateOutlet]="tabTemplate.template">
    </ng-container>
  </tab>
</tabset>
