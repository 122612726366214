import {
  resourceTaskDetailStore,
  ResourceTaskDetailStoreState,
  resourceTaskListStore,
  ResourceTaskListStoreState,
  resourceTaskMissionsStore,
  ResourceTaskMissionsStoreState,
  resourceTaskResourcesStore,
  ResourceTaskResourcesStoreState
} from './stores';

export interface ResourceTasksState {
  resourceTaskList: ResourceTaskListStoreState;
  resourceTaskDetail: ResourceTaskDetailStoreState;
  resourceTaskResources: ResourceTaskResourcesStoreState;
  resourceTaskMissions: ResourceTaskMissionsStoreState;
}

export const getInitialResourceTasksState = (): ResourceTasksState => ({
  resourceTaskList: resourceTaskListStore.getInitialState(),
  resourceTaskDetail: resourceTaskDetailStore.getInitialState(),
  resourceTaskResources: resourceTaskResourcesStore.getInitialState(),
  resourceTaskMissions: resourceTaskMissionsStore.getInitialState()
});
