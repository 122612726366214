import {ChangeDetectionStrategy, Component, Injector} from '@angular/core';
import {CorePortalEntityOverviewBaseComponent, DatatableActionButton} from '@nexnox-web/core-portal';
import {AddressDto, AppEntityType, AppPermissions, Mappers} from '@nexnox-web/core-shared';
import {locationAddressListStore} from '../../store';
import {PRIMARY_OUTLET} from "@angular/router";

@Component({
  selector: 'nexnox-web-master-data-locations-location-address-list',
  templateUrl: './location-address-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LocationAddressListComponent extends CorePortalEntityOverviewBaseComponent<AddressDto> {
  public title = 'core-portal.master-data.subtitles.address-list';
  public createTitle = 'core-portal.master-data.subtitles.address-create';
  public idProperty = 'addressId';
  public displayProperty = 'street';
  public datatableConfigName = 'MasterDataAddressDefault';
  public componentId = 'LocationAddressListComponent';

  constructor(
    protected injector: Injector
  ) {
    super(injector, locationAddressListStore, Mappers.AddressListDto.serializedName, AppEntityType.Address);
  }

  public getRowActionButtons(): DatatableActionButton[] {
    return [
      ...this.getDefaultRowActionButtons(
        'core-portal.master-data.actions.edit-address',
        address => `masterdata/locations/${this.parentIds[0]}/addresses/${address.addressId}`,
        [AppPermissions.UpdateAddress],
        {
          module: 'management'
        }
      ),
      ...this.getEmbeddedRowActionButtons()
    ];
  }

  public gotoStandaloneCreate(): void {
    const url = this.router.parseUrl(this.router.url).root.children[PRIMARY_OUTLET].segments.map((s) => s.path);
    this.router.navigate([...url, 'create'])
  }
}
