import {ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, OnInit, Output} from '@angular/core';
import {
  AppRoleDto,
  ContactDto,
  CORE_SHARED_ENVIRONMENT,
  CoreSharedSidebarBaseComponent,
  Environment,
  LocalStorageKeys,
  RoleSimpleDto,
  TenantInfoDto
} from '@nexnox-web/core-shared';
import {TranslateService} from '@ngx-translate/core';
import {CorePortalTenantLocalStorageService} from '../../../../services';
import {BehaviorSubject, from, NEVER, Observable} from 'rxjs';
import {filter, map, mergeMap, scan, skip, startWith, switchMap} from 'rxjs/operators';
import {select, Store} from "@ngrx/store";

import {authStore} from '../../../../store';
import {faUserAlt} from "@fortawesome/free-solid-svg-icons/faUserAlt";

interface LanguageOption {
  label: string;
  value: string;
  flag: string;
}

@Component({
  selector: 'nexnox-web-layout-app-settings-sidebar',
  templateUrl: './app-settings-sidebar.component.html',
  styleUrls: ['./app-settings-sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppSettingsSidebarComponent extends CoreSharedSidebarBaseComponent implements OnInit {
  @Input() public activeTenant$: Observable<TenantInfoDto> = NEVER;
  @Input() public tenantItems$: Observable<{ label: string; id: number }[]> = NEVER;

  @Output() public changePassword: EventEmitter<void> = new EventEmitter<void>();
  @Output() public logout: EventEmitter<void> = new EventEmitter<void>();

  public activeLanguage$: Observable<string>;
  public languageOptions$: Observable<LanguageOption[]>;
  public activeUser$: Observable<ContactDto>;
  public activeRoles$: Observable<(AppRoleDto & RoleSimpleDto)[]>;
  public activeLanguageOption$: Observable<LanguageOption>;
  public version: string;
  public faUser = faUserAlt;

  private selectLanguageSubject: BehaviorSubject<string>;

  constructor(
    @Inject(CORE_SHARED_ENVIRONMENT) private environment: Environment,
    private translate: TranslateService,
    private tenantLocalStorage: CorePortalTenantLocalStorageService,
    private store: Store
  ) {
    super();
  }

  public ngOnInit(): void {
    this.selectLanguageSubject = new BehaviorSubject<string>(this.translate.currentLang);
    this.activeLanguage$ = this.translate.onLangChange.asObservable().pipe(
      map(event => event.lang),
      startWith(this.translate.currentLang)
    );
    this.languageOptions$ = this.activeLanguage$.pipe(
      switchMap(() => from(this.translate.langs).pipe(
        mergeMap(language => this.translate.stream(`core-portal.core.languages.${language}`).pipe(
          map(translatedLanguage => ({
            label: translatedLanguage,
            value: language,
            flag: language.split('-')[1].toLowerCase()
          } as LanguageOption))
        ))
      )),
      scan((all, current) => [...all, current], []),
      filter(all => all.length === this.translate.langs.length)
    );
    this.activeLanguageOption$ = this.activeLanguage$.pipe(
      mergeMap(activeLanguage => this.languageOptions$.pipe(
        map(languageOptions => languageOptions.find(x => x.value === activeLanguage))
      ))
    );
    this.activeUser$ = this.store.pipe(
      select(authStore.selectors.selectActiveTenant),
      map(tenant => tenant?.names?.length > 0 ? tenant?.names[0] : undefined)
    );
    this.activeRoles$ = this.store.pipe(
      select(authStore.selectors.selectActiveRoles),
      map(roles => roles.length > 0 ? roles : undefined)
    );
    this.version = this.environment.version.replace('#{name}#', '');

    this.subscribeToLanguageChanges();
  }

  public onShow(): void {
    super.onShow();
    this.subscribeToLanguageChanges();
  }

  public onSelectLanguage(language: string): void {
    this.selectLanguageSubject.next(language);
  }

  private subscribeToLanguageChanges(): void {
    this.subscribe(this.selectLanguageSubject.asObservable().pipe(
      skip(1)
    ), language => {
      this.translate.use(language);
      this.tenantLocalStorage.set(LocalStorageKeys.ACTIVE_LANGUAGE, language);
    });
  }
}
