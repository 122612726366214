import { SnapLine } from './snap-line.model';
import { SnapElement } from './snap-element.model';

export type SnapArrowDirection = 'N' | 'S';

export class SnapArrow extends SnapLine {
  public get length(): number {
    return this._length;
  }

  public set length(length: number) {
    this._length = length;
    this.updateAttributes();
  }

  public get facing(): SnapArrowDirection {
    return this._facing;
  }

  public set facing(facing: SnapArrowDirection) {
    this._facing = facing;
    this.updateAttributes();
  }

  public get absoluteX2(): number {
    return this.absoluteX;
  }

  public get absoluteY2(): number {
    return this.absoluteY + this.length;
  }

  protected _length: number;
  protected _facing: SnapArrowDirection;

  constructor(
    x: number,
    y: number,
    length: number,
    stroke: string,
    strokeWidth: number,
    facing: SnapArrowDirection,
    parent: SnapElement = null
  ) {
    super(x, y, 0, 0, stroke, strokeWidth, parent);

    this._length = length;
    this._facing = facing;
  }

  public updateAttributes(): void {
    super.updateAttributes();
    this.element.attr({
      d: this.getPath()
    });
  }

  protected createElement(snapPaper: any): any {
    return snapPaper.path(this.getPath());
  }

  private getPath(): string {
    const arrowLength = 10;
    let angle = 70;
    let x1 = this.absoluteX;
    let y1 = this.absoluteY;
    let x2 = this.absoluteX2;
    let y2 = this.absoluteY2;

    if (this.facing === 'N') {
      angle = -angle;
      x1 = this.absoluteX2;
      y1 = this.absoluteY2;
      x2 = this.absoluteX;
      y2 = this.absoluteY;
    }

    const leftArrowPoint = this.calculatePoint(x2, y2, angle, false, arrowLength);
    const leftArrowPath = `M${x2},${y2}L${leftArrowPoint.x},${leftArrowPoint.y}`;

    const rightArrowPoint = this.calculatePoint(x2, y2, angle, true, arrowLength);
    const rightArrowPath = `M${x2},${y2}L${rightArrowPoint.x},${rightArrowPoint.y}`;

    return `M${x1},${y1}V${y2}${leftArrowPath}${rightArrowPath}`;
  }

  private calculatePoint(x: number, y: number, angle: number, opposite: boolean, distance: number): { x: number, y: number } {
    const toRad = (degrees: number): number => degrees * Math.PI / 100;
    const finalAngle = toRad(opposite ? angle * 2.45 : -angle);

    return {
      x: x + (distance * Math.cos(finalAngle)),
      y: y + (distance * Math.sin(finalAngle))
    };
  }
}
