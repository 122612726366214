import { Action, createSelector } from '@ngrx/store';
import { selectTicketsState } from '../../tickets.selectors';
import { Injectable, Injector } from '@angular/core';
import {
  TechPortalFeatureSolutionItemListXsStore,
  TechPortalFeatureSolutionItemListXsStoreState
} from '@nexnox-web/tech-portal/features/solution';

export interface TicketSolutionStoreState extends TechPortalFeatureSolutionItemListXsStoreState {
}

/* istanbul ignore next */
export const ticketSolutionStore = new TechPortalFeatureSolutionItemListXsStore({
  actionLabel: 'Tech Portal - Tickets - Ticket - Solution',
  stateSelector: createSelector(selectTicketsState, state => state.ticketSolution)
});

export function ticketSolutionStoreReducer(state: TicketSolutionStoreState, action: Action): any {
  return ticketSolutionStore.reducer(state, action);
}

@Injectable()
export class TicketSolutionStoreEffects extends ticketSolutionStore.effects {
  constructor(
    protected injector: Injector
  ) {
    super(injector);
  }
}
