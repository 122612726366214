<ng-template #entityEditTemplate let-title="title" let-id="id">
  <nexnox-web-notification-rules-edit #modelComponent
                                      [title]="title"
                                      [id]="id"
                                      [model]="model$ | async"
                                      [readonly]="readonly$ | async"
                                      [creating]="false"
                                      [loading]="loading$ | async"
                                      (modelChange)="onModelChange($event)">
  </nexnox-web-notification-rules-edit>
</ng-template>

<nexnox-web-entity-detail [entityDetailBaseComponent]="this"
                          [entityEditTemplate]="entityEditTemplate"
                          tabId="notificationRule"
                          [tabHeading]="title | translate"
                          [tabCommands]="['.']">
  <!-- Filter selection -->
  <ng-template nexnoxWebTabTemplate
               *ngIf="model$ | async as model"
               tabId="restriction"
               [heading]="'core-portal.settings.notification-rules.restriction' | translate"
               [lazy]="true"
               [hasError]="(model?.restriction?.filters ?? []).length === 0">
    <nexnox-web-settings-notification-rules-restriction [creating]="false"
                                                        [readonly]="readonly$ | async"
                                                        [loading]="loading$ | async"
                                                        [restriction]="model?.restriction"
                                                        [trigger]="model?.trigger"
                                                        (restrictionChanged)="onRestrictionChange($event, model)">
    </nexnox-web-settings-notification-rules-restriction>
  </ng-template>

  <!-- Target selection -->
  <ng-template nexnoxWebTabTemplate
               *ngIf="model$ | async as model"
               tabId="targets"
               [heading]="'core-portal.settings.notification-rules.target' | translate"
               [hasError]="(model?.targets ?? []).length === 0">
    <nexnox-web-settings-notification-rules-target-edit [targets]="model?.targets"
                                                        [readonly]="readonly$ | async"
                                                        [loading]="loading$ | async"
                                                        (targetsChanged)="onTargetsChanged($event, model)">
    </nexnox-web-settings-notification-rules-target-edit>
  </ng-template>

</nexnox-web-entity-detail>
