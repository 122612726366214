import {ChangeDetectionStrategy, Component, Injector, OnInit} from '@angular/core';
import {CorePortalEntityEditBaseComponent} from "@nexnox-web/libs/core-portal/src/lib/modules/entity";
import {
  AppEntityType,
  Filter,
  FilterOperators,
  FilterTypes,
  StateDto,
  StereotypeDto,
  TicketByContractDefinitionDto
} from '@nexnox-web/core-shared';
import {FormlyFieldConfig} from "@ngx-formly/core";
import {
  CorePortalSettingsStereotypeService,
  CorePortalTicketSettingsPriorityService,
  CorePortalTicketSettingsStateService
} from "../../store/services";

import {cloneDeep} from "lodash";
import {of} from "rxjs";

@Component({
  selector: 'nexnox-web-stereotypes-contract-settings-edit',
  templateUrl: './contract-settings-edit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StereotypeContractSettingsEditComponent extends CorePortalEntityEditBaseComponent<TicketByContractDefinitionDto> implements OnInit {

  constructor(
    protected injector: Injector,
    private priorityService: CorePortalTicketSettingsPriorityService,
    private ticketStateService: CorePortalTicketSettingsStateService,
    private stereotypeService: CorePortalSettingsStereotypeService
  ) {
    super(injector, 'StereotypeContractSettingsEditComponent');
  }

  public ngOnInit(): void {
    super.ngOnInit();
  }

  /* istanbul ignore next */
  public onModelChange(model: TicketByContractDefinitionDto): void {
    const newModel = {...this.model, ...cloneDeep(model)};
    this.modelSubject.next(newModel);
    this.modelChange.emit(this.model);
  }

  /* istanbul ignore next */
  public createForm(): FormlyFieldConfig[] {
    return [
      {
        key: 'state',
        type: 'core-portal-entity-select',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-6',
        defaultValue: null,
        templateOptions: {
          corePortalTranslated: {
            label: 'core-portal.settings.fields.contract-settings-ticket-state',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          },
          corePortalReadonly: {
            type: 5,
            displayKey: 'name',
            link: (state: StateDto) => state?.stateId ? [ 'states', state.stateId] : null,
            module: 'settings'
          },
          entityService: this.ticketStateService,
          idKey: 'stateId',
          displayKey: 'name',
          wholeObject: true,
          skipGetOne: true,
          clearable: false,
          link: (state: StateDto) => state?.stateId ? [ 'states', state.stateId] : null,
          module: 'settings'
        },
        expressionProperties: {
          'templateOptions.required': () => !this.readonly,
          'templateOptions.disabled': () => this.readonly,
          'templateOptions.readonly': () => this.readonly
        }
      },
      {
        key: 'priority',
        type: 'core-portal-entity-select',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-6',
        defaultValue: null,
        templateOptions: {
          corePortalTranslated: {
            label: 'core-portal.settings.fields.contract-settings-ticket-priority',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          },
          corePortalReadonly: {
            type: 5,
            displayKey: 'name'
          },
          entityService: this.priorityService,
          idKey: 'priorityId',
          displayKey: 'name',
          wholeObject: true,
          skipGetOne: true,
          clearable: false
        },
        expressionProperties: {
          'templateOptions.required': () => !this.readonly,
          'templateOptions.disabled': () => this.readonly,
          'templateOptions.readonly': () => this.readonly
        }
      },
      {
        key: 'ticketStereotype',
        type: 'core-portal-entity-select',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-6',
        defaultValue: null,
        templateOptions: {
          corePortalTranslated: {
            label: 'core-portal.settings.fields.contract-settings-ticket-stereotype',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          },
          corePortalReadonly: {
            type: 5,
            displayKey: 'name',
            link: (stereotype: StereotypeDto) => stereotype?.stereotypeId ? ['stereotypes', stereotype.stereotypeId] : null,
            module: 'settings'
          },
          entityService: this.stereotypeService,
          idKey: 'stereotypeId',
          displayKey: 'name',
          wholeObject: true,
          skipGetOne: true,
          clearable: false,
          link: (stereotype: StereotypeDto) => stereotype?.stereotypeId ? ['stereotypes', stereotype.stereotypeId] : null,
          module: 'settings',
          defaultFilters$: of([
            {
              property: 'isArchived',
              type: FilterTypes.DataTransferObject,
              operator: FilterOperators.Equal,
              value: 'false'
            },
            {
              property: 'entityType',
              type: FilterTypes.DataTransferObject,
              operator: FilterOperators.Equal,
              value: AppEntityType.Ticket.toString()
            }
          ] as Filter[]),
        },
        expressionProperties: {
          'templateOptions.required': () => !this.readonly,
          'templateOptions.disabled': () => this.readonly,
          'templateOptions.readonly': () => this.readonly
        }
      }
    ];
  }
}
