import {ChangeDetectionStrategy, Component, Injector, OnInit} from '@angular/core';
import {pathsDetailStore} from '../../store';
import {ActionButton, CorePortalEntityDetailBaseComponent} from 'libs/core-portal/src';
import {ApiNotificationService, AppPermissions, KnowledgePathDto} from 'libs/core-shared/src';
import {Observable, of, take} from 'rxjs';
import {map} from 'rxjs/operators';
import {faExclamationTriangle} from "@fortawesome/free-solid-svg-icons/faExclamationTriangle";
import {faTrashAlt} from "@fortawesome/free-solid-svg-icons/faTrashAlt";
import {OrgaPortalKnowledgePathService} from "@nexnox-web/orga-portal-lib";

@Component({
  selector: 'nexnox-web-knowledge-knowledge-paths-detail',
  templateUrl: './paths-detail.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PathsDetailComponent extends CorePortalEntityDetailBaseComponent<KnowledgePathDto> implements OnInit {

  public title = 'orga-portal.knowledge.subtitles.path-detail';

  public faExclamationTriangle = faExclamationTriangle;
  public faTrash = faTrashAlt;

  public canDeleteMigrationPaths$: Observable<boolean>;
  public learningPathsToDelete$: Observable<number[]>;

  protected idParam = 'knowledgePathId';
  protected displayKey = 'title';

  private knowledgePathService: OrgaPortalKnowledgePathService;
  private notificationService: ApiNotificationService;

  constructor(
    protected injector: Injector
  ) {
    super(injector, pathsDetailStore);
    this.knowledgePathService = injector.get(OrgaPortalKnowledgePathService);
    this.notificationService = injector.get(ApiNotificationService);
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.canDeleteMigrationPaths$ = this.model$.pipe(map(model => (model?.articles ?? []).filter(x => x.oldKnowledgePathId != null).length >= 2));
    this.learningPathsToDelete$ = this.model$.pipe(map(model => (model?.articles ?? []).map(x => x.oldKnowledgePathId).filter(x => x > 0)));
  }

  public onDeleteAllMigrationPaths(): void {
    this.modalService.showConfirmationModal(
      'orga-portal.knowledge.subtitles.delete-migration-paths',
      'orga-portal.knowledge.descriptions.should-delete-migration-paths',
      'error',
      'core-shared.shared.select.clear-all'
    ).then(() => this.deleteMigrationPaths()).catch(() => null);
  }

  /* istanbul ignore next */
  protected async getActionButtons(): Promise<ActionButton[]> {
    return this.getDefaultActionButtons(
      'orga-portal.knowledge.actions.edit-path',
      'orga-portal.knowledge.actions.save-path',
      'orga-portal.knowledge.actions.delete-path',
      'orga-portal.knowledge.descriptions.delete-path',
      AppPermissions.UpdateKnowledgeArticle,
      AppPermissions.DeleteKnowledgeArticle,
      ['/knowledge/paths']
    );
  }

  private deleteMigrationPaths(): void {   this.ngOnInit();
    this.knowledgePathService.deleteMigrationPaths(this.id).pipe(take(1)).subscribe(
      () => {
        this.notificationService.showTranslatedSuccess('orga-portal.knowledge.descriptions.all-migration-paths-deleted');
        this.canDeleteMigrationPaths$ = of(false);
      },
      (error) => this.notificationService.handleApiError(error),
    );
  }
}
