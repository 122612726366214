import {createSelector, MemoizedSelector, MemoizedSelectorWithProps} from '@ngrx/store';
import {AuthXsStoreState} from './auth-xs-store.state';
import {AppPermissions, AppRoleDto, TenantInfoDto} from '@nexnox-web/core-shared';

export interface AuthXsStoreSelectors {
  selectRefreshToken: MemoizedSelector<AuthXsStoreState, string>;
  selectAccessToken: MemoizedSelector<AuthXsStoreState, string>;
  selectPermissions: MemoizedSelector<AuthXsStoreState, AppPermissions[]>;
  selectHasPermission: MemoizedSelectorWithProps<AuthXsStoreState, { permission: AppPermissions }, boolean>;
  selectTenantId: MemoizedSelector<AuthXsStoreState, number>;
  selectTenant: MemoizedSelectorWithProps<AuthXsStoreState, { tenantId: number }, TenantInfoDto>;
  selectActiveTenant: MemoizedSelector<AuthXsStoreState, TenantInfoDto>;
  selectActiveRoles: MemoizedSelector<AuthXsStoreState, AppRoleDto[]>;
  selectTenants: MemoizedSelector<AuthXsStoreState, TenantInfoDto[]>;
  selectLoggedIn: MemoizedSelector<AuthXsStoreState, boolean>;
  selectLoading: MemoizedSelector<AuthXsStoreState, boolean>;
  selectLoaded: MemoizedSelector<AuthXsStoreState, boolean>;
}

export const createAuthXsStoreSelectors = (stateSelector: MemoizedSelector<any, AuthXsStoreState>): AuthXsStoreSelectors => {
  const selectPermissions = createSelector(stateSelector, state => state.permissions);
  const selectTenantId = createSelector(stateSelector, state => state?.tenantId ?? undefined);

  return {
    selectRefreshToken: createSelector(stateSelector, state => state.refreshToken),
    selectAccessToken: createSelector(stateSelector, state => state.accessToken),
    selectPermissions,
    selectHasPermission: createSelector(
      selectPermissions,
      (permissions, { permission }) => permissions.includes(permission)
    ),
    selectTenantId,
    selectTenant: createSelector(stateSelector, (state, { tenantId }) => {
      return (state?.info?.tenants ?? []).find(x => x.tenantId === tenantId) ?? null;
    }),
    selectActiveTenant: createSelector(stateSelector, state => state.activeTenant),
    selectActiveRoles: createSelector(stateSelector, state => state.activeRoles),
    selectTenants: createSelector(stateSelector, state => state?.info?.tenants ?? []),
    selectLoggedIn: createSelector(stateSelector, state => state.loggedIn),
    selectLoading: createSelector(stateSelector, state => state.loading),
    selectLoaded: createSelector(stateSelector, state => state.loaded),
  };
};
