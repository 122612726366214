import { EntityXsStore, EntityXsStoreState } from '@nexnox-web/core-store';
import { AppEntityType, StereotypeDto } from '@nexnox-web/core-shared';
import { createSelector } from '@ngrx/store';
import { selectStereotypesState } from '../../stereotypes.selector';
import { CorePortalStereotypeService } from '@nexnox-web/core-portal';
import { cloneDeep, isUndefined } from 'lodash';

export interface StereotypeDetailStoreState extends EntityXsStoreState<StereotypeDto> {
}

/* istanbul ignore next */
export const stereotypeDetailStore = new EntityXsStore<StereotypeDto>({
  actionLabel: 'Tech Portal - Settings - Stereotypes - Stereotype Detail',
  stateSelector: createSelector(selectStereotypesState, state => state.stereotypeDetail),
  serviceType: CorePortalStereotypeService,
  entityType: AppEntityType.Stereotype,
  stereotyped: false,
  sanitizeModel: (model, entity, base) => {
    const newModel = base(model, entity);

    newModel.customPropertySets = (newModel.customPropertySets ?? []).map(customSetReference => ({
      ...customSetReference,
      customPropertySet: ({
        ...customSetReference.customPropertySet,
        properties: (customSetReference.customPropertySet?.properties ?? []).map(property => {
          const newProperty = cloneDeep(property);

          if (isUndefined((newProperty as any).isRateable)) {
            delete (newProperty as any).weight;
          }

          return newProperty;
        })
      })
    }));

    return newModel;
  }
});
