import {ModuleWithProviders, NgModule} from "@angular/core";
import {SearchListsDeclarationsModule} from "./search-lists-declarations.module";
import {SearchListsRoutingModule} from "./search-lists-routing.module";
import {SearchListsStoreModule} from "./search-lists-store.module";

@NgModule({
  imports: [
    SearchListsDeclarationsModule,
    SearchListsRoutingModule,
    SearchListsStoreModule
  ]
})
export class CorePortalFeatureSettingsSearchListsModule {
  public static forFeature(): ModuleWithProviders<SearchListsDeclarationsModule> {
    return {ngModule: SearchListsDeclarationsModule};
  }
}
