import {
  BaseXsStore,
  BaseXsStoreReducerTypes,
  createPagedEntitiesXsStoreActions,
  PagedEntitiesXsStore,
  PagedEntitiesXsStoreEffects,
  PagedEntitiesXsStoreState
} from '@nexnox-web/core-store';
import { AppEntityType, CoreSharedApiBaseService, DeviceDto } from '@nexnox-web/core-shared';
import {
  OrgaPortalFeatureDeviceListXsStoreActions,
  OrgaPortalFeatureDeviceListXsStorePingOnePayload,
  OrgaPortalFeatureDeviceListXsStoreRebootOnePayload
} from './device-list-xs-store.actions';
import { Action, createAction, props, select } from '@ngrx/store';
import { Injectable, Injector, Type } from '@angular/core';
import { createEffect, ofType } from '@ngrx/effects';
import { catchError, groupBy, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { OrgaPortalFeatureDeviceService, OrgaPortalFeaturePingService } from '../../services';
import { of } from 'rxjs';
import { authStore } from '@nexnox-web/core-portal';
import { immerOn } from 'ngrx-immer/store';

export interface DeviceListXsStoreState extends PagedEntitiesXsStoreState<DeviceDto> {
}

export class DeviceListXsStore extends PagedEntitiesXsStore<DeviceDto, DeviceDto, DeviceListXsStoreState> {
  public actions: OrgaPortalFeatureDeviceListXsStoreActions;

  protected createActions(label: string): OrgaPortalFeatureDeviceListXsStoreActions {
    return {
      ...createPagedEntitiesXsStoreActions(label),

      rebootOne: createAction(
        BaseXsStore.getType(label, 'Reboot'),
        props<OrgaPortalFeatureDeviceListXsStoreRebootOnePayload>()
      ),
      rebootOneSuccess: createAction(
        BaseXsStore.getType(label, 'Reboot success'),
        props<OrgaPortalFeatureDeviceListXsStoreRebootOnePayload>()
      ),

      pingOne: createAction(
        BaseXsStore.getType(label, 'Ping'),
        props<OrgaPortalFeatureDeviceListXsStorePingOnePayload>()
      ),
      pingOneSuccess: createAction(
        BaseXsStore.getType(label, 'Ping success'),
        props<OrgaPortalFeatureDeviceListXsStorePingOnePayload>()
      )
    };
  }

  protected createReducerArray(
    initialState: DeviceListXsStoreState
  ): BaseXsStoreReducerTypes<DeviceListXsStoreState, OrgaPortalFeatureDeviceListXsStoreActions>[] {
    return [
      ...super.createReducerArray(initialState),

      immerOn(this.actions.rebootOne, draft => {
        draft.loading = true;
      }),
      immerOn(this.actions.rebootOneSuccess, draft => {
        draft.loading = false;
      }),

      immerOn(this.actions.pingOne, draft => {
        draft.loading = true;
      }),
      immerOn(this.actions.pingOneSuccess, draft => {
        draft.loading = false;
      })
    ];
  }

  protected createEffects(
    serviceType: Type<CoreSharedApiBaseService>,
    entityType: AppEntityType,
    prepareEntity: (entity: DeviceDto) => DeviceDto,
    prepareModel: (entity: DeviceDto, model: DeviceDto) => DeviceDto,
    sanitizeModel: (model: DeviceDto, entity: DeviceDto) => DeviceDto,
    ...args
  ): Type<PagedEntitiesXsStoreEffects<DeviceDto, DeviceDto, DeviceListXsStoreState>> {
    const actions = this.actions;
    const selectors = this.selectors;

    @Injectable()
    class Effects extends PagedEntitiesXsStoreEffects<DeviceDto> {
      public rebootOne$: any;
      public rebootOneSuccess$: any;

      public pingOne$: any;
      public pingOneSuccess$: any;

      protected actions: OrgaPortalFeatureDeviceListXsStoreActions;
      protected service: OrgaPortalFeatureDeviceService;
      protected pingService: OrgaPortalFeaturePingService;

      constructor(
        protected injector: Injector
      ) {
        super(injector, actions, selectors, serviceType, entityType, prepareEntity, prepareModel, sanitizeModel, false);

        this.pingService = this.injector.get(OrgaPortalFeaturePingService);
      }

      protected createEffects(): void {
        super.createEffects();

        this.rebootOne$ = createEffect(() => this.actions$.pipe(
          ofType(this.actions.rebootOne),
          groupBy(({ id }) => id),
          mergeMap(group => group.pipe(
            switchMap(({ id, parentIds }) => this.service.reboot(id, parentIds).pipe(
              map(() => this.actions.rebootOneSuccess({ id, parentIds })),
              catchError(error => of(this.actions.error({ error, action: this.actions.rebootOne })))
            ))
          ))
        ));

        this.rebootOneSuccess$ = createEffect(() => this.actions$.pipe(
          ofType(this.actions.rebootOneSuccess),
          tap(action => this.actionCallback(action, false))
        ), { dispatch: false });

        this.pingOne$ = createEffect(() => this.actions$.pipe(
          ofType(this.actions.pingOne),
          groupBy(({ id }) => id),
          mergeMap(group => group.pipe(
            withLatestFrom(this.store.pipe(select(authStore.selectors.selectTenantId))),
            switchMap(([{ id }, tenantId]) => this.pingService.ping(id, tenantId).pipe(
              map(() => this.actions.pingOneSuccess({ id })),
              catchError(error => of(this.actions.error({ error, action: this.actions.pingOne })))
            ))
          ))
        ));

        this.pingOneSuccess$ = createEffect(() => this.actions$.pipe(
          ofType(this.actions.pingOneSuccess),
          tap(action => this.actionCallback(action, false))
        ), { dispatch: false });
      }

      protected actionCallback(action: Action, isError: boolean = false): void {
        super.actionCallback(action, isError);

        this.checkAction(this.actions.rebootOneSuccess, action, () => this.rebootOneSuccess());
        this.checkAction(this.actions.pingOneSuccess, action, () => this.pingOneSuccess());
      }

      protected rebootOneSuccess(): void {
        this.apiNotificationService.showTranslatedSuccess('orga-portal.devices.toasts.device-rebooted');
      }

      protected pingOneSuccess(): void {
        this.apiNotificationService.showTranslatedSuccess('orga-portal.devices.toasts.device-pinged');
      }
    }

    return Effects;
  }
}
