import { ChangeDetectionStrategy, Component, Injector } from '@angular/core';
import { CorePortalFeatureAuditListBaseComponent } from '@nexnox-web/core-portal/features/audit';
import { ticketAuditStore } from '../../store';

@Component({
  selector: 'nexnox-web-tickets-ticket-audit',
  templateUrl: './ticket-audit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TicketAuditComponent extends CorePortalFeatureAuditListBaseComponent {
  public componentId = 'TicketAuditComponent';

  constructor(
    protected injector: Injector
  ) {
    super(injector, ticketAuditStore);
  }
}
