<ng-template #entityEditTemplate let-title="title" let-id="id">
  <nexnox-web-forms-form-edit #modelComponent
                              [title]="title"
                              [id]="id"
                              [model]="model$ | async"
                              [readonly]="(readonly$ | async) || (isOld$ | async)"
                              [creating]="false"
                              [loading]="loading$ | async"
                              [stereotyped]="false"
                              (modelChange)="onModelChange($event)">
  </nexnox-web-forms-form-edit>
</ng-template>

<div *ngIf="(loading$ | async) === false && (isOld$ | async)"
     class="alert alert-warning d-flex align-items-center w-100">
  <fa-icon [icon]="faExclamationTriangle" class="mr-2"></fa-icon>
  <span>
    {{ 'orga-portal.forms.descriptions.is-old' | translate }}
    <a nexnoxWebTenantRouterLink="/forms/{{(model$ | async)?.activeFormId}}"
       class="text-white font-weight-bold">
      {{ 'orga-portal.forms.actions.to-current-version' | translate }}
      <fa-icon [icon]="faArrowRight"></fa-icon>
    </a>
  </span>
</div>

<nexnox-web-entity-detail [entityDetailBaseComponent]="this"
                          [entityEditTemplate]="entityEditTemplate"
                          tabId="form"
                          [tabHeading]="title | translate"
                          [tabCommands]="['.']">
</nexnox-web-entity-detail>
