import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ChildTaskSimpleDto, ConcreteTaskTypes, UnsubscribeHelper } from '@nexnox-web/core-shared';
import {
  CorePortalFormlyReadonlyTypes,
  CorePortalFormlyReadonlyTyping,
  CorePortalFormlyTranslatedTyping,
  CorePortalTenantRouter,
  CoreSharedRuleEditorListComponent,
  InheritedField,
  ModelValid
} from '@nexnox-web/core-portal';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { sortBy } from 'lodash';
import { OrgaPortalFeatureFormService } from '@nexnox-web/orga-portal/features/forms';

@Component({
  selector: 'nexnox-web-tasks-task-subtasks',
  templateUrl: './task-subtasks.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TaskSubtasksComponent extends UnsubscribeHelper implements OnInit, ModelValid {
  @Input() public strictOrder$: Observable<boolean>;
  @Input() public subtasks$: Observable<ChildTaskSimpleDto[]>;

  @Input() public readonly: boolean;
  @Input() public loading: boolean;

  @ViewChild('subtasksEdit') public subtasksEdit: CoreSharedRuleEditorListComponent;

  @Output() public strictOrderChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() public subtasksChange: EventEmitter<ChildTaskSimpleDto[]> = new EventEmitter<ChildTaskSimpleDto[]>();

  public sortedSubtasks$: Observable<ChildTaskSimpleDto[]>;

  public subtasksTitleFn: any;
  public subtasksFieldsFn: any;
  public subtasksCanSelectFn: any;
  public subtasksSelectFn: any;

  /* istanbul ignore next */
  constructor(
    private tenantRouter: CorePortalTenantRouter,
    private formService: OrgaPortalFeatureFormService
  ) {
    super();

    this.subtasksTitleFn = (subtask: ChildTaskSimpleDto) => subtask.title;
    this.subtasksFieldsFn = () => this.createSubtasksFields();
    this.subtasksCanSelectFn = (subtask: ChildTaskSimpleDto) => Boolean(subtask.taskId);
    this.subtasksSelectFn = (subtask: ChildTaskSimpleDto) => window.open(
      this.tenantRouter.createUrlTree(['/tasks', subtask.taskId], { fragment: 'edit' }).toString(),
      '_blank'
    );
  }

  public ngOnInit(): void {
    this.sortedSubtasks$ = this.subtasks$.pipe(
      map(subtasks => sortBy(subtasks, subtask => subtask.position))
    );
  }

  public onStrictOrderChange(strictOrder: boolean): void {
    this.strictOrderChange.emit(strictOrder);
  }

  public onSubtasksChange(subtasks: ChildTaskSimpleDto[]): void {
    this.subtasksChange.emit(subtasks);
  }

  public onReset(): void {
    this.subtasksEdit?.ngOnInit();
  }

  public isModelValid(): boolean {
    return this.subtasksEdit ? this.subtasksEdit.areItemsValid() : true;
  }

  public isOwnModelValid(): boolean {
    return this.subtasksEdit ? this.subtasksEdit.isModelValid() : true;
  }

  public isOwnModelPristine(): boolean {
    return this.subtasksEdit ? this.subtasksEdit.isPristine() : true;
  }

  /* istanbul ignore next */
  public createSubtasksFields(): InheritedField[] {
    return [
      {
        formlyConfig: {
          defaultValue: ConcreteTaskTypes.ChildDocument
        },
        ownValueKey: 'type',
        hide: () => true
      },
      {
        formlyConfig: {
          type: 'input',
          wrappers: ['core-portal-translated', 'core-portal-readonly'],
          className: 'col-md-6',
          templateOptions: {
            corePortalTranslated: {
              label: 'core-shared.shared.fields.title',
              validationMessages: {
                required: 'core-portal.core.validation.required'
              }
            } as CorePortalFormlyTranslatedTyping,
            corePortalReadonly: {
              type: CorePortalFormlyReadonlyTypes.BASIC
            } as CorePortalFormlyReadonlyTyping
          },
          expressionProperties: {
            'templateOptions.required': () => !this.readonly,
            'templateOptions.readonly': () => this.readonly
          }
        },
        ownValueKey: 'title'
      },
      {
        formlyConfig: {
          type: 'core-portal-entity-select',
          wrappers: ['core-portal-translated', 'core-portal-readonly'],
          className: 'col-md-6',
          defaultValue: null,
          templateOptions: {
            corePortalTranslated: {
              label: 'core-shared.shared.fields.form',
              validationMessages: {
                required: 'core-portal.core.validation.required'
              }
            } as CorePortalFormlyTranslatedTyping,
            corePortalReadonly: {
              type: CorePortalFormlyReadonlyTypes.ENTITY,
              displayKey: 'title'
            } as CorePortalFormlyReadonlyTyping,
            entityService: this.formService,
            idKey: 'formId',
            displayKey: 'title',
            wholeObject: true,
            skipGetOne: true
          },
          expressionProperties: {
            'templateOptions.required': () => !this.readonly,
            'templateOptions.readonly': () => this.readonly
          }
        },
        ownValueKey: 'form'
      }
    ];
  }
}
