import {PagedEntitiesXsStore, PagedEntitiesXsStoreState} from '@nexnox-web/core-store';
import {Injectable, Injector} from '@angular/core';
import {AppEntityType, ResourceListDto} from '@nexnox-web/core-shared';
import {Action, createSelector} from '@ngrx/store';
import {selectControlsState} from '../../controls.selectors';
import {TechPortalFeatureControlResourceService} from "../../services";

export interface ControlResourcesStoreState extends PagedEntitiesXsStoreState<ResourceListDto> {
}

/* istanbul ignore next */
export const controlResourcesStore = new PagedEntitiesXsStore<ResourceListDto>({
  actionLabel: 'Tech Portal - Controls - Control Resources',
  stateSelector: createSelector(selectControlsState, state => state.controlResources),
  entityType: AppEntityType.Resource,
  serviceType: TechPortalFeatureControlResourceService,
  selectId: resource => resource.entity?.resourceId ?? resource.model?.resourceId
});

export function controlResourcesStoreReducer(state: ControlResourcesStoreState, action: Action): any {
  return controlResourcesStore.reducer(state, action);
}

@Injectable()
export class ControlResourcesStoreEffects extends controlResourcesStore.effects {
  constructor(
    protected injector: Injector
  ) {
    super(injector);
  }
}
