import {PagedEntitiesXsStore, PagedEntitiesXsStoreState} from '@nexnox-web/core-store';
import {AppEntityType, ResourceEventListDto} from '@nexnox-web/core-shared';
import {Action, ActionReducer, createSelector} from '@ngrx/store';
import {selectResourcesState} from '../../resources.selectors';
import {TechPortalFeatureResourceEventService} from '../../services';
import {Injectable, Injector} from '@angular/core';

export interface ResourceEventListStoreState extends PagedEntitiesXsStoreState<ResourceEventListDto> {
}

/* istanbul ignore next */
export const resourceEventListStore = new PagedEntitiesXsStore<ResourceEventListDto>({
  actionLabel: 'Core Portal - Resources - Resource Event List',
  stateSelector: createSelector(selectResourcesState, state => state.resourceEventList),
  serviceType: TechPortalFeatureResourceEventService,
  entityType: AppEntityType.None,
  selectId: resource => resource.entity?.resourceEventId ?? resource.model?.resourceEventId
});

export function resourceEventListStoreReducer(state: ResourceEventListStoreState, action: Action): ActionReducer<any> {
  return resourceEventListStore.reducer(state, action);
}

@Injectable()
export class ResourceEventListStoreEffects extends resourceEventListStore.effects {
  constructor(
    protected injector: Injector
  ) {
    super(injector);
  }
}
