<ng-template #entityEditTemplate let-title="title" let-id="id">
  <nexnox-web-tasks-task-edit #modelComponent
                              [title]="title"
                              [id]="id"
                              [model]="model$ | async"
                              [isChild]="isChild$ | async"
                              [readonly]="readonly$ | async"
                              [creating]="false"
                              [loading]="loading$ | async"
                              [stereotyped]="false"
                              (modelChange)="onModelChange($event)">
  </nexnox-web-tasks-task-edit>
</ng-template>

<nexnox-web-entity-detail [entityDetailBaseComponent]="this"
                          [entityEditTemplate]="entityEditTemplate"
                          tabId="task"
                          [tabHeading]="title | translate"
                          [tabCommands]="['.']"
                          [ready$]="loaded$">
  <ng-container *ngIf="model$ | async as model">
    <!-- Subtasks Tab -->
    <ng-template nexnoxWebTabTemplate
                 tabId="subtasks"
                 [heading]="'orga-portal.tasks.subtitles.subtasks' | translate"
                 [hasError]="subtasksComponent ? !subtasksComponent.isModelValid() : false"
                 [hide]="(isChild$ | async) || model.type !== concreteTaskTypes.Collection"
                 (selectTab)="onNavigateToTab(['subtasks'])">
      <nexnox-web-tasks-task-subtasks #subtasksComponent
                                      #modelComponent
                                      [strictOrder$]="strictOrder$"
                                      [subtasks$]="subtasks$"
                                      [readonly]="readonly$ | async"
                                      [loading]="loading$ | async"
                                      (strictOrderChange)="onStrictOrderChange($event, model)"
                                      (subtasksChange)="onSubtasksChange($event, model)">
      </nexnox-web-tasks-task-subtasks>
    </ng-template>

    <!-- Executions -->
    <ng-template nexnoxWebTabTemplate
                 tabId="executions"
                 [heading]="'orga-portal.tasks.subtitles.executions' | translate"
                 [hasError]="executionsComponent ? !executionsComponent.isModelValid() : false"
                 [hide]="(isChild$ | async) || $any(model).executionPlan?.type !== executionTypes.Planned"
                 (selectTab)="onNavigateToTab(['executions'])">
      <nexnox-web-tasks-task-executions #executionsComponent
                                        #modelComponent
                                        [executionPatterns$]="executionPatterns$"
                                        [readonly]="readonly$ | async"
                                        [loading]="loading$ | async"
                                        (executionPatternsChange)="onExecutionPatternsChange($event, model)">
      </nexnox-web-tasks-task-executions>
    </ng-template>

    <!-- Impacts Tab -->
    <ng-template nexnoxWebTabTemplate
                 tabId="impacts"
                 [heading]="'orga-portal.tasks.subtitles.impacts' | translate"
                 [hasError]="impactsComponent ? !impactsComponent.isModelValid() : false"
                 (selectTab)="onNavigateToTab(['impacts'])">
      <nexnox-web-tasks-task-impacts #impactsComponent
                                     #modelComponent
                                     [task$]="model$"
                                     [impacts$]="impacts$"
                                     [readonly]="readonly$ | async"
                                     [loading]="loading$ | async"
                                     (impactsChange)="onImpactsChange($event, model)">
      </nexnox-web-tasks-task-impacts>
    </ng-template>

    <!-- Planned Tab -->
    <ng-template nexnoxWebTabTemplate
                 tabId="planned"
                 [heading]="'orga-portal.tasks.subtitles.planned-tasks' | translate"
                 (selectTab)="onNavigateToTab(['planned'])">
      <nexnox-web-tasks-planned-tasks *ngIf="passedIds"
                                      [taskId]="model.taskId"
                                      [isCollection]="model.type === concreteTaskTypes.Collection">
      </nexnox-web-tasks-planned-tasks>
    </ng-template>
  </ng-container>
</nexnox-web-entity-detail>
