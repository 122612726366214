import {CoreSharedApiBaseService, Product} from '@nexnox-web/core-shared';
import {Injectable, Injector} from '@angular/core';

@Injectable()
export class InfoProgressFunctionService extends CoreSharedApiBaseService {
  constructor(
    protected injector: Injector
  ) {
    super(injector, Product.ORGA, 'function', ['v2/infoprogress']);
  }
}
