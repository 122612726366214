<ng-select class="ng-form-select"
           [class.is-invalid]="showError"
           [items]="items$ | async"
           [formControl]="$any(formControl)"
           [formlyAttributes]="field"
           [searchable]="true"
           [clearable]="!to.required"
           groupBy="countries"
           bindLabel="name"
           bindValue="code">
  <ng-template ng-optgroup-tmp let-item="item">
    <span [title]="item.title | translate">
        {{ item.title | translate }}
    </span>
  </ng-template>

  <ng-template ng-label-tmp let-item="item">
    <span class="d-flex" [title]="item.name">
        <span class="flag-icon flag-icon-{{ item.code.toLowerCase() }} mr-2"></span>
      {{ item.name }}
    </span>
  </ng-template>

  <ng-template ng-option-tmp let-item="item" let-searchTerm="searchTerm">
    <span class="d-flex" [title]="item.name">
        <span class="flag-icon flag-icon-{{ item.code.toLowerCase() }} mr-2"></span>
        <span [innerHTML]="item.name | nexnoxWebHighlight:searchTerm"></span>
    </span>
  </ng-template>

  <ng-template ng-notfound-tmp>
    <div class="ng-option disabled">
      {{ 'core-shared.shared.table.empty' | translate }}
    </div>
  </ng-template>
</ng-select>
