import {InjectionToken, NgModule} from '@angular/core';
import {ActionReducerMap, StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {controlsEffects, controlsReducers} from './store';

export const REDUCER_TOKEN = new InjectionToken<ActionReducerMap<any>>('@nexnox-web/tech-portal/controls: Store Reducers');

@NgModule({
  imports: [
    StoreModule.forFeature('tech-portal-feature-controls', REDUCER_TOKEN),
    EffectsModule.forFeature(controlsEffects)
  ],
  providers: [
    {
      provide: REDUCER_TOKEN,
      useValue: controlsReducers
    }
  ]
})
export class ControlsStoreModule {
}
