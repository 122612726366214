import { ErrorHandler, Injectable } from '@angular/core';
import { CoreSharedChunkErrorHandler } from '../chunk/chunk.error-handler';

@Injectable()
export class CoreSharedGlobalErrorHandler implements ErrorHandler {
  constructor(
    private chunkErrorHandler: CoreSharedChunkErrorHandler
  ) {
  }

  public handleError(error: any): void {
    this.chunkErrorHandler.handleError(error);

    console.error(error);
  }
}
