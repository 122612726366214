import {ChangeDetectionStrategy, Component, Injector} from '@angular/core';
import {ActionButton, CorePortalEntityDetailBaseComponent} from '@nexnox-web/core-portal';
import {AppPermissions, IssueContentType, IssueTemplateDto} from '@nexnox-web/core-shared';
import {issueTemplateDetailStore} from '../../store/stores';
import {lastValueFrom} from "rxjs";
import {filter, take} from "rxjs/operators";

@Component({
  selector: 'nexnox-web-settings-issue-template-detail',
  templateUrl: './issue-template-detail.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IssueTemplateDetailComponent extends CorePortalEntityDetailBaseComponent<IssueTemplateDto> {

  public title = 'core-portal.settings.issue-template.titles.detail';

  protected idParam = 'issueTemplateId';

  constructor(
    protected injector: Injector
  ) {
    super(injector, issueTemplateDetailStore);
  }

  protected async getActionButtons(): Promise<ActionButton[]> {

    const model = await lastValueFrom(this.model$.pipe(filter(model => Boolean(model.contentType)), take(1)));
    let saveButtonLabelKey;

    switch (model.contentType) {
      case IssueContentType.Message:
        saveButtonLabelKey = 'core-portal.settings.issue-template.actions.save-message-template';
        break;
      case IssueContentType.Mission:
        saveButtonLabelKey = 'core-portal.settings.issue-template.actions.save-mission-template';
        break;
      case IssueContentType.Ticket:
        saveButtonLabelKey = 'core-portal.settings.issue-template.actions.save-ticket-template';
        break;
      case IssueContentType.WinterhalterServiceCall:
        saveButtonLabelKey = 'core-portal.settings.issue-template.actions.save-winterhalter-service-call-template';
        break;
      case IssueContentType.WinterhalterProductOrder:
        saveButtonLabelKey = 'core-portal.settings.issue-template.actions.save-winterhalter-product-order-template';
        break;
      default:
        saveButtonLabelKey = 'core-portal.settings.issue-template.actions.save';
        break;
    }

    return this.getDefaultActionButtons(
      'core-portal.settings.issue-template.actions.edit',
      saveButtonLabelKey,
      'core-portal.settings.issue-template.actions.delete',
      'core-portal.settings.issue-template.descriptions.delete',
      AppPermissions.UpdateIssueTemplate,
      AppPermissions.DeleteIssueTemplate,
      undefined
    );
  }
}
