<p-sidebar #sidebarComponent
           position="right"
           appendTo="body"
           styleClass="p-sidebar-lg"
           [transitionOptions]="transitionOptions"
           [visible]="visible$ | async"
           (visibleChange)="onVisibleChange($event)">

  <h4>{{ 'missions.subtitles.editor-suggestions-current-editor' | translate }}</h4>
  <div class="d-flex flex-row mb-4">
    <ng-container *ngIf="currentSolutionContact$ | async as solutionContact; else noSolutionContactTemplate">
      <fa-icon [icon]="faUser"
               [fixedWidth]="true"
               size="sm"
               class="mr-2"></fa-icon>
      <a class="btn-link"
         [nexnoxWebTenantRouterLink]="['/masterdata/contacts', solutionContact.contactId]"
         module="management"
         target="_blank">
        {{ solutionContact.displayName }}
      </a>
    </ng-container>
    <ng-template #noSolutionContactTemplate>
      {{ 'core-shared.shared.select.empty' | translate }}
    </ng-template>
  </div>

  <h4 class="mt-4">{{ 'missions.subtitles.editor-suggestions-inquire' | translate }}</h4>
  <div class="row no-gutters">
    <div class="col-7 p-0">
      <form [formGroup]="form" class="w-100">
        <formly-form class="w-100"
                     [form]="form"
                     [(model)]="model"
                     [fields]="fields">
        </formly-form>
      </form>
    </div>
    <div class="col-5 p-0 pl-2">
      <button pButton
              type="button"
              class="p-button p-button-primary"
              [label]="'missions.actions.start-quest' | translate"
              [icon]="faUsers | nexnoxWebFaIconStringPipe"
              [disabled]="!model?.functionGroup?.functionId"
              (click)="onStartQuest(model?.functionGroup)">
      </button>
    </div>
  </div>

  <ng-container *ngIf="editors$ | async as editors;">
    <h4 class="mt-4">{{ 'missions.subtitles.editor-suggestions' | translate }}</h4>
    <nexnox-web-basic-table [config]="config"
                            [data]="editors"
                            [loading]="loading">
    </nexnox-web-basic-table>
  </ng-container>
</p-sidebar>
