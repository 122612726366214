import { ModuleWithProviders, NgModule } from '@angular/core';
import { CorePortalFeatureResourcesDeclarationsModule } from './resources-declarations.module';

@NgModule({
  imports: [
    CorePortalFeatureResourcesDeclarationsModule
  ]
})
export class CorePortalFeatureResourcesModule {
  public static forFeature(): ModuleWithProviders<CorePortalFeatureResourcesDeclarationsModule> {
    return { ngModule: CorePortalFeatureResourcesDeclarationsModule };
  }
}
