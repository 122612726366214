import {PagedEntitiesXsStoreState} from '@nexnox-web/core-store';
import {AppEntityType, IssueListDto} from '@nexnox-web/core-shared';
import {createSelector} from '@ngrx/store';
import {selectIssuesState} from '../../issues.selectors';
import {IssueListXsStore} from './issue-list.xs-store';
import {TechPortalFeatureIssueListService} from "../../services";

export interface IssueListStoreState extends PagedEntitiesXsStoreState<IssueListDto> {
}

/* istanbul ignore next */
export const issueListStore = new IssueListXsStore({
  actionLabel: 'Tech Portal - Issues - Issue List',
  stateSelector: createSelector(selectIssuesState, state => state.issueList),
  serviceType: TechPortalFeatureIssueListService,
  entityType: AppEntityType.Issue,
  selectId: issue => issue.entity?.issueId ?? issue.model?.issueId
});
