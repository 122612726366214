import { Injectable, Injector } from '@angular/core';
import { AppEntityType, MissionReportReceiptDto } from '@nexnox-web/core-shared';
import { EntityXsStoreState } from '@nexnox-web/core-store';
import { createEffect, ofType } from '@ngrx/effects';
import { Action, createSelector } from '@ngrx/store';
import { catchError, map, of, switchMap, tap } from 'rxjs';
import { selectMissionsState } from '../../missions.selectors';
import { TechPortalFeatureMissionReceiptService } from '../../services';
import { MissionReceiptXsStore, MissionReceiptXsStoreActions } from './mission-receipt.xs-store';

export interface MissionReceiptStoreState extends EntityXsStoreState<MissionReportReceiptDto> {
}

/* istanbul ignore next */
export const missionReceiptStore = new MissionReceiptXsStore({
  actionLabel: 'Tech Portal - Missions - Mission Receipt',
  stateSelector: createSelector(selectMissionsState, state => state.missionReceipt),
  serviceType: TechPortalFeatureMissionReceiptService,
  entityType: AppEntityType.None
});

@Injectable()
export class MissionReceiptStoreEffects extends missionReceiptStore.effects {

  public getReceipt$: any;
  public getReceiptSuccess$: any;

  public actions: MissionReceiptXsStoreActions;

  protected service: TechPortalFeatureMissionReceiptService;

  constructor(
    protected injector: Injector
  ) {
    super(injector);
  }

  protected createEffects(): void {
    super.createEffects();

    this.getReceipt$ = createEffect(() => this.actions$.pipe(
      ofType(this.actions.getReceipt),
      switchMap(({ missionId }) => this.service.getReceipt([missionId]).pipe(
        map(receipt => this.actions.getReceiptSuccess({
          entity: this.prepareEntity(receipt),
          model: this.prepareModel(this.prepareEntity(receipt), {} as any)
        })),
        catchError(error => of(this.actions.error({ error, action: this.actions.getReceipt, suppressNotification: error.status !== 404 ? false : true })))
      ))
    ));
    this.getReceiptSuccess$ = createEffect(() => this.actions$.pipe(
      ofType(this.actions.getReceiptSuccess),
      tap(action => this.actionCallback(action, false))
    ), { dispatch: false });

  }

  protected actionCallback(action: Action, isError: boolean): void {
    super.actionCallback(action, isError);
  }
}
