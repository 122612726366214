<ng-template #entityEditTemplate let-title="title" let-id="id">
    <nexnox-web-master-data-companies-company-edit #modelComponent
                                                   [title]="title"
                                                   [id]="id"
                                                   [model]="model$ | async"
                                                   [readonly]="readonly$ | async"
                                                   [creating]="false"
                                                   [loading]="loading$ | async"
                                                   [stereotypes$]="stereotypes$"
                                                   (modelChange)="onModelChange($event)">
    </nexnox-web-master-data-companies-company-edit>
</ng-template>

<nexnox-web-entity-detail [entityDetailBaseComponent]="this"
                          [entityEditTemplate]="entityEditTemplate"
                          tabId="company"
                          [tabHeading]="title | translate"
                          [tabCommands]="['.']">
    <!-- Contacts Tab -->
    <ng-template nexnoxWebTabTemplate
                 tabId="contacts"
                 [heading]="'core-portal.master-data.subtitles.contact-list' | translate"
                 [lazy]="true"
                 (selectTab)="onNavigateToTab(['contacts'])">
        <nexnox-web-master-data-companies-company-contact-list *ngIf="passedIds"
                                                               [isEmbedded]="true"
                                                               [parentIds]="passedIds"
                                                               [getEntityOnModeChange]="false">
        </nexnox-web-master-data-companies-company-contact-list>
    </ng-template>

    <!-- Addresses Tab -->
    <ng-template nexnoxWebTabTemplate
                 tabId="addresses"
                 *ngIf="displayAddressesPermission$ | async"
                 [heading]="'core-portal.master-data.subtitles.address-list' | translate"
                 [lazy]="true"
                 (selectTab)="onNavigateToTab(['addresses'])">
        <nexnox-web-master-data-companies-company-address-list *ngIf="passedIds"
                                                               [isEmbedded]="true"
                                                               [canCreate]="true"
                                                               [parentIds]="passedIds">
        </nexnox-web-master-data-companies-company-address-list>
    </ng-template>
</nexnox-web-entity-detail>
