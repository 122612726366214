import { FieldWrapper, FormlyTemplateOptions } from '@ngx-formly/core';
import { ChangeDetectionStrategy, Component, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { ValidationMessageWithArgs } from '../../components';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons/faCheckCircle';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons/faExclamationCircle';
import { distinctUntilChanged, map, shareReplay, startWith } from 'rxjs/operators';
import { isNull, isUndefined } from 'lodash';
import { Observable } from 'rxjs';

export interface CorePortalFormlyTranslatedSetPointMessage {
  label: string;
  value: any;
  args?: { [key: string]: any };
  valid?: boolean;
}

export interface CorePortalFormlyTranslatedTyping {
  label: string;
  description?: string;
  hideLabel?: boolean;
  hideValue?: boolean;
  title?: boolean;
  bold?: boolean;
  labelClass?: string;
  hideRequiredMarker?: boolean;
  formGroupClassName?: string;
  validationMessages?: { [key: string]: string | ValidationMessageWithArgs };
  horizontal?: boolean;
  horizontalLabelClass?: string;
  horizontalFieldClass?: string;
  descriptionTemplate?: TemplateRef<any>;
  rateable?: boolean;
  setPointMessages?: CorePortalFormlyTranslatedSetPointMessage[];
}

interface FormlyTranslatedTemplateOptions extends FormlyTemplateOptions {
  corePortalTranslated: CorePortalFormlyTranslatedTyping;
}

@Component({
  templateUrl: './formly-translated.component.html',
  styleUrls: ['./formly-translated.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormlyTranslatedComponent extends FieldWrapper implements OnInit {
  @ViewChild('fieldComponent', { read: ViewContainerRef }) public fieldComponent: ViewContainerRef;

  public value$: Observable<any>;
  public hasValue$: Observable<boolean>;

  public readonly to: FormlyTranslatedTemplateOptions;

  public faCheckCircle = faCheckCircle;
  public faExclamationCircle = faExclamationCircle;

  public ngOnInit(): void {
    this.value$ = this.formControl.valueChanges.pipe(
      startWith(this.formControl.value),
      distinctUntilChanged(),
      shareReplay(1)
    );

    this.hasValue$ = this.value$.pipe(
      map(value => !isUndefined(value) && !isNull(value) && value !== '')
    );
  }
}
