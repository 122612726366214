import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SidebarComponent, SidebarItemComponent} from './components';
import {SidebarModule} from 'ng-sidebar';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {RouterModule} from '@angular/router';
import {CORE_PORTAL_SIDEBAR_ITEMS} from './tokens';
import {CoreSharedModule} from '@nexnox-web/core-shared';
import {CollapseModule} from 'ngx-bootstrap/collapse';
import {CorePortalRouterOverridesModule} from '../../router-overrides';
import {CorePortalSidebarService} from './services';
import {ButtonModule} from 'primeng/button';
import {CorePortalSettingsMenuService} from "@nexnox-web/core-portal/features/settings/menu";

@NgModule({
  declarations: [
    SidebarComponent,
    SidebarItemComponent
  ],
  imports: [
    CommonModule,
    SidebarModule,
    FontAwesomeModule,
    RouterModule,
    CollapseModule,
    CoreSharedModule,
    CorePortalRouterOverridesModule,
    ButtonModule
  ],
  exports: [
    SidebarComponent
  ],
  providers: [
    CorePortalSettingsMenuService,
    CorePortalSidebarService,
    {
      provide: CORE_PORTAL_SIDEBAR_ITEMS,
      useValue: []
    }
  ]
})
export class CorePortalSidebarModule {
}
