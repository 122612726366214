import { InjectionToken, NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { ActionReducerMap, StoreModule } from '@ngrx/store';
import { articlesEffects, articlesReducers } from './store';

export const REDUCER_TOKEN = new InjectionToken<ActionReducerMap<any>>('@nexnox-web/core-portal/articles: Store Reducers');

@NgModule({
  imports: [
    StoreModule.forFeature('core-portal-feature-articles', REDUCER_TOKEN),
    EffectsModule.forFeature(articlesEffects)
  ],
  providers: [
    {
      provide: REDUCER_TOKEN,
      useValue: articlesReducers
    }
  ]
})
export class ArticlesStoreModule {
}
