import { PagedEntitiesXsStore, PagedEntitiesXsStoreState } from '@nexnox-web/core-store';
import { AppEntityType, TaskJobStateReasonSetDto } from '@nexnox-web/core-shared';
import { createSelector } from '@ngrx/store';
import { selectCancellationReasonsState } from '../../cancellation-reasons.selectors';
import { OrgaPortalFeatureCancellationReasonsService } from '../../services';

export interface CancellationReasonsListStoreState extends PagedEntitiesXsStoreState<TaskJobStateReasonSetDto> {
}

/* istanbul ignore next */
export const cancellationReasonsListStore = new PagedEntitiesXsStore<TaskJobStateReasonSetDto>({
  actionLabel: 'Orga Portal - Cancellation Reasons - Cancellation Reasons List',
  stateSelector: createSelector(selectCancellationReasonsState, state => state.cancellationReasonsList),
  serviceType: OrgaPortalFeatureCancellationReasonsService,
  entityType: AppEntityType.None,
  selectId: reasonSet => reasonSet.entity?.taskJobStateReasonSetId ?? reasonSet.model?.taskJobStateReasonSetId,
  stereotyped: false
});
