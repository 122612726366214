import { InjectionToken, NgModule } from '@angular/core';
import { stateMachinesEffects, stateMachinesReducers } from './store';
import { ActionReducerMap, StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

export const REDUCER_TOKEN = new InjectionToken<ActionReducerMap<any>>(
  '@nexnox-web/tech-portal/ticket-settings/state-machines: Store Reducers'
);

@NgModule({
  imports: [
    StoreModule.forFeature('tech-portal-feature-ticket-settings-state-machines', REDUCER_TOKEN),
    EffectsModule.forFeature(stateMachinesEffects)
  ],
  providers: [
    {
      provide: REDUCER_TOKEN,
      useValue: stateMachinesReducers
    }
  ]
})
export class StateMachinesStoreModule {
}
