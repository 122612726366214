import {
  ContextCloak,
  CoreSharedApiBaseService,
  FilterDto,
  FilterOperations,
  PageableRequest,
  Product,
  SortObject
} from '@nexnox-web/core-shared';
import {Injectable, Injector} from '@angular/core';
import {Observable} from "rxjs";

@Injectable()
export class InfoProgressContactReadUnreadService extends CoreSharedApiBaseService {

  public customQueryParams: { [key: string]: number[] | string[] } = {};

  constructor(
    protected injector: Injector
  ) {
    super(injector, Product.ORGA, 'contact', ['v2/infoprogress']);
  }

  public override getPage<T>(sortOptions: SortObject = null, pageNumber: number = 1, filters: FilterDto[] = [], operation: FilterOperations = FilterOperations.Include, customColumns: number[] = [], optionalColumns: string[] = [], pageSize: number = this.environment.defaultPageSize, parentIds: Array<string | number> = [], viewId?: number, useAllToken: boolean = false, contextCloak?: ContextCloak, queryParams?: {
    [p: string]: number[] | string[]
  }): Observable<PageableRequest<T>> {
    // Loads page with custom query params and a pageSize of 10
    return super.getPage(sortOptions, pageNumber, filters, operation, customColumns, optionalColumns, pageSize, parentIds, viewId, useAllToken, contextCloak, this.customQueryParams);
  }

}

