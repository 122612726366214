import { Component, ElementRef, forwardRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged, map, take } from 'rxjs/operators';
import dayjs from 'dayjs';
import { UnsubscribeHelper } from '../../helper';
import { isArray, isEqual } from 'lodash';
import { Calendar } from 'primeng/calendar';
import { TranslateService } from '@ngx-translate/core';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons/faPencilAlt';
import { CoreSharedModalService } from '../../services';
import { DatepickerModalComponent } from '../../modals';

@Component({
  selector: 'nexnox-web-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => DatePickerComponent)
    }
  ]
})
export class DatePickerComponent extends UnsubscribeHelper implements OnInit, OnDestroy, ControlValueAccessor {
  @Input() public noUtc: boolean;
  @Input() public numberOfMonths: number;
  @Input() public isRange: boolean;
  @Input() public performanceMode: boolean;
  @Input() public hasError: boolean;

  public get minDate(): string {
    return this.bsMinDate ? dayjs(this.bsMinDate).format('YYYY-MM-DDTHH:mm:ss') : null;
  }

  @Input()
  public set minDate(minDate: string) {
    if (minDate) {
      this.bsMinDate = dayjs(minDate).toDate();
    }
  }

  public get maxDate(): string {
    return this.bsMaxDate ? dayjs(this.bsMaxDate).format('YYYY-MM-DDTHH:mm:ss') : null;
  }

  @Input()
  public set maxDate(maxDate: string) {
    if (maxDate) {
      this.bsMaxDate = dayjs(maxDate).toDate();
    }
  }

  @ViewChild('calendarComponent') public calendarComponent: Calendar;

  public value$: Observable<string>;
  public valueAsDate$: Observable<Date>;
  public valueAsDateRange$: Observable<Date[]>;
  public disabled$: Observable<boolean>;

  public bsMinDate: Date = null;
  public bsMaxDate: Date = null;

  public bsConfig: Partial<BsDatepickerConfig>;

  public faPencilAlt = faPencilAlt;

  private valueSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  private disabledSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private onScrollListener;

  private onChange: any;
  private onTouched: any;

  constructor(
    private element: ElementRef,
    private translate: TranslateService,
    private modalService: CoreSharedModalService
  ) {
    super();

    this.onScrollListener = event => this.onScroll(event);
  }

  public ngOnInit(): void {
    this.value$ = this.valueSubject.asObservable().pipe(
      distinctUntilChanged((a, b) => isEqual(a, b))
    );
    this.valueAsDate$ = this.value$.pipe(
      map(value => {
        const date = value ? dayjs(value).local() : null;
        return date?.isValid() ? date.toDate() : null;
      })
    );
    this.valueAsDateRange$ = this.value$.pipe(
      map(value => {
        const splitValue = value?.split(' - ') ?? null;
        const firstValue = splitValue ? splitValue[0] : null;
        const secondValue = splitValue ? splitValue[1] : null;

        const firstDate = firstValue ? dayjs(firstValue).local() : null;
        const secondDate = secondValue ? dayjs(secondValue).local() : null;
        return firstDate?.isValid() && secondDate?.isValid() ? [firstDate.toDate(), secondDate.toDate()] : null;
      })
    );
    this.disabled$ = this.disabledSubject.asObservable();

    this.bsConfig = {
      adaptivePosition: true,
      containerClass: 'datepicker-theme',
      customTodayClass: 'datepicker-today'
    };

    this.subscribe(this.value$, value => {
      if (this.onChange && this.onTouched) {
        this.onChange(value);
        this.onTouched();
      }
    });

    window.addEventListener('scroll', this.onScrollListener, true);
  }

  public ngOnDestroy(): void {
    window.removeEventListener('scroll', this.onScrollListener, true);
  }

  public onSelect(): void {
    this.onTouched();
  }

  public onValueChange(value: dayjs.Dayjs | Date | Array<dayjs.Dayjs | Date>): void {
    if (this.isRange && isArray(value)) {
      const firstDate = value && value[0] ? dayjs(value[0]).startOf('day') : null;
      const secondDate = value && value[1] ? dayjs(value[1]).startOf('day') : null;

      const firstDateFormat = firstDate?.isValid() ?
        `${(!this.noUtc ? firstDate.utc() : firstDate).format('YYYY-MM-DDTHH:mm:ss')}${!this.noUtc ? 'Z' : ''}` : null;
      const secondDateFormat = secondDate?.isValid() ?
        `${(!this.noUtc ? secondDate.utc() : secondDate).format('YYYY-MM-DDTHH:mm:ss')}${!this.noUtc ? 'Z' : ''}` : null;
      this.valueSubject.next(firstDateFormat && secondDateFormat ? `${firstDateFormat} - ${secondDateFormat}` : null);
    } else if (!isArray(value)) {
      const date = value ? dayjs(value).startOf('day') : null;
      this.valueSubject.next(
        date?.isValid() ? `${(!this.noUtc ? date.utc() : date).format('YYYY-MM-DDTHH:mm:ss')}${!this.noUtc ? 'Z' : ''}` : null
      );
    }
  }

  public onOpenModal(): void {
    this.modalService.showModal(DatepickerModalComponent, async instance => {
      instance.value = await this.valueAsDate$.pipe(take(1)).toPromise();
      instance.minDate = this.bsMinDate;
      instance.maxDate = this.bsMaxDate;
    })
      .then(({ value }) => this.onValueChange(value))
      .catch(() => null);
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public onScroll(event: Event): void {
    if ((event.target as HTMLElement).contains(this.element.nativeElement)) {
      this.calendarComponent?.hideOverlay();
    }
  }

  public writeValue(value: string): void {
    this.valueSubject.next(value);
  }

  public setDisabledState(isDisabled: boolean): void {
    this.disabledSubject.next(isDisabled);
  }
}
