import { OpenEditorRequestOnContactListDto } from '@nexnox-web/core-shared';
import {
  BaseXsStore,
  BaseXsStoreReducerTypes, EmptyAction,
  EntityXsStore,
  EntityXsStoreActions,
  EntityXsStoreState,
  PagedEntitiesXsStore,
  PagedEntitiesXsStoreActions,
  PagedEntitiesXsStoreState,
  PropsAction
} from '@nexnox-web/core-store';
import { createAction, props } from '@ngrx/store';
import { cloneDeep } from 'lodash';
import { immerOn } from 'ngrx-immer/store';

export interface MissionMyRequestsXsStoreMissionIdContactIdPayload {
  missionId: number;
  contactId: number;
}

export interface MissionMyRequestsXsStoreActions extends PagedEntitiesXsStoreActions<OpenEditorRequestOnContactListDto> {
  acceptMission: PropsAction<MissionMyRequestsXsStoreMissionIdContactIdPayload>;
  acceptMissionSuccess: EmptyAction;

  declineMission: PropsAction<MissionMyRequestsXsStoreMissionIdContactIdPayload>;
  declineMissionSuccess: EmptyAction;
}

export class MissionMyRequestsXsStore extends PagedEntitiesXsStore<OpenEditorRequestOnContactListDto> {
  public actions: MissionMyRequestsXsStoreActions;

  protected createActions(label: string): MissionMyRequestsXsStoreActions {
    return {
      ...super.createActions(label),

      acceptMission: createAction(
        BaseXsStore.getType(label, 'Accept mission'),
        props<MissionMyRequestsXsStoreMissionIdContactIdPayload>()
      ),
      acceptMissionSuccess: createAction(
        BaseXsStore.getType(label, 'Accept mission success')
      ),

      declineMission: createAction(
        BaseXsStore.getType(label, 'Decline mission'),
        props<MissionMyRequestsXsStoreMissionIdContactIdPayload>()
      ),
      declineMissionSuccess: createAction(
        BaseXsStore.getType(label, 'Decline mission success')
      ),
    };
  }

  protected createReducerArray(
    initialState: PagedEntitiesXsStoreState<OpenEditorRequestOnContactListDto>
  ): BaseXsStoreReducerTypes<PagedEntitiesXsStoreState<OpenEditorRequestOnContactListDto>, MissionMyRequestsXsStoreActions>[] {
    return [
      ...super.createReducerArray(initialState),

      immerOn(this.actions.acceptMission, draft => {
        draft.loading = true;
      }),

      immerOn(this.actions.acceptMissionSuccess, (draft) => {
        draft.loading = false;
      }),

      immerOn(this.actions.declineMission, draft => {
        draft.loading = true;
      }),

      immerOn(this.actions.declineMissionSuccess, (draft) => {
        draft.loading = false;
      }),
    ];
  }
}
