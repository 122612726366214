import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { LocationDto } from '@nexnox-web/core-shared';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

@Component({
  selector: 'nexnox-web-core-shared-location-number',
  templateUrl: './location-number.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CoreSharedLocationNumberTemplateComponent {
  @Input()
  public set location(location: LocationDto) {
    this.locationSubject.next(location);
  }

  @Input() public label = true;
  @Input() public searchTerm: string;

  public title$: Observable<string>;
  public titleHtml$: Observable<string>;
  public titlePrefix$: Observable<string>;
  public titlePrefixHtml$: Observable<string>;

  private locationSubject: BehaviorSubject<LocationDto> = new BehaviorSubject<LocationDto>(null);

  constructor() {
    this.titlePrefix$ = this.locationSubject.asObservable().pipe(
      map(resource => CoreSharedLocationNumberTemplateComponent.prepareTitlePrefix(resource))
    );

    this.titlePrefixHtml$ = this.titlePrefix$.pipe(
      map(prefix => `<span class="text-muted">${prefix}</span>`)
    );

    this.title$ = this.locationSubject.asObservable().pipe(
      mergeMap(location => this.titlePrefix$.pipe(
        map(prefix => CoreSharedLocationNumberTemplateComponent.prepareTitle(location, prefix))
      ))
    );

    this.titleHtml$ = this.locationSubject.asObservable().pipe(
      mergeMap(location => this.titlePrefixHtml$.pipe(
        map(prefix => CoreSharedLocationNumberTemplateComponent.prepareTitle(location, prefix))
      ))
    );
  }

  /* istanbul ignore next */
  public static prepareTitlePrefix(location: LocationDto, fallback?: string): string {
    return location.locationNumber ? `${location.locationNumber}: ` : (fallback ?? '');
  }

  public static prepareTitle(location: LocationDto, prefix: string): string {
    return `${prefix}${location.name}`;
  }
}
