import {Brands} from "@nexnox-web/libs/core-shared/src";

export interface Environment {
  production: boolean;
  title: string;
  version: string;
  apiUrl: {
    identity: string;
    orga: string;
    tech: string;
    data: string;
  };
  google: {
    apiKey: string;
  };
  defaultPageSize: number;
  brand: Brands;
  defaultLanguage: string;
  defaultTimeout: number;
  additionalTranslations?: string[];
}

export enum Product {
  IDENTITY = 'identity',
  ORGA = 'orga',
  TECH = 'tech',
  DATA = 'data'
}
