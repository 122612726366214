import {ChangeDetectionStrategy, Component, Injector} from '@angular/core';
import {
  ActionButton,
  CorePortalEntityOverviewBaseComponent,
  DatatableActionButton,
  DeleteEntityModel
} from '@nexnox-web/core-portal';
import {AppEntityType, AppPermissions, HintCodeDto, Mappers} from '@nexnox-web/core-shared';
import {hintCodeListStore} from '../../store/stores';

@Component({
  selector: 'nexnox-web-connected-hint-codes-hint-code-list',
  templateUrl: './hint-code-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HintCodeListComponent extends CorePortalEntityOverviewBaseComponent<HintCodeDto> {
  public title = 'tech-portal.connected.hint-codes.subtitles.list';
  public createTitle = 'tech-portal.connected.hint-codes.actions.create';
  public idProperty = 'hintCodeId';
  public displayProperty = 'displayName';
  public datatableConfigName = 'HintCodeDefault';
  public componentId = 'HintCodeListComponent';

  constructor(
    protected injector: Injector
  ) {
    super(injector, hintCodeListStore, Mappers.HintCodeListDto.serializedName, AppEntityType.None);
  }

  /* istanbul ignore next */
  public getRowActionButtons(): DatatableActionButton[] {
    return this.getDefaultRowActionButtons(
      'tech-portal.connected.hint-codes.actions.edit',
      (row: HintCodeDto) => `connected/hint-codes/${row.hintCodeId}`,
      [AppPermissions.UpdateHintCode],
      {
        module: 'settings'
      }
    );
  }

  public getDeleteEntityModel(): DeleteEntityModel {
    return {
      titleKey: 'tech-portal.connected.hint-codes.actions.delete',
      descriptionKey: 'tech-portal.connected.hint-codes.descriptions.delete',
      deletePermission: AppPermissions.DeleteHintCode
    };
  }

  protected async getActionButtons(): Promise<ActionButton[]> {
    return this.getDefaultActionButtons(
      'tech-portal.connected.hint-codes.actions.create',
      AppPermissions.CreateHintCode
    );
  }
}
