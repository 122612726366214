import {RouterModule, Routes} from '@angular/router';
import {NgModule} from '@angular/core';
import {CorePortalPermissionGuard} from '@nexnox-web/core-portal';
import {TenantDetailComponent, TenantListComponent} from './containers';
import {AppPermissions, I18nBreadcrumbResolver, UnsavedChangesGuard, UnsavedCreateGuard} from '@nexnox-web/core-shared';

export const CORE_PORTAL_SERVICE_TENANTS_ROUTES: Routes = [
  {
    path: '',
    canActivate: [CorePortalPermissionGuard],
    canDeactivate: [UnsavedCreateGuard],
    component: TenantListComponent,
    data: {
      permissions: [AppPermissions.UseExpiredSubscription]
    }
  },
  {
    path: ':tenantId',
    component: TenantDetailComponent,
    canActivate: [CorePortalPermissionGuard],
    canDeactivate: [UnsavedChangesGuard],
    data: {
      permissions: [AppPermissions.UseExpiredSubscription],
      breadcrumbs: I18nBreadcrumbResolver,
      tab: 'tenant',
      text: 'core-portal.service.tenants.detail'
    },
    children: [
      {
        path: 'subscriptions',
        data: {
          tab: 'subscriptions'
        },
        children: []
      },
      {
        path: 'systemcontacts',
        data: {
          tab: 'systemcontacts'
        },
        children: []
      },
      {
        path: 'tenant-usage',
        data: {
          tab: 'tenant-usage'
        },
        children: []
      }
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(CORE_PORTAL_SERVICE_TENANTS_ROUTES)
  ],
  exports: [
    RouterModule
  ]
})
export class TenantsRoutingModule {
}
