import { Injectable } from '@angular/core';

@Injectable()
export class CoreSharedLocalStorageService {
  public set(key: string, storageObject: any): CoreSharedLocalStorageService {
    localStorage.setItem(key, JSON.stringify(storageObject));
    return this;
  }

  public get(key: string): any | null {
    const storageObject = localStorage.getItem(key);
    return storageObject ? JSON.parse(storageObject) : null;
  }

  public remove(key: string): CoreSharedLocalStorageService {
    localStorage.removeItem(key);
    return this;
  }

  public clear(): CoreSharedLocalStorageService {
    localStorage.clear();
    return this;
  }
}
