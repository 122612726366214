import { Injectable } from '@angular/core';

@Injectable()
export class CoreSharedTextConverterService {

  public convertHTMLtoText(html: string, reduceWhiteSpace?: boolean): string {
    const converter: HTMLElement = document.createElement('div');
    converter.innerHTML = html;
    return reduceWhiteSpace ? this.reduceWhiteSpace(converter.innerText) : converter.innerText;
  }

  public convertHTMLtoEscapedText(html: string, reduceWhiteSpace?: boolean): string {
    const converted = this.convertHTMLtoText(html, reduceWhiteSpace);    
    const text = document.createTextNode(converted);
    const converter = document.createElement('p');
    converter.appendChild(text);
    return converter.innerHTML;
  }

  public reduceWhiteSpace(text: string): string {
    while (text.match(/\s{2}/g)) {
      text = text.replace(/\s{2}/g, ' ');
    }
    return text.trim();
  }
}
