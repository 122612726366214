import {TranslateService} from '@ngx-translate/core';
import {TechPortalTextTemplateContextConfig} from '@nexnox-web/tech-portal/features/templates';
import {
  MissionByInspectionDto,
  MissionByTaskDto,
  MissionByTicketDto,
  MissionDescriptionContextDto,
  MissionDto,
  TemplateContextType
} from '@nexnox-web/core-shared';

export function provideTextTemplateContextConfig(translateService: TranslateService): TechPortalTextTemplateContextConfig {
  return {
    /* Missions */
    [TemplateContextType.MissionDescription]: {
      serializedName: 'MissionDescriptionTtm',
      prefix: 'mission',
      sanitize: (mission: MissionDto): MissionDescriptionContextDto => {
        if (!mission) return {};

        return {
          missionId: mission.missionId,
          state: mission.state,
          actualEnd: mission.actualEnd,
          actualStart: mission.actualStart,
          plannedEnd: mission.plannedEnd,
          plannedStart: mission.plannedStart,
          stereotypeId: mission.stereotypeId,
          tenantId: mission.tenantId,
          resourceId: mission.resource?.resourceId,
          solutionContactId: mission.solutionContact?.contactId,
          shippingAddressId: mission.shippingAddress?.addressId,
          invoiceAddressId: mission.invoiceAddress?.addressId,
          reportId: mission.reportId,
          title: mission.title
        };
      }
    },
    [TemplateContextType.MissionByTicketDescription]: {
      serializedName: 'MissionByTicketDescriptionTtm',
      prefix: 'mission',
      sanitize: (mission: MissionByTicketDto): MissionDescriptionContextDto => {
        if (!mission) return {};

        return {
          missionId: mission.missionId,
          state: mission.state,
          actualEnd: mission.actualEnd,
          actualStart: mission.actualStart,
          plannedEnd: mission.plannedEnd,
          plannedStart: mission.plannedStart,
          stereotypeId: mission.stereotypeId,
          tenantId: mission.tenantId,
          ticketId: mission.ticket?.ticketId,
          resourceId: mission.resource?.resourceId,
          solutionContactId: mission.solutionContact?.contactId,
          shippingAddressId: mission.shippingAddress?.addressId,
          invoiceAddressId: mission.invoiceAddress?.addressId,
          reportId: mission.reportId,
          title: mission.title
        };
      },
      parents: [TemplateContextType.MissionDescription]
    },
    [TemplateContextType.MissionByTaskDescription]: {
      serializedName: 'MissionByTaskDescriptionTtm',
      prefix: 'mission',
      sanitize: (mission: MissionByTaskDto): MissionDescriptionContextDto => {
        if (!mission) return {};

        return {
          missionId: mission.missionId,
          state: mission.state,
          actualEnd: mission.actualEnd,
          actualStart: mission.actualStart,
          plannedEnd: mission.plannedEnd,
          plannedStart: mission.plannedStart,
          stereotypeId: mission.stereotypeId,
          tenantId: mission.tenantId,
          taskId: mission.task?.resourceTaskId,
          resourceId: mission.resource?.resourceId,
          solutionContactId: mission.solutionContact?.contactId,
          shippingAddressId: mission.shippingAddress?.addressId,
          invoiceAddressId: mission.invoiceAddress?.addressId,
          reportId: mission.reportId,
          title: mission.title
        };
      },
      parents: [TemplateContextType.MissionDescription]
    },
    [TemplateContextType.MissionByInspectionDescription]: {
      serializedName: 'MissionByInspectionDescriptionTtm',
      prefix: 'mission',
      sanitize: (mission: MissionByInspectionDto): MissionDescriptionContextDto => {
        if (!mission) return {};

        return {
          missionId: mission.missionId,
          state: mission.state,
          actualEnd: mission.actualEnd,
          actualStart: mission.actualStart,
          plannedEnd: mission.plannedEnd,
          plannedStart: mission.plannedStart,
          stereotypeId: mission.stereotypeId,
          tenantId: mission.tenantId,
          resourceId: mission.resource?.resourceId,
          solutionContactId: mission.solutionContact?.contactId,
          shippingAddressId: mission.shippingAddress?.addressId,
          invoiceAddressId: mission.invoiceAddress?.addressId,
          reportId: mission.reportId,
          title: mission.title,
          inspectionId: mission.inspectionId,
          controlPointId: mission.controlPoint?.controlPointId
        }
      },
      parents: [TemplateContextType.MissionDescription]
    },

    /* Notes */
    [TemplateContextType.NoteBase]: {
      serializedName: 'TextTemplateTtm',
      prefix: 'note',
      sanitize: undefined
    },
    [TemplateContextType.NoteForTicket]: {
      serializedName: 'NoteForTicketTtm',
      prefix: 'ticket',
      sanitize: undefined,
      parents: [TemplateContextType.NoteBase]
    },
    [TemplateContextType.NoteForMission]: {
      serializedName: 'MissionDescriptionTtm',
      prefix: 'mission',
      sanitize: undefined,
      parents: [TemplateContextType.NoteBase]
    },
    [TemplateContextType.NoteForMissionByTicket]: {
      serializedName: 'NoteForMissionByTicketTtm',
      prefix: 'mission',
      sanitize: undefined,
      parents: [TemplateContextType.NoteBase, TemplateContextType.NoteForMission]
    },
    [TemplateContextType.NoteForMissionByTask]: {
      serializedName: 'NoteForMissionByTaskTtm',
      prefix: 'mission',
      sanitize: undefined,
      parents: [TemplateContextType.NoteBase, TemplateContextType.NoteForMission]
    },
    [TemplateContextType.NoteForMissionByInspection]: {
      serializedName: 'NoteForMissionByInspectionTtm',
      prefix: 'mission',
      sanitize: undefined,
      parents: [TemplateContextType.NoteBase, TemplateContextType.NoteForMission]
    },
    [TemplateContextType.InfoNotificationContent]: {
      serializedName: 'InfoNotificationRuleTtm',
      prefix: 'info',
      sanitize: undefined
    },
    [TemplateContextType.InfoNotificationSubject]: {
      serializedName: 'InfoNotificationRuleTtm',
      prefix: 'info',
      sanitize: undefined
    },
    [TemplateContextType.MissionNotificationContent]: {
      serializedName: 'MissionNotificationRuleTtm',
      prefix: 'mission',
      sanitize: undefined
    },
    [TemplateContextType.MissionNotificationSubject]: {
      serializedName: 'MissionNotificationRuleTtm',
      prefix: 'mission',
      sanitize: undefined
    },
    [TemplateContextType.TicketNotificationContent]: {
      serializedName: 'TicketNotificationRuleTtm',
      prefix: 'ticket',
      sanitize: undefined
    },
    [TemplateContextType.TicketNotificationSubject]: {
      serializedName: 'TicketNotificationRuleTtm',
      prefix: 'ticket',
      sanitize: undefined
    },
    [TemplateContextType.IssueEmailSubject]: {
      serializedName: 'IssueTtm',
      prefix: 'issue',
      sanitize: undefined
    },
    [TemplateContextType.IssueEmailContent]: {
      serializedName: 'IssueTtm',
      prefix: 'issue',
      sanitize: undefined
    },
    [TemplateContextType.MessageIssueEmailSubject]: {
      serializedName: 'UserIssueEmailTtm',
      prefix: 'issue',
      sanitize: undefined
    },
    [TemplateContextType.MessageIssueEmailContent]: {
      serializedName: 'UserIssueEmailTtm',
      prefix: 'issue',
      sanitize: undefined
    },
    [TemplateContextType.MissionIssueEmailSubject]: {
      serializedName: 'UserIssueEmailTtm',
      prefix: 'issue',
      sanitize: undefined
    },
    [TemplateContextType.MissionIssueEmailContent]: {
      serializedName: 'UserIssueEmailTtm',
      prefix: 'issue',
      sanitize: undefined
    },
    [TemplateContextType.TicketIssueEmailSubject]: {
      serializedName: 'UserIssueEmailTtm',
      prefix: 'issue',
      sanitize: undefined
    },
    [TemplateContextType.TicketIssueEmailContent]: {
      serializedName: 'UserIssueEmailTtm',
      prefix: 'issue',
      sanitize: undefined
    },
    [TemplateContextType.WinterhalterIssueEmailSubject]: {
      serializedName: 'WinterhalterIssueEmailTtm',
      prefix: 'issue',
      sanitize: undefined
    },
    [TemplateContextType.WinterhalterIssueEmailContent]: {
      serializedName: 'WinterhalterIssueEmailTtm',
      prefix: 'issue',
      sanitize: undefined
    }
  };
}
