import {RouterModule, Routes} from '@angular/router';
import {
  CorePortalAuthGuard,
  CorePortalDynamicDefaultRouteGuard,
  CorePortalSyncCanActivateGuard,
  CorePortalTenantGuard,
  DynamicDefaultRoute
} from '../guards';
import {CorePortalLayoutComponent} from '../modules';
import {I18nBreadcrumbResolver} from '@nexnox-web/core-shared';
import {ModuleWithProviders} from '@angular/core';
import {CorePortalNoPermissionComponent} from '../components';

export function corePortalGetAppRoutingDefaults(
  tenantRoutes: Routes = [],
  defaultTenantRoutes: DynamicDefaultRoute[],
  otherRoutes: Routes = []
): Routes {
  return [
    { path: '', redirectTo: 'tenant', pathMatch: 'full' },
    {
      path: 'tenant',
      canActivate: [CorePortalSyncCanActivateGuard],
      component: CorePortalLayoutComponent,
      data: {
        guards: [CorePortalAuthGuard, CorePortalTenantGuard]
      }
    },
    {
      path: 'tenant/:tenantDomain',
      component: CorePortalLayoutComponent,
      canActivate: [CorePortalSyncCanActivateGuard],
      canActivateChild: [CorePortalTenantGuard],
      data: {
        guards: [CorePortalAuthGuard, CorePortalTenantGuard]
      },
      children: [
        ...tenantRoutes,
        {
          path: 'error',
          loadChildren: () => import('@nexnox-web/core-portal/features/error').then(m => m.CorePortalFeatureErrorModule),
          data: {
            breadcrumbs: I18nBreadcrumbResolver,
            text: 'core-portal.core.header.breadcrumbs.error'
          }
        },

        { path: 'no-permission', component: CorePortalNoPermissionComponent },
        {
          path: '**',
          canActivate: [CorePortalDynamicDefaultRouteGuard],
          children: [],
          data: {
            defaultRoutes: [
              ...defaultTenantRoutes,
              { path: 'no-permission', permissions: [] }
            ]
          }
        }
      ]
    },
    {
      path: 'user',
      loadChildren: () => import('@nexnox-web/core-portal/features/user').then(mod => mod.CorePortalFeatureUserModule)
    },
    ...otherRoutes,
    { path: '**', redirectTo: '/tenant' }
  ];
}

export function corePortalGetAppRoutingRouterModule(appRoutes: Routes): ModuleWithProviders<RouterModule> {
  return RouterModule.forRoot(appRoutes, {
    enableTracing: false,
    scrollPositionRestoration: 'top'
  });
}
