<p-sidebar  #sidebarComponent
           position="right"
           appendTo="body"
           styleClass="p-sidebar-md"
           [transitionOptions]="transitionOptions"
           [visible]="visible$ | async"
           (visibleChange)="onVisibleChange($event)">
  <div class="d-flex align-content-between flex-column h-100" *ngIf="(loading$ | async) === false else loadingTemplate">
    <div class="w-100 flex-grow-1">
      <div class="row">
        <div class="col-12 mb-2">
          <h4>{{'core-portal.master-data.subtitles.function-create' | translate}}</h4>
        </div>
      </div>
      <div class="row w-100">
        <form [formGroup]="form" class="w-100">
          <formly-form class="row w-100"
                       [form]="form"
                       [(model)]="model"
                       [fields]="fields">
          </formly-form>
        </form>

      </div>
    </div>

    <div class="w-100 flex-shrink-1 d-flex justify-content-end">
      <button pButton
              type="button"
              class="p-button p-button-secondary mr-3"
              [label]="'core-portal.core.general.cancel' | translate"
              [icon]="faTimes | nexnoxWebFaIconStringPipe"
              (click)="onHide()">
      </button>

      <button pButton
              type="button"
              class="p-button p-button-primary"
              [label]="'core-portal.master-data.subtitles.function-create'  | translate"
              [icon]="faUser | nexnoxWebFaIconStringPipe"
              [disabled]="!form.valid || (canAssign$ | async) === false"
              (click)="onCreate()">
      </button>
    </div>
  </div>
</p-sidebar>

<ng-template #loadingTemplate>
  <nexnox-web-loading-icon class="d-flex w-100 h-100 align-items-center justify-content-center flex-column p-5"
                           size="5x">
  </nexnox-web-loading-icon>
</ng-template>
