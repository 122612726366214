import {PagedEntitiesXsStore, PagedEntitiesXsStoreState} from '@nexnox-web/core-store';
import {AppEntityType, TicketDto} from '@nexnox-web/core-shared';
import {Action, createSelector} from '@ngrx/store';
import {Injectable, Injector} from '@angular/core';
import {TechPortalTicketService} from "@nexnox-web/tech-portal-lib";
import {selectNotificationRulesState} from "../../notification-rules.selector";

export interface NotificationRulesTicketsStoreState extends PagedEntitiesXsStoreState<TicketDto> {
}

/* istanbul ignore next */
export const notificationRulesTicketsStore = new PagedEntitiesXsStore<TicketDto>({
  actionLabel: 'Tech Portal - Notification Rules - Notification Rule Tickets',
  stateSelector: createSelector(selectNotificationRulesState, state => state.notificationRulesTickets),
  entityType: AppEntityType.Ticket,
  serviceType: TechPortalTicketService,
  selectId: ticket => ticket.entity?.ticketId ?? ticket.model?.ticketId
});

export function notificationRulesTicketsStoreReducer(state: NotificationRulesTicketsStoreState, action: Action): any {
  return notificationRulesTicketsStore.reducer(state, action);
}

@Injectable()
export class NotificationRulesTicketsStoreEffects extends notificationRulesTicketsStore.effects {
  constructor(
    protected injector: Injector
  ) {
    super(injector);
  }
}
