import {ChangeDetectionStrategy, Component, Injector} from '@angular/core';
import {
  CorePortalEntityEditBaseComponent,
  CorePortalFormlyReadonlyTypes,
  CorePortalFormlyReadonlyTyping,
  CorePortalFormlyTranslatedTyping
} from '@nexnox-web/core-portal';
import {ChatByFormDefinitionSimpleDto, GeneralDataProtectionEntityType, TaskSimpleDto} from '@nexnox-web/core-shared';
import {FormlyFieldConfig} from '@ngx-formly/core';
import {gdprEntityTypeEnumOptions, LocalGDPRRegulationDto} from "./../../models";
import {
  OrgaPortalFeatureChatDefinitionService
} from "@nexnox-web/orga-portal/features/chat-definitions/src/lib/store/services/chat-definition/chat-definition.service";
import {
  OrgaPortalFeatureTaskService
} from "@nexnox-web/orga-portal/features/tasks/src/lib/store/services/task/task.service";

@Component({
  selector: 'nexnox-web-settings-gdpr-edit',
  templateUrl: './gdpr-edit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CorePortalFeatureGdprEditComponent extends CorePortalEntityEditBaseComponent<LocalGDPRRegulationDto> {
  constructor(
    protected injector: Injector,
    private chatDefinitionService: OrgaPortalFeatureChatDefinitionService,
    private taskService: OrgaPortalFeatureTaskService
  ) {
    super(injector);
  }

  /* istanbul ignore next */
  protected createForm(): FormlyFieldConfig[] {
    return [
      {
        key: 'generalDataProtectionRegulationId'
      },
      {
        key: 'restrictions'
      },
      {
        key: 'title',
        type: 'input',
        wrappers: ['core-portal-translated'],
        className: 'col-md-12',
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.title',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          },
          type: 'text'
        },
        expressionProperties: {
          'templateOptions.required': () => !this.readonly,
          'templateOptions.disabled': () => this.readonly
        },
        hideExpression: () => !this.creating
      },
      {
        key: 'entity',
        type: 'core-portal-ng-select',
        wrappers: ['core-portal-translated'],
        className: 'col-md-3',
        defaultValue: gdprEntityTypeEnumOptions[0].value,
        templateOptions: {
          corePortalTranslated: {
            label: 'core-portal.settings.gdpr.fields.entity',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.ENUM,
            enumOptions: gdprEntityTypeEnumOptions,
            translate: true
          } as CorePortalFormlyReadonlyTyping,
          corePortalNgSelect: {
            items: gdprEntityTypeEnumOptions,
            translate: true,
            noClear: true,
            noSearch: true
          },
        },
        expressionProperties: {
          'templateOptions.required': () => !this.readonly,
          'templateOptions.disabled': () => !this.creating || this.readonly,
        },
      },
      {
        key: 'lifespan',
        type: 'core-portal-timepicker',
        wrappers: ['core-portal-translated'],
        className: 'col-md-3',
        templateOptions: {
          corePortalTranslated: {
            label: 'core-portal.settings.gdpr.fields.lifespan'
          },
          corePortalTimepicker: {
            mode: 'timespan',
            showYears: true,
            showWeeks: true,
            showDays: true,
            showHours: false,
            showMinutes: false
          }
        },
        expressionProperties: {
          'templateOptions.required': () => !this.readonly,
          'templateOptions.disabled': () => this.readonly
        }
      },
      {
        key: 'tasks',
        type: 'core-portal-entity-select',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-12',
        defaultValue: null,
        templateOptions: {
          corePortalTranslated: {
            label: 'core-portal.settings.gdpr.fields.tasks',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          } as CorePortalFormlyTranslatedTyping,
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.ENTITY,
            displayKey: 'title',
            link: (row: TaskSimpleDto) => row?.taskId ? ['/tasks', row.taskId] : null,
            module: 'communication'
          } as CorePortalFormlyReadonlyTyping,
          entityService: this.taskService,
          idKey: 'taskId',
          displayKey: 'title',
          firstToDefault: false,
          wholeObject: true,
          skipGetOne: true,
          clearable: false,
          showAll: true,
          multiple: true,
          link: (row: TaskSimpleDto) => row?.taskId ? ['/tasks', row.taskId] : null,
          module: 'communication'
        },
        expressionProperties: {
          'templateOptions.required': () => !this.readonly,
          'templateOptions.disabled': () => this.readonly
        },
        hideExpression: () => this.model?.entity !== GeneralDataProtectionEntityType.TaskJob
      },
      {
        key: 'definitions',
        type: 'core-portal-entity-select',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-12',
        defaultValue: null,
        templateOptions: {
          corePortalTranslated: {
            label: 'core-portal.settings.gdpr.fields.definitions',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          } as CorePortalFormlyTranslatedTyping,
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.ENTITY,
            displayKey: 'title',
            link: (row: ChatByFormDefinitionSimpleDto) => row?.chatByFormDefinitionId ? ['chat-definitions', row.chatByFormDefinitionId] : null,
            module: 'settings'
          } as CorePortalFormlyReadonlyTyping,
          entityService: this.chatDefinitionService,
          idKey: 'chatByFormDefinitionId',
          displayKey: 'title',
          firstToDefault: false,
          wholeObject: true,
          skipGetOne: true,
          clearable: false,
          showAll: true,
          multiple: true,
          link: (row: ChatByFormDefinitionSimpleDto) => row?.chatByFormDefinitionId ? ['chat-definitions', row.chatByFormDefinitionId] : null,
          module: 'settings'
        },
        expressionProperties: {
          'templateOptions.required': () => !this.readonly,
          'templateOptions.disabled': () => this.readonly
        },
        hideExpression: () => this.model?.entity !== GeneralDataProtectionEntityType.Chat
      }
    ];
  }
}
