import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { TreeNode } from 'primeng/api';
import { select, Store } from '@ngrx/store';
import { authStore } from '@nexnox-web/core-portal';
import { distinctUntilChanged, map, take } from 'rxjs/operators';
import { OrgaPortalDocumentService } from '@nexnox-web/orga-portal-lib';
import {
  ApiNotificationService,
  NexnoxWebFaIconString,
  OrgaFolderItemDto,
  OrgaFolderItemType,
  PageableRequest,
  Paging
} from '@nexnox-web/core-shared';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { faSpinner } from '@fortawesome/free-solid-svg-icons/faSpinner';
import { TreeSelect } from 'primeng/treeselect';
import { TranslateService } from '@ngx-translate/core';
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';

@Component({
  selector: 'nexnox-web-formly-folder-select',
  templateUrl: './folder-select.component.html',
  styleUrls: ['./folder-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormlyFolderSelectComponent extends FieldType implements OnInit, OnDestroy {
  @ViewChild('treeSelectComponent', { static: true }) public treeSelectComponent: TreeSelect;

  public value$: Observable<TreeNode>;
  public treeSelectOptions$: Observable<TreeNode[]>;

  private valueSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  private treeSelectOptionsSubject: BehaviorSubject<TreeNode[]> = new BehaviorSubject<TreeNode[]>([]);
  private tenantId: number;

  private fieldChangeSubscription: Subscription;

  constructor(
    private store: Store<any>,
    private documentService: OrgaPortalDocumentService,
    private apiNotificationService: ApiNotificationService,
    private translate: TranslateService
  ) {
    super();

    this.treeSelectOptionsSubject.next(this.getEmptyOptions());
  }

  /* istanbul ignore next */
  public async ngOnInit(): Promise<void> {
    this.value$ = this.valueSubject.asObservable().pipe(
      map(path => path ? ({ label: path, data: path, leaf: false, children: [] }) : null)
    );
    this.treeSelectOptions$ = this.treeSelectOptionsSubject.asObservable();
    this.tenantId = await this.store.pipe(
      select(authStore.selectors.selectTenantId),
      take(1)
    ).toPromise();

    this.fieldChangeSubscription = this.field.options.fieldChanges.pipe(
      distinctUntilChanged()
    ).subscribe(() => this.valueSubject.next(this.formControl.value));
  }

  public ngOnDestroy(): void {
    if (this.fieldChangeSubscription && !this.fieldChangeSubscription.closed) {
      this.fieldChangeSubscription.unsubscribe();
    }
  }

  public onShow(): void {
    this.documentService.getDocumentPage('', this.tenantId).pipe(take(1))
      .toPromise()
      .then(({ items, paging }: PageableRequest<OrgaFolderItemDto>) => {
        const options = this.mapDocumentsToOptions(items, paging);
        this.treeSelectOptionsSubject.next(options?.length ? options : this.getEmptyOptions());
      })
      .catch(error => this.apiNotificationService.handleApiError(error));
  }

  public onValueChange(value: TreeNode): void {
    this.formControl.markAsTouched();
    this.formControl.markAsDirty();
    this.formControl.setValue(value.data);
  }

  public onExpand(event: any): void {
    const node: TreeNode = event.node;
    const path = node.type === 'more' ? (node.parent ? node.parent.data : '') : node.data;
    const pageNumber = node.type === 'more' ? node.data.paging.pageNumber + 1 : 1;

    node.icon = NexnoxWebFaIconString.transformIcon(faSpinner, 'fa-spin');
    node.selectable = false;
    node.styleClass = 'disabled';

    this.documentService.getDocumentPage(path, this.tenantId, pageNumber).pipe(take(1))
      .toPromise()
      .then(({ items, paging }: PageableRequest<OrgaFolderItemDto>) => {
        if (node.type === 'more') {
          if (node.parent) {
            node.parent.children = [
              ...node.parent.children.filter(x => x.type !== 'more'),
              ...this.mapDocumentsToOptions(items, paging)
            ];
          } else {
            this.treeSelectOptionsSubject.next([
              ...this.treeSelectOptionsSubject.getValue().filter(x => x.type !== 'more'),
              ...this.mapDocumentsToOptions(items, paging)
            ]);
          }
        } else {
          node.icon = undefined;
          node.children = this.mapDocumentsToOptions(items, paging);
          node.selectable = true;
          node.styleClass = undefined;
          node.leaf = !node.children.length;
        }

        this.treeSelectComponent.cd.detectChanges();
      })
      .catch(error => this.apiNotificationService.handleApiError(error));
  }

  private mapDocumentsToOptions(documents: OrgaFolderItemDto[], paging: Paging): TreeNode[] {
    return [
      ...documents.filter(document => document.type === OrgaFolderItemType.Folder).map(document => ({
        label: document.name,
        data: document.path,
        leaf: false,
        children: []
      })),
      ...(paging.pageNumber < paging.totalPages ? [{
        label: this.translate.instant('core-shared.shared.pagination.more'),
        icon: NexnoxWebFaIconString.transformIcon(faPlus),
        collapsedIcon: NexnoxWebFaIconString.transformIcon(faPlus),
        type: 'more',
        selectable: false,
        leaf: false,
        data: { paging }
      }] as TreeNode[] : [])
    ];
  }

  private getEmptyOptions(): TreeNode[] {
    return [{
      label: this.translate.instant('core-shared.shared.table.empty'),
      selectable: false
    }];
  }
}
