import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import {IconDefinition} from '@fortawesome/fontawesome-common-types';
import {faCheck} from '@fortawesome/free-solid-svg-icons/faCheck';
import {faChevronDown} from '@fortawesome/free-solid-svg-icons/faChevronDown';
import {faChevronUp} from '@fortawesome/free-solid-svg-icons/faChevronUp';
import {faComment} from '@fortawesome/free-solid-svg-icons/faComment';
import {faEnvelope} from '@fortawesome/free-solid-svg-icons/faEnvelope';
import {faExchangeAlt} from '@fortawesome/free-solid-svg-icons/faExchangeAlt';
import {faExclamationTriangle} from '@fortawesome/free-solid-svg-icons/faExclamationTriangle';
import {faFile} from '@fortawesome/free-solid-svg-icons/faFile';
import {faFileImage} from '@fortawesome/free-solid-svg-icons/faFileImage';
import {faFilePdf} from '@fortawesome/free-solid-svg-icons/faFilePdf';
import {faFlagCheckered} from '@fortawesome/free-solid-svg-icons/faFlagCheckered';
import {faPaperclip} from '@fortawesome/free-solid-svg-icons/faPaperclip';
import {faPhone} from '@fortawesome/free-solid-svg-icons/faPhone';
import {faQuestion} from '@fortawesome/free-solid-svg-icons/faQuestion';
import {faReply} from '@fortawesome/free-solid-svg-icons/faReply';
import {faTimes} from '@fortawesome/free-solid-svg-icons/faTimes';
import {faFolder} from '@fortawesome/free-solid-svg-icons/faFolder';
import {CorePortalCardboxAction} from '@nexnox-web/core-portal';
import {
  FileDto,
  NoteCallerDto,
  NoteCompleteDto,
  NoteDto,
  NoteMailDto,
  NoteType,
  SolutionItemDto,
  SolutionItemListDto,
  SolutionMemberType,
  SystemDirections
} from '@nexnox-web/core-shared';
import {PagedEntitiesXsStoreEntityData} from '@nexnox-web/core-store';
import {TranslateService} from '@ngx-translate/core';
import {isNull, isUndefined} from 'lodash';
import {BehaviorSubject, NEVER, Observable, of} from 'rxjs';
import {filter, map, mergeMap, switchMap} from 'rxjs/operators';
import {LocalSolutionItemListDto} from '../../models';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';

@Component({
  selector: 'nexnox-web-solutions-solution-item',
  templateUrl: './solution-item.component.html',
  styleUrls: ['./solution-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TechPortalFeatureSolutionItemComponent implements OnInit {

  public get item(): SolutionItemListDto {
    return this.itemSubject.getValue();
  }

  @Input()
  public set item(item: SolutionItemListDto) {
    this.itemSubject.next(item);
  }

  public get isCollapsed(): boolean {
    return this.isCollapsedSubject.getValue();
  }

  public set isCollapsed(value: boolean) {
    this.isCollapsedSubject.next(value);
  }

  public item$: Observable<SolutionItemListDto>;

  public memberReference$: Observable<{ name: string, link: any[], module: string, type: SolutionMemberType }>;

  @Input() public readonly: boolean;
  @Input() public loading = false;
  @Input() public loading$: Observable<boolean>;
  @Input() public loaded$: Observable<boolean>;
  @Input() public entityData: PagedEntitiesXsStoreEntityData;
  @Input() public showFilters$: Observable<{ stateChanges: boolean, chats: boolean, mails: boolean }> = NEVER;
  @Input() public isLastItem: boolean;

  @Output() public markAsSolution: EventEmitter<SolutionItemListDto> = new EventEmitter<SolutionItemListDto>();
  @Output() public unmarkAsSolution: EventEmitter<SolutionItemListDto> = new EventEmitter<SolutionItemListDto>();
  @Output() public replyToMail: EventEmitter<NoteMailDto> = new EventEmitter<NoteMailDto>();
  @Output() public replyToChatMessage: EventEmitter<NoteDto> = new EventEmitter<NoteDto>();

  public headerActions: CorePortalCardboxAction[];

  public createdAt$: Observable<string>;
  public noteType$: Observable<NoteType>;
  public titleInfo$: Observable<string>;
  public mailFrom$: Observable<string>;
  public mailTo$: Observable<string>;
  public mailCc$: Observable<string>;
  public mailSubject$: Observable<string>;
  public phoneNumber$: Observable<string>;
  public description$: Observable<SafeHtml>;

  public isVisible$: Observable<boolean>;
  public isCollapsed$: Observable<boolean>;
  public isExpanded$: Observable<boolean>;

  public isCollapsableItem$: Observable<boolean>;
  public isMessage$: Observable<boolean>;
  public isChangedStatusWithReason$: Observable<boolean>;
  public isShowReplyAction$: Observable<boolean>;

  public faPaperClip = faPaperclip;
  public faReply = faReply;
  public faCheck = faCheck;
  public faTimes = faTimes;

  private itemSubject: BehaviorSubject<SolutionItemListDto> = new BehaviorSubject<SolutionItemListDto>(null);
  private isCollapsedSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  constructor(
    private translateService: TranslateService,
    private sanitiser: DomSanitizer
  ) {
  }

  public ngOnInit(): void {

    this.isCollapsed$ = this.isCollapsedSubject.asObservable();

    this.isExpanded$ = this.isCollapsed$.pipe(map((collapsed) => !collapsed));

    this.item$ = this.itemSubject.asObservable().pipe(
      map(item => ({
        ...(item ?? {}),
        note: {
          ...(item?.note ?? {}),
          attachments: (item?.note?.attachments ?? []).map(attachment => ({
            ...attachment,
            isImage: this.isImageFile(attachment.file),
            fileIcon: this.getFileIcon(attachment.file)
          }))
        }
      } as LocalSolutionItemListDto))
    );

    this.isMessage$ = this.item$.pipe(
      filter(item => Boolean(item)),
      map(item => this.isMessage(item))
    );

    this.isChangedStatusWithReason$ = this.item$.pipe(
      filter(item => Boolean(item)),
      map(item => this.isChangedStatusWithReason(item))
    );

    this.isCollapsableItem$ = this.item$.pipe(
      filter(item => Boolean(item)),
      map(item => this.isMessage(item) || this.isChangedStatusWithReason(item))
    );

    this.isShowReplyAction$ = this.item$.pipe(
      filter(item => Boolean(item)),
      map(item => this.isShowReplyAction(item))
    );

    this.noteType$ = this.item$.pipe(
      filter(item => Boolean(item)),
      map(item => item.note?.type)
    );

    this.description$ = this.item$.pipe(
      filter(item => Boolean(item)),
      map(item => this.sanitiser.bypassSecurityTrustHtml(item.note?.description))
    );

    /* istanbul ignore next */
    this.isVisible$ = this.noteType$.pipe(
      mergeMap(itemType => this.showFilters$.pipe(
        filter(showFilters => Boolean(showFilters)),
        map(({ stateChanges, chats, mails }) => {
          switch (itemType) {
            case NoteType.ChangedStatus:
            case NoteType.ChangedStatusOfMission:
            case NoteType.EscalatedByEvent:
            case NoteType.EscalatedByUser:
            case NoteType.CanceledEscalation:
              return stateChanges;
            case NoteType.Mail:
              return mails;
            case NoteType.Text:
            case NoteType.Caller:
              return chats;
            default:
              return true;
          }
        })
      ))
    );

    this.titleInfo$ = this.item$.pipe(
      filter(item => Boolean(item)),
      map(item => this.getTitleInfo(item)),
      switchMap(translation => translation ? this.translateService.stream(translation.key, translation.parameter) : of(''))
    );

    this.createdAt$ = this.item$.pipe(
      filter(item => Boolean(item)),
      map(item => item.createdAt)
    );

    this.mailFrom$ = this.item$.pipe(
      filter(item => Boolean(item) && item.note?.type === NoteType.Mail),
      map(item => ((item.note as NoteMailDto).from ?? [])
        .map(mailBox => mailBox.mailBox.address)
        .join(',')
      )
    );

    this.mailTo$ = this.item$.pipe(
      filter(item => Boolean(item) && item.note?.type === NoteType.Mail),
      map(item => ((item.note as NoteMailDto).to ?? [])
        .map(mailBox => mailBox.mailBox.address)
        .join(', ')
      )
    );

    this.mailCc$ = this.item$.pipe(
      filter(item => Boolean(item) && item.note?.type === NoteType.Mail),
      map(item => ((item.note as NoteMailDto).cc ?? [])
        .map(mailBox => mailBox.mailBox.address)
        .join(', ')
      )
    );

    this.mailSubject$ = this.item$.pipe(
      filter(item => Boolean(item) && item.note?.type === NoteType.Mail),
      map(item => ((item.note as NoteCompleteDto)).subject)
    );

    this.phoneNumber$ = this.item$.pipe(
      filter(item => Boolean(item) && item.note?.type === NoteType.Caller),
      map(item => ((item.note as NoteCallerDto)).phoneNumber)
    );

    this.memberReference$ = this.item$.pipe(
      filter(item => Boolean(item)),
      map((item: SolutionItemListDto) => {
        if (item.ticketId) {
          return { name: item.ticket?.title, link: ['/tickets', item.ticketId], module: 'communication', type: SolutionMemberType.Ticket };
        } else if (item.missionId) {
          return { name: item.mission?.title, link: ['/missions', item.missionId], module: 'inventory', type: SolutionMemberType.Mission };
        }
        return null;
      })
    );

    /* istanbul ignore next */
    this.headerActions = [
      {
        icon: faFlagCheckered,
        class: 'p-button-secondary p-button-minimal px-2',
        tooltip: 'solutions.actions.mark-solution-item-as-solution',
        buttonSize: 'md',
        shouldShow: (item: SolutionItemListDto) => of(!item.isSolution),
        isLoading: (item: SolutionItemListDto) => of(
          this.entityData ? this.entityData[item.solutionItemId]?.loading.markAsSolution : true
        ),
        callback: (item: SolutionItemListDto) => this.markAsSolution.emit(item)
      },
      {
        icon: faTimes,
        class: 'p-button-secondary p-button-minimal px-2',
        tooltip: 'solutions.actions.unmark-solution-item-as-solution',
        buttonSize: 'md',
        shouldShow: (item: SolutionItemListDto) => of(item.isSolution),
        isLoading: (item: SolutionItemListDto) => of(
          this.entityData ? this.entityData[item.solutionItemId]?.loading.unmarkAsSolution : true
        ),
        callback: (item: SolutionItemListDto) => this.unmarkAsSolution.emit(item)
      },
      {
        icon: faChevronUp,
        class: 'p-button-secondary p-button-minimal px-2',
        tooltip: 'core-shared.shared.table.tooltip.collapse',
        buttonSize: 'md',
        shouldShow: () => this.isExpanded$,
        callback: () => this.isCollapsed = true
      },
      {
        icon: faChevronDown,
        class: 'p-button-secondary p-button-minimal px-2',
        tooltip: 'core-shared.shared.table.tooltip.expand',
        buttonSize: 'md',
        shouldShow: () => this.isCollapsed$,
        callback: () => this.isCollapsed = false
      }
    ];
  }

  public onReply(solutionItem: SolutionItemDto): void {
    solutionItem.note.type === NoteType.Mail ? this.replyToMail.emit(solutionItem.note) : this.replyToChatMessage.emit(solutionItem.note);
  }

  /* istanbul ignore next */
  public getSolutionItemIcon(solutionItem: SolutionItemDto): IconDefinition {
    switch (solutionItem?.note?.type) {
      case NoteType.Text:
      case NoteType.ChatMessage:
        return faComment;
      case NoteType.Mail:
        return faEnvelope;
      case NoteType.Caller:
        return faPhone;
      case NoteType.ChangedStatus:
      case NoteType.ChangedStatusOfMission:
      case NoteType.FinalizedMission:
        return faExchangeAlt;
      case NoteType.EscalatedByEvent:
      case NoteType.EscalatedByUser:
      case NoteType.CanceledEscalation:
        return faExclamationTriangle;
      case NoteType.ChangedResource:
        return faFolder;
      default:
        return faQuestion;
    }
  }

  public toggleCollapsed(): void {
    this.isCollapsed = !this.isCollapsed;
  }

  /* istanbul ignore next */
  private getFileIcon(file: FileDto): IconDefinition {
    if (file.mimeType.includes('image')) {
      return faFileImage;
    } else if (file.mimeType.includes('pdf')) {
      return faFilePdf;
    }
    return faFile;
  }

  private isImageFile(file: FileDto): boolean {
    return file.mimeType.includes('image');
  }

  private getTitleInfo(solutionItem: SolutionItemDto): { key: string, parameter: Record<string, string> } {
    const note = solutionItem?.note as NoteCompleteDto;

    switch (note?.type) {
      case NoteType.Mail:
        if (note.direction === SystemDirections.In) {
          return note.from?.length ? {
            key: 'solutions.descriptions.solution-item-mail-from',
            parameter: { sender: note.from.map(mailBox => mailBox.mailBox.address).join(', ') }
          } : null;
        } else {
          return note.to?.length ? {
            key: 'solutions.descriptions.solution-item-mail-to',
            parameter: { recipient: note.to.map(mailBox => mailBox.mailBox.address).join(', ') }
          } : null;
        }

      case NoteType.Caller:
        return note.phoneNumber ? {
          key: 'solutions.descriptions.solution-item-phone-caller',
          parameter: { phoneNumber: note.phoneNumber }
        } : null;

      default:
        return null;
    }
  }

  private isChangedStatusWithReason(solutionItem: SolutionItemDto): boolean {
    const type = solutionItem?.note?.type;
    const description = solutionItem?.note?.description;
    return type === NoteType.ChangedStatus && !isUndefined(description) && !isNull(description) && description !== '';
  }

  private isMessage(solutionItem: SolutionItemDto): boolean {
    const type = solutionItem?.note?.type;
    return type === NoteType.Mail || type === NoteType.ChatMessage || type === NoteType.Text || type === NoteType.Caller;
  }

  private isShowReplyAction(solutionItem: SolutionItemDto): boolean {
    const type = solutionItem?.note?.type;
    return (type === NoteType.Mail && (solutionItem?.note as NoteMailDto)?.direction === SystemDirections.In) || type === NoteType.ChatMessage;
  }
}
