import { DashboardBridgeEffects, dashboardStore } from './stores';

export * from './stores';

export * from './dashboard.state';
export * from './dashboard.selector';

export const dashboardReducers = {
  dashboard: dashboardStore.reducer
};

export const dashboardEffects = [
  dashboardStore.effects,
  DashboardBridgeEffects
];
