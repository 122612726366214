
import { ModuleWithProviders, NgModule } from '@angular/core';
import { HintCodesDeclarationsModule } from './hint-codes-declarations.module';
import { HintCodesRoutingModule } from './hint-codes-routing.module';
import { HintCodesStoreModule } from './hint-codes-store.module';

@NgModule({
  imports: [
    HintCodesDeclarationsModule,
    HintCodesRoutingModule,
    HintCodesStoreModule
  ]
})
export class TechPortalFeatureConnectedHintCodesModule {
  public static forFeature(): ModuleWithProviders<HintCodesDeclarationsModule> {
    return { ngModule: HintCodesDeclarationsModule };
  }
}
