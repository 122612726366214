import { RouterModule, Routes } from '@angular/router';
import { ProcessDetailComponent, ProcessListComponent } from './containers';
import { NgModule } from '@angular/core';
import { I18nBreadcrumbResolver, UnsavedChangesGuard } from '@nexnox-web/core-shared';

export const TECH_PORTAL_PROCESSES_ROUTES: Routes = [
  {
    path: '',
    component: ProcessListComponent
  },
  {
    path: ':ticketByProcessDefinitionId',
    component: ProcessDetailComponent,
    canDeactivate: [UnsavedChangesGuard],
    data: {
      breadcrumbs: I18nBreadcrumbResolver,
      tab: 'process',
      text: 'core-portal.core.header.breadcrumbs.settings.ticket-settings.process'
    }
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(TECH_PORTAL_PROCESSES_ROUTES)
  ],
  exports: [
    RouterModule
  ]
})
export class ProcessesRoutingModule {
}
