<nexnox-web-cardbox [title]="'resources.subtitles.resource-list' | translate"
                    [icon]="faList"
                    [noMarginBottom]="true"
                    [noDividerMarginBottom]="true"
                    [fullWidth]="true"
                    [fullHeight]="true"
                    classes="d-flex flex-column"
                    paddingClasses="overflow-isHidden">

  <div class="refresh-btn">
    <button type="button" class="btn" [disabled]="listLoading$ | async" (click)="onRefresh()">
      <fa-icon [icon]="faSync" size="lg" [spin]="listLoading$ | async"></fa-icon>
    </button>
  </div>

  <div class="d-flex flex-column align-content-between h-100">

    <div class="resource-scrollbar w-100 flex-grow-1">
      <nexnox-web-hierarchical-tree-view #treeViewComponent
                                         [treeViewItems]="items$ | async"
                                         [paging]="paging$ | async"
                                         [loading]="listLoading$ | async"
                                         [nextLoading]="nextLoading$ | async"
                                         [disabled]="preloadSubject | async"
                                         (expandItem)="onExpandItem($event)"
                                         (moreItem)="onMoreItem($event)"
                                         (moreRoot)="onMore()"
                                         (selectItem)="selectItem.emit()">
      </nexnox-web-hierarchical-tree-view>
    </div>
    <div class="flex-shrink-1 w-100 align-self-end pt-2" *ngIf="(listLoading$ | async) === false && (someItemsInProgress$ | async)">
      <div class="resource-menu-title d-flex justify-space-between pr-4 align-items-center cursor-pointer" (click)="toggleProgressMenu()">
        <div>
          <span>{{ 'resources.subtitles.in-progress' | translate }}</span>
          <span>&nbsp;({{ inProgressIndicator$ | async }})</span>
        </div>
        <div *ngIf="isProgressMenuSubject.asObservable() | async"><fa-icon [icon]="faChevronUp"></fa-icon></div>
        <div *ngIf="(isProgressMenuSubject.asObservable() | async) === false"><fa-icon [icon]="faChevronDown"></fa-icon></div>
      </div>
      <div #boundingElement class="w-100 tree-view progress-menu-content resource-scrollbar" *ngIf="isProgressMenuSubject.asObservable() | async">
        <ng-container *ngFor="let item of itemsInProgress$ | async; trackBy: trackItemsById">
          <nexnox-web-hierarchical-tree-view-item #hierarchicalTreeViewItemComponent
                                                  [item]="item"
                                                  [nestLevel]="0"
                                                  [boundingElement]="boundingElement"
                                                  (expandItem)="onExpandItem($event)"
                                                  (moreItem)="onMoreItem($event)">
          </nexnox-web-hierarchical-tree-view-item>
        </ng-container>
      </div>
    </div>
  </div>
</nexnox-web-cardbox>

