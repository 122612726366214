import {
  articleDetailStore,
  ArticleDetailStoreState,
  articleListStore,
  ArticleListStoreState,
  articleUsagesListStore,
  ArticleUsagesListStoreState
} from './stores';

export interface ArticlesState {
  articleList: ArticleListStoreState;
  articleDetail: ArticleDetailStoreState;
  articleUsagesList: ArticleUsagesListStoreState
}

export const getInitialArticlesState = (): ArticlesState => ({
  articleList: articleListStore.getInitialState(),
  articleDetail: articleDetailStore.getInitialState(),
  articleUsagesList: articleUsagesListStore.getInitialState()
});
