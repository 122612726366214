import { Directive, Input } from '@angular/core';
import { FormControlDirective } from '@angular/forms';

@Directive({
  selector: '[formControl][nexnoxWebDisableFormControl]'
})
export class CoreSharedDisableFormControlDirective {
  @Input()
  public set nexnoxWebDisableFormControl(disable: boolean) {
    if (this.formControlDirective.disabled && !disable) {
      this.formControlDirective.control.enable({ emitEvent: true });
    } else if (this.formControlDirective.enabled && disable) {
      this.formControlDirective.control.disable({ emitEvent: true });
    }
  }

  constructor(
    private formControlDirective: FormControlDirective
  ) {
  }
}
