import {Injectable, Injector} from '@angular/core';
import {createEffect, ofType} from '@ngrx/effects';
import {ModelDetailXsStoreActions} from './model-detail-xs-store.actions';
import {catchError, map, switchMap, tap} from 'rxjs/operators';
import {CorePortalModelService} from '../../services';
import {Action} from '@ngrx/store';
import {of} from 'rxjs';
import {modelDetailStore} from "./model-detail.store";
import {UrlTree} from "@angular/router";

@Injectable()
export class ModelDetailStoreEffects extends modelDetailStore.effects {
  public actions: ModelDetailXsStoreActions;
  public service: CorePortalModelService;

  public merge$: any;
  public mergeSuccess$: any;

  public move$: any;
  public moveSuccess$: any;

  constructor(
    protected injector: Injector
  ) {
    super(injector);
  }

  protected createEffects(): void {
    super.createEffects();

    this.merge$ = createEffect(() => this.actions$.pipe(
      ofType(this.actions.merge),
      switchMap(({model, newModelId, returnPath}) => this.service.mergeModel(model, newModelId).pipe(
        map(() => this.actions.mergeSuccess({returnPath})),
        catchError(error => of(this.actions.error({error, action: this.actions.merge})))
      ))
    ));

    this.mergeSuccess$ = createEffect(() => this.actions$.pipe(
      ofType(this.actions.mergeSuccess),
      tap(action => this.actionCallback(action, false))
    ), {dispatch: false});


    this.move$ = createEffect(() => this.actions$.pipe(
      ofType(this.actions.move),
      switchMap(({model, newManufacturerId, returnPath}) => this.service.moveModel(model, newManufacturerId).pipe(
        map(() => this.actions.moveSuccess({returnPath})),
        catchError(error => of(this.actions.error({error, action: this.actions.move})))
      ))
    ));

    this.moveSuccess$ = createEffect(() => this.actions$.pipe(
      ofType(this.actions.moveSuccess),
      tap(action => this.actionCallback(action, false))
    ), {dispatch: false});

  }

  protected actionCallback(action: Action, isError: boolean): void {
    super.actionCallback(action, isError);

    this.checkAction(this.actions.mergeSuccess, action, ({returnPath}) => this.mergeSuccess(returnPath));
    this.checkAction(this.actions.moveSuccess, action, ({returnPath}) => this.moveSuccess(returnPath));
  }

  protected mergeSuccess(returnPath?: any[]): void {
    this.apiNotificationService.showTranslatedSuccess('core-portal.settings.toasts.resource-model.merge-success');

    if (returnPath) {
      const path: UrlTree = returnPath ? this.router.createUrlTree(returnPath, {
        queryParams: {
          reload: true
        }
      }) : undefined;
      this.router.navigateByUrl(path).then();
    }
  }

  protected moveSuccess(returnPath?: any[]): void {
    this.apiNotificationService.showTranslatedSuccess('core-portal.settings.toasts.resource-model.move-success');

    if (returnPath) {
      const path: UrlTree = returnPath ? this.router.createUrlTree(returnPath, {
        queryParams: {
          reload: true
        }
      }) : undefined;
      this.router.navigateByUrl(path).then();
    }
  }
}
