<div *ngFor="let item of items$ | async; trackBy: trackItemsBy; let index = index; let isFirst = first; let isLast = last"
     class="d-flex flex-row align-items-center flex-wrap form-multiple-choice-item"
     [class.mb-2]="!to.disabled || !isLast">
  <div class="d-flex align-items-center"
       [class.flex-shrink-1]="!to.readonly"
       [class.flex-row-reverse]="to.readonly"
       [class.flex-grow-1]="!to.readonly"
  >
    <i *ngIf="!to.readonly || item.isSelected"
       [class.cursor-pointer]="!to.readonly"
       [class.text-success]="item.isSelected || (to.corePortalCustomPropertyMultipleChoice?.isRateable && item.rating === ratingTypes.Ok)"
       [class.text-danger]="!item.isSelected || (to.corePortalCustomPropertyMultipleChoice?.isRateable && item.rating === ratingTypes.Error)"
       [class]="getAnswerIcon(item) | nexnoxWebFaIconStringPipe: 'fa-lg'"
       (click)="!to.readonly ? onIconClick(item) : null">
    </i>

    <span *ngIf="to.readonly"
          class="d-flex mr-2 gap-2 align-items-center"
          [style.height.px]="29">
      <i class="letter font-weight-bold">{{ getAnswerLetter(index) }}</i>

      <span>{{ item.value }}</span>
    </span>

    <span *ngIf="!to.readonly"
          #inputContainer
          class="d-flex flex-shrink-1 flex-grow-1 ml-2 mr-1 align-items-center p-input-icon-left"
          [style.min-width.px]="50"
          [style.height.px]="29">
      <i class="letter__edit-mode font-weight-bold">{{ getAnswerLetter(index) }}</i>

      <input pInputText
             type="text"
             class="w-100 p-input text-sm"
             [ngModel]="item.value"
             [disabled]="to.disabled"
             [class.is-invalid]="!item.value"
             (ngModelChange)="onValueChange(item, $event)">
    </span>

    <ng-container *ngIf="itemsMultipleChoiceActions$ | async as itemsMultipleChoiceActions">
      <div *ngIf="!to.disabled"
           class="d-flex flex-shrink-0 p-1">
        <p-tieredMenu #moreActionMenu
                      appendTo="body"
                      [model]="itemsMultipleChoiceActions[index]"
                      [popup]="true">
        </p-tieredMenu>

        <button type="button"
                pButton
                class="p-button-text p-button-icon-only p-button-sm p-button-secondary"
                [icon]="faEllipsisV | nexnoxWebFaIconStringPipe:'fa-fw'"
                [title]="'core-portal.core.general.more' | translate"
                (click)="moreActionMenu.toggle($event)">
        </button>
      </div>
    </ng-container>
  </div>
</div>

<div *ngIf="!to.disabled" class="d-flex flex-row align-items-center">
  <div class="d-flex flex-shrink-1 flex-grow-1 mr-1">
    <input #addItemInput
           pInputText
           type="text"
           class="w-100 p-input text-sm"
           [(ngModel)]="addModel"
           [disabled]="to.disabled"
           (keyup.enter)="onAddItem()">
  </div>

  <div class="d-flex flex-shrink-0 p-1">
    <button pButton
            type="button"
            class="p-button-secondary p-button-text p-button-icon-only p-button-sm"
            [icon]="faPlus | nexnoxWebFaIconStringPipe"
            [pTooltip]="'core-shared.shared.actions.add' | translate"
            [disabled]="!addModel"
            (click)="onAddItem()">
    </button>
  </div>
</div>
