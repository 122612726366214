import {ChangeDetectionStrategy, Component, Injector, Input, OnInit} from '@angular/core';
import {ActionButton, CorePortalCardboxAction, CorePortalEntityDetailBaseComponent} from '@nexnox-web/core-portal';
import {
  AppPermissions,
  TicketByContractDefinitionDto,
  TicketByContractDefinitionSimpleDto
} from '@nexnox-web/core-shared';
import {contractSettingsStore} from "../../store";
import {BehaviorSubject} from "rxjs";
import {faTimes} from '@fortawesome/free-solid-svg-icons/faTimes';
import {faPlus} from "@fortawesome/free-solid-svg-icons/faPlus";
import {map, withLatestFrom} from "rxjs/operators";
import {faSave} from "@fortawesome/free-solid-svg-icons/faSave";
import {faTrash} from "@fortawesome/free-solid-svg-icons/faTrash";
import {ofType} from "@ngrx/effects";
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons/faExclamationTriangle';

@Component({
  selector: 'nexnox-web-stereotypes-contract-settings-detail',
  templateUrl: './contract-settings-detail.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StereotypeContractSettingsDetailComponent extends CorePortalEntityDetailBaseComponent<TicketByContractDefinitionDto> implements OnInit {

  @Input() public contractStereotypeId: number;
  @Input() public readonly$;
  @Input() public loading$;

  @Input()
  public set ticketByContractSimpleDefinition(definition: TicketByContractDefinitionSimpleDto) {
    this.id = definition?.definitionId;
    this.definitionSimpleSubject.next(definition);
  }

  public get ticketByContractSimpleDefinition(): TicketByContractDefinitionSimpleDto {
    return this.definitionSimpleSubject.getValue();
  }

  public title = 'core-portal.settings.subtitles.contract-settings'
  public idParam = 'contractStereotypeId';

  public definitionSimpleSubject: BehaviorSubject<TicketByContractDefinitionSimpleDto> = new BehaviorSubject<TicketByContractDefinitionSimpleDto>(null);
  public createModeSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public createModelSubject: BehaviorSubject<TicketByContractDefinitionDto> = new BehaviorSubject<TicketByContractDefinitionDto>(null);

  public faTimes = faTimes;
  public faTrash = faTrash;
  public faExclamationTriangle = faExclamationTriangle;

  constructor(
    protected injector: Injector,
  ) {
    super(injector, contractSettingsStore);
  }

  public ngOnInit(): void {

    // Synchronize createModelSubject with store model and ids
    this.subscribe(this.createModelSubject, (model: TicketByContractDefinitionDto) => {
      this.id = model?.definitionId;
      this.store.dispatch(contractSettingsStore.actions.modelUpdate({model}));
    });

    // Get entity when simple definition has been passed over
    this.subscribe(this.definitionSimpleSubject, (definition => {
      if (definition?.definitionId) {
        this.getEntity(definition?.definitionId, []);
      }
    }));

    // Synchronize createModelSubject with definitionId after get
    this.subscribe(this.actions$.pipe(ofType(contractSettingsStore.actions.getSuccess)),
      payload => {
        this.createModelSubject.next(payload?.model);
        this.id = payload?.model?.definitionId;
        this.createModeSubject.next(false);
      }
    );

    // Synchronize createModeSubject after create
    this.subscribe(this.actions$.pipe(ofType(contractSettingsStore.actions.createSuccess)),
      payload => {
        this.createModelSubject.next(payload?.model);
        this.id = payload?.model?.definitionId;
        this.createModeSubject.next(false);
      }
    );

    // Reset model after delete
    this.subscribe(this.actions$.pipe(ofType(contractSettingsStore.actions.deleteSuccess)),
      payload => {
        this.createModelSubject.next(null);
        this.definitionSimpleSubject.next(null);
        this.id = undefined;
      }
    );
  }

  public setCreateMode(): void {
    this.createModeSubject.next(true);
  }

  public onCreateAction(): void {
    this.store.dispatch(contractSettingsStore.actions.create({
      contractStereotypeId: this.contractStereotypeId,
      model: this.createModelSubject.getValue()
    }));
  }

  /* istanbul ignore next */
  public getCardBoxActionButtons(): CorePortalCardboxAction[] {
    return [
      {
        label: 'core-portal.settings.actions.create-contract-settings',
        icon: faPlus,
        class: 'btn-primary',
        shouldShow: () => this.createModeSubject.pipe(
          withLatestFrom(this.readonly$),
          map(([creating, readonly]) => !readonly && creating)
        ),
        isDisabled: () => this.isOwnModelValid().pipe(map((valid) => !valid)),
        isLoading: () => this.loading$,
        permission: AppPermissions.CreateTicketByContractDefinition,
        callback: () => this.onCreateAction()
      },
      {
        label: 'core-portal.settings.actions.save-contract-settings',
        icon: faSave,
        class: 'btn-primary',
        shouldShow: () => this.createModeSubject.pipe(
          withLatestFrom(this.readonly$),
          map(([creating, readonly]) => !readonly && !creating)
        ),
        isDisabled: () => this.isOwnModelValid().pipe(map((valid) => !valid)),
        isLoading: () => this.loading$,
        permission: AppPermissions.UpdateTicketByContractDefinition,
        callback: () => {
          this.onSaveAction();
        }
      },
      {
        label: 'core-portal.settings.actions.delete-contract-settings',
        icon: faTrash,
        class: 'btn-primary',
        isLoading: () => this.loading$,
        shouldShow: () => this.createModeSubject.pipe(
          withLatestFrom(this.readonly$),
          map(([creating, readonly]) => !readonly && !creating)
        ),
        permission: AppPermissions.DeleteTicketByContractDefinition,
        callback: () => {
          this.onDeleteAction(
            {label: 'core-portal.settings.actions.delete-contract-settings'} as ActionButton,
            'core-portal.settings.descriptions.contract-settings.delete'
          );
        }
      }
    ];
  }
}
