import { ModuleWithProviders, NgModule } from '@angular/core';
import { CategoriesDeclarationsModule } from './categories-declarations.module';
import { CategoriesRoutingModule } from './categories-routing.module';
import { CategoriesStoreModule } from './categories-store.module';

@NgModule({
  imports: [
    CategoriesDeclarationsModule,
    CategoriesRoutingModule,
    CategoriesStoreModule
  ]
})
export class OrgaPortalFeatureCategoriesModule {
  public static forFeature(): ModuleWithProviders<CategoriesDeclarationsModule> {
    return { ngModule: CategoriesDeclarationsModule };
  }
}
