import { PagedEntitiesXsStore, PagedEntitiesXsStoreState } from '@nexnox-web/core-store';
import { AppEntityType, AppTenantDto } from '@nexnox-web/core-shared';
import { createSelector } from '@ngrx/store';
import { selectTenantsState } from '../../tenants.selectors';
import { TenantService } from '../../services';

export interface TenantListStoreState extends PagedEntitiesXsStoreState<AppTenantDto> {
}

/* istanbul ignore next */
export const tenantListStore = new PagedEntitiesXsStore<AppTenantDto>({
  actionLabel: 'Core Portal - Service - Tenants - Tenant List',
  stateSelector: createSelector(selectTenantsState, state => state.tenantList),
  serviceType: TenantService,
  entityType: AppEntityType.None,
  selectId: tenant => tenant.entity?.tenantId ?? tenant.model?.tenantId,
  stereotyped: false,
  removeTenantIdOnCreate: true
});
