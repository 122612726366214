import { ModuleWithProviders, NgModule } from '@angular/core';
import { StereotypesStoreModule } from './stereotypes-store.module';
import { StereotypesDeclarationsModule } from './stereotypes-declarations.module';
import { StereotypesRoutingModule } from './stereotypes-routing.module';

@NgModule({
  imports: [
    StereotypesDeclarationsModule,
    StereotypesRoutingModule,
    StereotypesStoreModule
  ]
})
export class CorePortalFeatureStereotypesModule {
  public static forFeature(): ModuleWithProviders<StereotypesDeclarationsModule> {
    return { ngModule: StereotypesDeclarationsModule };
  }
}
