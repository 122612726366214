import { EntityAdapter, EntitySelectors } from '@ngrx/entity/src/models';
import { createSelector, MemoizedSelector } from '@ngrx/store';
import { Filter, Paging, SortObject, StereotypeDto } from '@nexnox-web/core-shared';
import { PagedEntitiesXsStoreEntity, PagedEntitiesXsStoreState } from './paged-entities-xs-store.state';
import { PagedEntitiesXsStoreEntityData } from './models';

export interface PagedEntitiesXsStoreSelectors<E, M = E, S = PagedEntitiesXsStoreState<E, M>>
  extends EntitySelectors<PagedEntitiesXsStoreEntity<E, M>, S> {
  selectPaging: MemoizedSelector<S, Paging>;
  selectLoading: MemoizedSelector<S, boolean>;
  selectLoaded: MemoizedSelector<S, boolean>;
  selectLoadedAt: MemoizedSelector<S, string>;
  selectAppendLoading: MemoizedSelector<S, boolean>;
  selectHasError: MemoizedSelector<S, boolean>;
  selectStereotypes: MemoizedSelector<S, StereotypeDto[]>;
  selectStereotypesLoaded: MemoizedSelector<S, boolean>;
  selectFilters: MemoizedSelector<S, Filter[]>;
  selectSortObject: MemoizedSelector<S, SortObject>;
  selectEntityData: MemoizedSelector<S, PagedEntitiesXsStoreEntityData>;
}

export const createPagedEntitiesXsStoreSelectors = <E, M, S extends PagedEntitiesXsStoreState<E, M>>(
  stateSelector: MemoizedSelector<any, S>,
  adapter: EntityAdapter<PagedEntitiesXsStoreEntity<E, M>>
): PagedEntitiesXsStoreSelectors<E, M, S> => ({
  ...adapter.getSelectors(stateSelector),
  selectPaging: createSelector(stateSelector, state => state?.paging),
  selectLoading: createSelector(stateSelector, state => state?.loading),
  selectLoaded: createSelector(stateSelector, state => state?.loaded),
  selectLoadedAt: createSelector(stateSelector, state => state?.loadedAt),
  selectAppendLoading: createSelector(stateSelector, state => state?.appendLoading),
  selectHasError: createSelector(stateSelector, state => state?.hasError),
  selectStereotypes: createSelector(stateSelector, state => state?.stereotypes),
  selectStereotypesLoaded: createSelector(stateSelector, state => state?.stereotypesLoaded),
  selectFilters: createSelector(stateSelector, state => state?.filters),
  selectSortObject: createSelector(stateSelector, state => state?.sortObject),
  selectEntityData: createSelector(stateSelector, state => state?.entityData)
});
