<nexnox-web-entity-overview #overviewComponent
                            [entityOverviewBaseComponent]="this"
                            [appendColumns]="appendColumns()"
                            [cardBoxHeaderActions]="getCardBoxHeaderActions()"
                            [datatableConfig]="customDatatableConfig"
                            detailLink="/missions"
                            module="inventory">
</nexnox-web-entity-overview>

<ng-template #ganttRowTemplate
             let-currentRow="row">
    <div class="gantt-block-container">
      <ng-container *ngIf="isFirstRow(ganttRows, currentRow)"
                    [ngTemplateOutlet]="ganttHeaderTemplate">
      </ng-container>
      <div class="gantt-block"
           *ngFor="let block of getGanttRow(ganttRows, currentRow)"
           (click)="expandDay(block, ganttRows)"
           [class.--expanded]="block.isExpanded">
        <div class="gantt-day-cell"
             [class.gantt-day-empty]="block.isEmpty"
             [class.gantt-odd-week]="block.isOddWeek">
          <div *ngIf="!block.isEmpty" class="gantt-day-bar"
               [style.left.%]="block.fillStart"
               [style.right.%]="block.fillEnd"
               [style.background-color]="'#' + block.contactColor">
          </div>
          <div *ngIf="block.isExpanded && !block.isEmpty" class="gantt-day-expanded-hour-scale">
            <div *ngFor="let hour of hourScaleArray">
              <span *ngIf="hour > 0">{{hour}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
</ng-template>

<ng-template #ganttHeaderTemplate>
  <div class="gantt-header">
    <div class="gantt-header-day-cell"
         *ngFor="let block of ganttHeader; let index = index"
         [class.--expanded]="block.isExpanded">
      <div class="gantt-header-month-title">
        <div *ngIf="block.isFirstOfMonth || index === 0">{{ block.monthTitle }}</div>
        &nbsp;
      </div>
      <div *ngIf="!isHideWeekNumbers" class="gantt-header-week-number">
        <div *ngIf="(block.isMonday || index === 0)">W {{ block.weekNumber }}</div>
        &nbsp;
      </div>
      <div class="gantt-header-day-number"
           [class.gantt-header-day-weekend]="block.isWeekend">
        {{ block.day }}
      </div>
    </div>
  </div>
</ng-template>
