import { CoreSharedApiBaseService, PageableRequest, Product, Query } from '@nexnox-web/core-shared';
import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class OrgaPortalDocumentService extends CoreSharedApiBaseService {
  constructor(
    protected injector: Injector
  ) {
    super(injector, Product.ORGA, 'document');
  }

  public getDocumentPage<T>(
    documentPath: string,
    tenantId: number,
    pageNumber: number = 1,
    pageSize: number = this.environment.defaultPageSize
  ): Observable<PageableRequest<T>> {
    const query = new Query()
      .add('path', documentPath ? documentPath : null)
      .add('pageNumber', pageNumber)
      .add('pageSize', pageSize);
    const queryString = query.build();
    const path = `${this.buildPath([], false)}/tenant/${tenantId}${queryString}`;

    return this.httpRequestService.get<PageableRequest<T>>(path).pipe(
      map(response => response.body)
    );
  }

  public addFolder(name: string, documentPath: string, tenantId: number): Observable<void> {
    const query = new Query()
      .add('name', name)
      .add('path', documentPath ? documentPath : null);
    const queryString = query.build();
    const path = `${this.buildPath([], false)}/tenant/${tenantId}/folder${queryString}`;

    return this.httpRequestService.post<any>(path, null).pipe(
      map(response => response.body)
    );
  }

  public addDocument(fileData: FormData, documentPath: string, tenantId: number): Observable<void> {
    const query = new Query()
      .add('path', documentPath ? documentPath : null);
    const queryString = query.build();
    const path = `${this.buildPath([], false)}/tenant/${tenantId}/file${queryString}`;

    return this.httpRequestService.post<any>(path, fileData).pipe(
      map(response => response.body)
    );
  }

  public deleteFolder(documentPath: string, tenantId: number): Observable<void> {
    const query = new Query()
      .add('path', documentPath ? documentPath : null);
    const queryString = query.build();
    const path = `${this.buildPath([], false)}/tenant/${tenantId}/folder${queryString}`;

    return this.httpRequestService.delete<any>(path).pipe(
      map(response => response.body)
    );
  }

  public deleteDocument(documentPath: string, documentName: string, tenantId: number): Observable<void> {
    const query = new Query()
      .add('path', documentPath ? documentPath : null)
      .add('name', documentName ? documentName : null);
    const queryString = query.build();
    const path = `${this.buildPath([], false)}/tenant/${tenantId}/file${queryString}`;

    return this.httpRequestService.delete<any>(path).pipe(
      map(response => response.body)
    );
  }
}
