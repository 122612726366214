import { PagedEntitiesXsStore, PagedEntitiesXsStoreState } from '@nexnox-web/core-store';
import { AppEntityType, ContactDto } from '@nexnox-web/core-shared';
import { createSelector } from '@ngrx/store';
import { selectLocationsState } from '../../locations.selectors';
import { LocationContactService } from '../../services';

export interface LocationContactListStoreState extends PagedEntitiesXsStoreState<ContactDto> {
}

/* istanbul ignore next */
export const locationContactListStore = new PagedEntitiesXsStore<ContactDto>({
  actionLabel: 'Core Portal - Master Data - Location - Contact List',
  stateSelector: createSelector(selectLocationsState, state => state.locationContactList),
  serviceType: LocationContactService,
  entityType: AppEntityType.Contact,
  selectId: contact => contact.entity?.contactId ?? contact.model?.contactId
});
