import {EntityXsStore, EntityXsStoreState} from '@nexnox-web/core-store';
import {AppEntityType, NotificationRuleDto} from '@nexnox-web/core-shared';
import {Action, createSelector} from '@ngrx/store';
import {selectNotificationRulesState} from '../../notification-rules.selector';
import {CorePortalFeatureNotificationRulesService} from '../../services';
import {Injectable, Injector} from '@angular/core';

export interface NotificationRulesDetailStoreState extends EntityXsStoreState<NotificationRuleDto> {
}

/* istanbul ignore next */
export const notificationRulesDetailStore = new EntityXsStore<NotificationRuleDto>({
  actionLabel: 'Core Portal - Settings - Notification Rules - Notification Rules Detail',
  stateSelector: createSelector(selectNotificationRulesState, state => state.notificationRulesDetail),
  serviceType: CorePortalFeatureNotificationRulesService,
  entityType: AppEntityType.None,
  stereotyped: false
});

export function notificationRulesDetailStoreReducer(state: NotificationRulesDetailStoreState, action: Action): any {
  return notificationRulesDetailStore.reducer(state, action);
}

@Injectable()
export class NotificationRulesDetailStoreEffects extends notificationRulesDetailStore.effects {
  constructor(
    protected injector: Injector
  ) {
    super(injector);
  }
}
