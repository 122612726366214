import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren
} from '@angular/core';
import {faSpinner} from '@fortawesome/free-solid-svg-icons/faSpinner';
import {
  ApiNotificationService,
  FilterDto,
  FilterOperators,
  FilterTypes,
  LabelDto,
  LabelDescriptorType,
  UnsubscribeHelper
} from '@nexnox-web/core-shared';
import {BehaviorSubject, distinctUntilKeyChanged, Observable, Subject} from 'rxjs';
import {
  CorePortalLabelService
} from "@nexnox-web/core-portal/features/settings/features/labels/src/lib/store/services/label/label.service";
import {faPlus} from '@fortawesome/free-solid-svg-icons/faPlus';
import {faMinus} from '@fortawesome/free-solid-svg-icons/faMinus';
import {CorePortalEntitySelectComponent, CorePortalEntitySelectOptions} from "@nexnox-web/core-portal/lib";
import {Store} from "@ngrx/store";
import {isEmpty} from "lodash";

enum LabelDisplayModes {
  EMPTY = 0,
  AUTOMATIC = 1,
  DISPLAY = 2,
  EDIT = 3
}

@Component({
  selector: 'nexnox-web-entity-labels',
  templateUrl: './entity-labels.component.html',
  styleUrls: ['./entity-labels.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CorePortalEntityLabelsComponent extends UnsubscribeHelper implements OnInit {

	@ViewChildren(CorePortalEntitySelectComponent) public selects: QueryList<CorePortalEntitySelectComponent>;
	@ViewChild('addButton') public addButton: ElementRef;

  @Input()
  public set labels(value: LabelDto[]) {
    this.labelSubject.next(value)
  }

  public get labels(): LabelDto[] {
    return this.labelSubject.getValue();
  }

  @Input()
  public set loading(value: boolean) {
    this.loadingSubject.next(value)
  }

  public get loading(): boolean {
    return this.loadingSubject.getValue();
  }

  @Input() public readonly: boolean;
  @Input() public tenantId: number;
  @Input() public entityId: number;
  @Input() public labelType: LabelDescriptorType;
  @Input() public isAutomaticLabeling$: Observable<boolean>;

  @Output() public labelsChange: EventEmitter<LabelDto[]> = new EventEmitter<LabelDto[]>();

  public labelSubject: BehaviorSubject<LabelDto[]> = new BehaviorSubject<LabelDto[]>([]);
  public loadingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public refreshAutocompleteSubject: Subject<void> = new Subject<void>();
  public defaultFilterSubject: BehaviorSubject<FilterDto[]> = new BehaviorSubject<FilterDto[]>([])

  public isAutomaticLabeling: boolean;
  public modes = LabelDisplayModes;
  public displayMode: LabelDisplayModes;
  public labelCreateOptions: CorePortalEntitySelectOptions;

  public faSpinner = faSpinner;
  public faPlus = faPlus;
  public faMinus = faMinus;

  public saveLabelFn = (content: string): void => this.onSaveLabel(content);

  constructor(
    private store: Store,
    private apiNotificationService: ApiNotificationService,
    private labelService: CorePortalLabelService
  ) {
    super();
  }

  /* istanbul ignore next */
  public ngOnInit(): void {

    this.subscribe(this.labelSubject.pipe(distinctUntilKeyChanged('length')), (labels) => {
      this.resetDisplayMode();
      this.setDefaultFilters();
      setTimeout(() => this.refreshAutocompleteSubject.next())
    });

    this.subscribe(this.isAutomaticLabeling$, (isAuto) => {
      this.isAutomaticLabeling = isAuto;
      this.resetDisplayMode();
    });

    this.labelCreateOptions = {
      idKey: 'labelId',
      displayKey: 'content',
      wholeObject: true,
      entityService: this.labelService,
      minimal: true,
      clearable: false,
      skipGetOne: true,
      refresh$: this.refreshAutocompleteSubject.asObservable(),
      onlyRefreshEntity: true,
      defaultFilters$: this.defaultFilterSubject.asObservable()
    };
  }

  public onChangeToEditMode(): void {
    this.displayMode = LabelDisplayModes.EDIT;
  this.focusSelect();
	}

  public onSaveLabel(content: string): void {
    const tenantId = this.tenantId;

    if (!isEmpty(content) && !this.isGiven(content)) {
      this.displayMode = LabelDisplayModes.DISPLAY;
      this.loading = true;
      this.labelService.createOne({content, tenantId}).toPromise().then(
        (savedLabel) => this.onAddLabel(savedLabel),
        (error) => this.onError(error)
      );
    }
  }

  public onAddLabel(label: LabelDto): void {
    this.loading = true;
    this.displayMode = LabelDisplayModes.DISPLAY;
    this.labelService.addLabel(label.labelId, this.entityId, this.labelType).toPromise().then(
      () => this.onAddSuccess(label),
      (error) => this.onError(error)
    );
  }

  public onRemoveLabel(label: LabelDto): void {
    this.displayMode = LabelDisplayModes.DISPLAY;
    this.labelService.removeLabel(label.labelId, this.entityId, this.labelType).toPromise().then(
      () => this.onRemoveSuccess(label),
      (error) => this.onError(error)
    );
  }

  public onAddSuccess(label: LabelDto): void {
    const labels = [...this.labels, label];
    this.labelSubject.next(labels);
    this.loading = false;
    this.apiNotificationService.showTranslatedSuccess('core-portal.settings.descriptions.add-label');
  this.focusAddButton();
	}

  public onRemoveSuccess(label: LabelDto): void {
    this.labelSubject.next(this.labels?.filter(l => l.labelId !== label.labelId));
    this.loading = false;
    this.apiNotificationService.showTranslatedSuccess('core-portal.settings.descriptions.remove-label');
  }


  private onError(error: Error): void {
    this.loading = false;
    this.apiNotificationService.showTranslatedError('core-portal.settings.descriptions.error-label');
    if (error) this.apiNotificationService.handleApiError(error);
    this.displayMode = LabelDisplayModes.DISPLAY;
  }

  private emitChange(): void {
    this.labelsChange.emit(this.labels)
  }

  private resetDisplayMode(): void {
    this.displayMode = this.isAutomaticLabeling ? LabelDisplayModes.AUTOMATIC : this.labels?.length ? LabelDisplayModes.DISPLAY : LabelDisplayModes.EMPTY;
  }

  private setDefaultFilters(): void {
    this.defaultFilterSubject.next(this.labels?.map((label) => (
      {
        property: 'labelId',
        type: FilterTypes.DataTransferObject,
        operator: FilterOperators.NotEqual,
        value: label.labelId.toString()
      }
    )));
  }

  private isGiven(content: string): boolean {
    return this.labels?.filter(label => label.content === content)?.length > 0;
  }

	private focusSelect(): void {
		const fastFocusInterval = setInterval(() => {
			if (this.selects?.first?.selectComponent) {
				this.selects?.first.selectComponent.focus();
				if (this.selects?.first.selectComponent.focused) {
					clearInterval(fastFocusInterval);
				}
			}
		},10);
	}

	private focusAddButton(): void {
		const fastFocusInterval = setInterval(() => {
			if (this.addButton?.nativeElement) {
				this.addButton.nativeElement.focus();
				if (this.addButton.nativeElement.focused) {
					clearInterval(fastFocusInterval);
				}
			}
		},10);
	}
}
