<nexnox-web-cardbox [title]="'tech-portal.ccp.control-points.subtitles.list' | translate"
                    [loading]="loading"
                    paddingClasses="px-3 pb-3">

  <div *ngIf="!readonly"
       class="mb-3">
    <form [formGroup]="addControlPointForm">
      <formly-form class="row w-100"
                   [form]="addControlPointForm"
                   [model]="addControlPointModelSubject | async"
                   [fields]="addControlPointFields"
                   (modelChange)="addControlPointModelSubject.next($event)">
      </formly-form>
    </form>

    <div class="d-flex justify-content-end w-100">
      <button type="button"
              pButton
              [icon]="faPlus | nexnoxWebFaIconStringPipe"
              [title]="'core-shared.shared.actions.add' | translate"
              class="p-button-secondary p-button-outlined mr-2"
              [label]="'core-shared.shared.actions.add' | translate"
              [disabled]="addControlPointForm.invalid"
              (click)="onAddControlPoint()">
      </button>
    </div>
  </div>

  <div class="pt-2 px-2 pb-3">
    <nexnox-web-sortable-list #controlPointListEdit
                              [items]="controlPointItems$  | async"
                              [disabled]="readonly"
                              [sortable]="true"
                              [editable]="false"
                              (itemsChange)="onControlPointsChange($event)">
    </nexnox-web-sortable-list>
  </div>

</nexnox-web-cardbox>
