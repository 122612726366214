<div class="d-flex flex-wrap align-items-center overflow-hidden position-relative"
     [style.min-width.px]="minWidth"
     [style.width]="'calc(100% + 0.25rem)'"
     [style.max-height.px]="maxHeight"
     [style.padding-left.rem]="0.25"
     [style.margin-left.rem]="-0.25">
  <div *ngIf="hasFlexContent"
       class="d-flex flex-shrink-1 flex-grow-1 align-items-center"
       [style.height.px]="maxHeight">
    <div class="d-flex flex-grow-1 mr-1">
      <ng-content></ng-content>
    </div>

    <div *ngIf="!readonly && (showMenuButton$ | async) && (actionsAsDropdownItems$ | async)?.length"
         class="d-flex flex-shrink-0 p-1">
      <p-tieredMenu #moreActionMenu
                    [model]="actionsAsDropdownItems$ | async"
                    [popup]="true"
                    appendTo="body">
      </p-tieredMenu>

      <button type="button"
              pButton
              [icon]="faEllipsisV | nexnoxWebFaIconStringPipe:'fa-fw'"
              class="p-button-text p-button-icon-only p-button-sm p-button-secondary"
              [title]="'core-portal.core.general.more' | translate"
              (click)="moreActionMenu.toggle($event)">
      </button>
    </div>
  </div>

  <div #actionsContainer
       class="d-flex flex-shrink-0">
    <div *ngFor="let action of actions$ | async; trackBy: trackActionsBy; let i = index">
      <ng-container *ngIf="action">
        <p-menu #actionMenu
                [model]="action.dropdownItems"
                [popup]="true"
                appendTo="body">
        </p-menu>

        <button *ngIf="!useNew; else newTemplate"
                class="btn {{ action.class }}"
                [class.d-none]="!action.shouldShow"
                [class.ml-2]="i > 0"
                [disabled]="action.isDisabled"
                [attr.title]="action.tooltip | translate"
                (click)="action.dropdownItems ? actionMenu.toggle($event) : action.callback(data, $event)">
          <fa-icon *ngIf="action.icon"
                   [icon]="action.icon"
                   [fixedWidth]="true"
                   size="sm"
                   [nexnoxWebLoadingIcon]="action.isLoading"
                   [class.mr-2]="action.label">
          </fa-icon>
          <span *ngIf="action.label">{{ action.label | translate }}</span>
        </button>

        <ng-template #newTemplate>
          <p-menu #actionMenu
                  [model]="action.dropdownItems"
                  [popup]="true"
                  appendTo="body">
          </p-menu>

          <ng-container *ngIf="action.badgeText">
            <p-badge *ngIf="action.badgeText"
                     [value]="action.badgeText"
                     [style]="{
                       minWidth: '0.9rem',
                       height: '0.9rem',
                       lineHeight: '0.9rem',
                       marginLeft: 'calc(32.98px / 2)',
                       marginTop: '1px'
                     }"
                     styleClass="position-absolute p-badge--top {{action.badgeClass}}">
            </p-badge>
          </ng-container>

          <div class="p-1"
               [class.d-none]="!action.shouldShow">
            <button type="button"
                    pButton
                    class="p-button-text p-button-icon-only {{ action.class }}"
                    [class.p-button-sm]="action.buttonSize === 'sm'"
                    [class.p-button-lg]="action.buttonSize === 'lg'"
                    [icon]="action.icon | nexnoxWebFaIconStringPipe"
                    [loadingIcon]="faSpinner | nexnoxWebFaIconStringPipe:'fa-spin'"
                    [disabled]="action.isDisabled"
                    [loading]="action.isLoading"
                    [pTooltip]="action.tooltip | translate"
                    [label]="action.label | translate"
                    (click)="action.dropdownItems ? actionMenu.toggle($event) : action.callback(data, $event)">
            </button>
          </div>
        </ng-template>
      </ng-container>
    </div>
  </div>
</div>
