import { PagedEntitiesXsStore, PagedEntitiesXsStoreState } from '@nexnox-web/core-store';
import { AppEntityType, LocationDto } from '@nexnox-web/core-shared';
import { Action, createSelector } from '@ngrx/store';
import { selectAddressesState } from '../../addresses.selectors';
import { AddressLocationService } from '../../services';
import { Injectable, Injector } from '@angular/core';

export interface AddressLocationListStoreState extends PagedEntitiesXsStoreState<LocationDto> {
}

/* istanbul ignore next */
export const addressLocationListStore = new PagedEntitiesXsStore<LocationDto>({
  actionLabel: 'Core Portal - Master Data - Address - Location List',
  stateSelector: createSelector(selectAddressesState, state => state.addressLocationList),
  serviceType: AddressLocationService,
  entityType: AppEntityType.Location,
  selectId: location => location.entity?.locationId ?? location.model?.locationId
});

export function addressLocationListStoreReducer(state: AddressLocationListStoreState, action: Action): any {
  return addressLocationListStore.reducer(state, action);
}

@Injectable()
export class AddressLocationListStoreEffects extends addressLocationListStore.effects {
  constructor(
    protected injector: Injector
  ) {
    super(injector);
  }
}
