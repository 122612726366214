import { InjectionToken, Injector, ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { Environment, Product } from './models';
import {
  CORE_SHARED_ENVIRONMENT,
  CORE_SHARED_ENVIRONMENT_INNER,
  CORE_SHARED_PRODUCT,
  CORE_SHARED_PRODUCT_INNER,
  CORE_SHARED_ROOT_GUARD
} from './tokens';
import { CoreSharedRootModule } from './core-shared-root.module';
import { exportedModules, sharedModules, sharedProviders } from './shared-exports';
import { CoreSharedGoogleMapsService, CoreSharedExportService } from './services';

@NgModule({
  imports: [
    ...sharedModules
  ],
  exports: [
    ...sharedModules,
    ...exportedModules
  ],
  providers: [
    ...sharedProviders
  ]
})
export class CoreSharedModule {
  public static forRoot(
    environment: Environment | InjectionToken<Environment>,
    product: Product | InjectionToken<Product>
  ): ModuleWithProviders<CoreSharedRootModule> {
    return {
      ngModule: CoreSharedRootModule,
      providers: [

        CoreSharedGoogleMapsService,
        CoreSharedExportService,

        {
          provide: CORE_SHARED_ROOT_GUARD,
          useFactory: rootGuardFactory,
          deps: [[CORE_SHARED_ROOT_GUARD, new Optional(), new SkipSelf()]]
        },

        {
          provide: CORE_SHARED_ENVIRONMENT_INNER,
          useValue: environment
        },
        {
          provide: CORE_SHARED_ENVIRONMENT,
          useFactory: tokenOrValueFactory,
          deps: [Injector, CORE_SHARED_ENVIRONMENT_INNER]
        },

        {
          provide: CORE_SHARED_PRODUCT_INNER,
          useValue: product
        },
        {
          provide: CORE_SHARED_PRODUCT,
          useFactory: tokenOrValueFactory,
          deps: [Injector, CORE_SHARED_PRODUCT_INNER]
        }
      ]
    };
  }
}

export function rootGuardFactory(rootGuard: any): any {
  if (rootGuard) {
    throw new TypeError('CoreSharedModule.forRoot() called twice.');
  }

  return 'guarded';
}

export function tokenOrValueFactory<T>(
  injector: Injector,
  valueOrToken: T | InjectionToken<T>
): T {
  return valueOrToken instanceof InjectionToken ? injector.get(valueOrToken) : valueOrToken;
}
