import {AppPermissions} from '@nexnox-web/core-shared';
import {faTasks} from '@fortawesome/free-solid-svg-icons/faTasks';
import {faInbox} from '@fortawesome/free-solid-svg-icons/faInbox';
import {faBoxes} from '@fortawesome/free-solid-svg-icons/faBoxes';
import {faUserShield} from '@fortawesome/free-solid-svg-icons/faUserShield';
import {faCogs} from '@fortawesome/free-solid-svg-icons/faCogs';
import {faChartSimple} from "@fortawesome/free-solid-svg-icons/faChartSimple";
import {IconDefinition} from '@fortawesome/fontawesome-common-types';

export interface CorePortalFeatureModule {
  path: string;
  name: string;
  target?: string;
  icon: IconDefinition;
  permissions?: AppPermissions[];
  hide?: boolean;
}

export const CORE_PORTAL_FEATURE_MODULES: CorePortalFeatureModule[] = [
  { path: 'management', name: 'core-portal.core.header.modules.management', icon: faTasks },
  { path: 'communication', name: 'core-portal.core.header.modules.communication', icon: faInbox },
  { path: 'inventory', name: 'core-portal.core.header.modules.inventory', icon: faBoxes },
  {
    path: 'service',
    name: 'core-portal.core.header.modules.service',
    icon: faUserShield,
    permissions: [AppPermissions.UseExpiredSubscription]
  },
  {
    path: 'analytics',
    name: 'core-portal.core.header.modules.analytics',
    icon: faChartSimple,
    permissions: [AppPermissions.ShowReportForMaintenance, AppPermissions.ShowReportForPrime]
  },
  { path: 'settings', name: 'core-portal.core.header.modules.settings', icon: faCogs }
];
