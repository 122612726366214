<nexnox-web-cardbox [title]="'core-portal.settings.notification-rules.target' | translate"
                    [loading]="loading"
                    paddingClasses="px-3 pb-3">

  <div *ngIf="!readonly"
       class="mb-3">
    <form [formGroup]="targetForm">
      <formly-form class="row w-100"
                   [form]="targetForm"
                   [model]="targetModelSubject.asObservable() | async"
                   [fields]="targetFields"
                   (modelChange)="targetModelSubject.next($event)">
      </formly-form>
    </form>

    <div class="d-flex justify-content-end w-100">
      <button type="button"
              pButton
              [icon]="faPlus | nexnoxWebFaIconStringPipe"
              [title]="'core-shared.shared.actions.add' | translate"
              class="p-button-secondary p-button-outlined mr-2"
              [label]="'core-shared.shared.actions.add' | translate"
              [disabled]="targetForm.invalid"
              (click)="onAddTarget()">
      </button>
    </div>
  </div>

  <div class="pt-2 px-2 pb-3" *ngIf="localTargetItems$ | async as items">
    <nexnox-web-basic-table [config]="targetTableConfig"
                            [data]="items">
    </nexnox-web-basic-table>
  </div>

</nexnox-web-cardbox>
