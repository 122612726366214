<ng-template #entityEditTemplate let-title="title" let-id="id">
    <nexnox-web-settings-searchlists-searchlist-edit #modelComponent
                                                     [title]="title"
                                                     [id]="id"
                                                     [model]="model$ | async"
                                                     [readonly]="readonly$ | async"
                                                     [creating]="false"
                                                     [loading]="loading$ | async"
                                                     (modelChange)="onModelChange($event)">
    </nexnox-web-settings-searchlists-searchlist-edit>
</ng-template>

<nexnox-web-entity-detail [entityDetailBaseComponent]="this"
                          [entityEditTemplate]="entityEditTemplate"
                          [useTabs]="false">
</nexnox-web-entity-detail>
