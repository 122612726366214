import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {
  ApiNotificationService,
  AppEntityType,
  ApplyFromResourcesByLocationDto,
  AppPermissions,
  CombineOperator,
  CoreSharedSidebarBaseComponent,
  Filter,
  FilterOperators,
  FilterTypes,
  PreviewResourceByLocationDto,
  ResourceDto,
  StereotypeDto
} from '@nexnox-web/core-shared';
import {faUser} from '@fortawesome/free-solid-svg-icons/faUser';
import {FormGroup} from "@angular/forms";
import {FormlyFieldConfig} from "@ngx-formly/core";
import {
  CorePortalFormlyNgSelectOption,
  CorePortalFormlyReadonlyTypes,
  CorePortalFormlyReadonlyTyping,
  CorePortalPermissionService,
  CorePortalStereotypeService
} from "@nexnox-web/core-portal";
import {faTimes} from '@fortawesome/free-solid-svg-icons/faTimes';
import {BehaviorSubject, distinctUntilChanged, Observable, of, Subject} from "rxjs";
import {CorePortalFeatureMasterDataLocationService} from "./../../store";
import {filter, map, mergeMap} from "rxjs/operators";
import {cloneDeep} from "lodash";
import {
  CorePortalFeatureResourceInheritableService
} from "@nexnox-web/core-portal/features/resources/src/lib/services/resource-inheritable/resource-inheritable.service";

interface SkeletonModelDto {
  skeletons: PreviewResourceByLocationDto[]
}

@Component({
  selector: 'nexnox-web-location-create-resources-by-definition-sidebar',
  templateUrl: './create-resources-by-definition-sidebar.component.html',
  styles: ['.sidebar-form-container {overflow: auto; overflow-y: scroll}'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CreateResourcesByDefinitionSidebarComponent extends CoreSharedSidebarBaseComponent implements OnInit {

  @Input() public locationStereotypeId$: Observable<number>;
  @Input() public locationId: number | string;
  @Output() public refreshList: EventEmitter<boolean> = new EventEmitter<boolean>();

  public canCreate$: Observable<boolean>;
  public loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public skeletons$: Observable<PreviewResourceByLocationDto[]>;

  public stereotypeId: number;
  public itemsSubject: Subject<CorePortalFormlyNgSelectOption[]> = new Subject<CorePortalFormlyNgSelectOption[]>();
  public tableValidSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public resources: PreviewResourceByLocationDto[]

  public form: FormGroup;
  public model: SkeletonModelDto;
  public modelSubject: BehaviorSubject<SkeletonModelDto> = new BehaviorSubject<SkeletonModelDto>({skeletons: []});
  public fields: FormlyFieldConfig[];

  public faTimes = faTimes;
  public faUser = faUser;

  constructor(
    private permissionService: CorePortalPermissionService,
    private locationService: CorePortalFeatureMasterDataLocationService,
    private apiNotificationService: ApiNotificationService,
    public stereotypeService: CorePortalStereotypeService,
    public resourceInheritableService: CorePortalFeatureResourceInheritableService
  ) {
    super();
  }

  public ngOnInit(): void {
    this.loading$.next(true);
    this.form = new FormGroup({});
    this.fields = this.createForm();
    this.canCreate$ = this.permissionService.hasPermission$(AppPermissions.CreateResourcesByLocationDefinition);

    this.skeletons$ = this.modelSubject.asObservable().pipe(map(model => model.skeletons));

    this.subscribe(this.locationStereotypeId$.pipe(
      distinctUntilChanged(),
      filter(id => Boolean(id)),
      map(id => this.stereotypeId = id),
      mergeMap((id) => this.locationService.getPreviewResourcesByLocation(id))
    ), (preview) => {
      const items = preview.definitions.map((definition) => ({label: definition.title, value: definition.resources}));
      this.loading$.next(false);
      setTimeout(() => {
        this.itemsSubject.next(items);
        this.form.updateValueAndValidity();
      });

    });
  }

  public isCreateDisabled(): boolean {
    return (this.resources ?? []).length <= 0 || !this.tableValidSubject.getValue();
  }

  public onCreate(): void {
    this.loading$.next(true);
    this.subscribe(this.locationService.applyResources(this.mapResources(), this.locationId),
      (response) => {
        this.apiNotificationService.showTranslatedSuccess('core-shared.shared.toast.entity-added');
        this.refreshList.emit(true);
        this.loading$.next(false);
        this.onHide();
      },
      (error) => {
        this.apiNotificationService.handleApiError(error);
        this.loading$.next(false);
      }
    )
  }

  public onModelChange(model): void {
    this.modelSubject.next(model);
  }

  public onResourcesChange(resources): void {
    this.resources = resources;
  }

  public onShow(): void {
    this.ngOnInit();
    super.onShow();
  }

  public onHide(): void {
    this.modelSubject.next({skeletons: []});
    this.form.reset();
    super.onHide();
  }

  public mapResources(): ApplyFromResourcesByLocationDto {
    const resources = cloneDeep(this.modelSubject.getValue().skeletons ?? []);
    const mappedResources = [];
    for (let s = 0; s < resources.length; s++) {
      const resource = resources[s];
      mappedResources.push({
        ...resource,
        stereotypeId: resource?.stereotype?.stereotypeId,
        stereotypeRowVersion: resource?.stereotype?.rowVersion,
        stereotype: undefined
      });
    }
    return {resources: mappedResources};
  }

  /* istanbul ignore next */
  protected createForm(): FormlyFieldConfig[] {
    return [
      {
        key: 'skeletons',
        type: 'core-portal-ng-select',
        wrappers: ['core-portal-translated'],
        className: 'col-md-6',
        defaultValue: null,
        templateOptions: {
          corePortalTranslated: {
            label: '',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          },
          corePortalNgSelect: {
            items$: this.itemsSubject.asObservable()
          }
        }
      },
    ];
  }

  /* istanbul ignore next */
  public createSkeletonFields(): FormlyFieldConfig[] {
    return [
      {key: 'resourceSkeletonId', defaultValue: 0},
      {
        key: 'name',
        type: 'input',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-3',
        templateOptions: {
          required: true,
          corePortalTranslated: {
            label: 'core-shared.shared.fields.name',
            validationMessages: {
              required: 'core-portal.core.validation.required',
            },
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.BASIC
          },
          type: 'text'
        },
      },
      {
        key: 'stereotype',
        type: 'core-portal-entity-select',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-3',
        defaultValue: null,
        templateOptions: {
          required: true,
          corePortalTranslated: {
            label: 'core-shared.shared.fields.stereotype',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.ENTITY,
            displayKey: 'name',
            link: (stereotype: StereotypeDto) => stereotype?.stereotypeId ? ['stereotypes', stereotype.stereotypeId] : null,
            module: 'settings'
          } as CorePortalFormlyReadonlyTyping,
          entityService: this.stereotypeService,
          idKey: 'stereotypeId',
          displayKey: 'name',
          wholeObject: true,
          skipGetOne: true,
          clearable: true,
          defaultFilters$: of([{
            type: FilterTypes.Grouped,
            combinedAs: CombineOperator.And,
            children: [
              {
                property: 'entityType',
                type: FilterTypes.DataTransferObject,
                operator: FilterOperators.Equal,
                value: AppEntityType.Resource.toString()
              },
              {
                property: 'isArchived',
                type: FilterTypes.DataTransferObject,
                operator: FilterOperators.Equal,
                value: false.toString()
              }]
          }] as Filter[]),
          module: 'settings'
        }
      },
      {
        key: 'parent',
        type: 'core-portal-entity-select',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-3',
        defaultValue: null,
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.inherits-from'
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.ENTITY,
            displayKey: 'name',
            link: (resource: ResourceDto) => resource?.resourceId ? ['/resources', resource.resourceId] : null,
            module: 'inventory'
          } as CorePortalFormlyReadonlyTyping,
          entityService: this.resourceInheritableService,
          idKey: 'resourceId',
          displayKey: 'name',
          wholeObject: true,
          skipGetOne: true,
          clearable: true,
          module: 'inventory',
        }
      },
      {
        key: 'installationDate',
        type: 'core-portal-datepicker',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-3',
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.installation-date',
            validationMessages: {
              required: 'core-portal.core.validation.required',
            }
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.DATE,
            format: 'LL'
          } as CorePortalFormlyReadonlyTyping
        },
        hooks: {
          onInit: (field) => field.formControl.setValue(new Date())
        }
      },
    ];
  }
}
