import {TemplateContextType} from '@nexnox-web/core-shared';
import {CorePortalFormlyNgSelectOption} from '@nexnox-web/core-portal';

export const textContextTypeForMissionsEnumOptions: CorePortalFormlyNgSelectOption[] = [
  /* Mission Contexts */
  {
    label: 'core-portal.settings.template-context-types.6',
    value: TemplateContextType.MissionDescription
  },
  {
    label: 'core-portal.settings.template-context-types.1',
    readonlyLabel: 'core-portal.settings.template-context-types-readonly.1',
    depth: 1,
    value: TemplateContextType.MissionByTicketDescription
  },
  {
    label: 'core-portal.settings.template-context-types.7',
    readonlyLabel: 'core-portal.settings.template-context-types-readonly.7',
    depth: 1,
    value: TemplateContextType.MissionByInspectionDescription
  },
  {
    label: 'core-portal.settings.template-context-types.2',
    readonlyLabel: 'core-portal.settings.template-context-types-readonly.2',
    depth: 1,
    value: TemplateContextType.MissionByTaskDescription
  }
];

export const textContextTypeForSolutionsEnumOptions: CorePortalFormlyNgSelectOption[] = [
  /* Solution Note Contexts */
  {
    label: 'core-portal.settings.template-context-types.11',
    value: TemplateContextType.NoteBase
  },
  {
    label: 'core-portal.settings.template-context-types.3',
    readonlyLabel: 'core-portal.settings.template-context-types-readonly.3',
    depth: 1,
    value: TemplateContextType.NoteForTicket
  },
  {
    label: 'core-portal.settings.template-context-types.9',
    readonlyLabel: 'core-portal.settings.template-context-types-readonly.9',
    depth: 1,
    value: TemplateContextType.NoteForMission
  },
  {
    label: 'core-portal.settings.template-context-types.4',
    readonlyLabel: 'core-portal.settings.template-context-types-readonly.4',
    depth: 2,
    value: TemplateContextType.NoteForMissionByTicket
  },
  {
    label: 'core-portal.settings.template-context-types.10',
    readonlyLabel: 'core-portal.settings.template-context-types-readonly.10',
    depth: 2,
    value: TemplateContextType.NoteForMissionByInspection
  },
  {
    label: 'core-portal.settings.template-context-types.5',
    readonlyLabel: 'core-portal.settings.template-context-types-readonly.5',
    depth: 2,
    value: TemplateContextType.NoteForMissionByTask
  },
];

export const textContextTypeForNotificationsEnumOptions: CorePortalFormlyNgSelectOption[] = [
  /* Notification Contexts */
  {
    label: 'core-portal.settings.template-context-types.notification',
    value: TemplateContextType.InfoNotificationSubject,
  },
  {
    label: 'core-portal.settings.template-context-types.subject',
    value: TemplateContextType.InfoNotificationSubject,
    depth: 1
  },
  {
    label: 'core-portal.settings.template-context-types.17',
    value: TemplateContextType.InfoNotificationSubject,
    depth: 2
  },
  {
    label: 'core-portal.settings.template-context-types.15',
    value: TemplateContextType.MissionNotificationSubject,
    depth: 2
  },
  {
    label: 'core-portal.settings.template-context-types.16',
    value: TemplateContextType.TicketNotificationSubject,
    depth: 2
  },
  {
    label: 'core-portal.settings.template-context-types.content',
    value: TemplateContextType.InfoNotificationContent,
    depth: 1
  },
  {
    label: 'core-portal.settings.template-context-types.14',
    value: TemplateContextType.InfoNotificationContent,
    depth: 2
  },
  {
    label: 'core-portal.settings.template-context-types.12',
    value: TemplateContextType.MissionNotificationContent,
    depth: 2
  },
  {
    label: 'core-portal.settings.template-context-types.13',
    value: TemplateContextType.TicketNotificationContent,
    depth: 2
  }
];

export const textContextTypeForIssuesEnumOptions: CorePortalFormlyNgSelectOption[] = [
  /* Issue template Contexts */
  {
    label: 'core-portal.settings.template-context-types.issue',
    value: TemplateContextType.IssueEmailSubject,
  },
  {
    label: 'core-portal.settings.template-context-types.subject',
    value: TemplateContextType.IssueEmailSubject,
    depth: 1
  },
  {
    label: 'core-portal.settings.template-context-types.181',
    value: TemplateContextType.MessageIssueEmailSubject,
    depth: 2
  },
  {
    label: 'core-portal.settings.template-context-types.183',
    value: TemplateContextType.MissionIssueEmailSubject,
    depth: 2
  },
  {
    label: 'core-portal.settings.template-context-types.182',
    value: TemplateContextType.TicketIssueEmailSubject,
    depth: 2
  },
  {
    label: 'core-portal.settings.template-context-types.184',
    value: TemplateContextType.WinterhalterIssueEmailSubject,
    depth: 2
  },
  {
    label: 'core-portal.settings.template-context-types.content',
    value: TemplateContextType.IssueEmailContent,
    depth: 1
  },
  {
    label: 'core-portal.settings.template-context-types.191',
    value: TemplateContextType.MessageIssueEmailContent,
    depth: 2
  },
  {
    label: 'core-portal.settings.template-context-types.193',
    value: TemplateContextType.MissionIssueEmailContent,
    depth: 2
  },
  {
    label: 'core-portal.settings.template-context-types.192',
    value: TemplateContextType.TicketIssueEmailContent,
    depth: 2
  },
  {
    label: 'core-portal.settings.template-context-types.194',
    value: TemplateContextType.WinterhalterIssueEmailContent,
    depth: 2
  },
];

// All together for several detail pages that contain contextType dropdowns, eg. ticket-detail
export const textContextTypeEnumOptions: CorePortalFormlyNgSelectOption[] = [
  ...textContextTypeForMissionsEnumOptions,
  ...textContextTypeForSolutionsEnumOptions,
  ...textContextTypeForNotificationsEnumOptions,
  ...textContextTypeForIssuesEnumOptions
]
