import {EntityXsStoreActions} from './entity-xs-store.actions';
import {EntityXsStoreState} from './entity-xs-store.state';
import {on} from '@ngrx/store';
import {cloneDeep, isEmpty, isUndefined} from 'lodash';
import {Draft} from 'immer';
import {EntityXsStoreEntityData} from './models';
import {immerOn} from 'ngrx-immer/store';
import {BaseXsStoreReducerTypes} from '../base';
import {FilledCustomValueDto, ResourceDto} from "@nexnox-web/core-shared";

export const createEntityXsStoreReducer = <E extends Record<any, any>, M extends Record<any, any>>(
  actions: EntityXsStoreActions<E, M>,
  initialState: EntityXsStoreState<E, M>,
  previewFields: string[] = []
): BaseXsStoreReducerTypes<EntityXsStoreState<E, M>, EntityXsStoreActions<E, M>>[] => ([
  immerOn(actions.get, draft => {
    draft.loading = true;
  }),

  immerOn(actions.getSuccess, (draft, {entity, model}) => {
    draft.entity = cloneDeep(entity) as Draft<E>;
    draft.model = cloneDeep(model) as Draft<M>;
    draft.loading = false;
    draft.loaded = true;
  }),

  immerOn(actions.getStereotypes, draft => {
    draft.stereotypesLoading = true;
  }),

  immerOn(actions.getStereotypesSuccess, (draft, {stereotypes}) => {
    draft.stereotypes = stereotypes;
    draft.stereotypesLoading = false;
    draft.stereotypesLoaded = true;
  }),

  immerOn(actions.entityUpdate, (draft, {entity}) => {
    const storeCustomPropertyValues = (draft.entity as any)?.customPropertyValues;
    const customPropertyValues = (entity as any)?.customPropertyValues;
    draft.entity = cloneDeep(entity as Draft<E>);

    if (!isEmpty(draft.entity) && isUndefined(customPropertyValues) && !isUndefined(storeCustomPropertyValues)) {
      (draft.entity as any).customPropertyValues = storeCustomPropertyValues;
    }
  }),

  immerOn(actions.modelUpdate, (draft, {model}) => {
    const storeCustomPropertyValues = (draft.model as any)?.customPropertyValues;
    const customPropertyValues = (model as any)?.customPropertyValues;
    draft.model = cloneDeep(model as Draft<M>);

    if (!isEmpty(draft.model) && isUndefined(customPropertyValues) && !isUndefined(storeCustomPropertyValues)) {
      (draft.model as any).customPropertyValues = storeCustomPropertyValues;
    }
  }),

  immerOn(actions.modelResetSuccess, (draft, {model}) => {
    draft.model = cloneDeep(model) as Draft<M>;
  }),

  immerOn(actions.save, draft => {
    draft.loading = true;
  }),

  immerOn(actions.saveSuccess, (draft, {entity, model}) => {
    draft.entity = cloneDeep(entity) as Draft<E>;
    draft.model = cloneDeep(model) as Draft<M>;
    draft.loading = false;
  }),

  immerOn(actions.delete, draft => {
    draft.loading = true;
  }),

  immerOn(actions.deleteSuccess, draft => {
    draft.loading = false;
  }),

  immerOn(actions.error, draft => {
    draft.loading = false;
    draft.stereotypesLoading = false;
    draft.entityData = {loading: {}};
  }),

  on(actions.clear, () => initialState),

  immerOn(actions.createOneNative, draft => {
    draft.loading = true;
  }),
  immerOn(actions.createOneNativeSuccess, (draft, {entity, model}) => {
    draft.entity = cloneDeep(entity) as Draft<E>;
    draft.model = cloneDeep(model) as Draft<M>;
    draft.loading = false;
    draft.loaded = true;
  })
]);

export function entityXsStoreSetLoadingForAction(
  entityData: EntityXsStoreEntityData,
  loading: { [key: string]: boolean }
): EntityXsStoreEntityData {
  return {
    ...entityData,
    loading: {
      ...entityData.loading ?? {},
      ...loading
    }
  };
}

function mergeCustomPropertyValues(entity: ResourceDto, preview: ResourceDto): FilledCustomValueDto[] {
  const mergedCustomPropertyValues = [];

  // Parse to custom values
  for (const customPropertyValue of cloneDeep(preview.customPropertyValues)) {
    const oldCustomPropertyValue = entity?.customPropertyValues?.find(value => value.propertyId === customPropertyValue.propertyId);

    if (oldCustomPropertyValue) {
      for (const customValue of customPropertyValue.customValues) {
        const oldCustomValue = oldCustomPropertyValue.customValues.find(value => value.customValueId === customValue.customValueId);

        if (oldCustomValue?.customValue?.isInherited === false) {
          // Take old custom value
          customValue.customValue.isInherited = false;
          (customValue.customValue as any).ownValue = (oldCustomValue?.customValue as any)?.ownValue;
        }
      }
    }
    mergedCustomPropertyValues.push(customPropertyValue);
  }
  return mergedCustomPropertyValues;
}
