import {
  BaseXsStore,
  BaseXsStoreReducerTypes,
  createEntityXsStoreActions,
  EmptyAction,
  EntityXsStore,
  EntityXsStoreActions,
  EntityXsStoreState,
  PropsAction
} from '@nexnox-web/core-store';
import {createAction, props} from '@ngrx/store';
import {immerOn} from 'ngrx-immer/store';
import {
  MissionEditorQuestXsStoreMissionIdPayload,
  MissionEditorQuestXsStoreGetQuestSuccessPayload,
  MissionEditorQuestXsStoreGetSuggestionsPayload,
  MissionEditorQuestXsStoreGetSuggestionsSuccessPayload,
  MissionEditorQuestXsStoreStartQuestPayload,
  MissionEditorQuestXsStoreContactPayload,
  MissionEditorQuestXsStoreCommissionContactSuccessPayload
} from "./mission-editor-quest.xs-store.payloads";
import {cloneDeep} from "lodash";
import {LocalEditorQuestDto} from "./mission-editor-quest.store";
import {empty} from "rxjs";

export interface MissionEditorQuestXsStoreActions extends EntityXsStoreActions<LocalEditorQuestDto> {
  getQuest: PropsAction<MissionEditorQuestXsStoreMissionIdPayload>;
  getQuestSuccess: PropsAction<MissionEditorQuestXsStoreGetQuestSuccessPayload>;

  getSuggestions: PropsAction<MissionEditorQuestXsStoreGetSuggestionsPayload>;
  getSuggestionsSuccess: PropsAction<MissionEditorQuestXsStoreGetSuggestionsSuccessPayload>;

  startQuest: PropsAction<MissionEditorQuestXsStoreStartQuestPayload>;
  startQuestSuccess: PropsAction<MissionEditorQuestXsStoreMissionIdPayload>;

  cancelQuest: PropsAction<MissionEditorQuestXsStoreMissionIdPayload>;
  cancelQuestSuccess: PropsAction<MissionEditorQuestXsStoreMissionIdPayload>;

  acceptContact: PropsAction<MissionEditorQuestXsStoreContactPayload>;
  acceptContactSuccess: PropsAction<MissionEditorQuestXsStoreMissionIdPayload>;

  declineContact: PropsAction<MissionEditorQuestXsStoreContactPayload>;
  declineContactSuccess: PropsAction<MissionEditorQuestXsStoreMissionIdPayload>;

  commissionContact: PropsAction<MissionEditorQuestXsStoreContactPayload>;
  commissionContactSuccess: PropsAction<MissionEditorQuestXsStoreCommissionContactSuccessPayload>;
}

export class MissionEditorQuestXsStore extends EntityXsStore<LocalEditorQuestDto> {
  public actions: MissionEditorQuestXsStoreActions;

  protected createActions(label: string): MissionEditorQuestXsStoreActions {
    return {
      ...createEntityXsStoreActions(label),

      getQuest: createAction(
        BaseXsStore.getType(label, 'Get quest'),
        props<MissionEditorQuestXsStoreMissionIdPayload>()
      ),

      getQuestSuccess: createAction(
        BaseXsStore.getType(label, 'Get quest success'),
        props<MissionEditorQuestXsStoreGetQuestSuccessPayload>()
      ),

      getSuggestions: createAction(
        BaseXsStore.getType(label, 'Get suggestions'),
        props<MissionEditorQuestXsStoreGetSuggestionsPayload>()
      ),

      getSuggestionsSuccess: createAction(
        BaseXsStore.getType(label, 'Get suggestions success'),
        props<MissionEditorQuestXsStoreGetSuggestionsSuccessPayload>()
      ),

      startQuest: createAction(
        BaseXsStore.getType(label, 'Start quest'),
        props<MissionEditorQuestXsStoreStartQuestPayload>()
      ),

      startQuestSuccess: createAction(
        BaseXsStore.getType(label, 'Start quest success'),
        props<MissionEditorQuestXsStoreMissionIdPayload>()
      ),

      cancelQuest: createAction(
        BaseXsStore.getType(label, 'Cancel quest'),
        props<MissionEditorQuestXsStoreMissionIdPayload>()
      ),

      cancelQuestSuccess: createAction(
        BaseXsStore.getType(label, 'Cancel quest success'),
        props<MissionEditorQuestXsStoreMissionIdPayload>()
      ),

      acceptContact: createAction(
        BaseXsStore.getType(label, 'Accept contact'),
        props<MissionEditorQuestXsStoreContactPayload>()
      ),

      acceptContactSuccess: createAction(
        BaseXsStore.getType(label, 'Accept contact success'),
        props<MissionEditorQuestXsStoreMissionIdPayload>()
      ),

      declineContact: createAction(
        BaseXsStore.getType(label, 'Decline contact'),
        props<MissionEditorQuestXsStoreContactPayload>()
      ),

      declineContactSuccess: createAction(
        BaseXsStore.getType(label, 'Decline contact success'),
        props<MissionEditorQuestXsStoreMissionIdPayload>()
      ),

      commissionContact: createAction(
        BaseXsStore.getType(label, 'Commission contact'),
        props<MissionEditorQuestXsStoreContactPayload>()
      ),

      commissionContactSuccess: createAction(
        BaseXsStore.getType(label, 'Commission contact success'),
        props<MissionEditorQuestXsStoreCommissionContactSuccessPayload>()
      ),
    };
  }

  protected createReducerArray(
    initialState: EntityXsStoreState<LocalEditorQuestDto>
  ): BaseXsStoreReducerTypes<EntityXsStoreState<LocalEditorQuestDto>, MissionEditorQuestXsStoreActions>[] {
    return [
      ...super.createReducerArray(initialState),

      immerOn(this.actions.getQuest, draft => {
        draft.loading = true;
      }),

      immerOn(this.actions.getQuestSuccess, (draft, {quest}) => {
        draft.entity = {...draft.entity, quest: cloneDeep(quest)};
        draft.model = {...draft.model, quest: cloneDeep(quest)};
        draft.loading = false;
      }),

      immerOn(this.actions.getSuggestions, draft => {
        draft.loading = true;
      }),

      immerOn(this.actions.getSuggestionsSuccess, (draft, {suggestions}) => {
        draft.entity = {...draft.entity, editors: cloneDeep(suggestions)};
        draft.model = {...draft.model, editors: cloneDeep(suggestions)};
        draft.loading = false;
      }),

      immerOn(this.actions.startQuest, draft => {
        draft.loading = true;
      }),

      immerOn(this.actions.startQuestSuccess, (draft) => {
        draft.loading = false;
      }),

      immerOn(this.actions.cancelQuest, draft => {
        draft.loading = true;
      }),

      immerOn(this.actions.cancelQuestSuccess, (draft) => {
        draft.loading = false;
      }),

      immerOn(this.actions.acceptContact, draft => {
        draft.loading = true;
      }),

      immerOn(this.actions.acceptContactSuccess, (draft) => {
        draft.loading = false;
      }),

      immerOn(this.actions.declineContact, draft => {
        draft.loading = true;
      }),

      immerOn(this.actions.declineContactSuccess, (draft) => {
        draft.loading = false;
      }),

      immerOn(this.actions.commissionContact, draft => {
        draft.loading = true;
      }),

      immerOn(this.actions.commissionContactSuccess, (draft) => {
        draft.loading = false;
      })
    ];
  }
}
