import { PagedEntitiesXsStore, PagedEntitiesXsStoreState } from '@nexnox-web/core-store';
import { AppEntityType, CategoryDto } from '@nexnox-web/core-shared';
import { createSelector } from '@ngrx/store';
import { selectCategoriesState } from '../../categories.selectors';
import { OrgaPortalFeatureCategoryService } from '../../services';

export interface CategoryListStoreState extends PagedEntitiesXsStoreState<CategoryDto> {
}

/* istanbul ignore next */
export const categoryListStore = new PagedEntitiesXsStore<CategoryDto>({
  actionLabel: 'Orga Portal - Categories - Category List',
  stateSelector: createSelector(selectCategoriesState, state => state.categoryList),
  serviceType: OrgaPortalFeatureCategoryService,
  entityType: AppEntityType.None,
  selectId: category => category.entity?.categoryId ?? category.model?.categoryId,
  stereotyped: false
});
