import {PagedEntitiesXsStore, PagedEntitiesXsStoreState} from '@nexnox-web/core-store';
import {AppEntityType, TicketsByLocationDefinitionListDto} from '@nexnox-web/core-shared';
import {createSelector} from '@ngrx/store';
import {selectStereotypesState} from '../../stereotypes.selector';
import {TicketsByLocationStereotypeService} from './../../services';

export interface TicketsByLocationStereotypeListStoreState extends PagedEntitiesXsStoreState<TicketsByLocationDefinitionListDto> {
}

/* istanbul ignore next */
export const ticketsByLocationStereotypeListStore = new PagedEntitiesXsStore<TicketsByLocationDefinitionListDto>({
  actionLabel: 'Core Portal - Settings - Stereotypes - Tickets By Location Stereotype List',
  stateSelector: createSelector(selectStereotypesState, state => state.ticketsByLocationStereotypeList),
  serviceType: TicketsByLocationStereotypeService,
  entityType: AppEntityType.None,
  stereotyped: false,
  selectId: item => item?.entity?.ticketsByLocationDefinitionId ?? item?.model?.ticketsByLocationDefinitionId
});
