import { Injectable, Injector } from '@angular/core';
import { CorePortalContactService, CorePortalEmbeddedEntityBaseService } from '@nexnox-web/core-portal';
import { Product } from '@nexnox-web/core-shared';

@Injectable()
export class FunctionContactService extends CorePortalEmbeddedEntityBaseService {
  constructor(
    protected injector: Injector
  ) {
    super(injector, CorePortalContactService, Product.TECH, 'contact', ['function']);
  }
}
