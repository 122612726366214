import {BaseXsStoreReducerTypes, EntityXsStore, EntityXsStoreState} from '@nexnox-web/core-store';
import {ResourceManufacturerDto} from '@nexnox-web/core-shared';
import {createModelDetailXsStoreActions, ModelDetailXsStoreActions} from './model-detail-xs-store.actions';
import {createModelDetailXsStoreReducer} from './model-detail-xs-store.reducer';

export class ModelDetailXsStore extends EntityXsStore<ResourceManufacturerDto> {
  public actions: ModelDetailXsStoreActions;

  protected createActions(label: string): ModelDetailXsStoreActions {
    return createModelDetailXsStoreActions(label);
  }

  protected createReducerArray(
    initialState: EntityXsStoreState<ResourceManufacturerDto>
  ): BaseXsStoreReducerTypes<EntityXsStoreState<ResourceManufacturerDto>, ModelDetailXsStoreActions>[] {
    return [
      ...super.createReducerArray(initialState),
      ...createModelDetailXsStoreReducer(this.actions, initialState)
    ];
  }
}
