import { NgModule } from '@angular/core';
import { CorePortalAttachmentsModule, CorePortalEntityModule } from '@nexnox-web/core-portal';
import { CorePortalFeatureAuditModule } from '@nexnox-web/core-portal/features/audit';
import { CorePortalFeatureMasterDataContactsModule } from '@nexnox-web/core-portal/features/master-data/features/contacts';
import { CorePortalFeatureMasterDataFunctionsModule } from '@nexnox-web/core-portal/features/master-data/features/functions';
import { CorePortalFeatureMasterDataLocationGroupsModule } from '@nexnox-web/core-portal/features/master-data/features/location-groups';
import { CorePortalFeatureMasterDataLocationsModule } from '@nexnox-web/core-portal/features/master-data/features/locations';
import { CoreSharedModule } from '@nexnox-web/core-shared';
import { TechPortalModule } from '@nexnox-web/tech-portal-lib';
import { TechPortalFeatureMissionsModule } from '@nexnox-web/tech-portal/features/missions';
import { TechPortalFeatureContractEditComponent } from './components';
import {
  ContractAuditComponent,
  ContractDetailComponent,
  ContractTicketsComponent,
  TechPortalFeatureContractListComponent
} from './containers';
import { contractsServices } from './store';

@NgModule({
  declarations: [
    TechPortalFeatureContractListComponent,
    ContractDetailComponent,
    ContractAuditComponent,
    ContractTicketsComponent,

    TechPortalFeatureContractEditComponent
  ],
  imports: [
    CoreSharedModule,
    CorePortalEntityModule,
    TechPortalModule,
    CorePortalAttachmentsModule,

    CorePortalFeatureMasterDataContactsModule.forFeature(),
    CorePortalFeatureMasterDataFunctionsModule.forFeature(),
    CorePortalFeatureMasterDataLocationsModule.forFeature(),
    CorePortalFeatureMasterDataLocationGroupsModule.forFeature(),
    TechPortalFeatureMissionsModule.forFeature(),
    CorePortalFeatureAuditModule
  ],
  providers: [
    ...contractsServices
  ],
  exports: [
    TechPortalFeatureContractEditComponent
  ]
})
export class ContractsDeclarationsModule {
}
