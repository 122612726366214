import {IsActiveMatchOptions, NavigationExtras, Router, UrlCreationOptions,
  UrlTree
} from '@angular/router';
import {Injectable} from '@angular/core';

import {CorePortalCurrentTenantService} from '../../services/current-tenant/current-tenant.service';
import {flatten, isString} from 'lodash';
import {CorePortalCurrentModuleService} from '../../services/current-module/current-module.service';

export interface CorePortalTenantRouterExtras {
  module?: string;
  withoutModule?: boolean;
}

export type CorePortalTenantRouterNavigationExtras = NavigationExtras & CorePortalTenantRouterExtras;
export type CorePortalTenantRouterUrlCreationOptions = UrlCreationOptions & CorePortalTenantRouterExtras;

@Injectable()
export class CorePortalTenantRouter {
  constructor(
    private router: Router,
    private currentTenantService: CorePortalCurrentTenantService,
    private currentModuleService: CorePortalCurrentModuleService
  ) {
  }

  public navigate(commands: any[], extras?: CorePortalTenantRouterNavigationExtras): Promise<boolean> {
    commands = this.mapCommands(commands);

    if (!extras?.withoutModule) {
      const module = extras?.module ?? this.currentModuleService.getModule();
      commands = [module, ...commands];
    }

    return this.router.navigate(['/tenant', this.currentTenantService.getTenantDomain(), ...commands], extras);
  }

  // ToDo: Implement
  // public navigateByUrl(url: string | UrlTree, extras?: CorePortalTenantRouterNavigationExtras): Promise<boolean> {
  //   throw new Error('NotImplemented');
  // }

  public createUrlTree(commands: any[], navigationExtras?: CorePortalTenantRouterUrlCreationOptions): UrlTree {
    commands = this.mapCommands(commands);

    if (!navigationExtras?.withoutModule) {
      const module = navigationExtras?.module ?? this.currentModuleService.getModule();
      commands = [module, ...commands];
    }

    return this.router.createUrlTree(['/tenant', this.currentTenantService.getTenantDomain(), ...commands], navigationExtras);
  }

  public createCommands(commands: any[], extras?: CorePortalTenantRouterExtras): any[] {
    commands = this.mapCommands(commands);

    if (!extras?.withoutModule) {
      const module = extras?.module ?? this.currentModuleService.getModule();
      commands = [module, ...commands];
    }

    return ['/tenant', this.currentTenantService.getTenantDomain(), ...commands];
  }

  public isActive(modulePath: string | UrlTree, exactOrOptions: boolean | IsActiveMatchOptions): boolean {
    const newOptions: IsActiveMatchOptions = typeof exactOrOptions === 'boolean' ? {
      matrixParams: exactOrOptions ? 'exact' : 'ignored',
      queryParams: exactOrOptions ? 'exact' : 'ignored',
      paths: exactOrOptions ? 'exact' : 'subset',
      fragment: exactOrOptions ? 'exact' : 'ignored',
    } : exactOrOptions;
    const path = `tenant/${this.currentTenantService.getTenantDomain()}/${this.currentModuleService.getModule()}${modulePath}`;
    return this.router.isActive(path, newOptions);
  }

  private mapCommands(commands: any[]): any[] {
    if (commands.length) {
      if (isString(commands[0]?.toString()) && commands[0].toString().startsWith('/')) {
        commands[0] = commands[0].toString().substring(1);
      }

      for (let i = 0; i < commands.length; i++) {
        if (isString(commands[i]?.toString()) && commands[i].toString().includes('/')) {
          commands[i] = commands[i].toString().split('/');
        }
      }

      commands = flatten(commands).filter(command => Boolean(command));
    }

    return commands;
  }
}
