import {PagedEntitiesXsStore, PagedEntitiesXsStoreState} from '@nexnox-web/core-store';
import {AppEntityType, ControlListDto} from '@nexnox-web/core-shared';
import {Action, ActionReducer, createSelector} from '@ngrx/store';
import {selectControlsState} from '../../controls.selectors';
import {TechPortalFeatureControlService} from '../../services';
import {Injectable, Injector} from '@angular/core';

export interface ControlListStoreState extends PagedEntitiesXsStoreState<ControlListDto> {
}

/* istanbul ignore next */
export const controlListStore = new PagedEntitiesXsStore<ControlListDto>({
  actionLabel: 'Core Portal - Controls - Control List',
  stateSelector: createSelector(selectControlsState, state => state.controlList),
  serviceType: TechPortalFeatureControlService,
  entityType: AppEntityType.None,
  selectId: control => control.entity?.controlId ?? control.model?.controlId
});

export function controlListStoreReducer(state: ControlListStoreState, action: Action): ActionReducer<any> {
  return controlListStore.reducer(state, action);
}

@Injectable()
export class ControlListStoreEffects extends controlListStore.effects {
  constructor(
    protected injector: Injector
  ) {
    super(injector);
  }
}
