import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {UnsubscribeHelper} from '@nexnox-web/core-shared';
import {ActionButton} from './action-button.model';

@Injectable({
	providedIn: 'root'
})
export class CorePortalActionBarService extends UnsubscribeHelper {
	public actions$: Observable<ActionButton[]>;

	private actions: BehaviorSubject<ActionButton[]> = new BehaviorSubject<ActionButton[]>([]);

	constructor() {
		super();
		this.actions$ = this.actions.asObservable();
	}

	public setActions(actions: ActionButton[]): void {
		this.actions.next(actions?.length ? actions : []);
	}

	public reset(): void {
		this.actions.next([]);
	}
}
