import { RouterModule, Routes } from '@angular/router';
import { ContractDetailComponent, TechPortalFeatureContractListComponent } from './containers';
import { NgModule } from '@angular/core';
import {AppPermissions, I18nBreadcrumbResolver, UnsavedChangesGuard, UnsavedCreateGuard} from '@nexnox-web/core-shared';
import { CorePortalPermissionGuard } from '@nexnox-web/core-portal';

export const TECH_PORTAL_CONTRACTS_ROUTES: Routes = [
  {
    path: '',
    canActivate: [CorePortalPermissionGuard],
    canDeactivate: [UnsavedCreateGuard],
    component: TechPortalFeatureContractListComponent,
    data: {
      permissions: [AppPermissions.DisplayContracts]
    }
  },
  {
    path: ':contractId',
    component: ContractDetailComponent,
    canActivate: [CorePortalPermissionGuard],
    canDeactivate: [UnsavedChangesGuard],
    data: {
      permissions: [AppPermissions.ReadContract, AppPermissions.ReadStereotype],
      breadcrumbs: I18nBreadcrumbResolver,
      tab: 'contract',
      text: 'core-portal.core.header.breadcrumbs.contracts.contract-detail'
    },
    children: [
      {
        path: 'links',
        data: {
          tab: 'links'
        },
        children: []
      },
      {
        path: 'attachments',
        data: {
          tab: 'attachments'
        },
        children: []
      },
      {
        path: 'responsibility',
        data: {
          tab: 'responsibility'
        },
        children: []
      },
      {
        path: 'audit',
        data: {
          tab: 'audit'
        },
        children: []
      },
      {
        path: 'tickets',
        data: {
          tab: 'tickets'
        },
        children: []
      }
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(TECH_PORTAL_CONTRACTS_ROUTES)
  ],
  exports: [
    RouterModule
  ]
})
export class ContractsRoutingModule {
}
