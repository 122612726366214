import {AppEntityType} from '@nexnox-web/core-shared';
import {selectControlsState} from '../../controls.selectors';
import {Action, ActionReducer, createSelector} from '@ngrx/store';
import {TechPortalFeatureControlService} from '../../services';
import {Injectable, Injector} from '@angular/core';
import {ControlDetailXsStore, ControlDetailXsStoreActions, ControlDetailXsStoreState} from './control-detail.xs-store';

export interface ControlDetailStoreState extends ControlDetailXsStoreState {
}

export const controlDetailStore = new ControlDetailXsStore({
  actionLabel: 'Core Portal - Controls - Control Detail',
  stateSelector: createSelector(selectControlsState, state => state.controlDetail),
  entityType: AppEntityType.None,
  serviceType: TechPortalFeatureControlService,
  inheritance: false,
  stereotyped: false
});

export function controlDetailStoreReducer(state: ControlDetailStoreState, action: Action): ActionReducer<any> {
  return controlDetailStore.reducer(state, action);
}

@Injectable()
export class ControlDetailStoreEffects extends controlDetailStore.effects {
  protected declare actions: ControlDetailXsStoreActions;
  protected declare service: TechPortalFeatureControlService;

  constructor(
    protected injector: Injector
  ) {
    super(injector);
  }
}
