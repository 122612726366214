import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { CorePortalTenantRouter } from '../../router-overrides';
import { AppPermissions } from '@nexnox-web/core-shared';
import { CorePortalPermissionService } from '../../services';
import { forkJoin, Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { filter, map, switchMap, take } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { authStore } from '../../store';

export interface DynamicDefaultRoute {
  path: string;
  permissions: AppPermissions[];
}

@Injectable()
export class CorePortalDynamicDefaultRouteGuard implements CanActivate {
  constructor(
    private store: Store<any>,
    private tenantRouter: CorePortalTenantRouter,
    private permissionService: CorePortalPermissionService
  ) {
  }

  public canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    const defaultRoutes: DynamicDefaultRoute[] = route.routeConfig.data.defaultRoutes ?? [];

    return this.store.pipe(
      select(authStore.selectors.selectActiveTenant),
      filter(activeTenant => Boolean(activeTenant)),
      take(1),
      switchMap(() => forkJoin([...defaultRoutes.map(defaultRoute => of(defaultRoute).pipe(
        switchMap(defaultRoute => forkJoin([...defaultRoute.permissions.map(permission => of(permission).pipe(
          switchMap(permission => this.permissionService.hasPermission$(permission).pipe(take(1)))
        )), of(true)])),
        map(permissions => ({ path: defaultRoute.path, permission: permissions.every(x => Boolean(x)) }))
      )), of({ path: null, permissions: false } as any)]).pipe(
        map(routes => {
          const validRoutes = routes.filter(route => route.permission);
          return validRoutes.length ? validRoutes[0] : null;
        }),
        map(validRoute => {
          if (!validRoute?.path) {
            this.tenantRouter.navigate(['/'], { withoutModule: true });
            return false;
          }

          const lastUrlParts = route.pathFromRoot.slice(2, route.pathFromRoot.length - 1).map(x => x.url[0]?.path);
          this.tenantRouter.navigate([...lastUrlParts, validRoute.path], { withoutModule: true });
          return false;
        })
      ))
    );
  }
}
