import { PagedEntitiesXsStore, PagedEntitiesXsStoreState } from '@nexnox-web/core-store';
import {AppEntityType, TaskJobDto, TaskJobListDto} from '@nexnox-web/core-shared';
import { createSelector } from '@ngrx/store';
import { selectTasksState } from '../../tasks.selectors';
import { TaskjobArchiveService } from '../../services';

export interface TaskArchiveListStoreState extends PagedEntitiesXsStoreState<TaskJobListDto> {
}

/* istanbul ignore next */
export const taskArchiveListStore = new PagedEntitiesXsStore<TaskJobListDto>({
  actionLabel: 'Orga Portal - Tasks - Task Archive List',
  stateSelector: createSelector(selectTasksState, state => state.taskArchiveList),
  serviceType: TaskjobArchiveService,
  entityType: AppEntityType.TaskJob,
  selectId: taskJob => taskJob.entity?.taskJobId ?? taskJob.model?.taskJobId,
  stereotyped: false
});
