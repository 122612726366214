import { TechPortalFeatureConnectedErrorCodeService } from './services';
import { errorCodeDetailStore, errorCodeListStore } from './stores';

export * from './error-codes.selectors';
export * from './error-codes.state';

export * from './services';
export * from './stores';

export const errorCodeReducers = {
  errorCodeList: errorCodeListStore.reducer,
  errorCodeDetail: errorCodeDetailStore.reducer
};

export const errorCodeEffects = [
  errorCodeListStore.effects,
  errorCodeDetailStore.effects
];

export const errorCodeServices = [
  TechPortalFeatureConnectedErrorCodeService
];
