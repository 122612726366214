import { InjectionToken, NgModule } from '@angular/core';
import { ActionReducerMap, StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { errorCodeEffects, errorCodeReducers } from './store';

export const REDUCER_TOKEN = new InjectionToken<ActionReducerMap<any>>('@nexnox-web/tech-portal/connected/error-codes: Store Reducers');

@NgModule({
  imports: [
    StoreModule.forFeature('tech-portal-feature-connected-error-codes', REDUCER_TOKEN),
    EffectsModule.forFeature(errorCodeEffects)
  ],
  providers: [
    {
      provide: REDUCER_TOKEN,
      useValue: errorCodeReducers
    }
  ]
})
export class ErrorCodesStoreModule {
}
