import {ChangeDetectionStrategy, Component, Injector} from '@angular/core';
import {
  ActionButton,
  CorePortalEntityOverviewBaseComponent,
  DatatableActionButton,
  DeleteEntityModel
} from '@nexnox-web/core-portal';
import {AppEntityType, AppPermissions, ControllerOperationId, ControlTagDto, Mappers} from '@nexnox-web/core-shared';
import {controlTagListStore} from '../../store';

@Component({
  selector: 'nexnox-web-ccp-control-tag-list',
  templateUrl: './control-tag-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ControlTagListComponent extends CorePortalEntityOverviewBaseComponent<ControlTagDto> {
  public title = 'tech-portal.ccp.control-tags.subtitles.list';
  public createTitle = 'tech-portal.ccp.control-tags.actions.create';
  public idProperty = 'controlTagId';
  public displayProperty = 'name';
  public datatableConfigName = 'ControlTagDefault';
  public componentId = 'ControlTagListComponent';
  public enableViews = true;
  public pageOperation = ControllerOperationId.ControlTagControllerList;

  constructor(
    protected injector: Injector
  ) {
    super(injector, controlTagListStore, Mappers.ControlTagListDto.serializedName, AppEntityType.None);
  }

  public getRowActionButtons(): DatatableActionButton[] {
    return this.getDefaultRowActionButtons(
      'tech-portal.ccp.control-tags.actions.edit',
      (row: ControlTagDto) => `ccp/control-tags/${row.controlTagId}`,
      [AppPermissions.UpdateControlTag],
      {
        module: 'settings'
      }
    );
  }

  public getDeleteEntityModel(): DeleteEntityModel {
    return {
      titleKey: 'tech-portal.ccp.control-tags.actions.delete',
      descriptionKey: 'tech-portal.ccp.control-tags.descriptions.delete',
      deletePermission: AppPermissions.DeleteControlTag
    };
  }

  protected async getActionButtons(): Promise<ActionButton[]> {
    return this.getDefaultActionButtons('tech-portal.ccp.control-tags.actions.create', AppPermissions.CreateControlTag);
  }
}
