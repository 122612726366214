import { CoreSharedHttpRequestService } from '../http-request/http-request.service';
import { Inject, Injectable } from '@angular/core';
import { HttpApiClient, HttpAuthorizedClient } from '../clients';
import { CORE_SHARED_ENVIRONMENT } from '../../../tokens';
import { Environment, Product } from '../../../models';

@Injectable()
export class CoreSharedHttpTechRequestService extends CoreSharedHttpRequestService {
  constructor(
    protected httpAuthorizedClient: HttpAuthorizedClient,
    protected httpApiClient: HttpApiClient,
    @Inject(CORE_SHARED_ENVIRONMENT) protected environment: Environment
  ) {
    super(httpAuthorizedClient, httpApiClient, Product.TECH, environment);
  }
}
