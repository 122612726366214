import {ChangeDetectionStrategy, Component, Injector, Input, OnInit} from '@angular/core';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons/faPencilAlt';
import { CorePortalCardboxAction, CorePortalEntityOverviewBaseComponent, DatatableActionButton, DeleteEntityModel } from '@nexnox-web/core-portal';
import {
  AppEntityType,
  AppPermissions,
  AreaAssignmentDto,
  ContactSimpleDto,
  Mappers,
  MissionSimpleDto, OpenEditorRequestOnContactListDto
} from '@nexnox-web/core-shared';
import { Observable } from 'rxjs';
import {contactAreaListStore, contactOpenRequestListStore} from '../../store';

@Component({
  selector: 'nexnox-web-master-data-contacts-contact-open-request-list',
  templateUrl: './contact-open-request-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContactOpenRequestListComponent extends CorePortalEntityOverviewBaseComponent<OpenEditorRequestOnContactListDto> implements OnInit {
  public title = 'core-portal.master-data.subtitles.contact-open-requests';
  public idProperty = 'missionId';
  public displayProperty = 'mission';
  public datatableConfigName = 'MasterDataContactOpenEditorRequestDefault';
  public componentId = 'ContactOpenRequestListComponent';
  public canCreate = false;

  constructor(
    protected injector: Injector
  ) {
    super(injector, contactOpenRequestListStore, Mappers.OpenEditorRequestOnContactListDto.serializedName, AppEntityType.None);
  }

  public ngOnInit():void {
    super.ngOnInit();
  }
}
