import { AfterViewInit, ChangeDetectionStrategy, Component, Injector, Input, OnInit, ViewChild } from '@angular/core';
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons/faEllipsisV';
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import { faSave } from '@fortawesome/free-solid-svg-icons/faSave';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import {
  ActionButton,
  CorePortalCardboxAction,
  CorePortalEntityDetailBaseComponent,
  CorePortalPermissionService
} from '@nexnox-web/core-portal';
import { AppPermissions, MissionDto, MissionReportDto, MissionState } from '@nexnox-web/core-shared';
import { ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { BindObservable } from 'bind-observable';
import { isNull } from 'lodash';
import { MenuItem } from 'primeng/api';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { delay, distinctUntilChanged, map, switchMap } from 'rxjs/operators';
import { TechPortalFeatureMissionReportEditComponent } from '../../components';
import { missionReportStore, MissionReportXsStore } from '../../store';

@Component({
  selector: 'nexnox-web-missions-mission-report',
  templateUrl: './mission-report.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MissionReportComponent extends CorePortalEntityDetailBaseComponent<MissionReportDto> implements OnInit, AfterViewInit {
  @Input() public missionId: number;

  @Input() @BindObservable() public mission: MissionDto;
  public mission$: Observable<MissionDto>;

  public get reportId(): number {
    return this.reportIdSubject.getValue();
  }

  @Input()
  public set reportId(reportId: number) {
    this.reportIdSubject.next(reportId);
    this.id = reportId;
  }

  @ViewChild('createComponent') public createComponent: TechPortalFeatureMissionReportEditComponent;

  public title = 'missions.subtitles.mission-report';

  public creatingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public createModelSubject: BehaviorSubject<MissionReportDto> = new BehaviorSubject<MissionReportDto>({});

  public createHeaderActions: CorePortalCardboxAction[] = [];
  public editHeaderActions: CorePortalCardboxAction[] = [];

  public faTimes = faTimes;

  protected idParam = 'missionReportId';
  protected displayKey = 'missionReportId';
  protected useParamMap = false;
  protected initialGet = false;

  protected entityStore: MissionReportXsStore;

  private reportIdSubject: BehaviorSubject<number> = new BehaviorSubject<number>(undefined);

  /* istanbul ignore next */
  constructor(
    protected injector: Injector,
    private translate: TranslateService,
    private permissionService: CorePortalPermissionService
  ) {
    super(injector, missionReportStore);

    this.createHeaderActions = [
      {
        label: 'missions.actions.create-mission-report',
        icon: faPlus,
        class: 'btn-outline-primary',
        isDisabled: () => this.createModelSubject.asObservable().pipe(
          delay(1),
          map(() => !this.createComponent?.isModelValid())
        ),
        isLoading: () => this.loading$,
        permission: AppPermissions.CreateMissionReport,
        callback: () => this.onCreate()
      },
      {
        label: 'missions.actions.finish-mission',
        icon: faCheck,
        class: 'btn-outline-primary',
        shouldShow: () => this.mission$.pipe(
          map(mission =>
            mission?.state !== MissionState.Done && mission?.state !== MissionState.Canceled
          )
        ),
        isDisabled: () => this.createModelSubject.asObservable().pipe(
          delay(1),
          map(() => !this.createComponent?.isModelValid())
        ),
        isLoading: () => this.loading$,
        permission: AppPermissions.CreateMissionReport,
        callback: () => this.onCreateAndFinish()
      }
    ];

    this.editHeaderActions = [
      {
        label: 'missions.actions.save-mission-report',
        icon: faSave,
        class: 'btn-outline-primary',
        shouldShow: () => this.readonly$.pipe(
          map(readonly => !readonly)
        ),
        isDisabled: () => this.shouldDisablePrimaryAction(),
        isLoading: () => this.loading$,
        permission: AppPermissions.UpdateMissionReport,
        callback: () => this.onSaveAction()
      },
      {
        label: 'missions.actions.finish-mission',
        icon: faCheck,
        class: 'btn-outline-primary',
        shouldShow: () => this.readonly$.pipe(
          switchMap(readonly => this.mission$.pipe(
            map(mission =>
              !readonly &&
              mission?.state !== MissionState.Done && mission?.state !== MissionState.Canceled
            )
          ))
        ),
        isDisabled: () => this.shouldDisablePrimaryAction(),
        isLoading: () => this.loading$,
        permission: AppPermissions.UpdateMissionReport,
        callback: () => this.onSaveAndFinish()
      },
      {
        class: 'btn-outline-secondary',
        icon: faEllipsisV,
        tooltip: 'core-portal.core.general.more',
        shouldShow: () => this.readonly$.pipe(
          map(readonly => !readonly)
        ),
        isLoading: () => this.loading$,
        dropdownItems: () => combineLatest([
          of(null),
          this.translate.stream('missions.actions.delete-mission-report').pipe(
            distinctUntilChanged(),
            switchMap(label => this.permissionService.hasPermission$(AppPermissions.DeleteMissionReport).pipe(
              map(hasPermission => ({
                label,
                visible: hasPermission,
                command: () => {
                  this.creatingSubject.next(false);
                  this.onDeleteAction(
                    { label: 'missions.actions.delete-mission-report' } as ActionButton,
                    'missions.descriptions.delete-mission-report'
                  );
                }
              }))
            ))
          )
        ] as Observable<MenuItem>[]).pipe(
          map(items => items.filter(x => !isNull(x)))
        )
      }
    ];
  }

  public ngOnInit(): void {
    this.subscribeToFragment();
    this.subscribeToParams();
    this.subscribeToActions();
  }

  public ngAfterViewInit(): void {
    if (this.reportId) {
      this.getEntity(this.getId(), []);
    }
  }

  public onCreate(): void {
    this.store.dispatch(missionReportStore.actions.create({
      model: this.createModelSubject.getValue(),
      missionId: this.missionId
    }));
  }

  public onCreateAndFinish(): void {
    this.store.dispatch(missionReportStore.actions.createAndConfirm({
      model: this.createModelSubject.getValue(),
      missionId: this.missionId
    }));
  }

  public onSaveAndFinish(): void {
    this.store.dispatch(this.entityStore.actions.saveAndConfirm({ id: this.id, parentIds: this.parentIds }))
  }

  public onCreateMode(): void {
    this.store.dispatch(missionReportStore.actions.getStereotypes({ excludeArchived: true }));
    this.creatingSubject.next(true);
  }

  protected subscribeToActions():void {
    super.subscribeToActions();

    this.subscribe(this.actions$.pipe(ofType(missionReportStore.actions.saveAndConfirmSuccess)), payload => this.onSaveSuccess(payload));
  }

  protected setViewMode(): void {
    super.setViewMode();
    this.creatingSubject.next(false);
  }

  protected getId(): number {
    return this.reportId;
  }
}
