import { ChangeDetectionStrategy, Component, Input, OnInit } from "@angular/core";
import {
  BasicTableColumnType,
  BasicTableConfig,
  ResourceSimpleDto,
  UnsubscribeHelper
} from "@nexnox-web/core-shared";
import { BindObservable } from "bind-observable";
import { BehaviorSubject, Observable } from "rxjs";
import { resourceTypeEnumOptions } from "@nexnox-web/tech-portal/features/resources";

@Component({
  selector: 'nexnox-web-settings-issue-published-resources',
  templateUrl: 'issue-published-resources.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IssuePublishedResourcesComponent extends UnsubscribeHelper implements OnInit {

  @Input() public loading: boolean;

  @Input() @BindObservable() public readonly: boolean;
  public readonly$!: Observable<boolean>;
  public readonlySubject!: BehaviorSubject<boolean>;

  @Input()
  public set resources(resources: ResourceSimpleDto[]) {
    this.resourcesSubject.next(resources ?? []);
  }

  public get resources(): ResourceSimpleDto[] {
    return this.resourcesSubject.getValue() ?? [];
  }

  public resourcesSubject: BehaviorSubject<ResourceSimpleDto[]> = new BehaviorSubject<ResourceSimpleDto[]>([]);
  public resources$: Observable<ResourceSimpleDto[]>;

  public resourceTableConfig: BasicTableConfig;

  constructor() {
    super();
    this.resources$ = this.resourcesSubject.asObservable();
  }

  public ngOnInit(): void {
    this.resourceTableConfig = {
      columns: [
        {
          key: 'resourceId',
          header: 'core-shared.shared.fields.resource.resource-id',
          type: BasicTableColumnType.Link,
          link: (row) => row?.resourceId ? {
            commands: ['resources', row.resourceId],
            module: 'inventory'
          } : null
        },
        {
          key: 'name',
          header: 'core-shared.shared.fields.name',
          type: BasicTableColumnType.Link,
          link: (row) => row?.resourceId ? {
            commands: ['resources', row.resourceId],
            module: 'inventory'
          } : null
        },
        {
          key: 'inventoryNumber',
          header: 'core-shared.shared.fields.resource.inventory-number',
          type: BasicTableColumnType.Text
        },
        {
          key: 'type',
          header: 'core-shared.shared.fields.resource-type',
          type: BasicTableColumnType.Enum,
          enumOptions: resourceTypeEnumOptions
        },
      ]
    }
  }

}
