import {
  tenantDetailStore,
  TenantDetailStoreState,
  tenantListStore,
  TenantListStoreState,
  tenantSubscriptionListStore,
  TenantSubscriptionListStoreState,
  tenantSystemContactListStore,
  TenantSystemContactListStoreState
} from './stores';

export interface TenantsState {
  tenantList: TenantListStoreState;
  tenantDetail: TenantDetailStoreState;
  tenantSubscriptionList: TenantSubscriptionListStoreState;
  tenantSystemContactList: TenantSystemContactListStoreState;
}

export const getInitialTenantsState = (): TenantsState => ({
  tenantList: tenantListStore.getInitialState(),
  tenantDetail: tenantDetailStore.getInitialState(),
  tenantSubscriptionList: tenantSubscriptionListStore.getInitialState(),
  tenantSystemContactList: tenantSystemContactListStore.getInitialState()
});
