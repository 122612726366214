import { NgModule } from '@angular/core';
import { CoreSharedModule } from '@nexnox-web/core-shared';
import { CorePortalEntityModule } from '@nexnox-web/core-portal';
import { processesServices } from './store';
import {
  ProcessChatEditComponent,
  ProcessMissionEditComponent,
  ProcessTicketEditComponent,
  TechPortalFeatureProcessEditComponent
} from './components';
import { ProcessDetailComponent, ProcessListComponent } from './containers';
import { CorePortalFeatureResourcesModule } from '@nexnox-web/core-portal/features/resources';
import { TechPortalFeatureTicketSettingsStatesModule } from '@nexnox-web/tech-portal/features/ticket-settings/features/states';
import { TechPortalFeatureTicketSettingsPrioritySetsModule } from '@nexnox-web/tech-portal/features/ticket-settings/features/priority-sets';
import { TechPortalFeatureTemplatesModule } from '@nexnox-web/tech-portal/features/templates';
import { CorePortalFeatureMasterDataLocationsModule } from '@nexnox-web/core-portal/features/master-data/features/locations';
import { CorePortalFeatureMasterDataLocationGroupsModule } from '@nexnox-web/core-portal/features/master-data/features/location-groups';

@NgModule({
  declarations: [
    TechPortalFeatureProcessEditComponent,
    ProcessTicketEditComponent,
    ProcessMissionEditComponent,
    ProcessChatEditComponent,

    ProcessListComponent,
    ProcessDetailComponent
  ],
  imports: [
    CoreSharedModule,
    CorePortalEntityModule,

    CorePortalFeatureResourcesModule.forFeature(),
    TechPortalFeatureTicketSettingsStatesModule.forFeature(),
    TechPortalFeatureTicketSettingsPrioritySetsModule.forFeature(),
    TechPortalFeatureTemplatesModule.forFeature(),
    CorePortalFeatureMasterDataLocationsModule.forFeature(),
    CorePortalFeatureMasterDataLocationGroupsModule.forFeature()
  ],
  providers: [
    ...processesServices
  ]
})
export class ProcessesDeclarationsModule {
}
