<div class="w-100 overflow-hidden">
  <div class="d-flex flex-row p-1">
    <div class="signature-pad-wrapper"
         [class.disabled]="to.disabled"
         (click)="onAddSignature()">
      <img *ngIf="(signatureUrl$ | async); else noSignatureTemplate"
           [src]="signatureUrl$ | async"
           class="img-fluid w-100">

      <ng-template #noSignatureTemplate>
        <a class="d-flex align-self-center">{{ 'core-shared.shared.custom-property-signature.add' | translate }}</a>
      </ng-template>
    </div>

    <div class="d-flex flex-column ml-2">
      <button type="button"
              pButton
              [icon]="faTrashAlt | nexnoxWebFaIconStringPipe"
              [title]="'core-shared.shared.select.clear' | translate"
              class="p-button-secondary p-button-text p-button-icon-only"
              [disabled]="(signatureUrl$ | async) === null || to.disabled"
              (click)="onClear()">
      </button>
    </div>
  </div>
</div>
