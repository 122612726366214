import { SnapElement } from './snap-element.model';

export class SnapLine extends SnapElement {
  public get x2(): number {
    return this._x2;
  }

  public set x2(x2: number) {
    this._x2 = x2;
    this.updateAttributes();
  }

  public get y2(): number {
    return this._y2;
  }

  public set y2(y2: number) {
    this._y2 = y2;
    this.updateAttributes();
  }

  public get absoluteX2(): number {
    return this._parent ? this._parent.absoluteX + this._x2 : this._x2;
  }

  public get absoluteY2(): number {
    return this._parent ? this._parent.absoluteY + this._y2 : this._y2;
  }

  protected _x2: number;
  protected _y2: number;

  constructor(
    x: number,
    y: number,
    x2: number,
    y2: number,
    stroke: string,
    strokeWidth: number,
    parent: SnapElement = null
  ) {
    super(x, y, parent);

    this._x2 = x2;
    this._y2 = y2;
    this._stroke = stroke;
    this._strokeWidth = strokeWidth;
  }

  public updateAttributes(): void {
    super.updateAttributes();
    this.element.attr({
      x1: this.absoluteX,
      y1: this.absoluteY,
      x2: this.absoluteX2,
      y2: this.absoluteY2
    });
  }

  protected createElement(snapPaper: any): any {
    return snapPaper.line(this.absoluteX, this.absoluteY, this.absoluteX2, this.absoluteY2);
  }
}
