import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { CorePortalEntityEditComponent, CustomPropertySetEditComponent, CorePortalCustomPropertySetsEditComponent } from './components';
import { CorePortalFormlyModule } from '../../formly';
import { CorePortalCardboxModule } from '../../cardbox';

const exportedComponents = [
  CorePortalEntityEditComponent,
  CorePortalCustomPropertySetsEditComponent
];

const sharedModules = [
  CorePortalFormlyModule,
  CorePortalCardboxModule
];

@NgModule({
  declarations: [
    ...exportedComponents,
    CustomPropertySetEditComponent
  ],
  imports: [
    ...sharedModules,
    CommonModule,
    TranslateModule
  ],
  exports: [
    ...exportedComponents,
    ...sharedModules
  ]
})
export class CorePortalEntityEditModule {
}
