import {ChangeDetectionStrategy, Component, Injector, OnInit} from '@angular/core';
import {
  CorePortalEntityEditBaseComponent,
  CorePortalFormlyReadonlyTypes,
  CorePortalFormlyReadonlyTyping,
  CorePortalVirtualResourceService
} from '@nexnox-web/core-portal';
import {
  BasicTableColumnType,
  BasicTableConfig,
  LocationDto,
  ResourceSimpleDto,
  WinterhalterProductOrderDto,
  WinterhalterProductOrderIssueDto
} from '@nexnox-web/core-shared';
import {FormlyFieldConfig} from '@ngx-formly/core';
import {
  CorePortalFeatureMasterDataLocationService
} from "@nexnox-web/core-portal/features/master-data/features/locations";
import {map, Observable} from "rxjs";
import {getWinterHalterProductOrderFormlyConfig} from "../../utils";

@Component({
  selector: 'nexnox-web-issues-winterhalter-product-order-issue-edit',
  templateUrl: './winterhalter-product-order-issue-edit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WinterhalterProductOrderIssueEditComponent extends CorePortalEntityEditBaseComponent<WinterhalterProductOrderIssueDto>
  implements OnInit {

  public products$: Observable<WinterhalterProductOrderDto[]>;
  public productTableConfig: BasicTableConfig;

  constructor(
    protected injector: Injector,
    private resourceService: CorePortalVirtualResourceService,
    private locationService: CorePortalFeatureMasterDataLocationService
  ) {
    super(injector);

    this.loadProducts();
    this.products$ = this.modelSubject.asObservable().pipe(map(model => model?.products));
  }

  public ngOnInit(): void {
    super.ngOnInit();

  }

  /* istanbul ignore next */
  protected createForm(): FormlyFieldConfig[] {
    return [
      {
        key: 'title',
        type: 'input',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-6',
        templateOptions: {
          type: 'text',
          corePortalTranslated: {
            label: 'core-shared.shared.fields.name',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          }
        },
        expressionProperties: {
          'templateOptions.required': () => !this.readonly,
          'templateOptions.disabled': () => this.readonly,
          'templateOptions.readonly': () => this.readonly
        },
        hideExpression: () => !this.creating
      },
      {
        key: 'resource',
        type: 'core-portal-entity-select',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-6',
        defaultValue: null,
        templateOptions: {
          corePortalTranslated: {
            label: 'core-portal.settings.fields.ticket-settings.processes.resource'
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.ENTITY,
            displayKey: 'name',
            link: (resource: ResourceSimpleDto) => resource?.resourceId ? ['resources', resource.resourceId] : null,
            module: 'inventory'
          } as CorePortalFormlyReadonlyTyping,
          entityService: this.resourceService,
          idKey: 'resourceId',
          displayKey: 'name',
          wholeObject: true,
          multiple: false
        },
        expressionProperties: {
          'templateOptions.disabled': () => this.readonly,
          'templateOptions.readonly': () => this.readonly
        }
      },
      {
        key: 'location',
        type: 'core-portal-entity-select',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-6',
        defaultValue: null,
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.location'
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.ENTITY,
            displayKey: 'name',
            link: (location: LocationDto) => location?.locationId ? ['/masterdata', 'locations', location.locationId] : null,
            module: 'management'
          } as CorePortalFormlyReadonlyTyping,
          entityService: this.locationService,
          idKey: 'locationId',
          displayKey: 'name',
          wholeObject: true,
          multiple: true,
          showAll: true
        },
        expressionProperties: {
          'templateOptions.disabled': () => this.readonly,
          'templateOptions.readonly': () => this.readonly
        }
      },

      /* Winterhalter Product Order fields */
      ...getWinterHalterProductOrderFormlyConfig(this.readonly)
    ];
  }

  private loadProducts(): void {
    this.productTableConfig = {
      class: 'w-100',
      columns: [
        {
          key: 'productId',
          header: 'core-shared.shared.fields.id',
          type: BasicTableColumnType.Text,
        },
        {
          key: 'title',
          header: 'core-shared.shared.fields.title',
          type: BasicTableColumnType.Text
        },
        {
          key: 'quantity',
          header: 'core-shared.shared.fields.total-amount',
          type: BasicTableColumnType.Text
        },
        {
          key: 'materialNumber',
          header: 'issues.fields.material-number',
          type: BasicTableColumnType.Text
        },
        {
          key: 'containerSize',
          header: 'issues.fields.container-size',
          type: BasicTableColumnType.Text
        },
        {
          key: 'urlImage',
          header: 'orga-portal.forms.form-field-types.7',
          type: BasicTableColumnType.Link,
          link: (row) => row ? {
            isExternalLink: true,
            externalLinkLabel: 'core-shared.shared.fields.open'
          } : null
        }
      ]
    }
  }
}
