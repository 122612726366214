import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { CardboxActionsComponent, CorePortalCardboxComponent } from './components';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { CardboxGhostComponent } from './ghosts';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CoreSharedModule } from '@nexnox-web/core-shared';
import { CorePortalFormlyModule } from '../formly';
import { MenuModule } from 'primeng/menu';
import { BadgeModule } from 'primeng/badge';
import { ButtonModule } from 'primeng/button';
import { TieredMenuModule } from 'primeng/tieredmenu';

const exportedComponents = [
  CorePortalCardboxComponent,
  CardboxActionsComponent
];

const exportedGhosts = [
  CardboxGhostComponent
];

@NgModule({
  declarations: [
    ...exportedComponents,
    ...exportedGhosts
  ],
  imports: [
    CommonModule,
    TranslateModule,
    NgxSkeletonLoaderModule,
    FontAwesomeModule,
    CoreSharedModule,
    CorePortalFormlyModule,
    MenuModule,
    ButtonModule,
    BadgeModule,
    TieredMenuModule
  ],
  exports: [
    ...exportedComponents,
    ...exportedGhosts
  ]
})
export class CorePortalCardboxModule {
}
