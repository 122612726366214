import {ChangeDetectionStrategy, Component, Injector, ViewChild} from '@angular/core';
import {
  CorePortalAttachmentsComponent,
  CorePortalEntityEditBaseComponent,
  CorePortalFormlyReadonlyTypes,
  CorePortalFormlyReadonlyTyping,
  CorePortalFormlyReceiverTyping
} from '@nexnox-web/core-portal';
import {AttachmentForTechDto, ContractDto, LinkDto} from '@nexnox-web/core-shared';
import {timespanTo} from '@nexnox-web/lodash';
import {TechPortalLinksComponent} from 'libs/tech-portal/src/lib/components';
import {FormlyFieldConfig} from '@ngx-formly/core';
import {TranslateService} from '@ngx-translate/core';
import dayjs from 'dayjs';
import {isNull, isUndefined} from 'lodash';
import {BehaviorSubject, Observable} from 'rxjs';
import {distinctUntilChanged, map, mergeMap, startWith} from 'rxjs/operators';
import {contractStateEnumOptions} from '../../models';

@Component({
  selector: 'nexnox-web-contracts-contract-edit',
  templateUrl: './contract-edit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TechPortalFeatureContractEditComponent extends CorePortalEntityEditBaseComponent<ContractDto> {
  @ViewChild('attachmentsComponent') public attachmentsComponent: CorePortalAttachmentsComponent;
  @ViewChild('linksComponent') public linksComponent: TechPortalLinksComponent;

  public attachmentsSubject: BehaviorSubject<AttachmentForTechDto[]> = new BehaviorSubject<AttachmentForTechDto[]>([]);
  public linksSubject: BehaviorSubject<LinkDto[]> = new BehaviorSubject<LinkDto[]>([]);

  private dueDate$: Observable<string>;
  private duePeriodSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  private endDateSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  constructor(
    protected injector: Injector,
    private translate: TranslateService
  ) {
    super(injector);

    /* istanbul ignore next */
    this.dueDate$ = this.duePeriodSubject.asObservable().pipe(
      mergeMap(duePeriod => this.endDateSubject.asObservable().pipe(
        map(endDate => {
          const toSubtract = duePeriod ? timespanTo(duePeriod) : null;
          let end = endDate ? dayjs(endDate).local() : null;

          if (end && toSubtract) {
            end = end
              .subtract(toSubtract.years, 'year')
              .subtract(toSubtract.weeks, 'week')
              .subtract(toSubtract.days, 'day')
              .subtract(toSubtract.hours, 'hour')
              .subtract(toSubtract.minutes, 'minute');
          }

          return toSubtract && end ? `${end.utc().format('YYYY-MM-DDTHH:mm:ss')}Z` : null;
        })
      ))
    );
  }

  public onAttachmentsChange(attachments: AttachmentForTechDto[]): void {
    this.setModel({...this.model, attachments});
    setTimeout(() => this.onModelChange(this.model));
  }

  public onLinksChange(links: LinkDto[]): void {
    this.setModel({...this.model, links});
    setTimeout(() => this.onModelChange(this.model));
  }

  public onModelChange(model: ContractDto): void {
    this.modelValidSubject.next({
      ...this.modelValidSubject.getValue(),
      links: this.linksComponent ? this.linksComponent.isModelValid() : true,
      attachments: this.attachmentsComponent ? this.attachmentsComponent.isModelValid() : true
    });

    super.onModelChange(model);
  }

  /* istanbul ignore next */
  protected createForm(): FormlyFieldConfig[] {
    return [
      {key: 'contacts'},
      {key: 'functions'},
      {key: 'locations'},
      {key: 'locationGroups'},
      {
        key: 'title',
        type: 'input',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-12',
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.title',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.BASIC
          } as CorePortalFormlyReadonlyTyping,
          type: 'text'
        },
        expressionProperties: {
          'templateOptions.required': () => !this.readonly,
          'templateOptions.disabled': () => this.readonly,
          'templateOptions.readonly': () => this.readonly
        },
        hideExpression: () => !this.creating
      },
      {
        key: 'description',
        type: 'core-portal-editor',
        wrappers: ['core-portal-translated'],
        className: 'col-md-12',
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.description',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          },
          corePortalEditor: {
            language: this.translate.currentLang
          }
        },
        expressionProperties: {
          'templateOptions.disabled': () => this.readonly,
          'templateOptions.readonly': () => this.readonly,
          'templateOptions.required': () => !this.readonly
        }
      },
      {
        key: 'receivers',
        type: 'core-portal-receivers',
        wrappers: ['core-portal-translated'],
        className: 'col-md-12',
        defaultValue: null,
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.receivers'
          },
          corePortalReceivers: {
            model: this.modelSubject
          } as CorePortalFormlyReceiverTyping
        },
        expressionProperties: {
          'templateOptions.disabled': () => this.readonly
        },
      },
      {
        key: 'state',
        type: 'core-portal-ng-select',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-12',
        defaultValue: contractStateEnumOptions[1].value,
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.current-state',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.ENUM,
            enumOptions: contractStateEnumOptions,
            translate: true
          } as CorePortalFormlyReadonlyTyping,
          corePortalNgSelect: {
            items: contractStateEnumOptions,
            translate: true
          }
        },
        expressionProperties: {
          'templateOptions.disabled': () => this.readonly,
          'templateOptions.readonly': () => this.readonly,
          'templateOptions.required': () => !this.readonly
        }
      },
      {
        key: 'startDate',
        type: 'core-portal-datepicker',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-6',
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.actual-start',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.DATE,
            format: 'LL'
          } as CorePortalFormlyReadonlyTyping
        },
        expressionProperties: {
          'templateOptions.disabled': () => this.readonly,
          'templateOptions.readonly': () => this.readonly,
          'templateOptions.required': () => !this.readonly
        }
      },
      {
        key: 'duePeriod',
        type: 'core-portal-timepicker',
        wrappers: ['core-portal-translated'],
        className: 'col-md-6',
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.due-period'
          },
          corePortalTimepicker: {
            mode: 'timespan',
            showYears: true,
            showWeeks: true,
            showDays: true,
            showHours: false,
            showMinutes: false
          }
        },
        expressionProperties: {
          'templateOptions.disabled': () => this.readonly
        },
        hooks: {
          onInit: field => this.subscribe(field.formControl.valueChanges.pipe(
            startWith(field.formControl.value),
            distinctUntilChanged()
          ), value => this.duePeriodSubject.next(value))
        }
      },
      {
        key: 'endDate',
        type: 'core-portal-datepicker',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-6',
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.actual-end',
            validationMessages: {
              required: 'core-portal.core.validation.required'
            }
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.DATE,
            format: 'LL'
          } as CorePortalFormlyReadonlyTyping
        },
        expressionProperties: {
          'templateOptions.disabled': () => this.readonly,
          'templateOptions.readonly': () => this.readonly,
          'templateOptions.required': () => !this.readonly && (!isUndefined(this.model.duePeriod) && !isNull(this.model?.duePeriod))
        },
        hooks: {
          onInit: field => this.subscribe(field.formControl.valueChanges.pipe(
            startWith(field.formControl.value),
            distinctUntilChanged()
          ), value => this.endDateSubject.next(value))
        }
      },
      {
        key: 'dueDate',
        type: 'core-portal-datepicker',
        wrappers: ['core-portal-translated', 'core-portal-readonly'],
        className: 'col-md-6',
        templateOptions: {
          corePortalTranslated: {
            label: 'core-shared.shared.fields.due-date'
          },
          corePortalReadonly: {
            type: CorePortalFormlyReadonlyTypes.DATE,
            format: 'LL'
          } as CorePortalFormlyReadonlyTyping,
          readonly: true,
          disabled: true
        },
        hooks: {
          onInit: field => this.subscribe(this.dueDate$, value => field.formControl.setValue(value))
        }
      },
      ...this.getStereotypeFields(false)
    ];
  }

  protected setModel(model: ContractDto): void {
    super.setModel(model);

    this.attachmentsSubject.next(model?.attachments ?? []);
    this.linksSubject.next(model?.links ?? []);
  }

  protected setReadonly(readonly: boolean): void {
    super.setReadonly(readonly);

    this.attachmentsComponent?.onReset();
    this.linksComponent?.onReset();
  }
}
