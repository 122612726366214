import {Injectable, Injector} from '@angular/core';
import {
  CoreSharedApiBaseService, FilterKind, FilterOperators,
  LocationDto,
  PageableRequest,
  Product,
  Query,
  ResourceState,
  SafeDto,
  ResourceListDto
} from '@nexnox-web/core-shared';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable()
export class TechPortalFeatureResourceService extends CoreSharedApiBaseService {
  constructor(
    protected injector: Injector
  ) {
    super(injector, Product.TECH, 'resource');
  }

  public getRoot<T>(pageSize?: number): Observable<PageableRequest<T>> {
    return this.getChildPage(0, 1, pageSize);
  }

  public getRootPage<T>(pageNumber: number, pageSize?: number): Observable<PageableRequest<T>> {
    return this.getChildPage(0, pageNumber, pageSize);
  }

  public getChildRoot<T>(parentId: number, pageSize?: number): Observable<PageableRequest<T>> {
    return this.getChildPage(parentId, 1, pageSize);
  }

  public getChildPage<T>(parentId: number, pageNumber: number, pageSize: number = 10): Observable<PageableRequest<T>> {
    const query = new Query()
      .add('pageNumber', pageNumber)
      .add('pageSize', pageSize)
      .add('cols', 'HasChildren');

    const queryString = query.build();
    const path = `${this.buildPath([], false)}/${parentId}/children${queryString}`;

    return this.httpRequestService.get<PageableRequest<T>>(path).pipe(
      map(response => response.body)
    );
  }

  public getResourcesInProgress<T>(pageNumber: number, pageSize: number = 100): Observable<T> {
    const filters = [{
      kind: FilterKind.Default,
      property: "isInProgressSince",
      operator: FilterOperators.GreaterThan,
      value: "1900-01-01"
    }];

    const query = new Query()
      .add('pageNumber', pageNumber)
      .add('pageSize', pageSize)
      .add('cols', 'isInProgressSince')
      .add('archived', false);

    return this.httpRequestService.post<T>('resource/filter' + query.build(), filters).pipe(
      map(response => response.body)
    );
  }

  public changeState<T>(
    resourceId: number | string,
    state: ResourceState,
    location?: LocationDto,
    isKeepingLocation?: boolean
  ): Observable<T> {
    const locationId: number | string = location?.locationId;
    return this.httpRequestService.post<T>(`/resource/${resourceId}/changeState`,
      {state, locationId, isKeepingLocation})
      .pipe(map(response => response.body));
  }

  public safeMove<T>(safe: SafeDto, resourceId: number | string): Observable<T> {
    return this.httpRequestService.post<T>(`/resource/${resourceId}/safe/move`, safe).pipe(
      map(response => response.body)
    );
  }

  public safeTakeOut<T>(resourceId: number | string): Observable<T> {
    return this.httpRequestService.post<T>(`/resource/${resourceId}/safe/takeout`, null).pipe(
      map(response => response.body)
    );
  }
}
