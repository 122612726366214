import {
  ticketAuditStore,
  TicketAuditStoreState,
  ticketDetailStore,
  TicketDetailStoreState,
  ticketEventsStore,
  TicketEventsStoreState,
  ticketListStore,
  TicketListStoreState,
  ticketMissionDetailStore,
  TicketMissionDetailStoreState,
  ticketMissionsStore,
  TicketMissionsStoreState,
  ticketSolutionStore,
  TicketSolutionStoreState
} from './stores';

export interface TicketsState {
  ticketList: TicketListStoreState;
  ticketDetail: TicketDetailStoreState;

  ticketSolution: TicketSolutionStoreState;
  ticketEvents: TicketEventsStoreState;
  ticketAudit: TicketAuditStoreState;
  ticketMissions: TicketMissionsStoreState;
  ticketMissionDetail: TicketMissionDetailStoreState;
}

export const getInitialTicketsState = (): TicketsState => ({
  ticketList: ticketListStore.getInitialState(),
  ticketDetail: ticketDetailStore.getInitialState(),

  ticketSolution: ticketSolutionStore.getInitialState(),
  ticketEvents: ticketEventsStore.getInitialState(),
  ticketAudit: ticketAuditStore.getInitialState(),
  ticketMissions: ticketMissionsStore.getInitialState(),
  ticketMissionDetail: ticketMissionDetailStore.getInitialState()
});
