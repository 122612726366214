import { EntityXsStore, EntityXsStoreState } from '@nexnox-web/core-store';
import { AppEntityType, StateMachineDto } from '@nexnox-web/core-shared';
import { Action, createSelector } from '@ngrx/store';
import { selectStateMachinesState } from '../../state-machines.selectors';
import { TechPortalFeatureTicketSettingsStateMachineService } from '../../services';
import { Injectable, Injector } from '@angular/core';

export interface StateMachineDetailStoreState extends EntityXsStoreState<StateMachineDto> {
}

/* istanbul ignore next */
export const stateMachineDetailStore = new EntityXsStore<StateMachineDto>({
  actionLabel: 'Tech Portal - Ticket Settings - State Machines - State Machine Detail',
  stateSelector: createSelector(selectStateMachinesState, state => state.stateMachineDetail),
  serviceType: TechPortalFeatureTicketSettingsStateMachineService,
  entityType: AppEntityType.None,
  stereotyped: false
});

export function stateMachineDetailStoreReducer(state: StateMachineDetailStoreState, action: Action): void {
  return stateMachineDetailStore.reducer(state, action);
}

@Injectable()
export class StateMachineDetailStoreEffects extends stateMachineDetailStore.effects {
  constructor(
    protected injector: Injector
  ) {
    super(injector);
  }
}
