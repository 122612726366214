import { isUndefined, isNull } from 'lodash';
import { QueryElement } from './query-element.model';
import { QuerySymbol } from './query-symbol.enum';

export class Query {
  public url: string;

  private queryElements: QueryElement[] = [];

  constructor(url?: string) {
    this.url = url ? url : '';
  }

  /**
   * Adds query element. If value is not undefined/null, adds nothing
   */
  public add(key: string, value: string | number | boolean): Query {
    if (isUndefined(value) || isNull(value)) {
      return this;
    }

    this.queryElements.push({
      symbol: !this.queryElements.length ? QuerySymbol.FIRST : QuerySymbol.AND,
      key,
      value: encodeURIComponent(value)
    });

    return this;
  }

  public build(): string {
    return this.url + this.queryElements.map(queryElement => {
      return `${queryElement.symbol}${queryElement.key}=${queryElement.value}`;
    }).join('');
  }
}
