import { NgModule } from '@angular/core';
import { CoreSharedModule, CoreSharedSortableListModule } from '@nexnox-web/core-shared';
import { CorePortalRouterOverridesModule } from '../../router-overrides/router-overrides.module';
import { CorePortalCardboxModule } from '../cardbox/cardbox.module';
import { CorePortalAttachmentsComponent } from './components/attachments/attachments.component';
import { CorePortalExistingAttachmentsModalComponent } from './modals/existing-attachments-modal/existing-attachments-modal.component';
import {
    CorePortalAttachmentsDownloadComponent
} from "./components/download/download.component";


@NgModule({
    declarations: [
        CorePortalAttachmentsComponent,
        CorePortalExistingAttachmentsModalComponent,
        CorePortalAttachmentsDownloadComponent
    ],
  imports: [
    CoreSharedModule,
    CorePortalCardboxModule,
    CoreSharedSortableListModule,
    CorePortalRouterOverridesModule
  ],
  exports: [
    CorePortalAttachmentsComponent,
    CorePortalExistingAttachmentsModalComponent,
    CorePortalAttachmentsDownloadComponent
  ]
})
export class CorePortalAttachmentsModule {
}
