import {AfterContentInit, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormlyFieldConfig} from "@ngx-formly/core";
import {FormGroup} from "@angular/forms";
import {faPencilAlt} from "@fortawesome/free-solid-svg-icons/faPencilAlt";
import {faTimes} from '@fortawesome/free-solid-svg-icons/faTimes';
import {faCheck} from '@fortawesome/free-solid-svg-icons/faCheck';
import {faTrashAlt} from "@fortawesome/free-solid-svg-icons/faTrashAlt";
import {faPlus} from '@fortawesome/free-solid-svg-icons/faPlus';
import {cloneDeep} from "lodash";
import {FormlyTableLabelModes} from "@nexnox-web/libs/core-portal/src/lib/modules/formly/components";

export enum FormlyTableRowModes {
  Create = 0,
  Edit = 1,
  Readonly = 2
}

export interface FormlyTableRowValidityDto {
  localId: number;
  isValid: boolean;
}

@Component({
  selector: 'nexnox-web-formly-table-row',
  templateUrl: './formly-table-row.component.html',
  styles: [':host ::ng-deep formly-field label {font-size: 0.8rem; margin-bottom: 0.1em}']
})
export class FormlyTableRowComponent implements OnInit, AfterContentInit {

  @Input() public localId = 404;
  @Input() public fields: FormlyFieldConfig[] = [];
  @Input() public model: any;
  @Input() public labelDisplayMode: FormlyTableLabelModes;

  @Output() public modelChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() public validChange: EventEmitter<FormlyTableRowValidityDto> = new EventEmitter<FormlyTableRowValidityDto>();
  @Output() public deleteRow: EventEmitter<number> = new EventEmitter<number>();
  @Output() public addRow: EventEmitter<any> = new EventEmitter<any>();

  public form: FormGroup;
  public mode = FormlyTableRowModes.Readonly;
  public rowModes = FormlyTableRowModes;
  public storedModel: any;
  public initializedFields;

  public faTimes = faTimes;
  public faPencil = faPencilAlt;
  public faCheck = faCheck;
  public faTrash = faTrashAlt;
  public faPlus = faPlus;

  public ngOnInit(): void {
    this.form = new FormGroup({});
    this.mode = this.model ? FormlyTableRowModes.Readonly : FormlyTableRowModes.Create;
  }

  public ngAfterContentInit(): void {
    this.initFields();
  }

  public onAdd(): void {
    this.addRow.emit(this.getModelClone());
    this.model = {};
    this.form.reset();
    this.form.updateValueAndValidity();
  }

  public onConfirm(): void {
    this.mode = FormlyTableRowModes.Readonly;
    this.modelChange.emit({localId: this.localId, model: this.getModelClone()});
    this.validChange.emit({localId: this.localId, isValid: true});
  }

  public onEdit(): void {
    this.storedModel = this.getModelClone();
    this.mode = FormlyTableRowModes.Edit;
    this.validChange.emit({localId: this.localId, isValid: false});
  }

  public onDelete(): void {
    this.deleteRow.emit(this.localId);
  }

  public onCancel(): void {
    if (this.storedModel) {
      this.form.setValue(this.storedModel);
      this.storedModel = undefined;
    }
    this.mode = FormlyTableRowModes.Readonly;
    this.validChange.emit({localId: this.localId, isValid: true});
  }

  private initFields(): void {
    for (let f = 0; f < this.fields.length; f++) {
      this.fields[f].id = `${this.localId}-${f}`;
      this.fields[f].expressionProperties = {'templateOptions.readonly': () => this.mode === FormlyTableRowModes.Readonly};

      if (this.fields[f]?.templateOptions?.corePortalTranslated?.label &&
        this.labelDisplayMode === FormlyTableLabelModes.Regular) {
        // Remove labels from fields
        this.fields[f].templateOptions.corePortalTranslated.label = '';
      }
    }
    this.initializedFields = this.fields;
  }

  private getModelClone(): any {
    return cloneDeep(this.form.value);
  }
}
