<nexnox-web-entity-datatable-filter-input *ngIf="(filterType | async) as type"
                                          [filterType]="type"
                                          [disabled]="disabled"
                                          [allowRelative]="allowRelative"
                                          [alternateReferenceEntitySelectOptions]="alternateReferenceEntitySelectOptions"
                                          [clearable]="clearable"
                                          [currentDirection]="currentDirection"
                                          [currentValue$]="currentValue$"
                                          [useAlternate]="useAlternate"
                                          [isDatatableSettings]="isDatatableSettings"
                                          [isRelative]="isRelative"
                                          [referenceEntitySelectOptions]="referenceEntitySelectOptions"
                                          [showAll]="showAll"
                                          [showError]="showError"
                                          [templates]="templates"
                                          [column]="column"
                                          [pathEntitySelectOptions]="pathEntitySelectOptions"
                                          (changeFilter)="onChangeFilter($event)"
                                          (changeRelative)="onChangeRelative($event)"
                                          (changeDirection)="onChangeDirection($event)"
                                          (changeAlternate)="onChangeAlternate($event)"
                                          (changePathFilter)="onChangePathFilter($event)"
                                          (changeDateFilter)="onChangeDateFilter($event)"
                                          (changeRelativeDateFilter)="onChangeRelativeDateFilter($event)"
></nexnox-web-entity-datatable-filter-input>

