import { ChangeDetectionStrategy, Component, Injector } from '@angular/core';
import { CorePortalEntityOverviewBaseComponent, DatatableActionButton } from '@nexnox-web/core-portal';
import { AppEntityType, CompanyDto, Mappers } from '@nexnox-web/core-shared';
import { addressCompanyListStore } from '../../store';

@Component({
  selector: 'nexnox-web-master-data-addresses-address-company-list',
  templateUrl: './address-company-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddressCompanyListComponent extends CorePortalEntityOverviewBaseComponent<CompanyDto> {
  public title = 'core-portal.master-data.subtitles.company-list';
  public idProperty = 'companyId';
  public displayProperty = 'name';
  public datatableConfigName = 'MasterDataCompanyDefault';
  public componentId = 'AddressCompanyListComponent';

  constructor(
    protected injector: Injector
  ) {
    super(injector, addressCompanyListStore, Mappers.CompanyListForAddressDto, AppEntityType.Company);
  }

  public getRowActionButtons(): DatatableActionButton[] {
    return this.getEmbeddedRowActionButtons();
  }
}
