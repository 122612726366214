import { ChangeDetectionStrategy, Component, Injector, Input } from '@angular/core';
import { CorePortalEntityEditBaseComponent } from '@nexnox-web/core-portal';
import { MissionInspectionReportDto } from '@nexnox-web/core-shared';
import { FormlyFieldConfig } from '@ngx-formly/core';

@Component({
  selector: 'nexnox-web-missions-mission-inspection-report-edit',
  templateUrl: './mission-inspection-report-edit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MissionInspectionReportEditComponent extends CorePortalEntityEditBaseComponent<MissionInspectionReportDto> {
  @Input()
  public set stereotypeId(stereotypeId: number) {
    this.setModel({ ...this.model, stereotypeId });
  }

  constructor(
    protected injector: Injector
  ) {
    super(injector);
  }

  protected createForm(): FormlyFieldConfig[] {
    return [];
  }
}
