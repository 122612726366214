import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {faList} from '@fortawesome/free-solid-svg-icons/faList';
import {BehaviorSubject, interval, Observable, of} from 'rxjs';
import {
  ApiNotificationService,
  Paging,
  ResourceDto,
  ResourceListDto,
  ResourceState,
  ResourceTreeListDto,
  ResourceType,
  UnsubscribeHelper
} from '@nexnox-web/core-shared';
import {
  CorePortalTenantRouter,
  HierarchicalTreeViewComponent,
  HierarchicalTreeViewItem,
  HierarchicalTreeViewItemComponent
} from '@nexnox-web/core-portal';
import {select, Store} from '@ngrx/store';
import {resourceTreeViewStore, resourceUIEntitiesStore, ResourceUIEntitiesXsStoreEntity} from '../../store';
import {filter, map, mergeMap, startWith, take} from 'rxjs/operators';
import {Actions, ofType} from '@ngrx/effects';
import {ResourcesComponent} from '../resources/resources.component';
import {faFolder} from '@fortawesome/free-solid-svg-icons/faFolder';
import {faFolderPlus} from '@fortawesome/free-solid-svg-icons/faFolderPlus';
import {IconDefinition} from "@fortawesome/fontawesome-common-types";
import {faSync} from '@fortawesome/free-solid-svg-icons/faSync';
import {faChevronUp} from '@fortawesome/free-solid-svg-icons/faChevronUp';
import {faChevronDown} from '@fortawesome/free-solid-svg-icons/faChevronDown';

@Component({
  selector: 'nexnox-web-resources-resource-tree-view',
  templateUrl: './resource-tree-view.component.html',
  styleUrls: ['./resource-tree-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ResourceTreeViewComponent extends UnsubscribeHelper implements OnInit {
  @ViewChild('treeViewComponent') public treeViewComponent: HierarchicalTreeViewComponent;

  @Input() public parent: ResourcesComponent;

  @Output() public selectItem: EventEmitter<void> = new EventEmitter<void>();
  @Output() public refresh: EventEmitter<void> = new EventEmitter<void>();

  public resources$: Observable<Array<ResourceUIEntitiesXsStoreEntity & ResourceDto>>;
  public items$: Observable<HierarchicalTreeViewItem[]>;
  public itemsInProgress$: Observable<HierarchicalTreeViewItem[]>;
  public someItemsInProgress$: Observable<boolean>;
  public paging$: Observable<Paging>;
  public loading$: Observable<boolean>;
  public nextLoading$: Observable<boolean>;
  public preloading$: Observable<boolean>;
  public listLoading$: Observable<boolean>;
  public inProgressIndicator$: Observable<number>;

  public preloadSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isProgressMenuSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public faFolder = faFolder;
  public faFolderPlus = faFolderPlus;
  public faSync = faSync;
  public faList = faList;
  public faChevronUp = faChevronUp;
  public faChevronDown = faChevronDown;

  constructor(
    private store: Store<any>,
    private actions$: Actions,
    private tenantRouter: CorePortalTenantRouter,
    private apiNotificationService: ApiNotificationService
  ) {
    super();
  }

  public ngOnInit(): void {
    this.resources$ = this.store.pipe(
      select(resourceTreeViewStore.selectors.selectIds),
      mergeMap(ids => this.store.pipe(select(resourceUIEntitiesStore.selectors.selectMerged(ids))))
    );

    this.loading$ = this.store.pipe(
      select(resourceTreeViewStore.selectors.selectLoading)
    );

    this.nextLoading$ = this.store.pipe(
      select(resourceTreeViewStore.selectors.selectAppendLoading)
    );

    this.preloading$ = this.store.pipe(
      select(resourceTreeViewStore.selectors.selectPreloading)
    );

    this.listLoading$ = this.loading$.pipe(
      mergeMap(loading => this.preloading$.pipe(
        map(preloading => loading || preloading)
      )),
      startWith(true)
    );

    this.paging$ = this.store.pipe(select(resourceTreeViewStore.selectors.selectPaging));

    // Visible items
    /* istanbul ignore next */
    this.items$ = this.resources$.pipe(map(resources => resources.map(resource => this._mapRootToTreeViewItem(resource))));

    // Items in progress
    /* istanbul ignore next */
    this.itemsInProgress$ = this.store.select(resourceTreeViewStore.selectors.selectResourcesInProgress).pipe(
        map((resources) => resources.map(resource => ({
          id: resource.resourceId,
          name: resource.name,
          state: ResourceState.Operation, // Don't grey it out
          inventoryNumber: resource.inventoryNumber,
          location: resource.location,
          icon: this._getIcon(resource?.type),
          link: `/resources/${resource.resourceId}`,
          module: 'inventory'
        })
      )));

    this.someItemsInProgress$ = this.itemsInProgress$.pipe(map(items => items.length > 0));
    this.inProgressIndicator$ = this.itemsInProgress$.pipe(map(items => items.length));

    /* istanbul ignore next */
    this.subscribe(this.actions$.pipe(ofType(resourceTreeViewStore.actions.preloadSuccess)), ({ids}) => {
      this.preloadSubject.next(true);
      this.subscribe(interval(500).pipe(
        mergeMap(() => this.treeViewComponent.componentsLoaded$.pipe(
          filter(loaded => loaded)
        )),
        filter(() => !this.treeViewComponent.loading),
        take(1)
      ), () => {
        this.treeViewComponent.selectFromIds(ids, true).then(() => {
          this.preloadSubject.next(false);
        }).catch(() => {
          this.preloadSubject.next(false);
          this.apiNotificationService.showTranslatedError('core-portal.core.error.unknown');
          this.tenantRouter.navigate(['/resources'], {module: 'inventory'});
        });
      });
    });
  }

  public onExpandItem(itemComponent: HierarchicalTreeViewItemComponent): void {
    this.store.dispatch(resourceUIEntitiesStore.actions.getChildRoot({parentId: itemComponent.item.id}));
  }

  public onMoreItem(itemComponent: HierarchicalTreeViewItemComponent): void {
    this.store.dispatch(resourceUIEntitiesStore.actions.getChildNext({parentId: itemComponent.item.id}));
  }

  public onMore(): void {
    this.store.dispatch(resourceTreeViewStore.actions.appendPage());
  }

  public onRefresh(): void {
    this.refresh.emit();
  }

  public trackItemsById(index: number, item: HierarchicalTreeViewItem): number {
    return item.id;
  }

  public toggleProgressMenu(): void {
    this.isProgressMenuSubject.next(!this.isProgressMenuSubject.getValue());
  }

  private _mapRootToTreeViewItem(resource: ResourceUIEntitiesXsStoreEntity & ResourceTreeListDto): HierarchicalTreeViewItem {
    return {
      id: resource.resourceId,
      parentId: resource.parentId ?? undefined,
      name: resource.name,
      state: resource.state,
      type: resource.type,
      inventoryNumber: resource.inventoryNumber,
      location: resource.location,
      icon: this._getIcon(resource?.type),
      link: `/resources/${resource.resourceId}`,
      module: 'inventory',
      hidden: (item) => resource.isInProgress,
      hasChildren: (resource as ResourceListDto).hasChildren !== false,
      getChildren: () => this._mapChildrenToTreeViewItem(resource),
      getChildrenLoading: () => this.store.pipe(select(resourceUIEntitiesStore.selectors.selectLoading(resource.resourceId))),
      getNextLoading: () => this.store.pipe(select(resourceUIEntitiesStore.selectors.selectAppendLoading(resource.resourceId))),
      getPaging: () => this.store.pipe(select(resourceUIEntitiesStore.selectors.selectPaging(resource.resourceId)))
    }
  }

  /* istanbul ignore next */
  private _mapChildrenToTreeViewItem(resource: ResourceUIEntitiesXsStoreEntity & ResourceListDto): Observable<HierarchicalTreeViewItem[]> {
    if (resource.hasChildren) {
      return this.store.pipe(select(resourceUIEntitiesStore.selectors.selectMergedChildren(resource.resourceId))).pipe(
        map(mergedChildren => mergedChildren.map(mergedChild => ({
          id: mergedChild.resourceId,
          parentId: mergedChild.parentId ?? undefined,
          name: mergedChild.name,
          state: mergedChild.state,
          type: mergedChild.type,
          inventoryNumber: mergedChild.inventoryNumber,
          location: mergedChild.location,
          icon: this._getIcon(mergedChild?.type),
          link: `/resources/${mergedChild.resourceId}`,
          module: 'inventory',
          hasChildren: (mergedChild as ResourceListDto).hasChildren === true,
          hidden: (item) => mergedChild.isInProgress,
          getChildren: () => this._mapChildrenToTreeViewItem(mergedChild),
          getChildrenLoading: () => this.store.pipe(select(resourceUIEntitiesStore.selectors.selectLoading(mergedChild.resourceId))),
          getNextLoading: () => this.store.pipe(select(resourceUIEntitiesStore.selectors.selectAppendLoading(mergedChild.resourceId))),
          getPaging: () => this.store.pipe(select(resourceUIEntitiesStore.selectors.selectPaging(mergedChild.resourceId)))
        })))
      );
    }

    return of([]);
  }

  private _getIcon(type: ResourceType): IconDefinition {

    let definition: IconDefinition;

    switch (type) {
      case ResourceType.Group:
        definition = this.faFolder;
        break;
      case ResourceType.Device:
        definition = null;
        break;
      case ResourceType.VirtualGroup:
        definition = this.faFolderPlus;
        break;
      case ResourceType.Virtual:
        definition = null;
        break;
      default:
        definition = null;
        break;
    }

    return definition;
  }
}
