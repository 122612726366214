import { PagedEntitiesXsStore, PagedEntitiesXsStoreState } from '@nexnox-web/core-store';
import {AppEntityType, ContactDto, LocationGroupDto} from '@nexnox-web/core-shared';
import { createSelector } from '@ngrx/store';
import { selectLocationsState } from '../../locations.selectors';
import { LocationLocationGroupService } from '../../services';

export interface LocationLocationGroupListStoreState extends PagedEntitiesXsStoreState<ContactDto> {
}

/* istanbul ignore next */
export const locationLocationGroupListStore = new PagedEntitiesXsStore<LocationGroupDto>({
  actionLabel: 'Core Portal - Master Data - Location - Location Group List',
  stateSelector: createSelector(selectLocationsState, state => state.locationLocationGroupList),
  serviceType: LocationLocationGroupService,
  entityType: AppEntityType.LocationGroup,
  selectId: group => group.entity?.locationGroupId ?? group.model?.locationGroupId
});
