<swal #swal
      position="center"
      [title]="!loading ? title : '...'"
      [icon]="icon"
      [width]="width"
      [customClass]="{
        confirmButton: 'p-button p-button-' + confirmStyle,
        cancelButton: 'p-button p-button-secondary mr-2'
      }"
      [showConfirmButton]="showConfirmButton"
      [confirmButtonText]="confirmButtonText"
      [showCancelButton]="showCancelButton"
      [cancelButtonText]="cancelButtonText"
      [preConfirm]="preConfirmFn">
  <div *swalPortal class="d-flex align-items-center justify-content-center pt-3 pb-3">
    <fa-icon *ngIf="loading; else contentTemplate" [icon]="faSpinner" [spin]="true"></fa-icon>

    <ng-template #contentTemplate>
      <ng-content></ng-content>
    </ng-template>
  </div>
</swal>
