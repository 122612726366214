import { PagedEntitiesXsStore, PagedEntitiesXsStoreState } from '@nexnox-web/core-store';
import { AppEntityType, ContactDto } from '@nexnox-web/core-shared';
import { createSelector } from '@ngrx/store';
import { selectRolesState } from '../../roles.selectors';
import { RoleContactService } from '../../services';

export interface RoleContactListStoreState extends PagedEntitiesXsStoreState<ContactDto> {
}

export const roleContactListStore = new PagedEntitiesXsStore<ContactDto>({
  actionLabel: 'Core Portal - Settings - Roles - Contact List',
  stateSelector: createSelector(selectRolesState, state => state.roleContactList),
  serviceType: RoleContactService,
  entityType: AppEntityType.Contact,
  selectId: contact => contact.entity?.contactId ?? contact.model?.contactId
});
