import {NgModule} from '@angular/core';
import {CoreSharedModule, CoreSharedSortableListModule} from 'libs/core-shared/src';
import {CorePortalEntityModule, CorePortalRouterOverridesModule} from 'libs/core-portal/src';
import {OrgaPortalModule} from 'libs/orga-portal/src';
import {pathsServices} from './store';
import {PathsArticlesEditComponent, PathsEditComponent, PathsExamsEditComponent} from './components';
import {
  PathsDetailComponent,
  PathsListComponent,
  PathsProgressComponent,
  PathsProgressContactListComponent,
  PathsProgressContactReadUnreadListComponent,
  PathsProgressFunctionListComponent,
  PathsProgressLocationListComponent
} from './containers';
import {PathsProgressSidebarComponent} from "./sidebars";

@NgModule({
  declarations: [
    PathsEditComponent,
    PathsArticlesEditComponent,
    PathsExamsEditComponent,

    PathsListComponent,
    PathsDetailComponent,

    PathsProgressComponent,
    PathsProgressContactListComponent,
    PathsProgressContactReadUnreadListComponent,
    PathsProgressFunctionListComponent,
    PathsProgressLocationListComponent,
    PathsProgressSidebarComponent
  ],
  imports: [
    CoreSharedModule,
    CorePortalEntityModule,
    CoreSharedSortableListModule,

    OrgaPortalModule,
    CorePortalRouterOverridesModule
  ],
  exports: [
    PathsArticlesEditComponent
  ],
  providers: [
    ...pathsServices
  ]
})
export class PathsDeclarationsModule {
}
