import {EntityXsStore, EntityXsStoreState} from '@nexnox-web/core-store';
import {AppEntityType, MessageControlDto} from '@nexnox-web/core-shared';
import {Action, createSelector} from '@ngrx/store';
import {MessageControlIssueService} from '../../services';
import {Injectable, Injector} from '@angular/core';
import {selectIssuesState} from "../../issues.selectors";

export interface MessageControlIssueDetailStoreState extends EntityXsStoreState<MessageControlDto> {
}

/* istanbul ignore next */
export const messageControlIssueDetailStore = new EntityXsStore<MessageControlDto>({
  actionLabel: 'Tech Portal - Issues - Message Control Issue Detail',
  stateSelector: createSelector(selectIssuesState, state => state.messageControlIssueDetail),
  serviceType: MessageControlIssueService,
  entityType: AppEntityType.Issue,
  stereotyped: true
});

export function messageControlIssueDetailStoreReducer(state: MessageControlIssueDetailStoreState, action: Action): any {
  return messageControlIssueDetailStore.reducer(state, action);
}

@Injectable()
export class MessageControlIssueDetailStoreEffects extends messageControlIssueDetailStore.effects {
  constructor(
    protected injector: Injector
  ) {
    super(injector);
  }
}
