import { PagedEntitiesXsStore, PagedEntitiesXsStoreState } from '@nexnox-web/core-store';
import { AppEntityType, FormDto } from '@nexnox-web/core-shared';
import { createSelector } from '@ngrx/store';
import { selectFormsState } from '../../forms.selectors';
import { OrgaPortalFeatureFormService } from '../../services';

export interface FormListStoreState extends PagedEntitiesXsStoreState<FormDto> {
}

/* istanbul ignore next */
export const formListStore = new PagedEntitiesXsStore<FormDto>({
  actionLabel: 'Orga Portal - Forms - Form List',
  stateSelector: createSelector(selectFormsState, state => state.formList),
  serviceType: OrgaPortalFeatureFormService,
  entityType: AppEntityType.Form,
  selectId: form => form.entity?.formId ?? form.model?.formId,
  stereotyped: false
});
