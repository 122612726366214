import {ChangeDetectionStrategy, Component, Inject, Injector, OnDestroy, OnInit, Optional} from '@angular/core';
import {AppEntityType, CombineOperator, FilterOperators, FilterTypes} from '@nexnox-web/core-shared';
import {CORE_PORTAL_FEATURE_STEREOTYPES_ALLOWED_ENTITY_TYPES} from '../tokens';
import {StereotypeListComponent} from "../containers";
import {CorePortalEntityCreatePresetService} from "@nexnox-web/core-portal";

@Component({
  selector: 'nexnox-web-settings-stereotypes-ticket-stereotype-list-wrapper',
  templateUrl: '../containers/stereotype-list/stereotype-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TicketStereotypeListWrapperComponent extends StereotypeListComponent implements OnInit, OnDestroy {

  constructor(
    protected injector: Injector,
    protected entityCreatePresetService: CorePortalEntityCreatePresetService,
    @Optional() @Inject(CORE_PORTAL_FEATURE_STEREOTYPES_ALLOWED_ENTITY_TYPES) protected allowedEntityTypes: AppEntityType[]
  ) {
    super(injector, allowedEntityTypes);

    this.defaultFilter = [
      {
        type: FilterTypes.Grouped,
        combinedAs: CombineOperator.Or,
        property: 'entityType',
        children: [
          {
            type: 0,
            operator: FilterOperators.Default,
            property: 'entityType',
            value: AppEntityType.Ticket.toString(),
          }
        ]
      }
    ];

    this.componentId = 'TicketStereotypeListWrapperComponent';
  }

  public ngOnInit(): void {
    this.setWrapperCreatePreset();
    super.ngOnInit();

    this.detailLink = 'stereotypes/ticket-stereotypes';
  }

  public ngOnDestroy(): void {
    this.isEmbedded = true;
    super.ngOnDestroy();
  }

  public setWrapperCreatePreset(): void {
    this.entityCreatePresetService.setPreset('CorePortalFeatureSettingsStereotypeEditComponent', {
      entityType: AppEntityType.Ticket
    });
  }
}
