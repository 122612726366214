import { NgModule } from '@angular/core';
import {
  CorePortalTenantRouterLinkDirective,
  CorePortalTenantRouterLinkWithHrefDirective
} from './tenant-router-link/tenant-router-link.directive';
import { CommonModule } from '@angular/common';
import { CorePortalTenantRouterLinkActiveDirective } from './tenant-router-link/tenant-router-link-active.directive';
import { CorePortalTenantRouter } from './tenant-router/tenant.router';
import { CorePortalTenantLocation } from './tenant-location/tenant.location';

const directives = [
  CorePortalTenantRouterLinkDirective,
  CorePortalTenantRouterLinkWithHrefDirective,
  CorePortalTenantRouterLinkActiveDirective
];

@NgModule({
  declarations: [
    ...directives
  ],
  imports: [
    CommonModule
  ],
  exports: [
    ...directives
  ],
  providers: [
    CorePortalTenantRouter,
    CorePortalTenantLocation
  ]
})
export class CorePortalRouterOverridesModule {
}
