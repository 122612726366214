import { ChangeDetectionStrategy, Component, Injector, OnInit, Input } from '@angular/core';
import { ticketDetailStore, ticketEventsStore, ticketSolutionStore } from '../../store';
import { TechPortalFeatureSolutionBaseComponent } from '@nexnox-web/tech-portal/features/solution';
import { FileAttachmentForTechDto, SolutionMemberType, TemplateContextType } from '@nexnox-web/core-shared';
import { ofType } from '@ngrx/effects';
import { withLatestFrom } from 'rxjs/operators';
import { select } from '@ngrx/store';
import { cloneDeep, uniqBy } from 'lodash';

@Component({
  selector: 'nexnox-web-tickets-ticket-solution',
  templateUrl: './ticket-solution.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TicketSolutionComponent extends TechPortalFeatureSolutionBaseComponent implements OnInit {
  @Input() public hasChat: boolean;
  public componentId = 'TicketSolutionComponent';

  public solutionMemberType = SolutionMemberType.Ticket;
  public templateContextTypes = TemplateContextType;

  constructor(
    protected injector: Injector
  ) {
    super(injector, ticketSolutionStore, [
      ticketDetailStore.actions.changeStateSuccess,
      ticketEventsStore.actions.cancelOneSuccess
    ]);
  }

  /* istanbul ignore next */
  public ngOnInit(): void {
    super.ngOnInit();

    this.subscribe(this.actions$.pipe(
      ofType(ticketSolutionStore.actions.createOneSuccess),
      withLatestFrom(this.store.pipe(select(ticketDetailStore.selectors.selectEntity))),
      withLatestFrom(this.store.pipe(select(ticketDetailStore.selectors.selectModel)))
    ), ([[action, ticketEntity], ticketModel]) => {
      const newEntity = cloneDeep(ticketEntity);
      const newModel = cloneDeep(ticketModel);

      if (!action.model?.note?.attachments) return;
      if (!newModel?.solution || !newEntity?.solution) return;

      newEntity.solution.attachments = uniqBy([
        ...(newEntity.solution.attachments ?? []),
        ...(action.model.note.attachments ?? [])
      ], x => (x as FileAttachmentForTechDto).file?.fileId);
      newModel.solution.attachments = uniqBy([
        ...(newModel.solution.attachments ?? []),
        ...(action.model.note.attachments ?? [])
      ], x => (x as FileAttachmentForTechDto).file?.fileId);

      this.store.dispatch(ticketDetailStore.actions.entityUpdate({ entity: newEntity }));
      this.store.dispatch(ticketDetailStore.actions.modelUpdate({ model: newModel }));
    });
  }
}
