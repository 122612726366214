import { InjectionToken } from '@angular/core';

export interface TechPortalTextTemplateContextConfig {
  [context: number]: {
    serializedName: string;
    prefix: string;
    sanitize: (model: any) => any;
    parents?: number[];
  };
}

export const TECH_PORTAL_TEXT_TEMPLATE_CONTEXT_CONFIG = new InjectionToken<TechPortalTextTemplateContextConfig>(
  '@nexnox-web/tech-portal: Text template context config'
);
