import { PagedEntitiesXsStore } from '@nexnox-web/core-store';
import { AppEntityType, ControlTagDto } from '@nexnox-web/core-shared';
import { createSelector } from '@ngrx/store';
import { selectControlTagsState } from '../../control-tags.selectors';
import { CorePortalCCPControlTagService } from '@nexnox-web/core-portal';

/* istanbul ignore next */
export const controlTagListStore = new PagedEntitiesXsStore<ControlTagDto>({
  actionLabel: 'Tech Portal - CCP - Control Tags - List',
  stateSelector: createSelector(selectControlTagsState, state => state.list),
  serviceType: CorePortalCCPControlTagService,
  entityType: AppEntityType.ControlTag,
  selectId: controlTag => controlTag.entity?.controlTagId ?? controlTag.model?.controlTagId,
  stereotyped: true
});
