import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { CoreSharedBreadcrumbsService } from '../../services';
import { CoreSharedBreadcrumb } from '../../models';
import { AppPermissions } from '@nexnox-web/core-shared';
import { map } from 'rxjs/operators';

@Component({
  selector: 'nexnox-web-core-shared-breadcrumbs',
  templateUrl: './breadcrumbs.component.html'
})
export class CoreSharedBreadcrumbsComponent implements OnInit {
  @Input() public permissions: AppPermissions[];

  public breadcrumbs$: Observable<Array<CoreSharedBreadcrumb & { hasPermission: boolean }>>;

  public trackBreadcrumbsBy: any;

  constructor(
    private service: CoreSharedBreadcrumbsService
  ) {
    this.trackBreadcrumbsBy = (index: number, breadcrumb: CoreSharedBreadcrumb) => index;
  }

  public ngOnInit(): void {
    this.breadcrumbs$ = this.service.breadcrumbs$.pipe(
      map(breadcrumbs => breadcrumbs.map(breadcrumb => ({
        ...breadcrumb,
        hasPermission: (breadcrumb.permissions ?? []).every(permission => this.permissions.includes(permission))
      })))
    );
  }
}
