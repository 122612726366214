import { ChangeDetectionStrategy, Component, Injector, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActionButton, CorePortalEntityOverviewBaseComponent, DatatableActionButton, DeleteEntityModel } from '@nexnox-web/core-portal';
import {AppEntityType, AppPermissions, ControllerOperationId, InfoDto, Mappers} from '@nexnox-web/core-shared';
import { infoListStore } from '../../store';

@Component({
  selector: 'nexnox-web-infos-info-list',
  templateUrl: './info-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InfoListComponent extends CorePortalEntityOverviewBaseComponent<InfoDto> implements OnInit {
  @ViewChild('categoryLabelTitleTemplate', { static: true }) public categoryLabelTitleTemplate: TemplateRef<any>;
  @ViewChild('categoryOptionTitleTemplate', { static: true }) public categoryOptionTitleTemplate: TemplateRef<any>;

  public title = 'orga-portal.infos.subtitles.info-list';
  public createTitle = 'orga-portal.infos.actions.create-info';
  public idProperty = 'infoId';
  public displayProperty = 'title';
  public datatableConfigName = 'InfoDefault';
  public componentId = 'InfoListComponent';

  public pageOperation = ControllerOperationId.InfoControllerList;
  public enableViews = true;

  constructor(
    protected injector: Injector
  ) {
    super(injector, infoListStore, Mappers.InfoListDto.serializedName, AppEntityType.Info);
  }

  public ngOnInit(): void {
    this.templates = {
      categoryLabelTitleTemplate: this.categoryLabelTitleTemplate,
      categoryOptionTitleTemplate: this.categoryOptionTitleTemplate
    };

    super.ngOnInit();
  }

  public getRowActionButtons(): DatatableActionButton[] {
    return this.getDefaultRowActionButtons(
      'orga-portal.infos.actions.edit-info',
      (row: InfoDto) => `/infos/${row.infoId}`,
      [AppPermissions.UpdateInfo],
      {
        module: 'communication'
      }
    );
  }

  public getDeleteEntityModel(): DeleteEntityModel {
    return {
      titleKey: 'orga-portal.infos.actions.delete-info',
      descriptionKey: 'orga-portal.infos.descriptions.delete-info',
      deletePermission: AppPermissions.DeleteInfo
    };
  }

  protected async getActionButtons(): Promise<ActionButton[]> {
    return this.getDefaultActionButtons('orga-portal.infos.actions.create-info', AppPermissions.CreateInfo);
  }
}
