import { InjectionToken, NgModule } from '@angular/core';
import { ActionReducerMap, StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { gdprReducers, gdprEffects } from './store';

export const REDUCER_TOKEN = new InjectionToken<ActionReducerMap<any>>(
  '@nexnox-web/core-portal/settings/gdpr: Store Reducers'
);

@NgModule({
  imports: [
    StoreModule.forFeature('core-portal-feature-settings-gdpr', REDUCER_TOKEN),
    EffectsModule.forFeature(gdprEffects)
  ],
  providers: [
    {
      provide: REDUCER_TOKEN,
      useValue: gdprReducers
    }
  ]
})
export class GdprStoreModule {
}
