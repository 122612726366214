import { AppEntityType, SystemContactDto } from '@nexnox-web/core-shared';
import { PagedEntitiesXsStore, PagedEntitiesXsStoreState } from '@nexnox-web/core-store';
import { createSelector } from '@ngrx/store';
import { TenantSystemContactService } from '../../services';
import { selectTenantsState } from '../../tenants.selectors';

export interface TenantSystemContactListStoreState extends PagedEntitiesXsStoreState<SystemContactDto> {
}

/* istanbul ignore next */
export const tenantSystemContactListStore = new PagedEntitiesXsStore<SystemContactDto>({
  actionLabel: 'Core Portal - Service - Tenants - Tenant Systemcontact List',
  stateSelector: createSelector(selectTenantsState, state => state.tenantSystemContactList),
  serviceType: TenantSystemContactService,
  entityType: AppEntityType.None,
  selectId: sub => sub.entity?.contactId ?? sub.model?.contactId,
  stereotyped: false,
  removeTenantIdOnCreate: false
});
