import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CorePortalPermissionGuard } from '@nexnox-web/core-portal';
import {AppPermissions, I18nBreadcrumbResolver, UnsavedChangesGuard, UnsavedCreateGuard} from '@nexnox-web/core-shared';
import { GdprListComponent, GdprDetailComponent } from './containers';

export const CORE_PORTAL_GDPR_ROUTES: Routes = [
  {
    path: '',
    canActivate: [CorePortalPermissionGuard],
    canDeactivate: [UnsavedCreateGuard],
    component: GdprListComponent,
    data: {
      permissions: [AppPermissions.DisplayGeneralDataProtectionRegulations]
    }
  },
  {
    path: ':generalDataProtectionRegulationId',
    component: GdprDetailComponent,
    canActivate: [CorePortalPermissionGuard],
    canDeactivate: [UnsavedChangesGuard],
    data: {
      permissions: [AppPermissions.ReadGeneralDataProtectionRegulation],
      breadcrumbs: I18nBreadcrumbResolver,
      text: 'core-portal.settings.gdpr.titles.detail'
    },
    children: [
      {
        path: '',
        data: {
          tab: 'gdpr-settings'
        }
      },
      {
        path: 'log-entries',
        data: {
          tab: 'log-entries'
        }
      }
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(CORE_PORTAL_GDPR_ROUTES)
  ],
  exports: [
    RouterModule
  ]
})
export class GdprRoutingModule {
}
