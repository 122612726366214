import {Injectable, Injector} from '@angular/core';
import {
	CoreSharedApiBaseService,
	LabelDescriptorType,
	LabelDto,
	Product
} from '@nexnox-web/core-shared';
import {Observable} from "rxjs";
import {map} from "rxjs/operators";

@Injectable({
	providedIn: 'root'
})
export class CorePortalLabelService extends CoreSharedApiBaseService {
	constructor(
		protected injector: Injector
	) {
		super(injector, Product.TECH, 'label');
	}

	public addLabel(labelId: number, entityId: number, type: LabelDescriptorType): Observable<LabelDto> {
		return this.httpRequestService.post<LabelDto>(`label/${labelId}/addLabel`, {entityId, type}).pipe(
			map(response => response.body)
		);
	}

	public removeLabel(labelId: number, entityId: number, type: LabelDescriptorType): Observable<LabelDto> {
		return this.httpRequestService.post<LabelDto>(`label/${labelId}/removeLabel`, {entityId, type}).pipe(
			map(response => response.body)
		);
	}
}
