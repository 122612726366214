import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  ContentChildren,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {CorePortalEntityOverviewBaseComponent} from '../../base-components';
import {
  CorePortalEntityDatatableComponent,
  DatatableActionButton,
  DatatableHeaderAction,
  DatatableLoadPagePayload,
  DatatableTableColumn,
  DatatableTableColumnTyping
} from '../../../entity-datatable';
import {NEVER, Observable, of, Subscription} from 'rxjs';
import {CorePortalCardboxAction} from '../../../../cardbox/components';
import {DataTableDto, mapFilterToDatatableFilter, UnsubscribeHelper} from '@nexnox-web/core-shared';
import {filter, take} from 'rxjs/operators';
import {TabsComponent, TabTemplateDirective} from '../../../../tabs';

@Component({
  selector: 'nexnox-web-entity-overview',
  templateUrl: './entity-overview.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CorePortalEntityOverviewComponent extends UnsubscribeHelper implements OnInit, AfterViewInit, OnDestroy {
  @Input() public entityOverviewBaseComponent: CorePortalEntityOverviewBaseComponent<any>;
  @Input() public detailLink: string;
  @Input() public module: string;
  @Input() public entityEditTemplate: TemplateRef<any>;
  @Input() public stereotyped = true;
  @Input() public detailFn: (row: any) => void = undefined;
  @Input() public isEmbedded = false;
  @Input() public detailTemplate: TemplateRef<any>;
  @Input() public title: string;
  @Input() public showSettings = true;
  @Input() public hideFilters = false;
  @Input() public disableFilters = false;
  @Input() public allowMargin = false;
  @Input() public datatableConfig: DataTableDto;
  @Input() public disableSettingsViews = false;
  @Input() public disableSettingsViewSelect = false;
  @Input() public saveFiltersOnChange = false;
  @Input() public customInit$: Observable<void> = null;
  @Input() public hideIfCreate = true;
  @Input() public disableCreate = false;
  @Input() public disableSavedFilters = false;
  @Input() public disableSavedSortObject = false;
  @Input() public canExport = true;
  @Input() public appendColumns: DatatableTableColumn[];
  @Input() public cardBoxHeaderActions: CorePortalCardboxAction[];
  @Input() public showDetailTemplateExpandButtonFn: (row: any) => boolean;
  @Input() public enableCalendarSubscription: boolean;
  @Input() public tabId: string;
  @Input() public tabHeading: string;
  @Input() public tabCommands: any[];
  @Input() public useTabs = false;

  @Input() public editFooterActions: CorePortalCardboxAction[];

  @Output() public datatableConfigChange: EventEmitter<DataTableDto> = new EventEmitter<DataTableDto>();
  @Output() public clickableCellEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() public selectInitialTab: EventEmitter<any[]> = new EventEmitter<any[]>();

  @ContentChildren(TabTemplateDirective) public tabTemplates: QueryList<TabTemplateDirective>;

  @ViewChild('tabsComponent') public tabsComponent: TabsComponent;
  @ViewChild('datatableComponent') public datatableComponent: CorePortalEntityDatatableComponent;

  @ContentChild('appendTitleTemplate') public appendCardboxTitleTemplate: TemplateRef<any>;

  public canDelete: Observable<boolean>;
  public rowActionButtons: DatatableActionButton[] = [];
  public additionalHeaderActions: DatatableHeaderAction[] = [];
  public columnTypings: DatatableTableColumnTyping[] = [];

  private applyFilterSubscription: Subscription;
  private clearFilterSubscription: Subscription;

  public get showTabs(): boolean {
    return !this.entityOverviewBaseComponent.hideTabs && this.useTabs;
  }

  public ngOnInit(): void {
    this.subscribeToRoute();
    const deleteEntityModel = this.entityOverviewBaseComponent.getDeleteEntityModel();
    if (deleteEntityModel) {
      this.canDelete = !this.entityOverviewBaseComponent.canDelete ? of(false) :
        this.entityOverviewBaseComponent.hasPermission$(deleteEntityModel.deletePermission);
    }

    this.rowActionButtons = this.entityOverviewBaseComponent.getRowActionButtons();
    this.additionalHeaderActions = this.entityOverviewBaseComponent.getAdditionalHeaderActions();
    this.columnTypings = this.entityOverviewBaseComponent.getColumnTypings();
  }

  public ngAfterViewInit(): void {
    const init$: Observable<any> = this.customInit$ ?? of(true);

    init$.pipe(take(1)).subscribe(() => this.datatableComponent.createColumns(
      this.entityOverviewBaseComponent.serializedNameOrMapper
    ));

    setTimeout(() => this.subscribeFilterActions());
  }

  public onLoadPage(payload: DatatableLoadPagePayload): void {
    this.entityOverviewBaseComponent.onLoadPage(payload);

    if (this.saveFiltersOnChange && payload.filtersChanged) {
      setTimeout(() => this.onDatatableConfigChange({
        ...this.datatableConfig,
        filters: (payload.filters ?? []).map(x => mapFilterToDatatableFilter(x))
      }));
    }
  }

  public onDatatableConfigChange(config: DataTableDto): void {
    this.datatableConfigChange.emit(config);
    this.entityOverviewBaseComponent.datatableConfigChange.emit(config);
  }

  public subscribeFilterActions(): void {

    // Apply a visible filter
    this.applyFilterSubscription = this.entityOverviewBaseComponent?.applyFilter?.subscribe((payload) => {
      const column = this.datatableComponent.columns.find((c) => c.name === payload.columnName);
      this.datatableComponent.onSearch(column, payload.filter);
    });

    // Remove visible filters
    this.clearFilterSubscription = this.entityOverviewBaseComponent?.clearFilters?.subscribe(() => {
      this.datatableComponent.onClearFilters()
    });
  }

  public onCellClick(event: any): void {
    this.clickableCellEvent.emit(event);
  }

  public onNavigateToTab(commands: any[]): void {
    this.selectInitialTab.emit(commands);
  }

  public ngOnDestroy(): void {
    this.applyFilterSubscription?.unsubscribe();
    this.clearFilterSubscription?.unsubscribe();
    super.ngOnDestroy();
  }

  private subscribeToRoute(): void {
    this.subscribe((this.entityOverviewBaseComponent?.route?.firstChild?.data ?? NEVER).pipe(
      filter(data => Boolean(data?.tab)),
      take(1)
    ), data => {
      setTimeout(() => this.tabsComponent?.activateTab(data.tab));
    });
  }
}
