import {PagedEntitiesXsStore, PagedEntitiesXsStoreState} from '@nexnox-web/core-store';
import {AppEntityType, ResourceDto} from '@nexnox-web/core-shared';
import {Action, ActionReducer, createSelector} from '@ngrx/store';
import {selectResourcesState} from '../../resources.selectors';
import {TechPortalFeatureVirtualResourceService} from '../../services';
import {Injectable, Injector} from '@angular/core';

export interface ResourceListStoreState extends PagedEntitiesXsStoreState<ResourceDto> {
}

/* istanbul ignore next */
export const resourceListStore = new PagedEntitiesXsStore<ResourceDto>({
  actionLabel: 'Core Portal - Resources - Resource List',
  stateSelector: createSelector(selectResourcesState, state => state.resourceList),
  serviceType: TechPortalFeatureVirtualResourceService,
  entityType: AppEntityType.Resource,
  selectId: resource => resource.entity?.resourceId ?? resource.model?.resourceId
});

export function resourceListStoreReducer(state: ResourceListStoreState, action: Action): ActionReducer<any> {
  return resourceListStore.reducer(state, action);
}

@Injectable()
export class ResourceListStoreEffects extends resourceListStore.effects {
  constructor(
    protected injector: Injector
  ) {
    super(injector);
  }
}
