import { EntityXsStoreState, PagedEntitiesXsStoreState } from '@nexnox-web/core-store';
import { ControlTagDto } from '@nexnox-web/core-shared';
import { controlTagDetailStore, controlTagListStore } from './stores';

export interface ControlTagsState {
  list: PagedEntitiesXsStoreState<ControlTagDto>;
  detail: EntityXsStoreState<ControlTagDto>;
}

export const getInitialControlTagsState = (): ControlTagsState => ({
  list: controlTagListStore.getInitialState(),
  detail: controlTagDetailStore.getInitialState()
});
